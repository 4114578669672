import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyBold, sourceSansPro } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ReactHtmlParser from 'react-html-parser';
import { handleUrl } from 'shared-components/theme/helpers';

export const ResourceCard = ({ data }) => {
    return data.metadata && data.metadata.show_its_landing_page ? (
        <Wrapper
            href={handleUrl(
                data.metadata.associated_landing_page['permalink']
            )}>
            <Image>
                <img
                    src={
                        data.metadata.associated_landing_page['featured_image']
                    }
                    alt="-"
                />
            </Image>
            <Content>
                <Title>
                    {ReactHtmlParser(
                        data.metadata.associated_landing_page['post_title']
                    )}
                </Title>
                <Para>
                    {ReactHtmlParser(
                        data.metadata && data.metadata['short_description']
                    )}
                </Para>
            </Content>
        </Wrapper>
    ) : (
        <Wrapper href={handleUrl(data['permalink'])}>
            <Image>
                <img src={data['featured_image']} alt="-" />
            </Image>
            <Content>
                <Title>{ReactHtmlParser(data['post_title'])}</Title>
                <Para>
                    {ReactHtmlParser(
                        data.metadata && data.metadata['short_description']
                    )}
                </Para>
            </Content>
        </Wrapper>
    );
};

const Image = styled.div`
    height: 350px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 330ms ease;
    }
    ${Media('xlscreens')} {
        height: 18.2vw;
    }
    ${Media('mobile')} {
        height: auto;
    }
`;

const Wrapper = styled.a`
    background-color: ${colors.salt['100']};
    border-radius: 10px;
    display: block;
    overflow: hidden;
    margin-bottom: 30px;
    transform: translateY(0px);
    transition: all 330ms ease;
    &:hover {
        box-shadow: 0px 5px 6px ${colors.black['10']};
        transform: translateY(-5px);
        ${Image} {
            img {
                transform: scale(1.1);
            }
        }
    }
    ${Media('xlscreens')} {
        margin-bottom: 1.6vw;
        height: 35vw;
    }
`;

const Content = styled.div`
    padding: 30px 50px;
    ${Media('xlscreens')} {
        padding: 2.2vw 5vw 3vw;
    }
    ${Media('mobile')} {
        padding: 20px;
    }
`;

const Title = styled.h2`
    font-size: 28px;
    font-family: ${gilroyBold};
    letter-spacing: -0.5px;
    margin: 0px 0 30px;
    line-height: 1.4;
    ${Media('xlscreens')} {
        font-size: 1.45vw;
        margin: 0px 0 1.5vw;
        letter-spacing: -0.05vw;
    }
    ${Media('mobile')} {
        font-size: 20px;
    }
`;

const Para = styled.div`
    font-size: 16px;
    font-family: ${sourceSansPro};
    color: ${colors.gray_dark['100']};
    p {
        font-size: inherit;
        font-family: inherit;
        margin: 0;
    }
    ${Media('xlscreens')} {
        font-size: 0.95vw;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
