import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const EbookMediumHeading = ({ children, color }) => {
    return <Wrapper color={color}>{children}</Wrapper>;
};

const Wrapper = styled.h3`
    font-size: 48px;
    font-family: ${gilroyMedium};
    font-weight: normal;
    color: ${(props) => (props.color ? props.color : colors.ebook.text)};
    line-height: 1.77;
    margin-top: 0px;
    margin-bottom: 50px;
    ${Media('xlscreens')} {
        font-size: 2.34vw;
        margin-bottom: 2.5vw;
    }
    ${Media('mobile')} {
        font-size: 32px;
        margin-bottom: 30px;
        line-height: 1.3;
        br {
            display: none;
        }
    }
`;
