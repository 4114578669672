import React from 'react';
import Layout from 'components/layout';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import {
    gilroySemibold,
    gilroyBold,
    gilroyMedium,
} from 'shared-components/theme/fonts';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { MdArrowForward } from 'react-icons/md';
import {
    WebinarMainHeading,
    WebinarSubHeading,
} from 'components/webinars/content/common';
import { FlexColumnCentered } from 'shared-components/theme/grid-helpers';
import BannerMorph from 'components/banner-morph/banner-morph';
import { baseURL, baseURLPage } from 'shared-components/theme/helpers';

const Aep = () => {
    return (
        <Layout page="health">
            <Breadcrumbs bgColor={colors.gray_lightest['100']}>
                <li>Solutions</li>
                <li>Health</li>
            </Breadcrumbs>
            <AepIntro>
                <Container>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <Row>
                            <Col sm="12" md="6">
                                <IntroHeading>
                                    Managing Burst <br /> Capacity
                                </IntroHeading>
                            </Col>
                            <Col sm="12" md={{ size: 5, offset: 1 }}>
                                <Aeptagline>Your Key to AEP Success</Aeptagline>
                                <AepLink>
                                    Proven Methodology. Predictable Performance.
                                </AepLink>
                            </Col>
                        </Row>
                    </ScrollAnimation>
                </Container>
                <BannerMorph
                    image={`${baseURL}assets/images/aep-intro-image-large.jpg`}
                />

                <Container>
                    <Row>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <AepMainPara>
                                    In an industry dependent on successfully
                                    managing seasonal spikes, having an <br />
                                    outsourcing partner with proven burst
                                    capacity performance is critical to the
                                    <br />
                                    success of your <a>AEP season.</a> Having a
                                    partner that can deliver the required number
                                    <br />
                                    of screeners and/or licensed agents, so that
                                    you can meet your expected close <br />
                                    ratios, is the difference between a good
                                    season and a great season.
                                </AepMainPara>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </AepIntro>
            <IbexLeverages>
                <IbexLeveragesImage>
                    <ScrollAnimation animateIn="fadeInLeft" animateOnce={false}>
                        <img
                            src={`${baseURL}assets/images/leverage-bg.jpg`}
                            alt=""
                        />
                    </ScrollAnimation>
                </IbexLeveragesImage>
                <LeverageTextWrap>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <h3>
                            ibex leverages multiple components to successfully
                            manage seasonal burst capacity:
                        </h3>
                        <ul>
                            <li>
                                <ListIconImage>
                                    <img
                                        src={`${baseURL}assets/images/robust-search.svg`}
                                        alt=""
                                    />
                                </ListIconImage>
                                <ListText>A robust recruiting engine</ListText>
                            </li>
                            <li>
                                <ListIconImage>
                                    <img
                                        src={`${baseURL}assets/images/proprietary-icon.svg`}
                                        alt=""
                                    />
                                </ListIconImage>
                                <ListText>
                                    Proprietary tools and processes dedicated to
                                    agent training and performance
                                </ListText>
                            </li>
                            <li>
                                <ListIconImage>
                                    <img
                                        src={`${baseURL}assets/images/wealth-icon.svg`}
                                        alt=""
                                    />
                                </ListIconImage>
                                <ListText>
                                    A wealth of experience managing seasonal
                                    ramps involving thousands of agents
                                </ListText>
                            </li>
                        </ul>
                    </ScrollAnimation>
                </LeverageTextWrap>
            </IbexLeverages>
            <IbexMultipleComponents>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h3>
                                    Delivering best-in-class burst capacity
                                    performance for <br />
                                    one of the world’s largest retail and
                                    e-commerce brands:
                                </h3>
                            </Col>
                        </Row>
                    </Container>
                </ScrollAnimation>
            </IbexMultipleComponents>
            <OffsetImageSec>
                <Container>
                    <Row>
                        <Col lg="4">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <TextCard>
                                    <img
                                        src={`${baseURL}assets/images/offset-headphone-icon.svg`}
                                        alt="-"
                                    />
                                    <h4>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 27.008 27.716">
                                            <path
                                                id="Icon_awesome-arrow-up"
                                                data-name="Icon awesome-arrow-up"
                                                d="M2.207,18.178.833,16.805a1.478,1.478,0,0,1,0-2.1L12.853,2.683a1.478,1.478,0,0,1,2.1,0L26.969,14.7a1.478,1.478,0,0,1,0,2.1L25.6,18.172a1.486,1.486,0,0,1-2.122-.025l-7.1-7.448V28.478a1.481,1.481,0,0,1-1.485,1.485H12.914a1.481,1.481,0,0,1-1.485-1.485V10.7l-7.1,7.454a1.476,1.476,0,0,1-2.122.025Z"
                                                transform="translate(-0.397 -2.247)"
                                                fill={
                                                    colors.health['dark_blue']
                                                }
                                            />
                                        </svg>
                                        3,000
                                    </h4>
                                    <p>
                                        For the 2020 holiday season, ibex
                                        ramped-up <br />
                                        3,000 new agents
                                    </p>
                                </TextCard>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="4">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <TextCard>
                                    <img
                                        src={`${baseURL}assets/images/offset-calendar-icon.svg`}
                                        alt="-"
                                    />
                                    <h4>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 27.008 27.716">
                                            <path
                                                id="Icon_awesome-arrow-up"
                                                data-name="Icon awesome-arrow-up"
                                                d="M2.207,18.178.833,16.805a1.478,1.478,0,0,1,0-2.1L12.853,2.683a1.478,1.478,0,0,1,2.1,0L26.969,14.7a1.478,1.478,0,0,1,0,2.1L25.6,18.172a1.486,1.486,0,0,1-2.122-.025l-7.1-7.448V28.478a1.481,1.481,0,0,1-1.485,1.485H12.914a1.481,1.481,0,0,1-1.485-1.485V10.7l-7.1,7.454a1.476,1.476,0,0,1-2.122.025Z"
                                                transform="translate(27.405 29.962) rotate(180)"
                                                fill={
                                                    colors.health['dark_blue']
                                                }
                                            />
                                        </svg>
                                        50%
                                    </h4>
                                    <p>
                                        ibex reduced the speed to proficiency
                                        <br />
                                        from the typical 6-7 weeks to 3 weeks
                                        <br />– a 50% reduction.
                                    </p>
                                </TextCard>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="4">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <TextCard>
                                    <img
                                        src={`${baseURL}assets/images/offset-trophy-icon.svg`}
                                        alt="-"
                                    />
                                    <h4>#1</h4>
                                    <p>
                                        ibex ranked #1 across the enterprise for
                                        <br />
                                        key performance metric attainment and
                                        <br />
                                        CSAT during the seasonal ramp period.
                                    </p>
                                </TextCard>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </OffsetImageSec>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                <InteractionSection>
                    <WebinarMainHeading color={colors.white['100']} noMargin>
                        Looking to level-up your enrollment program?
                    </WebinarMainHeading>
                    <WebinarSubHeading>
                        Let’s discuss your next move.
                    </WebinarSubHeading>
                    <TalkToUsBtn href={`${baseURLPage}contact-us`}>
                        <ButtonText>Talk to Us.</ButtonText>
                        <ButtonIcon>
                            <MdArrowForward />
                        </ButtonIcon>
                    </TalkToUsBtn>
                </InteractionSection>
            </ScrollAnimation>
        </Layout>
    );
};

const OffsetImageSec = styled.div`
    padding: 40px 0 60px;
    position: relative;
    z-index: 1;
    /* &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: -1;
        width: 100%;
        height: 30%;
        background-color: ${colors.health['lightest_blue']};
    } */
    ${Media('xlscreens')} {
        padding: 3vw 0 7vw;
    }
`;
const TextCard = styled(FlexColumnCentered)`
    text-align: center;
    margin: 50px 0 0;
    ${Media('xlscreens')} {
        margin: 5vw 0 0;
    }
    ${Media('tablet')} {
        margin: 30px 0 0;
    }
    img {
        width: auto;
        height: 37px;
        ${Media('xlscreens')} {
            height: 1.95vw;
        }
    }
    h4 {
        color: ${colors.health['blue']};
        font-family: ${gilroySemibold};
        font-size: 38px;
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        svg {
            width: 27px;
            margin-right: 10px;
        }
        ${Media('xlscreens')} {
            font-size: 1.9vw;
            margin-top: 1.6vw;
            margin-bottom: 1.1vw;
            svg {
                width: 1.3vw;
                margin-right: 0.65vw;
            }
        }
        ${Media('tablet')} {
            font-size: 24px;
        }
    }
    p {
        font-family: ${gilroyMedium};
        font-size: 16px;
        ${Media('xlscreens')} {
            font-size: 0.83vw;
        }
        ${Media('tablet')} {
            font-size: 16px;
        }
    }
`;
const IbexMultipleComponents = styled.div`
    position: relative;
    background-color: ${colors.health['lightest_blue']};
    padding: 60px 0;
    ${Media('xlscreens')} {
        padding: 6vw 0;
    }
    h3 {
        text-align: center;
        font-family: ${gilroySemibold};
        font-size: 35px;
        letter-spacing: -1px;
        color: ${colors.health['dark_blue']};
        ${Media('xlscreens')} {
            font-size: 1.83vw;
            letter-spacing: -0.08vw;
        }
        ${Media('tablet')} {
            font-size: 24px;
        }
    }
`;

const IbexLeverages = styled.section`
    position: relative;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    ${Media('tablet')} {
        height: auto;
    }
`;
const IbexLeveragesImage = styled.div`
    width: 50%;
    img {
        width: 100%;
    }
    ${Media('tablet')} {
        width: 100%;
    }
`;
const LeverageTextWrap = styled(FlexColumnCentered)`
    width: 50%;
    padding: 0px 30px;
    box-sizing: border-box;
    h3 {
        font-family: ${gilroySemibold};
        font-size: 35px;
        letter-spacing: -1px;
    }
    ul {
        padding: 0;
        li {
            display: flex;
            align-items: center;
            margin: 30px 0;
        }
    }
    ${Media('xlscreens')} {
        padding: 0px 10vw;
        h3 {
            font-size: 1.83vw;
            letter-spacing: -0.08vw;
        }
        ul {
            margin-top: 5vw;
            padding-right: 3vw;
            li {
                margin: 2.5vw 0;
            }
        }
    }
    ${Media('tablet')} {
        width: 100%;
        h3 {
            margin-top: 50px;
            margin-bottom: 50px;
            font-size: 30px;
        }
    }
`;
const ListIconImage = styled.div`
    img {
        width: 24px;
    }
    ${Media('xlscreens')} {
        img {
            width: 1.5vw;
        }
    }
`;
const ListText = styled.span`
    font-family: ${gilroyMedium};
    font-size: 22px;
    margin-left: 45px;
    ${Media('xlscreens')} {
        font-size: 1.1vw;
        margin-left: 2.2vw;
    }
`;
const AepIntro = styled.section`
    position: relative;
    background-color: ${colors.gray_lightest['100']};
    ${Media('xlscreens')} {
        padding-bottom: 7vw;
    }
`;
const IntroHeading = styled.h1`
    font-family: ${gilroySemibold};
    font-size: 80px;
    margin-top: 0;
    margin-bottom: 20px;
    ${Media('xlscreens')} {
        font-size: 4.1vw;
        margin-bottom: 1vw;
    }
    ${Media('tablet')} {
        font-size: 36px;
        margin-top: 0;
    }
`;
const Aeptagline = styled.span`
    font-family: ${gilroySemibold};
    font-size: 30px;
    display: block;
    margin-bottom: 15px;
    color: ${colors.health['blue']};
    ${Media('xlscreens')} {
        font-size: 1.25vw;
        margin-bottom: 0.8vw;
        margin-top: 1.2vw;
    }
    ${Media('tablet')} {
        font-size: 20px;
    }
`;
const AepLink = styled.div`
    font-family: ${gilroyMedium};
    font-size: 24px;
    text-decoration: none;
    color: ${colors.gray_dark['100']};
    ${Media('xlscreens')} {
        font-size: 1.2vw;
    }
    ${Media('tablet')} {
        font-size: 16px;
    }
`;
const AepMainPara = styled.p`
    font-family: ${gilroyMedium};
    font-size: 32px;
    margin-top: 140px;
    text-align: center;
    ${Media('xlscreens')} {
        font-size: 1.67vw;
        margin-top: 8.67vw;
    }
    ${Media('tablet')} {
        font-size: 20px;
        margin-top: 50px;
        br {
            display: none;
        }
    }
    a {
        color: ${colors.health['blue']};
    }
`;
const InteractionSection = styled.section`
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${colors.health['dark_blue']};
    text-align: center;
    h2 {
        margin-bottom: 30px;
    }
    ${Media('xlscreens')} {
        h2 {
            margin-bottom: 2vw;
            font-size: 2.34vw;
        }
        h5 {
            font-size: 1.35vw;
        }
    }
    ${Media('tablet')} {
        padding: 50px 20px;
    }
`;

const TalkToUsBtn = styled.a`
    height: 50px;
    width: 230px;
    display: flex;
    cursor: pointer;
    user-select: none;
    margin-top: 60px;
    ${Media('xlscreens')} {
        height: 2.6vw;
        width: 11.9vw;
        margin-top: 3.125vw;
    }
    ${Media('tablet')} {
        height: 35px;
        width: 200px;
        margin-top: 30px;
    }
`;
const ButtonText = styled.div`
    font-size: 23px;
    font-family: ${gilroyBold};
    color: ${colors.white['100']};
    background-color: ${colors.health['blue']};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.health['blue']};
    width: 80%;
    ${Media('xlscreens')} {
        font-size: 1.19vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
`;
const ButtonIcon = styled.div`
    height: 100%;
    background-color: ${colors.black['100']};
    font-size: 30px;
    color: ${colors.white['100']};
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border: 1px solid ${colors.white['100']};
    ${Media('xlscreens')} {
        font-size: 1.56vw;
    }
`;
export default Aep;
