import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import { gilroySemibold } from 'shared-components/theme/fonts';
import { Container } from '@bootstrap-styled/v4';
import Button from 'shared-components/button/button';
import { baseURLPage } from 'shared-components/theme/helpers';

export const LetsDiscuss = ({
    heading,
    para,
    bgColor,
    btnText,
    btnDark,
    btnBgColor,
    noPara,
}) => {
    return (
        <Wrapper bgColor={bgColor}>
            <div>
                <Container>
                    <BottomHeading>{heading}</BottomHeading>
                    {!noPara && (
                        <BottomText>
                            {para ? para : 'Let’s discuss your next move.'}
                        </BottomText>
                    )}

                    <Button
                        round={true}
                        href={`${baseURLPage}contact-us`}
                        bgColor={
                            btnBgColor
                                ? btnBgColor
                                : btnDark && colors.dark_purple['100']
                        }
                        color={btnDark && colors.white['100']}>
                        {btnText}
                    </Button>
                </Container>
            </div>
        </Wrapper>
    );
};
const Wrapper = styled.section`
    background-color: ${(props) =>
        props.bgColor ? props.bgColor : colors.gray_dark['100']};
    text-align: center;
    a {
        margin-top: 40px;
    }
    ${Media('xlscreens')} {
        a {
            margin-top: 2.08vw;
        }
    }
    ${Media('tablet')} {
        padding: 50px 0;
    }
`;
const BottomHeading = styled.h2`
    font-size: 30px;
    font-family: ${gilroySemibold};
    color: ${colors.white['100']};
    margin-top: 0;
    ${Media('xlscreens')} {
        font-size: 2.08vw;
    }
    ${Media('mobile')} {
        font-size: 26px;
    }
`;
const BottomText = styled.div`
    font-size: 14px;
    font-family: ${gilroySemibold};
    color: ${colors.white['100']};
    ${Media('xlscreens')} {
        font-size: 1.04vw;
    }
`;
