import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import {
    FlexColumnCentered,
    RowContentCenter,
} from 'shared-components/theme/grid-helpers';
import {
    gilroyLight,
    gilroyMedium,
    gilroyMediumItalic,
    gilroySemibold,
    morisonLightItalic,
} from 'shared-components/theme/fonts';
import { Col } from '@bootstrap-styled/v4';
import { baseURL } from 'shared-components/theme/helpers';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';

export const MainIntro = ({
    heading,
    para,
    noCircle,
    noPaddingBottom,
    style2,
}) => {
    return (
        <Intro noPaddingBottom={noPaddingBottom} style2={style2}>
            <RowContentCenter>
                <Col lg="10">
                    <FlexColumnCentered>
                        <SectionTag introTag>CX POCKET GUIDE</SectionTag>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <h1>{ReactHtmlParser(heading)}</h1>
                            <p>{ReactHtmlParser(para)}</p>
                        </ScrollAnimation>
                    </FlexColumnCentered>
                    <IntroVideoWrapper>
                        <iframe
                            width="100%"
                            height="100%"
                            src={
                                'https://www.youtube.com/embed/fF6Y24wWhxs?autoplay=1&mute=1'
                            }
                            title="Video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                        <SummaryBlock>
                            <span>TLDR</span>
                            <img
                                src={`${baseURL}assets/images/ebook/icon-arrow-up.svg`}
                                alt="-"
                            />
                        </SummaryBlock>
                        <SummaryBlock arrowDown>
                            <span>Full Content</span>
                            <img
                                src={`${baseURL}assets/images/ebook/icon-feather-arrow-down.svg`}
                                alt="-"
                            />
                        </SummaryBlock>
                    </IntroVideoWrapper>
                </Col>
            </RowContentCenter>
            <Controller>
                <Scene
                    triggerHook="onLeave"
                    duration="100%"
                    offset={'-1500%'}
                    indicators={false}>
                    <Timeline>
                        <Tween
                            from={{
                                transform: 'rotate(0deg)',
                            }}
                            to={{
                                transform: 'rotate(-270deg)',
                            }}>
                            <FloatingImg
                                src={`${baseURL}assets/images/ebook/cx-pocket-guide-round.svg`}
                                width="178"
                                height="178"
                                alt="-"
                                top="34%"
                                left="10%"
                                zIndex="9"
                            />
                        </Tween>
                    </Timeline>
                </Scene>
            </Controller>
            {!noCircle && (
                <FloatingImg
                    src={`${baseURL}assets/images/ebook/pink-circle-big.svg`}
                    width="490"
                    height="490"
                    alt="-"
                    top="65%"
                    left="-5%"
                    zIndex="0"
                />
            )}
            <FloatingImg
                src={`${baseURL}assets/images/ebook/pink-circle-big.svg`}
                width="490"
                height="490"
                alt="-"
                top="-24%"
                right="-16%"
                zIndex="0"
            />
        </Intro>
    );
};

const IntroVideoWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 70px 0;
    iframe {
        height: 50vw;
        border: none;
    }
    ${Media('xlscreens')} {
        margin: 3.64vw 0;
        iframe {
            height: 40vw;
            position: relative;
            z-index: 9;
        }
    }
    ${Media('tablet')} {
        margin-bottom: 0;
    }
    ${Media('mobile')} {
        margin-top: 50px;
    }
`;

const Intro = styled.section`
    padding-top: 0;
    h1 {
        font-size: 75px;
        font-family: ${gilroyMedium};
        text-transform: uppercase;
        line-height: 1.1;
        letter-spacing: -2px;
        text-align: center;
        span {
            font-family: ${gilroyMediumItalic};
        }
        i {
            font-family: ${(props) =>
                props.style2 ? gilroyLight : morisonLightItalic};
        }
    }
    p {
        font-size: 28px;
        font-family: ${gilroyMedium};
        text-align: center;
        margin: 5 0px 0;
    }
    ${Media('xlscreens')} {
        h1 {
            font-size: 3.9vw;
            margin-top: 2.5vw;
        }
        p {
            font-size: 1.45vw;
        }
    }
    ${Media('tablet')} {
        h1 {
            font-size: 50px;
        }
        p {
            font-size: 22px;
            br {
                display: none;
            }
        }
    }
    ${Media('mobile')} {
        h1 {
            font-size: 36px;
        }
        p {
            font-size: 18px;
        }
    }
    ${(props) => {
        if (props.noPaddingBottom) {
            return `
                padding-bottom:0;
            `;
        }
    }}
`;

const FloatingImg = styled.img`
    position: absolute;
    top: ${(props) => props.top};
    right: ${(props) => props.right};
    left: ${(props) => props.left};
    bottom: ${(props) => props.bottom};
    z-index: ${(props) => props.zIndex};
    height: ${(props) => props.height + 'px'};
    width: ${(props) => props.width + 'px'};
    ${Media('xlscreens')} {
        height: ${(props) => props.height / 19.2 + 'vw'};
        width: ${(props) => props.width / 19.2 + 'vw'};
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const SummaryBlock = styled.div`
    position: absolute;
    right: -110px;
    top: -70px;
    z-index: 999;
    span {
        font-size: 20px;
        font-family: ${gilroyMedium};
    }
    img {
        width: 75px;
        margin: 10px auto;
    }
    ${Media('xlscreens')} {
        right: -5.72vw;
        top: -3.64vw;
        span {
            font-size: 1.04vw;
        }
        img {
            width: 3.9vw;
            position: relative;
            left: -1.8vw;
        }
        ${(props) => {
            if (props.arrowDown) {
                return `
                    top:12vw;
                    right:-10vw;
                    text-align:center;
                    img{
                        width:8vw;
                        left:0vw;
                    }
                `;
            }
        }}
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const SectionTag = styled.div`
    font-size: 24px;
    font-family: ${gilroySemibold};
    background-color: ${colors.cx_pocket_guide['dark']};
    display: inline-flex;
    padding: 10px 25px;
    color: ${(props) => (props.color ? props.color : colors.white['100'])};
    text-transform: uppercase;
    ${(props) => {
        if (props.introTag) {
            return `
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                ${Media('xlscreens')}{
                    border-bottom-left-radius: 0.53vw;
                    border-bottom-right-radius: 0.53vw;
                }
            `;
        } else {
            return `
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                ${Media('xlscreens')}{
                    border-top-left-radius: 0.53vw;
                    border-top-right-radius: 0.53vw;
                }
            `;
        }
    }}
    ${Media('xlscreens')} {
        font-size: 0.8vw;
        padding: 0.52vw 1.3vw;
    }
    ${Media('tablet')} {
        font-size: 16px;
    }
    ${Media('mobile')} {
        font-size: 14px;
        padding: 10px 20px;
    }
`;
