import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { FaStar, FaRegStar } from 'react-icons/fa';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import { RowCenteredBoth } from 'shared-components/theme/grid-helpers';
import {
    gilroySemibold,
    gilroyLight,
    gilroyMedium,
} from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';

export const RefleCXSection = () => {
    return (
        <>
            <div>
                <Controller>
                    <Scene
                        triggerElement=".cx_rating"
                        triggerHook="onLeave"
                        duration={'20%'}
                        indicators={false}>
                        <Timeline>
                            <Tween
                                from={{
                                    backgroundSize: '120%',
                                    backgroundPosition: 'center 86%',
                                }}
                                to={{
                                    backgroundSize: '58.8446%',
                                    backgroundPosition: 'center 110%',
                                }}>
                                <CXRating className="cx_rating">
                                    <Container>
                                        <RowCenteredBoth>
                                            <Col md={{ size: 9 }} lg="7">
                                                <RatingHead>
                                                    <Controller>
                                                        <Scene
                                                            triggerHook="onLeave"
                                                            duration={'20%'}
                                                            offset={'-120%'}>
                                                            <Timeline>
                                                                <Tween
                                                                    from={{
                                                                        opacity:
                                                                            '1',
                                                                    }}
                                                                    to={{
                                                                        opacity:
                                                                            '0',
                                                                    }}>
                                                                    <RatingSubHead>
                                                                        <strong>
                                                                            RefleCX:
                                                                        </strong>
                                                                        &nbsp;
                                                                        5-Star
                                                                        Outreach
                                                                        &
                                                                        Feedback
                                                                        Analytics
                                                                    </RatingSubHead>
                                                                </Tween>
                                                                <Tween
                                                                    from={{
                                                                        transform:
                                                                            'scale(1)',
                                                                    }}
                                                                    to={{
                                                                        transform:
                                                                            'scale(0.8)',
                                                                    }}>
                                                                    <RatingMainHead>
                                                                        How
                                                                        would
                                                                        you rate
                                                                        your
                                                                        current
                                                                        survey
                                                                        tool?
                                                                    </RatingMainHead>
                                                                </Tween>
                                                                <Tween
                                                                    from={{
                                                                        transform:
                                                                            'scale(1)',
                                                                    }}
                                                                    to={{
                                                                        transform:
                                                                            'scale(0.8)',
                                                                    }}>
                                                                    <RatingStars>
                                                                        <li>
                                                                            <FaStar />
                                                                        </li>
                                                                        <li>
                                                                            <FaStar />
                                                                        </li>
                                                                        <li>
                                                                            <FaStar />
                                                                        </li>
                                                                        <li>
                                                                            <FaStar />
                                                                        </li>
                                                                        <li>
                                                                            <FaRegStar />
                                                                        </li>
                                                                    </RatingStars>
                                                                </Tween>
                                                            </Timeline>
                                                        </Scene>
                                                    </Controller>
                                                </RatingHead>
                                            </Col>
                                        </RowCenteredBoth>
                                    </Container>
                                </CXRating>
                            </Tween>
                        </Timeline>
                    </Scene>
                </Controller>
            </div>
            <ReflexcWrapper>
                <Container>
                    <Row>
                        <Col md="12">
                            <div>
                                <ReflexText>
                                    <h2>
                                        RefleCX <sup>TM</sup>: 5-Star Outreach &
                                        Feedback Analytics
                                    </h2>
                                    <p>
                                        RefleCX<sup>TM</sup> is a lightweight
                                        post-engagement survey platform that
                                        triggers a quick SMS or Email experience
                                        survey after any chat, voice, or email
                                        support interaction.&nbsp;
                                    </p>
                                </ReflexText>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </ReflexcWrapper>
        </>
    );
};
const CXRating = styled.section`
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 0px;
    background-image: url('${baseURL}assets/images/iphone-img.png');
    background-position: center 86%;
    background-size: 120%;
    background-repeat: no-repeat;
    ${Media('tablet')} {
        height: auto;
        padding: 250px 0px 200px;
        background: ${colors.gray_dark['100']};
    }
    ${Media('mobile')} {
        padding: 150px 0px 120px;
    }
`;

const RatingHead = styled.div`
    text-align: center;
    position: relative;
`;

const RatingSubHead = styled.div`
    font-family: ${gilroyMedium};
    font-size: 16px;
    color: ${colors.white['100']};
    ${Media('xlscreens')} {
        font-size: 0.85vw;
    }
`;

const RatingMainHead = styled.h2`
    font-family: ${gilroySemibold};
    color: ${colors.white['100']};
    font-size: 45px;
    ${Media('xlscreens')} {
        font-size: 3.3vw;
        margin: 3.7vw 0px;
    }
    ${Media('mobile')} {
        font-size: 36px;
    }
`;

const RatingStars = styled.ul`
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
        color: ${colors.pink['100']};
        font-size: 30px;
        margin: 0px 15px;
        display: inline-flex;
        ${Media('xlscreens')} {
            font-size: 2vw;
            margin: 0.6vw;
        }
    }
`;

const ReflexcWrapper = styled.section`
    padding: 0px 0px;
`;

const ReflexText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 70vh;
    padding-top: 2vh;
    h2,
    p {
        width: 60%;
        text-align: center;
    }
    h2 {
        font-family: ${gilroySemibold};
        font-size: 45px;
        margin: 0px 0px 50px;
        position: relative;
        letter-spacing: -0.5px;
        sup {
            font-family: ${gilroyLight};
            font-size: 18px;
            position: absolute;
            top: -12px;
            margin-left: -18px;
        }
        ${Media('xlscreens')} {
            font-size: 3.3vw;
            margin-bottom: 3vw;
            letter-spacing: -0.1vw;
            sup {
                font-size: 0.8vw;
                top: -1.2vw;
                top: -0.7vw;
                margin-left: -1vw;
            }
        }
    }
    p {
        font-family: ${gilroyLight};
        width: 29%;
        ${Media('xlscreens')} {
            font-size: 0.94vw;
        }
    }
    ${Media('tablet')} {
        h2 {
            width: 80%;
        }
        p {
            width: 50%;
        }
    }
    ${Media('mobile')} {
        height: auto;
        padding: 120px 0px 50px;
        h2,
        p {
            width: 100%;
        }
        h2 {
            font-size: 36px;
        }
        p {
            font-size: 14px;
        }
    }
`;
