import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { gilroySemibold } from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';

const CareerCareBox = ({ imageUrl, type }) => {
    return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
            <Wrapper>
                <ImageWrapper>
                    <img src={imageUrl} alt="" />
                </ImageWrapper>
                <CareType>{type}</CareType>
            </Wrapper>
        </ScrollAnimation>
    );
};
const Wrapper = styled.div`
    margin: 10px 0px;
    margin-right: 20px;
    width: 150px;
    height: 150px;
    background: ${colors.white['100']};
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ${Media('xlscreens')} {
        width: 7.89vw;
        height: 7.89vw;
    }
    ${Media('mobile')} {
        height: 200px;
        width: 100%;
    }
`;
const ImageWrapper = styled.div`
    max-width: 50px;
    ${Media('xlscreens')} {
        max-width: 2.63vw;
    }
    img {
        height: 100%;
        width: 100%;
    }
    ${Media('mobile')} {
        max-width: 80px;
    }
`;
const CareType = styled.div`
    font-size: 13px;
    color: ${colors.pink['100']};
    font-family: ${gilroySemibold};
    text-align: center;
    margin-top: 20px;
    ${Media('xlscreens')} {
        font-size: 0.68vw;
    }
    ${Media('mobile')} {
        font-size: 16px;
    }
`;
export default CareerCareBox;
