import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from '../theme/medias';
import { gilroyMedium } from '../theme/fonts';
import colors from '../theme/colors';
import { baseURL } from '../theme/helpers';

const Breadcrumbs = ({ children, bgColor, color }) => {
    return (
        <Wrapper bgColor={bgColor}>
            <Container>
                <Row>
                    <Col md="12">
                        <List color={color}>{children}</List>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding: 15px 0;
    background-color: ${(props) => props.bgColor};
    ${Media('xlscreens')} {
        padding: 1vw 0;
    }
`;

const List = styled.ul`
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    li {
        font-family: ${gilroyMedium};
        font-size: 12px;
        color: ${(props) =>
            props.color ? props.color : colors.gray_dark['40']};
        position: relative;
        padding-right: 30px;
        margin-right: 15px;
        text-transform: capitalize;
        a {
            color: inherit;
            &:hover {
                color: ${colors.pink['100']};
            }
        }
        &:after {
            content: '';
            background-image: url('${baseURL}assets/images/right-arrow-grey.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100%;
            width: 20px;
            height: 100%;
            position: absolute;
            right: 0px;
            top: 0;
        }
        ${Media('xlscreens')} {
            font-size: 0.85vw;
            padding-right: 1.8vw;
            margin-right: 1vw;
            &:after {
                width: 0.85vw;
            }
        }
        ${Media('tablet')} {
            &:after {
                width: 15px;
                height: 18px;
            }
        }
        &:last-child {
            margin-right: 0px;
            padding-right: 0px;
            &:after {
                display: none;
            }
        }
    }
`;

export default Breadcrumbs;
