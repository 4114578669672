import React, { Fragment } from 'react';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';

import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import MainHeading from 'shared-components/main-heading/main-heading';
import Media from 'shared-components/theme/medias';
import MainPara from 'shared-components/main-para/main-para';
import colors from 'shared-components/theme/colors';
import HomeBigText from 'components/home-big-text/home-big-text';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import BannerMorph from 'components/banner-morph/banner-morph';
import Loader from 'components/loader/loader';
import {
    PlatformSection,
    RefleCXSection,
    RefleCXFeaturesSection,
    FeedbackSection,
} from './sections';
import { baseURL } from 'shared-components/theme/helpers';

const scrollNavData = [
    {
        name: 'intro',
        target: 'intro',
        offset: -170,
    },
    {
        name: 'features',
        target: 'features',
        offset: 150,
    },
    {
        name: 'cxrating',
        target: 'cxrating',
        offset: 0,
    },
    {
        name: 'feedback',
        target: 'feedback',
        offset: 0,
    },
    {
        name: 'journey',
        target: 'journey',
        offset: 0,
    },
];

const sections = [
    {
        component: <PlatformSection />,
        isScrollNav: false,
    },
    {
        component: <RefleCXSection />,
        isScrollNav: false,
    },
    {
        component: <RefleCXFeaturesSection />,
        isScrollNav: false,
    },
    {
        component: <FeedbackSection />,
        isScrollNav: true,
    },
];
const CXPage = () => {
    return (
        <Layout page="cx">
            <Breadcrumbs>
                <li>Solutions</li>
                <li>CX</li>
            </Breadcrumbs>
            <CXIntro id="intro">
                <Container>
                    <div>
                        <Row>
                            <Col lg="7">
                                <IntroHead>
                                    <MainHeading noMargin={true}>
                                        Pursue the perfect customer experience.
                                    </MainHeading>
                                </IntroHead>
                            </Col>
                            <Col lg={{ size: 4, offset: 1 }}>
                                <MainPara>
                                    At ibex CX, we help create value from
                                    customer feedback. We make it easy for your
                                    brand to receive and act on customer
                                    feedback from any touchpoint, and then use
                                    this vital information to drive your
                                    business successfully forward.
                                </MainPara>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <BannerMorph
                    image={`${baseURL}assets/images/cx-intro-video.jpg`}
                />
                <BigTextWrap>
                    <HomeBigText
                        bigTextOne="Connect."
                        bigTextTwo="Engage. Delight."
                        level="cxOne"
                        textOneleft="-20%"
                        textOneRight="50%"
                        textTwoleft="50%"
                        textTwoRight="-120%"
                    />
                </BigTextWrap>
                <BigCircle />
            </CXIntro>
            {sections &&
                sections.map((section, index) => {
                    return (
                        <Fragment key={index}>
                            {index === 1 ? <div id="reflecx"></div> : null}
                            {section.isScrollNav && (
                                <ScrollNav data={scrollNavData} />
                            )}
                            {section.component}
                        </Fragment>
                    );
                })}
        </Layout>
    );
};

const CXIntro = styled.section`
    position: relative;
    padding-bottom: 30px;
    .container-fluid {
        padding: 0;
    }
`;

const BigTextWrap = styled.div`
    div {
        overflow: visible;
    }
`;

const IntroHead = styled.div`
    padding-top: 20px;
    ${Media('xlscreens')} {
        padding-top: 1vw;
    }
    ${Media('tablet')} {
        padding-left: 0px;
        padding-bottom: 40px;
    }
`;

const BigCircle = styled.div`
    position: absolute;
    width: calc(100vh - 82px);
    height: calc(100vh - 82px);
    background: ${colors.white['20']};
    border: 1px solid ${colors.pink['100']};
    border-radius: 50%;
    left: 50%;
    top: 30%;
    z-index: 99;
    ${Media('xlscreens')} {
        width: 67vw;
        height: 67vw;
        top: 40vw;
        left: 56vw;
    }
    ${Media('tablet')} {
        display: none;
    }
`;
export default CXPage;
