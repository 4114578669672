import React from 'react';
import { Container } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { gilroyLight, gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';

export const CallCenterSection = () => {
    return (
        <SectionWrapper>
            <Container>
                <div>
                    <SectionHeading>
                        Join the best call center <br />
                        agent force in the industry
                    </SectionHeading>
                </div>
                <div>
                    <BestAgentText>
                        <Text>
                            <CenterAligned>
                                Do you have what it takes to represent the most
                                innovative worldwide brands? With Mission:
                                Possible, we’re building the
                                <br /> best call center team in the industry.
                            </CenterAligned>
                        </Text>
                    </BestAgentText>
                </div>
            </Container>
            <div>
                <AgentsImage>
                    <img
                        src={`${baseURL}assets/images/best-service-agent1.jpg`}
                        alt=""
                    />
                </AgentsImage>
            </div>
            <div>
                <AgentsImage>
                    <img
                        src={`${baseURL}assets/images/best-service-agent2.jpg`}
                        alt=""
                    />
                </AgentsImage>
            </div>
            <div>
                <AgentsImage2>
                    <img
                        src={`${baseURL}assets/images/best-service-agent3.jpg`}
                        alt=""
                    />
                </AgentsImage2>
            </div>
        </SectionWrapper>
    );
};
const SectionWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${colors.white['100']};
`;
const SectionHeading = styled.div`
    font-family: ${gilroySemibold};
    margin-top: 165px;
    margin-bottom: 60px;
    font-size: 80px;
    text-align: center;
    ${Media('xlscreens')} {
        margin-top: 8.68vw;
        margin-bottom: 3.15vw;
        font-size: 5vw;
    }
    ${Media('tablet')} {
        font-size: 33px;
        margin-top: 80px;
        margin-bottom: 40px;
    }
`;
const BestAgentText = styled.div`
    font-family: ${gilroyLight};
    font-size: 16px;
    margin-bottom: 115px;
    ${Media('xlscreens')} {
        font-size: 0.84vw;
        margin-bottom: 6.05vw;
    }
    ${Media('mobile')} {
        margin-bottom: 60px;
    }
`;

const Text = styled.div`
    font-family: ${gilroyLight};
    font-size: 16px;
    ${Media('xlscreens')} {
        font-size: 0.84vw;
    }
`;

const CenterAligned = styled.div`
    text-align: center;
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
`;

const AgentsImage = styled.div`
    height: 25.42vw;
    width: 100%;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

const AgentsImage2 = styled.div`
    height: 50vw;
    width: 100%;
    img {
        height: 100%;
        width: 100%;
    }
`;
