import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const UtilTag = ({ children, noPaddingTop }) => {
    return <Wrapper noPaddingTop={noPaddingTop}>{children}</Wrapper>;
};

const Wrapper = styled.div`
    font-size: 24px;
    font-family: ${gilroyBold};
    color: ${colors.pink['100']};
    padding-top: ${(props) => !props.noPaddingTop && '5px'};
    ${Media('xlscreens')} {
        font-size: 2vw;
        padding-top: ${(props) => !props.noPaddingTop && '0.4vw'};
    }
`;
