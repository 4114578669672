import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import Typical from 'react-typical';
import { gilroyMedium } from 'shared-components/theme/fonts';

export const BigHeading = ({
    children,
    marginBottom,
    color,
    noMarginTop,
    typical,
    active,
}) => {
    return (
        <Heading
            marginBottom={marginBottom}
            color={color}
            noMarginTop={noMarginTop}>
            {typical && active ? (
                <Typical steps={[children, 3000]} wrapper="pre" />
            ) : (
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    {children}
                </ScrollAnimation>
            )}
        </Heading>
    );
};
const Heading = styled.h2`
    margin-top: 70px;
    margin-bottom: 30px;
    font-size: 32px !important;
    font-family: ${gilroyMedium} !important;
    word-spacing: 5px;
    color: ${(props) => (props.color ? props.color : colors.black)} !important;
    pre {
        font-family: ${gilroyMedium} !important;
        white-space: normal;
    }
    span {
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        margin-top: 3.64vw;
        margin-bottom: 1.57vw;
        font-size: 1.66vw !important;
        word-spacing: 0.26vw;
    }
    ${Media('tablet')} {
        font-size: 24px !important;
        margin-top: 50px;
        margin-bottom: ${(props) =>
            props.marginBottom ? '100px !important' : '70px !important'};
    }
    ${Media('mobile')} {
        font-size: 20px !important;
        margin-bottom: ${(props) =>
            props.marginBottom ? '70px !important' : '50px !important'};
    }
    ${(props) => {
        if (props.noMarginTop) {
            return `
                margin-top:0 !important;
                pre{
                    margin-top:0;
                }
            `;
        }
    }}
`;
