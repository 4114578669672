import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from '../theme/medias';
import { baseURL } from 'shared-components/theme/helpers';

const Video = ({ video, videotype, image, playBtn, playBtnDark, notMuted }) => {
    const vid = useRef(null);

    useEffect(() => {
        if (video && video !== undefined) {
            vid.current.play();
        }
    });

    return video && video !== undefined ? (
        <div
            style={{ width: 'inherit', height: 'inherit' }}>
            <Wrapper>
                <VideoWrap
                    data-poster={image}
                    poster={image}
                    preload="auto"
                    loop
                    ref={vid}
                    muted={notMuted ? false : 'muted'}
                    className="lozad video-wrap"
                    sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
                    <source
                        data-src={video}
                        src={video}
                        type={`video/${videotype}`}
                    />
                </VideoWrap>
                {playBtn && playBtn !== undefined ? (
                    <PlayBtn>
                        {playBtnDark && playBtnDark !== undefined ? (
                            <img
                                src={`${baseURL}assets/images/play-financial.png`}
                                alt="-"
                            />
                        ) : (
                            <img
                                src={`${baseURL}assets/images/play-btn.png`}
                                alt="-"
                            />
                        )}
                    </PlayBtn>
                ) : null}
            </Wrapper>
        </div>
    ) : (
        <Wrapper>
            <ImageWrap src={image} alt="-" />
            {playBtn && playBtn !== undefined ? (
                <PlayBtn>
                    {playBtnDark && playBtnDark !== undefined ? (
                        <img
                            src={`${baseURL}assets/images/play-financial.png`}
                            alt="-"
                        />
                    ) : (
                        <img
                            src={`${baseURL}assets/images/play-btn.png`}
                            alt="-"
                        />
                    )}
                </PlayBtn>
            ) : null}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: inherit;
    position: relative;
`;

const VideoWrap = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const PlayBtn = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 60px;
    height: 60px;
    margin: auto;
    img {
        width: 100%;
    }
    ${Media('xlscreens')} {
        width: 8.4vw;
        height: 8.4vw;
    }
`;

const ImageWrap = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export default Video;
