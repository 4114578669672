import React, { useState } from 'react';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { baseURL } from 'shared-components/theme/helpers';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import DemoRequestForm from 'components/social-rep-man/demo-request-form';
import SRMIntroSection from 'components/social-rep-man/intro-section';
import { SRMonitoringSection } from 'components/social-rep-man/common';
import {
    CampaignCallFlow,
    ExpertBrowseSection,
    IntegrationBottomSection,
} from './sections';
import Loader from 'components/loader/loader';
import LazyLoad from 'react-lazyload';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -210,
    },
    {
        name: 'monitor',
        target: 'monitor',
        offset: 0,
    },
    {
        name: 'manage',
        target: 'manage',
        offset: 0,
    },
];

const monitorList = [
    {
        icon: `${baseURL}assets/images/tech/flexcx-monitor-icon-1.svg`,
        title: 'CX, On Demand',
        para:
            'With just a laptop and an internet connection, @home or in-center agents provide personalized, real-time, omnichannel interactions.',
    },
    {
        icon: `${baseURL}assets/images/tech/flexcx-monitor-icon-2.svg`,
        title: 'One-Click Analytics',
        para:
            'FleCX is an advanced CRM, featuring the deep contact and interaction analytics you’d expect out of the box - except its in the cloud.',
    },
    {
        icon: `${baseURL}assets/images/tech/flexcx-monitor-icon-3.svg`,
        title: 'Perfect Pairing',
        para:
            'FleCX optimizes every interaction by pairing customers to the best available agent for their needs, improving FCR and CSAT.',
    },
    {
        icon: `${baseURL}assets/images/tech/score-insight-icon-2.svg`,
        title: 'Seamless Setup',
        para:
            'Need an even lighter installation? Get agents up and running in a few clicks with the FleCX Chrome Extension.',
    },
];

const solutionFeatures = [
    {
        icon: `${baseURL}assets/images/tech/flexcx-feature-icon.svg`,
        title: 'Phone',
    },
    {
        icon: `${baseURL}assets/images/tech/flexcx-feature-icon.svg`,
        title: 'Chat',
    },
    {
        icon: `${baseURL}assets/images/tech/flexcx-feature-icon.svg`,
        title: 'Email',
    },
];

const holisticFeatures = [
    {
        icon: `${baseURL}assets/images/tech/flexcx-holistic-feature-1.svg`,
        para:
            'Monitor operations, listen in <br/>on calls, coach in real-time, and <br/>get a 360-degree view of <br/>interactions and performance',
    },
    {
        icon: `${baseURL}assets/images/tech/flexcx-holistic-feature-2.svg`,
        para:
            'Automatically surface call volumes, <br/>service levels, agent statuses, and <br/>more with analytics and <br/>dashboards',
    },
    {
        icon: `${baseURL}assets/images/tech/flexcx-holistic-feature-3.svg`,
        para:
            'Choose from options to “listen in” <br/>without the agent knowing, “whisper in” <br/>to the agent without the caller hearing, <br/>or “barge in” by taking over a call',
    },
];

const cloudChromeFeatures = [
    {
        icon: `${baseURL}assets/images/tech/icon-awesome-fingerprint.svg`,
        para:
            '<span>Two-factor authentication</span> heightens <br/>security and reduces risk',
    },
    {
        icon: `${baseURL}assets/images/tech/icon-awesome-chrome.svg`,
        para:
            'By syncing Chrome extensions across all <br/>devices, you always have <span>access to all the data <br/>you need, no matter where you’re working</span>',
    },
    {
        icon: `${baseURL}assets/images/tech/icon-awesome-shopping-bag.svg`,
        para:
            'Download the FleCX Chrome <br/>extension from the <span>Chrome Web Store</span>',
    },
];

const manageContent = [
    {
        ImageRight: true,
        padTopSmall: true,
        VerticalCardHeading: `Deploy experience surveys after any interaction`,
        VerticalCardParagraph: `Survey customers immediately following their interactions with agents to help you understand their perceptions of your brand and services. Collect quantitative and qualitative data you can act on right away`,
        VerticalCardImage: `${baseURL}assets/images/tech/flexcx-monitor-img-1.svg`,
        list: [
            {
                icon: `${baseURL}assets/images/tech/flexcx-monitor-listicon-1.svg`,
                para:
                    'Gain deeper insights with unlimited <br/>responses for broad statistical variables',
            },
            {
                icon: `${baseURL}assets/images/tech/flexcx-monitor-listicon-2.svg`,
                para:
                    'Deliver a unified brand experience through <br/>custom colors, logo and URL',
            },
        ],
    },
    {
        ImageRight: false,
        padTopSmall: true,
        VerticalCardHeading: `Keep your program agile with continuous reporting & analytics`,
        VerticalCardParagraph: `Make sure you hit every goal with robust reporting tools <br/>that let you review real-time and historical performance, <br/>track KPIs and act on insights. With FleCX reporting you <br/>get a real-time snapshot of agent performance metrics <br/>to keep you, your agents, and your brand honest about <br/>performance - and next steps.`,
        VerticalCardImage: `${baseURL}assets/images/tech/flexcx-monitor-img-2.svg`,
    },
];

const expertData = [
    {
        icon: `${baseURL}assets/images/tech/flexcx-option-1.svg`,
        text: 'Louise Smith',
    },
    {
        icon: `${baseURL}assets/images/tech/flexcx-option-2.svg`,
        text: 'Valued Customer',
    },
    {
        icon: `${baseURL}assets/images/tech/flexcx-option-3.svg`,
        text: 'Technical Issues',
    },
    {
        icon: `${baseURL}assets/images/tech/flexcx-option-4.svg`,
        text: 'Senior Tier 2 Agent',
    },
];

const strategyContent = [
    {
        iconUrl: baseURL + 'assets/images/tech/flexcx-strategy-icon1.svg',
        heading: 'Immediate',
        para: `Get up and running quickly so your agents can <br/>start delivering great experiences right away.`,
    },
    {
        iconUrl: baseURL + 'assets/images/tech/flexcx-strategy-icon2.svg',
        heading: 'Easy to use',
        para: `Access a simple, configurable user interface, streamlined <br/>reporting and deep integration to Salesforce.`,
    },
    {
        iconUrl: baseURL + 'assets/images/tech/flexcx-strategy-icon3.svg',
        heading: 'Agile',
        para: `Quickly make changes across multiple divisions, <br/>while maintaining consistent standards.`,
    },
    {
        iconUrl: baseURL + 'assets/images/tech/flexcx-strategy-icon4.svg',
        heading: `Scalable`,
        para: `Expand your contact center on demand—even overnight—<br/>with a solution that grows with your business.`,
    },
];

const FlexCX = () => {
    const [demoRequestForm, setDemoRequestForm] = useState(false);

    const sections = [
        {
            component: (
                <CampaignCallFlow
                    solutionFeatures={solutionFeatures}
                    holisticFeatures={holisticFeatures}
                />
            ),
            isScrollNav: false,
        },
        {
            component: (
                <ExpertBrowseSection
                    manageContent={manageContent}
                    expertData={expertData}
                    cloudChromeFeatures={cloudChromeFeatures}
                />
            ),
            isScrollNav: false,
        },
        {
            component: (
                <IntegrationBottomSection
                    strategyContent={strategyContent}
                    setDemoRequestForm={setDemoRequestForm}
                />
            ),
            isScrollNav: true,
        },
    ];

    return (
        <Layout>
            <Breadcrumbs>
                <li>Wave X</li>
                <li>Technology</li>
            </Breadcrumbs>

            <DemoRequestForm
                handleDemoForm={(val) => setDemoRequestForm(val)}
                formState={demoRequestForm}
                marketFormId="1085"
            />

            <SRMIntroSection
                preheading="FleCX"
                heading="Fast, Scalable CX <br/>From Your Browser"
                para="Flecx gives your @home or in-center agents the <br/>power of the industry’s most advanced cloud <br/>platform for managing and improving customer <br/>interactions."
                mainImg={`${baseURL}assets/images/tech/flexcx-intro.svg`}
                textureImg={`${baseURL}assets/images/tech/flexcx-texture.svg`}
                textureImgPosition="back"
                onBtnClick={() => setDemoRequestForm(true)}
            />

            <SRMonitoringSection
                id="monitor"
                heading="The Cloud Contact Center, At Your Service"
                paragraph="FleCX is a cloud-based CRM for fast, customer-focused interactions, rich customer insights, <br/>and leading first-contact resolution."
                listData={monitorList}
                boxHeight={400}
                styleTwo={true}
            />
            {sections &&
                sections.map((section, index) => {
                    return (
                        <div
                            key={index}>
                            {section.isScrollNav && (
                                <ScrollNav data={scrollNavData} />
                            )}
                            {section.component}
                        </div>
                    );
                })}
        </Layout>
    );
};

export default FlexCX;
