import React, { Fragment } from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Row, Col } from '@bootstrap-styled/v4';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import MapSection from '../map-section/map-section';

const HomeGlobalDelivery = ({ page }) => {
    return (
        <Fragment>
            <Col lg="12">
                <MapSection page={page} />
            </Col>

            <Col lg="12">
                <ClientStats page={page}>
                    {page === 'financial' ? (
                        ''
                    ) : (
                        <ClientTitle>
                            We have not lost a Top 10 Client in
                            <u>over 6 years</u>
                        </ClientTitle>
                    )}

                    {page !== 'velocity' && page !== 'financial' ? (
                        <StatsWrap>
                            <Row noGutters={true}>
                                <Col md="4">
                                    <StatsBox>
                                        <h4>
                                            30,000
                                            <span>+</span> <br /> employees
                                        </h4>
                                        <p>
                                            Highly Trained <br />
                                            Entrepreneurial Culture <br />
                                            Proven Processes&nbsp;
                                        </p>
                                    </StatsBox>
                                </Col>
                                <Col md="4">
                                    <StatsBox>
                                        <h4>
                                            Across the <br /> world.
                                        </h4>
                                        <p>
                                            31 Service Delivery Locations
                                            Disruptive markets (Pakistan) for
                                            English
                                        </p>
                                    </StatsBox>
                                </Col>
                                <Col md="4">
                                    <StatsBoxLast>
                                        <h4>
                                            Enterprise Grade <br />
                                            Infrastructure.
                                        </h4>
                                        <p>
                                            PCI/HIPAA Certified Tier 4 Hardened
                                            Data Center No Single point of
                                            failure Leading Security solution
                                        </p>
                                    </StatsBoxLast>
                                </Col>
                            </Row>
                        </StatsWrap>
                    ) : (
                        <StatsWrap>
                            <Row noGutters={true}>
                                <Col lg="3">
                                    <StatsBox page={page}>
                                        <h4>
                                            6,500+ Agents <br />
                                            Nearshore
                                        </h4>
                                        <p>• Jamaica – 3* sites - High</p>
                                        <p>• Volume English</p>
                                        <p>• Nicaragua – 2 site - Bilingual</p>
                                    </StatsBox>
                                </Col>
                                <Col lg="3">
                                    <StatsBox page={page}>
                                        <h4>
                                            10,000+ Agents <br /> Offshore
                                        </h4>
                                        <p>• Philippines – 7 sites</p>
                                        <p>Metro Manila, Provincial</p>
                                    </StatsBox>
                                </Col>
                                <Col lg="3">
                                    <StatsBox page={page}>
                                        <h4>
                                            3,000 Agents <br /> Onshore
                                        </h4>
                                        <p>• US – 9 sites , 2 Retention</p>
                                        <p>Centers of Competency</p>
                                    </StatsBox>
                                </Col>
                                <Col lg="3">
                                    <StatsBoxLast page={page}>
                                        <h4>
                                            3,700 Agents
                                            <br /> International
                                        </h4>
                                        <p>• Pakistan – 5 sites,</p>
                                        <p>• UK – 1 site</p>
                                    </StatsBoxLast>
                                </Col>
                            </Row>
                        </StatsWrap>
                    )}

                    {page === 'financial' ? (
                        <ClientTitle page={page}>
                            We have not lost a Top 25 client
                            <u>in over 4 years</u>
                        </ClientTitle>
                    ) : (
                        ''
                    )}
                </ClientStats>
            </Col>
        </Fragment>
    );
};

const ClientStats = styled.div`
    border: 1px solid ${colors.gray_dark['100']};
    margin-top: 40px;
    margin-bottom: ${(props) => (props.page === 'velocity' ? '0' : '100px')};
    ${Media('xlscreens')} {
        margin-top: 2.8vw;
        margin-bottom: ${(props) => (props.page === 'velocity' ? '0' : '5vw')};
    }
`;

const ClientTitle = styled.h3`
    font-size: 25px;
    background-color: ${(props) =>
        props.page === 'financial' ? '#0A0648' : 'transparent'};
    font-family: ${(props) =>
        props.page === 'financial' ? 'poppinsregular' : 'poppinssemibold'};
    min-height: 80px;
    padding: 15px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    u {
        margin-left: 10px;
    }
    ${Media('xlscreens')} {
        font-size: ${(props) =>
            props.page === 'financial' ? '1.4vw' : '2.1vw'};
        min-height: ${(props) => (props.page === 'financial' ? '4vw' : '6vw')};
    }
    ${Media('mobile')} {
        display: block;
        text-align: center;
        padding: 15px 25px;
    }
`;

const StatsWrap = styled.div``;

const StatsBox = styled.div`
    border-top: 1px solid ${colors.gray_dark['100']};
    border-right: 1px solid ${colors.gray_dark['100']};
    display: flex;
    align-items: ${(props) =>
        props.page === 'velocity'
            ? 'flex-start'
            : props.page === 'financial'
            ? 'flex-start'
            : 'center'};
    justify-content: ${(props) =>
        props.page === 'velocity'
            ? 'center'
            : props.page === 'financial'
            ? 'center'
            : 'space-around'};
    flex-direction: ${(props) =>
        props.page === 'velocity'
            ? 'column'
            : props.page === 'financial'
            ? 'column'
            : 'row'};
    min-height: 120px;
    h4 {
        font-size: ${(props) =>
            props.page === 'velocity'
                ? '24px'
                : props.page === 'financial'
                ? '24px'
                : '19px'};
        font-family: ${gilroyMedium};
        font-weight: 500;
        margin: 0px;
        margin-bottom: ${(props) =>
            props.page === 'velocity'
                ? '20px'
                : props.page === 'financial'
                ? '20px'
                : '0'};
        line-height: ${(props) =>
            props.page === 'velocity'
                ? '1.4'
                : props.page === 'financial'
                ? '1.4'
                : '1.2'};
        span {
            color: ${colors.pink['100']};
        }
    }
    p {
        font-size: ${(props) =>
            props.page === 'velocity'
                ? '14px'
                : props.page === 'financial'
                ? '14px'
                : '9px'};
        margin: 0px;
        max-width: ${(props) =>
            props.page === 'velocity'
                ? 'auto'
                : props.page === 'financial'
                ? 'auto'
                : '120px'};
    }
    ${Media('xlscreens')} {
        min-height: ${(props) =>
            props.page === 'velocity'
                ? '12.1vw'
                : props.page === 'financial'
                ? '12.1vw'
                : '8.5vw'};
        padding-left: ${(props) =>
            props.page === 'velocity'
                ? '3vw'
                : props.page === 'financial'
                ? '3vw'
                : '0'};
        padding-right: ${(props) =>
            props.page === 'velocity'
                ? '3vw'
                : props.page === 'financial'
                ? '3vw'
                : '0'};
        h4 {
            margin-left: ${(props) =>
                props.page === 'velocity'
                    ? '0'
                    : props.page === 'financial'
                    ? '0'
                    : '1.2vw'};
            margin-bottom: ${(props) =>
                props.page === 'velocity'
                    ? '0.85vw'
                    : props.page === 'financial'
                    ? '0.85vw'
                    : '0'};
            font-size: ${(props) =>
                props.page === 'velocity'
                    ? '1.25vw'
                    : props.page === 'financial'
                    ? '1.25vw'
                    : '1.5vw'};
        }
        p {
            margin-left: 0.5vw;
            font-size: ${(props) =>
                props.page === 'velocity'
                    ? '0.95vw'
                    : props.page === 'financial'
                    ? '0.95vw'
                    : '0.7vw'};
            max-width: ${(props) =>
                props.page === 'velocity'
                    ? 'auto'
                    : props.page === 'financial'
                    ? 'auto'
                    : '10vw'};
        }
    }
    ${Media('tablet')} {
        flex-direction: column;
        align-items: center;
        padding: 20px 25px;
        h4,
        p {
            text-align: center;
        }
        h4 {
            margin-left: 0px;
            margin-bottom: 10px;
        }
        p {
            max-width: ${(props) =>
                props.page === 'velocity'
                    ? '100%'
                    : props.page === 'financial'
                    ? '100%'
                    : '80%'};
            font-size: ${(props) =>
                props.page === 'velocity'
                    ? '14px'
                    : props.page === 'financial'
                    ? '14px'
                    : '11px'};
        }
    }
`;

const StatsBoxLast = styled(StatsBox)`
    border-right: none;
`;

export default HomeGlobalDelivery;
