import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import Layout from 'components/layout';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import WaveZeroCard from 'components/blogs/blog-card';
import FeatureCardRight from 'components/blogs/blog-feature-card';
import Media from 'shared-components/theme/medias';
import Loader from 'components/loader/loader';
import colors from 'shared-components/theme/colors';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import Button from 'shared-components/button/button';
const Blogs = ({}) => {
    const [loading, setLoading] = useState(true);
    const [featuredBlog, setFeaturedBlog] = useState(null);
    const [topBlogs, setTopBlogs] = useState([]);
    const [popularBlogs, setPopularBlogs] = useState([]);
    const [loadMoreBtn, setLoadMoreBtn] = useState(true);
    const [smallLoader, setSmallLoader] = useState(false);

    useEffect(() => {
        getHomeBlogs();
    }, []);
    // This code is to be utilized after confirming the featured blogs
    // const getFeaturedBlogs = async () => {
    //     try {
    //         const res = await fetch(
    //             process.env.REACT_APP_IBEX_API +
    //                 '/blog?per_page=4&order=desc&' +
    //                 new URLSearchParams({
    //                     token: process.env.REACT_APP_API_TOKEN,
    //                 })
    //         );
    //         if (res.status >= 400) {
    //             throw new Error('Bad response from server');
    //         }
    //         const resData = await res.json();
    //         if (resData && resData.status === 200) {
    //             setLoading(false);
    //             getHomeBlogs(resData.data);
    //             console.log(resData.data);
    //             setFeaturedBlog(resData.data[0]);
    //         }
    //     } catch (err) {
    //         console.error(err);
    //     }
    // };
    const getHomeBlogs = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/blog?per_page=25&' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                setLoading(false);
                setFeaturedBlog(resData.data[0]);
                setTopBlogs(resData.data.slice(1, 4));
                setPopularBlogs(resData.data.slice(4));
            }
        } catch (err) {
            console.error(err);
        }
    };
    const searchCategoryBlog = async (term_id) => {
        setLoading(true);
        setFeaturedBlog(null);
        setTopBlogs([]);
        if (term_id === 'all') {
            getFeaturedBlogs();
        } else {
            try {
                const res = await fetch(
                    process.env.REACT_APP_IBEX_API +
                        '/blog?' +
                        new URLSearchParams({
                            token: process.env.REACT_APP_API_TOKEN,
                            category_id: term_id,
                        })
                );
                if (res.status >= 400) {
                    throw new Error('Bad response from server');
                }
                const resData = await res.json();
                if (resData && resData.status === 200) {
                    setLoading(false);
                    setPopularBlogs(resData.data);
                }
            } catch (err) {
                console.error(err);
            }
        }
    };

    const loadMoreBlogs = async ({}) => {
        setSmallLoader(true);
        try {
            const ExcludeBlogs = popularBlogs.concat(topBlogs, featuredBlog);
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/blog?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        per_page: 24,
                        exclude:
                            ExcludeBlogs &&
                            ExcludeBlogs.map(
                                (blog) => JSON.stringify(blog.ID) + ','
                            ),
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                var currentBlogs = popularBlogs;
                var newBlogs = currentBlogs.concat(resData.data);
                if (resData.data.length === 0) {
                    setLoadMoreBtn(false);
                }
                setSmallLoader(false);
                setPopularBlogs(newBlogs);
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Layout
            searchBlogs={(term_id) => searchCategoryBlog(term_id)}
            page="blogs">
            {loading ? (
                <Loader />
            ) : (
                <WaveZeroWrapper>
                    <Container>
                        <FeaturePostWrapper>
                            <Col lg="7">
                                <div>
                                    {featuredBlog && (
                                        <WaveZeroCard
                                            cardImg={
                                                featuredBlog &&
                                                featuredBlog.featured_image
                                            }
                                            cardTitle={
                                                featuredBlog &&
                                                featuredBlog.post_title
                                            }
                                            cardDesc={
                                                featuredBlog &&
                                                featuredBlog.metadata &&
                                                featuredBlog.metadata
                                                    .short_description
                                            }
                                            authorImg={
                                                featuredBlog &&
                                                featuredBlog.metadata &&
                                                featuredBlog.metadata
                                                    .authors[0] &&
                                                featuredBlog.metadata.authors[0]
                                                    ._picture
                                            }
                                            authorName={
                                                featuredBlog &&
                                                featuredBlog.metadata &&
                                                featuredBlog.metadata
                                                    .authors[0] &&
                                                featuredBlog.metadata.authors[0]
                                                    .display_name
                                            }
                                            publishDate={
                                                featuredBlog &&
                                                featuredBlog.post_date
                                            }
                                            videoUrl={
                                                (featuredBlog &&
                                                    featuredBlog.metadata &&
                                                    featuredBlog.metadata
                                                        .post_type_video &&
                                                    featuredBlog.metadata
                                                        .video_url) ||
                                                false
                                            }
                                            isVideo={
                                                (featuredBlog &&
                                                    featuredBlog.metadata &&
                                                    featuredBlog.metadata
                                                        .post_type_video) ||
                                                false
                                            }
                                            blogTerm={
                                                featuredBlog &&
                                                featuredBlog.post_terms &&
                                                featuredBlog.post_terms[0]
                                            }
                                            postID={
                                                featuredBlog && featuredBlog.ID
                                            }
                                            postName={
                                                featuredBlog &&
                                                featuredBlog.post_name
                                            }
                                            externalURL={
                                                featuredBlog &&
                                                featuredBlog.metadata &&
                                                featuredBlog.metadata
                                                    .external_post &&
                                                featuredBlog.metadata
                                                    .external_post_url
                                            }
                                            featurePost={true}
                                        />
                                    )}
                                </div>
                            </Col>
                            <Col lg="5">
                                <div>
                                    <FeaturePostRight>
                                        {topBlogs &&
                                            topBlogs.length > 0 &&
                                            topBlogs.map((blog, ind) => (
                                                <FeatureCardRight
                                                    blogData={blog}
                                                    key={ind}
                                                />
                                            ))}
                                    </FeaturePostRight>
                                </div>
                            </Col>
                        </FeaturePostWrapper>
                        <Row>
                            {popularBlogs &&
                                popularBlogs.length > 0 &&
                                popularBlogs.map((blog, ind) => (
                                    <Col lg="4" md="6" key={ind}>
                                        <div>
                                            <WaveZeroCard
                                                cardImg={
                                                    blog && blog.featured_image
                                                }
                                                cardTitle={
                                                    blog && blog.post_title
                                                }
                                                cardDesc={
                                                    blog &&
                                                    blog.metadata &&
                                                    blog.metadata
                                                        .short_description
                                                }
                                                authorImg={
                                                    blog &&
                                                    blog.metadata &&
                                                    blog.metadata.authors &&
                                                    blog.metadata.authors[0] &&
                                                    blog.metadata.authors[0]
                                                        ._picture
                                                }
                                                authorName={
                                                    blog &&
                                                    blog.metadata &&
                                                    blog.metadata.authors &&
                                                    blog.metadata.authors[0] &&
                                                    blog.metadata.authors[0]
                                                        .display_name
                                                }
                                                publishDate={
                                                    blog && blog.post_date
                                                }
                                                videoUrl={
                                                    (blog &&
                                                        blog.metadata &&
                                                        blog.metadata
                                                            .post_type_video &&
                                                        blog.metadata
                                                            .video_url) ||
                                                    false
                                                }
                                                isVideo={
                                                    (blog &&
                                                        blog.metadata &&
                                                        blog.metadata
                                                            .post_type_video) ||
                                                    false
                                                }
                                                externalURL={
                                                    blog &&
                                                    blog.metadata &&
                                                    blog.metadata
                                                        .external_post &&
                                                    blog.metadata
                                                        .external_post_url
                                                }
                                                blogTerm={
                                                    blog && blog.post_terms[0]
                                                }
                                                postName={
                                                    blog && blog.post_name
                                                }
                                                key={ind}
                                            />
                                        </div>
                                    </Col>
                                ))}
                        </Row>
                    </Container>
                    {loadMoreBtn && !smallLoader && (
                        <LoadMoreButton>
                            <FlexCentered>
                                <Button round ifButton onClick={loadMoreBlogs}>
                                    Load More
                                </Button>
                            </FlexCentered>
                        </LoadMoreButton>
                    )}
                    {smallLoader && <Loader size="small" />}
                </WaveZeroWrapper>
            )}
        </Layout>
    );
};
const LoadMoreButton = styled.div`
    margin-top: 30px;
    ${Media('xlscreens')} {
        margin-top: 2.66vw;
    }
`;
const WaveZeroWrapper = styled.div`
    background-color: ${colors.white['100']};
    ${Media('xlscreens')} {
        padding: 2vw 0vw;
    }
    ${Media('laptops')} {
        padding: 0px;
        padding-top: 30px;
    }
    ${Media('tablet')} {
        padding: 0px 10px;
        margin-top: 82px;
    }
`;
const FeaturePostWrapper = styled(Row)`
    margin-bottom: 50px;
    ${Media('xlscreens')} {
        margin-bottom: 2.61vw;
    }
`;
const FeaturePostRight = styled.div``;

export default Blogs;
