import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import { gilroySemibold, gilroyLight } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

const protectedItems = [
    {
        content: true,
        spacing: false,
        items: [
            {
                header: (
                    <Fragment>
                        Unmatched search <br /> & social expertise.
                    </Fragment>
                ),
                text: `Our history of wins in the paid
                search + social ad space allows us
                to uniquely leverage an unmatched
                library of deep channel search,
                click, and call data, critically
                identifying and increasing
                high-value lead discovery and
                targeting.`,
                animationDelay: 100,
            },
            {
                header: (
                    <Fragment>
                        Specialists in adaptive <br />
                        bidding & ad placement
                    </Fragment>
                ),
                text: `We achieve 2X the yield per click
                than our competitors. How? Our
                refined machine learning algorithms
                optimize ad spend and campaign
                management in real-time.`,
                animationDelay: 200,
            },
            {
                header: (
                    <Fragment>
                        Advanced AI <br />
                        Ad Tech.
                    </Fragment>
                ),
                text: `Our paid search and bulk bidding
                technologies are based on predictive
                customer modeling combined with a
                real-time upflow of ever shifting
                behavioural data.`,
                animationDelay: 300,
            },
        ],
    },
    {
        content: false,
        spacing: true,
    },
    {
        content: true,
        spacing: false,
        items: [
            {
                header: (
                    <Fragment>
                        In-house sales <br />
                        center operation.
                    </Fragment>
                ),
                text: `Our history of wins in the paid
                search + social ad space allows us
                to uniquely leverage an unmatched
                library of deep channel search,
                click, and call data, critically
                identifying and increasing
                high-value lead discovery and
                targeting.`,
                animationDelay: 100,
            },
            {
                header: (
                    <Fragment>
                        Connect click-to-call <br />
                        conversion data.
                    </Fragment>
                ),
                text: `We have complete value-chain
                analytics on both online and offline
                purchase events, allowing for
                precise bulk bidding on millions of
                stress-tested keywords.`,
                animationDelay: 200,
            },
            {
                header: (
                    <Fragment>
                        We are a digital agency <br />
                        with global redundancy.
                    </Fragment>
                ),
                text: `Our digital team is spread across
                the US, the Middle East, and Asia,
                offering cost optimization and
                execution redundancy.`,
                animationDelay: 300,
            },
        ],
    },
];
export const DigitalProtected = () => {
    return (
        <ProtectBrand id="protect">
            <Container>
                <ProtectHeader>
                    <div>
                        Protect your brand investment. <br />
                        Increase conversions. <br />
                        Accelerate ROI.
                    </div>
                </ProtectHeader>
            </Container>
            <ProtectGrid>
                <Container>
                    {protectedItems &&
                        protectedItems.map((i, ind) => {
                            if (i && i.items) {
                                return (
                                    <Row key={ind}>
                                        {i.items &&
                                            i.items.map((item, ind2) => (
                                                <Col xs="12" lg="4" key={ind2}>
                                                    <ContainerProtectedItem>
                                                        <div>
                                                            <Row>
                                                                <ProtectedItemHeader>
                                                                    {
                                                                        item.header
                                                                    }
                                                                </ProtectedItemHeader>
                                                            </Row>
                                                            <Row>
                                                                <ProtectedItemText>
                                                                    {item.text}
                                                                </ProtectedItemText>
                                                            </Row>
                                                        </div>
                                                    </ContainerProtectedItem>
                                                </Col>
                                            ))}
                                    </Row>
                                );
                            } else {
                                return (
                                    <Row key={ind}>
                                        <ProtectedSpacer />
                                    </Row>
                                );
                            }
                        })}
                </Container>
            </ProtectGrid>
        </ProtectBrand>
    );
};
const ContainerProtectedItem = styled.div`
    padding: 3vw 1vw;
    background: #f5f5f7;
    height: 100%;

    ${Media('tablet')} {
        margin-top: 13px;
        height: auto;
        padding: 4vw 4vw;
    }
`;
const ProtectedSpacer = styled.div`
    height: 3vw;
`;
const ProtectHeader = styled.div`
    background: #fafafa;
    font-family: ${gilroySemibold};
    padding-left: 5vw;
    padding-top: 4vw;
    padding-bottom: 5vw;
    ${Media('xlscreens')} {
        font-size: 3.3vw;
        line-height: 3.5vw;
    }
    ${Media('tablet')} {
        font-size: 6vw;
        padding: 7vw 8vw 7vw 8vw;
    }
`;
const ProtectedItemHeader = styled.div`
    font-family: ${gilroySemibold};
    padding: 0vw 2.5vw;
    padding-bottom: 2vw;
    ${Media('xlscreens')} {
        font-size: 1.5vw;
        letter-spacing: -0.06vw;
        line-height: 2.2vw;
    }

    ${Media('tablet')} {
        font-size: 5.8vw;
        padding: 3vw 8vw;
    }
`;
const ProtectedItemText = styled.div`
    font-family: ${gilroyLight};
    font-size: 0.9vw;
    padding: 0vw 2.5vw;
    letter-spacing: 0.02vw;
    line-height: 1.15vw;

    ${Media('tablet')} {
        font-size: 4.5vw;
        padding: 3vw 8vw;
        line-height: 5vw;
    }
`;

const ProtectGrid = styled.div`
    padding-bottom: 5vw;
`;
const ProtectBrand = styled.section`
    background-position: top;
    background: url('${baseURL}assets/images/digtal_head_roi.png') top no-repeat
        #fafafa;
    background-repeat: no-repeat;
    background-size: contain;
    ${Media('xlscreens')} {
        padding-top: 14vw;
    }
`;
