import React from 'react';
import styled from 'styled-components';
import { Container, Col, Row } from '@bootstrap-styled/v4';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { gilroyMedium } from 'shared-components/theme/fonts';
import { WebinarMainHeading } from './main-heading';
import ScrollAnimation from 'react-animate-on-scroll';
import { baseURL } from 'shared-components/theme/helpers';
import ReactHtmlParser from 'react-html-parser';

export const WebinarIntro = ({
    mainHeading,
    mainParagragh,
    headingCol,
    paraCol,
    videoSrc,
    paddingLeftPara,
    headingSize,
    tagMargBtm,
    headingNoWrap,
}) => {
    return (
        <Wrapper>
            <Container>
                <PageLabel tagMargBtm={tagMargBtm}>Webinar</PageLabel>
                <RowCentered>
                    <Col lg={headingCol ? headingCol : 7}>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <IntroHeading headingNoWrap={headingNoWrap}>
                                <WebinarMainHeading
                                    tag="h1"
                                    noMargin={true}
                                    size={headingSize}>
                                    {ReactHtmlParser(mainHeading)}
                                </WebinarMainHeading>
                            </IntroHeading>
                            <PostHeadingLabel>
                                <img
                                    src={`${baseURL}assets/images/video.svg`}
                                    alt="-"
                                />
                                Watch The Webinar Below
                            </PostHeadingLabel>
                        </ScrollAnimation>
                    </Col>
                    <Col lg={paraCol ? paraCol : 5}>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <IntroRight paddingLeftPara={paddingLeftPara}>
                                <MainPara>{mainParagragh}</MainPara>
                            </IntroRight>
                        </ScrollAnimation>
                    </Col>
                </RowCentered>
            </Container>
            <Container>
                <Row>
                    <Col md="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <LandingVideo>
                                <iframe
                                    width="100%"
                                    height="640"
                                    src={videoSrc}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            </LandingVideo>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.section`
    position: relative;
    ${Media('tablet')} {
        padding: 50px 0px;
    }
`;

const PageLabel = styled.div`
    font-size: 20px;
    color: ${colors.pink['100']};
    font-family: ${gilroyMedium};
    ${Media('xlscreens')} {
        font-size: 1.04vw;
        margin-bottom: ${(props) => props.tagMargBtm && '1vw'};
    }
`;

const IntroHeading = styled.div`
    ${Media('tablet')} {
        margin-bottom: 50px;
    }
    ${(props) => {
        if (props.headingNoWrap) {
            return `
            ${Media('xlscreens')} {
                h2 {
                    white-space: nowrap;
                }
            }
            `;
        }
    }}
`;

const MainPara = styled.p`
    font-size: 16px;
    line-height: 1.8;
    font-family: ${gilroyMedium};
    margin: 0px;
    ${Media('xlscreens')} {
        font-size: 1vw;
    }
    ${Media('desktopscreens')} {
        font-size: 1.1vw;
    }
    ${Media('mobile')} {
        font-size: 14px;
        br {
            display: none;
        }
    }
`;

const LandingVideo = styled.div`
    width: 100%;
    background-color: ${colors.gray_lighter['100']};
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 30px 0 0;
    iframe {
        height: 300px;
    }
    ${Media('xlscreens')} {
        padding: 2vw 0 1vw;
        iframe {
            height: 35.4vw;
        }
    }
    ${Media('tablet')} {
        height: auto;
        margin-top: 20px;
    }
`;

const PostHeadingLabel = styled.div`
    display: flex;
    font-size: 20px;
    font-family: ${gilroyMedium};
    margin: 30px 0px;
    img {
        margin-right: 25px;
        height: 24px;
    }
    ${Media('xlscreens')} {
        font-size: 1.04vw;
        margin: 1.57vw 0;
        img {
            margin-right: 1.3vw;
            height: 1.25vw;
        }
    }
    ${Media('tablet')} {
        font-size: 16px;
        img {
            height: 18px;
            margin-right: 15px;
        }
    }
`;

const IntroRight = styled.div`
    padding-left: ${(props) => (props.paddingLeftPara ? '30px' : '0px')};
    ${Media('xlscreens')} {
        padding-left: ${(props) => (props.paddingLeftPara ? '3vw' : '0px')};
    }
    ${Media('mobile')} {
        padding: 0px;
    }
`;
