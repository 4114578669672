import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NotFoundPage from 'pages/not-found';
import Layout from 'components/layout';
import styled from 'styled-components';
import { Container, Row } from '@bootstrap-styled/v4';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import CaseStudyIntro4 from 'components/case-study/case-study-intro4';
import BigText from 'components/case-study/case-bigtext';
import CaseStats2 from 'components/case-study/case-stats-box2';
import {
    AboutSection,
    NeedSection,
    SolutionQuoteSection,
    GoalsTransformSection,
} from './sections';
import { ScrollWrapper } from './common';
import LazyLoad from 'react-lazyload';
import Loader from 'components/loader/loader';
import {
    gilroyMedium,
    gilroySemibold,
    gilroyBold,
} from 'shared-components/theme/fonts';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -210,
    },
    {
        name: 'whotheyAre',
        target: 'whotheyAre',
        offset: -20,
    },
    {
        name: 'needGoals',
        target: 'needGoals',
        offset: -100,
    },
    {
        name: 'solutionQuote',
        target: 'solutionQuote',
        offset: -100,
    },
    {
        name: 'goalsTransform',
        target: 'goalsTransform',
        offset: -100,
    },
];

const CaseStudies = () => {
    const router = useParams();
    const [pageError, setPageError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pageData, setPageData] = useState({});
    const [statsData, setStatsData] = useState({});
    const [sectionData, setSectionData] = useState({
        whoTheyAre: {},
        need: {},
        goals: {},
        solution: {},
        quote: {},
        technology: {},
        transform: {},
        callAction: {},
    });

    useEffect(() => {
        getData();
    }, []);
    const getData = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/case-studies?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        post_name: router.slug,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
                setPageError(true);
                setLoading(false);
            }
            const resData = await res.json();
            if (resData.status === 200 && resData.data[0].length !== 0) {
                setLoading(false);
                setPageData(resData.data[0]);
                setStatsData(resData.data[0].metadata.statistics);
                setSectionData({
                    main: resData.data[0].metadata,
                    whoTheyAre: resData.data[0].metadata.who_they_are,
                    need: resData.data[0].metadata.the_need,
                    goals: resData.data[0].metadata.program_goals,
                    solution: resData.data[0].metadata.ibex_solution,
                    quote: resData.data[0].metadata.testimonial,
                    technology: resData.data[0].metadata.solutions_technology,
                    transform: resData.data[0].metadata.footer,
                });
            } else {
                setPageError(true);
                setLoading(false);
            }
        } catch (err) {
            console.error(err);
            setPageError(true);
            setLoading(false);
        }
    };
    const lazySections = [
        {
            id: 'whotheyAre',
            section: <AboutSection data={sectionData.whoTheyAre} />,
        },
        {
            id: 'needGoals',
            section: (
                <NeedSection
                    data={sectionData.need}
                    goals={sectionData.goals}
                />
            ),
        },
        {
            id: 'solutionQuote',
            section: (
                <SolutionQuoteSection
                    solution={sectionData.solution}
                    quote={sectionData.quote}
                    main={sectionData.main}
                />
            ),
        },
        {
            id: 'goalsTransform',
            section: (
                <GoalsTransformSection
                    goals={sectionData.technology}
                    transform={sectionData.transform}
                />
            ),
        },
    ];

    const handlePage = (page) => {
        switch (page) {
            case 'financial':
                return 'western-union';
            case 'health':
                return 'health';
            default:
                return false;
        }
    };
    return (
        <>
            {loading && <Loader position="absolute" />}
            {pageError ? (
                <NotFoundPage />
            ) : (
                Object.keys(pageData).length !== 0 &&
                pageData !== undefined && (
                    <Layout
                        page={handlePage(
                            pageData['metadata']['case_study_type']
                        )}
                        pageLoader={false}>
                        <Wrapper>
                            <Breadcrumbs>
                                {sectionData.main &&
                                    sectionData.main.case_study_type && (
                                        <li>
                                            {sectionData.main.case_study_type}
                                        </li>
                                    )}
                                <li>Success Story</li>
                            </Breadcrumbs>

                            <ScrollWrapper id="home">
                                <Container>
                                    <CaseStudyIntro4
                                        introHeading={
                                            pageData['metadata']['page_heading']
                                        }
                                        introSubHeading={
                                            pageData['metadata']['logo_text']
                                        }
                                        introPara={
                                            pageData['metadata'][
                                                'page_description'
                                            ]
                                        }
                                        introImage={
                                            pageData['metadata']['page_image']
                                        }
                                        brandLogo={pageData['metadata']['logo']}
                                        noPadding={true}
                                        useLogo={
                                            pageData['metadata']['use_logo']
                                        }
                                    />
                                </Container>
                            </ScrollWrapper>

                            <BigTextSection>
                                <BigText
                                    textOne={
                                        pageData['metadata']['animated_text']
                                    }
                                    textTwo={
                                        pageData['metadata']['animated_text_2']
                                    }
                                    textColor={
                                        pageData['metadata'][
                                            'animated_text_color'
                                        ] === 'light'
                                            ? colors.white['100']
                                            : colors.black['100']
                                    }
                                />
                            </BigTextSection>

                            <Container>
                                <StatsBoxWrapper>
                                    <CustomRow>
                                        {Object.keys(statsData).length !== 0 &&
                                            statsData !== undefined &&
                                            statsData.map((item, index) => {
                                                return (
                                                    <CustomStatsCol
                                                        width={`${
                                                            item['width'] &&
                                                            item['width'] !== ''
                                                                ? item['width']
                                                                : 100 /
                                                                  statsData.length
                                                        }%`}
                                                        key={index}>
                                                        {pageData['metadata'][
                                                            'statistics_type'
                                                        ] === 'type_1' ? (
                                                            <CaseStats2
                                                                left={
                                                                    item[
                                                                        'value'
                                                                    ]
                                                                }
                                                                textCenter={
                                                                    true
                                                                }
                                                                directionColumn={
                                                                    true
                                                                }
                                                                styleTwo={
                                                                    pageData[
                                                                        'metadata'
                                                                    ][
                                                                        'statistics_style_type'
                                                                    ] ===
                                                                    'style_2'
                                                                }
                                                                arrow={
                                                                    item[
                                                                        'status'
                                                                    ]
                                                                }
                                                                right={
                                                                    item['name']
                                                                }
                                                            />
                                                        ) : (
                                                            <CaseStats2
                                                                left={
                                                                    item[
                                                                        'value'
                                                                    ]
                                                                }
                                                                styleTwo={
                                                                    pageData[
                                                                        'metadata'
                                                                    ][
                                                                        'statistics_style_type'
                                                                    ] ===
                                                                    'style_2'
                                                                }
                                                                right={
                                                                    item['name']
                                                                }
                                                            />
                                                        )}
                                                    </CustomStatsCol>
                                                );
                                            })}
                                    </CustomRow>
                                </StatsBoxWrapper>
                            </Container>

                            {lazySections && lazySections.length > 0
                                ? lazySections.map((item, index) => {
                                      return (
                                          <div id={item.id} key={index}>
                                               {lazySections.length ===
                                                  index + 1 ? (
                                                      <ScrollNav
                                                          data={scrollNavData}
                                                      />
                                                  ) : null}
                                                  {item.section}
                                          </div>
                                      );
                                  })
                                : null}
                        </Wrapper>
                    </Layout>
                )
            )}
        </>
    );
};
const Wrapper = styled.div`
    h5 {
        display: block;
        font-family: ${gilroyMedium};
        font-weight: normal;
        font-size: 29px;
        color: ${colors.pink['100']};
        ${Media('xlscreens')} {
            font-size: 1.51vw;
        }
        ${Media('tablet')} {
            font-size: 24px;
        }
    }
    h2 {
        font-family: ${gilroySemibold};
        font-size: 52px;
        color: ${colors.gray_dark['100']};
        ${Media('xlscreens')} {
            font-size: 2.7vw;
        }
        ${Media('tablet')} {
            font-size: 38px;
        }
    }
    h1 {
        font-size: 80px;
        font-family: ${gilroySemibold};
        line-height: 1.21;
        letter-spacing: -0.29px;
        ${Media('tablet')} {
            font-size: 38px;
        }
        ${Media('xlscreens')} {
            font-size: 3.8vw;
        }
    }
    h4 {
        font-size: 24px;
        font-family: ${gilroyBold};
        ${Media('xlscreens')} {
            font-size: 1.25vw;
        }
    }
`;
const BigTextSection = styled.section`
    position: relative;
    padding-top: 0;
    margin-top: -300px;
    ${Media('xlscreens')} {
        margin-top: -36.4vw;
    }
    ${Media('tablet')} {
        margin-top: -300px;
        padding-bottom: 100px;
    }
    ${Media('mobile')} {
        margin-top: -200px;
        padding-bottom: 50px;
    }
`;
const StatsBoxWrapper = styled.div`
    padding: 30px 0px;
    display: flex;
    align-items: center;
    border-top: 1px solid ${colors.gray['border']};
    border-bottom: 1px solid ${colors.gray['border']};
    ${Media('xlscreens')} {
        padding: 2vw 0;
        margin-top: 6vw;
        margin-bottom: 1vw;
    }
    ${Media('tablet')} {
        padding: 0px;
        border: none;
    }
`;
const CustomRow = styled(Row)`
    ${Media('tablet')} {
        padding: 0px 4.57vw;
    }
    ${Media('xlscreens')} {
        width: 100%;
        align-items: center;
    }
`;
const CustomStatsCol = styled.div`
    ${Media('tablet')} {
        width: 100%;
    }
    ${Media('xlscreens')} {
        width: ${(props) => (props.width ? props.width : '25%')};
    }
`;

export default CaseStudies;
