import React from 'react';
import styled from 'styled-components';
import { Col, Row } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    ContainerWideSRM,
    SRMSectionHeading,
} from 'components/social-rep-man/common';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import MainPara from 'shared-components/main-para/main-para';
import { PagePara } from '../style';
import { RowCenteredBoth } from 'shared-components/theme/grid-helpers';
import VerticalCards from 'components/social-rep-man/vertical-cards';

export const ScoreManageSection = ({ data }) => {
    return (
        <ManageSection id="manage">
            <ContainerWideSRM>
                <Row>
                    <Col sm="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <SRMSectionHeading>
                                Data-driven, actionable insights delivered right
                                to your screen
                            </SRMSectionHeading>
                        </ScrollAnimation>
                    </Col>
                </Row>
                <RowCenteredBoth>
                    <Col sm="12" lg="9">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <PagePara>
                                <MainPara align="center">
                                    Scorecard is designed to streamline agent &
                                    contact center performance measurement,
                                    empowering supervisors and managers to make
                                    pin-point performance adjustments. Elevate
                                    your entire program towards consistent,
                                    predictable CX success and hit new target
                                    thresholds through tailored agent coaching.
                                </MainPara>
                            </PagePara>
                        </ScrollAnimation>
                    </Col>
                </RowCenteredBoth>

                {data &&
                    data.length > 0 &&
                    data.map((item, index) => {
                        return (
                            <ManageCards key={index}>
                                <VerticalCards
                                    ImageRight={item.ImageRight}
                                    ImageRightColEqual={item.ImageRight}
                                    padTopSmall={item.padTopSmall}
                                    CardTagImg={item.CardTagImg}
                                    VerticalCardHeading={
                                        item.VerticalCardHeading
                                    }
                                    VerticalCardParagraph={
                                        item.VerticalCardParagraph
                                    }
                                    VerticalCardImage={item.VerticalCardImage}
                                    sideExtraSmall
                                />
                            </ManageCards>
                        );
                    })}
            </ContainerWideSRM>
        </ManageSection>
    );
};

const ManageSection = styled.section``;

const ManageCards = styled.div`
    p {
        max-width: 75%;
        font-family: ${gilroyMedium};
    }
    ${Media('tablet')} {
        p {
            max-width: 100%;
        }
    }
`;
