import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import {
    UtilPagePara,
    UtilStepHeading,
    UtilStepHeadText,
    UtilTag,
} from '../common';
import MainHeading from 'shared-components/main-heading/main-heading';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import { gilroySemibold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

export const UtilStepThreeSection = () => {
    return (
        <UtilStepThird id="step_three">
            <Container>
                <Row>
                    <Col lg="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <UtilStepHeading>
                                <UtilTag>03</UtilTag>
                                <MainHeading noMargin={true}>
                                    Match Customers’ <br /> Channel of Choice.
                                </MainHeading>
                            </UtilStepHeading>
                            <UtilPagePara>
                                <span>Go where your customers are.</span>
                                &nbsp;Consultants and advisors make a lot of
                                noise about the need for utilities to adopt an
                                omnichannel approach to customer engagement. Of
                                course, no one has a simple recipe for what
                                omnichannel should mean. At ibex, we recommend
                                clients try to meet customers in their channel
                                of choice by making options available that makes
                                the CX easy for customers to connect with their
                                utility. Partners for a New Connection program
                                should offer the ability to connect with
                                customers digitally or telephonically, and even
                                a combination of both.
                            </UtilPagePara>
                            <UtilPagePara>
                                It’s important to work with a partner that meets
                                customers where they are - whether it’s – phone,
                                chat, online or mobile. Acquia recently reported
                                that 80% of customers expect technology to make
                                the experience with brands more valuable.
                            </UtilPagePara>
                        </ScrollAnimation>
                    </Col>
                    <Col lg="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <StepThreeMidHead>
                                <UtilStepHeadText>
                                    Customers expect technology to have a
                                    positive impact on their experience with
                                    brands
                                </UtilStepHeadText>
                            </StepThreeMidHead>
                        </ScrollAnimation>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <ScrollAnimation
                            animateIn="fadeInLeft"
                            animateOnce={false}>
                            <StepThreeStatBox>
                                <img
                                    src={`${baseURL}assets/images/step-three-stat-1.png`}
                                    alt="-"
                                />
                                <h4>
                                    Technology should make my experiences with
                                    brands more valuable
                                </h4>
                            </StepThreeStatBox>
                        </ScrollAnimation>
                    </Col>
                    <Col lg="6">
                        <ScrollAnimation
                            animateIn="fadeInRight"
                            animateOnce={false}>
                            <StepThreeStatBox>
                                <img
                                    src={`${baseURL}assets/images/step-three-stat-2.png`}
                                    alt="-"
                                />
                                <h4>
                                    Technology should make my online experiences
                                    with brands better
                                </h4>
                            </StepThreeStatBox>
                        </ScrollAnimation>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <UtilPagePara>
                                <span>
                                    Just because the phone call is complete,
                                    doesn’t mean the conversation is over.
                                </span>
                                &nbsp;Customers may start their interaction with
                                a phone call but they can continue the
                                discussion across many channels. At ibex, our
                                New Connection program supports our clients with
                                a destination website for digital only offers in
                                a modern utility CX, and the site is fully
                                supported by phone or chat based on customer
                                choice. And, the New Connection program should
                                be the start of the ongoing customer experience,
                                not an end in itself. Collecting relevant
                                information and opt-in for follow up contact
                                makes it easy for utilities to extend that
                                digital dialogue with their customers for email,
                                social, and online interactions. Customers want,
                                even expect, more engagement with their
                                utilities, but frequently on their own terms and
                                in their channel of choice. A quality New
                                Connection program supports that engagement
                                choice from the very beginning of the CX.
                            </UtilPagePara>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </UtilStepThird>
    );
};

const UtilStepThird = styled.section`
    padding-bottom: 0;
    ${Media('xlscreens')} {
        padding: 7vw 0 0;
    }
`;

const StepThreeMidHead = styled.div`
    padding: 30px 0px;
    h4 {
        text-align: left;
    }
    ${Media('xlscreens')} {
        padding-top: 1.3vw;
    }
`;

const StepThreeStatBox = styled(FlexCentered)`
    margin-bottom: 50px;
    img {
        flex: 0 0 220px;
        max-width: 100%;
    }
    h4 {
        flex: 1 0;
        font-size: 26px;
        font-family: ${gilroySemibold};
        line-height: 1.4;
        margin: 0px 20px;
    }
    ${Media('xlscreens')} {
        margin-top: 1.5vw;
        margin-bottom: 6vw;
        img {
            flex: 0 0 12vw;
            max-width: 12vw;
        }
        h4 {
            font-size: 1.4vw;
            margin: 0px 2vw;
            margin-right: 3.6vw;
        }
    }
    ${Media('mobile')} {
        flex-direction: column;
        h4 {
            font-size: 18px;
            text-align: center;
            padding-top: 30px;
            padding-bottom: 20px;
        }
    }
`;
