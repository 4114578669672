import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';

const SolutionTechBox = ({ img, desc, lastSection }) => {
    return (
        <ScrollAnimation animateIn="fadeInUp">
            <Wrapper lastSection={lastSection}>
                <ImgWrapper>
                    <img src={img} alt="-" />
                </ImgWrapper>
                <Desc>{ReactHtmlParser(desc)}</Desc>
            </Wrapper>
        </ScrollAnimation>
    );
};
const Wrapper = styled.div`
    background-color: ${colors.white['100']};
    height: 260px;
    border: 1px solid ${colors.gray['border']};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ${Media('xlscreens')} {
        height: 13.61vw;
    }
    ${Media('tablet')} {
        margin-bottom: ${(props) => (props.lastSection ? '50px' : '20px')};
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
    }
`;
const ImgWrapper = styled.div`
    height: 60%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-height: 45px;
        max-width: 65px;
    }
    ${Media('xlscreens')} {
        img {
            max-height: 2.35vw;
            max-width: 3.3vw;
        }
    }
    ${Media('tablet')} {
        height: auto;
        margin-bottom: 30px;
        img {
            min-height: 30px;
            max-width: 30px;
        }
    }
`;
const Desc = styled.div`
    font-size: 24px;
    font-family: ${gilroySemibold};
    text-align: center;
    flex: 1;
    padding: 0px 10px;
    ${Media('xlscreens')} {
        font-size: 1.25vw;
        padding: 0 1.04vw;
    }
    ${Media('tablet')} {
        font-size: 20px;
    }
    ${Media('mobile')} {
        font-size: 18px;
    }
`;
export default SolutionTechBox;
