import React from 'react';
import styled from 'styled-components';
import { gilroySemibold } from 'shared-components/theme/fonts';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import ReactHtmlParser from 'react-html-parser';

const CaseBottomSolution = ({ solutionText, bgImg, bgColor }) => {
    return (
        <Wrapper bgImg={bgImg} bgColor={bgColor}>
            {ReactHtmlParser(solutionText)}
        </Wrapper>
    );
};
const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    font-size: 52px;
    font-family: ${gilroySemibold};
    color: ${colors.white['100']};
    background-color: ${(props) =>
        props.bgColor && props.bgColor !== ''
            ? props.bgColor
            : colors.caseStudyPages['bottomSolutionBg']};
    padding: 70px 0px;
    line-height: 1.53;
    letter-spacing: -0.07;
    p {
        font-family: inherit;
        font-size: inherit;
        margin: 0px;
        color: inherit;
        br {
            display: none;
        }
    }
    ${Media('xlscreens')} {
        text-align: center;
        font-size: 2.4vw;
        padding: 3.66vw 0;
    }
    ${Media('tablet')} {
        font-size: 20px;
        padding: 30px;
    }
    ${(props) => {
        if (props.bgImg) {
            return `
                background-image: url(${props.bgImg});
            `;
        }
    }}
`;
export default CaseBottomSolution;
