import React from 'react';
import styled from 'styled-components';
import MainPara from 'shared-components/main-para/main-para';
import colors from 'shared-components/theme/colors';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import { SRMSectionHeading } from './section-heading';
import ReactHtmlParser from 'react-html-parser';
import Button from 'shared-components/button/button';
import { Container } from '@bootstrap-styled/v4';

export const SRMCallActionSection = ({
    heading,
    para,
    onBtnClick,
    btnBgColor,
    btnText,
}) => {
    return (
        <Wrapper as="section">
            <Container>
                <SRMSectionHeading color={colors.white['100']}>
                    {ReactHtmlParser(heading)}
                </SRMSectionHeading>
                {para && (
                    <MainPara align="center" color={colors.white['100']}>
                        {ReactHtmlParser(para)}
                    </MainPara>
                )}
                <Button
                    ifButton={true}
                    round={true}
                    onClick={onBtnClick}
                    bgColor={btnBgColor}>
                    {btnText}
                </Button>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    background-color: ${colors.socialRep['brandsBG']};
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    p {
        margin-top: 0px;
        font-family: ${gilroyMedium};
    }
    ${Media('xlscreens')} {
        p {
            margin-bottom: 2.5vw;
            font-size: 1.15vw;
        }
    }
    ${Media('tablet')} {
        p {
            margin-bottom: 30px;
        }
    }
`;
