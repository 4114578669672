import React, { Fragment } from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import CXFeedbackBox from 'components/cx-feedback-box/cx-feedback-box';
import {
    gilroySemibold,
    gilroyLight,
    gilroyBlack,
} from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

const cardBoxItems = [
    {
        animateDelay: 100,
        imgSrc: `${baseURL}assets/images/fast-icon.svg`,
        heading: 'Fast.',
        listItems: [
            'Speed to Launch',
            'Speed to Scale',
            'Speed to Performance',
            'Speed to Profitability',
        ],
    },
    {
        animateDelay: 300,
        imgSrc: `${baseURL}assets/images/flexible-icon.svg`,
        heading: 'Flexible.',
        listItems: [
            'Program Incubation',
            'Knowledge ramping',
            'Knowledge Transfer',
            'Custom Branding',
        ],
    },
    {
        animateDelay: 500,
        imgSrc: `${baseURL}assets/images/scalable-icon.svg`,
        heading: 'Scalable.',
        listItems: [
            <>
                We’ve helped brands like&nbsp;
                <span>Lyft, Walmart, Netflix, Toast</span> and more to start
                small and ramp big.
            </>,
        ],
    },
    {
        animateDelay: 700,
        imgSrc: `${baseURL}assets/images/expert-icon.svg`,
        heading: 'Expert.',
        listItems: [
            `Domain Expertise spanning
            multiple industries: Technology,
            eCommerce, Food & Delivery,
            Finance, and more.`,
        ],
    },
];
const CXFeedbackItems = [
    {
        image: `${baseURL}assets/images/comprehensive-cx-bg.png`,
        title: (
            <Fragment>
                Comprehensive <br /> & Elevated CX.
            </Fragment>
        ),
        description:
            'Our support agents specialize in maintaining highly-engaging and highly-valuable interactions, with call times optimized appropriately for effective support and sales.',
        productList: [
            'Customer Care',
            'TeleSales',
            'Technical Support',
            'Win-Back',
            'Service to Sales',
            'Customer Retention',
        ],
    },
    {
        image: `${baseURL}assets/images/flawless-launches.png`,
        title: (
            <Fragment>
                Flawless Launches & <br />
                Speed to Green
            </Fragment>
        ),
        description:
            'Built on selective hiring, advanced training, upfront management investment, and an agent-augmenting technology stack, we launch flawlessly and dramatically boost speed to proficiency. We get you to green KPIs faster than anyone (and keep you there).',
    },
    {
        image: `${baseURL}assets/images/highly-branded.png`,
        title: (
            <Fragment>
                Highly Branded <br />
                Support Centers
            </Fragment>
        ),
        description:
            'At ibex, we work with you to create a contact center delivery model that is optimal for your business needs. We take into account all factors including your unique situation, technology constraints, business needs, and financial considerations. Once we have assessed your complete set of requirements, we will structure a customized support solution that completely fulfills your brand requirements.',
    },
    {
        image: `${baseURL}assets/images/end-to-end.png`,
        title: (
            <Fragment>
                End-to-End <br />
                Enterprise Innovation
            </Fragment>
        ),
        description:
            'A 400-person technology center of excellence and a next-level business insights group allow ibex to proactively find new ways to help you increase revenue, reduce costs, and improve customer loyalty.',
    },
    {
        image: `${baseURL}assets/images/obsessed.png`,
        title: (
            <Fragment>
                Obsessed & Immersed <br />
                Frontline
            </Fragment>
        ),
        description:
            'Relentless employee engagement programs with out-of-this world client branding to ensure our frontlines are a radically engaged extension of your brand identity delivering passionate, industry-leading results.',
    },
    {
        image: `${baseURL}assets/images/dayone.png`,
        title: <Fragment>Day-One Leadership</Fragment>,
        description:
            'ibex believes in a fully-engaged, client-centric approach with great access to executive leadership, enabling a trusted partnership built on speed, flexibility, and a global organization all-in on attaining success with our clients.',
    },
];
export const ProfitSection = () => {
    return (
        <>
            <CardSection>
                <Container>
                    <Row>
                        {cardBoxItems &&
                            cardBoxItems.map((item, ind) => (
                                <Col lg="3" key={ind}>
                                    <div>
                                        <CardBox>
                                            <img src={item.imgSrc} alt="" />
                                            <TextContent>
                                                <h4>{item.heading}</h4>
                                                <ul>
                                                    {item.listItems &&
                                                        item.listItems.map(
                                                            (li, ind2) => (
                                                                <li key={ind2}>
                                                                    {li}
                                                                </li>
                                                            )
                                                        )}
                                                </ul>
                                            </TextContent>
                                        </CardBox>
                                    </div>
                                </Col>
                            ))}
                    </Row>
                </Container>
            </CardSection>

            <HeadingSection id="products">
                <Container>
                    <Row>
                        <Col lg="12">
                            <h2>Turn cost centers into profit centers.</h2>
                        </Col>
                    </Row>
                </Container>
            </HeadingSection>

            <ProductInfoSection>
                <Container>
                    <Row>
                        <Col lg="12">
                            <ProductContainer>
                                <Row>
                                    {CXFeedbackItems &&
                                        CXFeedbackItems.map((item, ind) => (
                                            <Col lg="12" key={ind}>
                                                <CXFeedbackBox
                                                    image={item.image}
                                                    title={item.title}
                                                    description={
                                                        item.description
                                                    }>
                                                    {item.productList && (
                                                        <Row>
                                                            <Col lg="6">
                                                                <ProductList>
                                                                    {item.productList.map(
                                                                        (
                                                                            li,
                                                                            ind2
                                                                        ) => (
                                                                            <li
                                                                                key={
                                                                                    ind2
                                                                                }>
                                                                                {
                                                                                    li
                                                                                }
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ProductList>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </CXFeedbackBox>
                                            </Col>
                                        ))}
                                </Row>
                            </ProductContainer>
                        </Col>
                    </Row>
                </Container>
            </ProductInfoSection>
        </>
    );
};
const HeadingSection = styled.section`
    text-align: center;
    padding-bottom: 200px;
    h2 {
        font-size: 60px;
        font-family: ${gilroySemibold};
    }
    ${Media('xlscreens')} {
        h2 {
            font-size: 3.5vw;
        }
    }
    ${Media('tablet')} {
        padding-top: 0px;
        h2 {
            font-size: 36px;
            margin-top: 0px;
        }
    }
`;

const ProductInfoSection = styled.section`
    background-color: ${colors.gray_dark['100']};
    padding-bottom: 0px;
`;

const ProductContainer = styled.div`
    position: relative;
    margin-top: -240px;
    margin-bottom: -140px;
`;

const ProductList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 0 15px;
    margin-top: 40px;
    li {
        flex: 0 0 50%;
        color: ${colors.white['100']};
        position: relative;
        margin-bottom: 10px;
        font-size: 14px;
        &::before {
            content: '';
            height: 5px;
            width: 5px;
            background-color: ${colors.white['100']};
            border-radius: 50%;
            position: absolute;
            left: -12px;
            top: calc(50% - 2.5px);
        }
        ${Media('xlscreens')} {
            font-size: 0.9vw;
        }
    }
`;
const CardSection = styled.section`
    ${Media('tablet')} {
        padding-top: 0px;
    }
`;

const CardBox = styled.div`
    height: 460px;
    background-color: ${colors.white['100']};
    display: flex;
    position: relative;
    text-align: left;
    width: 100%;
    padding-top: 120px;
    padding: 120px 39px 60px 39px;
    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        max-width: 65px;
        max-height: 65px;
    }
    h4 {
        font-size: 24px;
        margin: 0;
        font-family: ${gilroyBlack};
        color: ${colors.pink['100']};
        ${Media('xlscreens')} {
            font-size: 1.2vw;
        }
    }
    ul {
        padding: 0;
        li {
            font-family: ${gilroyLight};
            margin-bottom: 5px;
            font-size: 16px;
            line-height: 1.8;
            ${Media('xlscreens')} {
                font-size: 0.95vw;
            }
            span {
                color: ${colors.gray_dark['50']};
                font-family: ${gilroySemibold};
            }
        }
    }
    ${Media('xlscreens')} {
        height: 24vw;
        padding: 6.31vw 2.1vw 0 2.1vw;
    }
    ${Media('tablet')} {
        height: auto;
        margin-bottom: 50px;
    }
`;

const TextContent = styled.div``;
