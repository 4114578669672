import React, { lazy, Suspense } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import Loader from 'components/loader/loader';
import TopBIMethodologies from 'pages/resources/ebooks/top-BI-methodologies';
import Home from 'pages/Home';
import JoinUsPage from 'pages/join-us/index';
import ConnectPage from 'pages/connect/index';
import DigitalPage from 'pages/digital/index';
import CXPage from 'pages/cx/index';
import VelocityPage from 'pages/velocity/index';
import FinancialPage from 'pages/health/index';
import HealthPage from 'pages/financial/index';
import LeadershipPage from 'pages/leadership';
import NotFoundPage from 'pages/not-found';
import Adcast from 'pages/adcast';
import BundleDealer from 'pages/bundledealer/index';
import BusinessIntelligence from 'pages/business-intelligence/index';
import PrivaryPolicy from 'pages/privacy-policy';
import AtHome from 'pages/at-home/index';
import DigitalConferance from 'pages/digital-conference';
import EventIndex from 'pages/event';
import EventTemplate from 'pages/events/template';
import GBPIndex from 'pages/governance-in-action/index';
import FSAwards from 'pages/frost';
import Utilities from 'pages/utility-starter-kit';

import PixelPerfectSubscription from 'pages/pixel-perfect-subscriptions';
import SubscriptionStreetWearInfluencer from 'pages/subscription-streetwear-influencer';
import RunwayToTheHolidays from 'pages/runway-to-the-holidays';
import CaseStudies from 'pages/resources/case-studies/case-studies';
import SocialRepMan from 'pages/digital/social-reputation-management/index';
import Pharma from 'pages/resources/case-studies/healthcare-pharmacare-CX';
import HealthWP from 'pages/resources/whitepapers/healthcare-digital-patient-experience';
import FinancialServices from 'pages/industries/financial-services-cx';


import WebinarIndex from 'pages/resources/webinars';
import Aep from 'pages/health/burst-capacity-aep';
import WaveZero from 'pages/resources/blogs';
import WaveZeroBlog from 'pages/resources/blogs/blog-detail';
import WURedirect from 'pages/resources/case-studies/western-union-case-study';
import EbooksIndex from 'pages/resources/ebooks';
import TrainingSimulator from 'pages/technology/training-simulator';
import RetailEcommerce from 'pages/industries/retail-ecommerce';
import Witness from 'pages/technology/witness';
import Round from 'pages/technology/round';
import VitualSystemManage from 'pages/technology/virtual-floor-management-system';
import Flecx from 'pages/technology/flecx/index';
import Scorecard from 'pages/technology/scorecard';
import Inspire from 'pages/technology/inspire';
import Reflecx from 'pages/technology/reflecx/index';
import HeatMap from 'pages/technology/heatmap';

import WhyOutSource from 'pages/why-outsource';
import ResourcesLandingPage from 'pages/resources/landing-page';
import Resources from 'pages/resources';
import ContactUs from 'pages/contact-us';
import ThankYou from 'pages/thank-you';
import CSWeek from 'pages/utilities/csweek2021';

import IpadGiveAway from'pages/utilities/csweek2021/ipadgiveaway';
import DeliveryLogistics from 'pages/industries/delivery-logistics';
import HealthTechWellness from'pages/industries/healthtech-wellness';
import StreamingEntert from 'pages/industries/streaming-entertainment';
import Technology from'pages/industries/technology';
import Travelhospital from'pages/industries/travel-hospitality';

import DigitalTransformRec from  'pages/resources/webinars/digital-transformation-through-disruption-recording' ;
import FintechCXAward from 'pages/fintech-cx-awards';
import FintechAwards2024 from 'pages/cx-leadership-awards/2024';

import AllCaseStudies from 'pages/resources/case-studies/all-case-studies';
import AllWebinars from 'pages/resources/webinars/all-webinars';
import AllEbooks from 'pages/resources/ebooks/all-ebooks';
import CXGlossary from 'pages/cx-glossary/index';
import CXGlossaryDetail from 'pages/cx-glossary/detail';
import Money2020 from 'pages/events/money2020';
import StaffAug from 'pages/staff-augmentation';
import Diversity from 'pages/diversity-equity-inclusion';
import CoreValues from 'pages/core-values';
import JobListing from 'pages/join-us/careers/job-listing';
import LearnAboutIbex from 'pages/learn-about-ibex';
import LearnAboutIbexHealth from 'pages/learn-about-ibex-health';
import UtilitiesRework from 'pages/utilities';
import ESGPage from 'pages/esg';

import CBRedirector1 from 'pages/resources/ebooks/community-bank-digital-member-experience-part-1';

import CBRedirector2 from'pages/resources/ebooks/community-bank-digital-member-experience-part-2';

import WaveX from'pages/technology/wavex';
import CXPerformanceWaveX from'pages/technology/wavex/cx-performance-acceleration';
import CXManagementWaveX from'pages/technology/wavex/cx-interaction-management';
import OmniChannelSuiteWaveX from'pages/technology/wavex/omnichannel-cx-engagement';

import AIPage from'pages/ai';

import PKSiteSection from 'pages/join-us/careers/pk-site-sections';

import WaveIx from'pages/wave-ix';
import Translate from'pages/wave-ix/translate';
import Automate from'pages/wave-ix/automate';
import Authenticate from'pages/wave-ix/authenticate';
import VirtualAgent from'pages/wave-ix/virtual-agent';










// const Sites = lazy(() => import('pages/sites'));
// const SiteDetail = lazy(() => import('pages/sites/site-detail'));

const MainRoutes = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Router basename="/">
                <Switch>
                    <Route exact path="/resources/ebooks/BI-ebook">
                        <TopBIMethodologies />
                    </Route>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/join-us">
                        <JoinUsPage />
                    </Route>
                    <Route exact path="/join-us/pk-sites">
                        <PKSiteSection />
                    </Route>
                    <Route exact path="/join-us/:country">
                        <JobListing />
                    </Route>
                    <Route path="/leadership">
                        <LeadershipPage />
                    </Route>
                    <Route path="/adcast">
                        <Adcast />
                    </Route>
                    <Route path="/bundledealer">
                        <BundleDealer />
                    </Route>
                    <Route path="/business-intelligence">
                        <BusinessIntelligence />
                    </Route>
                    <Route path="/at-home">
                        <AtHome />
                    </Route>
                    <Route path="/digital-conference">
                        <DigitalConferance />
                    </Route>
                    <Route exact path="/event">
                        <EventIndex />
                    </Route>
                    <Route path="/governance-in-action">
                        <GBPIndex />
                    </Route>
                    <Route path="/utility-starter-kit">
                        <Utilities />
                    </Route>
                    <Redirect
                        from={
                            '/resources/ebooks/g/community-bank-digital-member-experience'
                        }
                        to={
                            '/resources/ebooks/g/community-bank-digital-customer-experience'
                        }
                    />
                    <Route path="/resources/:type/g/:slug">
                        <ResourcesLandingPage />
                    </Route>
                    <Route
                        exact
                        path="/resources/case-studies/healthcare-pharmacare-CX">
                        <Pharma />
                    </Route>
                    <Route exact path="/connect">
                        <ConnectPage  />

                    </Route>
                    <Route exact path="/digital/social-reputation-management">
                        <SocialRepMan />
                    </Route>
                    <Route exact path="/digital">
                        <DigitalPage />
                    </Route>
                    <Route path="/cx">
                        <CXPage />
                    </Route>
                    <Route path="/privacy-policy">
                        <PrivaryPolicy />
                    </Route>
                    <Route path="/velocity">
                        <VelocityPage />
                    </Route>
                    <Route path="/financial">
                        <FinancialPage />
                    </Route>
                    <Route exact path="/health">
                        <HealthPage />
                    </Route>
                    <Route path="/frost">
                        <FSAwards />
                    </Route>
                    <Route exact path="/resources/blogs">
                        <WaveZero />
                    </Route>
                    <Route path={`/resources/blogs/:topicId`}>
                        <WaveZeroBlog />
                    </Route>
                    <Route path="/pixel-perfect-subscriptions">
                        <PixelPerfectSubscription />
                    </Route>
                    <Route path="/subscription-streetwear-influencer">
                        <SubscriptionStreetWearInfluencer />
                    </Route>
                    <Route path="/runway-to-the-holidays">
                        <RunwayToTheHolidays />
                    </Route>
                    <Route
                        exact
                        path="/resources/case-studies/western-union-case-study">
                        <WURedirect />
                    </Route>
                    <Route exact path="/resources/case-studies/:slug">
                        <CaseStudies />
                    </Route>
                    <Route path="/health/burst-capacity-aep">
                        <Aep />
                    </Route>
                    <Route path="/resources/ebooks/community-bank-digital-member-experience-part-1">
                        <CBRedirector1 />
                    </Route>
                    <Route path="/resources/ebooks/community-bank-digital-member-experience-part-2">
                        <CBRedirector2 />
                    </Route>
                    <Route path="/resources/ebooks/:slug">
                        <EbooksIndex />
                    </Route>
                    <Route
                        exact
                        path="/resources/whitepapers/healthcare-digital-patient-experience">
                        <HealthWP />
                    </Route>
                    <Route
                        exact
                        path="/resources/webinars/digital-transformation-through-disruption-recording">
                        <DigitalTransformRec />
                    </Route>
                    <Route path="/resources/webinars/:slug">
                        <WebinarIndex />
                    </Route>
                    <Route path="/why-outsource">
                        <WhyOutSource />
                    </Route>
                    <Route path="/contact-us">
                        <ContactUs />
                    </Route>
                    <Route path="/thank-you">
                        <ThankYou />
                    </Route>
                    <Route exact path="/utilities/csweek2021">
                        <CSWeek />
                    </Route>
                    <Route exact path="/utilities/csweek2021/ipadgiveaway">
                        <IpadGiveAway />
                    </Route>
                    <Route path="/technology/scorecard">
                        <Scorecard />
                    </Route>
                    <Route path="/technology/inspire">
                        <Inspire />
                    </Route>
                    <Route path="/technology/flecx">
                        <Flecx />
                    </Route>
                    <Route path="/technology/heatmap">
                        <HeatMap />
                    </Route>
                    <Route path="/technology/reflecx">
                        <Reflecx />
                    </Route>
                    <Route path="/technology/witness">
                        <Witness />
                    </Route>
                    <Route path="/technology/round">
                        <Round />
                    </Route>
                    <Route path="/technology/virtual-floor-management-system">
                        <VitualSystemManage />
                    </Route>
                    <Route path="/technology/training-simulator">
                        <TrainingSimulator />
                    </Route>
                    <Route exact path="/technology/wavex">
                        <WaveX />
                    </Route>
                    <Route
                        exact
                        path="/technology/wavex/cx-performance-acceleration">
                        <CXPerformanceWaveX />
                    </Route>
                    <Route
                        exact
                        path="/technology/wavex/cx-interaction-management">
                        <CXManagementWaveX />
                    </Route>
                    <Route
                        exact
                        path="/technology/wavex/omnichannel-cx-engagement">
                        <OmniChannelSuiteWaveX />
                    </Route>
                    <Route path="/industries/financial-services-cx">
                        <FinancialServices />
                    </Route>
                    <Route path="/industries/retail-ecommerce">
                        <RetailEcommerce />
                    </Route>
                    <Route path="/industries/delivery-logistics">
                        <DeliveryLogistics />
                    </Route>
                    <Route path="/industries/healthtech-wellness">
                        <HealthTechWellness />
                    </Route>
                    <Route path="/industries/streaming-entertainment">
                        <StreamingEntert />
                    </Route>
                    <Route path="/industries/technology">
                        <Technology />
                    </Route>
                    <Route path="/industries/travel-hospitality">
                        <Travelhospital />
                    </Route>
                    <Route exact path="/events/money2020">
                        <Money2020 />
                    </Route>
                    <Route exact path="/events/:slug">
                        <EventTemplate />
                    </Route>
                    <Route path="/diversity-equity-inclusion">
                        <Diversity />
                    </Route>
                    <Route exact path="/resources">
                        <Resources />
                    </Route>
                    <Route exact path="/resources/case-studies">
                        <AllCaseStudies />
                    </Route>
                    <Route exact path="/resources/webinars">
                        <AllWebinars />
                    </Route>
                    <Route exact path="/resources/ebooks">
                        <AllEbooks />
                    </Route>
                    <Route exact path="/fintech-cx-awards">
                        <FintechCXAward />
                    </Route>
                    <Route path="/cx-leadership-awards/2024">
                        <FintechAwards2024 />
                    </Route>
                    <Route exact path="/cx-glossary/:slug">
                        <CXGlossaryDetail />
                    </Route>
                    <Route exact path="/cx-glossary">
                        <CXGlossary />
                    </Route>
                    <Route exact path="/staff-augmentation">
                        <StaffAug />
                    </Route>
                    <Route exact path="/core-values">
                        <CoreValues />
                    </Route>
                    <Route path="/learn-about-ibex">
                        <LearnAboutIbex />
                    </Route>
                    <Route path="/learn-about-ibex-health">
                        <LearnAboutIbexHealth />
                    </Route>
                    <Route path="/utilities">
                        <UtilitiesRework />
                    </Route>
                    <Route path="/esg">
                        <ESGPage />
                    </Route>
                    <Route path="/ai">
                        <AIPage />
                    </Route>
                    <Route exact path="/wave-ix">
                        <WaveIx />
                    </Route>
                    <Route exact path="/wave-ix/translate">
                        <Translate />
                    </Route>
                    <Route exact path="/wave-ix/automate">
                        <Automate />
                    </Route>
                    <Route exact path="/wave-ix/authenticate">
                        <Authenticate />
                    </Route>
                    <Route exact path="/wave-ix/virtual-agent">
                        <VirtualAgent />
                    </Route>
                    {/* <Route exact path="/sites">
                        <Sites />
                    </Route>
                    <Route path="/sites/:slug">
                        <SiteDetail />
                    </Route> */}
                    {/* Redirection */}
                    <Route
                        exact
                        path="/cellbenefit"
                        component={() => {
                            window.location.replace(
                                'https://app.smartsheet.com/b/form/0223de7cd1e145d0ac25bc59c0920dfe'
                            );
                            return null;
                        }}></Route>
                    <Redirect from={'/casestudy'} to={'/resources'} />

                    <Redirect
                        from={'/cx-leadership-awards/2023'}
                        to={'/cx-leadership-awards/2024'}
                    />
                    <Route>
                        <NotFoundPage />
                    </Route>
                </Switch>
            </Router>
        </Suspense>
    );
};

export default MainRoutes;
