import React from 'react';
import styled from 'styled-components';
import { gilroySemibold } from 'shared-components/theme/fonts';
import { FlexColumnCentered } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';
import { Container, Col, Row } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import MainPara from 'shared-components/main-para/main-para';
import ReactHtmlParser from 'react-html-parser';
export const ExpertOptions = ({
    expertData,
    heading,
    para,
    optionFlex,
    styleTwo,
}) => {
    return (
        <ExpertiseSection>
            <Container>
                <Row>
                    <Col sm="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <SolutionTitle align="center">
                                {heading}
                            </SolutionTitle>
                            <MainPara align="center" styleTwo={styleTwo}>
                                {ReactHtmlParser(para)}
                            </MainPara>
                        </ScrollAnimation>
                    </Col>
                </Row>
                <OptionsRow>
                    {expertData &&
                        expertData.length > 0 &&
                        expertData.map((item, index) => {
                            return (
                                <ExpCol
                                    sm="12"
                                    key={index}
                                    optionFlex={optionFlex}>
                                    <ExpertBox>
                                        <img src={item.icon} alt="-" />
                                        <p>{item.text}</p>
                                        {expertData.length !== index + 1 && (
                                            <ExpertArrow>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="15.995"
                                                    height="15.59"
                                                    viewBox="0 0 15.995 15.59">
                                                    <path
                                                        id="Icon_awesome-arrow-down"
                                                        data-name="Icon awesome-arrow-down"
                                                        d="M14.943,9.051l.793.793a.853.853,0,0,1,0,1.21L8.8,17.994a.853.853,0,0,1-1.21,0l-6.94-6.94a.853.853,0,0,1,0-1.21l.793-.793a.858.858,0,0,1,1.224.014l4.1,4.3V3.107a.855.855,0,0,1,.857-.857H8.763a.855.855,0,0,1,.857.857v10.26l4.1-4.3A.852.852,0,0,1,14.943,9.051Z"
                                                        transform="translate(-2.25 15.987) rotate(-90)"
                                                        fill="#fa0060"
                                                    />
                                                </svg>
                                            </ExpertArrow>
                                        )}
                                    </ExpertBox>
                                </ExpCol>
                            );
                        })}
                </OptionsRow>
            </Container>
        </ExpertiseSection>
    );
};
const ExpertiseSection = styled.section``;
const OptionsRow = styled(Row)`
    ${Media('xlscreens')} {
        margin-top: 2.66vw;
    }
`;
const ExpCol = styled(Col)`
    &&& {
        ${Media('xlscreens')} {
            flex: 0 0 ${(props) => props.optionFlex && props.optionFlex};
            max-width: ${(props) => props.optionFlex && props.optionFlex};
        }
    }
`;

const ExpertBox = styled(FlexColumnCentered)`
    margin: 50px 0 20px;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    p {
        font-size: 16px;
        font-family: ${gilroySemibold};
        margin: 20px 0 0px;
        padding: 0 30px;
    }
    ${Media('xlscreens')} {
        margin: 0;
        height: 100%;
        img {
            max-height: 6.3vw;
            max-width: 5.7vw;
        }
        p {
            font-size: 0.95vw;
            margin: 1.05vw 0 0px;
            padding: 0 2vw;
        }
    }
`;

const ExpertArrow = styled.div`
    position: absolute;
    right: 0px;
    top: 60px;
    ${Media('xlscreens')} {
        top: 5.8vw;
        right: -1.2vw;
        svg {
            max-width: 0.8125vw;
        }
    }
    ${Media('tablet')} {
        top: auto;
        bottom: -40px;
        right: 50%;
        transform: translateX(50%) rotate(90deg);
    }
`;
const SolutionTitle = styled.h2`
    font-size: 32px;
    font-family: ${gilroySemibold};
    line-height: 1.45;
    text-align: ${(props) => (props.align ? props.align : 'left')};
    margin-top: 0;
    ${Media('xlscreens')} {
        font-size: 2.08vw;
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 20px;
    }
`;
