import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { IoMdClose } from 'react-icons/io';
import Media from 'shared-components/theme/medias';
import { gilroyBold, gilroySemibold } from 'shared-components/theme/fonts';

const Modal = ({ state, closeForm, heading, subheading, heading_align }) => {
    return (
        <Wrapper open={state}>
            <OverlayWrapper onClick={closeForm} />
            <FormWrapper>
                <CloseFormIcon>
                    <IoMdClose onClick={closeForm} />
                </CloseFormIcon>
                <InnerWrapper>
                    {heading && heading !== '' ? (
                        <Heading align={heading_align}>{heading}</Heading>
                    ) : null}
                    {subheading && subheading !== '' ? (
                        <SubHeading>{subheading}</SubHeading>
                    ) : null}
                </InnerWrapper>
            </FormWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    ${(props) => {
        if (props.open) {
            return `opacity: 1;
                    visibility: visible;
                    transform: scale(1)`;
        } else {
            return `opacity: 0;
                    visibility: hidden;
                    transform: scale(0.8)`;
        }
    }};
`;

const OverlayWrapper = styled.div`
    height: 100%;
    width: 100%;
    background: transparent;
`;

const FormWrapper = styled.div`
    width: 60%;
    height: 80%;
    background-color: ${colors.white['100']};
    display: flex;
    justify-content: center;
    position: absolute;
    align-items: center;
    z-index: 999;
    ${Media('mobile')} {
        height: 100%;
        width: 100%;
    }
`;

const CloseFormIcon = styled.div`
    height: 50px;
    width: 50px;
    background-color: ${colors.black['100']};
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
        font-size: 30px;
        color: ${colors.white['100']};
    }
`;

const InnerWrapper = styled.div`
    width: 60%;
    ${Media('mobile')} {
        width: 80%;
        display: flex;
        align-items: center;
        padding: 30px 0;
        transform: scale(0.9);
    }
`;

const Heading = styled.h3`
    font-size: ${(props) =>
        props && props.headingSize ? props.headingSize : '39px'};
    font-family: ${gilroyBold};
    margin: 0;
    line-height: 1.2;
    color: ${(props) => props.color && props.color && props.color};
    text-align: ${(props) => (props.align ? props.align : 'left')};
    span {
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        font-size: ${(props) =>
            props.headingSize ? props.headingSize : '2.9vw'};
    }
    ${Media('tablet')} {
        font-size: 30px;
        br {
            display: none;
        }
    }
`;

const SubHeading = styled.div`
    padding: 10px 0px;
    font-size: 18px;
    span {
        font-size: 20px;
        font-family: ${gilroySemibold};
        b {
            font-family: ${gilroyBold};
        }
    }
    ${Media('xlscreens')} {
        font-size: 0.94vw;
        padding: 1vh 0vh;
    }
    ${Media('tabletlandscape')} {
        br {
            display: none;
        }
    }
    ${(props) => {
        if (props && props.color) {
            return `color:${props.color}`;
        }
    }}
`;

export default Modal;
