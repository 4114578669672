import { useEffect, useState } from 'react';

export default function useOnScreen(ref, delay) {
    const [isIntersecting, setIntersecting] = useState(false);
    let observerOptions = {
        root: null,
        rootMargin: delay ? delay : '0px',
        threshold: 0,
    };

    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        observerOptions
    );

    useEffect(() => {
        observer.observe(ref.current);
        // Remove the observer as soon as the component is unmounted
        return () => {
            observer.disconnect();
        };
    }, []);

    return isIntersecting;
}
