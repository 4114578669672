import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import {
    gilroySemibold,
    gilroyLight,
    gilroyRegular,
} from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';
import colors from 'shared-components/theme/colors';

const TheNeed = ({ contentParagraph, contentImage }) => {
    return (
        <ScrollAnimation animateIn="fadeInUp">
            <Wrapper>
                <Container>
                    <CustomRow>
                        <Col lg="5" md="12">
                            <ImageBox>
                                <img src={contentImage} alt="" />
                            </ImageBox>
                        </Col>
                        <Col lg="7" md="12">
                            <TextContentArea>
                                <Direction marginBottom={true}>
                                    <img
                                        src={`${baseURL}assets/images/arrow-down.svg`}
                                        alt="-"
                                    />
                                    The Need
                                </Direction>
                                {ReactHtmlParser(contentParagraph)}
                            </TextContentArea>
                        </Col>
                    </CustomRow>
                </Container>
            </Wrapper>
        </ScrollAnimation>
    );
};
const CustomRow = styled(Row)`
    ${Media('xlscreens')} {
        align-items: center;
    }
`;
const Direction = styled.div`
    display: flex;
    align-items: center;
    font-size: 52px;
    font-family: ${gilroySemibold};
    img {
        margin-right: 20px;
    }
    ${Media('xlscreens')} {
        margin-top: ${(props) =>
            props.marginTop ? '7vw' : props.marginTopSmall ? '2vw' : '0'};
        margin-bottom: ${(props) => (props.marginBottom ? '4vw' : '0')};
        font-size: 2.7vw;
        img {
            margin-right: 1.04vw;
        }
    }
    ${Media('tablet')} {
        font-size: 38px;
        justify-content: flex-start;
        margin-top: 50px;
        padding-bottom: 30px;
    }
`;
const Wrapper = styled.section`
    margin-top: 50px;
    padding: 0px;
    p {
        font-family: ${gilroyLight};
        font-size: 24px;
        line-height: 1.75;
        ${Media('xlscreens')} {
            font-size: 1.2vw;
        }
        ${Media('tablet')} {
            max-width: 100%;
            font-size: 18px;
            margin: 0px;
            br {
                display: none;
            }
        }
    }
    ${Media('xlscreens')} {
        margin: 5vw 0;
    }
`;
const ImageBox = styled.div`
    img {
        width: 100%;
        height: 100%;
    }
`;
const TextContentArea = styled.div`
    padding-left: 100px;
    p {
        font-family: ${(props) =>
            props.lightText ? gilroyRegular : gilroySemibold};
        font-size: 48px;
        line-height: 1.54;
        letter-spacing: -2px;
        color: ${colors.gray_dark['100']};
        strong,
        b {
            font-family: inherit;
        }
        ${Media('xlscreens')} {
            font-size: 2.51vw;
            margin: 0px;
        }
        ${Media('tablet')} {
            font-size: 30px;
            font-family: ${gilroyRegular};
            letter-spacing: 0px;
            margin: 0px;
        }
        ${Media('mobile')} {
            font-size: 24px;
        }
    }
    ${Media('xlscreens')} {
        padding-left: 4.5vw;
        margin-bottom: ${(props) => (props.marginBottom ? '4vw' : '0')};
    }
    ${Media('tablet')} {
        padding-left: 0px;
        margin-top: 40px;
    }
`;
export default TheNeed;
