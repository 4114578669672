import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { gilroySemibold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import { SAPageHeading, SAPagePara } from '../common';

const demandBox = [
    {
        imageUrl: `${baseURL}assets/images/staff_aug/demand-icon1.png`,
        title: 'Seamlessly Extend Your Team And Culture ',
        desc:
            'We focus on extending your team with candidates that fit your skill requirements and brand culture. We hire quickly to meet scale and project demands - just share your specifications and we’ll get the best-fit candidates ramped and ready.',
    },
    {
        imageUrl: `${baseURL}assets/images/staff_aug/demand-icon2.png`,
        title: 'Keep Hiring Costs Low and Remove Risk ',
        desc:
            'Our experienced and specialized recruitment methodology removes deployment and commitment risk.  Our solution ensures your costs will always be predictable, taking the guesswork out of your budget and ensuring you always have the right resources at the right time.',
    },
    {
        imageUrl: `${baseURL}assets/images/staff_aug/demand-icon3.png`,
        title: 'Increase Agility and Flexibility ',
        desc:
            'With access to highly-skilled talent, you can evolve and adapt your team to fit the needs of any request, in real time, allowing you to quickly move from project to project and take on new business.',
    },
];

export const SADemandSection = () => {
    return (
        <DemandSection id="demand">
            <Container>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <SAPageHeading align="center">
                        Your On-Demand Talent Stack.
                    </SAPageHeading>
                    <SAPagePara size="large" align="center">
                        Your time and cost-saving path to the best-fit
                        talent for you next project.
                    </SAPagePara>
                </ScrollAnimation>
                <Row>
                    {demandBox &&
                        demandBox.length > 0 &&
                        demandBox.map((demand, index) => {
                            return (
                                <Col sm="12" lg="4" key={index}>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}
                                        delay={index * 200}
                                    >
                                        <DemandBox>
                                            <DemandBoxHead>
                                                <img
                                                    src={demand.imageUrl}
                                                    alt="-"
                                                />
                                                <h5>{demand.title}</h5>
                                            </DemandBoxHead>
                                            <SAPagePara>
                                                {demand.desc}
                                            </SAPagePara>
                                        </DemandBox>
                                    </ScrollAnimation>
                                </Col>
                            );
                        })}
                </Row>
            </Container>
        </DemandSection>
    )
}

const DemandSection = styled.section`
    ${Media('xlscreens')} {
        padding-top: 6vw;
        .container {
            padding-left: 10vw !important;
            padding-right: 10vw !important;
        }
    }
`;
const DemandBox = styled.div`
    padding: 0 25px;
    margin-top: 50px;
    p {
        max-width: 90%;
    }
    ${Media('xlscreens')} {
        padding: 0 1.5vw;
        margin-top: 5vw;
    }
    ${Media('tablet')} {
        p {
            max-width: 100%;
        }
    }
`;
const DemandBoxHead = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    h5 {
        font-size: 27px;
        font-family: ${gilroySemibold};
        line-height: 1.4;
        margin: 0;
    }
    img {
        margin-right: 20px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 2.5vw;
        h5 {
            font-size: 1.4vw;
        }
        img {
            margin-right: 2vw;
            max-width: 4.2vw;
        }
    }
    ${Media('tablet')} {
        h5 {
            font-size: 20px;
            line-height: 26px;
        }
        img {
            margin-right: 10px;
        }
    }
`;