import React from 'react';
import styled from 'styled-components';
import { Container, Col, Row } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import colors from 'shared-components/theme/colors';
import {
    IndustrySectionHeading,
    IndustryTag,
    Text,
    ContentCenter,
    IndustryPerformanceBox,
    AmplifySection,
    LetsDiscuss,
} from 'components/industries/common';

export const PerformanceAmplifySec = ({ performanceList, amplifyList }) => {
    return (
        <>
            <CXPerformance id="cx-performance">
                <Container>
                    <div>
                        <ContentCenter>
                            <IndustryTag>Chaos Reduction</IndustryTag>
                            <IndustrySectionHeading>
                                The On-Demand Experience Ecosystem, <br />
                                Powered by ibex
                            </IndustrySectionHeading>
                            <Text>
                                Ensure repeatable success with ibex’s CX
                                Performance Methodology.
                            </Text>
                        </ContentCenter>
                    </div>
                </Container>
                <Container>
                    <Row>
                        {performanceList &&
                            performanceList.length > 0 &&
                            performanceList.map((listItem, index) => {
                                return (
                                    <Col lg="4" md="6" key={index}>
                                        <div>
                                            <IndustryPerformanceBox
                                                count={listItem.count}
                                                image={listItem.imgUrl}
                                                title={listItem.title}
                                                list={listItem.list}
                                            />
                                        </div>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </CXPerformance>

            <AmplifySection
                amplifyList={amplifyList}
                tag="SUCCESS GROWS HERE."
                heading="Your Billion-Dollar-Idea Success Engine"
                para="Featuring best-in-class agents, disruptive technologies, and enhanced IT security, ibex’s Technology CX solution pairs the power of an industry-<br/>tested CX performance engine with the innovation at the heart of your brand."
            />

            <LetsDiscuss
                heading="Looking to level-up your On-Demand High Tech CX?"
                btnText="Talk to us"
            />
        </>
    );
};
const CXPerformance = styled.section`
    background-color: ${colors.white['100']};
    padding-bottom: 0px;
`;
