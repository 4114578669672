import { Col, Row } from '@bootstrap-styled/v4';
import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { EbookDoubleBgSection, EbookMainHeading } from '../common';
import { ContainerWide } from '../common/style';
import ReactHtmlParser from 'react-html-parser';
import { gilroyBold } from 'shared-components/theme/fonts';

export const EbookWpMainTargetHeader = ({ image, title, count, id }) => {
    return (
        <EbookDoubleBgSection
            firstBg={colors.ebook.whitepaper['darkBlue']}
            firstBgHeight="100%"
            secondBg={colors.white['100']}
            noPadding
        >
            <ContainerWide>
                <Row>
                    <Col sm="12">
                        <Wrapper id={id}>
                            <Image>
                                <img src={image} alt="-" />
                            </Image>
                            <Content>
                                <Count>
                                    <EbookMainHeading
                                        color={colors.white['100']}
                                        noMargin
                                    >
                                        {count}
                                    </EbookMainHeading>
                                </Count>
                                <Title>{ReactHtmlParser(title)}</Title>
                            </Content>
                        </Wrapper>
                    </Col>
                </Row>
            </ContainerWide>
        </EbookDoubleBgSection>
    );
};

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    padding-top: 80px;
    ${Media('xlscreens')} {
        padding-top: 6.25vw;
    }
    ${Media('mobile')} {
        padding: 50px 0px;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
`;

const Image = styled.div`
    flex: 0 0 477px;
    max-width: 477px;
    height: 500px;
    overflow: hidden;
    margin-right: 30px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    ${Media('xlscreens')} {
        flex: 0 0 24.84vw;
        max-width: 24.84vw;
        height: 26vw;
        margin-right: 3.5vw;
    }
    ${Media('tablet')} {
        flex: 0 0 240px;
        max-width: 240px;
        height: 300px;
        margin-right: 25px;
    }
    ${Media('mobile')} {
        margin-right: 0;
    }
`;

const Content = styled.div`
    flex: 1 0;
    min-width: 0;
`;

const Count = styled.div`
    background-color: ${colors.health['blue']};
    width: 104px;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    ${Media('xlscreens')} {
        width: 5.4vw;
        height: 5.4vw;
    }
    ${Media('tablet')} {
        width: 70px;
        height: 70px;
    }
    ${Media('mobile')} {
        margin: 30px auto 0px;
    }
`;

const Title = styled.h3`
    color: ${colors.white['100']};
    font-size: 40px;
    margin-top: 30px;
    margin-bottom: 0px;
    font-family: ${gilroyBold};
    line-height: 1.2;
    ${Media('xlscreens')} {
        font-size: 2.6vw;
        margin-top: 3.6vw;
    }
    ${Media('tablet')} {
        font-size: 32px;
        br {
            display: none;
        }
    }
`;
