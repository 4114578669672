import React from 'react';

import { CXSectionIntro } from '../cx-pocket-guide/cx-section-intro';
import { Para } from '../cx-pocket-guide/para';
import { Col, Row } from '@bootstrap-styled/v4';
import { BigHeading } from '../cx-pocket-guide/big-heading';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';

export const Point2 = ({ active }) => {
    return (
        <>
            <Row>
                <Col lg="6">
                    <CXSectionIntro
                        style2
                        progress={0.26}
                        active={active}
                        source="Source:  Businesswire."
                        progressImg={`${baseURL}assets/images/ebook/imperative-ebook/dollar_sign_white.svg`}
                        introPara="Organizations enjoy <span>26% higher revenue</span> per employee when employees are highly engaged."
                    />
                </Col>
                <Col lg="6">
                    <CXSectionIntro
                        style2
                        progress={0.13}
                        active={active}
                        source="Source:  Businesswire."
                        progressImg={`${baseURL}assets/images/ebook/imperative-ebook/heart_arrow.svg`}
                        introPara="Organizations with highly engaged employees earned <span>13% greater returns</span> to shareholders."
                    />
                </Col>
            </Row>
            <BigHeading color={colors.cx_pocket_guide['pink']} typical active={active}>
                From onboarding to promotion, every touchpoint in your employee journey matters. This means advancement and development programs. Benefits. Bonuses. Incentives. Events.
            </BigHeading>
            <Para color={colors.white['100']}>
                It means creating a culture of community & engagement. It means designing a framework for advocacy and inclusion.
            </Para>
            <Para color={colors.white['100']}>
                And of course: it means pay. Pay your frontlines competitively. Without them, you’ll have no CX program at all.
            </Para>
        </>
    );
};
