import React from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    IndustrySectionHeading,
    IndustryTag,
    Text,
    TransformSection,
    ContentCenter,
} from 'components/industries/common';
import SolutionBox from 'components/solution-box/solution-box';
import styled from 'styled-components';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';

export const TransformSolutionSec = ({ retailSolutions }) => {
    return (
        <>
            <TransformSection
                tag="ALL STREAM EVERYTHING"
                sectionHeading="High-Impact, Loyalty-Commanding CX <br /> For Digital Content Innovators "
                img={`${baseURL}assets/images/industries/stream-ent-transform.jpg`}
                noBg
                noMarginTop
                para1="Original content calls for original customer experiences. As competition explodes in the streaming & digital media space, creating breathing room between you and your adversaries can mean the difference between acquiring that next million subscribers or an avalanche of cancelled subscriptions. "
                para2="At ibex, we believe great CX can deliver the breathing room you need. We understand that the on-demand world of digital streaming requires on-command, hyper-engaging CX that seamlessly amplifies First Contact Resolution, improves ROI, and protects platform loyalty. Our CX teams and technology move fast, execute flawlessly, and deliver outstanding results. "
            />

            <CXSolution id="cx-solution">
                <Container>
                    <div>
                        <ContentCenter>
                            <IndustryTag>
                                Repeat customers, not repeat contacts
                            </IndustryTag>
                            <IndustrySectionHeading>
                                Agile, Streaming CX for On-Demand Content
                                Leaders
                            </IndustrySectionHeading>
                            <Text>
                                For the world’s streaming & digital
                                entertainment innovators, <br /> ibex deploys
                                the best in people, technology, and methodology
                                to deliver flawless CX at speed and scale.
                            </Text>
                        </ContentCenter>
                    </div>
                    <SolutionBoxWrapper>
                        {retailSolutions &&
                            retailSolutions.length > 0 &&
                            retailSolutions.map((solution, index) => {
                                return (
                                    <Col lg="3" md="6" key={index}>
                                        <SolutionBox
                                            title={solution.title}
                                            imageUrl={solution.imageUrl}
                                            para={solution.para}
                                        />
                                    </Col>
                                );
                            })}
                    </SolutionBoxWrapper>
                </Container>
            </CXSolution>
        </>
    );
};
const CXSolution = styled.section`
    background-color: ${colors.gray_lightest['100']};
`;

const SolutionBoxWrapper = styled(Row)`
    min-height: 700px;
    ${Media('xlscreens')} {
        min-height: 36.4vw;
    }
`;
