import React from 'react';
import styled from 'styled-components';
import Layout from 'components/layout';
import colors from 'shared-components/theme/colors';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    FlexCentered,
    FlexColumnCentered,
    RowCentered,
    RowCenteredBoth,
} from 'shared-components/theme/grid-helpers';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import {
    gilroyBold,
    gilroyExtraboldItalic,
    gilroyMedium,
    sourceSansPro,
} from 'shared-components/theme/fonts';
import {
    EbookMainPara,
    EbookScrollDown,
    EbookBigText,
    EbookDoubleBgSection,
    EbookFeatureImage,
    EbookMenu,
    EbookMainHeading,
    EbookFeatureList,
    EbookDownArrow,
    EbookFloatingImage,
} from 'components/ebook/common';
import { Row, Col } from '@bootstrap-styled/v4';
import { ContainerCompact, ContainerWide } from 'components/ebook/common/style';
import {
    EbookHeadingLine,
    EbookFloatingLine,
    EbookFeatureBox,
    EbookMediumHeading,
    EbookQoute,
    EbookFeatureBoxList,
} from 'components/ebook/seasonal';
import ReactHtmlParser from 'react-html-parser';
import { FaArrowRight, FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { Parallax } from 'react-scroll-parallax';

const themeColor = colors.ebook.seasonal['main'];
const themeColor2 = colors.ebook.seasonal['main2'];
const themeColor3 = colors.ebook.seasonal['main3'];

const menuOptions = [
    {
        serial_number: '1',
        type: 'main',
        target: 'target1',
        title: 'Peak Perils',
    },
    {
        serial_number: '1.1',
        type: 'sub',
        target: 'target1-1',
        title: 'The Deadweight of Outdated Systems',
    },
    {
        serial_number: '1.2',
        type: 'sub',
        target: 'target1-2',
        title: 'The Competitive Pricing Push',
    },
    {
        serial_number: '1.3',
        type: 'sub',
        target: 'target1-3',
        title: 'The Risk of Forecasting Fails',
    },
    {
        serial_number: '1.4',
        type: 'sub',
        target: 'target1-4',
        title: 'The Seasonal Recruiting Slog',
    },
    {
        serial_number: '1.5',
        type: 'sub',
        target: 'target1-5',
        title: 'The High Cost of Doing Nothing',
    },
    {
        serial_number: '2',
        type: 'main',
        target: 'target2',
        title: 'Peak Partnerships: The Key Elements',
    },
    {
        serial_number: '2.1',
        type: 'sub',
        target: 'target2-1',
        title: 'Peak Performance Management Intelligence',
    },
    {
        serial_number: '2.2',
        type: 'sub',
        target: 'target2-2',
        title: 'Complexity Mapping',
    },
    {
        serial_number: '2.3',
        type: 'sub',
        target: 'target2-3',
        title: 'Speed to Proficiency',
    },
    {
        serial_number: '2.4',
        type: 'sub',
        target: 'target2-4',
        title: 'Staffing Up Strategically',
    },
    {
        serial_number: '2.5',
        type: 'sub',
        target: 'target2-5',
        title: 'Choreographing a Seamless Ramp',
    },
    // {
    //     serial_number: '2.6',
    //     type: 'sub',
    //     target: 'target2-6',
    //     title: 'Technology to Power Seasonal CX at Home',
    // },
    {
        serial_number: '3',
        type: 'main',
        target: 'target3',
        title: 'Case Studies',
    },
    {
        serial_number: '4',
        type: 'main',
        target: 'target4',
        title: 'Conclusion',
    },
];

const target1FeatureList = [
    {
        icon: `${baseURL}assets/images/ebook/sad-icon.svg`,
        text:
            'Working with outdated technology that impacts service levels and productivity',
    },
    {
        icon: `${baseURL}assets/images/ebook/sad-icon.svg`,
        text:
            'Managing increased pressure to hit the most competitive price points',
    },
    {
        icon: `${baseURL}assets/images/ebook/sad-icon.svg`,
        text: 'Accurately forecasting seasonal demand and staffing needs',
    },
    {
        icon: `${baseURL}assets/images/ebook/sad-icon.svg`,
        text: 'Recruiting reliable, high-quality seasonal employees',
    },
    {
        icon: `${baseURL}assets/images/ebook/sad-icon.svg`,
        text:
            'Rapidly ramping seasonal efforts while managing costs and quality',
    },
];

const target1_4Featurelist = [
    {
        image: `${baseURL}assets/images/ebook/t1-4_feature_1.jpg`,
        title: 'Accommodating compressed training timelines:',
        description:
            '<p> Without the proper training, agents may feel like they’re being thrown into the fire on day one, without the tools they need to do their job well. This is stressful for employees, bad for morale and onerous for managers who need to spend more time micromanaging and correcting mistakes. </p>',
    },
    {
        image: `${baseURL}assets/images/ebook/t1-4_feature_2.jpg`,
        title: 'Managing the burden on teams:',
        description:
            '<p> In addition to straining your budget and placing a heavy burden on site managers, supervisors and agents during an already challenging time, hiring high-quality seasonal employees also puts a great deal of pressure on your recruiting team. </p>',
    },
    {
        image: `${baseURL}assets/images/ebook/t1-4_feature_3.jpg`,
        title: 'Budgeting for hiring costs:',
        description:
            '<p> Seasonal recruiting becomes a recurring activity that needs to start months in advance—and finding the right people takes time and budget. The Society for Human Resource Management (SHRM) found that companies spend an average of 42 days to fill a position and $4,129 per <br /> hire <a href="https://www.shrm.org/about-shrm/press-room/press-releases/pages/human-capital-benchmarking-report.aspx" rel="noreferer noopener" target="_blank" > 4 </a>. The investment is considerable when you consider that the entire effort supports just a few months of revenue. </p>',
    },
    {
        image: `${baseURL}assets/images/ebook/t1-4_feature_4.jpg`,
        title: 'Filling seasonal roles:',
        description:
            '<p> Considering that the competition for seasonal staff can be fierce, there’s also the challenge of what happens if you don’t fill the positions—or can’t retain seasonal employees for as long as you need them. Almost one-quarter of retailers aren’t able to hire all the temporary workers they want for the holiday season, according to global consulting group Korn Ferry <a href="https://hbr.org/2018/12/do-your-seasonal-employees-feel-as-valued-as-they-should" rel="noreferer noopener" target="_blank" > 5 </a> . </p>',
    },
    {
        image: `${baseURL}assets/images/ebook/t1-4_feature_5.jpg`,
        title: 'Building a reliable workforce:',
        description:
            '<p> Temporary employees carry more risk than their full-time counterparts. They are more likely to leave a job for an incremental salary increase somewhere else, according to staffing firm <br /> Veritude <a href="https://www.industryweek.com/the-economy/public-policy/article/21953019/hiring-temporary-workers-can-cause-longterm-problems" rel="noreferer noopener" target="_blank" > 6 </a> . They are also more prone to low quality and high turnover, sometimes as high as 100% in a year. </p> <p> Of course, most employees’ preference is for full-time work and asking them to work on sacred cultural holidays can create conflicts. Knowing they will likely be let go after the season is complete, agents may be less motivated, more difficult to incentivize and less reliable. </p>',
    },
];

const target1_5Featurelist = [
    {
        icon: `${baseURL}assets/images/ebook/t1-5_feature-icon1.svg`,
        text: 'careful forecasting',
    },
    {
        icon: `${baseURL}assets/images/ebook/t1-5_feature-icon2.svg`,
        text: 'strategic planning ',
    },
    {
        icon: `${baseURL}assets/images/ebook/t1-5_feature-icon3.svg`,
        text: 'precision recruiting ',
    },
    {
        icon: `${baseURL}assets/images/ebook/t1-5_feature-icon4.svg`,
        text: 'expert training ',
    },
    {
        icon: `${baseURL}assets/images/ebook/t1-5_feature-icon5.svg`,
        text: 'agile execution',
    },
];

const target2FeatureList = [
    {
        icon: `${baseURL}assets/images/ebook/t2_feature1_1.svg`,
        text: 'Performance Management & BI Team',
    },
    {
        icon: `${baseURL}assets/images/ebook/t2_feature1_2.svg`,
        text: 'Elite Training Methodology',
    },
    {
        icon: `${baseURL}assets/images/ebook/t2_feature1_3.svg`,
        text: 'Workforce Management Team',
    },
    {
        icon: `${baseURL}assets/images/ebook/t2_feature1_4.svg`,
        text: 'Ramp-Tested PMO Organization',
    },
    {
        icon: `${baseURL}assets/images/ebook/t2_feature1_5.svg`,
        text: 'Performance Optimized Interaction Technology',
    },
];

const target2_2FeatureList = [
    {
        icon: `${baseURL}assets/images/ebook/t2-2_feature_1.svg`,
        text: 'Analyze interactions across the customer lifecycle',
    },
    {
        icon: `${baseURL}assets/images/ebook/t2-2_feature_2.svg`,
        text: 'Discover triggers for customer contact & churn',
    },
    {
        icon: `${baseURL}assets/images/ebook/t2-2_feature_3.svg`,
        text: 'Reduce Cost & Improve Program Performance',
    },
];

const T2StatsList1 = [
    {
        stat: '100%',
        text: 'virtual training',
    },
    {
        stat: '100%',
        text: 'fill rate on every class',
    },
    {
        stat: 'Sub-3.5%',
        text: 'attrition',
    },
    {
        stat: 'Both',
        text: 'tier 1 and tier 2',
    },
    {
        stat: '4.8%',
        text: 'absenteeism',
    },
    {
        stat: 'Flex staffing',
        text: 'across channels, at home and in-center',
    },
];

const T2StatsList2 = [
    {
        stat: '190K',
        text: 'Calls per day',
    },
    {
        stat: '91.4M',
        text: 'Calls per year',
    },
    {
        stat: '64',
        text: 'Lines of business',
    },
];

const SeasonalCXEbook = () => {
    return (
        <Layout
            page="ebook"
            bgColor={colors.technologyDropdown['box']}
            noScrollDown
            noTopBanner>
            <EbookMenu
                mainTheme={themeColor}
                mainTheme2={themeColor2}
                data={menuOptions}
                pdfFile={`${baseURL}assets/ebooks/Peak Failure - Fixing Your Seasonal CX - an ibex TransformBook.pdf`}
            />
            <div>
                <SeasonalIntro>
                    <SeasonalIntroInner>
                        <SeasonalIntroBgText>
                            <img
                                src={`${baseURL}assets/images/ebook/seasonal-intro-bg-text.svg`}
                                alt="-"
                            />
                        </SeasonalIntroBgText>
                        <RowCenteredBoth>
                            <Col lg={8} md={12} sm={12}>
                                <SeasonalIntroHeading>
                                    PEAK Failure: Fixing your
                                    <span> seasonal cx</span>
                                </SeasonalIntroHeading>
                            </Col>
                        </RowCenteredBoth>
                        <RowCenteredBoth>
                            <Col lg={9} sm={12}>
                                <SeasonalIntroPara>
                                    <EbookMainPara
                                        color={colors.white['100']}
                                        centerAligned
                                        seasonal>
                                        An in-depth guide for retailers and
                                        e-tailers on building predictable CX
                                        outcomes, season after season.
                                    </EbookMainPara>
                                </SeasonalIntroPara>
                            </Col>
                        </RowCenteredBoth>
                        <ScrollDownWrap>
                            <EbookScrollDown color={themeColor2} animate />
                            <p>Scroll down to start reading</p>
                        </ScrollDownWrap>
                    </SeasonalIntroInner>
                </SeasonalIntro>
            </div>

            <ForewardSection>
                <ForewardBigText>
                    <Parallax x={['-10%', '0%']}>
                        <img
                            src={`${baseURL}assets/images/ebook/foreward-top-image.jpg`}
                            alt="-"
                        />
                    </Parallax>
                    <EbookBigText color={themeColor2}>Foreword</EbookBigText>
                </ForewardBigText>
                <ContainerCompact>
                    <Row>
                        <Col sm={12}>
                            <div>
                                <ForewardImage>
                                    <img
                                        src={`${baseURL}assets/images/leaderships/bruce-dawson.jpg`}
                                        alt="-"
                                    />
                                </ForewardImage>
                            </div>
                            <ForewardContent>
                                <div>
                                    <h2>Foreword</h2>
                                </div>
                                <div>
                                    <p>
                                        An important shift is happening in the
                                        retail industry; the seasonal landscape
                                        is proliferating, with new and more
                                        frequent commercial seasons popping up
                                        each year. The result? Reduced peak
                                        severity countered with increasing
                                        seasonal length and complexity.
                                    </p>
                                    <p>
                                        Traditionally, depending on your
                                        industry, one-third or more of your
                                        yearly revenue was once captured within
                                        a timeframe of a few consecutive weeks.
                                        <br /> Thanks to Amazon, that’s now
                                        changed.
                                    </p>
                                    <p>
                                        When Amazon dropped Prime Day – its
                                        retail grenade - directly into the dog
                                        days of summer 2017, other retailers
                                        quickly followed suit, ramping up
                                        efforts to compete in the newly created
                                        retail season. Since then, the industry
                                        has gradually shifted towards a new
                                        higher-frequency year-round marketing &
                                        sales model, effectively transforming
                                        the holiday season into inter-connected
                                        micro seasons.
                                    </p>
                                    <p>
                                        For online brands, this is fast becoming
                                        a new Golden Age of Retail. Smoother
                                        ramps and more connected seasons mean
                                        retailers can now more safely plan their
                                        marketing, fulfillment, and logistics
                                        efforts across multiple connected
                                        seasons. Shifting from a
                                        one-season-takes-all mindset has pushed
                                        the industry to create more stand-out
                                        customer experiences, enjoy higher
                                        revenues, and mitigate operational risk.
                                    </p>
                                    <p>
                                        What does this mean for retailers
                                        looking to outsource their CX? With
                                        traditional ramps and micro-ramps
                                        converging, seasonal success
                                        increasingly depends on a more planned,
                                        measured, and targeted operational
                                        approach geared towards agility,
                                        continuity, and operational depth.
                                    </p>
                                    <p>
                                        In this eBook, we’re providing guidance
                                        and solutions on how to successfully
                                        prepare your CX operation for the coming
                                        headwinds of inbound retail seasons,
                                        micro or macro. And, throughout, be sure
                                        to keep one thing in mind - thank
                                        Amazon.
                                    </p>
                                </div>
                            </ForewardContent>
                            <ForewardSign>
                                <div>
                                    <SignInner>
                                        <h3>
                                            Bruce Dawson
                                            <span>
                                                Chief Sales and Client Services
                                                Officer, ibex
                                            </span>
                                        </h3>
                                    </SignInner>
                                </div>
                            </ForewardSign>
                        </Col>
                    </Row>
                </ContainerCompact>
            </ForewardSection>

            <EbookDoubleBgSection
                firstBg={themeColor2}
                firstBgHeight="40%"
                secondBg={colors.technologyDropdown['box']}
                noPadding>
                <div>
                    <EbookFeatureImage
                        image={`${baseURL}assets/images/ebook/perils-top-image.jpg`}
                        noMarginBottom
                        noMarginTop
                    />
                </div>
            </EbookDoubleBgSection>

            <TargetSection id="target1" noPaddingBottom>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/t1-floating-image.jpg`}
                    width={36}
                    left="0"
                    top="-15%"
                    y={['20%', '-20%']}
                />
                <EbookFloatingLine
                    width="20%"
                    bottom="18.5%"
                    left="0"
                    color={themeColor}
                    x={['-20%', '0%']}
                />
                <EbookFloatingLine
                    width="50%"
                    bottom="20%"
                    right="-40%"
                    color={themeColor2}
                    x={['10%', '-10%']}
                />
                <EbookFloatingLine
                    width="29%"
                    bottom="4%"
                    right="0"
                    color={colors.ebook.seasonal.pink}
                    x={['30%', '0%']}
                />
                <ContainerCompact>
                    <Row>
                        <Col sm="12">
                            <div>
                                <EbookHeadingLine
                                    width="33%"
                                    top="70%"
                                    right="9%"
                                    color={themeColor2}>
                                    <EbookMainHeading>
                                        Peak Perils: How Seasonal Ramps Test the
                                        Limits of your CX Program
                                    </EbookMainHeading>
                                </EbookHeadingLine>
                            </div>
                        </Col>
                        <Col sm="12">
                            <EbookMainPara seasonal>
                                <div>
                                    <p>
                                        Delivering high-quality CX during peak
                                        periods can add critical pressures
                                        across an organization. From smoothly
                                        integrating cross-functional teams, to
                                        technology upgrades, to carefully
                                        forecasting, planning, and executing
                                        your ramp, you need to do everything
                                        possible to win the battle for hearts
                                        and minds.
                                    </p>
                                    <p>
                                        The truth is many organizations struggle
                                        to pull it off, and a botched seasonal
                                        ramp can lead to millions in lost
                                        revenue, and the knock-on effect of
                                        negative customer mindshare. During your
                                        busiest season of the year, that’s a
                                        risk you can’t afford to take.
                                    </p>
                                    <p>
                                        As companies look to find efficiencies,
                                        smooth the journey, enhance CX—and do it
                                        all better than the other guys—these are
                                        some of the significant hurdles they’re
                                        facing:
                                    </p>
                                </div>
                            </EbookMainPara>
                        </Col>
                        <Col sm="12">
                            <div>
                                <T1FeatureList>
                                    <EbookFeatureList
                                        list={target1FeatureList}
                                        oddBgColor={`${themeColor2}10`}
                                        smallIcon
                                        seasonal
                                    />
                                </T1FeatureList>
                            </div>
                        </Col>
                    </Row>
                </ContainerCompact>
                <ContainerWide>
                    <Row>
                        <Col sm="12">
                            <div>
                                <WhiteBox>
                                    <RowCentered>
                                        <Col sm={{ size: 8, offset: 2 }}>
                                            <ContentCenter>
                                                <EbookMainPara centerAligned>
                                                    <p>
                                                        Let’s dive deeper into
                                                        each of these areas to
                                                        explore what’s holding
                                                        organizations back from
                                                        achieving their best
                                                        seasonal CX.
                                                    </p>
                                                </EbookMainPara>
                                                <EbookDownArrow />
                                            </ContentCenter>
                                        </Col>
                                    </RowCentered>
                                </WhiteBox>
                            </div>
                        </Col>
                    </Row>
                </ContainerWide>
                <ContainerCompact>
                    <RowCenteredBoth>
                        <Col sm="12" lg="10">
                            <div>
                                <T1QualityText>
                                    <EbookMediumHeading>
                                        <span>73%</span> of consumers say
                                        experience is an important factor in
                                        purchasing decisions, behind price and
                                        product quality
                                    </EbookMediumHeading>
                                    <EbookMainPara>Source: PWC</EbookMainPara>
                                </T1QualityText>
                            </div>
                        </Col>
                    </RowCenteredBoth>
                </ContainerCompact>
            </TargetSection>

            <TargetSection id="target1-1" noPaddingBottom>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/divide-floating-image.jpg`}
                    width={640}
                    height={626}
                    right="0"
                    bottom="0"
                    zIndex="-1"
                    x={['30%', '0%']}
                />
                <ContainerCompact>
                    <Row>
                        <Col sm="12">
                            <div>
                                <T1_1Heading>
                                    <EbookHeadingLine
                                        width="32%"
                                        top="85%"
                                        right="17%"
                                        color={colors.ebook.seasonal.pink}
                                        size="small">
                                        <EbookMainHeading size="small">
                                            The Deadweight of Outdated Systems
                                        </EbookMainHeading>
                                    </EbookHeadingLine>
                                </T1_1Heading>
                            </div>
                        </Col>
                        <Col sm="12">
                            <EbookMainPara seasonal>
                                <div>
                                    <p>
                                        Humans inevitably seek the path of least
                                        resistance. That impulse has led us to
                                        invent novel solutions, automate simple
                                        tasks and evolve to higher levels of
                                        thinking.
                                    </p>
                                    <p>
                                        But it also means we have little
                                        tolerance for overcomplicated or
                                        time-consuming ways of doing things.
                                        Today’s customers demand fast, easy
                                        service—in fact, 71% believe their
                                        problem should be solved immediately
                                        upon contacting customer service
                                        <Sup
                                            href="https://www.kustomer.com/guides/what-consumers-expect-from-the-customer-experience/"
                                            rel="noreferer noopener"
                                            target="_blank">
                                            1
                                        </Sup>
                                        .
                                    </p>
                                    <p>
                                        That expectation can be especially
                                        challenging to meet during peak shopping
                                        season, particularly if you’re using
                                        outdated legacy systems as part of your
                                        CX. Inefficient tools and technology put
                                        you at a serious disadvantage, causing
                                        friction for customers and employees
                                        alike. And because we seek the path of
                                        least resistance, if a system is overly
                                        complex, buggy or frustrating to use,
                                        people will gravitate toward an easier
                                        option (i.e. your competitor).
                                    </p>
                                    <p>
                                        On the other hand, solutions that are
                                        fine-tuned to your customers’
                                        preferences for quick, satisfying
                                        service present a major competitive
                                        advantage—and can reduce the time agents
                                        spend on standard processes by about a
                                        third
                                        <Sup
                                            href="https://www.de.kearney.com/communications-media-technology/article/?/a/the-future-of-customer-interaction-the-smart-contact-center"
                                            rel="noreferer noopener"
                                            target="_blank">
                                            2
                                        </Sup>
                                        . If your competitors are doing this
                                        better than you—especially during peak
                                        periods—that’s a problem.
                                    </p>
                                </div>
                            </EbookMainPara>
                        </Col>
                    </Row>
                    <DivideBoxRow>
                        <Col sm="12" md="12" lg="9">
                            <div>
                                <DivideBox>
                                    <EbookFloatingImage
                                        image={`${baseURL}assets/images/ebook/divide-image.jpg`}
                                        width={536}
                                        height={793}
                                        left="-67%"
                                        top="0"
                                    />
                                    <h4>The digital divide in CX</h4>
                                    <EbookMediumHeading
                                        color={colors.white['100']}>
                                        47% of customer service managers say
                                        outdated technology is their toughest
                                        challenge in delivering great service
                                    </EbookMediumHeading>
                                    <EbookMainPara>
                                        Source: Retail Touchpoints
                                    </EbookMainPara>
                                </DivideBox>
                            </div>
                        </Col>
                    </DivideBoxRow>
                    <Row>
                        <Col sm="8">
                            <div>
                                <EbookMainPara seasonal>
                                    <p>
                                        While it’s more important than ever to
                                        invest in the right solution stack,
                                        trying to incorporate new technologies
                                        that your existing system isn’t equipped
                                        to handle will only create more issues,
                                        especially if those solutions haven’t
                                        gone through proper testing or aren’t
                                        well integrated with your existing
                                        processes.
                                    </p>
                                </EbookMainPara>
                            </div>
                        </Col>
                    </Row>
                </ContainerCompact>
            </TargetSection>

            <FeatureImageHead noPaddingBottom>
                <EbookBigText color={colors.technologyDropdown['box']}>
                    PRICING PUSH
                </EbookBigText>
                <div>
                    <EbookFeatureImage
                        image={`${baseURL}assets/images/ebook/pricing-push-image.jpg`}
                        noMarginBottom
                    />
                </div>
            </FeatureImageHead>

            <TargetSection id="target1-2">
                <EbookFloatingLine
                    width="40%"
                    bottom="26%"
                    right="-20%"
                    x={['10%', '-10%']}
                    color={colors.ebook.seasonal.pink}
                />
                <ContainerCompact>
                    <Row>
                        <Col sm="12">
                            <T1_2Heading>
                                <div>
                                    <EbookHeadingLine
                                        width="33%"
                                        top="85%"
                                        right="15%"
                                        color={themeColor2}
                                        size="small">
                                        <EbookMainHeading size="small">
                                            The Competitive Pricing Push
                                        </EbookMainHeading>
                                    </EbookHeadingLine>
                                </div>
                                <div>
                                    <EbookMainPara seasonal>
                                        <p>
                                            During the seasonal rush, businesses
                                            face more intense competition around
                                            pricing than at any other time of
                                            year. As they battle for market
                                            share, they face a few key
                                            challenges:
                                        </p>
                                    </EbookMainPara>
                                </div>
                            </T1_2Heading>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <div>
                                <EbookFeatureBox
                                    image={`${baseURL}assets/images/ebook/t1-2_feature_1.jpg`}
                                    title="Meeting heightened eCommerce expectations:">
                                    <EbookMainPara seasonal>
                                        <p>
                                            Particularly with the rise of online
                                            shopping, retailers are increasingly
                                            struggling to keep up with new
                                            delivery mechanisms and timeframes
                                            being offered by market leaders who
                                            drive much of the competition
                                            pressure.
                                        </p>
                                    </EbookMainPara>
                                </EbookFeatureBox>
                            </div>
                            <div>
                                <EbookFeatureBox
                                    image={`${baseURL}assets/images/ebook/t1-2_feature_2.jpg`}
                                    title="Managing market volatility:">
                                    <EbookMainPara seasonal>
                                        <p>
                                            There are added pressures now too,
                                            as a result of volatile market
                                            conditions created by the
                                            coronavirus pandemic. Some consumers
                                            are under increasing financial
                                            strain as the pandemic takes its
                                            toll on household income, while
                                            others find themselves with more
                                            disposable income than ever because
                                            they’re spending less on things like
                                            travel and dining.
                                        </p>
                                    </EbookMainPara>
                                </EbookFeatureBox>
                            </div>
                            <div>
                                <EbookFeatureBox
                                    image={`${baseURL}assets/images/ebook/t1-2_feature_3.jpg`}
                                    title="Responding to pandemic-driven lifestyle shifts:">
                                    <EbookMainPara seasonal>
                                        <p>
                                            Changes in consumer behavior spurred
                                            by the pandemic have made it
                                            challenging for businesses to stay
                                            on top of supply and demand.
                                            Predicting purchasing decisions is
                                            more difficult than ever and that
                                            means brands have a lot to consider
                                            when pricing their products for the
                                            seasonal rush.
                                        </p>
                                    </EbookMainPara>
                                </EbookFeatureBox>
                            </div>
                        </Col>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainPara seasonal>
                                    <p>
                                        Ultimately, companies face the same
                                        intense pressure they’ve always faced
                                        during peak periods, only under
                                        different conditions—that is, the
                                        struggle to keep prices competitive
                                        while delivering service that meets
                                        customers’ expectations and wins their
                                        loyalty.
                                    </p>
                                </EbookMainPara>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </ContainerCompact>
            </TargetSection>

            <ForecastingSection id="target1-3">
                <EbookFloatingLine
                    width="35%"
                    left="-20%"
                    top="16%"
                    x={['-20%', '20%']}
                    color={themeColor}
                />
                <EbookFloatingLine
                    width="35%"
                    right="-20%"
                    top="60%"
                    x={['20%', '-20%']}
                    color={colors.ebook.seasonal.pink}
                />
                <ContainerCompact>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainHeading size="small">
                                    The Risks of Forecasting Fails
                                </EbookMainHeading>
                            </ScrollAnimation>
                            <EbookMainPara seasonal>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <p>
                                        “Stick to service level targets but keep
                                        staffing costs low.” It’s the struggle
                                        of workforce managers everywhere all
                                        throughout the year, but especially
                                        during seasonal hiring when they’re
                                        under pressure to do more with less—and
                                        strike the perfect balance between
                                        service levels and labor costs. Whether
                                        you bring on too many seasonal workers
                                        or not enough, inaccurate forecasts and
                                        poor scheduling decisions can have a
                                        significant impact on the bottom line.
                                    </p>
                                    <p>
                                        The best workforce planning allocates
                                        precisely the right resources at the
                                        right time to allow customer service
                                        teams to operate at peak performance
                                        during the seasonal rush. But many
                                        companies struggle to find that sweet
                                        spot, whether because they lack data
                                        from previous years or don’t know how to
                                        properly analyze and apply it to predict
                                        future scenarios.
                                    </p>
                                    <p>
                                        While insights from last year can shed
                                        light on what’s coming next, they don’t
                                        tell the whole story. The coronavirus
                                        pandemic created conditions this
                                        year—such as stay-home orders and store
                                        closures—that may not be at play two or
                                        three years down the road. Similarly,
                                        the convergence of American Thanksgiving
                                        and the first day of Hanukkah in 2013
                                        created unique conditions for retailers
                                        that aren’t expected to happen again for
                                        thousands of years
                                        <Sup
                                            href="https://www.wsj.com/articles/SB10001424052702304176904579112022682954300"
                                            rel="noreferer noopener"
                                            target="_blank">
                                            3
                                        </Sup>
                                        .
                                    </p>
                                    <p>
                                        Optimizing staffing levels requires
                                        precise forecasting. And accurate
                                        forecasting requires a combination of
                                        experience, knowledge, data,
                                        analysis—and significant preparation. If
                                        your organization doesn’t have a
                                        sophisticated workforce management (WFM)
                                        process or associated expertise, it can
                                        be challenging to do this well during
                                        your seasonal ramp. The result? Service
                                        levels will suffer, and you’ll likely
                                        end up over- or under-spending on
                                        seasonal labor.
                                    </p>
                                </ScrollAnimation>
                            </EbookMainPara>
                        </Col>
                    </Row>
                </ContainerCompact>
            </ForecastingSection>

            <EbookDoubleBgSection
                firstBg={themeColor2}
                firstBgHeight="50%"
                secondBg={colors.technologyDropdown['box']}
                noPadding>
                <ContainerWide>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <ForecastingBox>
                                    <ForecastingInner>
                                        <EbookMediumHeading
                                            color={colors.white['100']}
                                            seasonal>
                                            90% of companies see forecasting as
                                            important to their success. Only 13%
                                            believe they do it well.
                                        </EbookMediumHeading>
                                        <EbookMainPara centerAligned>
                                            Source: Concentric
                                        </EbookMainPara>
                                    </ForecastingInner>
                                </ForecastingBox>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </ContainerWide>
            </EbookDoubleBgSection>

            <FeatureImageHead noPaddingBottom>
                <EbookBigText color={colors.technologyDropdown['box']}>
                    RECRUITING
                </EbookBigText>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <EbookFeatureImage
                        image={`${baseURL}assets/images/ebook/recruiting-image.jpg`}
                        noMarginBottom
                    />
                </ScrollAnimation>
            </FeatureImageHead>

            <TargetSection id="target1-4" noPaddingBottom>
                <ContainerCompact>
                    <Row>
                        <Col sm="12">
                            <T1_2Heading>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookHeadingLine
                                        width="34%"
                                        top="85%"
                                        left="12%"
                                        color={themeColor}
                                        size="small">
                                        <EbookMainHeading size="small">
                                            The Seasonal Recruiting Slog
                                        </EbookMainHeading>
                                    </EbookHeadingLine>
                                </ScrollAnimation>
                                <EbookMainPara seasonal>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <p>
                                            While hiring temporary employees
                                            helps you pivot to address workload
                                            fluctuations, maintain staffing
                                            flexibility and evaluate workers
                                            without commitment, there are also
                                            big challenges involved.
                                        </p>
                                        <p>
                                            The majority of seasonal workers
                                            will only be with you for a few
                                            months or less. When you consider
                                            the resources that go into training
                                            them, it can feel like money down
                                            the drain. While their training is
                                            compressed into a shorter time span,
                                            they still need to reach a similar
                                            level of proficiency to your
                                            full-time staff and be sufficiently
                                            knowledgeable about your company,
                                            products and processes to deliver a
                                            consistent experience. Here are some
                                            of the key seasonal hiring
                                            challenges companies face today:
                                        </p>
                                    </ScrollAnimation>
                                </EbookMainPara>
                            </T1_2Heading>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <RecruitingFact>
                                <EbookFloatingLine
                                    width="50%"
                                    top="50%"
                                    left="-45%"
                                    x={['-35%', '15%']}
                                    color={colors.ebook.seasonal.pink}
                                />
                                <EbookFloatingLine
                                    width="42%"
                                    top="40%"
                                    right="-45%"
                                    color={themeColor}
                                    x={['35%', '-15%']}
                                />
                                <EbookFloatingLine
                                    width="45%"
                                    top="15%"
                                    left="52%"
                                    x={['20%', '-20%']}
                                    color={themeColor2}
                                />
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookMediumHeading>
                                        Companies spend an average of 42 days
                                        and $4,129 hiring a new employee
                                    </EbookMediumHeading>
                                    <EbookMainPara centerAligned>
                                        Source: SHRM
                                    </EbookMainPara>
                                </ScrollAnimation>
                            </RecruitingFact>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            {target1_4Featurelist &&
                                target1_4Featurelist.length > 0 &&
                                target1_4Featurelist.map((item, index) => {
                                    return (
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={false}
                                            key={index}>
                                            <EbookFeatureBox
                                                image={item.image}
                                                title={item.title}>
                                                <EbookMainPara seasonal>
                                                    {ReactHtmlParser(
                                                        item.description
                                                    )}
                                                </EbookMainPara>
                                            </EbookFeatureBox>
                                        </ScrollAnimation>
                                    );
                                })}
                        </Col>
                    </Row>
                </ContainerCompact>
                <div style={{ position: 'relative' }}>
                    <EbookFloatingImage
                        image={`${baseURL}assets/images/ebook/t2-floating-image.jpg`}
                        width={30}
                        left="0%"
                        top="25%"
                        y={['-20%', '20%']}
                    />
                    <EbookFloatingImage
                        image={`${baseURL}assets/images/ebook/t2-floating-image.jpg`}
                        width={30}
                        right="0%"
                        top="75%"
                        y={['20%', '-20%']}
                    />
                    <EbookQoute
                        bgColor={colors.ebook.text}
                        authorImg={`${baseURL}assets/images/ebook/qoute-1_author.jpg`}
                        bigText1="“It takes 20 years"
                        // bigText2="reputation and five"
                        qouteText="“It takes 20 years to build a reputation and five minutes to ruin it. If you think about that, you’ll do things differently.”"
                        qouteColor={themeColor2}
                        bigTextStroke={themeColor2}
                        authorName="Warren Buffett"
                        authorNameColor={colors.white['100']}
                        noMarginBottom
                    />
                </div>
            </TargetSection>

            <EbookDoubleBgSection
                firstBg={colors.ebook.text}
                firstBgHeight="20%"
                noPadding>
                <T1_5ImageHead noPaddingBottom>
                    <EbookBigText color={colors.technologyDropdown['box']}>
                        HIGH RISK
                    </EbookBigText>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <EbookFeatureImage
                            image={`${baseURL}assets/images/ebook/high-risk-image.jpg`}
                            noMarginBottom
                        />
                    </ScrollAnimation>
                </T1_5ImageHead>
            </EbookDoubleBgSection>

            <TargetSection id="target1-5" noPaddingBottom>
                <ContainerCompact>
                    <Row>
                        <Col sm="12">
                            <T1_2Heading>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookHeadingLine
                                        width="33%"
                                        top="85%"
                                        left="10%"
                                        color={colors.ebook.seasonal.pink}
                                        size="small">
                                        <EbookMainHeading size="small">
                                            The High Cost of Doing Nothing
                                        </EbookMainHeading>
                                    </EbookHeadingLine>
                                </ScrollAnimation>
                                <EbookMainPara seasonal>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <p>
                                            During peak periods, organizations
                                            are under a lot of pressure to
                                            maintain quality and manage customer
                                            expectations while making sure costs
                                            don’t spiral out of control. Whether
                                            it’s your budget or your customer
                                            relationships, you don’t want to
                                            break either in the process of
                                            quickly ramping your seasonal
                                            support efforts.
                                        </p>
                                        <p>
                                            <b>
                                                Striking a happy balance between
                                                cost and quality requires:
                                            </b>
                                        </p>
                                    </ScrollAnimation>
                                </EbookMainPara>
                                <Row>
                                    {target1_5Featurelist &&
                                        target1_5Featurelist.length > 0 &&
                                        target1_5Featurelist.map(
                                            (item, index) => {
                                                return (
                                                    <Col
                                                        sm="12"
                                                        md="6"
                                                        lg="4"
                                                        key={index}>
                                                        <ScrollAnimation
                                                            animateIn="fadeInUp"
                                                            animateOnce={false}>
                                                            <HighRiskListItem>
                                                                <img
                                                                    src={
                                                                        item.icon
                                                                    }
                                                                    alt="-"
                                                                />
                                                                <EbookMainPara
                                                                    seasonal>
                                                                    {item.text}
                                                                </EbookMainPara>
                                                            </HighRiskListItem>
                                                        </ScrollAnimation>
                                                    </Col>
                                                );
                                            }
                                        )}
                                </Row>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookMainPara seasonal>
                                        <p>
                                            A comprehensive approach helps you
                                            reduce launch timelines as much as
                                            possible, so you see return on
                                            investment (ROI) faster and never
                                            have to sacrifice CX.
                                        </p>
                                        <p>
                                            For companies that can’t ramp fast
                                            enough to stay on top of the rush,
                                            the impact on customer satisfaction
                                            can be detrimental. As customers
                                            drop off into the black hole of long
                                            wait times, inefficient processes
                                            and unsatisfactory resolutions,
                                            there’s a good chance it’s the last
                                            time you’ll ever see them. 32% of
                                            consumers will walk away from a
                                            brand they love after just one
                                            negative experience—meaning every
                                            interaction has the potential to
                                            make or break their loyalty.
                                            <Sup
                                                href="https://www.pwc.com/us/en/services/consulting/library/consumer-intelligence-series/future-of-customer-experience.html"
                                                rel="noreferer noopener"
                                                target="_blank">
                                                7
                                            </Sup>
                                        </p>
                                    </EbookMainPara>
                                </ScrollAnimation>
                            </T1_2Heading>
                        </Col>
                    </Row>
                </ContainerCompact>
            </TargetSection>

            <FeatureImageHead noPaddingBottom>
                <EbookBigText color={colors.technologyDropdown['box']}>
                    PARTNERSHIPS
                </EbookBigText>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <EbookFeatureImage
                        image={`${baseURL}assets/images/ebook/partnership-image.jpg`}
                        noMarginTop
                        noMarginBottom
                    />
                </ScrollAnimation>
            </FeatureImageHead>

            <TargetSection id="target2" noPaddingBottom>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/divide-floating-image.jpg`}
                    width={690}
                    height={690}
                    right="-18%"
                    top="50%"
                    x={['-20%', '20%']}
                    circled
                />
                <ContainerCompact>
                    <Row>
                        <Col sm="12">
                            <T1_2Heading>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookHeadingLine
                                        width="45%"
                                        top="80%"
                                        left="25%"
                                        color={colors.ebook.seasonal.pink}>
                                        <EbookMainHeading>
                                            Peak Partnerships: The Key Elements
                                            of Seasonal CX Success
                                        </EbookMainHeading>
                                    </EbookHeadingLine>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookMainPara seasonal>
                                        <p>
                                            At ibex, our goal is to connect you
                                            to the people best equipped to
                                            deliver the advice, knowledge and
                                            support you need to make all your
                                            customer interactions successful. In
                                            order to do that, we have a flat
                                            organizational model that gives our
                                            clients direct access to:
                                        </p>
                                    </EbookMainPara>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <T1FeatureList>
                                        <EbookFeatureList
                                            list={target2FeatureList}
                                            oddBgColor={`${colors.white['100']}40`}
                                            seasonal
                                        />
                                    </T1FeatureList>
                                </ScrollAnimation>
                                <EbookMainPara seasonal>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <p>
                                            Especially when it comes to the
                                            quick pivot needed for a seasonal CX
                                            ramp-up, our speed and flexibility
                                            ensure your needs are met
                                            efficiently, challenges are
                                            addressed quickly, and future
                                            situations are planned and accounted
                                            for proactively.
                                        </p>
                                        <p>
                                            As experienced partners during peak
                                            CX and beyond, we develop a deep
                                            understanding of your needs and
                                            environment. Through end-to-end
                                            processes and purpose-driven tools,
                                            we support you in rethinking and
                                            redesigning your seasonal CX to
                                            improve customer interactions,
                                            contact center performance and
                                            business outcomes.
                                        </p>
                                        <p>
                                            And because the strongest partners
                                            work closely with their clients to
                                            co-innovate, we don’t stop there. We
                                            work together with you to build
                                            engagements strategically, scale
                                            rapidly, measure as we go, adapt as
                                            needed, and shape the future of your
                                            CX program.
                                        </p>
                                    </ScrollAnimation>
                                </EbookMainPara>
                            </T1_2Heading>
                        </Col>
                    </Row>
                </ContainerCompact>
            </TargetSection>

            <FeatureImageHead bigTextMiddle noPaddingBottom>
                <EbookBigText color={colors.technologyDropdown['box']}>
                    INTELLIGENCE
                </EbookBigText>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <EbookFeatureImage
                        image={`${baseURL}assets/images/ebook/peak-performance-image.jpg`}
                        noMarginTop
                        noMarginBottom
                    />
                </ScrollAnimation>
            </FeatureImageHead>

            <TargetSection id="target2-1" noPaddingBottom>
                <ContainerCompact>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookHeadingLine
                                    width="33%"
                                    top="85%"
                                    left="37%"
                                    color={themeColor2}
                                    size="small">
                                    <EbookMainHeading size="small">
                                        Peak Performance Management Intelligence
                                    </EbookMainHeading>
                                </EbookHeadingLine>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainPara seasonal>
                                    <p>
                                        What’s the secret to getting seasonal CX
                                        right? In large part, it comes down to
                                        two key processes: Glide Path Modeling
                                        and Complexity Mapping, delivered by a
                                        sophisticated Business Intelligence (BI)
                                        team. These aren’t quite a crystal
                                        ball—but they’re the next best thing.
                                    </p>
                                </EbookMainPara>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookFeatureBox
                                    image={`${baseURL}assets/images/ebook/t2-1_feature_1.jpg`}
                                    title="Proprietary Glide Path Modeling">
                                    <EbookMainPara seasonal>
                                        <p>
                                            A glide path is a planning and
                                            forecasting analysis that optimizes
                                            service levels and labor costs by
                                            analyzing a range of factors. The BI
                                            team performs this analysis to
                                            identify the number of agents needed
                                            at specific intervals of the
                                            seasonal ramp—all done in advance to
                                            ensure resources are planned and
                                            accounted for. Glide paths
                                            meticulously account for every
                                            possible factor that could impact
                                            service, including historical
                                            trends, past performance, call
                                            triggers and peak contact periods,
                                            while taking into account costs,
                                            goals, objectives, recruitment
                                            needs, and more.
                                        </p>
                                        <p>
                                            This analysis is accompanied by
                                            specific key performance indicators
                                            (KPIs), used to measure success at
                                            each ramp interval, as well as tools
                                            to correct performance, maintain
                                            agent retention and engagement, and
                                            ensure consistent CX performance
                                            through the holiday volume spike.
                                        </p>
                                        <p>
                                            Performing comprehensive planning
                                            and analysis well ahead of the rush
                                            ensures that, when it’s go-time,
                                            you’re hitting service levels,
                                            sailing smoothly—and making it look
                                            easy.
                                        </p>
                                    </EbookMainPara>
                                </EbookFeatureBox>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12">
                            <ProjectGlideWrapper>
                                <EbookFloatingImage
                                    image={`${baseURL}assets/images/ebook/project-glide-bg.jpg`}
                                    width={770}
                                    height={1050}
                                    right="-13%"
                                    bottom="0"
                                    x={['-20%', '20%']}
                                    zIndex={'-1'}
                                />
                                <ProjectGlideGoal>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <ProjectGlideHeading>
                                            Projecting glide goals
                                        </ProjectGlideHeading>
                                        <Goal>
                                            <GoalCount>1</GoalCount>
                                            <GoalText>
                                                Review{' '}
                                                <b>
                                                    historical performance
                                                    trends
                                                </b>{' '}
                                                for each week to calculate wave
                                                performance averages with small
                                                increases to account for
                                                proficiency over time.
                                            </GoalText>
                                        </Goal>
                                        <Goal>
                                            <GoalCount>2</GoalCount>
                                            <GoalText>
                                                Look at{' '}
                                                <b>
                                                    {' '}
                                                    proficiency and maximum
                                                    performance caps{' '}
                                                </b>{' '}
                                                to determine the ceiling on
                                                average handle time (AHT) and
                                                accurately set top-end
                                                proficiency targets.
                                            </GoalText>
                                        </Goal>
                                        <Goal>
                                            <GoalCount>3</GoalCount>
                                            <GoalText>
                                                Review{' '}
                                                <b>
                                                    {' '}
                                                    vendor competitor
                                                    year-over-year performance
                                                    trends{' '}
                                                </b>{' '}
                                                to determine feasibility when
                                                setting targets.
                                            </GoalText>
                                        </Goal>
                                        <Goal>
                                            <GoalCount>4</GoalCount>
                                            <GoalText>
                                                Review variables like{' '}
                                                <b> projected volumes </b> by
                                                wave and tenure of each group.
                                            </GoalText>
                                        </Goal>
                                        <Goal>
                                            <GoalCount>5</GoalCount>
                                            <GoalText>
                                                Review{' '}
                                                <b> projected attrition </b>{' '}
                                                weekly and factor into each wave
                                                the number of agents,
                                                calls/chats per day, and AHT
                                                performance for remaining
                                                agents.
                                            </GoalText>
                                        </Goal>
                                        <Goal>
                                            <GoalCount>6</GoalCount>
                                            <GoalText>
                                                Set{' '}
                                                <b>
                                                    {' '}
                                                    glides and performance
                                                    milestones.{' '}
                                                </b>
                                            </GoalText>
                                        </Goal>
                                    </ScrollAnimation>
                                </ProjectGlideGoal>
                            </ProjectGlideWrapper>
                        </Col>
                    </Row>
                </ContainerCompact>
            </TargetSection>

            <ComplexitySection>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/t2-2_floating_circle.png`}
                    width={105}
                    left="-2%"
                    top="17%"
                    x={['-10%', '20%']}
                />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/project-glide-bg.jpg`}
                    width={550}
                    right="-19%"
                    top="39%"
                    x={['-10%', '20%']}
                />
                <EbookFloatingLine
                    width="40%"
                    top="20%"
                    right="-20%"
                    x={['20%', '-20%']}
                    color={themeColor2}
                />
                <EbookFloatingLine
                    width="40%"
                    top="48%"
                    left="-20%"
                    x={['-30%', '10%']}
                    color={colors.ebook.seasonal.pink}
                />
                <EbookFloatingLine
                    width="40%"
                    bottom="4%"
                    right="-10%"
                    x={['100%', '0%']}
                    color={colors.ebook.text}
                />
                <FeatureImageHead bigTextMiddle noPaddingBottom>
                    <EbookBigText color={themeColor} bigTextStroke={themeColor}>
                        COMPLEXITY
                    </EbookBigText>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <EbookFeatureImage
                            image={`${baseURL}assets/images/ebook/complexity-maping-image.jpg`}
                            noMarginBottom
                        />
                    </ScrollAnimation>
                </FeatureImageHead>
                <ContainerCompact>
                    <T2_2Heading id="target2-2">
                        <Row>
                            <Col sm="12">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookMainHeading
                                        color={colors.white['100']}
                                        size="small">
                                        Complexity Mapping
                                    </EbookMainHeading>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookMainPara
                                        color={colors.white['100']}
                                        seasonal>
                                        <p>
                                            <b>Insights to win it</b>
                                        </p>
                                        <p>
                                            Building on your wins and
                                            pinpointing areas for improvement in
                                            your seasonal CX aren’t possible
                                            without relevant data and insights.
                                            Beyond glide path planning, our BI
                                            services ensure you’re equipped with
                                            continuous quality assurance and
                                            robust performance management
                                            capabilities.
                                        </p>
                                    </EbookMainPara>
                                </ScrollAnimation>
                            </Col>
                            <Col sm="12">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <T1FeatureList>
                                        <EbookFeatureList
                                            list={target2_2FeatureList}
                                            oddBgColor={`${colors.white['100']}14`}
                                            color={colors.white['100']}
                                            seasonal
                                        />
                                    </T1FeatureList>
                                </ScrollAnimation>
                            </Col>
                            <Col sm="12">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookMainPara
                                        color={colors.white['100']}
                                        seasonal>
                                        <p>
                                            A good BI program connects the dots
                                            between operational teams and
                                            touchpoints, ensuring
                                            multi-functional alignment and
                                            communication. In order to do that,
                                            we’re always monitoring performance
                                            levels and making recommendations
                                            for real-time program adjustments.
                                            By analyzing interactions across the
                                            customer lifecycle, our BI services
                                            transform data into relevant
                                            insights that drive efficiencies in
                                            your processes and workflows.
                                        </p>
                                    </EbookMainPara>
                                </ScrollAnimation>
                            </Col>
                            <Col lg="6">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <T2List2>
                                        <li>Glide Path Creation</li>
                                        <li>Agent Quartile Management</li>
                                        <li>DSAT Analytics</li>
                                        <li>Value Stream Mapping</li>
                                    </T2List2>
                                </ScrollAnimation>
                            </Col>
                            <Col lg="6">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <T2List2>
                                        <li>Complexity Mapping</li>
                                        <li>Call Silence Ratio Analytics</li>
                                        <li>Dream Team Profiling</li>
                                    </T2List2>
                                </ScrollAnimation>
                            </Col>
                            <Col sm="12">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookMainPara
                                        color={colors.white['100']}
                                        seasonal>
                                        <p>
                                            By understanding areas for potential
                                            program enhancement and cost
                                            reduction, you can focus on
                                            improving the right parts of your
                                            contact center, increasing employee
                                            productivity, reducing costs and
                                            enhancing seasonal CX through
                                            insights into core business
                                            components, including:
                                        </p>
                                    </EbookMainPara>
                                </ScrollAnimation>
                            </Col>
                            <Col sm="12">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookFeatureBox
                                        image={`${baseURL}assets/images/ebook/t2-1_feature_4.png`}
                                        title="Agent Performance"
                                        titleColor={colors.white['100']}
                                        styleTwo>
                                        <EbookMainPara
                                            color={colors.white['100']}
                                            seasonal>
                                            <p>
                                                Understand the four quartiles of
                                                agents and their impact on site
                                                performance through DSAT review
                                                and feedback. Focus your efforts
                                                by tracking lowest-quartile
                                                agents and determining who is
                                                improving, regressing and
                                                staying flat.
                                            </p>
                                        </EbookMainPara>
                                    </EbookFeatureBox>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookFeatureBox
                                        image={`${baseURL}assets/images/ebook/t2-1_feature_5.png`}
                                        title="Customer behavior"
                                        titleColor={colors.white['100']}
                                        bgColor={`${colors.white['100']}14`}
                                        styleTwo>
                                        <EbookMainPara
                                            color={colors.white['100']}
                                            seasonal>
                                            <p>
                                                Discover triggers for customer
                                                contact and churn through
                                                behaviorally driven root cause
                                                analysis.
                                            </p>
                                        </EbookMainPara>
                                    </EbookFeatureBox>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookFeatureBox
                                        image={`${baseURL}assets/images/ebook/t2-1_feature_6.png`}
                                        title="Customer demographics"
                                        titleColor={colors.white['100']}
                                        styleTwo>
                                        <EbookMainPara
                                            color={colors.white['100']}
                                            seasonal>
                                            <p>
                                                Understand customer preferences
                                                and profiles based on their
                                                demographics.
                                            </p>
                                        </EbookMainPara>
                                    </EbookFeatureBox>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookFeatureBox
                                        image={`${baseURL}assets/images/ebook/t2-1_feature_7.png`}
                                        title="Demand"
                                        titleColor={colors.white['100']}
                                        bgColor={`${colors.white['100']}14`}
                                        styleTwo>
                                        <EbookMainPara
                                            color={colors.white['100']}
                                            seasonal>
                                            <p>
                                                Review how your sales ebb and
                                                flow during seasonal periods.
                                            </p>
                                        </EbookMainPara>
                                    </EbookFeatureBox>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookFeatureBox
                                        image={`${baseURL}assets/images/ebook/t2-1_feature_8.png`}
                                        title="Recruitment"
                                        titleColor={colors.white['100']}
                                        styleTwo>
                                        <EbookMainPara
                                            color={colors.white['100']}
                                            seasonal>
                                            <p>
                                                Analyze employee retention and
                                                spend.
                                            </p>
                                        </EbookMainPara>
                                    </EbookFeatureBox>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookFeatureBox
                                        image={`${baseURL}assets/images/ebook/t2-1_feature_9.png`}
                                        title="Quality:"
                                        titleColor={colors.white['100']}
                                        bgColor={`${colors.white['100']}14`}
                                        styleTwo>
                                        <EbookMainPara
                                            color={colors.white['100']}
                                            seasonal>
                                            <p>
                                                Regularly evaluate quality by
                                                analyzing interactions across
                                                the customer lifecycle.
                                            </p>
                                        </EbookMainPara>
                                    </EbookFeatureBox>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                    </T2_2Heading>
                </ContainerCompact>
            </ComplexitySection>

            <FeatureImageHead bigTextMiddle noPaddingBottom>
                <EbookBigText color={colors.technologyDropdown['box']}>
                    TRAINING
                </EbookBigText>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <EbookFeatureImage
                        image={`${baseURL}assets/images/ebook/proficiency-through-image.jpg`}
                        noMarginBottom
                    />
                </ScrollAnimation>
            </FeatureImageHead>

            <TargetSection id="target2-3" noPaddingBottom>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/t2-floating-image.jpg`}
                    width={30}
                    left="0%"
                    top="38%"
                    y={['30%', '-30%']}
                />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/t2-floating-image.jpg`}
                    width={30}
                    right="0%"
                    top="30.5%"
                    y={['-20%', '20%']}
                />
                <EbookFloatingLine
                    width="47%"
                    top="94.5%"
                    left="-20%"
                    x={['-100%', '0%']}
                    color={colors.ebook.seasonal.pink}
                />
                <EbookFloatingLine
                    width="40%"
                    top="89%"
                    right="-20%"
                    x={['100%', '-10%']}
                    color={themeColor2}
                />
                <EbookFloatingLine
                    width="47%"
                    top="96%"
                    right="-20%"
                    x={['100%', '-10%']}
                    color={themeColor}
                />
                <ContainerCompact>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookHeadingLine
                                    width="33%"
                                    top="85%"
                                    left="5%"
                                    color={colors.ebook.seasonal.pink}
                                    size="small">
                                    <EbookMainHeading size="small">
                                        Speed to Proficiency Through High-Tech
                                        Training
                                    </EbookMainHeading>
                                </EbookHeadingLine>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainPara seasonal>
                                    <p>
                                        Along with hiring reliable,
                                        customer-focused agents, winning
                                        customer service teams set themselves
                                        apart by accelerating speed to
                                        proficiency for their seasonal
                                        employees. Ensuring they’re onboarded,
                                        trained and up to speed as quickly as
                                        possible is necessary if you want to
                                        deliver the level of service customers
                                        expect, especially during peak seasons
                                        when call volumes skyrocket and even the
                                        most seasoned reps are put to the test.
                                    </p>
                                </EbookMainPara>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <ParaHeading>
                                    Leading CX training methodology
                                </ParaHeading>
                            </ScrollAnimation>
                            <EbookMainPara seasonal>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <p>
                                        ibex’s proprietary Training Innovation &
                                        Design Model takes a highly
                                        sophisticated and performance-driven
                                        approach to preparing talent for
                                        seasonal CX program launches. Our
                                        gold-standard tried and tested
                                        methodology for training leaders and
                                        agents quickly and effectively is based
                                        on extensive experience launching CX
                                        teams. Last year alone, we successfully
                                        launched nine new clients and managed
                                        seven simultaneous holiday peaks.
                                    </p>
                                    <p>
                                        At the heart of our world-class
                                        methodology are these three core
                                        components.
                                    </p>
                                </ScrollAnimation>
                            </EbookMainPara>
                        </Col>
                        <Col sm="12">
                            <EbookFeatureBox
                                image={`${baseURL}assets/images/ebook/t2-3_feature_1.jpg`}
                                title="Upfront leadership investment">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <IconParaWrapper>
                                        <img
                                            src={`${baseURL}assets/images/ebook/t2-3_feature1_1.svg`}
                                            alt="-"
                                        />
                                        <EbookMainPara seasonal>
                                            <p>
                                                In order to successfully ramp
                                                your seasonal CX, managers need
                                                to be ready to lead from day
                                                one. That&apos;s why we
                                                recommend the Wave 0 philosophy
                                                for launching seasonal CX
                                                programs. Wave 0 gets leaders
                                                leaned-in, invested in your
                                                brand and prepared to make
                                                smart, strategic decisions
                                                before the first agent is even
                                                hired.
                                            </p>
                                        </EbookMainPara>
                                    </IconParaWrapper>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <IconParaWrapper>
                                        <img
                                            src={`${baseURL}assets/images/ebook/t2-3_feature1_2.svg`}
                                            alt="-"
                                        />
                                        <EbookMainPara seasonal>
                                            <p>
                                                As part of Wave 0, all leaders,
                                                managers and support staff
                                                (e.g., QA, trainers) are
                                                hand-picked ahead of the program
                                                launch and complete training
                                                together as a group. By taking
                                                live calls, they get hands-on
                                                experience with real customers
                                                that supports a deep
                                                understanding of top call or
                                                chat drivers, common customer
                                                issues and resolutions, as well
                                                as the brand vision and mission.
                                            </p>
                                        </EbookMainPara>
                                    </IconParaWrapper>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <IconParaWrapper>
                                        <img
                                            src={`${baseURL}assets/images/ebook/t2-3_feature1_3.svg`}
                                            alt="-"
                                        />
                                        <EbookMainPara seasonal>
                                            <p>
                                                When it comes time to onboard
                                                the CX team, leaders are well
                                                equipped to guide their reports
                                                toward success. All leaders
                                                interact with their teams while
                                                agents are in training, so they
                                                have a strong foundation from
                                                the get-go, allowing you to
                                                quickly deliver performance
                                                that’s above your network
                                                average.
                                            </p>
                                        </EbookMainPara>
                                    </IconParaWrapper>
                                </ScrollAnimation>
                            </EbookFeatureBox>
                        </Col>
                    </Row>
                </ContainerCompact>
                <EbookQoute
                    bgColor={themeColor2}
                    authorImg={`${baseURL}assets/images/ebook/qoute-2_author.png`}
                    bigText1="EVERYONE"
                    // bigText2="FROM THE"
                    qouteText="Everyone from the CEO down to the contact center agent <br /> should know what it feels like to be a customer"
                    qouteColor={themeColor}
                    bigTextStroke={themeColor}
                    authorName="Blake Morgan, CX futurist, speaker and author"
                    authorNameColor={colors.ebook.text}
                />
                <ContainerCompact>
                    <Row>
                        <Col sm="12">
                            <EbookFeatureBox
                                image={`${baseURL}assets/images/ebook/t2-3_feature_2.png`}
                                title="Experiential learning">
                                <EbookMainPara seasonal>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <p>
                                            Onboarding and training are
                                            essential components of an agent’s
                                            success and a poor experience can be
                                            harmful to their performance. The
                                            potential for failure is even
                                            greater in a remote work
                                            environment, where, without the
                                            right virtual training tools, a rep
                                            might end up completely unprepared
                                            to interact with your customers.
                                            <br /> <br />
                                            Our progressive experiential
                                            learning approach is guided by three
                                            key steps designed to provide
                                            advocates with real-world, hands-on
                                            experience without real-world
                                            consequences:
                                        </p>
                                    </ScrollAnimation>
                                </EbookMainPara>
                                <T2FeatureList>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <T2ListItem>
                                            <ListTitle>
                                                Show me
                                                <FaArrowRight
                                                    color={themeColor}
                                                />
                                            </ListTitle>
                                            <ListContent>
                                                Learners observe best practices
                                                in a call while a virtual
                                                facilitator explains each step.
                                            </ListContent>
                                        </T2ListItem>
                                    </ScrollAnimation>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <T2ListItem>
                                            <ListTitle>
                                                Guide me
                                                <FaArrowRight
                                                    color={themeColor}
                                                />
                                            </ListTitle>
                                            <ListContent>
                                                Learners get practice in a
                                                controlled environment to master
                                                call drivers and reduce handling
                                                time while ensuring accuracy.
                                            </ListContent>
                                        </T2ListItem>
                                    </ScrollAnimation>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <T2ListItem>
                                            <ListTitle>
                                                Let me
                                                <FaArrowRight
                                                    color={themeColor}
                                                />
                                            </ListTitle>
                                            <ListContent>
                                                Learners navigate through the
                                                process on their own for
                                                certification.
                                            </ListContent>
                                        </T2ListItem>
                                    </ScrollAnimation>
                                </T2FeatureList>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookMainPara seasonal>
                                        <p>
                                            We regularly review the program
                                            curriculum to ensure top call and
                                            chat drivers are reflected in the
                                            training content. This allows us to
                                            focus and accelerate training for
                                            seasonal ramps and ensure speed to
                                            green.
                                        </p>
                                    </EbookMainPara>
                                </ScrollAnimation>
                            </EbookFeatureBox>
                        </Col>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookFeatureBox
                                    image={`${baseURL}assets/images/ebook/t2-3_feature_3.png`}
                                    title="Training technology">
                                    <EbookMainPara seasonal>
                                        <p>
                                            Our process for maximizing
                                            proficiency among new hires during
                                            seasonal ramps integrates
                                            leading-edge eLearning technologies.
                                        </p>
                                    </EbookMainPara>
                                </EbookFeatureBox>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12">
                            <T2FeatureList styleTwo>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <T2ListItem>
                                        <ListTitle>
                                            Simulated training environments
                                            <FaArrowRight color={themeColor} />
                                        </ListTitle>
                                        <ListContent>
                                            provide agents with a progressive
                                            hands-on approach to solving top
                                            customer concerns through scenarios
                                            that help learners master top call
                                            drivers. Learners are exposed to
                                            lifelike simulations of
                                            customer-agent conversations that
                                            make the learning experience as
                                            comprehensive as possible. This
                                            approach reduces time to proficiency
                                            and promotes continuous improvement
                                            for bottom-quartile performers.
                                        </ListContent>
                                    </T2ListItem>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <T2ListItem>
                                        <ListTitle>
                                            Micro-learning
                                            <FaArrowRight color={themeColor} />
                                        </ListTitle>
                                        <ListContent>
                                            allows new hires to grasp concepts
                                            in easy-to-digest, 5-7-minute online
                                            courses that target specific
                                            learning objectives and improve
                                            retention.
                                        </ListContent>
                                    </T2ListItem>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <T2ListItem>
                                        <ListTitle>
                                            Gamification
                                            <FaArrowRight color={themeColor} />
                                        </ListTitle>
                                        <ListContent>
                                            of the training experience
                                            incentivizes learners to build
                                            positive behaviors using rewards
                                            (e.g., gold starts, miles, points,
                                            badges) to build engagement and
                                            improve performance.
                                        </ListContent>
                                    </T2ListItem>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <T2ListItem>
                                        <ListTitle>
                                            E-Learning
                                            <FaArrowRight color={themeColor} />
                                        </ListTitle>
                                        <ListContent>
                                            provides highly interactive and
                                            customizable courses that maximize
                                            employee development and engagement
                                            goals. Learners can access modules
                                            on multiple platforms—desktop or
                                            mobile—ensuring ease, accessibility
                                            and analysis.
                                        </ListContent>
                                    </T2ListItem>
                                </ScrollAnimation>
                            </T2FeatureList>
                        </Col>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainPara seasonal>
                                    <p>
                                        <b>
                                            Mini Case Study: Top-notch training
                                            for the Leading Craft Retailer in
                                            North America:
                                        </b>
                                    </p>
                                </EbookMainPara>
                            </ScrollAnimation>
                        </Col>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <T2StatsWrapper>
                                {T2StatsList1 &&
                                    T2StatsList1.length > 0 &&
                                    T2StatsList1.map((statItem, index) => {
                                        return (
                                            <T2StatsList lg="6" key={index}>
                                                <StatLabel>
                                                    {statItem.stat}
                                                </StatLabel>
                                                <StatText>
                                                    {statItem.text}
                                                </StatText>
                                            </T2StatsList>
                                        );
                                    })}
                            </T2StatsWrapper>
                        </ScrollAnimation>
                    </Row>
                </ContainerCompact>
                <ContainerCompact>
                    <FeatureImageHead>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <EbookFeatureImage
                                image={`${baseURL}assets/images/ebook/digital-savvy-image.jpg`}
                                noMarginTop
                                noMarginBottom
                            />
                        </ScrollAnimation>
                    </FeatureImageHead>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookHeadingLine
                                    width="33%"
                                    top="70%"
                                    right="40%"
                                    color={themeColor2}>
                                    <EbookMainHeading>
                                        Training digital-savvy talent
                                    </EbookMainHeading>
                                </EbookHeadingLine>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainPara seasonal>
                                    <p>
                                        Delivering strong seasonal CX also
                                        increasingly means nailing the digital
                                        experience—particularly as more shoppers
                                        migrate to eCommerce. Ensuring your CX
                                        is catered to digital customers’
                                        expectations and behavior is only
                                        possible if you have customer-centric,
                                        tech-fluent agents to make it happen.
                                    </p>
                                </EbookMainPara>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <T1QualityText styleTwo>
                                    <EbookMediumHeading>
                                        [Companies should focus on] up-skilling
                                        support agents, turning them into
                                        digital customer service
                                        assistants—hiring people and training
                                        them to let them master conversations
                                        across digital channels.
                                    </EbookMediumHeading>
                                    <EbookMainPara centerAligned seasonal>
                                        Paolo Fabrizio, digital customer service
                                        <br />
                                        consultant, trainer and speaker
                                        <Sup
                                            href="https://www.pwc.com/us/en/services/consulting/library/consumer-intelligence-series/future-of-customer-experience.html"
                                            rel="noreferer noopener"
                                            target="_blank">
                                            8
                                        </Sup>
                                    </EbookMainPara>
                                </T1QualityText>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainPara seasonal noLetterSpacing>
                                    At ibex, our expert training model produces
                                    top-tier digitally fluent agents for fast
                                    seasonal execution. Through training that’s
                                    entirely digital-first, virtual and
                                    mobile-enabled, we set them up to deliver
                                    great experiences for your customers. Our
                                    engaging, interactive and purposeful
                                    employee onboarding and training process
                                    incorporates experiential learning,
                                    scenario-based methodology, role plays and
                                    guided practice that is quickly applied on
                                    the job to support fast proficiency.
                                </EbookMainPara>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </ContainerCompact>
            </TargetSection>

            <FeatureImageHead noPaddingBottom>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <EbookFeatureImage
                        image={`${baseURL}assets/images/ebook/staffing-up-image.jpg`}
                        noMarginBottom
                    />
                </ScrollAnimation>
            </FeatureImageHead>

            <TargetSection id="target2-4">
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/t1-floating-image.jpg`}
                    width={36}
                    left="0%"
                    top="4%"
                    y={['0%', '20%']}
                />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/t2-floating-image2.jpg`}
                    height={12}
                    right="23%"
                    top="92.8%%"
                    x={['20%', '0%']}
                />
                <ContainerCompact>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookHeadingLine
                                    width="32%"
                                    top="43%"
                                    left="10%"
                                    color={themeColor2}
                                    size="small">
                                    <EbookMainHeading size="small">
                                        Staffing Up Strategically: Integrated{' '}
                                        <br />
                                        Workforce Management
                                    </EbookMainHeading>
                                </EbookHeadingLine>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12">
                            <EbookMainPara seasonal>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <p>
                                        When staffing up for peak season,
                                        success isn’t just strong recruiting and
                                        training. It’s also highly dependent on
                                        how efficiently you run your contact
                                        center. In other words, how well can you
                                        rise to the challenge of maintaining
                                        service levels while keeping costs under
                                        control?
                                    </p>
                                    <p>
                                        Through integrated workforce management
                                        (WFM) processes, ibex helps you optimize
                                        your team’s productivity through
                                        accurate forecasting and smart
                                        scheduling. By developing strategic
                                        partnership with our clients, we find
                                        creative solutions to staffing
                                        challenges and provide comprehensive
                                        expertise in WFM support for all
                                        channels and call types.
                                    </p>
                                    <p>
                                        <b>
                                            The science of maximum performance
                                        </b>
                                    </p>
                                    <p>
                                        With a WFM approach, you can maximize
                                        agent productivity by anticipating the
                                        correct volume of transactions and
                                        scheduling the right number of agents
                                        with the right skill sets at the right
                                        time using these key elements:
                                    </p>
                                </ScrollAnimation>
                            </EbookMainPara>
                        </Col>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <IconParaWrapper styleTwo>
                                    <img
                                        src={`${baseURL}assets/images/ebook/t2-4_feature1_1.svg`}
                                        alt="-"
                                    />
                                    <EbookMainPara
                                        spanColor={themeColor}
                                        seasonal>
                                        <p>
                                            <span>Forecasting:</span> Using a
                                            scientific approach with
                                            cutting-edge technologies and
                                            methodology, we build highly
                                            accurate long- and short-term term
                                            forecasts. Our highly efficient
                                            agent schedules align to interval
                                            requirements, factoring in interval
                                            forecasted volume, AHT and
                                            shrinkage.
                                        </p>
                                    </EbookMainPara>
                                </IconParaWrapper>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <IconParaWrapper styleTwo>
                                    <img
                                        src={`${baseURL}assets/images/ebook/t2-4_feature1_2.svg`}
                                        alt="-"
                                    />
                                    <EbookMainPara
                                        spanColor={themeColor}
                                        seasonal>
                                        <p>
                                            <span>Workforce Planning:</span>
                                            &nbsp; Working behind the scenes and
                                            making sure everything goes off
                                            without a hitch, our workforce
                                            planners and schedulers maintain
                                            hiring plans, develop rolling
                                            forecasts, generate schedules,
                                            maintain rosters, and keep
                                            everything running smoothly on a
                                            weekly basis.
                                        </p>
                                    </EbookMainPara>
                                </IconParaWrapper>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <IconParaWrapper styleTwo>
                                    <img
                                        src={`${baseURL}assets/images/ebook/t2-4_feature1_3.svg`}
                                        alt="-"
                                    />
                                    <EbookMainPara
                                        spanColor={themeColor}
                                        seasonal>
                                        <p>
                                            <span>
                                                Future-focused Insights:
                                            </span>
                                            &nbsp; Workforce planners also
                                            provide guidance on staffing levels
                                            to achieve all critical metrics
                                            while balancing productivity goals.
                                            Keeping a close eye on emerging
                                            trends and patterns that can be
                                            applied to future forecasting and
                                            scheduling, they document the
                                            details behind the data to ensure we
                                            continue to meet and exceed service
                                            expectations.
                                        </p>
                                    </EbookMainPara>
                                </IconParaWrapper>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainPara seasonal>
                                    <p>
                                        <b>Going the distance</b>
                                    </p>
                                    <p>
                                        When it comes to meeting and exceeding
                                        service goals, the command center team
                                        is the “last mile.” Responsible for the
                                        tactical execution of the workforce
                                        plan, they take care of live, ongoing
                                        performance optimization, including the
                                        following:
                                    </p>
                                </EbookMainPara>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <IconParaWrapper styleTwo>
                                    <img
                                        src={`${baseURL}assets/images/ebook/t2-4_feature1_4.svg`}
                                        alt="-"
                                    />
                                    <EbookMainPara
                                        spanColor={themeColor}
                                        seasonal>
                                        <p>
                                            <span>Real-time adjustments:</span>
                                            &nbsp; The command center engages in
                                            exhaustive real-time monitoring and
                                            forecasting that they use to adjust
                                            staffing trajectories and achieve
                                            service. By monitoring real-time
                                            call volume and scheduling
                                            conformance, they make the most of
                                            staffing resources for the strongest
                                            performance possible.
                                        </p>
                                    </EbookMainPara>
                                </IconParaWrapper>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <IconParaWrapper styleTwo>
                                    <img
                                        src={`${baseURL}assets/images/ebook/t2-4_feature1_5.svg`}
                                        alt="-"
                                    />
                                    <EbookMainPara
                                        spanColor={themeColor}
                                        seasonal>
                                        <p>
                                            <span>
                                                Balancing cost and quality:
                                            </span>
                                            &nbsp; Real-time analysts work
                                            efficiently to balance service
                                            levels for the contact center across
                                            programs, internal locations, lines
                                            of business and products. They
                                            manage performance according to
                                            service level agreements (SLAs) and
                                            efficiency goals, focusing at all
                                            times on creating positive
                                            experiences for customers while
                                            maintaining a productive contact
                                            center.
                                        </p>
                                    </EbookMainPara>
                                </IconParaWrapper>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainPara seasonal>
                                    <p>
                                        <b>
                                            ibex Workforce Management: Millions
                                            of satisfied customers
                                        </b>
                                    </p>
                                </EbookMainPara>
                            </ScrollAnimation>
                        </Col>
                        <T2StatsWrapper>
                            {T2StatsList2 &&
                                T2StatsList2.length > 0 &&
                                T2StatsList2.map((statItem, index) => {
                                    return (
                                        <T2StatsList lg="6" key={index}>
                                            <StatLabel>
                                                {statItem.stat}
                                            </StatLabel>
                                            <StatText>{statItem.text}</StatText>
                                        </T2StatsList>
                                    );
                                })}
                        </T2StatsWrapper>
                    </Row>
                </ContainerCompact>
            </TargetSection>

            <EbookDoubleBgSection
                firstBg={colors.technologyDropdown['box']}
                firstBgHeight="50%"
                secondBg={themeColor3}
                noPadding>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <EbookFeatureImage
                        noBorderBottom={true}
                        image={`${baseURL}assets/images/ebook/choreographing-image.png`}
                        noMarginBottom
                        noMarginTop
                    />
                </ScrollAnimation>
            </EbookDoubleBgSection>

            <ChoreographingSection id="target2-5">
                <EbookFloatingLine
                    width="47%"
                    bottom="23.5%"
                    right="-20%"
                    x={['20%', '-50%']}
                    color={themeColor2}
                />
                <EbookFloatingLine
                    width="47%"
                    top="47.5%"
                    left="-20%"
                    x={['-30%', '30%']}
                    color={themeColor2}
                />
                <EbookFloatingLine
                    width="30%"
                    bottom="4%"
                    left="-10%"
                    x={['-80%', '10%']}
                    color={colors.ebook.seasonal['pink']}
                />
                <ContainerCompact>
                    <Row>
                        <Col sm="12">
                            <T1_2Heading>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookHeadingLine
                                        width="34%"
                                        top="85%"
                                        right="15%"
                                        color={colors.ebook.seasonal['pink']}
                                        size="small">
                                        <EbookMainHeading size="small">
                                            Choreographing a Seamless Ramp
                                        </EbookMainHeading>
                                    </EbookHeadingLine>
                                </ScrollAnimation>
                                <EbookMainPara seasonal>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <p>
                                            With so many moving parts to
                                            planning, launching and fine-tuning
                                            your seasonal ramp, there’s one key
                                            component that connects all the
                                            pieces of the puzzle and brings the
                                            full picture into view: the project
                                            management office (PMO) team.
                                            <br />
                                            Our world-class project managers
                                            bring everything together,
                                            skillfully managing the intricacies
                                            and complexities of the seasonal
                                            ramp so that, when peak season rolls
                                            around, your team is set up for
                                            success and ready to handle any
                                            customer interaction.
                                        </p>
                                        <p>
                                            <b>Reduce Client Effort</b>
                                        </p>
                                        <p>
                                            The number-one goal of the PMO team
                                            is to reduce the level of effort
                                            required for our client teams to get
                                            up and running. Project managers
                                            streamline communication between our
                                            organization and yours, execute the
                                            project at all levels, at all times,
                                            and keep everything seamlessly
                                            integrated, ensuring we get it right
                                            the first time.
                                        </p>
                                        <p>
                                            Above all, they choreograph a
                                            complicated dance and make it look
                                            easy, managing all activities to
                                            ensure success across teams that
                                            support each other, including IT
                                            (tools, technology, data), workforce
                                            management (staffing, scheduling,
                                            forecasting), recruiting (hiring
                                            agents and managers), training
                                            (onboarding, learning, development),
                                            operations (across client and ibex
                                            teams), quality (calibration, CX)
                                            and facilities (workspace, client
                                            branding).
                                        </p>
                                        <p>
                                            <b>
                                                End-to-end coordination and
                                                oversight
                                            </b>
                                        </p>
                                        <p>
                                            Project managers keep synergies
                                            flowing from start to finish and
                                            beyond through the following phases.
                                        </p>
                                    </ScrollAnimation>
                                </EbookMainPara>
                            </T1_2Heading>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookFeatureBoxList
                                    number="01"
                                    title="Project Initiation">
                                    <EbookMainPara seasonal>
                                        <p>
                                            At the outset, the PMO team takes
                                            care of project kickoff, due
                                            diligence sessions, knowledge
                                            transfer, client culture immersion,
                                            risk mitigation and project
                                            planning.
                                            <br />
                                            <br />
                                            To ensure everyone is on the same
                                            page when it comes to project scope,
                                            timing and next steps, they conduct
                                            kickoffs internally and with the
                                            client team. And they oversee all
                                            the steps required for the project
                                            to get off to the right start,
                                            including:
                                        </p>
                                    </EbookMainPara>
                                </EbookFeatureBoxList>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookArrowList>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            in-depth project review
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>budget approval</ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            governance model development
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>IT solutioning</ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>risk mitigation</ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>ramp planning</ListText>
                                    </li>
                                </EbookArrowList>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookFeatureBoxList
                                    number="02"
                                    title="Planning">
                                    <EbookMainPara seasonal>
                                        <p>
                                            Long before a single agent is hired,
                                            project managers coordinate
                                            essential elements of the ramp and
                                            lay the groundwork for a successful
                                            launch, including:
                                        </p>
                                    </EbookMainPara>
                                </EbookFeatureBoxList>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookArrowList>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            finalizing the IT solution
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>planning training</ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            identifying management ratio gaps
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            designing change management
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            establishing WFM processes
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            ensuring facilities readiness and
                                            branding
                                        </ListText>
                                    </li>
                                </EbookArrowList>
                            </ScrollAnimation>
                        </Col>

                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookFeatureBoxList
                                    number="03"
                                    title="Execution">
                                    <EbookMainPara seasonal>
                                        <p>
                                            When it comes time to implement,
                                            project managers are crucial to
                                            ensuring everything goes smoothly,
                                            including:
                                        </p>
                                    </EbookMainPara>
                                </EbookFeatureBoxList>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookArrowList>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            security and IT implementation
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            training room readiness
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            Wave 0 and support staff onboarding
                                            and certification
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>agent training</ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            production floor readiness
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            business continuity plan (BCP)
                                            reporting setup
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            end-to-end IT testing
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>go-live readiness</ListText>
                                    </li>
                                </EbookArrowList>
                            </ScrollAnimation>
                        </Col>

                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookFeatureBoxList
                                    number="04"
                                    title="Monitoring">
                                    <EbookMainPara seasonal>
                                        <p>
                                            The PMO team’s work continues during
                                            and after the launch too,
                                            coordinating and monitoring a
                                            variety of moving parts, including:
                                        </p>
                                    </EbookMainPara>
                                </EbookFeatureBoxList>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookArrowList>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            on-the-job training (OJT)
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>performance reports</ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            client feedback collection
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            quality reinforcements
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            ongoing agent development.
                                        </ListText>
                                    </li>
                                </EbookArrowList>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookFeatureBoxList
                                    number="05"
                                    title="Business as Usual">
                                    <EbookMainPara seasonal>
                                        <p>
                                            During business-as-usual, project
                                            managers continue ensuring the ramp
                                            is successful over the long term,
                                            coordinating these key elements and
                                            more:
                                        </p>
                                    </EbookMainPara>
                                </EbookFeatureBoxList>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookArrowList>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>business review</ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>change management</ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            continuous training needs
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            regular client updates
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            developmental and performance
                                            enhancement
                                        </ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>quality monitoring</ListText>
                                    </li>
                                    <li>
                                        <FaArrowRight />
                                        <ListText>
                                            yearly compliance training
                                        </ListText>
                                    </li>
                                </EbookArrowList>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </ContainerCompact>
            </ChoreographingSection>

            <TargetSection id="target2-6" style={{ display: 'none' }}>
                <EbookFloatingLine
                    width="20%"
                    top="10%"
                    right="0"
                    x={['20%', '-30%']}
                    color={themeColor}
                />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/t1-floating-image.jpg`}
                    width={36}
                    height={1050}
                    right="0"
                    top="20%"
                    y={['-10%', '30%']}
                />
                <FeatureImageHead noPaddingBottom>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <EbookFeatureImage
                            image={`${baseURL}assets/images/ebook/staffing-up-image.jpg`}
                            noMarginBottom
                            noMarginTop
                        />
                    </ScrollAnimation>
                </FeatureImageHead>
                <ContainerCompact>
                    <Row>
                        <Col sm="12">
                            <T1_2Heading>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookHeadingLine
                                        width="37%"
                                        top="80%"
                                        right="20%"
                                        color={themeColor2}>
                                        <EbookMainHeading>
                                            Technology to Power <br />
                                            Seasonal CX at Home
                                        </EbookMainHeading>
                                    </EbookHeadingLine>
                                </ScrollAnimation>
                                <EbookMainPara seasonal>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <p>
                                            Solving customers’ problems is at
                                            the heart of what we do in the
                                            contact center. During the seasonal
                                            rush, these challenges are
                                            compounded by the high volume of
                                            customer interactions and the
                                            heightened emotions that come along
                                            with high-stress shopping seasons.
                                        </p>
                                        <p>
                                            On top of the regular seasonal
                                            stressors, the new world of work
                                            brought on by the pandemic demands
                                            that companies equip their agents
                                            for consistent performance whether
                                            they’re working in-center or at
                                            home. Enabling them to succeed
                                            depends on having the right digital
                                            tools to support them and monitor
                                            their performance in real time, no
                                            matter where they’re working.
                                        </p>
                                        <p>
                                            At ibex, we use purpose-driven
                                            technology to ensure CX teams can
                                            deliver a seamless experience from
                                            anywhere.
                                        </p>
                                        <p>
                                            <b>Integrated technology center</b>
                                        </p>
                                        <p>
                                            In addition to tailormade tools for
                                            a distributed contact center
                                            workforce, our agents deliver
                                            exceptional CX through Wave X—a
                                            smart, centralized hub for quickly
                                            and proficiently helping customers.
                                            Agents and managers are empowered to
                                            achieve better performance than ever
                                            during the busiest time of the year
                                            through access to one, easy-to-use,
                                            integrated interface rather than a
                                            handful of disparate tools.
                                        </p>
                                        <p>
                                            Wave X technology combines
                                            third-party software and
                                            applications with custom-built tools
                                            that level up the experience for
                                            customers—and, at the same time,
                                            provide the right support to
                                            digitally native employees who have
                                            a high level of digital fluency.
                                        </p>
                                        <p>
                                            By accessing a 360-degree view of
                                            the customer’s needs, agents are
                                            able to deliver unified CX and
                                            contextualized, relevant support on
                                            any channel. And through core
                                            activities like click-to-contact
                                            digital marketing, customer
                                            interaction management, and complete
                                            interaction analytics, Wave X gives
                                            agents have the tools they need to
                                            perform and continuously improve.
                                        </p>
                                    </ScrollAnimation>
                                </EbookMainPara>
                            </T1_2Heading>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookFeatureBox
                                    image={`${baseURL}assets/images/ebook/ibex-score.jpg`}
                                    title="ibex Score">
                                    <EbookMainPara seasonal>
                                        <p>
                                            Employees can only improve if they
                                            know how they’re doing right now.
                                            Our agent dashboard, ibex Score,
                                            connects agent performance with a
                                            numbered score. To create a little
                                            healthy competition, the interface
                                            highlights agent performance against
                                            their peers, with gamification and
                                            incentives built into the system to
                                            motivate them even further.
                                        </p>
                                    </EbookMainPara>
                                </EbookFeatureBox>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookFeatureBox
                                    image={`${baseURL}assets/images/ebook/ibex-round.jpg`}
                                    title="ibex Round">
                                    <EbookMainPara seasonal>
                                        <p>
                                            Supervising agents remotely can be
                                            challenging, but ibex Round takes
                                            the process of moving from station
                                            to station virtual. As a one-stop
                                            platform, it lets supervisors and
                                            operational managers review multiple
                                            agents’ screens and audio at the
                                            same time. Supervisors can view any
                                            agent’s screen or several at once
                                            and directly message any agent, live
                                            during a call. The platform also
                                            supports compliance, QA and coaching
                                            teams in monitoring agent activity
                                            and providing feedback.
                                        </p>
                                    </EbookMainPara>
                                </EbookFeatureBox>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookFeatureBox
                                    image={`${baseURL}assets/images/ebook/ibex-inspire.jpg`}
                                    title="ibex Inspire">
                                    <EbookMainPara seasonal>
                                        <p>
                                            Just because employees are working
                                            remotely doesn’t mean critical
                                            activities like feedback, coaching
                                            and performance management should
                                            fall by the wayside. ibex Inspire is
                                            a centralized coaching management
                                            tool that integrates with Round and
                                            Score to let supervisors actively
                                            monitor agent performance, develop
                                            action plans to correct issues, and
                                            build proper behaviors into the
                                            program. The program keeps
                                            supervisors accountable, too, by
                                            ensuring they provide coaching to
                                            each agent at least once each week.
                                        </p>
                                    </EbookMainPara>
                                </EbookFeatureBox>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookFeatureBox
                                    image={`${baseURL}assets/images/ebook/ibex-score.jpg`}
                                    title="Virtual floor management">
                                    <EbookMainPara seasonal>
                                        <p>
                                            Keeping customers satisfied often
                                            takes a team effort. With the ibex
                                            Virtual Floor Management System,
                                            contact center productivity gets a
                                            boost, even when agents are working
                                            remotely, by giving them real-time
                                            assistance through a web-based
                                            application. If an agent is having
                                            trouble responding to a customer’s
                                            inquiry, they can request assistance
                                            from supervisory agents known as
                                            “floaters” or subject matter
                                            experts. A floater can then
                                            communicate with the agent remotely
                                            to help resolve the issue. This
                                            streamlined system improves agent
                                            productivity, reduces AHT and
                                            ultimately increases customer
                                            satisfaction.
                                        </p>
                                    </EbookMainPara>
                                </EbookFeatureBox>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </ContainerCompact>
            </TargetSection>

            <CaseStudyHead noPaddingBottom>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <EbookFeatureImage
                        image={`${baseURL}assets/images/ebook/case-studies-image.jpg`}
                        noMarginBottom
                    />
                </ScrollAnimation>
            </CaseStudyHead>

            <TargetSection id="target3">
                <ContainerCompact>
                    <Row>
                        <Col sm="12">
                            <T1_2Heading>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookHeadingLine
                                        width="35%"
                                        top="50%"
                                        left="16%"
                                        color={themeColor2}>
                                        <EbookMainHeading>
                                            Case Studies
                                        </EbookMainHeading>
                                    </EbookHeadingLine>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <ParaHeading>Postmates</ParaHeading>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookMainPara seasonal>
                                        <p>
                                            Postmates is a hyper-growth company
                                            that offers local delivery of
                                            restaurant-prepared meals and other
                                            goods. With more than 500,000 fleet
                                            members and 600,000 merchants
                                            serving 80% of US households across
                                            all 50 states, they needed an
                                            experienced CX outsourcer and
                                            consultant to deliver comprehensive
                                            customer, technology and payments
                                            support. <br /> <br />
                                            In the midst of a complex delivery
                                            environment and dramatic volume
                                            spikes due to COVID-19, ibex helped
                                            Postmates launch intelligently, get
                                            to proficiency fast, and deliver
                                            expert peak and seasonal support to
                                            the consumer, logistics, and
                                            retail/restaurant markets.
                                        </p>
                                    </EbookMainPara>
                                </ScrollAnimation>
                            </T1_2Heading>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainPara seasonal>
                                    <p>
                                        <b>Results</b>
                                    </p>
                                </EbookMainPara>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <ResultList>
                                    <li>
                                        <ResultPercentage>#1</ResultPercentage>
                                        <ListText>
                                            Named #1 vendor in client network
                                        </ListText>
                                    </li>
                                    <li>
                                        <ResultPercentage>90%</ResultPercentage>
                                        <ListText>
                                            Achieved 90% CSAT and 85% QA
                                            attainment
                                        </ListText>
                                    </li>
                                    <li>
                                        <ResultPercentage>$1M</ResultPercentage>
                                        <ListText>
                                            Saved $1M annually by identifying a
                                            reporting glitch driving AHT
                                            irregularities
                                        </ListText>
                                    </li>
                                    <li>
                                        <ResultPercentage>
                                            100%
                                        </ResultPercentage>
                                        <ListText>
                                            Executed 100% virtual training,
                                            hitting 90-day pilot goals in half
                                            the time
                                        </ListText>
                                    </li>
                                    <li>
                                        <ResultPercentage>
                                            <FaArrowUp />
                                        </ResultPercentage>
                                        <ListText>
                                            Scaled from 20 to 200 FTEs by
                                            year-end with planned expansion to a
                                            second site
                                        </ListText>
                                    </li>
                                </ResultList>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <CasestudyButton>
                                    {/* <a
                                        href={`${baseURLPage}resources/case-studies/postmates-managing-cx-volume`}>
                                        Read the full case study.
                                    </a> */}
                                </CasestudyButton>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12">
                            <DividerBorder></DividerBorder>
                        </Col>
                    </Row>
                </ContainerCompact>
                <CaseStudyHead>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <EbookFeatureImage
                            image={`${baseURL}assets/images/ebook/north-america-images.jpg`}
                        />
                    </ScrollAnimation>
                </CaseStudyHead>
                <ContainerCompact>
                    <Row>
                        <Col sm="12">
                            <T1_2Heading>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <ParaHeading>
                                        North America’s Largest Crafts Retailer
                                    </ParaHeading>
                                </ScrollAnimation>
                                <EbookMainPara seasonal>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <p>
                                            The largest arts and crafts
                                            specialty retailer in North America
                                            operates more than 1,250 stores in
                                            49 US states and across Canada.
                                            Rebounding from a difficult 2019
                                            holiday season, compounded by a
                                            volume shift to digital support
                                            driven by COVID-19, the DIY retail
                                            giant knew the success of its
                                            fast-approaching 2020 season
                                            depended on securing an experienced
                                            seasonal CX partner.
                                        </p>
                                        <p>
                                            ibex answered the call to deliver
                                            industry-leading forecasting,
                                            planning, and execution of a
                                            seasonal ramp with low AHT, low
                                            attrition, and expert digital
                                            channel management.
                                        </p>
                                    </ScrollAnimation>
                                </EbookMainPara>
                            </T1_2Heading>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <ParaHeading>Results</ParaHeading>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <ResultList>
                                    <li>
                                        <ResultPercentage>444</ResultPercentage>
                                        <ListText>
                                            Deployed 444 seasonal agents, in
                                            center and at home
                                        </ListText>
                                    </li>
                                    <li>
                                        <ResultPercentage>91%</ResultPercentage>
                                        <ListText>
                                            Achieved remarkable 91% success with
                                            complex agent training rollout
                                        </ListText>
                                    </li>
                                    <li>
                                        <ResultPercentage>
                                            450K
                                        </ResultPercentage>
                                        <ListText>
                                            Delivered 450K digital and voice
                                            interactions during one-month
                                            seasonal stretch
                                        </ListText>
                                    </li>
                                    <li>
                                        <ResultPercentage>
                                            3.3%
                                        </ResultPercentage>
                                        <ListText>
                                            Maintained 3.3% attrition rate and
                                            3% absenteeism for seasonal agents,
                                            well below industry averages
                                        </ListText>
                                    </li>
                                    <li>
                                        <ResultPercentage>
                                            <FaArrowDown />
                                        </ResultPercentage>
                                        <ListText>
                                            Reduced abandon rate by 4 points
                                            over previous vendor
                                        </ListText>
                                    </li>
                                </ResultList>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <CasestudyButton>
                                    {/* <a
                                        href={`${baseURLPage}resources/case-studies/fast-seasonal-retailer-ramp-cx`}>
                                        Read the full case study.
                                    </a> */}
                                </CasestudyButton>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </ContainerCompact>
            </TargetSection>

            <ConclusionSection as="section" id="target4">
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/t1-floating-image.jpg`}
                    width={36}
                    height={589}
                    left="0"
                    top="-53%"
                    y={['10%', '-30%']}
                />
                <EbookFloatingLine
                    width="27%"
                    top="19%"
                    left="0"
                    x={['-30%', '-10%']}
                    color={colors.ebook.seasonal.main}
                />
                <EbookFloatingLine
                    width="23%"
                    bottom="19%"
                    right="0"
                    x={['30%', '0%']}
                    color={colors.ebook.seasonal.pink}
                />
                <ContainerCompact>
                    <Row>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainHeading>Conclusion</EbookMainHeading>
                            </ScrollAnimation>
                            <EbookMainPara seasonal>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <p>
                                        There’s a lot riding on your seasonal CX
                                        efforts but with careful planning and
                                        the right expertise, you can make sure
                                        every peak season is your best
                                        performance yet.
                                    </p>
                                    <p>
                                        Beyond staying afloat while your support
                                        team gets inundated with inbound
                                        traffic, making the most of the seasonal
                                        rush is about harnessing customer demand
                                        as an incredible opportunity to drive
                                        better business outcomes.
                                    </p>
                                    <p>
                                        With fully integrated cross-functional
                                        teams working in lockstep, you can
                                        ensure proficient forecasting, hiring,
                                        training, implementation and execution.
                                        And with the right CX partner providing
                                        expertise, guiding your path and helping
                                        you launch successfully, the peak
                                        results will speak for themselves.
                                    </p>
                                </ScrollAnimation>
                            </EbookMainPara>
                        </Col>
                    </Row>
                </ContainerCompact>
            </ConclusionSection>
        </Layout>
    );
};

const TargetSection = styled.section`
    position: relative;
    padding-top: ${(props) => props.noPaddingTop && '0 !important'};
    padding-bottom: ${(props) => props.noPaddingBottom && '0 !important'};
`;

const ConclusionSection = styled(FlexCentered)`
    background-color: ${colors.ebook['text']};
    box-sizing: border-box;
    h2 {
        color: ${colors.white['100']};
        ${Media('xlscreens')} {
            margin: 2vw 0 4vw;
        }
    }
    p {
        color: ${colors.white['100']};
    }
`;
const DividerBorder = styled.div`
    width: 100%;
    height: 1px;
    margin: 100px 0;
    background-color: ${colors.ebook['text']};
    ${Media('xlscreens')} {
        margin: 4.2vw 0 0;
    }
    ${Media('tablet')} {
        margin: 50px 0 100px;
    }
`;
const CasestudyButton = styled.div`
    text-align: center;
    margin: 100px 0;
    ${Media('xlscreens')} {
        margin: 5.2vw 0 4vw;
    }
    a {
        background-color: ${colors.ebook.seasonal['main2']};
        padding: 20px 40px;
        border-radius: 10px;
        font-size: 24px;
        font-family: ${gilroyMedium};
        color: ${colors.ebook.seasonal['main']};
        ${Media('xlscreens')} {
            font-size: 1.25vw;
            padding: 1vw 2vw;
        }
        ${Media('mobile')} {
            font-size: 15px;
        }
    }
`;
const ResultPercentage = styled.div`
    flex: 0 0 58px;
    max-width: 58px;
    height: 58px;
    border-radius: 50%;
    font-size: 17px;
    font-family: ${gilroyBold};
    color: ${colors.white['100']};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.ebook.seasonal['main']};
    ${Media('xlscreens')} {
        flex: 0 0 3.03vw;
        max-width: 3.03vw;
        height: 3.03vw;
        font-size: 0.89vw;
    }
`;
const ResultList = styled.ul`
    position: relative;
    padding: 0;
    li {
        padding: 15px 0;
        display: flex;
        align-items: center;
        svg {
            path {
                fill: ${colors.white['100']};
            }
        }
    }
    ${Media('xlscreens')} {
        li {
            padding: 0.78vw 0;
        }
    }
`;
const EbookArrowList = styled.ul`
    position: relative;
    padding: 0;
    li {
        padding: 15px 0;
        display: flex;
        align-items: center;
        padding-left: 140px;
        &:nth-child(odd) {
            background-color: ${colors.white['26']};
        }
        &:nth-child(even) {
            background-color: transparent;
        }
        ${Media('xlscreens')} {
            padding: 0.78vw 0;
            padding-left: 6.3vw;
        }
        ${Media('mobile')} {
            padding-left: 20px;
        }
        svg {
            flex: 0 0 19px;
            height: 19px;
            width: 19px;
            path {
                fill: ${colors.ebook.seasonal['pink']};
            }
        }
    }
`;
const ListText = styled.span`
    font-size: 21px;
    font-family: ${gilroyMedium};
    margin-left: 38px;
    ${Media('xlscreens')} {
        font-size: 1.05vw;
        margin-left: 1.8vw;
    }
    ${Media('mobile')} {
        font-size: 16px;
    }
`;

const ChoreographingSection = styled(TargetSection)`
    position: relative;
    background-color: ${themeColor3};
`;

const SeasonalIntro = styled.div`
    background: url('${baseURL}assets/images/ebook/seasonal-intro-bg.jpg');
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    ${Media('xlscreens')} {
        padding: 1.25vw;
    }
    ${Media('mobile')} {
        padding: 15px;
    }
`;

const SeasonalIntroInner = styled(FlexColumnCentered)`
    background-color: ${themeColor};
    position: relative;
    z-index: 1;
    padding: 20px;
    padding-bottom: 30px;
    ${Media('xlscreens')} {
        padding: 3vw;
    }
`;

const SeasonalIntroBgText = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img {
        max-width: 100%;
    }
`;

const SeasonalIntroHeading = styled.h1`
    font-size: 80px;
    font-family: ${gilroyExtraboldItalic};
    text-transform: uppercase;
    text-align: center;
    color: ${colors.ebook.text};
    letter-spacing: -1px;
    transform: rotate(-3deg);
    line-height: 1.05;
    span {
        color: ${colors.technologyDropdown['box']};
    }
    ${Media('xlscreens')} {
        font-size: 7.4vw;
        letter-spacing: -0.25vw;
    }
    ${Media('mobile')} {
        font-size: 44px;
        letter-spacing: 0;
        line-height: 1.3;
    }
`;

const SeasonalIntroPara = styled.div`
    text-align: center;
    margin: 20px;
    ${Media('xlscreens')} {
        margin: 2vw 0px;
    }
    ${Media('mobile')} {
        div,
        p {
            text-align: center;
        }
    }
`;

const ScrollDownWrap = styled(FlexColumnCentered)`
    position: relative;
    top: 20px;
    p {
        font-size: 20px;
        font-family: ${gilroyMedium};
        color: ${colors.ebook.text};
    }
    ${Media('xlscreens')} {
        top: 1vw;
        p {
            font-size: 1.1vw;
        }
    }
    ${Media('mobile')} {
        p {
            font-size: 14px;
        }
    }
`;

const ForewardSection = styled.section`
    background-color: ${themeColor2};
    position: relative;
`;

const ForewardBigText = styled.div`
    position: absolute;
    top: 100px;
    img {
        margin-bottom: -60px;
    }
    ${Media('xlscreens')} {
        top: 6vw;
        img {
            width: 25.4vw;
            margin-bottom: -3vw;
        }
    }
    ${Media('mobile')} {
        img {
            display: none;
        }
    }
`;

const ForewardImage = styled.div`
    width: 300px;
    height: 300px;
    border-radius: 50%;
    overflow: hidden;
    margin: 20px auto;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    ${Media('xlscreens')} {
        width: 16.7vw;
        height: 16.7vw;
        margin-top: 2vw;
        margin-bottom: 1vw;
    }
`;

const ForewardContent = styled.div`
    margin-top: 30px;
    h2,
    p {
        color: ${colors.ebook.text};
    }
    h2 {
        font-size: 70px;
        font-family: ${gilroyBold};
    }
    p {
        font-size: 21px;
        line-height: 1.333;
        font-family: ${gilroyMedium};
    }
    ${Media('xlscreens')} {
        margin-top: 2vw;
        h2 {
            font-size: 3.6vw;
            margin-bottom: 4vw;
        }
        p {
            font-size: 1.25vw;
            margin-bottom: 2.2vw;
        }
    }
    ${Media('mobile')} {
        p {
            font-size: 18px;
        }
    }
`;

const ForewardSign = styled.div`
    display: flex;
    justify-content: flex-end;
    h3 {
        font-size: 24px;
        line-height: 1.8;
        font-family: ${gilroyBold};
        color: ${colors.ebook.text};
        span {
            display: block;
            font-family: ${gilroyMedium};
        }
    }
    img {
        position: relative;
        left: -20px;
    }
    ${Media('xlscreens')} {
        h3 {
            font-size: 1.35vw;
        }
        img {
            left: -1vw;
        }
    }
`;

const SignInner = styled.div`
    padding-top: 30px;
    text-align: right;
    ${Media('xlscreens')} {
        padding-top: 3vw;
    }
`;

const T1FeatureList = styled.div`
    padding: 50px 0px;
    ${Media('xlscreens')} {
        padding: 2vw 0 4vw;
    }
`;

const WhiteBox = styled(FlexColumnCentered)`
    background-color: ${colors.white['100']}80;
    padding: 30px;
    ${Media('xlscreens')} {
        padding: 3vw;
    }
`;

const ContentCenter = styled.div`
    text-align: center;
`;

const T1_1Heading = styled.div`
    margin-top: 20px;
    ${Media('xlscreens')} {
        margin-top: 2vw;
    }
`;

const Sup = styled.a`
    font-size: 8px;
    position: relative;
    color: ${themeColor};
    top: -20px;
    left: 1px;
    &:hover {
        color: ${themeColor2};
    }
    ${Media('xlscreens')} {
        font-size: 0.7vw;
        top: -0.5vw;
    }
`;

const T1QualityText = styled(FlexColumnCentered)`
    margin-top: 50px;
    text-align: center;
    h3 {
        span {
            font-family: ${gilroyBold};
            color: ${themeColor};
        }
    }
    ${(props) => {
        if (props.styleTwo) {
            return `
                [class*='main-para'] {
                    margin:0 0 50px;
                }
            `;
        }
    }}
    ${Media('xlscreens')} {
        margin-top: 5vw;
        margin-bottom: 3vw;
        ${(props) => {
            if (props.styleTwo) {
                return `
                    [class*='main-para'] {
                        margin: 0 0 2.6vw;
                    }
                `;
            }
        }}
    }
`;

const DivideBoxRow = styled(Row)`
    justify-content: flex-end;
`;

const DivideBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${themeColor};
    padding: 30px;
    position: relative;
    z-index: 10;
    margin: 30px 0px;
    h4,
    h2 {
        font-family: ${gilroyMedium};
        font-weight: normal;
    }
    h4 {
        font-size: 30px;
        color: ${themeColor2};
        text-transform: uppercase;
        margin: 0px;
        margin-bottom: 40px;
    }
    ${Media('xlscreens')} {
        padding: 5.4vw 5vw 4.8vw;
        padding-left: 6.5vw;
        margin: 3vw 0px 5vw;
        h4 {
            font-size: 1.8vw;
            margin-bottom: 3.6vw;
        }
    }
    ${Media('mobile')} {
        align-items: center;
        text-align: center;
        div {
            text-align: center;
        }
    }
`;

const FeatureImageHead = styled.div`
    position: relative;
    z-index: 1;
    [class*='big-text'] {
        position: absolute;
        z-index: -1;
        top: 50%;
        transform: ${(props) => props.bigTextMiddle && 'translateY(-50%)'};
        left: 0;
    }
    ${Media('xlscreens')} {
        padding: 4vw 0 5vw;
        padding-bottom: ${(props) => props.noPaddingBottom && '0 !important'};
        padding-top: ${(props) => props.noPaddingTop && '0 !important'};
    }
    ${Media('mobile')} {
        padding-left: 15px;
        padding-right: 15px;
    }
`;

const T1_2Heading = styled.div`
    [class^='main-heading'] {
        margin-bottom: 50px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 3vw;
        [class^='main-heading'] {
            margin-top: 2vw;
            margin-bottom: 4vw;
        }
    }
`;

const ForecastingSection = styled(TargetSection)`
    background-color: ${themeColor2};
    padding-top: 60px;
    ${Media('xlscreens')} {
        padding-top: 5vw;
    }
`;

const ForecastingBox = styled.div`
    background: url('${baseURL}assets/images/ebook/t1-3_forscasting_bg.jpg')
        no-repeat center;
    background-size: cover;
    padding: 50px;
    ${Media('xlscreens')} {
        padding: 6vw 8.2vw;
    }
    ${Media('mobile')} {
        padding: 10px;
        margin-left: -15px;
        margin-right: -15px;
    }
`;

const ForecastingInner = styled.div`
    padding: 50px;
    background-color: ${themeColor};
    text-align: center;
    ${Media('xlscreens')} {
        padding: 7vw 4vw 5vw 5vw;
    }
    ${Media('mobile')} {
        padding: 40px 20px;
        div {
            text-align: center;
        }
    }
`;

const RecruitingFact = styled.div`
    position: relative;
    padding: 60px 0px;
    text-align: center;
    ${Media('xlscreens')} {
        padding: 7vw 0 6vw;
    }
`;

const T1_5ImageHead = styled(FeatureImageHead)`
    margin-top: 0px !important;
    padding-top: 0px;
    [class*='feature-image'] {
        margin-top: 0px;
    }
    [class*='big-text'] {
        top: 40%;
    }
`;

const CaseStudyHead = styled(FeatureImageHead)`
    margin-top: 0px;
`;

const HighRiskListItem = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
    [class*='main-para'] {
        letter-spacing: 0;
        text-transform: capitalize;
    }
    img {
        height: 70px;
        margin-right: 30px;
    }
    ${Media('xlscreens')} {
        margin-top: 0.5vw;
        margin-bottom: 2vw;
        [class*='main-para'] {
            letter-spacing: 0.04vw;
        }
        img {
            height: 3.64vw;
            margin-right: 1.57vw;
        }
    }
    ${Media('mobile')} {
        margin: 10px 0;
        img {
            height: 50px;
            margin-right: 15px;
        }
    }
`;

const ParaHeading = styled.h3`
    font-size: 28px;
    font-family: ${gilroyBold};
    margin-bottom: 40px;
    margin-top: 0;
    ${Media('xlscreens')} {
        font-size: 1.25vw;
        margin-bottom: 2vw;
    }
    ${Media('mobile')} {
        font-size: 21px;
        margin-bottom: 30px;
        margin-top: 10px;
    }
`;

const IconParaWrapper = styled.div`
    display: flex;
    img {
        height: ${(props) => (props.styleTwo ? '80px' : '100px')};
        width: ${(props) => (props.styleTwo ? '80px' : '100px')};
        margin-top: ${(props) => (props.styleTwo ? '30px' : '40px')};
        margin-right: 40px;
    }
    ${Media('xlscreens')} {
        img {
            margin-top: ${(props) => (props.styleTwo ? '1.56vw' : '2.08vw')};
            margin-right: 2.08vw;
            height: ${(props) => (props.styleTwo ? '4.16vw' : '5.2vw')};
            width: ${(props) => (props.styleTwo ? '4.16vw' : '5.2vw')};
        }
    }
    ${Media('mobile')} {
        flex-direction: column;
        align-items: center;
        img {
            margin-top: 30px;
            margin-bottom: ${(props) => (props.styleTwo ? '1.56vw' : '2.08vw')};
            margin-right: 0;
            height: ${(props) => (props.styleTwo ? '50px' : '70px')};
            width: ${(props) => (props.styleTwo ? '50px' : '70px')};
        }
    }
`;

const ProjectGlideWrapper = styled.div`
    position: relative;
    margin-top: 50px;
    ${Media('xlscreens')} {
        margin-top: 2.6vw;
    }
`;

const ProjectGlideGoal = styled.div`
    background-color: ${colors.ebook.text};
    padding: 0px 130px;
    margin-bottom: -100px;
    position: relative;
    z-index: 1;
    ${Media('xlscreens')} {
        padding: 0 5vw;
    }
    ${Media('mobile')} {
        padding: 0 25px;
        margin-bottom: -30px;
    }
`;

const ProjectGlideHeading = styled.div`
    font-size: 35px;
    font-family: ${gilroyMedium};
    color: ${colors.white['100']};
    padding: 90px 0;
    ${Media('xlscreens')} {
        font-size: 1.82vw;
        padding: 3.5vw 0 3vw;
    }
    ${Media('mobile')} {
        padding: 40px 0;
        text-align: center;
    }
`;

const GoalCount = styled.div`
    height: 90px;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
    background-color: ${themeColor};
    border-radius: 50%;
    font-family: ${gilroyBold};
    position: relative;
    &:after {
        position: absolute;
        content: url('${baseURL}assets/images/ebook/icon-arrow-down-yellow.svg');
        height: 10px;
        bottom: -25%;
        left: 45%;
    }
    ${Media('xlscreens')} {
        height: 4.6vw;
        width: 4.6vw;
        margin-right: 2.6vw;
        &:after {
            height: 0.52vw;
        }
    }
    ${Media('mobile')} {
        height: 60px;
        width: 60px;
        margin-right: 0px;
        margin-bottom: 30px;
        &:after {
            display: none;
        }
    }
`;

const GoalText = styled.div`
    color: ${themeColor2};
    line-height: 1.6;
    flex: 1;
`;

const Goal = styled.div`
    display: flex;
    font-size: 24px;
    align-items: center;
    padding-bottom: 90px;
    &:last-child {
        padding-bottom: 160px;
        ${GoalCount} {
            &:after {
                display: none;
            }
        }
    }
    ${Media('xlscreens')} {
        font-size: 1.05vw;
        padding-bottom: 2.5vw;
        &:last-child {
            padding-bottom: 3vw;
        }
    }
    ${Media('mobile')} {
        flex-direction: column;
        text-align: center;
        padding-bottom: 60px;
        font-size: 18px;
        &:last-child {
            padding-bottom: 60px;
        }
    }
`;

const ComplexitySection = styled(TargetSection)`
    background-color: ${themeColor};
    ${Media('xlscreens')} {
        padding-bottom: 8vw;
    }
`;

const T2_2Heading = styled.div`
    padding-top: 50px;
    ${Media('xlscreens')} {
        padding-top: 5vw;
    }
`;

const T2List2 = styled.ul`
    padding-left: 0;
    margin: 40px 0;
    li {
        font-size: 24px;
        font-family: ${sourceSansPro};
        color: ${colors.white['100']};
        position: relative;
        line-height: 1.6;
        display: flex;
        align-items: center;
        padding-left: 70px;
        &:before {
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: ${colors.white['100']};
            content: '';
            position: absolute;
            left: 0;
        }
    }
    ${Media('xlscreens')} {
        margin: 1.8vw 0;
        li {
            font-size: 1.05vw;
            padding-left: 3vw;
            &:before {
                height: 0.31vw;
                width: 0.31vw;
            }
        }
    }
    ${Media('tablet')} {
        margin: 10px 0px;
        li {
            padding-left: 30px;
            font-size: 14px;
        }
    }
`;

const T2ListItem = styled.div`
    display: flex;
    margin: 50px 0;
    align-items: flex-start;
    line-height: 1.8;
    ${Media('xlscreens')} {
        margin: 2.6vw 0;
    }
    ${Media('mobile')} {
        flex-direction: column;
        align-items: center;
    }
`;
const ListTitle = styled.div`
    margin-right: 50px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 150px;
    ${Media('xlscreens')} {
        margin-right: 2.6vw;
        font-size: 1.05vw;
        width: 7.8vw;
    }
    ${Media('mobile')} {
        flex-direction: column;
        justify-content: center;
        font-size: 18px;
        margin-right: 0px;
        margin-bottom: 30px;
        svg {
            margin-left: 0;
            margin-top: 10px;
            transform: rotate(90deg);
        }
    }
`;
const ListContent = styled.div`
    font-size: 24px;
    font-family: ${sourceSansPro};
    flex: 1;
    ${Media('xlscreens')} {
        font-size: 1.05vw;
    }
    ${Media('mobile')} {
        font-size: 16px;
        text-align: center;
    }
`;

const T2FeatureList = styled.div`
    ${(props) => {
        if (props.styleTwo) {
            return `
                ${ListTitle}{
                    justify-content: flex-end;
                    width:39%;
                    svg{
                        margin-left: 40px;
                    }
                    ${Media('xlscreens')}{
                        svg{
                            margin-left:2.08vw;
                        }
                    }
                    ${Media('mobile')} {
                        width: 100%;
                        svg {
                            margin-left: 0px;
                        }
                    }
                }
            `;
        }
    }}
`;

const T2StatsWrapper = styled.div`
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    ${Media('xlscreens')} {
        margin-top: 1.56vw;
    }
`;

const T2StatsList = styled(Col)`
    display: flex;
    align-items: center;
    margin: 30px 0;
    ${Media('xlscreens')} {
        margin: 1.56 0;
    }
    ${Media('mobile')} {
        margin: 15px 0;
        flex-direction: column;
    }
`;
const StatLabel = styled.div`
    color: ${themeColor};
    font-size: 40px;
    font-family: ${gilroyBold};
    flex: 0 0 270px;
    ${Media('xlscreens')} {
        font-size: 1.6vw;
        flex: 0 0 14.06vw;
    }
    ${Media('mobile')} {
        flex: 1 0;
        margin-bottom: 10px;
        font-size: 24px;
        text-align: center;
    }
`;

const StatText = styled.div`
    font-size: 24px;
    font-family: ${sourceSansPro};
    flex: 1;
    ${Media('xlscreens')} {
        font-size: 1.05vw;
    }
    ${Media('mobile')} {
        flex: 1 0;
        margin-bottom: 20px;
        font-size: 18px;
        text-align: center;
    }
`;

export default SeasonalCXEbook;
