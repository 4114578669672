import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';

export const SRMFloatingSectionImage = ({
    x,
    y,
    image,
    top,
    bottom,
    right,
    left,
    width,
}) => {
    return (
        <Wrapper
            x={x}
            y={y}
            top={top}
            bottom={bottom}
            right={right}
            left={left}
            width={width}
        >
            <img src={image} alt="" />
        </Wrapper>
    );
};

const Wrapper = styled(Parallax)`
    position: absolute;
    z-index: -1;
    top: ${(props) => props.top};
    right: ${(props) => props.right};
    bottom: ${(props) => props.bottom};
    left: ${(props) => props.left};
    img {
        width: ${(props) => props.width}px;
    }
    ${Media('xlscreens')} {
        img {
            width: ${(props) => props.width / 19.2}vw;
        }
    }
    ${Media('tablet')} {
        display: none;
    }
`;
