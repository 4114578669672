import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';
import { paginate } from 'shared-components/pagination/pagination';

export const ResourceTabPagination = ({
    data,
    startIndex,
    endIndex,
    activePage,
    setActivePage,
}) => {
    const [pages, setPages] = useState([]);
    useEffect(() => {
        if (data) {
            const d = paginate(data.length, activePage, 4);
            setPages(d.pages);
            startIndex(d.startIndex);
            endIndex(d.endIndex);
        }
    }, [data, activePage]);
    return (
        <Pagination>
            {pages &&
                pages.length > 0 &&
                pages.map((page, index) => {
                    return (
                        <Item
                            key={index}
                            active={activePage - 1 === index}
                            onClick={() => setActivePage(index + 1)}>
                            {page}
                        </Item>
                    );
                })}
        </Pagination>
    );
};

const Pagination = styled(FlexCentered)`
    margin: 30px 0 0;
    ${Media('xlscreens')} {
        margin: 3vw 0 0;
    }
`;

const Item = styled(FlexCentered)`
    width: 34px;
    height: 34px;
    background-color: ${(props) => props.active && colors.dark['100']};
    color: ${(props) =>
        props.active ? colors.white['100'] : colors.dark['100']};
    border: 1px solid ${colors.dark['100']};
    border-radius: 50px;
    font-size: 16px;
    font-family: ${gilroyBold};
    margin: 0 5px;
    cursor: pointer;
    transition: all 330ms ease;
    &:hover {
        background-color: ${colors.dark['100']};
        color: ${colors.white['100']};
    }
    ${Media('xlscreens')} {
        width: 1.77vw;
        height: 1.77vw;
        font-size: 0.95vw;
    }
`;
