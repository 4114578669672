import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import {
    gilroyMedium,
    gilroyBold,
    gilroySemibold,
    gilroyHeavy,
} from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import { Col } from '@bootstrap-styled/v4';
import Button from 'shared-components/button/button';
import ScrollAnimation from 'react-animate-on-scroll';
import Video from 'shared-components/video/video';
import { RowCentered } from 'shared-components/theme/grid-helpers';

const HomeProfitJourney = ({
    infoImage,
    infoTag,
    infoTitle,
    infoDesc,
    infoPara,
    infoLink,
    tabList,
    video_image,
    video_url,
    videoText,
    level,
}) => {
    return (
        <Wrapper>
            <StickyControler>
                <RowCentered>
                    <Col md="4">
                        <ScrollAnimation
                            delay={300}
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <InfoBox>
                                <InfoBoxIcon>
                                    <img src={infoImage} alt="" />
                                    <SmallTag>{infoTag}</SmallTag>
                                </InfoBoxIcon>
                                <InfoBoxTitle level={level}>
                                    {infoTitle}
                                    <span>.</span>
                                </InfoBoxTitle>
                                <InfoBoxDesc>{infoDesc}</InfoBoxDesc>
                                <InfoBoxPara>{infoPara}</InfoBoxPara>
                                <LearnMore>
                                    <Button
                                        href={infoLink}
                                        round={true}
                                        bgColor="transparent"
                                        color={
                                            level === 'one'
                                                ? colors.pink['100']
                                                : level === 'two'
                                                ? colors.orange['100']
                                                : colors.blue['100']
                                        }
                                        borderColor={colors.gray_dark['50']}>
                                        Learn More
                                    </Button>
                                </LearnMore>
                            </InfoBox>
                        </ScrollAnimation>
                    </Col>
                    <Col md="8">
                        <ScrollAnimation
                            delay={100}
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <TabContainer>
                                <TabBox>
                                    <Video
                                        image={video_image}
                                        video={video_url}
                                        videotype="mp4"
                                    />
                                    <TabText>
                                        <TextInner level={level}>
                                            {videoText}
                                        </TextInner>
                                    </TabText>
                                </TabBox>
                                <TabList>{tabList}</TabList>
                            </TabContainer>
                        </ScrollAnimation>
                    </Col>
                </RowCentered>
            </StickyControler>
        </Wrapper>
    );
};

const StickyControler = styled.div`
    ${Media('xlscreens')} {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 5.3vw;
    }
`;
const Wrapper = styled.div`
    ${Media('xlscreens')} {
        height: 135vh;
        margin-top: 8vw;
    }
    ${Media('tablet')} {
        margin-top: 70px;
    }
`;

const InfoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 400px;
    margin: 0px auto;
    position: relative;
    left: 50px;
    ${Media('xlscreens')} {
        max-width: 20vw;
        left: 3vw;
    }
    ${Media('tablet')} {
        left: 0px;
    }
`;

const InfoBoxIcon = styled.div`
    margin-bottom: 30px;
    position: relative;
    ${Media('xlscreens')} {
        margin-bottom: 2.2vw;
        img {
            width: 5.42vw;
        }
    }
`;

const SmallTag = styled.div`
    font-size: 13px;
    font-family: ${gilroySemibold};
    color: ${colors.gray_dark['100']};
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    ${Media('xlscreens')} {
        font-size: 1vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
`;

const InfoBoxTitle = styled.h3`
    font-size: 38px;
    font-family: ${gilroyBold};
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 30px;
    span {
        color: ${(props) => {
            if (props.level === 'one') {
                return colors.pink['100'];
            } else if (props.level === 'two') {
                return colors.orange['100'];
            } else if (props.level === 'three') {
                return colors.blue['100'];
            }
        }};
    }
    ${Media('xlscreens')} {
        font-size: 2.6vw;
    }
    ${Media('mobile')} {
        font-size: 30px;
    }
`;

const InfoBoxDesc = styled.span`
    font-size: 14px;
    font-family: ${gilroySemibold};
    color: ${colors.gray_dark['50']};
    ${Media('xlscreens')} {
        font-size: 0.95vw;
    }
`;

const InfoBoxPara = styled.p`
    font-family: ${gilroyMedium};
    margin: 30px 0px 120px;
    ${Media('xlscreens')} {
        font-size: 0.85vw;
        margin-bottom: 4vw;
    }
    ${Media('tablet')} {
        margin-bottom: 50px;
    }
`;

const LearnMore = styled.div`
    a {
        ${Media('xlscreens')} {
            min-width: 9.7vw;
        }
    }
    ${Media('tablet')} {
        margin-bottom: 50px;
    }
`;

const TabContainer = styled.div`
    position: relative;
    margin-left: 50px;
    ${Media('xlscreens')} {
        margin-left: 2.6vw;
    }
    ${Media('tablet')} {
        margin-left: 0px;
    }
    ${Media('mobile')} {
        display: none;
    }
`;

const TabBox = styled.div`
    background-image: -webkit-radial-gradient(#ffffff 40%, #ededed);
    background-image: -moz-radial-gradient(#ffffff 40%, #ededed);
    background-image: radial-gradient(#ffffff 40%, #ededed);
    overflow: hidden;
    height: calc(100vh - 82px);
    display: flex;
    position: relative;
    ${Media('xlscreens')} {
        height: calc(100vh - 5.3vw - 3.2vw);
    }
    ${Media('mobile')} {
        height: 240px;
    }
`;

const TabText = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.gray_dark['50']};
`;

const TextInner = styled.div`
    font-size: 34px;
    line-height: 1.3;
    font-family: ${gilroyMedium};
    color: ${colors.white['100']};
    text-align: center;
    span {
        font-family: ${gilroyHeavy};
        color: ${(props) => {
            if (props.level === 'one') {
                return colors.pink['100'];
            } else if (props.level === 'two') {
                return colors.orange['100'];
            } else if (props.level === 'three') {
                return colors.blue['100'];
            }
        }};
    }
    ${Media('xlscreens')} {
        font-size: 2.4vw;
    }
    ${Media('mobile')} {
        font-size: 18px;
    }
`;

const TabList = styled.ul`
    width: 100%;
    padding: 0;
    margin: 0px;
    display: flex;
    li {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 63px;
        font-family: ${gilroyMedium};
        color: #fff;
        background-color: #1d1d1f;
        border: 1px solid ${colors.gray_lighter['100']};
        border-bottom: 0px;
        border-top: 0px;
        &:nth-child(1) {
            border-left: 0px;
        }
        &:nth-child(2) {
            border: 0px;
        }
        &:nth-child(3) {
            border-right: 0px;
        }
        ${Media('xlscreens')} {
            flex: 1 0;
            height: 3.2vw;
            font-size: 0.9vw;
        }
    }
    ${Media('mobile')} {
        flex-direction: column;
        li {
            height: auto;
            padding: 10px 0px;
            font-size: 14px;
            &:nth-child(1) {
                border-top: 0px;
            }
            &:nth-child(2) {
                border-top: 0px;
                border-bottom: 0px;
            }
        }
    }
`;

export default HomeProfitJourney;
