import React from 'react';
import SolutionBox from 'components/solution-box/solution-box';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';
import {
    TransformSection,
    Text,
    IndustryTag,
    IndustrySectionHeading,
    ContentCenter,
} from 'components/industries/common';

export const TransformSolution = ({ solutions }) => {
    return (
        <>
            <TransformSection
                tag="Navigating CX Success"
                sectionHeading="Enhanced Customer Experiences And Loyalty <br />Amidst Increasing Demands"
                img={`${baseURL}assets/images/industries/travel-hospital-leader.jpg`}
                para1="The Travel & Hospitality industry is all about experiences – customers are investing in their next Instagram-worthy destination and accommodations. With an increasingly global and digital reach, and heightened customer expectations, these brands are challenged to be agile, adaptable, and ready to meet new, on-demand customer requirements.  "
                para2="By choosing a CX partner who can help you traverse the changing Travel landscape with innovative, personalized, data-driven CX, you can get ahead of new customer demands, increase customer loyalty, and boost your ROI. "
            />
            <CXSolution id="cx-solution">
                <Container>
                    <div>
                        <ContentCenter>
                            <IndustryTag>Worldwide cx</IndustryTag>
                            <IndustrySectionHeading>
                                Take Your CX Success To New Heights
                            </IndustrySectionHeading>
                            <Text>
                                For the world’s leading Travel & Hospitality
                                brands (like you), we know the customer journey
                                never really ends – from search and booking{' '}
                                <br />
                                through the return home and planning their next
                                trip. ibex deploys the best in people,
                                technology, and methodology to deliver flawless
                                CX <br /> at speed and scale, ensuring that
                                every leg of the journey is seamless.
                            </Text>
                        </ContentCenter>
                    </div>
                    <SolutionBoxWrapper>
                        {solutions &&
                            solutions.length > 0 &&
                            solutions.map((solution, index) => {
                                return (
                                    <Col lg="3" md="6" key={index}>
                                        <SolutionBox
                                            title={solution.title}
                                            imageUrl={solution.imageUrl}
                                            para={solution.para}
                                        />
                                    </Col>
                                );
                            })}
                    </SolutionBoxWrapper>
                </Container>
            </CXSolution>
        </>
    );
};
const CXSolution = styled.section`
    background-color: ${colors.gray_lighter['100']};
`;
const SolutionBoxWrapper = styled(Row)`
    min-height: 700px;
    ${Media('xlscreens')} {
        min-height: 36.4vw;
    }
`;
