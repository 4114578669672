import React from 'react';
import styled from 'styled-components';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const WebinarMainPara = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
    font-size: 16px;
    line-height: 1.8;
    font-family: ${gilroyMedium};
    margin-top: 0px;
    ${Media('xlscreens')} {
        font-size: 1vw;
    }
    ${Media('desktopscreens')} {
        font-size: 0.95vw;
    }
    ${Media('mobile')} {
        font-size: 14px;
        br {
            display: none;
        }
    }
`;
