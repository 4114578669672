import React from 'react';
import { Container, Col, Row } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import SolutionBox from 'components/solution-box/solution-box';
import Media from 'shared-components/theme/medias';
import {
    Text,
    IndustryTag,
    IndustrySectionHeading,
    ContentCenter,
} from 'components/industries/common';

export const CXSolution = ({ financialSolutions }) => {
    return (
        <Wrapper id="cx-solution">
            <Section>
                <Container>
                    <div>
                        <ContentCenter>
                            <IndustryTag>
                                Strategy Embedded. Execution Delivered.
                            </IndustryTag>
                            <IndustrySectionHeading>
                                An agile CX ecosystem for delivering fast and
                                frictionless CX
                            </IndustrySectionHeading>
                            <Text>
                                For every touchpoint in your network, we are
                                ready to deploy people, technology, and
                                expertise to manage and solve your <br />
                                complex customer experience needs.
                            </Text>
                        </ContentCenter>
                    </div>
                    <SolutionBoxWrapper>
                        {financialSolutions &&
                            financialSolutions.length > 0 &&
                            financialSolutions.map((solution, index) => {
                                return (
                                    <Col lg="3" md="6" key={index}>
                                        <SolutionBox
                                            title={solution.title}
                                            imageUrl={solution.imageUrl}
                                            para={solution.para}
                                        />
                                    </Col>
                                );
                            })}
                    </SolutionBoxWrapper>
                </Container>
            </Section>
        </Wrapper>
    );
};
const Section = styled.section`
    ${Media('tablet')} {
        padding: 50px 0;
    }
`;
const Wrapper = styled.div`
    background-color: ${colors.gray_lighter['100']};
`;
const SolutionBoxWrapper = styled(Row)`
    min-height: 700px;
    ${Media('xlscreens')} {
        min-height: 36.4vw;
    }
`;
