import React from 'react';
import styled from 'styled-components';
import { gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const PageHeading = ({ children, color, noMargin }) => {
    return (
        <Wrapper color={color} noMargin={noMargin}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.h2`
    font-family: ${gilroySemibold};
    font-size: 30px;
    line-height: 1.23;
    letter-spacing: -1px;
    color: ${(props) => props.color};
    margin: ${(props) => (props.noMargin ? 0 : '30px 0px')};
    ${Media('xlscreens')} {
        font-size: 4.2vw;
        letter-spacing: -0.2vw;
    }
`;
