import React from 'react';
import styled from 'styled-components';
import { gilroyExtrabold, gilroyLight } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const UtilPagePara = ({ children, color }) => {
    return <Wrapper color={color}>{children}</Wrapper>;
};

const Wrapper = styled.p`
    font-size: 18px;
    font-family: ${gilroyLight};
    line-height: 1.5;
    margin-top: 0px;
    color: ${(props) => props.color};
    span {
        font-family: ${gilroyExtrabold};
    }
    ${Media('xlscreens')} {
        font-size: 1.1vw;
        margin-bottom: 2vw;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
