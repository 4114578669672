import React from 'react';
import colors from 'shared-components/theme/colors';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
export const Source = ({ children, color, style2 }) => {
    return (
        <Wrapper color={color} style2={style2}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.h5`
    font-size: 24px;
    font-family: ${gilroyMedium};
    color: ${(props) => (props.color ? props.color : colors.black['100'])};
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.style2 ? 0.93 : 1.25)}vw;
    }
    ${Media('tablet')} {
        font-size: 16px;
    }
`;
