import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import {
    gilroyMedium,
    gilroySemibold,
    gilroyLight,
} from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';

const WhoTheyAre = ({
    tagLine,
    contentHeading,
    contentParagraph,
    contentImage,
    multiPara,
    styleTwo,
}) => {
    return (
        <ScrollAnimation animateIn="fadeInUp">
            <Wrapper>
                <Container>
                    <CustomRow>
                        <Col lg={styleTwo ? '5' : '6'} md="12">
                            <ImageBox>
                                <img src={contentImage} alt="" />
                            </ImageBox>
                        </Col>
                        <Col lg="6" md="12">
                            {(multiPara && (
                                <TextContentWrapper multiPara={multiPara}>
                                    <TextContentArea
                                        multiPara={multiPara}
                                        marginBottom={true}>
                                        <Tagline>{tagLine[0]}</Tagline>
                                        <ContentHeading>
                                            {contentHeading[0]}
                                        </ContentHeading>
                                        <ContentParagraph>
                                            {contentParagraph[0]}
                                        </ContentParagraph>
                                    </TextContentArea>
                                    <TextContentArea multiPara={multiPara}>
                                        <Tagline>{tagLine[1]}</Tagline>
                                        <ContentHeading>
                                            {contentHeading[1]}
                                        </ContentHeading>
                                        <ContentParagraph>
                                            {contentParagraph[1]}
                                        </ContentParagraph>
                                    </TextContentArea>
                                </TextContentWrapper>
                            )) || (
                                <TextContentArea multiPara={multiPara}>
                                    <Tagline>{tagLine}</Tagline>
                                    <ContentHeading>
                                        {contentHeading}
                                    </ContentHeading>
                                    <ContentParagraph>
                                        {contentParagraph}
                                    </ContentParagraph>
                                </TextContentArea>
                            )}
                        </Col>
                    </CustomRow>
                </Container>
            </Wrapper>
        </ScrollAnimation>
    );
};
const CustomRow = styled(Row)`
    ${Media('xlscreens')} {
        align-items: center;
    }
`;

const Wrapper = styled.section`
    margin-top: 50px;
    padding: 0px;
    ${Media('xlscreens')} {
        margin-top: 2.61vw;
    }
`;
const ImageBox = styled.div`
    img {
        width: 100%;
        height: 100%;
    }
`;
const TextContentArea = styled.div`
    padding-left: 100px;
    ${Media('xlscreens')} {
        padding-left: 4.5vw;
        margin-bottom: ${(props) => (props.marginBottom ? '4vw' : '0')};
    }
    ${(props) => {
        if (!props.multiPara) {
            return `padding-top:100px;
                ${Media('xlscreens')}{
                    padding-top:2.5vw;
                }
                ${Media('tablet')}{
                    padding-top:20px;
                }
            `;
        }
    }}
    ${Media('tablet')} {
        padding-left: 0px;
        margin-top: 40px;
    }
`;
const TextContentWrapper = styled.div`
    display: flex;
    height: 100%;
    ${(props) => {
        if (props.multiPara) {
            return `justify-content:space-around;
                    flex-direction:column;`;
        } else {
            return ` justify-content:center;`;
        }
    }}
`;
const Tagline = styled.span`
    display: block;
    font-family: ${gilroyMedium};
    font-size: 29px;
    color: ${colors.pink['100']};
    ${Media('xlscreens')} {
        font-size: 1.51vw;
    }
`;
const ContentHeading = styled.h2`
    font-family: ${gilroySemibold};
    font-size: 52px;
    color: ${colors.gray_dark['100']};
    line-height: 1.34;
    letter-spacing: -0.43px;
    ${Media('xlscreens')} {
        font-size: 2.7vw;
        margin-bottom: 0;
    }
    ${Media('tablet')} {
        font-size: 38px;
        br {
            display: none;
        }
    }
`;
const ContentParagraph = styled.p`
    font-family: ${gilroyLight};
    font-size: 20px;
    line-height: 1.75;
    span {
        font-family: ${gilroySemibold};
    }
    ${Media('xlscreens')} {
        font-size: 1.04vw;
        max-width: 25vw;
    }
    ${Media('tablet')} {
        max-width: 100%;
        font-size: 18px;
        margin: 0px;
        br {
            display: none;
        }
    }
`;

export default WhoTheyAre;
