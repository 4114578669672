import React from 'react';
import styled from 'styled-components';
import { Container, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import { baseURL } from 'shared-components/theme/helpers';

const digitalBrands = [
    {
        items: [
            {
                colLgSize: '3',
                imgSrc: `${baseURL}assets/images/digital_st.svg`,
                imgWidth: '70%',
            },
            {
                colLgSize: '3',
                imgSrc: `${baseURL}assets/images/digital_mercari.svg`,
                imgWidth: '70%',
            },
            {
                colLgSize: '3',
                imgSrc: `${baseURL}assets/images/digital_hr.svg`,
                imgWidth: '80%',
            },
            {
                colLgSize: '3',
                imgSrc: `${baseURL}assets/images/digital_energy.svg`,
                imgWidth: '80%',
            },
        ],
        row1: true,
    },
    {
        items: [
            {
                colLgSize: '3',
                imgSrc: `${baseURL}assets/images/digital_att.svg`,
                imgWidth: '80%',
            },
            {
                colLgSize: '3',
                imgSrc: `${baseURL}assets/images/digital_tmobo.svg`,
                imgWidth: '96%',
            },
            {
                colLgSize: '3',
                imgSrc: `${baseURL}assets/images/digital_sprint.svg`,
                imgWidth: '90%',
            },
            {
                colLgSize: '2',
                imgSrc: `${baseURL}assets/images/digital_cox.svg`,
                imgWidth: '60%',
            },
            {
                colLgSize: '1',
                imgSrc: `${baseURL}assets/images/digital_adt.svg`,
                imgWidth: '40%',
            },
        ],
        row2: true,
    },
];
export const DigitalBrands = () => {
    return (
        <DigitalBrandsHolder id="brands">
            <Container>
                <div>
                    {digitalBrands &&
                        digitalBrands.map((item, ind) => {
                            if (item.row1) {
                                return (
                                    <RowCentered key={ind}>
                                        {item.items &&
                                            item.items.map((it, ind2) => {
                                                return (
                                                    <Col
                                                        xs="6"
                                                        lg={it.colLgSize}
                                                        key={ind2}>
                                                        <img
                                                            src={it.imgSrc}
                                                            width={it.imgWidth}
                                                        />
                                                    </Col>
                                                );
                                            })}
                                    </RowCentered>
                                );
                            } else {
                                return (
                                    <RowCentered key={ind}>
                                        {item.items &&
                                            item.items.map((it, ind2) => {
                                                if (
                                                    ind2 !==
                                                    item.items.length - 1
                                                ) {
                                                    return (
                                                        <Col
                                                            xs="6"
                                                            lg={it.colLgSize}
                                                            key={ind2}>
                                                            <DigitaBrandLogoHolder>
                                                                <img
                                                                    src={
                                                                        it.imgSrc
                                                                    }
                                                                    width={
                                                                        it.imgWidth
                                                                    }
                                                                />
                                                            </DigitaBrandLogoHolder>
                                                        </Col>
                                                    );
                                                } else {
                                                    return (
                                                        <Col
                                                            xs="12"
                                                            lg={it.colLgSize}
                                                            key={ind2}>
                                                            <DigitaBrandLogoHolder>
                                                                <AdtCustomSize>
                                                                    <img
                                                                        src={
                                                                            it.imgSrc
                                                                        }
                                                                        width={
                                                                            it.imgWidth
                                                                        }
                                                                    />
                                                                </AdtCustomSize>
                                                            </DigitaBrandLogoHolder>
                                                        </Col>
                                                    );
                                                }
                                            })}
                                    </RowCentered>
                                );
                            }
                        })}
                </div>
            </Container>
        </DigitalBrandsHolder>
    );
};

const AdtCustomSize = styled.div`
    ${Media('tablet')} {
        img {
            width: 20%;
        }
    }
`;
const DigitaBrandLogoHolder = styled.div`
    width: 10vw;
    text-align: left;
    img {
        display: inline-block;
    }
    ${Media('tablet')} {
        padding-top: 13px;
        width: 100%;
        text-align: center;
        img {
            display: inline;
        }
    }
`;
const DigitalBrandsHolder = styled.div`
    padding: 3vw;
`;
