import { Col, Row } from '@bootstrap-styled/v4';
import React from 'react';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import { BigHeading } from './big-heading';
import { BlockHeading } from './block-heading';
import { CXSectionIntro } from './cx-section-intro';
import { Para } from './para';

export const CXPoint3 = ({ active }) => {
    return (
        <div>
            <CXSectionIntro
                section3
                active={active}
                source={['1: go2HR', '2: PwC']}
                progressImg={`${baseURL}assets/images/ebook/cx-progress3.svg`}
                introPara="<span>40% of employees</span> with poor training will leave the company within the first year <sup>1</sup> , and <span>46% of customers</span> will abandon a brand if employees are not knowledgeable. <sup>2</sup>"
            />
            <BigHeading>
                The modern contact center needs to be built for predictable success and continuous high performance thereafter. The more effective your CX outsourcer is at training and ramping agents, the better the knowledge retention, learning speed, and Day 1 proficiency.
            </BigHeading>
            <Row>
                <Col lg="6">
                    <SectionImg>
                        <img
                            src={`${baseURL}assets/images/ebook/cx-pg-section3.jpg`}
                            alt="-"
                        />
                    </SectionImg>
                </Col>
                <Col lg="6">
                    <PaddingBlock>
                        <Para noMarginTop>Consider the following:</Para>
                        <Row>
                            <Col lg="12">
                                <BlockHeading>
                                    End-to-End Growth Methodology
                                </BlockHeading>
                                <Para>
                                    Look for a partner who includes continuous
                                    learning to consistently improve
                                    performance, and who invests in employee
                                    development. Make sure to ask your
                                    outsourcer to provide data about their
                                    training program and its
                                    speed-to-proficiency.
                                </Para>
                            </Col>
                            <Col lg="12">
                                <BlockHeading>Training Simulation</BlockHeading>
                                <Para>
                                    Training simulators are an important
                                    training tool for agents to be immersed in
                                    the contact center and ready for success
                                    from Day 1. Agents can also learn new skills
                                    in a simulated environment.
                                </Para>
                            </Col>
                        </Row>
                    </PaddingBlock>
                </Col>
            </Row>
            <BlockHeading>Personalized Agent Coaching</BlockHeading>
            <Para>
                Make sure contact center leaders are paying attention to
                individual agent needs. Look for leaders with a hands-on
                approach, who helps agents develop according to their individual
                skills.
            </Para>
            <BlockHeading>Agent-to-Leadership Pathway</BlockHeading>
            <Para>
                Ensure your contact center has a dedicated team whose sole focus
                is promoting from within, enabling internal talent to grow, and
                keeping agents motivated with a clear career path.
            </Para>
        </div>
    );
};
const SectionImg = styled.div`
    img {
        width: 100%;
    }
`;

const PaddingBlock = styled.div`
    padding-left: 30px;
    ${Media('xlscreens')} {
        padding-left: 1.57vw;
    }
    ${Media('tablet')} {
        padding-left: 0;
    }
`;
