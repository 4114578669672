import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import MainPara from 'shared-components/main-para/main-para';
import JoinUsCareBox from 'components/join-us-care-box/join-us-care-box';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import { gilroyLight, gilroySemibold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';

const careList = [
    {
        imageUrl: `${baseURL}assets/images/health-care.png`,
        type: 'HealthCare',
    },
    {
        imageUrl: `${baseURL}assets/images/dental-care.png`,
        type: 'Dental & Vision',
    },
    {
        imageUrl: `${baseURL}assets/images/retirement.png`,
        type: '401k Retirement *',
    },
    {
        imageUrl: `${baseURL}assets/images/life-insurance.png`,
        type: 'Life Insurance',
    },
    {
        imageUrl: `${baseURL}assets/images/employee-assistance.png`,
        type: 'Employee Assistance',
    },
    {
        imageUrl: `${baseURL}assets/images/holiday.png`,
        type: (
            <Fragment>
                Holiday and Paid <br /> Time Off
            </Fragment>
        ),
    },
];

export const BrandSection = () => {
    return (
        <BrandWrapper>
            <Container>
                <Row>
                    <Col lg="5">
                        <BrandDetailWrapper>
                            <div>
                                <SectionHeading2>
                                    People-Perfect Brand Champions
                                </SectionHeading2>
                            </div>
                            <div>
                                <MainPara>
                                    The only way to change an industry is to
                                    rely on talented
                                    <br />
                                    people, positive action, and a community of
                                    colleagues
                                    <br />
                                    and leaders who truly believe in the
                                    collaborative and
                                    <br />
                                    creative exchange of ideas.
                                </MainPara>
                            </div>
                            <div>
                                <SectionSubHeading>
                                    How we care about you?
                                </SectionSubHeading>
                                <MainPara>
                                    We offer a great starting hourly pay with
                                    incentives and
                                    <br />
                                    bonus plans. We make sure you get paid while
                                    you learn through paid training. Want to
                                    find a place where you can actually get
                                    promotions and find a career path? ibex will
                                    help you go as far as you would like to go.
                                    Need benefits? We have those too.
                                    <br />
                                    <br />
                                    US benefits include vision, dental and
                                    health insurance. We
                                    <br />
                                    also provide life insurance and an employee
                                    assistance program in case you need help
                                    along the way. Paid time off and some
                                    holidays. We even have a 401k retirement
                                    plan.
                                </MainPara>
                            </div>
                            <CareBoxWrapper>
                                {careList && careList.length > 0
                                    ? careList.map((item, index) => {
                                          return (
                                              <JoinUsCareBox
                                                  key={index}
                                                  imageUrl={item.imageUrl}
                                                  type={item.type}
                                              />
                                          );
                                      })
                                    : null}
                            </CareBoxWrapper>
                            <Text>* US ONLY</Text>
                            <CustomPara>
                                <CustomParaHeading>
                                    Price Transparency Disclosure
                                </CustomParaHeading>
                                <MainPara>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.cigna.com/legal/compliance/machine-readable-files">
                                        This link leads to the machine readable
                                        files
                                    </a>
                                    &nbsp;that are made available in response to
                                    the federal Transparency in Coverage Rule
                                    and includes negotiated service rates and
                                    out-of-network allowed amounts between
                                    health plans and healthcare providers. The
                                    machine-readable files are formatted to
                                    allow researchers, regulators, and
                                    application developers to more easily access
                                    and analyze data.
                                </MainPara>
                            </CustomPara>
                        </BrandDetailWrapper>
                    </Col>
                    <Col lg="7">
                        <div>
                            <PeopleChampImg>
                                <img
                                    src={`${baseURL}assets/images/people-perfect-champ.png`}
                                    alt="-"
                                />
                            </PeopleChampImg>
                        </div>
                    </Col>
                </Row>
            </Container>
        </BrandWrapper>
    );
};

const CustomPara = styled.div`
    margin-top: 50px;
    border-top: 1px solid ${colors.black['30']};
    border-bottom: 1px solid ${colors.black['30']};
    a {
        color: ${colors.pink['100']};
    }
    p {
        ${Media('xlscreens')} {
            margin-top: 1vw;
        }
    }
    ${Media('xlscreens')} {
        margin-top: 4vw;
    }
    ${Media('tablet')} {
        margin-bottom: 60px;
    }
`;

const SectionHeading2 = styled.div`
    font-family: ${gilroySemibold};
    font-size: 62px;
    margin-bottom: 40px;
    ${Media('xlscreens')} {
        font-size: 3.10vw;
        margin-bottom: 2.1vw;
    }
    ${Media('tablet')} {
        font-size: 36px;
    }
`;
const SectionSubHeading = styled.div`
    font-family: ${gilroySemibold};
    font-size: 32px;
    margin: 40px 0px;
    ${Media('xlscreens')} {
        font-size: 1.68vw;
        margin: 4.21vw 0vw;
    }
`;

const CustomParaHeading = styled.h3`
    font-family: ${gilroySemibold};
    font-size: 32px;
    margin-top: 30px;
    margin-bottom: 0;
    ${Media('xlscreens')} {
        font-size: 1.27vw;
        margin-top: 1.56vw;
    }
`;

const CareBoxWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 128px;
    margin-bottom: 50px;
    ${Media('xlscreens')} {
        margin-top: 6.73vw;
        margin-bottom: 2.63vw;
    }
    ${Media('tablet')} {
        margin-top: 35px;
        margin-bottom: 35px;
    }
    ${Media('mobile')} {
        display: block;
        margin-top: 60px;
    }
`;
const BrandWrapper = styled.div`
    padding-top: 105px;
    ${Media('xlscreens')} {
        padding-top: 5.52vw;
    }
`;
const BrandDetailWrapper = styled.div`
    padding-right: 0px;
`;
const PeopleChampImg = styled.div`
    height: 1420px;
    width: 100%;
    margin-left: 5vw;
    ${Media('xlscreens')} {
        height: 74.73vw;
    }
    ${Media('tabletlandscape')} {
        margin-left: 0vw;
        height: 150vw;
    }
    img {
        width: 100%;
        height: 100%;
    }
`;
const Text = styled.div`
    font-family: ${gilroyLight};
    font-size: 16px;
    ${Media('xlscreens')} {
        font-size: 0.84vw;
    }
`;
