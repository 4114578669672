import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ReactHtmlParser from 'react-html-parser';
import NotFoundPage from 'pages/not-found';
import Loader from 'components/loader/loader';
import Layout from 'components/layout';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import { baseURL } from 'shared-components/theme/helpers';
import { gilroyBold, gilroyMedium } from 'shared-components/theme/fonts';
import {
    WebinarContentIntro,
    WebinarContentCTA,
} from 'components/webinars/content/common';

const WebinarIndex = () => {
    const router = useParams();
    const [pageError, setPageError] = useState(false);
    const [cxFormState, handleCXForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pageData, setPageData] = useState({});

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/webinars?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        post_name: router.slug,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
                setPageError(true);
                setLoading(false);
            }
            const resData = await res.json();
            if (resData.status === 200 && resData.data[0].length !== 0) {
                setLoading(false);
                setPageData(resData.data[0].metadata);
            } else {
                setPageError(true);
                setLoading(false);
            }
        } catch (err) {
            console.error(err);
            setPageError(true);
            setLoading(false);
        }
    };

    return (
        <>
            {loading && <Loader position="absolute" />}
            {pageError ? (
                <NotFoundPage />
            ) : (
                Object.keys(pageData).length !== 0 &&
                pageData !== undefined && (
                    <Layout
                        handleCXForm={{
                            cxFormState,
                            handleState: (val) => handleCXForm(val),
                        }}>
                        <WebinarContentIntro
                            heading={pageData.page_heading}
                            headingSize="small"
                            para={pageData.page_description}
                            youtubeLink={pageData.video_url}
                        />

                        {pageData.show_related_webinars && (
                            <ContentSection>
                                <Container>
                                    <SectionHeading>
                                        More Recommended Content For You…
                                    </SectionHeading>
                                    <Row>
                                        {pageData.related_webinars &&
                                            pageData.related_webinars.length >
                                                0 &&
                                            pageData.related_webinars.map(
                                                (item, index) => {
                                                    return (
                                                        <Col lg="6" key={index}>
                                                            <WebinarItem
                                                                animateIn="fadeInUp"
                                                                animateOnce={
                                                                    true
                                                                }>
                                                                <WebinarThumbnail
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href={
                                                                        item.webinar_link
                                                                    }>
                                                                    <img
                                                                        src={
                                                                            item.webinar_image
                                                                        }
                                                                        alt="-"
                                                                    />
                                                                </WebinarThumbnail>
                                                                <WebinarInfo>
                                                                    <span>
                                                                        WEBINAR
                                                                    </span>
                                                                    <WebinarTitle>
                                                                        {ReactHtmlParser(
                                                                            item.webinar_title
                                                                        )}
                                                                    </WebinarTitle>
                                                                    <WatchItButton
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        href={
                                                                            item.webinar_link
                                                                        }>
                                                                        Watch It
                                                                        <img
                                                                            src={`${baseURL}assets/images/arrow_right-blue.svg`}
                                                                            alt="-"
                                                                        />
                                                                    </WatchItButton>
                                                                </WebinarInfo>
                                                            </WebinarItem>
                                                        </Col>
                                                    );
                                                }
                                            )}
                                    </Row>
                                </Container>
                            </ContentSection>
                        )}

                        <WebinarContentCTA
                            heading={pageData.call_to_action_heading}
                            subheading={pageData.call_to_action_sub_heading}
                            btnText={pageData.call_to_action_button_text}
                        />
                    </Layout>
                )
            )}
        </>
    );
};

const ContentSection = styled.div`
    margin-bottom: 50px;
    ${Media('xlscreens')} {
        margin-bottom: 2.6vw;
    }
    ${Media('tablet')} {
        margin-bottom: 30px;
    }
`;

const SectionHeading = styled.div`
    font-size: 40px;
    font-family: ${gilroyMedium};
    margin-bottom: 80px;
    ${Media('xlscreens')} {
        font-size: 2.08vw;
        margin-bottom: 4.16vw;
    }
    ${Media('tablet')} {
        font-size: 28px;
        margin-bottom: 50px;
    }
`;
const WebinarThumbnail = styled.a`
    margin-right: 40px;
    height: 175px;
    border-radius: 10px;
    img {
        height: 100%;
    }
    ${Media('xlscreens')} {
        margin-right: 2.08vw;
        height: 9.11vw;
        border-radius: 0.52vw;
    }
    ${Media('mobile')} {
        margin-right: 10px;
        height: 110px;
    }
`;
const WebinarItem = styled(ScrollAnimation)`
    display: flex;
    margin-bottom: 70px;
    ${Media('xlscreens')} {
        margin-bottom: 3.64vw;
    }
    ${Media('tablet')} {
        margin-bottom: 50px;
    }
    ${Media('mobile')} {
        margin-bottom: 30px;
    }
`;
const WebinarInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    span {
        font-size: 18px;
        font-family: ${gilroyMedium};
    }
    ${Media('xlscreens')} {
        span {
            font-size: 0.93vw;
        }
    }
    ${Media('mobile')} {
        span {
            font-size: 14px;
        }
    }
`;
const WebinarTitle = styled.div`
    font-size: 20px;
    font-family: ${gilroyMedium};
    letter-spacing: -0.2px;
    ${Media('xlscreens')} {
        font-size: 1.04vw;
        max-width: 14.3vw;
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
const WatchItButton = styled.a`
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: ${gilroyBold};
    img {
        width: 20px;
    }
    ${Media('xlscreens')} {
        font-size: 0.93vw;
        img {
            width: 1.04vw;
        }
    }
    ${Media('mobile')} {
        font-size: 14px;
        img {
            width: 16px;
        }
    }
`;

export default WebinarIndex;
