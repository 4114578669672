const nominees = [
    {
        name: 'April A. Sealy',
        company: 'Elevate',
    },
    {
        name: 'Scott Horace',
        company: 'MasterCard',
    },
    {
        name: 'Denise Adams',
        company: 'Truist Financial',
    },
    {
        name: 'Carey Wilson',
        company: 'Chime',
    },
    {
        name: 'Michael Milnes',
        company: 'Best Egg',
    },
    {
        name: 'Kudzaishe Mugara',
        company: 'TD Auto Finance LLC',
    },
    {
        name: 'Rob Strub',
        company: 'Citi',
    },
    {
        name: 'Mike Gathright',
        company: 'Hilton Worldwide',
    },
    {
        name: 'Kelly Nelson',
        company: 'Wells Fargo',
    },
    {
        name: 'Jason Entwistle',
        company: 'SiriusXM',
    },
    {
        name: 'Kara Allen',
        company: 'Zoom',
    },
    {
        name: 'Aaron Blonquist',
        company: 'Cash App',
    },
    {
        name: 'Darnell Brooks',
        company: 'The Home Depot',
    },
    {
        name: 'Len Clamp',
        company: 'Bank of America',
    },
    {
        name: 'Adam Seede',
        company: 'Ulta Beauty',
    },
    {
        name: 'Matthew Dolkart',
        company: 'Ulta Beauty',
    },
    {
        name: 'Jeff Lewis',
        company: 'Lowe’s',
    },
    {
        name: 'Megan Vogt',
        company: 'Nintendo of America',
    },
    {
        name: 'Emily Rama',
        company: 'Peloton',
    },
    {
        name: 'Rebecca Arwood',
        company: 'Roku',
    },
    {
        name: 'Sneh Hira',
        company: 'Afterpay',
    },
    {
        name: `Les O'Reilly`,
        company: 'Intuit',
    },
    {
        name: 'Linda Pools',
        company: 'Pandion',
    },
    {
        name: 'Precious Bucher',
        company: 'Zappos',
    },
    {
        name: 'Stacey Schultz',
        company: 'PayPal',
    },
    {
        name: `Alex Mesa`,
        company: 'JKBX (Juke Box)',
    },
    {
        name: 'Jamie Lancaster',
        company: 'Kroger',
    },
    {
        name: 'Howard Sharpley',
        company: 'Ancestry',
    },
    {
        name: 'Marc Shapiro',
        company: 'Altice USA',
    },
    {
        name: `Ryan Needham`,
        company: 'Carhartt',
    },
    {
        name: `Alex Rumjancevs`,
        company: 'Airbnb',
    },
    {
        name: 'Peter Andrews',
        company: 'Vrbo',
    },
    {
        name: 'Allan Lopez Quintero',
        company: 'Riot Games',
    },
    {
        name: 'Stacie Parrish',
        company: 'ID.me',
    },
    {
        name: 'Bud Homeyer',
        company: 'Interface Systems',
    },
    {
        name: 'Jon Wallgren',
        company: `Kohl's Corporation`,
    },
    {
        name: `Jack Grady`,
        company: 'American Eagle Outfitters',
    },
    {
        name: `Abhi Vijaywergiya'`,
        company: 'CarGurus',
    },
    {
        name: 'Morgan Shaw',
        company: 'AppFolio',
    },
    {
        name: 'David Bartsch',
        company: `Custom Ink`,
    },
    {
        name: `Brian Redmond`,
        company: 'Custom Ink',
    },
    {
        name: 'Xiaoxiao Cummings',
        company: 'Hisense USA',
    },
    {
        name: 'Ashleigh Hill',
        company: 'Custom Ink',
    },
    {
        name: 'John Paul Morris',
        company: `Bass Pro`,
    },
    {
        name: `Lauren Eaches`,
        company: 'CLEAR',
    },
    {
        name: 'Krystal Roloff',
        company: 'Michaels',
    },
    {
        name: 'Sheila Price',
        company: 'Nestlé',
    },
    {
        name: 'Bryan Stoller',
        company: `United Airlines`,
    },
    {
        name: 'Robert Moore',
        company: 'CardWorks',
    },
    {
        name: 'Lisa Smith',
        company: 'Optum',
    },
    {
        name: 'Daniel Thomas',
        company: 'Conifer Health Solutions, Inc.',
    },
    {
        name: 'Andrew Stein',
        company: `Chewy`,
    },
    {
        name: 'Rajiv Vellodi',
        company: 'Spotify',
    },
    {
        name: 'Kelley Cutforth',
        company: 'Chegg',
    },
    {
        name: 'Monica Perez',
        company: 'Notion',
    },
    {
        name: 'Lisa Wysocky',
        company: `Sonos`,
    },
    {
        name: 'Alicia Douglas',
        company: 'Sephora',
    },
    {
        name: 'Raymond Maccio',
        company: 'Major League Baseball',
    },
    {
        name: 'Wei Wei',
        company: 'Udemy',
    },

    {
        name: 'Lisa Wolowacki',
        company: 'Medtronic',
    },

    {
        name: 'Maureen Martin',
        company: 'WeightWatchers',
    },

    {
        name: 'Tomas Svitil',
        company: 'Oscar Health',
    },
    
    {
        name: 'Linda Westendorf',
        company: `Disney Streaming Services`,
    },
    {
        name: `Nicole Geosits`,
        company: 'Squarespace',
    },
    {
        name: 'Roger Brewer',
        company: 'Sony Electronics',
    },
    {
        name: 'Chris Wirth',
        company: 'Hyatt Hotels',
    },
    {
        name: 'Bryan Belmont',
        company: 'Microsoft',
    },
    {
        name: 'Pat Joe',
        company: 'DISH',
    },
    {
        name: 'Katie Beitzel',
        company: 'Republic Services',
    },
    {
        name: 'Scott Ireland',
        company: 'Molina Healthcare',
    },
    {
        name: 'Vince Alonge',
        company: 'Horizon BCBS',
    },
    {
        name: 'Jackie Heaven-Ah Hi',
        company: 'Cambia Health Solutions',
    },
    {
        name: 'Dennis Proctor',
        company: 'Blue Cross Blue Shield',
    },
    {
        name: 'Amy Rakoczy',
        company: 'HCSC',
    },
    {
        name: 'Allen Ng',
        company: 'Seagate Technology',
    },
    {
        name: 'Michael Walzak',
        company: 'ecoATM',
    },
    {
        name: 'Ruth Yankoupe',
        company: 'Getaround',
    },
    
];

nominees.sort(function (a, b) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
});

export default nominees;
