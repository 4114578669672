import React from 'react';

import { Para } from '../cx-pocket-guide/para';
import { Col, Row } from '@bootstrap-styled/v4';
import { BigHeading } from '../cx-pocket-guide/big-heading';
import colors from 'shared-components/theme/colors';
import styled from 'styled-components';
import { gilroyBold } from 'shared-components/theme/fonts';
import { IntroPara } from './intro-para';
import Media from 'shared-components/theme/medias';
import { Source } from './source';

export const Point3 = ({ active }) => {
    return (
        <>
            <Row>
                <Col lg="6">
                    <Wrapper>
                        <TagLine>$4,000</TagLine>
                        <IntroPara>
                            Employers in the US spend ~$4,000 on average to fill
                            each position.
                        </IntroPara>
                        <Source color={colors.white['100']} style2>
                            Source: Glassdoor
                        </Source>
                    </Wrapper>
                </Col>
                <Col lg="6">
                    <Wrapper>
                        <TagLine>$12,000</TagLine>
                        <IntroPara>
                            Now consider that a lost CX agent costs an average
                            of $12,000.
                        </IntroPara>
                        <Source color={colors.white['100']} style2>
                            Source: Deloitte
                        </Source>
                    </Wrapper>
                </Col>
            </Row>
            <BigHeading color={colors.cx_pocket_guide['pink']} typical active={active}>
                A great recruitment team can truly make or break your CX
                program.
            </BigHeading>
            <Para color={colors.white['100']}>
                A good recruiter understands how to build the right candidate
                profiles, which regions feature the ideal demographics and
                language skills for your particular program, down to the channel
                level, and how to engage in conversations with these candidates
                that surface the right indicators of success.{' '}
            </Para>
            <Para color={colors.white['100']}>
                Keep this in mind: hiring the wrong talent for the role not only
                leads to poor outcomes - it leads to higher attrition rates.
            </Para>
            <Para color={colors.white['100']}>
                At the end of the day, talent finds talent, and your recruitment
                team is where it all starts.
            </Para>
        </>
    );
};

const Wrapper = styled.div`
    ${Media('xlscreens')} {
        padding-right: 5vw;
    }
`;

const TagLine = styled.h5`
    font-size: 32px;
    font-family: ${gilroyBold};
    color: ${colors.pink['100']};
    ${Media('xlscreens')} {
        font-size: 1.66vw;
    }
    ${Media('tablet')} {
        font-size: 26px;
    }
`;
