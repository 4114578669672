import React from 'react';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';

export const SectionWrapper = ({ id, margin, children }) => {
    return (
        <Wrapper margin={margin} id={id}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.section`
    padding-top: 0px;
    ${Media('tablet')} {
        padding-bottom: 0px;
    }
    ${Media('xlscreens')} {
        margin: ${(props) => (props.margin ? '50px 0px' : '0px')};
    }
`;
