import React from 'react';
import Loader from 'components/loader/loader';
import LazyLoad from 'react-lazyload';
import { baseURL } from 'shared-components/theme/helpers';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import RetailIntro from 'components/retail/intro-section';
import {
    TransformSolution,
    AmplifyBottomSection,
    PerformanceSection,
} from './sections';
import { BigCircle } from 'components/industries/common';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -210,
    },
    {
        name: 'cx-solution',
        target: 'cx-solution',
        offset: -50,
    },
    {
        name: 'cx-performance',
        target: 'cx-performance',
        offset: -50,
    },
    {
        name: 'amplify-section',
        target: 'amplify-section',
        offset: -50,
    },
];
const financialSolutions = [
    {
        imageUrl: `${baseURL}assets/images/industries/travel-solution1.jpg`,
        title:
            'Omnichannel Solutions To Reach Your Customers Wherever They Are ',
        para: `ibex builds customizable, scalable, agile CX performance technology to drive seamless, best-in-class experiences across call, chat, email, and social support wherever your customers are. `,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/travel-solution2.jpg`,
        title: 'Burst Capacity CX Engine, Ready for Takeoff ',
        para: `We pair a global network of flexible in-center and @home CX teams with our elite workforce management operation to deliver unmatched, predictable CX outcomes, even through seasonal peaks, reducing risk and increasing ROI through the busiest months of your CX calendar.  `,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/travel-solution3.jpg`,
        title: 'Build and Grow Customer Loyalty ',
        para: `With expertise supporting the world’s leading Travel & Hospitality brands, we’re experts in First Contact Resolution, and with a dedicated Digital Marketing team for search & social, we’re ready to add new customers to your database while keeping existing clients coming back for every trip. `,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/travel-solution4.jpg`,
        title: 'Next-Level, <br /> Data-Driven CX ',
        para: `Discover valuable insights from your customer interactions to reveal areas for improvement while adding even more value across every journey touchpoint.  Mitigate CX risk, optimize costs and drive next-level CX with our Business Intelligence expertise. `,
    },
];
const performanceList = [
    {
        imgUrl: `${baseURL}assets/images/industries/th-cx-icon1.svg`,
        title: `Omnichannel CX Support & Execution `,
        list: [
            'Chat, Email, Social, & Voice Support',
            'Technical Support',
            'Account Management Support',
            'Digital Marketing',
            'Content Moderation',
        ],
    },
    {
        imgUrl: `${baseURL}assets/images/industries/th-cx-icon2.svg`,
        title: `Back Office Support`,
        list: [
            'Finance',
            'Accounting and Payroll',
            'Human Resources',
            'Recruitment and Training',
            'Workforce Management',
            'Marketing',
            'Data Management',
        ],
    },
    {
        imgUrl: `${baseURL}assets/images/industries/th-cx-icon3.svg`,
        title: `Digital Native Workforce`,
        list: [
            'Precision-Recruitment & Global Talent Pool',
            '@Home & In-Center Flexibility',
            'Weekly Engagement & Brand Affinity Programs',
            'Real-Time Coaching & Desktop Monitoring',
            'KPI Gamification & Incentivization',
            'Purpose-Built Agent Performance Toolset',
        ],
    },
    {
        imgUrl: `${baseURL}assets/images/industries/th-cx-icon4.svg`,
        title: `Wave X Technology Platform`,
        list: [
            'Agent Training Simulators',
            'Remote Real-Time Agent Monitoring',
            'End-to-End System Security',
            'Social Reputation Management',
            'Customer Experience Surveys',
            'Wave 0: Upfront Leadership Training Experiential Learning',
            'Virtual Training Simulation',
            'Performance Gamification',
            'Microlearning',
        ],
    },
    {
        imgUrl: `${baseURL}assets/images/industries/th-cx-icon5.svg`,
        title: `KPI-Ready PMO & Implementation Team`,
        list: [
            'Forecasting For Seasonal Peaks',
            'Workforce Planning',
            'Proactive Insights',
            'Real-Time Performance Adjustments',
            'Cost & Quality Analytics',
        ],
    },
    {
        imgUrl: `${baseURL}assets/images/industries/th-cx-icon6.svg`,
        title: `Predictive Performance <br /> Management & BI Operation `,
        list: [
            'Voice of Customer Analytics',
            'Glide Path Modeling',
            'Channel Mapping & Optimization',
            'Value Stream Mapping',
            'Complexity Mapping',
            'Customer Journey Mapping',
            'Loyalty and survey management solutions',
            'Travel Customer Care',
        ],
    },
];
const amplifyList = [
    {
        icon: `${baseURL}assets/images/industries/rocket.svg`,
        title: 'Speed.',
        para: `We build your CX teams to hit key KPIs quickly and consistently, ensuring there’s no gap in CX output once ibex is switched on. `,
    },
    {
        icon: `${baseURL}assets/images/industries/icon-awesome-check.svg`,
        title: 'Scalability.',
        para: `Whether it’s peak travel season or planning season, our teams are ready to wind up or wind down depending on your needs. We provide continuous support and performance for any situation, at any leg of the journey. `,
    },
    {
        icon: `${baseURL}assets/images/industries/chameleon.svg`,
        title: 'Flexibility.',
        para: `Our technology suite and deep industry expertise enable us to seamlessly pivot our operation at a moment’s notice to align with expanding work types or LOBs, optimizing quickly for shifting growth needs, issue demands, and changing CX channel requirements. `,
    },
    {
        icon: `${baseURL}assets/images/industries/crystal-ball.svg`,
        title: 'Predictability.',
        para: `Delivering consistent performance outcomes time and time again depends on a battle-tested methodology for repeatable, predictable, and reliable program execution that you can set your watch to.`,
    },
];

const TravelHospitality = () => {
    const sections = [
        {
            component: <TransformSolution solutions={financialSolutions} />,
            isScrollNav: false,
        },
        {
            component: <PerformanceSection performanceList={performanceList} />,
            isScrollNav: false,
        },
        {
            component: <AmplifyBottomSection amplifyList={amplifyList} />,
            isScrollNav: true,
        },
    ];

    return (
        <Layout>
            <Breadcrumbs>
                <li>Industry</li>
                <li>Travel & Hospitality</li>
            </Breadcrumbs>
            <BigCircle
                imgUrl={`${baseURL}assets/images/industries/travel-plane-icon.svg`}
                imgHeight={773}
                top="6%"
                right="36%"
                zIndex={'1'}
            />
            <RetailIntro
                rightImg={`${baseURL}assets/images/industries/travel-hospital-intro.png`}
                tag="INDUSTRY"
                introHeading="Travel & <br/>Hospitality"
                introPara="From couch to coast, create unforgettable customer <br/>experiences for every step of your customer’s journey."
                noButton={true}
                right="-9%"
                top="-10%"
                imgWidth="63.6%"
                zIndex="0"
                id="home"
            />
            {sections &&
                sections.map((section, index) => {
                    return (
                        <div
                            key={index}>
                            {section.isScrollNav && (
                                <ScrollNav data={scrollNavData} />
                            )}
                            {section.component}
                        </div>
                    );
                })}
        </Layout>
    );
};
export default TravelHospitality;
