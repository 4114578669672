import React from 'react';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';

const HeadingTag = ({ title }) => {
    return (
        <HeadingsTag>
            <img
                src={`${baseURL}assets/images/wave-ix/ix_ico.svg`}
                alt="ibex"
                width={46}
                height={34}
                className="img-fluid"
            />
            <div>
                <span>{title}</span>
                <sup>AI</sup>
            </div>
        </HeadingsTag>
    );
};

const HeadingsTag = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #030323;
    border-radius: 50px;
    padding: 8px 28px;
    max-width: max-content;
    margin-bottom: 30px;
    position: relative;

    ${Media('tabletlandscape')} {
        padding: 6px 18px;
        gap: 2px;
    }

    span {
        font-size: 34px;
        font-weight: 600;
        ${Media('xlscreens')} {
            font-size: 1.771vw;
        }
        ${Media('tabletlandscape')} {
            font-size: 18px;
        }
    }
    sup {
        font-size: 18px;
        font-weight: 700;
        text-transform: capitalize;
        background: linear-gradient(145deg, #fa0060, #7e17c0);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transform: translateY(-8px);
        display: inline-block;
        margin-left: 6px;

        ${Media('tabletlandscape')} {
            font-size: 14px;
            transform: translateY(-4px);
        }
    }
`;

export default HeadingTag;