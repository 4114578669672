import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import {
    gilroySemibold,
    gilroyLight,
} from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';

const WhoTheyAre = ({ contentImage, styleTwo, content }) => {
    return (
        <ScrollAnimation animateIn="fadeInUp">
            <Wrapper>
                <Container>
                    <CustomRow>
                        <Col lg={styleTwo ? '5' : '6'} md="12">
                            <ImageBox>
                                <img src={contentImage} alt="" />
                            </ImageBox>
                        </Col>
                        <Col lg="6" md="12">
                            <TextContentWrapper>
                                <TextContentArea>
                                    {ReactHtmlParser(content)}
                                </TextContentArea>
                            </TextContentWrapper>
                        </Col>
                    </CustomRow>
                </Container>
            </Wrapper>
        </ScrollAnimation>
    );
};
const CustomRow = styled(Row)`
    ${Media('xlscreens')} {
        align-items: center;
    }
`;

const Wrapper = styled.section`
    margin-top: 50px;
    padding: 0px;
    ${Media('xlscreens')} {
        margin-top: 2.61vw;
    }
`;
const ImageBox = styled.div`
    img {
        width: 100%;
        height: 100%;
    }
`;

const TextContentWrapper = styled.div`
    display: flex;
    height: 100%;
    ${(props) => {
        if (props.multiPara) {
            return `justify-content:space-around;
                    flex-direction:column;`;
        } else {
            return ` justify-content:center;`;
        }
    }}
`;

const TextContentArea = styled.div`
    padding-left: 100px;
    ${Media('xlscreens')} {
        padding-left: 4.5vw;
        margin-bottom: ${(props) => (props.marginBottom ? '4vw' : '0')};
    }
    ${(props) => {
        if (!props.multiPara) {
            return `padding-top:100px;
                ${Media('xlscreens')}{
                    padding-top:2.5vw;
                }
                ${Media('tablet')}{
                    padding-top:20px;
                }
            `;
        }
    }}
    h5 {
        margin: 0px;
    }
    h2 {
        line-height: 1.34;
        letter-spacing: -0.43px;
        ${Media('xlscreens')} {
            margin-bottom: 0;
        }
    }
    p {
        font-family: ${gilroyLight};
        font-size: 24px;
        line-height: 1.75;
        br {
            display: none;
        }
        span,
        b,
        strong {
            font-family: ${gilroySemibold};
        }
        ${Media('xlscreens')} {
            font-size: 1.2vw;
            max-width: 25vw;
        }
        ${Media('tablet')} {
            max-width: 100%;
            font-size: 18px;
            margin: 0px;
            br {
                display: none;
            }
        }
    }
    ${Media('tablet')} {
        padding-left: 0px;
        margin-top: 40px;
    }
`;

export default WhoTheyAre;
