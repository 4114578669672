import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { gilroyBold, gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';
import { EbookFloatingImage } from 'components/ebook/common';
import { SAPageHeading, SAPagePara } from '../common';

const processList = [
    {
        title: 'Identify The Gaps',
        desc:
            'ibex works with the client to identify the main scope of the project and the exact requirements requested.',
    },
    {
        title: 'Sourcing Your Talent',
        desc:
            'ibex begins sourcing talent to best fit your needs and create a candidate matrix to visualize your talent pool.',
    },
];
const processList2 = [
    {
        title: 'Selecting Your Hire',
        desc:
            'You will be presented with the candidate matrix to review the resumes of identified top talent and make your final selection.',
    },
    {
        title: 'Ramp to Success',
        desc:
            'Your hire will be briefed on your processes and ramped seamlessly on to your team and/or project.',
    },
    {
        title: 'Moving Forward',
        desc:
            'We’re here to support every move. Scale up or down as needed with flexible options to adapt as your project moves forward with additional talent and skills as required.',
    },
];

export const SAProcedureSection = () => {
    return (
        <ProcedureSection id="procedure">
            <EbookFloatingImage
                image={`${baseURL}assets/images/staff_aug/process-img.png`}
                width={1100}
                right="0"
                top="-7.2%"
                x={['0', '0']}
            />
            <EbookFloatingImage
                image={`${baseURL}assets/images/staff_aug/staff-process-dots.svg`}
                width={221}
                right="15%"
                top="-8%"
                y={['-20%', '20%']}
            />
            <Container>
                <Row>
                    <Col sm="12" lg="4">
                        <div>
                            <ProcessHead>
                                <h5>How We Work</h5>
                                <SAPageHeading color={colors.white['100']}>
                                    The Process
                                </SAPageHeading>
                            </ProcessHead>
                        </div>
                        <div>
                            {processList &&
                                processList.length > 0 &&
                                processList.map((item, index) => {
                                    return (
                                        <ProcessBox key={index}>
                                            <h4>{index + 1}</h4>
                                            <span>
                                                <h5>{item.title}</h5>
                                                <SAPagePara
                                                    size="small"
                                                    color={colors.white['100']}>
                                                    {item.desc}
                                                </SAPagePara>
                                            </span>
                                        </ProcessBox>
                                    );
                                })}
                        </div>
                    </Col>
                </Row>
                <Row>
                    {processList2 &&
                        processList2.length > 0 &&
                        processList2.map((item, index) => {
                            return (
                                <Col sm="12" lg="4" key={index}>
                                    <div>
                                        <ProcessBox>
                                            <h4>{index + 3}</h4>
                                            <span>
                                                <h5>{item.title}</h5>
                                                <SAPagePara
                                                    size="small"
                                                    color={colors.white['100']}>
                                                    {item.desc}
                                                </SAPagePara>
                                            </span>
                                        </ProcessBox>
                                    </div>
                                </Col>
                            );
                        })}
                </Row>
            </Container>
        </ProcedureSection>
    );
};

const ProcedureSection = styled.section`
    background-color: ${colors.fintech['text']};
    position: relative;
    margin-top: 80px;
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 80px;
        background-color: ${colors.fintech['text']}0A;
        top: -80px;
        left: 0px;
    }
    ${Media('xlscreens')} {
        padding-top: 7vw;
        margin-top: 5vw;
        &:before {
            height: 5vw;
            top: -5vw;
        }
        .container {
            padding-left: 10vw !important;
            padding-right: 10vw !important;
        }
    }
`;
const ProcessHead = styled.span`
    h2 {
        font-size: 60px;
        font-family: ${gilroyBold};
    }
    h5 {
        color: ${colors.pink['100']};
        font-size: 24px;
        font-weight: ${gilroySemibold};
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 10px;
        margin-top: 0px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 1vw;
        h5 {
            letter-spacing: 0.3vw;
        }
        h2 {
            font-size: 4vw;
        }
    }
`;
const ProcessBox = styled.div`
    display: flex;
    margin-top: 70px;
    h4 {
        font-size: 103px;
        color: ${colors.pink['100']};
        margin: 0;
        min-width: 80px;
        line-height: 80px;
    }
    span {
        h5 {
            color: ${colors.pink['100']};
            text-transform: uppercase;
            font-size: 25px;
            margin: 0;
            margin-bottom: 20px;
            font-family: ${gilroyBold};
        }
    }

    ${Media('xlscreens')} {
        margin-top: 4.5vw;
        max-width: 85%;
        h4 {
            font-size: 5.36vw;
            min-width: 4.16vw;
            line-height: 4.16vw;
        }
        span {
            h5 {
                font-size: 1.3vw;
            }
        }
    }
    ${Media('tablet')} {
        h4 {
            font-size: 80px;
        }
        span {
            h5 {
                font-size: 20px;
                margin-bottom: 10px;
            }
        }
    }
`;
