import React, { useState, useEffect, useRef } from 'react';
import Layout from 'components/layout';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import {
    akiraSuperBold,
    gilroyLight,
    gilroyMedium,
    gilroyRegular,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import { Container } from '@bootstrap-styled/v4';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { LetsDiscuss } from 'components/industries/common';
import {
    CXPoint1,
    CXPoint2,
    CXPoint3,
    CXPoint4,
    CXPoint5,
    CXPoint6,
    CXPoint7,
    MainIntro,
} from 'components/ebook/cx-pocket-guide';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';

const CXPocketGuide = () => {
    const [activeIndexes, setActiveIndex] = useState([]);

    const handleAccordion = (index) => {
        const arr = [...activeIndexes];
        if (arr && arr.includes(index)) {
            const filter = arr.filter((i) => i !== index);
            setActiveIndex(filter);
        } else {
            arr.push(index);
            setActiveIndex(arr);
        }
    };

    const cxSections = [
        {
            heading: 'Employee Experience (EX) as a Prerequisite for Success',
            component: <CXPoint1 active={activeIndexes.includes(0)} />,
        },
        {
            heading: 'Recruitment As a Strategic Business Driver',
            component: <CXPoint2 active={activeIndexes.includes(1)} />,
        },
        {
            heading: 'Training As A Talent Amplifier',
            component: <CXPoint3 active={activeIndexes.includes(2)} />,
        },
        {
            heading: 'Technology That Empowers Interaction Performance',
            component: <CXPoint4 active={activeIndexes.includes(3)} />,
        },
        {
            heading: 'Business Intelligence That Powers Predictive Action',
            component: <CXPoint5 active={activeIndexes.includes(4)} />,
        },
        {
            heading: 'Workforce Management That Enables Continuous Performance',
            component: <CXPoint6 active={activeIndexes.includes(5)} />,
        },
        {
            heading: 'Leaned-In Leadership',
            component: <CXPoint7 active={activeIndexes.includes(6)} />,
        },
    ];

    useEffect(() => {}, [activeIndexes]);
    return (
        <Layout>
            <SidePadding>
                <MainIntro
                    heading=" 7 Best Practices For Launching <br />
                    <i>A winning CX Program</i>
                    <br />
                    <u>
                        That Hits KPIs <span>Fast</span>
                    </u>"
                    para="After hundreds of successful CX program
                    launches, we’re sharing our best
                    practices <br />
                    for ensuring your program launch is
                    setup for success - before, during, and
                    after launch."
                />
            </SidePadding>
            <CXProgramme>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    <SidePadding>
                        <h2>
                            The outcome of most CX partnerships is determined
                            before a program ever launches.
                        </h2>
                        <p>
                            For every new CX program, success is contingent on
                            your contact center provider’s ability to hit
                            performance targets quickly. Switching providers can
                            carry a significant cost burden for brands, and
                            customers do not have the patience to be CX Guinea
                            Pigs.
                        </p>
                    </SidePadding>
                </ScrollAnimation>
            </CXProgramme>
            <PulseCheck>
                <SidePadding>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <SectionTag
                            bg={colors.cx_pocket_guide['dark']}
                            color={colors.pink['100']}>
                            Pulse check
                        </SectionTag>
                        <h3>
                            92% of users expect to find answers in 10 minutes or
                            less, indicating that they have high standards and
                            low patience when it comes to troubleshooting
                            problems.
                        </h3>
                        <Source>Source: Frost & Sullivan</Source>
                    </ScrollAnimation>
                </SidePadding>
            </PulseCheck>
            <SuccessPoints>
                <Container>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <h2>
                            To make sure you’re set up for Day 1 success, here
                            are the 7 critical factors for hitting CX
                            performance benchmarks, at speed.
                        </h2>
                    </ScrollAnimation>
                </Container>
                <CollapseWrapper>
                    {cxSections &&
                        cxSections.length &&
                        cxSections.map((section, index) => {
                            const refer = useRef();
                            return (
                                <Accordion
                                    key={index}
                                    active={activeIndexes.includes(index)}
                                    height={refer?.current?.clientHeight}>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={true}>
                                        <AccHeader
                                            onClick={() =>
                                                handleAccordion(index)
                                            }>
                                            <p>
                                                <span>{'0' + (index + 1)}</span>
                                                {section?.heading}
                                            </p>
                                            {(activeIndexes.includes(index) && (
                                                <FaMinus />
                                            )) || <FaPlus />}
                                        </AccHeader>
                                    </ScrollAnimation>

                                    <AccBody>
                                        <InnerBody ref={refer}>
                                            <SectionNumber
                                                active={activeIndexes.includes(
                                                    index
                                                )}>
                                                {'0' + (index + 1)}
                                            </SectionNumber>
                                            {section.component}
                                        </InnerBody>
                                    </AccBody>
                                </Accordion>
                            );
                        })}
                </CollapseWrapper>
            </SuccessPoints>
            <Conclusion>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    <SidePadding>
                        <h3>CONCLUSION</h3>
                        <p>
                            Success at speed is an output of expert people and
                            processes at the core of a well-honed CX operation.
                            If you’re looking to build a new CX program, or
                            revamp an existing one, consider a contact center
                            provider with proven expertise in delivering
                            successful CX programs, fast – and at scale.
                        </p>
                    </SidePadding>
                </ScrollAnimation>
            </Conclusion>
            <LetsDiscuss
                heading="Ready to Speed-Up Your CX Performance?"
                para="Let’s get you built for speed, scale, and success."
                btnText="Connect With Us"
                bgColor={colors.pink['100']}
                btnDark
                btnBgColor={colors.cx_pocket_guide['dark']}
            />
        </Layout>
    );
};

const SidePadding = styled.div`
    padding: 0px 140px;
    position: relative;
    ${Media('xlscreens')} {
        padding: 0 7.29vw;
    }
    ${Media('tablet')} {
        padding: 0 30px;
    }
    ${Media('mobile')} {
        padding: 0 15px;
    }
`;

const SectionTag = styled.div`
    font-size: 24px;
    font-family: ${gilroySemibold};
    background-color: ${colors.cx_pocket_guide['dark']};
    display: inline-flex;
    padding: 10px 25px;
    color: ${(props) => (props.color ? props.color : colors.white['100'])};
    text-transform: uppercase;
    ${(props) => {
        if (props.introTag) {
            return `
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                ${Media('xlscreens')}{
                    border-bottom-left-radius: 0.53vw;
                    border-bottom-right-radius: 0.53vw;
                }
            `;
        } else {
            return `
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                ${Media('xlscreens')}{
                    border-top-left-radius: 0.53vw;
                    border-top-right-radius: 0.53vw;
                }
            `;
        }
    }}
    ${Media('xlscreens')} {
        font-size: 0.8vw;
        padding: 0.52vw 1.3vw;
    }
    ${Media('tablet')} {
        font-size: 16px;
    }
    ${Media('mobile')} {
        font-size: 14px;
        padding: 10px 20px;
    }
`;

const CXProgramme = styled.section`
    background-color: ${colors.cx_pocket_guide['dark']};
    margin-top: -400px;
    padding: 350px 250px 100px 100px;
    h2 {
        font-size: 44px;
        font-family: ${gilroyMedium};
        color: ${colors.white['100']};
        line-height: 1.47;
    }
    p {
        font-size: 38px;
        color: ${colors.white['100']};
        font-family: ${gilroyLight};
        margin-top: 65px;
        line-height: 1.47;
    }
    ${Media('xlscreens')} {
        margin-top: -20.8vw;
        padding: 18.22vw 13vw 5.2vw 5.2vw;
        h2 {
            font-size: 2.29vw;
        }
        p {
            font-size: 1.97vw;
            margin-top: 3.38vw;
        }
    }
    ${Media('tablet')} {
        padding: 50px 0;
        margin-top: 0;
        h2 {
            font-size: 26px;
        }
        p {
            font-size: 20px;
            margin-top: 30px;
        }
    }
    ${Media('mobile')} {
        padding: 30px 0;
        h2 {
            font-size: 22px;
        }
        p {
            font-size: 16px;
        }
    }
`;

const PulseCheck = styled.section`
    background-color: ${colors.pink['100']};
    padding-left: 100px;
    h3 {
        font-size: 50px;
        font-family: ${akiraSuperBold};
        line-height: 1.48;
        text-decoration: underline;
        margin-top: 60px;
        word-spacing: 2px;
    }
    a {
        font-size: 24px;
        font-family: ${gilroySemibold};
        img {
            display: inline-flex;
            margin-right: 40px;
        }
        &:hover {
            color: ${colors.black['100']};
            text-decoration: underline;
        }
    }
    ${Media('xlscreens')} {
        padding-left: 5.2vw;
        h3 {
            font-size: 2.6vw;
            margin-top: 3.125vw;
        }
        a {
            font-size: 1.25vw;
            img {
                margin-right: 2.08vw;
            }
        }
    }
    ${Media('tablet')} {
        padding-left: 0;
        h3 {
            font-size: 32px;
        }
        a {
            font-size: 18px;
            img {
                margin-right: 20px;
            }
        }
    }
    ${Media('mobile')} {
        padding: 40px 0;
        h3 {
            margin-top: 30px;
            font-size: 20px;
        }
        a {
            font-size: 16px;
        }
    }
`;

const Source = styled.h5`
    font-size: 24px;
    font-family: ${gilroySemibold};
    margin: 0;
    margin-top: 20px;
    ${Media('xlscreens')} {
        font-size: 1.25vw;
        margin-top: 4vw;
    }
    ${Media('tablet')} {
        font-size: 16px;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;

const SuccessPoints = styled.section`
    position: relative;
    padding-left: 100px;
    padding-right: 100px;
    h2 {
        font-size: 42px;
        font-family: ${gilroyLight};
        line-height: 1.47;
        margin-bottom: 80px;
        word-spacing: 3px;
    }
    ${Media('xlscreens')} {
        padding-left: 5.2vw;
        padding-right: 5.2vw;
        h2 {
            font-size: 2.18vw;
            margin-bottom: 4.16vw;
        }
    }
    ${Media('tablet')} {
        padding-left: 30px;
        padding-right: 30px;
        h2 {
            font-size: 28px;
        }
    }
    ${Media('mobile')} {
        padding-left: 0;
        padding-right: 0;
        h2 {
            font-size: 22px;
        }
    }
`;

const CollapseWrapper = styled(Container)``;

const AccHeader = styled.button`
    margin-bottom: 70px;
    position: relative;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${colors.cx_pocket_guide['dark_gray']};
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${colors.black['100']};
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    p {
        color: inherit;
        font-size: 30px;
        font-family: ${gilroySemibold};
        margin: 0;
    }
    span {
        margin-right: 60px;
    }
    svg {
        height: 30px;
        width: 30px;
    }
    &:hover {
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        margin-bottom: 3.6vw;
        padding-bottom: 1.3vw;
        p {
            font-size: 1.57vw;
        }
        span {
            margin-right: 3.125vw;
        }
        svg {
            height: 1.57vw;
            width: 1.57vw;
        }
    }
    ${Media('tablet')} {
        p {
            font-size: 20px;
            text-align: left;
        }
        svg {
            height: 20px;
            width: 20px;
        }
    }
    ${Media('mobile')} {
        p {
            font-size: 14px;
            max-width: 90%;
            text-align: left;
        }
        span {
            margin-right: 10px;
        }
    }
`;

const AccBody = styled.div`
    height: 0px;
    min-height: 0;
    transition: all 500ms ease 200ms;
    overflow: hidden;
`;

const Accordion = styled.div`
    position: relative;
    ${(props) => {
        if (props.active) {
            return `
                ${AccBody}{
                    height:${
                        props.height ? props.height + 100 + 'px' : '500px'
                    };
                }
            `;
        }
    }}
`;

const InnerBody = styled.div``;

const SectionNumber = styled.h3`
    font-size: 400px;
    color: ${colors.pink['100']};
    font-family: ${gilroyMedium};
    position: absolute;
    left: -43%;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 500ms ease 200ms;
    ${(props) => {
        if (props.active) {
            return `
                opacity:1;
                visibility:visible;
            `;
        }
    }}
    ${Media('xlscreens')} {
        font-size: 20.8vw;
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const Conclusion = styled.section`
    background-color: ${colors.cx_pocket_guide['dark']};
    padding-left: 150px;
    padding-right: 150px;
    h3 {
        color: ${colors.pink['100']};
        text-decoration: underline;
        font-size: 50px;
        font-family: ${akiraSuperBold};
    }
    p {
        color: ${colors.white['100']};
        font-size: 42px;
        line-height: 1.47;
        text-decoration: underline;
        font-family: ${gilroyRegular};
    }
    ${Media('xlscreens')} {
        padding-left: 7.8vw;
        padding-right: 7.8vw;
        h3 {
            font-size: 2.6vw;
        }
        p {
            font-size: 2.18vw;
        }
    }
    ${Media('tablet')} {
        padding: 50px 30px;
        h3 {
            font-size: 32px;
        }
        p {
            font-size: 22px;
        }
    }
    ${Media('mobile')} {
        padding-left: 0;
        padding-right: 0;
        h3 {
            font-size: 24px;
        }
        p {
            font-size: 18px;
        }
    }
`;

export default CXPocketGuide;
