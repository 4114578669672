import React from 'react';
import { baseURL } from 'shared-components/theme/helpers';
import { BigHeading } from './big-heading';
import { BlockHeading } from './block-heading';
import { CXSectionIntro } from './cx-section-intro';
import { Para } from './para';

export const CXPoint2 = ({ active }) => {
    return (
        <div>
            <CXSectionIntro
                active={active}
                progress={0.52}
                source="Source: IDC"
                progressImg={`${baseURL}assets/images/ebook/cx-progress2.svg`}
                introPara="<span>For 52% of talent acquisition leaders,</span> the most challenging aspect of their work is screening individuals from a vast application pool to find the best-fit candidates."
            />
            <BigHeading>
                For a CX outsourcer to quickly become proficient with your customer support requirements, they must be able to expertly select from top geographic talent pools. Efficient recruitment is the core building block for program performance, speed, and revenue/ROI.
            </BigHeading>
            <Para>
                With this in mind, evaluate the reputation of your outsourcer via third party sources, as well as their employee retention rate. Consider the following:
            </Para>
            <BlockHeading>Regional Expertise</BlockHeading>
            <Para>
                Make sure your CX outsourcer has had extensive success in the
                regions you’re aiming to deploy in, and that they are an
                employer of choice. Take particular note of their top recruiting
                channels. A true employer of choice will have a majority of
                their applicants coming from employee referrals and social
                media.
            </Para>
            <BlockHeading>Recruitment Engine</BlockHeading>
            <Para>
                Great employers are able to ingest, analyze, and onboard high
                volumes of qualified candidates quickly. This will be critical
                to your program’s speedy success.
            </Para>
            <BlockHeading>Automation</BlockHeading>
            <Para>
                Leveraging AI to filter high volumes of applications will ease
                the burden on a recruitment team – is your CX outsourcer
                leveraging automation in the hiring process? How are they
                screening prospective candidates?
            </Para>
            <BlockHeading>eNPS, Retention, and Attrition</BlockHeading>
            <Para>
                Ensure your CX outsourcer has a proven track record when it
                comes to their eNPS score, employee tenure, and that they’re
                investing in employee growth and retention programs.
            </Para>
        </div>
    );
};
