import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import { gilroyBold, gilroySemibold } from 'shared-components/theme/fonts';
import SlickSlider from 'shared-components/slider/slider';
import ScrollAnimation from 'react-animate-on-scroll';
import { baseURL } from 'shared-components/theme/helpers';

const data = [
    {
        image: `${baseURL}assets/images/ibex-agent.jpg`,
        company: 'ibex',
        title: 'Agent',
    },
    {
        image: `${baseURL}assets/images/ibex-manager.jpg`,
        company: 'ibex',
        title: 'Manager',
    },
    {
        image: `${baseURL}assets/images/ibex-trainer.jpg`,
        company: 'ibex',
        title: 'Trainer',
    },
    {
        image: `${baseURL}assets/images/ibex-leader.jpg`,
        company: 'ibex',
        title: 'Leader',
    },
    {
        image: `${baseURL}assets/images/ibex-marketer.jpg`,
        company: 'ibex',
        title: 'Marketer',
    },
    {
        image: `${baseURL}assets/images/ibex-manager2.jpg`,
        company: 'ibex',
        title: 'Manager',
    },
    {
        image: `${baseURL}assets/images/ibex-agent2.jpg`,
        company: 'ibex',
        title: 'Agent',
    },
    {
        image: `${baseURL}assets/images/ibex-trainer2.jpg`,
        company: 'ibex',
        title: 'Trainer',
    },
    {
        image: `${baseURL}assets/images/ibex-technologist2.jpg`,
        company: 'ibex',
        title: 'Technologist',
    },
    {
        image: `${baseURL}assets/images/ibex-technologist.jpg`,
        company: 'ibex',
        title: 'Technologist',
    },
];

const JoinUsBlog = () => {
    const settingsBlog = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        centerMode: false,
        centerPadding: '0px',
        focusOnSelect: false,
        autoplay: false,
        autoplaySpeed: 3000,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    variableWidth: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                },
            },
        ],
    };

    return (
        <div>
            <Wrapper>
                <Container fluid={true}>
                    <Row>
                        <Col md="12">
                            <div>
                                <BlogSlider>
                                    <SlickSlider settings={settingsBlog}>
                                        {data && data.length > 0
                                            ? data.map((item, index) => {
                                                  return (
                                                      <BlogBox key={index}>
                                                          <BlogImg>
                                                              <img
                                                                  src={
                                                                      item.image
                                                                  }
                                                                  alt="-"
                                                              />
                                                          </BlogImg>
                                                          <BlogContent>
                                                              <span>
                                                                  {item.company}
                                                              </span>
                                                              <h4>
                                                                  {item.title}
                                                              </h4>
                                                          </BlogContent>
                                                      </BlogBox>
                                                  );
                                              })
                                            : null}
                                    </SlickSlider>
                                </BlogSlider>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Wrapper>
        </div>
    );
};

const Wrapper = styled.div`
    padding: 60px 0 50px 240px;
    ${Media('xlscreens')} {
        padding-left: 19.2vw;
        overflow: hidden;
    }
    ${Media('tablet')} {
        padding: 50px 0 50px 0px;
    }
`;

const BlogSlider = styled.div`
    margin-top: 70px;
    margin-bottom: 30px;
    position: relative;
    .slick-arrow {
        position: absolute;
        left: -11vw;
        top: calc(25% - 18px);
        z-index: 99;
        &.slick-next {
            transform: translateY(50px);
        }
        ${Media('large')} {
            top: calc(25% - 25px);
            &.slick-next {
                transform: translateY(62px);
            }
        }
    }
    ${Media('tablet')} {
        margin-top: 50px;
        margin-bottom: 50px;
        .slick-arrow {
            left: calc(50% - 50px);
            top: auto;
            bottom: -30px;
            &.slick-next {
                transform: translateX(50px);
            }
        }
    }
`;

const BlogBox = styled.div`
    padding-right: 40px;
    ${Media('tablet')} {
        padding: 0px;
    }
`;

const BlogImg = styled.div`
    height: 319px;
    width: 319px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition-duration: 0.4s;
        filter: grayscale(100%);
    }
    &:hover {
        img {
            filter: grayscale(0%);
        }
    }
    ${Media('xlscreens')} {
        height: 16.78vw;
        width: 16.78vw;
    }
    ${Media('mobile')} {
        height: 100%;
        width: 100%;
    }
`;

const BlogContent = styled.div`
    padding: 20px 0 20px;
    ${Media('xlscreens')} {
        padding-top: 1.05vw;
    }
    span {
        font-size: 24px;
        color: ${colors.pink['100']};
        display: block;
        font-family: ${gilroyBold};
        ${Media('xlscreens')} {
            font-size: 1.26vw;
        }
    }
    h4 {
        font-size: 24px;
        margin-bottom: 15px;
        margin-top: 15px;
        font-family: ${gilroySemibold};
        ${Media('xlscreens')} {
            font-size: 1.26vw;
        }
    }
`;

export default JoinUsBlog;
