import React from 'react';
import styled from 'styled-components';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const UtilStepHeadText = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.h4`
    font-size: 24px;
    font-family: ${gilroyBold};
    line-height: 1.4;
    text-align: center;
    margin-top: 0px;
    ${Media('xlscreens')} {
        font-size: 1.55vw;
        margin-bottom: 4.7vw;
    }
    ${Media('mobile')} {
        font-size: 20px;
    }
`;
