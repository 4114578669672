import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import MainPara from 'shared-components/main-para/main-para';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { PageHeading } from './page-heading';
import DigitalManage from 'components/at-home/at-home-slider';
import {
    gilroyLight,
    gilroySemibold,
    gilroyMedium,
} from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

export const EndtoEndSection = () => {
    return (
        <>
            <EndToEndSection>
                <Container>
                    <Row>
                        <Col lg="6">
                            <ScrollAnimation
                                delay={100}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <PageHeading noMargin={true}>
                                    End-to-End Virtual <br /> Training &
                                    Performance.
                                </PageHeading>
                            </ScrollAnimation>
                        </Col>
                        <Col lg={{ size: 5, offset: 1 }}>
                            <ScrollAnimation
                                delay={200}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <MainPara>
                                    Working effectively at home requires a
                                    tailored virtual agent training methodology.
                                    Leveraging both synchronous and asynchronous
                                    training models, our agents are prepared to
                                    act and adapt according to every situation,
                                    equipped with engagement and communication
                                    tools to ensure speed, agility, and success.
                                </MainPara>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4" md="6">
                            <ScrollAnimation
                                delay={100}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <BoxEndToEnd>
                                    <ImageBox>
                                        <img
                                            src={`${baseURL}assets/images/virtual-agent-training.png`}
                                            alt="-"
                                        />
                                    </ImageBox>
                                    <h3>Virtual Agent Training</h3>
                                    <ul>
                                        <li>LMS & Virtual Training Tools</li>
                                        <li>Fraud & Compliance Training</li>
                                        <li>Virtual Communication Training </li>
                                        <li>
                                            Brand Product & Service Training
                                        </li>
                                        <li>
                                            Training Simulators with
                                            Gamification
                                        </li>
                                    </ul>
                                </BoxEndToEnd>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="4" md="6">
                            <ScrollAnimation
                                delay={200}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <BoxEndToEnd>
                                    <ImageBox>
                                        <img
                                            src={`${baseURL}assets/images/vst.png`}
                                            alt="-"
                                        />
                                    </ImageBox>
                                    <h3>Virtual Supervisor Training </h3>
                                    <ul>
                                        <li>Virtual Agent Support Training</li>
                                        <li>Virtual Team Monitoring</li>
                                        <li>Performance Training</li>
                                        <li>Training & Support</li>
                                    </ul>
                                </BoxEndToEnd>
                            </ScrollAnimation>
                        </Col>

                        <Col lg="4" md="6">
                            <ScrollAnimation
                                delay={300}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <BoxEndToEnd>
                                    <ImageBox>
                                        <img
                                            src={`${baseURL}assets/images/vmt.png`}
                                            alt="-"
                                        />
                                    </ImageBox>
                                    <h3>Virtual Management Training</h3>

                                    <ul>
                                        <li>
                                            Training for managing remote
                                            employees
                                        </li>
                                        <li>Hosting Meetings</li>
                                        <li>Engagement</li>
                                        <li>Contacting agents</li>
                                        <li>Resources</li>
                                    </ul>
                                </BoxEndToEnd>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </EndToEndSection>
            <DigitalManage />
            <ThreeBoxes>
                <Container>
                    <Row>
                        <Col lg="4">
                            <ScrollAnimation
                                delay={100}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <ThreeTextBox>
                                    <h4>Data Security</h4>
                                    <p>
                                        No data stored on agent machine. Client
                                        Defined Security Requirements.
                                    </p>
                                </ThreeTextBox>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="4">
                            <ScrollAnimation
                                delay={200}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <ThreeTextBox>
                                    <h4>Client Defined Role Based Security</h4>
                                    <p>
                                        Agent is unable to cut/paste/copy data
                                        PCI & HIPAA Compliant workspaces.
                                    </p>
                                </ThreeTextBox>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="4">
                            <ScrollAnimation
                                delay={300}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <ThreeTextBoxLast>
                                    <h4>ibex W@H Tightens Restrictions</h4>
                                    <p>
                                        Data Masking for PII & PCI Options
                                        Available; W@H not given Screen Sharing
                                        Capability Spyware and Anti-Virus
                                        updates are Automated
                                    </p>
                                </ThreeTextBoxLast>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </ThreeBoxes>
        </>
    );
};

const ThreeBoxes = styled.section`
    padding: 0 0;
    background-color: #fff;
`;
const ThreeTextBox = styled.div`
    padding: 100px 30px;
    border-right: 6px solid #f7f7f7;
    min-height: 268px;
    ${Media('xlscreens')} {
        min-height: 19vw;
    }
    ${Media('tablet')} {
        min-height: 100px;
        padding: 0px 20px;
    }
    h4 {
        font-size: 23px;
        margin-bottom: 30px;
        font-family: ${gilroyMedium};
        ${Media('xlscreens')} {
            font-size: 1.2vw;
        }
    }
    p {
        font-size: 12px;
        font-family: ${gilroyMedium};
        color: ${colors.gray_dark['50']};
        ${Media('xlscreens')} {
            font-size: 0.69vw;
        }
    }
`;

const ThreeTextBoxLast = styled(ThreeTextBox)`
    border-right: none;
`;
const EndToEndSection = styled.section``;
const BoxEndToEnd = styled.div`
    margin-top: 50px;
    padding: 30px;
    ${Media('xlscreens')} {
        padding: 1.57vw;
    }
    h3 {
        font-size: 32px;
        margin-bottom: 30px;
        margin-top: 40px;
        font-family: ${gilroySemibold};
        ${Media('xlscreens')} {
            font-size: 1.5vw;
            margin-bottom: 1.57vw;
            margin-top: 2.09vw;
        }
        ${Media('tablet')} {
            font-size: 22px;
        }
    }
    ul {
        padding: 0;
        li {
            display: block;
            font-size: 20px;
            font-family: ${gilroyLight};
            line-height: 1.8;
            ${Media('xlscreens')} {
                font-size: 1.047vw;
            }
            &:before {
                content: '';
                width: 3px;
                height: 3px;
                background-color: #000;
                display: inline-block;
                border-radius: 50%;
                position: relative;
                left: -5px;
                top: -3px;
            }
        }
    }
`;

const ImageBox = styled.div`
    margin-bottom: 20px;
    img {
        width: 100%;
    }
`;
