import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import Loader from 'components/loader/loader';
import styled from 'styled-components';
import Layout from 'components/layout';
import { ResourceCard, FeaturedPostSlider } from 'components/resource';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    FlexCentered,
    RowCentered,
} from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';
import { useLocation } from 'react-router-dom';
import Button from 'shared-components/button/button';
import { baseURLPage } from 'shared-components/theme/helpers';

const tabItems = [
    { name: 'Webinars', target: 'webinars' },
    { name: 'eBooks', target: 'ebooks' },
    { name: 'Blogs', target: 'blogs' },
];

const ResourcesIndex = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [blogs, setBlogs] = useState(null);
    const [webinars, setWebinars] = useState(null);
    const [eBooks, setEbooks] = useState(null);
    const [loader] = useState(false);
    const [featuredPosts, setFeaturedPosts] = useState([]);

    const tabRef = useRef(null);

    const location = useLocation();

    useEffect(() => {
        getWebinars();
        getEbooks();
        getFeaturedBlogs();
        scrollToTabs();
    }, [location.hash]);

    console.log(location);
    const getHashValue = () => {
        switch (location.hash.toLowerCase()) {
            case '#webinars':
                setActiveTab(0);
                break;
            case '#ebooks':
                setActiveTab(1);
                break;
            default:
                setActiveTab(0);
                break;
        }
    };

    var featured = [];
    const scrollToTabs = () => {
        getHashValue();
        if (location.hash !== '') {
            tabRef.current &&
                tabRef.current.scrollIntoView({
                    behavior: 'smooth',
                });
        }
    };
    const getWebinars = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/webinars?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        per_page: 25,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                resData.data.map(
                    (d) => d.metadata.is_featured && featured.push(d)
                );
                setWebinars(resData.data);
            }
        } catch (err) {
            console.error(err);
        }
    };
    const getEbooks = async () => {
        var ebooks = [];
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/ebooks?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        per_page: 25,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                resData.data.map(
                    (d) => d.metadata.is_featured && featured.push(d)
                );
                resData.data.map((d) => ebooks.push(d));
            }
        } catch (err) {
            console.error(err);
        }
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/whitepapers?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                resData.data.map((d) => ebooks.push(d));
            }
        } catch (err) {
            console.error(err);
        }
        setEbooks(ebooks);
    };
    const getFeaturedBlogs = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/blog?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        per_page: 25,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                resData.data.map(
                    (d) => d.metadata.is_featured && featured.push(d)
                );
                setBlogs(resData.data);
            }
        } catch (err) {
            console.error(err);
        }
        setFeaturedPosts(featured);
    };
    const ResourceCardWrapper = ({ data }) => {
        return (
            data &&
            data.map((d, ind) => {
                return !d.metadata.show_on_resources_page &&
                    d.metadata.show_on_resources_page !== undefined ? null : (
                    <Col sm="12" lg="6" key={ind}>
                        <div>
                            <ResourceCard
                                data={d}
                                post_content={
                                    activeTab === 2
                                        ? d.post_content
                                        : d.metadata.short_description
                                }
                            />
                        </div>
                    </Col>
                );
            })
        );
    };

    return (
        <Layout title="Resources | ibex">
            {(loader && <Loader />) || (
                <>
                    <MainWrapper>
                        <Container>
                            <RowCentered>
                                <Col sm="12" lg="8">
                                    <PageHeading>Resources</PageHeading>
                                </Col>
                            </RowCentered>
                            <Row>
                                <Col sm="12">
                                    {featuredPosts &&
                                        featuredPosts.length > 0 && (
                                            <FeaturedPostSlider
                                                featuredData={featuredPosts}
                                            />
                                        )}
                                </Col>
                            </Row>
                        </Container>
                    </MainWrapper>
                    <ResourceTabsSection ref={tabRef}>
                        <Container>
                            <Row>
                                <Col sm="12">
                                    <TabsMainWrapper>
                                        <TabsInnerWrapper>
                                            {tabItems.map((item, index) => {
                                                return (
                                                    <TabItem
                                                        active={
                                                            activeTab === index
                                                        }
                                                        key={index}
                                                        onClick={() => {
                                                            setActiveTab(index);
                                                        }}>
                                                        {item.name}
                                                    </TabItem>
                                                );
                                            })}
                                        </TabsInnerWrapper>
                                    </TabsMainWrapper>
                                    <TabContent>
                                        <Row>
                                            <ResourceCardWrapper
                                                data={
                                                    activeTab === 0
                                                        ? webinars
                                                        : activeTab === 1
                                                        ? eBooks
                                                        : blogs
                                                }
                                            />
                                        </Row>
                                    </TabContent>
                                    {activeTab === 2 && (
                                        <FlexCentered>
                                            <Button
                                                round
                                                href={`${baseURLPage}resources/blogs`}>
                                                Load More
                                            </Button>
                                        </FlexCentered>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </ResourceTabsSection>
                </>
            )}
        </Layout>
    );
};

const MainWrapper = styled.section`
    position: relative;
    min-height: 500px;
    .slick-dots {
        button::before {
            font-size: 40px;
        }
    }
    ${Media('xlscreens')} {
        .slick-dots {
            bottom: -2.5vw;
            button::before {
                font-size: 2vw;
            }
        }
    }
    ${Media('mobile')} {
        .slides {
            height: 200px;
        }
    }
`;

const PageHeading = styled.h1`
    font-size: 48px;
    font-family: ${gilroyBold};
    letter-spacing: -0.5px;
    margin: 0px;
    ${Media('xlscreens')} {
        font-size: 3.125vw;
        letter-spacing: -0.14vw;
    }
    ${Media('tablet')} {
        font-size: 42px;
        margin-bottom: 30px;
    }
    ${Media('mobile')} {
        font-size: 36px;
    }
`;

const ResourceTabsSection = styled.section`
    padding-top: 0px;
`;

const TabsInnerWrapper = styled.ul`
    padding: 0px;
    display: flex;
    align-items: center;
    margin: 20px auto 0;
    width: 85%;
    ${Media('mobile')} {
        width: 100%;
        flex-direction: column;
    }
`;

const TabItem = styled.li`
    font-size: 21px;
    font-family: ${gilroyBold};
    height: 65px;
    border-bottom: 5px solid ${colors.gray['border']};
    background-color: ${(props) =>
        props.active ? colors.pink['2'] : 'transparent'};
    flex-grow: 1;
    padding: 0 15px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
    transition: all ease 330ms;
    position: relative;
    &:after {
        position: absolute;
        height: 5px;
        width: 0px;
        content: '';
        background-color: ${(props) => !props.active && colors.gray['100']};
        left: 0;
        bottom: -5px;
        transition: all ease 330ms;
    }
    &:hover {
        background-color: ${(props) => !props.active && colors.gray['2']};
        &:after {
            width: 100%;
        }
    }
    ${(props) => {
        if (props.active) {
            return `
                background-color:${colors.pink['2']};
                border-bottom-color:${colors.pink['100']};
            `;
        }
    }}
    ${Media('xlscreens')} {
        font-size: 1.04vw;
        height: 3.3vw;
        padding: 0 0.78vw;
    }
    ${Media('mobile')} {
        width: 100%;
        margin-top: 10px;
    }
`;

const TabContent = styled.div`
    margin: 50px 0px 20px;
    ${Media('xlscreens')} {
        margin: 5vw 0 2vw;
    }
`;

const TabsMainWrapper = styled.div`
    ${Media('xlscreens')} {
        background-color: ${colors.gray_lighter['100']};
        position: sticky;
        top: 5.31vw;
        z-index: 99;
    }
`;

export default ResourcesIndex;
