import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Layout from 'components/layout';
import MainHeading from 'shared-components/main-heading/main-heading';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { gilroyMedium, gilroyBold } from 'shared-components/theme/fonts';
import {
    FlexCentered,
    FlexColumnCentered,
} from 'shared-components/theme/grid-helpers';
import { Link } from 'react-scroll';
import ScrollAnimation from 'react-animate-on-scroll';
import { Parallax } from 'react-scroll-parallax';
import SubscribeForm from 'components/subscribe-form/subscribe-form';
import { UtilPagePara, UtilStepHeadText, UtilTag } from './common';
import {
    UtilStepFourSection,
    UtilStepOneSection,
    UtilStepThreeSection,
    UtilStepTwoSection,
} from './sections';
import LazyLoad from 'react-lazyload';
import Loader from 'components/loader/loader';
import { baseURL } from 'shared-components/theme/helpers';

const utilSteps = [
    {
        count: '01',
        title: 'Understand the New Utility Customer Mindset',
        link: 'step_one',
    },
    {
        count: '02',
        title: 'Offer Catalog Tuned to Customer Lifecycle',
        link: 'step_two',
    },
    {
        count: '03',
        title: 'Match the Customer’s Channel of Choice',
        link: 'step_three',
    },
    {
        count: '04',
        title:
            'Open the Door to Repeat Engagement After the Move and Beyond the Meter',
        link: 'step_four',
    },
];

const lazySections = [
    {
        id: 'step_one',
        section: <UtilStepOneSection />,
    },
    {
        id: 'step_two',
        section: <UtilStepTwoSection />,
    },
    {
        id: 'step_three',
        section: <UtilStepThreeSection />,
    },
    {
        id: 'step_four',
        section: <UtilStepFourSection />,
    },
];

const Utilities = () => {
    const [localKeyName] = useState('utilityKey');
    const [pageLock, setPageLock] = useState(true);

    useEffect(() => {
        const localKey = localStorage.getItem(localKeyName) || '';
        if (localKey !== '') {
            setPageLock(false);
        }
    }, []);

    const handlePageLock = (val) => {
        setPageLock(val);
    };

    return (
        <Layout page="util_starter_kit">
            {pageLock ? (
                <SubscribeForm
                    storeKey={localKeyName}
                    onSuccess={handlePageLock}
                    referrerProp={'utilities'}
                />
            ) : (
                <>
                    <UtilIntro id="intro">
                        <Container>
                            <Row>
                                <Col lg="12">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <MainHeading noMargin={true}>
                                            Utility New Connection <br />
                                            Starter Kit
                                            <span>.</span>
                                        </MainHeading>
                                    </ScrollAnimation>
                                </Col>
                                <Col lg={{ size: 7, offset: 5 }}>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <UtilIntroParaWrap>
                                            <UtilPagePara>
                                                Leading organizations know that
                                                a customer’s first brand
                                                experience sets the tone for the
                                                entire customer relationship. It
                                                frames the perception of the
                                                company and sets the foundation
                                                for a meaningful and rewarding
                                                long-term relationship.
                                            </UtilPagePara>
                                            <UtilPagePara>
                                                Amazon, Apple and Walmart have
                                                trained today’s customers to
                                                expect a personalized,&nbsp;
                                                <i>above</i> and <i>beyond</i>
                                                &nbsp;digital experience. These
                                                companies obsess about their
                                                customers, and in return they
                                                enjoy strong CSAT and customer
                                                loyalty. At ibex Digital, we
                                                help utilities deliver this same
                                                high level of customer
                                                satisfaction and engagement,
                                                using unique digital solutions
                                                and a wealth of experience
                                                supporting CX for major brands.
                                            </UtilPagePara>
                                        </UtilIntroParaWrap>
                                    </ScrollAnimation>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <UtilIntroImg>
                                            <img
                                                src={`${baseURL}assets/images/util-banner-img.jpg`}
                                                alt="-"
                                            />
                                        </UtilIntroImg>
                                    </ScrollAnimation>
                                </Col>
                            </Row>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <UtilTest>
                                    <Row>
                                        <Col lg={{ size: 8, offset: 2 }}>
                                            <PageHeading compact={true}>
                                                Utilities that help their new
                                                customers solve typical mover
                                                problems have the opportunity to
                                                deliver a personalized and
                                                customer-centric first
                                                experience, and establish a
                                                rewarding long term
                                                relationship.
                                            </PageHeading>
                                        </Col>
                                        <Col lg={{ size: 10, offset: 1 }}>
                                            <UtilPagePara>
                                                - Mark Wilkinson, SVP Products,
                                                ibex Digital
                                            </UtilPagePara>
                                        </Col>
                                    </Row>
                                </UtilTest>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <UtilTestPoints>
                                    <Row>
                                        <Col lg="3">
                                            <PageHeading>
                                                Transform your <br />
                                                technology.
                                            </PageHeading>
                                        </Col>
                                        <Col lg="9">
                                            <UtilPagePara>
                                                Today, technology makes it easy
                                                for companies to adapt quickly
                                                to increased customer
                                                expectations and deliver on
                                                their demands. In our
                                                experience, Utilities already
                                                possess all of the treasured
                                                assets to deliver a world class
                                                customer experience, and they
                                                have advantages over most brands
                                                in that critical first
                                                experience.
                                            </UtilPagePara>
                                        </Col>
                                    </Row>
                                </UtilTestPoints>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <UtilTestPoints>
                                    <Row>
                                        <Col lg="3">
                                            <PageHeading>
                                                Stand <br /> out.
                                            </PageHeading>
                                        </Col>
                                        <Col lg="9">
                                            <UtilPagePara>
                                                Utilities receive the first call
                                                most customers make during a
                                                move to a new residence. This
                                                means we have an enviable
                                                opportunity to delight customers
                                                and deliver a WOW new customer
                                                experience. McKinsey reports
                                                that “delivering stand-out
                                                moments that go above and beyond
                                                customers’ expectations can lead
                                                to dramatically higher
                                                satisfaction.” As a result, most
                                                utilities should consider adding
                                                a mover support program to their
                                                new energy start process to go
                                                “above and beyond” for their
                                                newest customers.
                                            </UtilPagePara>
                                        </Col>
                                    </Row>
                                </UtilTestPoints>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <UtilTestPoints>
                                    <Row>
                                        <Col lg="3">
                                            <PageHeading>
                                                Build a <br /> go-to <br />
                                                Connection <br /> Ecosystem.
                                            </PageHeading>
                                        </Col>
                                        <Col lg="9">
                                            <UtilPagePara>
                                                Adding new mover support to help
                                                new customers may seem simple,
                                                but utilities have a lot of
                                                options to consider in their
                                                search for that WOW first
                                                customer experience. Some new
                                                mover programs simply sell other
                                                services to new utility
                                                customers by sending them to
                                                other brands. These programs
                                                don’t amplify the role of the
                                                utility in helping their
                                                customer, but simply sell that
                                                customer to another vendor. A
                                                world-class New Connection
                                                experience should impress
                                                customers that their new utility
                                                considers their needs and sets
                                                up an experience to solve those
                                                problems in a personalized and
                                                supportive engagement. Done
                                                properly, the New Connection
                                                Experience expands your brand
                                                promise and sends customers a
                                                message that the utility strives
                                                to be a “Go To” resource to
                                                improve safety and comfort for
                                                their new home.
                                            </UtilPagePara>
                                        </Col>
                                    </Row>
                                </UtilTestPoints>
                            </ScrollAnimation>
                        </Container>
                        <Parallax x={['5%', '-5%']}>
                            <BigCircle />
                        </Parallax>
                    </UtilIntro>
                   
                        <UtilStepHead>
                            <Container>
                                <Row>
                                    <Col lg={{ size: 10, offset: 1 }}>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={false}>
                                            <UtilStepHeadText>
                                                We designed this starter kit to
                                                help Utility CX teams examine
                                                the 4 keys to a successful New
                                                Connection Experience:
                                            </UtilStepHeadText>
                                        </ScrollAnimation>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={false}>
                                            <UtilSteps>
                                                <Row noGutters={true}>
                                                    {utilSteps &&
                                                    utilSteps.length > 0
                                                        ? utilSteps.map(
                                                              (item, index) => {
                                                                  return (
                                                                      <Col
                                                                          lg="3"
                                                                          key={
                                                                              index
                                                                          }>
                                                                          <UtilStepBox
                                                                              last={
                                                                                  utilSteps.length ===
                                                                                  index +
                                                                                      1
                                                                              }>
                                                                              <InnerBox>
                                                                                  <UtilTag
                                                                                      noPaddingTop={
                                                                                          true
                                                                                      }>
                                                                                      {
                                                                                          item.count
                                                                                      }
                                                                                  </UtilTag>
                                                                                  <UtilStepText>
                                                                                      {
                                                                                          item.title
                                                                                      }
                                                                                  </UtilStepText>
                                                                                  <Link
                                                                                      to={
                                                                                          item.link
                                                                                      }
                                                                                      smooth={
                                                                                          true
                                                                                      }
                                                                                      offset={
                                                                                          0
                                                                                      }
                                                                                      duration={
                                                                                          1000
                                                                                      }>
                                                                                      Show
                                                                                      Me
                                                                                  </Link>
                                                                              </InnerBox>
                                                                          </UtilStepBox>
                                                                      </Col>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </Row>
                                            </UtilSteps>
                                        </ScrollAnimation>
                                    </Col>
                                </Row>
                            </Container>
                        </UtilStepHead>

                        {lazySections && lazySections.length > 0
                            ? lazySections.map((item, index) => {
                                  return (
                                      <div id={item.id} key={index}>
                                          {item.section}
                                      </div>
                                  );
                              })
                            : null}
                </>
            )}
        </Layout>
    );
};

const UtilIntro = styled.section`
    position: relative;
    .main-heading {
        letter-spacing: -0.5px;
    }
    ${Media('xlscreens')} {
        padding-top: 7vw;
        .main-heading {
            font-size: 4.7vw;
        }
    }
    ${Media('mobile')} {
        .main-heading {
            br {
                display: none;
            }
        }
    }
`;

const UtilIntroParaWrap = styled.div`
    ${Media('xlscreens')} {
        margin-top: -0.8vw;
        padding-left: 4vw;
    }
    ${Media('tablet')} {
        margin-top: 40px;
    }
`;

const PageHeading = styled.h3`
    font-size: 28px;
    font-family: ${gilroyMedium};
    line-height: ${(props) => (props.compact ? '1.1' : '1.25')};
    margin-top: 0px;
    ${Media('xlscreens')} {
        font-size: 1.7vw;
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 21px;
    }
`;

const UtilIntroImg = styled.div`
    margin: 50px 0px;
    img {
        border-radius: 19px;
        width: 100%;
    }
    ${Media('xlscreens')} {
        margin-top: 1vw;
    }
`;

const UtilTest = styled.div`
    text-align: center;
    ${PageHeading} {
        margin: 20px 0px 40px;
    }
    p {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
    ${Media('xlscreens')} {
        ${PageHeading} {
            margin: 2vw 1vw 2.7vw;
        }
    }
`;

const UtilTestPoints = styled.div`
    padding-top: 50px;
    p {
        padding: 5px 20px 0 0;
    }
    ${Media('xlscreens')} {
        padding-top: 2.5vw;
    }
`;

const BigCircle = styled.div`
    position: absolute;
    width: calc(100vh - 82px);
    height: calc(100vh - 82px);
    background: ${colors.white['20']};
    border: 1px solid ${colors.pink['100']};
    border-radius: 50%;
    right: -70%;
    bottom: 10%;
    z-index: 99;
    ${Media('xlscreens')} {
        width: 48.2vw;
        height: 48.2vw;
        bottom: 5vw;
        right: -40vw;
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const UtilStepHead = styled.section`
    padding-top: 0;
    padding-bottom: 0;
`;

const UtilSteps = styled.div`
    border: 1px solid ${colors.gray['border']};
    border-radius: 32px;
`;

const InnerBox = styled(FlexColumnCentered)`
    transform: translateY(15px);
    transition: all 220ms ease;
    ${Media('xlscreens')} {
        transform: translateY(0.8vw);
    }
`;

const UtilStepBox = styled(FlexColumnCentered)`
    border-right: ${(props) =>
        props.last ? 'none' : `1px solid ${colors.gray['border']}`};
    min-height: 270px;
    a {
        font-size: 16px;
        font-family: ${gilroyBold};
        padding-top: 10px;
        opacity: 0;
        visibility: hidden;
        transition: all 220ms ease;
    }
    &:hover {
        ${InnerBox} {
            transform: translateY(0);
        }
        a {
            opacity: 1;
            visibility: visible;
        }
    }
    ${Media('xlscreens')} {
        height: 14.1vw;
        a {
            padding-top: 1vw;
            font-size: 0.85vw;
        }
    }
    ${Media('tablet')} {
        border-right: none;
        border-bottom: ${(props) =>
            props.last ? 'none' : `1px solid ${colors.gray['border']}`};
        ${InnerBox} {
            transform: translateY(0);
        }
        a {
            opacity: 1;
            visibility: visible;
        }
    }
`;

const UtilStepText = styled(FlexCentered)`
    font-size: 16px;
    font-family: ${gilroyMedium};
    line-height: 1.65;
    min-height: 90px;
    text-align: center;
    width: 80%;
    margin: 20px auto 0;
    ${Media('xlscreens')} {
        font-size: 1.05vw;
        min-height: 4.8vw;
        margin: 1.2vw auto 0;
    }
`;

export default Utilities;
