import React from 'react';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import RetailIntro from 'components/retail/intro-section';
import { baseURL } from 'shared-components/theme/helpers';
import { BigCircle } from 'components/industries/common';
import {
    TransformSolutionSec,
    CaseStudyPerfSec,
    EbookBottomSec,
} from './sections';
import Loader from 'components/loader/loader';
import LazyLoad from 'react-lazyload';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -200,
    },
    {
        name: 'cx-solution',
        target: 'cx-solution',
        offset: -50,
    },
    {
        name: 'experience',
        target: 'experience',
        offset: 0,
    },
    {
        name: 'cx-performance',
        target: 'cx-performance',
        offset: 0,
    },
];

const retailSolutions = [
    {
        imageUrl: `${baseURL}assets/images/industries/healthtech-solution1.jpg`,
        title: 'Drive More Customers Into Your Ecosystem ',
        para: `Our expert search, social, and native digital marketing team is ready to launch ads across platforms like Google, Facebook, Pinterest, LinkedIn, Twitter, and more, driving customers and patients directly into your ecosystem. `,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/healthtech-solution2.jpg`,
        title: 'Embed Technology to Enhance Omnichannel CX Outcomes',
        para: `From Training Simulators to A.I. Customer Feedback Analytics, our proprietary Wave X platform provides end-to-end in-house performance technology to drive  superior call, chat, email, and social support experiences.`,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/healthtech-solution3.jpg`,
        title: 'Build Your Burst Capacity CX Engine ',
        para: `We pair a global network of flexible in-center and @home CX teams with our elite workforce management operation to deliver unmatched seasonal & burst capacity excellence and predictable performance outcomes. `,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/healthtech-solution4.jpg`,
        title: 'Enhance Every Interaction with Integrated BI & Analytics ',
        para: `Our sophisticated Business intelligence and Professional Services division can discover useful insights from within your customer interaction data, revealing areas for potential program enhancement, risk mitigation, and cost optimization. `,
    },
];

const performanceList = [
    {
        count: '01',
        imgUrl: `${baseURL}assets/images/industries/cx-performance-icon6.svg`,
        title: `Patient Access <br /> & Support`,
        list: [
            'Centralized scheduling ',
            'Post appointment follow-up / care coordination ',
            'Readmission reduction solution ',
            'Rx refill',
        ],
    },
    {
        count: '02',
        imgUrl: `${baseURL}assets/images/industries/healthtech-performance-2.svg`,
        title: `Patient Experience, <br /> Outreach & Wellness`,
        list: [
            'Patient experience management solutions',
            'Digital patient outreach',
            'Population Health',
            'Wellness / Closing gaps in care',
            'Chronic disease management',
            'Remote patient monitoring',
        ],
    },
    {
        count: '03',
        imgUrl: `${baseURL}assets/images/industries/healthtech-performance-3.svg`,
        title: `Member Acquisition <br /> & Support `,
        list: [
            'New member enrollment & acquisition',
            'Digital Marketing',
            'Member customer support services',
            'Closing gaps in care',
        ],
    },
    {
        count: '04',
        imgUrl: `${baseURL}assets/images/industries/healthtech-performance-4.svg`,
        title: `Payment, Billing <br /> & Collections `,
        list: [
            'Digital Self-pay solution',
            'Billing customer service',
            'Claims management',
            'Early-out collections',
            'Customer Experience Surveys',
            'Compliance Management & Fraud Prevention',
        ],
    },
    {
        count: '05',
        imgUrl: `${baseURL}assets/images/industries/healthtech-performance-5.svg`,
        title: `CX for Health & <br /> Wellness Tech`,
        list: [
            'Customer experience management solutions',
            'Digital Marketing / Customer acquisition',
            'Claims management',
            'Billing customer service',
            'Early-out collections',
            'Business Intelligence & Analytics',
            'Customer loyalty / Social reputation management',
        ],
    },
    {
        count: '06',
        imgUrl: `${baseURL}assets/images/industries/healthtech-performance-6.svg`,
        title: `Digital Solutions `,
        list: [
            'Digital Marketing',
            'New customer/patient/member acquisition',
            'Patient/member intake & onboarding',
            'Loyalty and survey management solutions',
            'Social reputation management solution',
        ],
    },
];

const expList = [
    {
        title: 'Ibex digital lead Gen',
        image: `${baseURL}assets/images/industries/exp-img-1.jpg`,
        details: {
            heading: 'Awareness & Consideration',
            list: [
                'Provider Selection',
                'Insurance Plan Selection',
                'New Patient Acquisition',
                'Patient Intake / Onboarding',
            ],
        },
    },
    {
        title: 'Scheduling App',
        image: `${baseURL}assets/images/industries/exp-img-2.jpg`,
        details: {
            heading: 'Schedule & Registration',
            list: [
                'Schedule service',
                'Payment estimation',
                'Pre-authorizations',
                'Obtain referral for specialist',
            ],
        },
    },
    {
        title: 'Digital Outreach App',
        image: `${baseURL}assets/images/industries/exp-img-3.jpg`,
        details: {
            heading: 'Pre-Arrival Check-in',
            list: [
                'Appointment reminder',
                'Pre-arrival forms',
                'Pre-arrival instructions',
                'Virtual check-in',
            ],
        },
    },
    {
        title: 'RefleCX',
        image: `${baseURL}assets/images/industries/exp-img-4.jpg`,
        details: {
            heading: 'Loyalty & Customization',
            list: [
                'Patient experience survey',
                'reputation management',
                'Recommend provider',
                'Switch providers',
                'Offer other services',
            ],
        },
    },
];

const sections = [
    {
        component: <TransformSolutionSec retailSolutions={retailSolutions} />,
        isScrollNav: false,
    },
    {
        component: (
            <CaseStudyPerfSec
                expList={expList}
                performanceList={performanceList}
            />
        ),
        isScrollNav: false,
    },
    {
        component: <EbookBottomSec />,
        isScrollNav: true,
    },
];

const HealthTechWellness = () => {
    return (
        <Layout>
            <Breadcrumbs>
                <li>Industry</li>
                <li>HealthTech & Wellness</li>
            </Breadcrumbs>

            <RetailIntro
                rightImg={`${baseURL}assets/images/industries/healthtech-intro-bg.png`}
                tag="INDUSTRY"
                introHeading="HealthTech<br />& Wellness"
                introPara="Advanced lifecycle solutions for <br /> digital patient & customer experiences."
                introBtnText="Connect with us."
                right="-7%"
                top="-18.3vw"
                id="home"
            />

            <BigCircle
                imgUrl={`${baseURL}assets/images/industries/healthtech-intro-icon.svg`}
                top="15.3vw"
                imgHeight={940}
                zIndex={-1}
            />
            {sections &&
                sections.map((section, index) => {
                    return (
                        <div
                            key={index}>
                            {section.isScrollNav && (
                                <ScrollNav data={scrollNavData} />
                            )}
                            {section.component}
                        </div>
                    );
                })}
        </Layout>
    );
};

export default HealthTechWellness;
