import React from 'react';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
export const DEHeading2 = ({ children, light }) => {
    return <Wrapper light={light}>{children}</Wrapper>;
};
const Wrapper = styled.h1`
    margin-top: 0;
    font-size: 40px;
    font-family: ${gilroyBold};
    line-height: 1.25;
    letter-spacing: -2px;
    color: ${(props) =>
        props.light ? colors.white['100'] : colors.black['100']};
    ${Media('xlscreens')} {
        font-size: 3.125vw;
        letter-spacing: -0.1vw;
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 30px;
        letter-spacing: -2px;
    }
`;
