import React from 'react';
import { Container } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { gilroyLight, gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';

export const CommunitySection = () => {
    return (
        <SectionWrapper>
            <Container>
                <div>
                    <SectionHeading>
                        Community involvement <br /> is important to us
                    </SectionHeading>
                </div>
                <div>
                    <Text>
                        <CenterAligned>
                            At ibex, we value compassion. We provide
                            opportunities to volunteer, fundraise, and get
                            involved because <br />
                            we know it is important to you as well. It is our
                            mission to make where we live and work a better
                            place.
                        </CenterAligned>
                    </Text>
                </div>
            </Container>
            <div>
                <CommunityImage>
                    <img
                        src={`${baseURL}assets/images/community-involvement.jpg`}
                        alt=""
                    />
                </CommunityImage>
            </div>
        </SectionWrapper>
    );
};

const SectionWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${colors.white['100']};
`;

const SectionHeading = styled.div`
    font-family: ${gilroySemibold};
    margin-top: 165px;
    margin-bottom: 60px;
    font-size: 80px;
    text-align: center;
    ${Media('xlscreens')} {
        margin-top: 8.68vw;
        margin-bottom: 3.15vw;
        font-size: 4.8vw;
    }
    ${Media('tablet')} {
        font-size: 33px;
        margin-top: 80px;
        margin-bottom: 40px;
    }
`;

const CenterAligned = styled.div`
    text-align: center;
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
`;

const Text = styled.div`
    font-family: ${gilroyLight};
    font-size: 16px;
    ${Media('xlscreens')} {
        font-size: 0.84vw;
    }
`;

const CommunityImage = styled.div`
    height: 63.94vw;
    width: 100%;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;
