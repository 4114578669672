import React from 'react';
import { baseURL } from 'shared-components/theme/helpers';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import RetailIntro from 'components/retail/intro-section';
import { BigCircle } from 'components/industries/common';
import Loader from 'components/loader/loader';
import LazyLoad from 'react-lazyload';
import { TransformSolutionSec, PerformanceAmplifySec } from './sections';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -200,
    },
    {
        name: 'cx-solution',
        target: 'cx-solution',
        offset: -50,
    },
    {
        name: 'cx-performance',
        target: 'cx-performance',
        offset: -50,
    },
];

const retailSolutions = [
    {
        imageUrl: `${baseURL}assets/images/industries/highTech-solution1.jpg`,
        title: 'Omnichannel solutions for high tech brands.',
        para: `Across chat, email, voice, or social, we build customizable, AI-powered, scalable, and agile CX performance technologies to create seamless, best-in-class agent and customer experiences, every time. `,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/ent-solution2.jpg`,
        title: 'CX Scale, <br /> At Lightning Speed. ',
        para: `Startup, Scale-Up, or Blue Chip, the world’s largest and fastest-growing technology brands are partnered with ibex to build agile, scalable CX solutions tailored to the fast-paced and hyper-growth needs of brands like yours.   Get from ramp to results before the paint dries.`,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/highTech-solution3.jpg`,
        title: 'Adaptive <br /> Bestshore + @Home CX',
        para: `ibex offers a flexible global network of @home & in-center CX delivery options onshore, nearshore, and offshore, providing persistent business continuity assurance, CX risk mitigation, and predictable performance outcomes, all while increasing ROI.`,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/highTech-solution4.jpg`,
        title: 'Smart CX Driven By Business Intelligence ',
        para: `We’re experts at adding, growing, and keeping your customers loyal to your brand, maximizing every CX opportunity in the customer journey. With an enterprise-grade BI toolkit, you’ll be armed with the insights and analytics needed to drive next-level CX.`,
    },
];

const performanceList = [
    {
        count: '01',
        imgUrl: `${baseURL}assets/images/industries/highTech-performance-icon1.svg`,
        title: `Omnichannel CX Execution`,
        list: [
            'Chat, Email, Social, & Voice Support',
            'Customer Support, Account Management,',
            'Multi-Tier Technical Support;',
            'Staff Augmentation, Back Office',
            'Content Moderation',
        ],
    },
    {
        count: '02',
        imgUrl: `${baseURL}assets/images/industries/highTech-performance-icon2.svg`,
        title: `Elite Training, Simulation, & <br /> Gamification Methodology`,
        list: [
            'Wave 0: Upfront Leadership Training',
            'Experiential Learning',
            'Virtual Training Simulation',
            'Performance Gamification',
            'Microlearning',
        ],
    },
    {
        count: '03',
        imgUrl: `${baseURL}assets/images/industries/highTech-performance-icon3.svg`,
        title: `First Call <br /> Resolution Expertise`,
        list: [
            'Precision-Recruitment & Global Talent Pool',
            '@Home & In-Center Flexibility',
            'Weekly Engagement & Brand Affinity Programs',
            'Real-Time Coaching & Desktop Monitoring',
            'KPI Gamification & Incentivization',
            'Purpose-Built Agent Performance Toolset ',
        ],
    },
    {
        count: '04',
        imgUrl: `${baseURL}assets/images/industries/cx-performance-icon4.svg`,
        title: `Wave X Technology Platform`,
        list: [
            'Agent Training Simulators ',
            'Remote real-time agent monitoring',
            'End-to-End System Security',
            'Social Reputation Management',
            'Customer Experience Surveys',
        ],
    },
    {
        count: '05',
        imgUrl: `${baseURL}assets/images/industries/cx-performance-icon5.svg`,
        title: `KPI-Ready PMO & <br /> Implementation Team `,
        list: [
            'Forecasting',
            'Workforce Planning',
            'Proactive Insights',
            'Real-Time Performance Adjustments',
            'Cost & Quality Analytics',
        ],
    },
    {
        count: '06',
        imgUrl: `${baseURL}assets/images/industries/ent-performance-icon4.svg`,
        title: `Predictive Performance <br /> Management & BI Operation`,
        list: [
            'Voice of Customer Analytics ',
            'Glide Path Modeling ',
            'Channel Mapping & Optimization',
            'Value Stream Mapping',
            'Complexity Mapping',
            'Customer Journey Mapping',
        ],
    },
];

const amplifyList = [
    {
        icon: `${baseURL}assets/images/industries/rocket.svg`,
        title: 'Speed',
        para: `We build your CX teams to hit key KPIs quickly and consistently, ensuring there’s no gap in CX output once ibex is switched on.`,
    },
    {
        icon: `${baseURL}assets/images/industries/track-icon.svg`,
        title: 'Flexibility',
        para: `Our teams are ready to start small and ramp big. Our deep industry expertise means we’re able to pivot at a moment’s notice, keep pace with your innovation, and provide continuous performance for any situation, at any touchpoint. `,
    },
    {
        icon: `${baseURL}assets/images/industries/chameleon.svg`,
        title: 'Adaptability',
        para: `Rooted in industry experience and our in-house dev team, innovation is as big a part of our operations as it is yours. <br />
        Our technology and teams enable us to seamlessly pivot our operation to align with expanding work types or LOBs, optimizing quickly for shifting growth needs, issue demands, and changing CX channel requirements.
        `,
    },
    {
        icon: `${baseURL}assets/images/industries/crystal-ball.svg`,
        title: 'Predictability',
        para: `Anyone can do well on a single program, but delivering consistent performance outcomes time and time again depends on a battle-tested methodology for repeatable, predictable, and reliable program execution that you can set your watch to.`,
    },
];

const sections = [
    {
        component: <TransformSolutionSec retailSolutions={retailSolutions} />,
        isScrollNav: false,
    },
    {
        component: (
            <PerformanceAmplifySec
                performanceList={performanceList}
                amplifyList={amplifyList}
            />
        ),
        isScrollNav: true,
    },
];

const HighTech = () => {
    return (
        <Layout>
            <Breadcrumbs>
                <li>Industry</li>
                <li>HighTech</li>
            </Breadcrumbs>

            <BigCircle
                imgUrl={`${baseURL}assets/images/bigger-circle.svg`}
                top="22.7vw"
                imgHeight={976}
            />
            <RetailIntro
                rightImg={`${baseURL}assets/images/industries/highTech-intro-bg.png`}
                tag="INDUSTRY"
                introHeading="HighTech"
                introPara="Technology-driven customer experiences <br /> at the speed of innovation."
                introBtnText="Connect with us."
                right="-5%"
                top="-19.5vw"
                id="home"
            />

            {sections &&
                sections.map((section, index) => {
                    return (
                        <div
                            key={index}>
                            {section.isScrollNav && (
                                <ScrollNav data={scrollNavData} />
                            )}
                            {section.component}
                        </div>
                    );
                })}
        </Layout>
    );
};

export default HighTech;
