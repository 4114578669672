import React from 'react';
import {
    SRMonitoringSection,
    SRMSectionHeading,
} from 'components/social-rep-man/common';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import { baseURL } from 'shared-components/theme/helpers';
import MainPara from 'shared-components/main-para/main-para';
import {
    FlexColumnCentered,
    RowCenteredBoth,
} from 'shared-components/theme/grid-helpers';
import ReactHtmlParser from 'react-html-parser';


export const MonitorBuildSection = ({ monitorList, buildData }) => {
    return (
        <>
            <SRMonitoringSection
                id="monitor"
                heading="The Industry’s Most Powerful Survey Management Platform."
                paragraph="From Startup, to Scale-Up, to Blue Chip, RefleCX is a startup-ready enterprise-grade survey tool built for flexibility, <br /> speed, and fine-tuned control.  "
                listData={monitorList}
                boxHeight={372}
            />

            <BuildSection id="build">
                <Container>
                    <RowCenteredBoth>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <SRMSectionHeading color={colors.white['100']}>
                                    Build, Send, and Manage Surveys, At Scale.
                                </SRMSectionHeading>
                            </ScrollAnimation>
                        </Col>
                    </RowCenteredBoth>
                    <RowCenteredBoth>
                        <Col sm="8">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <MainPara
                                    align="center"
                                    color={colors.white['100']}
                                    noMargin>
                                    From 5-stary surveys to full-scale survey
                                    creation, text analytics, and closed-loop
                                    issue resolution, <br /> RefleCX is ready to
                                    deliver the survey you want and the data you
                                    need.
                                </MainPara>
                            </ScrollAnimation>
                        </Col>
                    </RowCenteredBoth>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <BuildBox>
                                    <RowCenteredBoth>
                                        {buildData.map((item, index) => {
                                            return (
                                                <Col sm="6" lg="3" key={index}>
                                                    <BuildInner>
                                                        <BuildIcon>
                                                            <img
                                                                src={item.icon}
                                                                alt="-"
                                                            />
                                                        </BuildIcon>
                                                        <MainPara>
                                                            {ReactHtmlParser(
                                                                item.title
                                                            )}
                                                        </MainPara>
                                                    </BuildInner>
                                                </Col>
                                            );
                                        })}
                                    </RowCenteredBoth>
                                </BuildBox>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </BuildSection>
        </>
    );
};
const BuildSection = styled.section`
    background-image: url('${baseURL}assets/images/tech/build-bg-image.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    padding-bottom: 0px;
`;

const BuildBox = styled.div`
    background-color: ${colors.white['100']};
    border-radius: 20px;
    padding: 50px;
    margin-top: 120px;
    position: relative;
    bottom: -120px;
    ${Media('xlscreens')} {
        margin-top: 14.32vw;
        bottom: -10.68vw;
        padding: 3vw 3.3vw;
    }
    ${Media('tablet')} {
        margin-top: 0;
    }
    ${Media('mobile')} {
        margin-top: -50px;
    }
`;

const BuildInner = styled(FlexColumnCentered)`
    padding: 10px 30px;
    text-align: center;
    p {
        font-family: ${gilroyMedium};
        color: ${colors.socialRep['brandsBG']};
    }
    ${Media('xlscreens')} {
        padding: 0.48vw 2.2vw;
    }
`;

const BuildIcon = styled.div`
    ${Media('xlscreens')} {
        img {
            max-width: 3.38vw;
            max-height: 2.8vw;
        }
    }
`;
