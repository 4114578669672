import React from 'react';
import { Redirect } from 'react-router-dom';
import { baseURLPage } from 'shared-components/theme/helpers';

const WesternUnionCaseStudy = () => {
    return (
        <Redirect
            to={`${baseURLPage}resources/webinars/digital-transformation-through-disruption`}
        />
    );
};

export default WesternUnionCaseStudy;
