import React from 'react';
import { gilroyBold } from 'shared-components/theme/fonts';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { RiPlayCircleLine } from 'react-icons/ri';
import ReactHtmlParser from 'react-html-parser';

const BlogCard = ({ blogData }) => {
    const inner_data = blogData;
    const externalPost =
        blogData &&
        blogData.metadata &&
        blogData.metadata.external_post &&
        blogData.metadata.external_post_url;

    return (
        <Wrapper>
            <CardImg>
                <a
                    href={
                        externalPost
                            ? externalPost
                            : '/resources/blogs/' + blogData.post_name
                    }
                    target={externalPost && '_blank'}>
                    <img src={inner_data.featured_image} alt="" />
                    {inner_data.post_terms && (
                        <FeatureTag
                            bgColor={
                                inner_data.post_terms[0].metadata
                                    .category_bg_color
                            }>
                            {ReactHtmlParser(inner_data.post_terms[0].name)}
                        </FeatureTag>
                    )}
                    {inner_data && inner_data.metadata.post_type_video && (
                        <VideoPlayIcon>
                            <RiPlayCircleLine color={colors.white['100']} />
                        </VideoPlayIcon>
                    )}
                </a>
            </CardImg>
            <CardDetails>
                <a
                    href={
                        externalPost
                            ? externalPost
                            : '/resources/blogs/' + blogData.post_name
                    }
                    target={externalPost && '_blank'}>
                    <h3>{blogData.post_title}</h3>
                </a>
                <p>
                    {inner_data.metadata &&
                        inner_data.metadata.short_description &&
                        inner_data.metadata.short_description.substr(0, 68) +
                            '...'}
                </p>
            </CardDetails>
        </Wrapper>
    );
};
const VideoPlayIcon = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        font-size: 50px;
        transition: all 0.3s ease;
    }
    ${Media('xlscreens')} {
        svg {
            font-size: 2.6vw;
        }
    }
`;
const CardDetails = styled.div`
    flex: 1;
    padding-left: 15px;
    h3 {
        font-size: 16px;
        font-family: ${gilroyBold};
        transition: all 0.3s ease;
        margin-top: 4px;
        margin-bottom: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    p {
        font-size: 14px;
        line-height: 1.2;
        color: ${colors.gray['100']};
        margin-top: 5px;
        margin-bottom: 20px;
    }
    ${Media('xlscreens')} {
        padding-left: 0.78vw;
        h3 {
            font-size: 0.95vw;
            margin-bottom: 0.32vw;
        }
        p {
            font-size: 0.82vw;
        }
    }
    ${Media('tablet')} {
        h3 {
            margin-top: 0px;
        }
    }
`;

const Wrapper = styled.div`
    margin-bottom: 14px;
    display: flex;
    align-items: flex-start;
    &:hover {
        ${CardDetails} {
            h3 {
                color: ${colors.pink['100']};
            }
        }
        ${VideoPlayIcon} {
            svg {
                transform: scale(1.3);
            }
        }
    }
    ${Media('xlscreens')} {
        margin-bottom: 0.73vw;
    }
    ${Media('tablet')} {
        margin-bottom: 30px;
    }
`;

const CardImg = styled.a`
    max-width: 40%;
    position: relative;
    flex: 0 0 40%;
    height: 30%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    ${Media('xlscreens')} {
        height: 7.4vw;
    }
`;

const FeatureTag = styled.span`
    position: absolute;
    left: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 11px;
    display: inline-flex;
    padding: 3px 10px;
    color: ${colors.white['100']};
    background-color: ${(props) =>
        props.bgColor ? props.bgColor : colors.pink['100']};
    ${Media('xlscreens')} {
        padding: 0.15vw 0.52vw;
    }
`;

export default BlogCard;
