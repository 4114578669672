import React from 'react';
import colors from 'shared-components/theme/colors';
import { gilroyBold, gilroyBlack } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';

export const FCXPageHeading = ({
    children,
    size,
    letterSpacing,
    color,
    alignCenter,
}) => {
    return (
        <PageHeading
            size={size}
            letterSpacing={letterSpacing}
            color={color}
            alignCenter={alignCenter}>
            {children}
        </PageHeading>
    );
};

const PageHeading = styled.h2`
    font-size: ${(props) => (props.size === 'small' ? '32px' : '50px')};
    font-family: ${(props) =>
        props.size === 'small' ? gilroyBlack : gilroyBold};
    margin: 0px;
    line-height: ${(props) => (props.size === 'small' ? '1.3' : '1')};
    letter-spacing: ${(props) => props.letterSpacing && '1px'};
    color: ${(props) => (props.color ? props.color : colors.fintech['text'])};
    text-transform: uppercase;
    text-align: ${(props) => props.alignCenter && 'center'};
    span {
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.size === 'small' ? '1.82vw' : '2.96vw')};
    }
    ${Media('mobile')} {
        font-size: ${(props) => (props.size === 'small' ? '26px' : '38px')};
        br {
            display: none;
        }
    }
`;
