import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import Loader from 'components/loader/loader';
import styled from 'styled-components';
import Layout from 'components/layout';
import { ResourceCard, FeaturedPostSlider } from 'components/resource';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import { gilroyBold } from 'shared-components/theme/fonts';

const AllWebinars = () => {
    const [webinars, setWebinars] = useState(null);
    const [loader, setLoader] = useState(false);
    const [featuredPosts, setFeaturedPosts] = useState({});

    useEffect(() => {
        getWebinars();
    }, []);
    const getWebinars = async () => {
        setLoader(true);
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/webinars?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        per_page: 25,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                setLoader(false);
                const featured = resData.data.filter(
                    (d) => d.metadata.is_featured
                );
                setFeaturedPosts(featured);
                setWebinars(resData.data);
            }
        } catch (err) {
            console.error(err);
        }
    };
    const ResourceCardWrapper = ({ data }) => {
        return (
            data &&
            data.map((d, ind) => {
                return (
                    <Col sm="12" lg="6" key={ind}>
                        <div>
                            <ResourceCard data={d} />
                        </div>
                    </Col>
                );
            })
        );
    };
    return (
        <Layout>
            {(loader && <Loader />) || (
                <>
                    <MainWrapper>
                        <Container>
                            <RowCentered>
                                <Col sm="12" lg="8">
                                    <PageHeading>Webinars</PageHeading>
                                </Col>
                            </RowCentered>
                            <Row>
                                <Col sm="12">
                                    {featuredPosts &&
                                        featuredPosts.length > 0 && (
                                            <FeaturedPostSlider
                                                featuredData={featuredPosts}
                                            />
                                        )}
                                </Col>
                            </Row>
                        </Container>
                    </MainWrapper>
                    <ResourceTabsSection>
                        <Container>
                            <Row>
                                <Col sm="12">
                                    <TabContent>
                                        <Row>
                                            <ResourceCardWrapper
                                                data={webinars}
                                            />
                                        </Row>
                                    </TabContent>
                                </Col>
                            </Row>
                        </Container>
                    </ResourceTabsSection>
                </>
            )}
        </Layout>
    );
};

const ResourceTabsSection = styled.section`
    padding-top: 0px;
`;

const TabContent = styled.div`
    margin: 50px 0px 20px;
    ${Media('xlscreens')} {
        margin: 5vw 0 2vw;
    }
`;
const MainWrapper = styled.section`
    position: relative;
    .slick-dots {
        button::before {
            font-size: 40px;
        }
    }
    ${Media('xlscreens')} {
        .slick-dots {
            bottom: -2vw;
            button::before {
                font-size: 2vw;
            }
        }
    }
    ${Media('mobile')} {
        .slides {
            height: 200px;
        }
    }
`;

const PageHeading = styled.h1`
    font-size: 48px;
    font-family: ${gilroyBold};
    letter-spacing: -0.5px;
    margin: 0px;
    ${Media('xlscreens')} {
        font-size: 3.125vw;
        letter-spacing: -0.14vw;
    }
    ${Media('tablet')} {
        font-size: 42px;
        margin-bottom: 30px;
    }
    ${Media('mobile')} {
        font-size: 36px;
    }
`;

export default AllWebinars;
