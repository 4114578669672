import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import colors from 'shared-components/theme/colors';
import ReactHtmlParser from 'react-html-parser';
import Button from 'shared-components/button/button';
import { EbookFloatingImage } from 'components/ebook/common';
import {
    IndustryTag,
    IndustrySectionHeading,
} from 'components/industries/common';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import ScrollAnimation from 'react-animate-on-scroll';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -210,
    },
    {
        name: 'approach',
        target: 'approach',
        offset: -20,
    },
    {
        name: 'utility',
        target: 'utility',
        offset: -20,
    },
];

const introList = [
    {
        icon: `${baseURL}assets/images/utilities/csweek-intro-icon-1.svg`,
        text: 'Click the link and grab a slot to meet 1:1. 📅',
    },
    {
        icon: `${baseURL}assets/images/utilities/csweek-intro-icon-2.svg`,
        text:
            'Learn how we can help Utilities like you make impactful digital changes 🤝',
    },
    {
        icon: `${baseURL}assets/images/utilities/csweek-intro-icon-3.svg`,
        text: 'Watch the value of your Utility brand get AMPLIFIED ⚡️',
    },
    {
        icon: `${baseURL}assets/images/utilities/csweek-intro-icon-4.svg`,
        text: 'We’ll be at booth 631!',
    },
];

const meetingList = [
    {
        image: `${baseURL}assets/images/utilities/mark-hr.png`,
        name: 'Mark Wilkinson',
        place: 'Utility SmartHome Marketplace',
        link: 'https://calendly.com/mark-wilkinson',
    },
    {
        image: `${baseURL}assets/images/utilities/meeting-3.jpg`,
        name: 'Bob McCallister',
        place: 'BPO 2.0 - Digital CX Transformation',
        link: 'https://calendly.com/bob-mccallister',
    },
    {
        image: `${baseURL}assets/images/utilities/langley.png`,
        name: 'Langley Van Der Kley',
        place: 'Director, Global Business Development',
        link: 'https://calendly.com/langleyvdk',
    },
];

const approachList = [
    'Ibex Digital focuses on delivering a modern digital marketplace to expand the Utility catalog after the move and beyond the meter',
    'Enhancing the digital customer engagement can deliver real improvements for customer conservation goals and improved energy efficiency',
    'Utilities can create an meaningful digital conversation using the ibex approach - special persona based programs to keep the digital dialogue going throughout the full customer lifecycle.',
];

const utilityList = [
    {
        icon: `${baseURL}assets/images/utilities/utility-icon-1.svg`,
        title: 'New Connection Concierge',
        para:
            'With just one call or click deliver the whole home experience. Expand your energy start to include essential home services.',
    },
    {
        icon: `${baseURL}assets/images/utilities/utility-icon-2.svg`,
        title: 'All-In-One Digital Marketplace',
        para:
            'A ready-to-launch eCommerce platform filled with your products and services, ready to fit every utility customer’s needs.',
    },
    {
        icon: `${baseURL}assets/images/utilities/utility-icon-3.svg`,
        title: 'Mindshare Digital Outreach',
        para:
            'Create continuous and meaningful mindshare with your customers with regular digital outreach campaigns to drive awareness, adoption, and LTV.',
    },
    {
        icon: `${baseURL}assets/images/utilities/utility-icon-4.svg`,
        title: 'QuickPulse Customer Surveys',
        para:
            'Measure, monitor, and act on customer feedback in real-time, and continuously evolve your brand and customer experience.',
    },
];

const CSWeek = () => {
    return (
        <Layout>
            <ScrollNav data={scrollNavData} />
            <EbookFloatingImage
                image={`${baseURL}assets/images/utilities/csweek-intro-triangle.svg`}
                width={1209}
                top="-15%"
                right="-16%"
                zIndex="0"
                x={['20%', '-20%']}
            />

            <Breadcrumbs>
                <li>Utilities</li>
                <li>CSWeek</li>
            </Breadcrumbs>

            <IntroSection id="home">
                <Container>
                    <Row>
                        <Col sm="12" lg="7">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}
                            >
                                <IntroLogos>
                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/ibex-logo.svg`}
                                            alt="-"
                                            height="36"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/utilities/csweek-logo.svg`}
                                            alt="-"
                                            height="52"
                                        />
                                    </li>
                                </IntroLogos>
                                <PageHeading>
                                    Utility CX in an Amazon World.
                                </PageHeading>
                                <PagePara>
                                    We’re at CS Week offering guidance for
                                    Utilities on how to deliver <br /> digital
                                    customer experiences in a click-takes-all
                                    marketplace.
                                </PagePara>
                                <IntroListHead>
                                    Connect with us and begin transforming your
                                    digital Utility CX.
                                </IntroListHead>
                                <IntroList>
                                    {introList &&
                                        introList.length > 0 &&
                                        introList.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <IntroIcon>
                                                        <img
                                                            src={item.icon}
                                                            alt="-"
                                                        />
                                                    </IntroIcon>
                                                    <PagePara noMarginBottom>
                                                        {ReactHtmlParser(
                                                            item.text
                                                        )}
                                                    </PagePara>
                                                </li>
                                            );
                                        })}
                                </IntroList>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12" lg="5">
                            {meetingList &&
                                meetingList.length > 0 &&
                                meetingList.map((item, index) => {
                                    return (
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={false}
                                            delay={`${index + 1}00`}
                                            key={index}
                                        >
                                            <MeetingBox>
                                                <MeetingImg>
                                                    <img
                                                        src={item.image}
                                                        alt="-"
                                                    />
                                                </MeetingImg>
                                                <MeetingContent>
                                                    <IntroListHead>
                                                        {item.name}
                                                    </IntroListHead>
                                                    <PagePara>
                                                        {item.place}
                                                    </PagePara>
                                                    <Button
                                                        size="small"
                                                        round
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={item.link}
                                                    >
                                                        Book a Meeting
                                                    </Button>
                                                </MeetingContent>
                                            </MeetingBox>
                                        </ScrollAnimation>
                                    );
                                })}
                        </Col>
                    </Row>
                </Container>
            </IntroSection>

            <ApproachSection id="approach">
                <Container>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}
                            >
                                <ApproachHead>
                                    <IndustryTag>
                                        Digital Customer Engagement
                                    </IndustryTag>
                                    <IndustrySectionHeading bigText>
                                        A Modern Approach to the Modern Utility
                                        Customer
                                    </IndustrySectionHeading>
                                </ApproachHead>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12" lg="6">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}
                            >
                                <ApproachImage>
                                    <img
                                        src={`${baseURL}assets/images/utilities/approach-image.jpg`}
                                        alt="-"
                                    />
                                </ApproachImage>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12" lg="6">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}
                            >
                                <ApproachContent>
                                    <PagePara>
                                        The measure of a successful marketplace
                                        - beyond launching <br /> the storefront
                                        - is measured in both repeat visitors
                                        and <br />
                                        recurring transactions.
                                    </PagePara>
                                    {approachList &&
                                        approachList.length > 0 &&
                                        approachList.map((item, index) => {
                                            return (
                                                <ApproachList key={index}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 35.875 35.875"
                                                    >
                                                        <path
                                                            id="Icon_awesome-check-circle"
                                                            data-name="Icon awesome-check-circle"
                                                            d="M35.438,18A17.438,17.438,0,1,1,18,.563,17.437,17.437,0,0,1,35.438,18ZM15.983,27.233,28.921,14.3a1.125,1.125,0,0,0,0-1.591L27.33,11.114a1.125,1.125,0,0,0-1.591,0L15.188,21.665l-4.926-4.926a1.125,1.125,0,0,0-1.591,0L7.079,18.329a1.125,1.125,0,0,0,0,1.591l7.313,7.313a1.125,1.125,0,0,0,1.591,0Z"
                                                            transform="translate(-0.063 -0.063)"
                                                            fill="rgba(250,0,96,0.25)"
                                                            stroke="#fa0060"
                                                            strokeWidth="1"
                                                        />
                                                    </svg>
                                                    <PagePara noMarginBottom>
                                                        {item}
                                                    </PagePara>
                                                </ApproachList>
                                            );
                                        })}
                                </ApproachContent>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </ApproachSection>

            <UtilitySection id="utility">
                <Container fluid>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}
                            >
                                <ApproachHead>
                                    <IndustrySectionHeading>
                                        Introducing the leading full service
                                        solution <br /> for Utility Customer
                                        Engagement.
                                    </IndustrySectionHeading>
                                </ApproachHead>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        {utilityList &&
                            utilityList.length > 0 &&
                            utilityList.map((item, index) => {
                                return (
                                    <Col sm="12" lg="3" key={index}>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={false}
                                            delay={`${index + 1}00`}
                                        >
                                            <UtilityBox>
                                                <UtilIcon>
                                                    <img
                                                        src={item.icon}
                                                        alt="-"
                                                    />
                                                </UtilIcon>
                                                <h3>{item.title}</h3>
                                                <p>{item.para}</p>
                                            </UtilityBox>
                                        </ScrollAnimation>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </UtilitySection>
        </Layout>
    );
};

const IntroSection = styled.section`
    position: relative;
    z-index: 2;
    ${Media('tablet')} {
        padding: 50px 0 0;
    }
`;

const IntroLogos = styled.ul`
    margin-bottom: 30px;
    padding: 0;
    display: flex;
    align-items: center;
    li {
        padding: 10px 0;
        &:first-child {
            padding-right: 20px;
            margin-right: 20px;
            border-right: 1px solid ${colors.gray_dark['25']};
        }
    }
    img {
        max-width: 100%;
    }
    ${Media('xlscreens')} {
        margin-bottom: 2.2vw;
        li {
            padding: 0.52vw 0;
            &:first-child {
                padding-right: 1.05vw;
                margin-right: 1.05vw;
            }
        }
        img {
            max-width: 11.1vw;
        }
    }
`;

const PageHeading = styled.h2`
    font-size: 44px;
    font-family: ${gilroySemibold};
    margin-bottom: 40px;
    margin-top: 0px;
    ${Media('xlscreens')} {
        font-size: 2.6vw;
        margin-bottom: 2.5vw;
    }
    ${Media('mobile')} {
        font-size: 36px;
    }
`;

const PagePara = styled.p`
    font-size: 16px;
    font-family: ${gilroyMedium};
    margin: 0px;
    margin-bottom: 30px;
    margin-bottom: ${(props) => props.noMarginBottom && '0'};
    ${Media('xlscreens')} {
        font-size: 1.05vw;
        margin-bottom: 2vw;
        margin-bottom: ${(props) => props.noMarginBottom && '0'};
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
`;

const IntroListHead = styled.div`
    font-size: 18px;
    font-family: ${gilroyBold};
    margin: 0 0 30px;
    ${Media('xlscreens')} {
        font-size: 1.15vw;
        margin: 1vw 0 1.5vw;
    }
`;

const IntroList = styled.ul`
    margin: 30px 0 0;
    padding: 0px;
    li {
        display: flex;
        align-items: center;
        padding: 10px 0;
        &:nth-child(odd) {
            background-color: ${colors.black['3']};
        }
    }
    ${Media('xlscreens')} {
        padding-right: 1.3vw;
        li {
            padding: 0.62vw 0;
        }
    }
`;

const IntroIcon = styled.div`
    margin: 0 35px 0 20px;
    img {
        max-width: 27px;
        max-height: 30px;
    }
    ${Media('xlscreens')} {
        margin: 0 2vw 0 1.2vw;
        img {
            max-width: 1.45vw;
            max-height: 1.58vw;
        }
    }
    ${Media('mobile')} {
        margin: 0 25px 0 20px;
    }
`;

const MeetingBox = styled.div`
    display: flex;
    align-items: center;
    margin-top: 50px;
    ${Media('xlscreens')} {
        padding-left: 2.5vw;
        margin-top: 2vw;
        &:first-child {
            margin-top: 3vw;
        }
    }
`;

const MeetingImg = styled.div`
    flex: 0 0 175px;
    max-width: 175px;
    margin-right: 15px;
    img {
        border-radius: 15px;
        max-width: 100%;
    }
    ${Media('xlscreens')} {
        flex: 0 0 9.11vw;
        max-width: 9.11vw;
        margin-right: 1.1vw;
    }
    ${Media('mobile')} {
        flex: 0 0 160px;
        max-width: 160px;
    }
`;

const MeetingContent = styled.div`
    flex: 1 0;
    ${IntroListHead}, ${PagePara} {
        margin: 0;
        padding: 0 0 10px;
    }
    button,
    a {
        margin-top: 5px;
    }
    ${Media('xlscreens')} {
        ${IntroListHead}, ${PagePara} {
            margin: 0;
            padding: 0 0 0.6vw;
            white-space: nowrap;
        }
        button,
        a {
            margin-top: 0.3vw;
        }
    }
`;

const ApproachSection = styled.section`
    padding-bottom: 0px;
    ${Media('xlscreens')} {
        padding-bottom: 2vw;
    }
`;

const ApproachHead = styled.div`
    text-align: center;
    padding-bottom: 20px;
    ${Media('mobile')} {
        padding-bottom: 0;
    }
`;

const ApproachImage = styled.div`
    img {
        border-radius: 15px;
        max-width: 100%;
    }
`;

const ApproachContent = styled.div`
    ${Media('tablet')} {
        margin-top: 50px;
    }
    ${Media('xlscreens')} {
        padding-left: 4vw;
    }
`;

const ApproachList = styled.div`
    display: flex;
    margin: 10px 0 20px;
    svg {
        flex: 0 0 31px;
        max-width: 31px;
        height: 31px;
        margin-right: 20px;
        margin-top: 4px;
    }
    ${Media('xlscreens')} {
        margin: 2.5vw 0 0;
        padding-right: 1vw;
        svg {
            flex: 0 0 1.55vw;
            max-width: 1.55vw;
            height: 1.55vw;
            margin-right: 2.3vw;
            margin-top: 0.2vw;
        }
    }
`;

const UtilitySection = styled.section`
    ${Media('xlscreens')} {
        .container {
            padding: 0 5vw 0 10vw !important;
        }
    }
    ${Media('tablet')} {
        padding-top: 30px;
    }
`;

const UtilityBox = styled.div`
    margin-top: 50px;
    h3 {
        font-size: 18px;
        font-family: ${gilroyBold};
        margin-top: 0px;
    }
    p {
        font-size: 16px;
        font-family: ${gilroyMedium};
        margin: 0px;
    }
    ${Media('xlscreens')} {
        margin-top: 3vw;
        padding-right: 4vw;
        h3 {
            font-size: 1.05vw;
            margin-bottom: 2.3vw;
        }
        p {
            font-size: 0.95vw;
        }
    }
    ${Media('tablet')} {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
`;

const UtilIcon = styled.div`
    margin-bottom: 30px;
    img {
        width: auto;
        max-height: 85px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 2.2vw;
        img {
            max-height: 4.4vw;
        }
    }
`;

export default CSWeek;
