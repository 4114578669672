import React, { useEffect, useRef } from 'react';
import ProgressBar from 'progressbar.js';
import { Col, Row } from '@bootstrap-styled/v4';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';

import { Para } from '../cx-pocket-guide/para';
import { BigHeading } from '../cx-pocket-guide/big-heading';
import { BlockImg } from './block-img';
import { IntroPara } from './intro-para';
import { Source } from './source';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';

export const Point7 = ({ active }) => {
    const barProgress1 = useRef(null);

    const BarProgress = (element, barValue) => {
        if (element.current) {
            var bar = new ProgressBar.Line(element.current, {
                strokeWidth: 14,
                easing: 'easeInOut',
                duration: 1400,
                color: colors.pink['100'],
                trailColor: colors.cx_pocket_guide['pink'],
                trailWidth: 14,
                svgStyle: { width: '100%', height: '100%' },
                text: {
                    style: {
                        display: 'none',
                    },
                },
                from: { color: colors.cx_pocket_guide['100'] },
                to: { color: colors.cx_pocket_guide['100'] },
                step: (state, bar) => {
                    bar.setText(Math.round(bar.value() * 100) + ' %');
                },
            });

            bar.animate(barValue);
        }
    };

    useEffect(() => {
        BarProgress(barProgress1, 0.82);
    }, [active]);

    return (
        <>
            <Row>
                <Col lg="7">
                    <BarAnimation>
                        {active && <div style={{ height: '100%' }} ref={barProgress1}></div>}
                    </BarAnimation>
                    <BlockImg
                        imgUrl={`${baseURL}assets/images/ebook/imperative-ebook/point7_block_img.png`}
                    />
                </Col>
                <Col lg={{ size: 4, offset: 1 }}>
                    <IntroPara noMarginTop>
                        82% of U.S. consumers want more{' '}
                        <span>human interaction</span> in the future.
                    </IntroPara>
                    <Source color={colors.white['100']} style2>
                        Source: pwc
                    </Source>
                    <BigHeading color={colors.cx_pocket_guide['pink']} typical active={active}>
                        We sure do hear a lot about technology these days. RPA,
                        Automation, AI. And yet, people are busier than ever.
                    </BigHeading>
                </Col>
            </Row>
            <Para color={colors.white['100']}>
                The easy trap for any brand and their outsourcer is to over-rely
                on technology as the primary solution to their CX woes.
                Technology can do wonders, but it’s only as good as the inputs
                it receives. Garbage in, garbage out, y’know? These inputs
                depend on people – see Principle no. 1.
            </Para>
            <Para color={colors.white['100']}>
                Technology is an enabler. If your CX outsourcer puts tech before
                people, it might be time to CTRL+Z that relationship.
            </Para>
        </>
    );
};

const BarAnimation = styled.div`
    height:50px;
    ${Media('xlscreens')}{
        height:6vw;
    }
`;
