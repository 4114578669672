import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import {
    UtilPagePara,
    UtilStepHeading,
    UtilStepHeadText,
    UtilTag,
} from '../common';
import MainHeading from 'shared-components/main-heading/main-heading';
import { gilroyMedium } from 'shared-components/theme/fonts';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import colors from 'shared-components/theme/colors';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import { baseURL } from 'shared-components/theme/helpers';

const features = [
    {
        bgColor: 'light',
        icon: `${baseURL}assets/images/step-two-feature-1.svg`,
        title: 'Essential Home Connections',
        desc: 'Internet, TV, Phone and Wireless offers',
    },
    {
        bgColor: 'dark',
        icon: `${baseURL}assets/images/step-two-feature-2.svg`,
        title: 'Helpful Repair and Protection Plans',
        desc: 'Avoid unexpected repair costs in a new home',
    },
    {
        bgColor: 'light',
        icon: `${baseURL}assets/images/step-two-feature-3.svg`,
        title: 'Local Pros and Special Offers',
        desc: 'Help connect customers and the local community',
    },
    {
        bgColor: 'dark',
        icon: `${baseURL}assets/images/step-two-feature-4.svg`,
        title: 'Digital Coupons and Offers',
        desc: 'Give customer a reason to reconnect across the lifecycle',
    },
];

export const UtilStepTwoSection = () => {
    return (
        <UtilStepSecond>
            <Container>
                <Row>
                    <Col lg="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <UtilStepHeading>
                                <UtilTag>02</UtilTag>
                                <MainHeading noMargin={true}>
                                    Catalog of Helpful, Timely, <br /> and
                                    Trusted Offers
                                </MainHeading>
                            </UtilStepHeading>
                            <UtilPagePara>
                                Utilities rank among the best scoring companies
                                for customer trust and credibility, providing a
                                unique advantage on recommendations and offers
                                during your new customer experience.
                            </UtilPagePara>
                            <UtilPagePara>
                                <span>Create customer choice.</span> Offering
                                choice for essential home connections like
                                TV/Internet/Phone and Wireless affirms to your
                                customers that you work with established and
                                recognized service providers in your area, and
                                give them the opportunity to compare and choose
                                from the best offers for their individual needs.
                                Limited catalogs can leave customers with the
                                impression of limited choice, subverting your
                                brand credibility and trust.
                            </UtilPagePara>
                            <UtilPagePara>
                                <span>One stop Home Connection shopping.</span>
                                &nbsp;By providing a Utility New Connection
                                program that delivers one stop shopping for new
                                home services, the utility provides added value
                                to the customer further enhancing the Utilities
                                trusted advisor role. Thus, consider offers from
                                other essential categories based on the needs of
                                your new customers. During a move, customers may
                                worry about unexpected repairs or need special
                                services at their new home. The New Connection
                                experience provides the perfect opportunity to
                                offer service plans or access to contractors to
                                help with repair challenges.
                            </UtilPagePara>
                        </ScrollAnimation>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StepTwoImage>
                            <Row>
                                <Col lg="6">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <img
                                            src={`${baseURL}assets/images/step-two-image.jpg`}
                                            alt="-"
                                        />
                                    </ScrollAnimation>
                                </Col>
                                <Col lg="6">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}
                                        delay={500}>
                                        <UtilPagePara>
                                            <span>
                                                Treat every move as an
                                                inflection point.
                                            </span>
                                            &nbsp;Moving presents the most
                                            likely time for a consumer to switch
                                            from their “normal” routines and
                                            products, especially if they are
                                            moving to your region from a
                                            significant distance. Customers may
                                            need to learn the new grocery and
                                            home goods brands, or find a new
                                            wholesale club, or pick a new
                                            dentist. A Utility New Connection
                                            experience that delivers unique and
                                            special offers in the marketplace
                                            creates a distinct advantage, which
                                            increases the trust and appreciation
                                            customers associate with their
                                            utility company.
                                        </UtilPagePara>
                                        <UtilPagePara>
                                            <span>
                                                Continue the conversation.
                                            </span>
                                            &nbsp;Finally, consider offers that
                                            create links to ongoing customer
                                            outreach and communication. Most
                                            mover programs only focus on a few
                                            services, but a world-class Utility
                                            New Connection experience learns
                                            customer interests and collects
                                            contact information to make follow
                                            up communication welcome and
                                            rewarding. For example, sending a
                                            digital welcome pack of special
                                            offers or coupons two to four weeks
                                            after a move reminds customers of
                                            special values before they will get
                                            their first bill. Or, present
                                            options for home security or energy
                                            efficiency products ~30-days after
                                            the move once customers settle into
                                            their new residence, to offer a
                                            valuable service and extend the
                                            digital conversation. These offers
                                            and emails encourage customers to
                                            stay engaged and on the lookout for
                                            your next communication. Consider a
                                            range of products and services to
                                            help customers and give them a
                                            reason to return to your website or
                                            support center.
                                        </UtilPagePara>
                                    </ScrollAnimation>
                                </Col>
                            </Row>
                        </StepTwoImage>
                    </Col>
                </Row>
                <Row>
                    {features && features.length > 0
                        ? features.map((item, index) => {
                              return (
                                  <Col lg="12" key={index}>
                                      <ScrollAnimation
                                          animateIn="fadeInUp"
                                          animateOnce={false}
                                          delay={200}>
                                          <StepTwoFeature bg={item.bgColor}>
                                              <RowCentered>
                                                  <Col lg="2">
                                                      <StepTwoFeatureImage>
                                                          <img
                                                              src={item.icon}
                                                              alt="-"
                                                          />
                                                      </StepTwoFeatureImage>
                                                  </Col>
                                                  <Col lg="5">
                                                      <StepTwoFeatureText
                                                          head={true}>
                                                          {item.title}
                                                      </StepTwoFeatureText>
                                                  </Col>
                                                  <Col lg="5">
                                                      <StepTwoFeatureText>
                                                          {item.desc}
                                                      </StepTwoFeatureText>
                                                  </Col>
                                              </RowCentered>
                                          </StepTwoFeature>
                                      </ScrollAnimation>
                                  </Col>
                              );
                          })
                        : null}
                </Row>
            </Container>
            <UtilStepTwoLower>
                <Container>
                    <Row>
                        <Col lg={{ size: 10, offset: 1 }}>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <UtilStepHeadText>
                                    Just like going “above and beyond” in a new
                                    customer experience creates lasting customer
                                    loyalty, your New Connection experience
                                    should deliver more than the basics of a
                                    mover program. Consider that the entire new
                                    customer experience extends the value and
                                    connectedness of your customer relationship
                                    when selecting offers and partners for your
                                    program.
                                </UtilStepHeadText>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <img
                                    src={`${baseURL}assets/images/step-two-lower-image.jpg`}
                                    alt="-"
                                />
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </UtilStepTwoLower>
        </UtilStepSecond>
    );
};

const UtilStepSecond = styled.section`
    background-color: ${colors.gray_lightest['50']};
    padding-bottom: 0;
    ${Media('xlscreens')} {
        padding: 7vw 0 0;
    }
`;

const StepTwoImage = styled.div`
    padding-top: 50px;
    padding-bottom: 50px;
    img {
        border-radius: 20px;
        max-width: 100%;
    }
    ${Media('xlscreens')} {
        padding-top: 3vw;
        padding-bottom: 3vw;
        p {
            padding-right: 4vw;
            padding-top: 10px;
        }
    }
    ${Media('tablet')} {
        img {
            margin-bottom: 50px;
        }
    }
`;

const StepTwoFeature = styled.div`
    background-color: ${(props) =>
        props.bg === 'light'
            ? colors.utilities['gray_light']['100']
            : colors.utilities['gray']['50']};
    margin-bottom: 10px;
    ${Media('xlscreens')} {
        margin-bottom: 0.45vw;
    }
    ${Media('tablet')} {
        padding-bottom: 30px;
    }
`;

const StepTwoFeatureImage = styled(FlexCentered)`
    img {
        max-width: 100%;
        max-height: 80px;
    }
    ${Media('xlscreens')} {
        img {
            width: 3.4vw;
            max-height: 4.1vw;
        }
    }
    ${Media('tablet')} {
        padding-top: 30px;
        padding-bottom: 10px;
    }
`;

const StepTwoFeatureText = styled.div`
    font-size: ${(props) => (props.head ? '21px' : '20px')};
    font-family: ${gilroyMedium};
    min-height: 140px;
    display: flex;
    align-items: center;
    ${Media('xlscreens')} {
        min-height: 7.6vw;
        font-size: ${(props) => (props.head ? '1.1vw' : '1.05vw')};
    }
    ${Media('tablet')} {
        min-height: 1px;
        font-size: 16px;
        text-align: center;
        justify-content: center;
        padding: 20px 50px 0;
    }
`;

const UtilStepTwoLower = styled.section`
    position: relative;
    padding-bottom: 0;
    z-index: 0;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 35%;
        z-index: -1;
        background-color: ${colors.gray_lighter['100']};
    }
    img {
        border-radius: 20px;
        max-width: 100%;
    }
    ${Media('xlscreens')} {
        h4 {
            margin-bottom: 6vw;
            padding-left: 0.7vw;
            padding-right: 0.7vw;
        }
        padding: 7vw 0 0;
    }
    ${Media('tablet')} {
        &:after {
            height: 20%;
        }
        h4 {
            font-size: 18px;
            br {
                display: none;
            }
        }
        .parallax-inner {
            transform: none !important;
        }
    }
`;
