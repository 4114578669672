import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import LayersCards from 'components/social-rep-man/layer-card';
import { SRMSectionHeading } from 'components/social-rep-man/common';
import MainPara from 'shared-components/main-para/main-para';

const layers = [
    {
        image: `${baseURL}assets/images/digital-customer-care.png`,
        title: 'Digital Customer Care Agents',
        desc:
            'Enable your teams to <br />immediately identify and respond <br />to incoming feedback, using a <br />consistent strategy & brand voice <br />for the ultimate in long-term <br />customer affinity and value.',
    },
    {
        image: `${baseURL}assets/images/cx-program.png`,
        title: 'CX Program Leadership',
        desc:
            'Empower your program team <br />with up-leveled insights on <br />where your brand is <br />performing well, and where <br />you need to apply additional <br />strategic focus.',
    },
    {
        image: `${baseURL}assets/images/product-marketing-img.png`,
        title: 'Product & <br/>Marketing <br/>Leadership',
        desc:
            'Help your Product & Marketing <br />teams understand points of <br />friction in the product, and <br />drive product improvement <br />and evolution.',
    },
];

export const SynchronizeSection = () => {
    return (
        <Wrapper>
            <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce={true}
                delay={100}>
                <SRMSectionHeading>
                    Synchronize Social Strategy & Insight <br />
                    Across Your CX Teams
                </SRMSectionHeading>
                <MainPara align="center">
                    Social equity drives direct business value. Our technology
                    makes it easy to disseminate <br />
                    strategic insights across your operational value chain for
                    unified brand action.
                </MainPara>
            </ScrollAnimation>
            <Controller>
                <Scene triggerHook="onEnter" duration="50%" offset="100%">
                    <Timeline>
                        <Tween
                            from={{
                                transform: 'translateY(-24px)',
                            }}
                            to={{
                                transform: 'translateY(0px)',
                            }}>
                            <BgImageDesktop>
                                <img
                                    src={`${baseURL}assets/images/layer-bg.svg`}
                                    alt="-"
                                />
                            </BgImageDesktop>
                        </Tween>
                    </Timeline>
                </Scene>
            </Controller>

            <BgImageMobileOne>
                <img
                    src={`${baseURL}assets/images/synchronize-bg-mobile.png`}
                    alt="-"
                />
            </BgImageMobileOne>
            <BgImageMobileTwo>
                <img
                    src={`${baseURL}assets/images/synchronize-bg-mobile.png`}
                    alt="-"
                />
            </BgImageMobileTwo>
            <Container fluid={true}>
                <Row>
                    {layers &&
                        layers.length > 0 &&
                        layers.map((item, index) => {
                            return (
                                <Col lg={{ size: 4, offset: 0 }} key={index}>
                                    <LayersCards
                                        layerCardimg={item.image}
                                        HeadingLayerCard={item.title}
                                        ParagraphLayerCard={item.desc}
                                    />
                                </Col>
                            );
                        })}
                </Row>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.section`
    background-color: ${colors.gray_lighter['100']};
    position: relative;
    ${Media('xlscreens')} {
        padding: 5vw 9vw;
    }
`;

const BgImageDesktop = styled.div`
    position: absolute;
    left: 7vw;
    top: 546px;
    img {
        height: 546px;
    }
    ${Media('xlscreens')} {
        top: 41vw;
        img {
            height: 28.4vw;
        }
    }
    ${Media('tablet')} {
        display: none;
    }
`;
const BgImageMobileOne = styled.div`
    position: absolute;
    left: 0px;
    top: 546px;
    img {
        height: 440px;
    }
    ${Media('xlscreens')} {
        display: none;
    }
`;

const BgImageMobileTwo = styled.div`
    position: absolute;
    left: 0px;
    bottom: 350px;
    img {
        height: 440px;
        object-fit: cover;
        object-position: -720px;
    }
    ${Media('xlscreens')} {
        display: none;
    }
`;
