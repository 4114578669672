import React from 'react';
import { gilroyMedium, gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
export const Para = ({ children, align, color, fontFamily, spanColor }) => {
    return (
        <Wrapper
            align={align}
            color={color}
            spanColor={spanColor}
            fontFamily={fontFamily}>
            {children}
        </Wrapper>
    );
};
const Wrapper = styled.p`
    font-size: 22px;
    font-family: ${gilroyMedium};
    text-align: ${(props) => (props.align ? props.align : 'center')};
    color: ${(props) => props.color && props.color};
    span {
        font-family: ${(props) =>
            props.fontFamily ? props.fontFamily : gilroyBold};
        color: ${(props) => props.spanColor && props.spanColor};
    }
    ${Media('xlscreens')} {
        font-size: 1.14vw;
    }
    ${Media('tablet')} {
        font-size: 16px;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
