import { Col, Row } from '@bootstrap-styled/v4';
import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import MainPara from 'shared-components/main-para/main-para';
import colors from 'shared-components/theme/colors';
import { FlexColumnCentered } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';
import { SRMSectionHeading } from './section-heading';
import { ContainerWideSRM } from './index';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    gilroyMedium,
    gilroyRegular,
    gilroyBold,
} from 'shared-components/theme/fonts';

export const SRMonitoringSection = ({
    id,
    heading,
    paragraph,
    listData,
    boxHeight,
    styleTwo,
}) => {
    return (
        <SectionWrap id={id}>
            <ContainerWideSRM>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <Row>
                        <Col xs="12">
                            <SRMSectionHeading color={colors.white['100']}>
                                {ReactHtmlParser(heading)}
                            </SRMSectionHeading>
                            <MainPara
                                align="center"
                                styleTwo={styleTwo}
                                color={colors.white['100']}>
                                {ReactHtmlParser(paragraph)}
                            </MainPara>
                        </Col>
                    </Row>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <Row noGutters>
                        {listData &&
                            listData.length > 0 &&
                            listData.map((item, index) => {
                                return (
                                    <Col sm="12" md="6" lg="3" key={index}>
                                        <MonitorBox height={boxHeight}>
                                            <Icon>
                                                <img src={item.icon} alt="-" />
                                            </Icon>
                                            <Title>
                                                {ReactHtmlParser(item.title)}
                                            </Title>
                                            <Desc styleTwo={styleTwo}>
                                                {ReactHtmlParser(item.para)}
                                            </Desc>
                                        </MonitorBox>
                                    </Col>
                                );
                            })}
                    </Row>
                </ScrollAnimation>
            </ContainerWideSRM>
        </SectionWrap>
    );
};

const SectionWrap = styled.section`
    background-color: ${colors.socialRep['brandsBG']};
    ${Media('xlscreens')} {
        padding-top: 7vw;
    }
`;

const MonitorBox = styled(FlexColumnCentered)`
    border: 1px solid ${colors.white['20']};
    background-color: ${colors.socialRep['brandsBG']};
    margin: 40px -1px 0px;
    padding: 50px 30px;
    justify-content: flex-start;
    ${Media('xlscreens')} {
        padding: 0px 2vw;
        padding-top: 2.8vw;
        padding-bottom: 0px;
        height: ${(props) =>
            props.height ? `${(props.height / 19.2).toFixed(2)}vw` : '15.7vw'};
        margin-top: 3vw;
    }
    ${Media('tablet')} {
        min-height: 285px;
    }
    ${Media('mobile')} {
        min-height: 1px;
    }
`;

const Icon = styled.div`
    margin-bottom: 30px;
    min-height: 43px;
    display: flex;
    align-items: center;
    img {
        width: auto;
        max-height: 36px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 1.8vw;
        min-height: 2.13vw;
        img {
            max-width: 2.2vw;
            max-height: 2.18vw;
        }
    }
`;

const Title = styled.div`
    color: ${colors.white['100']};
    font-size: 18px;
    font-family: ${gilroyBold};
    margin-bottom: 22px;
    text-align: center;
    ${Media('xlscreens')} {
        font-size: 1.05vw;
        margin-bottom: 1.4vw;
    }
`;

const Desc = styled.div`
    color: ${colors.white['100']};
    font-size: 18px;
    text-align: center;
    line-height: ${(props) => (props.styleTwo ? 1.94 : 1.75)};
    font-family: ${(props) => (props.styleTwo ? gilroyMedium : gilroyRegular)};
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.styleTwo ? '0.93vw' : '1.05vw')};
    }
    ${Media('tablet')} {
        font-size:14px;
        br {
            display: none;
        }
    }
`;
