import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { gilroySemibold, gilroyMedium } from 'shared-components/theme/fonts';
import { Text, IndustryTag, IndustrySectionHeading } from './index';
import ReactHtmlParser from 'react-html-parser';

export const AmplifySection = ({ amplifyList, tag, heading, para }) => {
    return (
        <Wrapper id="amplify-section">
            <Container>
                <div>
                    <ContentCenter>
                        <IndustryTag>{tag}</IndustryTag>
                        <IndustrySectionHeading white>
                            {ReactHtmlParser(heading)}
                        </IndustrySectionHeading>
                        <Text white>{ReactHtmlParser(para)}</Text>
                    </ContentCenter>
                </div>
                <Row>
                    {amplifyList &&
                        amplifyList.length > 0 &&
                        amplifyList.map((item, index) => {
                            return (
                                <Col lg="3" md="6" key={index}>
                                    <div>
                                        <AmplifyBox>
                                            <AmplifyIcon>
                                                <img src={item.icon} alt="-" />
                                            </AmplifyIcon>
                                            <AmplifyTitle>
                                                {item.title}
                                            </AmplifyTitle>
                                            <AmplifyPara>
                                                {ReactHtmlParser(item.para)}
                                            </AmplifyPara>
                                        </AmplifyBox>
                                    </div>
                                </Col>
                            );
                        })}
                </Row>
            </Container>
        </Wrapper>
    );
};
const Wrapper = styled.section`
    background-color: ${colors.gray['darker']};
    ${Media('tablet')} {
        padding: 50px 0;
    }
`;
const AmplifyBox = styled.div`
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    ${Media('xlscreens')} {
        margin: 1.56vw 0;
    }
    ${Media('tablet')} {
        margin: 0;
        margin-top: 50px;
    }
`;
const AmplifyIcon = styled.div`
    img {
        height: 26px;
    }
    ${Media('xlscreens')} {
        img {
            height: 1.66vw;
        }
    }
`;
const AmplifyTitle = styled.h3`
    color: ${colors.white['100']};
    font-size: 22px;
    font-family: ${gilroySemibold};
    margin: 30px 0;
    ${Media('xlscreens')} {
        font-size: 1.25vw;
        margin: 3.125vw 0;
    }
`;
const AmplifyPara = styled.p`
    margin-top: 0;
    color: ${colors.white['100']};
    font-size: 14px;
    font-family: ${gilroyMedium};
    ${Media('xlscreens')} {
        font-size: 0.83vw;
        max-width: 80%;
    }
    ${Media('tablet')} {
        padding: 0px 20px;
        br {
            display: none;
        }
    }
`;
const ContentCenter = styled.div`
    margin-bottom: 50px;
    text-align: center;
    ${Media('xlscreens')} {
        margin-bottom: 3.64vw;
    }
`;
