import React from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import {
    gilroySemibold,
} from '../theme/fonts';
import Media from '../theme/medias';

const WhyOutSourceHeading = ({
    headingName,
    headingCol,
    subheadingCol,
    subheadingNumber,
    subheadingText,
    activateSubHeading,
}) => {
    return (
        <Wrapper>
            <Container>
                <Row>
                    <Col lg={headingCol}>
                        <h2>{headingName}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col lg={subheadingCol}>
                        <SubHeading activateSubHeading={activateSubHeading}>
                            <span>{subheadingNumber}</span>
                            <span className="hed">{subheadingText}</span>
                        </SubHeading>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    );
};

const SubHeading = styled.div`
    margin: 50px 0;
    border-top: 1px solid rgba(29, 29, 31, 0.5);
    border-bottom: 1px solid rgba(29, 29, 31, 0.5);
    padding: 5px 0;
    display: ${(props) => (props.activateSubHeading ? 'flex' : 'none')};
    justify-content: space-between;
    span {
        font-family: ${gilroySemibold};
        font-size: 16px;
        ${Media('xlscreens')} {
            font-size: 0.94vw;
        }
    }
    ${Media('tablet')} {
        margin: 30px 0px;
    }
`;

const Wrapper = styled.div`
    h2 {
        line-height: 1;
    }
`;

export default WhyOutSourceHeading;
