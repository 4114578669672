import React from 'react';
import Layout from 'components/layout';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import {
    gilroySemibold,
    gilroyBold,
    gilroyMedium,
} from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';
import Button from 'shared-components/button/button';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';
import MarketoForm from 'shared-components/marketo-form/marketo-form';
import { sourceSansPro } from 'shared-components/theme/fonts';

const retargetFeat = [
    {
        icon: `${baseURL}assets/images/icon-material-people.svg`,
        title: 'CX Experts',
        count: '34,000<span>+</span>',
    },
    {
        icon: `${baseURL}assets/images/icon-ionic-md-business.svg`,
        title: 'Clients',
        count: '100<span>+</span>',
    },
    {
        icon: `${baseURL}assets/images/icon-awesome-globe.svg`,
        title: 'Centers of Excellence',
        count: '31',
    },
    {
        icon: `${baseURL}assets/images/icon-ionic-ios-stats.svg`,
        title: 'Home-Grown CX Technologies',
        count: '10',
    },
];

const seamlessFeatures = [
    {
        icon: `${baseURL}assets/images/seamless-features-1.svg`,
        title: 'Telecommunications <br/>Network',
        rank: '#1',
    },
    {
        icon: `${baseURL}assets/images/seamless-features-2.svg`,
        title: 'Consumer <br/>Electronics',
        rank: '#1',
    },
    {
        icon: `${baseURL}assets/images/seamless-features-3.svg`,
        title: 'Financial <br/>Services',
        rank: '#1',
    },
    {
        icon: `${baseURL}assets/images/seamless-features-4.svg`,
        title: 'Global <br/>Company',
        rank: '#1',
    },
    {
        icon: `${baseURL}assets/images/seamless-features-5.svg`,
        title: 'Retail & <br/>E-Commerce',
        rank: '#1',
    },
    {
        icon: `${baseURL}assets/images/seamless-features-6.svg`,
        title: 'Shipping & <br/>Logistics',
        rank: '#1',
    },
    {
        icon: `${baseURL}assets/images/seamless-features-7.svg`,
        title: 'Streaming Media <br/>& Content',
        rank: '#1',
    },
    {
        icon: `${baseURL}assets/images/seamless-features-8.svg`,
        title: 'Ride Sharing <br/>Service',
        rank: '#2',
    },
    {
        icon: `${baseURL}assets/images/seamless-features-9.svg`,
        title: 'Fitness <br/>Device',
        rank: '#2',
    },
    {
        icon: `${baseURL}assets/images/seamless-features-10.svg`,
        title: 'Extended <br/>Warranty',
        rank: '#1',
    },
    {
        icon: `${baseURL}assets/images/seamless-features-11.svg`,
        title: 'Leading Set <br/>Top Box',
        rank: '#1',
    },
    {
        icon: `${baseURL}assets/images/seamless-features-12.svg`,
        title: 'Internet Related <br/>Services & Products',
        rank: '#1',
    },
];
const growthList1 = [
    'Voice Support',
    'Technical Support',
    'Telesales',
    'Back Office',
    'Chat Support',
    'Email Support',
    'Content Moderation',
];
const growthList2 = [
    'Digitally Native CX Experts',
    'Leading Technology Partnerships',
    'End-to-End Customer Journey Analytics',
    'Wave X Technology Platform',
    'AI-Augumented Customer Lifecycle',
    'Digital Work-at-Home CX Solutions',
];
const LearnAboutIbex = () => {
    return (
        <Layout>
            <Breadcrumbs>
                <li>CX Outsourcing</li>
            </Breadcrumbs>
            <IntroSection>
                <Container fluid>
                    <Row>
                        <Col lg="7">
                            <IntroInfoWrapper
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <IntroHeading>
                                    Omnichannel <br />
                                    Customer Support, <br />
                                    At Speed And Scale.
                                </IntroHeading>
                                <IntroPara>
                                    Future-proof your CX program. Our teams are
                                    ready to protect <br />
                                    your brand, elevate your CX journey across
                                    every channel, <br />
                                    and turn cost centers into profit centers.
                                    Drop us a note to <br />
                                    see how ibex can help.
                                </IntroPara>
                                <IntroFeatWrapper>
                                    {retargetFeat &&
                                        retargetFeat.length > 0 &&
                                        retargetFeat.map((feat, index) => {
                                            return (
                                                <IntroFeatBox key={index}>
                                                    <img
                                                        src={feat.icon}
                                                        alt="-"
                                                    />
                                                    <h4>{feat.title}</h4>
                                                    <h2>
                                                        {ReactHtmlParser(
                                                            feat.count
                                                        )}
                                                    </h2>
                                                </IntroFeatBox>
                                            );
                                        })}
                                </IntroFeatWrapper>
                            </IntroInfoWrapper>
                        </Col>
                        <Col lg="5">
                            <div>
                                <FormWrapper>
                                    <Form>
                                        <h2>What Are Your CX Needs?</h2>
                                        <MarketoForm Id={'1213'} />
                                    </Form>
                                </FormWrapper>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <FloatingImage>
                    <img
                        src={`${baseURL}assets/images/redirect-intro.png`}
                        alt="-"
                    />
                </FloatingImage>
            </IntroSection>
            <SeamLessSection>
                <Container>
                    <div>
                        <SeamlessTag>SEAMLESS. SIMPLE. CX.</SeamlessTag>
                        <SeamlessHeading>
                            We help leading brands around the world <br />
                            deliver flawless customer experience programs.
                        </SeamlessHeading>
                    </div>
                    <SeamlessFeatures>
                        <Row>
                            {seamlessFeatures &&
                                seamlessFeatures.length > 0 &&
                                seamlessFeatures.map((f, i) => {
                                    return (
                                        <Col lg="3" md="6" key={i}>
                                            <div>
                                                <Feature>
                                                    <img src={f.icon} alt="-" />
                                                    <h4>{f.rank}</h4>
                                                    <h5>
                                                        {ReactHtmlParser(
                                                            f.title
                                                        )}
                                                    </h5>
                                                </Feature>
                                            </div>
                                        </Col>
                                    );
                                })}
                        </Row>
                    </SeamlessFeatures>
                </Container>
            </SeamLessSection>
            <CXGrowth>
                <Container>
                    <div>
                        <SeamlessTag>CX FOR GROWTH.</SeamlessTag>
                        <SeamlessHeading color={colors.white['100']} semiBold>
                            We offer unparalleled operational <br />
                            sophistication and execution.
                        </SeamlessHeading>
                        <CXGrowthFeatures>
                            <Row>
                                <Col lg="4">
                                    <GrowthFeature>
                                        <img
                                            src={`${baseURL}assets/images/growth-img-3.png`}
                                            alt="-"
                                        />
                                        <p>
                                            We train & motivate highly engaged
                                            customer support and retention
                                            champions.
                                        </p>
                                    </GrowthFeature>
                                </Col>
                                <Col lg="4">
                                    <GrowthFeature>
                                        <img
                                            src={`${baseURL}assets/images/growth-img-2.png`}
                                            alt="-"
                                        />
                                        <p>
                                            We can seamlessly <br />
                                            scale our programs to <br />
                                            match your growth & <br />
                                            capacity needs.
                                        </p>
                                    </GrowthFeature>
                                </Col>
                                <Col lg="4">
                                    <GrowthFeature>
                                        <img
                                            src={`${baseURL}assets/images/growth-img-1.png`}
                                            alt="-"
                                        />
                                        <p>
                                            We integrate a technology and
                                            BI&nbsp;
                                            <br />
                                            stack for continuous program
                                            performance.
                                        </p>
                                    </GrowthFeature>
                                </Col>
                            </Row>
                        </CXGrowthFeatures>
                        <CXGrowthList>
                            <Row>
                                <GrowthListItem lg="6">
                                    <GrowthListHeader>
                                        <img
                                            src={`${baseURL}assets/images/growth-list-icon1.svg`}
                                            alt="-"
                                        />
                                        <h4>
                                            We Offer Omnichannel Customer
                                            Experiences
                                        </h4>
                                    </GrowthListHeader>
                                    <List>
                                        {growthList1 &&
                                            growthList1.length > 0 &&
                                            growthList1.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <img
                                                            src={`${baseURL}assets/images/icon-awesome-check.svg`}
                                                            alt="-"
                                                        />
                                                        {item}
                                                    </li>
                                                );
                                            })}
                                    </List>
                                </GrowthListItem>
                                <GrowthListItem lg="6">
                                    <GrowthListHeader>
                                        <img
                                            src={`${baseURL}assets/images/growth-list-icon2.svg`}
                                            alt="-"
                                        />
                                        <h4>
                                            We are Digital <br />
                                            Transformation Experts
                                        </h4>
                                    </GrowthListHeader>
                                    <List>
                                        {growthList2 &&
                                            growthList2.length > 0 &&
                                            growthList2.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <img
                                                            src={`${baseURL}assets/images/icon-awesome-check.svg`}
                                                            alt="-"
                                                        />
                                                        {item}
                                                    </li>
                                                );
                                            })}
                                    </List>
                                </GrowthListItem>
                            </Row>
                        </CXGrowthList>
                    </div>
                </Container>
            </CXGrowth>
            <BottomSection>
                <h3>Get in touch today and let’s get started.</h3>
                <Button round href={`/contact-us`}>
                    Talk to us
                </Button>
            </BottomSection>
        </Layout>
    );
};
const IntroSection = styled.div`
    margin-top: 100px;
    ${Media('xlscreens')} {
        padding: 0 5vw 0 13vw;
        margin-top: 5.2vw;
    }
    ${Media('tablet')} {
        margin-top: 50px;
    }
`;
const IntroHeading = styled.h1`
    font-size: 75px;
    font-family: ${gilroySemibold};
    line-height: 1.1;
    margin-top: 0;
    ${Media('xlscreens')} {
        font-size: 3.9vw;
    }
    ${Media('tablet')} {
        font-size: 50px;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 32px;
        text-align: center;
        margin-top: 30px;
    }
`;
const IntroPara = styled.p`
    font-size: 22px;
    font-family: ${gilroyMedium};
    line-height: 1.54;
    ${Media('xlscreens')} {
        font-size: 1.14vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        text-align: center;
    }
`;
const IntroInfoWrapper = styled(ScrollAnimation)`
    ${Media('mobile')} {
        background-color: ${colors.white['100']};
        padding: 50px 10px;
    }
`;
const FloatingImage = styled.div`
    position: absolute;
    z-index: -1;
    ${Media('xlscreens')} {
        top: -18vw;
        right: -20vw;
        img {
            width: 74vw;
        }
    }
    ${Media('tablet')} {
        display: none;
    }
    ${Media('mobile')} {
        display: block;
        top: -125px;
        right: -250px;
        img {
            width: 800px;
        }
    }
`;
const IntroFeatWrapper = styled.div`
    display: flex;
    margin-top: 50px;
    ${Media('xlscreens')} {
        margin-top: 2.6vw;
    }
    ${Media('mobile')} {
        flex-wrap: wrap;
        justify-content: center;
    }
`;
const IntroFeatBox = styled.div`
    padding: 20px;
    background-color: ${colors.white['100']};
    border-radius: 10px;
    box-shadow: 0px 0px 20px ${colors.black['4']};
    margin-right: 10px;
    width: 190px;
    img {
        max-width: 25px;
    }
    h4 {
        font-size: 22px;
        color: ${colors.redirectPg['grey']};
        font-family: ${gilroyBold};
        height: 50px;
    }
    h2 {
        font-size: 35px;
        font-family: ${gilroyBold};
        margin-bottom: 0;
        span {
            color: ${colors.pink['100']};
        }
    }
    ${Media('xlscreens')} {
        padding: 1.5vw 1vw;
        border-radius: 0.53vw;
        margin-right: 0.53vw;
        width: 9.89vw;
        img {
            max-width: 1.3vw;
        }
        h4 {
            font-size: 0.92vw;
            height: 2.6vw;
        }
        h2 {
            font-size: 1.82vw;
        }
    }
    ${Media('tablet')} {
        img {
            width: 18px;
        }
        h4 {
            font-size: 16px;
            height: 30px;
        }
        h2 {
            font-size: 26px;
        }
    }
    ${Media('mobile')} {
        width: 46%;
        height: 190px;
        margin: 0 5px 10px;
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        img {
            margin-top: 15px;
        }
        h4 {
            margin: 0;
        }
        h2 {
            margin: 0;
        }
    }
`;
const FormWrapper = styled.div`
    box-shadow: 0px 0px 15px ${colors.black['3']};
    ${Media('tablet')} {
        margin-top: 50px;
    }
`;
const Form = styled.div`
    background-color: ${colors.white['100']};
    padding: 50px;
    h2 {
        font-size: 34px;
        font-family: ${gilroyBold};
        margin-bottom: 0;
    }
    .mktoForm {
        width: 100% !important;
        margin-top: 20px;
        .mktoFormRow {
            display: flex;
            justify-content: space-between;
            .mktoFormCol {
                width: 47%;
            }
            .mktoLabel {
                width: 100% !important;
                font-size: 18px;
                font-family: ${sourceSansPro};
                font-weight: 400;
                color: ${colors.black['100']};
                margin-bottom: 5px;
            }
            .mktoFieldWrap {
                width: 100% !important;
                .mktoField {
                    width: 100% !important;
                    border: 1px solid ${colors.gray['border']} !important;
                    border-radius: 5px;
                    min-height: 40px;
                }
            }
            &:nth-child(6) {
                .mktoFormCol {
                    width: 100% !important;
                    .mktoFieldWrap {
                        .mktoField {
                            min-height: 100px;
                            outline: none;
                        }
                    }
                }
            }
        }
        .mktoButtonRow {
            margin-top: 30px;
            display: flex;
            justify-content: center;
            .mktoButtonWrap {
                margin-left: 0 !important;
                .mktoButton {
                    box-shadow: none;
                    border: 1px solid ${colors.pink['100']};
                    padding: 15px 50px;
                    width: auto;
                    border-radius: 30px;
                    font-size: 20px;
                    font-family: ${gilroySemibold};
                    text-shadow: none;
                    background-image: none !important;
                    transition: all 0.3s ease;
                    &:hover {
                        background-color: ${colors.white['100']};
                        color: ${colors.pink['100']};
                    }
                }
            }
        }
    }
    ${Media('xlscreens')} {
        padding: 2.6vw;
        h2 {
            font-size: 1.77vw;
        }
        .mktoForm {
            .mktoFormRow {
                .mktoLabel {
                    font-size: 0.93vw;
                    margin-bottom: 0.26vw;
                }
                .mktoFieldWrap {
                    .mktoField {
                        border-radius: 0.26vw;
                        min-height: 2.08vw;
                    }
                }
                &:nth-child(6) {
                    .mktoFormCol {
                        .mktoFieldWrap {
                            .mktoField {
                                min-height: 5.2vw;
                            }
                        }
                    }
                }
            }
            .mktoButtonRow {
                margin-top: 1.56vw;
                .mktoButtonWrap {
                    .mktoButton {
                        padding: 0.78vw 2.6vw;
                        border-radius: 1.56vw;
                        font-size: 1.04vw;
                    }
                }
            }
        }
    }
    ${Media('tablet')} {
        padding: 20px;
    }
    ${Media('mobile')} {
        h2 {
            text-align: center;
            font-size: 28px;
        }
        .mktoForm {
            .mktoFormRow {
                flex-wrap: wrap;
                .mktoFormCol {
                    width: 100% !important;
                    margin-bottom: 20px !important;
                    .mktoField {
                        width: 100% !important;
                    }
                }
            }
        }
    }
`;
const SeamLessSection = styled.div`
    margin-top: 100px;
    ${Media('xlscreens')} {
        margin-top: 10vw;
    }
`;
const SeamlessTag = styled.h5`
    color: ${colors.pink['100']};
    font-size: 22px;
    font-family: ${gilroyBold};
    text-align: center;
    margin-bottom: 10px;
    letter-spacing: 3px;
    ${Media('xlscreens')} {
        font-size: 1.14vw;
        margin-bottom: 0.53vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
const SeamlessHeading = styled.h2`
    font-size: ${(props) => (props.semiBold ? '50px' : '60px')};
    font-family: ${(props) => (props.semiBold ? gilroySemibold : gilroyMedium)};
    text-align: center;
    margin-top: 0;
    line-height: 1.3;
    color: ${(props) => props.color && props.color};
    letter-spacing: -1.3px;
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.semiBold ? '2.6vw' : '3.125vw')};
    }
    ${Media('tablet')} {
        font-size: 30px;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 26px;
    }
`;
const SeamlessFeatures = styled.div`
    padding: 0px 70px;
    .row {
        justify-content: center;
        margin-top: 100px;
        margin-bottom: 80px;
    }
    ${Media('xlscreens')} {
        padding: 0 3.64vw;
        .row {
            margin-top: 5.2vw;
            margin-bottom: 4.16vw;
        }
    }
    ${Media('tablet')} {
        padding: 0 30px;
    }
    ${Media('mobile')} {
        .row {
            margin: 50px 0;
        }
    }
`;
const Feature = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
    img {
        max-width: 36px;
        max-height: 30px;
    }
    h4 {
        font-size: 35px;
        font-family: ${gilroyBold};
        color: ${colors.pink['100']};
        margin: 10px 0;
    }
    h5 {
        font-size: 22px;
        font-family: ${gilroyBold};
        margin: 0;
        text-align: center;
        line-height: 1.86;
    }
    ${Media('xlscreens')} {
        margin: 1.56vw 0;
        img {
            max-width: 1.875vw;
            max-height: 1.56vw;
        }
        h4 {
            font-size: 1.82vw;
            margin: 0.53vw 0;
        }
        h5 {
            font-size: 1.14vw;
        }
    }
    ${Media('tablet')} {
        img {
            max-width: 26px;
            max-height: 20px;
        }
        h4 {
            font-size: 26px;
        }
        h5 {
            font-size: 18px;
        }
    }
    ${Media('mobile')} {
        padding: 0 30px;
    }
`;
const CXGrowth = styled.div`
    background-color: ${colors.socialRep['brandsBG']};
    padding-top: 50px;
    ${Media('xlscreens')} {
        padding-top: 2.6vw;
    }
`;
const GrowthFeature = styled.div`
    display: flex;
    align-items: center;
    img {
        height: 145px;
    }
    p {
        color: ${colors.white['100']};
        font-size: 22px;
        font-family: ${gilroyMedium};
        line-height: 1.36;
        margin-left: 50px;
    }
    ${Media('xlscreens')} {
        img {
            height: 7.55vw;
        }
        p {
            font-size: 1.14vw;
            margin-left: 2.6vw;
        }
    }
    ${Media('tablet')} {
        margin-bottom: 20px;
        img {
            height: 120px;
        }
        p {
            font-size: 18px;
            br {
                display: none;
            }
        }
    }
    ${Media('mobile')} {
        flex-direction: column;
        img {
            height: auto;
        }
        p {
            margin-left: 0;
            text-align: center;
            font-size: 16px;
        }
    }
`;
const CXGrowthFeatures = styled.div`
    margin: 80px 0;
    ${Media('xlscreens')} {
        margin: 4.166vw 0;
    }
    ${Media('mobile')} {
        margin-bottom: 0;
    }
`;
const CXGrowthList = styled.div`
    position: relative;
    &:before {
        position: absolute;
        content: '';
        width: 1px;
        height: 440px;
        background-color: ${colors.redirectPg['border']};
        top: 60px;
        left: 50%;
    }
    ${Media('xlscreens')} {
        &:before {
            height: 22.9vw;
            top: 3.125vw;
        }
    }
    ${Media('tablet')} {
        &:before {
            display: none;
        }
    }
`;
const GrowthListHeader = styled.div`
    display: flex;
    padding: 0 60px;
    img {
        max-width: 70px;
    }
    h4 {
        color: ${colors.white['100']};
        font-size: 34px;
        font-family: ${gilroyMedium};
        margin-left: 40px;
    }
    ${Media('xlscreens')} {
        padding: 0;
        img {
            max-width: 3.64vw;
        }
        h4 {
            font-size: 1.77vw;
            margin-left: 2.08vw;
        }
    }
    ${Media('tablet')} {
        img {
            max-width: 40px;
        }
        h4 {
            font-size: 26px;
        }
    }
    ${Media('mobile')} {
        padding: 0;
        h4 {
            font-size: 22px;
            margin-left: 20px;
        }
    }
`;
const GrowthListItem = styled(Col)`
    margin-bottom: 50px;
    ${Media('xlscreens')} {
        margin-bottom: 2.6vw;
        padding-right: 6vw !important;
        &:last-of-type {
            padding-left: 6vw;
            padding-right: 0 !important;
        }
    }
`;
const List = styled.ul`
    padding-left: 0;
    padding: 0 60px;
    li {
        display: flex;
        color: ${colors.white['100']};
        font-family: ${gilroyMedium};
        font-size: 24px;
        padding: 10px;
        img {
            width: 21px;
            margin: 0px 15px;
        }
        &:nth-child(odd) {
            background-color: ${colors.white['6']};
        }
    }
    ${Media('xlscreens')} {
        padding: 0;
        li {
            font-size: 1.25vw;
            padding: 0.53vw;
            img {
                width: 1.09vw;
                margin: 0 0.78vw;
            }
        }
    }
    ${Media('tablet')} {
        li {
            font-size: 18px;
            img {
                width: 16px;
            }
        }
    }
    ${Media('mobile')} {
        padding: 0px;
        li {
            font-size: 14px;
            img {
                width: 14px;
            }
        }
    }
`;
const BottomSection = styled.section`
    background-color: ${colors.gray_dark['100']};
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
        margin-top: 0;
        color: ${colors.white['100']};
        margin-bottom: 50px;
        font-size: 40px;
        font-family: ${gilroySemibold};
    }
    ${Media('xlscreens')} {
        h3 {
            margin-bottom: 2.6vw;
            font-size: 2.08vw;
        }
    }
    ${Media('tablet')} {
        h3 {
            font-size: 28px;
        }
    }
    ${Media('mobile')} {
        h3 {
            font-size: 24px;
            text-align: center;
            padding: 0px 20px;
        }
    }
`;
export default LearnAboutIbex;
