import React from 'react';
import styled from 'styled-components';
import { gilroyBold, gilroyMedium } from 'shared-components/theme/fonts';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';

const HomeBrand = ({ rank, icon, title, vdelay, page }) => {
    return (
        <Wrapper>
            <div>
                <Rank page={page}>{rank}</Rank>
                <Icon page={page}>{icon}</Icon>
                <Title page={page}>{title}</Title>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
    flex: 0 0 16.6%;
    padding: 0 10px;
    .animated {
        width: 100%;
    }
    ${Media('xlscreens')} {
        margin-top: 4.5vw;
        margin-bottom: 2.8vw;
    }
    ${Media('tablet')} {
        flex: 0 0 23%;
    }
    ${Media('mobile')} {
        flex: 0 0 48%;
    }
`;

const Rank = styled.div`
    font-size: 21px;
    font-family: ${gilroyBold};
    color: ${colors.pink['100']};
    ${(props) => {
        if (props.page === 'velocity') {
            return ` background: linear-gradient(to right, ${colors.velocity['gradient']});
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent; 
                    color: transparent;  
            `;
        }
    }}
    ${Media('xlscreens')} {
        font-size: 1.6vw;
    }
`;

const Icon = styled.div`
    font-size: 20px;
    margin: 15px 0px;
    color: ${(props) =>
        props.page === 'velocity'
            ? colors.white['100']
            : colors.gray_dark['100']};
    ${Media('xlscreens')} {
        margin: 1.3vw 0px;
        font-size: 1.5vw;
    }
`;

const Title = styled.div`
    font-size: 10px;
    font-family: ${gilroyMedium};
    color: ${(props) =>
        props.page === 'velocity'
            ? colors.white['100']
            : colors.gray_dark['100']};
    ${Media('xlscreens')} {
        font-size: 0.8vw;
    }
    ${Media('mobile')} {
        font-size: 12px;
    }
`;

export default HomeBrand;
