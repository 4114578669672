import React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import Layout from 'components/layout';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import Loader from 'components/loader/loader';
import HomeBlog from 'components/home-blog/home-blog';
import colors from 'shared-components/theme/colors';
import {
    DigitalIntro,
    DigitalBigText,
    DigitalProtected,
    DigitalManage,
    DigitalVideo,
    DigitalBenefits,
    DigitalBrands,
    DigitalValues,
    DigitalDemandBlock,
} from 'components/digital';

const sections = [
    {
        showBg: false,
        useClearBoth: false,
        component: <DigitalProtected />,
        bigTextSection: false,
        isScrollNav: false,
    },
    {
        showBg: false,
        useClearBoth: false,
        component: <HomeBlog page="digital" />,
        bigTextSection: false,
        isScrollNav: false,
    },
    {
        showBg: true,
        useClearBoth: true,
        component: <DigitalManage />,
        bigTextSection: (
            <DigitalBigText bigTextOne="case" bigTextTwo="studies" />
        ),
        isScrollNav: false,
    },
    {
        showBg: false,
        useClearBoth: false,
        component: <DigitalVideo />,
        bigTextSection: false,
        isScrollNav: false,
    },
    {
        showBg: false,
        useClearBoth: true,
        component: <DigitalBenefits />,
        bigTextSection: false,
        isScrollNav: false,
    },
    {
        showBg: false,
        useClearBoth: true,
        component: <DigitalBrands />,
        bigTextSection: false,
        isScrollNav: false,
    },
    {
        showBg: false,
        useClearBoth: false,
        component: <DigitalValues />,
        bigTextSection: false,
        isScrollNav: false,
    },
    {
        showBg: false,
        useClearBoth: false,
        component: <DigitalDemandBlock />,
        bigTextSection: false,
        isScrollNav: true,
    },
];
const SectionWrapper = ({ children, condition, wrapper }) =>
    condition ? wrapper(children) : children;
const Digital = () => {
    const scrollNavData = [
        {
            name: 'home',
            target: 'home',
            offset: -100,
        },
        {
            name: 'protect',
            target: 'protect',
            offset: -200,
        },
        {
            name: 'manage',
            target: 'manage',
            offset: -200,
        },
        {
            name: 'casestudies',
            target: 'casestudies',
            offset: 0,
        },
        {
            name: 'videos',
            target: 'videos',
            offset: -100,
        },
        {
            name: 'benefits',
            target: 'benefits',
            offset: -100,
        },
        {
            name: 'brands',
            target: 'brands',
            offset: -100,
        },
    ];
    return (
        <Layout page="digital">
            <Breadcrumbs>
                <li>Solutions</li>
                <li>Digital</li>
            </Breadcrumbs>
            <DigitalIntro />
            {sections &&
                sections.map((section) => {
                    return (
                        <>
                            <SectionWrapper
                                condition={section.showBg}
                                wrapper={(children) => (
                                    <div
                                        style={{
                                            backgroundColor: colors.digitalBg,
                                        }}>
                                        {children}
                                    </div>
                                )}>
                                {section.isScrollNav && (
                                    <ScrollNav data={scrollNavData} />
                                )}
                                {section.component && section.component}
                                {section.bigTextSection && (
                                    <BigTextSection>
                                        {section.bigTextSection}
                                    </BigTextSection>
                                )}
                            </SectionWrapper>
                            {section.useClearBoth && <ClearBoth />}
                        </>
                    );
                })}
        </Layout>
    );
};
const BigTextSection = styled.section`
    position: relative;
    padding-top: 0px;
`;
const ClearBoth = styled.div`
    clear: both;
`;
export default Digital;
