import React from 'react';
import { FaChevronRight } from 'react-icons/fa';
import styled from 'styled-components';
import { GlossaryMainPara } from '.';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';

export const GlossaryTerm = ({ term, link }) => {
    return (
        <a href={`/cx-glossary/${link}`}>
            <Wrapper>
                <GlossaryMainPara noMargin>{term}</GlossaryMainPara>
                <FaChevronRight />
            </Wrapper>
        </a>
    );
};

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    cursor: pointer;
    p {
        transition: all 330ms ease;
    }
    &:hover {
        p {
            color: ${colors.pink['100']};
        }
    }
    svg {
        color: ${colors.black['30']};
        font-size: 24px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 1vw;
        svg {
            font-size: 1.35vw;
            padding-right: 0.85vw;
        }
    }
    ${Media('tablet')} {
        svg {
            font-size: 18px;
        }
    }
    ${Media('mobile')} {
        margin-bottom: 10px;
        svg {
            font-size: 14px;
        }
    }
`;
