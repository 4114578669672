import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import SlickSlider from 'shared-components/slider/slider';
import Media from 'shared-components/theme/medias';
import { Row, Col } from '@bootstrap-styled/v4';
import { gilroyMedium, gilroyExtrabold } from 'shared-components/theme/fonts';
import { MdClose } from 'react-icons/md';
import { FaCalculator, FaStar, FaUser, FaUserFriends } from 'react-icons/fa';
import { baseURL } from 'shared-components/theme/helpers';
const settingsBlog = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerPadding: '0px',
    focusOnSelect: false,
    autoplay: false,
    autoplaySpeed: 3000,
};

const LeaderShipPopup = ({
    closePopup,
    state,
    leadershipData,
    activeSlideId,
}) => {
    const [data, setData] = useState([]);

    const getRefs = (r) => {
        const ids = [];
        if (leadershipData && leadershipData.length > 0) {
            leadershipData.forEach((person) => {
                ids.push(person.ID);
            });
        }
        const slideIndex = ids.indexOf(activeSlideId);
        if (r && r.current) {
            const dontAnimate = true;
            r.current.slickGoTo(slideIndex, dontAnimate);
        }
    };

    const handleBoardIcon = (val) => {
        switch (val) {
            case 'star':
                return <FaStar />;
            case 'person':
                return <FaUser />;
            case 'persons':
                return <FaUserFriends />;
            case 'report':
                return <FaCalculator />;
            default:
                return false;
        }
    };

    useEffect(() => {
        setData(leadershipData);
    }, [leadershipData]);

    return (
        <Wrapper open={state}>
            <CloseSliderIcon onClick={() => closePopup()}>
                <MdClose />
            </CloseSliderIcon>
            <SliderWrapper>
                <BlogSlider>
                    <SlickSlider
                        settings={settingsBlog}
                        refs={(r) => getRefs(r)}>
                        {data &&
                            data.length > 0 &&
                            data.map((person, index) => (
                                <Item key={index}>
                                    <Row>
                                        <Col
                                            lg="6"
                                            md={{ order: 1, size: 6 }}
                                            xs={{ order: 2, size: 12 }}>
                                            <TextSection>
                                                <MainHeading>
                                                    {person.post_title}
                                                </MainHeading>
                                                <Designation>
                                                    {
                                                        person.metadata
                                                            .designation
                                                    }
                                                </Designation>
                                                <About>
                                                    {person.post_content}
                                                </About>
                                                {person.metadata.board_points &&
                                                person.metadata.board_points
                                                    .length > 0 ? (
                                                    <BoardPoints>
                                                        {person.metadata.board_points.map(
                                                            (item, index) => {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }>
                                                                        &nbsp;
                                                                        {handleBoardIcon(
                                                                            item.type
                                                                        )}
                                                                        {
                                                                            item.desc
                                                                        }
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </BoardPoints>
                                                ) : null}
                                            </TextSection>
                                        </Col>
                                        <Col
                                            lg="6"
                                            md={{ order: 2, size: 6 }}
                                            xs={{ order: 1, size: 12 }}>
                                            <ImageSection>
                                                <img
                                                    src={person.featured_image}
                                                    alt=""
                                                />
                                            </ImageSection>
                                        </Col>
                                    </Row>
                                </Item>
                            ))}
                    </SlickSlider>
                </BlogSlider>
            </SliderWrapper>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    background: ${colors.gray_light['100']};
    z-index: 9999;
    display: flex;
    justify-content: center;
    transition: all 0.3s ease;
    overflow: auto;
    ${(props) => {
        if (props.open) {
            return `opacity: 1;
                    visibility: visible;
                    transform: scale(1)`;
        } else {
            return `opacity: 0;
                    visibility: hidden;
                    transform: scale(0.8)`;
        }
    }};
    ${Media('mobile')} {
        background: ${colors.white['100']};
    }
`;
const CloseSliderIcon = styled.div`
    position: absolute;
    right: 30px;
    top: 30px;
    font-size: 40px;
    height: 30px;
    width: 30px;
    color: ${colors.gray_dark['50']};
    z-index: 99;
    cursor: pointer;
    ${Media('mobile')} {
        color: ${colors.white['100']};
    }
`;
const SliderWrapper = styled.div`
    width: 80%;
    display: flex;
    align-items: center;
    ${Media('mobileportrait')} {
        width: 100%;
        background-color: ${colors.white['100']};
        display: block;
    }
`;
const BlogSlider = styled.div`
    width: 100%;
    .slick-slider {
        position: unset;
    }
    .slick-arrow {
        position: absolute;
        top: calc(50% - 30px);
        z-index: 99;
        border-radius: 0px;
        border: 2px solid ${colors.gray['20']};
        &:hover {
            border: 2px solid ${colors.black['100']};
            background-color: transparent;
        }
        &:focus {
            background-color: transparent;
        }
        ${Media('large')} {
            top: calc(50% - 30px);
        }
        ${Media('mobileportrait')} {
            top: calc(30%);
            border-color: ${colors.white['100']};
            height: 40px;
            width: 50px;
            &:before {
                background-image: url('${baseURL}assets/images/arrow-right-white.svg');
                background-size: 30px;
            }
            &:hover {
                border: 2px solid ${colors.white['100']};
                background-color: transparent;
            }
            &:focus {
                background-color: transparent;
            }
        }
    }
    .slick-next {
        right: 10px;
    }
    .slick-prev {
        left: 10px;
    }
    ${Media('mobile')} {
        .slick-arrow {
            border-color: ${colors.white['50']};
            color: ${colors.white['50']};
            &:hover {
                border-color: ${colors.white['100']};
                color: ${colors.white['100']};
            }
        }
    }
`;
const Item = styled.div`
    width: 100%;
    height: 100%;
    background: ${colors.white['100']};
`;
const TextSection = styled.div`
    padding: 25px;
    ${Media('xlscreens')} {
        padding: 1.31vw;
    }
`;
const MainHeading = styled.div`
    font-size: 60px;
    font-family: ${gilroyExtrabold};
    line-height: 1;
    margin-bottom: 10px;
    ${Media('xlscreens')} {
        font-size: 3.15vw;
    }
    ${Media('tabletlandscape')} {
        font-size: 6vw;
    }
    ${Media('tablet')} {
        font-size: 24px;
    }
`;

const Designation = styled.div`
    font-size: 18px;
    color: ${colors.pink['100']};
    font-family: ${gilroyMedium};
    padding: 10px 0px;
    margin-bottom: 10px;
    ${Media('xlscreens')} {
        font-size: 0.94vw;
    }
    ${Media('tabletlandscape')} {
        font-size: 16px;
    }
`;
const About = styled.div`
    font-size: 18px;
    font-family: ${gilroyMedium};
    overflow: auto;
    ${Media('xlscreens')} {
        font-size: 0.94vw;
    }
    ${Media('tabletlandscape')} {
        font-size: 14px;
    }
    ${Media('tablet')} {
        font-size: 16px;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
const ImageSection = styled.div`
    height: 100%;
    width: 100%;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

const BoardPoints = styled.ul`
    list-style-type: none;
    padding: 0px;
    margin: 20px 0;
    li {
        font-size: 18px;
        font-family: ${gilroyMedium};
        line-height: 1.3;
        display: flex;
        align-items: center;
        svg {
            font-size: calc(100% / 1.25);
            margin-right: 8px;
            margin-top: -3px;
            flex: 0 0 20px;
        }
        ${Media('xlscreens')} {
            font-size: 0.94vw;
            svg {
                flex: 0 0 1.05vw;
            }
        }
        ${Media('tabletlandscape')} {
            font-size: 14px;
        }
        ${Media('tablet')} {
            font-size: 16px;
        }
        ${Media('mobile')} {
            font-size: 14px;
        }
    }
`;

export default LeaderShipPopup;
