import React from 'react';
import styled from 'styled-components';
import Layout from 'components/layout';
import { Row, Col } from '@bootstrap-styled/v4';
import colors from 'shared-components/theme/colors';
import { gilroyMedium, gilroySemibold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { MarketoFormDesign } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';
import MarketoForm from 'shared-components/marketo-form/marketo-form';
import { baseURLPage } from 'shared-components/theme/helpers';

const ContactUs = () => {
    return (
        <Layout>
            <Row noGutters={true}>
                <FormCol lg="8">
                    <Col lg="8">
                        <FormWrapper>
                            <FormTitle>Contact Us.</FormTitle>
                            <p>
                                For employment inquiries, please visit our&nbsp;
                                <a href={`${baseURLPage}join-us`}>
                                    Careers Page.
                                </a>
                            </p>
                            <FormSubTitle>
                                Give us your details. We’ll get in touch.
                            </FormSubTitle>
                            <Row noGutters={true}>
                                <Col lg="12">
                                    <ContactUsMarketoForm>
                                        <MarketoFormDesign>
                                            <MarketoForm Id="1028" />
                                        </MarketoFormDesign>
                                    </ContactUsMarketoForm>
                                </Col>
                            </Row>
                        </FormWrapper>
                    </Col>
                </FormCol>
                <Col lg="4">
                    <OtherLinksWrapper>
                        <h3>Other links you might like:</h3>
                        <Row>
                            <Col lg="12" md="6">
                                <LinkCard>
                                    <img
                                        src={`${baseURL}assets/images/resource-link-card.jpg`}
                                        alt="-"
                                    />
                                    <h4>Resources</h4>
                                    <p>
                                        The latest ideas for driving innovation
                                        & growth in the CX industry.
                                    </p>
                                    <Linkbottom>
                                        <a
                                            href={`${baseURLPage}resources/blogs`}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            Take me to it!
                                        </a>
                                        <FaLongArrowAltRight
                                            color={colors.pink['100']}
                                        />
                                    </Linkbottom>
                                </LinkCard>
                            </Col>
                            <Col lg="12" md="6">
                                <LinkCard>
                                    <img
                                        src={`${baseURL}assets/images/careers-link-card.jpg`}
                                        alt="-"
                                    />
                                    <h4>Career Opportunities</h4>
                                    <p>
                                        Looking to join the fastest growing CX
                                        outsourcer in the industry?
                                    </p>
                                    <Linkbottom>
                                        <a href={`${baseURLPage}join-us`}>
                                            Yes, I want in!
                                        </a>
                                        <FaLongArrowAltRight
                                            color={colors.pink['100']}
                                        />
                                    </Linkbottom>
                                </LinkCard>
                            </Col>
                        </Row>
                    </OtherLinksWrapper>
                </Col>
            </Row>
        </Layout>
    );
};
const FormWrapper = styled.div`
    padding: 50px 0;
    p {
        font-size: 18px;
        font-family: ${gilroyMedium};
        a {
            color: ${colors.pink['100']};
        }
    }
    ${Media('xlscreens')} {
        padding: 2.66vw 0;
        p {
            font-size: 0.93vw;
        }
    }
    ${Media('tablet')} {
        padding: 10px;
    }
    ${Media('mobile')} {
        padding: 20px 0 50px;
    }
`;
const FormCol = styled(Col)`
    display: flex;
    justify-content: center;
`;
const FormTitle = styled.h1`
    font-size: 45px;
    font-family: ${gilroySemibold};
    ${Media('xlscreens')} {
        font-size: 2.3vw;
    }
    ${Media('tablet')} {
        font-size: 36px;
    }
`;
const FormSubTitle = styled.h2`
    font-size: 28px;
    font-family: ${gilroySemibold};
    ${Media('xlscreens')} {
        font-size: 1.45vw;
    }
    ${Media('tablet')} {
        font-size: 22px;
    }
`;
const OtherLinksWrapper = styled.div`
    padding: 120px 80px;
    background-color: ${colors.gray_lighter['lighter']};
    height: 100%;
    h3 {
        font-size: 22px;
        font-family: ${gilroySemibold};
    }
    ${Media('xlscreens')} {
        padding: 6.25vw 4.166vw;
        h3 {
            font-size: 1.14vw;
        }
    }
    ${Media('tablet')} {
        padding: 30px;
    }
    ${Media('mobile')} {
        padding: 10px;
    }
`;
const LinkCard = styled.div`
    background-color: ${colors.white['100']};
    border-radius: 10px;
    height: 310px;
    width: 320px;
    margin-top: 50px;
    padding: 30px 40px;
    img {
        height: 70px;
        width: 70px;
        object-fit: cover;
    }
    h4 {
        font-size: 18px;
        font-family: ${gilroySemibold};
        margin: 10px 0;
    }
    p {
        margin-top: 0;
        font-size: 18px;
        font-family: ${gilroyMedium};
        line-height: 1.6;
    }
    ${(props) => {
        if (props.styleTwo) {
            return `
                    height:368px;
                    img{
                        height:118px;
                        width:100%;
                        border-radius:8px;
                        margin-bottom:25px;
                    }
                `;
        }
    }}
    ${Media('xlscreens')} {
        border-radius: 0.53vw;
        height: 16.14vw;
        width: 16.66vw;
        margin-top: 2.66vw;
        padding: 1.56vw 2.08vw;
        img {
            height: 3.64vw;
            width: 3.64vw;
        }
        h4 {
            font-size: 0.93vw;
            margin: 0.53vw 0;
        }
        p {
            font-size: 0.93vw;
        }
        ${(props) => {
            if (props.styleTwo) {
                return `
                        height:19.16vw;
                        img{
                            height:6.14vw;
                            border-radius:0.41vw;
                            margin-bottom:1.30vw;
                            width:100%;
                        }
                    `;
            }
        }}
    }
    ${Media('tablet')} {
        width: 100%;
        margin-top: 15px;
    }
`;
const Linkbottom = styled.div`
    display: flex;
    align-items: center;
    a {
        font-size: 18px;
        font-family: ${gilroySemibold};
        text-decoration: underline;
    }
    svg {
        margin-left: 20px;
        font-size: 14px;
    }
    ${Media('xlscreens')} {
        a {
            font-size: 0.93vw;
        }
        svg {
            margin-left: 1.04vw;
            font-size: 0.72vw;
        }
    }
`;

const ContactUsMarketoForm = styled.div`
    .mktoForm {
        .mktoFormRow {
            .mktoFormCol {
                width: 100% !important;
            }
            .mktoFieldWrap {
                width: 100% !important;
                .mktoField {
                    width: calc(100% - 20px) !important;
                    border: 1px solid ${colors.gray['border']} !important;
                }
            }
            &:nth-child(5),
            &:nth-child(6) {
                .mktoFormCol {
                    width: 100% !important;
                }
            }
            ${Media('mobile')} {
                .mktoFormCol {
                    width: 100% !important;
                    .mktoField {
                        width: 100% !important;
                    }
                }
            }
        }
    }
`;
export default ContactUs;
