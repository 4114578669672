import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { gilroyLight, gilroySemibold } from 'shared-components/theme/fonts';

const CaseInfoSection = ({ img, heading, paragraph, list, paddingBottom }) => {
    return (
        <Wrapper paddingBottom={paddingBottom}>
            <Container>
                <Row>
                    <Col lg="6">
                        <LeftSection>
                            <Heading>{heading}</Heading>
                            <ImageSection>
                                <img src={img} alt="-" />
                            </ImageSection>
                        </LeftSection>
                    </Col>
                    <Col lg={{ size: 5, offset: 1 }}>
                        <RightSection>
                            <ParaContainer>{paragraph}</ParaContainer>
                            {list && (
                                <ListWrapper>
                                    <ul>
                                        {list &&
                                            list.map((item, ind) => (
                                                <li key={ind}>{item}</li>
                                            ))}
                                    </ul>
                                </ListWrapper>
                            )}
                        </RightSection>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    );
};
const Wrapper = styled.section`
    padding-top: 0;
    display: flex;
    padding-bottom: ${(props) => (props.paddingBottom ? '100px' : '0px')};
    ${Media('xlscreens')} {
        padding-bottom: ${(props) => (props.paddingBottom ? '5vw' : '0px')};
    }
`;
const LeftSection = styled.div``;
const RightSection = styled.div``;
const Heading = styled.div`
    font-size: 90px;
    font-family: ${gilroySemibold};
    padding-bottom: 100px;
    line-height: 1.1;
    letter-spacing: -4px;
    ${Media('xlscreens')} {
        font-size: 4vw;
        padding-bottom: 4.23vw;
    }
    ${Media('tablet')} {
        font-size: 40px;
        letter-spacing: 0px;
        padding-bottom: 0px;
        padding: 50px 0px;
        br {
            display: none;
        }
    }
`;
const ImageSection = styled.div`
    img {
        width: 100%;
        height: auto;
    }
`;
const ParaContainer = styled.div`
    font-size: 20px;
    font-family: ${gilroyLight};
    padding-top: 42px;
    line-height: 1.4;
    ${Media('xlscreens')} {
        font-size: 1.04vw;
        padding-top: 2.1vw;
    }
`;
const ListWrapper = styled.div`
    ul {
        padding: 0;
        margin-top: 90px;
        ${Media('xlscreens')} {
            margin-top: 4.71vw;
        }
        ${Media('tablet')} {
            margin-top: 30px;
        }
        li {
            color: ${colors.gray_dark['100']};
            font-family: ${gilroySemibold};
            font-size: 20px;
            position: relative;
            padding: 5px;
            padding-left: 60px;
            width: 70%;
            ${Media('xlscreens')} {
                font-size: 1vw;
                padding-left: 3.14vw;
            }
            ${Media('tablet')} {
                width: 100%;
            }
            &:after {
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: ${colors.pink['100']};
                position: absolute;
                left: 20px;
                top: 15px;
            }

            &:nth-child(odd) {
                background-color: ${colors.gray_light['100']};
            }
        }
    }
`;
export default CaseInfoSection;
