import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { PageHeading, PagePara, UpperRegBox } from '../common';
import Media from 'shared-components/theme/medias';
import { gilroySemibold } from 'shared-components/theme/fonts';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';

export const RegularSection = () => {
    return (
        <RegulatoryWrapper id="regular">
            <Controller>
                <Scene triggerHook="onEnter" duration="100%" offset="150%">
                    <Timeline>
                        <Tween
                            from={{
                                backgroundPosition: 'center 100px',
                            }}
                            to={{
                                backgroundPosition: 'center -100px',
                            }}>
                            <RegularUpper>
                                <Container>
                                    <Row>
                                        <Col
                                            lg={{
                                                size: 10,
                                                offset: 1,
                                            }}>
                                            <div>
                                                <UpperRegBox>
                                                    <span>GAU</span>
                                                    <PageHeading>
                                                        Persistent Regulatory
                                                        Monitoring
                                                    </PageHeading>
                                                    <PagePara>
                                                        At ibex, our expert GAU
                                                        team is comprised of the
                                                        heads of Compliance,
                                                        Legal, Healthcare,
                                                        Financial Services,
                                                        Information Security,
                                                        and Technology
                                                        divisions. These leaders
                                                        are responsible for
                                                        actively monitoring
                                                        regulatory updates and
                                                        trends, understanding
                                                        the impacts of these
                                                        updates to our clients,
                                                        and translating these
                                                        updates into operational
                                                        norms.
                                                    </PagePara>
                                                </UpperRegBox>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </RegularUpper>
                        </Tween>
                    </Timeline>
                </Scene>
            </Controller>
            <RegularLower>
                <Container>
                    <Row>
                        <Col lg="12">
                            <div>
                                <LowerRegBox>
                                    <li>
                                        Monitor National & Global <br />
                                        Regulatory Changes
                                    </li>
                                    <li>
                                        Communicate Regulatory <br /> Changes
                                    </li>
                                    <li>
                                        Ensure Continuous Compliance <br />
                                        Across all Relevant Frameworks
                                    </li>
                                </LowerRegBox>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </RegularLower>
        </RegulatoryWrapper>
    );
};

const RegulatoryWrapper = styled.section`
    padding-top: 50px;
`;

const RegularUpper = styled.div`
    background: url('${baseURL}assets/images/gau-regulatory-back.jpg') no-repeat
        center;
    background-size: cover;
    margin-bottom: 90px;
    ${Media('xlscreens')} {
        margin-bottom: 9vw;
    }
`;

const RegularLower = styled.div`
    padding-top: 60px;
    ${Media('xlscreens')} {
        padding-top: 5.5vw;
    }
`;

const LowerRegBox = styled.ul`
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    counter-reset: section;
    li {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-family: ${gilroySemibold};
        font-size: 24px;
        letter-spacing: -1px;
        line-height: 1.5;
        border-right: 1px solid ${colors.gray_dark['100']};
        position: relative;
        z-index: 0;
        counter-increment: section;
        &:before {
            content: '0' counter(section);
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -55%);
            font-family: ${gilroySemibold};
            font-size: 124px;
            color: ${colors.gray_lighter['100']};
            text-shadow: 0px 0px 0px ${colors.gray_dark['100']};
            opacity: 0.4;
        }
        &:nth-child(2) {
            justify-content: center;
        }
        &:last-child {
            justify-content: flex-end;
            border-right: none;
        }
    }
    ${Media('xlscreens')} {
        li {
            font-size: 1.57vw;
            height: 9vw;
            &:before {
                font-size: 14.6vw;
                letter-spacing: -0.1vw;
            }
        }
    }
    ${Media('tablet')} {
        li {
            font-size: 14px;
        }
    }
    ${Media('tablet')} {
        flex-direction: column;
        li {
            flex: 0 0 auto;
            max-width: 100%;
            width: 100%;
            font-size: 18px;
            height: 180px;
            border-right: none;
            border-bottom: 1px solid ${colors.gray_dark['100']};
            &:before {
                text-shadow: 0px 0px 1px ${colors.gray_dark['100']};
                opacity: 0.5;
            }
            &:last-child {
                justify-content: center;
                border-bottom: none;
            }
        }
    }
`;
