import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import { FCXPageHeading } from '../common';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import {
    gilroySemibold,
    gilroyMedium,
    gilroyBlack,
} from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';
import { Parallax } from 'react-scroll-parallax';
import ReactHtmlParser from 'react-html-parser';

const winnerList = [
    {
        image: `${baseURL}assets/images/ebook/fintech-award-winner-icon-1.svg`,
        title: `VIP networking dinner at Money20/20 with FinTech leaders`,
    },
    {
        image: `${baseURL}assets/images/ebook/fintech-award-winner-icon-2.svg`,
        title: `Profile on ibex web and social channels`,
    },
    {
        image: `${baseURL}assets/images/ebook/fintech-award-winner-icon-3.svg`,
        title: `Inclusion in ibex <br /> press release at <br /> Money20/20`,
    },
    {
        image: `${baseURL}assets/images/ebook/fintech-award-winner-icon-4.svg`,
        title: `Press release template for company to share `,
    },
];

export const FCXWinnerSection = () => {
    return (
        <>
            <WinnerSection>
                <Container>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <FCXPageHeading
                                    color={colors.white['100']}
                                    alignCenter>
                                    <span>What do winners win?</span>
                                </FCXPageHeading>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                    <Row noGutters>
                        {winnerList &&
                            winnerList.length > 0 &&
                            winnerList.map((item, index) => {
                                return (
                                    <Col sm="12" lg="3" key={index}>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={false}
                                            delay={`${index * 2}00`}>
                                            <WinnerBox
                                                last={
                                                    index ===
                                                    winnerList.length - 1
                                                }>
                                                <WinnerImg>
                                                    <img
                                                        src={item.image}
                                                        alt="-"
                                                    />
                                                </WinnerImg>
                                                <h3>
                                                    {ReactHtmlParser(
                                                        item.title
                                                    )}
                                                </h3>
                                            </WinnerBox>
                                        </ScrollAnimation>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </WinnerSection>

            <WinnerDateSection>
                <Container>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <FCXPageHeading alignCenter>
                                    <span>When</span> do winners win?
                                </FCXPageHeading>
                                <WinnerDateHeading>
                                    WE WILL BEGIN ANNOUNCING WINNERS AT THE
                                    <b> END OF SEPTEMBER, 2021!</b>
                                    <Parallax x={['-10%', '10%']}>
                                        <Headliner />
                                    </Parallax>
                                </WinnerDateHeading>
                                <WinnerDateLogo>
                                    <img
                                        src={`${baseURL}assets/images/ebook/fintech-award-footer-logo.svg`}
                                        alt="-"
                                    />
                                </WinnerDateLogo>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </WinnerDateSection>
        </>
    );
};

const WinnerSection = styled.section`
    position: relative;
    background-color: ${colors.fintech['winnerBgColor']};
    ${Media('xlscreens')} {
        .container {
            padding-left: 9vw !important;
            padding-right: 9vw !important;
        }
    }
    ${Media('tablet')} {
        h2 {
            margin-bottom: 50px;
        }
    }
`;

const WinnerBox = styled.div`
    margin-top: 50px;
    padding: 50px;
    border-right: ${(props) =>
        props.last ? 'none' : `3px solid ${colors.fintech['text']}4D`};
    position: relative;
    z-index: 10;
    min-height: 285px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
        font-size: 24px;
        font-family: ${gilroySemibold};
        color: ${colors.white['100']};
        text-align: center;
        margin-top: 40px;
    }
    ${Media('xlscreens')} {
        margin-top: 5vw;
        padding: 3vw;
        min-height: 18vw;
        h3 {
            font-size: 1.4vw;
            margin-top: 1.8vw;
        }
    }
    ${Media('tablet')} {
        border-right: none;
        margin-top: 0px;
        padding-top: 20px;
        min-height: 1px;
    }
`;

const WinnerImg = styled(FlexCentered)`
    max-width: 89px;
    max-height: 81px;
    min-height: 81px;
    img {
        max-height: 81px;
    }
    ${Media('xlscreens')} {
        max-height: 4.2vw;
        min-height: 4.2vw;
        img {
            max-height: 4.2vw;
        }
    }
`;

const WinnerDateSection = styled.section`
    background-color: ${colors.fintech['bgColor']};
    ${Media('xlscreens')} {
        padding-top: 8vw;
        padding-bottom: 6vw;
    }
`;

const WinnerDateHeading = styled.h3`
    font-size: 34px;
    font-family: ${gilroyMedium};
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 30px 0 50px;
    color: ${colors.fintech['text']};
    position: relative;
    b {
        font-family: ${gilroyBlack};
    }
    ${Media('xlscreens')} {
        font-size: 1.875vw;
        margin: 2.2vw 0 3.2vw;
    }
`;

const Headliner = styled.div`
    position: absolute;
    width: 38%;
    height: 20px;
    top: -10px;
    right: 0%;
    background-color: ${colors.pink['10']};
    ${Media('xlscreens')} {
        height: 1.2vw;
        top: -0.52vw;
    }
    ${Media('mobile')} {
        width: 85%;
    }
`;

const WinnerDateLogo = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 20px;
    img {
        max-width: 100%;
    }
    ${Media('xlscreens')} {
        padding-top: 3vw;
        img {
            max-width: 24.21vw;
        }
    }
`;
