import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import {
    gilroyMedium,
    gilroyBold,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import { baseURL } from 'shared-components/theme/helpers';
import MainPara from 'shared-components/main-para/main-para';
import {
    FlexColumnCentered,
    RowCenteredBoth,
} from 'shared-components/theme/grid-helpers';
import ReactHtmlParser from 'react-html-parser';
import LayersCards from 'components/social-rep-man/layer-card';
import {
    SRMFloatingSectionImage,
    SRMSectionHeading,
} from 'components/social-rep-man/common';

export const PedigreeStrategySection = ({ satisfyData, pedigreeData }) => {
    return (
        <>
            <PedigreeSection>
                <Container>
                    <RowCenteredBoth>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <SRMSectionHeading
                                    color={colors.socialRep['brandsBG']}>
                                    A Pedigree of Performance
                                </SRMSectionHeading>
                            </ScrollAnimation>
                        </Col>
                    </RowCenteredBoth>
                    <RowCenteredBoth>
                        <Col sm="7">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <MainPara
                                    align="center"
                                    color={colors.socialRep['brandsBG']}
                                    noMargin>
                                    With over 20 years in the industry and
                                    peerless results, RefleCX is here to turn
                                    your survey program into a key component of
                                    your CX strategy.
                                </MainPara>
                            </ScrollAnimation>
                        </Col>
                    </RowCenteredBoth>
                    <Row noGutters>
                        {pedigreeData.map((item, index) => {
                            return index === 0 ? (
                                <Col md="12" lg="6" key={index}>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={true}>
                                        <PedigreeBox first>
                                            <PedigreeTitle>
                                                {item.title}
                                                <img
                                                    src={`${baseURL}assets/images/tech/pedigree-arrow-icon.svg`}
                                                    alt=""
                                                />
                                            </PedigreeTitle>
                                            <Row noGutters>
                                                {item.content.map(
                                                    (stats, statIndex) => {
                                                        return (
                                                            <Col
                                                                sm="12"
                                                                lg="6"
                                                                key={statIndex}>
                                                                <PedigreeInner>
                                                                    {stats.count && (
                                                                        <h3>
                                                                            {
                                                                                stats.count
                                                                            }
                                                                        </h3>
                                                                    )}
                                                                    {stats.stats_desc && (
                                                                        <MainPara
                                                                            noMargin>
                                                                            {ReactHtmlParser(
                                                                                stats.stats_desc
                                                                            )}
                                                                        </MainPara>
                                                                    )}
                                                                    {stats.detail && (
                                                                        <h5>
                                                                            {ReactHtmlParser(
                                                                                stats.detail
                                                                            )}
                                                                        </h5>
                                                                    )}
                                                                </PedigreeInner>
                                                            </Col>
                                                        );
                                                    }
                                                )}
                                            </Row>
                                        </PedigreeBox>
                                    </ScrollAnimation>
                                </Col>
                            ) : (
                                <Col md="12" lg="3" key={index}>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={true}>
                                        <PedigreeBox
                                            last={
                                                index ===
                                                pedigreeData.length - 1
                                            }>
                                            <PedigreeTitle
                                                last={
                                                    index ===
                                                    pedigreeData.length - 1
                                                }>
                                                {item.title}
                                                <img
                                                    src={`${baseURL}assets/images/tech/pedigree-arrow-icon.svg`}
                                                    alt=""
                                                />
                                            </PedigreeTitle>
                                            <Row noGutters>
                                                {item.content.map(
                                                    (stats, statIndex) => {
                                                        return (
                                                            <Col
                                                                sm="12"
                                                                key={statIndex}>
                                                                <PedigreeInner
                                                                    shortBox
                                                                    first={
                                                                        statIndex ===
                                                                        0
                                                                    }>
                                                                    {stats.count && (
                                                                        <h3>
                                                                            {
                                                                                stats.count
                                                                            }
                                                                        </h3>
                                                                    )}
                                                                    {stats.stats_desc && (
                                                                        <MainPara
                                                                            noMargin>
                                                                            {ReactHtmlParser(
                                                                                stats.stats_desc
                                                                            )}
                                                                        </MainPara>
                                                                    )}
                                                                    {stats.detail && (
                                                                        <h5>
                                                                            {ReactHtmlParser(
                                                                                stats.detail
                                                                            )}
                                                                        </h5>
                                                                    )}
                                                                </PedigreeInner>
                                                            </Col>
                                                        );
                                                    }
                                                )}
                                            </Row>
                                        </PedigreeBox>
                                    </ScrollAnimation>
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            </PedigreeSection>

            <StrategySection id="strategy">
                <SRMFloatingSectionImage
                    x={[-20, 0]}
                    image={`${baseURL}assets/images/tech/reflecx-strategy-float-bg.png`}
                    width={765}
                    left="-10%"
                    bottom="0"
                />
                <Container>
                    <RowCenteredBoth>
                        <Col sm="12" lg="10">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <SRMSectionHeading color={colors.white['100']}>
                                    We Are Your CX Strategist and Advocate
                                </SRMSectionHeading>
                            </ScrollAnimation>
                        </Col>
                    </RowCenteredBoth>
                    <Row>
                        {satisfyData &&
                            satisfyData.length > 0 &&
                            satisfyData.map((item, index) => {
                                return (
                                    <Col sm="12" lg="3" key={index}>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={true}>
                                            <StrategyCards>
                                                <LayersCards
                                                    layerCardimg={item.image}
                                                    HeadingLayerCard={
                                                        item.heading
                                                    }
                                                    ParagraphLayerCard={
                                                        item.para
                                                    }
                                                    imgRound
                                                    imgBorder
                                                />
                                            </StrategyCards>
                                        </ScrollAnimation>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </StrategySection>
        </>
    );
};
const PedigreeSection = styled.section`
    padding-top: 200px;
    background-color: ${colors.reflecx['buildBg']};
    ${Media('xlscreens')} {
        padding-top: 16vw;
    }
    ${Media('tablet')} {
        padding-top: 180px;
    }
`;

const PedigreeBox = styled.div`
    border-left: ${(props) =>
        props.first && `1px solid ${colors.socialRep['brandsBG']}`};
    border-top-left-radius: ${(props) => props.first && '10px'};
    border-top-right-radius: ${(props) => props.last && '10px'};
    overflow: hidden;
    margin-top: 50px;
    ${Media('xlscreens')} {
        margin-top: 3vw;
    }
    ${Media('tablet')} {
        border-radius: 5px 5px 0px 0px;
        border-left: 1px solid ${colors.socialRep['brandsBG']};
    }
`;

const PedigreeTitle = styled.div`
    background-color: ${colors.socialRep['brandsBG']};
    color: ${colors.reflecx['orange']};
    padding: 15px 25px;
    font-size: 24px;
    font-family: ${gilroyMedium};
    border-right: ${(props) =>
        props.last ? 'none' : `1px solid ${colors.white['100']}`};
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${Media('xlscreens')} {
        font-size: 1.25vw;
        padding: 1vw 2.5vw 1vw 3.125vw;
        img {
            width: 1.4vw;
            height: 1.4vw;
        }
    }
`;

const PedigreeInner = styled(FlexColumnCentered)`
    padding: 30px;
    border-bottom: 1px solid ${colors.socialRep['brandsBG']};
    border-right: 1px solid ${colors.socialRep['brandsBG']};
    text-align: center;
    min-height: 188px;
    position: relative;
    ${(props) => {
        if (props.first) {
            return `
                &:before, &:after {
                    content: '';
                    position: absolute;
                    width: 20%;
                    height: 2px;
                    bottom: -1px;
                    background-color: ${colors.reflecx['buildBg']};
                }
                &:before {
                    left: 0px;
                }
                &:after {
                    right: 0px;
                }
            `;
        }
    }};
    h3 {
        font-size: 44px;
        font-family: ${gilroyBold};
        color: ${colors.reflecx['orange']};
        margin: 0px 0 20px;
    }
    h5 {
        font-size: 18px;
        font-family: ${gilroySemibold};
        margin: 0px;
        line-height: 1.6;
    }
    p {
        font-family: ${gilroyMedium};
        color: ${colors.socialRep['brandsBG']};
        line-height: 1.25;
    }
    ${Media('xlscreens')} {
        min-height: 9.8vw;
        padding: 1.5vw;
        h3 {
            font-size: 2.6vw;
            margin-bottom: 0.8vw;
        }
        h5 {
            font-size: 1.05vw;
        }
        p {
            min-height: 2.5vw;
        }
    }
    ${Media('tablet')} {
        &:before,
        &:after {
            display: none;
        }
    }
`;

const StrategySection = styled.section`
    position: relative;
    z-index: 10;
    background-color: ${colors.socialRep['brandsBG']};
    ${Media('xlscreens')} {
        .container {
            padding: 0 8vw !important;
        }
    }
`;

const StrategyCards = styled.div`
    margin-top: 30px;
    & > div {
        margin-left: 20px;
        margin-right: 20px;
        height: 690px;
        padding-top: 30px;
        padding-left: 1.5vw;
        padding-right: 1.5vw;
        text-align: center;
        div:nth-of-type(1) {
            div {
                background-color: ${colors.reflecx['orange']};
            }
        }
        div:nth-of-type(2) {
            margin-bottom: 0px;
        }
        div:nth-of-type(3) {
            margin-top: 20px;
            margin-bottom: 20px;
            min-height: 100px;
            justify-content: center;
            align-items: center;
            min-height: 60px;
        }
        h3 {
            font-family: ${gilroySemibold};
            line-height: 1;
            margin: 0px;
        }
        p {
            font-family: ${gilroyMedium};
            margin-top: 0;
            line-height: 1.8;
            letter-spacing: -0.5px;
        }
    }
    ${Media('xlscreens')} {
        margin-top: 3vw;
        & > div {
            height: 35.9vw;
            padding-top: 2.5vw;
            margin-left: 1vw;
            margin-right: 1vw;
            padding-left: 1vw;
            padding-right: 1vw;
            div:nth-of-type(3) {
                margin-top: 1.5vw;
                margin-bottom: 0.95vw;
                height: 4.5vw;
                min-height: 1px;
            }
            p {
                letter-spacing: -0.01vw;
            }
        }
    }
    ${Media('tablet')} {
        & > div {
            height: auto;
            max-width: 380px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            padding-bottom: 30px;
            div:nth-of-type(3) {
                margin-top: 30px;
                min-height: 1px;
                justify-content: center;
            }
        }
    }
`;
