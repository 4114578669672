import React from 'react';
import { Container, Col, Row } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import SolutionBox from 'components/solution-box/solution-box';
import {
    Text,
    IndustryTag,
    IndustrySectionHeading,
    TransformSection,
    ContentCenter,
    IndustryPerformanceBox,
} from 'components/industries/common';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { RowContentCenter } from 'shared-components/theme/grid-helpers';
import { baseURL } from 'shared-components/theme/helpers';

export const TransformSolutionSec = ({
    financialSolutions,
    performanceList,
}) => {
    return (
        <>
            <TransformSection
                tag="Two-click purchase - One-shot CX."
                sectionHeading="Powering On-Demand, Hyper-Expectation CX <br />For Delivery & Logistics Leaders"
                img={`${baseURL}assets/images/industries/logistics-leader.jpg`}
                para1="Digital consumers have rocketed the Delivery & Logistics industry into a new era of complexity and expectation. Fast, frictionless, on-demand experiences are the new baseline for engaging and retaining customers, regardless of whether its the first mile or the last."
                para2="At ibex, we build customer experience programs that maximize omni-channel CX options, minimize customer effort, and accelerate the speed and quality of your customer service. We work closely with the world’s leading delivery and logistics brands to solve breakpoints across the end-to-end CX value chain, providing expertise and value at every touchpoint, ensuring optimal outcomes at your most difficult moments."
            />
            <CXSolution id="cx-solution">
                <Container>
                    <div>
                        <ContentCenter>
                            <IndustryTag>Moment of Truth Experts</IndustryTag>
                            <IndustrySectionHeading>
                                Deliver Flawless CX At Every Touchpoint.
                            </IndustrySectionHeading>
                            <Text>
                                Our Delivery & Logistics solutions are designed
                                to optimize value and mitigate risk across a
                                complex, <br />
                                always-on marketplace.
                            </Text>
                        </ContentCenter>
                    </div>
                    <SolutionBoxWrapper>
                        {financialSolutions &&
                            financialSolutions.length > 0 &&
                            financialSolutions.map((solution, index) => {
                                return (
                                    <Col lg="3" md="6" key={index}>
                                        <SolutionBox
                                            title={solution.title}
                                            imageUrl={solution.imageUrl}
                                            para={solution.para}
                                        />
                                    </Col>
                                );
                            })}
                    </SolutionBoxWrapper>
                </Container>
            </CXSolution>
            <CXPerformance id="cx-performance">
                <Container>
                    <div>
                        <ContentCenter>
                            <IndustryTag>Chaos Reduction</IndustryTag>
                            <IndustrySectionHeading>
                                Enhancing & Supporting the <br />
                                Delivery & Logistics Ecosystem
                            </IndustrySectionHeading>
                            <Text>
                                More than just customers, the success of your
                                brand depends on efficient and optimized support
                                for your <br />
                                entire value chain, including Merchants, Driver,
                                and Application support.
                            </Text>
                        </ContentCenter>
                    </div>
                </Container>
                <Container fluid={true}>
                    <PerformanceFluidRow>
                        {performanceList &&
                            performanceList.length > 0 &&
                            performanceList.map((listItem, index) => {
                                return (
                                    <Col lg="3" md="6" key={index}>
                                        <div>
                                            <IndustryPerformanceBox
                                                image={listItem.imgUrl}
                                                title={listItem.title}
                                                list={listItem.list}
                                                noMarginBottom
                                            />
                                        </div>
                                    </Col>
                                );
                            })}
                    </PerformanceFluidRow>
                </Container>
            </CXPerformance>
        </>
    );
};
const Section = styled.section`
    ${Media('tablet')} {
        padding: 50px 0;
    }
`;
const CXSolution = styled(Section)`
    background-color: ${colors.gray_lighter['100']};
`;
const SolutionBoxWrapper = styled(Row)`
    min-height: 700px;
    ${Media('xlscreens')} {
        min-height: 36.4vw;
    }
`;
const CXPerformance = styled(Section)`
    background-color: ${colors.white['100']};
`;
const PerformanceFluidRow = styled(RowContentCenter)`
    ${Media('xlscreens')} {
        margin: 0px 3vw !important;
    }
`;
