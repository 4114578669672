import React from 'react';
import styled from 'styled-components';
import { gilroySemibold, gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import colors from 'shared-components/theme/colors';
import ReactHtmlParser from 'react-html-parser';

const CaseGrowth = ({ left, right }) => {
    return (
        <ScrollAnimation animateIn="fadeInUp">
            <Wrapper>
                <Left>{left}</Left>
                <Right>{ReactHtmlParser(right)}</Right>
            </Wrapper>
        </ScrollAnimation>
    );
};
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    ${Media('tablet')} {
        margin: 8px 0px;
    }
    ${Media('tablet')} {
        border: 1px solid ${colors.black['100']};
        padding: 10px 0px;
    }
`;
const Left = styled.div`
    font-size: 70px;
    font-family: ${gilroySemibold};
    ${Media('xlscreens')} {
        font-size: 3.4vw;
    }
    ${Media('tablet')} {
        font-size: 30px;
        margin-left: 20px;
    }
`;
const Right = styled.div`
    font-size: 30px;
    font-family: ${gilroySemibold};
    margin-left: 40px;
    ${Media('xlscreens')} {
        font-size: 1.4vw;
        margin-left: 2.09vw;
    }
    ${Media('tablet')} {
        margin-left: 20px;
        font-size: 25px;
        font-family: ${gilroyMedium};
        br {
            display: none;
        }
    }
`;
export default CaseGrowth;
