import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import { Row, Col } from '@bootstrap-styled/v4';
import colors from 'shared-components/theme/colors';
import { gilroySemibold, gilroyBold } from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';
import MainPara from 'shared-components/main-para/main-para';

const VerticalCards = ({
    VerticalCardHeading,
    VerticalCardParagraph,
    VerticalCardImage,
    ImageRight,
    CardTagImg,
    padTopSmall,
    ImageRightColEqual,
    sideExtraSmall,
    mediumPara,
    list,
}) => {
    return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce={false} delay={100}>
            <VerticalCardsContainer padTopSmall={padTopSmall}>
                {ImageRight ? (
                    <Row>
                        <Col
                            lg={{
                                size: ImageRightColEqual ? 6 : 5,
                                offset: 0,
                            }}>
                            <TextBgBox
                                paddingLeft={ImageRightColEqual}
                                sideExtraSmall={sideExtraSmall}>
                                {CardTagImg && (
                                    <CardTag ImageRight={ImageRight}>
                                        <img src={CardTagImg} alt="" />
                                    </CardTag>
                                )}
                                <CardHeading ImageRight={ImageRight}>
                                    {ReactHtmlParser(VerticalCardHeading)}
                                </CardHeading>
                                <MainPara
                                    color={colors.white['100']}
                                    styleTwo={mediumPara}
                                    align="left">
                                    {ReactHtmlParser(VerticalCardParagraph)}
                                </MainPara>
                                {list &&
                                    list.length > 0 &&
                                    list.map((li, ind) => {
                                        return (
                                            <ListItem key={ind}>
                                                <img src={li.icon} alt="-" />
                                                <p>
                                                    {ReactHtmlParser(li.para)}
                                                </p>
                                            </ListItem>
                                        );
                                    })}
                            </TextBgBox>
                        </Col>
                        <Col
                            lg={{
                                size: ImageRightColEqual ? 6 : 7,
                                offset: 0,
                                order: 'last',
                            }}
                            xs={{ order: 'first' }}>
                            <img src={VerticalCardImage} alt="" />
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col lg={{ size: 6, offset: 0 }}>
                            <img src={VerticalCardImage} alt="" />
                        </Col>
                        <Col lg={{ size: 6, offset: 0 }}>
                            <TextBgBox
                                styleTwo={true}
                                sideExtraSmall={sideExtraSmall}>
                                {CardTagImg && (
                                    <CardTag ImageRight={ImageRight}>
                                        <img src={CardTagImg} alt="" />
                                    </CardTag>
                                )}
                                <CardHeading ImageRight={ImageRight}>
                                    {ReactHtmlParser(VerticalCardHeading)}
                                </CardHeading>
                                <MainPara align="left" styleTwo={mediumPara}>
                                    {ReactHtmlParser(VerticalCardParagraph)}
                                </MainPara>
                            </TextBgBox>
                        </Col>
                    </Row>
                )}
            </VerticalCardsContainer>
        </ScrollAnimation>
    );
};
const CardTag = styled.div`
    position: absolute;
    top: -130px;
    width: 280px;
    height: 280px;
    ${Media('tablet')} {
        display: none;
    }
    img {
        width: 100%;
        height: 100%;
    }
    ${Media('xlscreens')} {
        width: 14.5vw;
        height: 14.5vw;
        top: -6.77vw;
    }
    ${(props) => {
        if (props.ImageRight) {
            return `  
            left:0; 
            `;
        } else {
            return `  
            right:0; 
            `;
        }
    }}
    img {
        width: auto;
    }
`;
const TextBgBox = styled.div`
    padding: 200px 45px 130px 130px;
    position: relative;
    h3,
    p {
        max-width: 78%;
    }
    ${(props) => {
        if (props.styleTwo) {
            return `
            background-color: ${colors.white['100']};
            &:before {
            content: '';
            width: ${(props) => (props.sideExtraSmall ? '140px' : '290px')};
            height: 100%;
            position: absolute;
            left: -${(props) => (props.sideExtraSmall ? '140px' : '290px')};
            top: 0;
            z-index:-1;
            background-color: ${colors.white['100']};
                ${Media('tablet')} {
                    display: none;
                }
        }
            `;
        } else {
            return `
            background-color: #030323;
            a{
                color:${colors.pink['100']};
            }
            &:after {
            content: '';
            width: ${(props) => (props.sideExtraSmall ? '140px' : '290px')};
            height: 100%;
            position: absolute;
            right: -${(props) => (props.sideExtraSmall ? '140px' : '290px')};
            top: 0;
            background-color: #030323;
                ${Media('tablet')} {
                    display: none;
                }
            }
        `;
        }
    }}
    ${Media('xlscreens')} {
        padding: 10.61vw 2.3vw 6.77vw 6.77vw;
        padding-left: ${(props) => props.paddingLeft && '10.2vw'};
        &:before,
        &:after {
            width: ${(props) => (props.sideExtraSmall ? '8vw' : '15vw')};
            ${(props) =>
                props.styleTwo
                    ? `left: -${props.sideExtraSmall ? '8vw' : '15vw'};`
                    : `right: -${props.sideExtraSmall ? '8vw' : '15vw'};`};
        }
    }
    ${Media('tablet')} {
        background-color: transparent;
        padding: 10px;
        h3,
        p {
            max-width: 100%;
        }
        p {
            color: ${colors.gray_dark['100']};
            text-align: center;
        }
    }
`;
const CardHeading = styled.h3`
    font-size: 40px;
    font-family: ${gilroySemibold};
    font-weight: normal;
    color: ${(props) =>
        props.ImageRight ? colors.white['100'] : colors.black['100']};
    padding-bottom: 40px;
    ${Media('xlscreens')} {
        font-size: 2vw;
        padding-bottom: 2.08vw;
    }
    ${Media('tablet')} {
        color: ${colors.black['100']};
        font-size: 30px;
        text-align: center;
        margin-top: 40px;
        padding-bottom: 15px;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 20px;
    }
`;
const VerticalCardsContainer = styled.div`
    padding-top: ${(props) => (props.padTopSmall ? '80px' : '180px')};
    position: relative;
    img {
        width: 100%;
    }
    .row {
        align-items: center;
    }
    ${Media('xlscreens')} {
        padding-top: ${(props) => (props.padTopSmall ? '4vw' : '9.3vw')};
        img {
            position: relative;
            top: 1.04vw;
        }
    }

    ${Media('tablet')} {
        padding: 30px 0;
    }
`;

const ListItem = styled.div`
    display: flex;
    padding: 15px 0;
    p {
        font-size: 18px;
        font-family: ${gilroyBold};
    }
    img {
        height: 45px;
        width: auto;
        margin-right: 40px;
    }
    ${Media('xlscreens')} {
        padding: 0.78vw 0;
        p {
            font-size: 0.93vw;
            color: ${colors.white['100']};
        }
        img {
            height: 2.3vw;
            margin-right: 2.08vw;
        }
    }
    ${Media('tablet')} {
        align-items: center;
        p {
            text-align: left;
            br {
                display: none;
            }
        }
    }
    ${Media('mobile')} {
        p {
            font-size: 14px;
        }
        img {
            height: 35px;
            margin-right: 15px;
        }
    }
`;

export default VerticalCards;
