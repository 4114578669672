import { Col, Container, Row } from '@bootstrap-styled/v4';
import { useParams } from 'react-router-dom';
import Layout from 'components/layout';
import React, { useState, useRef, useEffect } from 'react';
import Button from 'shared-components/button/button';
import colors from 'shared-components/theme/colors';
import {
    akiraSuperBold,
    axiformaMedium,
    axiformaSemiBold,
    gilroyMedium,
    gilroyRegular,
    morisonRegular,
    morisonSemiBold,
} from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import { FaRegClock } from 'react-icons/fa';
import {
    FlexColumnCentered,
    RowCentered,
} from 'shared-components/theme/grid-helpers';
import MarketoForm from 'shared-components/marketo-form/marketo-form';
import { MarketoFormDesign } from 'shared-components/theme/grid-helpers';
import Slider from 'react-slick';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import Loader from 'components/loader/loader';
import NotFoundPage from 'pages/not-found';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-scroll';

const EventTemplate = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [pageData, setPageData] = useState({});
    const [pageError, setPageError] = useState(false);
    const [loading, setLoading] = useState(true);
    const router = useParams();
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        adaptiveHeight: false,
        fade: true,
        afterChange: (index) => {
            setActiveIndex(index);
        },
    };

    const getPageData = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/glossary?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        post_name: router.slug,
                        post_type: 'invitation',
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
                setPageError(true);
                setLoading(false);
            }
            const resData = await res.json();
            if (resData.status === 200 && resData.data.length !== 0) {
                setLoading(false);
                setPageData(resData.data[0]);
            } else {
                setPageError(true);
                setLoading(false);
            }
        } catch {
            setPageError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        getPageData();
    }, []);
    const data = pageData && pageData.metadata;
    const slider =
        (data &&
            data.intro &&
            data.intro.intro_slider &&
            data.intro.intro_slider.length > 0 &&
            data.intro.intro_slider) ||
        [];
    return (
        <>
            {loading && <Loader position="absolute" />}
            {pageError ? (
                <NotFoundPage />
            ) : (
                Object.keys(pageData).length !== 0 &&
                pageData !== undefined && (
                    <Layout
                        bgColor={colors.event.template['dark']}
                        darkTheme
                        page="events">
                        <Intro>
                            <IntroContent>
                                <Header>
                                    <Link
                                        to={'rsvp-form'}
                                        activeClass="active"
                                        spy={true}
                                        smooth={true}
                                        duration={1000}
                                        offset={-120}>
                                        <Button round>RSVP Today</Button>
                                    </Link>
                                </Header>
                                <Container>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={true}
                                        delay={200}>
                                        <PageHeading>
                                            <Parallax x={['50%', '0%']}>
                                                {data &&
                                                    data.intro &&
                                                    data.intro.intro_heading &&
                                                    data.intro.intro_heading
                                                        .length > 0 &&
                                                    ReactHtmlParser(
                                                        data.intro
                                                            .intro_heading[0]
                                                            .line_1
                                                    )}
                                            </Parallax>
                                            <Parallax x={['-10%', '10%']}>
                                                {data &&
                                                    data.intro &&
                                                    data.intro.intro_heading &&
                                                    data.intro.intro_heading
                                                        .length > 0 &&
                                                    ReactHtmlParser(
                                                        data.intro
                                                            .intro_heading[0]
                                                            .line_2
                                                    )}
                                            </Parallax>
                                            <Parallax x={['59%', '0%']}>
                                                {data &&
                                                    data.intro &&
                                                    data.intro.intro_heading &&
                                                    data.intro.intro_heading
                                                        .length > 0 &&
                                                    ReactHtmlParser(
                                                        data.intro
                                                            .intro_heading[0]
                                                            .line_3
                                                    )}
                                            </Parallax>
                                        </PageHeading>
                                        {data &&
                                            data.intro &&
                                            data.intro.sponsored && (
                                                <Sponsored>
                                                    <Parallax
                                                        x={['20%', '40%']}>
                                                        SPONSORED BY:{' '}
                                                        <img
                                                            src={
                                                                data.intro
                                                                    .sponsored
                                                            }
                                                            alt="-"
                                                        />
                                                    </Parallax>
                                                </Sponsored>
                                            )}
                                    </ScrollAnimation>
                                    <Location>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={true}
                                            delay={200}>
                                            {data &&
                                                data.intro &&
                                                data.intro.intro_city}
                                        </ScrollAnimation>
                                    </Location>
                                </Container>
                            </IntroContent>
                            <GradientCircle>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={true}
                                    delay={200}>
                                    <p>
                                        <FaRegClock />
                                        {data &&
                                            data.intro &&
                                            ReactHtmlParser(
                                                data.intro.intro_date_time
                                            )}
                                    </p>
                                </ScrollAnimation>
                            </GradientCircle>
                            <IntroSlider>
                                <Slider {...settings} ref={sliderRef}>
                                    {slider.map((img, ind) => {
                                        return (
                                            <PillShapeImg
                                                key={ind}
                                                src={img.intro_slide}
                                                alt="-"
                                            />
                                        );
                                    })}
                                </Slider>
                            </IntroSlider>
                            <SliderDotsWrapper>
                                {slider.length > 0 && (
                                    <SlidesCount>
                                        {activeIndex + 1 + '/' + slider.length}
                                    </SlidesCount>
                                )}
                                {slider.map((img, ind) => {
                                    return (
                                        <SliderDot
                                            key={ind}
                                            active={activeIndex === ind}
                                            onClick={() => {
                                                sliderRef.current.slickGoTo(
                                                    ind
                                                ),
                                                    setActiveIndex(ind);
                                            }}
                                        />
                                    );
                                })}
                            </SliderDotsWrapper>
                        </Intro>
                        <About>
                            <FloatingImg
                                src={`${baseURL}assets/images/event/floating-img-1.svg`}
                                height="110"
                                width="123"
                                top="-3%"
                                left="10%"
                            />
                            <FloatingImg
                                src={`${baseURL}assets/images/event/floating-img-2.svg`}
                                height="440"
                                width="440"
                                top="18%"
                                right="-12%"
                            />
                            <Absolute>
                                <Container>
                                    {data?.agenda_boxes &&
                                        data.agenda_boxes.length > 0 && (
                                            <Row noGutters>
                                                {data.agenda_boxes.map(
                                                    (item, index) => {
                                                        return (
                                                            <Col
                                                                lg="4"
                                                                key={index}>
                                                                <TodoBox>
                                                                    <ScrollAnimation
                                                                        animateIn="fadeInUp"
                                                                        animateOnce={
                                                                            true
                                                                        }
                                                                        delay={
                                                                            200
                                                                        }>
                                                                        <TodoIcon>
                                                                            <img
                                                                                src={
                                                                                    item.agenda_icon
                                                                                }
                                                                                alt="-"
                                                                            />
                                                                        </TodoIcon>
                                                                        <Text
                                                                            font={
                                                                                axiformaMedium
                                                                            }>
                                                                            {ReactHtmlParser(
                                                                                item.agenda_text
                                                                            )}
                                                                        </Text>
                                                                    </ScrollAnimation>
                                                                </TodoBox>
                                                            </Col>
                                                        );
                                                    }
                                                )}
                                            </Row>
                                        )}
                                    <RSVP>
                                        <RowCentered>
                                            <Col lg="6">
                                                <ScrollAnimation
                                                    animateIn="fadeInUp"
                                                    animateOnce={true}
                                                    delay={200}>
                                                    <SubHeading>
                                                        RSVP
                                                    </SubHeading>
                                                    <BlockHeading marginTop>
                                                        {data &&
                                                            data.rsvp &&
                                                            data.rsvp
                                                                .rsvp_title}
                                                    </BlockHeading>
                                                    <Text>
                                                        {data &&
                                                            data.rsvp &&
                                                            data.rsvp.rsvp_text}
                                                    </Text>
                                                </ScrollAnimation>
                                            </Col>
                                            <Col lg={{ size: 5, offset: 1 }}>
                                                <ScrollAnimation
                                                    animateIn="fadeInUp"
                                                    animateOnce={true}
                                                    delay={200}>
                                                    <MarketoFormDesign
                                                        halfField
                                                        darkBack
                                                        id="rsvp-form">
                                                        <MarketoForm
                                                            Id={
                                                                data &&
                                                                data.rsvp &&
                                                                data.rsvp
                                                                    .rsvp_form_id
                                                            }
                                                            page="template"
                                                        />
                                                    </MarketoFormDesign>
                                                </ScrollAnimation>
                                            </Col>
                                        </RowCentered>
                                    </RSVP>
                                </Container>
                                <Container>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={true}
                                        delay={200}>
                                        <SubHeading marginBottom>
                                            ABOUT The Venue
                                        </SubHeading>
                                    </ScrollAnimation>
                                </Container>
                            </Absolute>
                            <AboutVenue>
                                <Container>
                                    <Row>
                                        <Col lg="6">
                                            <ScrollAnimation
                                                animateIn="fadeInUp"
                                                animateOnce={true}
                                                delay={200}>
                                                <img
                                                    src={
                                                        data &&
                                                        data.venue &&
                                                        data.venue.venue_image
                                                    }
                                                    alt="-"
                                                />
                                            </ScrollAnimation>
                                        </Col>
                                        <Col lg={{ size: 5, offset: 1 }}>
                                            <Absolute>
                                                <ScrollAnimation
                                                    animateIn="fadeInUp"
                                                    animateOnce={true}
                                                    delay={200}>
                                                    <BlockHeading>
                                                        {data &&
                                                            data.venue &&
                                                            data.venue
                                                                .venue_title}
                                                    </BlockHeading>
                                                    <Text>
                                                        {data &&
                                                            data.venue &&
                                                            data.venue
                                                                .venue_text}
                                                    </Text>
                                                    <Text
                                                        font={gilroyRegular}
                                                        address>
                                                        {data &&
                                                            data.venue &&
                                                            ReactHtmlParser(
                                                                data.venue
                                                                    .venue_address
                                                            )}
                                                    </Text>
                                                </ScrollAnimation>
                                            </Absolute>
                                        </Col>
                                    </Row>
                                </Container>
                            </AboutVenue>
                            <Absolute>
                                <Container>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={true}
                                        delay={200}>
                                        <SubHeading noBorder noMarginBottom>
                                            Map
                                        </SubHeading>
                                    </ScrollAnimation>
                                </Container>
                            </Absolute>
                        </About>
                        <Map>
                            <Container>
                                <SidePadding>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={true}
                                        delay={200}>
                                        <a
                                            href={data.map.map_link}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <img
                                                src={
                                                    data &&
                                                    data.map &&
                                                    data.map.map_image
                                                }
                                                alt="-"
                                            />
                                        </a>
                                    </ScrollAnimation>
                                </SidePadding>
                            </Container>
                        </Map>
                        <Footer>
                            <SidePadding>
                                <Container>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={true}
                                        delay={200}>
                                        <FlexColumnCentered>
                                            <SubHeading noBorder mobileCenter>
                                                {data &&
                                                    data.map &&
                                                    data.map.map_footer_heading}
                                            </SubHeading>
                                            <Link
                                                to={'rsvp-form'}
                                                activeClass="active"
                                                spy={true}
                                                smooth={true}
                                                duration={1000}
                                                offset={-120}>
                                                <Button round>
                                                    {data &&
                                                        data.map &&
                                                        data.map
                                                            .map_button_text}
                                                </Button>
                                            </Link>
                                        </FlexColumnCentered>
                                    </ScrollAnimation>
                                </Container>
                            </SidePadding>
                        </Footer>
                        <ForeGroundTexture
                            src={`${baseURL}assets/images/event/foreground-texture.png`}
                            alt="-"
                        />
                    </Layout>
                )
            )}
        </>
    );
};

const Intro = styled.div`
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    ${Media('xlscreens')} {
        height: 60vw;
    }
    ${Media('tablet')} {
        height: 430px;
    }
`;

const Sponsored = styled.h5`
    color: ${colors.white['100']};
    font-size: 26px;
    font-family: ${axiformaSemiBold};
    margin-top: 0;
    img {
        height: 30px;
        display: inline-flex;
        margin-left: 10px;
    }
    ${Media('xlscreens')} {
        font-size: 1.35vw;
        left: 35%;
        position: relative;
        img {
            height: 1.57vw;
            margin-left: 0.53vw;
        }
    }
    ${Media('tablet')} {
        font-size: 16px;
        img {
            height: 20px;
        }
    }
`;

const Location = styled.h2`
    font-size: 164px;
    font-family: ${akiraSuperBold};
    color: transparent;
    -webkit-text-stroke: 1px ${colors.white['100']};
    text-align: center;
    margin-top: 17%;
    ${Media('xlscreens')} {
        font-size: 8.54vw;
    }
    ${Media('tablet')} {
        font-size: 50px;
    }
    ${Media('mobile')} {
        font-size: 42px;
    }
`;

const FloatingImg = styled.img`
    position: absolute;
    top: ${(props) => props.top};
    right: ${(props) => props.right};
    left: ${(props) => props.left};
    bottom: ${(props) => props.bottom};
    z-index: ${(props) => props.zIndex};
    height: ${(props) => props.height + 'px'};
    width: ${(props) => props.width + 'px'};
    ${Media('xlscreens')} {
        height: ${(props) => props.height / 19.2 + 'vw'};
        width: ${(props) => props.width / 19.2 + 'vw'};
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 50px 100px;
    ${Media('xlscreens')} {
        padding: 2.6vw 5.2vw;
    }
    ${Media('tablet')} {
        padding: 30px;
    }
`;

const IntroContent = styled.div`
    width: 100%;
    position: relative;
    z-index: 999;
`;

const PageHeading = styled.div`
    p {
        font-size: 164px;
        font-family: ${akiraSuperBold};
        color: ${colors.event.template['white']};
        line-height: 1.1;
        margin: 0px;
        span {
            color: ${colors.pink['100']};
        }
    }
    ${Media('xlscreens')} {
        p {
            font-size: 8.54vw !important;
        }
    }
    ${Media('tablet')} {
        p {
            font-size: 50px !important;
        }
    }
    ${Media('mobile')} {
        p {
            font-size: 42px !important;
        }
    }
`;

const ForeGroundTexture = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.25;
    z-index: 9;
    mix-blend-mode: multiply;
`;

const IntroSlider = styled.div`
    position: absolute;
    width: 60%;
    bottom: 0;
    border-top-left-radius: 625px;
    border-top-right-radius: 625px;
    overflow: hidden;
    height: 85%;
    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide div {
        height: 100%;
    }
    .slick-dots {
        display: none !important;
    }
    ${Media('xlscreens')} {
        border-top-left-radius: 32.55vw;
        border-top-right-radius: 32.55vw;
    }
    ${Media('mobile')} {
        width: 80%;
        height: 70%;
    }
`;

const SlidesCount = styled.div`
    margin-bottom: 50px;
    font-family: ${morisonRegular};
    color: ${colors.white['100']};
    font-size: 22px;
    ${Media('xlscreens')} {
        margin-bottom: 2.6vw;
        font-size: 1.14vw;
    }
`;

const PillShapeImg = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    mix-blend-mode: lighten;
`;

const GradientCircle = styled.div`
    height: 345px;
    width: 345px;
    background-image: url(${baseURL}assets/images/event/gradient-circle.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
    box-sizing: border-box;
    position: absolute;
    bottom: 18%;
    left: 8%;
    z-index: 999;
    p {
        margin: 0;
        font-family: ${morisonRegular};
        text-align: center;
        font-size: 20px;
        text-transform: uppercase;
        svg {
            display: block;
            margin: 0 auto;
            height: 25px;
            width: 25px;
            margin-bottom: 20px;
        }
    }
    ${Media('xlscreens')} {
        height: 17.96vw;
        width: 17.96vw;
        padding: 3vw;
        p {
            font-size: 1.04vw;
            svg {
                height: 1.3vw;
                width: 1.3vw;
                margin-bottom: 1.04vw;
            }
        }
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const About = styled.section`
    padding-left: 80px;
    padding-right: 80px;
    background-color: ${colors.event.template['light']};
    padding-bottom: 0;
    position: relative;
    ${Media('xlscreens')} {
        padding-left: 4.16vw;
        padding-right: 4.16vw;
    }
    ${Media('tablet')} {
        padding-left: 30px;
        padding-right: 30px;
    }
    ${Media('mobile')} {
        padding-left: 15px;
        padding-right: 15px;
    }
`;

const TodoBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid ${colors.event.template['border']};
    padding: 70px 45px;
    img {
        max-height: 74px;
        max-width: 108px;
        margin: 0 auto;
    }
    ${Media('xlscreens')} {
        height: 100%;
        padding: 3.64vw 2.34vw;
        img {
            max-height: 3.85vw;
            max-width: 5.6vw;
        }
    }
    ${Media('tablet')} {
        padding: 50px 30px;
        margin-bottom: 20px;
        p {
            margin-bottom: 0;
        }
    }
`;

const TodoIcon = styled.div`
    margin-bottom: 40px;
    ${Media('xlscreens')} {
        margin-bottom: 2vw;
    }
`;

const Text = styled.p`
    color: ${colors.white['100']};
    font-size: 24px;
    font-family: ${(props) => (props.font ? props.font : gilroyMedium)};
    line-height: 1.66;
    ${(props) => {
        if (props.address) {
            return `
                font-size:20px;
                font-family:${gilroyRegular};
            `;
        }
    }}
    ${Media('xlscreens')} {
        font-size: 1.25vw;
        ${(props) => {
            if (props.address) {
                return `
                    font-size:1.04vw;
                `;
            }
        }}
    }
    ${Media('tablet')} {
        font-size: 16px;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;

const SubHeading = styled.h3`
    font-size: 28px;
    font-family: ${akiraSuperBold};
    color: ${colors.white['100']};
    border-bottom: ${(props) =>
        !props.noBorder && `1px solid ${colors.white['50']}`};
    padding-bottom: 30px;
    text-transform: uppercase;
    margin-bottom: ${(props) => props.marginBottom && '75px'};
    ${Media('xlscreens')} {
        font-size: 1.45vw;
        padding-bottom: 1.57vw;
        margin-bottom: ${(props) => props.marginBottom && '3.9vw'};
    }
    ${Media('tablet')} {
        font-size: 20px;
    }
    ${Media('mobile')} {
        font-size: 18px;
        ${(props) => {
            if (props.mobileCenter) {
                return `
                    text-align:center;
                `;
            }
        }}
    }
    ${(props) => {
        if (props.noMarginBottom) {
            return `
                margin-bottom:0;
            `;
        }
    }}
`;

const RSVP = styled.div`
    margin: 100px 0;
    .mktoForm {
        input[type='text'],
        input[type='email'],
        input[type='tel'],
        select.mktoField {
            background-color: transparent !important;
            border: 1px solid ${colors.event.template['border2']} !important;
            color: ${colors.event.template['label']} !important;
            font-size: 18px !important;
        }
        .mktoLabel {
            font-family: ${gilroyMedium} !important;
            font-size: 18px !important;
            color: ${colors.event.template['label']} !important;
            margin-bottom: 15px !important;
        }
        .mktoFormRow {
            width: 45% !important;
            margin-left: 15px !important;
            padding-right: 0px !important;
        }
        .mktoButtonRow .mktoButtonWrap button.mktoButton {
            width: 100% !important;
            border-radius: 5px !important;
            font-size: 15px !important;
            font-family: ${gilroyMedium} !important;
        }
        #thankYouContent {
            font-size: 24px;
            font-family: ${gilroyRegular};
            color: ${colors.white['100']};
            text-align: center;
            line-height: 1.66;
            padding: 4vw 3vw;
            border: 1px solid ${colors.event['template']['border']};
            ${Media('mobile')} {
                font-size: 14px;
                width: 100%;
            }
        }
    }
    .mktoTemplateBox {
        color: ${colors.white['100']};
        width: 100%;
        padding: 30px 0;
    }
    ${Media('xlscreens')} {
        margin: 5.2vw 0;
        .mktoForm {
            input[type='text'],
            input[type='email'],
            select.mktoField {
                font-size: 0.93vw !important;
            }
            .mktoLabel {
                font-size: 0.93vw !important;
                margin-bottom: 0.78vw !important;
            }
            .mktoFormRow {
                margin-left: 0.78vw !important;
            }
            .mktoButtonRow .mktoButtonWrap button.mktoButton {
                border-radius: 0.26vw !important;
                font-size: 0.78vw !important;
            }
        }
        .mktoTemplateBox {
            color: ${colors.white['100']};
            width: 100%;
            padding: 2.66vw 0;
        }
    }
    ${Media('tablet')} {
        .mktoForm {
            margin-top: 40px;
            .mktoFormRow {
                width: 50% !important;
                margin-left: 0px !important;
            }
        }
    }
    ${Media('mobile')} {
        margin: 70px 0;
        .mktoForm {
            .mktoFormRow {
                width: 100% !important;
                margin-left: 0 !important;
            }
            .mktoFormCol {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
            .mktoButtonRow {
                .mktoButtonWrap {
                    padding: 0 !important;
                }
            }
            .mktoLabel {
                font-size: 14px !important;
            }
        }
    }
`;

const BlockHeading = styled.h2`
    font-size: 42px;
    font-family: ${morisonSemiBold};
    color: ${colors.white['100']};
    ${Media('xlscreens')} {
        font-size: 2.18vw;
        margin-top: ${(props) => props.marginTop && '5.9vw'};
    }
    ${Media('tablet')} {
        font-size: 26px;
    }
`;

const AboutVenue = styled.div`
    margin: 100px 0;
    img {
        width: 100%;
        mix-blend-mode: lighten;
        border-radius: 10px;
    }
    ${Media('xlscreens')} {
        margin: 5.2vw 0;
    }
    ${Media('tablet')} {
        margin: 50px 0;
        img {
            margin-bottom: 50px;
        }
    }
`;

const Map = styled.div`
    position: relative;
    z-index: 999;
    img {
        width: 100%;
        filter: brightness(0.9);
    }
`;

const Footer = styled.section`
    position: relative;
    z-index: 99;
`;

const SidePadding = styled.div`
    ${Media('xlscreens')} {
        padding-left: 4.16vw;
        padding-right: 4.16vw;
    }
`;

const Absolute = styled.div`
    position: relative;
    z-index: 99;
`;

const SliderDotsWrapper = styled.div`
    position: absolute;
    right: 13%;
    top: 70%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${Media('tablet')} {
        display: none;
    }
`;

const SliderDot = styled.a`
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background-color: ${(props) =>
        props.active ? colors.pink['100'] : colors.white['100']};
    display: block;
    cursor: pointer;
    margin-bottom: 20px;
`;

export default EventTemplate;
