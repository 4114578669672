import React, { useEffect, useRef, useState } from 'react';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import { gilroyExtrabold, gilroySemibold } from 'shared-components/theme/fonts';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { Para } from './para';
import ReactHtmlParser from 'react-html-parser';
import { baseURL } from 'shared-components/theme/helpers';
import Video from 'shared-components/video/video';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import colors from 'shared-components/theme/colors';

export const WaveXLandingIntro = ({
    pageTag,
    pageHeading,
    pagePara,
    from,
    to,
}) => {
    const gradientRef = useRef(null);
    const [screenWidth, setScreenWidth] = useState('');
    const [gradientAnim, setGradientAnim] = useState(false);
    const [videoMuted, setVideoMuted] = useState(true);
    useEffect(() => {
        var controller = gradientRef.current;
        setScreenWidth(window.innerWidth);
        if (controller !== null && screenWidth > 1000) {
            window.onscroll = () => {
                var topPosition = document.body.getBoundingClientRect().top;
                if (topPosition < -300) {
                    setGradientAnim(true);
                } else {
                    setGradientAnim(false);
                }
            };
        }
    }, [screenWidth]);
    return (
        <Wrapper ref={gradientRef}>
            {!gradientAnim && (
                <Controller>
                    <Scene
                        triggerHook="onLeave"
                        duration="30%"
                        enabled={screenWidth < 1000 ? false : true}
                        offset={'-150%'}>
                        <Timeline>
                            <Tween from={from} to={to}>
                                <GradientBg />
                            </Tween>
                        </Timeline>
                    </Scene>
                </Controller>
            )}
            <Controller>
                <Scene
                    triggerElement=".wavex-intro-info"
                    triggerHook="onLeave"
                    duration="30%"
                    enabled={screenWidth < 1000 ? false : true}
                    offset={'-150%'}>
                    <Timeline>
                        <Tween
                            from={{
                                opacity: 1,
                            }}
                            to={{
                                opacity: 0,
                            }}>
                            <IntroInfo className="wavex-intro-info">
                                <Container>
                                    <Row>
                                        <Col lg="5" md="5">
                                            <InfoLeft>
                                                <PageTitle>{pageTag}</PageTitle>
                                                <PageHeading>
                                                    {ReactHtmlParser(
                                                        pageHeading
                                                    )}
                                                </PageHeading>
                                                <IntroPara>
                                                    <Para>
                                                        {ReactHtmlParser(
                                                            pagePara
                                                        )}
                                                    </Para>
                                                </IntroPara>
                                            </InfoLeft>
                                        </Col>
                                        <Col lg="7" md="7">
                                            <InfoRight>
                                                <img
                                                    src={`${baseURL}assets/images/tech/wave-intro-women.png`}
                                                    alt="-"
                                                />
                                            </InfoRight>
                                        </Col>
                                    </Row>
                                </Container>
                            </IntroInfo>
                        </Tween>
                    </Timeline>
                </Scene>
            </Controller>
            {(gradientAnim || screenWidth < 1000) && (
                <IframeVideo>
                    <Video
                        video={`${baseURL}assets/videos/wavex/wave-landing-video.mp4#t=02`}
                        videotype="mp4"
                        image={`${baseURL}assets/images/tech/wavex-landing-frame.jpg`}
                        notMuted={!videoMuted}
                    />
                    <MuteBtn onClick={() => setVideoMuted(!videoMuted)}>
                        {videoMuted ? (
                            <img
                                src={`${baseURL}assets/images/tech/muted.svg`}
                                alt="-"
                            />
                        ) : (
                            <img
                                src={`${baseURL}assets/images/tech/un-muted.svg`}
                                alt="-"
                            />
                        )}
                    </MuteBtn>
                </IframeVideo>
            )}
            <TransXImageBG />
        </Wrapper>
    );
};
const MuteBtn = styled.div`
    position: absolute;
    cursor: pointer;
    z-index: 99;
    bottom: 30px;
    left: 40px;
    display: none;
    img {
        height: 20px;
        width: 20px;
    }
    ${Media('xlscreens')} {
        left: 2.08vw;
        bottom: 1.56vw;
        img {
            height: 1.04vw;
            width: 1.04vw;
        }
    }
    ${Media('tablet')} {
        display: block;
        left: 25px;
    }
`;
const TransXImageBG = styled.div`
    ${Media('xlscreens')} {
        background-image: url('${baseURL}assets/images/tech/wavex-landing-transBg.png');
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        top: -6vw;
        height: 100vh;
        width: 100%;
        z-index: -9;
    }
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    ${Media('xlscreens')} {
        height: 74vw;
        margin-top: 2vw;
    }
    ${Media('tablet')} {
        min-height: 1000px;
    }
    ${Media('mobile')} {
        min-height: 950px;
    }
`;
const PageTitle = styled.div`
    font-size: 30px;
    font-family: ${gilroySemibold};
    position: relative;
    margin-bottom: 30px;
    color: ${colors.waveX['darkText']};
    ${Media('xlscreens')} {
        font-size: 1.56vw;
        margin-bottom: 2.6vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
const PageHeading = styled.h2`
    font-size: 100px;
    font-family: ${gilroyExtrabold};
    line-height: 1.01;
    margin: 0;
    letter-spacing: -3px;
    color: ${colors.waveX['darkText']};
    ${Media('xlscreens')} {
        font-size: 5.2vw;
    }
    ${Media('tablet')} {
        font-size: 40px;
    }
    ${Media('mobile')} {
        font-size: 30px;
        letter-spacing: 0px;
    }
`;
const IntroPara = styled.div`
    margin-top: 40px;
    color: ${colors.waveX['darkText']};
    p {
        text-align: left;
        line-height: 1.44;
        color: ${colors.waveX['darkText']};
    }
    ${Media('xlscreens')} {
        margin-top: 3vw;
    }
    ${Media('mobile')} {
        p {
            text-align: center;
        }
    }
`;
const IntroInfo = styled.div`
    position: absolute;
    z-index: 99;
    width: 100%;
    top: 80px;
    ${Media('xlscreens')} {
        top: 0;
    }
    ${Media('tablet')} {
        padding: 0px 50px;
        box-sizing: border-box;
    }
    ${Media('mobile')} {
        padding: 0px 18px;
        text-align: center;
        br {
            display: none;
        }
        .row {
            flex-direction: column-reverse;
        }
    }
`;
const InfoLeft = styled.div`
    ${Media('mobile')} {
        margin-top: 20px;
    }
`;
const InfoRight = styled.div`
    img {
        width: 100%;
    }
`;
const GradientBg = styled.div`
    margin: 0 auto;
    ${Media('xlscreens')} {
        height: 30vw;
        width: 30vw;
    }
    ${Media('tablet')} {
        height: 300px !important;
        width: 300px !important;
        margin: unset;
        position: relative;
        right: 150px;
        float: right;
        top: 50px;
    }
    ${Media('mobile')} {
        height: 250px !important;
        width: 250px !important;
        position: unset;
        margin: 0 auto;
        transform: translateX(0) !important;
        float:unset;
        margin-top:50px;
    }
`;
const IframeVideo = styled.div`
    position: relative;
    z-index: 99;
    video {
        height: 100%;
        width: 100%;
    }
    &:hover {
        ${MuteBtn} {
            display: block;
        }
    }
    ${Media('xlscreens')} {
        height: 33.38vw;
        width: 59.47vw;
        background-image: linear-gradient(to bottom, #1e0e2f 0%, #1e0e2f 0%);
        margin: 0 auto;
        margin-top: 25vw;
    }
    ${Media('tablet')} {
        margin-top: 240px;
        width: 600px;
        display: flex;
        align-self: center;
        video {
            width: 100%;
        }
    }
    ${Media('mobile')} {
        margin-top: 400px;
        width: 300px;
    }
`;
