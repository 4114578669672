import React, { useRef, useEffect } from 'react';
import { Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import colors from 'shared-components/theme/colors';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';
import ProgressBar from 'progressbar.js';
import ScrollAnimation from 'react-animate-on-scroll';
import { gilroyMedium } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

export const CXSectionIntro = ({
    section3,
    introPara,
    progressImg,
    progress,
    active,
    section4,
    source,
    style2
}) => {
    const progressAnim = useRef(null);
    const section3barAnim1 = useRef(null);
    const section3barAnim2 = useRef(null);
    const section5barAnim = useRef(null);

    const CircleProgress = (element, circleValue) => {
        if (element.current) {
            var bar = new ProgressBar.Circle(element.current, {
                color: colors.white['100'],
                // This has to be the same size as the maximum width to
                // prevent clipping
                strokeWidth: 10,
                trailWidth: 10,
                easing: 'easeInOut',
                duration: 3000,
                trailColor: style2 ? colors.cx_pocket_guide['pink'] : colors.cx_pocket_guide['dark'],
                from: { color: colors.pink['100'], width: 10 },
                to: { color: colors.pink['100'], width: 10 },
                // Set default step function for all animate calls
                step: function (state, circle) {
                    circle.path.setAttribute('stroke', state.color);
                    circle.path.setAttribute('stroke-width', state.width);
                },
            });
            bar.animate(circleValue);
        }
    };

    const BarProgress = (element, barValue) => {
        if (element.current) {
            var bar = new ProgressBar.Line(element.current, {
                strokeWidth: section4 ? 50 : 10,
                easing: 'easeInOut',
                duration: 1400,
                color: colors.pink['100'],
                trailColor: colors.cx_pocket_guide['dark'],
                trailWidth: section4 ? 50 : 10,
                svgStyle: { width: '100%', height: '100%' },
                text: {
                    style: {
                        color: colors.white['100'],
                        position: 'absolute',
                        right: section4 ? '35%' : '5%',
                        top: section4 ? '35%' : '9%',
                        padding: 0,
                        margin: 0,
                        transform: null,
                        fontSize: '22px',
                        fontFamily: gilroyMedium,
                    },
                    autoStyleContainer: false,
                },
                from: { color: '#FFEA82' },
                to: { color: '#ED6A5A' },
                step: (state, bar) => {
                    bar.setText(Math.round(bar.value() * 100) + ' %');
                },
            });

            bar.animate(barValue);
        }
    };

    useEffect(() => {
        CircleProgress(progressAnim, (progress && progress) || 0.5);
        BarProgress(section3barAnim1, 0.4);
        BarProgress(section3barAnim2, 0.46);
        BarProgress(section5barAnim, 0.6);
    }, [active]);

    return (
        <ProgressSection section3={section3} style2={style2}>
            <RowCentered>
                <Col lg={section3 ? '5' : (section4 || style2) ? '3' : '2'}>
                    <AnimWrapper>
                        {active && !section3 && !section4 && (
                            <ProgressCircle ref={progressAnim}>
                                <img src={progressImg} alt="Progress" />
                            </ProgressCircle>
                        )}
                        {active && section3 && (
                            <>
                                <Section3Progress>
                                    <Bar ref={section3barAnim1} />
                                </Section3Progress>
                                <Section3Progress>
                                    <Bar ref={section3barAnim2} />
                                </Section3Progress>
                            </>
                        )}
                        {active && section4 && (
                            <Section5Progress>
                                <Bar ref={section5barAnim} />
                                <img
                                    src={`${baseURL}assets/images/ebook/cx-progress4.svg`}
                                    alt="-"
                                />
                            </Section5Progress>
                        )}
                    </AnimWrapper>
                </Col>

                <Col lg={section3 ? '7' : (section4 || style2) ? '9' : '10'}>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <IntroPara style2={style2}>{ReactHtmlParser(introPara)}</IntroPara>
                        {source && typeof source === 'object' ? (
                            <SourcesWrapper>
                                {source.map((s, index) => {
                                    return <Source key={index}>{s}</Source>;
                                })}
                            </SourcesWrapper>
                        ) : (
                            <Source style2={style2}>{source}</Source>
                        )}
                    </ScrollAnimation>
                </Col>
            </RowCentered>
        </ProgressSection>
    );
};

const ProgressCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        position: absolute;
        object-fit: contain;
        max-width: 100%;
    }
    ${Media('xlscreens')} {
        height: 9vw;
        width: 9vw;
    }
    ${Media('tablet')} {
        height: 150px;
        width: 150px;
        margin: 0 auto;
        margin-bottom: 50px;
    }
`;

const Bar = styled.div``;

const AnimWrapper = styled.div`
    ${Media('tablet')} {
        height: 200px;
    }
`;

const ProgressSection = styled.div`
    padding-right: ${(props) => (props.section3 ? '20px' : '100px')};
    ${Media('xlscreens')} {
        padding-right: ${(props) => (props.section3 ? '1.04vw' : '5.2vw')};
    }
    ${Media('tablet')} {
        padding-right: 0;
    }
    ${props => {
        if (props.style2) {
            return `
                padding-right:0 !important;
                ${Media('tablet')}{
                    margin-bottom:80px;
                }
            `;
        }
    }}
`;

const Section3Progress = styled.div`
    position: relative;
`;

const Section5Progress = styled.div`
    position: relative;
    img {
        position: absolute;
        height: 120%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -9;
    }
    ${Media('tablet')} {
        width: 50%;
        margin: 0 auto;
    }
`;

const IntroPara = styled.p`
    font-size: 28px;
    padding-left: 50px;
    color: ${props => props.style2 ? colors.white['100'] : colors.cx_pocket_guide['dark_gray']};
    margin-top: 0;
    span {
        color: ${colors.pink['100']};
        text-decoration: underline;
    }
    ${Media('xlscreens')} {
        font-size: 1.45vw;
        padding-left: 2.6vw;
    }
    ${Media('tablet')} {
        padding-left: 0;
        font-size: 22px;
    }
    ${Media('mobile')} {
        font-size: 16px;
    }
`;

const Source = styled.h5`
    font-size: 18px;
    font-family: ${gilroyMedium};
    padding-left: 50px;
    margin: 0;
    color:${props => props.style2 ? colors.white['100'] : colors.black['100']};
    ${Media('xlscreens')} {
        font-size: 0.93vw;
        padding-left: 2.6vw;
    }
    ${Media('tablet')} {
        padding-left: 0;
        font-size: 16px;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;

const SourcesWrapper = styled.div`
    display: flex;
    ${Source} {
        margin-right: 10px;
    }
    ${Media('xlscreens')} {
        ${Source} {
            margin-right: 1.04vw;
        }
    }
`;
