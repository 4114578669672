import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';

export const Banner = ({
    bgImage,
    bgImageMobile,
    children,
    centered,
    bannerSideOff,
    bgSize,
    bgColor,
}) => {
    return (
        <Wrapper
            bgImage={bgImage}
            bgImageMobile={bgImageMobile}
            centered={centered}
            bannerSideOff={bannerSideOff}
            bgSize={bgSize}
            bgColor={bgColor}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    background-image: url(${(props) => props.bgImage});
    background-size: ${(props) => (props.bgSize ? props.bgSize : '100% 100%')};
    background-repeat: no-repeat;
    background-color: ${(props) => props.bgColor};
    height: 218px;
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${(props) => (props.centered ? 'center' : 'flex-start')};
    ${Media('xlscreens')} {
        margin: ${(props) => (props.bannerSideOff ? '0 auto' : '0 9.63vw')};
        height: 11.21vw;
        padding-left: 3.12vw;
    }
    ${Media('tablet')} {
        background-size: ${(props) => (props.bgSize ? props.bgSize : 'cover')};
        padding-left: 0px;
        height: auto;
        padding: 25px 0;
    }
    ${Media('mobile')} {
        background-image: url(${(props) => props.bgImageMobile});
        background-color: ${(props) => props.bgColor};
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;
