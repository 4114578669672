import React from 'react';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import ReactHtmlParser from 'react-html-parser';
import {
    SRMSectionHeading,
} from 'components/social-rep-man/common';
import MainPara from 'shared-components/main-para/main-para';
import {
    RowCenteredBoth,
    FlexCentered,
    FlexColumnCentered
} from 'shared-components/theme/grid-helpers';
import {
    gilroyBold,
    gilroyRegular,
} from 'shared-components/theme/fonts';
import colors from 'shared-components/theme/colors';

export const RWScenerioSection = ({ realworldData, sceneData }) => {
    return (
        <>
            <RealWorldSection id="gold">
                <Container>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <SRMSectionHeading>
                                    Real-world customer support experiences
                                    <br />
                                    without real-world consequences
                                </SRMSectionHeading>
                                <MainPara align="center">
                                    ibex Training Simulator runs agents through
                                    a structured three-stage training approach,
                                    where learners progress through <br />
                                    levels of increasing difficulty and defined
                                    targets, earning a certificate when they
                                    complete the final phase.
                                </MainPara>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                    <Row>
                        {realworldData &&
                            realworldData.length > 0 &&
                            realworldData.map((item, index) => {
                                return (
                                    <Col sm="12" lg="4" key={index}>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={true}>
                                            <RealWorldBox>
                                                <RWCount>{index + 1}</RWCount>
                                                <RWContent>
                                                    <RWImg>
                                                        <img
                                                            src={item.image}
                                                            alt="-"
                                                        />
                                                    </RWImg>
                                                    <RWHeading>
                                                        {item.heading}
                                                    </RWHeading>
                                                    <MainPara
                                                        color={
                                                            colors.pink['100']
                                                        }
                                                        noMargin>
                                                        Goal
                                                    </MainPara>
                                                    <MainPara>
                                                        {ReactHtmlParser(
                                                            item.goal
                                                        )}
                                                        <br />
                                                        <br />
                                                    </MainPara>
                                                    <MainPara
                                                        color={
                                                            colors.pink['100']
                                                        }
                                                        noMargin>
                                                        Approach
                                                    </MainPara>
                                                    <MainPara>
                                                        {ReactHtmlParser(
                                                            item.approach
                                                        )}
                                                    </MainPara>
                                                </RWContent>
                                            </RealWorldBox>
                                        </ScrollAnimation>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </RealWorldSection>

            <ScenarioSection>
                <Container fluid>
                    <RowCenteredBoth>
                        <Col sm="12" lg="8">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <SRMSectionHeading color={colors.white['100']}>
                                    Master call drivers, reduce handle time
                                </SRMSectionHeading>
                                <MainPara
                                    align="center"
                                    color={colors.white['100']}>
                                    Every learning scenario follows a
                                    prescriptive approach to accelerate and
                                    enhance agents’ mastery of common call
                                    drivers on various <br />
                                    technical, sales and customer support roles.
                                </MainPara>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12" md="12" lg="10">
                            <RowCenteredBoth>
                                {sceneData &&
                                    sceneData.length > 0 &&
                                    sceneData.map((item, index) => {
                                        return (
                                            <Col
                                                sm="12"
                                                md="4"
                                                lg="auto"
                                                key={index}>
                                                <ScrollAnimation
                                                    animateIn="fadeInUp"
                                                    animateOnce={true}>
                                                    <SceneBox>
                                                        <SceneIcon>
                                                            {ReactHtmlParser(
                                                                item.icon
                                                            )}
                                                        </SceneIcon>
                                                        <MainPara
                                                            color={
                                                                colors.white[
                                                                    '100'
                                                                ]
                                                            }
                                                            noMargin>
                                                            <b>
                                                                {ReactHtmlParser(
                                                                    item.content
                                                                )}
                                                            </b>
                                                        </MainPara>
                                                    </SceneBox>
                                                </ScrollAnimation>
                                            </Col>
                                        );
                                    })}
                            </RowCenteredBoth>
                        </Col>
                    </RowCenteredBoth>
                </Container>
            </ScenarioSection>
        </>
    );
};
const RealWorldSection = styled.section`
    ${Media('xlscreens')} {
        padding: 7vw 0;
    }
`;
const RealWorldBox = styled.div`
    position: relative;
    z-index: 10;
    padding-left: 85px;
    margin-top: 50px;
    ${Media('xlscreens')} {
        padding-left: 4.42vw;
        margin-top: 3vw;
    }
    ${Media('tablet')} {
        padding-left: 0;
        text-align: center;
        padding-top: 100px;
        max-width: 320px;
        margin: 50px auto 0;
    }
`;

const RWCount = styled(FlexCentered)`
    position: absolute;
    left: 0px;
    top: 20px;
    width: 75px;
    height: 75px;
    background-color: ${colors.pink['100']};
    border: 1px solid ${colors.gray['100']};
    color: ${colors.white['100']};
    font-size: 30px;
    font-family: ${gilroyBold};
    ${Media('xlscreens')} {
        font-size: 1.66vw;
        top: 1vw;
        width: 3.9vw;
        height: 3.9vw;
    }
    ${Media('tablet')} {
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
    }
`;

const RWContent = styled.div`
    p {
        font-family: ${gilroyRegular};
    }
    ${Media('xlscreens')} {
        max-width: 14.8vw;
    }
`;

const RWImg = styled.div`
    ${Media('xlscreens')} {
        min-height: 11.2vw;
        img {
            max-width: 12.8vw;
        }
    }
    ${Media('tablet')} {
        display: flex;
        justify-content: center;
    }
`;

const RWHeading = styled.div`
    font-size: 32px;
    font-family: ${gilroyBold};
    margin: 35px 0px 20px;
    ${Media('xlscreens')} {
        font-size: 2.1vw;
        margin: 1.8vw 0 1vw;
    }
`;

const ScenarioSection = styled(RealWorldSection)`
    background-color: ${colors.socialRep['brandsBG']};
`;

const SceneBox = styled(FlexColumnCentered)`
    margin-top: 40px;
    text-align: center;
    ${Media('xlscreens')} {
        margin-top: 3vw;
        min-width: 13vw;
    }
`;

const SceneIcon = styled.div`
    ${Media('xlscreens')} {
        max-width: 2.2vw;
        min-height: 2.2vw;
        margin-bottom: 1.7vw;
    }
`;