import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyMedium, gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import { baseURL } from 'shared-components/theme/helpers';
import { SRMCallActionSection } from 'components/social-rep-man/common';
import { EbookFloatingImage } from 'components/ebook/common';

export const NeedCallAction = ({ needList, setDemoRequestForm }) => {
    return (
        <>
            <NeedSection id="need">
                <EbookFloatingImage
                    image={`${baseURL}assets/images/tech/reflecx-need-image-1.png`}
                    width={972}
                    bottom="0"
                    right="-10%"
                    x={['0', '0']}
                />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/tech/reflecx-need-image-2.png`}
                    width={469}
                    height={660}
                    bottom="0"
                    right="20%"
                    x={['-10%', '10%']}
                />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/tech/reflecx-need-image-3.png`}
                    width={362}
                    height={727}
                    bottom="0"
                    right="-3%"
                    x={['10%', '-10%']}
                />
                <Container>
                    <Row>
                        <Col sm="12" lg="6">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <NeedHead>
                                    Everything you need for a proactive CX.
                                </NeedHead>
                                <NeedList>
                                    {needList.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <img
                                                    src={`${baseURL}assets/images/tech/reflcx-need-list-icon.svg`}
                                                    alt="-"
                                                />
                                                {item}
                                            </li>
                                        );
                                    })}
                                </NeedList>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </NeedSection>

            <SRMCallActionSection
                heading="Ready to Jumpstart Your <br /> Customer Engagement?"
                para="Upgrade Your CX today."
                btnText="Book a demo"
                onBtnClick={() => setDemoRequestForm(true)}
                btnBgColor={colors.reflecx['orange']}
            />
        </>
    );
};
const NeedSection = styled.section`
    position: relative;
    display: flex;
    align-items: center;
    ${Media('xlscreens')} {
        min-height: 47.7vw;
    }
`;

const NeedHead = styled.h2`
    font-size: 36px;
    font-family: ${gilroySemibold};
    line-height: 1.5;
    color: ${colors.socialRep['brandsBG']};
    max-width: 60%;
    ${Media('xlscreens')} {
        font-size: 2.08vw;
        margin-bottom: 2.3vw;
    }
    ${Media('mobile')} {
        font-size: 30px;
        max-width: 100%;
    }
`;

const NeedList = styled.ul`
    margin: 0px;
    padding: 0px;
    border: 1px solid ${colors.socialRep['brandsBG']};
    border-bottom: none;
    li {
        border-bottom: 1px solid ${colors.socialRep['brandsBG']};
        font-size: 20px;
        font-family: ${gilroyMedium};
        padding: 15px 0;
        padding-right: 15px;
        display: flex;
        align-items: center;
        img {
            width: 23px;
            height: 23px;
            margin: 0 20px;
        }
    }
    ${Media('xlscreens')} {
        li {
            font-size: 1.05vw;
            padding: 0.66vw 0;
            padding-right: 1vw;
            img {
                width: 1.2vw;
                height: 1.2vw;
                margin: 0 2vw;
            }
        }
    }
    ${Media('mobile')} {
        li {
            font-size: 16px;
        }
    }
`;
