import React, { useEffect, useState } from 'react';
import Layout from 'components/layout';
import { baseURL } from 'shared-components/theme/helpers';
import styled from 'styled-components';
import { FaCaretDown } from 'react-icons/fa';
import colors from 'shared-components/theme/colors';
import AnimatedText from 'react-animated-text-content';
import {
    akiraSuperBold,
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import { Col, Row } from '@bootstrap-styled/v4';
import { RowContentCenter } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-scroll';
import Loader from 'components/loader/loader';
import { Parallax } from 'react-scroll-parallax';
import nominees from './nominees';

const FintechAwards2024 = () => {
    const [imgAnimate, setImgAnimate] = useState(false);
    const [loader, setLoader] = useState(true);
    const [sponsored, setSponsored] = useState(null);

    const getPageData = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/page?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        post_name: 'cx-leadership-awards',
                    })
            );
            const resData = await res.json();
            if (resData.status === 200 && resData.data.length !== 0) {
                const logo = resData?.data[0]?.metadata || null;
                if (logo) {
                    setSponsored(logo);
                }
            }
        } catch {
            console.log('eror');
        } finally {
            setLoader(false);
            setImgAnimate(true);
        }
    };

    useEffect(() => {
        getPageData();
    }, []);

    const TextAnimation = ({ children, interval, duration, tag, type }) => {
        return (
            <AnimatedText
                type={type ? type : 'chars'}
                animation={{
                    x: '0px',
                    y: '500px',
                    scale: 1,
                    ease: 'ease-in-out',
                }}
                tag={tag}
                interval={interval ? interval : 0.08}
                duration={duration ? duration : 1}
                includeWhiteSpaces>
                {children}
            </AnimatedText>
        );
    };
    const rows = [];
    for (let i = 0; i < nominees.length; i += 2) {
        rows.push(nominees.slice(i, i + 2));
    }
    return (
        <Layout darkTheme>
            {/* CX Awards Intro Section */}
            <CXAwardsIntro>
                <BgImage
                    src={`${baseURL}assets/images/cx-awards/cx-awards-2022-1.jpg`}
                    alt="-"
                />

                <IntroHeader>
                    <AwardTag>
                        <img
                            src={`${baseURL}assets/images/cx-awards/logo.png`}
                            alt="-"
                        />
                    </AwardTag>
                </IntroHeader>
                <IntroRoundBg imgAnimate={imgAnimate} />
                {(loader && (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                )) || (
                    <>
                        <CXAwardMainText>
                            <MainText align="right">
                                <Parallax x={['2%', '-12%']}>
                                    <TextAnimation>The Cx</TextAnimation>
                                </Parallax>
                                <Parallax x={['-2%', '12%']}>
                                    <TextAnimation>Leadership</TextAnimation>
                                </Parallax>
                                <Parallax x={['2%', '-12%']}>
                                    <TextAnimation>Awards</TextAnimation>
                                </Parallax>
                            </MainText>
                        </CXAwardMainText>
                        <Text2024>
                            <Parallax x={['0%', '15%']}>
                                <TextAnimation interval={0.08} duration={0.5}>
                                    2024
                                </TextAnimation>
                            </Parallax>
                        </Text2024>
                        {sponsored && sponsored.sponsored_by && (
                            <Sponsored>
                                <Parallax x={['12%', '40%']}>
                                    <h1>
                                        <TextAnimation>
                                            Sponsored By
                                        </TextAnimation>
                                        <img
                                            src={sponsored.sponsored_by}
                                            alt="-"
                                        />
                                    </h1>
                                </Parallax>
                            </Sponsored>
                        )}
                    </>
                )}

                <IntroMainImg imgAnimate={imgAnimate}>
                    <img
                        src={`${baseURL}assets/images/cx-awards/cx-awards-2022-intro-lady.png`}
                        alt="-"
                    />
                </IntroMainImg>
                <OverlayImg>
                    <img
                        src={`${baseURL}assets/images/cx-awards/cx-awards-2022-intro-overlay.png`}
                        alt="-"
                    />
                </OverlayImg>
                <IntroFooter>
                    <NomineesLink>
                        <Link
                            to={'see_the_nominees'}
                            spy={true}
                            smooth={true}
                            offset={-80}
                            duration={1000}>
                            See the Nominees
                            <FaCaretDown color={colors.pink['100']} />
                        </Link>
                    </NomineesLink>
                </IntroFooter>
            </CXAwardsIntro>

            {/* CX Innovators */}
            <CXInnovators>
                <GreyCircleBg />
                <Section2Content>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <BuiltForTxt>Built for what’s next.</BuiltForTxt>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <Section2Heading>
                            The CX Leadership Awards honor <br /> the top CX
                            innovators, leaders, <br />
                            and contributors to the CX space.
                        </Section2Heading>
                    </ScrollAnimation>
                </Section2Content>
                <AboutOrg>
                    <RowContentCenter>
                        <Col lg="5">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}
                                delay={300}>
                                <AboutOrgBlock>
                                    <AboutOrgTitle>The Winners</AboutOrgTitle>
                                    <AboutOrgPara>
                                        Award winners demonstrate a commitment
                                        to the customer experience that goes
                                        beyond the transactional, but to a
                                        fundamental understanding of the
                                        customer journey, resulting in higher
                                        customer satisfaction, deeper customer
                                        loyalty, and a satisfying bounce to the
                                        bottom line.
                                    </AboutOrgPara>
                                </AboutOrgBlock>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="5">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}
                                delay={500}>
                                <AboutOrgBlock>
                                    <AboutOrgTitle>Who We Are</AboutOrgTitle>
                                    <AboutOrgPara>
                                        We’re an advanced CX outsourcer working
                                        with leading high-tech, fintech, and
                                        hyper-growth brands to deliver
                                        high-touch, secure, on-demand customer
                                        experiences delivered by a global talent
                                        pool of 31,000+ CX experts from 31
                                        omnichannel contact centers of
                                        excellence.
                                    </AboutOrgPara>
                                </AboutOrgBlock>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <PromotingHeading>
                                    Promoting the top leaders across the&nbsp;
                                    <span>CX Industry</span>
                                </PromotingHeading>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <FeaturesList>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={true}
                                    delay={300}>
                                    <Feature>
                                        <FeatImg>
                                            <img
                                                src={`${baseURL}assets/images/cx-awards/cx-awards-2022-innovation.svg`}
                                                alt="-"
                                            />
                                        </FeatImg>
                                        <FeatTitle>Innovation</FeatTitle>
                                    </Feature>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={true}
                                    delay={500}>
                                    <Feature>
                                        <FeatImg>
                                            <img
                                                src={`${baseURL}assets/images/cx-awards/cx-awards-2022-collaboration.svg`}
                                                alt="-"
                                            />
                                        </FeatImg>
                                        <FeatTitle>Collaboration</FeatTitle>
                                    </Feature>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={true}
                                    delay={700}>
                                    <Feature>
                                        <FeatImg>
                                            <img
                                                src={`${baseURL}assets/images/cx-awards/cx-awards-2022-performance.svg`}
                                                alt="-"
                                            />
                                        </FeatImg>
                                        <FeatTitle>Performance</FeatTitle>
                                    </Feature>
                                </ScrollAnimation>
                            </FeaturesList>
                        </Col>
                    </RowContentCenter>
                </AboutOrg>
                <FloatingImg top={'40%'} left={'5%'}>
                    <img
                        src={`${baseURL}assets/images/cx-awards/floating-diamong.svg`}
                        alt="-"
                    />
                </FloatingImg>
                <FloatingImg top={'12%'} right={'8%'}>
                    <img
                        src={`${baseURL}assets/images/cx-awards/floating-diamong-trans.svg`}
                        alt="-"
                    />
                </FloatingImg>
            </CXInnovators>

            {/* Nominees Section */}
            <NomineesSection id="see_the_nominees">
                <Row>
                    <Col lg="3">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <AwardTag>
                                <img
                                    src={`${baseURL}assets/images/cx-awards/logo.png`}
                                    alt="-"
                                />
                            </AwardTag>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <NomineeListTitle>
                                The 2024 <br />
                                Nominee Shortlist
                            </NomineeListTitle>
                        </ScrollAnimation>
                    </Col>
                    <Col lg="9">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <TableWrapper>
                                <NomineesTable>
                                    <thead>
                                        <TableHeading colWidth={280}>
                                            Nominee
                                        </TableHeading>
                                        <TableHeading colWidth={250}>
                                            Company
                                        </TableHeading>
                                        <TableHeading colWidth={280}>
                                            Nominee
                                        </TableHeading>
                                        <TableHeading colWidth={250}>
                                            Company
                                        </TableHeading>
                                    </thead>
                                    <tbody>
                                        {rows.map((pair, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {pair.map((obj, cellIndex) => (
                                                    <React.Fragment
                                                        key={cellIndex}>
                                                        <TableBodyData>
                                                            <NomineeName>
                                                                {obj.name}
                                                            </NomineeName>
                                                        </TableBodyData>
                                                        <TableBodyData>
                                                            <NomineeCompany>
                                                                {obj.company}
                                                            </NomineeCompany>
                                                        </TableBodyData>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </NomineesTable>
                            </TableWrapper>
                        </ScrollAnimation>
                    </Col>
                </Row>
                <BgImage
                    src={`${baseURL}assets/images/cx-awards/cx-awards-2022-1.jpg`}
                    alt="-"
                />
            </NomineesSection>

            {/* Nominate Someone  */}
            <NominateSomeone>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    <p>
                        Someone missing from our list? <br />
                        Nominate them now! Email&nbsp;
                        <a href="mailto:marketing@ibex.co">
                            Marketing@ibex.co.
                        </a>
                    </p>
                </ScrollAnimation>
            </NominateSomeone>

            {/* Announcement Data Section */}
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Announcement>
                    <AnnouncementDate>
                        Winners will be announced on May 30th, 2024.
                    </AnnouncementDate>
                    <AwardTag>
                        <img
                            src={`${baseURL}assets/images/cx-awards/logo-dark.png`}
                            alt="-"
                        />
                    </AwardTag>
                    {sponsored && sponsored.sponsored_by && (
                        <SponsoredDark>
                            <h3>Sponsored by:</h3>
                            <img
                                src={sponsored.sponsored_by_dark_logo}
                                alt="-"
                            />
                        </SponsoredDark>
                    )}
                </Announcement>
            </ScrollAnimation>
        </Layout>
    );
};

const BgImage = styled.img`
    position: absolute;
    z-index: -9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const LoaderWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: ${colors.white['100']};
    z-index: 99;
`;

const OverlayImg = styled.div`
    position: absolute;
    height: 100%;
    img {
        height: 100%;
        width: 100vw;
        opacity: 0.3;
        object-fit: cover;
    }
    ${Media('tablet')} {
        top: 0;
    }
`;

const IntroHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 40px 170px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    ${Media('xlscreens')} {
        position: absolute;
        padding: 2.08vw 8.85vw;
        z-index: 9;
    }
    ${Media('tablet')} {
        padding: 50px 0;
        flex-direction: column;
    }
`;

const AwardTag = styled.div`
    img {
        width: 260px;
    }
    ${Media('xlscreens')} {
        img {
            width: 13.54vw;
        }
    }
    ${Media('tablet')} {
        img {
            width: 270px;
            margin: 0 auto;
        }
    }
`;

const IntroRoundBg = styled.div`
    background-color: ${colors.socialRep['brandsBG']};
    height: 95vh;
    width: 70vw;
    border-top-left-radius: 50vw;
    border-top-right-radius: 50vw;
    margin-top: 5vh;
    transform: translateY(50vw);
    transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
    opacity: 0;
    ${(props) => {
        if (props.imgAnimate) {
            return `
                transform: translateY(0vw);
                opacity: 1;
            `;
        }
    }}
    ${Media('tablet')} {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
    }
`;

const Text2024 = styled.div`
    font-size: 450px;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${colors.white['50']};
    bottom: 0px;
    font-family: ${akiraSuperBold};
    ${Media('xlscreens')} {
        position: absolute;
        font-size: 23.43vw;
    }
    ${Media('tablet')} {
        font-size: 90px;
        text-align: center;
    }
`;

const CXAwardMainText = styled.div`
    bottom: 270px;
    text-transform: uppercase;
    ${Media('xlscreens')} {
        position: absolute;
        bottom: 13vw;
        left: 0;
        width: 75vw;
    }
`;

const MainText = styled.h2`
    font-size: 170px;
    font-family: ${akiraSuperBold};
    color: ${colors.white['100']};
    text-align: ${(props) => (props.align ? props.align : 'left')};
    line-height: 0.85;
    ${Media('xlscreens')} {
        font-size: 8.85vw;
    }
    ${Media('tablet')} {
        font-size: 44px;
        text-align: center;
    }
`;

const Sponsored = styled.div`
    h1 {
        display: flex;
        align-items: center;
        font-size: 26px;
        color: ${colors.white['100']};
        font-family: ${gilroyMedium};
    }
    img {
        display: inline;
        height: 60px;
        margin-left: 20px;
    }
    ${Media('xlscreens')} {
        position: absolute;
        bottom: 8vw;
        left: 68vw;
        h1 {
            font-size: 1.26vw;
        }
        img {
            height: 3.125vw;
            margin-left: 1.04vw;
        }
    }
    ${Media('tablet')} {
        h1 {
            font-size: 18px;
        }
        img {
            height: 40px;
        }
    }
`;

const IntroMainImg = styled.div`
    img {
        width: 788px;
    }
    bottom: -190px;
    transform: translateY(50vw);
    transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
    transition-delay: 1s;
    opacity: 0;
    ${(props) => {
        if (props.imgAnimate) {
            return `
                transform:translateY(0);
                opacity:1;
            `;
        }
    }}
    ${Media('xlscreens')} {
        position: absolute;
        img {
            width: 41vw;
        }
        bottom: -9.89vw;
    }
    ${Media('tablet')} {
        margin-top: 50px;
        img {
            width: 90%;
            margin: 0 auto;
        }
    }
`;

const IntroFooter = styled.div`
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    ${Media('xlscreens')} {
        position: absolute;
        padding: 2.08vw 8.85vw;
    }
    ${Media('tablet')} {
        position: relative;
        z-index: 99;
        padding: 20px;
        justify-content: center;
    }
`;

const NomineesLink = styled.div`
    a {
        font-size: 18px;
        font-family: ${akiraSuperBold};
        color: ${colors.pink['100']};
        display: flex;
        flex-direction: column;
        svg {
            margin-top: 30px;
        }
        ${Media('xlscreens')} {
            font-size: 0.98vw;
            svg {
                margin-top: 1.56vw;
            }
        }
    }
    ${Media('tablet')} {
        a {
            font-size: 14px;
            svg {
                margin-top: 0;
            }
            align-items: center;
        }
    }
`;

const CXAwardsIntro = styled.div`
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    position: relative;
    overflow: hidden;
    ${Media('xlscreens')} {
        display: flex;
    }
`;

const CXInnovators = styled.section`
    background-color: ${colors.socialRep['brandsBG']};
    position: relative;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${Media('xlscreens')} {
        padding-left: 8.85vw;
        padding-right: 8.85vw;
    }
    ${Media('tablet')} {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const Section2Content = styled.div`
    width: 100%;
`;

const BuiltForTxt = styled.div`
    font-size: 26px;
    color: ${colors.white['100']};
    text-decoration: underline;
    ${Media('xlscreens')} {
        font-size: 1.35vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
`;

const Section2Heading = styled.h2`
    font-size: 68px;
    font-family: ${gilroyMedium};
    color: ${colors.white['100']};
    text-decoration: underline;
    line-height: 1.45;
    margin-top: 50px;
    ${Media('xlscreens')} {
        font-size: 3.54vw;
        margin-top: 2.6vw;
    }
    ${Media('tablet')} {
        font-size: 36px;
        br {
            display: none;
        }
    }
`;

const AboutOrg = styled.div`
    margin-top: 180px;
    ${Media('xlscreens')} {
        margin-top: 7vw;
    }
    ${Media('tablet')} {
        margin-top: 70px;
    }
`;

const AboutOrgBlock = styled.div`
    margin-right: 100px;
    ${Media('xlscreens')} {
        margin-right: 5.2vw;
    }
    ${Media('tablet')} {
        margin-right: 0px;
        margin-bottom: 70px;
    }
`;

const AboutOrgTitle = styled.h3`
    color: ${colors.white['100']};
    font-size: 26px;
    font-family: ${gilroyBold};
    ${Media('xlscreens')} {
        font-size: 1.35vw;
    }
    ${Media('tablet')} {
        font-size: 20px;
    }
`;

const AboutOrgPara = styled.p`
    color: ${colors.white['100']};
    font-size: 21px;
    font-family: ${gilroyMedium};
    line-height: 1.9;
    ${Media('xlscreens')} {
        font-size: 1.09vw;
    }
    ${Media('tablet')} {
        font-size: 16px;
    }
`;

const PromotingHeading = styled.h3`
    text-align: center;
    color: ${colors.white['100']};
    margin-top: 260px;
    font-size: 42px;
    font-family: ${gilroyMedium};
    span {
        color: ${colors.pink['100']} !important;
    }
    ${Media('xlscreens')} {
        margin-top: 13.54vw;
        font-size: 2.18vw;
    }
    ${Media('tablet')} {
        margin-top: 30px;
        font-size: 26px;
    }
`;

const FeaturesList = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0px 150px;
    ${Media('xlscreens')} {
        padding: 0 7.8vw;
    }
    ${Media('tablet')} {
        flex-direction: column;
        padding: 0;
    }
`;

const Feature = styled.div`
    display: flex;
    align-items: center;
    margin-top: 75px;
    ${Media('xlscreens')} {
        margin-top: 3.9vw;
    }
    ${Media('tablet')} {
        margin-top: 30px;
    }
`;

const FeatImg = styled.div`
    img {
        max-height: 110px;
    }
    ${Media('xlscreens')} {
        img {
            max-height: 5.7vw;
        }
    }
    ${Media('tablet')} {
        img {
            width: 80px;
            max-height: 50px;
        }
    }
`;

const FeatTitle = styled.h2`
    font-size: 26px;
    font-family: ${gilroyBold};
    color: ${colors.white['100']};
    margin-left: 40px;
    ${Media('xlscreens')} {
        font-size: 1.35vw;
        margin-left: 2.08vw;
    }
    ${Media('tablet')} {
        font-size: 20px;
    }
`;

const GreyCircleBg = styled.div`
    height: 60vw;
    position: absolute;
    width: 70vw;
    background-color: ${colors.cx_awards['greyBg']};
    z-index: -1;
    border-bottom-left-radius: 50vw;
    border-bottom-right-radius: 50vw;
    top: 0;
    ${Media('tablet')} {
        width: 100%;
        left: 0;
    }
`;

const FloatingImg = styled.div`
    position: absolute;
    top: ${(props) => props.top};
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    left: ${(props) => props.left};
    right: ${(props) => props.right};
    bottom: ${(props) => props.bottom};
    ${Media('tablet')} {
        display: none;
    }
`;

const NomineesSection = styled.section`
    width: 100%;
    overflow: hidden;
    position: relative;
    ${Media('xlscreens')} {
        padding-left: 8.85vw;
        padding-right: 8.85vw;
    }
`;

const NomineeListTitle = styled.h3`
    font-size: 42px;
    font-family: ${gilroyMedium};
    color: ${colors.white['100']};
    text-decoration: underline;
    margin-top: 40px;
    ${Media('xlscreens')} {
        font-size: 2.18vw;
        margin-top: 2.08vw;
    }
    ${Media('tablet')} {
        font-size: 32px;
        text-align: center;
        margin-bottom: 50px;
    }
`;

const TableWrapper = styled.div`
    ${Media('tablet')} {
        width: 100%;
        padding: 0px 30px;
        overflow-x: scroll;
    }
    ${Media('mobile')} {
        padding: 0px 15px;
    }
`;

const NomineesTable = styled.table`
    td {
        padding: 10px 0px 10px 30px;
        color: ${colors.white['100']};
    }
    tbody {
        top: 50px;
    }
    ${Media('xlscreens')} {
        td {
            padding: 0.53vw 0 0.53vw 1.56vw;
        }
        tbody {
            top: 2.6vw;
        }
    }
    ${Media('mobile')} {
        td {
            padding: 10px 30px;
        }
    }
`;

const TableHeading = styled.td`
    background-color: ${colors.socialRep['brandsBG']};
    font-family: ${gilroySemibold};
    font-size: 22px;
    width: ${(props) => (props.colWidth ? props.colWidth + 'px' : '100px')};
    ${Media('xlscreens')} {
        font-size: 1.14vw;
        width: ${(props) =>
            props.colWidth ? props.colWidth / 19.2 + 'vw' : '100px'};
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
    ${Media('mobile')} {
        font-size: 16px;
    }
`;

const NomineeName = styled.p`
    font-family: ${gilroySemibold};
    font-size: 21px;
    margin: 0;
    color: ${colors.white['100']};
    ${Media('xlscreens')} {
        font-size: 1.09vw;
    }
    ${Media('tablet')} {
        font-size: 16px;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;

const NomineeCompany = styled.p`
    font-family: ${gilroySemibold};
    font-size: 16px;
    margin: 0;
    color: ${colors.white['100']};
    ${Media('xlscreens')} {
        font-size: 0.83vw;
    }
    ${Media('tablet')} {
        font-size: 14px;
    }
    ${Media('mobile')} {
        font-size: 12px;
    }
`;

const TableBodyData = styled.td`
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    ${Media('xlscreens')} {
        padding-top: 0.15vw;
        padding-bottom: 0.15vw;
    }
`;

const NominateSomeone = styled.section`
    padding: 70px 0;
    p {
        line-height: 1.64;
        font-size: 42px;
        font-family: ${gilroyMedium};
        margin: 0;
    }
    background-color: ${colors.fintech['bgColor']};
    text-align: center;
    ${Media('xlscreens')} {
        padding: 3.64vw 0;
        p {
            font-size: 2.18vw;
        }
    }
    ${Media('tablet')} {
        padding: 50px 10px;
        p {
            font-size: 20px;
            br {
                display: none;
            }
        }
    }
`;

const Announcement = styled.div`
    background-color: ${colors.white['100']};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    ${Media('xlscreens')} {
        padding-bottom: 5.2vw;
    }
    ${Media('tablet')} {
        padding: 0px 40px;
        padding-bottom: 50px;
    }
`;

const SponsoredDark = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
    h3 {
        font-size: 26px;
        font-family: ${gilroyMedium};
    }
    img {
        height: 50px;
    }
    ${Media('xlscreens')} {
        margin-top: 3.64vw;
        h3 {
            font-size: 1.26vw;
        }
        img {
            height: 2.6vw;
        }
    }
    ${Media('tablet')} {
        h3 {
            font-size: 18px;
        }
        img {
            height: 40px;
        }
    }
`;

const AnnouncementDate = styled.h5`
    font-size: 32px;
    font-family: ${gilroyMedium};
    text-align: center;
    ${Media('xlscreens')} {
        font-size: 1.66vw;
    }
    ${Media('tablet')} {
        font-size: 22px;
    }
`;

export default FintechAwards2024;
