import React from 'react';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';

export const Tagline = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.h5`
    ${Media('xlscreens')} {
        text-align: center;
        margin-top: 2vw;
        margin-bottom: 5vw;
    }
    ${Media('tablet')} {
        margin-top: 10px;
        margin-bottom: 15px;
    }
    ${Media('mobile')} {
        text-align: center;
    }
`;
