import React, { useRef, useEffect, useState } from 'react';
import Layout from 'components/layout';
import RetailIntro from 'components/retail/intro-section';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { baseURL } from 'shared-components/theme/helpers';
import { BigCircle } from 'components/industries/common';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { Link } from 'react-scroll';
import Button from 'shared-components/button/button';
import {
    gilroyMedium,
    gilroyBold,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import {
    Text,
    IndustryTag,
    IndustrySectionHeading,
    ContentCenter,
} from 'components/industries/common';
import { FlexColumnCentered } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';
import { LetsDiscuss, AmplifySection } from 'components/industries/common';

const stickyMenu = [
    {
        icon: `<svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.976"
        height="14.232"
        viewBox="0 0 18.976 14.232">
        <path
            id="Icon_awesome-concierge-bell"
            data-name="Icon awesome-concierge-bell"
            d="M10.674,6.966V6.279h.593a.593.593,0,0,0,.593-.593V5.093a.593.593,0,0,0-.593-.593H7.709a.593.593,0,0,0-.593.593v.593a.593.593,0,0,0,.593.593H8.3v.687a8.3,8.3,0,0,0-7.116,8.208h16.6A8.3,8.3,0,0,0,10.674,6.966Zm7.709,9.394H.593A.593.593,0,0,0,0,16.953v1.186a.593.593,0,0,0,.593.593h17.79a.593.593,0,0,0,.593-.593V16.953A.593.593,0,0,0,18.383,16.36Z"
            transform="translate(0 -4.5)"
            fill="#98a1b0"
        />
        </svg>`,
        title: 'New Connection Concierge',
        linkRef: 'everything-connected',
    },
    {
        icon: `<svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="14.875"
        viewBox="0 0 21 14.875">
        <path
            id="Icon_material-laptop-chromebook"
            data-name="Icon material-laptop-chromebook"
            d="M19.25,17.625V4.5H1.75V17.625H0v1.75H21v-1.75Zm-7,0H8.75V16.75h3.5ZM17.5,15H3.5V6.25h14Z"
            transform="translate(0 -4.5)"
            fill="#98a1b0"
        />
        </svg>`,
        title: 'Sparked Marketplace',
        linkRef: 'ecommerce-ecosystem',
    },
    {
        icon: ` <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.508"
        height="20.009"
        viewBox="0 0 17.508 20.009">
        <path
            id="Icon_awesome-share-alt"
            data-name="Icon awesome-share-alt"
            d="M13.756,12.506a3.735,3.735,0,0,0-2.337.817l-4.005-2.5a3.773,3.773,0,0,0,0-1.629l4.005-2.5a3.745,3.745,0,1,0-1.326-2.121l-4.005,2.5a3.752,3.752,0,1,0,0,5.87l4.005,2.5a3.752,3.752,0,1,0,3.663-2.937Z"
            fill="#98a1b0"
        />
        </svg>`,
        title: 'Mindshare Digital Outreach',
        linkRef: 'mindshare-section',
    },
    {
        icon: `<svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.875"
        height="14.875"
        viewBox="0 0 14.875 14.875">
        <path
            id="Icon_ionic-ios-checkbox"
            data-name="Icon ionic-ios-checkbox"
            d="M18.135,4.5H5.74A1.238,1.238,0,0,0,4.5,5.74v12.4a1.238,1.238,0,0,0,1.24,1.24h12.4a1.238,1.238,0,0,0,1.24-1.24V5.74A1.238,1.238,0,0,0,18.135,4.5ZM16.1,9.71l-5.179,5.2h0a.7.7,0,0,1-.449.213.678.678,0,0,1-.453-.221L7.847,12.735a.154.154,0,0,1,0-.221l.69-.69a.15.15,0,0,1,.217,0l1.72,1.72L15.2,8.784a.153.153,0,0,1,.108-.046h0a.141.141,0,0,1,.108.046l.678.7A.153.153,0,0,1,16.1,9.71Z"
            transform="translate(-4.5 -4.5)"
            fill="#98a1b0"
        />
        </svg>`,
        title: 'RefleCX VOC Surveys',
        linkRef: 'insight-driven',
    },
];

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -210,
    },
    {
        name: 'everything-connected',
        target: 'everything-connected',
        offset: -100,
    },
    {
        name: 'ecommerce-ecosystem',
        target: 'ecommerce-ecosystem',
        offset: -100,
    },
    {
        name: 'mindshare-section',
        target: 'mindshare-section',
        offset: -100,
    },
    {
        name: 'insight-driven',
        target: 'insight-driven',
        offset: -100,
    },
];

const utilityCXFeatures = [
    {
        icon: `${baseURL}assets/images/utilities/utility-cx-feat1.svg`,
        title: 'New Connection',
        btnRef: 'everything-connected',
        para: 'World class new customer onboarding experience for the modern utility just a click or call away.  Support new customers, improve utility program participation, optimize Beyond the Meter enrollment, enhance CSAT & build incremental revenue to invest for lifetime customer engagement.',
    },
    {
        icon: `${baseURL}assets/images/utilities/utility-cx-feat2.svg`,
        title: 'CliCX Utility Marketplace',
        btnRef: 'ecommerce-ecosystem',
        para: 'Turnkey utility marketplace stocked with an expanded array of products AND services to fit every utility customer’s needs.',
    },
    {
        icon: `${baseURL}assets/images/utilities/utility-cx-feat3.svg`,
        title: 'Mindshare Digital Outreach',
        btnRef: 'mindshare-section',
        para: 'Create continuous and meaningful mindshare with your customers with regular digital outreach campaigns to drive awareness, adoption, and lifetime value.',
    },
    {
        icon: `${baseURL}assets/images/utilities/utility-cx-feat4.svg`,
        title: 'RefleCX Voice of the Customer Surveys',
        btnRef: 'insight-driven',
        para: 'Build an ecosystem of digital listening posts across all milestones in your customer landscape for real time telemetry of your CX.',
    },
];
const newConnectionFeatures = [
    {
        icon: `${baseURL}assets/images/utilities/utility-new-connect-1.svg`,
        title: 'Unlock unlimited CX potential.',
        para: 'Call, Click, Chat – customers connect essential home services directly from your new energy start experience for added value.',
    },
    {
        icon: `${baseURL}assets/images/utilities/utility-new-connect-2.svg`,
        title: 'Keep customers in your digital ecosystem.',
        para: 'Don’t transfer customers to another brand’s ecosystem.  Establish your utility as the go-to source for all of your customers’ essential home service connections.',
    },
    {
        icon: `${baseURL}assets/images/utilities/utility-new-connect-3.svg`,
        title: 'Elevate the Customer Onboarding.',
        para: 'Introduce new customers to important utility programs and capture insights and interests vital to long term customer engagement that maximizes participation for Demand Response, Time of Use, Beyond the Meter, Benefical Electrification and More ...',
    },
];
const newConnectionFeatures2 = [
    {
        icon: `${baseURL}assets/images/utilities/icon-awesome-concierge-bell.svg`,
        title: 'Concierge Home Specialist Support',
        para: 'New Connection Program Specialists at ibex’s Utility Center of Excellence support your newest customers with the help of their move, to learn more about important utility programs, enroll in repair and protection plans, signup for news letters, and get the best start in their relationship with the utility. Its the onboarding experience of the Modern Utility CX.',
    },
    {
        icon: `${baseURL}assets/images/utilities/icon-material-call.svg`,
        title: 'All the Essentials',
        para: 'ibex New Connections provides all of the essential products AND services to deliver a world class experience for your customers, so that you can offer customers peace of mind that they have a great onboarding. Customers will get the latest home servics, the best offers, even exclusive valuesavailable just for being a member of the utility.',
    },
    {
        icon: `${baseURL}assets/images/utilities/icon-simple-ifixit.svg`,
        title: 'Beyond the Meter Support',
        para: 'Ibex supports all major utility Beyond the Meter partners for repair and protection plans, home automation services and energy effiency programs with little or no integration costs. If you donot offer a current program, we can bring a complete catalog of repair and protection plans to help your customers protect against unexpected home repair costs. Our Service Plan program enjoys 2x-4x customer paticipation with top quality and CSAT scores compared.',
    },
];

const ecommerceList1 = [
    {
        icon: `${baseURL}assets/images/utilities/connect.svg`,
        title: 'Connect.',
        para: 'Help your customers find the best plans and save money on Internet, TV, Wireless and Home Security plans from all of the major brands, instantly & securely.',
    },
    {
        icon: `${baseURL}assets/images/utilities/protect.svg`,
        title: 'Protect.',
        para: 'Customized catalogs including essential repair and protection plans, total home security plans, and contractor services, offer customers the plans they need to protect their home and families.',
    },
    {
        icon: `${baseURL}assets/images/utilities/enjoy.svg`,
        title: 'Enjoy.',
        para: 'All of the home entertainment services and connections your customers need to make their smart home fun and enjoyable.',
    },
    {
        icon: `${baseURL}assets/images/utilities/engage.svg`,
        title: 'Engage.',
        para: 'Supported by informative content, personalized outreach, and trackable campaigns, your marketplace expands the opportunity for digital dialogue with your customers.',
    },
];

const meetList = [
    {
        title: 'Your Complete Online Product Catalog',
        para: 'Wide array of product and service selection enables utilities to offer their own service plan catalog in a modern digital marketplace alongside the essential products utility customers demand.',
    },
    {
        title: 'Gather Customer Feedback',
        para: 'All marketplace solutions include QuickPulse support for real time telemetry on the digital customer experience so that utility partners have early insight into CSAT and customer trends',
    },
    {
        title: 'Launch Ready',
        para: 'Turnkey solution delivers an eComerce, branded marketplace with custom outreach options in weeks, with a professional onboarding experience preparing utility partners with catalog options, reporting and analysis, and ongoing customer insights.',
    },
    {
        title: 'Showcase Products & Services',
        para: 'Expanded catalog of both products AND services ensures choices for all segments of utility customers with value-add bundles available that offers exclusive choice to customers they can only find on your branded experience.',
    },
    {
        title: 'Self Funding',
        para: 'Your marketplace should not be a cost center.  ibex partners with utilities to increase customer outreach and support your marketplace for “escape velocity” to achieve self-funding through revenue share and bundling programs.',
    },
];

const mindshareList = [
    {
        icon: `${baseURL}assets/images/utilities/dart.svg`,
        title: 'Match the Message to your Customer.',
        para: 'Your customers have a variety of needs and challenges. With our behavior scoring and customer personas, we craft custom campaigns to deliver the right message to the right customer at the right time in their channel of choice.',
    },
    {
        icon: `${baseURL}assets/images/utilities/chain.svg`,
        title: 'Find Customers in their Channel of Choice.',
        para: 'Email, Social, and SMS create opportunities for utilities to leapfrog the digital divide and connect ongoing with customers in the personalized manner modern consumers expect.',
    },
    {
        icon: `${baseURL}assets/images/utilities/connection.svg`,
        title: 'Develop Vital Lifecycle Nurturing Programs.',
        para: 'We manage and deploy email, social, content, and search campaigns personalized across more than a dozen key moments in the customer journey. We will help you develop strategic omnichannel digital marketing campaigns and content calendars ensuring an',
    },
    {
        icon: `${baseURL}assets/images/utilities/hand-shake.svg`,
        title: 'Create Continuous LTV.',
        para: 'With highly effective channel messaging, regular content development, and an optimal outreach cadence, you will unlock a new stream of engagement as customers build deeper trust in your product offering and brand experience.',
    },
];

const amplifyList = [
    {
        icon: `${baseURL}assets/images/industries/rocket.svg`,
        title: 'Fast.',
        para: `We ensure your teams are prepared to hit key KPIs quickly and consistently, ensuring there’s no gap in output once ibex is switched on. `,
    },
    {
        icon: `${baseURL}assets/images/industries/icon-awesome-check.svg`,
        title: 'Flawless.',
        para: `Our teams have in-depth experience working with leading Utility, eCommerce, and Technology brands around the globe, and they’re ready to deploy this expertise for you. `,
    },
    {
        icon: `${baseURL}assets/images/industries/chameleon.svg`,
        title: 'Flexible.',
        para: `We are able to ramp and deploy technology and teams to match your project goals, leveraging dynamic onshore, nearshore, and offshore CX teams.  `,
    },
    {
        icon: `${baseURL}assets/images/industries/crystal-ball.svg`,
        title: 'Predictable.',
        para: `Anyone can do well on a single program, but delivering consistent performance outcomes  requires a battle-tested methodology for repeatable, predictable, and reliable program execution that you can set your watch to. `,
    },
];

const InsightFeatList = [
    {
        icon: `${baseURL}assets/images/utilities/check-circle.svg`,
        title: 'Specialized survey engagement for utility customer journeys',
        img: `${baseURL}assets/images/utilities/utility-insight-1.svg`,
    },
    {
        icon: `${baseURL}assets/images/utilities/check-circle.svg`,
        title: 'Enables actionable intelligence about specific customer interactions to focus investment and resources',
        img: `${baseURL}assets/images/utilities/utility-insight-2.svg`,
    },
    {
        icon: `${baseURL}assets/images/utilities/check-circle.svg`,
        title: 'Delivers customer insights instantly for immediate feedback',
        img: `${baseURL}assets/images/utilities/utility-insight-3.svg`,
    },
];

const Utilities = () => {
    const [showStickyMenu, setShowStickyMenu] = useState(false);
    const firstSection = useRef(null);
    const lastSection = useRef(null);

    useEffect(() => {
        window.onscroll = function () {
            var elemOffset =
                firstSection.current.offsetTop +
                document.body.getBoundingClientRect().top;
            var lastElem =
                lastSection.current.offsetTop +
                document.body.getBoundingClientRect().top;
            if (elemOffset <= 100 && !(lastElem <= 180)) {
                setShowStickyMenu(true);
            } else {
                setShowStickyMenu(false);
            }
        };
    }, []);

    return (
        <Layout>
            <StickyMenuWrapper showStickyMenu={showStickyMenu}>
                <StickyMenu>
                    <StickyMenuItem
                        bgColor={colors.white['100']}
                        borderLeft
                        menuTitle>
                        <MenuTitle>Modernize your utility cx</MenuTitle>
                        <MenuIcon>
                            <img
                                src={`${baseURL}assets/images/icon-ionic-ios-arrow-forward.svg`}
                                alt="-"
                            />
                        </MenuIcon>
                    </StickyMenuItem>
                    {stickyMenu &&
                        stickyMenu.length > 0 &&
                        stickyMenu.map((menu, index) => {
                            return (
                                <StickyMenuItem key={index}>
                                    <Link
                                        to={menu.linkRef}
                                        spy={true}
                                        smooth={true}
                                        activeClass="active"
                                        offset={-100}
                                        duration={1000}>
                                        <MenuTitle>
                                            {ReactHtmlParser(menu.icon)}
                                            {menu.title}
                                        </MenuTitle>
                                    </Link>
                                </StickyMenuItem>
                            );
                        })}
                </StickyMenu>
            </StickyMenuWrapper>

            <ScrollNav data={scrollNavData} />
            <IntroWrapper>
                <Breadcrumbs>
                    <li>Industry</li>
                    <li>Utilities</li>
                </Breadcrumbs>
                <RetailIntro
                    rightImg={`${baseURL}assets/images/utilities/utilities-intro.png`}
                    tag="UTILITIES"
                    introHeading="Expand Your <br/>Utility CX"
                    introPara="Advanced digital solutions to <br/>transform your customer experience"
                    introBtnText="Show Me"
                    right="-8%"
                    top="-26.3vw"
                    id="home"
                    bigTag
                />
            </IntroWrapper>
            <BigCircle
                imgUrl={`${baseURL}assets/images/utilities/icon-open-bolt.svg`}
                top="15vw"
                imgHeight={750}
                zIndex={-1}
                right="40vw"
            />
            <ModernizeUtitliyCX>
                <Container>
                    <div>
                        <SectionInfo>
                            <ContentCenter>
                                <IndustryTag bigText>
                                    Modernize your utility cx
                                </IndustryTag>
                                <IndustrySectionHeading bigText>
                                    Customer Experience Solutions&nbsp;
                                    <br />
                                    for the Entire Utility Customer Lifecycle
                                </IndustrySectionHeading>
                                <Text bigText lineHeight="1.45">
                                    Purpose built solutions to modernize utility
                                    CX programs from the critical first customer
                                    experience, to customer onboarding for
                                    essential utility programs and Beyond the
                                    Meter offers, optimize customer service and
                                    support, upgrade a "whole wallet" approach
                                    to billing and collections, and capture
                                    actionable insights from milestones along
                                    the customer journey.
                                </Text>
                            </ContentCenter>
                        </SectionInfo>
                    </div>
                </Container>
                <UtilityCXFeatures fluid={true}>
                    <Row>
                        {utilityCXFeatures &&
                            utilityCXFeatures.length > 0 &&
                            utilityCXFeatures.map((feat, index) => {
                                return (
                                    <Col lg="3" key={index}>
                                        <div>
                                            <FeatBox>
                                                <FlexColumnCentered
                                                    style={{ height: '100%' }}>
                                                    <img
                                                        src={feat.icon}
                                                        alt="-"
                                                    />
                                                    <h4>
                                                        {ReactHtmlParser(
                                                            feat.title
                                                        )}
                                                    </h4>
                                                    <p>{feat.para}</p>
                                                    <Link
                                                        to={feat.btnRef}
                                                        spy={true}
                                                        offset={-100}
                                                        smooth={true}
                                                        duration={1000}>
                                                        <Button round ifButton>
                                                            Show Me
                                                        </Button>
                                                    </Link>
                                                </FlexColumnCentered>
                                            </FeatBox>
                                        </div>
                                    </Col>
                                );
                            })}
                    </Row>
                </UtilityCXFeatures>
            </ModernizeUtitliyCX>
            <NewConnection id="everything-connected" ref={firstSection}>
                <Container>
                    <div>
                        <SectionInfo>
                            <ContentCenter>
                                <IndustryTag bigText>
                                    Everything, connected.
                                </IndustryTag>
                                <IndustrySectionHeading bigText>
                                    New Connection
                                </IndustrySectionHeading>
                                <Text bigText lineHeight="1.45">
                                    A customer’s first experience with your
                                    brand sets the tone for the entire
                                    relationship. ibex proves with each <br />
                                    new energy start that Utilities understand
                                    and value their customers.
                                </Text>
                            </ContentCenter>
                        </SectionInfo>
                    </div>
                    <Row>
                        {newConnectionFeatures &&
                            newConnectionFeatures.length > 0 &&
                            newConnectionFeatures.map((feat, index) => {
                                return (
                                    <Col lg="4" key={index}>
                                        <div>
                                            <FeatBox newConnect>
                                                <FlexColumnCentered>
                                                    <img
                                                        src={feat.icon}
                                                        alt="-"
                                                    />
                                                    <h4>{feat.title}</h4>
                                                    <p>{feat.para}</p>
                                                </FlexColumnCentered>
                                            </FeatBox>
                                        </div>
                                    </Col>
                                );
                            })}
                    </Row>
                    <NewConnectFeat2>
                        <Row>
                            <Col lg="6">
                                <NewConnectFeatImg>
                                    <img
                                        src={`${baseURL}assets/images/utilities/new-connect-feat.jpg`}
                                        alt="-"
                                    />
                                </NewConnectFeatImg>
                            </Col>
                            <Col lg="6">
                                {newConnectionFeatures2 &&
                                    newConnectionFeatures2.length > 0 &&
                                    newConnectionFeatures2.map(
                                        (feat, index) => {
                                            return (
                                                <div
                                                    key={index}>
                                                    <NewConnectFeat>
                                                        <img
                                                            src={feat.icon}
                                                            alt="-"
                                                        />
                                                        <NewConnectInfo>
                                                            <h4>
                                                                {feat.title}
                                                            </h4>
                                                            <p>{feat.para}</p>
                                                        </NewConnectInfo>
                                                    </NewConnectFeat>
                                                </div>
                                            );
                                        }
                                    )}
                            </Col>
                        </Row>
                    </NewConnectFeat2>
                    <div>
                        <Qoute>
                            <p>
                                Customers get a comprehensive value-add
                                experience and the Utility gets credit <br />
                                for providing the special assistance.
                                That&apos;s a New Connection experience
                                that&nbsp;
                                <br />
                                amplifies the value of your brand after the move
                                and beyond the meter.
                            </p>
                            <img
                                src={
                                    baseURL +
                                    'assets/images/ebook/chevron-down.svg'
                                }
                                alt="-"
                            />
                        </Qoute>
                    </div>
                </Container>
            </NewConnection>
            <EcommerceSystem id="ecommerce-ecosystem">
                <Container>
                    <div>
                        <SectionInfo>
                            <ContentCenter>
                                <IndustryTag bigText>
                                    eCommerce ecosystem
                                </IndustryTag>
                                <IndustrySectionHeading bigText>
                                    Modern Digital Marketplace for Utilities
                                </IndustrySectionHeading>
                                <Text bigText lineHeight="1.45">
                                    With more consumers than ever using
                                    self-service options online, a fresh,
                                    modern, and user-friendly storefront <br />
                                    is not just a nice feature; it is critical
                                    for building continual customer engagement.
                                </Text>
                            </ContentCenter>
                        </SectionInfo>
                    </div>
                    <Row>
                        {ecommerceList1 &&
                            ecommerceList1.length > 0 &&
                            ecommerceList1.map((item, index) => {
                                return (
                                    <Col lg="3" md="6" key={index}>
                                        <div>
                                            <AmplifyBox>
                                                <AmplifyIcon>
                                                    <img
                                                        src={item.icon}
                                                        alt="-"
                                                    />
                                                </AmplifyIcon>
                                                <AmplifyTitle>
                                                    {item.title}
                                                </AmplifyTitle>
                                                <AmplifyPara>
                                                    {ReactHtmlParser(item.para)}
                                                </AmplifyPara>
                                            </AmplifyBox>
                                        </div>
                                    </Col>
                                );
                            })}
                    </Row>
                    <MeetSectionHeading>
                        Meet your CliCX Marketplace
                    </MeetSectionHeading>
                    <Row>
                        <Col lg="4">
                            <div>
                                <MeetImg>
                                    <img
                                        src={`${baseURL}assets/images/utilities/meet-digital-marketplace.png`}
                                        alt="-"
                                    />
                                </MeetImg>
                            </div>
                        </Col>
                        {meetList &&
                            meetList.length > 0 &&
                            meetList.map((meetitem, index) => {
                                return (
                                    <Col lg="4" key={index}>
                                        <div>
                                            <DigitalMarketPlace>
                                                <h5>{meetitem.title}</h5>
                                                <p>{meetitem.para}</p>
                                            </DigitalMarketPlace>
                                        </div>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </EcommerceSystem>
            <MindshareSection id="mindshare-section">
                <Container>
                    <div>
                        <SectionInfo>
                            <ContentCenter>
                                <IndustryTag bigText>
                                    Mindshare means no sharing
                                </IndustryTag>
                                <IndustrySectionHeading bigText>
                                    Mindshare Digital Outreach
                                </IndustrySectionHeading>
                                <Text bigText lineHeight="1.45">
                                    ibex offers a full-service digital agency
                                    complete with a utility-focused persona
                                    framework designed to connect, inform,{' '}
                                    <br />
                                    and excite customers across their channel of
                                    choice. We harness expertise gleaned from
                                    work across all major industries <br />
                                    to help modern utilities cross the digital
                                    divide and connect with customers across the
                                    entire lifecycle.
                                </Text>
                            </ContentCenter>
                        </SectionInfo>
                    </div>
                    <Row>
                        {mindshareList &&
                            mindshareList.length > 0 &&
                            mindshareList.map((item, index) => {
                                return (
                                    <Col lg="3" md="6" key={index}>
                                        <div>
                                            <AmplifyBox>
                                                <AmplifyIcon>
                                                    <img
                                                        src={item.icon}
                                                        alt="-"
                                                    />
                                                </AmplifyIcon>
                                                <AmplifyTitle>
                                                    {item.title}
                                                </AmplifyTitle>
                                                <AmplifyPara>
                                                    {ReactHtmlParser(item.para)}
                                                </AmplifyPara>
                                            </AmplifyBox>
                                        </div>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </MindshareSection>
            <InsightDriven id="insight-driven">
                <ReflexCXImg>
                    <img
                        src={`${baseURL}assets/images/utilities/utilities-reflecx.jpg`}
                        alt="-"
                    />
                </ReflexCXImg>
                <Container>
                    <div>
                        <SectionInfo>
                            <ContentCenter>
                                <IndustryTag bigText>
                                    Insight Driven
                                </IndustryTag>
                                <IndustrySectionHeading bigText>
                                    RefleCX: CX as a Service
                                </IndustrySectionHeading>
                                <Text bigText lineHeight="1.45">
                                    Revitalize your Voice of the Customer
                                    program with enterprise class ”CX as a
                                    Service”. RefleCX enables SMS, email and web
                                    surveys for virtually every customer
                                    interaction across the utility lifecycle.
                                    Capture feedback from customers on their New
                                    Connection experience for a glimpse into
                                    future CSAT scores. Survey customer insights
                                    about their online bill pay experience to
                                    drive up adoption. Deliver surveys to the
                                    smartphone or inbox following&nbsp; a
                                    billing or outage inquiry and follow up
                                    immediately on informal customer escalations
                                    or issues.
                                </Text>
                            </ContentCenter>
                        </SectionInfo>
                    </div>
                    <Row>
                        {InsightFeatList &&
                            InsightFeatList.length > 0 &&
                            InsightFeatList.map((feat, index) => {
                                return (
                                    <Col lg="4" key={index}>
                                        <div>
                                            <InsightFeat>
                                                <InsightIcon>
                                                    <img
                                                        src={feat.icon}
                                                        alt="-"
                                                    />
                                                </InsightIcon>
                                                <InsightImg>
                                                    <img
                                                        src={feat.img}
                                                        alt="-"
                                                    />
                                                </InsightImg>
                                                <InsightTitle>
                                                    {feat.title}
                                                </InsightTitle>
                                            </InsightFeat>
                                        </div>
                                    </Col>
                                );
                            })}
                    </Row>
                    <div>
                        <Qoute>
                            <p>
                                Customers now have a lot of new ways to express
                                themselves, and VoC programs that ignore social
                                media feedback risk being “tone deaf” to their
                                customer’s voices. RefleCX Social Circles
                                programs gather insights from your customers’
                                social posts, as well as compare performance
                                among your peers. Social is a new frontier, so
                                tackle it with an experienced partner.
                                <span>
                                    Mark Wilkinson, SVP Products, ibex Digital
                                </span>
                            </p>
                            <img
                                src={
                                    baseURL +
                                    'assets/images/ebook/chevron-down.svg'
                                }
                                alt="-"
                            />
                        </Qoute>
                    </div>
                </Container>
            </InsightDriven>
            <AmplifySectionWrapper ref={lastSection}>
                <AmplifySection
                    amplifyList={amplifyList}
                    tag="PEOPLE. TECHNOLOGY. SUCCESS."
                    heading="At ibex, we see ourselves as a meaningful <br/>extension of your Utility brand."
                    para="We believe in brand stewardship, CX evolution, and engagement - our goal <br/>
                    is to elevate your brand, set you up for success, and revolutionize <br/>
                    the way you connect with your customers."
                />
            </AmplifySectionWrapper>
            <LetsDiscuss
                heading="Looking to level-up your Utility CX?"
                para="Let’s discuss your next move."
                btnText="Talk to us"
            />
        </Layout>
    );
};
const AmplifySectionWrapper = styled.div``;
const IntroWrapper = styled.div`
    ${Media('xlscreens')} {
        margin-top: -3.38vw;
    }
`;
const StickyMenu = styled.div`
    background-color: ${colors.gray_lightest['50']};
    width: 85%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 1px solid ${colors.gray_lightest['50']};
    ${Media('xlscreens')} {
        border-bottom-left-radius: 0.78vw;
        border-bottom-right-radius: 0.78vw;
    }
`;
const StickyMenuWrapper = styled.div`
    position: sticky;
    top: 101px;
    z-index: 99;
    height: 65px;
    width: 100%;
    // transform: translateY(-70px);
    transition: all 0.3s ease;
    ${(props) => {
        if (props.showStickyMenu) {
            return `
                transform: translateY(0px);
                ${StickyMenu}{
                    box-shadow: 0px 0px 20px ${colors.black['10']};
                }
            `;
        }
    }}
    ${Media('xlscreens')} {
        top: 5.26vw;
        height: 3.38vw;
    }
    ${Media('tablet')} {
        display: none;
    }
`;
const MenuTitle = styled.h3`
    font-size: 17px;
    font-family: ${gilroySemibold};
    flex: 1;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        margin-right: 15px;
    }
    ${Media('xlscreens')} {
        font-size: 0.88vw;
        svg {
            margin-right: 0.78vw;
        }
    }
`;
const MenuIcon = styled.div`
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid ${colors.gray_lightest['50']};
    height: 100%;
    img {
        height: 15px;
    }
    ${Media('xlscreens')} {
        width: 3.64vw;
        img {
            height: 0.78vw;
        }
    }
`;
const StickyMenuItem = styled.div`
    width: 20%;
    background-color: ${(props) => props.bgColor && colors.white['100']};
    border-bottom-left-radius: ${(props) => props.borderLeft && '15px'};
    position: relative;
    display: flex;
    align-items: center;
    cursor: ${(props) => !props.menuTitle && 'pointer'};
    justify-content: ${(props) =>
        props.menuTitle ? 'space-between' : 'center'};
    a {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
            position: absolute;
            content: '';
            bottom: 0;
            height: 1px;
            width: 0;
            background-color: ${colors.pink['100']};
            transition: all 0.3s ease;
        }
        &.active {
            &:after {
                width: 80%;
            }
            ${MenuTitle} {
                svg path {
                    fill: ${colors.pink['100']};
                }
            }
        }
        &:hover {
            &:after {
                width: 80%;
            }
            ${MenuTitle} {
                svg path {
                    fill: ${colors.pink['100']};
                }
            }
        }
    }
    ${Media('xlscreens')} {
        border-bottom-left-radius: ${(props) => props.borderLeft && '0.78vw'};
    }
`;

const ModernizeUtitliyCX = styled.div`
    margin-top: 180px;
    ${Media('xlscreens')} {
        margin-top: 9.37vw;
    }
    ${Media('tablet')} {
        margin-top: 70px;
    }
`;
const UtilityCXFeatures = styled(Container)`
    ${Media('xlscreens')} {
        padding: 0vw 7vw;
    }
    ${Media('tablet')} {
        max-width: 720px;
    }
`;
const FeatBox = styled.div`
    padding: ${(props) => (props.newConnect ? '90px' : '70px 50px')};
    background-color: ${colors.white['100']};
    height: ${(props) => (props.newConnect ? '555px' : '640px')};
    box-shadow: 0px 0px 10px ${colors.black['3']};
    img {
        max-width: 100px;
        max-height: 87px;
    }
    h4 {
        text-align: center;
        font-size: 24px;
        font-family: ${gilroySemibold};
        margin: 50px 0;
        sup {
            font-size: 16px;
            font-family: ${gilroyBold};
        }
    }
    p {
        text-align: center;
        font-size: 20px;
        font-family: ${gilroyMedium};
        margin-top: 0;
        min-height: ${(props) => !props.newConnect && '200px'};
    }
    button {
        background-color: transparent;
        border-color: ${colors.pink['100']};
        color: ${colors.black['100']};
        font-size: 18px;
    }
    a {
        margin-top: auto;
    }
    ${Media('xlscreens')} {
        padding: ${(props) => (props.newConnect ? '4.68vw' : '2vw 2.6vw')};
        height: ${(props) => (props.newConnect ? '34vw' : '38.33vw')};
        img {
            max-width: 5.2vw;
            max-height: 4.5vw;
        }
        h4 {
            font-size: 1.25vw;
            margin-top: 2vw;
            margin-bottom: 0;
            min-height: 5vw;
            sup {
                font-size: 0.83vw;
            }
        }
        p {
            font-size: 0.93vw;
            min-height: ${(props) => !props.newConnect && '12.41vw'};
        }
        button {
            font-size: 0.93vw;
            padding: 0.6vw 3vw;
        }
    }
    ${Media('tablet')} {
        height: auto;
        margin-bottom: 50px;
        p {
            min-height: auto;
            margin-bottom: 50px;
            font-size: 16px;
        }
        h4 {
            font-size: 22px;
        }
        button {
            font-size: 16px;
        }
    }
    ${Media('mobile')} {
        padding: 50px 30px;
        margin-bottom: 30px;
        img {
            max-width: 80px;
            max-height: 60px;
        }
        h4 {
            font-size: 20px;
        }
        p {
            font-size: 14px;
        }
    }
`;
const NewConnection = styled.section``;

const NewConnectFeat2 = styled.div`
    margin-top: 130px;
    ${Media('xlscreens')} {
        margin-top: 6.77vw;
    }
    ${Media('tablet')} {
        margin-top: 50px;
    }
    ${Media('mobile')} {
        margin-top: 30px;
    }
`;
const NewConnectFeatImg = styled.div`
    img {
        width: 100%;
    }
    ${Media('xlscreens')} {
        img {
            height: 39vw;
            object-fit: cover;
            border-radius: 0.53vw;
        }
    }
    ${Media('tablet')} {
        img {
            margin-bottom: 50px;
        }
    }
`;

const NewConnectFeat = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 0 35px;
    margin: 40px 0;
    img {
        position: relative;
        top: 15px;
        max-height: 27px;
        max-width: 30px;
    }
    ${Media('xlscreens')} {
        padding: 0 1.822vw;
        margin: 2.08vw 0;
        img {
            top: 0.78vw;
            max-height: 1.4vw;
            max-width: 1.56vw;
        }
    }
    ${Media('tablet')} {
        img {
            max-height: 24px;
            max-width: 24px;
        }
    }
    ${Media('mobile')} {
        padding: 0;
    }
`;

const NewConnectInfo = styled.div`
    margin-left: 30px;
    h4 {
        margin: 0;
        font-size: 36px;
        font-family: ${gilroySemibold};
        letter-spacing: -1.4px;
    }
    p {
        font-size: 18px;
        font-family: ${gilroyMedium};
        line-height: 1.55;
    }
    ${Media('xlscreens')} {
        margin-left: 1.56vw;
        h4 {
            font-size: 1.87vw;
        }
        p {
            font-size: 0.93vw;
        }
    }
    ${Media('tablet')} {
        h4 {
            font-size: 26px;
        }
        p {
            font-size: 16px;
        }
    }
    ${Media('mobile')} {
        margin-left: 20px;
        h4 {
            font-size: 22px;
        }
        p {
            font-size: 14px;
        }
    }
`;
const SectionInfo = styled.div`
    h2 {
        margin: 30px 0;
    }
    ${Media('xlscreens')} {
        h2 {
            margin: 1.56vw 0;
        }
    }
`;
const Qoute = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    p {
        font-size: 30px;
        font-family: ${gilroyMedium};
        line-height: 1.7;
        span {
            display: block;
            margin-top: 30px;
            font-size: 20px;
            color: ${colors.pink['100']};
            font-family: ${gilroySemibold};
        }
    }
    img {
        width: 23px;
        margin-top: 30px;
    }
    ${Media('xlscreens')} {
        margin-top: 5.2vw;
        p {
            font-size: 1.56vw;
            span {
                margin-top: 1.56vw;
                font-size: 0.93vw;
            }
        }
        img {
            width: 1.19vw;
            margin-top: 1.56vw;
        }
    }
    ${Media('tablet')} {
        margin-top: 50px;
        p {
            font-size: 20px;
            br {
                display: none;
            }
            span {
                font-size: 18px;
            }
        }
        img {
            width: 16px;
        }
    }
    ${Media('mobile')} {
        p {
            font-size: 18px;
            span {
                font-size: 16px;
            }
        }
        img {
            width: 14px;
        }
    }
`;
const EcommerceSystem = styled.section`
    background-color: ${colors.black['3']};
`;

const AmplifyBox = styled.div`
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    ${Media('xlscreens')} {
        margin: 1.56vw 0;
    }
    ${Media('tablet')} {
        margin: 0;
        margin-top: 50px;
    }
`;
const AmplifyIcon = styled.div`
    img {
        height: 26px;
    }
    ${Media('xlscreens')} {
        img {
            height: 1.66vw;
        }
    }
`;
const AmplifyTitle = styled.h3`
    color: ${colors.black['100']};
    font-size: 22px;
    font-family: ${gilroySemibold};
    margin: 30px 0;
    ${Media('xlscreens')} {
        font-size: 1.25vw;
        margin: 0;
        min-height: 6vw;
        margin-top: 3.64vw;
        padding: 0 1.56vw;
    }
`;
const AmplifyPara = styled.p`
    margin-top: 0;
    color: ${colors.black['100']};
    font-size: 14px;
    font-family: ${gilroyMedium};
    ${Media('xlscreens')} {
        font-size: 0.93vw;
        max-width: 80%;
    }
    ${Media('tablet')} {
        padding: 0px 20px;
        br {
            display: none;
        }
    }
`;
const MeetImg = styled.div`
    img {
        width: 95%;
    }
    ${Media('tablet')} {
        img {
            width: 100%;
            margin-bottom: 70px;
        }
    }
    ${Media('mobile')} {
        img {
            margin-bottom: 50px;
        }
    }
`;
const MeetSectionHeading = styled.h2`
    font-size: 50px;
    font-family: ${gilroySemibold};
    text-align: center;
    margin-top: 80px;
    margin-bottom: 120px;
    ${Media('xlscreens')} {
        font-size: 2.66vw;
        margin-top: 4.166vw;
        margin-bottom: 6.25vw;
    }
    ${Media('tablet')} {
        font-size: 34px;
        margin-bottom: 80px;
    }
    ${Media('mobile')} {
        font-size: 26px;
        margin-bottom: 50px;
    }
`;
const DigitalMarketPlace = styled.div`
    margin-bottom: 80px;
    padding: 0 20px;
    h5 {
        font-size: 28px;
        font-family: ${gilroyMedium};
        margin-top: 0;
        margin-bottom: 30px;
    }
    p {
        font-size: 20px;
        font-family: ${gilroyMedium};
    }
    ${Media('xlscreens')} {
        margin-bottom: 4.166vw;
        padding: 0 1.04vw;
        h5 {
            font-size: 1.45vw;
            margin-bottom: 1.56vw;
        }
        p {
            font-size: 0.93vw;
        }
    }
    ${Media('tablet')} {
        h5 {
            font-size: 24px;
        }
        p {
            font-size: 16px;
            letter-spacing: 0;
        }
    }
    ${Media('mobile')} {
        padding: 0;
        margin-bottom: 50px;
        h5 {
            font-size: 20px;
        }
        p {
            font-size: 14px;
        }
    }
`;
const MindshareSection = styled.section``;

const ReflexCXImg = styled.div`
    img {
        height: 575px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 5vw;
        img {
            height: 29.94vw;
        }
    }
    ${Media('tablet')} {
        margin-bottom: 60px;
        img {
            width: 100%;
            height: auto;
        }
    }
`;
const InsightDriven = styled.section`
    padding-top: 0;
`;
const InsightFeat = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    margin-top: 30px;
    ${Media('xlscreens')} {
        padding: 0 1.04vw;
        margin-top: 1.56vw;
    }
    ${Media('tablet')} {
        margin-top: 70px;
    }
    ${Media('mobile')} {
        margin-top: 50px;
    }
`;
const InsightIcon = styled.div`
    img {
        height: 80px;
        width: 80px;
        margin-bottom: 40px;
    }
    ${Media('xlscreens')} {
        img {
            height: 4.166vw;
            width: 4.166vw;
            margin-bottom: 2.08vw;
        }
    }
    ${Media('tablet')} {
        img {
            height: 50px;
            width: 50px;
        }
    }
    ${Media('mobile')} {
        img {
            height: 40px;
            width: 40px;
        }
    }
`;
const InsightImg = styled.div`
    img {
        max-height: 220px;
        width: 200px;
    }
    ${Media('xlscreens')} {
        img {
            max-height: 11.45vw;
            width: 10.41vw;
        }
    }
    ${Media('tablet')} {
        img {
            max-height: 150px;
            width: 120px;
        }
    }
    ${Media('tablet')} {
        img {
            max-height: 120px;
            width: 90px;
        }
    }
`;
const InsightTitle = styled.h4`
    text-align: center;
    font-size: 26px;
    font-family: ${gilroySemibold};
    line-height: 1.5;
    letter-spacing: -1px;
    ${Media('xlscreens')} {
        font-size: 1.35vw;
    }
    ${Media('tablet')} {
        font-size: 22px;
    }
    ${Media('mobile')} {
        font-size: 18px;
        letter-spacing: 0;
    }
`;
export default Utilities;
