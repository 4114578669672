import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import { gilroySemibold, gilroyMedium } from 'shared-components/theme/fonts';
import colors from 'shared-components/theme/colors';
import { Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';

const CXFeedbackBox = ({
    children,
    image,
    title,
    description,
    bgColor,
    image_mobile,
}) => {
    return (
        <div>
            <Wrapper image={image} bgColor={bgColor} img_mobile={image_mobile}>
                <FeedbackText>
                    <Row>
                        <Col lg="6">
                            <h2>{title}</h2>
                            <p>{description}</p>
                        </Col>
                    </Row>
                    {children}
                </FeedbackText>
            </Wrapper>
        </div>
    );
};

const Wrapper = styled.div`
    background-color: ${(props) =>
        props.bgColor ? props.bgColor : colors.gray_darker['100']};
    background-image: url(${(props) => props.image});
    background-position: right center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    min-height: 400px;
    border: 1px solid ${colors.white['10']};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    ${Media('xlscreens')} {
        min-height: 25vw;
        margin-top: 3vw;
    }
    ${Media('tablet')} {
        padding-top: 80%;
        background-size: 100% auto;
        background-position: top center;
        background-image: url(${(props) =>
            props.img_mobile ? props.img_mobile : props.image});
    }
`;

const FeedbackText = styled.div`
    padding-left: 100px;
    padding-top: 30px;
    padding-bottom: 30px;
    h2,
    h4,
    p {
        color: ${colors.white['100']};
    }
    h2 {
        font-family: ${gilroySemibold};
        margin-bottom: 30px;
        font-size: 38px;
        letter-spacing: -1px;
        margin-top: 0px;
    }
    h4 {
        font-family: ${gilroySemibold};
        margin-bottom: 30px;
        font-size: 17px;
    }
    p {
        font-family: ${gilroyMedium};
        margin: 0px;
        font-size: 16px;
        max-width: 95%;
        line-height: 1.8;
    }
    ${Media('xlscreens')} {
        padding-left: 8vw;
        padding-top: 4vw;
        padding-bottom: 4vw;
        h2 {
            font-size: 2vw;
            margin-bottom: 2vw;
        }
        h4 {
            font-size: 0.95vw;
            margin-bottom: 1vw;
        }
        p {
            font-size: 0.92vw;
        }
    }
    ${Media('tablet')} {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export default CXFeedbackBox;
