import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { LetsDiscuss } from 'components/industries/common';

export const SAChooseSection = () => {
    return (
        <>
            <DiscussWrapper>
                <LetsDiscuss
                    heading="Ready to Build Your Dream Team?"
                    para="Find out how much money you could be saving."
                    btnText="Get a Quote"
                    bgColor={`${colors.socialRep['brandsBG']}`}
                />
            </DiscussWrapper>
        </>
    );
};

const DiscussWrapper = styled.div`
    position: relative;
`;
