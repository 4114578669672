import React, { useState, useEffect, useRef } from 'react';
import Layout from 'components/layout';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import {
    akiraSuperBold,
    gilroyBold,
    gilroyMedium,
    gilroyRegular,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { LetsDiscuss } from 'components/industries/common';
import { MainIntro } from 'components/ebook/cx-pocket-guide';
import {
    Point1,
    Point2,
    Point3,
    Point4,
    Point5,
    Point6,
    Point7,
    Point8,
    Point9,
    Point10,
    Point11,
} from 'components/ebook/imperative-cx';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import { Para } from 'components/ebook/cx-pocket-guide/para';
import { baseURL } from 'shared-components/theme/helpers';
import ProgressBar from 'progressbar.js';
import useOnScreen from './viewport-observer';
import { BlockImg } from 'components/ebook/imperative-cx/block-img';
import { Source } from 'components/ebook/imperative-cx/source';

const ImperativeCX = () => {
    const [activeIndexes, setActiveIndex] = useState([]);
    const barProgress1 = useRef(null);
    const barProgress2 = useRef(null);
    const BarWrap = useRef(null);
    const isVisible = useOnScreen(BarWrap);

    const handleAccordion = (index) => {
        const arr = [...activeIndexes];
        if (arr && arr.includes(index)) {
            const filter = arr.filter((i) => i !== index);
            setActiveIndex(filter);
        } else {
            arr.push(index);
            setActiveIndex(arr);
        }
    };

    const cxSections = [
        {
            heading: 'At its core, CX is people',
            component: <Point1 active={activeIndexes.includes(0)} />,
        },
        {
            heading: 'The employee experience is critical to CX',
            component: <Point2 active={activeIndexes.includes(1)} />,
        },
        {
            heading: 'Great recruiting is a CX cheat code',
            component: <Point3 active={activeIndexes.includes(2)} />,
        },
        {
            heading: 'Training reveals and activates talent',
            component: <Point4 active={activeIndexes.includes(3)} />,
            floatingImg: `${baseURL}assets/images/ebook/imperative-ebook/ebook_text1.png`,
            top: '43%',
            right: '-37%',
            height: '150',
        },
        {
            heading: 'Inclusion amplifies success',
            component: <Point5 active={activeIndexes.includes(4)} />,
        },
        {
            heading: 'Operational superiority creates CX predictability',
            component: <Point6 active={activeIndexes.includes(5)} />,
            floatingImg: `${baseURL}assets/images/ebook/imperative-ebook/ebook_text2.png`,
            top: '30%',
            right: '-60%',
            height: '260',
        },
        {
            heading: 'Technology is servant, not master',
            component: <Point7 active={activeIndexes.includes(6)} />,
        },
        {
            heading: 'Pick your channel battles',
            component: <Point8 active={activeIndexes.includes(7)} />,
        },
        {
            heading: 'Data is useless without insight',
            component: <Point9 active={activeIndexes.includes(8)} />,
        },
        {
            heading: 'Speed-to-performance is the ultimate vendor benchmark',
            component: <Point10 active={activeIndexes.includes(9)} />,
            floatingImg: `${baseURL}assets/images/ebook/imperative-ebook/ebook_text3.png`,
            top: '30%',
            right: '-80%',
            height: '260',
        },
        {
            heading: 'Insane transparency begets insane trust',
            component: <Point11 active={activeIndexes.includes(10)} />,
            floatingImg: `${baseURL}assets/images/ebook/imperative-ebook/ebook_text4.png`,
            top: '30%',
            right: '-32%',
            height: '260',
        },
    ];

    const BarProgress = (element, barValue) => {
        if (element.current) {
            var bar = new ProgressBar.Line(element.current, {
                strokeWidth: 6,
                easing: 'easeInOut',
                duration: 3000,
                color: colors.pink['100'],
                trailColor: colors.cx_pocket_guide['dark'],
                trailWidth: 6,
                svgStyle: { width: '80%', height: '100%' },
                text: {
                    style: {
                        color: colors.black['100'],
                        position: 'absolute',
                        left: '3%',
                        top: '50%',
                        padding: 0,
                        margin: 0,
                        transform: null,
                        fontSize: '28px',
                        fontFamily: akiraSuperBold,
                    },
                    autoStyleContainer: false,
                },
                from: { color: '#FFEA82' },
                to: { color: '#ED6A5A' },
                step: (state, bar) => {
                    bar.setText(Math.round(bar.value() * 100) + ' %');
                },
            });

            bar.animate(barValue);
        }
    };

    useEffect(() => {
        BarProgress(barProgress1, 0.87);
        BarProgress(barProgress2, 0.11);
    }, [activeIndexes, isVisible]);

    return (
        <Layout>
            <SidePadding>
                <MainIntro
                    heading="11 imperatives <br/>
                    <i>For <u>great cx</u></i>"
                    para="Our manifesto for great CX partnerships and winning <br/>the battle for customer loyalty."
                    noCircle
                    noPaddingBottom
                    style2
                />
            </SidePadding>
            <CXEverything>
                <Container>
                    <h2>
                        CX is <u>Everything.</u>
                    </h2>
                    <h5>
                        That statement might seem self-serving, coming from a CX{' '}
                        <br />
                        outsourcer like ourselves.
                    </h5>
                    <Para>
                        But, as growing brands like yours seek to occupy
                        increasingly sought-after mindshare and market share in
                        a hyper digital, hyper demand consumer landscape, it’s
                        vitally important that you acknowledge it as gospel.
                    </Para>
                    <Para>
                        <b>CX everything, and everything leads back to CX.</b>
                    </Para>
                    <Para>
                        Did Sally tell her friends about her bad support chat?
                        That’s a CX data point. Did John write a positive review
                        after having his account access fixed? Give your CX team
                        a bonus.
                    </Para>
                    <Para>
                        CX is everywhere inside your business, too. From the
                        tone you set with your team, to the Monthly Business
                        Reviews with a client, to streaming old reruns of
                        Seinfeld at night as you build a seasonal forecast –
                        it’s all CX.
                    </Para>
                    <Para>
                        As modern humans, we interact with brands at an
                        increasingly alarming rate.
                    </Para>
                    <Para>
                        Unless you understand that every interaction is a CX
                        interaction, both internal and external, you’ll be
                        woefully underprepared to build an effective CX program,
                        and to effectively prepare your brand and your people
                        for long-term success.
                    </Para>
                </Container>
            </CXEverything>
            <CXProgramme>
                <SidePadding>
                    <p>
                        <div>
                            Every internal process / Every training course
                            /Every recruitment drive / Every technology / Every
                            leader / Every new hire / Every contact center /
                            Every marketing campaign / Every sales person /
                            Every chat, call, email / Every measurement / Every
                            season.
                        </div>
                    </p>
                    <h3>
                        <div>
                            <u>Every. Single. One.</u>
                            <br />
                            <span>
                                <u>It’s all CX.</u>
                            </span>
                        </div>
                    </h3>
                </SidePadding>
            </CXProgramme>
            <FeedbackStat>
                <SidePadding>
                    <Row>
                        <Col lg="7">
                            <div>
                                <h3>
                                    So… why are so many brands failing to get
                                    their CX right?😨
                                </h3>
                            </div>
                            <div ref={BarWrap}>
                                <Row>
                                    <Col lg="6">
                                        <div>
                                            <h4>What the Companies Say:</h4>
                                            {isVisible && (
                                                <div ref={barProgress1} />
                                            )}
                                            <p>
                                                of companies state they <br />
                                                provide excellent CX.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div>
                                            <h4>What the Customers Say:</h4>
                                            {isVisible && (
                                                <div ref={barProgress2} />
                                            )}
                                            <p>
                                                Only 11% of <br />
                                                customers agree.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={{ offset: 1, size: 4 }}>
                            <div>
                                <BlockImg
                                    imgUrl={`${baseURL}assets/images/ebook/imperative-ebook/cx-right.jpg`}
                                />
                                <Source>Source: prnewswire</Source>
                            </div>
                        </Col>
                    </Row>
                </SidePadding>
                <FloatingImg
                    src={`${baseURL}assets/images/ebook/imperative-ebook/pink_circle.svg`}
                    width="520"
                    height="520"
                    alt="-"
                    bottom="-24%"
                    right="-16%"
                    zIndex="0"
                />
            </FeedbackStat>
            <PulseCheck>
                <ArrowDown>
                    <img
                        src={`${baseURL}assets/images/ebook/imperative-ebook/arrow_down.svg`}
                        alt="-"
                    />
                </ArrowDown>
                <div>
                    <SidePadding>
                        <h3>
                            Rising costs. Competitive Talent Pools. Economic
                            Volatility. AI.
                        </h3>
                        <h5>
                            The hurdles to great CX are <u>everywhere</u> and
                            they are <u>relentless</u>.
                        </h5>
                    </SidePadding>
                </div>
            </PulseCheck>
            <SuccessPoints>
                <div>
                    <h1>
                        To cut through the noise and succeed, You need strong
                        principles.
                    </h1>
                </div>
                <AccordionWrapper>
                    <Container>
                        <Row>
                            <Col lg="10">
                                <div>
                                    <h2>
                                        <u>
                                            We call them imperatives, and we’ve
                                            spent many years succeeding (and
                                            yes, at times, failing) to win
                                            wisdom here. We think our CX
                                            imperatives will stand the test of
                                            time.
                                        </u>
                                    </h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <CollapseWrapper>
                        {cxSections &&
                            cxSections.length &&
                            cxSections.map((section, index) => {
                                const refer = useRef();
                                return (
                                    <Accordion
                                        key={index}
                                        active={activeIndexes.includes(index)}
                                        height={refer?.current?.clientHeight}>
                                        <div>
                                            <AccHeader
                                                onClick={() =>
                                                    handleAccordion(index)
                                                }>
                                                <p>
                                                    <span>
                                                        {index < 9
                                                            ? '0' + (index + 1)
                                                            : index + 1}
                                                    </span>
                                                    {section?.heading}
                                                </p>
                                                {(activeIndexes.includes(
                                                    index
                                                ) && <FaMinus />) || <FaPlus />}
                                            </AccHeader>
                                        </div>

                                        <AccBody>
                                            <InnerBody ref={refer}>
                                                {section.floatingImg && (
                                                    <FloatingImg
                                                        height={section.height}
                                                        alt="-"
                                                        top={section.top}
                                                        right={section.right}
                                                        zIndex="99"
                                                        animatedImg
                                                        active={activeIndexes.includes(
                                                            index
                                                        )}
                                                        src={
                                                            section.floatingImg
                                                        }
                                                    />
                                                )}
                                                <SectionNumber
                                                    greaterNum={
                                                        index && index > 8
                                                    }
                                                    active={activeIndexes.includes(
                                                        index
                                                    )}>
                                                    {index < 9
                                                        ? '0' + (index + 1)
                                                        : index + 1}
                                                </SectionNumber>
                                                {section.component}
                                            </InnerBody>
                                        </AccBody>
                                    </Accordion>
                                );
                            })}
                    </CollapseWrapper>
                </AccordionWrapper>
            </SuccessPoints>
            <NutShell>
                <Container>
                    <Row>
                        <CustomCol lg="6">
                            <div>
                                <h3>Let’s nutshell this.</h3>
                            </div>
                            <Para>
                                Good CX isn’t easy, but there are clear
                                principles that will guide you to success.
                            </Para>
                            <Para>
                                Success comes down to building the right game
                                plan, developing the right partnerships, and
                                hiring the right people.
                            </Para>
                        </CustomCol>
                        <CustomCol lg="6">
                            <Para>
                                <u>
                                    In a nutshell, it’s about putting extreme
                                    care into every aspect of your CX program –
                                    which is to say, every aspect of your
                                    business.
                                </u>
                            </Para>
                            <Para>
                                Because CX is everything. And everything impacts
                                CX.
                            </Para>
                            <Para>
                                So, how do you put the proper care into your CX
                                program to build stable, ongoing, predictably
                                high-performing CX results?
                            </Para>
                            <Para>You can start by reaching out to us.</Para>
                        </CustomCol>
                    </Row>
                </Container>
                <FloatingImg
                    src={`${baseURL}assets/images/ebook/imperative-ebook/black_circle.svg`}
                    width="790"
                    height="790"
                    alt="-"
                    top="-53%"
                    left="-29%"
                    zIndex="0"
                />
                <FloatingImg
                    src={`${baseURL}assets/images/ebook/pink-circle-big.svg`}
                    width="790"
                    height="790"
                    alt="-"
                    bottom="-65%"
                    right="-26%"
                    zIndex="0"
                />
            </NutShell>
            <LetsDiscuss
                heading="Ready to Speed-Up Your CX Performance?"
                para="Let’s get you built for speed, scale, and success."
                btnText="Connect With Us"
                bgColor={colors.pink['100']}
                btnDark
                btnBgColor={colors.cx_pocket_guide['dark']}
            />
        </Layout>
    );
};

const ArrowDown = styled.div`
    padding: 30px 40px;
    background-color: ${colors.cx_pocket_guide['pink']};
    position: absolute;
    top: 0;
    img {
        animation: down 2s infinite;
        width: 90px;
    }
    ${Media('xlscreens')} {
        padding: 1.57vw 2.08vw;
        img {
            width: 4.68vw;
        }
    }
    ${Media('tablet')} {
        display: none;
    }
    @keyframes down {
        0% {
            transform: translate(0);
        }
        20% {
            transform: translateY(15px);
        }
        40% {
            transform: translate(0);
        }
        60% {
            transform: translateY(15px);
        }
        100% {
            transform: translate(0);
        }
    }
`;

const NutShell = styled.section`
    background-color: ${colors.cx_pocket_guide['pink']};
    position: relative;
    overflow: hidden;
    h3 {
        font-size: 50px;
        font-family: ${akiraSuperBold};
        line-height: 1.48;
        text-decoration: underline;
        margin-bottom: 60px;
    }
    p {
        font-family: ${gilroyRegular};
        font-size: 24px;
    }
    ${Media('xlscreens')} {
        h3 {
            font-size: 2.66vw;
            margin-bottom: 3.125vw;
        }
        p {
            font-size: 1.25vw;
        }
    }
    ${Media('tablet')} {
        h3 {
            font-size: 32px;
        }
        p {
            font-size: 18px;
        }
    }
    ${Media('mobile')} {
        p {
            font-size: 16px;
        }
    }
`;

const CustomCol = styled(Col)`
    ${Media('xlscreens')} {
        padding-right: 7.8vw !important;
    }
`;

const CXEverything = styled.div`
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
    h2 {
        font-size: 70px;
        font-family: ${gilroyRegular};
        text-align: center;
    }
    h5 {
        font-size: 38px;
        font-family: ${gilroyMedium};
        line-height: 1.84;
        text-align: center;
    }
    img {
        width: 100%;
        position: relative;
        z-index: 99;
    }
    p {
        line-height: 1.45;
    }
    ${Media('xlscreens')} {
        padding-left: 2.66vw;
        padding-right: 2.66vw;
        h2 {
            font-size: 3.64vw;
        }
        h5 {
            font-size: 1.97vw;
        }
        p {
            margin-bottom: 1.6vw;
        }
    }
    ${Media('tablet')} {
        padding-left: 0;
        padding-right: 0;
        h2 {
            font-size: 42px;
        }
        h5 {
            font-size: 20px;
            br {
                display: none;
            }
        }
    }
`;

const SidePadding = styled.div`
    padding: 0px 140px;
    position: relative;
    ${Media('xlscreens')} {
        padding: 0 6.5vw;
    }
    ${Media('tablet')} {
        padding: 0 30px;
    }
    ${Media('mobile')} {
        padding: 0 15px;
    }
`;

const FeedbackStat = styled.section`
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
    .row {
        justify-content: space-between;
    }
    h3 {
        font-size: 50px;
        font-family: ${akiraSuperBold};
        line-height: 1.48;
        margin-bottom: 100px;
        text-decoration: underline;
    }
    h4 {
        font-size: 32px;
        font-family: ${gilroySemibold};
    }
    p {
        font-size: 26px;
        font-family: ${gilroyRegular};
        margin-top: 80px;
    }
    ${Media('xlscreens')} {
        padding-left: 2.66vw;
        padding-right: 2.66vw;
        h3 {
            font-size: 2.66vw;
            margin-bottom: 5vw;
        }
        h4 {
            font-size: 1.66vw;
        }
        p {
            font-size: 1.35vw;
            margin-top: 4.16vw;
        }
    }
    ${Media('tablet')} {
        padding-left: 0;
        padding-right: 0;
        h3 {
            font-size: 26px;
            margin-bottom: 50px;
        }
        h4 {
            font-size: 20px;
        }
        p {
            font-size: 18px;
            margin-top: 60px;
            br {
                display: none;
            }
        }
    }
`;

const CXProgramme = styled.div`
    background-color: ${colors.cx_pocket_guide['dark']};
    text-align: center;
    font-size: 45px;
    position: relative;
    z-index: 99;
    section {
        overflow: hidden;
        position: relative;
    }
    p {
        font-size: inherit;
        font-family: ${gilroyRegular};
        color: ${colors.white['100']};
        line-height: 1.47;
        position: relative;
        z-index: 999;
    }
    h3 {
        font-size: inherit;
        font-family: ${gilroyBold};
        color: ${colors.white['100']};
        line-height: 1.47;
        margin-top: 100px;
        position: relative;
        z-index: 9;
        span {
            color: ${colors.pink['100']};
        }
    }
    ${Media('xlscreens')} {
        font-size: 2.34vw;
        padding: 7vw 0;
        section {
            padding: 18.22vw 6vw 6vw 6vw;
        }
        font-size: 2.29vw;
        margin-top: 4vw;
        h3 {
            margin-top: 5vw;
        }
        background-image: url(${baseURL}assets/images/ebook/imperative-ebook/cx-everything.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top;
    }
    ${Media('tablet')} {
        margin-top: 0;
        font-size: 20px;
        h3 {
            margin-top: 50px;
        }
    }
    ${Media('mobile')} {
        padding: 15px 0;
        font-size: 16px;
    }
`;

const PulseCheck = styled.section`
    background-color: ${colors.pink['100']};
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    h3 {
        font-size: 50px;
        font-family: ${akiraSuperBold};
        line-height: 1.48;
        text-decoration: underline;
        margin-top: 100px;
        word-spacing: 2px;
    }
    h5 {
        font-size: 32px;
        font-family: ${gilroyBold};
        margin-bottom: 0;
    }
    ${Media('xlscreens')} {
        h3 {
            font-size: 2.6vw;
            margin-top: 5vw;
        }
        h5 {
            font-size: 1.66vw;
        }
    }
    ${Media('tablet')} {
        h3 {
            font-size: 32px;
            margin-top: 0px !important;
        }
        h5 {
            font-size: 16px;
        }
    }
    ${Media('mobile')} {
        padding: 40px 0;
        h3 {
            margin-top: 30px;
            font-size: 20px;
        }
    }
`;

const SuccessPoints = styled.section`
    position: relative;
    background-color: ${colors.cx_pocket_guide['dark']};
    h1 {
        font-size: 133px;
        font-family: ${akiraSuperBold};
        text-align: center;
        color: ${colors.pink['100']};
        line-height: 1.015;
        margin-top: 0;
        margin-bottom: 100px;
    }
    h2 {
        font-size: 42px;
        font-family: ${gilroyRegular};
        line-height: 1.47;
        margin-bottom: 80px;
        word-spacing: 3px;
        color: ${colors.white['100']};
    }
    ${Media('xlscreens')} {
        h1 {
            font-size: 7.1vw;
            margin-bottom: 3vw;
            letter-spacing: -0.29vw;
        }
        h2 {
            font-size: 2.18vw;
            margin-bottom: 4.16vw;
        }
    }
    ${Media('tablet')} {
        padding-left: 30px;
        padding-right: 30px;
        h1 {
            font-size: 70px;
            margin-bottom: 50px;
        }
        h2 {
            font-size: 28px;
        }
    }
    ${Media('mobile')} {
        padding-left: 15px;
        padding-right: 15px;
        h1 {
            font-size: 36px;
        }
        h2 {
            font-size: 22px;
        }
    }
`;

const AccordionWrapper = styled.div`
    ${Media('xlscreens')} {
        padding: 0 4.5vw;
    }
`;

const CollapseWrapper = styled(Container)``;

const AccHeader = styled.button`
    margin-bottom: 70px;
    position: relative;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${colors.white['100']};
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${colors.white['100']};
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    p {
        color: inherit;
        font-size: 30px;
        font-family: ${gilroySemibold};
        margin: 0;
    }
    span {
        margin-right: 60px;
        color: ${colors.cx_pocket_guide['pink']};
    }
    svg {
        height: 30px;
        width: 30px;
        color: ${colors.pink['100']};
    }
    &:hover {
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        margin-bottom: 2.4vw;
        padding-bottom: 1.3vw;
        p {
            font-size: 1.57vw;
        }
        span {
            margin-right: 3.125vw;
        }
        svg {
            height: 1.57vw;
            width: 1.57vw;
        }
    }
    ${Media('tablet')} {
        p {
            font-size: 20px;
            text-align: left;
        }
        svg {
            height: 20px;
            width: 20px;
        }
    }
    ${Media('mobile')} {
        p {
            font-size: 14px;
            max-width: 90%;
            text-align: left;
        }
        span {
            margin-right: 10px;
        }
    }
`;

const AccBody = styled.div`
    height: 0px;
    min-height: 0;
    transition: all 500ms ease 200ms;
    overflow: hidden;
`;

const Accordion = styled.div`
    position: relative;
    ${(props) => {
        if (props.active) {
            return `
                    ${AccBody}{
                        height:${
                            props.height ? props.height + 150 + 'px' : '500px'
                        };
                }
            `;
        }
    }}
`;

const InnerBody = styled.div``;

const SectionNumber = styled.h3`
    font-size: 400px;
    color: ${colors.pink['100']};
    font-family: ${gilroyMedium};
    position: absolute;
    left: -40%;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 500ms ease 200ms;
    ${(props) => {
        if (props.active) {
            return `
                opacity:1;
                visibility:visible;
            `;
        }
    }}
    ${Media('xlscreens')} {
        font-size: 20.83vw;
        ${(props) => {
            if (props.greaterNum) {
                return `
                    left:-33%;
                `;
            }
        }}
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const FloatingImg = styled.img`
    position: absolute;
    top: ${(props) => props.top};
    right: ${(props) => props.right};
    left: ${(props) => props.left};
    bottom: ${(props) => props.bottom};
    z-index: ${(props) => props.zIndex};
    height: ${(props) => props.height + 'px'};
    width: ${(props) => props.width + 'px'};
    opacity: ${(props) => (props.animatedImg ? 0 : 1)};
    visibility: ${(props) => (props.animatedImg ? 'hidden' : 'visible')};
    transition: all 0.3s ease;
    transition-delay: 0.3s;
    ${Media('xlscreens')} {
        height: ${(props) => props.height / 19.2 + 'vw'};
        width: ${(props) => props.width / 19.2 + 'vw'};
    }
    ${Media('tablet')} {
        display: none;
    }
    ${(props) => {
        if (props.active && props.animatedImg) {
            return `
                opacity:1;
                visibility:visible;
            `;
        }
    }}
`;

export default ImperativeCX;
