import React from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import BigParagraph2 from 'components/case-study/case-big-paragraph2';
import CaseArrowHeading from 'components/case-study/case-arrow-heading';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import GoalBox from 'components/case-study/case-study-goalbox';
import { SectionWrapper } from '../common';
import TheNeed from 'components/case-study/case-the-need';

export const NeedSection = ({ data, goals }) => {
    return (
        <>
            <ContentSectionArea>
                {(data['section_image'] && (
                    <TheNeed
                        contentParagraph={data['section_description']}
                        contentImage={data['section_image']}
                    />
                )) || (
                    <Container>
                        <CaseArrowHeading
                            marginTop
                            marginBottom
                            heading={data['heading_text']}
                        />
                        <BigParagraph2
                            bigParagraph={data['section_description']}
                        />
                    </Container>
                )}
            </ContentSectionArea>
            {goals && goals.list && (
                <SectionWrapper margin>
                    <Container>
                        <CaseArrowHeading
                            marginBottom
                            heading={goals['heading_text']}
                        />
                        <Row noGutters>
                            {goals.list.map((item, index) => {
                                return (
                                    <Col lg="4" key={index}>
                                        <GoalBox
                                            icon={item['icon']}
                                            desc={item['text']}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </Container>
                </SectionWrapper>
            )}
        </>
    );
};

const ContentSectionArea = styled.section`
    padding-top: 0px;
    ${Media('tablet')} {
        padding: 0px;
    }
`;
