import React, { useState } from 'react';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { baseURL } from 'shared-components/theme/helpers';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import DemoRequestForm from 'components/social-rep-man/demo-request-form';
import SRMIntroSection from 'components/social-rep-man/intro-section';
import Loader from 'components/loader/loader';
import LazyLoad from 'react-lazyload';
import {
    ScoreEngageSection,
    ScoreManageSection,
    ScoreInsightSection,
} from './sections';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -210,
    },
    {
        name: 'engage',
        target: 'engage',
        offset: 0,
    },
    {
        name: 'manage',
        target: 'manage',
        offset: 0,
    },
    {
        name: 'insight',
        target: 'insight',
        offset: 0,
    },
];

const engageData = [
    {
        image: `${baseURL}assets/images/tech/score-engage-1.jpg`,
        heading: 'Measure Performance Against Peers and Program Goals ',
        para:
            'Agents are motivated with real-time performance ranking against their peers and identified KPIs, competing to win new incentives. ',
    },
    {
        image: `${baseURL}assets/images/tech/score-engage-2.jpg`,
        heading: 'Transform the agent experience ',
        para:
            'A gamified agent experience creates a culture of healthy competition, giving agents the tools and incentives they need to win, driving better client results ',
    },
    {
        image: `${baseURL}assets/images/tech/score-engage-3.jpg`,
        heading: 'Reach new goals through rewards and recognition ',
        para:
            'Gamified rewards system encourages agents to continue hitting new targets. Watch agents exceed targets and turn positive habits into long-term behaviors.  ',
    },
];

const manageContent = [
    {
        ImageRight: false,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/quick-access-square.svg`,
        VerticalCardHeading: `Agent incentivization & engagement, made easy`,
        VerticalCardParagraph: `Scorecard gamifies the agent experience using customizable target-setting and point/reward attribution, keeping agents engaged and motivated to keep each customer experience humming. Real-time scoreboards allow agents to compete against their peers and continually strive to win.`,
        VerticalCardImage: `${baseURL}assets/images/tech/score-monitor-img-1.svg`,
    },
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/vfms-monitor-tag-2.svg`,
        VerticalCardHeading: `One view for agents, supervisors, and managers `,
        VerticalCardParagraph: `Scorecard brings all performance data into a single dashboard, creating a transparent view of customer experience interactions for agents, supervisors, and OMs, ensuring everyone is on the same page. Whether it is historical trends, agent improvement, or performance-based rewards, Scorecard displays all key information to keep you up-to-date and ready to act. `,
        VerticalCardImage: `${baseURL}assets/images/tech/score-monitor-img-2.svg`,
    },
    {
        ImageRight: false,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/score-manage-icon-arrow.svg`,
        VerticalCardHeading: `Make data-driven plans to improve CX outcomes`,
        VerticalCardParagraph: `Stay informed with built-in analytics and create focused improvement plans for specified agents - or the entire contact center - based on real-time and historical data. Stack rank and quartile views are auto-computed, and are available at Team, Site and Program levels. `,
        VerticalCardImage: `${baseURL}assets/images/tech/score-monitor-img-3.svg`,
    },
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/witness-monitor-tag-2.svg`,
        VerticalCardHeading: `Integrated Agent Development and Coaching Solution`,
        VerticalCardParagraph: `Scorecard is made to keep track of every agent’s performance, helping you identify areas for improvement.  Invest in your agents’ performance by taking advantage of Scorecard’s integration with ibex <a href="/technology/inspire">Inspire</a>, a performance management and training tool to automate continuous coaching, ensuring constantly improving CX.`,
        VerticalCardImage: `${baseURL}assets/images/tech/score-monitor-img-4.svg`,
    },
];

const insightData = [
    {
        icon: `${baseURL}assets/images/tech/score-insight-icon-1.svg`,
        title:
            'Gamify Your Performance Data to Improve End-to-End Program Performance',
        desc:
            'Supervisors are armed with all performance data to monitor performance, identify opportunities to coach agents, and drive consistent positive behaviors that turn into measurable CX results. ',
    },
    {
        icon: `${baseURL}assets/images/tech/score-insight-icon-2.svg`,
        title: 'Achieve KPIs faster and Reduce CX Risk',
        desc:
            'Leverage real-time performance data to drive continuous improvements, ensuring ongoing program evolution and enhancement and dramatically reducing risk. ',
    },
    {
        icon: `${baseURL}assets/images/tech/score-insight-icon-3.svg`,
        title: 'Improve Agent Retention & Engagement',
        desc:
            'Scorecard’s unified incentive system, integrated with ibex Inspire for pin-point agent performance coaching, builds more skilled, invested, engaged, and productive agents. ',
    },
];

const Scorecard = () => {
    const [demoRequestForm, setDemoRequestForm] = useState(false);

    const sections = [
        {
            component: <ScoreEngageSection data={engageData} />,
            isScrollNav: false,
        },
        {
            component: <ScoreManageSection data={manageContent} />,
            isScrollNav: false,
        },
        {
            component: (
                <ScoreInsightSection
                    data={insightData}
                    requestForm={() => setDemoRequestForm(true)}
                />
            ),
            isScrollNav: true,
        },
    ];

    return (
        <Layout>
            <Breadcrumbs>
                <li>Wave X</li>
                <li>Technology</li>
            </Breadcrumbs>

            <DemoRequestForm
                handleDemoForm={(val) => setDemoRequestForm(val)}
                formState={demoRequestForm}
                marketFormId="1172"
            />

            <SRMIntroSection
                preheading="Scorecard"
                heading="Agent Performance Gamification & Acceleration "
                para="Smash through contact center performance thresholds with KPI gamification, outcome incentivization, and end-to-end engagement optimization."
                mainImg={`${baseURL}assets/images/tech/score-intro.svg`}
                textureImg={`${baseURL}assets/images/tech/score-front.svg`}
                textureimgHeight={440}
                textureImgPosition="front"
                onBtnClick={() => setDemoRequestForm(true)}
            />

            {sections &&
                sections.map((section, index) => {
                    return (
                        <div
                            key={index}>
                            {section.isScrollNav && (
                                <ScrollNav data={scrollNavData} />
                            )}
                            {section.component}
                        </div>
                    );
                })}
        </Layout>
    );
};

export default Scorecard;
