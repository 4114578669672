import { Col, Row } from '@bootstrap-styled/v4';
import React from 'react';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import { BigHeading } from './big-heading';
import { BlockHeading } from './block-heading';
import { CXSectionIntro } from './cx-section-intro';
import { Para } from './para';

export const CXPoint5 = ({ active }) => {
    return (
        <div>
            <CXSectionIntro
                active={active}
                progress={0.99}
                source="Source: CCW"
                progressImg={`${baseURL}assets/images/ebook/cx-progress5.svg`}
                introPara="Notably, Fortune 1000 companies can see an average revenue boost of $2 billion annually if they improve data usability by 10%, but an overwhelming <span>99% of contact centers</span> don’t think that their data intelligence strategy currently meets business needs."
            />
            <BigHeading>
                Delivering an upgraded experience your customers want requires that you truly understand what your customers actually need - this depends on a robust and sophisticated business intelligence and performance management operation.
            </BigHeading>
            <Para>Consider the following:</Para>
            <BlockHeading>Customer Journey Mapping</BlockHeading>
            <Para>
                Using customer interaction data, your outsourcer should map the
                entire customer journey to better understand your customers’
                habits and points of friction, and then feed this data into
                every level of agent training.
            </Para>
            <BlockHeading>Complexity Mapping</BlockHeading>
            <Para>
                Use customer data to build complexity studies that identify
                customer patterns, help map drivers across the customer
                lifecycle, and inform channel strategy.
            </Para>
            <SectionImage>
                <img
                    src={`${baseURL}assets/images/ebook/cx-pg-section5.jpg`}
                    alt="-"
                />
            </SectionImage>
            <Row>
                <Col lg="5">
                    <BlockHeading>Data-Driven Training</BlockHeading>
                    <Para>
                        A proactive outsourcer will integrate historical
                        customer data into training programs to ensure agents
                        are effectively trained on your specific customers’
                        biggest support needs for Day 1 customer success.
                    </Para>
                </Col>
                <Col lg={{ size: 6, offset: 1 }}>
                    <BlockHeading>Dream Team Modeling</BlockHeading>
                    <Para>
                        Having the right team in place is essential for Day 1
                        success. Make sure your CX partner is using data-driven
                        screening and recruitment methods to narrow down the
                        best talent based on the qualities top agents already
                        possess.
                    </Para>
                </Col>
            </Row>
        </div>
    );
};
const SectionImage = styled.div`
    margin-bottom: 70px;
    img {
        width: 100%;
    }
    ${Media('xlscreens')} {
        margin-bottom: 3.64vw;
    }
`;
