import React from 'react';
import Media from 'shared-components/theme/medias';
import { Col, Row } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import ReactHtmlParser from 'react-html-parser';
import { FlexColumnCentered } from 'shared-components/theme/grid-helpers';
import { gilroyBold, gilroyMedium } from 'shared-components/theme/fonts';
import Button from 'shared-components/button/button';

export const FeaturedPost = ({ data }) => {
    const handleType = (data) => {
        switch (data.post_type) {
            case 'webinars':
                return 'Webinar';
            case 'ebooks':
                return 'Ebook';
            case 'case-study':
                return 'Case Study';
            case 'post':
                return 'Blog';
            default:
                return '';
        }
    };

    const handleBtnText = (val) => {
        return val === 'webinars' ? 'Watch Now' : 'Read Now';
    };

    return data.metadata && data.metadata.show_its_landing_page ? (
        <MainBlogCard>
            <Row noGutters>
                <Col sm="12" lg="7">
                    <MainBlogImg>
                        <img
                            src={
                                data.metadata['featured_slider_image']
                                    ? data.metadata['featured_slider_image']
                                    : data.metadata.associated_landing_page[
                                          'featured_image'
                                      ]
                            }
                            alt="-"
                        />
                    </MainBlogImg>
                </Col>
                <Col sm="12" lg="5">
                    <MainBlogContent>
                        <h4>Featured {handleType(data)}</h4>
                        <h2>
                            {
                                data.metadata.associated_landing_page[
                                    'post_title'
                                ]
                            }
                        </h2>
                        <ContentPara>
                            {ReactHtmlParser(
                                data.metadata['short_description']
                            )}
                        </ContentPara>
                        <FeatBlogBtn>
                            <Button
                                ifButton={false}
                                round={true}
                                href={
                                    data.metadata['associated_landing_page'][
                                        'permalink'
                                    ]
                                }>
                                {handleBtnText(data['post_type'])}
                            </Button>
                        </FeatBlogBtn>
                    </MainBlogContent>
                </Col>
            </Row>
        </MainBlogCard>
    ) : (
        <MainBlogCard>
            <Row noGutters>
                <Col sm="12" lg="7">
                    <MainBlogImg>
                        <img
                            src={
                                data.metadata['featured_slider_image']
                                    ? data.metadata['featured_slider_image']
                                    : data['featured_image']
                            }
                            alt="-"
                        />
                    </MainBlogImg>
                </Col>
                <Col sm="12" lg="5">
                    <MainBlogContent>
                        <h4>Featured {handleType(data)}</h4>
                        <h2>{data['post_title']}</h2>
                        <ContentPara>
                            {ReactHtmlParser(
                                data.metadata['short_description']
                            )}
                        </ContentPara>
                        <FeatBlogBtn>
                            <Button
                                ifButton={false}
                                round={true}
                                href={data['permalink']}>
                                {handleBtnText(data['post_type'])}
                            </Button>
                        </FeatBlogBtn>
                    </MainBlogContent>
                </Col>
            </Row>
        </MainBlogCard>
    );
};
const MainBlogCard = styled.div`
    margin-top: 30px;
    border-radius: 10px;
    overflow: hidden;
    background-color: ${colors.dark['100']};
    ${Media('xlscreens')} {
        margin-top: 3vw;
    }
`;

const MainBlogImg = styled.div`
    height: 100%;
    min-height: 520px;
    max-height: 520px;
    overflow: hidden;
    border-bottom: 9px solid ${colors.resources['cardPurple']};
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    ${Media('xlscreens')} {
        max-height: 24.7vw;
        min-height: 24.7vw;
        border-bottom-width: 0.47vw;
    }
    ${Media('tablet')} {
        height: 290px;
    }
    ${Media('mobile')} {
        height: 200px;
    }
`;

const MainBlogContent = styled(FlexColumnCentered)`
    width: 100%;
    height: 100%;
    padding: 50px;
    align-items: flex-start;
    position: relative;
    border-bottom: 9px solid ${colors.pink['100']};
    h4,
    p {
        font-size: 20px;
        letter-spacing: -0.5px;
    }
    h4 {
        font-family: ${gilroyMedium};
        margin-top: 0px;
        margin-bottom: 20px;
        color: ${colors.pink['100']};
    }
    h2,
    p {
        margin-top: 0px;
        color: ${colors.white['100']};
    }
    h2 {
        font-size: 34px;
        letter-spacing: 1.15;
        font-family: ${gilroyBold};
        letter-spacing: -0.5px;
        margin-bottom: 20px;
    }
    ${Media('xlscreens')} {
        border-bottom-width: 0.47vw;
        padding: 1.5vw 4vw;
        max-height: 24.7vw;
        h4,
        p {
            letter-spacing: -0.01vw;
            font-size: 1vw;
        }
        h4 {
            margin-bottom: 1.8vw;
        }
        h2 {
            font-size: 1.5vw;
            letter-spacing: -0.03vw;
            margin-bottom: 1.3vw;
        }
        p {
            margin-bottom: 0;
        }
    }
    ${Media('mobile')} {
        padding: 30px 20px;
        min-height: 335px;
        h4,
        p {
            font-size: 14px;
        }
        h2 {
            font-size: 24px;
        }
    }
`;

const ContentPara = styled.div`
    font-size: 18px;
    letter-spacing: -0.5px;
    color: ${colors.white['100']};
    font-family: ${gilroyMedium};
    line-height: 1.4;
    p {
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        margin-top: 0px;
        color: inherit;
    }
    ${Media('xlscreens')} {
        letter-spacing: -0.01vw;
        font-size: 0.9vw;
        p {
            margin-bottom: 0;
        }
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;

const FeatBlogBtn = styled.div`
    margin-top: 30px;
    ${Media('xlscreens')} {
        margin-top: 1.6vw;
    }
`;
