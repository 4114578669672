import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import Media from 'shared-components/theme/medias';
import MainPara from 'shared-components/main-para/main-para';
import colors from 'shared-components/theme/colors';
import HomeBigText from 'components/home-big-text/home-big-text';
// import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import { baseURL } from 'shared-components/theme/helpers';
import BannerMorphPill from 'components/banner-morph-pill/banner-morph-pill';
import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import { Link } from 'react-scroll';
import { FaArrowDown } from 'react-icons/fa';
import StaffAugSlider from 'components/staff_aug/slider';
import HomeBlog from 'components/home-blog/home-blog';
import {
    TeamBuilderSection,
    PlatformSection,
    SAChooseSection,
} from './sections';
import MainHeading from 'shared-components/main-heading/main-heading';

const vetterContent = [
    {
        icon: `${baseURL}assets/images/staff_aug/extend-team-icon.svg`,
        title: 'Extend your team',
        para: 'Our thorough matching process empowers us to set up teams of all sizes with profoundly trained, experienced specialists.',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/keep-hiring-cost.svg`,
        title: 'Keep hiring cost low',
        para: 'We do recruitment the smart way. Our hiring processes have evolved to be extremely cost-effective and predictable.',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/find-talent-globally.svg`,
        title: 'Find talent globally',
        para: 'We build brilliant teams with handpicked local or global talent. Let us find you one from our vetted talent pool.',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/improved-skills-icon.svg`,
        title: 'Improved skills & better talent',
        para: 'Using modern tools and systems for efficient reporting, we guarantee you the right-sized skilled team for your project.',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/ensure-hiring-icon.svg`,
        title: 'Ensure a Fast hiring process',
        para: 'ibex has a very fast turnaround time for prompt requests of resources made by our clients to ensure their progress never halts.',
    },
];

const platforms = [
    {
        icon: `${baseURL}assets/images/staff_aug/zendesk.png`,
        height: '40',
        width: '180',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/marketo.png`,
        height: '55',
        width: '190',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/sap.png`,
        height: '48',
        width: '95',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/siebel.png`,
        height: '30',
        width: '190',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/salesforce.png`,
        height: '90',
        width: '130',
    },
];

const tools = [
    {
        icon: `${baseURL}assets/images/staff_aug/ableau.png`,
        height: '40',
        width: '190',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/power-bi.png`,
        height: '50',
        width: '180',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/microsoft-excel.png`,
        height: '58',
        width: '56',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/dart.png`,
        height: '45',
        width: '145',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/coupa.png`,
        height: '40',
        width: '150',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/workday.png`,
        height: '55',
        width: '140',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/servicenow.png`,
        height: '25',
        width: '180',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/hyperion.png`,
        height: '20',
        width: '158',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/sharepoint.png`,
        height: '35',
        width: '176',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/naplan.png`,
        height: '28',
        width: '130',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/clari.png`,
        height: '38',
        width: '125',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/azure.png`,
        height: '58',
        width: '58',
    },
];

const languages = [
    {
        icon: `${baseURL}assets/images/staff_aug/python.png`,
        height: '45',
        width: '190',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/c++.png`,
        height: '55',
        width: '48',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/ruby.png`,
        height: '50',
        width: '50',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/nodejs.png`,
        height: '45',
        width: '155',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/js.png`,
        height: '40',
        width: '55',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/mysql.png`,
        height: '40',
        width: '72',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/java.png`,
        height: '70',
        width: '107',
    },
    {
        icon: `${baseURL}assets/images/staff_aug/dotnet.png`,
        height: '30',
        width: '77',
    },
];

const talentStats = [
    {
        title: '15+',
        para: 'Years of experience',
    },
    {
        title: '31+',
        para: 'Global Delivery Centers',
    },
    {
        title: '31k+',
        para: 'Employees Worldwide',
    },
    {
        title: '540M',
        para: 'Company Revenue',
    },
    {
        title: '100%',
        para: 'Client Retention',
    },
];

const StaffAugment = () => {
    return (
        <Layout page="cx" bgColor={colors.gray_lighter['100']}>
            <Breadcrumbs>
                <li>Solutions</li>
                <li>Staff Augmentation</li>
            </Breadcrumbs>
            <CXIntro id="intro">
                <Container>
                    <div>
                        <Row>
                            <Col lg="6">
                                <IntroHead>
                                    <SubHeading>
                                        Staff Augmentation & Managed Services
                                    </SubHeading>
                                    <MainHeading
                                        noMargin={true}
                                        font={gilroySemibold}>
                                        Grow your business with the&nbsp;
                                        <u>right people</u>&nbsp; at the&nbsp;
                                        <u>right price</u>.
                                    </MainHeading>
                                </IntroHead>
                            </Col>
                            <Col lg={{ size: 5, offset: 1 }}>
                                <MainParaWrapper>
                                    <MainPara styleTwo noMargin>
                                        We help leading brands unlock new
                                        business potential. Our staff
                                        augmentation and managed services
                                        solutions deliver top-tier talent at
                                        massive cost savings.
                                    </MainPara>
                                    <Link
                                        to="features"
                                        smooth={true}
                                        spy={true}
                                        offset={-100}
                                        duration={1000}>
                                        <MainPara styleTwo noMargin>
                                            Learn More
                                        </MainPara>
                                        <FaArrowDown
                                            color={colors.pink['100']}
                                        />
                                    </Link>
                                </MainParaWrapper>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <BannerMorphPill
                    image={`${baseURL}assets/images/staff-augmented-header.jpg`}
                />
                <HomeBigText
                    bigTextOne="Top Global"
                    bigTextTwo="Talent Pool"
                    level="cxOne"
                    textOneleft="-50%"
                    textOneRight="50%"
                    textTwoleft="50%"
                    textTwoRight="-50%"
                />
                <BigCircle />
            </CXIntro>
            <VettedSection>
                <Row>
                    <Col lg="12">
                        <VettedHeading>
                            <span>Vetted talent, on-demand.</span>
                            <br />
                            We provide you with top-tier talent and <br />
                            perfect-fit resources from the world’s leading&nbsp;
                            <br />
                            talent hubs.
                        </VettedHeading>
                    </Col>
                </Row>
                <VettedCards>
                    <Row>
                        <Col lg="12">
                            {vetterContent &&
                                vetterContent.length > 0 &&
                                vetterContent.map((item, index) => {
                                    return (
                                        <VettedCard key={index}>
                                            <IconWrap>
                                                <VettedIcon src={item.icon} />
                                            </IconWrap>
                                            <VettedTitle>
                                                {item.title}
                                            </VettedTitle>
                                            <VettedPara>{item.para}</VettedPara>
                                        </VettedCard>
                                    );
                                })}
                        </Col>
                    </Row>
                </VettedCards>
            </VettedSection>
            <DreamTeam>
                <Row noGutters>
                    <Col lg="7">
                        <img
                            src={`${baseURL}assets/images/staff_aug/dream-team.jpg`}
                            alt="-"
                        />
                    </Col>
                    <Col lg="5">
                        <DreamTeamContent>
                            <span>Let’s Connect</span>
                            <SectionHeading>
                                Your <br />
                                <u>Dream Team</u>
                                <br /> Awaits
                            </SectionHeading>
                            <MainPara styleTwo>
                                Whether you need to expand your team’s power
                                with specialized skills, increase bandwidth, or
                                just need a team that can keep things running
                                smoothly and evolving as quickly as you can say
                                what’s next, ibex augment delivers the right
                                talent and strategic solutions to grow, scale,
                                and manage your projects – at speed.
                            </MainPara>
                        </DreamTeamContent>
                    </Col>
                </Row>
            </DreamTeam>
            <ProjectReady>
                <Container>
                    <Row>
                        <Col lg="7">
                            <SectionHeading>
                                <u>Project-Ready</u> Capabilities
                            </SectionHeading>
                            <MainPara styleTwo>
                                Select from our expensive menu of recruitment
                                skill-sets
                            </MainPara>
                        </Col>
                    </Row>
                </Container>
                <StaffAugSlider />
            </ProjectReady>
            <PlatformTools>
                <Container>
                    <SectionHeading white center>
                        A high-level selection of&nbsp;
                        <span>
                            <u>highly skilled experts</u>
                        </span>
                        <br /> on demand for all leading platforms, tools &
                        languages
                    </SectionHeading>
                    <PlatformHeader>
                        <MainPara styleTwo color={colors.white['30']}>
                            Platforms
                        </MainPara>
                    </PlatformHeader>
                    <Platforms>
                        {platforms &&
                            platforms.length > 0 &&
                            platforms.map((item, index) => {
                                return (
                                    <PlatformIcon
                                        key={index}
                                        height={item.height}
                                        width={item.width}
                                        src={item.icon}
                                        alt="-"
                                    />
                                );
                            })}
                    </Platforms>
                    <PlatformHeader>
                        <MainPara styleTwo color={colors.white['30']}>
                            Tools
                        </MainPara>
                    </PlatformHeader>
                    <Platforms>
                        {tools &&
                            tools.length > 0 &&
                            tools.map((item, index) => {
                                return (
                                    <PlatformIcon
                                        key={index}
                                        height={item.height}
                                        width={item.width}
                                        src={item.icon}
                                        alt="-"
                                    />
                                );
                            })}
                    </Platforms>
                    <PlatformHeader>
                        <MainPara styleTwo color={colors.white['30']}>
                            Languages
                        </MainPara>
                    </PlatformHeader>
                    <Platforms>
                        {languages &&
                            languages.length > 0 &&
                            languages.map((item, index) => {
                                return (
                                    <PlatformIcon
                                        key={index}
                                        height={item.height}
                                        width={item.width}
                                        src={item.icon}
                                        alt="-"
                                    />
                                );
                            })}
                    </Platforms>
                </Container>
            </PlatformTools>
            <GlobalTalent>
                <TalentStats>
                    {talentStats &&
                        talentStats.length > 0 &&
                        talentStats.map((item, index) => {
                            return (
                                <StatBox key={index}>
                                    <StatCount>{item.title}</StatCount>
                                    <MainPara styleTwo noMargin>
                                        {item.para}
                                    </MainPara>
                                </StatBox>
                            );
                        })}
                </TalentStats>
                <StaffAugMap>
                    <Container>
                        <SectionHeading center>
                            Our Global Talent Hub
                        </SectionHeading>
                        <MapImg>
                            <img
                                src={`${baseURL}assets/images/staff_aug/new-staff-aug.svg`}
                            />
                            {/* Pakistan Tooltip */}
                            <StatTooltip top={'35%'} left={'62.5%'}>
                                <Tooltip country="Pakistan">
                                    5 sites <Dot />
                                    3,400 employees
                                </Tooltip>
                                <FlagImg
                                    src={`${baseURL}assets/images/staff_aug/pakistan-flag-round.svg`}
                                    alt="pakistan flag round"
                                />
                            </StatTooltip>
                            {/* USA Tooltip */}
                            <StatTooltip top={'24%'} left={'22%'}>
                                <Tooltip country="USA">
                                    9 sites <Dot />
                                    3,000+ employees
                                </Tooltip>
                                <FlagImg
                                    src={`${baseURL}assets/images/staff_aug/usa-flag-round.svg`}
                                    alt="pakistan flag round"
                                />
                            </StatTooltip>
                            {/* Nicaragua Tooltip */}
                            <StatTooltip top={'45%'} left={'19%'}>
                                <Tooltip country="Nicaragua">
                                    2 sites <Dot />
                                    1,500 employees
                                </Tooltip>
                                <FlagImg
                                    src={`${baseURL}assets/images/staff_aug/nicaragua-flag-round.svg`}
                                    alt="pakistan flag round"
                                />
                            </StatTooltip>
                            {/* Jamaica Tooltip */}
                            <StatTooltip top={'39%'} left={'22%'}>
                                <Tooltip country="Jamaica">
                                    3 sites <Dot />
                                    4,500 employees
                                </Tooltip>
                                <FlagImg
                                    src={`${baseURL}assets/images/staff_aug/jamaica-flag-round.svg`}
                                    alt="pakistan flag round"
                                />
                            </StatTooltip>
                            {/* Philippines Tooltip */}
                            <StatTooltip top={'48%'} left={'77%'}>
                                <Tooltip country="Philippines">
                                    7 sites <Dot />
                                    9,500 employees
                                </Tooltip>
                                <FlagImg
                                    src={`${baseURL}assets/images/staff_aug/philippines-flag-round.svg`}
                                    alt="pakistan flag round"
                                />
                            </StatTooltip>
                        </MapImg>
                    </Container>
                </StaffAugMap>
                <TeamBuilderSection />
            </GlobalTalent>
            <HomeBlog />
            <PlatformSection />
            <SAChooseSection />
        </Layout>
    );
};

const SubHeading = styled.h5`
    display: block;
    font-size: 28px;
    color: ${(props) => (props.color ? props.color : colors.gray_dark['100'])};
    font-family: ${gilroySemibold};
    line-height: 1.1;
    ${Media('xlscreens')} {
        font-size: 1.6vw;
        margin-top: 0;
    }
    ${Media('tablet')} {
        font-size: 24px;
    }
    ${Media('mobile')} {
        font-size: 18px;
    }
`;

const MainParaWrapper = styled.div`
    background-color: ${colors.white['100']};
    padding: 70px;
    border-radius: 50px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    a {
        display: flex;
        align-items: center;
        margin-top: 20px;
        p {
            margin-right: 10px;
        }
    }
    ${Media('xlscreens')} {
        padding: 3.64vw;
        border-radius: 2.6vw;
        border-bottom-left-radius: 0 !important;
        a {
            margin-top: 1.04vw;
        }
    }
    ${Media('mobile')} {
        padding: 30px;
        border-radius: 20px;
    }
`;

const CXIntro = styled.section`
    position: relative;
    padding-bottom: 30px;
    .container-fluid {
        padding: 0;
    }
    ${Media('xlscreens')} {
        padding-bottom: 1.57vw;
    }
    ${Media('mobile')} {
        padding-top: 20px;
    }
`;

const IntroHead = styled.div`
    ${Media('xlscreens')} {
        h2 {
            font-size: 3.3vw;
        }
    }
    ${Media('tablet')} {
        padding-left: 0px;
        padding-bottom: 40px;
        h2 {
            font-size: 42px;
        }
    }
    ${Media('mobile')} {
        h2 {
            font-size: 32px;
        }
    }
`;

const BigCircle = styled.div`
    position: absolute;
    background: ${colors.white['20']};
    border: 1px solid ${colors.pink['100']};
    border-radius: 50%;
    z-index: 99;
    ${Media('xlscreens')} {
        width: 50vw;
        height: 50vw;
        left: 60%;
        top: 55%;
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const VettedSection = styled.section`
    margin: 10vw 0 5vw;
    ${Media('xlscreens')} {
        padding-left: 7.29vw;
        padding-right: 7.29vw;
    }
    ${Media('tablet')} {
        padding: 0px 30px;
        margin-top: 0px;
    }
`;

const VettedHeading = styled.h2`
    font-size: 45px;
    font-family: ${gilroySemibold};
    line-height: 1.17;
    letter-spacing: -1.5px;
    span {
        color: ${colors.pink['100']};
        text-decoration: underline;
    }
    ${Media('xlscreens')} {
        font-size: 2.3vw;
    }
    ${Media('tablet')} {
        font-size: 36px;
    }
    ${Media('mobile')} {
        font-size: 26px;
        text-align: center;
        br {
            display: none;
        }
    }
`;

const VettedCards = styled.div`
    margin-top: 50px;
    ${Media('xlscreens')} {
        margin-top: 2.6vw;
    }
`;

const IconWrap = styled.div`
    padding: 20px;
    background-color: ${colors.white['100']};
    height: 85px;
    width: 85px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    ${Media('xlscreens')} {
        padding: 1.04vw;
        height: 4.42vw;
        width: 4.42vw;
    }
    ${Media('tablet')} {
        height: 60px;
        width: 60px;
    }
`;

const VettedCard = styled.div`
    width: 20%;
    float: left;
    display: flex;
    flex-direction: column;
    padding: 45px 15px;
    padding-left: 30px;
    transition: all 0.3s ease;
    &:hover {
        background-color: ${colors.white['100']};
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        ${IconWrap} {
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        }
    }
    ${Media('xlscreens')} {
        padding: 2.34vw 0.78vw;
        padding-left: 1.57vw;
    }
    ${Media('tablet')} {
        width: 50%;
        padding: 20px;
    }
    ${Media('mobile')} {
        width: 100%;
        margin-bottom: 50px;
        align-items: center;
        text-align: center;
    }
`;

const VettedIcon = styled.img`
    max-height: 50px;
    max-width: 50px;
    ${Media('xlscreens')} {
        max-height: 2.6vw;
        max-width: 2.6vw;
    }
    ${Media('tablet')} {
        max-height: 30px;
        max-width: 30px;
    }
`;
const VettedTitle = styled.h5`
    font-size: 22px;
    font-family: ${gilroySemibold};
    margin: 0px;
    min-height: 100px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    ${Media('xlscreens')} {
        font-size: 1.14vw;
        min-height: 5.2vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
    ${Media('mobile')} {
        min-height: unset;
        margin: 20px 0;
    }
`;
const VettedPara = styled.p`
    font-size: 18px;
    font-family: ${gilroyMedium};
    margin: 0;
    ${Media('xlscreens')} {
        font-size: 0.93vw;
    }
    ${Media('tablet')} {
        font-size: 14px;
    }
`;

const DreamTeam = styled.div`
    img {
        width: 100%;
        object-fit: cover;
    }
    ${Media('tablet')} {
        padding: 0px 30px;
        padding-bottom: 50px;
    }
    ${Media('mobile')} {
        padding: 0px 15px;
    }
`;

const DreamTeamContent = styled.div`
    height: 100%;
    width: 100%;
    padding: 0px 130px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    span {
        font-size: 24px;
        font-family: ${gilroyMedium};
        color: ${colors.pink['100']};
        text-transform: uppercase;
    }
    ${Media('xlscreens')} {
        padding: 0 6.77vw;
        span {
            font-size: 1.25vw;
        }
    }
    ${Media('tablet')} {
        padding: 0px;
        margin-top: 50px;
        span {
            font-size: 20px;
        }
    }
    ${Media('mobile')} {
        margin-top: 30px;
        padding-bottom: 30px;
        span {
            font-size: 18px;
        }
    }
`;

const SectionHeading = styled.h3`
    margin-top: 30px;
    font-size: 45px;
    font-family: ${gilroySemibold};
    color: ${(props) =>
        props.white ? colors.white['100'] : colors.black['100']};
    text-align: ${(props) => (props.center ? 'center' : 'left')};
    span {
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        margin-top: 1.57vw;
        font-size: 2.3vw;
    }
    ${Media('tablet')} {
        font-size: 36px;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 26px;
    }
`;

const ProjectReady = styled.section`
    background-color: ${colors.white['100']};
    ${Media('mobile')} {
        padding: 30px 0;
    }
`;

const PlatformTools = styled.section`
    background-color: ${colors.socialRep['brandsBG']};
`;

const PlatformHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 99;
    p {
        background-color: ${colors.socialRep['brandsBG']};
        padding: 10px 40px;
    }
    &::before {
        height: 1px;
        width: 100%;
        background-color: ${colors.white['30']};
        content: '';
        position: absolute;
        z-index: -9;
    }
    ${Media('xlscreens')} {
        p {
            padding: 0.52vw 2.08vw;
        }
    }
`;

const Platforms = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;

const PlatformIcon = styled.img`
    object-fit: contain;
    margin: 20px 40px;
    height: ${(props) => props.height + 'px'};
    width: ${(props) => props.width + 'px'};
    ${Media('xlscreens')} {
        margin: 1.04vw 2.08vw;
        height: ${(props) => props.height / 19.2 + 'vw'};
        width: ${(props) => props.width / 19.2 + 'vw'};
    }
    ${Media('mobile')} {
        width: 100%;
    }
`;

const GlobalTalent = styled.section`
    background-color: ${colors.white['100']};
`;

const StaffAugMap = styled.div`
    margin-top: 90px;
    ${Media('xlscreens')} {
        margin-top: 4.68vw;
    }
`;

const MapImg = styled.div`
    position: relative;
    img {
        margin-top: 90px;
        width: 100%;
    }
    ${Media('xlscreens')} {
        width: 90%;
        margin: 0 auto;
        img {
            margin-top: 4.68vw;
            margin-bottom: 2vw;
        }
    }
    ${Media('tablet')} {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 50px;
        padding-top: 30px;
        img {
            display: none;
        }
    }
`;

const Tooltip = styled.div`
    background-color: ${colors.socialRep['brandsBG']};
    border-radius: 30px;
    padding: 3px 5px;
    display: flex;
    color: ${colors.white['100']};
    position: absolute;
    z-index: 99;
    height: 26px;
    width: 176px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: ${gilroyMedium};
    left: 60px;
    top: calc(50% - 14px);
    opacity: 0;
    visibility: hidden;
    transform: translateX(-10px);
    transition: all 0.3s ease-in-out;
    ${Media('xlscreens')} {
        border-radius: 1.57vw;
        height: 1.35vw;
        width: 9.166vw;
        font-size: 0.62vw;
        left: 5vw;
    }
    ${Media('tablet')} {
        visibility: visible;
        opacity: 1;
        width: 100%;
        border-radius: 0;
        left: 0;
        position: relative;
        padding-top: 40px;
        transform: translateX(0px);
        top: 0;
        padding-bottom: 10px;
        &::before {
            content: '${(props) => props.country}';
            position: absolute;
            top: 15px;
            display: block;
            font-size: 18px;
            font-family: ${gilroySemibold};
        }
    }
`;

const FlagImg = styled.img`
    height: 30px;
    width: 30px;
    ${Media('xlscreens')} {
        position: absolute;
        top: 1vw;
        margin: 0 !important;
        left: 2vw;
        height: 1.56vw;
        width: 1.56vw;
    }
`;

const StatTooltip = styled.div`
    position: absolute;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    top: ${(props) => props.top};
    left: ${(props) => props.left};
    right: ${(props) => props.right};
    bottom: ${(props) => props.bottom};
    &:hover {
        ${Tooltip} {
            visibility: visible;
            opacity: 1;
            transform: translateX(0);
        }
    }
    ${Media('xlscreens')} {
        height: 3.64vw;
        width: 3.64vw;
    }
    ${Media('tablet')} {
        position: relative;
        left: 0;
        top: 0;
        width: 47%;
        padding: 0px 10px;
        margin-bottom: 15px;
    }
    ${Media('mobile')} {
        width: 100%;
    }
`;

const Dot = styled.span`
    height: 5px;
    width: 5px;
    background-color: ${colors.white['100']};
    border-radius: 50%;
    margin: 0px 8px;
    ${Media('xlscreens')} {
        height: 0.26vw;
        width: 0.26vw;
        margin: 0 0.41vw;
    }
`;

const TalentStats = styled.div`
    display: flex;
    padding: 0px 150px;
    ${Media('xlscreens')} {
        padding: 0 7.8vw;
    }
    ${Media('tablet')} {
        padding: 0px 30px;
        flex-wrap: wrap;
    }
`;

const StatBox = styled.div`
    width: 20%;
    padding: 50px 93px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid ${colors.black['100']};
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    ${Media('xlscreens')} {
        padding: 2.6vw 4.84vw;
    }
    ${Media('tablet')} {
        width: 50%;
    }
    ${Media('mobile')} {
        width: 100%;
        margin-bottom: 15px;
    }
`;

const StatCount = styled.h5`
    margin: 0;
    font-size: 30px;
    font-family: ${gilroyBold};
    color: ${colors.pink['100']};
    margin-bottom: 10px;
    ${Media('xlscreens')} {
        font-size: 1.57vw;
        margin-bottom: 0.53vw;
    }
`;

export default StaffAugment;
