import React from 'react';
import SolutionBox from 'components/solution-box/solution-box';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';
import {
    TransformSection,
    Text,
    IndustryTag,
    IndustrySectionHeading,
    ContentCenter
} from 'components/industries/common';

export const TransformSolution = ({ retailSolutions }) => {
    return (
        <>
            <TransformSection
                tag="RETAIL CX THERAPY"
                sectionHeading="Your Retail & eCommerce <br />Digital Transformation Drop Team"
                img={`${baseURL}assets/images/industries/retail-digital-transform.jpg`}
                noBg
                noMarginTop
                para1="The digital era of Retail & eCommerce is
                driven by brand equity and hyper-engaging
                CX. Two-click shopping, white glove digital
                customer engagement, and cutting-edge user &
                support experiences are the hallmarks of
                successful eCommerce and Retail brands."
                para2="In a buyer’s market of mobile-first social
                CX, speed, disruption, and innovation are
                the norm in daily eCommerce business
                operations. Partnering with a CX operator
                who can push your speed, predict trends,
                innovate with you, and pivot quickly to meet
                ever-changing digital transformation
                requirements is critically important in
                delivering highly impactful CX in the
                consumer age of hyper-expectation and
                hyper-demand."
            />
            <CXSolution id="cx-solution">
                <Container>
                    <div>
                        <ContentCenter>
                            <IndustryTag>MAKE IT PREDICTABLE</IndustryTag>
                            <IndustrySectionHeading>
                                ibex builds ROI-optimized CX solutions <br />
                                for predictable retail success
                            </IndustrySectionHeading>
                            <Text>
                                From hyper-growth to burst capacity seasonal
                                complexity, <br />
                                ibex deploys the best in people, technology, and
                                methodology to deliver unmatched year-round CX.
                            </Text>
                        </ContentCenter>
                    </div>
                    <SolutionBoxWrapper>
                        {retailSolutions &&
                            retailSolutions.length > 0 &&
                            retailSolutions.map((solution, index) => {
                                return (
                                    <Col lg="3" md="6" key={index}>
                                        <SolutionBox
                                            title={solution.title}
                                            imageUrl={solution.imageUrl}
                                            para={solution.para}
                                        />
                                    </Col>
                                );
                            })}
                    </SolutionBoxWrapper>
                </Container>
            </CXSolution>
        </>
    );
};
const CXSolution = styled.section`
    background-color: ${colors.gray_lightest['100']};
    ${Media('tablet')} {
        padding: 50px 0;
    }
`;
const SolutionBoxWrapper = styled(Row)`
    min-height: 700px;
    ${Media('xlscreens')} {
        min-height: 36.4vw;
    }
`;