import React from 'react';
import { Container } from '@bootstrap-styled/v4';
import CaseArrowHeading from 'components/case-study/case-arrow-heading';
import SolutionBox from 'components/case-study/case-solution-box2';
import QuoteBox from 'components/case-study/case-quotebox';
import { ScrollWrapper, Tagline } from '../common';

export const SolutionQuoteSection = ({ solution, quote, main }) => {
    let solData = solution.solution_section;

    return (
        <>
            <ScrollWrapper>
                <Container>
                    <CaseArrowHeading heading={solution['heading_text']} />
                    <Tagline>{solution['subheading_text']}</Tagline>
                </Container>
                {solData &&
                    solData.length > 0 &&
                    solData.map((item, index) => {
                        return (
                            <SolutionBox
                                key={index}
                                solutionImage={item['section_image']}
                                withQoute={true}
                                imgRight={index % 2 !== 0}
                                firstSection={index === 0}
                                content={item['section_description']}
                            />
                        );
                    })}
            </ScrollWrapper>
            {quote['quote'] && (
                <QuoteBox
                    quotePara={quote['quote']}
                    quoteName={quote['name']}
                    quoteDesignation={quote['designation']}
                    authorImg={quote['picture']}
                    contentCenter={main['quote_center']}
                    fullWidth={main['quote_full_width']}
                    noPaddingBottom
                />
            )}
        </>
    );
};
