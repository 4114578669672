import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import { gilroyBold, gilroyMedium } from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';
import colors from 'shared-components/theme/colors';
const LeadershipPerson = ({ image, name, designation, openPopup }) => {
    return (
        <div>
            <Wrapper onClick={() => openPopup()}>
                <ImageWrapper>
                    <img src={image} alt="" />
                </ImageWrapper>
                <PersonName>{name}</PersonName>
                <Designation>{designation}</Designation>
            </Wrapper>
        </div>
    );
};

const ImageWrapper = styled.div`
    width: 100%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: all 0.5s ease;
    }
`;
const PersonName = styled.div`
    padding-top: 20px;
    padding-bottom: 5px;
    font-family: ${gilroyBold};
    font-size: 20px;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    ${Media('xlscreens')} {
        padding-top: 1.05vw;
        padding-bottom: 0.3vw;
        font-size: 1.05vw;
    }
`;
const Wrapper = styled.div`
    margin: 0px 15px 30px;
    flex: 0 0 230px;
    max-width: 230px;
    cursor: pointer;
    &:hover {
        ${ImageWrapper} {
            img {
                transform: scale(1.2);
            }
        }
        ${PersonName} {
            color: ${colors.pink['100']};
        }
    }
    ${Media('xlscreens')} {
        flex: 0 0 12.35vw;
        max-width: 12.35vw;
        margin: 0 1.4vw 2.8vw;
    }
    ${Media('tabletlandscape')} {
        max-width: 320px;
    }
    ${Media('mobile')} {
        max-width: 100%;
        margin-bottom: 50px;
    }
`;

const Designation = styled.div`
    font-family: ${gilroyMedium};
    font-size: 16px;
    white-space: initial;
    max-width: 100%;
    ${Media('xlscreens')} {
        font-size: 0.85vw;
    }
`;
export default LeadershipPerson;
