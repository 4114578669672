import React from 'react';
import LazyLoad from 'react-lazyload';
import Layout from 'components/layout';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import {
    BundleOffers,
    BundleHowItWorks,
    BundleIntro,
    SearchCompareOrder,
} from './sections';
import Loader from 'components/loader/loader';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -170,
    },
    {
        name: 'searchcompare',
        target: 'searchcompare',
        offset: 0,
    },
    {
        name: 'offers',
        target: 'offers',
        offset: 0,
    },
    {
        name: 'howitworks',
        target: 'howitworks',
        offset: 0,
    },
];

const lazySections = [
    {
        id: 'home',
        section: <BundleIntro />,
    },
    {
        id: 'searchcompare',
        section: <SearchCompareOrder />,
    },
    {
        id: 'offers',
        section: <BundleOffers />,
    },
    {
        id: 'howitworks',
        section: <BundleHowItWorks />,
    },
];

const BundleDealer = () => {
    return (
        <Layout>
            {lazySections && lazySections.length > 0
                ? lazySections.map((item, index) => {
                      return (
                          <div id={item.id} key={index}>
                               {lazySections.length === index + 1 ? (
                                      <ScrollNav data={scrollNavData} />
                                  ) : null}
                                  {item.section}
                          </div>
                      );
                  })
                : null}
        </Layout>
    );
};

export default BundleDealer;
