import React from 'react';
import { Redirect } from 'react-router-dom';

const CommunityBankMemberExp1 = () => {
    return (
        <Redirect
            to={`/resources/ebooks/community-bank-digital-customer-experience-part-1`}
        />
    );
};

export default CommunityBankMemberExp1;
