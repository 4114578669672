import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import { gilroyBold, gilroyMedium } from 'shared-components/theme/fonts';
import colors from 'shared-components/theme/colors';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';

const Retail = ({ imageUrl, title, para, bigTitle, noImageOverlay }) => {
    return (
        <SolBoxWrapper>
            <SolInnerBox>
                <SolFeatImg noImageOverlay={noImageOverlay}>
                    <img src={imageUrl} alt="-" />
                </SolFeatImg>
                <SolTextWrapper>
                    <SolFeatTitle bigTitle={bigTitle}>
                        {ReactHtmlParser(title)}
                    </SolFeatTitle>
                    <SolPara bigTitle={bigTitle}>
                        {ReactHtmlParser(para)}
                    </SolPara>
                </SolTextWrapper>
            </SolInnerBox>
        </SolBoxWrapper>
    );
};
const SolBoxWrapper = styled.div`
    height: 100%;
    ${Media('tablet')} {
        padding-bottom: 30px;
    }
`;

const SolFeatImg = styled(FlexCentered)`
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    height: 220px;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all 0.3s ease;
    }
    &:after {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: ${colors.black['30']};
        content: '';
        display: ${(props) => props.noImageOverlay && 'none'};
    }
    ${Media('xlscreens')} {
        height: 11.5vw;
        border-radius: 0.52vw;
    }
`;
const SolFeatTitle = styled.h3`
    font-size: ${(props) => (props.bigTitle ? '22px' : '20px')};
    font-family: ${gilroyBold};
    line-height: 1.2;
    margin: 60px 0;
    text-transform: capitalize;
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.bigTitle ? '1.2vw' : '.9vw')};
        margin: 0;
        min-height: ${(props) => (props.bigTitle ? '8vw' : '9vw')};
        max-width: ${(props) => (props.bigTitle ? 'none' : '11.8vw')};
        display: flex;
        align-items: center;
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        margin: 30px 0;
    }
`;
const SolTextWrapper = styled.div`
    padding: 0px 10px;
    ${Media('xlscreens')} {
        padding: 0 0.52vw;
    }
`;
const SolInnerBox = styled.div`
    background-color: ${colors.white['100']};
    border-radius: 15px;
    overflow: hidden;
    transition: all 330ms ease;
    box-shadow: 0px 3px 66px ${colors.black['10']};
    padding: 30px;
    height: 100%;
    ${Media('xlscreens')} {
        &:hover {
            box-shadow: 0px 3px 66px ${colors.black['20']};
            transform: translateY(-10px);
            ${SolFeatImg} {
                img {
                    height: 120%;
                    width: 120%;
                }
            }
        }
        border-radius: 0.78vw;
        padding: 1.56vw;
    }
`;
const SolPara = styled.p`
    font-size: 14px;
    font-family: ${gilroyMedium};
    color: ${colors.dark_brown['100']};
    margin-top: 0;
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.bigTitle ? '0.98vw' : '0.83vw')};
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
`;
export default Retail;
