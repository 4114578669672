import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import { Parallax } from 'react-scroll-parallax';
import Media from 'shared-components/theme/medias';
import { UtilPagePara, UtilStepHeading, UtilTag } from '../common';
import MainHeading from 'shared-components/main-heading/main-heading';
import { gilroyMedium } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

export const UtilStepOneSection = () => {
    return (
        <UtilStepFirst>
            <Container>
                <Row>
                    <Col lg="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <UtilStepHeading>
                                <UtilTag>01</UtilTag>
                                <MainHeading noMargin={true}>
                                    Understanding the <br /> New Utility
                                    Customer Mindset
                                </MainHeading>
                            </UtilStepHeading>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <UtilPagePara>
                                <span>First Call Sets The Tone.</span> Some
                                utilities choose mover programs that consider
                                the move to be a stand-alone event. These
                                programs generally point new customers away from
                                the utility brand into other brands ecosystems
                                to sell a few home services. These programs
                                focus on a sale, not on the customer, and don’t
                                really take all of the new customer’s challenges
                                into consideration. A solitary mover experience
                                may not close the loop to reinforce the
                                utility’s relationship with their customer, due
                                to the transactional nature of those programs.
                            </UtilPagePara>
                            <UtilPagePara>
                                <span>Customers Value problem solvers.</span> We
                                recommend a partner and a program that really
                                understands all of the challenges facing new
                                utility customers, and focuses a multi-channel
                                approach to engagement and support. By doing so,
                                the Utility receives the dual benefit of
                                increasing CSAT and of being perceived as a
                                problem solver during a stressful moving event
                                for their customers.
                            </UtilPagePara>
                        </ScrollAnimation>
                    </Col>
                    <Col lg="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <UtilCallHead>
                                Utility Call Starts It All
                            </UtilCallHead>
                            <UtilCallStat>
                                <img
                                    src={`${baseURL}assets/images/util-call-img.svg`}
                                    alt="-"
                                />
                            </UtilCallStat>
                        </ScrollAnimation>
                    </Col>
                    <Col lg="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <UtilPagePara>
                                As you can see, that first call to the utility
                                marks just the first step in a predictable
                                series of tasks that new customers need to
                                tackle during the move. Understanding that
                                sequence and connecting new customers with quick
                                and easy solutions to those challenges amplifies
                                the value new customers receive from their
                                utility, and delivers much needed value add
                                benefits simply for being a new utility
                                customer.
                            </UtilPagePara>
                            <UtilPagePara>
                                <span>Delivering that “WOW” moment</span> really
                                requires the utility new connection partner
                                delivers more than just a few home services, but
                                demonstrates the ability to anticipate needs
                                across the entire customer lifecycle. New
                                Connection programs that help with tasks before,
                                during and after the move extends the connection
                                between the utility and their new customers
                                weeks following the move, and sets up a
                                foundation for long term customer engagement.
                                Best of all, by solving additional challenges
                                for new customers, utilities benefit from the
                                positive sentiment of the extra help. The best
                                New Connection programs prove utilities go
                                “above and beyond” by delivering a personalized
                                first experience that really understands their
                                new customers.
                            </UtilPagePara>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
            <BigTriangle x={['-5%', '10%']}>
                <img
                    src={`${baseURL}assets/images/big-triangle.svg`}
                    alt="-"
                />
            </BigTriangle>
        </UtilStepFirst>
    );
};

const BigTriangle = styled(Parallax)`
    position: absolute;
    width: 300px;
    left: -10%;
    bottom: 10%;
    z-index: 99;
    ${Media('xlscreens')} {
        width: 20vw;
        bottom: -9vw;
        left: -2vw;
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const UtilStepFirst = styled.section`
    position: relative;
    ${Media('xlscreens')} {
        padding-top: 7vw;
        padding-bottom: 10vw;
    }
`;

const UtilCallHead = styled.h3`
    font-size: 33px;
    font-family: ${gilroyMedium};
    margin-bottom: 15px;
    text-align: center;
    letter-spacing: -0.5px;
    ${Media('xlscreens')} {
        font-size: 2.1vw;
        margin-top: 3vw;
    }
`;

const UtilCallStat = styled.div`
    margin: 50px 0px 80px;
    img {
        width: 100%;
    }
    ${Media('xlscreens')} {
        margin: 0 -6vw 6vw;
        img {
            margin-top: 3vw;
        }
    }
`;
