import React from 'react';
import { baseURL } from 'shared-components/theme/helpers';
import { BigHeading } from './big-heading';
import { BlockHeading } from './block-heading';
import { CXSectionIntro } from './cx-section-intro';
import { Para } from './para';

export const CXPoint4 = ({ active }) => {
    return (
        <div>
            <CXSectionIntro
                active={active}
                section4
                source="Source: Customer Contact Central"
                progressImg={`${baseURL}assets/images/ebook/cx-progress4.svg`}
                introPara="Alarmingly, <span>60% of agents</span> agree that their contact center doesn’t always provide the technology that staff needs to address the challenges they face when helping customers."
            />
            <BigHeading>
                Your outsourcer should be invested in technologies that create
                opportunities for agents to perform better and faster. Beyond
                the agent experience, an outsourcer should be investing in the
                latest in CX technology, as well as technology to help their own
                internal processes run more efficiently.
            </BigHeading>
            <Para>Consider the following:</Para>
            <BlockHeading>Hiring Automation</BlockHeading>
            <Para>
                Organizations that use end-to-end recruitment technology can
                increase productivity, accelerate time-to-fill, reduce hiring
                costs, improve the candidate experience, and hire better quality
                talent.
            </Para>
            <BlockHeading>Performance Coaching</BlockHeading>
            <Para>
                Using performance coaching technologies can help more quickly
                target and identify personalized, real-time coaching
                opportunities for every agent, ensuring agile, continuously
                improving programs.
            </Para>
            <BlockHeading>Performance Gamification</BlockHeading>
            <Para>
                Performance gamification technology can help integrate real-time
                coaching and performance analytics with incentives for
                improvement. Agents are in it to win it, literally, when they
                are rewarded for their performance.
            </Para>
            <BlockHeading>Floor Management Systems</BlockHeading>
            <Para>
                Outsourcers that leverage floor management technology can
                provide digital escalation to enable faster issue resolution,
                increased agent productivity, optimized efficiency, and better
                CX outcomes due to more transparency, real-time agent support,
                and collaboration.
            </Para>
        </div>
    );
};
