import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-scroll';
import { baseURL } from 'shared-components/theme/helpers';
import { EbookFloatingImage } from 'components/ebook/common';
import { SAPageHeading, SAPagePara } from '../common';

const workList = [
    {
        list: 'One Page NDA to begin the process ',
    },
    {
        list:
            'Low Risk - No liability until you like and want to keep a resource ',
    },
    {
        list: 'Simple and efficient SOW ',
    },
    {
        list: 'World-class ibex security standards ',
    },

    {
        list: 'Offshore and @Home delivery',
    },
];

const staffAug = [
    {
        imageUrl: `${baseURL}assets/images/staff_aug/stf1.png`,
        name: 'Extended Teams and Culture',
        desc: '100%',
    },
    {
        imageUrl: `${baseURL}assets/images/staff_aug/stf2.png`,
        name: 'No Recruitment or Rehiring Cost ',
        desc: 'save 100%',
    },
    {
        imageUrl: `${baseURL}assets/images/staff_aug/stf3.png`,
        name: 'Reduces Cost of Keeping Employees In-House ',
        desc: 'save up to 60%',
    },
    {
        imageUrl: `${baseURL}assets/images/staff_aug/stf4.png`,
        name: 'No Facilities or Infrastructure Cost ',
        desc: 'save 100%',
    },
    {
        imageUrl: `${baseURL}assets/images/staff_aug/stf5.png`,
        name: 'Reduces Time Spent on Recruitment ',
        desc: '7 – 15 days ',
    },
    {
        imageUrl: `${baseURL}assets/images/staff_aug/stf6.png`,
        name: 'Extensive Geographic Reach to Fill Gaps ',
        desc: '4 global locations',
    },
];

const ManagedServices = [
    {
        imageUrl: `${baseURL}assets/images/staff_aug/mng1.svg`,
        name:
            'ibex Assumes Control Over Execution So That You Focus On Other Priorities ',
    },
    {
        imageUrl: `${baseURL}assets/images/staff_aug/mng2.svg`,
        name: 'SLA Commitments',
    },
    {
        imageUrl: `${baseURL}assets/images/staff_aug/mng3.svg`,
        name: 'Committed Scope <br /> And Term',
    },
    {
        imageUrl: `${baseURL}assets/images/staff_aug/mng4.svg`,
        name: 'Hourly Rates ',
    },

    {
        imageUrl: `${baseURL}assets/images/staff_aug/mng5.svg`,
        name: 'Tools & Process By Ibex. ',
    },
    {
        imageUrl: `${baseURL}assets/images/staff_aug/mng6.svg`,
        name: 'Operational Assets Managed By ibex ',
    },
];

export const SAWorkSection = () => {
    return (
        <>
            <WorkSection id="work">
                <Container>
                    <Row>
                        <Col lg="7">
                            <WorkText>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <SAPageHeading size="large">
                                        Ready to work.
                                    </SAPageHeading>
                                    <SAPagePara>
                                        Find out which solution works best for
                                        your needs.
                                    </SAPagePara>
                                    <WorkLinks>
                                        <Link
                                            to="staffAug"
                                            smooth={true}
                                            offset={-50}>
                                            Staff Augmentation
                                        </Link>
                                        <Link
                                            to="manageServices"
                                            smooth={true}
                                            offset={-50}>
                                            Managed Services
                                        </Link>
                                    </WorkLinks>
                                    <ul>
                                        {workList &&
                                            workList.length > 0 &&
                                            workList.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="32.7"
                                                            height="24.525"
                                                            viewBox="0 0 32.7 24.525">
                                                            <path
                                                                id="Path_1405"
                                                                data-name="Path 1405"
                                                                d="M0,77.3,11.2,88.524,32.7,67.043,29.613,64,11.2,82.393,3.044,74.239Z"
                                                                transform="translate(0 -63.999)"
                                                                fill={
                                                                    colors.pink[
                                                                        '100'
                                                                    ]
                                                                }
                                                            />
                                                        </svg>
                                                        <SAPagePara>
                                                            {item.list}
                                                        </SAPagePara>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </ScrollAnimation>
                            </WorkText>
                        </Col>
                        <Col lg="5">
                            <WorkImg>
                                <EbookFloatingImage
                                    image={`${baseURL}assets/images/staff_aug/staff-work-dots.svg`}
                                    width={428}
                                    right="4%"
                                    bottom="-6%"
                                    x={['-20%', '20%']}
                                />
                                <ScrollAnimation
                                    animateIn="fadeInRight"
                                    animateOnce={false}>
                                    <img
                                        src={`${baseURL}assets/images/staff_aug/work-img.png`}
                                        alt="-"
                                    />
                                </ScrollAnimation>
                            </WorkImg>
                        </Col>
                    </Row>
                </Container>
            </WorkSection>

            <StaffAugSection id="staffAug">
                <Container>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <SAPageHeading size="large" align="center">
                            Staff Augmentation
                        </SAPageHeading>
                        <SAPagePara align="center">
                            It’s just like hiring locally! You share your
                            requirements with us, and our specialists select the
                            best fit candidates for you.
                        </SAPagePara>
                    </ScrollAnimation>
                    <StaffBoxRow>
                        {staffAug &&
                            staffAug.length > 0 &&
                            staffAug.map((item, index) => {
                                return (
                                    <StaffBoxCol key={index} md="6" lg="4">
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={false}>
                                            <StaffBox>
                                                <img
                                                    src={item.imageUrl}
                                                    alt="-"
                                                />
                                                <h5>{item.name}</h5>
                                                <SAPagePara
                                                    color={colors.white['100']}>
                                                    {item.desc}
                                                </SAPagePara>
                                            </StaffBox>
                                        </ScrollAnimation>
                                    </StaffBoxCol>
                                );
                            })}
                    </StaffBoxRow>
                </Container>
            </StaffAugSection>

            <ManageServSection id="manageServices">
                <Container>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <SAPageHeading size="large" align="center">
                            Managed Services
                        </SAPageHeading>
                        <SAPagePara align="center">
                            It’s just like outsourcing the results. ibex will be
                            responsible for the delivery.
                        </SAPagePara>
                    </ScrollAnimation>
                    <StaffBoxRow>
                        {ManagedServices &&
                            ManagedServices.length > 0 &&
                            ManagedServices.map((item, index) => {
                                return (
                                    <StaffBoxCol key={index} md="6" lg="4">
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={false}>
                                            <ManagedBox>
                                                <img
                                                    src={item.imageUrl}
                                                    alt="-"
                                                />
                                                <h5>
                                                    {ReactHtmlParser(item.name)}
                                                </h5>
                                            </ManagedBox>
                                        </ScrollAnimation>
                                    </StaffBoxCol>
                                );
                            })}
                    </StaffBoxRow>
                </Container>
            </ManageServSection>
        </>
    );
};

const WorkSection = styled.section`
    ${Media('xlscreens')} {
        .container {
            padding-right: 8vw !important;
        }
    }
`;
const WorkText = styled.div`
    ul {
        padding: 0;
        margin-top: 55px;
        li {
            display: flex;
            margin-bottom: 12px;
            font-size: 21px;
            align-items: center;
            svg {
                height: 13px;
                margin-right: 12px;
            }
        }
    }
    ${Media('xlscreens')} {
        ul {
            margin-top: 6vw;
            li {
                margin-bottom: 1vw;
                font-size: 1.09vw;
                svg {
                    height: 1.25vw;
                    margin-right: 1.5vw;
                }
            }
        }
        h2 + p {
            font-size: 1.45vw;
        }
    }
    ${Media('tablet')} {
        ul {
            li {
                svg {
                    flex: 0 0 30px;
                }
            }
        }
    }
`;
const WorkLinks = styled.div`
    display: flex;
    align-items: center;
    padding-top: 30px;
    a {
        text-transform: uppercase;
        font-family: ${gilroySemibold};
        font-size: 21px;
        border: 2px solid ${colors.fintech['text']};
        margin-right: 15px;
        padding: 10px 20px;
        position: relative;
        z-index: 0;
        overflow: hidden;
        &:last-child {
            margin-right: 0px !important;
        }
        &:before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: ${colors.fintech['text']};
            transform: translateX(-100%);
            transition: all 330ms ease;
        }
        &:hover {
            color: ${colors.white['100']};
            &:before {
                transform: translateX(0%);
            }
        }
    }
    ${Media('xlscreens')} {
        padding-top: 2.5vw;
        a {
            font-size: 1.09vw;
            margin-right: 2vw;
            padding: 1.2vw 2.8vw;
        }
    }
    ${Media('tablet')} {
        a {
            font-size: 18px;
        }
    }
    ${Media('mobile')} {
        flex-direction: column;
        a {
            width: 100%;
            margin: 10px 0px;
            text-align: center;
        }
    }
`;
const WorkImg = styled.div`
    position: relative;
    padding-top: 30px;
    ${Media('xlscreens')} {
        padding-top: 1vw;
    }
    ${Media('tablet')} {
        img {
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
        }
    }
`;

const StaffAugSection = styled.section`
    ${Media('xlscreens')} {
        .container {
            padding-left: 8vw !important;
            padding-right: 8vw !important;
        }
    }
`;
const StaffBoxRow = styled(Row)`
    ${Media('xlscreens')} {
        margin-left: -2.42vw !important;
        margin-right: -2.42vw !important;
        padding-top: 1vw;
    }
`;
const StaffBoxCol = styled(Col)`
    padding-top: 50px;
    ${Media('xlscreens')} {
        padding-left: 2.42vw !important;
        padding-right: 2.42vw !important;
        padding-top: 3.125vw;
    }
`;
const StaffBox = styled.div`
    background-color: ${colors.fintech['text']};
    padding: 30px;
    text-align: center;
    min-height: 291px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h5 {
        font-size: 25px;
        margin-top: 20px;
        color: ${colors.white['100']};
        flex: 1 0;
    }
    p {
        letter-spacing: -1px;
        line-height: 1;
    }
    img {
        margin: 0 auto;
    }
    ${Media('xlscreens')} {
        padding: 2vw;
        min-height: 15.15vw;
        img {
            max-height: 3.125vw;
            max-width: 3.65vw;
        }
        h5 {
            font-size: 1.35vw;
            margin-top: 1.04vw;
        }
        p {
            letter-spacing: -0.02vw;
        }
    }
    ${Media('tablet')} {
        min-height: 250px;
        img {
            max-width: 50px;
            max-height: 50px;
        }
        h5 {
            font-size: 20px;
        }
    }
    ${Media('mobile')} {
        min-height: 220px;
    }
`;

const ManageServSection = styled(StaffAugSection)`
    background-color: ${colors.fintech['text']}0A;
    ${Media('mobile')} {
        padding-bottom: 0px;
    }
`;

const ManagedBox = styled(StaffBox)`
    background-color: ${colors.white['100']};
    border: 1px solid ${colors.staffAug['greyBorder']};
    justify-content: center;
    h5 {
        color: ${colors.fintech['text']};
        margin-bottom: 0px;
        flex-grow: 0;
        min-height: 80px;
    }
    ${Media('xlscreens')} {
        padding: 2.8vw 3.3vw;
        h5 {
            min-height: 4.1vw;
        }
    }
    ${Media('tablet')} {
        min-height: 200px;
        h5 {
            min-height: 1px;
        }
    }
`;
