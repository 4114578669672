import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { gilroySemibold } from 'shared-components/theme/fonts';

const CaseBigText = ({ textOne, textTwo, textColor, reverseDirection }) => {
    return (
        <BigText id="bigText">
            <Parallax
                x={reverseDirection ? ['100%', '-110%'] : ['-110%', '100%']}>
                <OneText id="bigTextOne" textColor={textColor}>
                    {textOne}
                </OneText>
            </Parallax>
            <Parallax x={reverseDirection ? ['-100%', '5%'] : ['5%', '-100%']}>
                <TwoText id="bigTextTwo" textColor={textColor}>
                    {textTwo}
                </TwoText>
            </Parallax>
        </BigText>
    );
};
const BigText = styled.div`
    font-size: 300px;
    font-family: ${gilroySemibold};
    overflow: hidden;
    line-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    letter-spacing: -0.15px;
    span {
        white-space: nowrap;
        position: relative;
    }
    ${Media('xlscreens')} {
        width: 100%;
        font-size: 15.7vw;
        line-height: 15.6vw;
    }
    ${Media('tablet')} {
        font-size: 140px;
    }
    ${Media('mobile')} {
        font-size: 80px;
        line-height: 1;
    }
`;
const OneText = styled.span`
    display: inline-flex;
    align-self: flex-start;
    left: -2%;
    color: ${(props) =>
        props.textColor ? props.textColor : colors.gray_dark['100']};
`;
const TwoText = styled.span`
    display: inline-flex;
    align-self: flex-start;
    left: 35%;
    color: ${(props) =>
        props.textColor ? props.textColor : colors.gray_dark['100']};
`;
export default CaseBigText;
