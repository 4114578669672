import React from 'react';
import Typical from 'react-typical';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { gilroySemibold, gilroyBold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

const voiceBoxItems = [
    {
        colOffset: null,
        colSize: 3,
        imgSrc: `${baseURL}assets/images/voice-support.svg`,
        heading: 'Voice Support',
        animationDelay: 0,
    },
    {
        colOffset: null,
        colSize: 3,
        imgSrc: `${baseURL}assets/images/chat-support.svg`,
        heading: 'Chat Support',
        animationDelay: 200,
    },
    {
        colOffset: null,
        colSize: 3,
        imgSrc: `${baseURL}assets/images/email-support.svg`,
        heading: 'Email Support',
        animationDelay: 300,
    },
    {
        colOffset: null,
        colSize: 3,
        imgSrc: `${baseURL}assets/images/technical-support.svg`,
        heading: 'Technical Support',
        animationDelay: 400,
    },
    {
        colOffset: 1,
        colSize: 3,
        imgSrc: `${baseURL}assets/images/telesales.svg`,
        heading: 'Telesales',
        animationDelay: 50,
    },
    {
        colOffset: 0,
        colSize: 3,
        imgSrc: `${baseURL}assets/images/backoffice.svg`,
        heading: 'Back-Office',
        animationDelay: 150,
    },
    {
        colOffset: 0,
        colSize: 4,
        imgSrc: `${baseURL}assets/images/content-moderation.svg`,
        heading: 'Content Moderation',
        animationDelay: 250,
    },
];
export const SupportSection = () => {
    return (
        <>
            <ThousandSmiles id="thousandSmiles">
                <Container>
                    <Row>
                        <Col lg="6">
                            <div>
                                <h2>A thousand smiles a second.</h2>
                            </div>
                        </Col>
                        <Col lg="6" style={{ zIndex: '4' }}>
                            <div>
                                <ChatBox>
                                    <p>
                                        At ibex, our connected agents and
                                        analytical A.I. work hand-in-hand to
                                        deliver the pinnacle in disruptive,
                                        predictive, and outcome-oriented
                                        customer experiences,  providing the ultimate in cost reduction, brand protection, and increasing revenues.
                                    </p>
                                </ChatBox>
                            </div>
                        </Col>
                        <Col lg="12">
                            <ImageContainerBox>
                                <Controller>
                                    <Scene
                                        triggerHook="onEnter"
                                        duration="80%"
                                        offset="140%">
                                        <Timeline>
                                            <Tween
                                                from={{
                                                    width: '50vw',
                                                    height: '50vw',
                                                    borderRadius: '50%',
                                                }}
                                                to={{
                                                    width: '100%',
                                                    borderRadius: '0%',
                                                }}>
                                                <ImgHolderThousandSmiles />
                                            </Tween>
                                        </Timeline>
                                    </Scene>
                                </Controller>
                            </ImageContainerBox>
                        </Col>
                    </Row>
                    <Row noGutters={true}>
                        <Col lg={{ size: 10, offset: 1 }}>
                            <Row noGutters={true}>
                                {voiceBoxItems &&
                                    voiceBoxItems.map((item, index) => (
                                        <Col
                                            lg={
                                                (item.colOffset && {
                                                    size: item.colSize,
                                                    offset: item.colOffset,
                                                }) ||
                                                item.colSize
                                            }
                                            key={index}>
                                            <div>
                                                <VoiceBox>
                                                    <ImageWrapper>
                                                        <img
                                                            src={item.imgSrc}
                                                            alt=""
                                                        />
                                                    </ImageWrapper>
                                                    <h4>{item.heading}</h4>
                                                </VoiceBox>
                                            </div>
                                        </Col>
                                    ))}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </ThousandSmiles>
            <div>
                <AgentSection>
                    <Container>
                        <Row>
                            <Col lg="12">
                                <AgentBox>
                                    <Row>
                                        <Col lg="6">
                                            <AgentSectionTopLeft>
                                                <TopSection>
                                                    <h2>
                                                        There’s an agent for
                                                        that.
                                                    </h2>
                                                    <p>
                                                        Trained on powerful
                                                        simulation tools and
                                                        equipped with A.I.
                                                        augmented workstations,
                                                        our award-winning agents
                                                        are trained and ready to
                                                        protect your brand,
                                                        elevate your CX, and
                                                        turn cost centers into
                                                        profit centers.&nbsp;
                                                    </p>
                                                </TopSection>
                                                <SimpleTextBox>
                                                    <h3>
                                                        We train & motivate
                                                        highly engaged
                                                        <br />
                                                        brand support champions.
                                                    </h3>
                                                    <p>
                                                        We are highly selective
                                                        in recruiting our global
                                                        agent force, resulting
                                                        in highly meaningful and
                                                        effective interactions,
                                                        across 20+ languages.
                                                    </p>
                                                </SimpleTextBox>
                                            </AgentSectionTopLeft>
                                        </Col>
                                        <Col lg="6">
                                            <AgentImage>
                                                <img
                                                    src={`${baseURL}assets/images/agent-bg.png`}
                                                    alt=""
                                                />
                                            </AgentImage>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <SimpleTextBox2>
                                                <h3>
                                                    We build customer trust, and
                                                    seamlessly <br />
                                                    transition service to sales.
                                                </h3>
                                                <p>
                                                    You can’t upsell a customer
                                                    if they don’t trust you. We
                                                    make sure our agents
                                                    exemplify the best in your
                                                    brand, quickly and
                                                    effectively solve issues on
                                                    the first pass, then set the
                                                    table for what to sell next.
                                                </p>
                                            </SimpleTextBox2>
                                        </Col>
                                        <Col lg="6">
                                            <SimpleTextBox3>
                                                <h3>
                                                    We drive sophisticated
                                                    retention and win-back
                                                    programs to keep customers
                                                    in your ecosystem.
                                                </h3>
                                                <p>
                                                    Successful customer
                                                    retention is a key factor in
                                                    ongoing brand revenue,
                                                    performance, and profit. We
                                                    understand how important a
                                                    positive interaction can be,
                                                    and how vocal a lost
                                                    customer is. We work to
                                                    ensure that an angry
                                                    customer doesn’t turn into a
                                                    lost customer.
                                                </p>
                                            </SimpleTextBox3>
                                        </Col>
                                    </Row>
                                </AgentBox>
                            </Col>
                        </Row>
                    </Container>
                </AgentSection>
            </div>
        </>
    );
};
const ImgHolderThousandSmiles = styled.div`
    background: url('${baseURL}assets/images/thousan-smiles-bg.png');
    background-position: center;
    background-size: cover;
    margin: 0 auto;
`;
const ThousandSmiles = styled.section`
    padding-top: 0px;
    padding-bottom: 60px;
    h2 {
        font-size: 60px;
    }
    ${Media('xlscreens')} {
        padding-bottom: 4vw;
        h2 {
            font-size: 3.5vw;
        }
    }
    ${Media('tablet')} {
        h2 {
            font-size: 36px;
        }
    }
`;

const ChatBox = styled.div`
    background-color: ${colors.darkblue['100']};
    border-radius: 17px;
    padding: 30px 50px 40px;
    position: relative;
    top: 90px;
    z-index: 1;
    right: -50px;
    &::before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 30px 30px 30px;
        border-color: transparent transparent ${colors.darkblue['100']}
            transparent;
        position: absolute;
        bottom: 2px;
        left: -13px;
        transform: rotate(-21deg);
    }
    p {
        color: ${colors.white['100']};
    }
    ${Media('tablet')} {
        top: 30px;
        right: -15px;
        margin-left: 40px;
        padding: 20px 40px;
    }
`;

const ImageContainerBox = styled.div`
    width: 100%;
    margin-top: 50px;
    margin-bottom: 60px;
    img {
        width: 100%;
    }
    ${Media('xlscreens')} {
        margin-bottom: 3.6vw;
    }
    ${Media('tablet')} {
        margin-top: 0px;
    }
`;

const AgentSection = styled.section``;

const AgentBox = styled.div`
    background-color: ${colors.white['100']};
    padding: 50px 40px 50px 50px;
    h2 {
        font-size: 3.7vw;
        font-family: ${gilroySemibold};
    }
    p {
        margin-bottom: 50px;
    }
    ${Media('xlscreens')} {
        padding: 6.8vh 4.8vw 6.8vh 6.8vw;
    }
    ${Media('tablet')} {
        padding: 30px;
        h2 {
            font-size: 36px;
        }
    }
`;
const TopSection = styled.div``;
const AgentSectionTopLeft = styled.div`
    ${Media('xlscreens')} {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
    }
`;
const AgentImage = styled.div`
    position: relative;
    top: -90px;
    img {
        width: 40vw;
    }
    ${Media('xlscreens')} {
        top: -10vh;
    }
    ${Media('tablet')} {
        display: none;
    }
`;
const VoiceBox = styled.div`
    height: 120px;
    display: flex;
    border: 1px solid ${colors.gray_dark['10']};
    width: 100%;
    justify-content: center;
    align-items: center;
    h4 {
        font-size: 25px;
        margin: 0;
        font-family: ${gilroySemibold};
        ${Media('xlscreens')} {
            font-size: 1.2vw;
        }
    }
    ${Media('xlscreens')} {
        height: 6.4vw;
        h4 {
            font-size: 1.1vw;
        }
    }
    ${Media('mobile')} {
        height: auto;
        padding: 20px 0px;
        justify-content: flex-start;
        padding-left: 60px;
        h4 {
            font-size: 18px;
        }
        img {
            width: 45px;
        }
    }
`;
const ImageWrapper = styled.div`
    height: 30px;
    width: 30px;
    margin-right: 15px;
    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    ${Media('xlscreens')} {
        height: 1.58vw;
        width: 1.58vw;
    }
`;
const SimpleTextBox = styled.div`
    margin: 40px 0;
    h3 {
        font-size: 28px;
        font-family: ${gilroyBold};
    }
    ${Media('xlscreens')} {
        padding-right: 3vw;
        margin: 0px;
        h3 {
            font-size: 1.26vw;
            padding-bottom: 1vh;
        }
    }
    ${Media('tablet')} {
        margin: 0px 0px 40px;
        h3 {
            margin-top: 0px;
        }
    }
    ${Media('mobile')} {
        h3 {
            font-size: 22px;
            br {
                display: none;
            }
        }
    }
`;
const SimpleTextBox2 = styled(SimpleTextBox)`
    ${Media('xlscreens')} {
        padding-top: 0px;
    }
`;
const SimpleTextBox3 = styled(SimpleTextBox)`
    ${Media('xlscreens')} {
        padding-left: 2vw;
        padding-top: 0px;
    }
`;
