import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { gilroyRegular } from 'shared-components/theme/fonts';

export const WebinarSubHeading = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.h5`
    font-size: 30px;
    margin: 0;
    color: ${colors.white['100']};
    font-family: ${gilroyRegular};
    font-weight: normal;
    ${Media('xlscreens')} {
        font-size: 1.57vw;
    }
    ${Media('tablet')} {
        font-size: 20px;
    }
`;
