import React from 'react';
import Layout from 'components/layout';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';

import { gilroySemibold, gilroyBold } from 'shared-components/theme/fonts';
import MainHeading from 'shared-components/main-heading/main-heading';
import MainPara from 'shared-components/main-para/main-para';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import ReactHtmlParser from 'react-html-parser';
import { baseURL } from 'shared-components/theme/helpers';

const adCastHelp = [
    {
        borderRight: true,
        imgSrc: `${baseURL}assets/images/tailofb.svg`,
        para: 'Tailor every Facebook <br />ad parameter',
    },
    {
        borderRight: true,
        imgSrc: `${baseURL}assets/images/develop-highly.svg`,
        para: 'Develop highly-segmented <br />and targeted campaigns.',
    },
    {
        borderRight: false,
        imgSrc: `${baseURL}assets/images/bulk-launch.svg`,
        para: 'Bulk launch thousands <br/>of ads at once.',
    },
];

const campaignItems = [
    {
        heading: '3-Level Campaign Management:',
        items: [
            {
                colSize: 4,
                imgSrc: `${baseURL}assets/images/campaign-level.svg`,
                heading: 'Campaign level',
                innerItems: false,
                para:
                    'This is where you set and outline the general direction of your campaign.',
            },
            {
                colSize: 4,
                imgSrc: `${baseURL}assets/images/add-set-level.svg`,
                heading: 'Ad Set level',
                innerItems: false,
                para:
                    'Here you can easily define and streamline your target audience to the most granular degree possible.',
            },
            {
                colSize: 4,
                imgSrc: `${baseURL}assets/images/add-level.svg`,
                heading: 'Ad level',
                innerItems: false,
                para:
                    'Create the ad itself and tailor for the different post types and locations where your campaign will appear across Facebook.',
            },
        ],
    },
    {
        heading: 'A Complete Feature Set:',
        items: [
            {
                colSize: 8,
                innerItems: [
                    {
                        colSize: 6,
                        imgSrc: `${baseURL}assets/images/template-icon.svg`,
                        heading: 'Templates',
                        para:
                            'Create templates for click-and- go future campaign launches.',
                    },
                    {
                        colSize: 6,
                        imgSrc: `${baseURL}assets/images/fb-control.svg`,
                        heading: 'Full FB Control',
                        para:
                            'Control all FB ad parameters, from targeting, to launch time, to audience to platform, to bidding parameters.',
                    },
                    {
                        colSize: 6,
                        imgSrc: `${baseURL}assets/images/report-planning.svg`,
                        heading: 'Reports & Planning',
                        para:
                            'Adcast comes with a full reporting suite featuring live campaign KPIs and historical performance data.',
                    },
                    {
                        colSize: 6,
                        imgSrc: `${baseURL}assets/images/custom-parameter.svg`,
                        heading: 'Custom Parameters',
                        para:
                            'Create custom parameters that are not offered on the Facebook platform itself.',
                    },
                ],
            },
            {
                colSize: 4,
                imgSrc: `${baseURL}assets/images/smart-automation.svg`,
                heading: 'Smart Automation',
                innerItems: false,
                para:
                    'Before you launch your campaign, set precise automation parameters to tell Adcast when to increase bids, pause ads, or update budgets. Adcast can automatically edit ad budget, status, bid levels, and more on the fly, or send action items and performance notifications to assigned brand personnel.',
            },
        ],
    },
];

const adcastBenefits = [
    {
        heading: 'Granular Bid Management',
        para: `Adcast<sup>TM</sup>&nbsp;automatically 
        manages each
        bid at the most granular
        levels possible: by KW,
        geo-location (down to the
        decile level within a zip
        code), and by 5 different
        bid types.`,
    },
    {
        heading: 'Real-Time Ad Calibration',
        para: `Adcast<sup>TM</sup> actively
        monitors & tracks the
        real-time up ow of online +
        offline conversion outcomes
        from all ads, actively
        adjusting targeting and
        bidding algorithms on the
        fly.`,
    },
    {
        heading: 'Historical Bid Trend Analysis',
        para: `Adcast<sup>TM</sup>
        seamlessly analyzes and
        integrates historical bid
        trends into its real-time
        bidding algorithms, looking
        at hourly, daily, weekly,
        and monthly outcomes to
        optimize ongoing bids while
        simultaneously looking at
        live bid auction data.`,
    },
    {
        heading: 'Predictive Bid Modeling',
        para: `Adcast<sup>TM</sup>
        accurately predicts when,
        where, and how to bid and
        place ads. Based on machine
        learning from millions of
        successful and unsuccessful
        purchase events, AdcastTM
        has achieved diamond-tipped accuracy.`,
    },
];

const Adcast = () => {
    return (
        <Layout>
            <Breadcrumbs bgColor={colors.gray_lightest['100']}>
                <li>Solutions</li>
                <li>Adcast</li>
            </Breadcrumbs>

            <AdcastIntro>
                <Container>
                    <Row>
                        <Col lg="7">
                            <div>
                                <MainHeading>
                                    Adcast<sup>TM</sup>
                                </MainHeading>
                            </div>
                        </Col>
                        <Col lg="5">
                            <div>
                                <MainPara>
                                    Adcast is a scalable SaaS ad management
                                    platform that integrates with the Facebook
                                    advertising platform, allowing ibex Digital
                                    Marketers to launch & manage fully-featured
                                    client advertising campaigns across Facebook
                                    and Instagram.
                                </MainPara>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <div>
                                <SubHeadingSection>
                                    <h3>
                                        High-Volume Ad Placement & Management.
                                        <br />
                                        Intelligent Ad Bidding.
                                    </h3>
                                </SubHeadingSection>
                            </div>
                            <AdcastBottomText>
                                <div>
                                    <h4>Adcast Can Help You:</h4>
                                </div>
                                <Row>
                                    <Col lg={{ size: 10, offset: 1 }}>
                                        <AdcastStatsBox>
                                            <Row>
                                                {adCastHelp &&
                                                    adCastHelp.map(
                                                        (helpItem, index) => (
                                                            <Col
                                                                lg="4"
                                                                key={index}>
                                                                <div>
                                                                    <AdcastHelpBox
                                                                        borderRight={
                                                                            helpItem.borderRight
                                                                        }>
                                                                        <img
                                                                            src={
                                                                                helpItem.imgSrc
                                                                            }
                                                                            alt=""
                                                                        />
                                                                        <p>
                                                                            {ReactHtmlParser(
                                                                                helpItem.para
                                                                            )}
                                                                        </p>
                                                                    </AdcastHelpBox>
                                                                </div>
                                                            </Col>
                                                        )
                                                    )}
                                            </Row>
                                        </AdcastStatsBox>
                                    </Col>
                                </Row>
                            </AdcastBottomText>
                        </Col>
                    </Row>
                </Container>
            </AdcastIntro>

            <AdcastCampaignSection>
                <Container>
                    {campaignItems &&
                        campaignItems.map((item, index) => (
                            <Row key={index}>
                                <Col lg="12">
                                    <div>
                                        <h4>{item.heading}</h4>
                                    </div>
                                </Col>
                                {item.items &&
                                    item.items.map((item2, index2) => {
                                        return (
                                            <Col
                                                lg={item2.colSize}
                                                key={index2}>
                                                {!item2.innerItems && (
                                                    <div>
                                                        <CampaignBox>
                                                            <img
                                                                src={
                                                                    item2.imgSrc
                                                                }
                                                                alt=""
                                                            />
                                                            <CampaigntextBox>
                                                                <h5>
                                                                    {
                                                                        item2.heading
                                                                    }
                                                                </h5>
                                                                <MainPara>
                                                                    {item2.para}
                                                                </MainPara>
                                                            </CampaigntextBox>
                                                        </CampaignBox>
                                                    </div>
                                                )}
                                                {item2.innerItems && (
                                                    <Row>
                                                        {item2.innerItems.map(
                                                            (
                                                                innerItem,
                                                                index3
                                                            ) => (
                                                                <Col
                                                                    lg={
                                                                        innerItem.colSize
                                                                    }
                                                                    key={
                                                                        index3
                                                                    }>
                                                                    <div>
                                                                        <CampaignBox>
                                                                            <img
                                                                                src={
                                                                                    innerItem.imgSrc
                                                                                }
                                                                                alt=""
                                                                            />
                                                                            <CampaigntextBox>
                                                                                <h5>
                                                                                    {
                                                                                        innerItem.heading
                                                                                    }
                                                                                </h5>
                                                                                <MainPara>
                                                                                    {
                                                                                        innerItem.para
                                                                                    }
                                                                                </MainPara>
                                                                            </CampaigntextBox>
                                                                        </CampaignBox>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        )}
                                                    </Row>
                                                )}
                                            </Col>
                                        );
                                    })}
                            </Row>
                        ))}
                    <Row>
                        <Col lg="12">
                            <div>
                                <TextBox>
                                    <Row>
                                        <Col lg="4">
                                            <h4>Adcast is Smart</h4>
                                        </Col>
                                        <Col lg={{ size: 7, offset: 1 }}>
                                            <MainPara>
                                                Adcast features automated bid
                                                intelligence technology for the
                                                fastest and smartest bid
                                                optimization and ad placement on
                                                the planet.
                                            </MainPara>
                                        </Col>
                                    </Row>
                                </TextBox>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </AdcastCampaignSection>
            <SmartSection>
                <Container>
                    <Row>
                        <Col lg="5">
                            <ImageTextBox>
                                <img
                                    src={`${baseURL}assets/images/fb-phone.png`}
                                    alt="-"
                                />
                                <KeyBenefits>
                                    <div>
                                        <h5>Key Benefits</h5>
                                        <MainPara>
                                            By managing high-volume ad launching
                                            through a single platform, your team
                                            will benefit from:
                                        </MainPara>
                                        <ul>
                                            <li>
                                                <img
                                                    src={`${baseURL}assets/images/checkbox-icon.svg`}
                                                    alt=""
                                                />
                                                <MainPara>
                                                    90% time reduction in
                                                    high-volume ad creation &
                                                    launch process
                                                </MainPara>
                                            </li>
                                            <li>
                                                <img
                                                    src={`${baseURL}assets/images/checkbox-icon.svg`}
                                                    alt=""
                                                />
                                                <MainPara>
                                                    Fewer errors
                                                </MainPara>
                                            </li>
                                            <li>
                                                <img
                                                    src={`${baseURL}assets/images/checkbox-icon.svg`}
                                                    alt=""
                                                />
                                                <MainPara>
                                                    Drastically reduced
                                                    complexity in building and
                                                    refreshing ad campaigns
                                                </MainPara>
                                            </li>
                                        </ul>
                                    </div>
                                </KeyBenefits>
                            </ImageTextBox>
                        </Col>
                        <Col lg={{ size: 7, offset: 0 }}>
                            <BottomSection>
                                <Row>
                                    {adcastBenefits &&
                                        adcastBenefits.map(
                                            (benefitItem, index) => (
                                                <Col
                                                    lg={{ size: 5, offset: 1 }}
                                                    key={index}>
                                                    <div>
                                                        <TextBoxTwo>
                                                            <h5>
                                                                {
                                                                    benefitItem.heading
                                                                }
                                                            </h5>
                                                            <MainPara>
                                                                {ReactHtmlParser(
                                                                    benefitItem.para
                                                                )}
                                                            </MainPara>
                                                        </TextBoxTwo>
                                                    </div>
                                                </Col>
                                            )
                                        )}
                                </Row>
                            </BottomSection>
                        </Col>
                    </Row>
                </Container>
            </SmartSection>
        </Layout>
    );
};

const AdcastIntro = styled.section`
    position: relative;
    min-height: 1340px;
    padding-bottom: 0;
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        background-image: url('${baseURL}assets/images/adcast-intro.png');
        background-position: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        background-color: ${colors.salt['100']};
    }
    ${Media('tablet')} {
        min-height: 1px;
    }
`;

const SubHeadingSection = styled.div`
    text-align: center;
    margin-top: 75px;
    ${Media('xlscreens')} {
        margin-top: 3.9vw;
    }
    h3 {
        font-family: ${gilroySemibold};
        font-size: 33px;
        ${Media('xlscreens')} {
            font-size: 1.7vw;
        }
    }
    ${Media('tablet')} {
        margin-top: 30px;
        margin-bottom: 50px;
        h3 {
            font-size: 30px;
        }
    }
    ${Media('mobile')} {
        margin-top: 50px;
        h3 {
            font-size: 30px;
        }
    }
`;

const AdcastBottomText = styled.div`
    padding-top: 300px;
    text-align: center;
    h4 {
        font-family: ${gilroySemibold};
    }
    ${Media('xlscreens')} {
        padding-top: 55vw;
    }
    ${Media('tablet')} {
        padding-top: 350px;
        h4 {
            font-size: 26px;
        }
    }
    ${Media('mobile')} {
        padding-top: 300px;
    }
`;

const AdcastStatsBox = styled.div`
    background-color: ${colors.white['100']};
    border-radius: 32px;
    text-align: center;
    height: 170px;
    ${Media('tablet')} {
        height: auto;
    }
`;

const AdcastHelpBox = styled.div`
    height: 170px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: left;
    padding: 20px;
    border-right: ${(props) =>
        props.borderRight ? `1px solid ${colors.gray['border']}` : 'none'};
    ${Media('tablet')} {
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        border: none;
        border-bottom: 1px solid ${colors.gray['border']};
    }
`;

const AdcastCampaignSection = styled.section`
    background-color: ${colors.gray_lighter['100']};
    h4 {
        font-family: ${gilroySemibold};
    }
    ${Media('tablet')} {
        h4 {
            font-size: 26px;
        }
    }
`;

const CampaigntextBox = styled.div`
    padding-left: 20px;
    h5 {
        font-size: 25px;
        font-family: ${gilroySemibold};
        margin: 0 auto;
        ${Media('xlscreens')} {
            font-size: 1.3vw;
        }
    }
    ${Media('xlscreens')} {
        padding-left: 2.1vw;
    }
`;

const CampaignBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    padding: 20px;
    img {
        max-width: 50px;
        display: flex;
        align-self: flex-start;
    }
`;

const TextBox = styled.div`
    margin: 50px 0 0 0;
    h4 {
        font-family: ${gilroySemibold};
        margin: 0px 0;
    }
`;

const SmartSection = styled.section`
    background-color: ${colors.gray_lightest['100']};
    padding-top: 30px;
    ${Media('xlscreens')} {
        padding-top: 0px;
        padding-bottom: 8vw;
    }
`;

const TextBoxTwo = styled.div`
    padding-top: 105px;
    h5 {
        font-size: 25px;
        font-family: ${gilroySemibold};
        margin: 0 auto;
        ${Media('xlscreens')} {
            font-size: 1.3vw;
        }
    }
    ${Media('xlscreens')} {
        padding-top: 5.52vw;
    }
    ${Media('tablet')} {
        padding-top: 30px;
    }
`;
const BottomSection = styled.div`
    ${Media('tablet')} {
        padding-top: 90px;
    }
    ${Media('mobile')} {
        padding-top: 30px;
    }
`;
const ImageTextBox = styled.div`
    position: relative;
    top: -50px;
    img {
        width: 100%;
    }
    ${Media('xlscreens')} {
        height: 30.6vw;
    }
    ${Media('tablet')} {
        margin-bottom: 80px;
    }
`;

const KeyBenefits = styled.div`
    background-color: #459eff;
    border-radius: 23px;
    padding: 35px;
    width: 90%;
    position: absolute;
    bottom: -30%;
    right: 0;
    ${Media('xlscreens')} {
        padding: 1.84vw;
    }
    h5 {
        color: ${colors.white['100']};
        font-size: 19px;
        font-family: ${gilroyBold};
        margin: 10px 0;
    }
    p {
        color: ${colors.white['100']};
    }

    ul {
        padding: 0;
        margin-top: 15px;
        li {
            display: flex;
            color: ${colors.white['100']};
            margin-bottom: 15px;
            img {
                height: 23px;
                width: 23px;
                margin-right: 10px;
                position: relative;
                top: 2px;
            }
            p {
                margin: 0;
            }
        }
    }
    ${Media('xlscreens')} {
        width: 22vw;
        bottom: -8vw;
    }
`;
export default Adcast;
