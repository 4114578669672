import React from 'react';
import RetailIntro from 'components/retail/intro-section';
import { baseURL } from 'shared-components/theme/helpers';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import { BigCircle } from 'components/industries/common';
import { TransformSolutionSec, CaseStudyAmplify } from './sections';
import Loader from 'components/loader/loader';
import LazyLoad from 'react-lazyload';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -210,
    },
    {
        name: 'cx-solution',
        target: 'cx-solution',
        offset: -50,
    },
    {
        name: 'cx-performance',
        target: 'cx-performance',
        offset: -50,
    },
    {
        name: 'amplify-section',
        target: 'amplify-section',
        offset: -50,
    },
];
const financialSolutions = [
    {
        imageUrl: `${baseURL}assets/images/industries/logistic-solution1.jpg`,
        title: 'Performance at Scale. Deep Operational Power at Your Command.',
        para: `With the world’s leading delivery, logistics, technology, and transportation brands as clients, ibex deploys layers of unmatched operational sophistication and expertise to ensure success at every touchpoint.`,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/logistic-solution2.jpg`,
        title: 'Uncompromising Merchant, Driver, & Customer CX.  ',
        para: `We understand the multi-vector needs of delivery & logistics clients, and our Merchant, Driver, and Customer support programs are tailored specifically to the complex needs of your industry. `,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/logistic-solution3.jpg`,
        title: 'Performance-Optimized Merchant, Customer, & Driver Digital Acquisition ',
        para: `Our expert search, social, and native digital marketing team is ready to launch ads across  Google, Facebook, Pinterest, LinkedIn, Twitter, and more, driving customers and merchants directly into your ecosystem. `,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/financial-solution4.jpg`,
        title: 'A Technology Engine for End-to-End CX Horsepower.',
        para: `From Training Simulators to A.I. Customer Feedback Analytics, our Wave X platform provides end-to-end  performance technology paired with leading partner integrations to ensure continuous performance throughout your ecosystem. `,
    },
];
const performanceList = [
    {
        imgUrl: `${baseURL}assets/images/industries/f-cx-icon6.svg`,
        title: `Merchant & B2B Support`,
        list: [
            'Application Support ',
            'Order Management, Support, & Fulfillment ',
            'Hardware Support ',
            'Chain Development ',
            'Menu Development ',
            'Real-Time Order Tracking ',
            'Corporate Account Creation & Mgmt',
        ],
    },
    {
        imgUrl: `${baseURL}assets/images/industries/f-cx-icon7.svg`,
        title: `Driver Support`,
        list: [
            'Driver Account Management Support ',
            'Driver Payments Support ',
            'Driver Delivery Support ',
            'Prop 22 1099 Gig Worker Mngmt. ',
            'Real-Time Order Tracking',
        ],
    },
    {
        imgUrl: `${baseURL}assets/images/industries/f-cx-icon1.svg`,
        title: `Customer Support`,
        list: [
            'Customer Ordering Support & Fulfillment ',
            'Customer Account Management Support ',
            'Customer Payments (Billing, Verification) Support ',
            'Real-time Order Tracking',
        ],
    },
    {
        imgUrl: `${baseURL}assets/images/industries/f-cx-icon5.svg`,
        title: `The End-to-End CX <br/>Data Layer`,
        list: [
            'End-to-End BI & Analytics ',
            'Social Media Monitoring ',
            'Customer Journey Mapping ',
            'Proactive 5-Star Surveying & Feedback Analytics ',
            'Issue Alerts & Resolution ',
            'Text & Sentiment Analytics',
        ],
    },
];
const amplifyList = [
    {
        icon: `${baseURL}assets/images/industries/rocket.svg`,
        title: 'Fast.',
        para: `We ensure your agent teams are prepared to hit key KPIs quickly and consistently, ensuring there’s no gap in CX output once ibex is switched on.`,
    },
    {
        icon: `${baseURL}assets/images/industries/icon-awesome-check.svg`,
        title: 'Flawless.',
        para: `Our teams have in-depth experience working with leading delivery, logistics, shipping, and transportation brands around the globe, and they’re ready to deploy this expertise for you.`,
    },
    {
        icon: `${baseURL}assets/images/industries/chameleon.svg`,
        title: 'Flexible.',
        para: `We are able to ramp, train, and flex agent teams to suit your growth needs, at dynamic offshore, nearshore, and onshore locations around the globe.`,
    },
    {
        icon: `${baseURL}assets/images/industries/crystal-ball.svg`,
        title: 'Predictable.',
        para: `Anyone can do well on a single program, but delivering consistent performance outcomes requires a battle-tested methodology for repeatable, predictable, and reliable program execution that you can set your watch to.`,
    },
];
const Logistics = () => {
    const sections = [
        {
            component: (
                <TransformSolutionSec
                    performanceList={performanceList}
                    financialSolutions={financialSolutions}
                />
            ),
            isScrollNav: false,
        },
        {
            component: <CaseStudyAmplify amplifyList={amplifyList} />,
            isScrollNav: true,
        },
    ];
    return (
        <Layout>
            <Breadcrumbs>
                <li>Industry</li>
                <li>Delivery & Logistics</li>
            </Breadcrumbs>

            <RetailIntro
                rightImg={`${baseURL}assets/images/industries/logistics-intro.png`}
                tag="INDUSTRY"
                introHeading="Delivery <br/>& Logistics"
                introPara="Managing complexity and solving real-time CX <br/>challenges across a multi-vector marketplace."
                introBtnText="Connect with us."
                right="-6%"
                top="-15.9vw"
                id="home"
            />
            <BigCircle
                imgUrl={`${baseURL}assets/images/industries/Icon-awesome-forward.svg`}
                imgHeight={735}
                top="13vw"
                right="35%"
                zIndex={-1}
            />
            {sections &&
                sections.map((section, index) => {
                    return (
                        <div
                            key={index}>
                            {section.isScrollNav && (
                                <ScrollNav data={scrollNavData} />
                            )}
                            {section.component}
                        </div>
                    );
                })}
        </Layout>
    );
};

export default Logistics;
