import React from 'react';
import styled from 'styled-components';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const PagePara = ({ children, color }) => {
    return <Wrapper color={color}>{children}</Wrapper>;
};

const Wrapper = styled.p`
    font-family: ${gilroyMedium};
    font-size: 16px;
    line-height: 1.3;
    margin-top: 0px;
    color: ${(props) => props.color};
    ${Media('xlscreens')} {
        font-size: 1.15vw;
        sup {
            font-size: 0.7vw;
        }
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
`;
