import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ReactHtmlParser from 'react-html-parser';
import { Parallax } from 'react-scroll-parallax';

import Media from 'shared-components/theme/medias';
import Layout from 'components/layout';
import colors from 'shared-components/theme/colors';
import {
    EbookMainPara,
    EbookScrollDown,
    EbookDoubleBgSection,
    EbookFeatureImage,
    EbookMenu,
    EbookMainHeading,
    EbookFeatureList,
    EbookFloatingImage,
} from 'components/ebook/common';
import {
    EbookHeadingLine,
    EbookMediumHeading,
    EbookFeatureBox,
} from 'components/ebook/seasonal';
import { FeatureBox, EbookFeatureList2 } from 'components/ebook/crypto';
import {
    FlexColumnCentered,
    RowCenteredBoth,
} from 'shared-components/theme/grid-helpers';
import {
    gilroyBold,
    gilroyMedium,
    gilroyHeavyItalic,
} from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

const themeColor = colors.ebook.crypto['main'];
const themeColor2 = colors.ebook.crypto['main2'];

const menuOptions = [
    {
        serial_number: '1',
        type: 'main',
        target: 'target1',
        title: 'Industry Challenges Impacting Crypto CX',
    },
    {
        serial_number: '1.1',
        type: 'sub',
        target: 'target1-1',
        title: 'Intense security scrutiny',
    },
    {
        serial_number: '1.2',
        type: 'sub',
        target: 'target1-2',
        title: 'Government involvement and regulation',
    },
    {
        serial_number: '1.3',
        type: 'sub',
        target: 'target1-3',
        title: 'Rapid growth in a highly competitive marketplace',
    },
    {
        serial_number: '1.4',
        type: 'sub',
        target: 'target1-4',
        title: 'High volatility in crypto coins can impact the adoption curve',
    },
    {
        serial_number: '2',
        type: 'main',
        target: 'target2',
        title: 'The Crypto CX Advantage',
    },
    {
        serial_number: '2.1',
        type: 'sub',
        target: 'target2-1',
        title: 'Understanding crypto customer expectations',
    },
    {
        serial_number: '2.2',
        type: 'sub',
        target: 'target2-2',
        title: 'Keeping up with changing regulations',
    },
    {
        serial_number: '3',
        type: 'main',
        target: 'target3',
        title: 'Delivering Security, Scale & Performance',
    },
    {
        serial_number: '3.1',
        type: 'sub',
        target: 'target3-1',
        title: 'Institutional-grade security',
    },
    {
        serial_number: '3.2',
        type: 'sub',
        target: 'target3-2',
        title: 'Proficiency at scale',
    },
    {
        serial_number: '3.3',
        type: 'sub',
        target: 'target3-3',
        title: 'Agility through volatility',
    },
    {
        serial_number: '3.4',
        type: 'sub',
        target: 'target3-4',
        title: 'Speed to impact',
    },
    {
        serial_number: '3.5',
        type: 'sub',
        target: 'target3-5',
        title: 'CX insight feedback loop',
    },
    {
        serial_number: '3.6',
        type: 'sub',
        target: 'target3-6',
        title: 'Repeatable methodology for predictable crypto CX',
    },
    {
        serial_number: '4',
        type: 'main',
        target: 'target4',
        title: 'Conclusion',
    },
];
const contentsList = [
    {
        heading: `SECTION 1`,
        text: 'Industry Challenges Impacting Crypto CX',
    },
    {
        heading: `SECTION 2`,
        text: 'The Crypto CX Advantage',
    },
    {
        heading: `SECTION 3`,
        text: 'Delivering Security, Scale & Performance',
    },
];
const featureBoxList = [
    {
        imgUrl: `${baseURL}assets/images/ebook/crypto_feature_box1.jpg`,
        heading: 'Intense security scrutiny',
        paraColor: colors.white['100'],
        headingColor: themeColor2,
        lineBg: themeColor2,
        bgColor: themeColor,
        supColor: themeColor2,
        targetID: 'target1-1',
        para: `<p>Despite its recent boom, cryptocurrency still faces a serious PR problem. With regular headlines around fraud, criminal activity, and security breaches on exchanges, the intense scrutiny around crypto industry security will only be amplified as more institutional players buy in.</p>
        <p>In 2020, cryptocurrency losses through fraud and crime were down nearly 60% from 2019<a rel="noreferer noopener" target="_blank" href='https://www.reuters.com/business/finance/exclusive-crypto-crime-down-2021-through-april-defi-fraud-record-ciphertrace-2021-05-13/'>3</a>. With the Crypto market’s  coming-of-age, the industry has learned from some of the large-scale hacks that have happened in the past, requiring precautions like multi-factor authentication, crypto-suitability checks, KYC (more on this in the Government section below), and the growing use of private consortium-based networks that can expose bad actors and further reduce security risks.</p>
        <p>In order to build customer trust, crypto brands will need to ensure security is a central focus of CX at every phase. Experts are calling on crypto exchanges to increase their security and adopt industry-wide security standards, whether self-governed or (eventually) introduced by regulators. There are already security best practices specific to cryptocurrencies in place, like the CryptoCurrency Security Standard (CCSS). Consumers are also demanding more secure decentralized exchanges (DEXs) – and companies like 0x, Loopring, and others are already answering the call.<a rel="noreferer noopener" target="_blank" href="https://decrypt.co/7142/loopring-0x-race-serious-dex">4</a></p>`,
    },
    {
        imgUrl: `${baseURL}assets/images/ebook/crypto_feature_box2.jpg`,
        heading: 'Government involvement and regulation',
        paraColor: colors.white['100'],
        headingColor: colors.white['100'],
        lineBg: colors.white['100'],
        bgColor: themeColor2,
        targetID: 'target1-2',
        supColor: themeColor,
        para: `<p>Uncertainty around the regulatory environment may just be the biggest barrier to significant global mainstream adoption of cryptocurrencies. While global authorities are generally receptive to central bank digital currencies (CBDCs), private cryptocurrencies are another story. In some countries around the world, legislation has been passed to ban crypto entirely owing, in part, to its role in facilitating untraceable criminal transactions.<a rel="noreferer noopener" target="_blank" href="https://www.financemagnates.com/cryptocurrency/crypto-market-faces-tough-challenges-in-2021/">5</a></p>
        <p>In 2021 and beyond, global crypto regulations will likely see significant change. In the EU, a regulation on Markets in Crypto-assets (MiCA) proposed in 2020 will regulate cryptocurrencies by 2024 to protect consumers and investors, financial stability, and market integrity. In the United States, the Treasury Department has proposed a know your customer (KYC) rule for crypto wallets. The rule, if finalized, will require U.S.-based digital exchanges to confirm a wallet owner’s identity for transactions over $3,000.</p>
        <p>Crypto leaders, including Coinbase, have spoken out against the move as fundamentally infringing on the privacy rights of cryptocurrency owners, since a KYC requirement on self-hosted wallets presents technological and even constitutional issues. While the proposal is under review, the overall shift to greater regulation around crypto (however that ends up looking) is a shift that the community will have no choice but to accept in the coming years<a rel="noreferer noopener" target="_blank" href="https://www.financemagnates.com/cryptocurrency/news/us-treasury-wants-complete-cryptocurrency-control/">6</a>. Crypto brands need to equip themselves to deliver CX that complies with the continuously evolving regulatory landscape around crypto.</p>`,
    },
    {
        imgUrl: `${baseURL}assets/images/ebook/crypto_feature_box3.jpg`,
        heading: 'Rapid growth in a highly competitive marketplace',
        paraColor: themeColor,
        headingColor: themeColor2,
        lineBg: themeColor2,
        bgColor: colors.white['100'],
        supColor: themeColor2,
        noPaddingBottom: true,
        targetID: 'target1-3',
        para: `<p>The rising mainstream uptake of cryptocurrency translates to heightened demand that will place pressure on crypto brands to rapidly scale their platforms and offerings. With this sharp increase in new business, companies are likely to face growing pains when it comes to providing customer support and delivering competitive CX.</p>
        <p>This is a problem that brands like Coinbase are already facing in a big way. A recent article in The New York Times highlights the complaints of users who, on the eve of Coinbase’s IPO said “it is struggling with basic customer service.”<a rel="noreferer noopener" target="_blank" href="https://www.nytimes.com/2021/03/24/technology/coinbase-bitcoin-complaints.html">7</a> As the article explains, the company failed to detect issues or help dozens of Coinbase customers “who say that their accounts were plundered by attackers or that they were locked out of their life savings without warning or reason.”</p>
        <p>To be sure, Coinbase is not and will not be the only crypto brand to face these challenges as significant increase in demand butts up against a 24/7 crypto economy. Rising demand necessitates a clear and deliberate customer experience strategy – something many crypto companies don’t yet have.</p>
        <p>The challenges of rapid growth are also amplified by increasing competition in the market, with more players entering the game every year. Brands need to be smart, strategic, and agile about how they grow their offerings. And they also need to be ready to meet customers’ extremely high expectations around protecting their money and personally identifying information (PII). Keeping customers’ needs top of mind will be imperative for crypto brands to succeed – and to avoid becoming just another headline in the crypto data breach archives.</p>
        `,
    },
    {
        imgUrl: `${baseURL}assets/images/ebook/crypto_feature_box4.jpg`,
        heading:
            'High volatility in crypto coins can impact the adoption curve',
        paraColor: themeColor,
        headingColor: themeColor2,
        lineBg: themeColor2,
        bgColor: colors.white['100'],
        supColor: themeColor,
        targetID: 'target1-4',
        para: `<p>Price fluctuations in the cryptocurrency market are historically quite extreme and can be attributed to many different drivers.</p>
        <p>One of the biggest factors is negative publicity around Bitcoin and other cryptocurrencies that stoke investor fear and hurt adoption. These include headlines tying crypto to criminal activity like the drug transactions via Silk Road, any kind of government statement about regulation, and crypto exchange hacks or bankruptcies like the well-known Mt. Gox story.</p>
        <p>Volatility is also driven by general uncertainty about cryptocurrencies’ intrinsic value, fear caused by frequent security breaches and high-profile losses, and the shifting regulatory landscape around crypto, all of which add a speculative element to cryptocurrency prices. The fact that cryptocurrencies offer a hedge during times of geopolitical unrest or uncertainty in the market also adds to price fluctuations.</p>
        <p>All in all, high volatility presents a challenge for market growth and greater investment since the unpredictability of crypto versus fiat currencies has traditionally scared off risk-averse investors. Crypto brands have to be prepared for sharp spikes and declines in new business and customer support as demand shifts with fluctuations in the market.</p>
        `,
    },
];
const cryptoFeatureList_1 = [
    'The strongest possible security that safeguards against malicious actors from both inside and outside the organization',
    'Additional measures insuring their assets are secure in case of a breach, ensuring peace of mind that there won’t be a resulting loss, similar to how bank accounts are protected by the FDIC',
    'Company-wide security standards and conduct regular audits to ensure full compliance across the organization',
    'Protocols for constantly testing and validating the strength of security systems',
    'Regular communication with customers about how their accounts and PII are being protected',
];
const cryptoFeatureList_2 = [
    'A pleasant, straightforward, and fluid sign-up process that makes account creation and fund transfers as fast and easy as possible',
    'Seamless integration with all major banks and financial institutions to make the experience of managing multiple accounts pain-free',
    'An intuitive user interface that makes it quick and easy to manage accounts, perform routine tasks, and find information',
];
const cryptoFeatureList_3 = [
    'Immediately available 24/7 chat support that answers customer questions quickly and easily with hassle-free escalation of issues as needed',
    'Minimal wait times for live voice support',
    'Friendly, knowledgeable, and helpful agents who are equipped to assist diverse customers with different needs, concerns, and levels of financial literacy',
    'Fast, efficient, and proactive support when customers are impacted by security issues',
];
const cryptoFeatureList_4 = [
    'Rich, interactive education and financial literacy resources that support customers in making smart investment decisions',
    'Up-to-date industry news that helps customers stay on top of the latest developments in the crypto market',
    'Easy-to-use, online investing and financial planning tools',
    'Ongoing security tips and reminders about how customers can keep their accounts and information safe',
    'Helpful FAQs and industry insights that add value to the experience',
];
const section3_features = [
    {
        heading: 'Institutional-grade security',
        para: `<p>In a market where brands live and die by the strength of their security, crypto companies’ standards for any partner they’re doing business with are necessarily high. Having a strong security philosophy baked into the organization from the ground up is non-negotiable. That mindset needs to be supported by expertise, as well as leading-edge security infrastructure and technology that keeps customer and enterprise data safe. Above and beyond advanced security protocols, winning CX partners also have a culture of compliance and governance that shows they walk the walk.</p>
        <p>Security-related questions to ask crypto CX outsourcers:</p>`,
        leftImg: `${baseURL}assets/images/ebook/crypto_performance_1.jpg`,
        targetID: 'target3-1',
        listItems: [
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'Does the outsourcer have dedicated screen, audio, and keystroke monitoring and recording technology?',
            },
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'Are there processes and protocols in place for fraud and compliance training, as well as a hotline for anonymous reporting?',
            },
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'Is the outsourcer’s technology infrastructure secured by the latest encryption and data security protocols?',
            },
        ],
    },
    {
        heading: 'Proficiency at scale',
        para: `<p>Scaling from a small customer support team to one with thousands of agents fielding customer interactions around the clock is no easy feat. A strong CX partner is equipped to handle mass operations with proficiency, no matter the level of complexity, by rapidly recruiting, onboarding, and training highly proficient agents.</p>
        <p>Proficiency-related questions to ask crypto CX outsourcers:</p>`,
        leftImg: `${baseURL}assets/images/ebook/crypto_performance_2.jpg`,
        targetID: 'target3-2',
        listItems: [
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'Can the vendor recruit quickly and effectively? Do they have a defined and rigorous process for identifying and selecting candidates?',
            },
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'Do they have a demonstrable track record of recruiting the top talent?',
            },
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'Do they use leading-edge training methodologies, such as training simulators, to speed up training times and improve day one performance?',
            },
        ],
    },
    {
        heading: 'Agility through volatility',
        para: `<p>CX partners that are positioned to deliver for growing crypto brands have a startup-like agility to manage, perform, and win through volatility – however that looks. Because there are no guarantees in the crypto market, the ability to scale up and back while accommodating bursts in demand and continuously improving performance is key.</p>
        <p>Agility-related questions to ask crypto CX outsourcers:</p>`,
        leftImg: `${baseURL}assets/images/ebook/crypto_performance_3.jpg`,
        targetID: 'target3-3',
        listItems: [
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'Does the outsourcer have a sophisticated workforce management team?',
            },
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'What does agent churn and satisfaction look like across the vendor’s geographies and sites?',
            },
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'Beyond initial training, do they offer agents ongoing training for evolving their skill sets?',
            },
        ],
    },
    {
        heading: 'Speed to impact',
        para: `<p>Crypto brands that are ramping up their CX to accommodate a sharp rise in demand have little time to waste. Their CX partner needs to have the operational sophistication to deliver speed-to-green: quickly ramping to deliver train highly proficient agents, optimize performance and quality assurance, and drive profits without sacrificing excellence.</p>
        <p>Speed-related questions to ask crypto CX outsourcers:</p>`,
        leftImg: `${baseURL}assets/images/ebook/crypto_performance_4.jpg`,
        targetID: 'target3-4',
        listItems: [
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'Does the outsourcer have a PMO group with defined implementation and launch procedures, as well as an implementation timeline?',
            },
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'Can they show a successful history of rapid speed-to-green?',
            },
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'How robust and efficient are their training programs? (This is where speed to proficiency is born.)',
            },
        ],
    },
    {
        heading: 'CX insight feedback loop',
        para: `<p>Competitive CX partners are data-driven with a growth mindset. Collecting, analyzing, and acting on business insights is core to their philosophy. By leveraging data to learn more about your customer, an effective CX outsourcer can help you, allowing them to improve performance, drive productivity gains, increase customer lifetime value (CLV), and ultimately support and strengthen your bottom line.</p>
        <p>Insights-related questions to ask crypto CX outsourcers:</p>`,
        leftImg: `${baseURL}assets/images/ebook/crypto_performance_5.jpg`,
        targetID: 'target3-5',
        listItems: [
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'How deep are their business intelligence capabilities?<br/>Are they both insightful and actionable?',
            },
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'What types of analyses can the vendor perform? Do they include complexity studies and churn analytics?',
            },
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'Do they provide insight across the lifecycle, and feed that back into your contact center for improved agent interactions and performance?',
            },
        ],
    },
    {
        heading: 'Repeatable methodology for predictable crypto CX',
        para: `<p>Capitalizing on rapid growth requires an established partner who’s been in the game for a while and has a track record of success built on a clear, repeatable methodology and process. A predictable approach based on deep industry knowledge, experience, and awareness drives exceptional results.</p>
        <p>Expertise-related questions to ask crypto CX outsourcers:</p>`,
        leftImg: `${baseURL}assets/images/ebook/crypto_performance_6.jpg`,
        targetID: 'target3-6',
        listItems: [
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'Has the outsourcer had previous success with a brand like yours? What were the timelines for success and does this meet your goals?',
            },
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'Does the outsourcer have a mature PMO and WFM operation?',
            },
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'How does client success differ across the vendor’s locations?',
            },
            {
                icon: `${baseURL}assets/images/ebook/icon_question_mark_circle.svg`,
                text:
                    'Do they have client references? Speaking with one of their current hyper-growth clients is an effective way to gauge performance.',
            },
        ],
    },
];
const CryptoCXTippingPoint = () => {
    return (
        <Layout bgColor={themeColor}>
            <EbookMenu
                mainTheme={themeColor2}
                mainTheme2={themeColor2}
                data={menuOptions}
                pdfFile={`${baseURL}assets/ebooks/Ebook - Crypto CX Tipping Point.pdf`}
            />
            <CryptoIntro>
                <RowCenteredBoth>
                    <Col lg={8} md={12} sm={12}>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <CryptoIntroHeading>
                                The Crypto <span>CX</span> Tipping Point
                            </CryptoIntroHeading>
                        </ScrollAnimation>
                    </Col>
                </RowCenteredBoth>
                <RowCenteredBoth>
                    <Col lg={9} sm={12}>
                        <CryptoIntroPara>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainPara
                                    color={colors.white['100']}
                                    centerAligned
                                    size="large">
                                    How to deliver secure and agile CX to your
                                    crypto customers
                                </EbookMainPara>
                            </ScrollAnimation>
                        </CryptoIntroPara>
                    </Col>
                </RowCenteredBoth>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <ScrollDownWrap>
                        <p>Scroll down to start reading</p>
                        <EbookScrollDown
                            color={colors.white['100']}
                            bgColor={themeColor2}
                            animate
                        />
                    </ScrollDownWrap>
                </ScrollAnimation>
            </CryptoIntro>
            <Introduction>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/crypto_floating_1.jpg`}
                    height={700}
                    right="0"
                    top="0%"
                    x={['20%', '-20%']}
                    zIndex={-1}
                />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/crypto_floating_2.png`}
                    width={600}
                    right="0"
                    top="75%"
                    x={['20%', '-20%']}
                />
                <Container>
                    <EbookHeadingLine
                        width="25%"
                        top="75%"
                        right="31%"
                        color={themeColor2}
                        size="small">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <EbookMainHeading
                                centerAligned
                                color={colors.white['100']}>
                                Introduction
                            </EbookMainHeading>
                        </ScrollAnimation>
                    </EbookHeadingLine>
                    <Row>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <T1QualityText bgColor={themeColor}>
                                    <EbookMediumHeading
                                        color={colors.white['100']}>
                                        We are moving to an exciting&nbsp;
                                        <span>
                                            <b>phase of action for Bitcoin</b>
                                        </span>
                                        , where much of the hype that surrounds
                                        the asset is beginning to become a
                                        reality. As the asset moves closer
                                        towards a staple market offering, it is
                                        clear that it is time for investors to
                                        get on board, or risk being left behind.
                                    </EbookMediumHeading>
                                    <EbookMainPara color={colors.white['100']}>
                                        - Nasdaq
                                        <Sup
                                            href="https://www.nasdaq.com/articles/institutions-at-heart-of-latest-bitcoin-market-movement-2021-02-05"
                                            rel="noreferer noopener"
                                            target="_blank">
                                            1
                                        </Sup>
                                    </EbookMainPara>
                                </T1QualityText>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainHeading
                                    centerAligned
                                    color={themeColor2}
                                    size="small">
                                    The Critical Role of CX in Differentiating
                                    Crypto Brands
                                </EbookMainHeading>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainPara
                                    seasonal
                                    color={colors.white['100']}>
                                    <p>
                                        The need to reach new markets and
                                        quickly accommodate the rise in demand
                                        from investors of all kinds is placing
                                        heightened pressure on crypto brands to
                                        deliver. What will set them apart? It
                                        all connects back to the roots of early
                                        crypto platforms and services – serving
                                        the needs of individual investors.
                                    </p>
                                    <p>
                                        Crypto leaders are traditionally
                                        hyper-focused on customers and sensitive
                                        to their needs – especially in the areas
                                        of security and CX. This approach
                                        continues to be necessary in a space
                                        that faces higher security risks and
                                        tends to have barriers to entry because
                                        of the technical proficiency and
                                        specialized knowledge it has
                                        historically demanded of customers, as
                                        well as the changing regulations
                                        surrounding cryptocurrencies.
                                    </p>
                                    <p>
                                        For competitive crypto brands, the
                                        impact of those critical differentiators
                                        – security and CX – will only be
                                        amplified as competition in the space
                                        grows.
                                    </p>
                                    <p>
                                        In this eBook we break down some of the
                                        major industry challenges that crypto
                                        brands face, and explore what it looks
                                        like to deliver successful customer
                                        experience (CX) in the rapidly changing
                                        crypto market.
                                    </p>
                                </EbookMainPara>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainHeading
                                    centerAligned
                                    color={themeColor2}
                                    size="small">
                                    Contents
                                </EbookMainHeading>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <T1FeatureList>
                                    <EbookFeatureList
                                        list={contentsList}
                                        oddBgColor={themeColor2}
                                        color={colors.white['100']}
                                        evenBgColor={themeColor2 + '16'}
                                        seasonal
                                    />
                                </T1FeatureList>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </Introduction>
            <Container>
                <Row>
                    <Col lg="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <EbookFeatureImage
                                image={`${baseURL}assets/images/ebook/crypto_feature_1.jpg`}
                                noMarginTop
                                noMarginBottom
                            />
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
            <TargetSection id="target1" noPaddingBottom>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/crypto_floating_3.png`}
                    width={150}
                    left="46%"
                    top="-70%"
                    y={['-20%', '20%']}
                />
                <Container>
                    <Row>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <MainHeadingWrapper>
                                    <EbookMainHeading
                                        color={colors.white['100']}>
                                        <span>Section 1 </span>- Industry
                                        Challenges Impacting CX
                                    </EbookMainHeading>
                                </MainHeadingWrapper>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainPara color={colors.white['100']}>
                                    As crypto investment accelerates, the
                                    industry faces a number of growth-related
                                    challenges that will impact how brands build
                                    and deliver their CX programs.
                                </EbookMainPara>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <T1QualityText>
                                    <EbookMediumHeading
                                        color={colors.white['100']}>
                                        “History shows that cryptocurrency
                                        markets don’t always play nice.
                                        Valuations fluctuate.&nbsp;
                                        <span>
                                            <b>
                                                Technologies evolve, and
                                                regulators work to make crypto
                                                smarter and safer within the
                                                digital world.
                                            </b>
                                        </span>
                                        Challenges range from mild headaches to
                                        catastrophes that can take a bite out of
                                        a company’s bottom line, and crypto CEOs
                                        must be ready to tackle these.”
                                    </EbookMediumHeading>
                                    <EbookMainPara color={colors.white['100']}>
                                        - Alon Muroch, CEO and co-founder of
                                        BloxStaking
                                        <Sup
                                            href="https://www.forbes.com/sites/forbestechcouncil/2019/05/23/the-six-challenges-facing-crypto-ceos/?sh=50e73d597231"
                                            rel="noreferer noopener"
                                            target="_blank">
                                            2
                                        </Sup>
                                    </EbookMainPara>
                                </T1QualityText>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </TargetSection>
            {featureBoxList &&
                featureBoxList.length > 0 &&
                featureBoxList.map((item, index) => {
                    return (
                        // <TargetSection key={index} id={item.targetID}>
                        <FeatureBox
                            imgUrl={item.imgUrl}
                            heading={item.heading}
                            para={item.para}
                            paraColor={item.paraColor}
                            lineBg={item.lineBg}
                            bgColor={item.bgColor}
                            headingColor={item.headingColor}
                            supColor={item.supColor}
                            noPaddingBottom={item.noPaddingBottom}
                            key={index}
                            id={item.targetID}
                        />
                        // </TargetSection>
                    );
                })}
            <Container>
                <Row>
                    <Col lg="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <EbookFeatureImage
                                image={`${baseURL}assets/images/ebook/crypto_feature_2.jpg`}
                                noMarginTop
                                noMarginBottom
                            />
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
            <TargetSection id="target2">
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/crypto_floating_3.png`}
                    width={150}
                    left="46%"
                    top="-70%"
                    y={['-20%', '20%']}
                />
                <Container>
                    <Row>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <MainHeadingWrapper>
                                    <EbookMainHeading
                                        color={colors.white['100']}>
                                        <span>Section 2 </span>- The Crypto CX
                                        Advantage
                                    </EbookMainHeading>
                                </MainHeadingWrapper>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainPara color={colors.white['100']}>
                                    As competition in the crypto market
                                    skyrockets, brands’ capacity to deliver
                                    secure, fast, and frictionless CX that
                                    complies with evolving regulatory
                                    requirements and boast additional security
                                    measures and simple, transparent processes,
                                    will be a critical factor in determining
                                    customers’ platform of choice.
                                </EbookMainPara>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <T1QualityText>
                                    <EbookMediumHeading
                                        color={colors.white['100']}>
                                        “Coinbase’s going public is ‘seminal’
                                        for the cryptocurrency industry, said
                                        David Silver, a lawyer who represents
                                        digital currency investors. But he added
                                        that if the company wanted to be ‘the
                                        Goldman Sachs of crypto,’ it ‘needs to
                                        maintain quality customer support.’”
                                    </EbookMediumHeading>
                                    <EbookMainPara color={colors.white['100']}>
                                        - The New York Times, March 24, 2021
                                        <Sup
                                            href="https://www.nytimes.com/2021/03/24/technology/coinbase-bitcoin-complaints.html"
                                            rel="noreferer noopener"
                                            target="_blank">
                                            8
                                        </Sup>
                                    </EbookMainPara>
                                </T1QualityText>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </TargetSection>
            <EbookDoubleBgSection
                firstBg={themeColor2}
                firstBgHeight="60%"
                secondBg={colors.white['100']}
                noPadding>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/crypto_floating_4.jpg`}
                    height={45}
                    left="30%"
                    top="0%"
                    x={['-40%', '40%']}
                />
                <FeatureImageWrapper>
                    <EbookFeatureImage
                        image={`${baseURL}assets/images/ebook/crypto_feature_3.jpg`}
                        noMarginBottom
                        noMarginTop
                        wideImg
                    />
                </FeatureImageWrapper>
            </EbookDoubleBgSection>
            <TargetSection id="target2-1" bgColor={colors.white['100']}>
                <Container>
                    <Row>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainHeading
                                    color={themeColor2}
                                    size="small">
                                    Understanding crypto customer expectations
                                </EbookMainHeading>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <EbookMainPara color={themeColor} seasonal>
                                        With a growing number of crypto
                                        exchanges coming to market, customers
                                        will ultimately choose based on a few
                                        key factors, including security,
                                        transparency, ease, support and
                                        education.
                                    </EbookMainPara>
                                </ScrollAnimation>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <FeatureBoxWrapper>
                                    <EbookFeatureBox
                                        image={`${baseURL}assets/images/ebook/crypto_feature_list_1.png`}
                                        title="Security"
                                        titleColor={themeColor2}>
                                        <EbookMainPara seasonal>
                                            <p>
                                                When it comes to the crypto
                                                space, customers have heightened
                                                security concerns. Retail
                                                investors’ fears may be related
                                                to negative news stories they’ve
                                                heard or the fact that they’re
                                                investing in crypto for the
                                                first time. Meanwhile,
                                                institutional customers have to
                                                meet regulatory compliance and
                                                protect their clients’ financial
                                                well-being and PII. In both
                                                cases, customers expect security
                                                that goes above and beyond,
                                                including:
                                            </p>
                                        </EbookMainPara>
                                        <EbookFeatureList2
                                            list={cryptoFeatureList_1}
                                            oddBgColor={themeColor2 + '10'}
                                            color={themeColor}
                                            seasonal
                                        />
                                    </EbookFeatureBox>
                                </FeatureBoxWrapper>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <FeatureBoxWrapper>
                                    <EbookFeatureBox
                                        image={`${baseURL}assets/images/ebook/crypto_feature_list_2.png`}
                                        title="Ease and speed of use"
                                        titleColor={themeColor2}>
                                        <EbookMainPara seasonal>
                                            <p>
                                                The level of ease with which
                                                customers can sign up and manage
                                                their accounts will be another
                                                significant factor in their
                                                choice of platform. Customers
                                                will count on crypto companies
                                                to deliver these staples of
                                                strong CX:
                                            </p>
                                        </EbookMainPara>
                                        <EbookFeatureList2
                                            list={cryptoFeatureList_2}
                                            oddBgColor={themeColor2 + '10'}
                                            color={themeColor}
                                            seasonal
                                        />
                                    </EbookFeatureBox>
                                </FeatureBoxWrapper>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <FeatureBoxWrapper>
                                    <EbookFeatureBox
                                        image={`${baseURL}assets/images/ebook/crypto_feature_list_3.png`}
                                        title="Support"
                                        titleColor={themeColor2}>
                                        <EbookMainPara seasonal>
                                            <p>
                                                Delivering quality crypto CX
                                                isn’t possible without fast,
                                                friendly, and effective customer
                                                support. From helping first-time
                                                investors transfer funds to
                                                troubleshooting inevitable
                                                technical difficulties, crypto
                                                brands need to be able to
                                                deliver these critical services:
                                            </p>
                                        </EbookMainPara>
                                        <EbookFeatureList2
                                            list={cryptoFeatureList_3}
                                            oddBgColor={themeColor2 + '10'}
                                            color={themeColor}
                                            seasonal
                                        />
                                    </EbookFeatureBox>
                                </FeatureBoxWrapper>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <FeatureBoxWrapper>
                                    <EbookFeatureBox
                                        image={`${baseURL}assets/images/ebook/crypto_feature_list_4.png`}
                                        title="Education"
                                        titleColor={themeColor2}>
                                        <EbookMainPara seasonal>
                                            <p>
                                                Understanding the world of
                                                crypto comes with a big learning
                                                curve, and deepening that
                                                knowledge is critical to
                                                successful investing. Brands can
                                                differentiate themselves by
                                                offering customers high-quality
                                                resources and tools that empower
                                                them to be more proficient
                                                crypto investors, including:
                                            </p>
                                        </EbookMainPara>
                                        <EbookFeatureList2
                                            list={cryptoFeatureList_4}
                                            oddBgColor={themeColor2 + '10'}
                                            color={themeColor}
                                            seasonal
                                        />
                                    </EbookFeatureBox>
                                </FeatureBoxWrapper>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </TargetSection>
            <TargetSection id="target2-2">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <Container>
                        <Row>
                            <Col lg="12">
                                <MainHeadingWrapper marginBottom>
                                    <EbookMainHeading
                                        color={themeColor2}
                                        size="small"
                                        noMargin>
                                        Keeping up with changing regulations
                                    </EbookMainHeading>
                                </MainHeadingWrapper>
                            </Col>
                            <Col lg="12">
                                <EbookMainPara
                                    seasonal
                                    color={colors.white['100']}>
                                    <p>
                                        In order to achieve long-term success,
                                        crypto brands will also rely on their CX
                                        partners to be constantly dialed into
                                        the evolving regulatory landscape around
                                        crypto.
                                    </p>
                                    <p>
                                        In the coming years, governments around
                                        the world will be developing more
                                        concrete rules and regulations related
                                        to cryptocurrencies. And because the
                                        world of crypto is anything but static,
                                        those regulatory frameworks will
                                        constantly evolve to address emerging
                                        contexts.
                                    </p>
                                    <p>
                                        Failing to comply could result in legal
                                        challenges, financial losses, bad PR,
                                        reputational damage, and friction in
                                        customer relationships that could lead
                                        to long-term issues. Crypto companies
                                        will need to make sure every aspect of
                                        their business measures up, including CX
                                        delivery.
                                    </p>
                                    <p>
                                        An experienced CX outsourcer is key to
                                        success in a rapidly evolving regulatory
                                        environment. By consistently monitoring
                                        nascent government frameworks around
                                        crypto, an effective partner can help
                                        crypto brands act and adapt quickly so
                                        nothing falls through the cracks.
                                    </p>
                                    <p>
                                        The best partners have a dedicated
                                        compliance or governmental affairs team,
                                        a deep understanding of current
                                        regulatory frameworks, and a track
                                        record of successful compliance work for
                                        other financial services brands.
                                    </p>
                                </EbookMainPara>
                            </Col>
                        </Row>
                    </Container>
                </ScrollAnimation>
            </TargetSection>
            <Container>
                <Row>
                    <Col lg="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <EbookFeatureImage
                                image={`${baseURL}assets/images/ebook/crypto_feature_4.jpg`}
                                noMarginTop
                                noMarginBottom
                            />
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
            <TargetSection id="target3">
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/crypto_floating_3.png`}
                    width={150}
                    left="46%"
                    top="-88%"
                    y={['-20%', '20%']}
                />
                <Container>
                    <Row>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <MainHeadingWrapper>
                                    <EbookMainHeading
                                        color={colors.white['100']}>
                                        <span>Section 3 </span>- Delivering
                                        Security, Scale & Performance
                                    </EbookMainHeading>
                                </MainHeadingWrapper>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookMainPara color={colors.white['100']}>
                                    <p>
                                        For crypto companies to effectively
                                        manage rapid growth while delivering
                                        maximum security, support, and ease to
                                        customers, the scalability and certainty
                                        of their CX partner is key.
                                    </p>
                                    <p>
                                        Particularly in a market that is
                                        anything but predictable, crypto brands
                                        need to be confident that their CX
                                        outsourcer can deliver capably and
                                        consistently, no matter what. And that
                                        means no surprises, regardless of how
                                        the market fluctuates.
                                    </p>
                                    <p>
                                        As crypto brands witness record growth,
                                        they will depend on agile CX partners
                                        who can grow at their pace and
                                        demonstrate a reliable approach within
                                        their security and performance
                                        operations. So what does a winning CX
                                        partner deliver?
                                    </p>
                                </EbookMainPara>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </TargetSection>
            {section3_features &&
                section3_features.length > 0 &&
                section3_features.map((feature, index) => {
                    return (
                        <TargetSection
                            id={feature.targetID}
                            key={index}
                            bgColor={colors.white['100']}
                            noPaddingBottom={
                                index === section3_features.length - 1
                                    ? false
                                    : true
                            }>
                            <CustomAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <Container>
                                    <Row>
                                        <Col lg="12">
                                            <EbookMainHeading
                                                color={themeColor2}
                                                size="small">
                                                {feature.heading}
                                            </EbookMainHeading>
                                        </Col>
                                        <Col lg="12">
                                            <EbookMainPara
                                                color={themeColor}
                                                seasonal>
                                                {ReactHtmlParser(feature.para)}
                                            </EbookMainPara>
                                        </Col>
                                        <Col lg="12">
                                            <S3FeaturesWrapper>
                                                <Row>
                                                    <Col lg="3">
                                                        <Section3FeatureImg>
                                                            <img
                                                                src={
                                                                    feature.leftImg
                                                                }
                                                                alt="-"
                                                            />
                                                            <CustomParallax
                                                                x={[
                                                                    '-20%',
                                                                    '0%',
                                                                ]}>
                                                                <Line />
                                                            </CustomParallax>
                                                        </Section3FeatureImg>
                                                    </Col>
                                                    <Col lg="9">
                                                        <EbookFeatureList
                                                            list={
                                                                feature.listItems
                                                            }
                                                            oddBgColor={
                                                                themeColor2 +
                                                                '10'
                                                            }
                                                            longIconMarg
                                                            color={themeColor}
                                                            smallIcon={true}
                                                            seasonal
                                                        />
                                                    </Col>
                                                </Row>
                                            </S3FeaturesWrapper>
                                        </Col>
                                    </Row>
                                </Container>
                            </CustomAnimation>
                        </TargetSection>
                    );
                })}
            <TargetSection id="target4">
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/crypto_floating_1.jpg`}
                    width={250}
                    right="-5%"
                    top="7%"
                    y={['-50%', '50%']}
                />
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <Container>
                        <Row>
                            <Col lg="12">
                                <MainHeadingWrapper marginBottom>
                                    <EbookMainHeading
                                        color={colors.white['100']}
                                        noMargin>
                                        Conclusion
                                    </EbookMainHeading>
                                </MainHeadingWrapper>
                            </Col>
                            <Col lg="12">
                                <MainHeadingWrapper marginBottom>
                                    <EbookMainHeading
                                        color={themeColor2}
                                        size="small"
                                        noMargin>
                                        Winning the future of crypto
                                    </EbookMainHeading>
                                </MainHeadingWrapper>
                            </Col>
                            <Col lg="12">
                                <EbookMainPara
                                    color={colors.white['100']}
                                    seasonal>
                                    <p>
                                        Established and emerging crypto brands
                                        are forging a future of accessible,
                                        customer-friendly investment in
                                        cryptocurrencies – a shift powered by
                                        the rising participation of both retail
                                        and institutional investors.
                                    </p>
                                    <p>
                                        In the evolving crypto landscape, the
                                        opportunities are great, but so are the
                                        challenges. From the security issues
                                        that have long hindered the market to
                                        regulatory uncertainty around
                                        cryptocurrencies to the steep and sudden
                                        rise in demand, ongoing disruption
                                        requires crypto brands to be strategic
                                        and agile to come out on top.
                                    </p>
                                    <p>
                                        Above all else, the quality and security
                                        of the customer experience will make or
                                        break their capacity to perform and win
                                        in a highly competitive marketplace.
                                    </p>
                                </EbookMainPara>
                            </Col>
                        </Row>
                    </Container>
                </ScrollAnimation>
            </TargetSection>
        </Layout>
    );
};
const CryptoIntro = styled(FlexColumnCentered)`
    padding: 20px;
    padding-bottom: 30px;
    background: url('${baseURL}assets/images/ebook/crypto-ebook-intro-bg.jpg');
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    width: 100%;
    box-sizing: border-box;
    ${Media('xlscreens')} {
        background-size: cover;
        height: calc(100vh - 5.3vw);
        padding: 3vw;
    }
    ${Media('tablet')} {
        padding: 50px;
    }
`;

const CryptoIntroHeading = styled.h1`
    font-size: 80px;
    font-family: ${gilroyHeavyItalic};
    text-transform: uppercase;
    text-align: center;
    color: ${colors.white['100']};
    line-height: 1.05;
    span {
        color: ${themeColor2};
    }
    ${Media('xlscreens')} {
        font-size: 6.09vw;
    }
    ${Media('mobile')} {
        font-size: 44px;
        letter-spacing: 0;
        line-height: 1.3;
    }
`;

const CryptoIntroPara = styled.div`
    text-align: center;
    margin: 20px;
    ${Media('xlscreens')} {
        margin: 2vw 0px;
    }
    ${Media('mobile')} {
        div,
        p {
            text-align: center;
        }
    }
`;
const ScrollDownWrap = styled(FlexColumnCentered)`
    position: relative;
    top: 10px;
    p {
        font-size: 20px;
        font-family: ${gilroyMedium};
        color: ${colors.white['100']};
    }
    ${Media('xlscreens')} {
        top: 3vw;
        p {
            font-size: 1.1vw;
        }
    }
    ${Media('mobile')} {
        p {
            font-size: 14px;
        }
    }
`;
const Introduction = styled.section`
    position: relative;
`;
const T1QualityText = styled(FlexColumnCentered)`
    margin-top: 50px;
    text-align: center;
    background-color: ${(props) => props.bgColor};
    h3 {
        span {
            font-family: ${gilroyBold};
            color: ${themeColor2};
        }
    }
    ${(props) => {
        if (props.styleTwo) {
            return `
                [class*='main-para'] {
                    margin:0 0 50px;
                }
            `;
        }
    }}
    ${Media('xlscreens')} {
        margin-top: 5vw;
        margin-bottom: 3vw;
        padding: ${(props) => props.bgColor && '2.66vw'};
        ${(props) => {
            if (props.styleTwo) {
                return `
                    [class*='main-para'] {
                        margin: 0 0 2.6vw;
                    }
                `;
            }
        }}
    }
`;
const Sup = styled.a`
    font-size: 8px;
    position: relative;
    color: ${(props) => (props.color ? props.color : themeColor2)};
    top: -20px;
    left: 1px;
    &:hover {
        color: ${themeColor2};
    }
    ${Media('xlscreens')} {
        font-size: 0.7vw;
        top: -0.5vw;
    }
`;
const T1FeatureList = styled.div`
    padding: 50px 0px;
    ${Media('xlscreens')} {
        padding: 2vw 0 4vw;
    }
    ${Media('tablet')} {
        padding: 0px;
    }
`;
const TargetSection = styled.section`
    position: relative;
    padding-top: ${(props) => props.noPaddingTop && '0 !important'};
    padding-bottom: ${(props) => props.noPaddingBottom && '0 !important'};
    background-color: ${(props) => props.bgColor};
`;
const MainHeadingWrapper = styled.div`
    margin-bottom: ${(props) => props.marginBottom && '15px'};
    span {
        color: ${themeColor2};
    }
    ${Media('xlscreens')} {
        margin-bottom: ${(props) => props.marginBottom && '2vw'};
    }
`;
const FeatureBoxWrapper = styled.div`
    padding: 20px 0;
    ${Media('xlscreens')} {
        padding: 1.04vw 0;
    }
`;
const S3FeaturesWrapper = styled.div`
    ${Media('xlscreens')} {
        margin: 1.56vw 0;
    }
`;
const Section3FeatureImg = styled.div`
    position: relative;
    img {
        width: 240px;
        object-fit: cover;
    }
    ${Media('xlscreens')} {
        img {
            width: 12.5vw;
        }
    }
    ${Media('tablet')} {
        img {
            margin: 20px auto 50px;
            width: 400px;
        }
    }
    ${Media('mobile')} {
        img {
            width: 100%;
            margin: 0px auto 20px;
        }
    }
`;
const Line = styled.div`
    height: 5px;
    width: 285px;
    background-color: ${themeColor2};
    transform: rotate(45deg);
    ${Media('xlscreens')} {
        height: 0.26vw;
        width: 14.84vw;
    }
`;
const FeatureImageWrapper = styled.div`
    padding-top: 30px;
    ${Media('xlscreens')} {
        padding-top: 2.08vw;
    }
    ${Media('mobile')} {
        padding-top: 0;
    }
`;
const CustomAnimation = styled(ScrollAnimation)`
    position: relative;
    z-index: 9;
`;
const CustomParallax = styled(Parallax)`
    position: absolute;
    left: -90px;
    bottom: 45px;
    ${Media('xlscreens')} {
        left: -4.68vw;
        bottom: 2.34vw;
    }
    ${Media('tablet')} {
        display: none;
    }
`;
export default CryptoCXTippingPoint;
