import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import Layout from 'components/layout';
import ReactHtmlParser from 'react-html-parser';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    SRMCallActionSection,
    SRMFloatingSectionImage,
    SRMSectionHeading,
    ContainerWideSRM,
} from 'components/social-rep-man/common';
import SRMIntroSection from 'components/social-rep-man/intro-section';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import MainPara from 'shared-components/main-para/main-para';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import {
    FlexCentered,
    FlexColumnCentered,
    RowCenteredBoth,
} from 'shared-components/theme/grid-helpers';
import VerticalCards from 'components/social-rep-man/vertical-cards';
import colors from 'shared-components/theme/colors';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import LayersCards from 'components/social-rep-man/layer-card';
import DemoRequestForm from 'components/social-rep-man/demo-request-form';
import Loader from 'components/loader/loader';
import LazyLoad from 'react-lazyload';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -170,
    },
    {
        name: 'empowered',
        target: 'empowered',
        offset: 0,
    },
    {
        name: 'manage',
        target: 'manage',
        offset: 0,
    },
    {
        name: 'engage',
        target: 'engage',
        offset: 0,
    },
];

const goldList = [
    {
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="64.459" height="48.917" viewBox="0 0 64.459 48.917"> <g id="Group_995" data-name="Group 995" transform="translate(-457.5 -1489.451)"> <g id="Group_738" data-name="Group 738"> <g id="Group_737" data-name="Group 737"> <g id="Ellipse_577" data-name="Ellipse 577" transform="translate(473.042 1489.451)" fill="none" stroke="#fa0060" stroke-width="1"> <circle cx="24.459" cy="24.459" r="24.459" stroke="none"/> <circle cx="24.459" cy="24.459" r="23.959" fill="none"/> </g> <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M23.037,20.951,11.866,9.789a2.1,2.1,0,0,1,0-2.979,2.127,2.127,0,0,1,2.988,0L27.511,19.457a2.106,2.106,0,0,1,.062,2.909L14.863,35.1a2.11,2.11,0,0,1-2.988-2.979Z" transform="translate(474.375 1494.776)" fill="#fff"/> <path id="Icon_ionic-ios-arrow-forward-2" data-name="Icon ionic-ios-arrow-forward" d="M23.037,20.951,11.866,9.789a2.1,2.1,0,0,1,0-2.979,2.127,2.127,0,0,1,2.988,0L27.511,19.457a2.106,2.106,0,0,1,.062,2.909L14.863,35.1a2.11,2.11,0,0,1-2.988-2.979Z" transform="translate(460.625 1494.776)" fill="#fa0060"/> <path id="Icon_ionic-ios-arrow-forward-3" data-name="Icon ionic-ios-arrow-forward" d="M23.037,20.951,11.866,9.789a2.1,2.1,0,0,1,0-2.979,2.127,2.127,0,0,1,2.988,0L27.511,19.457a2.106,2.106,0,0,1,.062,2.909L14.863,35.1a2.11,2.11,0,0,1-2.988-2.979Z" transform="translate(446.254 1494.776)" fill="#fff"/> </g> </g> </g> </svg>`,
        title: 'Get Ahead Of Issues and <br/>Predict Operational Needs ',
        para:
            'Real-time activity insight lets you identify where additional resources are needed, act quickly to make on-the-fly resourcing adjustments and keep operations running smoothly.  ',
    },
    {
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="61.656" height="55.285" viewBox="0 0 61.656 55.285">
            <g id="Group_1456" data-name="Group 1456" transform="translate(-505.001 -3196)">
              <g id="Group_1455" data-name="Group 1455" transform="translate(505 3197.808)">
                <path id="Path_8535" data-name="Path 8535" d="M2982.22,2743.928a21.788,21.788,0,0,0-1.96-30.48,24.914,24.914,0,0,1-23.933,31.705c-.318,0,1.6.011-5.948-.035a21.7,21.7,0,0,0,15.363,6.347l20.235-.1a1.553,1.553,0,0,0,1.083-2.657Z" transform="translate(-2934.546 -2698.488)" fill="#fa0060" stroke="#030323" stroke-width="1"/>
                <path id="Path_8536" data-name="Path 8536" d="M2819.143,2612.429a21.777,21.777,0,1,0-16.478-7.537l-4.84,4.776a1.553,1.553,0,0,0,1.083,2.657Zm-9.26-29.489h18.623a1.552,1.552,0,1,1,0,3.1h-18.623a1.552,1.552,0,1,1,0-3.1Zm0,6.208h18.623a1.552,1.552,0,1,1,0,3.1h-18.623a1.552,1.552,0,1,1,0-3.1Zm-1.552,7.76a1.552,1.552,0,0,1,1.552-1.552h18.623a1.552,1.552,0,1,1,0,3.1h-18.623A1.553,1.553,0,0,1,2808.331,2596.908Z" transform="translate(-2797.363 -2568.868)" fill="#fff"/>
              </g>
              <g id="Ellipse_5305" data-name="Ellipse 5305" transform="translate(538.397 3196)" fill="#030323" stroke="#fff" stroke-width="1">
                <circle cx="14.13" cy="14.13" r="14.13" stroke="none"/>
                <circle cx="14.13" cy="14.13" r="13.63" fill="none"/>
              </g>
              <circle id="Ellipse_5306" data-name="Ellipse 5306" cx="5.138" cy="5.138" r="5.138" transform="translate(547.389 3204.991)" fill="#fa0060"/>
            </g>
          </svg>`,
        title: 'Real-time Workstation Activity Monitoring',
        para:
            'Analyze how your contact center is performing in one holistic, color-coded display, while also monitoring real-time statistics for individual agents.',
    },
    {
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="56.778" height="42.066" viewBox="0 0 56.778 42.066">
            <g id="Group_1927" data-name="Group 1927" transform="translate(-485.55 -2980.72)">
              <rect id="Rectangle_19940" data-name="Rectangle 19940" width="55.778" height="7.113" transform="translate(486.05 2981.22)" fill="#030323" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
              <rect id="Rectangle_20671" data-name="Rectangle 20671" width="55.778" height="33.953" transform="translate(486.05 2988.333)" fill="#fff" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
              <ellipse id="Ellipse_5438" data-name="Ellipse 5438" cx="1.836" cy="2" rx="1.836" ry="2" transform="translate(489 2982.693)" fill="#fa0060"/>
              <ellipse id="Ellipse_5439" data-name="Ellipse 5439" cx="1.836" cy="2" rx="1.836" ry="2" transform="translate(495.121 2982.693)" fill="#fa0060"/>
              <ellipse id="Ellipse_5440" data-name="Ellipse 5440" cx="1.836" cy="2" rx="1.836" ry="2" transform="translate(501.241 2982.693)" fill="#fa0060"/>
              <g id="Group_1923" data-name="Group 1923" transform="translate(-239 1451.291)">
                <rect id="Rectangle_20665" data-name="Rectangle 20665" width="35" height="6" rx="3" transform="translate(735 1545.709)" fill="#030323"/>
                <rect id="Rectangle_20666" data-name="Rectangle 20666" width="35" height="6" rx="3" transform="translate(735 1556.709)" fill="#030323"/>
                <circle id="Ellipse_5427" data-name="Ellipse 5427" cx="4.5" cy="4.5" r="4.5" transform="translate(739 1543.709)" fill="#fa0060"/>
                <ellipse id="Ellipse_5428" data-name="Ellipse 5428" cx="4" cy="4.5" rx="4" ry="4.5" transform="translate(755 1554.709)" fill="#fa0060"/>
              </g>
            </g>
          </svg>
          `,
        title: 'One Dashboard for High-Level <br/>Ops Data',
        para:
            'Track AHT, Occupancy, AUX time, Staffed Time, and schedule activity in one simple-to-read dashboard. ',
    },
];

const manageContent = [
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/heatmap-tag-1.svg`,
        VerticalCardHeading: `Supervise multiple <br/>remote or on-site <br/>agents`,
        VerticalCardParagraph: `Monitor up to 12 agents in one <br/>window. Drag-and-drop to arrange <br/>agent screens to your liking.`,
        VerticalCardImage: `${baseURL}assets/images/tech/heatmap-intro.svg`,
    },
    {
        ImageRight: false,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/heatmap-tag-2.svg`,
        VerticalCardHeading: `Identify areas of <br/>improvement, faster`,
        VerticalCardParagraph: `Heatmap tracks all operations data in real-time, so you <br/>can identify potential issues or areas for improvement <br/>with just one look. Heatmap will help you improve the <br/>average handle time of requests, and optimize time to <br/>ensure consistent results.`,
        VerticalCardImage: `${baseURL}assets/images/tech/heatmap-img-2.svg`,
    },
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/heatmap-tag-3.svg`,
        VerticalCardHeading: `Optimize your <br/>contact center`,
        VerticalCardParagraph: `By tracking important operations data like <br/>agent statistics, AUX time, and staffed time, <br/>Heatmap can help you optimize scheduling <br/>and manage staffing more accurately, <br/>resulting in better agent and customer <br/>experiences, and reducing client costs.`,
        VerticalCardImage: `${baseURL}assets/images/tech/heatmap-img-3.svg`,
    },
];

const insightData = [
    {
        icon: `${baseURL}assets/images/tech/search.svg`,
        title: 'Search',
        desc:
            'Using agent search capabilities, you can <br/>pull up information on any agent to see <br/>recent activity and performance metrics.',
    },
    {
        icon: `${baseURL}assets/images/tech/recording.svg`,
        title: 'View Real-Time Workstation Activity',
        desc:
            'Using the color-coded graphical display, <br/>you can click into any seat to see agent <br/>and supervisor information',
    },
    {
        icon: `${baseURL}assets/images/tech/flexcx-monitor-icon-2.svg`,
        title: 'Monitor KPIs',
        desc:
            'Mitigate CX risk and continuously improve <br/>performance to hit key milestones and <br/>bolster brand sentiment.',
    },
];

const engageData = [
    {
        image: `${baseURL}assets/images/tech/heatmap-engage-1.jpg`,
        heading: 'Increase Contact Center Productivity ',
        para:
            'Track important operational data like call volume, average handle time and AUX Time and track against your KPIs to ensure consistent CX.',
    },
    {
        image: `${baseURL}assets/images/tech/heatmap-engage-2.jpg`,
        heading: 'Never Be Caught Off-Guard',
        para:
            'By visualizing the entire contact center’s activity, you can make precise changes as you encounter any daily challenges. Visibility into real-time data on AUX Time vs. Staffed Time, you can more effectively manage staffing to keep up with activity and ensure you’re always prepared.',
    },
    {
        image: `${baseURL}assets/images/tech/heatmap-engage-3.jpg`,
        heading: 'Manage Agent Performance',
        para:
            'Get a snapshot of every agent’s performance, and be alerted to any problems with the color-coded view of real-time issues.',
    },
];

const TrainingSimulator = () => {
    const [demoRequestForm, setDemoRequestForm] = useState(false);

    return (
        <Layout>
            <Breadcrumbs>
                <li>Wave X</li>
                <li>Technology</li>
            </Breadcrumbs>
            <DemoRequestForm
                handleDemoForm={(val) => setDemoRequestForm(val)}
                formState={demoRequestForm}
                marketFormId="1080"
            />
            <SRMIntroSection
                preheading="ibex Heatmap"
                heading="Real-Time Contact <br/>Center Activity Visualization"
                para="A color-coded dashboard displaying a graphical view <br/>of the contact center floor with real-time insights to <br/>give you complete control of your contact center <br/>operations."
                mainImg={`${baseURL}assets/images/tech/heatmap-intro.svg`}
                textureImg={`${baseURL}assets/images/texture-one.svg`}
                textureImgPosition="back"
                onBtnClick={() => setDemoRequestForm(true)}
            />
            
                <GoldSection id="empowered">
                    <SRMFloatingSectionImage
                        x={[-20, 0]}
                        image={`${baseURL}assets/images/gold-bg-float.png`}
                        width={765}
                        left="-10%"
                        bottom="0"
                    />
                    <Container>
                        <RowCenteredBoth>
                            <Col sm="12" lg="8">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={true}>
                                    <SRMSectionHeading
                                        color={colors.white['100']}>
                                        Take Back Control and Optimize <br />
                                        Your Contact Center
                                    </SRMSectionHeading>
                                    <MainPara
                                        align="center"
                                        color={colors.white['100']}>
                                        Heatmap is an agent and operations
                                        visualization tool, providing a holistic
                                        view of the entire contact center floor
                                        with real-time statistics, providing
                                        managers with visibility into all
                                        activity in a single display.
                                    </MainPara>
                                </ScrollAnimation>
                            </Col>
                        </RowCenteredBoth>
                        <Row noGutters>
                            {goldList &&
                                goldList !== undefined &&
                                goldList.map((item, index) => {
                                    return (
                                        <Col sm="12" md="12" lg="4" key={index}>
                                            <ScrollAnimation
                                                animateIn="fadeInUp"
                                                animateOnce={true}>
                                                <GoldBox>
                                                    <GoldIcon>
                                                        {ReactHtmlParser(
                                                            item.icon
                                                        )}
                                                    </GoldIcon>
                                                    <GoldTitle>
                                                        {ReactHtmlParser(
                                                            item.title
                                                        )}
                                                    </GoldTitle>
                                                    <GoldContent>
                                                        {ReactHtmlParser(
                                                            item.para
                                                        )}
                                                    </GoldContent>
                                                </GoldBox>
                                            </ScrollAnimation>
                                        </Col>
                                    );
                                })}
                        </Row>
                    </Container>
                </GoldSection>

                <ManageSection id="manage">
                    <ContainerWideSRM>
                        <Row>
                            <Col sm="12">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <SRMSectionHeading>
                                        Operations made easy with one dashboard
                                        for&nbsp;
                                        <br />
                                        all agent tools and communications
                                    </SRMSectionHeading>
                                    <MainPara align="center">
                                        Heatmap is designed to provide real-time
                                        visibility of the contact center floor,
                                        visualizing the most important
                                        operations&nbsp;
                                        <br />
                                        data so you can stay up-to-date, take
                                        action when needed, and create
                                        efficiency for better, more consistent
                                        CX results.
                                    </MainPara>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                        {manageContent &&
                            manageContent.length > 0 &&
                            manageContent.map((item, index) => {
                                return (
                                    <>
                                        <VerticalCards
                                            key={index}
                                            ImageRight={item.ImageRight}
                                            ImageRightColEqual={item.ImageRight}
                                            padTopSmall={item.padTopSmall}
                                            CardTagImg={item.CardTagImg}
                                            VerticalCardHeading={
                                                item.VerticalCardHeading
                                            }
                                            VerticalCardParagraph={
                                                item.VerticalCardParagraph
                                            }
                                            VerticalCardImage={
                                                item.VerticalCardImage
                                            }
                                            sideExtraSmall
                                        />
                                        {index === 0 && (
                                            <Row>
                                                {insightData &&
                                                    insightData.length > 0 &&
                                                    insightData.map(
                                                        (item, index) => {
                                                            return (
                                                                <Col
                                                                    sm="12"
                                                                    lg="4"
                                                                    key={index}>
                                                                    <ScrollAnimation
                                                                        animateIn="fadeInUp"
                                                                        animateOnce={
                                                                            true
                                                                        }>
                                                                        <InsightCards>
                                                                            <InsightIcon>
                                                                                <img
                                                                                    src={
                                                                                        item.icon
                                                                                    }
                                                                                    alt="-"
                                                                                />
                                                                            </InsightIcon>
                                                                            <InsightTitle>
                                                                                {ReactHtmlParser(
                                                                                    item.title
                                                                                )}
                                                                            </InsightTitle>
                                                                            <InsightDesc>
                                                                                <MainPara align="center">
                                                                                    {ReactHtmlParser(
                                                                                        item.desc
                                                                                    )}
                                                                                </MainPara>
                                                                            </InsightDesc>
                                                                        </InsightCards>
                                                                    </ScrollAnimation>
                                                                </Col>
                                                            );
                                                        }
                                                    )}
                                            </Row>
                                        )}
                                    </>
                                );
                            })}
                    </ContainerWideSRM>
                </ManageSection>
            
            
                <ScrollNav data={scrollNavData} />

                <EngageSection id="engage">
                    <SRMFloatingSectionImage
                        x={[-20, 20]}
                        image={`${baseURL}assets/images/tech/heatmap-bg-texture.svg`}
                        width={2000}
                        left="0%"
                        bottom="0"
                    />
                    <Container>
                        <RowCenteredBoth>
                            <Col sm="12" lg="9">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={true}>
                                    <SRMSectionHeading>
                                        Boost contact center operational
                                        efficiency and improve CX outcomes
                                    </SRMSectionHeading>
                                    <PagePara>
                                        <MainPara align="center">
                                            ibex Score automatically crunches
                                            and aggregates CX program
                                            performance data and feeds it into a
                                            single-view, data-rich dashboard.
                                            This empowers agents with direct
                                            insight into their real-time
                                            performance metrics, and offers
                                            supervisors the insights they need
                                            to improve agent and contact center
                                            performance.
                                        </MainPara>
                                    </PagePara>
                                </ScrollAnimation>
                            </Col>
                        </RowCenteredBoth>
                        <Row>
                            {engageData &&
                                engageData.length > 0 &&
                                engageData.map((item, index) => {
                                    return (
                                        <Col sm="12" lg="4" key={index}>
                                            <ScrollAnimation
                                                animateIn="fadeInUp"
                                                animateOnce={true}>
                                                <EngageCards>
                                                    <LayersCards
                                                        layerCardimg={
                                                            item.image
                                                        }
                                                        HeadingLayerCard={
                                                            item.heading
                                                        }
                                                        ParagraphLayerCard={
                                                            item.para
                                                        }
                                                        imgRound
                                                        imgBorder
                                                    />
                                                </EngageCards>
                                            </ScrollAnimation>
                                        </Col>
                                    );
                                })}
                        </Row>
                    </Container>
                </EngageSection>

                <SRMCallActionSection
                    heading="Ready to optimize your contact center floor performance?"
                    btnText="Let's Talk"
                    onBtnClick={() => setDemoRequestForm(true)}
                />
        </Layout>
    );
};

const GoldSection = styled.section`
    background-color: ${colors.socialRep['brandsBG']};
    position: relative;
    z-index: 10;
    overflow-x: hidden;
    ${Media('xlscreens')} {
        padding: 8vw 0 5vw;
        top: -2.4vw;
    }
    ${Media('desktopscreens')} {
        padding: 140px 0 80px;
    }
`;

const GoldBox = styled(FlexColumnCentered)`
    background-color: ${colors.socialRep['brandsBG']};
    border: 1px solid ${colors.white['20']};
    margin: 40px -1px 0;
    padding: 50px 30px;
    p {
        line-height: 1.778;
    }
    ${Media('xlscreens')} {
        padding: 3vw 2.9vw;
        height: 22vw;
        margin-top: 3vw;
        justify-content: flex-start;
    }
    ${Media('tablet')} {
        min-height: 320px;
    }
    ${Media('mobile')} {
        min-height: 1px;
    }
`;

const GoldIcon = styled.div`
    min-height: 58px;
    display: flex;
    align-items: center;
    ${Media('xlscreens')} {
        margin-top: 1vw;
        svg {
            height: 100%;
            max-width: 3vw;
        }
    }
`;

const GoldTitle = styled.h5`
    font-size: 20px;
    color: ${colors.white['100']};
    font-family: ${gilroyBold};
    text-align: center;
    margin-top: 10px;
    ${Media('xlscreens')} {
        font-size: 1.04vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
`;

const GoldContent = styled.div`
    color: ${colors.white['100']};
    font-size: 20px;
    font-family: ${gilroyMedium};
    text-align: center;
    ${Media('xlscreens')} {
        font-size: 1.04vw;
    }
    ${Media('tablet')} {
        font-size: 14px;
    }
`;

const ManageSection = styled.section``;

const InsightCards = styled(FlexColumnCentered)`
    background-color: ${colors.white['100']};
    border: 1px solid ${colors.black['20']};
    padding: 50px 30px;
    min-height: 550px;
    margin-top: 50px;
    justify-content: flex-start;
    ${Media('xlscreens')} {
        padding: 4vw 0.5vw;
        margin-top: 4vw;
        min-height: 21vw;
    }
    ${Media('tablet')} {
        min-height: 1px;
        max-width: 380px;
        margin-left: auto;
        margin-right: auto;
    }
    ${Media('tablet')} {
        margin-top: 0;
        margin-bottom: 20px;
    }
`;

const InsightIcon = styled(FlexCentered)`
    min-height: 50px;
    margin-bottom: 20px;
    ${Media('xlscreens')} {
        min-height: 2.6vw;
        margin-bottom: 1vw;
        img {
            max-height: 2.5vw;
            max-width: 2.2vw;
        }
    }
`;

const InsightTitle = styled.h3`
    font-size: 20px;
    font-family: ${gilroyBold};
    margin-top: 0px;
    margin-bottom: 30px;
    line-height: 1.2;
    min-height: 80px;
    text-align: center;
    max-width: 75%;
    display: inline-flex;
    color: ${colors.socialRep['brandsBG']};
    ${Media('xlscreens')} {
        font-size: 1.1vw;
        margin-bottom: 1vw;
        min-height: 2vw;
    }
    ${Media('tablet')} {
        min-height: 1px;
        max-width: 100%;
    }
    ${Media('mobile')} {
        font-size: 16px;
    }
`;

const InsightDesc = styled.div`
    p {
        line-height: 2;
        margin: 0px;
        font-family: ${gilroyMedium};
    }
    ${Media('mobile')} {
        p {
            line-height: 1.5;
        }
    }
`;

const PagePara = styled.div`
    p {
        font-family: ${gilroyMedium};
    }
`;

const EngageSection = styled.section`
    position: relative;
    z-index: 0;
    background-color: ${colors.white['100']};
    ${Media('xlscreens')} {
        padding: 5vw 0;
    }
`;

const EngageCards = styled.div`
    margin-top: 30px;
    & > div {
        margin-left: 20px;
        margin-right: 20px;
        height: 680px;
        padding-top: 30px;
        div:nth-of-type(2) {
            margin-bottom: 0px;
        }
        div:nth-of-type(3) {
            margin-top: 20px;
            margin-bottom: 20px;
            min-height: 100px;
        }
        h3 {
            font-family: ${gilroySemibold};
            line-height: 1;
            margin: 0px;
        }
        p {
            font-family: ${gilroyMedium};
            margin-top: 0;
        }
    }
    ${Media('xlscreens')} {
        margin-top: 3vw;
        & > div {
            height: 45vw;
            padding-top: 4vw;
            margin-left: 1vw;
            margin-right: 1vw;
            padding: 2.4vw;
            div:nth-of-type(3) {
                margin-top: 1.5vw;
                margin-bottom: 0.95vw;
                height: 5.2vw;
                align-items: center;
            }
            h3 {
                margin-top: 4vw;
            }
            p {
                padding-top: 2vw;
            }
        }
    }
    ${Media('tablet')} {
        & > div {
            height: auto;
            max-width: 380px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            padding-bottom: 30px;
            div:nth-of-type(3) {
                margin-top: 30px;
                min-height: 1px;
                justify-content: center;
            }
        }
    }
`;
export default TrainingSimulator;
