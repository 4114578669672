import React from 'react';
import Slider from 'react-slick';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import { FeaturedPost } from './featured-post';

const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    adaptiveHeight: false,
};

export const FeaturedPostSlider = ({ featuredData }) => {
    const arrSort = featuredData.sort(function (a, b) {
        return new Date(b.post_date) - new Date(a.post_date);
    });
    const filteredData = arrSort.slice(0, 5);

    return featuredData.length > 1 ? (
        <CustomSlider>
            <Slider {...settings}>
                {filteredData &&
                    filteredData.length > 0 &&
                    filteredData.map((post, index) => {
                        return <FeaturedPost key={index} data={post} />;
                    })}
            </Slider>
        </CustomSlider>
    ) : (
        <FeaturedPost data={filteredData[0]} />
    );
};

const CustomSlider = styled.div`
    ${Media('tablet')} {
        .slick-dots {
            bottom: -40px;
        }
    }
`;
