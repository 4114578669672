import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import { baseURL } from 'shared-components/theme/helpers';

export const BundleIntro = () => {
    return (
        <Wrapper>
            <Container>
                <div>
                    <Row>
                        <Col lg="6">
                            <div>
                                <div>
                                    <IntroBundleTitle tag="h1" noMargin={false}>
                                        BundleDealer
                                    </IntroBundleTitle>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6">
                            <IntroRight>
                                Our BundleDealer platform is the only
                                application available in the US offering
                                address-based comparison shopping across
                                multiple service providers.
                            </IntroRight>
                        </Col>
                    </Row>
                </div>

                <div>
                    <Row id="videoHolder">
                        <Col>
                            <img
                                src={`${baseURL}assets/images/bundledealer_topimg.png`}
                                width="100%"
                            />
                        </Col>
                    </Row>
                </div>
            </Container>
        </Wrapper>
    );
};

const IntroBundleTitle = styled.h1`
    padding: 0vw 0vw 3vw 5vw;
    ${Media('tablet')} {
        padding: 0px 0px 20px 0;
    }
`;

const Wrapper = styled.section`
    position: relative;
    ${Media('xlscreens')} {
        padding: 3.4vw 0 4vw;
    }
`;

const IntroRight = styled.div`
    padding-left: 12.7vw;
    padding-right: 4vw;
    padding-top: 1vw;
    line-height: 1.5vw;
    ${Media('tablet')} {
        padding: 0;
        line-height: 1.3;
        font-size: 16px;
        padding-bottom: 50px;
    }
`;
