import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import MainHeading from 'shared-components/main-heading/main-heading';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import HomeBigTag from 'components/home-big-tag/home-big-tag';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { gilroyMedium } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

export const SpeedSection = () => {
    return (
        <>
            <SpeedWrapper>
                <Container>
                    <Row>
                        <Col lg="6">
                            <ScrollAnimation
                                delay={0}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <HomeBigTag>04</HomeBigTag>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                    <RowCentered>
                        <Col lg="5">
                            <ScrollAnimation
                                delay={200}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <MainHeading>
                                    We <br />
                                    are built for <br />
                                    <i>
                                        <b>speed</b>
                                    </i>
                                </MainHeading>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="6">
                            <ScrollAnimation
                                delay={300}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <SpeedPara>
                                    <p>
                                        Launching and scaling a CX program isn’t
                                        easy. <br /> In the race to service
                                        digital-native customers,&nbsp;
                                        <span>speed matters</span>. At ibex, we
                                        work with startups, scale-ups and
                                        industry giants to get their programs
                                        switched-on, turned-up, and
                                        outperforming faster than anyone else in
                                        the industry.
                                    </p>
                                </SpeedPara>
                            </ScrollAnimation>
                        </Col>
                    </RowCentered>
                    <Row>
                        <Col lg="12">
                            <ScrollAnimation
                                delay={100}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <SpeedMidText>
                                    <p>
                                        <img
                                            src={`${baseURL}assets/images/small-arrow-black.svg`}
                                            width="20"
                                            alt="-"
                                        />
                                        Speed to:
                                    </p>
                                </SpeedMidText>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="12">
                            <SpeedDescWrapper>
                                <SpeedDescBox>
                                    <ScrollAnimation
                                        delay={200}
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <img
                                            src={`${baseURL}assets/images/training_icon.svg`}
                                            alt="-"
                                        />
                                        <p>Training</p>
                                    </ScrollAnimation>
                                </SpeedDescBox>
                                <SpeedDescBox>
                                    <ScrollAnimation
                                        delay={200}
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <img
                                            src={`${baseURL}assets/images/proficiency_icon.svg`}
                                            alt="-"
                                        />
                                        <p>Proficiency</p>
                                    </ScrollAnimation>
                                </SpeedDescBox>
                                <SpeedDescBox>
                                    <ScrollAnimation
                                        delay={200}
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <img
                                            src={`${baseURL}assets/images/launch_icon.svg`}
                                            alt="-"
                                        />
                                        <p>Launch</p>
                                    </ScrollAnimation>
                                </SpeedDescBox>
                                <SpeedDescBox>
                                    <ScrollAnimation
                                        delay={200}
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <img
                                            src={`${baseURL}assets/images/performance_icon.svg`}
                                            alt="-"
                                        />
                                        <p>Performance</p>
                                    </ScrollAnimation>
                                </SpeedDescBox>
                                <SpeedDescBox>
                                    <ScrollAnimation
                                        delay={250}
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <img
                                            src={`${baseURL}assets/images/scale_icon.svg`}
                                            alt="-"
                                        />
                                        <p>Scale</p>
                                    </ScrollAnimation>
                                </SpeedDescBox>
                            </SpeedDescWrapper>
                        </Col>
                    </Row>
                </Container>
            </SpeedWrapper>
        </>
    );
};

const SpeedWrapper = styled.section`
    background-color: ${colors.gray_light['40']};
    ${Media('xlscreens')} {
        padding: 7vw 0;
    }
`;

const SpeedPara = styled.div`
    max-width: 420px;
    p {
        font-size: 16px;
        span {
            color: ${colors.pink['100']};
        }
    }
    ${Media('xlscreens')} {
        max-width: 100%;
        p {
            font-size: 1.45vw;
            line-height: 1.5;
        }
    }
`;

const SpeedMidText = styled.div`
    margin: 28px 0;
    p {
        position: relative;
        color: ${colors.pink['100']};
        margin: 0px;
        padding-left: 35px;
        img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    ${Media('xlscreens')} {
        margin: 2vw 0;
        p {
            padding-left: 2vw;
            font-size: 1.3vw;
        }
    }
`;

const SpeedDescWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    ${Media('tablet')} {
        justify-content: space-around;
    }
`;

const SpeedDescBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 10px;
    margin: 10px 0px 30px;
    text-align: center;
    p {
        font-family: ${gilroyMedium};
        font-size: 21px;
        margin: 15px 0px 0px;
    }
    ${Media('xlscreens')} {
        margin: 0.8vw 0px 1.5vw;
        img {
            width: 7.8vw;
        }
        p {
            font-size: 1.5vw;
        }
    }
    ${Media('tablet')} {
        p {
            font-size: 18px;
        }
    }
`;
