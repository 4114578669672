import React from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import {
    Text,
    IndustryTag,
    IndustrySectionHeading,
    ContentCenter,
    // IndustryCaseStudy,
    IndustryPerformanceBox,
} from 'components/industries/common';

export const CaseStudyPerformance = ({ performanceList }) => {
    return (
        <>
            {/* <CaseStudyWrapper>
                <Row noGutters={true}>
                    <Col sm="12">
                        <IndustryCaseStudy
                            image={`${baseURL}assets/images/industries/retail-casestudy1.jpg`}
                            preheading="CASE STUDY"
                            heading="Launching 500 agents for North America’s largest craft retailer."
                            caseLink={`${baseURLPage}resources/case-studies/g/fast-seasonal-retailer-ramp-cx`}
                            noTag
                        />
                    </Col>
                </Row>
            </CaseStudyWrapper> */}
            <CXPerformance id="cx-performance">
                <Container>
                    <div>
                        <ContentCenter>
                            <IndustryTag>
                                APPLY METHODOLOGY TO THE MADNESS
                            </IndustryTag>
                            <IndustrySectionHeading>
                                The ibex Retail & eCommerce <br />
                                CX Performance Methodology
                            </IndustrySectionHeading>
                            <Text>
                                Ensure repeatable success with ibex’s CX
                                Performance Methodology, <br />
                                built specifically for the Retail & eCommerce
                                industry.
                            </Text>
                        </ContentCenter>
                    </div>
                    <Row>
                        {performanceList &&
                            performanceList.length > 0 &&
                            performanceList.map((listItem, index) => {
                                return (
                                    <Col lg="4" md="6" key={index}>
                                        <div>
                                            <IndustryPerformanceBox
                                                count={listItem.count}
                                                image={listItem.imgUrl}
                                                title={listItem.title}
                                                list={listItem.list}
                                            />
                                        </div>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </CXPerformance>
        </>
    );
};
const Section = styled.section`
    ${Media('tablet')} {
        padding: 50px 0;
    }
`;
const CXPerformance = styled(Section)`
    background-color: ${colors.white['100']};
`;
