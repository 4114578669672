import { Container } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import Media from '../../../shared-components/theme/medias';

export const ContainerCompact = styled(Container)`
    &&& {
        ${Media('xlscreens')} {
            padding-left: 21.6vw !important;
            padding-right: 21.6vw !important;
        }
    }
`;

export const ContainerWide = styled(Container)`
    &&& {
        ${Media('xlscreens')} {
            padding-left: ${(props) =>
                props.noPaddingLeft ? '0px !important' : '14.5vw !important'};
            padding-right: 14.5vw !important;
        }
    }
`;
