import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import { gilroySemibold, gilroyMedium } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

export const BIAnalyticsSection = () => {
    return (
        <BIAnalytics id="analytics">
            <div>
                <Container>
                    <Row>
                        <Col md="12">
                            <AnalyticHeading>
                                Analytics in action: Identifying Churn Triggers
                                Leveraging Customer Insight
                            </AnalyticHeading>
                        </Col>
                    </Row>
                    <RowCentered>
                        <Col lg="7">
                            <AnalyticsPieReadings>
                                <Row noGutters={true}>
                                    <Col md="6">
                                        <PieReading>
                                            <ReadLabel>A</ReadLabel>
                                            Billing 34.2%
                                        </PieReading>
                                        <PieReading>
                                            <ReadLabel>B</ReadLabel>
                                            Cancel 23.56%
                                        </PieReading>
                                        <PieReading>
                                            <ReadLabel>C</ReadLabel>
                                            Account Service 19.54%
                                        </PieReading>
                                    </Col>
                                    <Col md="6">
                                        <PieReading>
                                            <ReadLabel>D</ReadLabel>
                                            Repair & Technical Support 12.93%
                                        </PieReading>
                                        <PieReading>
                                            <ReadLabel>E</ReadLabel>
                                            Order Inquiry 5.75%
                                        </PieReading>
                                        <PieReading>
                                            <ReadLabel>F</ReadLabel>
                                            Others 3.16%
                                        </PieReading>
                                    </Col>
                                </Row>
                            </AnalyticsPieReadings>
                        </Col>
                        <Col lg="5">
                            <AnalyticsPie>
                                <img
                                    src={`${baseURL}assets/images/analytics-pie.svg`}
                                    alt="-"
                                />
                            </AnalyticsPie>
                        </Col>
                    </RowCentered>
                </Container>
            </div>
            <Container fluid={true}>
                <BubblesRow>
                    <div>
                        <BubblesList>
                            <li>Price increases </li>
                            <li>Product price too expensive</li>
                            <li>Promo expiration </li>
                            <li>Pricing delta new vs existing customers</li>
                        </BubblesList>
                    </div>
                    <div>
                        <BubblesListTwo>
                            <li>We don’t need the service anymore.</li>
                            <li>Already have another provider</li>
                            <li>
                                Moved to another location, have an existing
                                provider
                            </li>
                        </BubblesListTwo>
                    </div>
                </BubblesRow>
                <Row>
                    <Col md="12">
                        <div>
                            <AnalyticBubbleImg>
                                <img
                                    src={`${baseURL}assets/images/analytics-bubble.svg`}
                                    alt="-"
                                />
                            </AnalyticBubbleImg>
                        </div>
                    </Col>
                </Row>
                <BubblesRowNormal>
                    <div>
                        <BubblesListThree>
                            <li>Intermittent internet speeds</li>
                            <li>Tech appointments not timely</li>
                        </BubblesListThree>
                    </div>
                    <div>
                        <BubblesListFour>
                            <li>Price increases </li>
                            <li>Product price too expensive</li>
                            <li>Promo expiration </li>
                            <li>Pricing delta new vs existing customers</li>
                        </BubblesListFour>
                    </div>
                </BubblesRowNormal>
            </Container>
        </BIAnalytics>
    );
};

const BIAnalytics = styled.section`
    ${Media('xlscreens')} {
        padding: 7vw 0;
    }
`;

const AnalyticHeading = styled.h2`
    font-size: 45px;
    letter-spacing: -1px;
    max-width: 60%;
    line-height: 1.2;
    margin: 0;
    ${Media('xlscreens')} {
        font-size: 3.15vw;
        letter-spacing: -0.1vw;
    }
    ${Media('tablet')} {
        font-size: 36px;
        max-width: 100%;
        margin-bottom: 30px;
    }
`;

const AnalyticsPie = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: -50px;
    img {
        max-width: 100%;
    }
    ${Media('xlscreens')} {
        margin-top: -2.6vw;
        img {
            width: 25.5vw;
        }
    }
    ${Media('tablet')} {
        margin-top: 50px;
        justify-content: center;
    }
`;

const AnalyticsPieReadings = styled.div``;

const PieReading = styled.div`
    font-family: ${gilroyMedium};
    font-size: 24px;
    min-height: 90px;
    border-bottom: 1px solid ${colors.gray['20']};
    display: flex;
    align-items: center;
    white-space: nowrap;
    ${Media('xlscreens')} {
        font-size: 1.23vw;
        min-height: 5vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
        white-space: normal;
        min-height: 1px;
        padding: 20px 0px;
    }
`;

const ReadLabel = styled.span`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid ${colors.gray_dark['100']};
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${gilroySemibold};
    color: ${colors.pink['100']};
    margin-right: 30px;
    ${Media('xlscreens')} {
        flex: 0 0 2.4vw;
        width: 2.4vw;
        height: 2.4vw;
        margin-right: 1vw;
    }
`;

const AnalyticBubbleImg = styled.div`
    display: flex;
    justify-content: center;
    img {
        position: relative;
        left: -30px;
        ${Media('xlscreens')} {
            width: 78.4vw;
            left: -1vw;
        }
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const BubblesRowNormal = styled.div`
    display: flex;
    ${Media('tablet')} {
        display: none;
    }
`;

const BubblesRow = styled(BubblesRowNormal)`
    align-items: flex-end;
`;

const BubblesList = styled.ol`
    padding: 0px;
    margin: 50px 0px;
    counter-reset: section;
    li {
        counter-increment: section;
        font-family: ${gilroyMedium};
        font-size: 15px;
        margin-bottom: 10px;
        &:before {
            content: '0' counter(section);
            color: ${colors.pink['100']};
            display: inline-block;
            width: 40px;
        }
    }
    ${Media('xlscreens')} {
        margin: 5vw 0 3.5vw;
        padding-left: 12vw;
        li {
            font-size: 1.15vw;
            margin-bottom: 0.8vw;
            &:before {
                width: 4vw;
            }
        }
    }
`;

const BubblesListTwo = styled(BubblesList)`
    ${Media('xlscreens')} {
        padding-left: 17.5vw;
    }
`;

const BubblesListThree = styled(BubblesList)`
    ${Media('xlscreens')} {
        margin: 3.5vw 0 0;
        padding-left: 9vw;
    }
`;

const BubblesListFour = styled(BubblesList)`
    ${Media('xlscreens')} {
        margin: 3.5vw 0 0;
        padding-left: 12.5vw;
    }
`;
