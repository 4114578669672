import React from 'react';
import Layout from 'components/layout';
import {
    BottomSection,
    TechPage,
    WaveXIntro,
    SubIntro
} from 'components/technology/wavex';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { baseURL, baseURLPage } from 'shared-components/theme/helpers';
import styled from 'styled-components';

const performancePages = [
    {
        title: 'Virtual Floor Management System',
        para:
            'Real-time agent assistance for high-performing CX. Boost Agent Outcomes & Productivity.',
        pageLink: `${baseURLPage}technology/virtual-floor-management-system`,
        thumbImg: `${baseURL}assets/images/tech/virtual-floor-manage-tech.svg`,
        rowReverse: false,
    },
    {
        title: 'Live Agent Desktop Video Monitoring',
        para:
            'ibex Round provides complete multi-agent call & desktop monitoring, for complete oversight of your global customer support teams.',
        pageLink: `${baseURLPage}technology/round`,
        thumbImg: `${baseURL}assets/images/tech/agent-video-monitioring-tech.svg`,
        rowReverse: true,
    },
    {
        title: 'Fraud Prevention & Monitoring',
        para:
            'Your comprehensive fraud detection system, designed for the contact center. Contact Center Compliance and Fraud Management.',
        pageLink: `${baseURLPage}technology/witness`,
        thumbImg: `${baseURL}assets/images/tech/fraud-prevention-tech.svg`,
        rowReverse: false,
    },
    {
        title: 'Live Agent Assistance Monitoring',
        para:
            'A color-coded dashboard displaying a graphical view of the contact center floor with real-time insights to give you complete control of your contact center operations.',
        pageLink: `${baseURLPage}technology/heatmap`,
        thumbImg: `${baseURL}assets/images/tech/agent-assistance-tech.svg`,
        rowReverse: true,
    },
];

const CXManagementWaveX = () => {
    return (
        <Layout page="wavex">
            <Breadcrumbs>
                <li>Technology</li>
                <li>WaveX</li>
            </Breadcrumbs>
            <WaveXIntro
                pageTag="Wave X"
                pageHeading="The CX Management <br/>Suite"
                pagePara="Monitor and manage every interaction, <br/>for complete CX transparency & oversight."
                introImg={`${baseURL}assets/images/tech/wave-mngmnt-img.png`}
                gradientImg={`${baseURL}assets/images/tech/wavex-mngmnt-suit.png`}
                imgHeight={'640'}
                imgWidth={'640'}
                from={{
                    transform: 'translateY(0) scale(1)',
                }}
                to={{
                    transform: 'translateY(18vw) scale(1.7)',
                }}
            />
            <SubIntro
                imgURL={`${baseURL}assets/images/tech/wave-mngmnt-img.png`}
                subHeading={
                    'CX Technology Built to Empower Your Entire <br />CX Operation.'
                }
            />
            <TechPages>
                {performancePages &&
                    performancePages.length > 0 &&
                    performancePages.map((page, index) => {
                        return (
                            <TechPage
                                key={index}
                                $reverse={page.rowReverse}
                                title={page.title}
                                para={page.para}
                                thumbImg={page.thumbImg}
                                pageLink={page.pageLink}
                            />
                        );
                    })}
            </TechPages>
            <BottomSection
                heading="Ready to Upgrade Your CX?"
                subHeading="Performance gamification technology designed to incentivize agent <br/>performance for enhanced CX outcomes."
            />
        </Layout>
    );
};
const TechPages = styled.div``;
export default CXManagementWaveX;
