import React from 'react';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
export const DEFullHeight = ({ children, bgDark, paddingTop, id }) => {
    return (
        <Wrapper id={id} bgDark={bgDark} paddingTop={paddingTop}>
            {children}
        </Wrapper>
    );
};
const Wrapper = styled.div`
    overflow: hidden;
    position: relative;
    background-color: ${(props) =>
        props.bgDark
            ? colors.socialRep['brandsBG']
            : colors.technologyDropdown['box']};
    .container-fluid {
        padding: 0;
    }
    ${Media('xlscreens')} {
        height: calc(56.25vw - 5.8vw);
        width: 100%;
    }
`;
