import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import {
    gilroyMedium,
    gilroyLight,
    gilroyBold,
} from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

export const BIOperationsSection = () => {
    return (
        <BIOperations id="operations">
            <Container>
                <Row>
                    <Col lg="12">
                        <div>
                            <OperationHead>
                                Of customers that showed churn language in
                                survey feedback, 40% customers cancelled within
                                90 days. <br /> 33% of those customers who
                                cancelled were over 5 years customer tenure.
                            </OperationHead>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div>
                <OperationsBanner>
                    <Container>
                        <Row>
                            <Col lg="6">
                                <OperationBannerBox>
                                    <h2>Analytics & Operational Synergy</h2>
                                    <p>
                                        A good BI program connects the dots
                                        between operational teams and
                                        touchpoints, ensuring multi-functional
                                        alignment & communication.
                                    </p>
                                </OperationBannerBox>
                            </Col>
                        </Row>
                    </Container>
                </OperationsBanner>
            </div>
            <div>
                <OperationsListWrapper>
                    <Container>
                        <Row>
                            <Col lg="2">
                                <OprBox> Operations </OprBox>
                            </Col>
                            <Col lg="2">
                                <OprBox> Client Services </OprBox>
                            </Col>
                            <Col lg="2">
                                <OprBox> Training </OprBox>
                            </Col>
                            <Col lg="2">
                                <OprBox> Recruitment </OprBox>
                            </Col>
                            <Col lg="2">
                                <OprBox> Front-Line Agents </OprBox>
                            </Col>
                            <Col lg="2">
                                <OprBox> Quality Assurance </OprBox>
                            </Col>
                        </Row>
                        <InsightWrapper>
                            <Row noGutters={true}>
                                <Col lg="4">
                                    <InsightBox>
                                        <img
                                            src={`${baseURL}assets/images/cust-ins.jpg`}
                                            alt="-"
                                        />
                                        <h4>Customer Insight</h4>
                                    </InsightBox>
                                </Col>
                                <Col lg="4">
                                    <InsightBox>
                                        <img
                                            src={`${baseURL}assets/images/prod-ins.jpg`}
                                            alt="-"
                                        />
                                        <h4>Product Insight</h4>
                                    </InsightBox>
                                </Col>
                                <Col lg="4">
                                    <InsightBox>
                                        <img
                                            src={`${baseURL}assets/images/mark-ins.jpg`}
                                            alt="-"
                                        />
                                        <h4>Market Insight</h4>
                                    </InsightBox>
                                </Col>
                            </Row>
                        </InsightWrapper>
                    </Container>
                </OperationsListWrapper>
            </div>
        </BIOperations>
    );
};

const BIOperations = styled.section`
    border-top: 1px solid ${colors.gray['20']};
`;

const OperationHead = styled.div`
    font-size: 20px;
    font-family: ${gilroyMedium};
    margin-bottom: 60px;
    text-align: center;
    line-height: 1.8;
    ${Media('xlscreens')} {
        font-size: 1.23vw;
        margin-bottom: 7vw;
    }
`;

const OperationsBanner = styled.div`
    background: url('${baseURL}assets/images/operation-banner.jpg') no-repeat
        center;
    background-size: cover;
`;

const OperationBannerBox = styled.div`
    background-color: ${colors.white['100']};
    margin-right: -50px;
    margin-top: 300px;
    margin-bottom: -50px;
    h2 {
        font-size: 45px;
        margin-top: 0px;
        margin-bottom: 40px;
    }
    p {
        font-family: ${gilroyLight};
        font-size: 16px;
        margin-bottom: 0px;
    }
    ${Media('xlscreens')} {
        padding: 4.3vw 5.5vw;
        margin-right: -2.6vw;
        margin-top: 12.7vw;
        margin-bottom: -3vw;
        h2 {
            font-size: 3.2vw;
            letter-spacing: -0.19vw;
            margin-bottom: 2.2vw;
        }
        p {
            font-size: 1vw;
        }
    }
    ${Media('tablet')} {
        margin-top: 150px;
        margin-right: 0px;
        padding: 20px;
        box-sizing: border-box;
        h2 {
            font-size: 33px;
        }
        p {
            font-size: 14px;
        }
    }
`;

const OperationsListWrapper = styled.div`
    padding-top: 100px;
    ${Media('xlscreens')} {
        padding-top: 6vw;
    }
`;

const OprBox = styled.div`
    border: 1px solid ${colors.gray['100']};
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 190px;
    font-family: ${gilroyBold};
    font-size: 18px;
    padding: 20px;
    text-align: center;
    ${Media('xlscreens')} {
        font-size: 1.15vw;
        min-height: 10vw;
    }
    ${Media('tablet')} {
        min-height: 1px;
        padding: 50px;
        font-size: 22px;
        margin-top: -1px;
    }
`;

const InsightWrapper = styled.div`
    margin-top: 150px;
    ${Media('xlscreens')} {
        margin-top: 6vw;
    }
    ${Media('tablet')} {
        margin-top: 60px;
    }
`;

const InsightBox = styled.div`
    position: relative;
    height: 400px;
    overflow: hidden;
    &:before,
    h4 {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
    }
    &:before {
        background: ${colors.gray_dark['60']};
        z-index: 9;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 1s ease;
    }
    h4 {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${colors.white['100']};
        font-family: ${gilroyBold};
        font-size: 33px;
        margin: 0px;
        z-index: 10;
    }
    &:hover {
        img {
            transform: scale(1.2);
        }
    }
    ${Media('xlscreens')} {
        height: 25vw;
        h4 {
            font-size: 1.72vw;
        }
    }
    ${Media('mobile')} {
        height: 350px;
    }
`;
