import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import { Parallax } from 'react-scroll-parallax';
import colors from 'shared-components/theme/colors';
import { gilroyExtrabold } from 'shared-components/theme/fonts';
import ReactHtmlParser from 'react-html-parser';
import { SRMSectionHeading } from 'components/social-rep-man/common';
import MainPara from 'shared-components/main-para/main-para';

const moderationContent = [
    {
        delay: 100,
        iconUrl: `${baseURL}assets/images/unify-icon.svg`,
        heading: 'Unify.',
        para: `Aggregate Cross-Platform Social <br />
        Media Feedback & Reviews Into One <br />
        Centralized, Intuitive Dashboard`,
    },
    {
        delay: 400,
        iconUrl: `${baseURL}assets/images/analyze-icon.svg`,
        heading: 'Analyze.',
        para: `Automatically Measure, Assess <br />
        Sentiment, Categorize, & Manage <br />
        Unsolicited Feedback`,
    },
    {
        delay: 600,
        iconUrl: `${baseURL}assets/images/act-icon.svg`,
        heading: 'Act.',
        para: `Connect Directly Into Your Customer <br />
        Support & Content Moderation Teams <br />
        for Unified Brand Voice & Action`,
    },
];

export const ModerateSection = () => {
    return (
        <ModerationSection>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <SRMSectionHeading>
                    Next-Level Your Brand Reputation <br /> With Actionable
                    Social Insights
                </SRMSectionHeading>
                <MainPara align="center">
                    Cut through the noise and get to the feedback that matters
                    most to your brand’s online success.
                </MainPara>
            </ScrollAnimation>
            <ActionBoxes>
                <ActionBoxesbg x={[-10, 40]}>
                    <img
                        src={`${baseURL}assets/images/next-level-bg.svg`}
                        alt="-"
                    />
                </ActionBoxesbg>
                <Container>
                    <Row noGutters={true}>
                        {moderationContent &&
                            moderationContent.length > 0 &&
                            moderationContent.map((item, index) => {
                                return (
                                    <Col lg="4" key={index}>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={true}
                                            delay={item.delay}>
                                            <CustomColBox>
                                                <ActionIcon>
                                                    <img
                                                        src={item.iconUrl}
                                                        alt="-"
                                                    />
                                                </ActionIcon>
                                                <ActionHeading>
                                                    {item.heading}
                                                </ActionHeading>
                                                <MainPara align="center">
                                                    {ReactHtmlParser(item.para)}
                                                </MainPara>
                                            </CustomColBox>
                                        </ScrollAnimation>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </ActionBoxes>
        </ModerationSection>
    );
};

const ModerationSection = styled.section`
    position: relative;
    background-color: ${colors.gray_lightest['100']};
`;

const ActionBoxes = styled.div`
    padding: 100px 0px;
    position: relative;
    ${Container} {
        position: relative;
        z-index: 99;
    }
    .parallax-inner {
        height: 100%;
    }
    ${Media('xlscreens')} {
        padding: 5.2vw 0;
        margin-top: 3vw;
    }
    ${Media('tablet')} {
        padding: 100px 20px;
        margin-top: 0px;
        padding-bottom: 0px;
    }
    ${Media('mobile')} {
        padding: 50px 20px 0px 20px;
    }
`;

const ActionBoxesbg = styled(Parallax)`
    position: absolute;
    bottom: -100px;
    z-index: 1;
    width: 100%;
    transform: translate(-110%, 0px);
    height: 719px;
    div {
        height: 100%;
    }
    img {
        height: 100%;
    }
    ${Media('xlscreens')} {
        height: 37.44vw;
        bottom: -5vw;
    }
    ${Media('tablet')} {
        bottom: 70px;
    }
`;

const CustomColBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.black['100']};
    background-color: ${colors.white['100']};
    position: relative;
    z-index: 2;
    height: 400px;
    p {
        margin-top: 0px;
    }
    ${Media('xlscreens')} {
        height: 20.8vw;
    }
    ${Media('tablet')} {
        margin-bottom: 20px;
        height: 260px;
    }
`;

const ActionIcon = styled.div`
    margin: 20px 0 40px;
    img {
        width: 100%;
    }
    ${Media('xlscreens')} {
        margin: 1.6vw 0 3.12vw;
    }
    ${Media('tablet')} {
        margin: 15px 0 30px;
    }
    ${Media('mobile')} {
        img {
            height: 45px;
        }
    }
`;

const ActionHeading = styled.div`
    margin-bottom: 30px;
    font-size: 30px;
    font-family: ${gilroyExtrabold};
    text-align: center;
    ${Media('xlscreens')} {
        margin-bottom: 1.56vw;
        font-size: 1.56vw;
    }
    ${Media('tablet')} {
        margin-bottom: 10px;
    }
`;
