import React from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import CXFeatureBox from 'components/cx-feature-box/cx-feature-box';
import Media from 'shared-components/theme/medias';
import { gilroySemibold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

const featureBoxItems = [
    {
        left: true,
        content: [
            {
                imgSrc: `${baseURL}assets/images/cx-feature-1.jpg`,
                animationDelay: 100,
                title: 'Send branded surveys at any touchpoint.',
                description:
                    'Our platform allows you to send branded, customized surveys to any customer, after any interaction',
            },
            {
                imgSrc: `${baseURL}assets/images/cx-feature-3.jpg`,
                animationDelay: 100,
                title: 'Full-Lifecycle Data Analytics.',
                description:
                    'Our Business Intelligence & Professional Services teams take a holistic, end-to-end approach to analyzing your customer feedback, revealing actionable insights to help your program pivot, evolve, and grow.',
            },
            {
                imgSrc: `${baseURL}assets/images/cx-feature-5.jpg`,
                animationDelay: 100,
                title: 'Build lifetime brand advocates & Reliable ROI.',
                description:
                    'With a more consistent & reliable customer experience, consumers are more likely to engage with your brand in positive ways, increasing overall brand advocacy. The net result? ROI.',
            },
        ],
    },
    {
        left: false,
        content: [
            {
                imgSrc: `${baseURL}assets/images/cx-feature-2.jpg`,
                animationDelay: 500,
                title: 'Perform real-time feedback analysis & issue resolution.',
                description:
                    'Our technology analyses customer feedback and sentiment in real-time - then pushes you to act on and resolve issues before your customers start looking sideways.',
            },
            {
                imgSrc: `${baseURL}assets/images/cx-feature-4.jpg`,
                animationDelay: 500,
                title: 'Remove Cost & Drive Revenue.',
                description:
                    'Through streamlined interactions, improved customer experience, and reduced concessions, your brand is enabled to pursue revenue and reduce cost across the CX value chain.',
            },
        ],
    },
];
const FeatureBox = ({ children, left }) =>
    left ? (
        <Col md="6">
            <FeatureLeft>{children}</FeatureLeft>
        </Col>
    ) : (
        <Col md="6">
            <FeatureRight>{children}</FeatureRight>
        </Col>
    );
export const PlatformSection = () => {
    return (
        <>
            <CXFeatures id="features">
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <FeatureHead size="small">
                                    Our platform excels at helping brands
                                    proactively engage with customers, take
                                    effective and valuable action with any
                                    customer, and implement strategic
                                    multi-channel conversations at any
                                    touchpoint.
                                </FeatureHead>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4">
                            <FeatureHead>
                                <div>
                                    Everything <br /> you need for <br />
                                    proactive CX.
                                </div>
                            </FeatureHead>
                        </Col>
                        <Col lg="8">
                            <Row>
                                {featureBoxItems &&
                                    featureBoxItems.map((item, index) => {
                                        return (
                                            <FeatureBox
                                                key={index}
                                                left={item.left}>
                                                {item.content &&
                                                    item.content.map(
                                                        (item2, index2) => {
                                                            return (
                                                                <div
                                                                    key={
                                                                        index2
                                                                    }>
                                                                    <CXFeatureBox
                                                                        image={
                                                                            item2.imgSrc
                                                                        }
                                                                        title={
                                                                            item2.title
                                                                        }
                                                                        description={
                                                                            item2.description
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </FeatureBox>
                                        );
                                    })}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </CXFeatures>
        </>
    );
};
const CXFeatures = styled.section`
    padding-top: 0;
`;

const FeatureHead = styled.h2`
    position: sticky;
    top: 10vw;
    font-size: ${(props) => (props.size === 'small' ? '21px' : '24px')};
    font-family: ${gilroySemibold};
    line-height: 1.3;
    margin: ${(props) =>
        props.size === 'small' ? '30px 0 100px' : '100px 0 0'};
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.size === 'small' ? '2vw' : '2.5vw')};
        margin: ${(props) =>
            props.size === 'small' ? '2vw 0 8vw' : '21vw 0 0'};
    }
    ${Media('tablet')} {
        margin: ${(props) =>
            props.size === 'small' ? '30px 0 50px' : '30px 0 0'};
    }
`;

const FeatureLeft = styled.div``;

const FeatureRight = styled.div`
    margin-top: 100px;
    ${Media('xlscreens')} {
        margin-top: 15vw;
    }
    ${Media('tablet')} {
        margin-top: 250px;
    }
    ${Media('mobile')} {
        margin-top: 0px;
    }
`;
