import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ReactHtmlParser from 'react-html-parser';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import { EbookMainPara } from 'components/ebook/common';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import { Parallax } from 'react-scroll-parallax';

export const FeatureBox = ({
    imgUrl,
    heading,
    para,
    paraColor,
    lineBg,
    bgColor,
    headingColor,
    supColor,
    noPaddingBottom,
    id,
}) => {
    return (
        <Wrapper
            bgColor={bgColor}
            supColor={supColor}
            noPaddingBottom={noPaddingBottom}
            id={id}
        >
            <Container>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <Row>
                        <Col lg="4">
                            <FeatureImg>
                                <img src={imgUrl} alt="-" />
                                <CustomParallax x={['-15%', '0%']}>
                                    <Line lineBg={lineBg} />
                                </CustomParallax>
                            </FeatureImg>
                        </Col>
                        <Col lg="8">
                            <FeatureContentWrapper>
                                <FeatureHeading headingColor={headingColor}>
                                    {heading}
                                </FeatureHeading>
                                <EbookMainPara color={paraColor} seasonal>
                                    {ReactHtmlParser(para)}
                                </EbookMainPara>
                            </FeatureContentWrapper>
                        </Col>
                    </Row>
                </ScrollAnimation>
            </Container>
        </Wrapper>
    );
};
const Wrapper = styled.section`
    background-color: ${(props) => props.bgColor};
    padding-bottom: ${(props) => props.noPaddingBottom && '0'};
    a {
        font-size: 8px;
        position: relative;
        color: ${(props) => props.supColor ?? colors.ebook.crypto['main2']};
        top: -20px;
        left: 1px;
        &:hover {
            color: ${colors.ebook.crypto['main2']};
        }
    }
    ${Media('xlscreens')} {
        a {
            font-size: 0.7vw;
            top: -0.5vw;
        }
    }
`;
const FeatureImg = styled.div`
    position: relative;
    img {
        width: 400px;
        object-fit: cover;
    }
    ${Media('xlscreens')} {
        margin-top: 1.56vw;
        img {
            width: 20.83vw;
        }
    }
    ${Media('tablet')} {
        img {
            margin: 0px auto 50px;
        }
    }
    ${Media('mobile')} {
        img {
            width: 100%;
            margin: 0px auto 20px;
        }
    }
`;
const CustomParallax = styled(Parallax)`
    position: absolute;
    right: -63px;
    top: 90px;
    ${Media('xlscreens')} {
        right: -3.28vw;
        top: 4.68vw;
    }
    ${Media('tablet')} {
        display: none;
    }
`;
const Line = styled.div`
    height: 5px;
    width: 400px;
    background-color: ${(props) => props.lineBg ?? colors.white['100']};
    transform: rotate(45deg);
    ${Media('xlscreens')} {
        height: 0.26vw;
        width: 20.83vw;
    }
`;
const FeatureHeading = styled.h3`
    color: ${(props) => props.headingColor ?? colors.ebook.crypto['main2']};
    font-size: 28px;
    font-family: ${gilroyBold};
    ${Media('xlscreens')} {
        font-size: 1.45vw;
    }
`;
const FeatureContentWrapper = styled.div`
    ${Media('xlscreens')} {
        padding-left: 4.16vw;
        padding-right: 2.66vw;
    }
`;
