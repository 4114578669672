import React, { useEffect, useRef } from 'react';
import Layout from 'components/layout';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { baseURL, baseURLPage } from 'shared-components/theme/helpers';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import { gilroyMedium } from 'shared-components/theme/fonts';
import { sourceSansPro } from 'shared-components/theme/fonts';
import { EbookBigText } from 'components/ebook/common/big-text';
import {
    DEHeader,
    DEFooter,
    DEHeading,
    DEHeading2,
    DEMediumHeading,
    DEPara,
    DEFullHeight,
} from 'components/ebook/digital-experience';
import { EbookFloatingImage } from 'components/ebook/common/floating-image';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-scroll';
import { gilroyLight } from 'shared-components/theme/fonts';
import Button from 'shared-components/button/button';
import ScrollAnimation from 'react-animate-on-scroll';
import ProgressBar from 'progressbar.js';
import useOnScreen from './viewport-observer';

const FeatureList1 = [
    {
        para: `For a successful CX program, you need to incorporate the right channels into your member journey—for the right reasons—and integrate them to create a unified experience. Be intentional about each channel you use. Map the customer journey between and across channels to identify and resolve any gaps.`,
    },
    {
        icon: `${baseURL}assets/images/ebook/de-envelope-icon.svg`,
        title: 'Email',
        para:
            'Email offers customers the convenience of asynchronous communication or, in other words, freedom from waiting on hold. And it’s good for business, too, since it’s low-cost and easy to manage and segment based on customer requests.',
    },
    {
        icon: `${baseURL}assets/images/ebook/de-chat-icon.svg`,
        title: 'Live chat',
        para:
            'Live chat has the highest satisfaction level of any customer service channel, with 73% of customers saying it’s their preferred method of getting support.14 Real-time interaction (without the typical wait times of voice and email support) satisfies customers’ need for immediate answers. And, increasingly, chatbots are making those resolutions even faster and less resource-intensive for companies.',
    },
    {
        icon: `${baseURL}assets/images/ebook/de-social-media-icon.svg`,
        title: 'Social media',
        para:
            'Customers appreciate being able to get support on the apps they’re already using every day, such as Facebook, Instagram, and YouTube. The accessibility, convenience, and ease of social media is unmatched by other channels. Delivering support on social allows you to reach a wider demographic than traditional channels. But it’s important to make sure you’re responding promptly and have a social listening strategy in place to give customers the level of attention they expect.',
    },
];

const FeatureList2 = [
    'Giving members instant updates and real-time information related to billing, application statuses, or upcoming changes',
    'Making communication two-way by sending regular surveys that can help identify and eliminate friction in the customer journey',
    'Using AI to share relevant educational resources and information that helps members reach their financial goals and choose the right products and services',
    'Meeting consumers where they are with highly targeted marketing that drives awareness, engagement, and loyalty',
];

const FeatureList3 = [
    {
        icon: `${baseURL}assets/images/ebook/de-file-icon.svg`,
        title: 'Regulatory compliance',
        para:
            'For credit unions, it’s critical to comply with highly complex regulatory frameworks. It follows that your ideal partner has the expertise in the compliance frameworks relevant to financial services, and the proper teams dedicated to ensuring you’re keeping up with changing regulatory requirements, expectations, and industry practices. ',
    },
    {
        icon: `${baseURL}assets/images/ebook/de-chess-pieces.svg`,
        title: 'Upfront leadership training and investment',
        para:
            'An expert outsourcer understands that, when launching a client, having your leadership trained and invested upfront—before any agent is hired—is imperative to program success. It results in dramatically higher performance and returns for your brand. Pay attention to your potential partner’s training plan; if management isn’t involved during program launch, it’s a red flag.',
    },
    {
        icon: `${baseURL}assets/images/ebook/de-smile-emoji.svg`,
        title: 'Community and brand immersion',
        para:
            'Because community is a defining quality of credit unions, members expect a personal, local experience. That can be tricky to pull off if you don’t have the right outsourcer. Make sure your potential partner tailors their contact center floors to the branding and culture of your credit union, so you can reap the rewards of an established operation without sacrificing the brand experience. The ideal partner gives you access to a worldwide network of turnkey contact centers with multiple service delivery locations, pricing options, and languages. They have a policy of giving back to the communities in which they operate, high rates of agent retention and satisfaction, and a consistent track record of client success across their locations.',
    },
    {
        icon: `${baseURL}assets/images/ebook/de-headphone.svg`,
        title: 'Top-notch agents',
        para:
            'By outsourcing your customer support team, you get access to a highly skilled and experienced recruiting team that can quickly pinpoint the best candidates for your contact center, while managing employee retention, fostering engagement, and controlling churn. Top-notch outsourcers know where the best talent is located, have a defined and rigorous process for identifying and selecting candidates, and have a demonstrated track record of recruiting top talent.',
    },
];

const FeatureList4 = [
    {
        icon: `${baseURL}assets/images/ebook/de-monitor-icon.svg`,
        title: 'Advanced training for agent performance',
        para:
            'Working with an outsourcer lets you take advantage of cutting-edge learning technology for onboarding agents, including training simulators, gamiﬁcation, and virtual learning, that empowers your team to continuously improve their proﬁciency and overachieve for your brand. Any good training program will have data around speed-to-proficiency, so make sure you ask for it. Check to see if the outsourcer uses training simulators to increase day-one performance, as well as ongoing training for upskilling your agents.',
    },
    {
        icon: `${baseURL}assets/images/ebook/de-jet-icon.svg`,
        title: 'Speed to performance',
        para:
            'With so many beneﬁts that set your frontline apart, including selective hiring, smart training, and aligned leadership, your outsourced customer support team becomes rapidly proﬁcient and hits the ground running. To ensure your day-to-day operations are as successful as possible, your outsourcer needs to have a sophisticated workforce management team. They should also be able to show you a successful history of rapid speed-to-green.',
    },
    {
        icon: `${baseURL}assets/images/ebook/de-switch-icon-white.svg`,
        title: 'Experienced project management',
        para:
            'Having an experienced project management team dedicated to coordinating and planning your program launch lets you do it right the ﬁrst time, while redirecting valuable internal resources toward other important revenue-generating activities. Your ideal partner has a PMO group with defined implementation and launch procedures, a detailed implementation timeline they can show you, and case studies around successful launches.',
    },
    {
        icon: `${baseURL}assets/images/ebook/de-chart-icon.svg`,
        title: 'Business intelligence and analytics',
        para:
            'There’s no growth without continuous improvement. And you can’t improve what you don’t measure. Working with an outsourcer gives you access to business intelligence and insights that help you identify key challenges, build alignment across the organization, and uncover opportunities to improve customer-focused processes. Look into your potential partner’s business intelligence capabilities to ensure they’re both insightful and actionable. They should be able to provide insight across the lifecycle, and feed that back into your contact center for improved agent interactions and performance.',
    },
];

const strategyItems = [
    {
        icon: `${baseURL}assets/images/ebook/de-strategy-1.svg`,
        heading: 'The right channels',
    },
    {
        icon: `${baseURL}assets/images/ebook/de-strategy-2.svg`,
        heading: 'The right agents',
    },
    {
        icon: `${baseURL}assets/images/ebook/de-strategy-3.svg`,
        heading: 'The right content',
    },
    {
        icon: `${baseURL}assets/images/ebook/de-strategy-4.svg`,
        heading: 'The right fit for each member',
    },
    {
        icon: `${baseURL}assets/images/ebook/de-strategy-5.svg`,
        heading: 'The right technology',
    },
];

const BuildingDigitalExp = () => {
    const progressCircle1 = useRef(null);
    const progressCircle2 = useRef(null);
    const progressCircle3 = useRef(null);
    const progressCircle4 = useRef(null);
    const circleWrape = useRef();

    const isVisible = useOnScreen(circleWrape);

    const CircleProgress = (element, circleValue) => {
        if (element.current) {
            var bar = new ProgressBar.Circle(element.current, {
                color: colors.white['100'],
                // This has to be the same size as the maximum width to
                // prevent clipping
                strokeWidth: 16,
                trailWidth: 0,
                easing: 'easeInOut',
                duration: 3000,

                from: { color: colors.ebook.digitalExp['green'], width: 16 },
                to: { color: colors.ebook.digitalExp['green'], width: 16 },
                // Set default step function for all animate calls
                step: function (state, circle) {
                    circle.path.setAttribute('stroke', state.color);
                    circle.path.setAttribute('stroke-width', state.width);

                    var value = Math.round(circle.value() * 100);
                    if (value === 0) {
                        circle.setText('');
                    } else {
                        circle.setText(value + '%');
                    }
                },
            });
            bar.text.style.fontFamily = gilroyBold;
            bar.text.style.fontSize = '1.56vw';
            bar.animate(circleValue);
        }
    };
    useEffect(() => {
        CircleProgress(progressCircle1, 0.7);
        CircleProgress(progressCircle2, 0.45);
        CircleProgress(progressCircle3, 0.12);
        CircleProgress(progressCircle4, 0.43);
    });

    return (
        <Layout noheaderfooter npPaddingBottom>
            {/* Intro Section */}
            <DEFullHeight>
                <DEHeader dark />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle.svg`}
                    width={880}
                    right="15%"
                    top="72%"
                    x={['-20%', '20%']}
                />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-light.svg`}
                    width={207}
                    right="2%"
                    top="50%"
                    x={['-20%', '20%']}
                />
                <BigTextWrapper>
                    <EbookBigText color={'transparent'}>DIGITAL</EbookBigText>
                    <EbookBigText color={'transparent'} reverse>
                        EXPERIENCE
                    </EbookBigText>
                </BigTextWrapper>
                <SidePaddingWrapper>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <EbookPart>PART 2</EbookPart>
                        <IntroHeading>
                            Building a Digital <br />
                            Member Experience <br />
                            for Credit Unions.
                        </IntroHeading>
                        <IntroPara>
                            A guide to planning your digital transformation for{' '}
                            <br />
                            maximum engagement, loyalty and walletshare
                        </IntroPara>
                    </ScrollAnimation>
                    <IntroImg>
                        <img
                            src={`${baseURL}assets/images/ebook/de-intro.png`}
                            alt="-"
                        />
                    </IntroImg>
                </SidePaddingWrapper>
                <DEFooter />
            </DEFullHeight>

            {/* Content Section */}
            <DEFullHeight>
                <Contents>
                    <SidePaddingWrapper topPadding>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <Container fluid={true}>
                                <Row>
                                    <Col lg="6">
                                        <h1>Contents</h1>
                                    </Col>
                                    <Col lg="6">
                                        <ol>
                                            <li>
                                                <Link
                                                    to={'success-building'}
                                                    spy={true}
                                                    smooth={true}
                                                    duration={1000}>
                                                    Building a successful
                                                    omnichannel strategy
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={'planning-evolution'}
                                                    spy={true}
                                                    smooth={true}
                                                    duration={1000}>
                                                    Planning your evolution to
                                                    digital CX in 2022
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={'conclusion'}
                                                    spy={true}
                                                    smooth={true}
                                                    duration={1000}>
                                                    Conclusion
                                                </Link>
                                            </li>
                                        </ol>
                                    </Col>
                                </Row>
                            </Container>
                        </ScrollAnimation>
                    </SidePaddingWrapper>
                </Contents>
            </DEFullHeight>

            {/* Building a successfull omini channel */}
            <DEFullHeight id="success-building">
                <SuccessfullChannel>
                    <Container fluid>
                        <Row noGutters>
                            <Col lg="6">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <LightBlockSection>
                                        <DEHeading2>
                                            Building a successful omnichannel
                                            strategy.
                                        </DEHeading2>
                                        <DEMediumHeading>
                                            Five core ingredients for
                                            next-level&nbsp;
                                            <br />
                                            member engagement
                                        </DEMediumHeading>
                                        <DEPara>
                                            Taking on a digital transformation
                                            project to improve the <br />
                                            member experience for your credit
                                            union can feel daunting.
                                            <br />
                                            But, ultimately, a successful
                                            omnichannel strategy comes down{' '}
                                            <br />
                                            to these five key factors:
                                        </DEPara>
                                    </LightBlockSection>
                                </ScrollAnimation>
                            </Col>
                            <Col lg="6">
                                <DarkBlockSection>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <CXChecklistHeading>
                                            CX strategy checklist
                                        </CXChecklistHeading>
                                        <Row noGutters>
                                            {strategyItems &&
                                                strategyItems.length > 0 &&
                                                strategyItems.map(
                                                    (si, index) => {
                                                        return (
                                                            <StrategyBox
                                                                lg="6"
                                                                md="6"
                                                                key={index}>
                                                                <img
                                                                    src={
                                                                        si.icon
                                                                    }
                                                                    alt="-"
                                                                />
                                                                <h5>
                                                                    {si.heading}
                                                                </h5>
                                                            </StrategyBox>
                                                        );
                                                    }
                                                )}
                                            <Col lg="6">
                                                <ScrollAnimation
                                                    animateIn="fadeInRight"
                                                    animateOnce={false}>
                                                    <OminiBlockImg>
                                                        <img
                                                            src={`${baseURL}assets/images/ebook/de-success-channel.jpg`}
                                                            alt="-"
                                                        />
                                                    </OminiBlockImg>
                                                </ScrollAnimation>
                                            </Col>
                                        </Row>
                                    </ScrollAnimation>
                                </DarkBlockSection>
                            </Col>
                        </Row>
                    </Container>
                </SuccessfullChannel>
            </DEFullHeight>

            {/* The Right channel section */}
            <DEFullHeight>
                <TheRightChannel>
                    <Container fluid>
                        <Row noGutters>
                            <Col lg="6">
                                <LightBlockSection>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <DEPara>
                                            Tackling each component one by one,
                                            while understanding the big
                                            picture&nbsp;
                                            <br />
                                            and working toward your strategic
                                            goals, can help you dramatically
                                            simplify&nbsp;
                                            <br />
                                            processes and improve the journey
                                            for your members.
                                        </DEPara>
                                        <TheRightChHeading>
                                            <span>01</span> The right channels
                                        </TheRightChHeading>
                                        <RCLeftBottom>
                                            <DEPara>
                                                <p>
                                                    Customer support channels
                                                    are communication
                                                    touchpoints between members
                                                    and your business for
                                                    resolving issues, getting
                                                    help and completing
                                                    transactions.
                                                </p>
                                            </DEPara>

                                            <RCBlockImg1>
                                                <ScrollAnimation
                                                    animateIn="fadeInLeft"
                                                    animateOnce={false}>
                                                    <img
                                                        src={`${baseURL}assets/images/ebook/right-channels-1.jpg`}
                                                        alt="-"
                                                    />
                                                </ScrollAnimation>
                                            </RCBlockImg1>
                                        </RCLeftBottom>
                                    </ScrollAnimation>
                                </LightBlockSection>
                            </Col>
                            <Col lg="6">
                                <DarkBlockSection ref={circleWrape}>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <DEMediumHeading light>
                                            Digital is increasingly the <br />
                                            primary channel for customers’{' '}
                                            <br />
                                            most serious complaints
                                        </DEMediumHeading>
                                        <RightChannel01Block>
                                            <h2>VOICE</h2>
                                            <ProgressWrapper>
                                                <CircleWrap>
                                                    <ProgressYear>
                                                        2017
                                                    </ProgressYear>
                                                    {isVisible && (
                                                        <ProgressCircle
                                                            ref={
                                                                progressCircle1
                                                            }
                                                        />
                                                    )}
                                                </CircleWrap>
                                                <ArrowImg
                                                    src={`${baseURL}assets/images/ebook/de-long-arrow.svg`}
                                                    alt="-"
                                                />
                                                <CircleWrap>
                                                    <ProgressYear
                                                        color={
                                                            colors.ebook
                                                                .digitalExp[
                                                                'green'
                                                            ]
                                                        }>
                                                        2020
                                                    </ProgressYear>
                                                    {isVisible && (
                                                        <ProgressCircle
                                                            ref={
                                                                progressCircle2
                                                            }
                                                        />
                                                    )}
                                                </CircleWrap>
                                            </ProgressWrapper>
                                        </RightChannel01Block>
                                        <RightChannel01Block noBorder>
                                            <h2>DIGITAL</h2>
                                            <ProgressWrapper>
                                                <CircleWrap>
                                                    <ProgressYear>
                                                        2017
                                                    </ProgressYear>
                                                    {isVisible && (
                                                        <ProgressCircle
                                                            ref={
                                                                progressCircle3
                                                            }
                                                        />
                                                    )}
                                                </CircleWrap>
                                                <ArrowImg
                                                    src={`${baseURL}assets/images/ebook/de-long-arrow.svg`}
                                                    alt="-"
                                                />
                                                <CircleWrap>
                                                    <ProgressYear
                                                        color={
                                                            colors.ebook
                                                                .digitalExp[
                                                                'green'
                                                            ]
                                                        }>
                                                        2020
                                                    </ProgressYear>
                                                    {isVisible && (
                                                        <ProgressCircle
                                                            ref={
                                                                progressCircle4
                                                            }
                                                        />
                                                    )}
                                                </CircleWrap>
                                            </ProgressWrapper>
                                        </RightChannel01Block>
                                        <DEPara light>
                                            Source: <a>SharpenCX</a>
                                        </DEPara>
                                    </ScrollAnimation>
                                </DarkBlockSection>
                            </Col>
                        </Row>
                    </Container>
                </TheRightChannel>
            </DEFullHeight>

            {/* The Right channel section slide 02 */}
            <DEFullHeight>
                <FeaturesWrapper>
                    <SidePaddingWrapper topPadding>
                        <Container fluid>
                            <Row>
                                {FeatureList1 &&
                                    FeatureList1.length > 0 &&
                                    FeatureList1.map((f, index) => {
                                        return (
                                            <Col lg="6" key={index}>
                                                <ScrollAnimation
                                                    animateIn="fadeInUp"
                                                    animateOnce={false}>
                                                    {f.title && (
                                                        <DEMediumHeading>
                                                            <img
                                                                src={f.icon}
                                                                alt=""
                                                            />
                                                            {f.title}
                                                        </DEMediumHeading>
                                                    )}
                                                    <DEPara>
                                                        <p>
                                                            {ReactHtmlParser(
                                                                f.para
                                                            )}
                                                        </p>
                                                    </DEPara>
                                                </ScrollAnimation>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </Container>
                    </SidePaddingWrapper>
                </FeaturesWrapper>
                <RCPreHeader dark>
                    <span>01</span>
                    The right channels
                </RCPreHeader>
            </DEFullHeight>

            {/* The Right channel section slide 03 */}
            <DEFullHeight>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-lighter.svg`}
                    width={879}
                    left="-20%"
                    top="70%"
                    zIndex="0"
                    x={['-20%', '20%']}
                />
                <RCSlide02>
                    <SidePaddingWrapper noRightPadding>
                        <Container fluid>
                            <Row noGutter>
                                <Col lg="6">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <RCSlide2Left>
                                            <DEMediumHeading>
                                                <img
                                                    src={`${baseURL}assets/images/ebook/de-messaging-icon.svg`}
                                                    alt="-"
                                                />
                                                Messaging apps
                                            </DEMediumHeading>
                                            <DEPara>
                                                Messaging apps, such as
                                                WhatsApp, Viber, and Telegram,
                                                have recently become a key part
                                                of customer service delivery.
                                                With over 2 billion active users
                                                a month on WhatsApp alone,
                                                messaging provides a readily
                                                available direct line to
                                                customers.<Sup>15</Sup> Many
                                                messaging apps have business
                                                platforms that help you
                                                automate, prioritize, and
                                                respond to requests, ensuring an
                                                efficient workflow.
                                            </DEPara>
                                            <RCSlide02BlockImg>
                                                <ScrollAnimation
                                                    animateIn="fadeInLeft"
                                                    animateOnce={false}>
                                                    <img
                                                        src={`${baseURL}assets/images/ebook/right-channels-1-img1.jpg`}
                                                        alt="-"
                                                    />
                                                </ScrollAnimation>
                                            </RCSlide02BlockImg>
                                        </RCSlide2Left>
                                    </ScrollAnimation>
                                </Col>
                                <Col lg="6">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <RCSlide02BlockImg>
                                            <ScrollAnimation
                                                animateIn="fadeInRight"
                                                animateOnce={false}>
                                                <img
                                                    src={`${baseURL}assets/images/ebook/right-channels-1-img2.jpg`}
                                                    alt="-"
                                                />
                                            </ScrollAnimation>
                                        </RCSlide02BlockImg>
                                        <DEMediumHeading>
                                            <img
                                                src={`${baseURL}assets/images/ebook/de-phone-icon.svg`}
                                                alt="-"
                                            />
                                            Voice
                                        </DEMediumHeading>
                                        <DEPara>
                                            Telephone support can be considered
                                            the original customer support <br />
                                            channel. Despite losing popularity
                                            to digital, it has serious
                                            staying&nbsp;
                                            <br />
                                            power as a go-to method for
                                            resolving issues. Phone support
                                            delivers <br />
                                            perhaps the highest degree of human
                                            connection in the customer <br />
                                            experience. Customers and agents are
                                            free to have a natural <br />
                                            conversation, making it easier to
                                            understand and convey emotions, as{' '}
                                            <br />
                                            compared to chat. Companies need to
                                            make sure their agents are highly{' '}
                                            <br />
                                            trained to deliver support over
                                            voice, including providing the{' '}
                                            <br />
                                            personalized experience customers
                                            receive on digital channels.
                                        </DEPara>
                                    </ScrollAnimation>
                                </Col>
                            </Row>
                        </Container>
                    </SidePaddingWrapper>
                </RCSlide02>
                <RCPreHeader dark>
                    <span>01</span>
                    The right channels
                </RCPreHeader>
            </DEFullHeight>

            {/* The Right agent section */}
            <DEFullHeight bgDark>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-lighter.svg`}
                    width={225}
                    right="5%"
                    top="38%"
                    x={['-20%', '20%']}
                />
                <SidePaddingWrapper topPadding>
                    <Container fluid>
                        <Row noGutter>
                            <Col lg="6">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <TheRightChHeading light>
                                        <span>02</span> The right agents
                                    </TheRightChHeading>
                                    <DEPara light>
                                        Delivering competitive CX means nailing
                                        the digital experience. <br />
                                        But catering to digital customers’
                                        expectations and preferences is <br />
                                        only possible if you have
                                        customer-centric, tech-fluent agents to{' '}
                                        <br />
                                        make it happen.
                                    </DEPara>
                                    <DEMediumHeading light>
                                        <img
                                            src={`${baseURL}assets/images/ebook/de-interactivity-icon.svg`}
                                            alt="-"
                                        />
                                        Digital fluency
                                    </DEMediumHeading>
                                    <DEPara light>
                                        When it comes to digital-first member
                                        experiences, digital natives <br />
                                        are your secret weapon. They have the
                                        prior experience, skills, <br />
                                        and aptitudes to understand the
                                        mentality and needs of your <br />
                                        digital customers. Supplemented by the
                                        right training (which <br />
                                        we’ll get to shortly), they can be your
                                        biggest asset in delivering a <br />
                                        seamless omnichannel experience.
                                    </DEPara>
                                </ScrollAnimation>
                            </Col>
                            <Col lg={{ size: 6, offset: 0 }}>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <DEMediumHeading light>
                                        <img
                                            src={`${baseURL}assets/images/ebook/de-icon-heart.svg`}
                                            alt="-"
                                        />
                                        High-touch service
                                    </DEMediumHeading>
                                    <DEPara light>
                                        On top of digital fluency, credit unions
                                        need customer support agents to be
                                        highly engaged, motivated brand
                                        champions who understand how to deliver
                                        the unique personal touch that sets your
                                        member experience apart from big banks.
                                    </DEPara>
                                    <RCBlockImg>
                                        <ScrollAnimation
                                            animateIn="fadeInRight"
                                            animateOnce={false}>
                                            <img
                                                src={`${baseURL}assets/images/ebook/de-section-2-img1.jpg`}
                                                alt="-"
                                            />
                                        </ScrollAnimation>
                                    </RCBlockImg>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                    </Container>
                </SidePaddingWrapper>
            </DEFullHeight>

            {/* The Right content section */}
            <DEFullHeight>
                <RightContent>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <Container fluid>
                            <Row noGutters>
                                <Col lg="6">
                                    <RContentImg>
                                        <ScrollAnimation
                                            animateIn="fadeInLeft"
                                            animateOnce={false}>
                                            <img
                                                src={`${baseURL}assets/images/ebook/de-right-content-img.jpg`}
                                                alt="-"
                                            />
                                        </ScrollAnimation>
                                    </RContentImg>
                                </Col>
                                <Col lg={{ size: 5, offset: 1 }}>
                                    <TheRightChHeading>
                                        <span>03</span> The right content
                                    </TheRightChHeading>
                                    <DEPara>
                                        <p>
                                            It’s a given that consumers want
                                            digital banking that’s easy to use,
                                            accessible, and available on any
                                            device, including mobile. But the
                                            evolution toward digital banking
                                            also represents a shift in customer
                                            mentality. Now, more than ever,
                                            members want online, self-serve
                                            content (on top of support and
                                            transactions) designed to help them
                                            increase their financial literacy
                                            and make smart decisions about their
                                            money.
                                        </p>
                                        <p>
                                            Online resources and content, such
                                            as debt reduction tools, investment
                                            advice, and knowledge bases, equip
                                            consumers with valuable guidance
                                            they once had to seek out from a
                                            financial advisor. Now, some big
                                            banks and challenger banks are
                                            taking it a step further, using
                                            AI-based software to give
                                            personalized saving, spending, and
                                            goal-setting advice and
                                            notifications.
                                        </p>
                                        <p>
                                            Credit unions’ long-term success
                                            will depend, at least in part, on
                                            their ability to provide relevant,
                                            consultative digital content. Beyond
                                            seeking general advice and
                                            assistance, members will look for
                                            highly personalized content that
                                            steers them toward the right
                                            products for their financial goals,
                                            for the specific point they’re at in
                                            the customer journey, the same way a
                                            human advisor would.
                                        </p>
                                    </DEPara>
                                </Col>
                            </Row>
                        </Container>
                    </ScrollAnimation>
                </RightContent>
            </DEFullHeight>

            {/* The Right Fit section */}
            <DEFullHeight>
                <RightFit>
                    <SidePaddingWrapper topPadding>
                        <Container fluid>
                            <Row noGutters>
                                <Col lg="5">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <TheRightChHeading>
                                            <span>04</span> The right fit for{' '}
                                            <br />
                                            each member
                                        </TheRightChHeading>
                                        <DEPara>
                                            On top of providing tailored
                                            content, credit unions need to
                                            extend the personal touch of
                                            face-to-face interactions to digital
                                            communication channels. And, while
                                            it takes some strategic planning and
                                            the right technology, a
                                            digital-first approach actually
                                            gives credit unions more ways to
                                            double down on the attentive,
                                            customer-centric experience that
                                            differentiates them from big banks.
                                        </DEPara>
                                    </ScrollAnimation>
                                    <ScrollAnimation
                                        animateIn="fadeInLeft"
                                        animateOnce={false}>
                                        <img
                                            src={`${baseURL}assets/images/ebook/de-right-fit.jpg`}
                                            alt="-"
                                        />
                                    </ScrollAnimation>
                                </Col>
                                <Col lg={{ size: 6, offset: 1 }}>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <DEPara>
                                            Digital channels give you direct and
                                            personalized ways to educate,
                                            notify, engage, and collect feedback
                                            from members, which can dramatically
                                            improve communication and member
                                            experience. A more personalized
                                            digital-first member experience
                                            involves these key elements:
                                        </DEPara>
                                        <ul>
                                            {FeatureList2 &&
                                                FeatureList2.length > 0 &&
                                                FeatureList2.map(
                                                    (fi, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <img
                                                                    src={`${baseURL}assets/images/ebook/icon-awesome-dot-circle.svg`}
                                                                    alt="-"
                                                                />
                                                                <DEPara>
                                                                    {fi}
                                                                </DEPara>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                        </ul>
                                    </ScrollAnimation>
                                </Col>
                            </Row>
                        </Container>
                    </SidePaddingWrapper>
                </RightFit>
            </DEFullHeight>

            {/* The Right Technologies Section */}
            <DEFullHeight bgDark>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-lighter.svg`}
                    width={225}
                    right="45%"
                    top="35%"
                    x={['-20%', '20%']}
                />
                <RightTechnologies>
                    <SidePaddingWrapper topPadding>
                        <Container fluid>
                            <Row>
                                <Col lg="6">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <TheRightChHeading light>
                                            <span>05</span> The right
                                            technologies
                                        </TheRightChHeading>
                                        <DEPara light>
                                            The best member experiences are
                                            delivered by proficient agents,
                                            backed by proven technologies
                                            designed for CX performance. Here
                                            are some non-negotiable tools for
                                            equipping your agents to succeed.
                                        </DEPara>
                                    </ScrollAnimation>
                                    <ScrollAnimation
                                        animateIn="fadeInLeft"
                                        animateOnce={false}>
                                        <FloatingBlockImg>
                                            <img
                                                src={`${baseURL}assets/images/ebook/de-right-tech-img1.jpg`}
                                                alt="-"
                                            />
                                        </FloatingBlockImg>
                                    </ScrollAnimation>
                                </Col>
                                <Col lg={{ size: 5, offset: 1 }}>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <DEPara light>
                                            <p>
                                                It’s a given that consumers want
                                                digital banking that’s easy to
                                                use, accessible, and available
                                                on any device, including mobile.
                                                But the evolution toward digital
                                                banking also represents a shift
                                                in customer mentality. Now, more
                                                than ever, members want online,
                                                self-serve content (on top of
                                                support and transactions)
                                                designed to help them increase
                                                their financial literacy and
                                                make smart decisions about their
                                                money.
                                            </p>
                                            <p>
                                                Online resources and content,
                                                such as debt reduction tools,
                                                investment advice, and knowledge
                                                bases, equip consumers with
                                                valuable guidance they once had
                                                to seek out from a financial
                                                advisor. Now, some big banks and
                                                challenger banks are taking it a
                                                step further, using AI-based
                                                software to give personalized
                                                saving, spending, and
                                                goal-setting advice and
                                                notifications.
                                            </p>
                                            <p>
                                                Credit unions’ long-term success
                                                will depend, at least in part,
                                                on their ability to provide
                                                relevant, consultative digital
                                                content. Beyond seeking general
                                                advice and assistance, members
                                                will look for highly
                                                personalized content that steers
                                                them toward the right products
                                                for their financial goals, for
                                                the specific point they’re at in
                                                the customer journey, the same
                                                way a human advisor would.
                                            </p>
                                        </DEPara>
                                    </ScrollAnimation>
                                </Col>
                            </Row>
                        </Container>
                    </SidePaddingWrapper>
                </RightTechnologies>
            </DEFullHeight>

            {/* The Right Technologies Section Slide 02 */}
            <DEFullHeight bgDark>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-lighter.svg`}
                    width={556}
                    right="58%"
                    top="-20%"
                    x={['-20%', '20%']}
                />
                <RightTech02>
                    <SidePaddingWrapper>
                        <Container fluid>
                            <Row>
                                <Col lg="6">
                                    <TechFlexEnd>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={false}>
                                            <TechBlockHeading>
                                                TRAINING
                                            </TechBlockHeading>
                                            <TechBlock>
                                                <TechBlockHead>
                                                    TS
                                                </TechBlockHead>
                                                <TechBlockPara>
                                                    <span>
                                                        Training simulation
                                                    </span>
                                                    &nbsp;gives agents a
                                                    progressive, hands-on
                                                    approach to solving customer
                                                    issues. Through lifelike
                                                    scenarios of customer-agent
                                                    conversations, learners
                                                    master top call drivers. And
                                                    you reduce time to
                                                    proficiency while promoting
                                                    continuous learning for low
                                                    performers.
                                                </TechBlockPara>
                                            </TechBlock>
                                            <TechBlock>
                                                <TechBlockHead>
                                                    GM
                                                </TechBlockHead>
                                                <TechBlockPara>
                                                    <span>Gamification</span> of
                                                    the training experience
                                                    incentivizes agents to build
                                                    positive behaviors using
                                                    rewards, such as gold stars,
                                                    miles, points, and badges.
                                                    By driving higher
                                                    engagement, this approach
                                                    helps learning stick and
                                                    creates better outcomes.
                                                </TechBlockPara>
                                            </TechBlock>
                                        </ScrollAnimation>
                                    </TechFlexEnd>
                                </Col>
                                <Col lg="6">
                                    <TechFlexEnd>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={false}>
                                            <TechBlockHeading>
                                                PERFORMANCE
                                            </TechBlockHeading>
                                            <TechBlock>
                                                <TechBlockHead>
                                                    FM
                                                </TechBlockHead>
                                                <TechBlockPara>
                                                    <span>
                                                        Fraud monitoring
                                                    </span>
                                                    &nbsp;helps protect member
                                                    data. Employees are a
                                                    primary source of falsified
                                                    information (whether
                                                    intentionally or not).
                                                    Without proper tracking
                                                    tools and procedures in
                                                    place, fraudulent behavior
                                                    can negatively impact
                                                    business. A monitoring
                                                    system helps you detect
                                                    tampering and safeguard
                                                    valuable member information.
                                                </TechBlockPara>
                                            </TechBlock>
                                            <TechBlock>
                                                <TechBlockHead>
                                                    RC
                                                </TechBlockHead>
                                                <TechBlockPara>
                                                    <span>Remote coaching</span>
                                                    &nbsp;platforms let
                                                    supervisors and operational
                                                    managers monitor multiple
                                                    agents’ voice, chat, and
                                                    screen interactions at
                                                    once—from anywhere—and offer
                                                    support when it’s needed to
                                                    improve response times and
                                                    member satisfaction.
                                                </TechBlockPara>
                                            </TechBlock>
                                            <TechBlock>
                                                <TechBlockHead>
                                                    EM
                                                </TechBlockHead>
                                                <TechBlockPara>
                                                    <span>
                                                        Escalation management
                                                        systems
                                                    </span>
                                                    &nbsp;enhance productivity
                                                    by connecting agents with
                                                    real-time assistance, so
                                                    they can delight your
                                                    members every time. Whether
                                                    they’re in training or
                                                    stumped by a complaint,
                                                    agents can request
                                                    assistance from supervisory
                                                    agents who communicate with
                                                    them remotely to resolve the
                                                    issue.
                                                </TechBlockPara>
                                            </TechBlock>
                                        </ScrollAnimation>
                                    </TechFlexEnd>
                                </Col>
                            </Row>
                        </Container>
                    </SidePaddingWrapper>
                </RightTech02>
                <RCPreHeader>
                    <span>05</span>
                    The right technologies
                </RCPreHeader>
            </DEFullHeight>

            {/* The Right Technologies Section Slide 03 */}
            <DEFullHeight bgDark>
                <RightTechSlide3>
                    <SidePaddingWrapper noRightPadding topPadding>
                        <Container fluid>
                            <Row>
                                <Col lg="6">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <TechBlockHeading>
                                            INSIGHTS
                                        </TechBlockHeading>
                                        <TechBlock>
                                            <TechBlockHead>SR</TechBlockHead>
                                            <TechBlockPara>
                                                <span>
                                                    Social reputation management
                                                </span>
                                                &nbsp;uses a social engagement
                                                platform to centralize, answer,
                                                and influence online reviews
                                                from multiple sites. With full
                                                awareness of member feedback,
                                                you can mobilize your CX teams
                                                to quickly identify and resolve
                                                issues before they escalate.
                                            </TechBlockPara>
                                        </TechBlock>
                                    </ScrollAnimation>
                                    <ScrollAnimation
                                        animateIn="fadeInLeft"
                                        animateOnce={false}>
                                        <img
                                            src={`${baseURL}assets/images/ebook/de-right-tech-img2.svg`}
                                            alt="-"
                                        />
                                    </ScrollAnimation>
                                </Col>
                                <Col lg="6">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <Slide3RedBox>
                                            <h2>
                                                Getting buy-in on your strategy
                                            </h2>
                                            <p>
                                                Credit unions that live and
                                                breathe member experience have
                                                investment from every level of
                                                the organization. When planning
                                                your omnichannel strategy, start
                                                by consulting the C-suite, then
                                                key stakeholders in the
                                                organization, followed by member
                                                experience advocates who will
                                                help you champion the importance
                                                of a member-focused omnichannel
                                                journey. <Sup light>16</Sup>
                                            </p>
                                        </Slide3RedBox>
                                    </ScrollAnimation>
                                </Col>
                            </Row>
                        </Container>
                    </SidePaddingWrapper>
                </RightTechSlide3>
                <RCPreHeader>
                    <span>05</span>
                    The right technologies
                </RCPreHeader>
            </DEFullHeight>

            {/* Planning you evolution section */}
            <DEFullHeight id="planning-evolution">
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-lighter.svg`}
                    width={493}
                    left="-10%"
                    top="80%"
                    x={['-20%', '20%']}
                />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-lighter.svg`}
                    width={493}
                    right="-15%"
                    top="-20%"
                    x={['-20%', '20%']}
                />
                <PlanEvolution>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <SidePaddingWrapper noRightPadding>
                            <Container fluid>
                                <Row noGutters>
                                    <Col lg="7">
                                        <LightBlockSection>
                                            <DEHeading>
                                                Planning <br />
                                                your evolution to <br />
                                                digital in 2022
                                            </DEHeading>
                                            <DEMediumHeading>
                                                How a BPO partner can <br />
                                                accelerate your member <br />
                                                experience
                                            </DEMediumHeading>
                                            <PlanEvalBlockImg>
                                                <ScrollAnimation
                                                    animateIn="fadeInLeft"
                                                    animateOnce={false}>
                                                    <img
                                                        src={`${baseURL}assets/images/ebook/de-planeval-blockimg.jpg`}
                                                        alt="-"
                                                    />
                                                </ScrollAnimation>
                                            </PlanEvalBlockImg>
                                        </LightBlockSection>
                                    </Col>
                                    <Col lg="5">
                                        <DarkBlockSection>
                                            <DEMediumHeading light>
                                                The power of an expert <br />
                                                customer support team
                                            </DEMediumHeading>
                                            <DEPara light>
                                                <p>
                                                    Delivering a satisfying
                                                    omnichannel member
                                                    experience isn’t possible
                                                    without a confident, capable
                                                    frontline. Whether through
                                                    voice, chat, email, or
                                                    social, your agents can make
                                                    or break your business. The
                                                    small size of many credit
                                                    union teams makes it
                                                    challenging to deliver the
                                                    seamless, digitally
                                                    supported experience that
                                                    members expect and most
                                                    competitors are now
                                                    delivering. That’s where an
                                                    outsourced customer support
                                                    team can help.
                                                </p>
                                                <p>
                                                    Historically, credit unions
                                                    have been hesitant to
                                                    outsource customer support
                                                    functions for fear of
                                                    diminishing the personal,
                                                    local service that sets them
                                                    apart from big banks.
                                                    There’s a misconception
                                                    that, because credit unions
                                                    differentiate based on their
                                                    ability to offer high-touch
                                                    interactions in the
                                                    community, outsourcing isn’t
                                                    an option. Fortunately, it
                                                    is possible to deliver
                                                    member-focused,
                                                    community-based experiences,
                                                    as long as you choose the
                                                    right partner.
                                                </p>
                                            </DEPara>
                                        </DarkBlockSection>
                                    </Col>
                                </Row>
                            </Container>
                        </SidePaddingWrapper>
                    </ScrollAnimation>
                </PlanEvolution>
            </DEFullHeight>

            {/* Finding The Right CX Partner */}
            <DEFullHeight>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-lighter.svg`}
                    width={493}
                    right="15%"
                    top="-37%"
                    x={['-20%', '20%']}
                />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-lighter.svg`}
                    width={160}
                    right="60%"
                    top="75%"
                    x={['-20%', '20%']}
                />
                <TheRightCXPartner>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <SidePaddingWrapper noRightPadding>
                            <Container fluid>
                                <Row noGutters>
                                    <Col lg="4">
                                        <LightBlockSection>
                                            <DEHeading2>
                                                Finding the right CX partner
                                            </DEHeading2>
                                            <DEPara>
                                                <p>
                                                    Selecting the best partner
                                                    for your brand can be
                                                    time-consuming, but it’s
                                                    worth the investment upfront
                                                    to avoid issues down the
                                                    road. Getting it wrong can
                                                    result in financial losses,
                                                    negative experiences for
                                                    your customers, and damage
                                                    to your brand reputation.
                                                </p>
                                                <p>
                                                    Here’s how the right
                                                    outsourcer can advance your
                                                    member experience
                                                    strategy—and what to look
                                                    for when choosing a
                                                    strategic partner.
                                                </p>
                                            </DEPara>
                                        </LightBlockSection>
                                    </Col>
                                    <Col lg="8">
                                        <DarkBlockSection>
                                            <DEMediumHeading light>
                                                <img
                                                    src={`${baseURL}assets/images/ebook/de-security-icon.svg`}
                                                    alt="-"
                                                />
                                                Institutional-grade security
                                            </DEMediumHeading>
                                            <DEPara light>
                                                <p>
                                                    It goes without saying that
                                                    credit unions need to uphold
                                                    rigorous security standards.
                                                    One data leak is all it
                                                    takes to irreversibly damage
                                                    your brand. Ensure your
                                                    outsourcer provides highly
                                                    sophisticated security
                                                    frameworks in two layers—the
                                                    technology layer and the
                                                    people layer—to protect
                                                    company data and member PII.
                                                    The technology
                                                    infrastructure should be
                                                    secured by the latest
                                                    encryption and data security
                                                    protocols. And the
                                                    outsourcer should have
                                                    processes and protocols in
                                                    place for fraud and
                                                    compliance training and
                                                    monitoring.
                                                </p>
                                            </DEPara>
                                            <DEMediumHeading light>
                                                <img
                                                    src={`${baseURL}assets/images/ebook/de-calendar-icon.svg`}
                                                    alt="-"
                                                />
                                                Financial services expertise
                                            </DEMediumHeading>
                                            <DEPara light>
                                                <p>
                                                    Hiring the right outsourcer
                                                    can protect and accelerate
                                                    your brand, but only if they
                                                    have the right expertise.
                                                    Check their track record to
                                                    make sure they’ve had
                                                    previous success in the
                                                    financial services industry.
                                                    Pay attention to timelines,
                                                    locations, and references
                                                    for past successes. Speaking
                                                    with a current hyper-growth
                                                    client of your potential
                                                    outsourcing partner is an
                                                    essential way to gauge their
                                                    performance. Finally,
                                                    analyze the terminology they
                                                    use; if they know the credit
                                                    union space, they’ll speak
                                                    your language.
                                                </p>
                                            </DEPara>
                                        </DarkBlockSection>
                                    </Col>
                                </Row>
                            </Container>
                        </SidePaddingWrapper>
                    </ScrollAnimation>
                </TheRightCXPartner>
            </DEFullHeight>

            {/* Right CX Partner Features 1 Section */}
            <DEFullHeight bgDark>
                <FeaturesWrapper>
                    <SidePaddingWrapper featuresPart2>
                        <Container fluid>
                            <Row>
                                {FeatureList3 &&
                                    FeatureList3.length > 0 &&
                                    FeatureList3.map((f, index) => {
                                        return (
                                            <Col lg="6" key={index}>
                                                <ScrollAnimation
                                                    animateIn="fadeInUp"
                                                    animateOnce={false}>
                                                    {f.title && (
                                                        <DEMediumHeading light>
                                                            <img
                                                                src={f.icon}
                                                                alt=""
                                                            />
                                                            {f.title}
                                                        </DEMediumHeading>
                                                    )}
                                                    <DEPara light>
                                                        <p>
                                                            {ReactHtmlParser(
                                                                f.para
                                                            )}
                                                        </p>
                                                    </DEPara>
                                                </ScrollAnimation>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </Container>
                    </SidePaddingWrapper>
                </FeaturesWrapper>
            </DEFullHeight>

            {/* Right CX Partner Features 2 Section */}
            <DEFullHeight bgDark>
                <FeaturesWrapper>
                    <SidePaddingWrapper featuresPart2>
                        <Container fluid>
                            <Row>
                                {FeatureList4 &&
                                    FeatureList4.length > 0 &&
                                    FeatureList4.map((f, index) => {
                                        return (
                                            <Col lg="6" key={index}>
                                                <ScrollAnimation
                                                    animateIn="fadeInUp"
                                                    animateOnce={false}>
                                                    {f.title && (
                                                        <DEMediumHeading light>
                                                            <img
                                                                src={f.icon}
                                                                alt=""
                                                            />
                                                            {f.title}
                                                        </DEMediumHeading>
                                                    )}
                                                    <DEPara light>
                                                        <p>
                                                            {ReactHtmlParser(
                                                                f.para
                                                            )}
                                                        </p>
                                                    </DEPara>
                                                </ScrollAnimation>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </Container>
                    </SidePaddingWrapper>
                </FeaturesWrapper>
            </DEFullHeight>

            {/* Conclusion Section */}
            <DEFullHeight bgDark id="conclusion">
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-lighter.svg`}
                    width={493}
                    right="-20%"
                    top="55%"
                    x={['-20%', '20%']}
                />
                <Conclusion>
                    <Container fluid>
                        <Row noGutters>
                            <Col lg="7">
                                <ScrollAnimation
                                    animateIn="fadeInLeft"
                                    animateOnce={false}>
                                    <ConclusionBlockImg>
                                        <img
                                            src={`${baseURL}assets/images/ebook/de-conclusion-img.jpg`}
                                            alt="-"
                                        />
                                    </ConclusionBlockImg>
                                    <DEHeading light>Conclusion</DEHeading>
                                </ScrollAnimation>
                            </Col>
                            <Col lg="5">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <ConclusionRight>
                                        <DEPara light>
                                            <p>
                                                With the rise of digital
                                                banking, credit unions are
                                                facing stiff competition for
                                                member walletshare. Their
                                                long-term growth depends on how
                                                well—and how quickly—they can
                                                evolve to meet the shifting
                                                needs of consumers.
                                            </p>
                                            <p>
                                                To stay competitive, credit
                                                unions must develop and execute
                                                an omnichannel member experience
                                                strategy that incorporates the
                                                right channels, the right
                                                agents, the right content, the
                                                right fit for each member, and
                                                the right technologies. Working
                                                with a trusted CX partner can
                                                drive game-changing results and
                                                take your member experience
                                                above and beyond the level that
                                                today’s digital-first customers
                                                expect.
                                            </p>
                                        </DEPara>
                                        <Button
                                            ifButton={false}
                                            href={`${baseURLPage}contact-us`}
                                            round={true}>
                                            See How ibex Can Help
                                        </Button>
                                    </ConclusionRight>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                    </Container>
                    <BigTextWrapper>
                        <EbookBigText color={'transparent'}>
                            CON <br /> CLU <br /> SION
                        </EbookBigText>
                    </BigTextWrapper>
                </Conclusion>
            </DEFullHeight>
        </Layout>
    );
};
const ProgressCircle = styled.div`
    border: 1px solid ${colors.white['100']};
    border-radius: 50%;
    ${Media('xlscreens')} {
        height: 7vw;
        width: 7vw;
    }
    ${Media('tablet')} {
        height: 150px;
        width: 150px;
    }
`;

const ProgressWrapper = styled.div`
    display: flex;
    align-items: center;
    ${Media('xlscreens')} {
        margin-left: 3vw;
    }
    ${Media('mobile')} {
        flex-direction: column;
    }
`;

const ArrowImg = styled.img`
    height: 10px !important;
    ${Media('xlscreens')} {
        margin: 0 3vw !important;
        position: relative;
        top: 1vw;
    }
    ${Media('tablet')} {
        margin: 0px 30px;
        position: relative;
        top: 30px;
    }
    ${Media('mobile')} {
        margin: 50px 0;
        transform: rotate(90deg);
        margin-bottom: 100px;
    }
`;

const CircleWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${Media('tablet')} {
        .progressbar-text {
            font-size: 20px !important;
        }
    }
`;

const ProgressYear = styled.h3`
    font-size: 18px;
    color: ${(props) => (props.color ? props.color : colors.white['100'])};
    ${Media('xlscreens')} {
        font-size: 1.66vw;
    }
`;

const SidePaddingWrapper = styled.div`
    padding: ${(props) => (props.topPadding ? '50px' : '0px 50px')};
    box-sizing: border-box;
    position: relative;
    ${Media('xlscreens')} {
        height: 100%;
        padding: ${(props) =>
            props.topPadding ? '7.29vw 5.98vw' : '0 5.98vw'};
        ${(props) => {
            if (props.noRightPadding) {
                return `padding-right:0`;
            }
            if (props.featuresPart2) {
                return `padding-top:3vw`;
            }
        }}
    }
    ${Media('mobile')} {
        padding: ${(props) => (props.topPadding ? '15px' : '0px 15px')};
    }
`;
const EbookPart = styled.h4`
    font-family: ${gilroyBold};
    font-size: 22px;
    letter-spacing: 6px;
    margin-top: 0;
    ${Media('xlscreens')} {
        font-size: 2.08vw;
        letter-spacing: 0.31vw;
    }
    ${Media('mobile')} {
        font-size: 18px;
    }
`;

const IntroHeading = styled.h1`
    font-size: 50px;
    letter-spacing: 1.13;
    font-family: ${gilroyBold};
    letter-spacing: -3px;
    ${Media('xlscreens')} {
        font-size: 5.3vw;
        letter-spacing: -0.25vw;
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 36px;
        letter-spacing: -2px;
    }
`;

const IntroPara = styled.p`
    font-size: 22px;
    line-height: 1.72;
    margin-top: 40px;
    ${Media('xlscreens')} {
        font-size: 1.66vw;
        margin-top: 2.08vw;
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 18px;
    }
`;

const IntroImg = styled.div`
    img {
        width: auto;
        height: 948px;
    }
    ${Media('xlscreens')} {
        position: absolute;
        right: -3vw;
        top: -4.5vw;
        img {
            height: 47vw;
        }
    }
    ${Media('tablet')} {
        img {
            height: 300px;
            float: right;
        }
    }
    ${Media('mobile')} {
        img {
            margin-left: 20px;
            float: unset;
        }
    }
`;

const Contents = styled.div`
    background-image: url(${baseURL}assets/images/ebook/de-content-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    h1 {
        font-size: 50px;
        font-family: ${gilroyBold};
        letter-spacing: -3px;
        margin-top: 0;
    }
    ol {
        li {
            font-size: 22px;
            list-style: decimal;
            font-family: ${gilroyMedium};
            padding: 15px;
            &::marker {
                color: ${colors.pink['100']};
                font-family: ${gilroyBold};
                margin-right: 20px;
            }
        }
    }
    ${Media('xlscreens')} {
        h1 {
            font-size: 6.25vw;
            letter-spacing: -0.25vw;
        }
        ol {
            li {
                font-size: 1.61vw;
                padding: 1.04vw;
                &::marker {
                    margin-right: 1.56vw;
                }
            }
        }
    }
    ${Media('tablet')} {
        padding-top: 50px;
        padding-bottom: 300px;
        ${SidePaddingWrapper} {
            padding-top: 0;
        }
    }
    ${Media('mobile')} {
        h1 {
            font-size: 36px;
            letter-spacing: -2px;
            margin-bottom: 15px;
        }
        ol {
            padding-left: 15px;
            li {
                font-size: 14px;
            }
        }
    }
`;

const BigTextWrapper = styled.div`
    position: absolute;
    bottom: 50px;
    .parallax-inner {
        -webkit-text-stroke: 0.4px ${colors.pink['50']};
    }
    ${Media('xlscreens')} {
        bottom: 2.6vw;
        .parallax-inner {
            font-size: 25vw !important;
            line-height: 0.8;
        }
    }
`;

const SuccessfullChannel = styled.div`
    padding-left: 115px;
    ${Media('xlscreens')} {
        padding-left: 5.98vw;
    }
    ${Media('tablet')} {
        padding: 30px 0;
    }
`;

const LightBlockSection = styled.div`
    background-color: ${colors.technologyDropdown['box']};
    width: 100%;
    padding-right: ${(props) => (props.noPaddingRight ? '0px' : '50px')};
    display: flex;
    justify-content: center;
    flex-direction: column;
    ${Media('xlscreens')} {
        height: calc(56.25vw - 5.8vw);
        padding-right: ${(props) => (props.noPaddingRight ? '0px' : '5.98vw')};
    }
    ${Media('tablet')} {
        padding: 50px;
        padding-top: 0;
    }
    ${Media('mobile')} {
        padding: 15px;
    }
`;
const DarkBlockSection = styled.div`
    background-color: ${colors.socialRep['brandsBG']};
    width: 100%;
    h3 {
        margin-top: 0;
    }
    ${Media('xlscreens')} {
        height: calc(56.25vw - 5.8vw);
    }
`;

const CXChecklistHeading = styled.h3`
    text-align: center;
    color: ${colors.white['100']};
    padding: 45px 0;
    margin: 0;
    font-size: 32px;
    font-family: ${gilroyMedium};
    ${Media('xlscreens')} {
        padding: 2.34vw 0;
        font-size: 2.34vw;
    }
    ${Media('mobile')} {
        font-size: 26px;
    }
`;

const StrategyBox = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    border: 1px solid ${colors.technologyDropdown['16']};
    h5 {
        color: ${colors.white['100']};
        font-size: 18px;
        font-family: ${gilroyMedium};
        margin-bottom: 0;
    }
    img {
        height: 60px;
        width: 60px;
    }
    ${Media('xlscreens')} {
        padding: 2.08vw 0;
        h5 {
            font-size: 1.25vw;
        }
        img {
            height: 5.2vw;
            width: 5.2vw;
        }
    }
`;

const TheRightChannel = styled.div`
    padding-left: 115px;
    ${LightBlockSection} {
        padding-right: 0;
        padding-top: 110px;
    }
    ${DarkBlockSection} {
        padding: 80px 100px;
        a {
            color: ${colors.ebook.digitalExp['green']};
        }
    }
    ${Media('xlscreens')} {
        padding-left: 5.98vw;
        ${LightBlockSection} {
            padding-top: 5.72vw;
        }
        ${DarkBlockSection} {
            padding: 4.16vw 5.2vw;
            a {
                color: ${colors.ebook.digitalExp['green']};
            }
        }
    }
    ${Media('tablet')} {
        padding: 0px;
        ${LightBlockSection} {
            padding: 50px;
            padding-top: 0;
        }
        ${DarkBlockSection} {
            padding: 50px;
            a {
                color: ${colors.ebook.digitalExp['green']};
            }
        }
    }
    ${Media('mobile')} {
        ${LightBlockSection} {
            padding: 15px;
        }
        ${DarkBlockSection} {
            padding: 15px;
        }
    }
`;

const TheRightChHeading = styled.h1`
    font-size: 45px;
    font-family: ${gilroyBold};
    margin: 30px 0;
    color: ${(props) =>
        props.light ? colors.white['100'] : colors.black['100']};
    span {
        font-family: ${gilroyLight};
        color: ${colors.pink['100']};
        margin-right: 20px;
    }
    ${Media('xlscreens')} {
        font-size: 3.125vw;
        margin: 1.56vw 0;
        span {
            margin-right: 1.04vw;
        }
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 32px;
    }
`;

const RCBlockImg1 = styled.div`
    img {
        width: 100%;
    }
`;
const RCLeftBottom = styled.div`
    display: flex;
    position: relative;
    top: 20px;
    p {
        margin-right: 20px;
        margin-top: 0px;
    }
    img {
        height: 540px;
        width: 513px;
    }
    ${Media('xlscreens')} {
        top: 2.66vw;
        p {
            margin-right: 1.04vw;
        }
        img {
            height: 28.12vw;
            width: 26.71vw;
        }
    }
    ${Media('tablet')} {
        flex-direction: column;
        p {
            margin-right: 0;
        }
        img {
            width: 100%;
            margin-bottom: 30px;
            height: auto;
        }
    }
`;

const RightChannel01Block = styled.div`
    display: flex;
    align-items: center;
    padding: 50px 0;
    border-bottom: ${(props) =>
        props.noBorder
            ? 'none'
            : `1px solid ${colors.technologyDropdown['box']}`};
    h2 {
        font-family: ${gilroyBold};
        font-size: 24px;
        color: ${colors.ebook.digitalExp['green']};
    }
    &:last-of-type {
        border: none;
    }
    ${Media('xlscreens')} {
        padding: 2.66vw 0;
        h2 {
            font-size: 1.56vw;
        }
        img {
            height: 9.89vw;
            margin-left: 3.64vw;
        }
    }
    ${Media('tablet')} {
        justify-content: center;
        flex-direction: column;
    }
    ${Media('mobile')} {
        padding: 35px 0;
        h2 {
            font-size: 16px;
        }
    }
`;

const FeaturesWrapper = styled.div`
    box-sizing: border-box;
    a {
        font-size: 8px;
        position: relative;
        color: ${(props) =>
            props.light ? colors.white['100'] : colors.black['100']};
        top: -20px;
        left: 1px;
        &:hover {
            color: ${colors.pink['100']};
        }
        ${Media('xlscreens')} {
            font-size: 0.7vw;
            top: -0.5vw;
        }
    }
    ${Media('xlscreens')} {
        p {
            margin-right: 3.125vw;
        }
    }
    ${Media('tablet')} {
        padding-top: 70px;
    }
`;

const RCPreHeader = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px 20px;
    font-size: 22px;
    color: ${colors.white['100']};
    font-family: ${gilroyBold};
    background-color: ${(props) =>
        props.dark ? colors.socialRep['brandsBG'] : colors.pink['27']};
    span {
        font-family: ${gilroyLight};
        color: ${colors.pink['100']};
        margin-right: 20px;
    }
    ${Media('xlscreens')} {
        padding: 1.56vw 2.08vw;
        font-size: 1.71vw;
        span {
            margin-right: 1.04vw;
        }
    }
    ${Media('tablet')} {
        margin-top: 50px;
    }
    ${Media('mobile')} {
        font-size: 16px;
        padding: 10px;
        margin-top: 25px;
        span {
            margin-right: 10px;
        }
    }
`;

const RCSlide02 = styled.div`
    ${Media('tablet')} {
        padding-top: 70px;
    }
`;

const RCSlide02BlockImg = styled.div`
    img {
        width: 100%;
        margin-bottom: 50px;
    }
    ${Media('xlscreens')} {
        img {
            margin-bottom: 2.6vw;
        }
    }
`;

const Sup = styled.a`
    font-size: 8px;
    position: relative;
    color: ${(props) =>
        props.light ? colors.white['100'] : colors.black['100']};
    top: -20px;
    left: 1px;
    &:hover {
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        font-size: 0.7vw;
        top: -0.5vw;
    }
`;

const RCSlide2Left = styled.div`
    padding-top: 140px;
    padding-right: 100px;
    ${RCSlide02BlockImg} {
        img {
            height: 388px;
            width: auto;
            float: right;
        }
    }
    ${Media('xlscreens')} {
        padding-top: 7.29vw;
        padding-right: 5.2vw;
        ${RCSlide02BlockImg} {
            img {
                height: 20.2vw;
            }
        }
    }
    ${Media('tablet')} {
        padding: 0px;
        ${RCSlide02BlockImg} {
            img {
                width: 100%;
                height: auto;
            }
        }
    }
`;

const RCBlockImg = styled.div`
    position: relative;
    top: 20px;
    img {
        width: 100%;
    }
    ${Media('xlscreens')} {
        top: 1.04vw;
    }
`;

const RightContent = styled.div`
    padding-right: 100px;
    padding-top: 50px;
    ${Media('xlscreens')} {
        padding-right: 5.2vw;
        padding-top: 2.66vw;
    }
    ${Media('tablet')} {
        padding: 0 50px;
    }
    ${Media('mobile')} {
        padding: 0 15px;
    }
`;

const RContentImg = styled.div`
    position: relative;
    img {
        width: 100%;
    }
    ${Media('xlscreens')} {
        top: 5.7vw;
    }
`;

const RightFit = styled.div`
    img {
        width: 100%;
    }
    ul {
        padding-left: 0px;
        li {
            display: flex;
            border-bottom: 1px solid ${colors.black['20']};
            padding: 30px 0;
            div {
                margin: 0;
                font-family: ${gilroyMedium};
            }
            img {
                height: 25px;
                width: 25px;
                margin-right: 20px;
            }
            &:last-of-type {
                border: none;
            }
        }
    }
    ${TheRightChHeading} {
        display: flex;
        margin-top: 0;
        span {
            display: block;
        }
    }
    ${Media('xlscreens')} {
        ul {
            li {
                padding: 1.56vw 0;
            }
            img {
                height: 1.82vw;
                width: 1.82vw;
                margin-right: 1.04vw;
            }
        }
    }
    ${Media('tablet')} {
        img {
            margin: 50px 0;
        }
        ul {
            li {
                align-items: center;
                img {
                    margin: 0;
                    margin-right: 15px;
                }
            }
        }
    }
`;

const RightTechnologies = styled.div`
    ${TheRightChHeading} {
        margin-top: 0;
    }
    p {
        margin-top: 0;
    }
`;

const FloatingBlockImg = styled.div`
    img {
        height: 552px;
    }
    ${Media('xlscreens')} {
        position: relative;
        left: -113px;
        bottom: -17px;
        img {
            height: 28.75vw;
        }
    }
    ${Media('tablet')} {
        img {
            width: 100%;
            height: auto;
            margin: 50px 0;
        }
    }
`;

const RightTech02 = styled.div`
    background-image: url(${baseURL}assets/images/ebook/de-right-tech-bg.jpg);
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-size: cover;
    padding: 50px 0;
    ${SidePaddingWrapper} {
        display: flex;
        align-items: flex-end;
    }
    ${Media('xlscreens')} {
        padding: 5.2vw 0;
    }
    ${Media('mobile')} {
        padding: 50px 0;
    }
`;

const TechBlock = styled.div`
    display: flex;
    margin-top: 20px;
    ${Media('xlscreens')} {
        margin-top: 1.04vw;
    }
`;

const TechBlockHead = styled.div`
    font-size: 22px;
    font-family: ${gilroyBold};
    color: ${colors.white['100']};
    border: 1px solid ${colors.white['100']};
    height: 40px;
    width: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${Media('xlscreens')} {
        font-size: 1.56vw;
        height: 3.12vw;
        width: 3.12vw;
        border-radius: 0.78vw;
    }
    ${Media('mobile')} {
        font-size: 16px;
        height: 30px;
        width: 30px;
    }
`;

const TechBlockPara = styled.p`
    flex: 1;
    margin-left: 30px;
    color: ${colors.white['100']};
    margin-top: 0;
    font-size: 20px;
    line-height: 1.5;
    font-family: ${sourceSansPro};
    span {
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        margin-left: 1.56vw;
        font-size: 1.25vw;
    }
    ${Media('mobile')} {
        font-size: 14px;
        margin-left: 20px;
    }
`;

const TechBlockHeading = styled.h2`
    font-size: 32px;
    color: ${colors.white['100']};
    font-family: ${gilroyBold};
    margin-bottom: 30px;
    ${Media('xlscreens')} {
        font-size: 2.08vw;
        margin-bottom: 2.6vw;
    }
    ${Media('mobile')} {
        font-size: 22px;
    }
`;

const TechFlexEnd = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
`;

const RightTechSlide3 = styled.div`
    img {
        width: 80%;
        margin-top: 30px;
    }
    ${Media('xlscreens')} {
        img {
            margin-top: 1.56vw;
        }
    }
    ${Media('tablet')} {
        img {
            width: 100%;
            margin-bottom: 30px;
        }
    }
    ${Media('mobile')} {
        padding: 50px 0;
    }
`;

const Slide3RedBox = styled.div`
    background-color: ${colors.pink['100']};
    height: 100%;
    width: 100%;
    padding: 60px 110px;
    h2 {
        font-size: 30px;
        font-family: ${gilroyBold};
        color: ${colors.white['100']};
    }
    p {
        font-size: 20px;
        color: ${colors.white['100']};
        font-family: ${gilroyMedium};
        ${Sup} {
            font-size: 16px;
            &:hover {
                color: ${colors.white['100']};
            }
        }
    }
    ${Media('xlscreens')} {
        padding: 3.12vw 5.2vw;
        h2 {
            font-size: 2.08vw;
        }
        p {
            font-size: 1.82vw;
            ${Sup} {
                font-size: 1.14vw;
            }
        }
    }
    ${Media('tablet')} {
        padding: 50px;
    }
    ${Media('mobile')} {
        padding: 15px;
        h2 {
            font-size: 26px;
        }
        p {
            font-size: 16px;
        }
    }
`;

const PlanEvolution = styled.div`
    ${LightBlockSection} {
        justify-content: flex-start;
        padding-top: 140px;
        h1 {
            position: relative;
            z-index: 9;
        }
    }
    ${DarkBlockSection} {
        padding: 80px;
    }
    ${Media('xlscreens')} {
        ${LightBlockSection} {
            padding-top: 7.29vw;
        }
        ${DarkBlockSection} {
            padding: 4.16vw;
        }
    }
    ${Media('tablet')} {
        padding: 70px 0;
        ${LightBlockSection} {
            padding: 0px;
        }
        ${DarkBlockSection} {
            padding: 50px;
        }
    }
    ${Media('mobile')} {
        ${DarkBlockSection} {
            padding: 15px;
        }
    }
`;

const PlanEvalBlockImg = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    img {
        width: 447px;
    }
    ${Media('xlscreens')} {
        img {
            width: 23.28vw;
        }
    }
    ${Media('tablet')} {
        position: relative;
    }
    ${Media('mobile')} {
        img {
            width: 100%;
            margin-bottom: 30px;
        }
    }
`;

const TheRightCXPartner = styled.div`
    ${DarkBlockSection} {
        padding: 130px;
        p {
            margin-bottom: 80px;
        }
    }
    ${Media('xlscreens')} {
        ${DarkBlockSection} {
            padding: 6.7vw;
            p {
                margin-bottom: 4.16vw;
            }
        }
    }
    ${Media('tablet')} {
        ${DarkBlockSection} {
            padding: 50px;
            p {
                margin-bottom: 80px;
            }
        }
        ${LightBlockSection} {
            padding: 0;
        }
    }
    ${Media('mobile')} {
        ${DarkBlockSection} {
            padding: 30px 15px;
            p {
                margin-bottom: 50px;
            }
        }
    }
`;

const Conclusion = styled.div`
    h1 {
        position: absolute;
        z-index: 9;
        top: 50px;
        left: 50px;
    }
    ${Media('xlscreens')} {
        h1 {
            top: 7.29vw;
            left: 5.98vw;
        }
        ${BigTextWrapper} {
            bottom: -9vw;
        }
    }
    ${Media('mobile')} {
        h1 {
            top: 30px;
            left: 15px;
        }
    }
`;

const ConclusionBlockImg = styled.div`
    img {
        width: 100%;
    }
`;

const ConclusionRight = styled.div`
    padding: 50px;
    padding-top: 80px;
    a {
        margin-top: 60px;
        font-family: ${gilroyBold};
    }
    ${Media('xlscreens')} {
        padding: 4.68vw;
        padding-top: 9.89vw;
        a {
            margin-top: 3.125vw;
        }
    }
    ${Media('mobile')} {
        padding: 15px;
        a {
            margin-top: 30px;
        }
    }
`;

const OminiBlockImg = styled.div`
    img {
        width: 100%;
    }
`;

export default BuildingDigitalExp;
