import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyLight } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

const MainPara = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.p`
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0vw;
    color: ${colors.gray_dark['100']};
    font-family: ${gilroyLight};
    ${Media('xlscreens')} {
        font-size: 1.01vw;
    }
    ${Media('tablet')} {
        font-size: 4vw;
        margin-top: 2vw;
    }
`;

export default MainPara;
