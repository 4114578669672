import { Container } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';

export * from './section-heading';
export * from './floating-section-image';
export * from './monitoring-section';
export * from './call-action-section';
export * from './strategy-box';

// Styles
export const ContainerWideSRM = styled(Container)`
    &&& {
        ${Media('xlscreens')} {
            padding: 0 7.3vw !important;
        }
    }
`;
