import React, { useEffect, useRef } from 'react';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import WebViewer from '@pdftron/pdfjs-express-viewer';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import { Col } from '@bootstrap-styled/v4';
import Button from 'shared-components/button/button';

const RenderMenu = ({ url }) => {
    const viewer = useRef(null);

    useEffect(() => {
        WebViewer(
            {
                // licenseKey: 'St6aD6rbOuRWunQx3xKv', // Staging Key
                licenseKey: 't6FLd5VNUbmbxY2RtUTk', // Production Key
                path: process.env.REACT_APP_BASE_URL + 'assets/pdftron',
                initialDoc: url,
            },
            viewer.current
        ).then((instance) => {
            instance.setFitMode(instance.FitMode.FitWidth);
            instance.UI.setTheme('dark');
        });
    }, []);

    return (
        <Wrapper className="mt-28">
            <RowCentered>
                <Col xs="12">
                    <DownloadBtnWrap>
                        <Button href={url} round={true} download>
                            Download PDF
                        </Button>
                    </DownloadBtnWrap>
                </Col>
            </RowCentered>
            <div className="webviewer" ref={viewer}></div>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    width: 100%;
    margin: 60px auto;
    ${Media('xlscreens')} {
        margin: 2vw auto;
        .webviewer {
            height: 80vh;
        }
    }
    ${Media('tablet')} {
        width: 100%;
        .webviewer {
            height: 580px;
        }
    }
    ${Media('mobile')} {
        .webviewer {
            height: 480px;
        }
    }
`;

const DownloadBtnWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    ${Media('mobile')} {
        margin-bottom: 1.5vw;
    }
`;

export default RenderMenu;
