import React from 'react';
import styled from 'styled-components';
import { Container, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import colors from 'shared-components/theme/colors';
import {
    IndustrySectionHeading,
    IndustryTag,
    Text,
    ContentCenter,
    IndustryPerformanceBox,
} from 'components/industries/common';
import { RowContentCenter } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';

export const PerformanceSection = ({ performanceList }) => {
    return (
        <CXPerformance id="cx-performance">
            <Container>
                <div>
                    <ContentCenter>
                        <IndustryTag>Every step counts</IndustryTag>
                        <IndustrySectionHeading>
                            The ibex Travel & Hospitality CX Performance Model
                        </IndustrySectionHeading>
                        <Text>
                            Ensure repeatable success with ibex’s CX Performance
                            Methodology, built specifically for the Travel{' '}
                            <br />& Hospitality industry.
                        </Text>
                    </ContentCenter>
                </div>
            </Container>
            <Container fluid>
                <PerformanceFluidRow>
                    {performanceList &&
                        performanceList.length > 0 &&
                        performanceList.map((listItem, index) => {
                            return (
                                <Col lg="4" md="6" key={index}>
                                    <div>
                                        <IndustryPerformanceBox
                                            image={listItem.imgUrl}
                                            title={listItem.title}
                                            list={listItem.list}
                                        />
                                    </div>
                                </Col>
                            );
                        })}
                </PerformanceFluidRow>
            </Container>
        </CXPerformance>
    );
};
const CXPerformance = styled.section`
    background-color: ${colors.white['100']};
    padding-bottom: 0px;
`;
const PerformanceFluidRow = styled(RowContentCenter)`
    ${Media('xlscreens')} {
        margin: 0px 7vw !important;
    }
`;
