import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { Row, Col } from '@bootstrap-styled/v4';
import CXProduct from 'components/cx-product/cx-product';
import { gilroyMedium } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';
export const RefleCXFeaturesSection = () => {
    return (
        <>
            <div id="cxrating"></div>
            <CXProduct
                image={`${baseURL}assets/images/cx-product-1.png`}
                imageSide="right">
                <span>RefleCX</span>
                <h3>
                    RefleCX<sup>TM</sup> features an analytical back-end for
                    analyzing and scoring feedback, with an intuitive front-end
                    for understanding your CX - <br />
                    and taking action.
                </h3>
            </CXProduct>
            <CXProduct
                image={`${baseURL}assets/images/cx-product-2.png`}
                imageSide="left">
                <span>RefleCX</span>
                <h2>
                    Connect RefleCX directly <br /> into your customer journey.
                </h2>
                <ProductList>
                    <Row>
                        <Col md="5">
                            <li>
                                <FaCheckCircle />
                                5-Star Rating System
                            </li>
                            <li>
                                <FaCheckCircle />
                                Mobile-Optimized
                            </li>
                            <li>
                                <FaCheckCircle />
                                Tailored Branding
                            </li>
                        </Col>
                        <Col md={{ size: 6, offset: 1 }}>
                            <li>
                                <FaCheckCircle />
                                Sentiment Disposition & Analysis
                            </li>
                            <li>
                                <FaCheckCircle />
                                Closed Loop Issue Tracking, Escalation, &
                                Resolution
                            </li>
                            <li>
                                <FaCheckCircle />
                                Analytics Suite
                            </li>
                        </Col>
                    </Row>
                </ProductList>
                <p>
                    Send branded surveys at any touchpoint; Perform
                    real-time&nbsp;
                    <br /> feedback analysis & issue resolution; Build lifetime
                    brand <br /> advocates.
                </p>
            </CXProduct>
        </>
    );
};
const ProductList = styled.ul`
    padding: 0px;
    margin: 0px 0px 30px;
    li {
        display: flex;
        align-items: center;
        font-size: 20px;
        border-bottom: 1px solid ${colors.gray_dark['10']};
        min-height: 100px;
        letter-spacing: -0.5px;
        font-family: ${gilroyMedium};
        svg {
            margin-right: 20px;
            flex: 0 0 30px;
        }
    }
    ${Media('xlscreens')} {
        margin-bottom: 3vw;
        li {
            min-height: 4.5vw;
            font-size: 1vw;
            letter-spacing: -0.05vw;
            svg {
                margin-right: 1.3vw;
                flex: 0 0 1.2vw;
            }
        }
    }
    ${Media('mobile')} {
        li {
            min-height: auto;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
`;
