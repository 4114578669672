import React from 'react';
import Layout from 'components/layout';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import LazyLoad from 'react-lazyload';
import Loader from 'components/loader/loader';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { IntroRightTime, NavBlackSection, HappenedComm } from './sections';
const sections = [
    {
        component: <IntroRightTime />,
    },
    {
        component: <NavBlackSection />,
    },
    {
        component: <HappenedComm />,
    },
];
const Outsource = () => {

    return (
        <Layout>
            <Breadcrumbs bgColor={colors.gray_lightest['100']}>
                <li>Why Outsource</li>
            </Breadcrumbs>
            <WhyOutsorceWrapper>
                {sections &&
                    sections.map((section, index) => {
                        return (
                            <div
                                key={index}>
                                {section.component}
                            </div>
                        );
                    })}
            </WhyOutsorceWrapper>
        </Layout>
    );
};
const WhyOutsorceWrapper = styled.div`
    section {
        ${Media('xlscreens')} {
            padding: 2vw 7.5vw;
        }
    }
`;

export default Outsource;
