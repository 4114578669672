import React, { Fragment } from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import {
    FaMobileAlt,
    FaDollarSign,
    FaGlobeAmericas,
    FaPlay,
    FaShieldAlt,
    FaCar,
    FaWarehouse,
} from 'react-icons/fa';
import { IoIosMail, IoMdBicycle, IoMdRadio, IoMdWatch } from 'react-icons/io';
import { FiMonitor } from 'react-icons/fi';
import HomeBrand from 'components/home-brand/home-brand';
import MainHeading from 'shared-components/main-heading/main-heading';
import { gilroyLight } from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { baseURL } from 'shared-components/theme/helpers';

const homeBrandsItems = [
    {
        rank: '#1',
        title: (
            <Fragment>
                Telecommunications <br /> Network
            </Fragment>
        ),
        icon: <FaMobileAlt />,
        vdelay: 0,
    },
    {
        rank: '#1',
        title: (
            <Fragment>
                Money Transfer <br /> Service
            </Fragment>
        ),
        icon: <FaDollarSign />,
        vdelay: 100,
    },
    {
        rank: '#1',
        title: (
            <Fragment>
                Global <br /> Company
            </Fragment>
        ),
        icon: <FaGlobeAmericas />,
        vdelay: 300,
    },
    {
        rank: '#1',
        title: (
            <Fragment>
                Retail & <br /> E-Commerce
            </Fragment>
        ),
        icon: <IoIosMail />,
        vdelay: 400,
    },
    {
        rank: '#1',
        title: (
            <Fragment>
                Shipping & <br /> Logistics
            </Fragment>
        ),
        icon: <IoMdBicycle />,
        vdelay: 500,
    },
    {
        rank: '#1',
        title: (
            <Fragment>
                Streaming Media <br /> & Content
            </Fragment>
        ),
        icon: <FaPlay />,
        vdelay: 600,
    },
    {
        rank: '#1',
        title: (
            <Fragment>
                Consumer <br /> Electronics
            </Fragment>
        ),
        icon: <IoMdRadio />,
        vdelay: 700,
    },
    {
        rank: '#1',
        title: (
            <Fragment>
                Extended <br /> Warranty
            </Fragment>
        ),
        icon: <FaShieldAlt />,
        vdelay: 800,
    },
    {
        rank: '#1',
        title: (
            <Fragment>
                Leading Set <br /> Top Box
            </Fragment>
        ),
        icon: <FiMonitor />,
        vdelay: 900,
    },
    {
        rank: '#2',
        title: (
            <Fragment>
                Ride Sharing <br /> Service
            </Fragment>
        ),
        icon: <FaCar />,
        vdelay: 1100,
    },
    {
        rank: '#2',
        title: (
            <Fragment>
                Fitness <br /> Device
            </Fragment>
        ),
        icon: <IoMdWatch />,
        vdelay: 1200,
    },
    {
        rank: '#2',
        title: (
            <Fragment>
                Warehouse <br /> Club
            </Fragment>
        ),
        icon: <FaWarehouse />,
        vdelay: 1300,
    },
];
export const BrandsSection = () => {
    return (
        <>
            <LeadingBrands id="brands">
                <Container>
                    <Row>
                        <Col lg="12">
                            <MainHeading>
                                The world’s leading brands trust ibex.
                            </MainHeading>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <BrandsList>
                                {homeBrandsItems &&
                                    homeBrandsItems.map((item, ind) => (
                                        <HomeBrand
                                            rank={item.rank}
                                            icon={item.icon}
                                            vdelay={item.vdelay}
                                            title={item.title}
                                            key={ind}
                                        />
                                    ))}
                            </BrandsList>
                        </Col>
                    </Row>
                </Container>
            </LeadingBrands>

            <BuildForWhat>
                <Container>
                    <Row>
                        <div>
                            <Col lg="12">
                                <BuildImgbox>
                                    <img
                                        src={`${baseURL}assets/images/whatsnext.png`}
                                        alt=""
                                    />
                                    <BuildtextBox>
                                        <h3>Built for what’s next.</h3>
                                        <p>
                                            ibex has become the service
                                            disrupter in the world of business
                                            process outsourcing, consistently
                                            outperforming and outpacing our much
                                            larger competitors. We are the
                                            perfect alternative to the lethargic
                                            and costly billion-dollar players -
                                            no matter the size of your business,
                                            you’ll always be treated like our
                                            biggest client.
                                        </p>
                                    </BuildtextBox>
                                </BuildImgbox>
                            </Col>
                        </div>
                    </Row>
                </Container>
            </BuildForWhat>
        </>
    );
};
const LeadingBrands = styled.section`
    padding-bottom: 0px;
    position: relative;
    h2 {
        font-size: 3.5vw;
    }
`;

const BrandsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    ${Media('xlscreens')} {
        margin: 0px -4vw;
    }
`;
const BuildForWhat = styled.section`
    ${Media('xlscreens')} {
        padding: 10vw 0 10vw;
    }
    ${Media('tablet')} {
        padding-bottom: 120px;
    }
`;

const BuildImgbox = styled.div`
    position: relative;
    img {
        width: 100%;
    }
`;

const BuildtextBox = styled.div`
    padding: 30px 70px 60px;
    background-color: ${colors.white['100']};
    position: absolute;
    bottom: -50px;
    width: 56%;
    margin: 0 auto;
    left: 0;
    right: 0;
    h3 {
        font-size: 44px;
        ${Media('xlscreens')} {
            font-size: 3vw;
            letter-spacing: -0.19vw;
        }
    }

    p {
        font-size: 21px;
        font-family: ${gilroyLight};
        line-height: 1.4;
        ${Media('xlscreens')} {
            font-size: 1.6vw;
        }
    }
    ${Media('xlscreens')} {
        width: 41.6vw;
        padding: 4vw 6.6vw 3vw;
    }
    ${Media('tablet')} {
        h3 {
            font-size: 36px;
            line-height: 1.2;
        }
        p {
            font-size: 16px;
        }
    }
    ${Media('mobile')} {
        padding: 30px;
        width: 96%;
        h3 {
            font-size: 30px;
        }
        p {
            font-size: 14px;
        }
    }
`;
