import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const UpperRegBox = ({ children, level }) => {
    return <Wrapper level={level}>{children}</Wrapper>;
};

const Wrapper = styled.div`
    background-color: ${colors.white['100']};
    padding: 90px;
    margin-bottom: -90px;
    margin-top: 110px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
        font-family: ${gilroySemibold};
        font-size: 24px;
    }
    h2 {
        margin: 30px 0px 40px;
    }
    ${Media('xlscreens')} {
        padding: 7.3vw 9vw;
        margin-bottom: -7.3vw;
        margin-top: 12vw;
        min-height: ${(props) =>
            props.level === 'guarantee' ? '35vw' : '1px'};
        span {
            font-size: 1.46vw;
        }
        h2 {
            margin: 2.5vw 0 3.5vw;
        }
    }
    ${Media('mobile')} {
        padding: 50px 30px;
    }
`;
