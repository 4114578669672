import React from 'react';
import colors from 'shared-components/theme/colors';
import { gilroyMedium, gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    ContainerWideSRM,
    SRMSectionHeading,
} from 'components/social-rep-man/common';
import styled from 'styled-components';
import MainPara from 'shared-components/main-para/main-para';
import ReactHtmlParser from 'react-html-parser';
import { Col, Row } from '@bootstrap-styled/v4';
import { baseURL } from 'shared-components/theme/helpers';


export const CampaignCallFlow = ({ solutionFeatures, holisticFeatures }) => {
    return (
        <>
            <CampaignSection>
                <ContainerWideSRM>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <SRMSectionHeading>
                                    An Integrated Cloud CRM For Agile <br />&
                                    Outcome-Driven CX
                                </SRMSectionHeading>
                                <MainPara align="center" styleTwo={true}>
                                    With everything you need to make CX your
                                    competitive advantage, FleCX is an
                                    end-to-end solution that <br />
                                    boosts your productivity, efficiency, and
                                    cost savings—all from a single platform.
                                </MainPara>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </ContainerWideSRM>
                <Solution>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <Row noGutters={true}>
                            <Col lg="7">
                                <img
                                    src={`${baseURL}assets/images/tech/flexcx-driven.svg`}
                                    alt="-"
                                />
                            </Col>
                            <Col lg="5">
                                <SolutionDesc>
                                    <SolutionTitle>
                                        Real-Time Campaign Management
                                    </SolutionTitle>
                                    <SolutionPara>
                                        Understand and optimize performance
                                        in&nbsp;
                                        <br />
                                        every part of your operation by
                                        aligning&nbsp;
                                        <br />
                                        efforts across KPIs and SLAs.
                                    </SolutionPara>
                                    <SolutionFeatures>
                                        {solutionFeatures &&
                                            solutionFeatures.length > 0 &&
                                            solutionFeatures.map(
                                                (solution, ind) => (
                                                    <Feature key={ind}>
                                                        <img
                                                            src={solution.icon}
                                                            alt="-"
                                                        />
                                                        {solution.title}
                                                    </Feature>
                                                )
                                            )}
                                    </SolutionFeatures>
                                </SolutionDesc>
                            </Col>
                        </Row>
                    </ScrollAnimation>
                </Solution>
            </CampaignSection>
            <HolisticSection>
                <ContainerWideSRM>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <SolutionTitle align="center">
                                    Holistic Performance Measurement
                                </SolutionTitle>
                                <MainPara align="center" styleTwo={true}>
                                    Stay on top of in-center or @home agent
                                    performance in real time with tools that
                                    help supervisors monitor, support, coach and
                                    train from anywhere.
                                </MainPara>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                    <HolisticFeatures>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <Row>
                                {holisticFeatures &&
                                    holisticFeatures.length > 0 &&
                                    holisticFeatures.map((feature, ind) => {
                                        return (
                                            <Col lg="4" key={ind}>
                                                <HolisticBox key={ind}>
                                                    <img
                                                        src={feature.icon}
                                                        alt="-"
                                                    />
                                                    <p>
                                                        {ReactHtmlParser(
                                                            feature.para
                                                        )}
                                                    </p>
                                                </HolisticBox>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </ScrollAnimation>
                    </HolisticFeatures>
                </ContainerWideSRM>
            </HolisticSection>
            <CallFlow>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <ContainerWideSRM>
                        <Row>
                            <Col lg="6">
                                <CallFlowDesc>
                                    <SolutionTitle>
                                        Customize and Manage Every Call Flow
                                    </SolutionTitle>
                                    <SolutionPara>
                                        Design, build and deploy compelling
                                        customer journeys <br />— no code
                                        required. FleCX enables non-technical{' '}
                                        <br />
                                        employees to create intelligent,
                                        context-driven IVR flows <br />
                                        for a friction-free customer experience.
                                    </SolutionPara>
                                </CallFlowDesc>
                            </Col>
                            <Col lg="6">
                                <img
                                    src={`${baseURL}assets/images/tech/flexcx-call-flow.svg`}
                                    alt="-"
                                />
                            </Col>
                        </Row>
                    </ContainerWideSRM>
                </ScrollAnimation>
            </CallFlow>
        </>
    );
};
const CampaignSection = styled.section``;
const Solution = styled.div`
    margin-top: 40px;
    img {
        width: 100%;
    }
    ${Media('xlscreens')} {
        margin-top: 2.6vw;
    }
    ${Media('tablet')} {
        width: 90%;
        margin: 0 auto;
    }
`;
const SolutionDesc = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    ${Media('xlscreens')} {
        padding: 0 6vw;
    }
    ${Media('tablet')} {
        margin-top: 20px;
    }
`;
const SolutionFeatures = styled.div`
    display: flex;
    margin-top: 50px;
    ${Media('xlscreens')} {
        margin-top: 2.66vw;
    }
    ${Media('tablet')} {
        justify-content: center;
    }
    ${Media('mobile')} {
        margin-top: 20px;
    }
`;
const Feature = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: ${gilroySemibold};
    margin-right: 40px;
    img {
        margin-right: 15px;
        height: 20px;
        width: 20px;
    }
    ${Media('xlscreens')} {
        font-size: 1.04vw;
        margin-right: 2.08vw;
        img {
            margin-right: 0.78vw;
            height: 1.04vw;
            width: 1.04vw;
        }
    }
    ${Media('mobile')} {
        margin: 5px 0;
        font-size: 14px;
        margin-right: 20px;
        img {
            height: 15px;
            width: 15px;
            margin-right: 5px;
        }
    }
`;
const HolisticSection = styled.section`
    padding-top: 0;
`;
const HolisticFeatures = styled.div`
    margin-top: 30px;
    ${Media('xlscreens')} {
        margin-top: 2.08vw;
    }
`;
const HolisticBox = styled.div`
    display: flex;
    img {
        margin-right: 40px;
        width: 135px;
    }
    p {
        font-size: 16px;
        font-family: ${gilroyMedium};
        padding-right: 10px;
    }
    ${Media('xlscreens')} {
        img {
            margin-right: 2.08vw;
            width: 7.03vw;
        }
        p {
            font-size: 0.83vw;
            padding-right: 0.52vw;
        }
    }
    ${Media('tablet')} {
        margin: 12px 0;
        p {
            br {
                display: none;
            }
        }
    }
    ${Media('mobile')} {
        flex-direction: column;
        align-items: center;
        img {
            width: 40%;
            margin-right: 0;
        }
        p {
            text-align: center;
            font-size: 14px;
        }
    }
`;
const CallFlow = styled.section`
    background-color: ${colors.reflecx.buildBg};
    img {
        width: 100%;
    }
`;
const CallFlowDesc = styled.div`
    ${Media('xlscreens')} {
        padding: 0 7vw;
    }
`;
const SolutionTitle = styled.h2`
    font-size: 32px;
    font-family: ${gilroySemibold};
    line-height: 1.45;
    text-align: ${(props) => (props.align ? props.align : 'left')};
    margin-top: 0;
    ${Media('xlscreens')} {
        font-size: 2.08vw;
    }
    ${Media('tablet')} {
        text-align: center;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 20px;
    }
`;
const SolutionPara = styled.p`
    font-size: 16px;
    font-family: ${gilroyMedium};
    line-height: 2;
    text-align: ${(props) => (props.align ? props.align : 'left')};
    ${Media('xlscreens')} {
        font-size: 0.93vw;
    }
    ${Media('tablet')} {
        text-align: center;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
