import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { SRMSectionHeading } from 'components/social-rep-man/common';

export const BrandSection = () => {
    return (
        <BrandsLogoSection>
            <SRMSectionHeading styleTwo={true}>
                Gather & act on reviews from over 90 sites, including:
            </SRMSectionHeading>
            <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce={true}
                delay={100}>
                <BrandsLogos>
                    <img
                        src={`${baseURL}assets/images/socialrepman-brands.svg`}
                        alt="-"
                    />
                </BrandsLogos>
                <BrandsLogosMobile>
                    <img
                        src={`${baseURL}assets/images/socialrepman-brands-mobile.svg`}
                        alt="-"
                    />
                </BrandsLogosMobile>
            </ScrollAnimation>
        </BrandsLogoSection>
    );
};

const BrandsLogoSection = styled.section`
    background-color: ${colors.socialRep['brandsBG']};
    display: flex;
    flex-direction: column;
    align-items: center;
    ${Media('xlscreens')} {
        padding: 7.8vw 0;
    }
    ${Media('tablet')} {
        padding: 40px 0px;
        padding-bottom: 70px;
    }
`;
const BrandsLogos = styled.div`
    display: flex;
    margin-top: 100px;
    img {
        height: 400px;
    }
    ${Media('xlscreens')} {
        margin-top: 5.2vw;
        img {
            height: 20.8vw;
        }
    }
    ${Media('tablet')} {
        display: none;
    }
`;
const BrandsLogosMobile = styled.div`
    ${Media('xlscreens')} {
        display: none;
    }
    ${Media('tablet')} {
        margin-top: 50px;
        padding: 0px 30px;
        img {
            width: 100%;
        }
    }
`;
