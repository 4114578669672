import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { sourceSansPro } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ReactHtmlParser from 'react-html-parser';

export const EbookFeatureList2 = ({
    list,
    oddBgColor,
    color,
    seasonal,
    evenBgColor,
}) => {
    return (
        <Wrapper oddBgColor={oddBgColor} evenBgColor={evenBgColor}>
            {list &&
                list.length > 0 &&
                list.map((item, index) => {
                    return (
                        <li key={index}>
                            <Text color={color} seasonal={seasonal}>
                                {ReactHtmlParser(item)}
                            </Text>
                        </li>
                    );
                })}
        </Wrapper>
    );
};

const Wrapper = styled.ul`
    padding: 0px;
    margin: 0px;
    counter-reset: feature;
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0px;
        &:nth-child(odd) {
            background-color: ${(props) => props.oddBgColor};
        }
        &:nth-child(even) {
            background-color: ${(props) =>
                props.evenBgColor && props.evenBgColor};
        }
    }
    ${Media('xlscreens')} {
        li {
            padding: 1.2vw 0;
        }
    }
    ${Media('tablet')} {
        li {
            padding-right: 10px;
            br {
                display: none;
            }
        }
    }
`;

const Text = styled.div`
    flex: 1 0;
    font-size: 20px;
    line-height: ${(props) => (props.seasonal ? '1.6' : '1.8')};
    font-family: ${sourceSansPro};
    color: ${(props) => (props.color ? props.color : colors.ebook.text)};
    padding: 0px 50px;
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.seasonal ? '1.05vw' : '1.25vw')};
        letter-spacing: 0.02vw;
        padding: 0 2.66vw;
    }
    ${Media('tablet')}{
        padding: 0px 20px;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
