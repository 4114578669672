import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import { gilroyMedium, gilroyBold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

export const RelatedItem = ({ img, title, target, url }) => {
    return (
        <WebinarItem animateIn="fadeInUp" animateOnce={true}>
            <WebinarThumbnail target={target} href={url}>
                <img src={img} alt="-" />
            </WebinarThumbnail>
            <WebinarInfo>
                <span>WEBINAR</span>
                <WebinarTitle>{ReactHtmlParser(title)}</WebinarTitle>
                <WatchItButton href={url} target={target}>
                    Watch It
                    <img
                        src={`${baseURL}assets/images/arrow_right-blue.svg`}
                        alt="-"
                    />
                </WatchItButton>
            </WebinarInfo>
        </WebinarItem>
    );
};
const WebinarThumbnail = styled.a`
    margin-right: 40px;
    height: 175px;
    border-radius: 10px;
    img {
        height: 100%;
    }
    ${Media('xlscreens')} {
        margin-right: 2.08vw;
        height: 9.11vw;
        border-radius: 0.52vw;
    }
    ${Media('mobile')} {
        margin-right: 10px;
        height: 110px;
    }
`;
const WebinarItem = styled(ScrollAnimation)`
    display: flex;
    margin-bottom: 70px;
    ${Media('xlscreens')} {
        margin-bottom: 3.64vw;
    }
    ${Media('tablet')} {
        margin-bottom: 50px;
    }
    ${Media('mobile')} {
        margin-bottom: 30px;
    }
`;
const WebinarInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    span {
        font-size: 18px;
        font-family: ${gilroyMedium};
    }
    ${Media('xlscreens')} {
        span {
            font-size: 0.93vw;
        }
    }
    ${Media('mobile')} {
        span {
            font-size: 14px;
        }
    }
`;
const WebinarTitle = styled.div`
    font-size: 20px;
    font-family: ${gilroyMedium};
    letter-spacing: -0.2px;
    ${Media('xlscreens')} {
        font-size: 1.04vw;
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
const WatchItButton = styled.a`
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: ${gilroyBold};
    img {
        width: 20px;
    }
    ${Media('xlscreens')} {
        font-size: 0.93vw;
        img {
            width: 1.04vw;
        }
    }
    ${Media('mobile')} {
        font-size: 14px;
        img {
            width: 16px;
        }
    }
`;
