import React from 'react';

import { Para } from '../cx-pocket-guide/para';
import { Col, Row } from '@bootstrap-styled/v4';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import styled from 'styled-components';
import { BlockImg } from './block-img';
import Media from 'shared-components/theme/medias';
import { gilroyMedium } from 'shared-components/theme/fonts';

export const Point1 = () => {
    return (
        <Wrapper>
            <BlockImg
                imgUrl={`${baseURL}assets/images/ebook/imperative-ebook/point1_block_img.png`}
            />
            <Row>
                <Col lg="6">
                    <Para color={colors.white['100']}>
                        And when people are involved, things can go the opposite
                        of smoothly. CX takes country-sized villages of
                        interconnected talent and teams, working seamlessly,
                        daily, tirelessly to ensure positive, meaningful
                        interactions with those complex people we call
                        customers.
                    </Para>
                </Col>
                <Col lg="6">
                    <BoxWrapper>
                        <h5>
                            People are your brand. If you understand this,
                            you’ve increased your chances of success.
                        </h5>
                    </BoxWrapper>
                </Col>
            </Row>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    ${Media('xlscreens')} {
        .row {
            margin-top: 3vw;
        }
    }
`;

const BoxWrapper = styled(FlexCentered)`
    border: 1px solid ${colors.white['100']};
    height: 100%;
    padding: 0px 20px;
    h5 {
        font-size: 32px;
        font-family: ${gilroyMedium};
        color: ${colors.white['100']};
        line-height: 1.62;
        text-align: center;
    }
    ${Media('xlscreens')} {
        h5 {
            font-size: 1.66vw;
        }
    }
    ${Media('tablet')} {
        h5 {
            font-size: 22px;
        }
    }
`;
