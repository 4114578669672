import React from 'react';
import colors from 'shared-components/theme/colors';
import { gilroyMedium } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
export const DEFooter = ({
    slideNumber,
    numberDark,
    iconLight,
    noIcons,
    numberTabDark,
}) => {
    return (
        <Wrapper noIcons={noIcons}>
            {!noIcons && (
                <IconWrapper>
                    <img
                        src={`${baseURL}assets/images/ebook/${
                            iconLight ? 'plus-white' : 'plus-black'
                        }.svg`}
                        alt="-"
                    />
                    <img
                        src={`${baseURL}assets/images/ebook/${
                            iconLight ? 'circle-white' : 'circle-black'
                        }.svg`}
                        alt="-"
                    />
                    <img
                        src={`${baseURL}assets/images/ebook/${
                            iconLight ? 'triangle-white' : 'triangle-black'
                        }.svg`}
                        alt="-"
                    />
                </IconWrapper>
            )}
            {slideNumber && (
                <SlideNumber dark={numberDark} numberTabDark={numberTabDark}>
                    {slideNumber}
                </SlideNumber>
            )}
        </Wrapper>
    );
};

const IconWrapper = styled.div`
    display: flex;
    img {
        height: 15px;
        width: 15px;
        margin-right: 15px;
    }
    ${Media('xlscreens')} {
        img {
            height: 1.04vw;
            width: 1.04vw;
            margin-right: 1.56vw;
        }
    }
    ${Media('mobile')} {
        img {
            height: 12px;
            width: 12px;
        }
    }
`;

const SlideNumber = styled.div`
    font-size: 16px;
    font-family: ${gilroyMedium};
    color: ${(props) =>
        props.dark ? colors.black['100'] : colors.white['100']};
    ${Media('xlscreens')} {
        font-size: 1.04vw;
    }
    ${Media('tablet')} {
        ${(props) => {
            if (props.numberTabDark) {
                return `
                    color:${colors.black['100']};
                `;
            }
        }}
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;

const Wrapper = styled.div`
    position: absolute;
    bottom: 40px;
    padding: 0px 50px;
    display: flex;
    justify-content: ${(props) =>
        props.noIcons ? 'flex-end' : 'space-between'};
    width: 100%;
    box-sizing: border-box;
    ${Media('xlscreens')} {
        bottom: 2.08vw;
        padding: 0 5.98vw;
    }
    ${Media('tablet')} {
        position: relative;
        bottom: 0;
        margin: 40px 0;
    }
    ${Media('mobile')} {
        padding: 0px 15px;
        margin: 20px 0;
    }
`;
