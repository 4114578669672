import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyBold, gilroyRegular } from 'shared-components/theme/fonts';
import { FlexColumnCentered } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';
import Layout from 'components/layout';
import { baseURL, baseURLPage } from 'shared-components/theme/helpers';
import Button from 'shared-components/button/button';

const NotFoundPage = () => {
    return (
        <Layout title="404: This page could not be found" noheaderfooter>
            <Wrapper>
                <IbexLogo>
                    <img src={`${baseURL}assets/images/ibex-logo.svg`} />
                </IbexLogo>
                <Text404>
                    <h1>404</h1>
                    <p>This page could not be found.</p>
                </Text404>
                <Button ifButton={false} round={true} href={baseURLPage}>
                    Return to Homepage
                </Button>
            </Wrapper>
        </Layout>
    );
};

const Wrapper = styled(FlexColumnCentered)`
    height: calc(100vh - 1px);
    width: 100%;
`;
const IbexLogo = styled.div`
    img {
        width: auto;
        height: 40px;
    }
    ${Media('xlscreens')} {
        img {
            height: 2.66vw;
        }
    }
`;
const Text404 = styled.div`
    display: flex;
    align-items: center;
    margin: 40px 0;
    h1 {
        display: inline-block;
        border-right: 1px solid ${colors.black['30']};
        margin: 0;
        margin-right: 20px;
        padding: 10px 23px 10px 0;
        font-size: 18px;
        vertical-align: top;
        font-family: ${gilroyBold};
        ${Media('xlscreens')} {
            font-size: 1.25vw;
            margin-right: 1.04vw;
            padding: 0.52vw 1.19vw 0.52vw 0;
        }
    }
    p {
        margin: 0px;
        font-size: 14px;
        font-family: ${gilroyRegular};
        ${Media('xlscreens')} {
            font-size: 0.8vw;
        }
    }
    ${Media('xlscreens')} {
        margin: 3.64vw 0;
    }
`;
export default NotFoundPage;
