import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import { gilroySemibold } from 'shared-components/theme/fonts';
import DigitalCases from '../digital-cases/digital-cases';
import { Parallax } from 'react-scroll-parallax';

export const DigitalBigText = ({ bigTextOne, bigTextTwo }) => {
    return (
        <Wrapper>
            <BigText id="bigText">
                <Parallax x={['-110%', '8%']}>
                    <OneText id="bigTextOne">{bigTextOne}</OneText>
                </Parallax>
                <Parallax x={['8%', '-53%']}>
                    <TwoText id="bigTextTwo">{bigTextTwo}</TwoText>
                </Parallax>
            </BigText>
            <DigitalCasesMarginControl>
                <DigitalCases />
            </DigitalCasesMarginControl>
        </Wrapper>
    );
};
const DigitalCasesMarginControl = styled.div`
    margin-top: 13vw;
    @media only screen and (min-width: 1366px) and (max-width: 1680px) {
        margin-top: 11vw;
    }
`;
const Wrapper = styled.div`
    overflow: hidden;
`;
const BigText = styled.div`
    font-size: 140px;
    font-family: ${gilroySemibold};
    height: 58vw;
    overflow: hidden;
    line-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 9;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: ${(props) => (props.level === 'one' ? '0' : '-420px')};
    span {
        white-space: nowrap;
        position: relative;
    }
    ${Media('xlscreens')} {
        height: 28vw;
        width: 100%;
        font-size: 21.4vw;
        line-height: 13.6vw;
    }
`;
const OneText = styled.span`
    display: inline-flex;
    align-self: flex-start;
    left: -2%;
`;
const TwoText = styled.span`
    display: inline-flex;
    align-self: flex-start;
    left: 35%;
`;
