import React, { useState } from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import Input from 'shared-components/input/input';
import Button from 'shared-components/button/button';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import MainHeading from 'shared-components/main-heading/main-heading';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { gilroyMedium } from 'shared-components/theme/fonts';
import fetch from 'cross-fetch';

const SubscribeForm = ({ storeKey, onSuccess, referrerProp }) => {
    const [first_name, set_first_name] = useState('');
    const [last_name, set_last_name] = useState('');
    const [email_address, set_email_address] = useState('');
    const [submit_btn_text, set_submit_btn_text] = useState('Access');
    const [error, setError] = useState('');

    const handleForm = async (e) => {
        e.preventDefault();

        set_submit_btn_text('Accessing...');

        try {
            var requestOptions = {
                method: 'POST',
            };
            const res = await fetch(
                `${process.env.REACT_APP_IBEX_API}/subscribe-form?token=${process.env.REACT_APP_API_TOKEN}&first_name=${first_name}&last_name=${last_name}&email_address=${email_address}&referrer=${referrerProp}`,
                requestOptions
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                set_first_name('');
                set_last_name('');
                set_email_address('');
                localStorage.setItem(storeKey, 'Completed');
                onSuccess(false);
            } else {
                set_contact_msg(result);
                setError(result.msg);
                set_submit_btn_text('Access');
            }
        } catch (err) {
            console.error(err);
            setError(err.msg);
            set_submit_btn_text('Access');
        }
    };

    return (
        <PassWrapper>
            <Container>
                <Row>
                    <Col md="12" lg={{ size: 8, offset: 2 }}>
                        <PassInner>
                            <MainHeading>ONE MORE STEP</MainHeading>
                            <PageHeading>
                                We need your name and email before we can unlock
                                this page.
                            </PageHeading>
                            <form onSubmit={(e) => handleForm(e)}>
                                <Row>
                                    <Col lg="6">
                                        <PassField>
                                            <Input
                                                label="First Name"
                                                name="first_name"
                                                type="text"
                                                onChange={(val) =>
                                                    set_first_name(
                                                        val.target.value
                                                    )
                                                }
                                                value={first_name}
                                            />
                                        </PassField>
                                    </Col>
                                    <Col lg="6">
                                        <PassField>
                                            <Input
                                                label="Last Name"
                                                name="last_name"
                                                type="text"
                                                onChange={(val) =>
                                                    set_last_name(
                                                        val.target.value
                                                    )
                                                }
                                                value={last_name}
                                            />
                                        </PassField>
                                    </Col>
                                    <Col lg="12">
                                        <PassField>
                                            <Input
                                                label="Email Address"
                                                name="email_address"
                                                type="text"
                                                onChange={(val) =>
                                                    set_email_address(
                                                        val.target.value
                                                    )
                                                }
                                                value={email_address}
                                            />
                                            {error !== '' ? (
                                                <PassErrorText>
                                                    {error}
                                                </PassErrorText>
                                            ) : null}
                                        </PassField>
                                    </Col>
                                </Row>
                                <FormBtn>
                                    <Button ifButton={true} submit={false}>
                                        <b>{submit_btn_text}</b>
                                    </Button>
                                </FormBtn>
                            </form>
                        </PassInner>
                    </Col>
                </Row>
            </Container>
        </PassWrapper>
    );
};

const PassWrapper = styled(FlexCentered)`
    height: calc(100vh - 82px);
    padding-bottom: 100px;
    box-sizing: border-box;
    ${Media('mobile')} {
        padding-bottom: 0px;
    }
`;

const PassInner = styled.div`
    text-align: center;
`;

const PassField = styled.div`
    text-align: left;
    padding-bottom: 30px;
`;

const FormBtn = styled.div``;

const PageHeading = styled.h3`
    font-size: 28px;
    font-family: ${gilroyMedium};
    line-height: 1.25;
    margin-top: 0px;
    ${Media('xlscreens')} {
        font-size: 1.7vw;
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 21px;
    }
`;

const PassErrorText = styled.div`
    text-align: center;
    padding-top: 10px;
    color: red;
    font-size: 14px;
    text-align: left;
    ${Media('xlscreens')} {
        font-size: 0.73vw;
    }
`;

export default SubscribeForm;
