import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { EbookBigText } from '../common';
import ReactHtmlParser from 'react-html-parser';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import { ContainerWide } from '../common/style';
import { Col, Row } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';

export const EbookQoute = ({
    bgColor,
    authorImg,
    qouteText,
    qouteColor,
    authorName,
    authorNameColor,
    bigText1,
    bigText2,
    bigTextStroke,
    noMarginBottom,
}) => {
    return (
        <Wrapper bgColor={bgColor} noMarginBottom={noMarginBottom}>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                <AuthorImg>
                    <img src={authorImg} alt="-" />
                </AuthorImg>
            </ScrollAnimation>

            <ContainerWide>
                <Row>
                    <Col sm="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}
                        >
                            <QouteText qouteColor={qouteColor}>
                                {ReactHtmlParser(qouteText)}
                            </QouteText>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={false}
                        >
                            <AuhorName
                                authorNameColor={authorNameColor}
                                noMarginBottom={noMarginBottom}
                            >
                                {authorName}
                            </AuhorName>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </ContainerWide>
            <BidTextWrapper>
                {bigText1 && (
                    <EbookBigText stroke={bigTextStroke} color={bgColor}>
                        {bigText1}
                    </EbookBigText>
                )}
                {bigText2 && (
                    <EbookBigText
                        stroke={bigTextStroke}
                        reverse
                        color={bgColor + '20'}
                    >
                        {bigText2}
                    </EbookBigText>
                )}
            </BidTextWrapper>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    margin: 120px 0;
    background-color: ${(props) =>
        props.bgColor ? props.bgColor : colors.white['100']};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
    text-align: center;
    margin-bottom: ${(props) => props.noMarginBottom && '0'};
    ${Media('xlscreens')} {
        margin: 6.25vw 0;
        margin-bottom: ${(props) => props.noMarginBottom && '0'};
    }
    ${Media('mobile')} {
        margin-bottom: ${(props) => !props.noMarginBottom && '60px'};
    }
`;
const AuthorImg = styled.div`
    height: 350px;
    width: 350px;
    margin-top: -100px;
    border-radius: 50%;
    overflow: hidden;
    img {
        height: 100%;
        width: 100%;
    }
    ${Media('xlscreens')} {
        height: 18.22vw;
        width: 18.22vw;
        margin-top: -5.2vw;
    }
    ${Media('mobile')} {
        height: 200px;
        width: 200px;
    }
`;
const QouteText = styled.div`
    font-size: 48px;
    font-family: ${gilroyMedium};
    line-height: 1.36;
    text-align: center;
    color: ${(props) =>
        props.qouteColor ? props.qouteColor : colors.black['100']};
    ${Media('xlscreens')} {
        margin: 6vw 0;
        font-size: 3.38vw;
    }
    ${Media('tablet')} {
        margin: 30px 0;
    }
    ${Media('mobile')} {
        font-size: 32px;
        br {
            display: none;
        }
    }
`;
const AuhorName = styled.div`
    font-size: 25px;
    font-family: ${gilroyMedium};
    color: ${(props) =>
        props.authorNameColor ? props.authorNameColor : colors.black['100']};
    ${Media('xlscreens')} {
        margin-bottom: ${(props) => (props.noMarginBottom ? '8vw' : '12vw')};
        font-size: 1.3vw;
    }
    ${Media('tablet')} {
        margin-bottom: 30px;
    }
    ${Media('mobile')} {
        font-size: 18px;
    }
`;
const BidTextWrapper = styled.div`
    position: absolute;
    z-index: -1;
    opacity: 0.2;
    [class*='big-text'] {
        text-transform: uppercase;
        .parallax-inner {
            line-height: 1.1;
        }
    }
    ${Media('xlscreens')} {
        [class*='big-text'] {
            .parallax-inner {
                font-size: 26vw;
            }
        }
    }
`;
