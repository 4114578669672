import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import { gilroyMedium, gilroySemibold } from 'shared-components/theme/fonts';
import SlickSlider from 'shared-components/slider/slider';
import ScrollAnimation from 'react-animate-on-scroll';
import { baseURL } from 'shared-components/theme/helpers';
import { FaChevronRight } from 'react-icons/fa';

const StaffAugSlider = () => {
    const sliderData = [
        {
            icon: `${baseURL}assets/images/staff_aug/it-engineering.svg`,
            title: 'IT/Engineering',
            list: [
                'Robotics Process Automation',
                'Data Center Design Engineer',
                'Web & App Development',
            ],
        },
        {
            icon: `${baseURL}assets/images/staff_aug/it-engineering.svg`,
            title: 'Data Analytics & Business Intelligence',
            list: [
                'Business Intelligence Analysts',
                'Data Analysts',
                'Reports Analysts',
            ],
        },
        {
            icon: `${baseURL}assets/images/staff_aug/it-engineering.svg`,
            title: 'Sales Operations',
            list: [
                'Account and Pipeline Management Experts',
                'Pricing and Quotation Specialists',
                'Firmographics Executives',
                'Compensation and Incentive Analysts',
            ],
        },
        {
            icon: `${baseURL}assets/images/staff_aug/it-engineering.svg`,
            title: 'Information/Cyber Security',
            list: [
                'Cyber Security Analysts',
                'Cyber Security Engineer',
                'Cloud Security Analysts',
                'Cloud Security Engineer',
            ],
        },
        {
            icon: `${baseURL}assets/images/staff_aug/it-engineering.svg`,
            title: 'Digital Marketing',
            list: [
                'Ad Operations Analysts',
                'SEO & SEM Analysts',
                'Social Media Specialists',
            ],
        },
        {
            icon: `${baseURL}assets/images/staff_aug/it-engineering.svg`,
            title: 'Human Resources',
            list: [
                'Recruitment Support Executives',
                'Employee Onboarding / Offboarding Specialists',
                'HR Backoffice Support Analysts',
                'Proactive Recruitment Outreach Consultants',
            ],
        },
        {
            icon: `${baseURL}assets/images/staff_aug/it-engineering.svg`,
            title: 'Visuals',
            list: ['Instructional Designers', 'Graphic Designers'],
        },
        {
            icon: `${baseURL}assets/images/staff_aug/it-engineering.svg`,
            title: 'CRM/Integration & Development',
            list: [
                'Salesforce Architects and Developers',
                'Zendesk Integrators',
                'AWS Developers',
            ],
        },
        {
            icon: `${baseURL}assets/images/staff_aug/it-engineering.svg`,
            title: 'Help Desk & Service Desk',
            list: [
                'Customer Service Representative',
                'Technicians',
                'Subject Matter Experts',
            ],
        },
        {
            icon: `${baseURL}assets/images/staff_aug/it-engineering.svg`,
            title: 'Operations Support',
            list: [
                'Quality Analysts',
                'Queue Coordination and Monitoring',
                'Commercial Dispatch Executives',
                'Quality System Analysts',
            ],
        },
        {
            icon: `${baseURL}assets/images/staff_aug/it-engineering.svg`,
            title: 'Marketing Operations & Customer Insights',
            list: [
                'Marketing Operations Analysts',
                'Forecasting and Budget Analysts',
                'Campaign Management Specialists',
            ],
        },
        {
            icon: `${baseURL}assets/images/staff_aug/it-engineering.svg`,
            title: 'Accounting & Finance',
            list: [
                'Account Receivable Analysts',
                'Billing and Invoice Specialists',
            ],
        },
        {
            icon: `${baseURL}assets/images/staff_aug/it-engineering.svg`,
            title: 'Workforce Management',
            list: ['Real-Time Analysts'],
        },
        {
            icon: `${baseURL}assets/images/staff_aug/it-engineering.svg`,
            title: 'Supply Chain & Logistics',
            list: [
                'Supply Chain Analysts',
                'Vendor Relationship Representatives',
            ],
        },
        {
            icon: `${baseURL}assets/images/staff_aug/it-engineering.svg`,
            title: 'Legal',
            list: ['Legal Operations Analysts'],
        },
    ];

    const settingsBlog = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        centerMode: false,
        centerPadding: '0px',
        focusOnSelect: false,
        autoplay: false,
        autoplaySpeed: 6000,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                },
            },
        ],
    };

    return (
        <Wrapper>
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            {sliderData && sliderData.length > 0 && (
                                <BlogSlider>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {sliderData.map((item, index) => {
                                            return (
                                                <SliderBox key={index}>
                                                    <ContentWrapper>
                                                        <SlideHeader>
                                                            <IconImg
                                                                src={item.icon}
                                                                alt="-"
                                                            />
                                                            <Title>
                                                                {item.title}
                                                            </Title>
                                                        </SlideHeader>
                                                        <SlideContent>
                                                            <ul>
                                                                {item.list &&
                                                                    item.list
                                                                        .length >
                                                                        0 &&
                                                                    item.list.map(
                                                                        (
                                                                            content,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <li
                                                                                    key={
                                                                                        index
                                                                                    }>
                                                                                    <FaChevronRight />
                                                                                    <p>

                                                                                    {
                                                                                        content
                                                                                    }
                                                                                    </p>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                            </ul>
                                                        </SlideContent>
                                                    </ContentWrapper>
                                                </SliderBox>
                                            );
                                        })}
                                    </div>
                                </BlogSlider>
                            )}
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    ${Media('xlscreens')} {
        padding-left: 15vw;
        overflow: hidden;
    }
`;

const BlogSlider = styled.div`
    margin-top: 70px;
    margin-bottom: 30px;
    position: relative;
    .slick-arrow {
        position: absolute;
        left: -11vw;
        top: calc(25% - 18px);
        z-index: 99;
        &.slick-next {
            transform: translateY(50px);
        }
        ${Media('large')} {
            top: calc(25% - 25px);
            &.slick-next {
                transform: translateY(62px);
            }
        }
    }
    ${Media('xlscreens')}{
        margin-top:3.64vw;
        margin-bottom:1.56vw;
    }
    ${Media('tablet')} {
        margin-top: 50px;
        margin-bottom: 50px;
        .slick-arrow {
            left: calc(50% - 50px);
            top: auto;
            bottom: -30px;
            &.slick-next {
                transform: translateX(50px);
            }
        }
        .slick-slide{
            margin-bottom: 50px;
        }
        .slick-track{
            display: flex;
        }
    }
    ${Media('mobile')}{
        .slick-slide{
            width: 100% !important;
        }
    }
`;

const SliderBox = styled.div`
    margin-right: 30px;
    ${Media('xlscreens')}{
        margin-right:1.56vw;
    }
    ${Media('mobile')} {
        padding-right: 0px;
        width: 100%;
        padding:0px 5px;
    }
`;

const ContentWrapper = styled.div`
    background-color: ${colors.socialRep['brandsBG']};
    border-radius: 10px;
    ${Media('xlscreens')}{
        height:24.5vw;
        width:21.6vw;
        border-radius:0.53vw;
        margin-bottom: 1.4vw;
    }
    ${Media('tablet')}{
        width:350px;
        height:300px;
    }
    ${Media('mobile')}{
        width:100%;
    }
`;

const SlideHeader = styled.div`
    height: 30%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colors.gray['border']}26;
    padding: 0px 30px;
    ${Media('xlscreens')}{
        padding:0px 1.56vw;
    }
`;

const IconImg = styled.img`
    color: ${colors.white['100']};
`;

const Title = styled.h5`
    color: ${colors.white['100']};
    font-size: 22px;
    font-family: ${gilroySemibold};
    margin-left: 40px;
    ${Media('xlscreens')}{
        font-size:1.14vw;
        margin-left:2.08vw;
    }
    ${Media('tablet')}{
        font-size:16px;
        margin-left:20px;
    }
`;

const SlideContent = styled.div`
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 30px;
    ul {
        padding-left: 0;
        li {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            p{
                font-size: 18px;
                font-family: ${gilroyMedium};
                color: ${colors.white['100']};
                margin:0;
            }
            svg {
                color: ${colors.pink['100']};
                margin-right: 10px;
            }
        }
    }
    ${Media('xlscreens')}{
        padding:0 1.56vw;
        ul{
            li{
                margin-bottom:0.78vw;
                font-size:0.93vw;
                svg{
                    margin-right:0.53vw;
                }
            }
        }
    }
    ${Media('tablet')}{
        ul{
            li{
                margin-bottom: 10px;
                p{
                    font-size:14px;
                }
            }
        }
    }
`;

export default StaffAugSlider;
