import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { PageHeading, PagePara } from '../common';
import { gilroySemibold, gilroyLight } from 'shared-components/theme/fonts';

const data = [
    {
        title: 'Screen Recorder',
        animate_delay: 0,
        content: [
            '100% screen recording with 3 month retention of all data',
            'Supports voice and non-voice environments',
        ],
    },
    {
        title: 'Blind Monitor',
        animate_delay: 400,
        content: [
            'Monitor agent screen and audio that are underway to ensure quality and catch issues on a real-time basis.',
            'Role based security access',
            'Ongoing calls can be searched on a real time basis using employee or call state information',
            'Users can listen to both audio and view screen during the call',
            'Users can VNC agent screens if they are not on a call',
        ],
    },
    {
        title: 'Key Logger',
        animate_delay: 700,
        content: [
            'Identifies potentially fraudulent activity on the agent desktop',
            'Facilitates monitoring to ensure PII (Personally Identifiable Information) is not improperly captured in other applications or reused',
            'Other Compliance violations – to include but not limited to survey manipulation',
        ],
    },
];

export const ComptechSection = () => {
    return (
        <CompTechWrapper id="compTech">
            <Container>
                <div>
                    <Row>
                        <Col lg="7">
                            <PageHeading noMargin={true}>
                                The Compliance Tech
                            </PageHeading>
                        </Col>
                        <Col lg="5">
                            <PagePara>
                                ibex Witness<sup>TM</sup> is our proprietary
                                automated Fraud <br />
                                software used by our GAU team to actively <br />
                                monitor agent desktops, calls, keystrokes, and
                                <br />
                                digital behaviour in order to prevent fraud and
                                <br />
                                discover & deter compliance infractions.
                            </PagePara>
                            <CompTechNote>
                                *ibex Witness is <b>only</b> used at client
                                request and/or approval
                            </CompTechNote>
                        </Col>
                    </Row>
                </div>
                <Row>
                    {data && data.length > 0
                        ? data.map((item, index) => {
                              return (
                                  <Col lg="4" key={index}>
                                      <div>
                                          <CompTechBox>
                                              <h3>{item.title}</h3>
                                              <ul>
                                                  {item.content &&
                                                  item.content.length > 0
                                                      ? item.content.map(
                                                            (inner, ind) => {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            ind
                                                                        }>
                                                                        {inner}
                                                                    </li>
                                                                );
                                                            }
                                                        )
                                                      : null}
                                              </ul>
                                          </CompTechBox>
                                      </div>
                                  </Col>
                              );
                          })
                        : null}
                </Row>
            </Container>
        </CompTechWrapper>
    );
};

const CompTechWrapper = styled.section`
    p {
        padding-top: 30px;
    }
    ${Media('xlscreens')} {
        padding: 9vw 0 7vw;
        p {
            padding-top: 1.5vw;
        }
    }
`;

const CompTechNote = styled.p`
    font-size: 12px;
    font-family: ${gilroyLight};
    ${Media('xlscreens')} {
        font-size: 0.83vw;
    }
`;

const CompTechBox = styled.div`
    margin-top: 60px;
    h3 {
        font-family: ${gilroySemibold};
        font-size: 24px;
        letter-spacing: -1px;
        padding-left: 10px;
    }
    ul {
        margin: 0px;
        padding: 0px;
        li {
            border: 1px solid ${colors.gray_dark['100']};
            margin-top: -1px;
            font-size: 12px;
            padding: 15px 20px;
            font-family: ${gilroyLight};
        }
    }
    ${Media('xlscreens')} {
        padding-top: 3vw;
        h3 {
            font-size: 1.7vw;
            letter-spacing: -0.06vw;
            margin-bottom: 2vw;
        }
        ul {
            li {
                font-size: 0.88vw;
                padding: 1.2vw 2vw;
            }
        }
    }
`;
