import React from 'react';
import styled from 'styled-components';
import GlobalDelivery from 'components/global-delivery/global-delivery';
import Media from 'shared-components/theme/medias';

export const DeliverySection = () => {
    return (
        <GlobalDeliveryWrapper>
            <GlobalDelivery
                mainTitle="Our Sandbox"
                mainPara={
                    <>
                        We operate globally and behave like a community. Here’s
                        <br />
                        where we innovate and play.
                    </>
                }
            />
        </GlobalDeliveryWrapper>
    );
};
const GlobalDeliveryWrapper = styled.section`
    ${Media('xlscreens')} {
        padding: 7vw 0;
    }
`;
