import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import { baseURL } from 'shared-components/theme/helpers';
import {
    SRMSectionHeading,
    SRMStrategyBox,
} from 'components/social-rep-man/common';

const strategyContent = [
    {
        iconUrl: `${baseURL}assets/images/protect-icon.svg`,
        heading: 'Protect your brand',
        para: `Provide administrators visibility and control over <br/>
        your channels, teams, and workflows to promote <br/>
        a consistent brand voice and governance across <br/>
        social presences.`,
    },
    {
        iconUrl: `${baseURL}assets/images/scale-icon.svg`,
        heading: 'Scale your interactions',
        para: `Finally, all your teams, channels, and <br/>
        content are in one platform. You can now <br/>
        manage real-time  conversations in a <br/>
        single dashboard.`,
    },
    {
        iconUrl: `${baseURL}assets/images/measure-icon.svg`,
        heading: 'Measure what matters',
        para: `Translate your social performance to metrics <br/>
        that matter to your business with dashboards <br/>
        and data exports that connect your results <br/>
        back to business value.`,
    },
    {
        iconUrl: `${baseURL}assets/images/campaign-ad-icon.svg`,
        heading: `Unify paid & organic social <br/>campaign management`,
        para: `Visibility for Facebook,  Instagram, and <br/>
        LinkedIn social feedback to see what’s <br/>
        working across all channels.`,
    },
];

export const StrategySection = () => {
    return (
        <Wrapper id="strategy">
            <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce={true}
                delay={100}>
                <SRMSectionHeading>
                    Maximize the reach, influence, and impact of <br />
                    your social CX strategy
                </SRMSectionHeading>
            </ScrollAnimation>
            {strategyContent && strategyContent.length > 0 && (
                <Container>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOnce={true}
                        delay={100}>
                        <StrategyRow>
                            {strategyContent.map((item, index) => {
                                return (
                                    <Col lg="6" key={index}>
                                        <SRMStrategyBox
                                            icon={item.iconUrl}
                                            heading={item.heading}
                                            para={item.para}
                                        />
                                    </Col>
                                );
                            })}
                        </StrategyRow>
                    </ScrollAnimation>
                </Container>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.section``;

const StrategyRow = styled(Row)`
    ${Media('tablet')} {
        padding: 0px 20px;
        margin-top: 50px;
    }
`;
