import React, { useRef } from 'react';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { baseURL, baseURLPage } from 'shared-components/theme/helpers';
import styled from 'styled-components';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import { gilroyBold, gilroyExtrabold } from 'shared-components/theme/fonts';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Button from 'shared-components/button/button';
import ScrollAnimation from 'react-animate-on-scroll';
import colors from 'shared-components/theme/colors';
import { gilroySemibold } from 'shared-components/theme/fonts';
import {
    BottomSection,
    Para,
    WaveXLandingIntro,
} from 'components/technology/wavex';
import useOnScreen from '../../resources/ebooks/viewport-observer';
import ReactHtmlParser from 'react-html-parser';
import { RowContentCenter } from 'shared-components/theme/grid-helpers';

const wavexPages = [
    {
        icon: `${baseURL}assets/images/tech/performance-acceleration.svg`,
        title: 'Agent Performance',
        pagelink: `/technology/wavex/cx-performance-acceleration`,
        animationdelay: 100,
        para: 'Ensure predictable CX performance outcomes from your contact center team.',
        list: [
            {
                icon: `${baseURL}assets/images/tech/list1-icon-1.svg`,
                title: 'Training Simulation',
                pageLink: `${baseURLPage}technology/training-simulator`,
            },
            {
                icon: `${baseURL}assets/images/tech/list1-icon-2.svg`,
                title: 'Performance Gamification',
                pageLink: `${baseURLPage}technology/scorecard`,
            },
            {
                icon: `${baseURL}assets/images/tech/list1-icon-3.svg`,
                title: 'Performance Coaching',
                pageLink: `${baseURLPage}technology/inspire`,
            },
        ],
    },
    {
        icon: `${baseURL}assets/images/tech/interaction-management.svg`,
        title: 'Contact Center Management',
        pagelink: '/technology/wavex/cx-interaction-management',
        animationdelay: 200,
        para: 'Monitor and manage every interaction, for complete CX transparency & oversight.',
        list: [
            {
                icon: `${baseURL}assets/images/tech/list2-icon-1.svg`,
                title: 'Virtual Floor Management System',
                pageLink: `${baseURLPage}technology/virtual-floor-management-system`,
            },
            {
                icon: `${baseURL}assets/images/tech/list2-icon-2.svg`,
                title: 'Live Agent Desktop Video Monitoring',
                pageLink: `${baseURLPage}technology/round`,
            },
            {
                icon: `${baseURL}assets/images/tech/list2-icon-3.svg`,
                title: 'Fraud Prevention & Agent Monitoring',
                pageLink: `${baseURLPage}technology/witness`,
            },
            {
                icon: `${baseURL}assets/images/tech/list2-icon-4.svg`,
                title: 'Live Agent Disposition Monitoring',
                pageLink: `${baseURLPage}technology/heatmap`,
            },
        ],
    },
    {
        icon: `${baseURL}assets/images/tech/cx-engagement.svg`,
        title: 'Omnichannel CX Engagement',
        pagelink: '/technology/wavex/omnichannel-cx-engagement',
        animationdelay: 300,
        para: 'Elite survey & social media reputation management technology.',
        list: [
            {
                icon: `${baseURL}assets/images/tech/list3-icon-1.svg`,
                title: 'Social Reputation Management',
                pageLink: `${baseURLPage}digital/social-reputation-management`,
            },
            {
                icon: `${baseURL}assets/images/tech/list3-icon-2.svg`,
                title: 'Survey & Feedback Management',
                pageLink: `${baseURLPage}technology/reflecx`,
            },
        ],
    },
];

const ecoSystemFeat = [
    {
        count: '200+',
        title: 'IT Professionals',
        colLg: '4',
        animationdelay: 100,
    },
    {
        count: '16+',
        title: 'Platforms Supported',
        colLg: '4',
        animationdelay: 200,
    },
    {
        count: '50+',
        title: 'Solutions Developed',
        colLg: '4',
        animationdelay: 300,
    },
    {
        count: '16',
        title: 'Year legacy of innovative technology delivery',
        colLg: '5',
        animationdelay: 400,
    },
    {
        count: '15',
        title: 'Technology Partnerships',
        colLg: '5',
        animationdelay: 500,
    },
];

const brands = [
    `${baseURL}assets/images/tech/hire-iq-logo.svg`,
    `${baseURL}assets/images/tech/techrecruit.svg`,
    `${baseURL}assets/images/tech/twilio-logo.svg`,
    `${baseURL}assets/images/tech/ui-path-logo.svg`,
    `${baseURL}assets/images/tech/guru-white-logo.svg`,
    `${baseURL}assets/images/tech/help-juice.svg`,
    `${baseURL}assets/images/tech/genesys-logo.svg`,
    `${baseURL}assets/images/tech/google-logo.svg`,
    `${baseURL}assets/images/tech/zendesk_logo.svg`,
    `${baseURL}assets/images/tech/salesforce-logo.svg`,
    `${baseURL}assets/images/tech/aws-logo.svg`,
    `${baseURL}assets/images/tech/azure-logo.svg`,
    `${baseURL}assets/images/tech/airkit.svg`,
    `${baseURL}assets/images/tech/Krisp.svg`,
];

const WaveX = () => {
    const featVid1 = useRef(null);
    const featVid2 = useRef(null);
    const featVid3 = useRef(null);
    const featVid4 = useRef(null);
    const circleAnimation = useRef(null);

    const isVisible1 = useOnScreen(featVid1, '-30%');
    const isVisible2 = useOnScreen(featVid2, '-30%');
    const isVisible3 = useOnScreen(featVid3, '-30%');
    const isVisible4 = useOnScreen(featVid4, '-30%');

    const isVisibleCircle = useOnScreen(circleAnimation, '-50%');

    const featVideos = [
        {
            videoUrl: `${baseURL}assets/videos/wavex/girl-texting.mp4`,
            title: 'Boost Agent Performance, Fast.',
            para: `With industry-leading onboarding, training, and performance gamification tools, the Wave X platform is purpose-built to achieve dramatic gains in speed to proficiency and speed to performance.`,
            ref: featVid1,
            isVisible: isVisible1,
        },
        {
            videoUrl: `${baseURL}assets/videos/wavex/award-recognition.mp4`,
            title: 'Build Scalable & Predictable <br/>Contact Center Performance.',
            para: `The Wave X platform provides contact center leaders with tools to surgically monitor, control, and enhance all aspects of contact center performance, ensuring consistent outcomes regardless of channel type or volume demand.`,
            ref: featVid2,
            isVisible: isVisible2,
        },
        {
            videoUrl: `${baseURL}assets/videos/wavex/girl-showing-statistics.mp4`,
            title: 'Ensure Transparency & Data Security.',
            para: `Client trust depends on an abundance of vendor transparency and extreme data security. Wave X is designed to actively monitor fraud, maintain systems security across a dynamic global infrastructure, and diligently protect client and customer trust.`,
            ref: featVid3,
            isVisible: isVisible3,
        },
        {
            videoUrl: `${baseURL}assets/videos/wavex/girl-using-laptop.mp4`,
            title: 'Protect Your Brand Reputation.',
            para: `Featuring social listening and enterprise-grade feedback measurement and analytics technologies, Wave X allows brands to act on real-time omnichannel customer sentiment, and feed these insights back into the program.`,
            ref: featVid4,
            isVisible: isVisible4,
        },
    ];

    return (
        <Layout page="wavex" bgColor={colors.waveX['pageBgColor']}>
            <Breadcrumbs>
                <li>Technology</li>
                <li>Wave X</li>
            </Breadcrumbs>
            <WaveXLandingIntro
                pageTag="Wave X"
                pageHeading="The CX <br/>Performance <br/>Platform"
                pagePara="Wave X is a technology platform designed to improve the agent employee experience, contact center performance, and CX outcomes."
                introImg={`${baseURL}assets/images/tech/wavex-bg-img.png`}
                gradientImg={`${baseURL}assets/images/tech/wavex-logo.png`}
                from={{
                    transform: 'translateY(1.5vw) translateX(10vw)',
                    height: '33vw',
                    width: '30vw',
                    backgroundImage: `linear-gradient(to bottom, ${colors.pink['100']} -10%, ${colors.white['100']} 100%)`,
                    clipPath:
                        'polygon(30% 0%, 0% 0%, 30% 50%, 0% 100%, 30% 100%, 50% 70%, 70% 100%, 100% 100%, 70% 50%, 100% 0%, 70% 0%, 50% 30%)',
                }}
                to={{
                    transform: 'translateY(25vw) translateX(0vw)',
                    height: '33.38vw',
                    width: '59.47vw',
                    backgroundImage: `linear-gradient(to bottom, #1E0E2F 0%, #1E0E2F 0%)`,
                    clipPath:
                        'polygon(30% 0%, 0% 0%, 0% 50%, 0% 100%, 30% 100%, 50% 100%, 70% 100%, 100% 100%, 100% 50%, 100% 0%, 70% 0%, 50% 0%)',
                }}
            />
            <UpgradeCx>
                <Container>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <TagText>WAVE X</TagText>
                        <PageSubHeading bigger>
                            Empowered CX Agents Create <br />
                            Predictable CX Outcomes
                        </PageSubHeading>
                        <Para>
                            Successful CX is measured in predictable, consistent
                            outperformance.
                        </Para>
                    </ScrollAnimation>
                    <FeatureVideos>
                        <FeatVideoWrapper>
                            <Row>
                                {featVideos &&
                                    featVideos.length > 0 &&
                                    featVideos.map((video, index) => {
                                        return (
                                            <Col lg="6" key={index}>
                                                <FeatVideo ref={video.ref}>
                                                    <VideoWrap
                                                        preload="auto"
                                                        loop
                                                        autoPlay
                                                        muted
                                                        isVisible={
                                                            video.isVisible
                                                        }
                                                        sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
                                                        <source
                                                            src={video.videoUrl}
                                                            type={`video/mp4`}
                                                        />
                                                    </VideoWrap>
                                                    <ScrollAnimation
                                                        animateIn="fadeInUp"
                                                        delay={100}>
                                                        <FeatTitle>
                                                            {ReactHtmlParser(
                                                                video.title
                                                            )}
                                                        </FeatTitle>
                                                        <FeatPara>
                                                            {ReactHtmlParser(
                                                                video.para
                                                            )}
                                                        </FeatPara>
                                                    </ScrollAnimation>
                                                </FeatVideo>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </FeatVideoWrapper>
                    </FeatureVideos>
                </Container>
            </UpgradeCx>

            <BetterCXContent>
                <Container>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <TagText>WAVE X</TagText>
                        <PageSubHeading>Your OS for better CX</PageSubHeading>
                        <Para fontFamily={gilroySemibold}>
                            Wave X delivers a complete CX performance toolset,{' '}
                            <br />
                            designed for the contact center, by the contact
                            center.
                        </Para>
                    </ScrollAnimation>
                </Container>
                <WaveXPages fluid>
                    <Row>
                        {wavexPages &&
                            wavexPages.length > 0 &&
                            wavexPages.map((page, index) => {
                                return (
                                    <WaveXPage lg="4" key={index}>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            delay={page.animationdelay}
                                            animateOnce={false}>
                                            <WaveXPageLink href={page.pagelink}>
                                                <img
                                                    src={page.icon}
                                                    className="crd-image"
                                                />
                                                <h3>{page.title}</h3>
                                            </WaveXPageLink>

                                            <p>{page.para}</p>
                                            <List>
                                                {page.list &&
                                                    page.list.length > 0 &&
                                                    page.list.map(
                                                        (listItem, index) => {
                                                            return (
                                                                <ListItem
                                                                    key={index}>
                                                                    <a
                                                                        href={
                                                                            listItem.pageLink
                                                                        }>
                                                                        <img
                                                                            src={
                                                                                listItem.icon
                                                                            }
                                                                            alt="-"
                                                                        />
                                                                        <p>
                                                                            {
                                                                                listItem.title
                                                                            }
                                                                            <span>
                                                                                {
                                                                                    listItem.desc
                                                                                }
                                                                            </span>
                                                                        </p>
                                                                    </a>
                                                                </ListItem>
                                                            );
                                                        }
                                                    )}
                                            </List>
                                        </ScrollAnimation>
                                    </WaveXPage>
                                );
                            })}
                    </Row>
                    <WaveXHelpsIbex>
                        <img
                            src={`${baseURL}assets/images/tech/star.svg`}
                            alt="-"
                        />
                        <h3>
                            Wave X helps ibex achieve a World-Class eNPS of 71
                        </h3>
                    </WaveXHelpsIbex>
                </WaveXPages>
            </BetterCXContent>

            <WaveXEcosystemSection>
                <Container>
                    <Row>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <PageSubHeading textColor>
                                    An Integrated Technology Ecosystem <br />
                                    For Agent Empowerment
                                </PageSubHeading>
                                <Para
                                    fontFamily={gilroySemibold}
                                    color={'#fff'}>
                                    Third Party Technology Integrations &
                                    Partnerships For Agent Performance.
                                </Para>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <CirclePathRow>
                            <CirclePath
                                src={`${baseURL}assets/images/tech/circle-path.svg`}
                            />
                        </CirclePathRow>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <RowContentCenter noGutters>
                            <TableBox lg="3">
                                <TableBoxContent>
                                    <LogoImage
                                        src={brands[0]}
                                        imgHeight={52}
                                        imgWidth={179}
                                    />
                                </TableBoxContent>
                            </TableBox>

                            <TableBox lg="3">
                                <TableBoxContent>
                                    <LogoImage
                                        src={brands[4]}
                                        imgHeight={44}
                                        imgWidth={194}
                                    />
                                </TableBoxContent>
                            </TableBox>

                            <TableBox lg="3">
                                <TableBoxContent>
                                    <LogoImage
                                        src={brands[8]}
                                        imgHeight={32}
                                        imgWidth={172}
                                    />
                                </TableBoxContent>
                            </TableBox>
                        </RowContentCenter>

                        <RowContentCenter>
                            <TableBox lg="3">
                                <TableBoxContent>
                                    <LogoImage
                                        src={brands[1]}
                                        imgHeight={59}
                                        imgWidth={185}
                                    />
                                </TableBoxContent>
                            </TableBox>
                            <TableBox lg="3">
                                <TableBoxContent>
                                    <LogoImage
                                        src={brands[5]}
                                        imgHeight={43}
                                        imgWidth={216}
                                    />
                                </TableBoxContent>
                            </TableBox>
                            <TableBox lg="3">
                                <TableBoxContent>
                                    <LogoImage
                                        src={brands[9]}
                                        imgHeight={70}
                                        imgWidth={100}
                                    />
                                </TableBoxContent>
                            </TableBox>
                        </RowContentCenter>
                        <RowContentCenter>
                            <TableBox lg="3">
                                <TableBoxContent>
                                    <LogoImage
                                        src={brands[2]}
                                        imgHeight={49}
                                        imgWidth={165}
                                    />
                                </TableBoxContent>
                            </TableBox>
                            <TableBox lg="3">
                                <TableBoxContent>
                                    <LogoImage
                                        src={brands[6]}
                                        imgHeight={35}
                                        imgWidth={176}
                                    />
                                </TableBoxContent>
                            </TableBox>
                            <TableBox lg="3">
                                <TableBoxContent>
                                    <LogoImage
                                        src={brands[10]}
                                        imgHeight={51}
                                        imgWidth={85}
                                    />
                                </TableBoxContent>
                            </TableBox>
                        </RowContentCenter>
                        <RowContentCenter>
                            <TableBox lg="3">
                                <TableBoxContent>
                                    <LogoImage
                                        src={brands[3]}
                                        imgHeight={55}
                                        imgWidth={162}
                                    />
                                </TableBoxContent>
                            </TableBox>
                            <TableBox lg="3">
                                <TableBoxContent>
                                    <LogoImage
                                        src={brands[7]}
                                        imgHeight={50}
                                        imgWidth={150}
                                    />
                                </TableBoxContent>
                            </TableBox>
                            <TableBox lg="3">
                                <TableBoxContent>
                                    <LogoImage
                                        src={brands[11]}
                                        imgHeight={44}
                                        imgWidth={155}
                                    />
                                </TableBoxContent>
                            </TableBox>
                        </RowContentCenter>
                        <RowContentCenter>
                            <TableBox lg="3">
                                <TableBoxContent>
                                    <LogoImage
                                        src={brands[12]}
                                        imgHeight={55}
                                        imgWidth={162}
                                    />
                                </TableBoxContent>
                            </TableBox>
                            <TableBox lg="3">
                                <TableBoxContent>
                                    <LogoImage
                                        src={brands[13]}
                                        imgHeight={50}
                                        imgWidth={150}
                                    />
                                </TableBoxContent>
                            </TableBox>
                        </RowContentCenter>
                    </ScrollAnimation>
                </Container>
            </WaveXEcosystemSection>

            <WaveXEcosystem>
                <EcosystemInner>
                    <Row noGutters>
                        <LeftLightSection lg="7">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <Tag>Connected Performance</Tag>
                                <PageSubHeading2>
                                    The Wave X Ecosystem
                                </PageSubHeading2>
                                <Para align="left">
                                    Using purpose-built technology paired with
                                    advanced partner technologies, ibex can
                                    deliver sophisticated CX performance
                                    technologies for its clients.
                                </Para>
                                <Separator />
                            </ScrollAnimation>
                            <Row>
                                {ecoSystemFeat &&
                                    ecoSystemFeat.length > 0 &&
                                    ecoSystemFeat.map((feat, index) => {
                                        return (
                                            <EcoSystemFeat
                                                lg={feat.colLg}
                                                md={4}
                                                key={index}>
                                                <ScrollAnimation
                                                    animateIn="fadeInUp"
                                                    delay={feat.animationdelay}
                                                    animateOnce={false}>
                                                    <h2>{feat.count}</h2>
                                                    <Para
                                                        align="left"
                                                        color={
                                                            colors.black['50']
                                                        }>
                                                        {feat.title}
                                                    </Para>
                                                </ScrollAnimation>
                                            </EcoSystemFeat>
                                        );
                                    })}
                            </Row>
                        </LeftLightSection>
                        <RightDarkSection lg="5">
                            <TechPlatFormBtn>
                                Technology platforms
                            </TechPlatFormBtn>
                            <Row>
                                {brands &&
                                    brands.length > 0 &&
                                    brands.map((brand, index) => {
                                        return (
                                            <Brand
                                                key={index}
                                                lg="6"
                                                md={6}
                                                xs={6}>
                                                <ScrollAnimation
                                                    animateIn="fadeInUp"
                                                    animateOnce={false}>
                                                    <img src={brand} alt="-" />
                                                </ScrollAnimation>
                                            </Brand>
                                        );
                                    })}
                            </Row>
                        </RightDarkSection>
                    </Row>
                </EcosystemInner>
            </WaveXEcosystem>

            <PerfomanceLifeCycle>
                <Container>
                    <Row>
                        <Col lg="12">
                            <h3>The Wave X Performance Lifecycle</h3>
                        </Col>
                        <Col lg="12">
                            <CircleXBox ref={circleAnimation}>
                                <XcircleImage
                                    src={`${baseURL}assets/images/tech/performance-x-img.png`}
                                />

                                <CircleList animateIcons={isVisibleCircle}>
                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/tech/recruit-icon.svg`}
                                            alt=""
                                        />
                                        <span>Recruit</span>
                                    </li>
                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/tech/onboard-icon.svg`}
                                            alt=""
                                        />
                                        <span>Onboard</span>
                                    </li>

                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/tech/train-icon.svg`}
                                            alt=""
                                        />
                                        <span>Train</span>
                                    </li>
                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/tech/monitor-icon.svg`}
                                            alt=""
                                        />
                                        <span>Monitor</span>
                                    </li>
                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/tech/coach-icon.svg`}
                                            alt=""
                                        />
                                        <span>Coach</span>
                                    </li>
                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/tech/incentivize-icon.svg`}
                                            alt=""
                                        />
                                        <span>Incentivize</span>
                                    </li>

                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/tech/scale-icon.svg`}
                                            alt=""
                                        />
                                        <span>Scale</span>
                                    </li>

                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/tech/succeed-icon.svg`}
                                            alt=""
                                        />
                                        <span>Succeed</span>
                                    </li>
                                </CircleList>
                            </CircleXBox>
                        </Col>
                    </Row>
                </Container>
            </PerfomanceLifeCycle>

            <PerformanceManagementSection>
                <Container>
                    <Row>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <PageSubHeading>
                                    Performance Management <br />& Business
                                    Insights
                                </PageSubHeading>
                                <Para fontFamily={gilroySemibold}>
                                    End-to-End interaction insights and CX
                                    evolution.
                                </Para>
                            </ScrollAnimation>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="5">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <PfImage
                                    src={`${baseURL}assets/images/tech/performance-mangement-img.png`}
                                />
                            </ScrollAnimation>
                        </Col>

                        <Col lg="7">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <ul>
                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/tech/aha-icon.svg`}
                                            alt=""
                                        />
                                        Automated Hire Analytics
                                    </li>

                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/tech/cfa-icon.svg`}
                                            alt=""
                                        />
                                        Customer Friction Analysis
                                    </li>

                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/tech/cjm-icon.svg`}
                                            alt=""
                                        />
                                        Customer Journey Mapping
                                    </li>

                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/tech/cd-icon.svg`}
                                            alt=""
                                        />
                                        Call Drivers
                                    </li>

                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/tech/kda-icon.svg`}
                                            alt=""
                                        />
                                        Key Driver Analytics
                                    </li>

                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/tech/vsm-icon.svg`}
                                            alt=""
                                        />
                                        Value Stream Mapping
                                    </li>

                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/tech/eda-icon.svg`}
                                            alt=""
                                        />
                                        Escalation Driver Analysis
                                    </li>

                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/tech/ca-icon.svg`}
                                            alt=""
                                        />
                                        Churn Analysis
                                    </li>
                                </ul>

                                <BuildCtxBtn>
                                    <Button
                                        href="https://www.ibex.co/business-intelligence"
                                        round={true}>
                                        Discover More
                                    </Button>
                                </BuildCtxBtn>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </PerformanceManagementSection>

            <BottomSection heading="Ready to Upgrade Your CX?" />
        </Layout>
    );
};

const BuildCtxBtn = styled.div`
    margin-left: 30px;
    margin-top: 50px;
    a {
        background-color: ${colors.pink['100']};

        &:hover {
            color: ${colors.pink['100']};
        }
    }
    ${Media('xlscreens')} {
        margin-top: 2.5vw;
    }
    ${Media('mobile')} {
        margin-left: 0px;
    }
`;
const PerformanceManagementSection = styled.section`
    background-color: ${colors.waveX['lightGreyBg']};
    ul {
        margin-top: 200px;
        display: flex;
        flex-wrap: wrap;
        li {
            display: flex;
            align-items: center;
            font-family: ${gilroySemibold};
            font-size: 20px;
            margin: 10px 0;
            width: 50%;
            img {
                margin-right: 10px;
                height: 30px;
                width: 30px;
                object-fit: contain;
            }
        }
    }
    ${Media('xlscreens')} {
        ul {
            margin-top: 8vw;
            li {
                font-size: 1vw;
                margin: 0.63vw 0;
                img {
                    height: 1.57vw;
                    width: 1.57vw;
                }
            }
        }
    }
    ${Media('mobile')} {
        ul {
            padding: 0px;
            margin-top: 50px;
            li {
                width: 100%;
            }
        }
    }
`;

const PfImage = styled.img`
    width: 100%;
`;
const PerfomanceLifeCycle = styled.section`
    background-color: ${colors.waveX['pageBgColor2']};
    h3 {
        text-align: center;
        margin-bottom: 50px;
        font-size: 32px;
        font-family: ${gilroyBold};
        text-align: center;
        line-height: 1.32;
        font-size: ${(props) => (props.bigger ? '80px' : '65px')};
        ${Media('xlscreens')} {
            font-size: ${(props) => (props.bigger ? '4.16vw' : '3.38vw')};
            margin-bottom: 4.5vw;
        }
        ${Media('tablet')} {
            font-size: ${(props) => (props.bigger ? '40px' : '30px')};
            br {
                display: none;
            }
        }
        ${Media('mobile')} {
            font-size: ${(props) => (props.bigger ? '36px' : '26px')};
            padding: 20px 0;
        }
    }
    ${Media('xlscreens')} {
        padding-top: 3vw;
    }
    ${Media('mobile')} {
        padding-bottom: 0;
        display: none;
    }
`;

const CircleXBox = styled.div`
    width: 730px;
    height: 730px;
    background-color: ${colors.white['100']};
    border-radius: 50%;
    border: 2px dashed ${colors.waveX.pageBgColor2};
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    div {
        height: inherit;
    }
    ${Media('xlscreens')} {
        width: 38.25vw;
        height: 38.25vw;
    }
    ${Media('tablet')} {
        border-radius: 0%;
        width: 100%;
        height: auto;
        padding: 30px;
    }
    ${Media('mobile')} {
        border-radius: 0%;
        width: 100%;
        height: auto;
    }
`;

const XcircleImage = styled.img`
    height: 60%;
    ${Media('tablet')} {
        display: none;
    }
`;

const CircleList = styled.ul`
    padding: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    ${Media('tablet')} {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    ${Media('mobile')} {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    li {
        text-align: center;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease-in;
        ${Media('tablet')} {
            position: relative;
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            margin: 15px 0px 20px;
            flex: 0 0 25%;
        }
        ${Media('mobile')} {
            position: static;
            flex: 0 0 33%;
            margin: 15px 0px;
            transition: none;
            opacity: 1;
            visibility: visible;
        }
        img {
            margin: 0 auto;
            height: 63px;
            width: 63px;
            ${Media('xlscreens')} {
                height: 3.28vw;
                width: 3.28vw;
            }
        }
        span {
            font-family: ${gilroyExtrabold};
            font-size: 29px;
            ${Media('xlscreens')} {
                font-size: 1vw;
            }
            ${Media('tablet')} {
                font-size: 16px;
            }
            ${Media('mobile')} {
                font-size: 16px;
            }
        }
        &:first-child {
            top: -50px;
            ${Media('xlscreens')} {
                top: -8%;
                left: 45%;
            }
        }

        &:nth-child(2) {
            right: -278px;
            top: -63px;
            transition-delay: 0.2s;
            ${Media('xlscreens')} {
                right: 6%;
                top: 10%;
            }
        }

        &:nth-child(3) {
            left: -286px;
            top: -769px;
            transition-delay: 0.3s;
            ${Media('xlscreens')} {
                right: -140%;
                top: 42.5%;
            }
        }

        &:nth-child(4) {
            left: -286px;
            top: -769px;
            transition-delay: 0.4s;
            ${Media('xlscreens')} {
                right: -116%;
                top: 78%;
            }
        }

        &:nth-child(5) {
            transition-delay: 0.5s;
            ${Media('xlscreens')} {
                right: 46%;
                bottom: -6%;
            }
        }

        &:nth-child(6) {
            transition-delay: 0.6s;
            ${Media('xlscreens')} {
                left: 3%;
                bottom: 11%;
            }
        }

        &:nth-child(7) {
            transition-delay: 0.7s;
            ${Media('xlscreens')} {
                left: -5%;
                bottom: 45%;
            }
        }

        &:nth-child(8) {
            transition-delay: 0.8s;
            ${Media('xlscreens')} {
                left: 5%;
                top: 10%;
            }
        }
    }
    ${(props) => {
        if (props.animateIcons) {
            return `
                li{
                    opacity:1;
                    visibility:visible;
                }
            `;
        }
    }}
`;
const WaveXEcosystemSection = styled.section`
    background-color: ${colors.technologyDropdown['dark']};
    ${Media('mobile')} {
        padding-bottom: 20px;
    }
    ${Media('xlscreens')} {
        padding-bottom: 5.8vw;
    }
`;

const TableBox = styled(Col)``;

const TableBoxContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${Media('xlscreens')} {
        padding: 0vw 1.5vw;
    }
    ${Media('mobile')} {
        text-align: center;
        margin-bottom: 50px;
    }
`;

const LogoImage = styled.img`
    height: ${(props) => props.imgHeight && props.imgHeight + 'px'};
    width: ${(props) => props.imgWidth && props.imgWidth + 'px'};

    ${Media('xlscreens')} {
        height: ${(props) => props.imgHeight && props.imgHeight / 19.2 + 'vw'};
        width: ${(props) => props.imgWidth && props.imgWidth / 19.2 + 'vw'};
        margin: 1.2vw 0;
    }
    ${Media('mobile')} {
        height: auto;
        width: auto;
        max-width: 150px;
        margin: 30px auto;
    }
`;

const CirclePathRow = styled(Row)`
    position: relative;
    height: 10px;
    overflow: hidden;
    text-align: center;
    margin-top: 50px;
    img {
        display: none;
    }
    ${Media('xlscreens')} {
        padding: 0 10vw;
        margin-top: 2vw;
    }
    ${Media('mobile')} {
        padding: 0;
        margin-top: 0;
    }
`;

const CirclePath = styled.img`
    position: absolute;
    top: 20px;
    margin: 0 auto;
    right: 0;
    left: -60px;
    ${Media('xlscreens')} {
        left: -3.125vw;
    }
`;
const WaveXPages = styled(Container)`
    ${Media('xlscreens')} {
        padding: 0 10vw;
    }
`;
const WaveXPageLink = styled.a`
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    img {
        margin: 0 auto;
    }
`;
const WaveXPage = styled(Col)`
    img {
        max-width: 406px;
        &.crd-image {
            min-height: 250px;
        }
        &:hover {
            & + h3 {
                color: ${colors.pink['100']};
            }
        }
    }
    h3 {
        font-size: 25px;
        font-family: ${gilroyBold};
        margin-top: 30px;
        transition: all 0.3s ease-in;
        &:hover {
            color: ${colors.pink['100']};
        }
    }
    p {
        font-size: 20px;
        font-family: ${gilroyMedium};
        line-height: 1.55;
        text-align: left;
    }
    a {
        font-size: 23px;
        font-family: ${gilroyBold};
        color: ${colors.pink['100']};
        display: flex;
        align-items: center;
        svg {
            margin-left: 20px;
            font-size: 24px;
        }
        &:hover {
            svg {
                animation: right 2s infinite;
            }
        }
    }
    ${Media('xlscreens')} {
        padding: 0vw 2.5vw !important;
        img {
            max-width: 21.14vw;
        }
        a {
            font-size: 1.19vw;
            margin: 1.56vw 0;
            svg {
                margin-left: 1.04vw;
                font-size: 1.25vw;
            }
        }
        h3 {
            font-size: 1.4vw;
            margin-top: 2.5vw;
        }
        p {
            font-size: 1.02vw;
            margin-bottom: 2.08vw;
        }
    }

    ${Media('tablet')} {
        margin-bottom: 50px;
        .animated {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            img {
                max-width: 250px;
            }
            p {
                padding: 0px 20px;
                font-size: 18px;
            }
            a {
                font-size: 20px;
            }
        }
    }
    ${Media('mobile')} {
        margin-bottom: 30px;
        .animated {
            img {
                max-width: 200px;
            }
            h3 {
                font-size: 22px;
            }
            p {
                font-size: 14px;
                padding: 0px 10px;
            }
            a {
                font-size: 16px;
            }
        }
    }
    @keyframes right {
        0% {
            transform: translate(0);
        }
        20% {
            transform: translateX(10px);
        }
        40% {
            transform: translate(0);
        }
        60% {
            transform: translateX(10px);
        }
        100% {
            transform: translate(0);
        }
    }
`;
const List = styled.ul`
    padding-left: 0;
    ${Media('tablet')} {
        margin-top: 30px;
    }
    ${Media('mobile')} {
        width: 100%;
    }
`;
const ListItem = styled.li`
    display: flex;
    align-items: center;
    background-color: ${colors.white['100']};
    margin-bottom: 15px;
    border: 1px solid;
    position: relative;
    cursor: pointer;
    display: flex;
    p {
        margin: 0;
        font-size: 18px;
        font-family: ${gilroySemibold};
        span {
            display: block;
            font-size: 16px;
            font-family: ${gilroyMedium};
            line-height: 1.31;
        }
    }
    a {
        padding: 10px 30px;
        margin: 0;
        font-size: inherit;
        color: inherit;
        width: 100%;
        &:hover {
            p {
                color: ${colors.pink['100']};
            }
        }
    }
    img {
        max-width: 40px;
        max-height: 33px;
        margin-right: 20px;
    }
    &:after {
        position: absolute;
        top: 9px;
        left: 9px;
        background-color: ${colors.waveX['lightGreyBg']};
        border: 1px solid;
        content: '';
        height: 100%;
        width: 100%;
        z-index: -1;
    }
    ${Media('xlscreens')} {
        margin-bottom: 0.78vw;
        p {
            font-size: 0.8vw;
            span {
                font-size: 0.83vw;
            }
        }
        a {
            padding: 0.53vw 1.3vw;
        }
        img {
            max-width: 2.08vw;
            max-height: 1.71vw;
            margin-right: 1.04vw;
        }
        &:after {
            top: 0.46vw;
            left: 0.46vw;
        }
    }
    ${Media('tablet')} {
        a {
            p {
                text-align: left;
                font-size: 18px !important;
                span {
                    font-size: 14px;
                }
            }
        }
    }
    ${Media('mobile')} {
        width: 100%;
        a {
            padding: 10px 20px;
            text-align: left;
            p {
                font-size: 14px !important;
                span {
                    font-size: 12px;
                }
            }
        }
        img {
            max-width: 30px;
            max-height: 23px;
            margin-right: 15px;
        }
    }
`;
const UpgradeCx = styled.div`
    ${Media('xlscreens')} {
        margin-bottom: 7.8vw;
        margin-top: -10.5vw;
    }
    ${Media('tablet')} {
        margin-bottom: 70px;
    }
`;
const TagText = styled.div`
    color: ${colors.pink['100']};
    text-align: center;
    font-family: ${gilroyBold};
    letter-spacing: 4px;
    ${Media('xlscreens')} {
        font-size: 1.56vw;
        letter-spacing: 0.2vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
    ${Media('mobile')} {
        font-size: 16px;
    }
`;
const PageSubHeading = styled.h2`
    font-size: ${(props) => (props.bigger ? '80px' : '65px')};
    font-family: ${gilroyBold};
    text-align: center;
    line-height: 1.32;
    color: ${(props) => (props.textColor ? '#fff' : '#1D1D1F')};
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.bigger ? '4.16vw' : '3.38vw')};
    }
    ${Media('tablet')} {
        font-size: ${(props) => (props.bigger ? '40px' : '30px')};
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 26px;
        line-height: 1.2;
    }
`;

const WaveXHelpsIbex = styled.div`
    text-transform: uppercase;
    margin-top: 80px;
    padding: 40px 0;
    text-align: center;
    border-top: 1px solid ${colors.black['20']};
    border-bottom: 1px solid ${colors.black['20']};
    position: relative;
    h3 {
        font-size: 32px;
        font-family: ${gilroyExtrabold};
    }

    img {
        display: inline;
        position: absolute;
        top: -21px;
        height: 40px;
        width: 40px;
        right: 0;
        left: 0;
        margin: 0 auto;
    }
    ${Media('xlscreens')} {
        margin-top: 7.8vw;
        padding: 2.08vw 0 1.6vw;
        h3 {
            font-size: 1.66vw;
        }
        img {
            height: 3.54vw;
            width: 3.54vw;
            top: -1.82vw;
        }
    }

    ${Media('tablet')} {
        h3 {
            font-size: 22px;
        }
        img {
            left: calc(50% - 20px);
            right: auto;
        }
    }
    ${Media('mobile')} {
        margin-top: 50px;
        padding: 20px 0;
        h3 {
            font-size: 18px;
        }
    }
`;

const BetterCXContent = styled.section`
    background-color: ${colors.waveX['pageBgColor2']};
    padding-bottom: 0;
    ${Media('xlscreens')} {
        height: 100%;
        width: 100%;
        text-align: center;
    }
`;
const WaveXEcosystem = styled.div`
    margin-bottom: 70px;
    display: none;
    ${Media('xlscreens')} {
        padding: 0 7vw;
        margin-bottom: 3.64vw;
    }
    ${Media('tablet')} {
        padding: 0px 20px;
    }
    ${Media('mobile')} {
        padding: 0px 10px;
    }
`;
const EcosystemInner = styled.div`
    background-color: ${colors.socialRep['brandsBG']};
    width: 100%;
    padding: 20px;
    border-radius: 35px;
    box-sizing: border-box;
    ${Media('xlscreens')} {
        padding: 1.04vw;
        border-radius: 1.82vw;
    }
    ${Media('mobile')} {
        padding: 10px;
    }
`;
const LeftLightSection = styled(Col)`
    background-color: ${colors.waveX['lightGreyBg']};
    border-radius: 35px;
    ${Media('xlscreens')} {
        border-radius: 1.82vw;
        padding: 2.08vw 4.16vw !important;
    }
    ${Media('tablet')} {
        padding: 20px 40px !important;
    }
    ${Media('mobile')} {
        padding: 10px 20px !important;
    }
`;
const Tag = styled.h5`
    font-size: 16px;
    font-family: ${gilroySemibold};
    color: ${colors.black['50']};
    ${Media('xlscreens')} {
        font-size: 1.04vw;
    }
`;
const PageSubHeading2 = styled.h3`
    font-size: 30px;
    margin: 20px 0;
    font-family: ${gilroyBold};
    ${Media('xlscreens')} {
        font-size: 2.3vw;
        margin: 2.08vw 0;
    }
    ${Media('mobile')} {
        font-size: 26px;
    }
`;
const Separator = styled.div`
    height: 1px;
    width: 100%;
    margin: 40px 0;
    background-color: ${colors.gray['30']};
    ${Media('xlscreens')} {
        margin: 3.64vw 0;
    }
    ${Media('mobile')} {
        margin: 20px 0;
    }
`;
const EcoSystemFeat = styled(Col)`
    h2 {
        font-size: 24px;
        font-family: ${gilroyBold};
        margin-bottom: 0;
    }
    p {
        margin-top: 10px;
    }
    ${Media('xlscreens')} {
        h2 {
            font-size: 1.82vw;
        }
        p {
            margin-top: 0.53vw;
        }
    }
`;
const RightDarkSection = styled(Col)`
    ${Media('xlscreens')} {
        padding: 4.16vw 0px 4.16vw 6.25vw !important;
    }
    ${Media('tablet')} {
        padding: 50px !important;
        padding-bottom: 20px !important;
    }
    ${Media('mobile')} {
        padding: 20px !important;
    }
`;
const TechPlatFormBtn = styled.button`
    padding: 10px 30px;
    border-radius: 30px;
    border: none;
    font-size: 20px;
    font-family: ${gilroySemibold};
    background-image: linear-gradient(
        to right,
        ${colors.pink['100']} 0%,
        ${colors.white['100']} 150%
    );
    margin-bottom: 50px;
    ${Media('xlscreens')} {
        padding: 0.53vw 1.56vw;
        border-radius: 1.56vw;
        font-size: 1.04vw;
        margin-bottom: 5.2vw;
    }
    ${Media('mobile')} {
        font-size: 16px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
`;
const Brand = styled(Col)`
    margin: 25px 0;
    img {
        max-height: 73px;
        max-width: 190px;
    }
    ${Media('xlscreens')} {
        margin: 1.3vw 0;
        img {
            max-height: 3.8vw;
            max-width: 9.8vw;
        }
    }
    ${Media('mobile')} {
        img {
            max-height: 50px;
            max-width: 80px;
        }
    }
`;
const FeatureVideos = styled.div`
    margin: 30px 0;
    ${Media('xlscreens')} {
        margin: 3.64vw 0;
    }
`;
const FeatVideo = styled.div`
    ${Media('xlscreens')} {
        margin-bottom: 2vw;
        padding: 0 5vw;
        text-align: center;
    }
    ${Media('tablet')} {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0px 30px;
    }
    ${Media('mobile')} {
        padding: 0px;
    }
`;
const FeatVideoWrapper = styled.div``;
const FeatTitle = styled.h2`
    font-family: ${gilroySemibold};
    margin-bottom: 40px;
    line-height: 1.62;
    ${Media('xlscreens')} {
        font-size: 1.51vw;
        margin-bottom: 2.08vw;
        height: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    ${Media('tablet')} {
        font-size: 26px;
        br {
            display: none;
        }
    }
    ${Media('tablet')} {
        font-size: 22px;
    }
`;
const FeatPara = styled.p`
    font-family: ${gilroyMedium};
    line-height: 1.8;
    ${Media('xlscreens')} {
        font-size: 1.04vw;
    }
    ${Media('tablet')} {
        font-size: 16px;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
const VideoWrap = styled.video`
    object-fit: cover;
    margin: 100px auto 0px;
    clip-path: polygon(
        30% 0%,
        0% 0%,
        30% 50%,
        0% 100%,
        30% 100%,
        50% 70%,
        70% 100%,
        100% 100%,
        70% 50%,
        100% 0%,
        70% 0%,
        50% 30%
    );
    opacity: 0;
    transform: scale(0.75);
    border: 1px solid ${colors.white['100']};
    transition: transform 0.4s cubic-bezier(0.3, 0, 0.3, 1),
        opacity 0.4s cubic-bezier(0.3, 0, 0.3, 1),
        -webkit-transform 0.4s cubic-bezier(0.3, 0, 0.3, 1);
    ${(props) => {
        if (props.isVisible) {
            return `
                opacity:1;
                transform:scale(1);
            `;
        }
    }}
    ${Media('xlscreens')} {
        height: 15.88vw;
        width: 14.63vw;
        margin: 5.2vw auto 0;
    }
    ${Media('tablet')} {
        height: 250px;
        width: 250px;
    }
    ${Media('mobile')} {
        height: 220px;
        width: 75%;
        margin-top: 70px;
    }
`;
export default WaveX;
