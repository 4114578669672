import React from 'react';
import styled from 'styled-components';
import { gilroySemibold, gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';
import ReactHtmlParser from 'react-html-parser';

const CaseStats2 = ({
    left,
    right,
    textCenter,
    directionColumn,
    styleTwo,
    arrow,
}) => {
    return (
        <ScrollAnimation animateIn="fadeInUp">
            <Wrapper directionColumn={directionColumn}>
                <Left
                    directionColumn={directionColumn}
                    styleTwo={styleTwo}
                    arrowDirect={arrow}>
                    {arrow && arrow !== 'hide' && (
                        <img
                            src={`${baseURL}assets/images/arrow-down.svg`}
                            alt="-"
                        />
                    )}
                    {left}
                </Left>
                <Right
                    textCenter={textCenter}
                    directionColumn={directionColumn}
                    styleTwo={styleTwo}>
                    {ReactHtmlParser(right)}
                </Right>
            </Wrapper>
        </ScrollAnimation>
    );
};
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    ${Media('xlscreens')} {
        flex-direction: ${(props) =>
            props.directionColumn ? 'column' : 'row'};
        justify-content: center;
        ${(props) => {
            if (props.directionColumn) {
                return `
                min-height:12vw;
            `;
            }
        }}
    }
    ${Media('tablet')} {
        border: 1px solid ${colors.black['100']};
        padding: 10px 0px;
        margin: 10px 0px;
    }
    ${Media('mobile')} {
        flex-direction: column;
    }
`;
const Left = styled.div`
    font-size: 60px;
    font-family: ${gilroySemibold};
    letter-spacing: -0.2px;
    color: ${colors.pink['100']};
    img {
        height: 12px;
        transform: ${(props) =>
            props.arrowDirect === 'up' ? 'rotate(180deg)' : 'rotate(0deg)'};
    }
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.styleTwo ? '2.6vw' : '2.14vw')};
        img {
            height: 1.2vw;
            position: relative;
            right: 1.5vw;
        }
        ${(props) => {
            if (props.directionColumn) {
                return `
                    height:7vw;
                    display:flex;
                    align-items:center;
                `;
            }
        }}
    }
    ${Media('tablet')} {
        font-size: 26px;
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            margin-right: 5px;
        }
    }
    ${Media('mobile')} {
        font-size: 20px;
    }
`;
const Right = styled.div`
    font-size: 24px;
    font-family: ${gilroySemibold};
    padding: 0px 20px;
    line-height: 1.4;
    letter-spacing: -0.5px;
    text-align: ${(props) => (props.textCenter ? 'center' : 'left')};
    ${Media('xlscreens')} {
        font-size: 1vw;
        font-size: ${(props) => (props.styleTwo ? '1.2vw' : '1vw')};
        padding: 0 1.04vw;
        ${(props) => {
            if (props.directionColumn) {
                return `
                    flex:1;
                `;
            }
        }}
    }
    ${Media('tablet')} {
        margin-left: 0px;
        font-size: 20px;
        flex: 1;
        text-align: left;
        font-family: ${gilroyMedium};
        margin-right: 10px;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 18px;
        text-align: center;
        margin: 0px 10px;
    }
`;
export default CaseStats2;
