import React, { useEffect, useState } from 'react';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import styled from 'styled-components';
import Container from '@bootstrap-styled/v4/lib/Container';
import { gilroySemibold } from 'shared-components/theme/fonts';
import LeadershipPerson from 'components/leadership-persons/leadership-persons';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import LeaderShipPopup from 'components/leadership-popup/leadership-popup';
import fetch from 'cross-fetch';

const LeadershipPage = () => {
    const [popup, togglePopup] = useState(false);
    const [activeSlideId, setId] = useState(0);
    const [leaders, setLeaders] = useState([]);
    const [directors, setDirectors] = useState([]);
    const [allData, setAllData] = useState([]);

    const getLeaders = async () => {
        try {
            const res = await fetch(
                `${process.env.REACT_APP_IBEX_API}/leadership?token=${process.env.REACT_APP_API_TOKEN}&per_page=25&category_slug=leadership`
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            const reverseData = resData.data.map(
                (item, index, array) => array[array.length - 1 - index]
            );
            setLeaders(reverseData);
            getDirectors();
        } catch (err) {
            console.error(err);
        }
    };

    const getDirectors = async () => {
        try {
            const res = await fetch(
                `${process.env.REACT_APP_IBEX_API}/leadership?token=${process.env.REACT_APP_API_TOKEN}&per_page=25&category_slug=board_of_directors`
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            const reverseData = resData.data.map(
                (item, index, array) => array[array.length - 1 - index]
            );
            setDirectors(reverseData);
        } catch (err) {
            console.error(err);
        }
    };

    const handleLeadershipData = () => {
        setAllData([...leaders, ...directors]);
    };

    useEffect(() => {
        getLeaders();
    }, []);

    return (
        <Wrapper>
            {allData && allData.length > 0 ? (
                <LeaderShipPopup
                    state={popup}
                    closePopup={() => togglePopup(false)}
                    leadershipData={allData}
                    activeSlideId={activeSlideId}
                />
            ) : null}
            <Layout page="leadership">
                <Breadcrumbs>
                    <li>Who We Are</li>
                    <li>Leadership</li>
                </Breadcrumbs>
                <LeadersWrapper>
                    <Container>
                        <LeadershipWrapper>
                            <div>
                                <PageHeading>Leadership</PageHeading>
                            </div>
                            <PersonsWrapper>
                                <PersonsRow>
                                    {leaders &&
                                        leaders.length > 0 &&
                                        leaders.map((person, index) => (
                                            <LeadershipPerson
                                                image={person.featured_image}
                                                name={person.post_title}
                                                designation={
                                                    person.metadata.designation
                                                }
                                                openPopup={() => {
                                                    setId(person.ID);
                                                    togglePopup(true);
                                                    handleLeadershipData();
                                                }}
                                                key={index}
                                            />
                                        ))}
                                </PersonsRow>
                            </PersonsWrapper>
                        </LeadershipWrapper>
                        <LeadershipWrapper>
                            <div>
                                <PageHeading>Board of Directors</PageHeading>
                            </div>
                            <PersonsWrapper>
                                <PersonsRow>
                                    {directors &&
                                        directors.length > 0 &&
                                        directors.map((person, index) => (
                                            <LeadershipPerson
                                                image={person.featured_image}
                                                name={person.post_title}
                                                designation={
                                                    person.metadata.designation
                                                }
                                                openPopup={() => {
                                                    setId(person.ID);
                                                    togglePopup(true);
                                                    handleLeadershipData();
                                                }}
                                                key={index}
                                            />
                                        ))}
                                </PersonsRow>
                            </PersonsWrapper>
                        </LeadershipWrapper>
                    </Container>
                </LeadersWrapper>
            </Layout>
        </Wrapper>
    );
};
const LeadershipWrapper = styled.div``;

const Wrapper = styled.div``;
const LeadersWrapper = styled.section`
    padding-top: 0px;
`;
const PageHeading = styled.div`
    padding: 20px 0px;
    font-family: ${gilroySemibold};
    font-size: 50px;
    ${Media('xlscreens')} {
        padding: 3.15vw 0vw;
        font-size: 3.42vw;
    }
    ${Media('tabletlandscape')} {
        padding: 40px 0px;
    }
`;
const PersonsWrapper = styled.div`
    display: block;
`;
const PersonsRow = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -15px;
    ${Media('xlscreens')} {
        margin: 0 -1.4vw;
    }
`;
export default LeadershipPage;
