import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyLight, gilroySemibold, gilroyMedium } from '../theme/fonts';
import Media from '../theme/medias';

const MainPara = ({ children, color, bigtext, align, noMargin, styleTwo }) => {
    return (
        <Wrapper
            color={color}
            bigtext={bigtext}
            align={align}
            styleTwo={styleTwo}
            noMargin={noMargin}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.p`
    font-size: 15px;
    line-height: 1.6;
    color: ${(props) => (props.color ? props.color : colors.gray_dark['100'])};
    font-family: ${(props) =>
        props.bigtext
            ? gilroySemibold
            : props.styleTwo
            ? gilroyMedium
            : gilroyLight};
    text-align: ${(props) => props.align};
    margin: ${(props) => props.noMargin && '0'};
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.bigtext ? '1.36vw' : '0.94vw')};
        margin: ${(props) => (props.noMargin ? '0' : '1.5vw 0')};
    }
    ${Media('desktopscreens')} {
        font-size: 1.1vw;
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;

export default MainPara;
