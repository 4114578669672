/* eslint-disable require-jsdoc */
import React, { useEffect } from 'react';
import MainRoutes from './main-routes';
import CreateGlobalStyle from './global-style';
import { ParallaxProvider } from 'react-scroll-parallax';
import lozad from 'lozad';


function App() {
    useEffect(() => {
        const el = document.querySelectorAll(['img', 'video']);
        const observer = lozad(el);
        observer.observe();
    });

    return (
        <>
            <CreateGlobalStyle />
            <ParallaxProvider>
                <MainRoutes />
            </ParallaxProvider>
        </>
    );
}

export default App;
