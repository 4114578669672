import React, { Fragment } from 'react';
import Layout from 'components/layout';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import CaseStudyIntro from 'components/case-study/case-study-intro';
import CaseGrowth from 'components/case-study/case-growth';
import BigParagraph from 'components/case-study/case-big-paragraph';
import Media from 'shared-components/theme/medias';
import WhoTheyAre from 'components/case-study/case-who-they-are2';
import QuoteBox from 'components/case-study/case-quotebox2';
import CaseInfoSection from 'components/case-study/case-info-section';
import BottomSection from 'components/case-study/case-bottom-section2';
import { Parallax } from 'react-scroll-parallax';
import { gilroySemibold } from 'shared-components/theme/fonts';

import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import LazyLoad from 'react-lazyload';
import Loader from 'components/loader/loader';
import { baseURL } from 'shared-components/theme/helpers';

const Criquet = () => {
    const scrollNavData = [
        {
            name: 'home',
            target: 'home',
            offset: -100,
        },
        {
            name: 'contentSection',
            target: 'contentSection',
            offset: -100,
        },
        {
            name: 'growthSection',
            target: 'growthSection',
            offset: -100,
        },
        {
            name: 'whotheyare',
            target: 'whotheyare',
            offset: -100,
        },
        {
            name: 'caseInfo',
            target: 'caseInfo',
            offset: -100,
        },
        {
            name: 'bottomSection',
            target: 'bottomSection',
            offset: -100,
        },
    ];
    const growthItems = [
        {
            left: '91%',
            right: 'increase in <br />purchases',
        },
        {
            left: '80%',
            right: 'lift in return on <br />ad spend',
        },
        {
            left: '25%',
            right: 'decrease in cost <br />per acquisition',
        },
    ];
    return (
        <Layout page="digital">
            <Breadcrumbs>
                <li>Digital</li>
                <li>Criquet</li>
            </Breadcrumbs>
            <CaseStudyIntro
                introHeading={
                    <Fragment>
                        Runway to the <br /> holidays.
                    </Fragment>
                }
                introPara="The men’s apparel company used dynamic ads to drive more sales during its holiday campaign and decrease the cost per acquisition, boosting its return on ad spend by 80%."
                headingImage={`${baseURL}assets/images/criquet-icon.svg`}
                introImage={`${baseURL}assets/images/criquet-intro.jpg`}
            />
            <BigTextSection>
                <BigText id="bigText">
                    <Parallax x={['-110%', '100%']}>
                        <OneText id="bigTextOne">Dynamic ads,</OneText>
                    </Parallax>
                    <Parallax x={['8%', '-53%']}>
                        <TwoText id="bigTextTwo">refreshed classics.</TwoText>
                    </Parallax>
                </BigText>
            </BigTextSection>

            
                <ContentSectionArea id="contentSection">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <BigParagraph bigParagraph="Criquet Shirts needed a partner who could help them affordably reach their ideal buying audience, while helping visitors who had left their website to return and complete their purchase.  They partnered with ibex Digital to get the job done." />
                            </Col>
                        </Row>
                    </Container>
                </ContentSectionArea>

                <GrowthContainer id="growthSection">
                    <Container>
                        <Row>
                            {growthItems &&
                                growthItems.map((item, index) => (
                                    <Col lg="4" key={index}>
                                        <CaseGrowth
                                            left={item.left}
                                            right={item.right}
                                        />
                                    </Col>
                                ))}
                        </Row>
                    </Container>
                </GrowthContainer>
           

            
                <ScrollWrapper id="whotheyare">
                    <WhoTheyAre
                        tagLine={['Who they are']}
                        contentHeading={[' Fresh.Classic.']}
                        contentParagraph={[
                            'Founded in Austin, Texas, in 2010, Criquet Shirts is a men’s apparel brand known for the Players Shirt: its modern take on the classic polo. Details Magazine and the Wall Street Journal have both praised the brand.',
                        ]}
                        contentImage={`${baseURL}assets/images/fresh-classic.jpg`}
                        multiPara={false}
                    />
                </ScrollWrapper>

                <QuoteBox
                    quotePara="Facebook helps us expand our reach to qualified audiences across the country. As we continue to scale our business, the size of these audiences will only increase. Our expectation is that Facebook will be as big a part of our marketing budget in 2 years as it is today."
                    quoteName="Hobson Brown,"
                    quoteDesignation="co-founder, Criquet Shirts"
                    offsetMinus={false}
                    paddingTop={true}
                />
           

            
                <ScrollWrapper id="caseInfo">
                    <CaseInfoSection
                        paddingBottom={true}
                        img={`${baseURL}assets/images/track-learn.jpg`}
                        heading={
                            <Fragment>
                                Track. Learn. <br />
                                Target. Convert.
                            </Fragment>
                        }
                        paragraph={
                            <Fragment>
                                Partnering with agency ibex.digital, Criquet
                                Shirts used a variety of tactics and products,
                                including dynamic ads, to fuel its holiday 2015
                                Facebook campaign. <br /> <br />
                                To build awareness, it first targeted ads to men
                                aged 25–45 who are outdoor enthusiasts, baseball
                                fans and golfers. The apparel brand built Custom
                                Audiences from email addresses it had gathered
                                on its website. Criquet Shirts also added a
                                Facebook pixel to its website to track purchases
                                and conversions. <br /> <br />
                                Criquet Shirts segmented its audience by
                                identifying new customers and returning
                                customers, separating the latter into existing
                                and high-value repeat customers. The company
                                built 1% lookalike audiences off these segments
                                to reach people who were likely to become
                                customers. Criquet Shirts used carousel ads to
                                reach new people, and dynamic ads to re-engage
                                people who made a purchase. These dynamic ads
                                were also retargeted to existing customers to
                                help close sales. <br /> <br />
                                Criquet Shirts also tailored its creative to
                                each audience. Lifestyle shots showcasing the
                                brand’s personality were most effective with new
                                people, while returning customers preferred
                                product collages. All ads included a “Shop Now”
                                call-to-action button. Ads with strong value
                                propositions—such as “vintage-inspired golf
                                shirts”, “made with super-soft 100% organic
                                cotton” and “a better shirt for a better
                                man”—outperformed ads without text.
                            </Fragment>
                        }
                    />
                </ScrollWrapper>
           

            
                <ScrollNav data={scrollNavData} />
                <ScrollWrapper id="bottomSection">
                    <BottomSection
                        withoutOffset={true}
                        leftHeading="Unhemmed results."
                        leftPara="Criquet Shirts’ 2015 holiday season campaign helped it reach more people and create a brand presence among new audiences, which increased sales returns. The campaign achieved:"
                        rightList={[
                            '91% increase in purchases',
                            '80% lift in return on ad spend',
                            '25% decrease in cost per acquisition',
                        ]}
                    />
                </ScrollWrapper>

                <QuoteBox
                    quotePara="The holidays are obviously a crucial time for Criquet Shirts. Successfully implementing a mobile-first Facebook advertising approach enabled Criquet Shirts to shatter its prior year holiday sales marks and finish the year with a bang."
                    quoteName="Andrew Wallach,"
                    quoteDesignation="Director of Ad Ops, ibex.digital"
                    offsetMinus={true}
                />
           
        </Layout>
    );
};
const BigTextSection = styled.section`
    position: relative;
    padding-top: 0;
    margin-top: -350px;

    ${Media('xlscreens')} {
        margin-top: -18vw;
    }
`;
const ContentSectionArea = styled.section`
    padding-top: 0;
    ${Media('tablet')} {
        padding: 0px;
    }
`;
const BigText = styled.div`
    font-size: 140px;
    font-family: ${gilroySemibold};

    overflow: hidden;
    line-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    span {
        white-space: nowrap;
        position: relative;
    }
    ${Media('xlscreens')} {
        width: 100%;
        font-size: 15.6vw;
        line-height: 0.82;
    }
`;
const GrowthContainer = styled.div`
    padding-bottom: 100px;
    ${Media('xlscreens')} {
        padding-bottom: 5.23vw;
    }
    ${Media('tablet')} {
        padding-bottom: 0px;
    }
`;
const OneText = styled.span`
    display: inline-flex;
    align-self: flex-start;
    left: -2%;
`;

const TwoText = styled.span`
    display: inline-flex;
    align-self: flex-start;
    left: 35%;
`;

const ScrollWrapper = styled.div``;
export default Criquet;
