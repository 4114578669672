import React from 'react';
import styled from 'styled-components';
import { gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const PageHeading = ({ children }) => {
    return <PageMainHeading>{children}</PageMainHeading>;
};

const PageMainHeading = styled.h3`
    font-family: ${gilroySemibold};
    font-size: 60px;
    letter-spacing: -0.5px;
    ${Media('xlscreens')} {
        font-size: 3.1vw;
        letter-spacing: -0.15vw;
    }
    ${Media('tablet')} {
        font-size: 40px;
    }
    br {
        ${Media('mobile')} {
            display: none;
        }
    }
`;
