import React from 'react';
import colors from 'shared-components/theme/colors';
import {
    gilroyRegular,
    gilroyExtrabold,
    gilroyMedium,
} from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';

export const FCXPagePara = ({
    children,
    size,
    extraBold,
    fontWeight,
    color,
}) => {
    return (
        <PagePara
            size={size}
            extraBold={extraBold}
            fontWeight={fontWeight}
            color={color}>
            {children}
        </PagePara>
    );
};

const PagePara = styled.p`
    font-size: ${(props) => (props.size === 'small' ? '16px' : '21px')};
    font-family: ${(props) =>
        props.extraBold ? gilroyExtrabold : gilroyMedium};
    font-family: ${(props) => props.fontWeight === 'regular' && gilroyRegular};
    margin: 0px;
    line-height: 1.5;
    color: ${(props) => (props.color ? props.color : colors.fintech['text'])};
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.size === 'small' ? '0.833vw' : '1.1vw')};
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: ${(props) => (props.size === 'small' ? '14px' : '18px')};
    }
`;
