import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import { gilroyMedium, gilroyLight } from 'shared-components/theme/fonts';
import SlickSlider from 'shared-components/slider/slider';
import ScrollAnimation from 'react-animate-on-scroll';
import Moment from 'react-moment';
import fetch from 'cross-fetch';
import Loader from 'components/loader/loader';
import { baseURL } from 'shared-components/theme/helpers';

const HomeBlog = ({ page }) => {
    const [blogData, setBlogData] = useState([]);

    const getBlogs = async () => {
        const digital_cat =
            page === 'digital' ? 'digital-marketing-accelerator' : '';
        try {
            const res = await fetch(
                `${process.env.REACT_APP_IBEX_API}/blog?token=${process.env.REACT_APP_API_TOKEN}&per_page=10&category_slug=${digital_cat}`,
                { method: 'GET' }
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                setBlogData(resData.data);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const settingsBlog = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        centerMode: false,
        centerPadding: '0px',
        focusOnSelect: false,
        autoplay: false,
        autoplaySpeed: 6000,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                },
            },
        ],
    };

    useEffect(() => {
        setTimeout(() => {
            getBlogs();
        }, 2000);
    }, []);

    return (
        <Wrapper>
            <Container fluid={true}>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    <Row>
                        <Col md="4">
                            <WaveLogo>
                                <img
                                    data-src={`${baseURL}assets/images/wavezero-logo.svg`}
                                    src={`${baseURL}assets/images/wavezero-logo.svg`}
                                    alt=""
                                    className="lozad"
                                    width="320"
                                    height="73"
                                />
                            </WaveLogo>
                        </Col>
                        <Col md="8">
                            <BlogDesc>
                                A blog from ibex that explores the power <br />
                                of CX to transform business.
                            </BlogDesc>
                        </Col>
                    </Row>
                </ScrollAnimation>
                <Row>
                    <Col md="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            {blogData && blogData.length > 0 ? (
                                <BlogSlider>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {blogData.map((item, index) => {
                                            return (
                                                <BlogBox key={index}>
                                                    <BlogImg>
                                                        <a
                                                            href={
                                                                item.permalink
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            tabIndex="0">
                                                            <img
                                                                data-src={
                                                                    item.featured_image
                                                                }
                                                                src={
                                                                    item.featured_image
                                                                }
                                                                height="100"
                                                                width="100"
                                                                alt=""
                                                                className="lozad"
                                                            />
                                                        </a>
                                                    </BlogImg>
                                                    <BlogContent>
                                                        <span>
                                                            <Moment format="MMM DD, YYYY">
                                                                {item.post_date}
                                                            </Moment>
                                                        </span>
                                                        <h4>
                                                            <a
                                                                href={
                                                                    item.permalink
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                tabIndex="0">
                                                                {
                                                                    item.post_title
                                                                }
                                                            </a>
                                                        </h4>
                                                        <p>
                                                            {`${item.metadata[
                                                                'short_description'
                                                            ].substr(
                                                                0,
                                                                70
                                                            )} ...`}
                                                        </p>
                                                    </BlogContent>
                                                </BlogBox>
                                            );
                                        })}
                                    </div>
                                </BlogSlider>
                            ) : (
                                <Loader size="small" />
                            )}
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    background-color: ${colors.gray_light['50']};
    padding: 100px 0 50px 240px;
    ${Media('tablet')} {
        padding: 50px 0px 50px;
    }
    ${Media('xlscreens')} {
        // padding-left: 19.2vw;
        overflow: hidden;
        // height: 40vw;
    }
    ${Media('mobile')} {
        height: 700px;
    }
`;

const WaveLogo = styled.div`
    img {
        max-width: 100%;
    }
    ${Media('xlscreens')} {
        img {
            width: 16.7vw;
            height: auto;
        }
    }
    ${Media('mobile')} {
        margin-bottom: 10px;
        img {
            width: 260px;
            height: auto;
        }
    }
`;

const BlogDesc = styled.div`
    font-size: 26px;
    color: ${colors.gray_dark['100']};
    margin: 0;
    position: relative;
    font-family: ${gilroyMedium};
    ${Media('tablet')} {
        font-size: 16px;
        margin: 20px 0;
    }
    ${Media('xlscreens')} {
        font-size: 1.9vw;
    }
`;

const BlogSlider = styled.div`
    margin-top: 70px;
    margin-bottom: 30px;
    position: relative;
    .slick-arrow {
        position: absolute;
        left: -11vw;
        top: calc(25% - 18px);
        z-index: 99;
        &.slick-next {
            transform: translateY(50px);
        }
        ${Media('large')} {
            top: calc(25% - 25px);
            &.slick-next {
                transform: translateY(62px);
            }
        }
    }
    ${Media('tablet')} {
        margin-top: 50px;
        margin-bottom: 50px;
        .slick-arrow {
            left: calc(50% - 50px);
            top: auto;
            bottom: -30px;
            &.slick-next {
                transform: translateX(50px);
            }
        }
    }
`;

const BlogBox = styled.div`
    padding-right: 40px;
    /* max-width: 320px; */
    ${Media('xlscreens')} {
        max-width: 24vw;
    }
    ${Media('mobile')} {
        padding-right: 0px;
        width: 100%;
    }
`;

const BlogImg = styled.div`
    height: 240px;
    width: 100%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition-duration: 0.3s;
        /* -webkit-filter: grayscale(100%);
        filter: grayscale(100%); */
    }
    &:hover {
        img {
            transform: scale(1.1);
            /* -webkit-filter: grayscale(0);
            filter: grayscale(0); */
        }
    }
    ${Media('xlscreens')} {
        height: 15.4vw;
    }
`;

const BlogContent = styled.div`
    padding: 50px 0 20px;
    ${Media('xlscreens')} {
        padding-top: 3.8vw;
    }
    span {
        font-size: 12px;
        color: ${colors.gray_dark['50']};
        display: block;
        margin-bottom: 5px;
        ${Media('xlscreens')} {
            font-size: 0.65vw;
        }
    }

    h4 {
        font-size: 15px;
        color: ${colors.gray_dark['100']};
        margin-bottom: 15px;
        margin-top: 15px;
        font-family: ${gilroyMedium};
        font-weight: 500;
        ${Media('xlscreens')} {
            font-size: 0.95vw;
        }
    }

    p {
        font-size: 12px;
        font-family: ${gilroyLight};
        color: ${colors.gray_dark['100']};
        max-width: 75%;
        ${Media('xlscreens')} {
            font-size: 0.8vw;
        }
        ${Media('mobile')} {
            max-width: 80%;
        }
    }
`;

export default HomeBlog;
