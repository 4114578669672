import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { Link } from 'react-scroll';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { gilroyMedium } from 'shared-components/theme/fonts';

export const GlossaryTabMenu = ({ data }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <TabsWrapper>
                <Container>
                    <Row>
                        <Col sm="12">
                            <TabsList>
                                {data &&
                                    data.length > 0 &&
                                    data.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <Link
                                                    to={item}
                                                    activeClass="active"
                                                    spy={true}
                                                    smooth={true}
                                                    duration={1000}
                                                    offset={-120}>
                                                    {item}
                                                </Link>
                                            </li>
                                        );
                                    })}
                            </TabsList>
                        </Col>
                    </Row>
                </Container>
            </TabsWrapper>

            <MobileWrapper>
                <MenuBtn
                    className="menuOpener"
                    open={open}
                    onClick={() => setOpen(true)}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="55"
                        height="63.14"
                        viewBox="0 0 93 106.762">
                        <g transform="translate(-1827 -222)">
                            <g transform="translate(0 -554)">
                                <path
                                    d="M10,0H93a0,0,0,0,1,0,0V105a0,0,0,0,1,0,0H10A10,10,0,0,1,0,95V10A10,10,0,0,1,10,0Z"
                                    transform="translate(1827 776)"
                                    fill={colors.socialRep['brandsBG']}
                                />
                                <line
                                    x2="27"
                                    transform="translate(1861.5 882.637)"
                                    fill="none"
                                    stroke={colors.white['100']}
                                    strokeWidth="0.25"
                                    opacity="0.649"
                                />
                            </g>
                            <path
                                d="M18.105,31.3l-1.561,1.561a1.681,1.681,0,0,1-2.384,0L.492,19.2a1.681,1.681,0,0,1,0-2.384L14.161,3.143a1.681,1.681,0,0,1,2.384,0L18.105,4.7a1.689,1.689,0,0,1-.028,2.412L9.6,15.188H29.813A1.683,1.683,0,0,1,31.5,16.875v2.25a1.683,1.683,0,0,1-1.687,1.688H9.6l8.473,8.072A1.677,1.677,0,0,1,18.105,31.3Z"
                                transform="translate(1858.003 257.354)"
                                fill={colors.white['100']}
                            />
                        </g>
                    </svg>
                </MenuBtn>
                <MenuBtn open={open} onClick={() => setOpen(false)}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </MenuBtn>
                <InnerWrapper open={open}>
                    <Menu>
                        {data &&
                            data.length > 0 &&
                            data.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <Link
                                            activeClass="active"
                                            to={item}
                                            spy={true}
                                            smooth={true}
                                            duration={1000}
                                            offset={-70}
                                            onClick={() => setOpen(false)}>
                                            {item}
                                        </Link>
                                    </li>
                                );
                            })}
                    </Menu>
                </InnerWrapper>
            </MobileWrapper>
        </>
    );
};

const TabsWrapper = styled.div`
    box-shadow: 0px 3px 14px ${colors.black['10']};
    background-color: ${colors.gray_lighter['100']};
    padding: 15px;
    position: sticky;
    z-index: 99;
    top: 85px;
    ${Media('xlscreens')} {
        padding: 1.1vw 0;
        top: 5.3vw;
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const TabsList = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 0px;
    li {
        a {
            font-size: 24px;
            font-family: ${gilroyMedium};
            color: ${colors.socialRep['brandsBG']};
            width: 52px;
            height: 52px;
            border-radius: 8px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin: 0px 1px;
            &.active,
            &:hover {
                background-color: ${colors.pink['100']};
                color: ${colors.white['100']};
            }
        }
    }
    ${Media('xlscreens')} {
        li {
            a {
                font-size: 1.35vw;
                width: 2.7vw;
                height: 2.7vw;
            }
        }
    }
`;

const MobileWrapper = styled.div`
    display: none;
    ${Media('tablet')} {
        display: block;
    }
`;

const MenuBtn = styled.a`
    position: fixed;
    z-index: 9999;
    width: 55px;
    height: 65px;
    top: 10px;
    right: 10px;
    display: ${(props) => (props.open ? 'block' : 'none')};
    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 30px;
        background: ${colors.gray_dark['100']};
        opacity: 1;
        left: calc(50% - 15px);
        margin-bottom: 5px;
        border-radius: 9px;
        transform: scale(1);
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
        &:nth-child(1) {
            top: ${(props) => (props.open ? '31px' : '22px')};
            transform: ${(props) => (props.open ? 'scale(0)' : 'scale(1)')};
        }
        &:nth-child(2) {
            top: 31px;
            transform: ${(props) =>
                props.open ? 'rotate(-45deg)' : 'rotate(0deg)'};
        }
        &:nth-child(3) {
            top: 31px;
            transform: ${(props) =>
                props.open ? 'rotate(45deg)' : 'rotate(0deg)'};
        }
        &:nth-child(4) {
            top: 40px;
            transform: ${(props) => (props.open ? 'scale(0)' : 'scale(1)')};
        }
    }
    &.menuOpener {
        top: 60%;
        transform: translateY(-50%);
        right: 0;
        display: ${(props) => (props.open ? 'none' : 'flex')};
        border-radius: 10px 0 0 10px;
        align-items: center;
        justify-content: center;
    }
`;

const InnerWrapper = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9998;
    width: 100%;
    height: 100%;
    background-color: ${colors.white['100']};
    padding: 120px 30px 20px;
    box-sizing: border-box;
    transition: all 0.5s ease;
    transform: ${(props) =>
        props.open ? 'translateX(0)' : 'translateX(100%)'};
    ${Media('mobile')} {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 70px;
    }
`;

const Menu = styled(TabsList)`
    position: relative;
    overflow-y: scroll;
    height: 100%;
    flex-wrap: wrap;
    align-content: center;
    li {
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
    }
`;
