import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import Button from 'shared-components/button/button';
import { gilroyBold, sourceSansPro } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const IndustryEbookSection = ({
    bgImage,
    bgColor,
    color,
    heading,
    para,
    ebookLink,
}) => {
    return (
        <Wrapper bgImage={bgImage} bgColor={bgColor}>
            <EbookDetail>
                <div>
                    <EbookHeading color={color}>
                        {ReactHtmlParser(heading)}
                    </EbookHeading>
                    <EbookText color={color}>{ReactHtmlParser(para)}</EbookText>
                    <EbookDownBtn>
                        <Button
                            ifButton={false}
                            round={true}
                            bgColor={color}
                            href={`${ebookLink}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            Download the eBook
                        </Button>
                    </EbookDownBtn>
                </div>
            </EbookDetail>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    background-image: ${(props) => `url(${props.bgImage})`};
    background-position: center bottom;
    background-repeat: no-repeat;
    background-color: ${(props) => props.bgColor};
    position: relative;
    background-size: cover;
    ${Media('xlscreens')} {
        height: 31.5vw;
    }
    ${Media('tablet')} {
        background-image: none;
    }
`;
const EbookDetail = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    ${Media('xlscreens')} {
        position: absolute;
        right: 0;
        width: 40%;
    }
    ${Media('tablet')} {
        align-items: center;
        text-align: center;
        padding: 50px 0;
        width: 100%;
    }
`;
const EbookHeading = styled.h1`
    font-size: 36px;
    font-family: ${gilroyBold};
    line-height: 1.17;
    color: ${(props) => props.color};
    ${Media('xlscreens')} {
        font-size: 2.6vw;
    }
    ${Media('tablet')} {
        margin-top: 0;
    }
    ${Media('mobile')} {
        font-size: 30px;
    }
`;
const EbookText = styled.p`
    font-size: 14px;
    font-family: ${sourceSansPro};
    margin-top: 0;
    color: ${(props) => props.color};
    ${Media('xlscreens')} {
        font-size: 1.14vw;
    }
`;
const EbookDownBtn = styled.div`
    margin-top: 50px;
    button,
    a {
        padding: 15px 80px;
    }
    ${Media('xlscreens')} {
        margin-top: 2.6vw;
        button,
        a {
            padding: 0.78vw 4.166vw;
        }
    }
    ${Media('mobile')} {
        button,
        a {
            padding: 10px 40px;
        }
    }
`;
