import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';
import Button from 'shared-components/button/button';
import colors from 'shared-components/theme/colors';
import { gilroyBold, gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const IndustryCaseStudy = ({
    tag,
    image,
    heading,
    para,
    caseLink,
    preheading,
    btnText,
    noTag,
}) => {
    return (
        <div>
            <Wrapper>
                <CSImage>
                    <img src={image} alt="-" />
                    {!noTag && (
                        <CaseStudyTag>{tag ? tag : 'CASE STUDY'}</CaseStudyTag>
                    )}
                </CSImage>
                <CsContent>
                    {preheading && <PreHeading>{preheading}</PreHeading>}
                    <MainHeading>{ReactHtmlParser(heading)}</MainHeading>
                    {para && <MainPara>{para}</MainPara>}
                    <Button ifButton={false} round={true} href={`${caseLink}`}>
                        {btnText ? btnText : 'Read the Case Study'}
                    </Button>
                </CsContent>
            </Wrapper>
        </div>
    );
};

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    background-color: ${colors.gray_dark['100']};
    ${Media('tablet')} {
        flex-direction: column;
    }
`;

const CSImage = styled.div`
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    ${Media('xlscreens')} {
        height: 31.25vw;
    }
    ${Media('tablet')} {
        flex: 0 0 100%;
        max-width: 100%;
    }
`;

const MainPara = styled.div`
    font-size: 16px;
    font-family: ${gilroyMedium};
    line-height: 1.77;
    margin-bottom: 30px;
    ${Media('xlscreens')} {
        font-size: 0.95vw;
        margin-bottom: 1.8vw;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;

const CsContent = styled.div`
    padding: 50px 30px;
    div,
    h3,
    p {
        color: ${colors.white['100']};
    }
    ${Media('xlscreens')} {
        padding: 0px 8vw;
        ${MainPara} {
            margin-bottom: 2.1vw;
        }
    }
`;

const PreHeading = styled.div`
    color: ${colors.white['100']};
    font-size: 14px;
    font-family: ${gilroyBold};
    letter-spacing: 2px;
    padding-bottom: 30px;
    ${Media('xlscreens')} {
        padding-bottom: 2.08vw;
        font-size: 0.83vw;
    }
`;

const MainHeading = styled.h3`
    font-size: 34px;
    font-family: ${gilroyBold};
    line-height: 1.17;
    letter-spacing: -0.4px;
    margin-top: 0px;
    margin-bottom: 30px;
    ${Media('xlscreens')} {
        font-size: 2.6vw;
        margin-bottom: 2.2vw;
        letter-spacing: -0.1vw;
    }
    ${Media('mobile')} {
        font-size: 28px;
        br {
            display: none;
        }
    }
`;

const CaseStudyTag = styled.div`
    top: 0;
    left: 0;
    padding: 15px 40px;
    color: ${colors.white['100']};
    font-size: 14px;
    font-family: ${gilroyBold};
    background-color: ${colors.gray_dark['100']};
    position: absolute;
    letter-spacing: 2px;
    text-transform: uppercase;
    ${Media('xlscreens')} {
        padding: 0.78vw 2.08vw;
        font-size: 0.83vw;
    }
`;
