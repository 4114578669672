import React from 'react';
import {
    Text,
    IndustryTag,
    IndustrySectionHeading,
    TransformSection,
    ContentCenter,
} from 'components/industries/common';
import SolutionBox from 'components/solution-box/solution-box';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';

export const TransformSolutionSec = ({ retailSolutions }) => {
    return (
        <>
            <TransformSection
                tag="CONSUMER-GRADE PATIENT ENGAGEMENT"
                sectionHeading="Digital lifecycle solutions for consumer-driven, <br /> scalable healthcare CX "
                img={`${baseURL}assets/images/industries/healthtech-transform.jpg`}
                noBg
                noMarginTop
                para1="As innovation and digital transformation explode through the healthcare space, savvy healthcare brands must ensure that the quality of their customer experience keeps pace. Healthcare consumers expect seamless digital access matched with a personal, careful touch, putting unique pressure on healthcare organizations who need to balance quality of care with ever-increasing digital consumer expectations."
                para2="At ibex, we help leading healthcare and health-tech organizations connect the dots across their customer ecosystem, building digital-first experiences for high-expectation, on-demand CX."
            />

            <CXSolution id="cx-solution">
                <Container>
                    <div>
                        <ContentCenter>
                            <IndustryTag>
                                innovate. Then do it again.
                            </IndustryTag>
                            <IndustrySectionHeading>
                                ibex builds ROI-optimized CX solutions for the
                                <br />
                                world’s leading brands
                            </IndustrySectionHeading>
                            <Text>
                                Whether you’re looking for hyper-growth support,
                                seasonal burst capacity, or a next-gen patient
                                journey, ibex will deploy <br /> the best
                                people, technology, and methodology to deliver
                                unmatched patient, member, and customer
                                experiences.
                            </Text>
                        </ContentCenter>
                    </div>
                    <Row>
                        {retailSolutions &&
                            retailSolutions.length > 0 &&
                            retailSolutions.map((solution, index) => {
                                return (
                                    <Col lg="3" md="6" key={index}>
                                        <SolutionBox
                                            title={solution.title}
                                            imageUrl={solution.imageUrl}
                                            para={solution.para}
                                        />
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </CXSolution>
        </>
    );
};
const CXSolution = styled.section`
    background-color: ${colors.gray_lightest['100']};
`;