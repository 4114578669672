import React from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    IndustrySectionHeading,
    IndustryTag,
    Text,
    TransformSection,
    ContentCenter,
} from 'components/industries/common';
import SolutionBox from 'components/solution-box/solution-box';
import styled from 'styled-components';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';

export const TransformSolutionSec = ({ retailSolutions }) => {
    return (
        <>
            <TransformSection
                tag="CX, AT SPEED."
                sectionHeading="Scalable CX Solutions for <br /> High-Speed Technology Innovators"
                img={`${baseURL}assets/images/industries/highTech-transform.jpg`}
                noBg
                noMarginTop
                para1="Innovation is your super power, but CX demands can put extreme pressure on your growth, ROI, and ability to <i>win</i>."
                para2="Technology brands play an increasingly embedded and vital role in the lives of people around the globe. Selecting a CX partner who can move as intelligently and quickly as you do - at scale - is critical to your success. The right CX partner will become your competitive edge, managing high-volume CX interactions at speed, future-proofing your brand with cross-channel CX insights, and building predictable CX outcomes for powerful brand loyalty."
            />

            <CXSolution id="cx-solution">
                <Container>
                    <div>
                        <ContentCenter>
                            <IndustryTag>WIN. THEN DO IT AGAIN.</IndustryTag>
                            <IndustrySectionHeading>
                                Tech Moves Fast. So Do We.
                            </IndustrySectionHeading>
                            <Text>
                                From software to hardware to your
                                business-as-a-service, ibex deploys the best in
                                people, technology, and processes <br /> to
                                deliver unmatched CX for every point along the
                                customer journey. We&apos;re agents of change
                                for smart CX, letting you <br /> focus on
                                creating the next industry game-changer.
                            </Text>
                        </ContentCenter>
                    </div>
                    <SolutionBoxWrapper>
                        {retailSolutions &&
                            retailSolutions.length > 0 &&
                            retailSolutions.map((solution, index) => {
                                return (
                                    <Col lg="3" md="6" key={index}>
                                        <SolutionBox
                                            title={solution.title}
                                            imageUrl={solution.imageUrl}
                                            para={solution.para}
                                        />
                                    </Col>
                                );
                            })}
                    </SolutionBoxWrapper>
                </Container>
            </CXSolution>
        </>
    );
};
const CXSolution = styled.section`
    background-color: ${colors.gray_lightest['100']};
`;

const SolutionBoxWrapper = styled(Row)`
    min-height: 700px;
    ${Media('xlscreens')} {
        min-height: 36.4vw;
    }
`;
