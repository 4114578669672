import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { gilroyBold } from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';
import { baseURL } from 'shared-components/theme/helpers';

export const DigitalDemandBlock = () => {
    return (
        <div>
            <Wrapper>
                <TextBlock>
                    <DemandHeader>
                        Driving demand <br />
                        & sales, thousands of <br />
                        leads at a time<span>.</span>
                    </DemandHeader>
                    <DemandText>
                        If you’re a startup, an SMB, or a large global player,
                        our expertise <br />
                        in the digital advertising space means that we’re able
                        to
                        <br />
                        efficiently manage your ad spend across multiple digital
                        & social <br />
                        channels. We drive growth, help scale, and push
                        innovation.
                    </DemandText>
                </TextBlock>
            </Wrapper>
        </div>
    );
};
const DemandText = styled.div`
    margin-top: 2vw;
    font-size: 1vw;
    letter-spacing: -0.01vw;

    ${Media('tablet')} {
        font-size: 5vw;
        line-height: 6vw;
        text-align: center;
        br {
            display: none;
        }
    }
`;
const DemandHeader = styled.div`
    font-family: ${gilroyBold};
    font-size: 3vw;
    line-height: 4vw;
    letter-spacing: -0.15vw;
    span {
        color: ${colors.pink['100']};
    }

    ${Media('tablet')} {
        font-size: 7vw;
        line-height: 8vw;
        text-align: center;
    }
`;
const TextBlock = styled.div`
    background: #fff;
    margin: 5vw 15.5vw;
    padding: 7vw 7vw 7vw 6vw;
    position: absolute;
    ${Media('tablet')} {
        margin: 5vw 8vw;
        position: relative;
    }
`;
const Wrapper = styled.div`
    position: relative;
    margin-top: 2vw;
    clear: both;
    background-color: #1d1d1f;
    padding: 4vw 0px 0px 0px;
    background-position: center;
    border-radius: 21px;
    height: 45vw;
    background: url('${baseURL}assets/images/digital-container-block.png');

    ${Media('tablet')} {
        border-radius: 0px;
        height: auto;
    }
`;
