import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import {
    gilroySemibold,
    gilroyMedium,
    gilroyBold,
} from 'shared-components/theme/fonts';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import { Parallax } from 'react-scroll-parallax';
import ScrollAnimation from 'react-animate-on-scroll';
import { baseURL } from 'shared-components/theme/helpers';

const HomeBigText = ({
    bigTextOne,
    bigTextTwo,
    bigTextColor,
    qoute,
    author,
    authorTitle,
    level,
    textSize,
    textOneleft,
    textOneRight,
    textTwoleft,
    textTwoRight,
}) => {
    console.log(bigTextTwo);
    return (
        <Wrapper level={level} textSize={textSize}>
            <BigText level={level} color={bigTextColor} textSize={textSize}>
                {bigTextOne !== undefined ? (
                    <Parallax
                        x={[textOneleft, textOneRight]}
                        slowerScrollRate={'Slower'}>
                        <OneText>{bigTextOne}</OneText>
                    </Parallax>
                ) : null}
                {bigTextTwo !== undefined ? (
                    <Parallax x={[textTwoleft, textTwoRight]}>
                        <TwoText>{bigTextTwo}</TwoText>
                    </Parallax>
                ) : null}
            </BigText>
            <Container>
                <RowCentered>
                    <Col md="6">
                        {level === 'two' ? (
                            <VpImage>
                                <img
                                    data-src={`${baseURL}assets/images/vp-image.jpg`}
                                    src={`${baseURL}assets/images/vp-image.jpg`}
                                    alt="-"
                                    className="lozad"
                                />
                            </VpImage>
                        ) : null}
                    </Col>
                    <Col md="6">
                        {qoute !== undefined ? (
                            <VpText>
                                <ScrollAnimation
                                    delay={100}
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <VpQuote>“{qoute}”</VpQuote>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    delay={400}
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <VpAuthor>
                                        <span>{author}</span> {authorTitle}
                                    </VpAuthor>
                                </ScrollAnimation>
                            </VpText>
                        ) : null}
                    </Col>
                </RowCentered>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    overflow: hidden;
    margin-top: ${(props) => {
        if (props.level === 'cxOne') {
            return props.textSize === 'small' ? '-60px' : '-120px';
        } else {
            return '0';
        }
    }};
    ${Media('xlscreens')} {
        margin-top: ${(props) => {
            if (props.level === 'cxOne') {
                return props.textSize === 'small' ? '-6.5vw' : '-11vw';
            } else if (props.level === 'athOne') {
                return '-14vw';
            } else {
                return '0';
            }
        }};
    }
    ${Media('mobile')} {
        margin-top: ${(props) => {
            if (props.level === 'cxOne') {
                return '-60px';
            } else {
                return '0';
            }
        }};
    }
`;

const BigText = styled.div`
    font-size: ${(props) => (props.textSize === 'small' ? '120px' : '240px')};
    font-family: ${gilroySemibold};
    line-height: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 9;
    padding-bottom: 20px;
    margin-bottom: ${(props) => {
        if (props.level === 'two') {
            return '-120px';
        } else {
            return '0';
        }
    }};
    color: ${(props) => props.color};
    span {
        white-space: nowrap;
        position: relative;
    }
    ${Media('xlscreens')} {
        font-size: ${(props) =>
            props.textSize === 'small' ? '9.4vw' : '15.4vw'};
        margin-bottom: ${(props) => {
            if (props.level === 'two') {
                return '-9vw';
            } else {
                return '0';
            }
        }};
    }
    ${Media('mobile')} {
        font-size: 80px;
        font-family: ${gilroyBold};
        margin-bottom: ${(props) => {
            if (props.level === 'two') {
                return '-60px';
            } else {
                return '0';
            }
        }};
    }
`;

const OneText = styled.span`
    display: inline-flex;
    align-self: flex-start;
    left: -5%;
`;

const TwoText = styled.span`
    display: inline-flex;
    align-self: flex-start;
    left: 18%;
    span {
        color: ${colors.pink['100']};
    }
`;

const VpImage = styled.div`
    img {
        max-width: 100%;
        ${Media('xlscreens')} {
            width: 33vw;
        }
    }
`;

const VpText = styled.div`
    padding-top: 50px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    ${Media('mobile')} {
        flex-direction: column;
        text-align: center;
    }
`;

const VpQuote = styled.div`
    font-size: 17px;
    font-family: ${gilroyMedium};
    margin-left: 15px;
    line-height: 1.6;
    ${Media('xlscreens')} {
        font-size: 1vw;
        margin-left: ${(props) => (props.level === 'one' ? '0' : '3vw')};
    }
    ${Media('mobile')} {
        margin-left: 0px;
        margin-bottom: 20px;
    }
`;

const VpAuthor = styled.div`
    font-size: 15px;
    line-height: 1.6;
    font-family: ${gilroyMedium};
    span {
        font-family: ${gilroyBold};
        display: block;
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        font-size: 1vw;
    }
`;

export default HomeBigText;
