import React from 'react';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';

const CaseArrowHeading = ({
    marginTop,
    marginTopSmall,
    marginBottom,
    heading,
}) => {
    return (
        <Wrapper
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginTopSmall={marginTopSmall}>
            <img src={`${baseURL}assets/images/arrow-down.svg`} alt="-" />
            {heading}
        </Wrapper>
    );
};

const Wrapper = styled.h2`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    img {
        margin-right: 20px;
    }
    ${Media('xlscreens')} {
        margin-top: ${(props) =>
            props.marginTop ? '7vw' : props.marginTopSmall ? '2vw' : '0'};
        margin-bottom: ${(props) => (props.marginBottom ? '4vw' : '0')};
        img {
            margin-right: 1.04vw;
        }
    }
    ${Media('tablet')} {
        justify-content: flex-start;
        margin-top: 50px;
        padding-bottom: 30px;
    }
`;

export default CaseArrowHeading;
