import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from 'components/layout';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import { FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { RowCenteredBoth } from 'shared-components/theme/grid-helpers';
import ScrollAnimation from 'react-animate-on-scroll';
import { Parallax } from 'react-scroll-parallax';
import Modal from 'components/modal/modal';
import EventForm from 'components/event-form/event-form';
import { handleDay } from 'shared-components/theme/helpers';
import { baseURL } from 'shared-components/theme/helpers';

const EventIndex = () => {
    const [modalOpen, setModalopen] = useState(false);
    const [name, setName] = useState('');

    const handleSuccess = (val, bool) => {
        setName(val);
        setModalopen(bool);
    };

    return (
        <Layout nofooter={true} page="event">
            <EventWrapper>
                <EventBanner>
                    <FixedSocial>
                        <p>Follow on social</p>
                        <ul>
                            <li>
                                <a
                                    href="https://twitter.com/ibexWaveZero"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <FaTwitter />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/company/ibex-global"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <FaLinkedinIn />
                                </a>
                            </li>
                        </ul>
                    </FixedSocial>
                    <Container>
                        <Row>
                            <Col lg="9">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <PageHeading>
                                        Fintech Community Speaker Series
                                    </PageHeading>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                        <ScrollAnimation animateIn="fadeInUp">
                            <Row>
                                <Col lg="7">
                                    <Row>
                                        <Col lg="6">
                                            <SpeakerHead>
                                                A new kind of speaker series,
                                                created by you, for you.
                                            </SpeakerHead>
                                        </Col>
                                        <Col lg="6">
                                            <SpeakerHeadArrow>
                                                <img
                                                    src={`${baseURL}assets/images/speaker-head-arrow.svg`}
                                                    alt="-"
                                                />
                                            </SpeakerHeadArrow>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <SubscribeText>
                                                RSVP your seat in our first
                                                speaker session!
                                            </SubscribeText>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <EventForm
                                                onSuccess={handleSuccess}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="5">
                                    <BannerDescription>
                                        <GradientText>
                                            Community Generated
                                        </GradientText>
                                        <p>
                                            The ibex Fintech Community Speaker
                                            Series is designed as a showcase for
                                            the best and brightest across the
                                            fast-changing financial services
                                            industry - as chosen by you. Each
                                            speaker is selected by the members
                                            of our fintech & technology
                                            community, with the goal of driving
                                            deeper conversations and meaningful
                                            change.
                                        </p>
                                    </BannerDescription>
                                </Col>
                            </Row>
                        </ScrollAnimation>
                    </Container>
                </EventBanner>
                <EventBigText>
                    <h2>
                        <Parallax x={['-10%', '10%']}>
                            <span>Stay tuned...</span>
                        </Parallax>
                        <Parallax x={['10%', '-10%']}>
                            <span>
                                there&apos;s more <br /> to come.
                            </span>
                        </Parallax>
                    </h2>
                    <Container>
                        <ScrollAnimation animateIn="fadeInUp">
                            <RowCenteredBoth>
                                <Col lg={{ size: 9 }}>
                                    <EventSubscribe>
                                        <SubscribeHead>
                                            Register today - No Strings Attached
                                        </SubscribeHead>
                                        <SubscribeText>
                                            Register today to claim your digital
                                            seat - we won’t bother you with
                                            spam, nor will we share your email.
                                            We just want to make sure who is
                                            attending, and how to thank them
                                            after the event.
                                        </SubscribeText>
                                    </EventSubscribe>
                                    <EventForm
                                        level="bottom"
                                        onSuccess={handleSuccess}
                                    />
                                </Col>
                            </RowCenteredBoth>
                        </ScrollAnimation>
                    </Container>
                </EventBigText>
            </EventWrapper>
            <Modal
                state={modalOpen}
                closeForm={() => setModalopen(false)}
                heading_align="center"
                heading={`Thank you for registering, ${name}! We'll be in touch soon with further details on the event. Have a great ${handleDay()}!`}
            />
        </Layout>
    );
};

const EventWrapper = styled.div`
    background-color: ${colors.event['dark']};
    min-height: calc(100vh - 1px);
    position: relative;
    &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0px;
        width: 100%;
        height: 1px;
        background-color: inherit;
    }
`;

const FixedSocial = styled.div`
    position: absolute;
    width: 175px;
    height: 50px;
    top: calc(50% - 15px);
    right: -45px;
    transform: rotate(90deg);
    transform-origin: 50% 48%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${Media('large')} {
        width: 225px;
        height: 70px;
        right: -55px;
        top: calc(50% - 65px);
    }
    p {
        margin: 0px;
        font-size: 11px;
        font-family: ${gilroyMedium};
        color: ${colors.white['100']};
        ${Media('large')} {
            font-size: 14px;
        }
    }
    ul {
        display: flex;
        margin: 0px;
        padding: 0px 0 0 15px;
        li {
            margin: 0px 5px;
            a {
                display: inline-flex;
                font-size: 12px;
                transition-duration: 0.3s;
                color: ${colors.white['100']};
                transform: rotate(-90deg);
                &:hover {
                    color: ${colors.pink['100']};
                }
                ${Media('large')} {
                    font-size: 16px;
                }
            }
        }
    }
    ${Media('tablet')} {
        top: calc(50% - 120px);
    }
    ${Media('mobile')} {
        display: none;
    }
`;

const SubscribeText = styled.p`
    font-size: 16px;
    font-family: ${gilroyMedium};
    opacity: 0.46;
    color: ${colors.white['100']};
    margin-bottom: 10px;
    ${Media('xlscreens')} {
        font-size: 1.05vw;
        letter-spacing: 0.02vw;
    }
`;

const EventBanner = styled.section`
    padding: 0px;
    background-image: url('${baseURL}assets/images/event-banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    position: relative;
    ${SubscribeText} {
        ${Media('xlscreens')} {
            margin-top: 3vw;
            margin-bottom: 2vw;
        }
    }
    ${Media('tablet')} {
        background-position: center -220px;
    }
`;

const PageHeading = styled.h1`
    font-size: 44px;
    color: ${colors.white['100']};
    line-height: 1.1;
    ${Media('xlscreens')} {
        font-size: 5.7vw;
        margin-top: 5vw;
        margin-bottom: 3.5vw;
    }
    ${Media('tablet')} {
        margin-top: 50px;
        margin-bottom: 30px;
    }
    ${Media('mobile')} {
        font-size: 36px;
    }
`;

const SpeakerHead = styled.div`
    color: ${colors.white['100']};
    font-size: 18px;
    letter-spacing: 2px;
    font-family: ${gilroySemibold};
    line-height: 1.2;
    ${Media('xlscreens')} {
        font-size: 1.05vw;
        letter-spacing: 0.15vw;
    }
`;

const SpeakerHeadArrow = styled.div`
    img {
        width: 80px;
    }
    ${Media('xlscreens')} {
        padding-top: 0.6vw;
        padding-left: 1vw;
        img {
            width: 4.9vw;
        }
    }
    ${Media('tablet')} {
        padding-top: 40px;
        display: none;
        img {
            transform: rotate(90deg) scale(0.7);
            position: relative;
            left: -30px;
            bottom: -10px;
        }
    }
`;

const BannerDescription = styled.div`
    p {
        font-family: ${gilroyMedium};
        color: ${colors.white['100']};
        line-height: 1.78;
    }
    ${Media('xlscreens')} {
        padding-left: 4vw;
        padding-top: 0.6vw;
        p {
            font-size: 1vw;
            letter-spacing: 0.02vw;
        }
    }
`;

const GradientText = styled.div`
    background: linear-gradient(to right, ${colors.velocity['gradient']});
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 18px;
    margin-bottom: 40px;
    font-family: ${gilroyBold};
    ${Media('xlscreens')} {
        font-size: 1.05vw;
        letter-spacing: 0.4vw;
        margin-bottom: 2.5vw;
    }
`;

const EventBigText = styled.section`
    padding-top: 50px;
    padding-left: 3vw;
    h2 {
        font-size: 15.7vw;
        color: ${colors.pink['10']};
        line-height: 0.8;
        -webkit-text-stroke: 1px ${colors.pink['100']};
    }
`;

const EventSubscribe = styled.div`
    padding-top: 40px;
    text-align: center;
    ${Media('xlscreens')} {
        padding-top: 3vw;
    }
`;

const SubscribeHead = styled.h3`
    font-size: 24px;
    color: ${colors.pink['100']};
    margin-bottom: 40px;
    ${Media('xlscreens')} {
        font-size: 1.5vw;
        margin-bottom: 2.8vw;
    }
`;

export default EventIndex;
