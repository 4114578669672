import React from 'react';
import { baseURL } from 'shared-components/theme/helpers';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import RetailIntro from 'components/retail/intro-section';
import { BigCircle } from 'components/industries/common';
import Loader from 'components/loader/loader';
import LazyLoad from 'react-lazyload';
import { TransformSolutionSec, PerformanceAmplifySec } from './sections';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -200,
    },
    {
        name: 'cx-solution',
        target: 'cx-solution',
        offset: -50,
    },
    {
        name: 'cx-performance',
        target: 'cx-performance',
        offset: -50,
    },
];

const retailSolutions = [
    {
        imageUrl: `${baseURL}assets/images/industries/ent-solution1.jpg`,
        title: 'Galvanize Powerful Platform Loyalty',
        para: `With expertise supporting the world’s leading streaming & entertainment brands, we’re experts in First Contact Resolution with a fundamental understanding that one interaction is frustrating; a second can be subscription-ending.`,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/ent-solution2.jpg`,
        title: 'Scale Your Growth with Global CX Teams',
        para: `From contact to insight, ibex offers layers of operational depth across a global network of in-center and @home CX teams, allowing our clients to scale rapidly and pivot quickly without fear of disruption or inconsistency in CX delivery.`,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/ent-solution3.jpg`,
        title:
            'Fortify Your Customer Lifecycle With High Performance CX Technology',
        para: `From Training Simulators to A.I. Customer Feedback Analytics, our Wave X platform provides end-to-end CX technology designed specifically for in-center and @home performance maximization. `,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/ent-solution4.jpg`,
        title: 'Keep Evolving with Continuous CX Insights',
        para: `Gain access to a business intelligence team that actively mines every interaction for optimal CX action and persistently surfaces novel insights across your customer journey.`,
    },
];

const performanceList = [
    {
        count: '01',
        imgUrl: `${baseURL}assets/images/industries/ent-performance-icon1.svg`,
        title: `Customer Support`,
        list: [
            'Email, Chat, Phone, & Social Support ',
            ' Technical Support ',
            ' Billing Support ',
            ' Account Management Support ',
            ' Back Office',
        ],
    },
    {
        count: '02',
        imgUrl: `${baseURL}assets/images/industries/ent-performance-icon2.svg`,
        title: `On-Demand <br /> CX Intelligence`,
        list: [
            'Defector Analysis ',
            'Detractor Management ',
            'Complexity Mapping ',
            'Journey Mapping ',
            'Value Stream Mapping',
        ],
    },
    {
        count: '03',
        imgUrl: `${baseURL}assets/images/industries/ent-performance-icon3.svg`,
        title: `First Call <br /> Resolution Expertise`,
        list: [
            'Simulated Training Platform',
            'In-Depth Agent Coaching Process & Technology',
            'Speed-to-Green Optimization',
            'Agent Performance Gamification',
        ],
    },
    {
        count: '04',
        imgUrl: `${baseURL}assets/images/industries/ent-performance-icon4.svg`,
        title: `The End-to-End <br /> CX Data Layer`,
        list: [
            'End-to-End BI & Analytics',
            'Social Media Monitoring',
            'Proactive 5-Star Surveying & Feedback Analytics',
            'Issue Alerts & Resolution',
            'Text & Sentiment Analytics',
        ],
    },
];

const amplifyList = [
    {
        icon: `${baseURL}assets/images/industries/rocket.svg`,
        title: 'Fast.',
        para: `We hire selectively and train expertly to ensure your agent teams hit key KPIs quickly and consistently, driving flawless CX continuity once ibex is switched on. `,
    },
    {
        icon: `${baseURL}assets/images/industries/diamond.svg`,
        title: 'Flawless',
        para: `Our teams have in-depth experience working with on-demand content & entertainment brands around the globe, and they’re ready to deploy this expertise for you. `,
    },
    {
        icon: `${baseURL}assets/images/industries/chameleon.svg`,
        title: 'Flexible',
        para: `We are able to seamlessly pivot agent teams to align with expanding work types or LOBs, optimizing quickly for shifting growth needs, issue demands, and changing CX channel requirements. `,
    },
    {
        icon: `${baseURL}assets/images/industries/crystal-ball.svg`,
        title: 'Predictable.',
        para: `Anyone can do well on a single program, but delivering consistent performance requires a battle-tested methodology for repeatable, predictable, and reliable program execution that you can set your watch to. `,
    },
];

const sections = [
    {
        component: <TransformSolutionSec retailSolutions={retailSolutions} />,
        isScrollNav: false,
    },
    {
        component: (
            <PerformanceAmplifySec
                performanceList={performanceList}
                amplifyList={amplifyList}
            />
        ),
        isScrollNav: true,
    },
];

const StreamingEntertainment = () => {
    return (
        <Layout>
            <Breadcrumbs>
                <li>Industry</li>
                <li>Streaming & Entertainment</li>
            </Breadcrumbs>

            <BigCircle
                imgUrl={`${baseURL}assets/images/ent-intro-play-icon.svg`}
                top="11.45vw"
                right="45%"
                imgWidth={1131}
                imgHeight={980}
            />
            <RetailIntro
                rightImg={`${baseURL}assets/images/industries/ent-intro-bg.png`}
                tag="INDUSTRY"
                introHeading="On-Demand <br /> Streaming & <br /> Entertainment"
                introPara="Protect your content investment with powerful <br /> customer experiences."
                introBtnText="Connect with us."
                right="-5%"
                top="-17.3vw"
                id="home"
            />
            {sections &&
                sections.map((section, index) => {
                    return (
                        <div
                            key={index}>
                            {section.isScrollNav && (
                                <ScrollNav data={scrollNavData} />
                            )}
                            {section.component}
                        </div>
                    );
                })}
        </Layout>
    );
};

export default StreamingEntertainment;
