import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from '@bootstrap-styled/v4';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { baseURL } from 'shared-components/theme/helpers';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import DemoRequestForm from 'components/social-rep-man/demo-request-form';
import SRMIntroSection from 'components/social-rep-man/intro-section';
import {
    ContainerWideSRM,
    SRMCallActionSection,
    SRMonitoringSection,
    SRMSectionHeading,
} from 'components/social-rep-man/common';
import MainPara from 'shared-components/main-para/main-para';
import VerticalCards from 'components/social-rep-man/vertical-cards';
import ScrollAnimation from 'react-animate-on-scroll';
import Loader from 'components/loader/loader';
import LazyLoad from 'react-lazyload';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -210,
    },
    {
        name: 'monitor',
        target: 'monitor',
        offset: 0,
    },
    {
        name: 'manage',
        target: 'manage',
        offset: 0,
    },
];

const monitorList = [
    {
        icon: `${baseURL}assets/images/tech/witness-monitor-icon-1.svg`,
        title: 'Monitor',
        para: 'Gain complete insight into  agent activity ',
    },
    {
        icon: `${baseURL}assets/images/tech/witness-monitor-icon-2.svg`,
        title: 'Identify',
        para: 'Detect infractions on calls, screens and keyboards ',
    },
    {
        icon: `${baseURL}assets/images/tech/witness-monitor-icon-3.svg`,
        title: 'Enforce',
        para: 'Strengthen regulatory compliance ',
    },
    {
        icon: `${baseURL}assets/images/tech/witness-monitor-icon-4.svg`,
        title: 'Empower',
        para: 'Deter behavior that could threaten your brand ',
    },
];

const manageContent = [
    {
        ImageRight: false,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/witness-monitor-tag-1.svg`,
        VerticalCardHeading: `Automated fraud detection and investigation toolset`,
        VerticalCardParagraph: `Witness is a comprehensive fraud prevention, detection, and compliance management system designed to ease and expedite fraud investigation workflows by identifying and highlighting PII-related infractions and potential non-compliance events. `,
        VerticalCardImage: `${baseURL}assets/images/tech/witness-monitor-img-1.svg`,
    },
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/witness-monitor-tag-2.svg`,
        VerticalCardHeading: `Layered monitoring of agent activity`,
        VerticalCardParagraph: `Maintain full awareness of every agent activity by capturing synchronized screen and key punch data. Get complete insight into agent activities, whether on a call, behind a virtual machine, or across the agent’s desktop. `,
        VerticalCardImage: `${baseURL}assets/images/tech/witness-monitor-img-2.svg`,
    },
    {
        ImageRight: false,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/witness-monitor-tag-3.svg`,
        VerticalCardHeading: `Predictive alerts for proactive fraud monitoring `,
        VerticalCardParagraph: `Proactively detect non-compliant activity by configuring alerts for specific scenarios, such assuspicious changes to credit card information<br /> or inappropriate sign-ins. `,
        VerticalCardImage: `${baseURL}assets/images/tech/witness-monitor-img-3.svg`,
    },
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/witness-monitor-tag-4.svg`,
        VerticalCardHeading: `Automated Computer Vision`,
        VerticalCardParagraph: `Capture specific fields from agent-accessed CRMs and web applications and gain more insight into the data collected through optical character recognition (OCR). `,
        VerticalCardImage: `${baseURL}assets/images/tech/witness-monitor-img-4.svg`,
    },
    {
        ImageRight: false,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/witness-monitor-tag-5.svg`,
        VerticalCardHeading: `Real-timeactivity awareness`,
        VerticalCardParagraph: `Monitor agent activity in real-time<br /> through an easy-to-use interface that makes screenshots and key logs instantly available for analysis.  `,
        VerticalCardImage: `${baseURL}assets/images/tech/witness-monitor-img-5.svg`,
    },
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/witness-monitor-tag-6.svg`,
        VerticalCardHeading: `End-to-end on-click data reporting `,
        VerticalCardParagraph: `Generate detailed reports containing data collected from agent CRMs and applications and easily export them to MS Excel. `,
        VerticalCardImage: `${baseURL}assets/images/tech/witness-monitor-img-6.svg`,
    },
    {
        ImageRight: false,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/witness-monitor-tag-7.svg`,
        VerticalCardHeading: `Secure user authentication and ease of access `,
        VerticalCardParagraph: `Log into Witness seamlessly using network credentials with secure single sign-on (SSO) technology. `,
        VerticalCardImage: `${baseURL}assets/images/tech/witness-monitor-img-7.svg`,
    },
];

const TechWitness = () => {
    const [demoRequestForm, setDemoRequestForm] = useState(false);

    return (
        <Layout>
            <Breadcrumbs>
                <li>Wave X</li>
                <li>Technology</li>
            </Breadcrumbs>

            <DemoRequestForm
                handleDemoForm={(val) => setDemoRequestForm(val)}
                formState={demoRequestForm}
                marketFormId="1085"
            />

            <SRMIntroSection
                preheading="ibex Witness"
                heading="Contact Center Compliance and Fraud Monitoring"
                para="Your comprehensive fraud detection system, designed for the contact center. "
                mainImg={`${baseURL}assets/images/tech/witness-intro.svg`}
                textureImg={`${baseURL}assets/images/tech/witness-texture.svg`}
                textureImgPosition="back"
                onBtnClick={() => setDemoRequestForm(true)}
            />
            
                <SRMonitoringSection
                    id="monitor"
                    heading="Monitor Agent Behavior At Every Touchpoint"
                    paragraph="Witness provides maximum awareness and visibility into agent interactions. Actively monitor desktops, calls, keystrokes, and digital activity. Reduce risk by flagging issues early so you can correct non-compliant behavior and keep personally identifiable information (PII) safe. "
                    listData={monitorList}
                />
            
                <ManageSection id="manage">
                    <ContainerWideSRM>
                        <Row>
                            <Col sm="12">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <SRMSectionHeading>
                                        Advanced anti-fraud technology for @home
                                        & contact center CX programs
                                    </SRMSectionHeading>
                                    <MainPara align="center">
                                        Millions of data points flow through
                                        your contact center every day, and it
                                        only takes one fraudulent or
                                        non-compliant action to severely damage
                                        your brand. Witness is a comprehensive
                                        brand protection toolset designed to
                                        help you mitigate risk, protect revenue,
                                        and provide powerful peace of mind.
                                    </MainPara>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                        {manageContent &&
                            manageContent.length > 0 &&
                            manageContent.map((item, index) => {
                                return (
                                    <VerticalCards
                                        key={index}
                                        ImageRight={item.ImageRight}
                                        ImageRightColEqual={item.ImageRight}
                                        padTopSmall={item.padTopSmall}
                                        CardTagImg={item.CardTagImg}
                                        VerticalCardHeading={
                                            item.VerticalCardHeading
                                        }
                                        VerticalCardParagraph={
                                            item.VerticalCardParagraph
                                        }
                                        VerticalCardImage={
                                            item.VerticalCardImage
                                        }
                                        sideExtraSmall
                                    />
                                );
                            })}
                    </ContainerWideSRM>
                </ManageSection>
            
                <SRMCallActionSection
                    heading="  A proven technology for fraud detection and compliance management in the contact center "
                    para="Prevent fraud, deter non-compliance, keep your customers safe, and protect your brand with ibex Witness. "
                    btnText="Book a Demo"
                    onBtnClick={() => setDemoRequestForm(true)}
                />
                <ScrollNav data={scrollNavData} />
        </Layout>
    );
};

const ManageSection = styled.section``;

export default TechWitness;
