import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import Button from 'shared-components/button/button';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { WebinarMainHeading } from './main-heading';
import { WebinarSubHeading } from './sub-heading';
import { baseURLPage } from 'shared-components/theme/helpers';

export const WebinarContentCTA = ({ heading, subheading, btnText }) => {
    return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <Wrapper>
                <WebinarMainHeading color={colors.white['100']}>
                    {heading}
                </WebinarMainHeading>
                <WebinarSubHeading>{subheading}</WebinarSubHeading>
                <TalkToUsBtn>
                    <Button href={`${baseURLPage}contact-us`} round>
                        {btnText ? btnText : 'Let’s get started'}
                    </Button>
                </TalkToUsBtn>
            </Wrapper>
        </ScrollAnimation>
    );
};

const Wrapper = styled.section`
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${colors.socialRep['brandsBG']};
    text-align: center;
    ${Media('tablet')} {
        padding: 50px 20px;
    }
`;

const TalkToUsBtn = styled.div`
    margin-top: 60px;
    ${Media('xlscreens')} {
        margin-top: 3.125vw;
        button,
        a {
            min-width: 10.3vw;
        }
    }
    ${Media('tablet')} {
        margin-top: 30px;
    }
`;
