import React from 'react';
import colors from 'shared-components/theme/colors';
import { sourceSansPro } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
export const DEPara = ({ light, children }) => {
    return <Wrapper light={light}>{children}</Wrapper>;
};

const Wrapper = styled.div`
    margin-top: 0px;
    font-family: ${sourceSansPro};
    font-size: 20px;
    line-height: 1.5;
    color: ${(props) =>
        props.light ? colors.white['100'] : colors.black['100']};
    margin-bottom: ${(props) => (props.noMarginBottom ? '0' : '40px')};
    p {
        font-size: inherit;
        color: inherit;
        line-height: inherit;
        letter-spacing: 0;
        font-family: inherit;
        margin-bottom: inherit;
    }
    ${Media('xlscreens')} {
        font-size: 1.25vw;
        margin-bottom: ${(props) => (props.noMarginBottom ? '0' : '2.08vw')};
    }
    ${Media('tablet')} {
        margin-bottom: 20px;
        p {
            margin-bottom: 40px;
        }
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
