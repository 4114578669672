import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const WebinarMainHeading = ({ children, as, noMargin, color, size }) => {
    return (
        <Wrapper as={as} noMargin={noMargin} color={color} size={size}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.h2`
    font-size: ${(props) => (props.size === 'small' ? '50px' : '60px')};
    font-size: ${(props) => props.size === 'smallest' && '40px'};
    font-family: ${gilroyBold};
    line-height: 1.15;
    color: ${(props) => (props.color ? props.color : colors.gray_dark['100'])};
    letter-spacing: -0.4px;
    margin: ${(props) => (props.noMargin ? '0' : '30px 0')};
    ${Media('xlscreens')} {
        font-size: ${(props) =>
            props.size === 'small' ? '2.86vw' : '3.125vw'};
        font-size: ${(props) => props.size === 'smallest' && '2.29vw'};
    }
    ${Media('tablet')} {
        font-size: ${(props) => (props.size === 'small' ? '40px' : '48px')};
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: ${(props) => (props.size === 'small' ? '30px' : '36px')};
    }
`;
