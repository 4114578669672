import React from 'react';

import styled from 'styled-components';
import { CXSectionIntro } from './cx-section-intro';
import { Para } from './para';
import { BlockHeading } from './block-heading';
import { Col, Row } from '@bootstrap-styled/v4';
import { BigHeading } from './big-heading';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';

export const CXPoint1 = ({ active }) => {
    return (
        <>
            <CXSectionIntro
                progress={0.85}
                active={active}
                source="Source: IDC"
                progressImg={`${baseURL}assets/images/ebook/cx-progress1.svg`}
                introPara="<span>85% of companies</span> agree that improved EX and employee engagement translate to better CX, customer satisfaction, and revenues for their organization."
            />
            <BigHeading>
                CX leaders are beginning to understand the primary driver of
                great CX – <u>great contact center employee experiences.</u>
            </BigHeading>
            <Para>
                The most straightforward way to achieve fast, reliable results is to find a contact center provider who operates on an employee-first methodology. Consider the following:
            </Para>
            <BlockHeading>Start with Employee Success in Mind</BlockHeading>
            <Para>
                It’s natural to think of things like compensation, benefits, and
                amenities when it comes to EX. But at every person’s core there
                is a desire to be valued and no amenity can make up for a
                person’s lack of self-esteem. Help them do their job well.
            </Para>
            <BlockHeading>Recruitment & Onboarding</BlockHeading>
            <Para>
                BPOs must start investing in the applicant experience to attract and retain great talent. Once hired, a positive onboarding experience engages agents, aids NPS, creates a commitment to the brand, and makes new agents feel like part of the team.
            </Para>
            <Row>
                <Col lg="6">
                    <Section1Video>
                        <iframe
                            width="100%"
                            height="100%"
                            src={'https://www.youtube.com/embed/eiiyEjjunUQ'}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    </Section1Video>
                </Col>
                <Col lg="6">
                    <BlockWrapper>
                        <BlockHeading>
                            Internal Development & Growth
                        </BlockHeading>
                        <Para>
                            Introducing opportunities for development and growth helps agents feel empowered. Agents feel they are gaining new skills that will help their future career success — and will help your organization succeed, too.
                        </Para>
                    </BlockWrapper>
                </Col>
                <Col lg="6">
                    <BlockHeading>Events & Rewards</BlockHeading>
                    <Para>
                        The more agents are recognized for their achievements, the more likely they will be engaged and drive your program forward. Employee events also help energize employees, while showing them gratitude for their hard work.
                    </Para>
                </Col>
                <Col lg="6">
                    <BlockWrapper>
                        <BlockHeading>Culture & Community</BlockHeading>
                        <Para>
                            A contact center that emphasizes culture and diversity will have more engaged agents who are bought in to the mission of your brand. Look for a partner that fosters a rich culture and sense of community and understands its employees as individuals.
                        </Para>
                    </BlockWrapper>
                </Col>
            </Row>
            <BigHeading marginBottom>
                For a full run-down of the key EX strategies your contact center
                provider must follow, check out our pocket guide:&nbsp;
                <span>
                    <u>
                        The Top 5 Employee Experience Strateiges for CX Success
                    </u>
                </span>
            </BigHeading>
        </>
    );
};

const BlockWrapper = styled.div`
    padding-left: 40px;
    ${Media('xlscreens')} {
        padding-left: 2.08vw;
    }
    ${Media('tablet')} {
        padding-left: 0;
    }
`;

const Section1Video = styled.div`
    margin-bottom: 50px;
    iframe {
        width: 100%;
        height: 250px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 2.6vw;
        iframe {
            height: 15.6vw;
        }
    }
    ${Media('tablet')} {
        iframe {
            height: 400px;
        }
    }
    ${Media('mobile')} {
        iframe {
            height: 250px;
        }
    }
`;
