import React, { useState } from 'react';
import styled from 'styled-components';
import { baseURL } from 'shared-components/theme/helpers';
import Layout from 'components/layout';
import colors from 'shared-components/theme/colors';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import DemoRequestForm from 'components/social-rep-man/demo-request-form';
import {
    ModerateSection,
    BrandSection,
    BusinessValueSection,
    SynchronizeSection,
    StrategySection,
} from './sections';
import SRMIntroSection from 'components/social-rep-man/intro-section';
import LazyLoad from 'react-lazyload';
import Loader from 'components/loader/loader';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -210,
    },
    {
        name: 'moderate',
        target: 'moderate',
        offset: 0,
    },
    {
        name: 'brands',
        target: 'brands',
        offset: 0,
    },
    {
        name: 'business-value',
        target: 'business-value',
        offset: 0,
    },
    {
        name: 'synchronize',
        target: 'synchronize',
        offset: 0,
    },
    {
        name: 'strategy',
        target: 'strategy',
        offset: 0,
    },
];

const lazySections = [
    {
        id: 'moderate',
        section: <ModerateSection />,
    },
    {
        id: 'brands',
        section: <BrandSection />,
    },
    {
        id: 'business-value',
        section: <BusinessValueSection />,
    },
    {
        id: 'synchronize',
        section: <SynchronizeSection />,
    },
    {
        id: 'strategy',
        section: <StrategySection />,
    },
];

const SocialRepMan = () => {
    const [demoRequestForm, setDemoRequestForm] = useState(false);
    return (
        <Layout
            page="SocialRepMan"
            handleCXDemoForm={(val) => setDemoRequestForm(val)}>
            <DemoRequestForm
                handleDemoForm={(val) => setDemoRequestForm(val)}
                formState={demoRequestForm}
                marketFormId="1098"
            />
            <TopSection>
                <Breadcrumbs>
                    <li>Wave X</li>
                    <li>Technology</li>
                </Breadcrumbs>

                <SRMIntroSection
                    preheading="Social Reputation Management"
                    heading="Turn the internet <br /> into your focus group."
                    para="Our all-in-one social engagement platform allows <br /> you to centralize, answer, and influence online <br /> feedback & reviews from 90+ sites, mobilizing your <br /> CX teams for complete social media insight, <br /> action, and evolution."
                    mainImg={`${baseURL}assets/images/socialrepman-intro.svg`}
                    textureImg={`${baseURL}assets/images/texture-one.svg`}
                    textureImgPosition="back"
                    onBtnClick={() => setDemoRequestForm(true)}
                />
            </TopSection>

            {lazySections && lazySections.length > 0
                ? lazySections.map((item, index) => {
                      return (
                          <div id={item.id} key={index}>
                               {lazySections.length === index + 1 ? (
                                    <ScrollNav data={scrollNavData} />
                                ) : null}
                                {item.section}
                          </div>
                      );
                  })
                : null}
        </Layout>
    );
};

const TopSection = styled.div`
    background-color: ${colors.gray_lightest['100']};
    .parallax-inner {
        height: 100%;
    }
`;

export default SocialRepMan;
