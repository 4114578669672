import React from 'react';
import { Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyLight, gilroyBold, gilroySemibold } from '../theme/fonts';
import Media from '../theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import { baseURL } from 'shared-components/theme/helpers';
const WhyOutSourceBox = ({
    sectionId,
    lessBottom,
    outBoxCol,
    hedNumber,
    hedText,
    activateSubHeadingBox,
    mainBoxHeading,
    boxParagraph,
    boxImage,
    statsData,
}) => {
    const mainsectionid = Number(sectionId);
    const increment = 1;
    const totalvalue = mainsectionid + increment;
    return (
        <Wrapper id={sectionId}>
            <ScrollAnimation animateIn="fadeInUp">
                <NextButton>
                    <span>NEXT</span>
                    <a href={'#' + 0 + totalvalue} className="arrow-box">
                        <img
                            src={`${baseURL}assets/images/down-white-arrow.svg`}
                            alt=""
                        />
                    </a>
                </NextButton>
                <Row>
                    <Col lg={outBoxCol !== undefined ? outBoxCol : '5'}>
                        <SubHeadingBox
                            activateSubHeadingBox={activateSubHeadingBox}
                            lessBottom={lessBottom}>
                            <span>{hedNumber}</span>
                            <span className="hed">{hedText}</span>
                        </SubHeadingBox>
                        <MainBoxHeading>{mainBoxHeading}</MainBoxHeading>
                        <BoxParagraph>{boxParagraph}</BoxParagraph>
                    </Col>
                    <Col lg="6">
                        <ImageBox>
                            <img src={boxImage} alt="" />
                        </ImageBox>
                    </Col>
                </Row>
                <FlexBoxWrapper>
                    <Row>
                        {statsData !== undefined && statsData.length > 0
                            ? statsData.map((item, index) => {
                                  return (
                                      <Col lg={item.columnNumber} key={index}>
                                          <TextFlexBox>
                                              <span className="count">
                                                  {item.statsCount}
                                              </span>
                                              <p className="text-content">
                                                  {item.statsText}
                                              </p>
                                          </TextFlexBox>
                                      </Col>
                                  );
                              })
                            : null}
                    </Row>
                </FlexBoxWrapper>
            </ScrollAnimation>
        </Wrapper>
    );
};
const ImageBox = styled.div`
    ${Media('tablet')} {
        img {
            width: 100%;
        }
    }
`;
const TextFlexBox = styled.div`
    display: flex;
    margin-bottom: 50px;
    .count {
        color: #ffffff;
        font-size: 21px;
    }
    .text-content {
        color: #ffffff;
        font-size: 14px;
        padding: 0 0px 0 20px;
        margin-top: 0;
    }
    ${Media('tablet')} {
        margin-bottom: 10px;
    }
`;
const MainBoxHeading = styled.h3`
    font-family: ${gilroyBold};
    font-size: 22px;
    color: ${colors.white['100']};
    ${Media('xlscreens')} {
        font-size: 1.15vw;
    }
`;
const BoxParagraph = styled.p`
    font-family: ${gilroyLight};
    font-size: 18px;
    color: ${colors.white['100']};
    ${Media('xlscreens')} {
        font-size: 0.94vw;
        margin-bottom: 5vw;
    }
`;
const Wrapper = styled.article`
    font-size: 15px;
    line-height: 1.6;
    color: #000;
    font-family: ${gilroySemibold};
    position: relative;
    ${Media('xlscreens')} {
        font-size: 1.1vw;
        padding: 2vw 0;
    }
    ${Media('mobile')} {
        font-size: 14px;
        br {
            display: none;
        }
    }
`;
const NextButton = styled.div`
    width: 65px;
    position: absolute;
    right: 0;
    top: 350px;
    z-index: 11;
    ${Media('xlscreens')} {
        top: 18.32vw;
    }
    span {
        font-size: 14px;
        color: #ffffff;
        transform: rotate(-90deg);
        display: block;
        position: relative;
        top: -40px;
        ${Media('xlscreens')} {
            top: -2.09vw;
        }
    }

    a {
        width: 65px;
        height: 65px;
        background-color: #000;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    ${Media('tablet')} {
        display: none;
    }
`;
const SubHeadingBox = styled.div`
    margin: 50px 0 100px;
    border-top: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 5px 0;
    display: ${(props) => (props.activateSubHeadingBox ? 'flex' : 'none')};
    justify-content: space-between;
    ${Media('xlscreens')} {
        margin: ${(props) =>
            props.lessBottom ? '2.6vw 0 1vw' : '2.6vw 0 5.2vw'};
    }
    span {
        font-family: ${gilroySemibold};
        color: #fff;
        font-size: 16px;
        ${Media('xlscreens')} {
            font-size: 0.95vw;
        }
        &.hed {
            color: rgba(255, 255, 255, 0.5);
        }
    }
    ${Media('tablet')} {
        margin: 15px 0px;
    }
`;
const FlexBoxWrapper = styled.div`
    ${Media('tablet')} {
        margin-top: 30px;
    }
`;
export default WhyOutSourceBox;
