import React from 'react';
import Layout from 'components/layout';
import {
    BottomSection,
    TechPage,
    WaveXIntro,
    SubIntro
} from 'components/technology/wavex';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { baseURL, baseURLPage } from 'shared-components/theme/helpers';
import styled from 'styled-components';

const performancePages = [
    {
        title: 'Training Simulator',
        para:
            'Training environments built to simulate real-world customer support and engagement scenarios, advancing speed-to-proficiency and performance by 3x.',
        pageLink: `${baseURLPage}technology/training-simulator`,
        thumbImg: `${baseURL}assets/images/tech/training-simulator-tech.svg`,
        rowReverse: false,
    },
    {
        title: 'Performance Gamification',
        para:
            'Performance gamification technology designed to incentivize agent performance for enhanced CX outcomes.',
        pageLink: `${baseURLPage}technology/scorecard`,
        thumbImg: `${baseURL}assets/images/tech/perform-gamifi-tech.svg`,
        rowReverse: true,
    },
    {
        title: 'Performance Coaching',
        para:
            'Performance gamification technology designed to incentivize agent performance for enhanced CX outcomes.',
        pageLink: `${baseURLPage}technology/inspire`,
        thumbImg: `${baseURL}assets/images/tech/perform-coach-tech.svg`,
        rowReverse: false,
    },
];

const CXPerformanceWaveX = () => {
    return (
        <Layout page="wavex">
            <Breadcrumbs>
                <li>Technology</li>
                <li>WaveX</li>
            </Breadcrumbs>
            <WaveXIntro
                pageTag="Wave X"
                pageHeading="The CX Performance <br/>Acceleration Suite."
                pagePara="Ensure predictable CX performance outcomes from your contact center team."
                introImg={`${baseURL}assets/images/tech/performance-suit-2.png`}
                gradientImg={`${baseURL}assets/images/tech/performance-suit.png`}
                imgHeight={'582'}
                imgWidth={'725'}
                from={{
                    transform: 'translateY(0) scale(1)',
                }}
                to={{
                    transform: 'translateY(18vw) scale(1.7)',
                }}
            />
            <SubIntro
                imgURL={`${baseURL}assets/images/tech/performance-suit-2.png`}
                subHeading={
                    'Improve speed-to-performance, CX outcomes, and retention.'
                }
            />
            <TechPages>
                {performancePages &&
                    performancePages.length > 0 &&
                    performancePages.map((page, index) => {
                        return (
                            <TechPage
                                key={index}
                                $reverse={page.rowReverse}
                                title={page.title}
                                para={page.para}
                                thumbImg={page.thumbImg}
                                pageLink={page.pageLink}
                            />
                        );
                    })}
            </TechPages>
            <BottomSection
                heading="Ready to Upgrade Your CX?"
                subHeading="Performance gamification technology designed to incentivize agent <br/>performance for enhanced CX outcomes."
            />
        </Layout>
    );
};
const TechPages = styled.div``;
export default CXPerformanceWaveX;
