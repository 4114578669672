import React from 'react';
import Button from 'shared-components/button/button';
import colors from 'shared-components/theme/colors';
import {
    axiformaBold,
    gilroyMedium,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const FrostBanner = (props) => {
    const screenWidth = window.innerWidth;
    const {
        desktop_banner,
        tablet_banner,
        mobile_banner,
        banner_link,
        tagline_or_logo,
        banner_logo,
        banner_tagline,
        tagline_color,
        banner_title,
        title_color,
        banner_sub_title,
        sub_title_color,
        button_text,
    } = props;
    const handleImgView = () => {
        if (screenWidth > 1000) {
            return desktop_banner;
        } else if (screenWidth <= 1000 && screenWidth > 475) {
            return tablet_banner;
        } else {
            return mobile_banner;
        }
    };
    return (
        <Wrapper>
            <BannerWrapper bgImg={handleImgView()}>
                <TaglineWrapper>
                    {tagline_or_logo ? (
                        tagline_or_logo === 'Tagline' ? (
                            <Tagline color={tagline_color}>
                                {banner_tagline}
                            </Tagline>
                        ) : (
                            <Logo>
                                <img src={banner_logo} alt="-" />
                            </Logo>
                        )
                    ) : (
                        ''
                    )}
                </TaglineWrapper>
                <TitleWrapper>
                    {banner_title && (
                        <BannerTitle color={title_color}>
                            {ReactHtmlParser(banner_title)}
                        </BannerTitle>
                    )}
                    {banner_sub_title && (
                        <SubTitle color={sub_title_color}>
                            {ReactHtmlParser(banner_sub_title)}
                        </SubTitle>
                    )}
                </TitleWrapper>
                <ReadMoreBtn>
                    <Button
                        href={banner_link}
                        round
                        color={colors.pink['100']}
                        target={'_blank'}
                        bgColor={colors.white['100']}>
                        {button_text}
                    </Button>
                </ReadMoreBtn>
            </BannerWrapper>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    z-index: 99;
`;
const BannerWrapper = styled.div`
    width: 100%;
    background: ${(props) =>
        props.bgImg
            ? `url(${props.bgImg})`
            : colors.technologyDropdown['purpleBg']};
    background-size: cover;
    background-repeat: no-repeat;
    img {
        width: 100%;
    }
    padding: 25px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${Media('xlscreens')} {
        padding: 1.7vw 6vw 1.7vw 3.9vw;
    }
    ${Media('tablet')} {
        padding: 40px 25px;
    }
    ${Media('mobile')} {
        padding: 25px 30px;
        display: block;
    }
`;

const TaglineWrapper = styled.div`
    max-width: 20%;
    ${Media('mobile')} {
        width: 100%;
    }
`;

const Tagline = styled.div`
    font-family: ${gilroySemibold};
    letter-spacing: 4px;
    color: ${(props) => (props.color ? props.color : colors.white['100'])};
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    ${Media('xlscreens')} {
        font-size: 1.04vw;
    }
    ${Media('tablet')} {
        font-size: 12px;
    }
    ${Media('mobile')} {
        margin-top: 0px;
        margin-bottom: 17px;
        font-size: 7px;
        text-align: left;
    }
`;

const Logo = styled.div`
    img {
        height: 100%;
        object-fit: contain;
    }
    ${Media('xlscreens')} {
        height: 2vw;
        max-width: 10vw;
    }
    ${Media('tablet')} {
        height: 30px;
        width: 100px;
    }
`;

const TitleWrapper = styled.div`
    ${Media('xlscreens')} {
        flex-grow: 1;
        text-align: left;
        padding-left: 7vw;
        padding-right: 3vw;
    }
    ${Media('tablet')} {
        padding: 0px 20px;
    }
    ${Media('mobile')} {
        padding: 0;
    }
`;

const BannerTitle = styled.h2`
    color: ${(props) => (props.color ? props.color : colors.white['100'])};
    font-family: ${gilroySemibold};
    margin: 10px 0;
    line-height: 1.28;
    ${Media('xlscreens')} {
        font-size: 1.57vw;
        margin: 0;
    }
    ${Media('tablet')} {
        font-size: 18px;
        text-align: center;
    }
    ${Media('mobile')} {
        font-size: 12px;
        text-align: left;
    }
`;

const SubTitle = styled.h3`
    color: ${(props) => (props.color ? props.color : colors.white['100'])};
    font-family: ${gilroyMedium};
    margin: 0;
    ${Media('xlscreens')} {
        font-size: 1.04vw;
        line-height: 1.28;
    }
    ${Media('tablet')} {
        font-size: 12px;
        text-align: center;
    }
    ${Media('mobile')} {
        font-size: 8px;
        margin-bottom: 15px;
        text-align: left;
    }
`;

const ReadMoreBtn = styled.div`
    a {
        font-family: ${axiformaBold};
        &:hover {
            color: ${colors.white['100']};
            &:before {
                transform: scale(1);
                opacity: 1;
                background-color: ${colors.pink['100']};
            }
        }
        ${Media('xlscreens')} {
            padding: 0.53vw 1.5vw;
        }
        ${Media('tablet')} {
            min-width: 80px;
            font-size: 12px;
            padding: 8px 20px;
        }
        ${Media('mobile')} {
            font-size: 8px;
        }
    }
`;

export default FrostBanner;
