import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import MainHeading from 'shared-components/main-heading/main-heading';
import MainPara from 'shared-components/main-para/main-para';
import BannerMorph from 'components/banner-morph/banner-morph';
import { gilroySemibold } from 'shared-components/theme/fonts';
import ReactHtmlParser from 'react-html-parser';
import { baseURL } from 'shared-components/theme/helpers';

const toolset = [
    {
        row_content: [
            {
                icon: `${baseURL}assets/images/glide-paths.svg`,
                title: 'Glide Paths',
            },
            {
                icon: `${baseURL}assets/images/AQM.svg`,
                title: 'Agent Quartile <br /> Management',
            },
            {
                icon: `${baseURL}assets/images/top-bottom.svg`,
                title: 'Top Vs Bottom',
            },
            {
                icon: `${baseURL}assets/images/impact-calc.svg`,
                title: 'Impact Calc',
            },
        ],
    },
    {
        row_content: [
            {
                icon: `${baseURL}assets/images/speech.svg`,
                title: 'Speech & <br /> Text Analytics',
            },
            {
                icon: `${baseURL}assets/images/defect.svg`,
                title: 'Defect Pareto',
            },
            {
                icon: `${baseURL}assets/images/waterfall.svg`,
                title: 'KPI Water Fall',
            },
            {
                icon: `${baseURL}assets/images/dsat-reviews.svg`,
                title: 'DSAT Reviews',
            },
            {
                icon: `${baseURL}assets/images/value-stream.svg`,
                title: 'Value Stream Map',
            },
        ],
    },
    {
        row_content: [
            {
                icon: `${baseURL}assets/images/dream-team.svg`,
                title: 'Dream Team <br /> Profile',
            },
            {
                icon: `${baseURL}assets/images/correlation.svg`,
                title: 'KPI Correlation',
            },
            {
                icon: `${baseURL}assets/images/process.svg`,
                title: 'Process Complexity',
            },
            {
                icon: `${baseURL}assets/images/speedometer.svg`,
                title: 'DSAT Reviews',
            },
            {
                icon: `${baseURL}assets/images/call.svg`,
                title: 'Call Silence Ratio',
            },
        ],
    },
];

export const BIntroSection = () => {
    return (
        <BIIntro>
            <Container>
                <div>
                    <RowCentered>
                        <Col lg="6">
                            <MainHeading noMargin={true}>
                                Pair interaction with insight.
                            </MainHeading>
                        </Col>
                        <Col lg={{ size: 4, offset: 1 }}>
                            <MainPara>
                                Our sophisticated Business Intelligence and
                                Professional Services division can discover
                                useful insights from within your customer
                                interaction data, revealing areas for potential
                                program enhancement and cost reduction.
                            </MainPara>
                        </Col>
                    </RowCentered>
                </div>
            </Container>
            <BannerMorph image={`${baseURL}assets/images/bi-intro-video.jpg`} />
            <Container>
                <Row>
                    <Col md="12">
                        <div>
                            <BIIntroPoints>
                                <li>
                                    Analyze interactions across the customer
                                    lifecycle
                                </li>
                                <li>
                                    Discover triggers for customer contact &
                                    churn
                                </li>
                                <li>
                                    Reduce Cost & Improve Program Performance
                                </li>
                            </BIIntroPoints>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div>
                            <BiToolsetWrapper>
                                <h2>
                                    A BI toolset for client insight <br /> &
                                    global customer success.
                                </h2>
                                {toolset && toolset.length > 0
                                    ? toolset.map((item, index) => {
                                          return (
                                              <BiToolsetRow key={index}>
                                                  {index === 0 && (
                                                      <ToolMain>
                                                          Enterprise-Grade
                                                          <br /> BI Toolset
                                                          <img
                                                              src={`${baseURL}assets/images/small-arrow-pink.svg`}
                                                              alt="-"
                                                          />
                                                      </ToolMain>
                                                  )}
                                                  {item.row_content &&
                                                  item.row_content.length > 0
                                                      ? item.row_content.map(
                                                            (inner, ind) => {
                                                                return (
                                                                    <Toolbox
                                                                        key={
                                                                            ind
                                                                        }>
                                                                        <ToolImg>
                                                                            <img
                                                                                src={
                                                                                    inner.icon
                                                                                }
                                                                                alt="-"
                                                                            />
                                                                        </ToolImg>
                                                                        <p>
                                                                            {ReactHtmlParser(
                                                                                inner.title
                                                                            )}
                                                                        </p>
                                                                    </Toolbox>
                                                                );
                                                            }
                                                        )
                                                      : null}
                                              </BiToolsetRow>
                                          );
                                      })
                                    : null}
                            </BiToolsetWrapper>
                        </div>
                    </Col>
                </Row>
            </Container>
        </BIIntro>
    );
};

const BIIntro = styled.section`
    position: relative;
    background: ${colors.gray_lightest['100']};
    .row:nth-child(1) p {
        padding-right: 20px;
        ${Media('xlscreens')} {
            padding-right: 2.5vw;
        }
        ${Media('tablet')} {
            padding-top: 50px;
        }
    }

    #bi_video {
        padding: 0;
    }
`;

const BIIntroPoints = styled.ul`
    padding: 0px;
    margin: 0px;
    display: flex;
    li {
        flex: 0 0 33%;
        max-width: 33%;
        min-height: 139px;
        border-right: 1px solid ${colors.gray_dark['50']};
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: ${gilroySemibold};
        font-size: 25px;
        text-align: center;
        padding: 0px 30px;
        &:last-child {
            border-right: none;
        }
    }
    ${Media('xlscreens')} {
        margin: 5.5vw 0 0;
        li {
            padding: 0px 2vw;
            font-size: 1.5vw;
        }
    }
    ${Media('tablet')} {
        flex-direction: column;
        li {
            border-right: none;
            border-bottom: 1px solid ${colors.gray_dark['50']};
            font-size: 18px;
            flex: 0 0 100%;
            max-width: 100%;
            min-height: 1px;
            padding: 30px;
            &:last-child {
                border-bottom: none;
            }
        }
    }
`;

const BiToolsetWrapper = styled.div`
    margin: 70px 0 0;
    h2 {
        font-family: ${gilroySemibold};
        font-size: 45px;
        margin-bottom: 30px;
    }
    ${Media('xlscreens')} {
        margin: 6vw 0 0;
        h2 {
            font-size: 3.26vw;
            margin-bottom: 5.5vw;
        }
    }
    ${Media('mobile')} {
        margin-top: 50px;
        h2 {
            font-size: 36px;
            margin-bottom: 50px;
            br {
                display: none;
            }
        }
    }
`;

const BiToolsetRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    ${Media('tablet')} {
        flex-direction: column;
    }
`;

const ToolMain = styled.div`
    flex-grow: 1;
    background: ${colors.gray_dark['100']};
    font-size: 22px;
    color: ${colors.white['100']};
    font-family: ${gilroySemibold};
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -1px;
    position: relative;
    &:before {
        content: '';
        width: 25%;
        height: 100%;
        background: inherit;
        position: absolute;
        left: -25%;
        top: 0px;
    }
    img {
        width: 20px;
        margin-top: 20px;
    }
    ${Media('xlscreens')} {
        font-size: 1.5vw;
        img {
            margin-top: 1vw;
            width: 1.5vw;
        }
    }
    ${Media('tablet')} {
        padding: 25px;
        margin: 0px;
        &:before {
            display: none;
        }
        img {
            transform: rotate(90deg);
        }
    }
`;

const Toolbox = styled.div`
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
    border: 1px solid ${colors.gray_dark['100']};
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    min-height: 200px;
    margin-left: -1px;
    margin-top: -1px;
    img {
        max-width: 100%;
    }
    p {
        font-size: 18px;
        margin-bottom: 0px;
        line-height: 1.3;
    }
    ${Media('xlscreens')} {
        width: 12.3vw;
        min-height: 12.3vw;
        p {
            font-size: 0.95vw;
        }
    }
    ${Media('tablet')} {
        flex: 0 0 100%;
        max-width: 100%;
        margin-right: 0px;
        min-height: 1px;
        padding: 30px 0px;
    }
`;

const ToolImg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 10px;
    ${Media('xlscreens')} {
        min-height: 4vw;
        margin-top: 2.5vw;
        margin-bottom: 0.6vw;
        img {
            width: 3.2vw;
        }
    }
    ${Media('tablet')} {
        margin-top: 0px;
    }
`;
