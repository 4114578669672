import React from 'react';
import WhyOutSourceHeading from 'shared-components/outsource/outsource-heading';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import {
    gilroySemibold,
    gilroyBold,
    gilroyMedium,
} from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

export const HappenedComm = () => {
    return (
        <>
            <WhatHappened>
                <WhyOutSourceHeading
                    headingName="If I choose ibex, what happens after I sign?"
                    headingCol="12"
                    subheadingCol="4"
                    subheadingNumber="03"
                    subheadingText="After you sign…"
                    activateSubHeading={true}
                />
                <Container>
                    <Row>
                        <Col lg="12">
                            <p>
                                After partnering with ibex, we take great care
                                to ensure we’re meeting ever expectation. We
                                partner your leadership with leadership of our
                                own, to ensure a smooth transition as well as a
                                close working relationship.
                            </p>
                        </Col>
                        <Col lg="12">
                            <ContentList>
                                <li>
                                    <span className="title-text">Advocate</span>
                                    <img
                                        src={`${baseURL}assets/images/pink-right-arrow.svg`}
                                        alt="-"
                                    />
                                    <span className="text-content">
                                        You’ll work directly with a senior
                                        Business Development leader.
                                    </span>
                                </li>
                                <li>
                                    <span className="title-text">Expert</span>
                                    <img
                                        src={`${baseURL}assets/images/pink-right-arrow.svg`}
                                        alt="-"
                                    />
                                    <span className="text-content">
                                        You’ll be assigned an expert Client
                                        Services representative.&nbsp;
                                    </span>
                                </li>
                                <li>
                                    <span className="title-text">
                                        Strategist
                                    </span>
                                    <img
                                        src={`${baseURL}assets/images/pink-right-arrow.svg`}
                                        alt="-"
                                    />
                                    <span className="text-content">
                                        For ibex Financial Velocity clients,{' '}
                                        <br />
                                        you’ll be paired with a General Manager
                                        with expertise in your industry.
                                    </span>
                                </li>
                                <li>
                                    <span className="title-text">C-Suite</span>
                                    <img
                                        src={`${baseURL}assets/images/pink-right-arrow.svg`}
                                        alt="-"
                                    />
                                    <span className="text-content">
                                        You’ll have direct access to C-level
                                        leadership.
                                    </span>
                                </li>
                            </ContentList>
                        </Col>
                        <Col lg="12">
                            <img
                                src={`${baseURL}assets/images/down-arrow.svg`}
                                alt="-"
                                height="30"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <SecondaryContent>
                        <Row>
                            <div className="big-line-one"></div>
                            <div className="big-line-two"></div>
                            <Col lg="5">
                                <SecondaryHeading>
                                    The launch <br />
                                    process begins
                                </SecondaryHeading>
                                <p>
                                    From initiation all the way through launch,
                                    we define our process clearly and support it
                                    with each layer of our operation. We believe
                                    in delivering transparency and trust through
                                    every stage of implementation.
                                </p>
                            </Col>
                            <Col lg="3">
                                <CardBox>
                                    <div className="small-circle-two"></div>
                                    <CardHeader>01</CardHeader>
                                    <CardBody>
                                        <h5 className="card-title">
                                            Solutioning
                                        </h5>
                                        <ul>
                                            <li>• Contract Review,</li>
                                            <li>
                                                • Project Pre-Planning Activity
                                                Starts
                                            </li>
                                        </ul>
                                    </CardBody>
                                </CardBox>
                            </Col>
                            <Col lg="3">
                                <CardBoxBlack>
                                    <div className="small-circle-one"></div>
                                    <div className="small-circle-two"></div>
                                    <CardHeaderBlack>02</CardHeaderBlack>
                                    <CardBodyBlack>
                                        <h5>Initiation</h5>
                                        <ul>
                                            <li>
                                                • Pre-Launch Initiation Meeting
                                            </li>
                                            <li>• Project Approval</li>
                                            <li>
                                                • Internal Kick Off, Briefing
                                                Planning Sessions
                                            </li>
                                            <li>• Client Kick Off</li>
                                            <li>• Approval gate</li>
                                        </ul>
                                    </CardBodyBlack>
                                </CardBoxBlack>
                            </Col>
                            <Col lg="3">
                                <CardBox>
                                    <div className="small-circle-two"></div>
                                    <CardHeader>03</CardHeader>
                                    <CardBody>
                                        <h5>Planning</h5>
                                        <ul>
                                            <li>• Due Diligence Process</li>
                                            <li>
                                                • Requirements, Due Diligence
                                            </li>
                                            <li>• Confirm Amend Resources</li>
                                            <li>• Approval gate</li>
                                        </ul>
                                    </CardBody>
                                </CardBox>
                            </Col>
                            <Col lg="3">
                                <CardBoxBlack>
                                    <div className="small-circle-one"></div>
                                    <div className="small-circle-two"></div>
                                    <CardHeaderBlack>02</CardHeaderBlack>
                                    <CardBodyBlack>
                                        <h5>Execution</h5>
                                        <ul>
                                            <li>
                                                • Implementation of all
                                                workstreams
                                            </li>
                                            <li>• Go Live</li>
                                            <li>• Approval Gate</li>
                                        </ul>
                                    </CardBodyBlack>
                                </CardBoxBlack>
                            </Col>
                            <Col lg="3">
                                <CardBox>
                                    <div className="small-circle-one"></div>
                                    <div className="small-circle-two"></div>
                                    <CardHeader>05</CardHeader>
                                    <CardBody>
                                        <h5>Closing</h5>
                                        <ul>
                                            <li>• Post Project Review</li>
                                            <li>• Approval Gate</li>
                                        </ul>
                                    </CardBody>
                                </CardBox>
                            </Col>
                            <Col lg="12">
                                <img
                                    src={`${baseURL}assets/images/down-arrow.svg`}
                                    alt="-"
                                    height="30"
                                />
                            </Col>
                        </Row>
                    </SecondaryContent>
                </Container>
            </WhatHappened>
            <CommunicatedSection>
                <Container>
                    <Row>
                        <Col lg="8">
                            <SecondaryHeading>
                                Every step, documented <br />
                                and communicated.
                            </SecondaryHeading>
                            <p className="marg-50">
                                For every partnership, communication is key. We
                                document every phase of the project, and make
                                you’re involved at every step.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="5">
                            <StatsBoxData>
                                <p>
                                    <img
                                        src={`${baseURL}assets/images/file-icon.svg`}
                                        alt="-"
                                    />
                                </p>
                                <a href="#" className="button-stat">
                                    <img
                                        src={`${baseURL}assets/images/pink-right-arrow.svg`}
                                        alt="-"
                                        height="20"
                                    />
                                    PROJECT DOCUMENTATION
                                </a>
                                <div className="text-box">
                                    <ul>
                                        <li>
                                            <strong className="text-pink">
                                                Ramp Plan:
                                            </strong>
                                        </li>
                                        <li>
                                            <strong>Includes:</strong>
                                        </li>
                                        <li>Training Waves</li>
                                        <li>Trainers, class times</li>
                                        <li>Est HC by week</li>
                                    </ul>
                                </div>
                            </StatsBoxData>
                            <StatsBoxData>
                                <div className="text-box">
                                    <ul>
                                        <li>
                                            <strong className="text-pink">
                                                Project Workbook
                                            </strong>
                                        </li>
                                        <li>
                                            <strong>Includes:</strong>
                                        </li>
                                        <li>Action Item List</li>
                                        <li>Go Live Readiness Checklist</li>
                                        <li>Due Diligence Info</li>
                                        <li>Milestone Plan</li>
                                        <li>Project Contact List</li>
                                        <li>Risk Mitigation Plan</li>
                                    </ul>
                                </div>
                            </StatsBoxData>
                            <StatsBoxData>
                                <div className="text-box">
                                    <ul>
                                        <li>
                                            <strong className="text-pink">
                                                Distribution:
                                            </strong>
                                        </li>
                                        <li>All project team members</li>
                                        <li>Prior to project status meeting</li>
                                    </ul>
                                </div>
                            </StatsBoxData>
                        </Col>
                        <Col lg="2" d-flex={true}>
                            <a href="#" className="arrow-box-style2">
                                <img
                                    src={`${baseURL}assets/images/down-white-arrow.svg`}
                                    alt="-"
                                />
                            </a>
                        </Col>
                        <Col lg="5">
                            <StatsBoxData>
                                <p>
                                    <img
                                        src={`${baseURL}assets/images/chat-icon.svg`}
                                        alt="-"
                                    />
                                </p>
                                <a href="#" className="button-stat">
                                    <img
                                        src={`${baseURL}assets/images/pink-right-arrow.svg`}
                                        alt="-"
                                        height="20"
                                    />
                                    PROJECT COMMUNICATION
                                </a>
                                <div className="text-box">
                                    <ul>
                                        <li>
                                            <strong className="text-pink">
                                                Weekly Project Status Meetings
                                            </strong>
                                        </li>
                                        <li>
                                            <strong>Objective:</strong>
                                        </li>
                                        <li>Project Status Communication</li>
                                        <li>Planning</li>
                                        <li>Risk management</li>
                                        <li>
                                            <strong>Attended by:</strong>
                                        </li>
                                        <li>
                                            Facilitated by IBEX Project Manager
                                        </li>
                                        <li>IBEX Project Team</li>
                                        <li>Client Project Team</li>
                                    </ul>
                                </div>
                            </StatsBoxData>
                            <StatsBoxData>
                                <div className="text-box">
                                    <ul>
                                        <li>
                                            <strong className="text-pink">
                                                Weekly Email Status Reports
                                            </strong>
                                        </li>
                                        <li>
                                            <strong>Objective:</strong>
                                        </li>
                                        <li>Communicate Health of Project</li>
                                        <li>Highlight opportunities</li>
                                        <li>
                                            Call out upcoming key deliverables
                                        </li>
                                    </ul>
                                </div>
                            </StatsBoxData>
                            <StatsBoxData>
                                <div className="text-box">
                                    <ul>
                                        <li>
                                            <strong className="text-pink">
                                                Distribution:
                                            </strong>
                                        </li>
                                        <li>All project team members</li>
                                        <li>Close of week</li>
                                    </ul>
                                </div>
                            </StatsBoxData>
                        </Col>
                    </Row>
                </Container>
            </CommunicatedSection>
            <AllPersonalSection>
                <Container>
                    <Row>
                        <Col lg="12">
                            <SecondaryHeading>
                                All personnel, ready.
                            </SecondaryHeading>
                        </Col>
                        <Col lg="12">
                            <ChartImg>
                                <img
                                    src={`${baseURL}assets/images/chart-img.svg`}
                                    alt="-"
                                />
                            </ChartImg>
                        </Col>
                    </Row>
                </Container>
            </AllPersonalSection>
        </>
    );
};
const ChartImg = styled.div`
    margin-top: 50px;
    ${Media('xlscreens')} {
        margin-top: 2.3vw;
    }
    ${Media('tablet')} {
        img {
            width: 100%;
            object-fit: contain;
        }
    }
`;
const AllPersonalSection = styled.section`
    text-align: center;
    ${Media('tablet')} {
        padding: 20px 0px;
    }
`;
const CommunicatedSection = styled.section`
    .arrow-box-style2 {
        width: 100%;
        height: 65px;
        background-color: #000;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        img {
            transform: rotate(-90deg);
        }
    }

    .marg-50 {
        margin-bottom: 70px;
        ${Media('xlscreens')} {
            margin-bottom: 3.3vw;
        }
    }
    ${Media('tablet')} {
        padding: 20px 0px;
    }
`;
const StatsBoxData = styled.div`
    padding: 30px;
    border: 1px solid #1d1d1d;
    width: 100%;
    .button-stat {
        border: 1px solid #1d1d1d;
        padding: 10px 15px;
        display: inline-flex;
        align-items: center;
        color: #000000;
        img {
            padding-right: 20px;
        }
    }
    .text-pink {
        color: #fa0060;
    }

    .text-box {
        ul {
            padding: 0;
            margin: 30px 0;
            li {
                display: flex;
                margin-bottom: 5px;
                font-family: ${gilroyMedium};
                strong {
                    font-family: ${gilroyBold};
                    font-size: 14px;
                    ${Media('xlscreens')} {
                        font-size: 0.73vw;
                    }
                }
            }
        }
    }

    &:nth-child(2) {
        border-top: none;
        border-bottom: none;
    }
`;
const SecondaryContent = styled.div`
    margin-top: 100px;
    position: relative;
    ${Media('xlscreens')} {
        margin-top: 5.7vw;
    }
    .big-line-one {
        width: 100%;
        height: 1px;
        background-color: #000;
        position: absolute;
        right: -50%;
        top: 140px;
        ${Media('tablet')} {
            display: none;
        }
    }
    .big-line-two {
        width: 100%;
        height: 1px;
        background-color: #000;
        position: absolute;
        left: -10%;
        top: 450px;
        &:before {
            content: '';
            width: 1px;
            height: 20px;
            background-color: #000;
            position: absolute;
            right: 6px;
            transform: rotate(45deg);
            top: -2px;
        }

        &:after {
            content: '';
            width: 1px;
            height: 20px;
            background-color: #000;
            position: absolute;
            right: 6px;
            transform: rotate(-45deg);
            top: -16px;
        }
        ${Media('tablet')} {
            display: none;
        }
    }
    ${Media('tablet')} {
        margin-top: 40px;
    }
`;
const CardBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    min-height: 270px;
    margin-bottom: 40px;
    position: relative;
    .small-circle-two {
        width: 12px;
        height: 12px;
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 50%;
        position: absolute;
        right: -7px;
        top: 134px;
        ${Media('tablet')} {
            display: none;
        }
    }
    .small-circle-one {
        width: 12px;
        height: 12px;
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 50%;
        position: absolute;
        left: -7px;
        top: 134px;
        ${Media('tablet')} {
            display: none;
        }
    }
`;
const CardHeader = styled.div`
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    color: #fa0060;
    font-size: 18px;
    background-color: #f0f1f2;
    font-family: ${gilroyBold};
    ${Media('xlscreens')} {
        font-size: 0.96vw;
    }
`;
const CardBody = styled.div`
    flex: 1 1 auto;
    padding: 1.25rem;
    h5 {
        font-family: ${gilroyBold};
        font-size: 18px;
        margin-top: 0;
        ${Media('xlscreens')} {
            font-size: 0.96vw;
        }
    }
    ul {
        padding: 0;
        li {
            font-size: 12px;
        }
    }
`;
const CardBoxBlack = styled(CardBox)`
    background-color: #1d1d1f;
`;
const CardHeaderBlack = styled(CardHeader)`
    background-color: #000000;
`;
const CardBodyBlack = styled(CardBody)`
    h5 {
        color: #fff;
    }
    ul {
        li {
            color: #fff;
        }
    }
`;
const SecondaryHeading = styled.h3`
    font-family: ${gilroySemibold};
    font-size: 52px;
    line-height: 1;
    color: #1d1d1f;
    ${Media('xlscreens')} {
        font-size: 2.7vw;
    }
    ${Media('tablet')} {
        font-size: 35px;
    }
`;
const ContentList = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 50px;
    li {
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        border-top: 1px solid rgba(29, 29, 31, 0.5);
        align-items: center;
        background-color: rgba(#e8e8e8, 0.2);
        span {
            line-height: 1;
        }
        .title-text {
            font-size: 25px;
            font-family: ${gilroySemibold};
            width: 40%;
            ${Media('xlscreens')} {
                font-size: 1.3vw;
            }
            ${Media('tablet')} {
                font-size: 20px;
            }
        }
        img {
        }
        .text-content {
            font-size: 12px;
            width: 40%;
        }
    }
`;

const WhatHappened = styled.section`
    ${Media('tablet')} {
        padding: 20px 0px;
    }
`;
