import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import { MarketoFormDesign } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';

export const LandingFormWrapper = ({
    children,
    title,
    subTitle,
    noTitle,
    titleAlign,
    noPadding,
    noLeftRightPadding,
    noFormMargin,
}) => {
    return (
        <Wrapper noPadding={noPadding} noLeftRightPadding={noLeftRightPadding}>
            <FormInner noFormMargin={noFormMargin}>
                {!noTitle && (
                    <FormHeading align={titleAlign}>
                        {title && title !== undefined
                            ? title
                            : 'Watch the Webinar Now'}
                    </FormHeading>
                )}
                <SubTitle>{subTitle}</SubTitle>
                <MarketoFormDesign>{children}</MarketoFormDesign>
            </FormInner>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    height: 100%;
    ${Media('xlscreens')} {
        padding: ${(props) => (props.noLeftRightPadding ? '3vw 0' : '3vw')};
        padding-bottom: 3.5vw;
        padding: ${(props) => props.noPadding && '0'};
    }
`;

const FormInner = styled.div`
    background-color: ${colors.white['100']};
    width: 100%;
    height: 100%;
    padding-top: 40px;
    padding: 0 30px;
    box-shadow: 0px 0px 30px ${colors.black['10']};
    ${Media('xlscreens')} {
        padding: 0 2.61vw;
        padding-top: 2.08vw;
        padding-bottom: 2.5vw;
        form {
            margin-top: ${(props) => (props.noFormMargin ? '0' : '3vw')};
        }
    }
    ${Media('laptops')} {
        padding-top: 1vw;
    }
    ${Media('tablet')} {
        padding: 30px;
    }
    ${Media('mobile')} {
        padding: 30px 10px;
    }
`;
const FormHeading = styled.h2`
    font-size: 42px;
    font-family: ${gilroyBold};
    margin-bottom: 10px;
    letter-spacing: -0.15px;
    text-align: ${(props) => (props.align ? props.align : 'center')};
    ${Media('xlscreens')} {
        font-size: 2.19vw;
        margin-bottom: 0.52vw;
    }
    ${Media('tablet')} {
        font-size: 30px;
        margin-top: 0;
    }
    ${Media('mobile')} {
        font-size: 20px;
        margin-bottom: 30px;
    }
`;

const SubTitle = styled.h3`
    font-size: 20px;
    color: ${colors.caseStudyPages['wuLandingHeading']};
    margin-top: 0px;
    margin-bottom: 20px;
    letter-spacing: -0.15px;
    ${Media('xlscreens')} {
        font-size: 1.1vw;
        margin-bottom: 1.1vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
`;
