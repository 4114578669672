import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const SRMSectionHeading = ({ children, styleTwo, color }) => {
    return (
        <Wrapper styleTwo={styleTwo} color={color}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.h2`
    text-align: center;
    font-size: ${(props) => (props.styleTwo ? '40px' : '50px')};
    line-height: 1.3;
    font-family: ${gilroySemibold};
    font-weight: normal;
    margin: 0px 0 30px;
    color: ${(props) =>
        props.styleTwo ? colors.white['100'] : colors.black['100']};
    ${(props) => props.color && `color: ${props.color}`};
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.styleTwo ? '2.08vw' : '2.6vw')};
        margin-bottom: 2vw;
        letter-spacing: -0.04vw;
    }
    ${Media('tablet')} {
        font-size: 30px;
        padding: ${(props) => (props.noPadding ? '0' : '0px 20px')};
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 20px;
    }
`;
