import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import {
    gilroyMedium,
    gilroySemibold,
    gilroyLight,
} from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';

const QuoteBox = ({
    quotePara,
    quoteName,
    quoteDesignation,
    offsetMinus,
    paddingTop,
    authorImg,
    largepaddingTop,
    fullWidth,
    contentCenter,
    mediumPaddingTop,
    noSidePadding,
    noPaddingBottom,
}) => {
    return (
        <Wrapper
            offsetMinus={offsetMinus}
            paddingTop={paddingTop}
            noPaddingBottom={noPaddingBottom}>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Container>
                    <Row>
                        <Col lg="12">
                            <QuoteBoxContainer
                                largepaddingTop={largepaddingTop}
                                contentCenter={contentCenter}
                                mediumPaddingTop={mediumPaddingTop}
                                noSidePadding={noSidePadding}>
                                <CustRow>
                                    {authorImg && (
                                        <Col lg="2">
                                            <QuoteText>
                                                <img src={authorImg} alt="-" />
                                            </QuoteText>
                                        </Col>
                                    )}
                                    <Col lg={fullWidth ? '12' : '8'}>
                                        <QuotePara>
                                            {ReactHtmlParser(quotePara)}
                                        </QuotePara>
                                        <QuoteName>{quoteName}</QuoteName>
                                        <QuoteDesignation>
                                            {quoteDesignation}
                                        </QuoteDesignation>
                                    </Col>
                                </CustRow>
                            </QuoteBoxContainer>
                        </Col>
                    </Row>
                </Container>
            </ScrollAnimation>
        </Wrapper>
    );
};
const Wrapper = styled.section`
    padding-top: ${(props) => (props.paddingTop ? '150px' : '0px')};
    margin-top: ${(props) => (props.offsetMinus ? '-190px' : '0px')};
    padding-bottom: ${(props) => props.noPaddingBottom && '0px'};
    ${Media('xlscreens')} {
        margin-top: ${(props) => (props.offsetMinus ? '-10vw' : '0px')};
        padding-top: ${(props) => (props.paddingTop ? '7.85vw' : '0px')};
    }
    ${Media('tablet')} {
        padding: 0px;
        margin: 0px;
    }
`;
const QuoteDesignation = styled.div`
    font-family: ${gilroyLight};
    font-size: 23px;
    padding-top: 10px;
    ${Media('xlscreens')} {
        font-size: 1.2vw;
        padding-top: 0.52vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
`;
const QuoteName = styled.div`
    font-family: ${gilroySemibold};
    font-size: 23px;
    ${Media('xlscreens')} {
        font-size: 1.2vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
`;
const QuotePara = styled.div`
    font-family: ${gilroySemibold};
    font-size: 34px;
    line-height: 1.61;
    margin-bottom: 50px;
    p {
        font-family: inherit;
        font-size: inherit;
        margin: 0px;
    }
    ${Media('xlscreens')} {
        font-size: 1.7vw;
        margin-bottom: 2.5vw;
    }
    ${Media('tablet')} {
        font-size: 24px;
        margin-top: 20px;
        br {
            display: none;
        }
    }
`;

const QuoteText = styled.div`
    font-family: ${gilroyMedium};
    font-size: 26px;
    color: ${colors.white['100']};
    img {
        height: 150px;
        width: 150px;
    }
    ${Media('xlscreens')} {
        font-size: 1.3vw;
        img {
            height: 7.81vw;
            width: 7.81vw;
        }
    }
    ${Media('tablet')} {
        img {
            display: none;
        }
    }
`;
const QuoteBoxContainer = styled.div`
    padding: ${(props) => (props.noSidePadding ? '0' : '0 100px')};
    ${Media('xlscreens')} {
        padding: ${(props) => (props.noSidePadding ? '0' : '0 5vw')};
        ${(props) => {
            if (props.largepaddingTop) {
                return `padding-top: 10vw; padding-bottom: 2vw;`;
            } else if (props.mediumPaddingTop) {
                return `padding-top: 6vw; padding-bottom: 2vw;`;
            } else {
                return `padding-top: 7vw; padding-bottom: 0;`;
            }
        }};
        text-align: ${(props) => (props.contentCenter ? 'center' : 'left')};
    }
    ${Media('tablet')} {
        padding: 30px 30px;
    }
`;

const CustRow = styled(Row)`
    &&& {
        justify-content: center;
    }
`;

export default QuoteBox;
