import React from 'react';
import colors from 'shared-components/theme/colors';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
export const DEHeader = ({ dark, absolute, noRightText }) => {
    return (
        <Wrapper absolute={absolute} dark={dark}>
            <Logo>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="112"
                    height="51"
                    viewBox="0 0 112 51">
                    <text
                        id="ibex."
                        transform="translate(0 40)"
                        fontFamily="Axiforma-ExtraBold, Axiforma"
                        fontWeight="800"
                        letterSpacing="-0.04em">
                        <tspan x="0" y="0">
                            ibex.
                        </tspan>
                    </text>
                </svg>
            </Logo>
            {!noRightText && <RightText>Built for what’s next.</RightText>}
        </Wrapper>
    );
};

const Logo = styled.div`
    svg text {
        font-size: 30px;
        fill: ${colors.white['100']};
    }
    ${Media('xlscreens')} {
        svg text {
            font-size: 2.6vw;
        }
    }
    ${Media('mobile')} {
        svg text {
            font-size: 26px;
        }
    }
`;

const RightText = styled.div`
    font-size: 22px;
    font-family: ${gilroyMedium};
    color: ${colors.white['100']};
    ${Media('xlscreens')} {
        font-size: 1.6vw;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    position: ${(props) => (props.absolute ? 'absolute' : 'relative')};
    width: 100%;
    box-sizing: border-box;
    z-index: 99;
    ${(props) => {
        if (props.dark) {
            return `
                ${RightText}{
                    color:${colors.black['100']};
                }
                ${Logo}{
                    svg text{
                        fill:${colors.black['100']}
                    }
                }
            `;
        }
    }}
    ${Media('xlscreens')} {
        padding: 2.6vw 5.98vw;
    }
    ${Media('tablet')} {
        position: relative;
    }
    ${Media('mobile')} {
        padding: 15px;
    }
`;
