import React from "react";

import { Para } from '../cx-pocket-guide/para';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';
import { BlockImg } from "./block-img";
import { BigHeading } from "../cx-pocket-guide/big-heading";

export const Point10 = ({ active }) => {
    return (
        <div>
            <BlockImg imgUrl={`${baseURL}assets/images/ebook/imperative-ebook/point10_block_img.png`} />
            <BigHeading color={colors.cx_pocket_guide['pink']} typical active={active}>
                The speed at which your CX vendor delivers remarkable results is the primary indicator that your CX partner is at the top of their game and is working to make your brand better.
            </BigHeading>
            <Para color={colors.white['100']}>
                This ultimate benchmark indicates that every internal program and process has been thoughtfully designed with the client, customer, and employee in mind. It indicates an holistic, comprehensive approach to CX that combines employee experience, recruiting, training, insights, technology, operations, and more - it is every piece of the operational machine operating at maximum efficiency.
            </Para>
            <Para color={colors.white['100']}>
                This is where partnerships are made and broken.
            </Para>
        </div>
    );
}
