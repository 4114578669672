import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import MainHeading from 'shared-components/main-heading/main-heading';
import HomeProfitJourney from 'components/home-profit-journey/home-profit-journey';
import HomeBigTag from 'components/home-big-tag/home-big-tag';
import Media from 'shared-components/theme/medias';
import { baseURL } from 'shared-components/theme/helpers';

export const ProfitSection = () => {
    return (
        <ProfitJourney>
            <Container>
                <ScrollAnimation
                    delay={0}
                    animateIn="fadeInUp"
                    animateOnce={false}>
                    <Row>
                        <Col md="8">
                            <HomeBigTag>03</HomeBigTag>
                            <MainHeading>
                                We protect & accelerate your profit journey.
                            </MainHeading>
                        </Col>

                        <Col md="4">
                            <ProfitPara>
                                Seamlessly scale your operations.
                                <br />
                                <u>Execute smashing CX.</u>
                            </ProfitPara>
                        </Col>
                    </Row>
                </ScrollAnimation>
            </Container>
            <Container fluid={true}>
                <HomeProfitJourney
                    infoImage={`${baseURL}assets/images/ibex-connect-icon.svg`}
                    infoTag="ibex. Connect"
                    infoTitle={<Fragment>Worldwide CX Outsourcing</Fragment>}
                    infoDesc={
                        <Fragment>Expert Outsourcer. In-House Feel.</Fragment>
                    }
                    infoPara="The most elite contact center agents in the industry, at your service."
                    infoLink="connect"
                    tabList={
                        <Fragment>
                            <li>Elite Customer Care</li>
                            <li>Certified TeleSales Agents</li>
                            <li>Digital Transformation Experts</li>
                        </Fragment>
                    }
                    video_image={`${baseURL}assets/images/connect-home-video-image.jpg`}
                    video_url={`${baseURL}assets/videos/connect.mp4`}
                    videoText={
                        <Fragment>
                            Customer Support <br />
                            <span>can </span> be this good.
                        </Fragment>
                    }
                    level="one"
                />
                <HomeProfitJourney
                    infoImage={`${baseURL}assets/images/ddg-icon.svg`}
                    infoTag="ibex. Digital"
                    infoTitle={<Fragment>Digital Demand Generation</Fragment>}
                    infoDesc={
                        <Fragment>Accelerate your digital marketing.</Fragment>
                    }
                    infoPara="The art & science of online customer acquisition, infused with A.I."
                    infoLink="digital"
                    tabList={
                        <Fragment>
                            <li>Paid Search & Social</li>
                            <li>SEO & SEM</li>
                            <li>Intelligent Bidding & Ad Launching</li>
                        </Fragment>
                    }
                    video_image={`${baseURL}assets/images/digital-home-video-image.jpg`}
                    video_url={`${baseURL}assets/videos/digital.mp4`}
                    videoText={
                        <Fragment>
                            Prepare for <span>liftoff.</span>
                        </Fragment>
                    }
                    level="two"
                />
                <HomeProfitJourney
                    infoImage={`${baseURL}assets/images/ibex-cx-icon.svg`}
                    infoTag="ibex. CX"
                    infoTitle={
                        <Fragment>
                            Survey Technology & Feedback Analytics
                        </Fragment>
                    }
                    infoDesc={
                        <Fragment>Upgrade your customer experience.</Fragment>
                    }
                    infoPara="Omnichannel customer surveys, tied directly into your contact center."
                    infoLink="cx"
                    bigImage={`${baseURL}assets/images/ibexcx-bg.png`}
                    tabList={
                        <Fragment>
                            <li>Omnichannel Survey Delivery</li>
                            <li>5-Star Surveys & Analytics</li>
                            <li>Connected To The Contact Center</li>
                        </Fragment>
                    }
                    video_image={`${baseURL}assets/images/cx-home-video-image.jpg`}
                    video_url={`${baseURL}assets/videos/CX.mp4`}
                    videoText={
                        <Fragment>
                            A <span>connection</span> is made.
                        </Fragment>
                    }
                    level="three"
                />
            </Container>
        </ProfitJourney>
    );
};

const ProfitJourney = styled.section`
    ${Media('xlscreens')} {
        padding-top: 8vw;
    }
    ${Media('mobile')} {
        padding-top: 0;
    }
`;

const ProfitPara = styled.p`
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    ${Media('xlscreens')} {
        font-size: 1.3vw;
    }
    ${Media('mobile')} {
        height: auto;
    }
`;
