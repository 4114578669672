import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import MainHeading from 'shared-components/main-heading/main-heading';
import ScrollAnimation from 'react-animate-on-scroll';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import { baseURL } from 'shared-components/theme/helpers';

const IndustriesServed = () => {
    return (
        <Wrapper id="industries">
            <Container>
                <Row>
                    <Col lg="12">
                        <MainHeading>Industries Served</MainHeading>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row noGutters={true}>
                    <Col lg="3">
                        <div>
                            <InsightBox>
                                <img
                                    src={`${baseURL}assets/images/technology-bg.jpg`}
                                    alt="-"
                                />
                                <h4>Technology</h4>
                            </InsightBox>
                        </div>
                    </Col>
                    <Col lg="3">
                        <div>
                            <InsightBox>
                                <img
                                    src={`${baseURL}assets/images/streaming-bg.jpg`}
                                    alt="-"
                                />
                                <h4>Streaming</h4>
                            </InsightBox>
                        </div>
                    </Col>

                    <Col lg="3">
                        <div>
                            <InsightBox>
                                <img
                                    src={`${baseURL}assets/images/delivery-bg.jpg`}
                                    alt="-"
                                />
                                <h4>Delivery</h4>
                            </InsightBox>
                        </div>
                    </Col>
                    <Col lg="3">
                        <div>
                            <InsightBox>
                                <img
                                    src={`${baseURL}assets/images/ecom-bg.jpg`}
                                    alt="-"
                                />
                                <h4>eComm</h4>
                            </InsightBox>
                        </div>
                    </Col>

                    <Col lg="3">
                        <div>
                            <InsightBox>
                                <img
                                    src={`${baseURL}assets/images/retail-bg.jpg`}
                                    alt="-"
                                />
                                <h4>Retail</h4>
                            </InsightBox>
                        </div>
                    </Col>
                    <Col lg="3">
                        <div>
                            <InsightBox>
                                <img
                                    src={`${baseURL}assets/images/fintect-bg.jpg`}
                                    alt="-"
                                />
                                <h4>Fintech</h4>
                            </InsightBox>
                        </div>
                    </Col>
                    <Col lg="3">
                        <div>
                            <InsightBox>
                                <img
                                    src={`${baseURL}assets/images/hospitality-bg.jpg`}
                                    alt="-"
                                />
                                <h4>Hospitality</h4>
                            </InsightBox>
                        </div>
                    </Col>
                    <Col lg="3">
                        <div>
                            <InsightBox>
                                <img
                                    src={`${baseURL}assets/images/telecom-bg.jpg`}
                                    alt="-"
                                />
                                <h4>Telecom</h4>
                            </InsightBox>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.section`
    padding-bottom: 0px;
    .container-fluid {
        padding: 0;
    }
    h2 {
        text-align: center;
        margin-bottom: 60px;
    }
    ${Media('tablet')} {
        padding-top: 0px;
    }
`;

const InsightBox = styled.div`
    position: relative;
    height: 400px;
    overflow: hidden;
    &:before,
    h4 {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
    }
    &:before {
        background: ${colors.gray_dark['60']};
        z-index: 9;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 1s ease;
    }
    h4 {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${colors.white['100']};
        font-family: ${gilroyBold};
        font-size: 33px;
        margin: 0px;
        z-index: 10;
    }
    &:hover {
        img {
            transform: scale(1.2);
        }
    }
    ${Media('xlscreens')} {
        height: 25vw;
        h4 {
            font-size: 1.72vw;
        }
    }
    ${Media('mobile')} {
        height: 300px;
    }
`;

export default IndustriesServed;
