import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Container, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import { gilroySemibold, gilroyLight } from 'shared-components/theme/fonts';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import DigitalFeedbackBox from './digital-feedback-box';
import ScrollAnimation from 'react-animate-on-scroll';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';
const feedbacksItems = [
    {
        img: `${baseURL}assets/images/digital_phone.png`,
        img_mobile: `${baseURL}assets/images/digital_phone.png`,
        title: (
            <Fragment>
                Adaptive Bidding <br />& Ad Placement
            </Fragment>
        ),
        description:
            'Don’t bid blindly. We achieve 2X the yield per click than our competitors. How? Our refined machine learning algorithms optimize ad spend and campaign management in real-time.  We have complete value-chain analytics on bothonline and offline purchase events, allowing for precise bulk bidding on millions of stress-tested keywords.',
        bgColor: colors.gray_dark['100'],
    },
    {
        img: `${baseURL}assets/images/digital-benefit-social.png`,
        img_mobile: `${baseURL}assets/images/digital-benefit-social.png`,
        title: (
            <Fragment>
                Save Money on <br />
                Paid Search + Social
            </Fragment>
        ),
        description:
            'Dramatically lower your CPA.  Our history of wins in the paid search + social ad space allows us to uniquely leverage an unmatched library of deep channel search, click, and call data, critically identifying and increasing high-value lead discovery and targeting.',
        bgColor: colors.gray_dark['100'],
    },
    {
        img: `${baseURL}assets/images/digital-cartao.png`,
        img_mobile: `${baseURL}assets/images/digital-cartao.png`,
        title: (
            <Fragment>
                Predictive Customer <br />
                Intelligence
            </Fragment>
        ),
        description: `Our predictive modeling capabilities crunch
        historical customer behavior and purchase data to
        help agents anticipate inbound customer tendencies
        and expectations, as well as current customer
        support needs.`,
        bgColor: colors.gray_darker['100'],
    },
    {
        img: `${baseURL}assets/images/digital-headp.png`,
        img_mobile: `${baseURL}assets/images/digital-headp.png`,
        title: (
            <Fragment>
                Customer Profiling, <br />
                Segmentation, & Relevancy
            </Fragment>
        ),
        description: `For every lead that comes in or purchase made, we
        are constantly developing and refining our customer
        profile lists, and filtering this into our
        Predictive Customer Intelligence system for
        high-impact lead conversion.`,
        bgColor: colors.gray_darker['100'],
    },
    {
        img: `${baseURL}assets/images/digital-faster.png`,
        img_mobile: `${baseURL}assets/images/digital-faster.png`,
        title: (
            <Fragment>
                We do it faster, better, <br />
                and cheaper.
            </Fragment>
        ),
        description: (
            <Fragment>
                Our system optimizes ad spend and campaign management in virtual
                real time, comparing revenue + cost + agent occupancy, with
                adjustments occurring 30 times/minute.
                <br />
                Know who else is doing that? No one.
            </Fragment>
        ),
        bgColor: colors.gray_darker['100'],
    },
];
export const DigitalBenefits = () => {
    return (
        <Wrapper id="benefits">
            <Container>
                <div>
                    <RowCentered>
                        <Col lg="6">
                            <HeaderBenefits>
                                How our technology <br />
                                benefits your brand.
                            </HeaderBenefits>
                        </Col>
                        <Col lg="6">
                            <HeaderTextBenefits>
                                We help great brands reach more customers. Our
                                paid search and bulk bidding technologies are
                                based on predictive customer modeling combined
                                with a real-time upflow of ever-shifting
                                behavioural data. We are constantly sculpting
                                and refining our bidding algorithms for
                                high-value segment incursion and pin-point
                                target accuracy.
                            </HeaderTextBenefits>
                        </Col>
                    </RowCentered>
                </div>
                {feedbacksItems &&
                    feedbacksItems.map((item, ind) => (
                        <DigitalFeedbackBox
                            image={item.img}
                            image_mobile={item.img_mobile}
                            title={item.title}
                            description={item.description}
                            bgColor={item.bgColor}
                            key={ind}
                        />
                    ))}
            </Container>
        </Wrapper>
    );
};
const HeaderTextBenefits = styled.div`
    color: #fff;
    font-family: ${gilroyLight};
    font-size: 1vw;
    padding: 0.5vw;
    line-height: 1.5vw;
    ${Media('tablet')} {
        font-size: 4vw;
        line-height: 4.4vw;
        padding: 5vw 3vw;
    }
`;
const HeaderBenefits = styled.div`
    color: #fff;
    font-family: ${gilroySemibold};
    font-size: 3vw;
    padding: 1.5vw;
    letter-spacing: -0.1vw;
    line-height: 4.4vw;
    ${Media('tablet')} {
        font-size: 7vw;
        line-height: 7.4vw;
        padding: 5vw 3vw;
    }
`;
const Wrapper = styled.div`
    clear: both;
    padding: 60px 0px 0px 0px;
    background: linear-gradient(${colors.digitalWrapper['bg1']} 95%, ${colors.digitalWrapper['bg2']} 5%);
    ${Media('xlscreens')} {
        padding: 4vw 0px 0px 0px;
    }
`;
