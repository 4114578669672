import React, { useState, Fragment, useEffect } from 'react';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import MainHeading from 'shared-components/main-heading/main-heading';
import MainPara from 'shared-components/main-para/main-para';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import Video from 'shared-components/video/video';
import {
    gilroySemibold,
    gilroyLight,
    gilroyMedium,
} from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import SubscribeForm from 'components/subscribe-form/subscribe-form';
import { PageHeading, PagePara, UpperRegBox } from './common';
import {
    ComplianceSection,
    ComptechSection,
    MonitorSection,
    RegularSection,
} from './sections';
import LazyLoad from 'react-lazyload';
import Loader from 'components/loader/loader';
import { baseURL } from 'shared-components/theme/helpers';

const scrollNavData = [
    {
        name: 'intro',
        target: 'intro',
        offset: -170,
    },
    {
        name: 'regular',
        target: 'regular',
        offset: 0,
    },
    {
        name: 'monitor',
        target: 'monitor',
        offset: 0,
    },
    {
        name: 'compliance',
        target: 'compliance',
        offset: 0,
    },
    {
        name: 'compTech',
        target: 'compTech',
        offset: 0,
    },
    {
        name: 'guarantee',
        target: 'guarantee',
        offset: 0,
    },
];

const lazySections = [
    {
        id: 'regular',
        section: <RegularSection />,
    },
    {
        id: 'monitor',
        section: <MonitorSection />,
    },
    {
        id: 'compliance',
        section: <ComplianceSection />,
    },
    {
        id: 'compTech',
        section: <ComptechSection />,
    },
];

const GBPIndex = () => {
    const [localKeyName] = useState('gauKey');
    const [pageLock, setPageLock] = useState(false);

    useEffect(() => {
        const localKey = localStorage.getItem(localKeyName) || '';
        if (localKey !== '') {
            setPageLock(false);
        }
    }, []);

    const handlePageLock = (val) => {
        setPageLock(val);
    };

    return (
        <Layout>
            {pageLock ? (
                <SubscribeForm
                    storeKey={localKeyName}
                    onSuccess={handlePageLock}
                    referrerProp={'goverence-action'}
                />
            ) : (
                <Fragment>
                    <Breadcrumbs>
                        <li>Governance In Action</li>
                    </Breadcrumbs>
                    <GauIntro id="intro">
                        <Container>
                            <div>
                                <RowCentered>
                                    <Col lg="8">
                                        <PreHeading>
                                            Governance Best Practices:
                                        </PreHeading>
                                        <MainHeading noMargin={true}>
                                            The ibex Governmental <br />
                                            Affairs Unit
                                        </MainHeading>
                                    </Col>
                                    <Col lg={{ size: 3, offset: 1 }}>
                                        <MainPara>
                                            Expert regulatory monitoring to
                                            guarantee compliance, deter fraud,
                                            and ensure uptime.
                                        </MainPara>
                                    </Col>
                                </RowCentered>
                            </div>
                            <Row>
                                <Col lg="12">
                                    <div>
                                        <GauIntroVideo>
                                            <Video
                                                image={`${baseURL}assets/images/gau-intro-video.jpg`}
                                            />
                                        </GauIntroVideo>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4">
                                    <div>
                                        <IntroFeature>
                                            <h4>Reduce Compliance Risk</h4>
                                            <p>
                                                Stepping over the regulatory
                                                line leads to reputation risk
                                                and uptime risk - ibex’s GAU
                                                ensures that our teams - and
                                                your programs - are compliant
                                                with the latest regulatory
                                                changes.
                                            </p>
                                        </IntroFeature>
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div>
                                        <IntroFeature>
                                            <h4>Reduce Financial Risk</h4>
                                            <p>
                                                Infractions come at a cost. To
                                                protect your brand from
                                                unnecessary financial penalties,
                                                ibex’s GAU provides compliance
                                                models for our CX teams To
                                                ensure complete risk mitigation.
                                            </p>
                                        </IntroFeature>
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div>
                                        <IntroFeature>
                                            <h4>Reduce Behavioral Risk</h4>
                                            <p>
                                                With our GAU team actively
                                                playing a role in all CX
                                                training and program execution,
                                                compliance has become an
                                                embedded aspect of our business
                                                operation.
                                            </p>
                                        </IntroFeature>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </GauIntro>

                    {lazySections && lazySections.length > 0
                        ? lazySections.map((item, index) => {
                              return (
                                  <div id={item.id} key={index}>
                                      {lazySections.length === index + 1 ? (
                                              <ScrollNav data={scrollNavData} />
                                            ) : null}
                                        {item.section}
                                  </div>
                              );
                          })
                        : null}

                    <Controller>
                        <Scene
                            triggerHook="onEnter"
                            duration="100%"
                            offset="150%">
                            <Timeline>
                                <Tween
                                    from={{
                                        backgroundPosition: 'center 100px',
                                    }}
                                    to={{
                                        backgroundPosition: 'center -100px',
                                    }}>
                                    <GuaranteeWrapper id="guarantee">
                                        <Container>
                                            <Row>
                                                <Col
                                                    lg={{
                                                        size: 10,
                                                        offset: 1,
                                                    }}>
                                                    <div>
                                                        <UpperRegBox level="guarantee">
                                                            <PageHeading>
                                                                Our Guarantee
                                                            </PageHeading>
                                                            <PagePara>
                                                                Our GAU team is
                                                                completely
                                                                dedicated to
                                                                regulatory
                                                                compliance and
                                                                fraud detection.
                                                                We layer and
                                                                deploy resources
                                                                as needed to
                                                                assess and
                                                                understand
                                                                client systems
                                                                and
                                                                vulnerabilities.
                                                                We then provide
                                                                detailed
                                                                oversight and
                                                                insight into
                                                                compliance data,
                                                                regulation, and
                                                                contractual
                                                                compliance.
                                                            </PagePara>
                                                        </UpperRegBox>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </GuaranteeWrapper>
                                </Tween>
                            </Timeline>
                        </Scene>
                    </Controller>
                </Fragment>
            )}
        </Layout>
    );
};

const PreHeading = styled.div`
    font-size: 24px;
    font-family: ${gilroyMedium};
    padding-bottom: 40px;
    ${Media('xlscreens')} {
        font-size: 1.45vw;
        padding-bottom: 2.5vw;
    }
`;

const GauIntro = styled.section`
    position: relative;
    h2 {
        letter-spacing: -1px;
    }
    ${Container} {
        ${RowCentered} {
            &:nth-child(1) p {
                padding-left: 20px;
                ${Media('xlscreens')} {
                    padding-left: 1.8vw;
                    padding-top: 7vw;
                }
                ${Media('tablet')} {
                    padding-left: 0;
                    margin-top: 40px;
                }
            }
        }
    }
    ${Media('xlscreens')} {
        padding-top: 3vw;
        h2 {
            letter-spacing: -0.12vw;
        }
    }
`;

const GauIntroVideo = styled.div`
    width: 100%;
    margin-top: 70px;
    background-color: ${colors.gray_lighter['100']};
    border-radius: 32px;
    position: relative;
    height: 100vh;
    overflow: hidden;
    video {
        border-radius: 32px;
    }
    ${Media('xlscreens')} {
        margin-top: 4vw;
    }
    ${Media('tablet')} {
        height: auto;
    }
`;

const IntroFeature = styled.div`
    text-align: center;
    margin-top: 80px;
    h4 {
        font-size: 33px;
        font-family: ${gilroySemibold};
        letter-spacing: -1px;
        margin: 0px;
        margin-bottom: 30px;
    }
    p {
        font-family: ${gilroyLight};
        font-size: 16px;
        max-width: 90%;
        margin: 0px auto;
        line-height: 1.5;
    }
    ${Media('xlscreens')} {
        margin-top: 5.5vw;
        h4 {
            font-size: 1.7vw;
            letter-spacing: -0.08vw;
            margin-bottom: 2vw;
        }
        p {
            font-size: 1.15vw;
        }
    }
    ${Media('mobile')} {
        margin-top: 50px;
        h4 {
            font-size: 24px;
        }
        p {
            font-size: 14px;
            line-height: 1.4;
        }
    }
`;

const GuaranteeWrapper = styled.section`
    background: url('${baseURL}assets/images/guarantee-back.jpg') no-repeat
        center;
    background-size: cover;
    padding-bottom: 0;
    margin-bottom: 100px;
    h2 {
        margin: 0px;
    }
    p {
        margin: 30px 0px 0;
    }
    ${Media('xlscreens')} {
        margin-bottom: 7.3vw;
        p {
            margin: 3.5vw 0 0;
        }
    }
`;

export default GBPIndex;
