import React from 'react';
import Loader from 'components/loader/loader';
import LazyLoad from 'react-lazyload';
import { baseURL } from 'shared-components/theme/helpers';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import RetailIntro from 'components/retail/intro-section';
import {
    TransformSolution,
    CaseStudyPerformance,
    AmplifyBottomSection,
} from './sections';
import { BigCircle } from 'components/industries/common';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -200,
    },
    {
        name: 'cx-solution',
        target: 'cx-solution',
        offset: -100,
    },
    {
        name: 'cx-performance',
        target: 'cx-performance',
        offset: -100,
    },
    {
        name: 'amplify-section',
        target: 'amplify-section',
        offset: -100,
    },
];
const retailSolutions = [
    {
        imageUrl: `${baseURL}assets/images/industries/retail-solution1.jpg`,
        title: 'Flawless Seasonal Burst Capacity Expertise.',
        para: `The world’s largest retail & eCommerce brands are partnered with ibex to manage complex retail CX programs, and we’re consistently #1 in their networks.`,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/retail-solution2.jpg`,
        title: 'Retail CX Powered by Technology, BI, & AI.',
        para: `From Training Simulators to A.I. Customer Feedback Analytics, our Wave X platform provides end-to-end in-house performance technology paired with leading partner integrations to ensure continuous performance in and out of season.`,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/retail-solution3.jpg`,
        title: 'Adaptive Bestshore + @Home CX',
        para: `ibex offers a flexible global network of @home & in-center CX delivery options, providing persistent business continuity assurance, CX risk mitigation, and predictable performance outcomes.`,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/retail-solution4.jpg`,
        title: 'AI-driven Direct-Response Digital Marketing Engine.',
        para: `Our expert search, social, and native digital marketing team is ready to launch ads across platforms like Google, Facebook, Pinterest, LinkedIn, Twitter, and more, driving customers and merchants directly into your ecosystem.`,
    },
];
const performanceList = [
    {
        count: '01',
        imgUrl: `${baseURL}assets/images/industries/cx-performance-icon1.svg`,
        title: `Predictive Performance <br/>Management & BI Operation`,
        list: [
            'Voice of Customer Analytics',
            'Glide Path Modeling',
            'Channel Mapping & Optimization',
            'Value Stream Mapping',
            'Complexity Mapping',
            'Customer Journey Mapping',
        ],
    },
    {
        count: '02',
        imgUrl: `${baseURL}assets/images/industries/cx-performance-icon2.svg`,
        title: `Elite Training, Simulation, <br/>& Gamification Methodology`,
        list: [
            'Wave 0: Upfront Leadership Training ',
            'Experiential Learning ',
            'Virtual Training Simulation ',
            'Performance Gamification ',
            'Microlearning ',
            'eLearning',
        ],
    },
    {
        count: '03',
        imgUrl: `${baseURL}assets/images/industries/cx-performance-icon3.svg`,
        title: `Digitally Native <br/>Workforce`,
        list: [
            'Precision-Recruitment & Global Talent Pool ',
            '@Home & In-Center Flexibility ',
            'Weekly Engagement & Brand Affinity Programs ',
            'Real-Time Coaching & Desktop Monitoring ',
            'KPI Gamification & Incentivization ',
            'Purpose-Built Agent Performance Toolset',
        ],
    },
    {
        count: '04',
        imgUrl: `${baseURL}assets/images/industries/cx-performance-icon4.svg`,
        title: `Wave X Technology <br/>Platform`,
        list: [
            'Agent Training Simulators ',
            'Remote real-time agent monitoring ',
            'End-to-End System Security ',
            'Social Reputation Management ',
            'Customer Experience Surveys',
        ],
    },
    {
        count: '05',
        imgUrl: `${baseURL}assets/images/industries/cx-performance-icon5.svg`,
        title: `Launch-Ready PMO & <br/>Implementation Team`,
        list: [
            'Forecasting ',
            'Workforce Planning ',
            'Proactive Insights ',
            'Real-Time Performance Adjustments ',
            'Cost & Quality Analytics',
        ],
    },
    {
        count: '06',
        imgUrl: `${baseURL}assets/images/industries/cx-performance-icon6.svg`,
        title: `End-to-End Workforce <br/>Management`,
        list: [
            'Program initiation ',
            'Security & IT Readiness ',
            'End-to-End Planning ',
            'Reliable & Predictable Deployment ',
            'Steady-State Program Monitoring',
        ],
    },
];
const amplifyList = [
    {
        icon: `${baseURL}assets/images/industries/rocket.svg`,
        title: 'Fast.',
        para: `We ensure your universal agent teams are prepared to hit key KPIs quickly and consistently, ensuring there’s no gap in CX output once ibex is switched on.`,
    },
    {
        icon: `${baseURL}assets/images/industries/rise.svg`,
        title: 'Scalable.',
        para: `Our teams are ready to wind up or wind down depending on your needs throughout the year and throughout each seasonal ramp.`,
    },
    {
        icon: `${baseURL}assets/images/industries/chameleon.svg`,
        title: 'Agile.',
        para: `Our deep industry expertise and flexible agent teams mean we’re able to handle new information and fresh challenges with responsive and effective action, ensuring continuous performance through any crisis.`,
    },
    {
        icon: `${baseURL}assets/images/industries/crystal-ball.svg`,
        title: 'Predictable.',
        para: `Anyone can do well on a single program, but delivering consistent performance outcomes time and time again depends on a battle-tested methodology for repeatable, predictable, and reliable program execution that you can set your watch to.`,
    },
];

const Retail = () => {
    const sections = [
        {
            component: <TransformSolution retailSolutions={retailSolutions} />,
            isScrollNav: false,
        },
        {
            component: (
                <CaseStudyPerformance performanceList={performanceList} />
            ),
            isScrollNav: false,
        },
        {
            component: <AmplifyBottomSection amplifyList={amplifyList} />,
            isScrollNav: true,
        },
    ];

    return (
        <Layout page="retail">
            <Breadcrumbs>
                <li>Industry</li>
                <li>Retail</li>
            </Breadcrumbs>
            <BigCircle
                imgUrl={`${baseURL}assets/images/bigger-circle.svg`}
                top="27.7vw"
                imgHeight={990}
            />
            <RetailIntro
                rightImg={`${baseURL}assets/images/industries/retail-intro-bg.png`}
                tag="INDUSTRY"
                introHeading="Retail &<br /> eCommerce"
                introPara="Managing the retail expectation economy with <br />uncompromising year-round CX."
                introBtnText="Connect with us."
                right="-5%"
                top="-19.8vw"
            />
            {sections &&
                sections.map((section, index) => {
                    return (
                        <div
                            key={index}>
                            {section.isScrollNav && (
                                <ScrollNav data={scrollNavData} />
                            )}
                            {section.component}
                        </div>
                    );
                })}
        </Layout>
    );
};
export default Retail;
