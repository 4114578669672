import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';
import Media from 'shared-components/theme/medias';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';
import { gilroyMedium } from 'shared-components/theme/fonts';
import { WebinarMainHeading } from './main-heading';
import { WebinarMainPara } from './main-para';
import PdfReader from 'shared-components/pdfViewer/PdfReader';

export const WebinarContentIntro = ({
    heading,
    headingCol,
    headingSize,
    para,
    paraCol,
    youtubeLink,
    centered,
    pdfUrl,
}) => {
    return (
        <Wrapper>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                <Container>
                    {!pdfUrl && <PageLabel>Webinar</PageLabel>}
                    <CustomRow centered={centered}>
                        <Col lg={headingCol ? headingCol : 7}>
                            <IntroHeading>
                                <WebinarMainHeading
                                    tag="h1"
                                    noMargin={true}
                                    size={headingSize}>
                                    {ReactHtmlParser(heading)}
                                </WebinarMainHeading>
                                {!pdfUrl && (
                                    <PostHeadingLabel>
                                        <img
                                            src={`${baseURL}assets/images/video.svg`}
                                            alt="-"
                                        />
                                        Watch The Webinar Below
                                    </PostHeadingLabel>
                                )}
                            </IntroHeading>
                        </Col>
                        <Col lg={paraCol ? 12 - paraCol : 5}>
                            <IntroRight centered={centered}>
                                <WebinarMainPara>
                                    {ReactHtmlParser(para)}
                                </WebinarMainPara>
                            </IntroRight>
                        </Col>
                    </CustomRow>
                </Container>
                <Container>
                    <Row>
                        <Col md="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                {(pdfUrl && <PdfReader url={pdfUrl} />) || (
                                    <LandingVideo>
                                        <iframe
                                            width="100%"
                                            height="640"
                                            src={youtubeLink}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen></iframe>
                                    </LandingVideo>
                                )}
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </ScrollAnimation>
        </Wrapper>
    );
};

const Wrapper = styled.section`
    position: relative;
    ${Media('tablet')} {
        padding: 50px 0px;
    }
`;

const PageLabel = styled.div`
    font-size: 20px;
    color: ${colors.pink['100']};
    font-family: ${gilroyMedium};
    ${Media('xlscreens')} {
        font-size: 1.04vw;
        margin-bottom: 1vw;
    }
`;

const CustomRow = styled(Row)`
    justify-content: space-between;
    align-items: ${(props) => props.centered && 'center'};
`;

const IntroHeading = styled.div`
    position: relative;
    padding-bottom: 50px;
    ${Media('xlscreens')} {
        padding-bottom: 2.46vw;
    }
    ${Media('tablet')} {
        padding-bottom: 0px;
        margin-bottom: 50px;
    }
`;

const PostHeadingLabel = styled.div`
    display: flex;
    font-size: 20px;
    font-family: ${gilroyMedium};
    position: absolute;
    left: 0px;
    bottom: 0px;
    img {
        margin-right: 25px;
        height: 24px;
    }
    ${Media('xlscreens')} {
        font-size: 1.04vw;
        img {
            margin-right: 1.3vw;
            height: 1.25vw;
        }
    }
    ${Media('tablet')} {
        font-size: 16px;
        position: static;
        margin: 30px 0px;
        img {
            height: 18px;
            margin-right: 15px;
        }
    }
`;

const IntroRight = styled.div`
    padding-bottom: ${(props) => (props.centered ? '50px' : '0')};
    ${Media('xlscreens')} {
        padding-left: 3vw;
        padding-bottom: ${(props) => (props.centered ? '2.46vw' : '0')};
    }
    ${Media('tablet')} {
        padding-bottom: 0;
    }
    ${Media('mobile')} {
        padding: 0px;
    }
`;

const LandingVideo = styled.div`
    width: 100%;
    background-color: ${colors.gray_lighter['100']};
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 30px 0 0;
    iframe {
        height: 300px;
    }
    ${Media('xlscreens')} {
        padding: 3vw 0 1vw;
        iframe {
            height: 41vw;
        }
    }
    ${Media('tablet')} {
        height: auto;
        margin-top: 20px;
    }
`;
