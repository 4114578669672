import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyMediumItalic, gilroyBold } from '../theme/fonts';
import Media from '../theme/medias';

const MainHeading = ({ children, tag, noMargin, color, font }) => {
    return (
        <Heading
            font={font}
            as={tag === 'h1' ? 'h1' : 'h2'}
            margin={noMargin}
            color={color}
            className="main-heading">
            {children}
        </Heading>
    );
};

const Heading = styled.h2`
    font-size: 60px;
    color: ${(props) => (props.color ? props.color : colors.gray_dark['100'])};
    font-family: ${(props) => (props.font ? props.font : gilroyBold)};
    line-height: 1.1;
    margin: ${(props) => (props.margin ? '0' : '30px 0')};
    span {
        color: ${colors.pink['100']};
    }
    i {
        font-family: ${gilroyMediumItalic};
    }
    ${Media('xlscreens')} {
        font-size: 3.5vw;
        sup {
            font-size: 2vw;
        }
    }
    ${Media('tablet')} {
        font-size: 48px;
    }
    ${Media('mobile')} {
        font-size: 36px;
    }
`;

export default MainHeading;
