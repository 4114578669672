import React from 'react';
import styled from 'styled-components';
import { Col, Row } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    ContainerWideSRM,
    SRMSectionHeading,
    SRMFloatingSectionImage,
    SRMCallActionSection,
} from 'components/social-rep-man/common';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import MainPara from 'shared-components/main-para/main-para';
import { PagePara } from '../style';
import {
    RowCenteredBoth,
    FlexColumnCentered,
    FlexCentered,
} from 'shared-components/theme/grid-helpers';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';

export const ScoreInsightSection = ({ data, requestForm }) => {
    return (
        <>
            <InsightSection id="insight">
                <SRMFloatingSectionImage
                    x={[-20, 0]}
                    image={`${baseURL}assets/images/next-level-bg.svg`}
                    width={2394}
                    left="-80%"
                    bottom="-15%"
                />
                <ContainerWideSRM>
                    <RowCenteredBoth>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <SRMSectionHeading>
                                    Gamified insights drive stronger, faster
                                    results
                                </SRMSectionHeading>
                                <PagePara>
                                    <MainPara align="center">
                                        Turn your contact center into a CX
                                        performance hub.
                                    </MainPara>
                                </PagePara>
                            </ScrollAnimation>
                        </Col>
                    </RowCenteredBoth>
                    <Row>
                        {data &&
                            data.length > 0 &&
                            data.map((item, index) => {
                                return (
                                    <Col sm="12" lg="4" key={index}>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={true}>
                                            <InsightCards>
                                                <InsightIcon>
                                                    <img
                                                        src={item.icon}
                                                        alt="-"
                                                    />
                                                </InsightIcon>
                                                <InsightTitle>
                                                    {item.title}
                                                </InsightTitle>
                                                <InsightDesc>
                                                    <MainPara align="center">
                                                        {item.desc}
                                                    </MainPara>
                                                </InsightDesc>
                                            </InsightCards>
                                        </ScrollAnimation>
                                    </Col>
                                );
                            })}
                    </Row>
                </ContainerWideSRM>
            </InsightSection>

            <SRMCallActionSection
                heading="Ready to take your contact center performance to the next level?"
                btnText="Let's Talk"
                onBtnClick={requestForm}
            />
        </>
    );
};

const InsightSection = styled.section`
    background-color: ${colors.gray_lightest['100']};
    position: relative;
    z-index: 0;
    overflow: hidden;
    ${Media('xlscreens')} {
        padding: 7vw 0;
    }
`;

const InsightCards = styled(FlexColumnCentered)`
    background-color: ${colors.white['100']};
    border: 1px solid ${colors.black['20']};
    padding: 50px 30px;
    min-height: 550px;
    margin-top: 50px;
    justify-content: flex-start;
    ${Media('xlscreens')} {
        padding: 4vw 3.3vw;
        margin-top: 4vw;
        min-height: 28.7vw;
    }
    ${Media('tablet')} {
        min-height: 1px;
        max-width: 380px;
        margin-left: auto;
        margin-right: auto;
    }
`;

const InsightIcon = styled(FlexCentered)`
    min-height: 50px;
    margin-bottom: 20px;
    ${Media('xlscreens')} {
        min-height: 2.6vw;
        margin-bottom: 1.1vw;
        img {
            max-height: 2.5vw;
            max-width: 2.2vw;
        }
    }
`;

const InsightTitle = styled.h3`
    font-size: 20px;
    font-family: ${gilroyBold};
    margin-top: 0px;
    margin-bottom: 30px;
    line-height: 1.2;
    min-height: 80px;
    text-align: center;
    max-width: 75%;
    display: inline-flex;
    color: ${colors.socialRep['brandsBG']};
    ${Media('xlscreens')} {
        font-size: 1.1vw;
        margin-bottom: 2vw;
        min-height: 4vw;
    }
    ${Media('tablet')} {
        min-height: 1px;
        max-width: 100%;
    }
    ${Media('mobile')} {
        font-size: 16px;
    }
`;

const InsightDesc = styled(PagePara)`
    p {
        line-height: 2;
        margin: 0px;
    }
    ${Media('mobile')} {
        p {
            line-height: 1.5;
        }
    }
`;
