import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import {
    gilroyBold,
    gilroySemibold,
    gilroyMediumItalic,
    gilroyLight,
} from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';

const CaseStudyIntro = ({
    introHeading,
    introPara,
    headingImage,
    introImage,
    paraHeading,
    paddingLeft,
}) => {
    return (
        <Wrapper id="home">
            <Container>
                <Row>
                    <Col sm="12" md="12" lg="7">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <div>
                                <MainHeading tag="h1" noMargin={false}>
                                    {introHeading}
                                </MainHeading>
                            </div>
                        </ScrollAnimation>
                    </Col>
                    <Col sm="12" md="12" lg="5">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <IntroRight paddingLeft={paddingLeft}>
                                <HeadingPara>{paraHeading}</HeadingPara>
                                {headingImage ? (
                                    <HeadingImage>
                                        <img src={headingImage} alt="" />
                                    </HeadingImage>
                                ) : null}

                                <MainPara>{introPara}</MainPara>
                            </IntroRight>
                        </ScrollAnimation>
                    </Col>
                    <Col lg="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <CaseIntroImage>
                                <img src={introImage} alt="" />
                            </CaseIntroImage>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    );
};
const MainHeading = styled.h2`
    font-size: 38px;
    letter-spacing: -4px;
    color: ${colors.gray_dark['100']};
    font-family: ${gilroySemibold};
    line-height: 1.04;
    margin: ${(props) => (props.margin ? '0' : '5px 0')};
    span {
        color: ${colors.pink['100']};
    }
    i {
        font-family: ${gilroyMediumItalic};
    }
    ${Media('xlscreens')} {
        font-size: 4.24vw;
    }

    ${Media('tablet')} {
        margin-top: 1vw;
        letter-spacing: -1px;
        br {
            display: none;
        }
    }
`;
const HeadingPara = styled.h4`
    font-family: ${gilroyBold};
    font-size: 20px;
    color: ${colors.gray_dark['100']};
    ${Media('xlscreens')} {
        font-size: 0.85vw;
    }
`;
const CaseIntroImage = styled.div`
    margin-top: 50px;
    ${Media('xlscreens')} {
        margin-top: 2.4vw;
    }
    img {
        width: 100%;
    }
`;
const Wrapper = styled.section`
    position: relative;
    #video_conatiner {
        padding: 0;
    }
    ${Media('xlscreens')} {
        padding: 5.4vw 0 5vw;
        height: 61vw;
    }
`;
const IntroRight = styled.div`
    padding-left: ${(props) => (props.paddingLeft ? `50px` : `0px`)};
    ${Media('xlscreens')} {
        padding-left: ${(props) => (props.paddingLeft ? `2.61vw` : `0px`)};
    }
    ${Media('tablet')} {
        padding-left: 0px;
    }
`;
const HeadingImage = styled.div`
    display: block;
    margin: 30px 0;
`;
const MainPara = styled.p`
    font-size: 18px;
    line-height: 1.6;
    letter-spacing: 0vw;
    color: ${colors.gray_dark['100']};
    font-family: ${gilroyLight};
    ${Media('xlscreens')} {
        font-size: 1.01vw;
    }
`;
export default CaseStudyIntro;
