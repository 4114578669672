import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';

const monitorPoints = [
    {
        title: 'Industry <br /> Expertise',
        content: [
            {
                icon: `${baseURL}assets/images/telecom-icon.svg`,
                label: 'Telecom',
            },
            {
                icon: `${baseURL}assets/images/healthcare-icon.svg`,
                label: 'Healthcare',
            },
            {
                icon: `${baseURL}assets/images/financial-icon.svg`,
                label: 'Financial Services',
            },
            {
                icon: `${baseURL}assets/images/technology-icon.svg`,
                label: 'Technology',
            },
        ],
    },
    {
        title: 'Consumer Agency <br /> & Bureau Monitoring',
        content: [
            {
                label: 'FTC',
            },
            {
                label: 'FCC',
            },
            {
                label: 'CFPB',
            },
            {
                label: 'OCC',
            },
        ],
    },
    {
        title: 'Consumer Privacy <br /> Regulation Monitoring',
        content: [
            {
                label: 'HIPAA',
            },
            {
                label: 'FDCPA',
            },
            {
                label: 'GDPR',
            },
            {
                label: 'CCPA',
            },
            {
                label: 'TCPA',
            },
        ],
    },
];

export const MonitorSection = () => {
    return (
        <MonitorWrapper>
            <Container>
                <Row>
                    <Col lg="12">
                        <div>
                            <MonitorHeading>
                                The GAU Monitoring Framework
                            </MonitorHeading>
                        </div>
                    </Col>
                </Row>
                <div>
                    <Row noGutters={true}>
                        {monitorPoints && monitorPoints.length > 0
                            ? monitorPoints.map((item, index) => {
                                  return monitorPoints.length !== index + 1 ? (
                                      <Col key={index}>
                                          <MonitorTableWrapper>
                                              <MonitorTableHead>
                                                  {ReactHtmlParser(item.title)}
                                              </MonitorTableHead>
                                              <MonitorTableList>
                                                  {item.content &&
                                                  item.content.length > 0
                                                      ? item.content.map(
                                                            (inner, ind) => {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            ind
                                                                        }>
                                                                        {inner.icon &&
                                                                        inner.icon !==
                                                                            undefined ? (
                                                                            <>
                                                                                <MTIcon>
                                                                                    <img
                                                                                        src={
                                                                                            inner.icon
                                                                                        }
                                                                                        alt="-"
                                                                                    />
                                                                                </MTIcon>
                                                                                <span>
                                                                                    {
                                                                                        inner.label
                                                                                    }
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            inner.label
                                                                        )}
                                                                    </li>
                                                                );
                                                            }
                                                        )
                                                      : null}
                                              </MonitorTableList>
                                          </MonitorTableWrapper>
                                      </Col>
                                  ) : (
                                      <Col lg="5" key={index}>
                                          <MonitorTableWrapperLast>
                                              <MonitorTableHead>
                                                  {ReactHtmlParser(item.title)}
                                              </MonitorTableHead>
                                              <MonitorTableListLast>
                                                  {item.content &&
                                                  item.content.length > 0
                                                      ? item.content.map(
                                                            (inner, ind) => {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            ind
                                                                        }>
                                                                        {inner.icon &&
                                                                        inner.icon !==
                                                                            undefined ? (
                                                                            <>
                                                                                <MTIcon>
                                                                                    <img
                                                                                        src={
                                                                                            inner.icon
                                                                                        }
                                                                                        alt="-"
                                                                                    />
                                                                                </MTIcon>
                                                                                <span>
                                                                                    {
                                                                                        inner.label
                                                                                    }
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            inner.label
                                                                        )}
                                                                    </li>
                                                                );
                                                            }
                                                        )
                                                      : null}
                                              </MonitorTableListLast>
                                          </MonitorTableWrapperLast>
                                      </Col>
                                  );
                              })
                            : null}
                    </Row>
                </div>
                <Row>
                    <Col lg="12">
                        <div>
                            <MonitorCountWrapper>
                                <MonitorCountImg>
                                    <img
                                        src={`${baseURL}assets/images/monitor-count.svg`}
                                        alt="-"
                                    />
                                </MonitorCountImg>
                                <MonitorHeading color={colors.white['100']}>
                                    We actively monitor over 200
                                    <br />
                                    regulations & operational processes
                                </MonitorHeading>
                            </MonitorCountWrapper>
                        </div>
                    </Col>
                </Row>
            </Container>
        </MonitorWrapper>
    );
};

const MonitorWrapper = styled.section`
    padding-bottom: 0px;
    ${Media('tablet')} {
        padding-top: 0px;
        .col {
            flex: 0 0 100% !important;
            max-width: 100%;
        }
    }
`;

const MonitorHeading = styled.h2`
    font-size: 24px;
    font-family: ${gilroyBold};
    margin: 0px;
    text-align: center;
    line-height: 1.3;
    color: ${(props) => props.color};
    ${Media('xlscreens')} {
        font-size: 2.6vw;
    }
`;

const MonitorTableWrapper = styled.div`
    margin-right: -1px;
    border-right: 1px solid ${colors.gray_dark['100']};
    margin-top: 50px;
    ${Media('xlscreens')} {
        margin-top: 4vw;
    }
    ${Media('tablet')} {
        border-right: none;
    }
`;

const MonitorTableHead = styled.div`
    background-color: ${colors.white['100']};
    border: 1px solid ${colors.gray_dark['100']};
    border-right: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    font-size: 20px;
    line-height: 1.15;
    ${Media('xlscreens')} {
        height: 6.9vw;
        font-size: 1.45vw;
    }
    ${Media('tablet')} {
        border-right: 1px solid ${colors.gray_dark['100']};
    }
`;

const MonitorTableWrapperLast = styled(MonitorTableWrapper)`
    border-right: none;
    ${MonitorTableHead} {
        border-right: 1px solid ${colors.gray_dark['100']};
    }
`;

const MonitorTableList = styled.ul`
    padding: 10px 0;
    margin: 0px;
    ${Media('xlscreens')} {
        min-height: 17.8vw;
    }
    li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 16px;
        min-height: 50px;

        span {
            flex-grow: 1;
            text-align: center;
        }
        ${Media('xlscreens')} {
            font-size: 1.36vw;
            min-height: 4.2vw;
        }
    }
`;

const MTIcon = styled.div`
    text-align: center;
    margin-left: 20px;
    width: 30px;
    img {
        display: inline-block;
        width: 86%;
    }
    ${Media('xlscreens')} {
        width: 1.5vw;
        margin-left: 1.5vw;
    }
    ${Media('tablet')} {
        img {
            width: 64%;
        }
    }
`;

const MonitorTableListLast = styled(MonitorTableList)`
    display: flex;
    align-content: baseline;
    flex-wrap: wrap;
    li {
        flex: 0 0 50%;
        max-width: 50%;
    }
`;

const MonitorCountWrapper = styled.div`
    background: url('${baseURL}assets/images/monitor-count-back.jpg') no-repeat
        center;
    background-size: cover;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    margin-top: 60px;
    margin-bottom: -80px;
    ${Media('xlscreens')} {
        height: 33vw;
        margin-top: 5.5vw;
        margin-bottom: -8vw;
    }
    ${Media('mobile')} {
        height: 280px;
        h2 {
            font-size: 18px;
        }
    }
`;

const MonitorCountImg = styled.div`
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 83%;
    }
`;
