import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import Media from 'shared-components/theme/medias';

export const EbookFloatingLine = ({
    top,
    bottom,
    right,
    left,
    color,
    width,
    x,
    y,
}) => {
    return (
        <Wrapper
            top={top}
            bottom={bottom}
            right={right}
            left={left}
            color={color}
            width={width}
        >
            <ParallaxWrapper x={x} y={y}>
                <Line color={color} />
            </ParallaxWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: absolute;
    width: ${(props) => props.width};
    top: ${(props) => props.top};
    bottom: ${(props) => props.bottom};
    right: ${(props) => props.right};
    left: ${(props) => props.left};
    ${Media('tablet')} {
        display: none;
    }
`;

const ParallaxWrapper = styled(Parallax)`
    width: 100%;
    div {
        width: 100%;
    }
`;

const Line = styled.div`
    background-color: ${(props) => props.color};
    width: 100%;
    height: 11px;
    ${Media('xlscreens')} {
        height: 0.68vw;
    }
`;
