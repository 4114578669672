import React, { useEffect, useState } from 'react';
import Layout from 'components/layout';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';

import {
    gilroyLight,
    gilroyExtrabold,
    gilroyBold,
} from 'shared-components/theme/fonts';

const PrivaryPolicy = () => {
    const [privacyPolicy, setPrivacyPolicy] = useState({});

    useEffect(() => {
        getPrivacyPolicy();
    }, []);
    const getPrivacyPolicy = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/page?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        id: 3,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                setPrivacyPolicy(resData.data[0]);
            }
        } catch (err) {
            console.error(err);
        }
    };
    return (
        <Layout page="privacy-policy">
            <Wrapper>
                <Container>
                    <div>
                        <MainHeading>Privacy Policy</MainHeading>
                        {privacyPolicy &&
                            privacyPolicy.post_content &&
                            ReactHtmlParser(privacyPolicy.post_content)}
                    </div>
                </Container>
            </Wrapper>
        </Layout>
    );
};
const Wrapper = styled.div`
    padding: 30px;
    p {
        font-size: 18px;
        font-family: ${gilroyLight};
        padding-bottom: 50px;
        line-height: 1.4;
        ${Media('xlscreens')} {
            font-size: 0.94vw;
            padding-bottom: 2.63vh;
        }
        ${Media('tablet')} {
            padding-bottom: 20px;
        }
        a {
            color: ${colors.pink['100']};
        }
    }
    ul li {
        list-style-type: disc;
        padding: 5px 0px;
        font-size: 18px;
        font-family: ${gilroyLight};
        line-height: 1.6;
        ${Media('xlscreens')} {
            font-size: 0.94vw;
        }
    }

    h2 {
        font-size: 35px;
        font-family: ${gilroyBold};
        padding: 30px 0px;
        margin: 0;
        line-height: inherit;
        ${Media('xlscreens')} {
            font-size: 1.84vw;
            padding: 1.57vh 0vh;
        }
        ${Media('tablet')} {
            padding: 15px 0px;
        }
    }
`;
const MainHeading = styled.div`
    font-size: 70px;
    font-family: ${gilroyExtrabold};
    padding-bottom: 20px;
    ${Media('xlscreens')} {
        font-size: 3.68vw;
        padding-bottom: 1.05vh;
    }
    ${Media('tablet')} {
        padding-bottom: 10px;
    }
`;
export default PrivaryPolicy;
