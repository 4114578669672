import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { gilroyBold, sourceSansPro } from 'shared-components/theme/fonts';
import Button from 'shared-components/button/button';
import { baseURL } from 'shared-components/theme/helpers';
import { LetsDiscuss, AmplifySection } from 'components/industries/common';
import { baseURLPage } from 'shared-components/theme/helpers';

export const AmplifyBottomSection = ({ amplifyList }) => {
    return (
        <>
            <AmplifySection
                amplifyList={amplifyList}
                tag="MAKE IT SMART"
                heading="Amplify & Accelerate Your Retail & eCommerce CX"
                para="Featuring intelligent systems and hand-picked
                agents, ibex’s Retail & eCommerce CX solution <br />puts 
                the power of an industry-tested CX
                performance engine directly into the CX nerve
                center of your brand."
            />
            <EbookSection>
                <EbookDetail>
                    <div>
                        <EbookHeading>
                            A New Retail Model <br />
                            For Seasonal CX.
                        </EbookHeading>
                        <EbookText>
                            Discover How Your Brand Can Overcome <br />
                            The CX Challenges Of Peak Seasonal Demand.
                        </EbookText>
                        <EbookDownBtn>
                            <Button
                                ifButton={false}
                                round={true}
                                bgColor={colors.dark_purple['100']}
                                href={`${baseURLPage}resources/ebooks/g/retail-seasonal-peak-cx`}
                                target="_blank"
                                rel="noopener noreferrer">
                                Download the eBook
                            </Button>
                        </EbookDownBtn>
                    </div>
                </EbookDetail>
            </EbookSection>
            <LetsDiscuss
                heading="Looking to level-up your CX and ROI?"
                btnText="Talk to us"
            />
        </>
    );
};
const EbookSection = styled.div`
    background-image: url(${baseURL}assets/images/industries/seasonal-ebook-bg-new.png);
    background-position: center bottom;
    background-repeat: no-repeat;
    position: relative;
    background-color: ${colors.light_purple['100']};
    background-size: cover;
    ${Media('xlscreens')} {
        height: 31.5vw;
    }
`;
const EbookDetail = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background-color: ${colors.light_purple['100']};
    ${Media('xlscreens')} {
        position: absolute;
        right: 0;
        width: 40%;
    }
    ${Media('tablet')} {
        align-items: center;
        text-align: center;
        padding: 50px 0;
        width: 100%;
    }
`;
const EbookHeading = styled.h1`
    font-size: 36px;
    font-family: ${gilroyBold};
    line-height: 1.17;
    color: ${colors.dark_purple['100']};
    ${Media('xlscreens')} {
        font-size: 2.6vw;
    }
    ${Media('tablet')} {
        margin-top: 0;
    }
    ${Media('mobile')} {
        font-size: 30px;
    }
`;
const EbookText = styled.p`
    font-size: 14px;
    font-family: ${sourceSansPro};
    margin-top: 0;
    color: ${colors.dark_purple['100']};
    ${Media('xlscreens')} {
        font-size: 1.14vw;
    }
`;
const EbookDownBtn = styled.div`
    margin-top: 50px;
    a {
        padding: 15px 80px;
        min-width: unset;
    }
    ${Media('xlscreens')} {
        margin-top: 2.6vw;
        a {
            padding: 0.78vw 4.166vw;
        }
    }
    ${Media('mobile')} {
        a {
            padding: 10px 40px;
        }
    }
`;
