import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    gilroySemibold,
    gilroyLight,
    gilroyBold,
} from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

export const BundleHowItWorks = () => {
    return (
        <Wrap>
            <Container>
                <HowItWorksHeader>
                    <div>
                        <ArrowTT>
                            <img
                                src={`${baseURL}assets/images/bundledealer_arrowd.svg`}
                                width="8vw"
                            />
                        </ArrowTT>
                        <ArrowTitle>How BundleDealer Works</ArrowTitle>
                    </div>
                </HowItWorksHeader>

                <Row>
                    <Col lg="6">
                        <div>
                            <IconBundlerHolder>
                                <img
                                    src={`${baseURL}assets/images/bundle_addressbased.svg`}
                                    width="52vw"
                                />
                            </IconBundlerHolder>
                            <ContentBundlerHolder>
                                <HowBundleItemHeader>
                                    Address-Based Shopping
                                </HowBundleItemHeader>
                                <HowBundleItemText>
                                    BundleDealer uses consumer location data to
                                    showcase geographically optimized products
                                    and services. From here, brands and dealers
                                    can market any number of value-added
                                    packages or offers through the seamless
                                    BundleDealer UI, making upsell and
                                    cross-sell a breeze.
                                </HowBundleItemText>
                            </ContentBundlerHolder>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div>
                            <IconBundlerHolder>
                                <img
                                    src={`${baseURL}assets/images/bundle_comparisonshopping.svg`}
                                    width="52vw"
                                />
                            </IconBundlerHolder>
                            <ContentBundlerHolder>
                                <HowBundleItemHeader>
                                    Comparison Shopping & Deal Sharing
                                </HowBundleItemHeader>
                                <HowBundleItemText>
                                    BundleDealer.com allows consumers and agents
                                    the ability to cross-compare 3 different
                                    products from the same provider, or between
                                    3 different providers, all while allowing
                                    compared offers and deals to be quickly and
                                    conveniently shared with other customers via
                                    email.
                                </HowBundleItemText>
                            </ContentBundlerHolder>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <div>
                            <IconBundlerHolder>
                                <img
                                    src={`${baseURL}assets/images/bundle_agent.svg`}
                                    width="52vw"
                                />
                            </IconBundlerHolder>
                            <ContentBundlerHolder>
                                <HowBundleItemHeader>Agent</HowBundleItemHeader>
                                <HowBundleItemText>
                                    BundleDealer allows call center agents to
                                    enter an inbound consumer’s zip code and
                                    work with them to find and purchase the best
                                    localized offers to match their needs.
                                </HowBundleItemText>
                            </ContentBundlerHolder>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div>
                            <IconBundlerHolder>
                                <img
                                    src={`${baseURL}assets/images/bundle_consumer.svg`}
                                    width="52vw"
                                />
                            </IconBundlerHolder>
                            <ContentBundlerHolder>
                                <HowBundleItemHeader>
                                    Consumer
                                </HowBundleItemHeader>
                                <HowBundleItemText>
                                    BundleDealer allows an online shopper to
                                    simply enter their zip code and purchase
                                    from a localized comparison list of brand
                                    offers, products, and services.
                                </HowBundleItemText>
                            </ContentBundlerHolder>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6">
                        <div>
                            <IconBundlerHolder>
                                <img
                                    src={`${baseURL}assets/images/bundle_optimizedoffers.svg`}
                                    width="52vw"
                                />
                            </IconBundlerHolder>
                            <ContentBundlerHolder>
                                <HowBundleItemHeader>
                                    Optimized Offers & Upsells
                                </HowBundleItemHeader>
                                <HowBundleItemText>
                                    Offer Maximizer provides brands the ability
                                    to maximize the return on their marketing
                                    efforts through the industry’s first Offer
                                    Maximizer Algorithm (OMA). OMA leverages 500
                                    plus data points from over 5 years of
                                    conversion data to recommend products or
                                    offers that historically exhibit higher
                                    probabilities of service activation.
                                </HowBundleItemText>
                            </ContentBundlerHolder>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div>
                            <IconBundlerHolder>
                                <img
                                    src={`${baseURL}assets/images/bundle_unifiedshoppingcart.svg`}
                                    width="52vw"
                                />
                            </IconBundlerHolder>
                            <ContentBundlerHolder>
                                <HowBundleItemHeader>
                                    Unified Shopping Cart
                                </HowBundleItemHeader>
                                <HowBundleItemText>
                                    BundleDealer eliminates the need for
                                    consumers or agents go to a different
                                    website for each provider when entering an
                                    order. The buyflow for all providers is
                                    contained entirely within BundleDealer and
                                    is presented in a uniform and orderly manner
                                    for all providers.
                                </HowBundleItemText>
                            </ContentBundlerHolder>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6">
                        <div>
                            <div>
                                <IconBundlerHolder>
                                    <img
                                        src={`${baseURL}assets/images/bundle_multipleservices.svg`}
                                        width="52vw"
                                    />
                                </IconBundlerHolder>
                                <ContentBundlerHolder>
                                    <HowBundleItemHeader>
                                        Multiple Service Providers
                                    </HowBundleItemHeader>
                                    <HowBundleItemText>
                                        BundleDealer’s API is built to integrate
                                        with the back-end of any service
                                        provider, meaning offer and product
                                        information is always live and
                                        up-to-date.
                                    </HowBundleItemText>
                                </ContentBundlerHolder>
                            </div>
                        </div>
                        <div>
                            <div>
                                <IconBundlerHolder>
                                    <img
                                        src={`${baseURL}assets/images/bundle_bundledealerapi.svg`}
                                        width="52vw"
                                    />
                                </IconBundlerHolder>
                                <ContentBundlerHolder>
                                    <HowBundleItemHeader>
                                        BundleDealer API
                                    </HowBundleItemHeader>
                                    <HowBundleItemText>
                                        With the BundleDealer API, you can
                                        easily integrate BundleDealer
                                        functionality and capabilities on any
                                        brand website.
                                    </HowBundleItemText>
                                </ContentBundlerHolder>
                            </div>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div>
                            <IconBundlerHolder>
                                <img
                                    src={`${baseURL}assets/images/bundle_realtimeoffer.svg`}
                                    width="52vw"
                                />
                            </IconBundlerHolder>
                            <ContentBundlerHolder>
                                <HowBundleItemHeader>
                                    Real-Time Reporting
                                </HowBundleItemHeader>
                                <HowBundleItemText>
                                    BundleDealer tracks the status of the
                                    individual products related to submitted
                                    orders in virtual real-time, and provides
                                    detailed reports via the Reporting Center.
                                    <br /> <br />
                                    <RealTimeData>
                                        • &nbsp;&nbsp;Revenue Generating
                                        Units&nbsp;
                                        <br />• &nbsp;&nbsp;Commission Payment
                                        Status <br />
                                        • &nbsp;&nbsp;Installation Dates <br />
                                        • &nbsp;&nbsp;Moving Data <br />
                                        • &nbsp;&nbsp;Lead Source Detail <br />•
                                        &nbsp;&nbsp;Qualification Close Rate
                                    </RealTimeData>
                                </HowBundleItemText>
                            </ContentBundlerHolder>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Wrap>
    );
};

const RealTimeData = styled.div`
    font-family: ${gilroyBold};
`;

const HowBundleItemHeader = styled.div`
    font-family: ${gilroySemibold};
    font-size: 1.3vw;
    ${Media('tablet')} {
        font-size: 24px;
    }
`;

const HowBundleItemText = styled.div`
    font-family: ${gilroyLight};
    padding: 1vw 1vw 3vw 0vw;
    line-height: 1.6vw;
    font-size: 1vw;
    ${Media('tablet')} {
        padding: 20px 20px 50px 0;
        font-size: 16px;
        line-height: 1.3;
    }
`;

const IconBundlerHolder = styled.div`
    float: left;
    ${Media('tablet')} {
        img {
            max-width: 40px;
        }
    }
`;

const ContentBundlerHolder = styled.div`
    margin-left: 7vw;
    ${Media('tablet')} {
        margin-left: 60px;
    }
`;

const Wrap = styled.section`
    background: #fafafa;
    padding-top: 0px;
`;
const ArrowTT = styled.div`
    float: left;
    padding-top: 0.8vw;
    padding-right: 1.2vw;
    ${Media('tablet')} {
        padding-right: 15px;
        padding-top: 11px;
    }
`;

const ArrowTitle = styled.div`
    font-family: ${gilroySemibold};
    font-size: 2.5vw;
    ${Media('tablet')} {
        font-size: 36px;
    }
`;
const HowItWorksHeader = styled.div`
    margin-bottom: 5vw;
    ${Media('tablet')} {
        margin-bottom: 50px;
    }
`;
