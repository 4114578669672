import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import MainPara from 'shared-components/main-para/main-para';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { PageHeading } from './page-heading';
import { gilroyMedium } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

export const LaborSection = () => {
    return (
        <>
            <ReliableUptime id="ReliableUptime">
                <Container>
                    <Row>
                        <Col lg="8">
                            <ScrollAnimation
                                delay={100}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <PageHeading noMargin={true}>
                                    Reliable Uptime Protection <br /> & Crisis
                                    Readiness.
                                </PageHeading>
                            </ScrollAnimation>
                        </Col>
                        <Col lg={{ size: 4, offset: 0 }}>
                            <ScrollAnimation
                                delay={100}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <MainPara>
                                    In conjunction with proper Business
                                    Continuity Planning, adding @Home agents to
                                    your outsourcing mix will help protect your
                                    brand and mitigate risk against service
                                    interruption.
                                </MainPara>
                            </ScrollAnimation>
                        </Col>

                        <Col lg="12">
                            <ScrollAnimation
                                delay={100}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <ul>
                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/global-usa.svg`}
                                            alt="-"
                                        />
                                        <span>
                                            Distributed agents for
                                            impact-reduction during crisis
                                        </span>
                                    </li>

                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/calendar.svg`}
                                            alt="-"
                                        />
                                        <span>
                                            Improved Schedule Adherence &
                                            Reduced Absenteeism
                                        </span>
                                    </li>

                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/bcp-icon.svg`}
                                            alt="-"
                                        />
                                        <span>
                                            BCP & Crisis Planning Framework
                                            Integration & Training
                                        </span>
                                    </li>

                                    <li>
                                        <img
                                            src={`${baseURL}assets/images/glasses-icon.svg`}
                                            alt="-"
                                        />
                                        <span>Reduced Attrition</span>
                                    </li>
                                </ul>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </ReliableUptime>
        </>
    );
};

const ReliableUptime = styled.section`
    padding: 100px 0;
    background-color: ${colors.gray_lightest['100']};
    ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin-top: 100px;
        ${Media('xlscreens')} {
            margin-top: 5.2vw;
        }
        ${Media('tablet')} {
            margin-top: 50px;
        }
        li {
            display: flex;
            flex: 0 0 48%;
            font-size: 22px;
            background-color: ${colors.white['50']};
            font-family: ${gilroyMedium};
            margin-bottom: 15px;
            padding: 25px;
            align-items: center;
            margin-right: 15px;
            ${Media('xlscreens')} {
                font-size: 1.15vw;
                margin-bottom: 0.7vw;
                padding: 1.3vw;
            }

            ${Media('tablet')} {
                flex: 0 0 100%;
                font-size: 16px;
            }

            span {
                padding-left: 15px;
            }
        }
    }
`;
