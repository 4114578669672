import React from 'react';
import colors from 'shared-components/theme/colors';
import { FlexColumnCentered } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';
import ReactHtmlParser from 'react-html-parser';
import { gilroyExtrabold } from 'shared-components/theme/fonts';
import MainPara from 'shared-components/main-para/main-para';
import styled from 'styled-components';

export const SRMStrategyBox = ({ icon, heading, para, mediumPara }) => {
    return (
        <Wrapper>
            <StrategyIcon>
                <img src={icon} alt="-" />
            </StrategyIcon>
            <StrategyHeading>{ReactHtmlParser(heading)}</StrategyHeading>
            <MainPara align="center" styleTwo={mediumPara}>
                {ReactHtmlParser(para)}
            </MainPara>
        </Wrapper>
    );
};

const Wrapper = styled(FlexColumnCentered)`
    background-color: ${colors.white['100']};
    margin-top: 30px;
    justify-content: flex-start;
    ${Media('xlscreens')} {
        min-height: 23vw;
        margin-top: 1.56vw;
    }
    ${Media('tablet')} {
        margin-top: 15px;
        padding: 30px;
        padding-top: 10px;
    }
    ${Media('mobile')} {
        padding: 15px;
    }
`;

const StrategyIcon = styled.div`
    margin-bottom: 60px;
    margin-top: 80px;
    img {
        height: 78px;
        width: auto;
    }
    ${Media('xlscreens')} {
        margin-bottom: 3.12vw;
        margin-top: 4.16vw;
        img {
            min-height: 3.75vw;
            max-height: 5vw;
        }
    }
    ${Media('tablet')} {
        margin: 30px 0;
    }
    ${Media('mobile')} {
        img {
            height: 45px;
        }
    }
`;
const StrategyHeading = styled.div`
    margin-bottom: 30px;
    font-size: 30px;
    font-family: ${gilroyExtrabold};
    text-align: center;
    ${Media('xlscreens')} {
        font-size: 1.56vw;
        margin-bottom: 0px;
    }
    ${Media('tablet')} {
        font-size: 24px;
        margin-bottom: 10px;
    }
    ${Media('mobile')} {
        font-size: 20px;
        padding: 0px 10px;
    }
`;
