import React from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import MainPara from 'shared-components/main-para/main-para';
import MainHeading from 'shared-components/main-heading/main-heading';
import styled from 'styled-components';
import MapSection from '../map-section/map-section';
import { gilroyMedium } from 'shared-components/theme/fonts';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';

const GlobalDelivery = ({ mainTitle, mainPara }) => {
    return (
        <Container>
            <div>
                <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                        <CenterContent>
                            <MainHeading>{mainTitle}</MainHeading>
                            <MainPara>{mainPara}</MainPara>
                        </CenterContent>
                    </Col>
                    <Col lg="12">
                        <MapSection />
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <ClientStats>
                            <StatsWrap>
                                <Row noGutters={true}>
                                    <Col md="4">
                                        <StatsBox>
                                            <h4>~30,000</h4>
                                            <p>Employees</p>
                                        </StatsBox>
                                    </Col>
                                    <Col md="4">
                                        <StatsBox>
                                            <h4>31</h4>
                                            <p>
                                                Service Delivery <br />
                                                Locations
                                            </p>
                                        </StatsBox>
                                    </Col>
                                    <Col md="4">
                                        <StatsBoxLast>
                                            <h4>138M</h4>
                                            <p>Interactions/year</p>
                                        </StatsBoxLast>
                                    </Col>
                                </Row>
                            </StatsWrap>
                        </ClientStats>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

const CenterContent = styled.div`
    text-align: center;
`;

const ClientStats = styled.div`
    border: 1px solid ${colors.gray_dark['100']};
    margin-top: 40px;
    ${Media('xlscreens')} {
        margin-top: 2.8vw;
    }
`;

const StatsWrap = styled.div``;

const StatsBox = styled.div`
    border-right: 1px solid ${colors.gray_dark['100']};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 120px;
    h4 {
        font-size: 24px;
        font-family: ${gilroyMedium};
        font-weight: 500;
        margin: 0px;
        line-height: 1;
        span {
            color: ${colors.pink['100']};
        }
    }
    p {
        font-size: 14px;
        margin: 0px;
        line-height: 1.1;
        text-align: center;
    }
    ${Media('xlscreens')} {
        min-height: 9.1vw;
        h4 {
            font-size: 2.6vw;
        }
        p {
            font-size: 1.6vw;
        }
    }
    ${Media('mobile')} {
        border-right: none;
        border-bottom: 1px solid ${colors.gray_dark['100']};
    }
`;

const StatsBoxLast = styled(StatsBox)`
    border-right: none;
    ${Media('mobile')} {
        border-bottom: none;
    }
`;

export default GlobalDelivery;
