import React from 'react';
import styled from 'styled-components';
import { Container, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import { gilroySemibold, gilroyBold } from 'shared-components/theme/fonts';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import ReactHtmlParser from 'react-html-parser';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';

const data = [
    {
        imageUrl: `${baseURL}assets/images/att.png`,
        total: '164',
        desc: 'View Offers',
    },
    {
        imageUrl: `${baseURL}assets/images/spectrum.png`,
        total: '24',
        desc: 'View Offers',
    },
    {
        imageUrl: `${baseURL}assets/images/frontier.svg`,
        total: '12',
        desc: 'View Offers',
    },
];

export const BundleOffers = () => {
    return (
        <Wrap>
            <div>
                <Container>
                    <TabletContainer>
                        <TableMainImg
                            src={`${baseURL}assets/images/bundle_tablet.jpg`}
                            width="100%"
                        />
                        <TableContainer>
                            <TableContainerHeader>
                                Offers for you:
                            </TableContainerHeader>
                            {data && data.length > 0
                                ? data.map((item, index) => {
                                      return (
                                          <TableOfferRow key={index}>
                                              <RowCentered>
                                                  <Col lg="4">
                                                      <TableOfferImg
                                                          src={item.imageUrl}
                                                          alt="-"
                                                      />
                                                  </Col>
                                                  <Col lg="4">
                                                      <TableOffersCont>
                                                          <TableTotalOfLabel>
                                                              Total offer(s):
                                                          </TableTotalOfLabel>
                                                          <TableTotalOfPrice>
                                                              {item.total}
                                                          </TableTotalOfPrice>
                                                      </TableOffersCont>
                                                  </Col>
                                                  <Col lg="4">
                                                      <TablePriceInfo>
                                                          {ReactHtmlParser(
                                                              item.desc
                                                          )}
                                                      </TablePriceInfo>
                                                  </Col>
                                              </RowCentered>
                                          </TableOfferRow>
                                      );
                                  })
                                : null}
                        </TableContainer>
                    </TabletContainer>
                </Container>
            </div>
        </Wrap>
    );
};

const TableOffersCont = styled.div`
    text-align: center;
    ${Media('tablet')} {
        margin: 15px 0px;
    }
`;
const TablePriceInfo = styled.div`
    font-family: ${gilroyBold};
    font-size: 0.8vw;
    line-height: 1.3;
    color: ${colors.gray['50']};
    text-decoration: underline;
    ${Media('tablet')} {
        font-size: 14px;
        float: none;
    }
`;

const TableTotalOfPrice = styled.span`
    font-family: ${gilroyBold};
    font-size: 1vw;
    color: ${colors.twitter['100']};
    padding-left: 0.5vw;
    ${Media('tablet')} {
        font-size: 16px;
        padding-left: 10px;
    }
`;
const TableTotalOfLabel = styled.span`
    font-family: ${gilroyBold};
    font-size: 1vw;
    ${Media('tablet')} {
        font-size: 16px;
    }
`;
const TableOfferRow = styled.div`
    margin-bottom: 3vw;
    border-radius: 21px;
    border: 1px solid ${colors.gray['border']};
    padding: 1.4vw 4.5vw;
    width: 42vw;
    background: ${colors.white['100']};
    ${Media('tablet')} {
        width: auto;
        padding: 25px;
    }
`;

const TableOfferImg = styled.img`
    max-width: 100px;
    margin: 0px auto;
    ${Media('xlscreens')} {
        width: 113vw;
    }
`;

const TableContainerHeader = styled.div`
    font-family: ${gilroySemibold};
    font-size: 1.8vw;
    padding-bottom: 2vw;
    padding-top: 1vw;
    ${Media('tablet')} {
        font-size: 30px;
        padding-bottom: 30px;
        padding-top: 20px;
    }
`;

const TableContainer = styled.div`
    padding-top: 3vw;
    padding-left: 9vw;
    width: 48.5vw;
    height: 33vw;
    background: ${colors.white['100']};
    position: absolute;
    top: 10vw;
    left: 11vw;
    border-radius: 21px;
    text-align: center;
    ${Media('tablet')} {
        padding: 50px 20px 40px;
        width: 100%;
        height: auto;
        position: static;
        box-sizing: border-box;
        margin-bottom: 60px;
    }
`;

const Wrap = styled.section`
    background: ${colors.gray_lighter['100']};
    padding-top: 0px !important;
    padding-bottom: 2vw;
`;

const TabletContainer = styled.div`
    position: relative;
`;

const TableMainImg = styled.img`
    ${Media('tablet')} {
        display: none;
    }
`;
