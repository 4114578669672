import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { Link } from 'react-scroll';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';
import Layout from 'components/layout';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import {
    gilroyBlack,
    gilroyBold,
    gilroyExtrabold,
    gilroyMedium,
    gilroyRegular,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import colors from 'shared-components/theme/colors';
import { FaArrowDown } from 'react-icons/fa';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import { EbookFloatingImage } from 'components/ebook/common';
import LazyLoad from 'react-lazyload';
import Loader from 'components/loader/loader';
import { PromoteSection, FCXWinnerSection } from './sections';

const featList = [
    {
        title: 'The Awards',
        desc: 'The FinTech CX Leadership Awards <br /> honor the top CX innovators, leaders, <br /> and contributors to the FinTech space.',
    },
    {
        title: 'The Winners',
        desc: 'Award winners demonstrate a commitment to the customer experience that goes beyond the transactional, but to a fundamental understanding of the customer journey, resulting in higher customer satisfaction, deeper customer loyalty, and a satisfying bounce to the bottom line.',
    },
    {
        title: 'Who We Are',
        desc: 'We’re an advanced CX outsourcer <br /> working with leading High-Tech, <br />FinTech, and hyper-growth brands to <br /> deliver high-touch, secure, on-demand <br /> customer experiences.',
    },
];

const FintechCXAward = () => {
    const lazySections = [
        {
            id: 'promote',
            section: <PromoteSection />,
        },
        {
            id: 'winner',
            section: <FCXWinnerSection />,
        },
    ];

    return (
        <Layout bgColor={colors.fintech['bgColor']}>
            <IntroSection>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/fintech-award-intro-img-1.png`}
                    width={862}
                    right="13%"
                    top="5%"
                    y={['-10%', '10%']}
                />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/fintech-award-intro-img-2.png`}
                    width={178}
                    right="4%"
                    top="25%"
                    y={['-30%', '40%']}
                />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/fintech-award-intro-img-3.png`}
                    width={340}
                    right="34%"
                    top="1%"
                    y={['-40%', '20%']}
                />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/fintech-award-intro-img-4.svg`}
                    width={152}
                    right="10%"
                    top="25%"
                    y={['-30%', '40%']}
                />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/fintech-award-intro-img-5.png`}
                    width={58}
                    right="17%"
                    top="71%"
                    y={['0%', '50%']}
                />
                <Container>
                    <Row>
                        <Col sm="12" lg="7">
                            <IntroContent>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <IntroHeading>
                                        FINTECH CX LEADERSHIP AWARDS
                                    </IntroHeading>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}
                                    delay={300}>
                                    <IntroSubHeading>
                                        Celebrating The Best & Brightest in
                                        <span> FinTech CX</span>
                                    </IntroSubHeading>
                                    <IntroBtn
                                        to="nominees"
                                        smooth={true}
                                        offset={-50}>
                                        See the Nominees
                                        <IntroBtnIcon>
                                            <FaArrowDown />
                                        </IntroBtnIcon>
                                    </IntroBtn>
                                </ScrollAnimation>
                            </IntroContent>
                        </Col>
                    </Row>
                </Container>
            </IntroSection>

            <FeaturesSection>
                <Container>
                    <Row noGutters>
                        {featList &&
                            featList.length > 0 &&
                            featList.map((item, index) => {
                                return (
                                    <Col sm="12" md="12" lg="4" key={index}>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={false}
                                            delay={`${index * 2}00`}>
                                            <FeatureBox>
                                                <PageHeading
                                                    as="h3"
                                                    size="small">
                                                    {item.title}
                                                </PageHeading>
                                                <PagePara fontWeight="regular">
                                                    {ReactHtmlParser(item.desc)}
                                                </PagePara>
                                            </FeatureBox>
                                        </ScrollAnimation>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </FeaturesSection>

            {lazySections && lazySections.length > 0
                ? lazySections.map((item, index) => {
                      return (
                          <div id={item.id} key={index}>
                              {item.section}
                          </div>
                      );
                  })
                : null}
        </Layout>
    );
};

const IntroSection = styled.section`
    position: relative;
    ${Media('xlscreens')} {
        padding-top: 8vw;
        padding-bottom: 16vw;
        ${Container} {
            padding-left: 10vw !important;
            padding-right: 10vw !important;
        }
    }
`;

const IntroContent = styled.div`
    position: relative;
    z-index: 99;
`;

const IntroHeading = styled.h1`
    font-size: 90px;
    font-family: ${gilroyBold};
    line-height: 1;
    color: ${colors.fintech['text']};
    margin-bottom: 0px;
    ${Media('xlscreens')} {
        font-size: 6.667vw;
    }
    ${Media('tablet')} {
        font-size: 60px;
    }
    ${Media('mobile')} {
        font-size: 48px;
        margin-top: 20px;
    }
`;

const IntroSubHeading = styled.h3`
    font-size: 24px;
    font-family: ${gilroySemibold};
    line-height: 1.67;
    letter-spacing: -0.5px;
    color: ${colors.fintech['text']};
    padding: 30px 0;
    margin: 0px;
    span {
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        font-size: 1.45vw;
        letter-spacing: -0.07vw;
        padding: 2vw 0 4.5vw;
    }
    ${Media('mobile')} {
        font-size: 18px;
    }
`;

const IntroBtnIcon = styled(FlexCentered)`
    background-color: ${colors.fintech['text']};
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 80px;
    color: ${colors.pink['100']};
    font-size: 24px;
    @keyframes right {
        0% {
            transform: translate(0);
        }
        20% {
            transform: translateY(7px);
        }
        40% {
            transform: translate(0);
        }
        60% {
            transform: translateY(7px);
        }
        100% {
            transform: translate(0);
        }
    }
    ${Media('xlscreens')} {
        font-size: 1.2vw;
        width: 4.166vw;
    }
    ${Media('mobile')} {
        width: 50px;
        font-size: 14px;
    }
`;

const IntroBtn = styled(Link)`
    border: 3px solid ${colors.fintech['text']};
    font-size: 18px;
    font-family: ${gilroySemibold};
    text-transform: uppercase;
    position: relative;
    padding: 20px 25px;
    padding-right: 110px;
    &:hover {
        ${IntroBtnIcon} {
            svg {
                animation: right 2s infinite;
            }
        }
    }
    ${Media('xlscreens')} {
        font-size: 1.1vw;
        padding: 1.05vw 1.3vw;
        padding-right: 5.5vw;
    }
    ${Media('tablet')} {
        display: inline-flex;
    }
    ${Media('mobile')} {
        padding: 10px 20px;
        padding-right: 70px;
        font-size: 14px;
    }
`;

const FeaturesSection = styled.section`
    position: relative;
    padding: 0px !important;
    ${Media('xlscreens')} {
        ${Container} {
            padding-left: 6.15vw !important;
            padding-right: 6.15vw !important;
        }
    }
    ${Media('tablet')} {
        padding: 30px 0 50px !important;
    }
`;

const PageHeading = styled.h2`
    font-size: ${(props) => (props.size === 'small' ? '32px' : '50px')};
    font-family: ${(props) =>
        props.size === 'small' ? gilroyBlack : gilroyBold};
    margin: 0px;
    line-height: ${(props) => (props.size === 'small' ? '1.3' : '1')};
    letter-spacing: ${(props) => props.letterSpacing && '1px'};
    color: ${(props) => (props.color ? props.color : colors.fintech['text'])};
    text-transform: uppercase;
    text-align: ${(props) => props.alignCenter && 'center'};
    span {
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.size === 'small' ? '1.82vw' : '2.96vw')};
    }
    ${Media('mobile')} {
        font-size: ${(props) => (props.size === 'small' ? '26px' : '38px')};
        br {
            display: none;
        }
    }
`;

const PagePara = styled.p`
    font-size: ${(props) => (props.size === 'small' ? '16px' : '21px')};
    font-family: ${(props) =>
        props.extraBold ? gilroyExtrabold : gilroyMedium};
    font-family: ${(props) => props.fontWeight === 'regular' && gilroyRegular};
    margin: 0px;
    line-height: 1.5;
    color: ${(props) => (props.color ? props.color : colors.fintech['text'])};
    ${Media('xlscreens')} {
        font-size: ${(props) =>
            props.size === 'small' ? '0.833vw' : '0.95vw'};
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: ${(props) => (props.size === 'small' ? '14px' : '18px')};
    }
`;

const FeatureBox = styled.div`
    background-color: ${colors.white['100']};
    border-left: 2px solid ${colors.fintech['bgColor']};
    border-right: 2px solid ${colors.fintech['bgColor']};
    padding: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 460px;
    ${PageHeading} {
        margin-bottom: 30px;
    }
    ${PagePara} {
        letter-spacing: -1px;
    }
    ${Media('xlscreens')} {
        padding: 4vw 3.6vw;
        min-height: 24.3vw;
        ${PageHeading} {
            margin-bottom: 2.6vw;
        }
        ${PagePara} {
            letter-spacing: -0.03vw;
        }
    }
    ${Media('tablet')} {
        min-height: 1px;
        border: none;
        border-top: 4px solid ${colors.fintech['bgColor']};
    }
`;

export default FintechCXAward;
