import React, { useState } from 'react';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import SRMIntroSection from 'components/social-rep-man/intro-section';
import { baseURL } from 'shared-components/theme/helpers';
import DemoRequestForm from 'components/social-rep-man/demo-request-form';
import { EbookFloatingImage } from 'components/ebook/common';
import colors from 'shared-components/theme/colors';
import Loader from 'components/loader/loader';
import LazyLoad from 'react-lazyload';
import {
    MonitorBuildSection,
    PedigreeStrategySection,
    NeedCallAction,
} from './sections';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -210,
    },
    {
        name: 'monitor',
        target: 'monitor',
        offset: 0,
    },
    {
        name: 'build',
        target: 'build',
        offset: 0,
    },
    {
        name: 'strategy',
        target: 'strategy',
        offset: 0,
    },
    {
        name: 'need',
        target: 'need',
        offset: 0,
    },
];

const monitorList = [
    {
        icon: `${baseURL}assets/images/tech/reflecx-monitor-icon-1.svg`,
        title: 'Create',
        para:
            'Build, customize, and launch interactive customer surveys across email, web, text, social, and more. ',
    },
    {
        icon: `${baseURL}assets/images/tech/reflecx-monitor-icon-2.svg`,
        title: 'Automate',
        para:
            'Automated text & sentiment analytics deliver high-precision Voice of Customer  right to your dashboard. ',
    },
    {
        icon: `${baseURL}assets/images/tech/reflecx-monitor-icon-3.svg`,
        title: 'Act',
        para: 'Action & resolve incoming customer issues in real-time. ',
    },
    {
        icon: `${baseURL}assets/images/tech/reflecx-monitor-icon-4.svg`,
        title: 'Evolve',
        para:
            'Cycle RefleCX’s powerful customer insights back into your business for continuous program evolution. ',
    },
];

const buildData = [
    {
        icon: `${baseURL}assets/images/tech/reflecx-build-icon-1.svg`,
        title: 'Multimedia, Mobile Ready Surveys',
    },
    {
        icon: `${baseURL}assets/images/tech/reflecx-build-icon-2.svg`,
        title: 'OmniChannel Survey Delivery',
    },
    {
        icon: `${baseURL}assets/images/tech/reflecx-build-icon-3.svg`,
        title: 'Social Media Monitoring',
    },
    {
        icon: `${baseURL}assets/images/tech/reflecx-build-icon-4.svg`,
        title: 'Sentiment Analytics & Business Intelligence',
    },
    {
        icon: `${baseURL}assets/images/tech/reflecx-build-icon-5.svg`,
        title: 'Comprehensive, Action-Oriented Dashboard',
    },
    {
        icon: `${baseURL}assets/images/tech/reflecx-build-icon-6.svg`,
        title: 'Real-Time Issue Action & Resolution',
    },
    {
        icon: `${baseURL}assets/images/tech/reflecx-build-icon-7.svg`,
        title: 'End-to-End Performance Reporting',
    },
    {
        icon: `${baseURL}assets/images/tech/reflecx-build-icon-8.svg`,
        title: 'Dedicated Planning & Launch Team',
    },
    {
        icon: `${baseURL}assets/images/tech/reflecx-build-icon-9.svg`,
        title: 'Custom Survey Builder',
    },
    {
        icon: `${baseURL}assets/images/tech/reflecx-build-icon-10.svg`,
        title: 'VoC & CSAT Improvement',
    },
];

const pedigreeData = [
    {
        title: 'Performance',
        content: [
            {
                count: '7.5M',
                stats_desc:
                    'Surveys & Social Media Feedback Gathered Each Year',
            },
            {
                count: '40%',
                stats_desc: 'Average Survey Response Rate',
            },
            {
                count: '15M',
                stats_desc:
                    'Pieces of verbatim (comments) analyzed & categorized to date',
            },
            {
                count: '80K',
                stats_desc: 'Customer issues <br /> Identified annually',
            },
        ],
    },
    {
        title: 'Install Base',
        content: [
            {
                count: '6,500+',
                stats_desc: 'Retail Locations',
            },
            {
                detail: '41 Countries <br /> 36 Languages <br /> 2 Continents',
            },
        ],
    },
    {
        title: 'Channels',
        content: [
            {
                detail: 'Email, SMS, IVR, <br /> Chat, Social Media',
            },
            {
                detail:
                    'Pioneered the first <br /> VOC platform in the <br /> automotive Industry',
            },
        ],
    },
];

const satisfyData = [
    {
        image: `${baseURL}assets/images/tech/reflecx-strategy-image-1.jpg`,
        heading: 'Plan & <br /> Design',
        para:
            '• Survey strategy and design <br />• Blueprint Meeting System <br />• Requirements Sign Off Workback <br />• Schedule Weekly Meetings ',
    },
    {
        image: `${baseURL}assets/images/tech/reflecx-strategy-image-2.jpg`,
        heading: 'Setup & <br /> Development',
        para:
            '• Infrastructure Setup <br /> • Coding and development begins <br />• Agreed upon Ul/UX determined <br />• SSO configuration',
    },
    {
        image: `${baseURL}assets/images/tech/reflecx-strategy-image-3.jpg`,
        heading: 'Quality <br />Assurance & UAT',
        para:
            '• Rigid in-depth quality assurance processes  <br /> • Interactive UAT experience <br /> with Launch Leader <br /> • Sign off on Pilot Program',
    },
    {
        image: `${baseURL}assets/images/tech/reflecx-strategy-image-4.jpg`,
        heading: 'Training & <br /> Launch',
        para:
            '• Webinar and in person <br /> pre-launch training targeted <br /> by user role:  <br /> • Corp/Mgr/Emp Responsive User Guide & Quick Reference Guide <br />• Ongoing Training Program ',
    },
];

const needList = [
    'Visibility to individual and aggregate customer journeys',
    'Intelligent, predictive and proactive engagement',
    'Closed-Loop Real-Time Issue Resolution',
    'Native Language Text & Voice Analytics',
    'Powerful Self-Service Tools',
    'Social Media Monitoring & Strategy',
    'Analysis beyond survey data',
    'Complete Business Intelligence',
];

const RefleCX = () => {
    const [demoRequestForm, setDemoRequestForm] = useState(false);

    const sections = [
        {
            component: (
                <MonitorBuildSection
                    monitorList={monitorList}
                    buildData={buildData}
                />
            ),
            isScrollNav: false,
        },
        {
            component: (
                <PedigreeStrategySection
                    satisfyData={satisfyData}
                    pedigreeData={pedigreeData}
                />
            ),
            isScrollNav: false,
        },
        {
            component: (
                <NeedCallAction
                    needList={needList}
                    setDemoRequestForm={setDemoRequestForm}
                />
            ),
            isScrollNav: true,
        },
    ];

    return (
        <Layout>
            <Breadcrumbs>
                <li>Wave X</li>
                <li>Technology</li>
            </Breadcrumbs>

            <DemoRequestForm
                handleDemoForm={(val) => setDemoRequestForm(val)}
                formState={demoRequestForm}
                marketFormId="1197"
            />

            <SRMIntroSection
                preheading="Keep the conversation flowing."
                heading="RefleCX"
                para="An industrial strength CX survey platform for nimble<br /> survey delivery, end-to-end feedback analytics, <br /> real-time issue resolution, and frictionless customer <br /> experience management, at scale. "
                customImage={
                    <EbookFloatingImage
                        image={`${baseURL}assets/images/tech/reflecx-intro.png`}
                        width={1008}
                        top="3%"
                        right="0"
                        x={['-10%', '10%']}
                    />
                }
                btnBgColor={colors.reflecx['main']}
                onBtnClick={() => setDemoRequestForm(true)}
            />

            {sections &&
                sections.map((section, index) => {
                    return (
                        <div
                            key={index}>
                            {section.isScrollNav && (
                                <ScrollNav data={scrollNavData} />
                            )}
                            {section.component}
                        </div>
                    );
                })}
        </Layout>
    );
};

export default RefleCX;
