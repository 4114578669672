import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import ScrollAnimation from 'react-animate-on-scroll';
import { gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ReactHtmlParser from 'react-html-parser';
const StatsBox = ({ statsNumber, statsPara, backgroundColor }) => {
    return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <Wrapper backgroundColor={backgroundColor}>
                <StatsNumber>{statsNumber}</StatsNumber>
                <StatsPara>{ReactHtmlParser(statsPara)}</StatsPara>
            </Wrapper>
        </ScrollAnimation>
    );
};
const Wrapper = styled.div`
    border: 1px solid ${colors.gray['100']};
    min-height: 392px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : 'transparent'};
    ${Media('tablet')} {
        justify-content: center;
    }
    ${Media('xlscreens')} {
        min-height: 20.5vw;
    }
    p {
        font-family: ${gilroySemibold};
        font-size: 52px;
        line-height: 1.2;
        letter-spacing: -2px;
        color: ${colors.gray_dark['100']};
        ${Media('xlscreens')} {
            font-size: 2.7vw;
        }
    }
    ${Media('tablet')} {
        margin: 20px 0px;
    }
`;
const StatsNumber = styled.div`
    font-family: ${gilroySemibold};
    font-size: 70px;
    color: ${colors.gray_dark['100']};
    ${Media('tablet')} {
        margin-bottom: 15px;
    }
    ${Media('xlscreens')} {
        font-size: 3.66vw;
        margin-top: 4vw;
        margin-bottom: 2.09vw;
    }
`;
const StatsPara = styled.div`
    font-family: ${gilroySemibold};
    font-size: 25px;
    color: ${colors.gray_dark['100']};
    letter-spacing: 0px;
    line-height: 1.4;
    ${Media('tablet')} {
        padding: 0px 20px;
        padding-top: 15px;
        br {
            display: none;
        }
    }
    ${Media('xlscreens')} {
        font-size: 1.3vw;
        min-height: 5.6vw;
    }
`;
export default StatsBox;
