import React from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import { gilroySemibold } from 'shared-components/theme/fonts';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';

const ProductGoals = ({ img, productGoalsData, noPadding }) => {
    return (
        <Wrapper>
            <ScrollAnimation animateIn="fadeInUp">
                <Container>
                    <Row>
                        {img && (
                            <Col lg="4" md="12">
                                <ImageWrapper>
                                    <img src={img} alt="-" />
                                </ImageWrapper>
                            </Col>
                        )}
                        {productGoalsData &&
                            productGoalsData.map((content, ind) => {
                                return (
                                    <Col lg={img ? '4' : '6'} key={ind} md="6">
                                        <ListWrapper noPadding={noPadding}>
                                            <Heading>{content.heading}</Heading>
                                            <List>
                                                <ul>
                                                    {content.data &&
                                                        content.data.map(
                                                            (item, ind) => (
                                                                <li key={ind}>
                                                                    <img
                                                                        src={
                                                                            item.img
                                                                        }
                                                                        alt=""
                                                                    />
                                                                    {item.desc}
                                                                </li>
                                                            )
                                                        )}
                                                </ul>
                                            </List>
                                        </ListWrapper>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </ScrollAnimation>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    margin-bottom: 130px;
    ${Media('xlscreens')} {
        margin-bottom: 6.8vw;
    }
    ${Media('tablet')} {
        margin-bottom: 20px;
    }
`;
const Heading = styled.div`
    font-size: 50px;
    font-family: ${gilroySemibold};
    ${Media('xlscreens')} {
        font-size: 2.61vw;
    }
    ${Media('tablet')} {
        font-size: 40px;
    }
`;
const ListWrapper = styled.div`
    padding-left: ${(props) => (props.noPadding ? '0px' : '80px')};
    ${Media('xlscreens')} {
        padding-left: ${(props) => (props.noPadding ? '0px' : '4.18vw')};
    }
    ${Media('tablet')} {
        padding-left: 0px;
        padding-top: 40px;
    }
`;
const List = styled.div`
    padding-top: 60px;
    ul {
        padding: 0;
        li {
            display: flex;
            font-family: ${gilroySemibold};
            color: ${colors.gray_dark['100']};
            font-size: 21px;
            margin-bottom: 20px;
            align-items: center;
            img {
                margin-right: 20px;
                width: 20px;
                height: 20px;
            }
            ${Media('tablet')} {
                font-size: 18px;
                img {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
    ${Media('xlscreens')} {
        padding-top: 3vw;
        ul li {
            font-size: 1.1vw;
            margin-bottom: 1vw;
            img {
                margin-right: 1.04vw;
                width: 1.04vw;
                height: 1.04vw;
            }
        }
    }
    ${Media('tablet')} {
        padding-top: 20px;
    }
`;
const ImageWrapper = styled.div`
    img {
        width: 100%;
        height: auto;
    }
`;
export default ProductGoals;
