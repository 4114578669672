import React, { Fragment } from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import { gilroySemibold } from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';
const digitalVideos = [
    {
        href: '/adcast',
        animationDelay: 300,
        vidID: 'videoOne',
        vidSrc: `${baseURL}assets/videos/001.mp4`,
        viewHolderImg: `${baseURL}assets/images/digital_ac.svg`,
        viewHolderWidth: '90vw',
        subtitImg: `${baseURL}assets/images/digital_adcast.svg`,
        subtitWidth: '121vw',
        viewholderText: (
            <Fragment>
                High-Volume Ad Launching. <br />
                A.I. Guided Ad Bidding.
            </Fragment>
        ),
        posRel: true,
    },
    {
        href: '/bundledealer',
        animationDelay: 400,
        vidID: 'videoTwo',
        vidSrc: `${baseURL}assets/videos/002.mp4`,
        viewHolderImg: `${baseURL}assets/images/digital_bd.svg`,
        viewHolderWidth: '90vw',
        subtitImg: `${baseURL}assets/images/digital_bundledealer.svg`,
        subtitWidth: '221vw',
        viewholderText: (
            <Fragment>
                Address-based <br />
                Comparison Shopping API.
            </Fragment>
        ),
        posRel: false,
    },
    {
        href: '/cx#cxrating',
        animationDelay: 500,
        vidID: 'videoThree',
        vidSrc: `${baseURL}assets/videos/003.mp4`,
        viewHolderImg: `${baseURL}assets/images/digital_rx.svg`,
        viewHolderWidth: '90vw',
        subtitImg: `${baseURL}assets/images/digital_refleCX.svg`,
        subtitWidth: '136vw',
        viewholderText: (
            <Fragment>
                5-Star Survey & <br />
                Feedback Analytics Platform.
            </Fragment>
        ),
        posRel: false,
    },
];
export const DigitalVideo = () => {
    return (
        <VideoWrapper id="videos">
            {digitalVideos &&
                digitalVideos.map((vid, ind) => (
                    <ContentVideoHolder key={ind}>
                        <a
                            href={vid.href}
                            target="_blank"
                            rel="noopener noreferrer">
                            <div>
                                <VideoDigital
                                    id={vid.vidID}
                                    loop
                                    preload="auto"
                                    autos="true"
                                    video={vid.vidSrc}
                                    videotype="mp4"
                                    muted="muted"
                                    autoPlay
                                    posRel={vid.posRel}>
                                    <source src={vid.vidSrc} type="video/mp4" />
                                    Your browser does not support the video tag.
                                    I suggest you upgrade your browser.
                                </VideoDigital>
                            </div>
                            <ImageContentVideoHolder>
                                <TitleViewHoldeR>
                                    <img
                                        src={vid.viewHolderImg}
                                        width={vid.viewHolderWidth}
                                    />
                                </TitleViewHoldeR>

                                <SubTitleViewrHolder>
                                    <img
                                        src={vid.subtitImg}
                                        width={vid.subtitWidth}
                                    />
                                </SubTitleViewrHolder>
                                <TextContentViewerHolder>
                                    {vid.viewholderText}
                                </TextContentViewerHolder>
                            </ImageContentVideoHolder>
                        </a>
                    </ContentVideoHolder>
                ))}
        </VideoWrapper>
    );
};

const VideoWrapper = styled.div``;

const TextContentViewerHolder = styled.div`
    text-align: center;
    color: #fff;
    margin-top: 5vw;
    font-size: 1.1vw;
    ${Media('tablet')} {
        font-size: 16px;
    }
`;

const SubTitleViewrHolder = styled.div`
    text-align: center;
    width: 100%;
    margin-top: 5vw;
    img {
        display: inline-block;
    }
`;

const TitleViewHoldeR = styled.div`
    font-family: ${gilroySemibold};
    text-align: center;
    width: 100%;
    img {
        display: inline-block;
    }
`;
const ImageContentVideoHolder = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: inline;
    align-content: center;
`;

const ContentVideoHolder = styled.div`
    height: 44vw;
    width: 33.3%;
    position: relative;
    float: left;
    ${Media('tablet')} {
        float: none;
        height: 390px;
        width: 100%;
        overflow: hidden;
    }
`;
const VideoDigital = styled.video`
    height: 44vw;
    object-fit: cover;
    overflow: hidden;
    transition: width 0.25s ease-out;
    width: 100%;
    background-color: ${colors.gray_dark['100']};
    ${Media('tablet')} {
        height: 400px;
    }
    ${(props) => {
        if (props.posRel) {
            return `position:relative`;
        }
    }}
`;
