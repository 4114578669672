import React, { Fragment } from 'react';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import Layout from 'components/layout';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import CaseStudyIntro from 'components/case-study/case-study-intro';
import CaseGrowth from 'components/case-study/case-growth';
import BigParagraph from 'components/case-study/case-big-paragraph';
import Media from 'shared-components/theme/medias';
import WhoTheyAre from 'components/case-study/case-who-they-are2';
import QuoteBox from 'components/case-study/case-quotebox2';
import CaseInfoSection from 'components/case-study/case-info-section';
import BottomSection from 'components/case-study/case-bottom-section2';
import { Parallax } from 'react-scroll-parallax';
import { gilroySemibold } from 'shared-components/theme/fonts';

import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import ProductGoals from 'components/case-study/case-product-goals';
import Loader from 'components/loader/loader';
import { baseURL } from 'shared-components/theme/helpers';

const Trendy = () => {
    const scrollNavData = [
        {
            name: 'home',
            target: 'home',
            offset: -100,
        },
        {
            name: 'contentText',
            target: 'contentText',
            offset: -100,
        },
        {
            name: 'whotheyAre',
            target: 'whotheyAre',
            offset: -100,
        },
        {
            name: 'infoSection',
            target: 'infoSection',
            offset: -100,
        },
        {
            name: 'goalSection',
            target: 'goalSection',
            offset: -200,
        },
        {
            name: 'bottomSection',
            target: 'bottomSection',
            offset: -100,
        },
    ];
    const productGoalsData = [
        {
            heading: 'Product used',
            data: [
                {
                    img: `${baseURL}assets/images/ad.svg`,
                    desc: 'Ads',
                },
                {
                    img: `${baseURL}assets/images/video.svg`,
                    desc: 'Video',
                },
                {
                    img: `${baseURL}assets/images/carousel.svg`,
                    desc: 'Carousel',
                },
                {
                    img: `${baseURL}assets/images/people.svg`,
                    desc: 'Audience Insights',
                },
                {
                    img: `${baseURL}assets/images/icon-material-chat.svg`,
                    desc: 'Conversion Tracking',
                },
                {
                    img: `${baseURL}assets/images/feather-target.svg`,
                    desc: 'Custom Audiences',
                },
                {
                    img: `${baseURL}assets/images/equals.svg`,
                    desc: 'Lookalike Audiences',
                },
            ],
        },
        {
            heading: 'Goals',
            data: [
                {
                    img: `${baseURL}assets/images/add-circle.svg`,
                    desc: 'Raise Brand Awareness',
                },
                {
                    img: `${baseURL}assets/images/add-to-queue.svg`,
                    desc: 'Drive Online Sales',
                },
            ],
        },
    ];
    const growthItems = [
        {
            left: '35%',
            right: 'decrease in cost <br/>per purchase',
        },
        {
            left: '32%',
            right: `increase in total <br/>purchases`,
        },
        {
            left: '3.5X',
            right: `growth in revenue, <br/>year over year`,
        },
    ];
    return (
        <Layout page="digital">
            <Breadcrumbs>
                <li>Digital</li>
                <li>Trendy Butler Case Study</li>
            </Breadcrumbs>
            <CaseStudyIntro
                introHeading={
                    <Fragment>
                        Pixel-perfect <br /> subscriptions
                    </Fragment>
                }
                introPara="The men’s apparel subscription service used ibex Digital and the Facebook pixel to make precise projections and increase revenue, resulting in a 3.5X increase in year-over-year revenue and a 35% drop in cost per purchase."
                headingImage={`${baseURL}assets/images/trendy-butler-icon.svg`}
                introImage={`${baseURL}assets/images/wear-your-best.jpg`}
            />
            <BigTextSection>
                <BigText id="bigText">
                    <Parallax x={['-110%', '100%']}>
                        <OneText id="bigTextOne">Pixel meets</OneText>
                    </Parallax>
                    <Parallax x={['8%', '-53%']}>
                        <TwoText id="bigTextTwo">personality.</TwoText>
                    </Parallax>
                </BigText>
            </BigTextSection>
            <ContentSectionArea id="contentText">
                <Container>
                    <Row>
                        <Col lg="12">
                            <BigParagraph bigParagraph="To achieve its revenue goals, Trendy Butler required a digital marketing partner with expertise in Facebook advertising. Using the Facebook pixel, ibex Digital helped Trendy Butler achieve & outperform its growth goals." />
                        </Col>
                    </Row>
                </Container>
            </ContentSectionArea>

            
                <GrowthContainer>
                    <Container>
                        <Row>
                            {growthItems &&
                                growthItems.map((item, index) => (
                                    <Col lg="4" key={index}>
                                        <CaseGrowth
                                            left={item.left}
                                            right={item.right}
                                        />
                                    </Col>
                                ))}
                        </Row>
                    </Container>
                </GrowthContainer>
                <ScrollWrapper id="whotheyAre">
                    <WhoTheyAre
                        tagLine={['Their Story', 'Their Goal']}
                        contentHeading={[
                            'Style and class, within reach.',
                            'Pixel-powered projections.',
                        ]}
                        contentParagraph={[
                            'Based in Los Angeles, Trendy Butler is a men’s apparel subscription service offering high-value fashion for a monthly membership fee. The company has quickly become popular, thanks to its exceptional member discounts and the exposure it provides for its featured fashion brands.',
                            'To ensure its ongoing success, ibex Digital used the Facebook pixel to provide Trendy Butler with accurate growth projections critical to expanding marketing, sales and production plans.',
                        ]}
                        contentImage={`${baseURL}assets/images/trendy-butler-img1.jpg`}
                        multiPara={true}
                    />
                </ScrollWrapper>
                <QuoteBox
                    quotePara="The ability to measure sales was the first sign that our business would be a success. Our first day of breaking 100-plus sales always sticks out. Point blank, our marketing plan is Facebook, Facebook, and more Facebook…Facebook is 100% the backbone of our customer acquisition efforts and it’s been made even better with the improved Facebook pixel, which was easy to migrate over to."
                    quoteName="Ali Najafian,"
                    quoteDesignation="co-founder, Trendy Butler"
                    offsetMinus={false}
                    paddingTop={true}
                />

            
            <ScrollWrapper id="infoSection">
                <CaseInfoSection
                    img={`${baseURL}assets/images/trendy-butler-info.jpg`}
                    heading={
                        <Fragment>
                            Information <br />
                            drives conversion.
                        </Fragment>
                    }
                    paragraph={
                        <Fragment>
                            Partnering with agency ibex.digital, Trendy
                            Butler switched from the conversion pixel to the
                            improved Facebook pixel in May 2016. The
                            Facebook pixel is not only able to optimize and
                            track conversions, but also can be used to
                            retarget ads to specific audiences and run
                            dynamic ads.
                            <br />
                            <br />
                            The Trendy Butler team targeted ads to males
                            aged 18–44 interested in fitness, dating apps
                            and luxury brands. It also used the Facebook
                            pixel to remarket to Custom Audiences built from
                            its email lists and people who had visited its
                            website. By placing one element of the pixel—the
                            event code—on its website, they could track up
                            to 9 standard events.&nbsp;
                            <span>They chose the following 5 events:</span>
                        </Fragment>
                    }
                    list={[
                        'View Content',
                        'Add to Cart',
                        'Initiate Checkout',
                        'Add payment info',
                        'Make purchase',
                    ]}
                />
            </ScrollWrapper>
            <Container>
                <Paragraph>
                    Using Audience Insights to analyze website traffic, the
                    team built lookalike audiences from their customer and
                    lead data, initially testing 1% lookalike audiences from
                    various seed audiences (created using subsets of
                    customer and lead data) and expanding to 2%–10% of the
                    top- performing groups.
                    <br />
                    <br />
                    Trendy Butler used link ads and the carousel format to
                    showcase its products, while video ads highlighted
                    lifestyle shots and customer reviews. Its “unboxing”
                    videos, however, were the most successful. The ads were
                    paired with a $15 discount and a “Learn More” call to
                    action. Campaign budgets were managed with manual
                    bidding, optimized for conversions and were based on the
                    ads’ performance during the last 7 and the last 30 days.
                </Paragraph>
            </Container>

            
            <ScrollWrapper id="goalSection">
                <ProductGoals
                    productGoalsData={productGoalsData}
                    img={`${baseURL}assets/images/trendy-butler-fbslider.jpg`}
                />
            </ScrollWrapper>

           
                <ScrollNav data={scrollNavData} />
                <ScrollWrapper id="bottomSection">
                    <BottomSection
                        withoutOffset={true}
                        leftHeading="Increased purchases Reduces cost."
                        leftPara="In the first 30 days after ibex.digital helped it switch to the Facebook pixel, Trendy Butler tracked website conversions and retargeted ads to potential customers more accurately. Its ongoing campaign, which began in May 2016, achieved:"
                        rightList={[
                            '35% decrease in cost per purchase',
                            '32% increase in total purchases',
                            '3.5X growth in revenue, year over year',
                        ]}
                    />
                    <QuoteBox
                        quotePara="The Facebook pixel enables us to serve the right ads 
                    at the key points along the consumer’s journey to 
                    purchase. Whether it’s for a new startup looking for 
                    support with their go-to-market strategy or an 
                    established brand looking to scale their efforts, we 
                    use Facebook as an integral part of our strategy for 
                    both our clients and ourselves."
                        quoteName="Sanaz Limouee,"
                        quoteDesignation="co-founder, ibex.digital"
                        offsetMinus={true}
                    />
                </ScrollWrapper>
        </Layout>
    );
};
const BigTextSection = styled.section`
    position: relative;
    padding-top: 0;
    margin-top: -350px;
    ${Media('xlscreens')} {
        margin-top: -18vw;
    }
    ${Media('tablet')} {
        padding-bottom: 80px;
    }
`;
const ContentSectionArea = styled.section`
    padding-top: 0;
    ${Media('tablet')} {
        padding: 0px;
    }
`;
const BigText = styled.div`
    font-size: 140px;
    font-family: ${gilroySemibold};
    overflow: hidden;
    line-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    span {
        white-space: nowrap;
        position: relative;
    }
    ${Media('xlscreens')} {
        width: 100%;
        font-size: 15.6vw;
        line-height: 0.82;
    }
`;
const GrowthContainer = styled.div`
    padding-bottom: 100px;
    ${Media('xlscreens')} {
        padding-bottom: 5.23vw;
    }
    ${Media('tablet')} {
        padding-bottom: 0px;
        padding-top: 20px;
    }
`;
const OneText = styled.span`
    display: inline-flex;
    align-self: flex-start;
    left: -2%;
`;
const TwoText = styled.span`
    display: inline-flex;
    align-self: flex-start;
    left: 35%;
`;
const Paragraph = styled.div`
    font-size: 20px;
    padding-top: 140px;
    margin-bottom: 90px;
    ${Media('xlscreens')} {
        font-size: 1vw;
        padding-top: 7.32vw;
        margin-bottom: 4.71vw;
    }
    ${Media('tablet')} {
        padding-top: 50px;
    }
`;
const ScrollWrapper = styled.div``;
export default Trendy;
