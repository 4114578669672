import React from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import MainPara from 'shared-components/main-para/main-para';
import Media from 'shared-components/theme/medias';
import { baseURL } from 'shared-components/theme/helpers';
import {
    SRMFloatingSectionImage,
    SRMSectionHeading,
} from 'components/social-rep-man/common';
import { RowCenteredBoth } from 'shared-components/theme/grid-helpers';
import LayersCards from 'components/social-rep-man/layer-card';
import { PagePara } from 'pages/technology/scorecard/style';
import ScrollAnimation from 'react-animate-on-scroll';
import { gilroySemibold, gilroyMedium } from 'shared-components/theme/fonts';

export const ScoreEngageSection = ({ data }) => {
    return (
        <EngageSection id="engage">
            <SRMFloatingSectionImage
                x={[-20, 20]}
                image={`${baseURL}assets/images/tech/inspire-engage-bg.svg`}
                width={1967}
                left="8%"
                bottom="0"
            />
            <Container>
                <RowCenteredBoth>
                    <Col sm="12" lg="9">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <SRMSectionHeading>
                                Improve Agent Performance, Tenure, and CX
                                outcomes
                            </SRMSectionHeading>
                        </ScrollAnimation>
                    </Col>
                    <Col sm="12" lg="8">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <PagePara>
                                <MainPara align="center">
                                    Whether it’s ad-hoc coaching for real-time
                                    situational agent assistance, or strategic
                                    planning for long-term performance
                                    evolution, Inspire offers a personalized,
                                    process-driven agent coaching platform for
                                    continuous performance improvement and
                                    persistent performance monitoring.
                                </MainPara>
                            </PagePara>
                        </ScrollAnimation>
                    </Col>
                </RowCenteredBoth>
                <Row>
                    {data &&
                        data.length > 0 &&
                        data.map((item, index) => {
                            return (
                                <Col sm="12" lg="4" key={index}>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={true}>
                                        <EngageCards>
                                            <LayersCards
                                                layerCardimg={item.image}
                                                HeadingLayerCard={item.heading}
                                                ParagraphLayerCard={item.para}
                                                imgRound
                                                imgBorder
                                            />
                                        </EngageCards>
                                    </ScrollAnimation>
                                </Col>
                            );
                        })}
                </Row>
            </Container>
        </EngageSection>
    );
};

const EngageSection = styled.section`
    position: relative;
    z-index: 0;
    padding-bottom: 0;
    ${Media('xlscreens')} {
        padding: 7vw 0 0;
    }
`;

const EngageCards = styled.div`
    margin-top: 30px;
    & > div {
        margin-left: 20px;
        margin-right: 20px;
        height: 680px;
        padding-top: 30px;
        div:nth-of-type(2) {
            margin-bottom: 0px;
        }
        div:nth-of-type(3) {
            margin-top: 20px;
            margin-bottom: 20px;
            min-height: 100px;
        }
        h3 {
            font-family: ${gilroySemibold};
            line-height: 1.15;
            margin: 0px;
        }
        p {
            font-family: ${gilroyMedium};
            margin-top: 0;
        }
    }
    ${Media('xlscreens')} {
        margin-top: 3vw;
        & > div {
            height: 39.6vw;
            padding-top: 3vw;
            margin-left: 0.85vw;
            margin-right: 0.85vw;
            margin-bottom: 1.2vw;
            div:nth-of-type(3) {
                margin-top: 2.5vw;
                margin-bottom: 1.5vw;
                height: auto;
                align-items: center;
            }
        }
    }
    ${Media('tablet')} {
        & > div {
            height: auto;
            max-width: 380px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            padding-bottom: 30px;
            div:nth-of-type(3) {
                margin-top: 30px;
                min-height: 1px;
                justify-content: center;
            }
        }
    }
`;
