import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import {
    FaArrowRight,
    FaFacebookF,
    FaTwitter,
    FaLinkedinIn,
} from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { gilroyRegular } from 'shared-components/theme/fonts';

const JobBox = ({ job }) => {
    const [parsedJob, setParsedJob] = useState({
        title: '',
        description: '',
        responsibilities: '',
        qualifications: '',
        category: '',
        position_type: '',
        salary: '',
        level: '',
        url: '',
        location: '',
    });

    const parseJobObject = () => {
        job.details.header.map((header) => {
            if (header.label == 'Title' || header.label == 'External Title (What the candidate will see in a website post)') {
                setParsedJob((prevState) => ({
                    ...prevState,
                    title: header.value,
                }));
            }
            if (header.label == 'Job Family' || header.label == 'Job Family (Portal Searching)') {
                setParsedJob((prevState) => ({
                    ...prevState,
                    category: header.value,
                }));
            }
            if (header.label == 'Location' || header.label == 'Job Locations') {
                setParsedJob((prevState) => ({
                    ...prevState,
                    location: header.value,
                }));
            }
        });
        job.details.description.map((description) => {
            if (description.label == 'Overview') {
                setParsedJob((prevState) => ({
                    ...prevState,
                    description: description.value,
                }));
            }
            if (description.label == 'Responsibilities') {
                setParsedJob((prevState) => ({
                    ...prevState,
                    responsibilities: description.value,
                }));
            }
            if (description.label == 'Qualifications') {
                setParsedJob((prevState) => ({
                    ...prevState,
                    qualifications: description.value,
                }));
            }
        });
        setParsedJob((prevState) => ({
            ...prevState,
            position_type: job.field28138.value,
        }));
    };
    useEffect(() => {
        parseJobObject();
        setParsedJob((prevState) => ({ ...prevState, url: job.portalUrl }))
    }, [job]);

    return (
        <Wrapper>
            <BoxHeader>
                <CityText>{parsedJob.location}</CityText>
                <JobDays>{parsedJob.position_type}</JobDays>
            </BoxHeader>
            <BoxConetnt>
                <h3>{parsedJob.title}</h3>
                <a href={parsedJob.url} rel="noreferrer" target="_blank">
                    Apply Now <FaArrowRight />
                </a>
            </BoxConetnt>
            <BoxFooter>
                <ReferaFriend>
                    <AiOutlineInfoCircle />
                    <span>{parsedJob.category}</span>
                </ReferaFriend>
                <SocialLinks>
                    <a
                        href={`https://twitter.com/share?url=${parsedJob.url}`}
                        rel="noreferrer"
                        target="_blank">
                        <FaTwitter />
                    </a>
                    <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${parsedJob.url}`}
                        rel="noreferrer"
                        target="_blank">
                        <FaLinkedinIn />
                    </a>
                    <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${parsedJob.url}`}
                        rel="noreferrer"
                        target="_blank">
                        <FaFacebookF />
                    </a>
                </SocialLinks>
            </BoxFooter>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    background-color: ${colors.white['100']};
    padding: 20px;
    border: 1px solid ${colors.gray['20']};
    margin: 20px 0;
`;
const BoxHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;
const CityText = styled.div`
    font-size: 15px;
    color: ${colors.pink['100']};
`;
const JobDays = styled.div`
    color: ${colors.dark['100']};
`;
const BoxConetnt = styled.div`
    margin-top: 20px;
    h3 {
        color: ${colors.dark['100']};
        font-size: 20px;
        font-family: ${gilroyRegular};
        margin: 0px 0px 20px;
        min-height: 52px;
    }
    a {
        color: ${colors.pink['100']};
        font-family: ${gilroyRegular};
        font-size: 14px;
        svg {
            position: relative;
            top: 4px;
            left: 3px;
            path {
                fill: ${colors.dark['20']};
            }
        }
    }
`;

const BoxFooter = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;
const ReferaFriend = styled.div`
    color: ${colors.gray['50']};
    display: flex;
    align-items: center;
    span {
        margin-left: 5px;
    }
`;
const SocialLinks = styled.div`
    a {
        color: ${colors.gray['50']};
        display: inline-flex;
        padding: 5px 3px;
    }
`;
export default JobBox;
