import React, { useRef } from 'react';
import Layout from 'components/layout';
import styled from 'styled-components';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { FaRobot, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';
import { gilroyMedium, gilroySemibold } from 'shared-components/theme/fonts';
import HomeBigText from 'components/home-big-text/home-big-text';
import ReactHtmlParser from 'react-html-parser';
import { LetsDiscuss } from 'components/industries/common';
import Slider from 'react-slick';
import Button from 'shared-components/button/button';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import { EbookFloatingImage } from 'components/ebook/common';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import BannerMorphPill from 'components/banner-morph-pill/banner-morph-pill';
import MainPara from 'shared-components/main-para/main-para';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import { RowCenteredBoth } from 'shared-components/theme/grid-helpers';
const AI = () => {
    const sliderRef = useRef(null);

    const seamless_cx = [
        {
            icon: `${baseURL}assets/images/ai-assets/heart-icon.svg`,
            heading: 'Improve <br/>Customer Interactions',
        },
        {
            icon: `${baseURL}assets/images/ai-assets/hit.svg`,
            heading: 'Hit <br/>KPIs Faster',
        },
        {
            icon: `${baseURL}assets/images/ai-assets/star.svg`,
            heading: 'Accelerate <br/>CX Agility',
        },
        {
            icon: `${baseURL}assets/images/ai-assets/lock.svg`,
            heading: 'Enhance <br/>Privacy & Security',
        },
    ];

    const performance = [
        {
            title: 'Omnichannel AI Support',
            para: 'AI optimized to enable customers to seamlessly switch between channels without losing conversational history or context.',
        },
        {
            title: 'Enhanced Personalization',
            para: 'Use chatbots or virtual assistants to offer tailored recommendations, product suggestions, and relevant content based on a seamless analysis of customer engagement and purchase history.',
        },
        {
            title: 'Proactive AI Support',
            para: 'Anticipate customer needs based on engagement and purchase history, and offer proactive personalized support to enhance their experience.',
        },
        {
            title: 'Enhanced Self-Service',
            para: 'Use AI chatbots or virtual assistants to guide customers through self-service options and troubleshoot common issues.',
        },
    ];

    const guide = [
        {
            icon: `${baseURL}assets/images/ai-assets/guide-icon-1.svg`,
            title: 'Human + AI',
            para: 'Foster a seamless collaboration between AI systems and human agents. Provide real-time suggestions or knowledge to human agents during customer interactions to enhance accuracy and customer satisfaction.',
        },
        {
            icon: `${baseURL}assets/images/ai-assets/guide-icon-2.svg`,
            title: 'Big NLP',
            para: 'Train your AI using Natural Language Processing based on big data sets for highly accurate customer engagement across chatbots, automated email responses, & voice assistants.',
        },
        {
            icon: `${baseURL}assets/images/ai-assets/guide-icon-3.svg`,
            title: 'AI Sentiment Analysis',
            para: 'Understand customer sentiment in real-time. Monitor social media mentions, customer feedback, and support interactions to identify trends and proactively address issues before they escalate.',
        },
        {
            icon: `${baseURL}assets/images/ai-assets/guide-icon-4.svg`,
            title: 'Intelligent Routing',
            para: 'Match customer inquiries with agent expertise or AI system capabilities, reducing response times and enhancing customer satisfaction.',
        },
        {
            icon: `${baseURL}assets/images/ai-assets/guide-icon-5.svg`,
            title: 'Continuous Learning',
            para: 'Continuously train and improve AI systems using real-time customer data. Leverage machine learning techniques to analyze customer interactions, identify patterns, and make predictions to meet changing customer needs and preferences.',
        },
        {
            icon: `${baseURL}assets/images/ai-assets/guide-icon-6.svg`,
            title: 'Privacy & Data Security',
            para: 'Ensure compliance with privacy regulations and prioritize data security. Protect customer data and build trust with customers by clearly communicating your privacy policies and obtaining explicit consent for data usage.',
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 999,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const waveXslides = [
        {
            preTitle: 'in',
            title: 'Inspire',
            img: `${baseURL}assets/images/ai-assets/inspire.svg`,
            heading: 'AI-Enhanced Performance Coaching',
            para: 'An all-in-one agent coaching and performance acceleration platform designed to target and identify personalized coaching opportunities for every agent, in real-time.',
            link: '/technology/inspire',
        },
        {
            preTitle: 'sc',
            title: 'Scorecard',
            img: `${baseURL}assets/images/ai-assets/scorecard.svg`,
            heading: 'AI Performance Gamification',
            para: 'Smash through contact center performance thresholds with KPI gamification, outcome incentivization, and end-to-end engagement optimization.',
            link: '/technology/scorecard',
        },
        {
            preTitle: 'rds',
            title: 'Rounds',
            img: `${baseURL}assets/images/ai-assets/rounds.svg`,
            heading: 'Live Agent Monitoring & AI Guidance',
            para: 'AI multi-agent call & desktop monitoring, for total oversight of your global customer support teams.',
            link: '/technology/round',
        },
        {
            preTitle: 'vfms',
            title: 'vFMS',
            img: `${baseURL}assets/images/ai-assets/vfms.svg`,
            heading: 'Virtual Floor Management System',
            para: 'Marshall The Expertise Of Your Contact Center for Any Customer Interaction.',
            link: '/technology/virtual-floor-management-system',
        },
        {
            preTitle: 'rfx',
            title: 'RefleCX',
            img: `${baseURL}assets/images/ai-assets/reflecx.svg`,
            heading: 'CX Feedback Measurement & Action',
            para: 'Automated survey delivery, AI sentiment analysis, end-to-end feedback analytics, real-time issue resolution, and frictionless customer experience management, at scale.',
            link: '/technology/reflecx',
        },
        {
            preTitle: 'wts',
            title: 'Witness',
            img: `${baseURL}assets/images/ai-assets/witness.svg`,
            heading: 'Compliance & Fraud Monitoring',
            para: 'Actively monitor desktops, calls, keystrokes, and digital activity. Reduce risk by flagging issues early so you can correct non-compliant behavior and keep personally identifiable information (PII) safe.',
            link: '/technology/witness',
        },
    ];

    const handleSlide = (state) => {
        if (state === 'next') {
            sliderRef.current.slickNext();
        } else {
            sliderRef.current.slickPrev();
        }
    };

    const ai_partner = [
        `${baseURL}assets/images/ai-assets/logo-1.svg`,
        `${baseURL}assets/images/ai-assets/logo-2.svg`,
        `${baseURL}assets/images/ai-assets/logo-3.svg`,
        `${baseURL}assets/images/ai-assets/logo-4.svg`,
        `${baseURL}assets/images/ai-assets/logo-5.svg`,
        `${baseURL}assets/images/ai-assets/logo-6.svg`,
        `${baseURL}assets/images/ai-assets/logo-7.svg`,
        `${baseURL}assets/images/ai-assets/logo-8.svg`,
    ];

    const scrollNavData = [
        {
            name: 'intro',
            target: 'intro',
            offset: -100,
        },
        {
            name: 'seamless_cx',
            target: 'seamless_cx',
            offset: 0,
        },
        {
            name: 'performance',
            target: 'performance',
            offset: -100,
        },
        {
            name: 'guide',
            target: 'guide',
            offset: 0,
        },
        {
            name: 'wavex',
            target: 'wavex',
            offset: 0,
        },
    ];
    return (
        <Layout>
            <Breadcrumbs>
                <li>Solutions</li>
                <li>AI</li>
            </Breadcrumbs>
            <ScrollNav data={scrollNavData} />
            <AIIntro id="intro">
                <Container>
                    <ScrollAnimation
                        delay={100}
                        animateIn="fadeInUp"
                        animateOnce={false}>
                        <RowCentered>
                            <Col lg="6">
                                <h1>
                                    The <u>AI-Powered</u> Contact Center
                                </h1>
                            </Col>
                            <Col lg={{ size: 5, offset: 1 }}>
                                <MainParaWrapper>
                                    <MainPara styleTwo noMargin>
                                        Embedding AI In The Contact Center For
                                        Unmatched CX Performance
                                    </MainPara>
                                </MainParaWrapper>
                            </Col>
                        </RowCentered>

                        <RowCentered>
                            <Col lg="12">
                                <AssignmentButtonWrap>
                                    <RowCenteredBoth>
                                        <MainPara styleTwo noMargin>
                                            Starting Your AI Journey?
                                        </MainPara>
                                        <AssignmentButton>
                                            <Button
                                                bgColor={colors.black['100']}
                                                href="https://app.smartsheet.com/b/form/055ff38af4f040d7a0ea7b6831e279f1"
                                                round={true}
                                                target="_blank">
                                                Take the AI Assessment
                                            </Button>
                                        </AssignmentButton>
                                    </RowCenteredBoth>
                                </AssignmentButtonWrap>
                            </Col>
                        </RowCentered>
                    </ScrollAnimation>
                </Container>
                <FeaturePill top="62%" left="63%">
                    <FaRobot />
                    Hello! How may I help you?
                </FeaturePill>
                <FeaturePill top="78%" left="18%">
                    <img src={`${baseURL}assets/images/ai-assets/wave.svg`} />
                </FeaturePill>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/bigger-circle.svg`}
                    top={'80%'}
                    right={'-15%'}
                    height={966}
                    width={966}
                />
                <BannerMorphPill
                    image={`${baseURL}assets/images/ai-assets/intro-img.png`}
                />
            </AIIntro>
            <div style={{ backgroundColor: colors.gray_lightest['100'] }}>
                <BigText>
                    <HomeBigText
                        bigTextOne={<>The future of</>}
                        bigTextTwo={
                            <>
                                CX, now<span>.</span>
                            </>
                        }
                        level="cxOne"
                        textOneleft="80%"
                        textOneRight="-50%"
                        textTwoleft="-130%"
                        textTwoRight="20%"
                    />
                </BigText>
                <Container>
                    <SeamlessCX id="seamless_cx">
                        <SectionHeading>
                            Seamless CX With The Power of AI
                        </SectionHeading>
                        <Row>
                            {seamless_cx?.length &&
                                seamless_cx.map((item, index) => {
                                    return (
                                        <Col lg="3" key={index}>
                                            <ScrollAnimation
                                                animateIn="fadeInUp"
                                                animateOnce={true}>
                                                <SeamlessBox
                                                    borderLeft={index === 0}
                                                    borderRight={
                                                        index ===
                                                        seamless_cx.length - 1
                                                    }>
                                                    <IconWrapper>
                                                        <img
                                                            src={item.icon}
                                                            alt="-"
                                                        />
                                                    </IconWrapper>
                                                    <h3>
                                                        {ReactHtmlParser(
                                                            item.heading
                                                        )}
                                                    </h3>
                                                </SeamlessBox>
                                            </ScrollAnimation>
                                        </Col>
                                    );
                                })}
                        </Row>
                    </SeamlessCX>
                    <Performance id="performance">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <h3>
                                Enhance Performance Across The Customer Journey
                            </h3>
                            <p>
                                AI designed to enhance every engagement at every
                                touchpoint
                            </p>
                        </ScrollAnimation>
                        <Row>
                            {performance?.length &&
                                performance.map((item, index) => {
                                    return (
                                        <Col lg="6" key={index}>
                                            <ScrollAnimation
                                                animateIn="fadeInUp"
                                                animateOnce={true}>
                                                <PerformanceBox>
                                                    <h5>{item.title}</h5>
                                                    <p>{item.para}</p>
                                                </PerformanceBox>
                                            </ScrollAnimation>
                                        </Col>
                                    );
                                })}
                        </Row>
                    </Performance>
                    <Guide id="guide">
                        <SectionHeading>
                            <u>Guide & Protect</u> Every Customer Interaction
                        </SectionHeading>
                        <GuideItems>
                            <Row>
                                {guide?.length &&
                                    guide.map((item, index) => {
                                        return (
                                            <Col lg="4" key={index}>
                                                <ScrollAnimation
                                                    animateIn="fadeInUp"
                                                    animateOnce={true}>
                                                    <h5>
                                                        <img
                                                            src={item.icon}
                                                            alt="-"
                                                        />
                                                        {item.title}
                                                    </h5>
                                                    <p>{item.para}</p>
                                                </ScrollAnimation>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </GuideItems>
                    </Guide>
                </Container>
            </div>
            <WaveX id="wavex">
                <SectionHeading>
                    Wave X: <br />
                    The AI Embedded CX Performance Platform
                </SectionHeading>
                <SliderWrapper>
                    <Container>
                        <Slider {...settings} ref={sliderRef}>
                            {waveXslides?.length &&
                                waveXslides.map((item, index) => {
                                    return (
                                        <Slide key={index}>
                                            <ScrollAnimation
                                                animateIn="fadeInUp"
                                                animateOnce={true}>
                                                <WaveXBox>
                                                    <BoxHead>
                                                        <span>
                                                            {item.preTitle}
                                                        </span>
                                                        <h3>{item.title}</h3>
                                                    </BoxHead>
                                                    <BoxBody>
                                                        <img
                                                            src={item.img}
                                                            alt="-"
                                                        />
                                                        <h5>{item.heading}</h5>
                                                        <p>{item.para}</p>
                                                        <Button
                                                            href={item.link}
                                                            round
                                                            bgColor={
                                                                colors.white[
                                                                    '100'
                                                                ]
                                                            }
                                                            color={
                                                                colors.pink[
                                                                    '100'
                                                                ]
                                                            }
                                                            borderColor={
                                                                colors.black[
                                                                    '100'
                                                                ]
                                                            }>
                                                            Learn More
                                                        </Button>
                                                    </BoxBody>
                                                </WaveXBox>
                                            </ScrollAnimation>
                                        </Slide>
                                    );
                                })}
                        </Slider>
                    </Container>
                    <ArrowBtn onClick={() => handleSlide('next')}>
                        <FaChevronRight color={colors.pink['100']} />
                    </ArrowBtn>
                    <ArrowBtn left onClick={() => handleSlide('prev')}>
                        <FaChevronLeft color={colors.pink['100']} />
                    </ArrowBtn>
                </SliderWrapper>
            </WaveX>
            <TechnologyPartner>
                <SectionHeading color={colors.white['100']}>
                    Our AI Technology Partners
                </SectionHeading>
                <Partners>
                    {ai_partner?.length &&
                        ai_partner.map((item, index) => {
                            return (
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={true}
                                    key={index}>
                                    <img src={item} alt="-" />
                                </ScrollAnimation>
                            );
                        })}
                </Partners>
            </TechnologyPartner>
            <LetsDiscuss
                heading="Ready to Upgrade Your CX With The Power Of AI?"
                para="Get started with ibex today."
                btnText="Talk To A CX Expert"
                bgColor={colors.socialRep['brandsBG']}
            />
        </Layout>
    );
};
const AIIntro = styled.section`
    position: relative;
    z-index: 1;
    h1 {
        font-size: 64px;
        font-family: ${gilroySemibold};
        line-height: 1.2;
    }
    ${Media('xlscreens')} {
        min-height: 100vh;
        h1 {
            font-size: 3.33vw;
        }
    }
    ${Media('tablet')} {
        padding-top: 20px;
        h1 {
            font-size: 32px;
        }
    }
`;

const MainParaWrapper = styled.div`
    background-color: ${colors.white['100']};
    padding: 70px;
    border-radius: 50px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    a {
        display: flex;
        align-items: center;
        margin-top: 20px;
        p {
            margin-right: 10px;
        }
    }
    ${Media('xlscreens')} {
        padding: 3.64vw;
        border-radius: 2.6vw;
        border-bottom-left-radius: 0 !important;
        a {
            margin-top: 1.04vw;
        }
    }
    ${Media('mobile')} {
        padding: 30px;
        border-radius: 20px;
    }
`;

const FeaturePill = styled.div`
    backdrop-filter: blur(14px);
    position: absolute;
    border-radius: 74px;
    left: ${(props) => props.left};
    top: ${(props) => props.top};
    font-size: 24px;
    font-family: ${gilroyMedium};
    display: flex;
    align-items: center;
    height: 147px;
    width: 450px;
    justify-content: center;
    background-color: ${colors.white['15']};
    z-index: 9;
    svg {
        margin-right: 26px;
        font-size: 38px;
    }
    ${Media('xlscreens')} {
        border-radius: 3.85vw;
        font-size: 1.25vw;
        height: 7.65vw;
        width: 23.4vw;
        svg {
            margin-right: 1.35vw;
            font-size: 1.97vw;
        }
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const BigText = styled.section`
    ${Media('xlscreens')} {
        span {
            letter-spacing: -0.53vw;
        }
    }
    ${Media('tablet')} {
        span {
            letter-spacing: -5px;
        }
    }
`;

const SeamlessCX = styled.section`
    text-align: center;
    padding-top: 0;
`;

const SectionHeading = styled.h2`
    font-size: 45px;
    font-family: ${gilroySemibold};
    text-align: center;
    line-height: 1.17;
    color: ${(props) => props.color && props.color};
    margin-bottom: 50px;
    letter-spacing: -0.6px;
    ${Media('xlscreens')} {
        font-size: 2.3vw;
        margin-bottom: 2.66vw;
    }
    ${Media('tablet')} {
        font-size: 30px;
        padding: 0px 10px;
    }
`;

const IconWrapper = styled.div`
    height: 85px;
    width: 85px;
    background-color: ${colors.white['100']};
    margin: 0 auto;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;
    transition: all 0.3s ease;
    img {
        max-width: 60%;
    }
    ${Media('xlscreens')} {
        height: 4.42vw;
        width: 4.42vw;
        margin-bottom: 1.57vw;
    }
    ${Media('tablet')} {
        height: 70px;
        width: 70px;
        img {
            max-width: 40%;
        }
    }
`;

const SeamlessBox = styled.div`
    background-color: ${colors.white['100']};
    padding: 35px 0;
    h3 {
        font-size: 24px;
        font-family: ${gilroySemibold};
    }
    &:hover {
        ${IconWrapper} {
            background-color: ${colors.pink['100']};
        }
    }
    ${(props) => {
        if (props.borderLeft) {
            return `
                border-top-left-radius:35px;
                border-bottom-left-radius:35px;
                ${Media('xlscreens')}{
                    border-top-left-radius:1.82vw;
                    border-bottom-left-radius:1.82vw;
                }
            `;
        }
        if (props.borderRight) {
            return `
                border-top-right-radius:35px;
                border-bottom-right-radius:35px;
                ${Media('xlscreens')}{
                    border-top-right-radius:1.82vw;
                    border-bottom-right-radius:1.82vw;
                }
            `;
        }
    }}
    ${Media('xlscreens')} {
        padding: 1.82vw 0;
        h3 {
            font-size: 1.25vw;
        }
    }
    ${Media('tablet')} {
        border-radius: 10px;
        margin-bottom: 20px;
        box-shadow: 0 10px 10px ${colors.black['3']};
        h3 {
            font-size: 20px;
        }
    }
`;

const Performance = styled.section`
    background-image: url(${baseURL}assets/images/ai-assets/performance-bg.png);
    background-repeat: no-repeat;
    background-position: left;
    background-color: ${colors.black['100']};
    background-size: contain;
    border-radius: 35px;
    padding-left: 45%;
    padding-right: 80px;
    color: ${colors.white['100']};
    h3 {
        font-size: 45px;
        color: inherit;
    }
    p {
        font-size: 24px;
        color: inherit;
    }
    ${Media('xlscreens')} {
        border-radius: 1.82vw;
        padding-right: 4.16vw;
        h3 {
            font-size: 2.3vw;
        }
        p {
            font-size: 1.25vw;
        }
    }
    ${Media('tablet')} {
        background-image: none;
        padding-left: 5%;
        padding-right: 20px;
        h3 {
            font-size: 30px;
        }
        p {
            font-size: 20px;
        }
    }
`;

const PerformanceBox = styled.div`
    h5 {
        font-size: 22px;
        font-family: ${gilroySemibold};
    }
    p {
        font-size: 20px;
        font-family: ${gilroyMedium};
    }
    ${Media('xlscreens')} {
        h5 {
            font-size: 1.14vw;
        }
        p {
            font-size: 1.04vw;
        }
    }
    ${Media('tablet')} {
        h5 {
            font-size: 20px;
        }
        p {
            font-size: 16px;
        }
    }
`;

const Guide = styled.section``;

const GuideItems = styled.div`
    margin-top: 100px;
    h5 {
        font-size: 22px;
        display: flex;
        font-family: ${gilroySemibold};
        margin-bottom: 18px;
        align-items: center;
        img {
            margin-right: 25px;
        }
    }
    p {
        font-size: 20px;
        font-family: ${gilroyMedium};
        margin-top: 0;
        padding-right: 50px;
    }
    ${Media('xlscreens')} {
        margin-top: 5.2vw;
        h5 {
            font-size: 1.14vw;
            margin-bottom: 0.93vw;
            img {
                margin-right: 1.3vw;
            }
        }
        p {
            font-size: 1.04vw;
            padding-right: 2.6vw;
        }
    }
    ${Media('tablet')} {
        margin-top: 15px;
        h5 {
            font-size: 20px;
        }
        p {
            font-size: 16px;
            padding-right: 0;
        }
    }
`;

const WaveX = styled.section``;

const SliderWrapper = styled.div`
    margin-top: 130px;
    position: relative;
    .slick-arrow {
        display: none;
    }
    .slick-dots {
        bottom: -50px;
        li {
            height: 15px;
            width: 15px;
            border-radius: 50%;
            background-color: ${colors.black['100']};
            margin: 0px 10px;
            &.slick-active {
                background-color: ${colors.pink['100']};
            }
        }
    }
    ${Media('xlscreens')} {
        margin-top: 6.77vw;
        .slick-dots {
            bottom: -2.6vw;
            li {
                height: 0.78vw;
                width: 0.78vw;
                margin: 0 0.53vw;
            }
        }
    }
    ${Media('tablet')} {
        margin-top: 70px;
        .slick-dots {
            li {
                height: 10px;
                width: 10px;
                margin: 0px 6px;
            }
        }
    }
`;

const Slide = styled.div``;

const WaveXBox = styled.div`
    background-color: ${colors.white['100']};
    padding-bottom: 40px;
    height: 100%;
    margin: 0px 28px;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    ${Media('xlscreens')} {
        min-height: 43vw;
        padding-bottom: 2.08vw;
        margin: 0 1.45vw;
        border-radius: 1.82vw;
    }
    ${Media('tablet')} {
        min-height: 622px;
        margin: 0px 15px;
    }
`;

const BoxHead = styled.div`
    display: flex;
    h3 {
        font-size: 32px;
        margin: 0;
        padding: 32px 0;
        padding-left: 34px;
    }
    span {
        background-color: ${colors.black['100']};
        color: ${colors.white['100']};
        border-top-left-radius: 35px;
        padding: 32px;
        font-size: 32px;
        font-family: ${gilroySemibold};
    }
    ${Media('xlscreens')} {
        h3 {
            font-size: 1.66vw;
            padding: 1.66vw 0;
            padding-left: 1.77vw;
        }
        span {
            border-top-left-radius: 1.82vw;
            padding: 1.66vw;
            font-size: 1.66vw;
        }
    }
    ${Media('tablet')} {
        h3 {
            font-size: 20px;
            padding: 15px 0;
            padding-left: 20px;
        }
        span {
            font-size: 20px;
            padding: 15px 25px;
        }
    }
`;

const BoxBody = styled.div`
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    img {
        width: 100%;
    }
    h5 {
        padding: 0px 30px;
        font-size: 20px;
        font-family: ${gilroySemibold};
        margin-top: 40px;
    }
    p {
        padding: 0px 50px;
        font-size: 18px;
        font-family: ${gilroyMedium};
        margin-bottom: 40px;
        flex: 1;
    }
    a {
        margin: 0 auto;
    }
    ${Media('xlscreens')} {
        h5 {
            padding: 0px 1.57vw;
            font-size: 1.04vw;
            margin-top: 2.06vw;
        }
        p {
            padding: 0 2.6vw;
            font-size: 0.93vw;
            margin-bottom: 2.06vw;
        }
    }
    ${Media('tablet')} {
        h5 {
            margin-bottom: 10px;
        }
        p {
            font-size: 16px;
            padding: 0 10px;
        }
    }
`;

const ArrowBtn = styled.span`
    padding: 90px 40px;
    background-color: ${colors.black['100']};
    font-size: 50px;
    position: absolute;
    top: 30%;
    right: 0;
    cursor: pointer;
    ${(props) => {
        if (props.left) {
            return `
                left:0%;
                right:unset;
            `;
        }
    }}
    ${Media('xlscreens')} {
        padding: 4.6vw 2.06vw;
        font-size: 2.6vw;
    }
    ${Media('tablet')} {
        padding: 10px;
        display: flex;
        top: 47%;
        svg {
            font-size: 20px;
        }
    }
`;

const TechnologyPartner = styled.section`
    background-color: ${colors.gray_dark['100']};
    padding-top: 50px;
    ${Media('xlscreens')} {
        padding-top: 2.66vw;
    }
`;

const Partners = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    img {
        margin: 15px 30px;
    }
    ${Media('xlscreens')} {
        margin-top: 5.2vw;
        img {
            margin: 0 1.57vw;
            max-width: 8vw;
            max-height: 3vw;
        }
    }
    ${Media('tablet')} {
        margin-top: 50px;
        img {
            max-width: 70px;
        }
    }
`;

const AssignmentButton = styled.div`
    margin-left: 18px;
    a {
        width: 310px;
        font-size: 14px;
        height: 50px;
        border-radius: 40px;
    }

    ${Media('xlscreens')} {
        margin-left: 0.9vw;
        a {
            width: 18.08vw;
            font-size: 1vw;
            height: 3.5vw;
            border-radius: 2.08vw;
        }
    }

    ${Media('tablet')} {
        margin-left: 10px;
        a {
            width: 290px;
        }
    }

    ${Media('mobile')} {
        margin-left: 0;
        margin-top: 20px;
    }
`;

const AssignmentButtonWrap = styled.div`
    margin-top: 50px;

    ${Media('xlscreens')} {
        margin-top: 2.6vw;
    }
    ${Media('tablet')} {
        margin-top: 50px;
    }
`;

export default AI;
