import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import { LetsDiscuss } from 'components/industries/common';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import Button from 'shared-components/button/button';
import colors from 'shared-components/theme/colors';
import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import { baseURL, baseURLPage } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import AutomateAnim from './automate-anim';
import { Link } from 'react-router-dom';

const support_items = [
    {
        img: `${baseURL}assets/images/wave-ix/automate/feat_img_1.png`,
        title: 'Frictionless High-Volume Interactions',
        para: 'Wave iX Automate is designed to deliver high performance customer interactions at scale.',
    },
    {
        img: `${baseURL}assets/images/wave-ix/automate/feat_img_2.png`,
        title: 'Upgrade Productivity',
        para: 'Wave iX Automate removes low-value, manual tasks, supports agents in higher-complexity work, and provides useful insights for boosting performance.',
    },
    {
        img: `${baseURL}assets/images/wave-ix/automate/feat_img_3.png`,
        title: 'Flatten Costs',
        para: 'Generative AI allows fewer people to do much more - and better. This ensures your costs stay low while you amplify outcomes.',
    },
];

const cX_complexity = [
    {
        icon: `${baseURL}assets/images/wave-ix/automate/cX_complexity_1.svg`,
        title: 'The Latest in AI',
        para: 'Leverage cutting-edge in-house and external AI technologies, developed over years of client interaction records, seamlessly integrated into your processes.',
    },
    {
        icon: `${baseURL}assets/images/wave-ix/automate/cX_complexity_2.svg`,
        title: 'Day 1 ROI',
        para: 'Implement bots within weeks – expanding your lineup when required – to streamline operations and enhance agent tasks, boosting productivity while lowering support expenses.',
    },
    {
        icon: `${baseURL}assets/images/wave-ix/automate/cX_complexity_3.svg`,
        title: 'Productivity Multiplier',
        para: 'Achieve total customer experience adaptability by effortlessly coordinating tasks between human agents and dedicated bots.',
    },
];

const ai_automate = [
    {
        title: 'Engage',
        para: 'Automate answers to common questions for quick issue resolution.',
    },
    {
        title: 'Route',
        para: 'Automated sentiment analytics assess context and intent, and automatically route inquiries to the most effective support agent.',
    },
    {
        title: 'Empower',
        para: 'Generative AI based on an LLM trained to your brand, ready to empower and assist support agents during every interaction.',
    },
    {
        title: 'Enhance',
        para: 'Use generative AI to identify churn and trigger points, streamline the customer journey, and drive enhanced support training.',
    },
];

const translations = [
    {
        original: `Bonjour! <br/>Comment puis je vous aider?`,
        translate: `Hello! <br/>How can I help you?`,
    },
    {
        original: `Brindar soporte al cliente <br/> en cualquier idioma.`,
        translate: `Deliver customer support in any language.`,
    },
    {
        original: `Su intérprete de IA en tiempo real.`,
        translate: `Your Real-Time AI Interpreter.`,
    },
    {
        original: `Traducción bidireccional de voz y texto`,
        translate: `2-Way Voice & Text Translation`,
    },
    {
        original: `Reconocimiento de voz e intención`,
        translate: `Speech & Intent Recognition`,
    },
    {
        original: `Voz de IA entrenada por la marca`,
        translate: `Brand Trained AI Voice`,
    },
];

const cx_programmes = [
    {
        icon: `${baseURL}assets/images/wave-ix/automate/cx_icon_1.svg`,
        title: 'Multi-Modal Authentication',
        desc: 'Powerful AI-based solutions that effortlessl authenticate customers, undersetand intent, and keep the conversation moving.',
        list: [
            'Intent Recognition',
            'Voice Authentication',
            'Chat Authentication',
            'Call Routing',
        ],
        link: `/wave-ix/authenticate`,
    },
    {
        icon: `${baseURL}assets/images/wave-ix/automate/cx_icon_2.svg`,
        title: 'Conversational Virtual Agents',
        desc: 'Powerful AI-based solutions that effortlessl authenticate customers, undersetand intent, and keep the conversation moving.',
        list: ['Gen AI for Chat', 'Gen AI For Voice', 'Hyper-Pesonalized'],
        link: '/wave-ix/virtual-agent',
    },
    {
        icon: `${baseURL}assets/images/wave-ix/automate/cx_icon_3.svg`,
        title: 'Real-Time Multi-Lingual Translation',
        desc: 'Deliver customer support in any language, across any channel, in real-time.',
        list: [
            '150+ Languages',
            'Voice Translation',
            'Chat Translation',
            'Trained On Your Brand Voice',
        ],
        link: '/wave-ix/translate',
    },
    {
        icon: `${baseURL}assets/images/wave-ix/automate/cx_icon_4.svg`,
        title: 'Customer Journey Management',
        desc: 'Data unlocks enterprise-wide CX Automation. From first touch to complete customer journey elevation, our iX Transform layer operates across the entire Wave iX technology stack.',
        list: [
            'Insights Across 100% of Customer Interactions',
            'Revenue Generation Opportunities',
            'Digital Solution Evolution',
            'Team Analytics & Enhancement',
        ],
        link: '',
    }

    
];

const Automate = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            if (activeIndex !== translations.length - 1) {
                setActiveIndex(activeIndex + 1);
            } else {
                setActiveIndex(0);
            }
        }, 6000);
    });
    return (
        <Layout>
            <Wrapper>
                <Breadcrumbs color={colors.white['50']}>
                    <li>Solutions</li>
                    <li>Wave iX</li>
                    <li>Automate</li>
                </Breadcrumbs>
                <Intro>
                    <Container>
                        <RowCentered>
                            <Col lg="5">
                                <IntroContent>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={true}>
                                        <img
                                            src={`${baseURL}assets/images/wave-ix/ix-logo.svg`}
                                            alt="-"
                                        />
                                        <h1>Automate</h1>
                                        <p>
                                            Sophisticated Generative AI For
                                            End-to-End CX Automation, Cost
                                            Reduction, and Performance
                                            Enhancement
                                        </p>
                                        <Button
                                            href={`${baseURLPage}contact-us`}>
                                            Book a Demo
                                            <img
                                                src={`${baseURL}assets/images/wave-ix/automate/arrow-right.svg`}
                                            />
                                        </Button>
                                    </ScrollAnimation>
                                </IntroContent>
                            </Col>
                            <Col lg="7">
                                <IntroImg>
                                    <img
                                        src={`${baseURL}assets/images/wave-ix/automate/intro-img.png`}
                                        alt="-"
                                    />
                                    <IntroImgSecond>
                                        <img
                                            src={`${baseURL}assets/images/wave-ix/automate/intro-img-2.png`}
                                        />
                                    </IntroImgSecond>
                                    <Planets>
                                        <img
                                            src={`${baseURL}assets/images/wave-ix/automate/planet_1_circle.png`}
                                            alt="-"
                                        />
                                        <img
                                            src={`${baseURL}assets/images/wave-ix/automate/planet_2_circle.png`}
                                            alt="-"
                                        />
                                        <img
                                            src={`${baseURL}assets/images/wave-ix/automate/planet_3_circle.png`}
                                            alt="-"
                                        />
                                        <img
                                            src={`${baseURL}assets/images/wave-ix/automate/planet_4_circle.png`}
                                            alt="-"
                                        />
                                        <img
                                            src={`${baseURL}assets/images/wave-ix/automate/planet_1_circle_big.png`}
                                            alt="-"
                                        />
                                        <img
                                            src={`${baseURL}assets/images/wave-ix/automate/planet_2_circle_big.png`}
                                            alt="-"
                                        />
                                        <img
                                            src={`${baseURL}assets/images/wave-ix/automate/planet_3_circle_big.png`}
                                            alt="-"
                                        />
                                    </Planets>
                                </IntroImg>
                            </Col>
                        </RowCentered>
                    </Container>
                </Intro>
                <CxComplexity>
                    <Container>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <SectionHeader
                                style={{
                                    textAlign: 'left',
                                }}
                                medium>
                                <h2 style={{ color: colors.gray['text'] }}>
                                    Great CX automation is invisible to the
                                    customer. <br />
                                    At ibex, our&nbsp;
                                    <span
                                        style={{ color: colors.white['100'] }}>
                                        AI-enhanced automation solutions
                                    </span>
                                    &nbsp;
                                    <br />
                                    remove CX complexity through intuitive
                                    simplicity.
                                </h2>
                            </SectionHeader>
                        </ScrollAnimation>
                        <CxComplexityList>
                            <Row>
                                {cX_complexity &&
                                    cX_complexity.length > 0 &&
                                    cX_complexity.map((item, index) => {
                                        return (
                                            <Col lg="4" key={index}>
                                                <ScrollAnimation
                                                    animateIn="fadeInUp"
                                                    animateOnce={true}>
                                                    <CxComplexityItem
                                                        key={index}
                                                        lastChild={
                                                            index ===
                                                            cX_complexity.length -
                                                                1
                                                        }>
                                                         <img
                                                            src={item.icon}
                                                            alt="-"
                                                        />   
                                                        <h5>{item.title}</h5>
                                                        <p>{item.para}</p>
                                                    </CxComplexityItem>
                                                </ScrollAnimation>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </CxComplexityList>
                    </Container>
                </CxComplexity>
                <CXProgramme>
                    <Container>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <SectionHeader medium>
                                <h2>
                                    Unlock Potential And Reduce Cost <br />
                                    Across Your CX Program
                                </h2>
                            </SectionHeader>
                        </ScrollAnimation>
                        <CXProgrammesList>
                            {cx_programmes &&
                                cx_programmes.length > 0 &&
                                cx_programmes.map((item, index) => {
                                    return (
                                        <CXProgrammeRow key={index}>
                                            <Col lg="6">
                                                <AutomateAnim index={index} />
                                            </Col>
                                            <Col lg="6">
                                                <CXProgrammeContent>
                                                    <ScrollAnimation
                                                        animateIn="fadeInUp"
                                                        animateOnce={true}>
                                                        <img
                                                            src={item.icon}
                                                            alt="-"
                                                        />
                                                        <h5>{item.title}</h5>
                                                        <p>{item.desc}</p>
                                                        <ul>
                                                            {item.list.length &&
                                                                item.list.map(
                                                                    (
                                                                        li,
                                                                        index2
                                                                    ) => {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    index2
                                                                                }>
                                                                                {
                                                                                    li
                                                                                }
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                        </ul>
                                                       
                                                        <Link to={item.link} className={index === 3 ? 'coming-soon' : ''}>
                                                            {index === 3 ? "Coming Soon" :  "Learn More"}
                                                            <img
                                                                src={`${baseURL}assets/images/wave-ix/automate/arrow.svg`}
                                                                alt="-"
                                                            />
                                                        </Link>
                                                      
                                                    </ScrollAnimation>
                                                </CXProgrammeContent>
                                            </Col>
                                        </CXProgrammeRow>
                                    );
                                })}
                        </CXProgrammesList>
                    </Container>
                </CXProgramme>
            </Wrapper>
            <AutomateAi>
                <Container>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <SectionHeader color={colors.gray_dark['100']}>
                            <h2>
                                <u>Automation AI For the Ultimate ROI</u>
                            </h2>
                            <p>
                                Automate key moments along your CX value chain
                                to drive cost down and CSAT up.
                            </p>
                        </SectionHeader>
                    </ScrollAnimation>
                    <AiAutomateList>
                        {ai_automate &&
                            ai_automate.length > 0 &&
                            ai_automate.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <h4>{item.title}</h4>
                                        <AiAutomateImgWrap>
                                            <img
                                                src={`${baseURL}assets/images/wave-ix/automate/arrow.svg`}
                                                alt="-"
                                            />
                                        </AiAutomateImgWrap>
                                        <p>{item.para}</p>
                                    </li>
                                );
                            })}
                    </AiAutomateList>
                </Container>
            </AutomateAi>
            <Support>
                <Container>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <SectionHeader color={colors.gray_dark['100']}>
                            <h2>
                                <u>
                                    Your AI Sidekick For Heroic CX Performance.
                                </u>
                            </h2>
                        </SectionHeader>
                    </ScrollAnimation>
                    <Row>
                        {support_items &&
                            support_items.length > 0 &&
                            support_items.map((item, index) => {
                                return (
                                    <Col lg="4" key={index}>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={true}>
                                            <SupportItem
                                                key={index}
                                                lastChild={
                                                    index ===
                                                    support_items.length - 1
                                                }>
                                                <img src={item.img} alt="-" />
                                                <h5>{item.title}</h5>
                                                <p>{item.para}</p>
                                            </SupportItem>
                                        </ScrollAnimation>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </Support>
            <LetsDiscuss
                heading="Ready to deliver support in any language, across any channel?"
                para="Book a demo today."
                btnText="Book a demo"
                bgColor={colors.socialRep['brandsBG']}
            />
        </Layout>
    );
};

const Wrapper = styled.div`
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 20px;
    ${Media('desktop')} {
        background-image: url(${baseURL}assets/images/wave-ix/automate/bg.jpg);
        padding-top: 1.04vw;
    }
    ${Media('tablet')} {
        padding-top: 10px;
        background-image: url(${baseURL}assets/images/wave-ix/mobo-bg.jpg);
    }
`;

const Intro = styled.div`
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    ${Media('xlscreens')} {
        padding-bottom: 2.5vw;
    }
    ${Media('tablet')} {
        padding: 0px 15px;
        padding-bottom: 50px;
    }
`;

const IntroContent = styled.div`
    h1 {
        color: ${colors.white['100']};
        font-family: ${gilroyMedium};
        font-size: 64px;
    }

    p {
        color: ${colors.white['100']};
        font-family: ${gilroyMedium};
        margin-bottom: 56px;
        font-size: 20px;
    }
    a {
        background-color: ${colors.purple['100']};
        border-radius: 5px;
        box-shadow: -7px 6px 0 ${colors.purple['25']};
        img {
            width: 12px;
            margin-bottom: 0;
            margin-left: 10px;
            transition: all 0.3s ease;
        }
        &:hover {
            color: ${colors.white['100']};
            img {
                animation: topBottom 1s ease-in-out infinite;
            }
            ::before {
                display: none;
            }
        }
    }

    img {
        margin-bottom: 30px;
        width: 100px;
    }
    ${Media('xlscreens')} {
        h1 {
            font-size: 3.33vw;
        }
        p {
            font-size: 1.04vw;
            margin-bottom: 2.9vw;
        }
        img {
            margin-bottom: 1.57vw;
            width: 5.2vw;
        }
        a {
            img {
                width: 0.62vw;
                margin-left: 0.53vw;
            }
        }
    }
    ${Media('tablet')} {
        padding-top: 50px;
        margin-bottom: 50px;
        h1 {
            font-size: 42px;
        }
        p {
            font-size: 16px;
        }
        img {
            margin-bottom: 50px;
            width: 70px;
        }
    }
    @keyframes topBottom {
        0% {
            transform: translateX(0px) translateY(0px);
        }
        100% {
            transform: translateX(5px) translateY(-5px);
        }
    }
`;

const IntroImg = styled.div`
    position: relative;
    img {
        width: 100%;
    }
`;

const IntroImgSecond = styled.div`
    position: absolute;
    inset: 0;
    z-index: 99;
    img {
        height: 100%;
        width: 100%;
    }
`;

const Planets = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        position: absolute;
        height: 34vw;
        width: 38vw;
        animation: rotate 90s linear infinite;
        object-fit: contain;
        &:nth-of-type(3) {
            height: 33vw;
        }
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
            height: 60vw;
            width: 60vw;
        }
        ${Media('tablet')} {
            height: 69vw;
            width: 74vw;
            &:nth-of-type(3) {
                height: 66vw;
            }
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
                height: 120vw;
                width: 120vw;
            }
        }
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

const SectionHeader = styled.div`
    text-align: center;
    margin-bottom: 100px;
    h2 {
        font-size: 45px;
        font-family: ${(props) =>
            props.medium ? gilroyMedium : gilroySemibold};
        color: ${(props) => props.color || colors.white['100']};
    }
    p {
        color: ${(props) => props.color || colors.white['100']};
        font-size: 18px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 5.2vw;
        h2 {
            font-size: 2.34vw;
        }
        p {
            font-size: 0.93vw;
        }
    }
    ${Media('tablet')} {
        margin-bottom: 30px;
        br {
            display: none;
        }
        h2 {
            font-size: 32px;
        }
        p {
            font-size: 16px;
        }
    }
`;

const CXProgramme = styled.section``;

const CXProgrammesList = styled.div``;

const CXProgrammeRow = styled(RowCentered)`
    margin-bottom: 70px;
    ${Media('xlscreens')} {
        &:nth-of-type(even) {
            flex-direction: row-reverse;
        }
        margin-bottom: 3.64vw;
    }
    ${Media('tablet')} {
        margin: 80px 0;
    }
`;

const CXProgrammeContent = styled.div`
    color: ${colors.white['100']};
    margin-left: 20px;
    img {
        margin-bottom: 15px;
        max-width: 62px;
        max-height: 55px;
    }
    h5 {
        font-family: ${gilroyMedium};
        font-size: 35px;
        margin: 0;
        margin-bottom: 30px;
        font-weight: 400;
    }
    p {
        color: inherit;
    }
    ul {
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 70px;
        padding-right: 30px;
        margin-top: 50px;
        li {
            width: 50%;
            padding: 16px 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px dashed ${colors.white['50']};
            font-size: 18px;
            text-align: center;
            font-family: ${gilroyMedium};
        }
    }
    a {
        padding: 15px 33px;
        background-color: ${colors.white['100']};
        border-radius: 5px;
        font-size: 18px;
        font-family: ${gilroyMedium};
        box-shadow: -7px 6px 0 ${colors.purple['25']};
        img {
            display: inline-block;
            margin-bottom: 0;
            height: 12px;
            width: 12px;
            margin-left: 10px;
        }
    }
    .coming-soon{
        pointer-events: none;
    }
    ${Media('xlscreens')} {
        margin-left: 1.04vw;
        padding-right: 2vw;
        img {
            margin-bottom: 0.53vw;
            max-width: 3.22vw;
            max-height: 2.86vw;
        }
        h5 {
            font-size: 1.82vw;
            margin-bottom: 1.57vw;
        }
        ul {
            margin-bottom: 3.64vw;
            padding-right: 1.57vw;
            margin-top: 2.6vw;
            li {
                padding: 0.83vw 1.14vw;
                font-size: 0.93vw;
            }
        }
        a {
            padding: 0.78vw 1.71vw;
            border-radius: 0.2vw;
            font-size: 0.93vw;
            img {
                height: 0.62vw;
                width: 0.62vw;
                margin-left: 0.53vw;
            }
        }
    }
    ${Media('tablet')} {
        margin-top: 50px;
        img {
            max-width: 52px;
            max-height: 45px;
        }
        h5 {
            font-size: 26px;
        }
        ul {
            li {
                justify-content: flex-start;
                width: 100%;
                font-size: 14px;
                text-align: left;
            }
        }
        a {
            font-size: 14px;
            padding: 10px 26px;
        }
    }
`;

const Support = styled.section`
    background-color: ${colors.gray_lighter['100']};
`;

const CxComplexity = styled.section`
    ${Media('xlscreens')} {
        padding-bottom: 1.57vw;
    }
    ${Media('tablet')} {
        padding: 30px 0;
    }
`;

const CxComplexityList = styled.div`
    color: ${colors.white['100']};
    p {
        color: ${colors.white['100']};
    }
`;

const CxComplexityItem = styled.div`
    h5 {
        font-size: 18px;
        font-family: ${gilroyBold};
    }
    ${Media('xlscreens')} {
        padding-right: 1vw;
        h5 {
            font-size: 0.93vw;
        }
    }
`;

const AutomateAi = styled.section`
    ${Media('tablet')} {
        padding-bottom: 0;
    }
`;

const AiAutomateList = styled.ul`
    li {
        display: flex;
        align-items: center;
        border-bottom: 1px dashed ${colors.black['50']};
        padding: 12px;
        padding-left: 0 !important;
        h4 {
            font-size: 35px;
            margin: 0;
            font-family: ${gilroyMedium};
            font-weight: 500;
            width: 170px;
            display: inline;
            letter-spacing: -1px;
        }
        p {
            letter-spacing: -0.4px;
        }

        img {
            margin: 0 40px;
        }
        &:first-child {
            border-top: 1px dashed ${colors.black['50']};
        }
    }

    ${Media('xlscreens')} {
        li {
            padding: 0.92vw;
            h4 {
                font-size: 1.82vw;
                width: 8.85vw;
            }

            img {
                margin: 0 1.6vw;
            }
        }
    }
    ${Media('tablet')} {
        padding-left: 0;
        li {
            display: block;
            padding: 20px 0;
            h4 {
                width: 100px;
                font-size: 20px;
                display: block;
            }
            img {
                display: none;
            }
            p {
                margin-bottom: 0;
            }
        }
    }
`;

const AiAutomateImgWrap = styled.div`
    img {
        transform: rotate(45deg);
    }
`;

const SupportItem = styled.div`
    img {
        width: 100%;
    }
    h5 {
        font-size: 20px;
        font-family: ${gilroySemibold};
    }
    p {
        font-size: 18px;
        font-family: ${gilroyMedium};
    }
    ${Media('xlscreens')} {
        h5 {
            font-size: 1.04vw;
        }
        p {
            font-size: 0.93vw;
        }
    }

    ${Media('tablet')} {
        margin-bottom: 50px;
        padding-bottom: 30px;
        border-bottom: 1px solid ${colors.black['10']};
        p {
            font-size: 16px;
        }
        ${(props) => {
            if (props.lastChild) {
                return `
                    border-bottom: none;
                    margin-bottom: 0;
                    padding-bottom: 0;
                `;
            }
        }}
    }
`;

export default Automate;
