import React from 'react';
import colors from 'shared-components/theme/colors';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
export const DEMediumHeading = ({ light, children }) => {
    return <Wrapper light={light}>{children}</Wrapper>;
};

const Wrapper = styled.h3`
    font-size: 24px;
    font-family: ${gilroyMedium};
    line-height: 1.75;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    color: ${(props) =>
        props.light ? colors.white['100'] : colors.black['100']};
    img {
        height: 32px;
        margin-right: 25px;
    }
    ${Media('xlscreens')} {
        font-size: 1.66vw;
        margin-bottom: 1.56vw;
        img {
            height: 2.6vw;
            margin-right: 2.6vw;
        }
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 20px;
        img {
            height: 26px;
            margin-right: 20px;
        }
    }
`;
