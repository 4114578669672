import React from 'react';
import MainHeading from 'shared-components/main-heading/main-heading';
import MainPara from 'shared-components/main-para/main-para';
import Video from 'shared-components/video/video';
import WhyOutSourceHeading from 'shared-components/outsource/outsource-heading';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { gilroySemibold, gilroyBold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

export const IntroRightTime = () => {
    return (
        <>
            <WhyOutsorceIntro>
                <Container>
                    <Row>
                        <Col lg="5">
                            <MainHeading>Guide #1:</MainHeading>
                            <MainPara bigtext={true}>
                                What to expect when transitioning&nbsp;
                                <br />
                                your customer support team from&nbsp;
                                <br />
                                in-house to outsourced.
                            </MainPara>
                            <ArrowImg
                                src={`${baseURL}assets/images/down-arrow-big.svg`}></ArrowImg>
                            <ul>
                                <li>
                                    <p>
                                        On average, companies who have
                                        completely outsourced their call center
                                        functions have realized a greater
                                        10-year average return to investors, a
                                        higher average 10-year annual growth
                                        rate and a larger percentage change in
                                        earnings per share.
                                    </p>
                                    <h6>DGY Associates</h6>
                                </li>
                                <li>
                                    <p>
                                        Outsourced contact center services often
                                        cost 30-40% less than in-house customer
                                        care.
                                    </p>
                                    <h6>PWC and Jupiter</h6>
                                </li>
                                <li>
                                    <p>
                                        Global market expansion, which puts a
                                        premium on the ability to respond
                                        quickly to change, is driving companies
                                        of all sizes to consider outsourcing.
                                    </p>
                                    <h6>IDC</h6>
                                </li>
                            </ul>
                        </Col>
                        <Col lg={{ size: 6, offset: 1 }}>
                            <BannerVideo>
                                <Video
                                    image={`${baseURL}assets/images/video-poster.png`}
                                    video={`${baseURL}assets/images/outsource-video.mp4`}
                                    videotype="mp4"
                                    playBtn={false}
                                    playBtnDark={false}
                                />
                            </BannerVideo>
                        </Col>
                    </Row>
                </Container>
            </WhyOutsorceIntro>
            <AbouOutsource>
                <WhyOutSourceHeading
                    headingName="Why Outsource?"
                    headingCol="8"
                    subheadingCol="4"
                    subheadingNumber="01"
                    subheadingText="The Advantage"
                    activateSubHeading={true}
                />
                <Container>
                    <Row>
                        <Col lg="3">
                            <SmBox>
                                <Count>01</Count>
                                <p>Speed-to-Market</p>
                            </SmBox>
                        </Col>
                        <Col lg="3">
                            <SmBox>
                                <Count>02</Count>
                                <p>Scalability</p>
                            </SmBox>
                        </Col>
                        <Col lg="3">
                            <SmBox>
                                <Count>03</Count>
                                <p>
                                    Turnkey Infrastructure. Zero capital outlay.
                                </p>
                            </SmBox>
                        </Col>
                        <Col lg="3">
                            <SmBox>
                                <Count>04</Count>
                                <p>Maintain focus on Brand & Product.</p>
                            </SmBox>
                        </Col>
                        <Col lg="3">
                            <SmBox>
                                <Count>05</Count>
                                <p>Geographic Diversification & Resilience</p>
                            </SmBox>
                        </Col>
                        <Col lg="3">
                            <SmBox>
                                <Count>06</Count>
                                <p>Capability Expansion</p>
                            </SmBox>
                        </Col>
                        <Col lg="3">
                            <SmBox>
                                <Count>07</Count>
                                <p>Integrated Technologies & Innovation</p>
                            </SmBox>
                        </Col>
                        <Col lg="3">
                            <SmBox>
                                <Count>08</Count>
                                <p>Improved ROI</p>
                            </SmBox>
                        </Col>
                    </Row>
                </Container>
            </AbouOutsource>
            <RightTime>
                <WhyOutSourceHeading
                    headingName="How to select the right partner."
                    headingCol="7"
                    subheadingCol="4"
                    subheadingNumber="02"
                    subheadingText="Partner Selection"
                    activateSubHeading={true}
                />
                <Container>
                    <Row>
                        <Col lg="12">
                            <p>
                                Selecting the best partner for your brand is a
                                rigorous and time-consuming process. Getting it
                                wrong can result in more than just money wasted;
                                it can lead to a negative experience for your
                                customers and a reduction in perception towards
                                your brand.
                                <br />
                                <br />
                                Knowing what to look for before you make your
                                decision can make all the difference.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RightTime>
        </>
    );
};
const SmBox = styled.div`
    min-height: 270px;
    background: #fff;
    border: 1px solid rgba(203, 203, 203, 0.75);
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px;
    margin-bottom: 16px;
    transition: all 0.3s ease-in-out;
    transform: translateY(0px);
    p {
        color: #1d1d1f;
        font-size: 16px;
        font-family: ${gilroySemibold};
        ${Media('xlscreens')} {
            font-size: 0.76vw;
        }
    }
    &:hover {
        transform: translateY(-10px);
    }
`;
const Count = styled.div`
    display: inline-flex;
    align-self: flex-end;
    font-size: 25px;
    color: #fa0060;
    font-family: ${gilroySemibold};
    ${Media('xlscreens')} {
        font-size: 1.3vw;
    }
`;
const AbouOutsource = styled.section`
    background-color: rgba(232, 232, 232, 0.2);
    ${Media('tablet')} {
        padding: 20px 0px;
    }
`;
const ArrowImg = styled.img`
    display: block;
    margin: 30px 0;
`;
const BannerVideo = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #000;
    position: relative;
    ${Media('xlscreens')} {
    }

    ${Media('tablet')} {
        margin-top: 50px;
    }
`;
const WhyOutsorceIntro = styled.section`
    ul {
        padding: 0;
        margin-bottom: 20px;
        ${Media('xlscreens')} {
            margin-bottom: 2vw;
        }
        li {
            display: inline-block;
            padding: 30px 0px;
            border-bottom: 1px solid #000;
            ${Media('xlscreens')} {
                padding: 1.5vw 0px;
            }
            p {
                margin-bottom: 40px;
            }
            h6 {
                color: #212529;
                font-family: ${gilroyBold};
                font-size: 16px;
                ${Media('xlscreens')} {
                    font-size: 0.83vw;
                }
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
    ${Media('tablet')} {
        padding: 20px 0px;
    }
`;
const RightTime = styled.section`
    ${Media('tablet')} {
        padding: 20px 0px;
    }
`;
