import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const GlossaryMainPara = ({ children, as, color, noMargin }) => {
    return (
        <Wrapper as={as} color={color} noMargin={noMargin}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.p`
    font-size: 18px;
    font-family: ${gilroySemibold};
    margin-top: 0;
    color: ${(props) =>
        props.color ? props.color : colors.socialRep['brandsBG']};
    line-height: 1.7;
    margin: ${(props) => props.noMargin && '0'};
    ${Media('xlscreens')} {
        font-size: 1.05vw;
        margin-bottom: 0.95vw;
        margin: ${(props) => props.noMargin && '0'};
    }
`;
