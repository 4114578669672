import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import MainHeading from 'shared-components/main-heading/main-heading';
import HomeGlobalDelivery from 'components/home-global-delivery/home-global-delivery';

import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { LetsDiscuss } from 'components/industries/common';

export const GlobalStatsSection = () => {
    return (
        <>
            <GlobalDelivery>
                <Container>
                    <div>
                        <Row>
                            <Col lg="12">
                                <MainHeading>
                                    Flexibility @ Scale: <br /> A global CX
                                    delivery infrastructure.
                                </MainHeading>
                                <SpeedPara>
                                    <p>
                                        Designed for speed, scale and
                                        performance.
                                    </p>
                                </SpeedPara>
                            </Col>

                            <HomeGlobalDelivery />
                        </Row>
                    </div>
                </Container>
            </GlobalDelivery>
            <LetsDiscuss
                heading="Ready to upgrade your customer experience?"
                btnText="Contact Us"
            />
        </>
    );
};

const SpeedPara = styled.div`
    max-width: 420px;
    p {
        font-size: 16px;
        span {
            color: ${colors.pink['100']};
        }
    }
    ${Media('xlscreens')} {
        max-width: 100%;
        p {
            font-size: 1.45vw;
            line-height: 1.5;
        }
    }
`;

const GlobalDelivery = styled.section``;
