import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroySemibold, gilroyRegular } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';

const BigParagraph = ({ bigParagraph, lightText }) => {
    return (
        <ScrollAnimation animateIn="fadeInUp">
            <Wrapper lightText={lightText}>
                {ReactHtmlParser(bigParagraph)}
            </Wrapper>
        </ScrollAnimation>
    );
};
const Wrapper = styled.div`
    margin-top: 20px;
    text-align: center;
    p {
        font-family: ${(props) =>
            props.lightText ? gilroyRegular : gilroySemibold};
        font-size: 48px;
        line-height: 1.54;
        letter-spacing: -2px;
        color: ${colors.gray_dark['100']};
        strong,
        b {
            font-family: inherit;
        }
        ${Media('xlscreens')} {
            font-size: 2.51vw;
            margin: 0px;
        }
        ${Media('tablet')} {
            font-size: 30px;
            font-family: ${gilroyRegular};
            letter-spacing: 0px;
            margin: 0px;
        }
        ${Media('mobile')} {
            font-size: 24px;
        }
    }
    ${Media('tablet')} {
        text-align: left;
        margin-top: 0px;
    }
`;
export default BigParagraph;
