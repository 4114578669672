import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import { gilroySemibold, gilroyLight } from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';
const QuoteBox = ({
    quotePara,
    quoteName,
    quoteDesignation,
    offsetMinus,
    paddingTop,
}) => {
    return (
        <Wrapper offsetMinus={offsetMinus} paddingTop={paddingTop}>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Container>
                    <Row>
                        <Col lg="12">
                            <QuoteBoxContainer>
                                <QuotePara>{quotePara}</QuotePara>
                                <QuoteName>{quoteName}</QuoteName>
                                <QuoteDesignation>
                                    {quoteDesignation}
                                </QuoteDesignation>
                            </QuoteBoxContainer>
                        </Col>
                    </Row>
                </Container>
            </ScrollAnimation>
        </Wrapper>
    );
};
const Wrapper = styled.section`
    padding-top: ${(props) => (props.paddingTop ? '150px' : '0px')};
    margin-top: ${(props) => (props.offsetMinus ? '-100px' : '0px')};
    ${Media('xlscreens')} {
        margin-top: ${(props) => (props.offsetMinus ? '-5vw' : '0px')};
        padding-top: ${(props) => (props.paddingTop ? '7.85vw' : '0px')};
    }
    ${Media('tablet')} {
        padding: 0px;
        margin: 30px 0px;
    }
`;
const QuoteDesignation = styled.div`
    font-family: ${gilroyLight};
    font-size: 23px;
    color: ${colors.white['100']};
    padding-top: 10px;
    ${Media('xlscreens')} {
        font-size: 1.2vw;
        padding-top: 0.52vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
`;
const QuoteName = styled.div`
    font-family: ${gilroySemibold};
    font-size: 23px;
    color: ${colors.white['100']};
    ${Media('xlscreens')} {
        font-size: 1.2vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
`;
const QuotePara = styled.div`
    font-family: ${gilroySemibold};
    font-size: 34px;
    line-height: 1.5;
    color: ${colors.white['100']};
    margin-bottom: 50px;
    ${Media('xlscreens')} {
        font-size: 1.7vw;
        margin-bottom: 2.5vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
        margin-top: 20px;
    }
`;
const QuoteBoxContainer = styled.div`
    background-color: ${colors.gray_dark['100']};
    padding: 135px 100px;
    ${Media('xlscreens')} {
        padding: 7vw 15.7vw;
    }
    ${Media('tablet')} {
        padding: 70px 50px;
    }
`;
export default QuoteBox;
