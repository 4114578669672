import React from 'react';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import ReactHtmlParser from 'react-html-parser';
import {
    SRMSectionHeading,
    SRMFloatingSectionImage,
    SRMCallActionSection,
} from 'components/social-rep-man/common';
import MainPara from 'shared-components/main-para/main-para';
import { RowCenteredBoth } from 'shared-components/theme/grid-helpers';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { gilroySemibold } from 'shared-components/theme/fonts';

export const SatisfactionCallSection = ({
    satisfyData,
    setDemoRequestForm,
}) => {
    return (
        <>
            <SatisfactionSection id="satisfaction">
                <SRMFloatingSectionImage
                    x={[-20, 0]}
                    image={`${baseURL}assets/images/satisfy-float-bg.png`}
                    width={765}
                    left="-10%"
                    bottom="0"
                />
                <Container>
                    <RowCenteredBoth>
                        <Col sm="12" lg="10">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <SRMSectionHeading>
                                    The quickest route to customer satisfaction
                                </SRMSectionHeading>
                                <MainPara align="center">
                                    Our Training Simulator technology provides
                                    an accelerated, hands-on program for agent
                                    learning, preparedness, and empowerment,
                                    <br />
                                    leading to superior customer engagement
                                    outcomes across every interaction type.
                                </MainPara>
                            </ScrollAnimation>
                        </Col>
                    </RowCenteredBoth>
                    <Row>
                        {satisfyData &&
                            satisfyData.length > 0 &&
                            satisfyData.map((item, index) => {
                                return (
                                    <Col sm="12" lg="3" key={index}>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={true}>
                                            <LayersCardsContainer>
                                                <Controller>
                                                    <Scene
                                                        triggerHook="onEnter"
                                                        duration="100%"
                                                        offset="100%">
                                                        <Timeline>
                                                            <Tween
                                                                from={{
                                                                    transform:
                                                                        'translate(-23px, -24px)',
                                                                }}
                                                                to={{
                                                                    transform:
                                                                        'translate(0px, 0px)',
                                                                }}>
                                                                <LayerShadow></LayerShadow>
                                                            </Tween>
                                                        </Timeline>
                                                    </Scene>
                                                </Controller>

                                                <PeopleImgWrapper>
                                                    <img
                                                        src={item.image}
                                                        alt=""
                                                    />
                                                </PeopleImgWrapper>
                                                <HeadingWrapper>
                                                    <h3>
                                                        {ReactHtmlParser(
                                                            item.heading
                                                        )}
                                                    </h3>
                                                </HeadingWrapper>
                                                <MainPara>
                                                    {ReactHtmlParser(item.para)}
                                                </MainPara>
                                            </LayersCardsContainer>
                                        </ScrollAnimation>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </SatisfactionSection>

            <SRMCallActionSection
                heading="Accelerate agent skills and confidence, today."
                para="Better CX is just a Training Simulator away."
                btnText="Start Now"
                onBtnClick={() => setDemoRequestForm(true)}
            />
        </>
    );
};
const SatisfactionSection = styled.section`
    position: relative;
    z-index: 10;
    ${Media('xlscreens')} {
        padding: 7vw 0 14vw;
        .container {
            padding: 0 8vw !important;
        }
    }
`;
const PeopleImgWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 0px !important;
    img {
        max-width: 100%;
        height: auto;
        border-radius: 50%;
        border: 2px solid ${colors.socialRep['brandsBG']};
    }
    ${Media('xlscreens')} {
        margin-bottom: 3vw;
        max-width: 10.5vw;
    }
    ${Media('tablet')} {
        margin-bottom: 30px;
        img {
            height: 180px;
        }
    }
`;
const HeadingWrapper = styled.div`
    height: 150px;
    display: flex;
    align-items: flex-end;
    h3 {
        font-weight: normal;
        font-family: ${gilroySemibold};
        margin: 50px 0 20px;
        line-height: 1.1;
    }
    ${Media('xlscreens')} {
        height: auto;
        h3 {
            margin: 4vw 0 0.5vw;
            font-size: 1.8vw;
        }
    }
    ${Media('tablet')} {
        height: unset;
        h3 {
            font-size: 18px;
            br {
                display: none;
            }
        }
    }
`;
const LayersCardsContainer = styled.div`
    border: 2px solid ${colors.black['100']};
    position: relative;
    margin: 50px 0;
    height: 800px;
    background-color: ${colors.white['100']};
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    p {
        margin: 0;
    }
    ${Media('xlscreens')} {
        padding: 1.56vw 0.75vw;
        height: 30vw;
        margin: 4vw 1vw 0;
        p {
            min-height: 3.125vw;
        }
    }
    ${Media('tablet')} {
        height: unset;
        padding: 50px 25px;
        margin: 35px 0;
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
        &::after {
            right: -15px;
            top: 15px;
        }
    }
`;
const LayerShadow = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    right: -24px;
    top: 24px;
    background-color: ${colors.black['100']};
    z-index: -1;
`;
