import React from 'react';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
export const DEHeading = ({ children, light }) => {
    return <Wrapper light={light}>{children}</Wrapper>;
};
const Wrapper = styled.h1`
    margin-top: 0;
    font-size: 50px;
    font-family: ${gilroyBold};
    line-height: 1.16;
    letter-spacing: -2px;
    color: ${(props) =>
        props.light ? colors.white['100'] : colors.black['100']};
    a {
        color: ${colors.pink['100']};
        text-decoration: underline;
    }
    ${Media('xlscreens')} {
        font-size: 4.68vw;
        letter-spacing: -0.2vw;
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 36px;
    }
`;
