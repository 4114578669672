import React, { Fragment } from 'react';
import styled from 'styled-components';
import Layout from 'components/layout';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import MainHeading from 'shared-components/main-heading/main-heading';
import MainPara from 'shared-components/main-para/main-para';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import ScrollAnimation from 'react-animate-on-scroll';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import BannerMorph from 'components/banner-morph/banner-morph';
import HomeBigText from 'components/home-big-text/home-big-text';
import { gilroyLight } from 'shared-components/theme/fonts';
import { EndtoEndSection, ExpandedSection, LaborSection } from './sections';
import LazyLoad from 'react-lazyload';
import Loader from 'components/loader/loader';
import { baseURL } from 'shared-components/theme/helpers';

const scrollNavData = [
    {
        name: 'intro',
        target: 'intro',
        offset: -170,
    },

    {
        name: 'endtoend',
        target: 'endtoend',
        offset: 0,
    },

    {
        name: 'ExpandedFlexible',
        target: 'ExpandedFlexible',
        offset: 0,
    },

    {
        name: 'ReliableUptime',
        target: 'ReliableUptime',
        offset: -20,
    },
];

const lazySections = [
    {
        id: 'endtoend',
        section: <EndtoEndSection />,
    },
    {
        id: 'ExpandedFlexible',
        section: <ExpandedSection />,
    },
    {
        id: 'ReliableUptime',
        section: <LaborSection />,
    },
];

const AtHome = () => {
    return (
        <Layout>
            <Breadcrumbs>
                <li>At Home</li>
            </Breadcrumbs>
            <AtHomeIntro id="intro">
                <Container>
                    <Row>
                        <Col lg="8">
                            <ScrollAnimation
                                delay={100}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <MainHeading noMargin={true}>
                                    Building what&apos;s next, <br /> at home.
                                </MainHeading>
                            </ScrollAnimation>
                        </Col>
                        <Col lg={{ size: 3, offset: 1 }}>
                            <ScrollAnimation
                                delay={200}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <MainPara>
                                    A crisis-tested organization built for
                                    performance, flexibility, and security
                                    @Home.
                                </MainPara>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
                <BannerMorph
                    image={`${baseURL}assets/images/ath-intro-img.jpg`}
                />
                <HomeBigText
                    bigTextOne="Expand Your"
                    bigTextTwo={
                        <Fragment>
                            Talent pool
                            <b style={{ color: colors.pink['100'] }}>.</b>
                        </Fragment>
                    }
                    level="athOne"
                    textOneleft="-50%"
                    textOneRight="50%"
                    textTwoleft="50%"
                    textTwoRight="-100%"
                />
                <Container>
                    <Row>
                        <Col lg="6">
                            <ScrollAnimation
                                delay={100}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <AtHomePoints>
                                    <AtHPointIcon>
                                        <img
                                            src={`${baseURL}assets/images/ath-perform-icon.svg`}
                                            alt="-"
                                        />
                                    </AtHPointIcon>
                                    <AtHPointText>
                                        <h3>Maximize Performance.</h3>
                                        <p>
                                            With advanced remote monitoring and
                                            connection technology, paired with
                                            ongoing virtual training programs,
                                            @Home is perfectly setup for
                                            continuous program outperformance.
                                        </p>
                                    </AtHPointText>
                                </AtHomePoints>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="6">
                            <ScrollAnimation
                                delay={200}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <AtHomePoints>
                                    <AtHPointIcon>
                                        <img
                                            src={`${baseURL}assets/images/ath-security-icon.svg`}
                                            alt="-"
                                        />
                                    </AtHPointIcon>
                                    <AtHPointText>
                                        <h3>Guarantee Security.</h3>
                                        <p>
                                            Advanced fraud & monitoring
                                            software, locked-down hardware, 2FA,
                                            and in-depth training protocols
                                            ensure robust PII security.
                                        </p>
                                    </AtHPointText>
                                </AtHomePoints>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <ScrollAnimation
                                delay={300}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <AtHomePoints>
                                    <AtHPointIcon>
                                        <img
                                            src={`${baseURL}assets/images/ath-talent-icon.svg`}
                                            alt="-"
                                        />
                                    </AtHPointIcon>
                                    <AtHPointText>
                                        <h3>Expand your talent pool.</h3>
                                        <p>
                                            Tap into a world of over ten
                                            thousand talented @Home agents,
                                            ready to support your brand
                                            throughout multiple experienced
                                            geographies.
                                        </p>
                                    </AtHPointText>
                                </AtHomePoints>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="6">
                            <ScrollAnimation
                                delay={400}
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <AtHomePoints>
                                    <AtHPointIcon>
                                        <img
                                            src={`${baseURL}assets/images/ath-protect-icon.svg`}
                                            alt="-"
                                        />
                                    </AtHPointIcon>
                                    <AtHPointText>
                                        <h3>Protect Against Crisis.</h3>
                                        <p>
                                            Adding distributed teams to our
                                            outsourcing mix means no single
                                            point of failure, reduced risk, and
                                            ensures continuous CX uptime.
                                        </p>
                                    </AtHPointText>
                                </AtHomePoints>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </AtHomeIntro>

            {lazySections && lazySections.length > 0
                ? lazySections.map((item, index) => {
                      return (
                          <div id={item.id} key={index}>
                                {lazySections.length === index + 1 ? (
                                      <ScrollNav data={scrollNavData} />
                                  ) : null}
                                {item.section}
                          </div>
                      );
                  })
                : null}
        </Layout>
    );
};

const AtHomeIntro = styled.section`
    h2 {
        letter-spacing: -0.5px;
    }
    ${Container} {
        &:nth-child(1) p {
            padding: 0px;
        }
    }
    ${Media('xlscreens')} {
        padding-top: 6vw;
        h2 {
            letter-spacing: -0.1vw;
            padding-left: 2vw;
        }
        ${Container} {
            &:nth-child(1) p {
                padding-left: 2vw;
            }
        }
    }
    ${Media('tablet')} {
        ${Container} {
            &:nth-child(1) p {
                margin-top: 40px;
            }
        }
    }
`;

const AtHomePoints = styled.div`
    display: flex;
    padding: 60px 0 0;
    ${Media('xlscreens')} {
        padding: 5.5vw 0 0;
    }
`;

const AtHPointIcon = styled.div`
    flex: 0 0 100px;
    max-width: 100px;
    padding-top: 5px;
    padding-left: 10px;
    ${Media('xlscreens')} {
        flex: 0 0 6.2vw;
        max-width: 6.2vw;
        padding-top: 0.4vw;
        padding-left: 0.5vw;
        img {
            width: 2vw;
        }
    }
`;

const AtHPointText = styled.div`
    h3 {
        font-size: 34px;
        letter-spacing: -0.7px;
        margin-top: 0px;
        ${Media('mobile')} {
            font-size: 22px;
        }
    }
    p {
        font-size: 14px;
        font-family: ${gilroyLight};
        line-height: 1.5;
        margin: 0px;
    }
    ${Media('xlscreens')} {
        h3 {
            font-size: 1.95vw;
            letter-spacing: -0.1vw;
            margin-bottom: 2.1vw;
        }
        p {
            font-size: 1.05vw;
            width: 85%;
        }
    }
`;

export default AtHome;
