import React from 'react';
import styled from 'styled-components';
import { Container, Col } from '@bootstrap-styled/v4';
import {
    RowReversedHCentered,
    RowCentered,
} from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';
import { gilroyMedium, gilroyBold } from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';

const CXProduct = ({ children, image, imageSide }) => {
    return (
        <Wrapper>
            <Container fluid={true}>
                {imageSide === 'right' ? (
                    <RowReversedHCentered noGutters={true}>
                        <Col lg="6">
                            <div>
                                <ProductImg>
                                    <img
                                        data-src={image}
                                        src={image}
                                        alt="-"
                                        className="lozad"
                                    />
                                </ProductImg>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div>
                                <ProductText>{children}</ProductText>
                            </div>
                        </Col>
                    </RowReversedHCentered>
                ) : (
                    <RowCentered noGutters={true}>
                        <Col lg="6">
                            <div>
                                <ProductImgLeft>
                                    <img
                                        data-src={image}
                                        src={image}
                                        alt="-"
                                        className="lozad"
                                    />
                                </ProductImgLeft>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div>
                                <ProductText>{children}</ProductText>
                            </div>
                        </Col>
                    </RowCentered>
                )}
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.section`
    ${Media('xlscreens')} {
        padding: 3vw 0;
    }
`;

const ProductImg = styled.div`
    height: calc(100vh - 100px);
    img {
        height: 100%;
        object-fit: cover;
    }
    ${Media('tablet')} {
        height: auto;
        overflow: hidden;
        margin: 0px -15px;
        img {
            height: auto;
            width: 100%;
        }
    }
`;

const ProductImgLeft = styled(ProductImg)`
    display: flex;
    justify-content: flex-end;
`;

const ProductText = styled.div`
    padding: 50px 150px;
    span {
        font-size: 17px;
        font-family: ${gilroyMedium};
    }
    h2,
    h3 {
        font-size: 50px;
        font-family: ${gilroyBold};
        letter-spacing: -0.5px;
        margin: 40px 0;
        line-height: 1.3;
        sup {
            position: relative;
            top: -10px;
            font-size: 14px;
        }
    }
    h3 {
        font-family: ${gilroyMedium};
    }
    p {
        font-size: 16px;
    }
    ${Media('xlscreens')} {
        padding-left: 7.5vw;
        padding-right: 7.5vw;
        span {
            font-size: 0.9vw;
        }
        h2,
        h3 {
            font-size: 2.4vw;
            margin: 2.1vw 0;
            letter-spacing: -0.15vw;
            sup {
                font-size: 0.8vw;
                top: -1vw;
            }
        }
        p {
            font-size: 0.85vw;
        }
    }
    ${Media('tablet')} {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        h2,
        h3 {
            font-size: 30px;
            br {
                display: none;
            }
        }
        p {
            br {
                display: none;
            }
        }
    }
`;

export default CXProduct;
