import React from 'react';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import colors from 'shared-components/theme/colors';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import {
    BIOperationsSection,
    BIAnalyticsSection,
    BIntroSection,
} from './sections';
import LazyLoad from 'react-lazyload';
import Loader from 'components/loader/loader';

const scrollNavData = [
    {
        name: 'intro',
        target: 'intro',
        offset: -250,
    },
    {
        name: 'analytics',
        target: 'analytics',
        offset: -100,
    },
    {
        name: 'operations',
        target: 'operations',
        offset: 50,
    },
];

const lazySections = [
    {
        id: 'intro',
        section: <BIntroSection />,
    },
    {
        id: 'analytics',
        section: <BIAnalyticsSection />,
    },
    {
        id: 'operations',
        section: <BIOperationsSection />,
    },
];

const BusinessIntelligence = () => {
    return (
        <Layout>
            <Breadcrumbs bgColor={colors.gray_lightest['100']}>
                <li>Solutions</li>
                <li>Business Intelligence</li>
            </Breadcrumbs>

            {lazySections && lazySections.length > 0
                ? lazySections.map((item, index) => {
                      return (
                          <div id={item.id} key={index}>
                               {lazySections.length === index + 1 ? (
                                      <ScrollNav data={scrollNavData} />
                                  ) : null}
                                  {item.section}
                          </div>
                      );
                  })
                : null}
        </Layout>
    );
};

export default BusinessIntelligence;
