import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import {
    gilroyMedium,
    gilroySemibold,
    gilroyLight,
} from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';
const SolutionBox = ({
    solutionTagLine,
    solutionHeading,
    solutionImage,
    solutioParagraph,
    imageStyleRight,
    styleTwo,
}) => {
    return (
        <Wrapper>
            <Container>
                {imageStyleRight ? (
                    <Row>
                        <Col lg={styleTwo ? '6' : '7'}>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <Tagline>{solutionTagLine}</Tagline>
                                <SolutionHeading>
                                    {solutionHeading}
                                </SolutionHeading>
                                <SolutioParagraph
                                    imageStyleRight={imageStyleRight}
                                    styleTwo={styleTwo}>
                                    <p>{solutioParagraph}</p>
                                </SolutioParagraph>
                            </ScrollAnimation>
                        </Col>
                        <Col lg={styleTwo ? '6' : '5'}>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <SolutionImage>
                                    <img src={solutionImage} alt="" />
                                </SolutionImage>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col lg="6">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <Tagline>{solutionTagLine}</Tagline>
                                <SolutionHeading>
                                    {solutionHeading}
                                </SolutionHeading>
                                <SolutionImage>
                                    <img src={solutionImage} alt="" />
                                </SolutionImage>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="6">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <SolutioParagraph
                                    imageStyleRight={imageStyleRight}
                                    styleTwo={styleTwo}>
                                    <p>{solutioParagraph}</p>
                                </SolutioParagraph>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                )}
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.section`
    padding-top: 0px;
    ${Media('tablet')} {
        padding: 0px;
        padding-top: 50px;
    }
`;
const SolutionImage = styled.div`
    img {
        width: 100%;
    }
`;
const SolutioParagraph = styled.div`
    p {
        display: block;
        font-family: ${gilroyLight};
        font-size: ${(props) => (props.styleTwo ? '28px' : '20px')};
        margin-bottom: 50px;
        padding-left: ${(props) => (props.imageStyleRight ? '0' : '90px')};
        padding-right: ${(props) => (props.imageStyleRight ? '90px' : '0')};
        color: ${colors.gray_dark['100']};
        line-height: 1.3;
        ${Media('xlscreens')} {
            font-size: ${(props) => (props.styleTwo ? '1.46vw' : '1.04vw')};
            margin-bottom: 0.8vw;
            padding-left: ${(props) =>
                props.imageStyleRight ? '0px' : '4.8vw'};
        }
        ${Media('tablet')} {
            padding: 20px 0px;
            font-size: 18px;
        }
    }
`;
const SolutionHeading = styled.h2`
    display: block;
    font-family: ${gilroySemibold};
    font-size: 50px;
    margin-bottom: 50px;
    letter-spacing: -0.43px;
    color: ${colors.gray_dark['100']};
    ${Media('xlscreens')} {
        font-size: 2.6vw;
        margin-bottom: 2.8vw;
    }
    ${Media('tablet')} {
        font-size: 38px;
        margin-bottom: 30px;
    }
`;
const Tagline = styled.span`
    display: block;
    font-family: ${gilroyMedium};
    font-size: 29px;
    letter-spacing: -0.43px;
    color: ${colors.pink['100']};
    ${Media('xlscreens')} {
        font-size: 1.51vw;
    }
    ${Media('tablet')} {
        font-size: 24px;
    }
`;
export default SolutionBox;
