import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';

export const EbookHeadingLine = ({
    children,
    top,
    bottom,
    right,
    left,
    color,
    width,
    size,
}) => {
    return (
        <Wrapper
            top={top}
            bottom={bottom}
            right={right}
            left={left}
            color={color}
            width={width}
            size={size}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;
    z-index: 1;
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        width: ${(props) => props.width};
        height: ${(props) => (props.size === 'small' ? '13px' : '20px')};
        top: ${(props) => props.top};
        bottom: ${(props) => props.bottom};
        right: ${(props) => props.right};
        left: ${(props) => props.left};
        background-color: ${(props) => props.color};
    }
    ${Media('xlscreens')} {
        &:before {
            height: ${(props) =>
                props.size === 'small' ? '0.677vw' : '1.2vw'};
        }
    }
`;
