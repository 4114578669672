import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Container, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import { gilroySemibold, gilroyMedium } from 'shared-components/theme/fonts';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import colors from 'shared-components/theme/colors';
const digitalVals = [
    {
        title: '$2.3B',
        text: (
            <Fragment>
                Billian We Generate $2.3B each year <br />
                in lifetime value for our clients.
            </Fragment>
        ),
    },
    {
        title: '10M',
        text: (
            <Fragment>
                Million Sales calls pushed to call <br />
                centers each year.
            </Fragment>
        ),
    },
    {
        title: '1M',
        text: (
            <Fragment>
                Million We help our clients acquire over <br />
                1M new lines of business each year.
            </Fragment>
        ),
    },
];
export const DigitalValues = () => {
    return (
        <div>
            <Container>
                <BoxGeneratedValue>
                    <RowCentered noGutters>
                        {digitalVals &&
                            digitalVals.map((item, index) => (
                                <Col xs="12" lg="4" key={index}>
                                    <ValueHolderB
                                        noBorder={
                                            digitalVals.length - 1 === index
                                        }>
                                        <BoxGeneratedValueTitle>
                                            {item.title}
                                        </BoxGeneratedValueTitle>
                                        <BoxGeneratedValueText>
                                            {item.text}
                                        </BoxGeneratedValueText>
                                    </ValueHolderB>
                                </Col>
                            ))}
                    </RowCentered>
                </BoxGeneratedValue>
            </Container>
        </div>
    );
};
const ValueHolderB = styled.div`
    ${Media('xlscreens')} {
        border-right: 1px solid ${colors.gray['border']};
    }
    ${Media('tablet')} {
        border-bottom: 1px solid ${colors.gray['border']};
    }
    ${(props) => {
        if (props.noBorder) {
            return `border:none !important`;
        }
    }}
`;
const BoxGeneratedValueTitle = styled.div`
    font-family: ${gilroySemibold};
    font-size: 2.5vw;
    padding-top: 3.2vw;
    text-align: center;
    width: 100%;
    ${Media('tablet')} {
        font-size: 13.5vw;
        padding: 4vw;
    }
`;
const BoxGeneratedValueText = styled.div`
    font-family: ${gilroyMedium};
    font-size: 0.8vw;
    text-align: center;
    width: 100%;
    padding: 1vw;
    padding-bottom: 3.2vw;
    ${Media('tablet')} {
        font-size: 4.5vw;
        padding: 0vw 16vw 8vw 16vw;
    }
`;
const BoxGeneratedValue = styled.div`
    border-radius: 34px;
    border: 1px #e0e0e0 solid;
    background: #ffffff;
    margin: 2.8vw;
    margin-bottom: 8vw;
    ${Media('tablet')} {
        border-radius: 18px;
    }
`;
