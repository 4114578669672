import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { MdClose } from 'react-icons/md';
import Media from 'shared-components/theme/medias';

const MediaPopup = ({ videoUrl, state, closeLightBox }) => {
    var ytIframe = document.getElementById('popupIframe');
    const [playVideo, setPlayVideo] = useState(false);
    useEffect(() => {
        setPlayVideo(state);
    }, [state]);
    const closePopup = () => {
        ytIframe.contentWindow.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            '*'
        );
        setPlayVideo(false);
        closeLightBox();
    };
    if (playVideo) {
        ytIframe.contentWindow.postMessage(
            '{"event":"command","func":"playVideo","args":""}',
            '*'
        );
    }
    return (
        <Wrapper state={playVideo}>
            <Overlay onClick={closePopup} />
            <iframe
                src={`${videoUrl}?enablejsapi=1`}
                title="YouTube video player"
                frameBorder="0"
                id="popupIframe"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>

            <CloseIcon onClick={closePopup}>
                <MdClose color={colors.white['100']} />
            </CloseIcon>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    background-color: ${colors.black['70']};
    transform: scale(0.5);
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    iframe {
        height: 90%;
        width: 90%;
    }
    ${(props) => {
        if (props.state) {
            return `
                transform:scale(1);
                opacity:1;
                visibility:visible;
            `;
        }
    }}
    ${Media('tablet')} {
        iframe {
            height: 80%;
        }
    }
`;
const Overlay = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    top: 0;
    left: 0;
`;
const CloseIcon = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    background-color: ${colors.black['100']};
    cursor: pointer;
    svg {
        font-size: 30px;
    }
`;
export default MediaPopup;
