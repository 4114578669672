import React, { useState } from 'react';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { baseURL } from 'shared-components/theme/helpers';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import DemoRequestForm from 'components/social-rep-man/demo-request-form';
import SRMIntroSection from 'components/social-rep-man/intro-section';
import Loader from 'components/loader/loader';
import LazyLoad from 'react-lazyload';
import {
    ScoreEngageSection,
    ScoreManageSection,
    ScoreInsightSection,
} from './sections';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -210,
    },
    {
        name: 'engage',
        target: 'engage',
        offset: 0,
    },
    {
        name: 'manage',
        target: 'manage',
        offset: 0,
    },
    {
        name: 'insight',
        target: 'insight',
        offset: 0,
    },
];

const engageData = [
    {
        image: `${baseURL}assets/images/tech/inspire-engage-1.jpg`,
        heading: 'Add Certainty to KPI Performance',
        para:
            'Measure real-time agent performance against CX KPI goals, with solutions for ad-hoc corrections and near- and long-term action planning. ',
    },
    {
        image: `${baseURL}assets/images/tech/inspire-engage-2.jpg`,
        heading: 'Coaching and Performance Transparency & Control',
        para:
            'Every coaching session and performance outcome is tracked and measured, allowing for high-precision adjustments and complete CX program control.  ',
    },
    {
        image: `${baseURL}assets/images/tech/inspire-engage-3.jpg`,
        heading: 'Elevate Long-Term Agent Performance & Tenure ',
        para:
            'Better agent development reduces turnover and keeps program expertise embedded in your system.',
    },
];

const manageContent = [
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/monitor-tag.svg`,
        VerticalCardHeading: `One dashboard for all coaching & training activities `,
        VerticalCardParagraph: `Inspire brings all coaching & training activities into one cohesive dashboard, creating a seamless environment to empower supervisors to better train agents. See agents’ scorecards, logs for coaching, observations, corrective actions, and evaluations, providing an up-to-date picture of agent performance. `,
        VerticalCardImage: `${baseURL}assets/images/tech/inspire-monitor-img-1.svg`,
    },
    {
        ImageRight: false,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/quick-access-square.svg`,
        VerticalCardHeading: `Coaching requests, just a click away`,
        VerticalCardParagraph: `Inspire is built to make training simple, seamless, and real-time. See an opportunity to engage and improve an agent’s performance? Quickly submit a coaching request within Inspire, and the agent will be automatically notified.`,
        VerticalCardImage: `${baseURL}assets/images/tech/inspire-monitor-img-2.svg`,
    },
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/inpire-monitor-tag-3.svg`,
        VerticalCardHeading: `Integrate your existing data`,
        VerticalCardParagraph: `Inspire is designed pull in any of your existing dashboards, bringing the numbers closer to where the conversations are happening, coaching sessions or team huddles. The data can also be used to automate the process of setting goals and setting trigger points that would automatically generate a coaching requests.`,
        VerticalCardImage: `${baseURL}assets/images/tech/inspire-monitor-img-3.svg`,
    },
    {
        ImageRight: false,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/inspire-monitor-tag-4.svg`,
        VerticalCardHeading: `Track coaching activities and performance`,
        VerticalCardParagraph: `Inspire provides regular coaching with visibility into all historical training data. The system requires at least one coaching session per week for regular coaching programs, making sure touchpoints and communication between supervisors and agents are consistent and that KPIs are clearly outlined.  `,
        VerticalCardImage: `${baseURL}assets/images/tech/inspire-monitor-img-2.svg`,
    },
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/inspire-monitor-tag-5.svg`,
        VerticalCardHeading: `Set personalized goals `,
        VerticalCardParagraph: `CX isn’t always one-size-fits-all, and your approach to agent improvement and coaching shouldn’t be, either. Create laser-focused, customized action plans with a planned performance schedule and stage-by-stage action tracking, systematically improving the performance of each agent over time. `,
        VerticalCardImage: `${baseURL}assets/images/tech/inspire-monitor-img-5.svg`,
    },
    {
        ImageRight: false,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/inspire-monitor-tag-6.svg`,
        VerticalCardHeading: `Stay informed with built-in analytics`,
        VerticalCardParagraph: `Supervisors can see daily, weekly, and monthly performance data at-a-glance, and compare trends over longer periods of time using historical data. Agents can use the same data to see how they are performing against specific metrics, or against their peers. `,
        VerticalCardImage: `${baseURL}assets/images/tech/inspire-monitor-img-6.svg`,
    },
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/inspire-monitor-tag-7.svg`,
        VerticalCardHeading: `Real-time performance gamification `,
        VerticalCardParagraph: `Integrated with <a href="/technology/scorecard">Scorecard</a>, ibex’s agent performance gamification platform, supervisors and managers can see a simple overview of an agent’s scorecard based on that agent’s identified improvement and focus areas, as well as how they’re tracking against their goal score. Agents can also view their scorecard to review their performance, refer back to their coaching plan, and claim incentives. `,
        VerticalCardImage: `${baseURL}assets/images/tech/inspire-monitor-img-7.svg`,
    },
];

const insightData = [
    {
        icon: `${baseURL}assets/images/tech/partnership.svg`,
        title: 'Build a Culture of Engaged Agents ',
        desc:
            'When you invest in coaching and upskilling, you create a culture of dedicated, highly-skilled employees who deliver better, faster results. ',
    },
    {
        icon: `${baseURL}assets/images/tech/wand.svg`,
        title: 'Enhance Agent Investment and Retention',
        desc:
            'Invested agents stick around longer, ensuring valuable CX knowledge doesn’t exit your program, leaving gaps in your CX delivery. ',
    },
    {
        icon: `${baseURL}assets/images/tech/score-insight-icon-2.svg`,
        title: 'Drive Immediate & Long-Term CX Improvements ',
        desc:
            'Mitigate CX risk and continuously improve performance to hit key milestones and bolster brand sentiment. ',
    },
];

const Inspire = () => {
    const [demoRequestForm, setDemoRequestForm] = useState(false);

    const sections = [
        {
            component: <ScoreEngageSection data={engageData} />,
            isScrollNav: false,
        },
        {
            component: <ScoreManageSection data={manageContent} />,
            isScrollNav: false,
        },
        {
            component: (
                <ScoreInsightSection
                    data={insightData}
                    requestForm={() => setDemoRequestForm(true)}
                />
            ),
            isScrollNav: true,
        },
    ];

    return (
        <Layout>
            <Breadcrumbs>
                <li>Wave X</li>
                <li>Technology</li>
            </Breadcrumbs>

            <DemoRequestForm
                handleDemoForm={(val) => setDemoRequestForm(val)}
                formState={demoRequestForm}
                marketFormId="1182"
            />

            <SRMIntroSection
                preheading="Inspire"
                heading="Agent Coaching and Performance Management "
                para="An all-in-one agent coaching and performance acceleration platform designed to target and identify personalized coaching opportunities for every agent, in real-time. "
                mainImg={`${baseURL}assets/images/tech/inspire-intro.svg`}
                textureImg={`${baseURL}assets/images/tech/inspire-front.svg`}
                textureimgHeight={558}
                textureImgPosition="front"
                onBtnClick={() => setDemoRequestForm(true)}
            />

            {sections &&
                sections.map((section, index) => {
                    return (
                        <div
                            key={index}>
                            {section.isScrollNav && (
                                <ScrollNav data={scrollNavData} />
                            )}
                            {section.component}
                        </div>
                    );
                })}
        </Layout>
    );
};

export default Inspire;
