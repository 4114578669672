import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import Media from 'shared-components/theme/medias';
import { gilroyMedium, gilroyBold } from 'shared-components/theme/fonts';
import SlickSlider from 'shared-components/slider/slider';
import ScrollAnimation from 'react-animate-on-scroll';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';

const slides = [
    {
        icon: `${baseURL}assets/images/ibex_secured.svg`,
        title: 'Secured Agent <br /> Computer',
    },
    {
        icon: `${baseURL}assets/images/two-factor-auth.svg`,
        title: 'DUO 2 factor <br /> authentication',
    },
    {
        icon: `${baseURL}assets/images/cisco.svg`,
        title: 'Cisco Any <br /> Connect VPN',
    },
    {
        icon: `${baseURL}assets/images/sentinel-one.svg`,
        title: 'SentinelOne Endpoint <br /> Detection and Response',
    },
    {
        icon: `${baseURL}assets/images/IVRmasking.svg`,
        title: 'IVR Masking Technologies for <br /> secure PCI transactions',
    },
    {
        icon: `${baseURL}assets/images/rt-monitoring.svg`,
        title:
            'Real-Time Monitoring & ability to <br /> push configuration updates <br /> remotely via Desktop Central',
    },
];

const AtHomeSlider = () => {
    const settingsBlog = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        centerMode: false,
        centerPadding: '0px',
        focusOnSelect: false,
        autoplay: false,
        autoplaySpeed: 3000,
        initialSlide: 0,
        variableWidth: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <MainScrollerContainero id="manage">
            <ScrollAnimation
                animateIn="fadeInDown"
                animateOnce={true}
                delay={100}>
                <HeaderOne>
                    <HeaderTitle>
                        Securing the workspaces <br /> of our @Home agents:
                        <br />
                        Critical Hardware & <br /> Software For Data <br />
                        Security & Integrity.
                    </HeaderTitle>
                </HeaderOne>
            </ScrollAnimation>
            <div style={{ height: '27vw' }}>
                <ScrollAnimation
                    animateIn="fadeInDown"
                    animateOnce={true}
                    delay={100}>
                    <SlickSlider settings={settingsBlog}>
                        {slides && slides.length > 0
                            ? slides.map((item, index) => {
                                  return (
                                      <ManageSliderBox
                                          key={index}
                                          darkBg={index % 2 === 0 && true}>
                                          <ManageSizeCont>
                                              <BoxNumber>
                                                  0{index + 1}
                                              </BoxNumber>
                                              <BoxImgHolder>
                                                  <img src={item.icon} alt="" />
                                              </BoxImgHolder>

                                              <BoxHeaderContainer>
                                                  {ReactHtmlParser(item.title)}
                                              </BoxHeaderContainer>
                                          </ManageSizeCont>
                                      </ManageSliderBox>
                                  );
                              })
                            : null}
                    </SlickSlider>
                </ScrollAnimation>
            </div>
        </MainScrollerContainero>
    );
};

const ManageSizeCont = styled.div`
    height: 25.7vw;
    ${Media('tablet')} {
        width: 100%;
        height: 65vw;
    }
`;

const BoxNumber = styled.div`
    padding: 4vw 0vw 0vw 4vw;
    font-size: 1.8vw;
    line-height: 2.4vw;
    margin-bottom: 2.7vw;
    font-family: ${gilroyBold};
    letter-spacing: -0.08vw;
    color: ${colors.gray_dark['100']};
    ${Media('tablet')} {
        width: 100%;
        height: auto;
        font-size: 9vw;
        text-align: center;
        padding: 13vw 0vw 8vw 0vw;
    }
`;

const BoxImgHolder = styled.div`
    text-align: center;
    height: 9.5vw;
    position: relative;
    img {
        display: inline-block !important;
    }
    ${Media('xlscreens')} {
        img {
            height: 7.8vw;
        }
    }
    ${Media('mobile')} {
        height: 200px;
    }
`;

const ManageSliderBox = styled.div`
    background: ${(props) =>
        props.darkBg ? colors.gray_lightest['100'] : colors.gray_light['100']};
    ${Media('tablet')} {
        height: 155vw;
    }
`;

const BoxHeaderContainer = styled.div`
    font-family: ${gilroyMedium};
    color: ${colors.gray_dark['100']};
    font-size: 17px;
    padding: 0vw 4vw;
    text-align: center;
    ${Media('xlscreens')} {
        font-size: 0.89vw;
    }
    ${Media('tablet')} {
        font-size: 7vw;
        text-align: center;
        padding: 5vw 0vw 5vw 0vw;
    }

    ${Media('mobile')} {
        font-size: 20px;
    }
`;

const MainScrollerContainero = styled.section`
    background-color: ${colors.gray_lightest['100']};
    padding-top: 0px;
    overflow: hidden;
    .slick-arrow {
        position: absolute;
        left: 50%;
        margin-left: -80px;
        top: 27vw;
        z-index: 99;
        &.slick-next {
            transform: translateX(50px);
        }
    }
    ${Media('tablet')} {
        padding-bottom: 0px !important;
        height: 230vw;
        ${Media('mobile')} {
            height: 190vw;
        }
        .slick-arrow {
            position: absolute;
            left: 50%;
            margin-left: -40px;
            top: 125vw;
            z-index: 99;
            &.slick-next {
                transform: translateX(50px);
            }
        }
    }
`;

const HeaderTitle = styled.div`
    font-size: 2.1vw;
    line-height: 2.6vw;
    margin-bottom: 1.8vw;
    font-family: ${gilroyMedium};
    letter-spacing: -0.08vw;
    ${Media('tablet')} {
        font-size: 5.6vw;
        line-height: 6.5vw;
        text-align: center;
        padding-bottom: 4vw;
    }
`;

const HeaderOne = styled.div`
    padding: 5vw 8vw 6vw 9vw;
    background: ${colors.gray_dark['100']};
    color: ${colors.white['100']};
    float: left;
    ${Media('tablet')} {
        float: none;
    }
`;

export default AtHomeSlider;
