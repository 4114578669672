import React, { Fragment } from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';

import MainPara from 'shared-components/main-para/main-para';
import GlobalDelivery from 'components/global-delivery/global-delivery';
import IndustriesServed from 'components/industries-served/industries-served';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { gilroyBold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

const skyBoxItems = [
    {
        heading: (
            <Fragment>
                Performance <br /> At Scale.
            </Fragment>
        ),
        para: `Our PMO and WFM teams ensure
        performance at every minute,
        hour, and day — across every
        touchpoint.`,
        isLast: false,
    },
    {
        heading: (
            <Fragment>
                Award-Winning <br /> Bestshore Teams.
            </Fragment>
        ),
        para: `Our award-winning agent
        teams drive lower AHT,
        achieve higher CSAT, and
        push accelerated ROI.`,
        isLast: false,
    },
    {
        heading: (
            <Fragment>
                Day 1 Leadership <br /> Investment.
            </Fragment>
        ),
        para: `Our Wave 0 program gets
        leadership leaned-in and
        invested in your brand,
        before the first agent is
        even hired.`,
        isLast: false,
    },
    {
        heading: (
            <Fragment>
                Business Intelligence <br /> & Analytics.
            </Fragment>
        ),
        para: `From Complexity Studies, to
        Churn Rate Analysis, to
        Feedback Analytics, our BI
        team is ready to streamline
        everything.`,
        isLast: true,
    },
];

export const FeaturesSection = () => {
    return (
        <>
            <SkyContent>
                <Container>
                    <Row>
                        <Col lg="12">
                            <SkyBox>
                                <div>
                                    <Row>
                                        {skyBoxItems &&
                                            skyBoxItems.map((item, ind) => {
                                                if (!item.isLast) {
                                                    return (
                                                        <Col lg="3" key={ind}>
                                                            <MainSkyBox>
                                                                <h4>
                                                                    {
                                                                        item.heading
                                                                    }
                                                                </h4>
                                                                <MainPara>
                                                                    {item.para}
                                                                </MainPara>
                                                            </MainSkyBox>
                                                        </Col>
                                                    );
                                                } else {
                                                    return (
                                                        <Col lg="3" key={ind}>
                                                            <MainSkyBoxLast>
                                                                <h4>
                                                                    {
                                                                        item.heading
                                                                    }
                                                                </h4>
                                                                <MainPara>
                                                                    {item.para}
                                                                </MainPara>
                                                            </MainSkyBoxLast>
                                                        </Col>
                                                    );
                                                }
                                            })}
                                    </Row>
                                </div>
                            </SkyBox>
                        </Col>
                    </Row>
                </Container>
            </SkyContent>

            <div>
                <GlobalDeliveryWrapper id="delivery">
                    <GlobalDelivery
                        mainTitle="Strategic Footprint"
                        mainPara={
                            <Fragment>
                                Our global network of contact centers provides a
                                cost effective and strategic way to outsource
                                your key customer <br />
                                management requirements to a partner with
                                experience in supporting New Economy, Blue Chip,
                                and Fortune 100 companies.
                            </Fragment>
                        }
                    />
                </GlobalDeliveryWrapper>
            </div>
            <IndustriesServed />
        </>
    );
};
const GlobalDeliveryWrapper = styled.section`
    ${Media('xlscreens')} {
        padding: 15vw 0 5vw;
    }
    padding-top: 200px;
`;
const SkyContent = styled.section`
    width: 100%;
    padding-bottom: 0px;
    background-image: url('${baseURL}assets/images/sky-bg.png');
    background-position: 100%;
    background-size: cover;
    background-repeat: no-repeat;
`;

const SkyBox = styled.div`
    background-color: ${colors.white['100']};
    padding: 50px;
    position: relative;
    top: 150px;
    margin-top: 50px;
    ${Media('mobile')} {
        padding: 50px 30px;
    }
`;
const MainSkyBox = styled.div`
    min-height: 185px;
    padding: 0 18px;
    border-right: 1px solid ${colors.gray_dark['20']};
    h4 {
        font-size: 25px;
        margin: 0;
        font-family: ${gilroyBold};
        ${Media('xlscreens')} {
            font-size: 1.2vw;
        }
    }
    ${Media('tablet')} {
        border-right: none;
        border-bottom: 1px solid ${colors.gray_dark['20']};
        min-height: 0;
        padding-bottom: 15px;
        margin-bottom: 30px;
    }
`;
const MainSkyBoxLast = styled(MainSkyBox)`
    border-right: none;
    ${Media('tablet')} {
        border-bottom: none;
        margin-bottom: 0px;
    }
`;
