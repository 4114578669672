import React from 'react';
import colors from 'shared-components/theme/colors';
import { IndustryEbookSection } from 'components/industries/sections';
import { LetsDiscuss } from 'components/industries/common';
import { baseURL } from 'shared-components/theme/helpers';
import { baseURLPage } from 'shared-components/theme/helpers';

export const EbookBottomSec = ({}) => {
    return (
        <>
            <IndustryEbookSection
                bgImage={`${baseURL}assets/images/industries/healthtech-ebook-bg.png`}
                bgColor={colors.ebook.whitepaper['main']}
                color={colors.dark_purple['100']}
                heading={'Happier Patients, <br /> Healthier Bottom Line'}
                para={
                    'Enhancing The Patient Financial Experience <br /> With Improved Payment Services, <br /> Billing Support, And Digital Transformation'
                }
                ebookLink={`${baseURLPage}resources/whitepapers/g/healthcare-digital-patient-experience`}
            />

            <LetsDiscuss
                heading="Looking to level-up your CX and ROI?"
                btnText="Let's Talk."
            />
        </>
    );
};
