import React from 'react';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import ReactHtmlParser from 'react-html-parser';
import {
    SRMSectionHeading,
    SRMFloatingSectionImage,
} from 'components/social-rep-man/common';
import MainPara from 'shared-components/main-para/main-para';
import {
    FlexColumnCentered,
    RowCenteredBoth,
} from 'shared-components/theme/grid-helpers';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';

export const EmpoweredGoldSection = ({ empoweredList, goldList }) => {
    return (
        <>
            <EmpoweredSection id="home">
                <Container>
                    <Row>
                        <Col sm="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <SRMSectionHeading>
                                    Empowered agents create <br />
                                    happy customers
                                </SRMSectionHeading>
                                <MainPara align="center">
                                    ibex Training Simulator is purpose-built to
                                    expedite proficiency and empower the world’s
                                    leading brands. Our enhanced technology
                                    funnels
                                    <br /> our team’s extensive experience
                                    training global agents into one intelligent,
                                    easy-to-use platform.
                                </MainPara>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                    <Row>
                        {empoweredList &&
                            empoweredList !== undefined &&
                            empoweredList.map((item, index) => {
                                return (
                                    <Col sm="12" md="6" key={index}>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={true}>
                                            <EmpoweredBox>
                                                <EmpIcon>
                                                    {ReactHtmlParser(item.icon)}
                                                </EmpIcon>
                                                <EmpContent>
                                                    <MainPara noMargin>
                                                        {ReactHtmlParser(
                                                            item.content
                                                        )}
                                                    </MainPara>
                                                </EmpContent>
                                            </EmpoweredBox>
                                        </ScrollAnimation>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </EmpoweredSection>
            <GoldSection id="empowered">
                <SRMFloatingSectionImage
                    x={[-20, 0]}
                    image={`${baseURL}assets/images/gold-bg-float.png`}
                    width={765}
                    left="-10%"
                    bottom="0"
                />
                <Container>
                    <RowCenteredBoth>
                        <Col sm="12" lg="8">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <SRMSectionHeading color={colors.white['100']}>
                                    The gold-standard in agent training
                                </SRMSectionHeading>
                                <MainPara
                                    align="center"
                                    color={colors.white['100']}>
                                    Developed by the ibex Global Learning
                                    Innovation & Design Team, ibex Training
                                    Simulator uses a proven training process to
                                    accelerate time to proficiency.
                                </MainPara>
                            </ScrollAnimation>
                        </Col>
                    </RowCenteredBoth>
                    <Row noGutters>
                        {goldList &&
                            goldList !== undefined &&
                            goldList.map((item, index) => {
                                return (
                                    <Col sm="12" md="4" key={index}>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={true}>
                                            <GoldBox>
                                                <GoldIcon>
                                                    {ReactHtmlParser(item.icon)}
                                                </GoldIcon>
                                                <GoldContent>
                                                    <MainPara
                                                        noMargin
                                                        align="center"
                                                        color={
                                                            colors.white['100']
                                                        }>
                                                        {ReactHtmlParser(
                                                            item.content
                                                        )}
                                                    </MainPara>
                                                </GoldContent>
                                            </GoldBox>
                                        </ScrollAnimation>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </GoldSection>
        </>
    );
};
const EmpoweredSection = styled.section`
    position: relative;
`;

const EmpoweredBox = styled.div`
    display: flex;
    width: 92%;
    margin-top: 40px;
    ${Media('xlscreens')} {
        margin-top: 3vw;
    }
`;

const EmpIcon = styled.div`
    flex: 0 0 100px;
    max-width: 100px;
    svg {
        width: 50px;
    }
    ${Media('xlscreens')} {
        flex: 0 0 5.2vw;
        max-width: 5.2vw;
        svg {
            width: 2.6vw;
        }
    }
`;
const EmpContent = styled.div`
    flex: 1 0;
`;
const GoldSection = styled.section`
    background-color: ${colors.socialRep['brandsBG']};
    position: relative;
    z-index: 10;
    overflow-x: hidden;
    ${Media('xlscreens')} {
        padding: 8vw 0 5vw;
    }
    ${Media('desktopscreens')} {
        padding: 140px 0 80px;
    }
`;

const GoldBox = styled(FlexColumnCentered)`
    background-color: ${colors.socialRep['brandsBG']};
    border: 1px solid ${colors.white['20']};
    margin: 40px -1px 0;
    padding: 50px 30px;
    p {
        line-height: 1.778;
    }
    ${Media('xlscreens')} {
        padding: 0 2vw;
        height: 18.85vw;
        margin-top: 3vw;
    }
    ${Media('tablet')} {
        min-height: 320px;
    }
    ${Media('mobile')} {
        min-height: 1px;
    }
`;

const GoldIcon = styled.div`
    margin-bottom: 30px;
    min-height: 58px;
    display: flex;
    align-items: center;
    ${Media('xlscreens')} {
        margin-top: 1vw;
        margin-bottom: 3vw;
        svg {
            height: 100%;
            max-width: 4.6vw;
        }
    }
`;

const GoldContent = styled.div``;
