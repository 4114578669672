import React from 'react';
import styled from 'styled-components';
import { Col, Row } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    ContainerWideSRM,
    SRMSectionHeading,
} from 'components/social-rep-man/common';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import MainPara from 'shared-components/main-para/main-para';
import { PagePara } from 'pages/technology/scorecard/style';
import { RowCenteredBoth } from 'shared-components/theme/grid-helpers';
import VerticalCards from 'components/social-rep-man/vertical-cards';
import colors from 'shared-components/theme/colors';

export const ScoreManageSection = ({ data }) => {
    return (
        <ManageSection id="manage">
            <ContainerWideSRM>
                <Row>
                    <Col sm="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <SRMSectionHeading>
                                Easily Plan, Track, and Inspire Agent
                                Performance
                            </SRMSectionHeading>
                        </ScrollAnimation>
                    </Col>
                </Row>
                <RowCenteredBoth>
                    <Col sm="12" lg="9">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <PagePara>
                                <MainPara align="center">
                                    Inspire is built to simplify the coaching
                                    process, enabling supervisors and managers
                                    to quickly track, measure, and act on agent
                                    performance needs, ensuring that every
                                    client program is agile, up-to-speed, and
                                    ready to deliver continuous results.
                                </MainPara>
                            </PagePara>
                        </ScrollAnimation>
                    </Col>
                </RowCenteredBoth>

                {data &&
                    data.length > 0 &&
                    data.map((item, index) => {
                        return (
                            <ManageCards key={index}>
                                <VerticalCards
                                    ImageRight={item.ImageRight}
                                    ImageRightColEqual={item.ImageRight}
                                    padTopSmall={item.padTopSmall}
                                    CardTagImg={item.CardTagImg}
                                    VerticalCardHeading={
                                        item.VerticalCardHeading
                                    }
                                    VerticalCardParagraph={
                                        item.VerticalCardParagraph
                                    }
                                    VerticalCardImage={item.VerticalCardImage}
                                    sideExtraSmall
                                />
                            </ManageCards>
                        );
                    })}
            </ContainerWideSRM>
        </ManageSection>
    );
};

const ManageSection = styled.section`
    background-color: ${colors.gray_light['100']};
    ${Media('xlscreens')} {
        padding-top: 7vw;
    }
`;

const ManageCards = styled.div`
    p {
        max-width: 75%;
        font-family: ${gilroyMedium};
    }
    ${Media('tablet')} {
        p {
            max-width: 100%;
        }
    }
`;
