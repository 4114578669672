import React, { Fragment } from 'react';
import LazyLoad from 'react-lazyload';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import CaseStudyIntro from 'components/case-study/case-study-intro';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import Media from 'shared-components/theme/medias';
import BigParagraph from 'components/case-study/case-big-paragraph';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import StatsBox from 'components/case-study/case-stats-box';
import WhoTheyAre from 'components/case-study/case-who-they-are2';
import QuoteBox from 'components/case-study/case-quotebox2';
import SolutionBox from 'components/case-study/case-solution-box';
import ProductGoals from 'components/case-study/case-product-goals';
import BottomSection from 'components/case-study/case-bottom-section2';

import { gilroySemibold } from 'shared-components/theme/fonts';
import Loader from 'components/loader/loader';
import { baseURL } from 'shared-components/theme/helpers';

const ThreadBeast = () => {
    const scrollNavData = [
        {
            name: 'home',
            target: 'home',
            offset: -100,
        },
        {
            name: 'contentText',
            target: 'contentText',
            offset: -100,
        },
        {
            name: 'whotheyAre',
            target: 'whotheyAre',
            offset: -100,
        },
        {
            name: 'solutionBox',
            target: 'solutionBox',
            offset: -100,
        },
        {
            name: 'goalSection',
            target: 'goalSection',
            offset: -200,
        },
        {
            name: 'bottomSection',
            target: 'bottomSection',
            offset: -100,
        },
    ];
    const productGoalsData = [
        {
            heading: 'Product used',
            data: [
                {
                    img: `${baseURL}assets/images/ad.svg`,
                    desc: 'Ads',
                },
                {
                    img: `${baseURL}assets/images/video.svg`,
                    desc: 'Video',
                },
                {
                    img: `${baseURL}assets/images/carousel.svg`,
                    desc: 'Carousel',
                },
                {
                    img: `${baseURL}assets/images/people.svg`,
                    desc: 'Audience Insights',
                },
                {
                    img: `${baseURL}assets/images/feather-target.svg`,
                    desc: 'Custom Audiences',
                },
                {
                    img: `${baseURL}assets/images/equals.svg`,
                    desc: 'Lookalike Audiences',
                },
            ],
        },
        {
            heading: 'Goals',
            data: [
                {
                    img: `${baseURL}assets/images/add-circle.svg`,
                    desc: 'Raise Brand Awareness',
                },
                {
                    img: `${baseURL}assets/images/add-to-queue.svg`,
                    desc: 'Drive Online Sales',
                },
            ],
        },
    ];
    const stats = [
        {
            statsNumber: '33%',
            statsPara: 'decrease in <br />average cost per <br />registration',
        },
        {
            statsNumber: '15%',
            statsPara: 'decrease <br />in cost <br />per action',
        },
        {
            statsNumber: '36%',
            statsPara: 'increase in <br />click-through <br />rate',
        },
        {
            statsNumber: '75%',
            statsPara: 'decrease in <br />cost per <br />video view',
        },
    ];
    return (
        <Layout page="digital">
            <Breadcrumbs>
                <li>Digital</li>
                <li>ThreadBeast Case Study</li>
            </Breadcrumbs>
            <CaseStudyIntro
                introHeading="Subscription streetwear influencer."
                introPara="The men’s streetwear subscription brand partnered with ibex.digital on a video ad campaign featuring Facebook influencers, achieving a 36% increase in its click-through rate and a 33% decrease in cost per registration."
                headingImage={`${baseURL}assets/images/threadbeast-icon.svg`}
                introImage={`${baseURL}assets/images/wear-your-best-threadBeast.jpg`}
                paddingLeft={true}
            />
            <BigTextSection>
                <BigText id="bigText">
                    <Parallax x={['-110%', '100%']}>
                        <OneText id="bigTextOne">Video & the</OneText>
                    </Parallax>
                    <Parallax x={['8%', '-53%']}>
                        <TwoText id="bigTextTwo">ThreadBeast</TwoText>
                    </Parallax>
                </BigText>
            </BigTextSection>

            
                <ContentSectionArea id="contentText">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <BigParagraph
                                    margin={true}
                                    bigParagraph="ThreadBeast wanted to drive brand awareness by partnering with influencers who would share their excitement and feedback on Facebook, thereby encouraging others to do the same. They partnered with ibex Digital to launch & manage their video ad campaign across Facebook."
                                />
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row noGutters={true}>
                            {stats &&
                                stats.map((stat, index) => (
                                    <Col lg="3" key={index}>
                                        <StatsBox
                                            statsNumber={stat.statsNumber}
                                            statsPara={stat.statsPara}
                                        />
                                    </Col>
                                ))}
                        </Row>
                    </Container>
                </ContentSectionArea>

            
                <ScrollWrapper id="whotheyAre">
                    <WhoTheyAre
                        id="whotheyAre"
                        tagLine="Who they be"
                        contentHeading="Curating a modern wardrobe."
                        contentParagraph="Launched in 2015, ThreadBeast is a subscription-based men’s streetwear apparel company. The brand’s style assistants work with trendy and influential fashion lines to deliver modern, affordable wardrobe pieces."
                        contentImage={`${baseURL}assets/images/threadbeast-who-they-be.jpg`}
                    />
                </ScrollWrapper>

                <QuoteBox
                    quotePara="Facebook is the driving force for our online customer acquisition strategy. The ability to segment, target, test and expand the reach of our ads with new ad types, throughout the full marketing funnel, has accelerated growth for our business. Integral to this is the tracking and reporting that Facebook provides and the strategic guidance of our agency, ibex.digital."
                    quoteName="John Stevens,"
                    quoteDesignation="co-founder, ThreadBeast"
                    offsetMinus={false}
                    paddingTop={true}
                />

                <ScrollWrapper id="solutionBox">
                    <SolutionBox
                        imageStyleRight={true}
                        solutionTagLine="Their Solution"
                        solutionHeading="Unboxing conversions."
                        solutionImage={`${baseURL}assets/images/threadbeast-solutionbox.jpg`}
                        solutioParagraph={
                            <Fragment>
                                ThreadBeast launched a month-long awareness
                                campaign that used video ads to showcase its
                                service. The brand partnered with streetwear
                                influencers to create a series of “unboxing”
                                videos, which were optimized for mobile and
                                produced using Facebook’s Power Editor. Each
                                video showed an influencer opening his
                                ThreadBeast package and raving about its
                                contents—everything from shorts and shoes to
                                t-shirts, hats and sunglasses.
                                <br />
                                <br />
                                To ensure it reached the right audience, ibex
                                Digital targeted its video ads to men in the US
                                aged 18–24 with interests in hip hop and
                                streetwear brands. It also created a Custom
                                Audience based on email signups and people who
                                completed its style survey. They then created
                                lookalike audiences based on current and former
                                customers.
                                <br />
                                <br />
                                Following this initial awareness campaign, the
                                team targeted a series of carousel ads to people
                                who watched the videos but did not make a
                                purchase or complete the ThreadBeast style
                                survey. The carousel ads further encouraged
                                people to visit the website and fill out the
                                style survey.
                            </Fragment>
                        }
                    />
                </ScrollWrapper>

            
                <ScrollWrapper id="goalSection">
                    <ProductGoals
                        productGoalsData={productGoalsData}
                        noPadding={true}
                    />
                </ScrollWrapper>

            
                <ScrollNav data={scrollNavData} />
                <ScrollWrapper id="bottomSection">
                    <BottomSection
                        leftHeading="Signing them up"
                        withoutOffset={true}
                        leftPara="ThreadBeast’s style-savvy video ad campaign was not only cost-effective—it also increased customer engagement and registrations. The campaign, which ran through July 2016, also achieved:"
                        rightList={[
                            '33% decrease in average cost per registration',
                            '15% decrease in cost per action',
                            '36% increase in click-through rate',
                            '75% decrease in cost per video view',
                            '3.4 million people reached',
                        ]}
                    />
                    <QuoteBox
                        quotePara="The Facebook pixel enables us to serve the right ads 
                        at the key points along the consumer’s journey to 
                        purchase. Whether it’s for a new startup looking for 
                        support with their go-to-market strategy or an 
                        established brand looking to scale their efforts, we 
                        use Facebook as an integral part of our strategy for 
                        both our clients and ourselves."
                        quoteName="Sanaz Limouee,"
                        quoteDesignation="co-founder, ibex.digital"
                        offsetMinus={true}
                    />
                </ScrollWrapper>
        </Layout>
    );
};
const BigTextSection = styled.section`
    position: relative;
    padding-top: 0;
    margin-top: -350px;
    ${Media('xlscreens')} {
        margin-top: -18vw;
    }
`;
const BigText = styled.div`
    font-size: 140px;
    font-family: ${gilroySemibold};
    overflow: hidden;
    line-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    span {
        white-space: nowrap;
        position: relative;
    }
    ${Media('xlscreens')} {
        width: 100%;
        font-size: 15.6vw;
        line-height: 0.82;
    }
`;
const OneText = styled.span`
    display: inline-flex;
    align-self: flex-start;
    left: -2%;
`;

const TwoText = styled.span`
    display: inline-flex;
    align-self: flex-start;
    left: 35%;
`;
const ContentSectionArea = styled.section`
    padding-top: 0;
    ${Media('tablet')} {
        padding: 0px;
    }
`;
const ScrollWrapper = styled.div``;
export default ThreadBeast;
