import React from 'react';
import Layout from 'components/layout';
import {
    BottomSection,
    TechPage,
    WaveXIntro,
    SubIntro,
} from 'components/technology/wavex';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { baseURL, baseURLPage } from 'shared-components/theme/helpers';
import styled from 'styled-components';

const performancePages = [
    {
        title: 'Social Reputation Management',
        para:
            'Our all-in-one social engagement platform allows you to centralize, answer, and influence online feedback & reviews from 90+ sites.',
        pageLink: `${baseURLPage}digital/social-reputation-management`,
        thumbImg: `${baseURL}assets/images/tech/social-repman-tech.svg`,
        rowReverse: false,
    },
    {
        title: 'Survey & Feedback Management',
        para:
            'An industrial strength CX survey platform for nimble survey delivery, end-to-end feedback analytics, real-time issue resolution, and frictionless customer experience management, at scale.',
        pageLink: `${baseURLPage}technology/reflecx`,
        thumbImg: `${baseURL}assets/images/tech/reflecx-tech.png`,
        rowReverse: true,
    },
    {
        title: 'Cloud CRM',
        para:
            'Flecx gives your @home or in-center agents the power of the industry’s most advanced cloud platform for managing and improving customer interactions.',
        pageLink: `${baseURLPage}technology/flecx`,
        thumbImg: `${baseURL}assets/images/tech/cloud-crm-tech.svg`,
        rowReverse: false,
    },
];

const OmniChannelSuiteWaveX = () => {
    return (
        <Layout page="wavex">
            <Breadcrumbs>
                <li>Technology</li>
                <li>WaveX</li>
            </Breadcrumbs>
            <WaveXIntro
                pageTag="Wave X"
                pageHeading="The Omnichannel <br/>CX Engagement Suite"
                pagePara="Build surveys, protect your social reputation, and manage <br/>customer interactions in the cloud."
                introImg={`${baseURL}assets/images/tech/wavex-omnichannel-img.png`}
                gradientImg={`${baseURL}assets/images/tech/wavex-omnichannel.png`}
                imgHeight={'555'}
                imgWidth={'551'}
                from={{
                    transform: 'translateY(0) scale(1)',
                }}
                to={{
                    transform: 'translateY(18vw) scale(1.7)',
                }}
            />
            <SubIntro
                imgURL={`${baseURL}assets/images/tech/wavex-omnichannel-img.png`}
                subHeading={
                    'Complete Customer Experience Management, <br />From Anywhere.'
                }
            />
            <TechPages>
                {performancePages &&
                    performancePages.length > 0 &&
                    performancePages.map((page, index) => {
                        return (
                            <TechPage
                                key={index}
                                $reverse={page.rowReverse}
                                title={page.title}
                                para={page.para}
                                thumbImg={page.thumbImg}
                                pageLink={page.pageLink}
                            />
                        );
                    })}
            </TechPages>
            <BottomSection
                heading="Ready to Upgrade Your CX?"
                subHeading="Performance gamification technology designed to incentivize agent <br/>performance for enhanced CX outcomes."
            />
        </Layout>
    );
};
const TechPages = styled.div``;
export default OmniChannelSuiteWaveX;
