import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import { UtilPagePara, UtilStepHeading, UtilTag } from '../common';
import MainHeading from 'shared-components/main-heading/main-heading';
import colors from 'shared-components/theme/colors';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import { gilroySemibold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

const programs = [
    {
        image: `${baseURL}assets/images/step-four-img-1.jpg`,
        title: 'eCommerce Capabilities',
        desc:
            'Digital marketplace letting your customers compare offers, buy online or with help, and return for repeat visits or new promotions',
    },
    {
        image: `${baseURL}assets/images/step-four-img-2.jpg`,
        title: 'Promotion Outreach',
        desc:
            'Useful content online and via email to entertain and promote important topics like energy efficiency, rebates and promotions, or special offers',
    },
    {
        image: `${baseURL}assets/images/step-four-img-3.jpg`,
        title: 'Lifecycle Support',
        desc:
            'Nurture campaigns to maintain a connection with customers after the move and supporting adoption of important programs like online bill payment, outage and storm alerts, and special promotions and rebates.',
    },
    {
        image: `${baseURL}assets/images/step-four-img-4.jpg`,
        title: 'Dynamic Catalogs',
        desc:
            'New products and services or special offers available exclusively from the utility gives customers a reason to stay connected, open emails, and return to the marketplace.',
    },
    {
        image: `${baseURL}assets/images/step-four-img-5.jpg`,
        title: 'Referral Programs',
        desc:
            'Great customers promote the brands they value, so offer customers an opportunity to share great experiences in their social circles and be ambassadors for your utility brand.',
    },
];

export const UtilStepFourSection = () => {
    return (
        <>
            <UtilStepFour id="step_four">
                <Container>
                    <Row>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <UtilStepHeading>
                                    <UtilTag>04</UtilTag>
                                    <MainHeading noMargin={true}>
                                        After the Move and <br /> Beyond the
                                        Meter
                                    </MainHeading>
                                </UtilStepHeading>
                                <UtilPagePara>
                                    A great first customer experience sets the
                                    stage for lasting customer relationships
                                    across multiple interactions along the
                                    customer journey; make sure to select a
                                    partner who amplifies the value of your
                                    utility brand and provides a digital
                                    foundation for future encounters. At ibex,
                                    we create utility ecosystems to support
                                    ongoing customer engagement, so look for
                                    programs that include the following:
                                </UtilPagePara>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <StepFourBoxWrap>
                                {programs && programs.length > 0
                                    ? programs.map((item, index) => {
                                          return (
                                              <ScrollAnimation
                                                  animateIn="fadeInUp"
                                                  animateOnce={false}
                                                  delay={`${index + 2}00`}
                                                  key={index}>
                                                  <StepFourBox>
                                                      <StepFourImg>
                                                          <img
                                                              src={item.image}
                                                              alt="-"
                                                          />
                                                      </StepFourImg>
                                                      <h4>{item.title}</h4>
                                                      <p>{item.desc}</p>
                                                  </StepFourBox>
                                              </ScrollAnimation>
                                          );
                                      })
                                    : null}
                            </StepFourBoxWrap>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <UtilPagePara>
                                    <span>
                                        It takes just one thoughtful interaction
                                        to kick-off a world of new valuable
                                        opportunities.
                                    </span>
                                    &nbsp;Utility customers continue to demand
                                    more and more from the brands they trust and
                                    value, and a rewarding first experience at
                                    the new energy start can set up a great
                                    relationship between utilities and their
                                    customers. A mover program that helps
                                    customers solve some of the typical moving
                                    problems merely meets customer expectations.
                                    A New Connection program that solves those
                                    problems, delivers unexpected special offers
                                    and values, and amplifies the value of the
                                    utility brand ensures the “WoW” experience
                                    that shows customers how much you value
                                    their engagement.
                                </UtilPagePara>
                                <UtilPagePara>
                                    With today’s technology and partnership
                                    opportunities, its easier than ever to
                                    deliver a world class new customer
                                    experience. Not only will you deliver new
                                    value add services to your customer, but
                                    those new customer relationships pay
                                    dividends in loyalty, CSAT and revenue
                                    enhancements for the lifetime of the
                                    customer.
                                </UtilPagePara>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </UtilStepFour>
            <WhyWrapper>
                <Container>
                    <Row>
                        <Col lg="6">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <WhyHead>
                                    <MainHeading
                                        noMargin={true}
                                        color={colors.white['100']}>
                                        Why ibex?
                                    </MainHeading>
                                </WhyHead>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="6">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <UtilPagePara color={colors.white['100']}>
                                    ibex.Digital works with some of the world’s
                                    most trusted brands to revolutionize the way
                                    you connect with your customers.
                                    Specializing in digital engagement, our team
                                    delivers solutions – across the entire
                                    lifecycle – that amplify the value of your
                                    brand after the move and beyond the meter.
                                    From connecting the home with needed
                                    services and engaging the customer across
                                    predictable touchpoints, ibex.Digital
                                    delivers products and services like branded
                                    e-commerce, lifecycle outreach and tools to
                                    enhance the Voice of the Customer. We help
                                    our utility clients address the increasing
                                    demands of customers through a world-class
                                    utility customer concierge experience.
                                </UtilPagePara>
                                <UtilPagePara color={colors.white['100']}>
                                    <span>
                                        To learn more about our Utility
                                        solutions or meet our leadership team,
                                        please connect with us on
                                        www.ibex.co/utilities
                                    </span>
                                </UtilPagePara>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </WhyWrapper>
        </>
    );
};

const UtilStepFour = styled.section`
    ${Media('xlscreens')} {
        padding: 7vw 0 5vw;
    }
`;

const StepFourBoxWrap = styled(FlexCentered)`
    flex-wrap: wrap;
    ${Media('xlscreens')} {
        padding: 5vw 0;
    }
`;

const StepFourImg = styled.div`
    overflow: hidden;
    height: 240px;
    img {
        width: 100%;
        height: 100%;
        transform: scale(1);
        transition: all 0.3s ease;
    }
    ${Media('xlscreens')} {
        height: 12.5vw;
    }
`;

const StepFourBox = styled.div`
    background-color: ${colors.white['100']};
    border: 1px solid ${colors.gray_dark['50']};
    margin-right: 20px;
    margin-bottom: 20px;
    max-width: 330px;
    min-height: 500px;
    h4 {
        font-size: 24px;
        font-family: ${gilroySemibold};
        letter-spacing: -0.5px;
        margin: 15px 0 30px;
    }
    p {
        font-size: 14px;
        opacity: 0.7;
        line-height: 1.6;
        margin-bottom: 0px;
    }
    &:hover {
        ${StepFourImg} {
            img {
                transform: scale(1.1);
            }
        }
    }
    ${Media('xlscreens')} {
        padding: 0 3vw 3vw;
        margin-right: 1.3vw;
        margin-bottom: 1.3vw;
        max-width: 20.8vw;
        min-height: 28.9vw;
        h4 {
            font-size: 1.4vw;
            letter-spacing: -0.07vw;
            margin: 1.6vw 0 2vw;
        }
        p {
            font-size: 0.82vw;
        }
    }
    ${Media('tablet')} {
        max-width: 325px;
        padding: 0px 30px 30px;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    ${Media('mobile')} {
        width: 100%;
        max-width: 100%;
        margin-right: 0px;
        min-height: 1px;
    }
`;

const WhyWrapper = styled.section`
    background-color: ${colors.gray_dark['100']};
    ${Media('xlscreens')} {
        padding: 7.5vw 0 5vw;
    }
`;

const WhyHead = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    ${Media('xlscreens')} {
        padding-right: 7vw;
        margin-top: -10px;
    }
    ${Media('tablet')} {
        padding-right: 0;
        margin-top: 0px;
        justify-content: flex-start;
        margin-bottom: 30px;
    }
`;
