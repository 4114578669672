import React, { useEffect, useState, useRef } from 'react';
import Layout from 'components/layout';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import {
    gilroyBold,
    gilroySemibold,
    gilroyBlack,
    akiraSuperBold,
    gilroyMedium,
    gilroyRegular,
} from 'shared-components/theme/fonts';
import Button from 'shared-components/button/button';
import colors from 'shared-components/theme/colors';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { baseURL, baseURLPage } from 'shared-components/theme/helpers';
import { FaAngleDown } from 'react-icons/fa';
import { Link } from 'react-scroll';
import Media from 'shared-components/theme/medias';
import { LetsDiscuss } from 'components/industries/common';
import ScrollAnimation from 'react-animate-on-scroll';

const ESG = () => {
    const [showStickyMenu, setShowStickyMenu] = useState(false);
    const firstSection = useRef(null);

    const stickyMenu = [
        {
            title: 'Environment',
            linkRef: 'environment',
        },
        {
            title: 'Social',
            linkRef: 'social',
        },
        {
            title: 'Governance',
            linkRef: 'governance',
        },
    ];
    const sustainOperations = [
        {
            title: 'Education',
            icon: `${baseURL}assets/images/esg-list-icon1.svg`,
            para:
                'ibex is passionate about reducing the number of resources we consume across our global operations. As such, we are committed to educating our staff and establishing routine procedures designed to minimize our impact on the environment.',
        },
        {
            title: 'Global Temperature Standards',
            icon: `${baseURL}assets/images/esg-list-icon2.svg`,
            para:
                'In 2022, ibex adopted global temperature standards in all our physical locations around the world. We require an office temperature of 70-72 degrees F, in accordance with recommendations from Helsinki University of Technology’s Laboratory for Heating, Ventilating and Air Conditioning.',
        },
        {
            title: 'Transportation Initiatives',
            icon: `${baseURL}assets/images/esg-list-icon3.svg`,
            para:
                'ibex promotes shared/sustainable transportation in all major markets.  Each employee that takes advantage of shared transportation, for an average 20 miles round trip commute to the office, can reduce the carbon footprint by 4,800 lbs. annually.',
        },
        {
            title: 'LED Light Conversion',
            icon: `${baseURL}assets/images/esg-list-icon4.svg`,
            para:
                'ibex is actively converting all our fluorescent bulbs to LED lights throughout our footprint. We began this initiative in 2021 by converting every light in the Philippines. Other regions will continue to pursue this change in process throughout 2022-2023.',
        },
        {
            title: 'Environmental Certifications',
            icon: `${baseURL}assets/images/esg-list-icon5.svg`,
            para:
                'Across Jamaica, Nicaragua, and the Philippines ibex is driving innovation across its sites. Our Alabang, Invercasa, and Waterfront sites boast various environmental building certifications. These locations, in 3 separate regions, are pioneers in the sustainability sector and serve as flagship sites.',
        },
        {
            title: 'Waste Reduction',
            icon: `${baseURL}assets/images/esg-list-icon6.svg`,
            para:
                'We understand that reducing waste is a fundamental component of any ESG plan. In compliance with our PCI DSS certification, our work environments are paperless, and we are installing recycle bins across our network of contact center sites.',
        },
        {
            title: 'Work From Home',
            icon: `${baseURL}assets/images/esg-list-icon7.svg`,
            para:
                'ibex plans to sustain 20-35% of our work from home workforce permanently. Maintaining a virtual component of the business results in a reduction in commute time and CO2 emissions. Offering work at home opportunities also reduces infrastructure needs, utility costs/usage and equipment costs.',
        },
        {
            title: 'Green Cleaning Products',
            icon: `${baseURL}assets/images/esg-list-icon8.svg`,
            para:
                'Our teams will be converting to all green cleaning supplies by 2023.  According to the EPA (Environmental Protection Agency), green cleaning products are less hazardous for the environment. These safe, non-toxic, and biodegradable products are eco-friendly and help fight ozone depletion and climate change.',
        },
        {
            title: 'Equipment Donation',
            icon: `${baseURL}assets/images/esg-list-icon9.svg`,
            para:
                'In an effort to reduce waste by reuse, ibex has plans to donate unused equipment to non-profit organizations. Not only does this enable ibex to make a positive contribution to our communities, it also enables us to reduce our environmental footprint.',
        },
    ];
    const socialDiversity = [
        {
            title: 'Women of ibex',
            para:
                'Our Women of ibex group was created to increase the opportunities for women at the executive level within the company, further the impact of women across the organization, and create a visibly gender diversified culture.',
        },
        {
            title: 'Global Mentorship Initiative',
            para:
                'Through our partnership with Global Mentorship Initiative, ibex aims to give back by helping underserved college students and refugees from all over the world reach their employment goals.',
        },
        {
            title: 'ibex Siren',
            para:
                'Each year, LGBTQIA+ employees are invited to compete to be the ibex Siren. This prestigious and elegant pageant has become a solid platform for various advocacies ranging from anti-discrimination to AIDS awareness throughout the years.',
        },
    ];
    const philanthophies = [
        [
            { name: 'Cruz Roja', href: 'https://cruzrojanicaraguense.org/' },
            {
                name: 'Raleigh Country Humane Society',
                href: 'https://hsrcwv.org/',
            },
            {
                name: 'Relay for Life',
                href:
                    'https://secure.acsevents.org/site/SPageServer?pagename=relay_learn',
            },
            {
                name: 'Airman’s Attic',
                href: 'https://www.facebook.com/AirmansAtticLAK',
            },
            { name: 'Center of Hope' },
            { name: 'Jamaica schools', href: 'https://moey.gov.jm/' },
            {
                name: 'Feed America',
                href:
                    'https://secure.feedingamerica.org/site/Donation2?df_id=30411&30411.donation=form1&s_src=Y22YP1H1Z&s_subsrc=c&s_keyword=feeding%20america&gclid=CjwKCAjwiJqWBhBdEiwAtESPaGm9UncNlhS7JXOJHOn6t3S_GM1cuF0-op4nFWFXko0Mp4YacTIVthoCdq0QAvD_BwE&gclsrc=aw.ds',
            },
            {
                name: 'St. Jude Children’s Research Hospital',
                href: 'https://www.stjude.org/',
            },
            { name: 'Make a Wish Foundation', href: 'https://wish.org/' },
            {
                name: 'Save the Children',
                href: 'https://www.savethechildren.org/',
            },
            { name: 'Akhuwat Foundation', href: 'https://akhuwat.org.pk/' },
        ],
        [
            {
                name: 'Strathmore Gardens Children’s Home La Prensa',
                href:
                    'https://greatnonprofits.org/org/la-prensa-foundation-inc',
            },
            {
                name: 'Philippines Children’s Hospital',
                href: 'http://www.pcmc.gov.ph/',
            },
            {
                name: 'Project Teach Mandaluyong',
                href:
                    'https://www.facebook.com/Mandaluyong-CARES-Project-TEACH-153486504701083/',
            },
            {
                name: 'Tahnan ng Pagmamahal Children’s Home',
                href: 'https://tahananngpagmamahal.org/',
            },
            { name: 'Doljo Elementary School', href: '' },
            {
                name: 'Gentle Hands Orphanage',
                href: 'https://gentlehandsorphanages.com/',
            },
            { name: 'Citizen’s Foundation', href: 'https://www.tcf.org.pk/' },
            {
                name: 'Hogar de Ancianos',
                href:
                    'https://www.nia.nih.gov/espanol/establecimientos-residenciales-centros-vida-asistida-asilos-ancianos',
            },
            { name: 'Jamaica Cancer Society', href: 'https://jcs.live/' },
        ],
        [
            { name: 'Deaf Reach', href: 'https://www.deafreach.com/' },
            {
                name: 'Just for Kids Child Advocacy Center',
                href: 'https://jfkwv.com/',
            },
            { name: 'Urban Impact', href: 'https://uifpgh.org/' },
            { name: 'United Way', href: 'https://www.unitedway.org/' },
            { name: 'Asedeme', href: 'https://www.asedeme.org/' },
            {
                name: 'Ronald McDonald House',
                href:
                    'https://support.rmhc.org/site/SPageNavigator/pw/Donation_Landing.html?s_src=paid&s_subsrc=22AOPMsearch&gclid=CjwKCAjwiJqWBhBdEiwAtESPaEatM__LGOAGEV1b_0Vdv8NhMgDk2wfeDMdrwfwI_O6YGFXBh5ZRDRoCCnQQAvD_BwE&gclsrc=aw.ds',
            },
            {
                name: 'Central Oregon Veteran Outreach',
                href: 'https://covo-us.org/',
            },
            {
                name: 'Sojourners Women’s and Children’s Shelter',
                href: 'https://www.sojournerhome.org/',
            },
            {
                name: 'Alzheimer’s Association',
                href:
                    'https://act.alz.org/site/Donation2?df_id=32112&32112.donation=form1&utm_source=google&utm_medium=paidsearch&utm_campaign=google_giving&s_subsrc=giving&gclid=CjwKCAjwiJqWBhBdEiwAtESPaE3DG48ITGRrVpHCRdH_cS-MPUuaRxMqkE5XM9Lup3m405KfUlEEuBoCJzkQAvD_BwE',
            },
            { name: 'Mandaluyong C.A.R.E.S.', href: '' },
        ],
    ];

    useEffect(() => {
        window.onscroll = function () {
            var elemOffset =
                firstSection.current.offsetTop +
                document.body.getBoundingClientRect().top;
            if (elemOffset <= 100) {
                setShowStickyMenu(true);
            } else {
                setShowStickyMenu(false);
            }
        };
    }, []);

    return (
        <Layout>
            <StickyMenuWrapper showStickyMenu={showStickyMenu}>
                <StickyMenu>
                    <StickyMenuItem
                        bgColor={colors.white['100']}
                        borderLeft
                        menuTitle>
                        <MenuTitle>Navigation Menu</MenuTitle>
                        <MenuIcon>
                            <img
                                src={`${baseURL}assets/images/icon-ionic-ios-arrow-forward.svg`}
                                alt="-"
                            />
                        </MenuIcon>
                    </StickyMenuItem>
                    {stickyMenu &&
                        stickyMenu.length > 0 &&
                        stickyMenu.map((menu, index) => {
                            return (
                                <StickyMenuItem key={index}>
                                    <Link
                                        to={menu.linkRef}
                                        spy={true}
                                        smooth={true}
                                        activeClass="active"
                                        offset={-100}
                                        duration={1000}>
                                        <MenuTitle>{menu.title}</MenuTitle>
                                    </Link>
                                </StickyMenuItem>
                            );
                        })}
                </StickyMenu>
            </StickyMenuWrapper>
            <Breadcrumbs>
                <li>About Us</li>
                <li>ESG</li>
            </Breadcrumbs>
            <Container>
                {/* ESG Intro */}
                <ESGIntro>
                    <Row>
                        <Col lg="7">
                            <div>
                                <IntroHeading>
                                    ESG <br />
                                    Environment, Social, & Governance
                                </IntroHeading>
                                <IntroPara>
                                    At ibex, we understand that we are
                                    responsible for the footprint we make on the
                                    world. We are committed to adopting and
                                    maintaining business practices that hold us
                                    accountable while leaving the communities we
                                    serve better than we found them.
                                </IntroPara>
                                <Link
                                    to={'ourmission'}
                                    spy={true}
                                    smooth={true}
                                    activeClass="active"
                                    offset={-100}
                                    duration={1000}>
                                    <IntroButton
                                        round
                                        ifButton
                                        bgColor={colors.socialRep['brandsBG']}>
                                        Keep Reading <FaAngleDown />
                                    </IntroButton>
                                </Link>
                            </div>
                        </Col>
                        <CustomCol>
                            <div>
                                <IntroImg intro>
                                    <img
                                        src={`${baseURL}assets/images/esg-intro.png`}
                                        alt="-"
                                    />
                                </IntroImg>
                            </div>
                        </CustomCol>
                    </Row>
                </ESGIntro>

                {/* Our Mission */}
                <OurMission id="ourmission">
                    <SectionSubHeading>Our Mission</SectionSubHeading>
                    <Row>
                        <Col lg="4">
                            <div>
                                <ImgWrapper>
                                    <img
                                        src={`${baseURL}assets/images/esg-our-mission.jpg`}
                                        alt="-"
                                    />
                                </ImgWrapper>
                            </div>
                        </Col>
                        <Col lg={{ offset: 1, size: 7 }}>
                            <div>
                                <EsgPara>
                                    By proactively addressing environmental,
                                    social, and governmental concerns, ibex can
                                    live the core values that drive our
                                    decisions and determine the course for our
                                    organization.
                                </EsgPara>
                                <EsgPara>
                                    Our comprehensive ESG program is built on
                                    the principles of philanthropy, diversity,
                                    inclusion, sustainable operations, and
                                    responsible management of information and
                                    data. These programs are actively managed,
                                    well-appointed, and aligned with our chief
                                    goal to do things the R.I.T.E. Way (Respect.
                                    Integrity. Transparency. Excellence.)
                                </EsgPara>
                            </div>
                        </Col>
                    </Row>
                </OurMission>
            </Container>

            {/* Environment */}
            <Environment id="environment" ref={firstSection}>
                <Container>
                    <div>
                        <SectionHeading>Environment</SectionHeading>
                        <SectionSubHeading>
                            Sustainable Operations
                        </SectionSubHeading>
                    </div>
                    <Row>
                        {sustainOperations &&
                            sustainOperations.length > 0 &&
                            sustainOperations.map((item, index) => {
                                return (
                                    <Col lg="4" key={index} md="6">
                                        <div
                                            
                                            >
                                            <TextBlock>
                                                <BlockIcon>
                                                    <img
                                                        src={item.icon}
                                                        alt="-"
                                                    />
                                                </BlockIcon>
                                                <BlockHeading>
                                                    {item.title}
                                                </BlockHeading>
                                                <EsgPara lineHeight={1.45}>
                                                    {item.para}
                                                </EsgPara>
                                            </TextBlock>
                                        </div>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </Environment>

            {/* Social */}
            <Social id="social">
                <Container>
                    <div>
                        <SectionHeading>Social</SectionHeading>
                        <SectionSubHeading>
                            Diversity, Equity, and Inclusion
                        </SectionSubHeading>
                    </div>
                    <Row>
                        <Col lg="6">
                            <div>
                                <ImgWrapper>
                                    <img
                                        src={`${baseURL}assets/images/esg-social.png`}
                                        alt="-"
                                    />
                                </ImgWrapper>
                            </div>
                        </Col>
                        <Col lg={{ size: 5, offset: 1 }}>
                            <div>
                                <EsgPara>
                                    At ibex, we believe our employees’
                                    individuality and unique abilities form the
                                    DNA of our company. Diverse race, gender
                                    identity, sexual orientation and expression
                                    foster a rewarding mix of creativity and
                                    partnership that translates into a
                                    high-performing, collaborative company.
                                </EsgPara>
                                <EsgPara>
                                    Every day at ibex, we are building programs
                                    that enhance and enrich the lives of our
                                    diverse employees.
                                </EsgPara>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {socialDiversity &&
                            socialDiversity.length > 0 &&
                            socialDiversity.map((item, index) => {
                                return (
                                    <SocialTextBlock
                                        lg="4"
                                        sideSpacing={index === 1}
                                        key={index}>
                                        <div
                                            
                                            >
                                            <TextBlock social>
                                                <BlockHeading>
                                                    {item.title}
                                                </BlockHeading>
                                                <EsgPara lineHeight={1.45}>
                                                    {item.para}
                                                </EsgPara>
                                            </TextBlock>
                                        </div>
                                    </SocialTextBlock>
                                );
                            })}
                    </Row>
                    <ProgressWrap>
                        <DiversityWrapper lg="3">
                            <div>
                                <DiversityPerc>34%</DiversityPerc>
                                <ImgWrapper>
                                    <img
                                        src={`${baseURL}assets/images/esg-diversity-34.jpg`}
                                        alt="-"
                                    />
                                </ImgWrapper>
                                <DiverDesc>
                                    of our directors And above are women
                                </DiverDesc>
                            </div>
                        </DiversityWrapper>

                        <DiversityWrapper lg="3">
                            <div>
                                <DiversityPerc>50%</DiversityPerc>
                                <ImgWrapper>
                                    <img
                                        src={`${baseURL}assets/images/esg-diversity-50.jpg`}
                                        alt="-"
                                    />
                                </ImgWrapper>
                                <DiverDesc>
                                    of our Board of Directors are women or a
                                    minority
                                </DiverDesc>
                            </div>
                        </DiversityWrapper>
                    </ProgressWrap>
                </Container>

                {/* Philanthrophy */}
                <Philanthrophy>
                    <Container>
                        <div
                            
                            >
                            <SectionSubHeading>Philanthropy</SectionSubHeading>
                            <CareLogo>
                                <img
                                    src={`${baseURL}assets/images/ibex-care.svg`}
                                    alt="-"
                                />
                            </CareLogo>
                        </div>
                        <div
                            
                            >
                            <TextBlock>
                                <BlockHeading>Community Outreach</BlockHeading>
                                <EsgPara>
                                    ibex is dedicated to the communities where
                                    we work and live. Our ibex Cares program
                                    supports local charities where the company
                                    operates. In 2022, the program will be
                                    responsible for giving more than $250,000 to
                                    local non-profits and causes. Employees
                                    themselves choose the local charities they
                                    want to support and strive to make a
                                    difference in the lives of the people
                                    closest to them – including friends, family
                                    members and co-workers. ibex is actively
                                    supporting over 20 charities around the
                                    world, including:
                                </EsgPara>
                            </TextBlock>
                        </div>
                        <Philanthrophies>
                            {philanthophies &&
                                philanthophies.length > 0 &&
                                philanthophies.map((item, index) => {
                                    return (
                                        <Col lg="4" key={index}>
                                            <div
                                                
                                                >
                                                {item &&
                                                    item.length > 0 &&
                                                    item.map(
                                                        (
                                                            nestedItem,
                                                            index2
                                                        ) => {
                                                            return (
                                                                <PhilanthropyItem
                                                                    href={
                                                                        nestedItem.href
                                                                    }
                                                                    key={
                                                                        index2
                                                                    }>
                                                                    {
                                                                        nestedItem.name
                                                                    }
                                                                </PhilanthropyItem>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        </Col>
                                    );
                                })}
                        </Philanthrophies>
                    </Container>
                </Philanthrophy>

                {/* Employee Experience */}
                <EmployeeExp>
                    <Container>
                        <div
                            
                            >
                            <SectionSubHeading white>
                                Employee Experience
                            </SectionSubHeading>
                        </div>
                        <Row>
                            <Col lg="12">
                                <YTVideo>
                                    <iframe
                                        src="https://www.youtube.com/embed/rhaiGA8sYoA?rel=0"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                                </YTVideo>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <div
                                    
                                    >
                                    <TextBlock>
                                        <BlockHeading
                                            color={colors.pink['100']}>
                                            ibexology
                                        </BlockHeading>
                                        <EsgPara color={colors.white['100']}>
                                            ibexology is the framework for how
                                            we intend to interact with our
                                            employees. ibex understands that
                                            engagement is the outcome of a total
                                            positive work experience for our
                                            employees. This experience is
                                            defined by relationships between
                                            employees and peers, physical work
                                            environment, health & wellness
                                            programs, diversity and inclusion
                                            initiatives, social opportunities,
                                            rewards, and recognition. While a
                                            positive experience is typically
                                            linked with reduced attrition, it is
                                            a differentiator for our company.
                                            Like Maslow’s Hierarchy of Needs,
                                            ibexology is built from the ground
                                            up on a solid foundation. Each level
                                            accounts for the changing needs that
                                            occur throughout the employee
                                            lifecycle.
                                        </EsgPara>
                                    </TextBlock>
                                </div>
                            </Col>
                            <Col lg="6">
                                <EmployeExpCustRow>
                                    <Col lg="12">
                                        <div
                                            
                                            >
                                            <TextBlock noPaddingRight>
                                                <BlockHeading
                                                    color={colors.pink['100']}>
                                                    Employee Engagement
                                                </BlockHeading>
                                                <EsgPara
                                                    color={colors.white['100']}>
                                                    We make an intentional
                                                    investment in our people and
                                                    that yields results. We
                                                    value loyalty and are
                                                    committed to recognizing our
                                                    people for the work that
                                                    they do. We are governed by
                                                    our core values: Respect,
                                                    Integrity, Transparency,
                                                    Excellence. We care about
                                                    the livelihood of our
                                                    employees personally and
                                                    professionally.
                                                </EsgPara>
                                            </TextBlock>
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <div
                                            
                                            >
                                            <TextBlock noPaddingRight>
                                                <BlockHeading
                                                    color={colors.pink['100']}>
                                                    Global Engagement Calendar
                                                </BlockHeading>
                                                <EsgPara
                                                    color={colors.white['100']}>
                                                    Our framework comes to life
                                                    through intentional
                                                    programming. In order to
                                                    provide a first-class
                                                    employee experience program,
                                                    initiatives have to be well
                                                    appointed, well-funded and
                                                    properly executed. ibex has
                                                    teams of people across the
                                                    organization dedicated to
                                                    the oversight of employee
                                                    engagement initiatives.
                                                </EsgPara>
                                            </TextBlock>
                                        </div>
                                    </Col>
                                </EmployeExpCustRow>
                            </Col>
                        </Row>
                    </Container>
                </EmployeeExp>

                {/* Rewards & Recognition */}
                <RewardRecog>
                    <Container>
                        <div
                            
                            >
                            <SectionSubHeading noMargin>
                                Rewards & Recognition
                            </SectionSubHeading>
                        </div>
                        <Row>
                            <Col lg="4">
                                <div
                                    
                                    >
                                    <ImgWrapper>
                                        <img
                                            src={`${baseURL}assets/images/esg-rewards.jpg`}
                                            alt="-"
                                        />
                                    </ImgWrapper>
                                </div>
                            </Col>
                            <CustomCol lg="4" sidePadding>
                                <div
                                    
                                    >
                                    <TextBlock borderTop noPaddingRight>
                                        <RewardRecogLogo>
                                            <img
                                                src={`${baseURL}assets/images/ibex-awards.png`}
                                                alt="-"
                                            />
                                        </RewardRecogLogo>
                                        <BlockHeading
                                            color={colors.pink['100']}>
                                            ibex Service Awards
                                        </BlockHeading>
                                        <EsgPara>
                                            The ibex Service Awards Program is
                                            intended to reinforce our People
                                            First culture. At ibex we are
                                            committed to recognizing excellence,
                                            passion, and service. This program
                                            allows for a standardized and
                                            consistent process for recognizing
                                            employees based on tenure across the
                                            globe.
                                        </EsgPara>
                                    </TextBlock>
                                </div>
                            </CustomCol>
                            <Col lg="4">
                                <div
                                    
                                    >
                                    <TextBlock borderTop noPaddingRight>
                                        <RewardRecogLogo>
                                            <img
                                                src={`${baseURL}assets/images/ibex-cm-awards.png`}
                                                alt="-"
                                            />
                                        </RewardRecogLogo>
                                        <BlockHeading
                                            color={colors.pink['100']}>
                                            ibex Country Manager Awards
                                        </BlockHeading>
                                        <EsgPara>
                                            This prestigious award goes to
                                            individuals who have had a profound
                                            impact on the success of the
                                            business. Site leaders will select
                                            one outstanding agent and one
                                            outstanding non-agent each month.
                                            These individuals will be recognized
                                            for their success and, in turn,
                                            motivate their peers to strive for
                                            excellence.
                                        </EsgPara>
                                    </TextBlock>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </RewardRecog>
            </Social>

            {/* Governance */}
            <Governance id="governance">
                <Container>
                    <div>
                        <SectionHeading>Governance</SectionHeading>
                        <SectionSubHeading>
                            Governmental Affairs Unit
                        </SectionSubHeading>
                    </div>
                    <div>
                        <EsgPara>
                            Our Government Affairs Unit (GAU) is composed of
                            employees dedicated to deterring fraud, monitoring
                            regulations, guaranteeing compliance, and ensuring
                            uptime. The GAU team ensures that our teams and
                            programs are compliant with the latest regulatory
                            changes.
                        </EsgPara>
                    </div>
                    <div>
                        <EsgPara fontFamily={gilroySemibold}>
                            For more information on ibex’s Governmental Affairs
                            Unit,&nbsp;
                            <a
                                href={`${baseURLPage}governance-in-action`}
                                target="_blank"
                                rel="noopener noreferrer">
                                head here.
                            </a>
                        </EsgPara>
                    </div>
                    <div>
                        <SectionSubHeading marginTop>
                            Policy & Training
                        </SectionSubHeading>
                    </div>
                    <Row>
                        <Col lg="4">
                            <div>
                                <TextBlock>
                                    <BlockHeading noMarginTop>
                                        Employee Handbooks
                                    </BlockHeading>
                                    <EsgPara>
                                        Employee handbooks are published and
                                        acknowledged annually for all regions.
                                        These handbooks clearly outline ibex
                                        policy and procedures.
                                    </EsgPara>
                                </TextBlock>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div>
                                <TextBlock>
                                    <BlockHeading noMarginTop>
                                        Cybersecurity & Compliance Training
                                    </BlockHeading>
                                    <EsgPara>
                                        All ibex employees are required to
                                        complete annual training on the
                                        following topics:
                                    </EsgPara>
                                    <List>
                                        <li>Information Security Awareness</li>
                                        <li>Phishing Awareness</li>
                                    </List>
                                </TextBlock>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div>
                                <List>
                                    <li>Integrity Training</li>
                                    <li>Insider Trading/Policy</li>
                                    <li>Acknowledgement</li>
                                    <li>Anti-Money Laundering</li>
                                    <li>Insider Trading (Managers Above)</li>
                                    <li>HIPAA (Healthcare Verticals)</li>
                                    <li>
                                        GDPR (Accounts servicing EU
                                        customers/access)
                                    </li>
                                </List>
                            </div>
                        </Col>
                    </Row>
                    <div>
                        <SectionSubHeading marginTop>
                            Security & Compliance Certifications
                        </SectionSubHeading>
                    </div>
                    <div>
                        <EsgPara>
                            ibex is proud to hold certifications in PCI-DSS and
                            ALCPA SOC. We are currently pursuing certifications
                            in ISO 27001 and COPC.
                        </EsgPara>
                    </div>
                    <Row>
                        <Col lg="6">
                            <Row>
                                <Col lg="12">
                                    <div
                                        
                                        >
                                        <TextBlock marginTop>
                                            <BlockHeading noMarginTop>
                                                Whistleblower Solutions
                                            </BlockHeading>
                                            <EsgPara>
                                                ibex has robust whistleblower
                                                solutions, including an Open
                                                Door Policy and a 3rd party tool
                                                (Lighthouse) that allows for
                                                anonymous reporting of concerns.
                                                We established an Ethics Hotline
                                                Reward Policy for information
                                                that helps us uncover unethical
                                                behavior.  
                                            </EsgPara>
                                        </TextBlock>
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <div
                                        
                                        >
                                        <TextBlock marginTop>
                                            <BlockHeading noMarginTop>
                                                Supplier Code of Conduct
                                            </BlockHeading>
                                            <EsgPara>
                                                ibex is committed to the highest
                                                standards of labor and human
                                                rights and ethical/moral conduct
                                                and the management of health and
                                                safety. As such, our suppliers
                                                are required to provide a safe
                                                working environment, treat their
                                                employees with dignity and
                                                respect, act fairly and
                                                ethically/morally, and use
                                                environmentally responsible
                                                practices in the performance of
                                                services for ibex, as outlined
                                                in our Supplier Code of Conduct.
                                            </EsgPara>
                                        </TextBlock>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="6">
                            <Row>
                                <Col lg="12">
                                    <div
                                        
                                        >
                                        <TextBlock marginTop>
                                            <BlockHeading noMarginTop>
                                                RITEWay Training
                                            </BlockHeading>
                                            <EsgPara>
                                                RITE Way Training, through
                                                regular training sessions,
                                                videos, learning bursts, live
                                                leader sessions and more,
                                                demonstrates our ongoing
                                                commitment to ibex’s core values
                                                each and every day. We believe
                                                that employee retention,
                                                engagement, and the ultimate
                                                success of the business is
                                                contingent on our ability to
                                                bolster a culture built on
                                                Respect, Integrity,
                                                Transparency, and Excellence. We
                                                endeavor to educate our
                                                employees and leaders on these
                                                important ethical considerations
                                                and promote a safe work
                                                environment where everyone can
                                                reach their maximum potential.
                                            </EsgPara>
                                        </TextBlock>
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <LogoWrapper>
                                        <Row>
                                            <Col lg="6" md="6">
                                                <div
                                                    
                                                    >
                                                    <ImgWrapper logos>
                                                        <img
                                                            src={`${baseURL}assets/images/esg-syntrio-logo.jpg`}
                                                            alt="-"
                                                        />
                                                    </ImgWrapper>
                                                </div>
                                            </Col>
                                            <Col lg="6" md="6">
                                                <div
                                                    
                                                    >
                                                    <ImgWrapper logos>
                                                        <img
                                                            src={`${baseURL}assets/images/esg-global-riteway.jpg`}
                                                            alt="-"
                                                        />
                                                    </ImgWrapper>
                                                </div>
                                            </Col>
                                        </Row>
                                    </LogoWrapper>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Governance>

            <LetsDiscuss
                heading="Interested in partnering with ibex on your next CX project?"
                para="Get in touch today."
                btnText="Let’s Talk CX."
            />
        </Layout>
    );
};

const CareLogo = styled.div`
    margin-bottom: 50px;
    img {
        width: 100px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 3.125vw;
        img {
            width: 7.8vw;
        }
    }
`;

const RewardRecogLogo = styled.div`
    img {
        width: 100px;
    }
    ${Media('xlscreens')} {
        img {
            width: 11vw;
        }
    }
`;

const StickyMenu = styled.div`
    background-color: ${colors.gray_lightest['50']};
    width: 50%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 1px solid ${colors.gray_lightest['50']};
    ${Media('xlscreens')} {
        border-bottom-left-radius: 0.78vw;
        border-bottom-right-radius: 0.78vw;
    }
`;
const StickyMenuWrapper = styled.div`
    position: sticky;
    top: 101px;
    z-index: 99;
    height: 65px;
    width: 100%;
    // transform: translateY(-70px);
    transition: all 0.3s ease;
    ${(props) => {
        if (props.showStickyMenu) {
            return `
                transform: translateY(0px);
                ${StickyMenu}{
                    box-shadow: 0px 0px 20px ${colors.black['10']};
                }
            `;
        }
    }}
    ${Media('xlscreens')} {
        top: 5.26vw;
        height: 3.38vw;
    }
    ${Media('tablet')} {
        display: none;
    }
`;
const MenuTitle = styled.h3`
    font-size: 17px;
    font-family: ${gilroySemibold};
    flex: 1;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        margin-right: 15px;
    }
    ${Media('xlscreens')} {
        font-size: 0.88vw;
        svg {
            margin-right: 0.78vw;
        }
    }
`;
const MenuIcon = styled.div`
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid ${colors.gray_lightest['50']};
    height: 100%;
    img {
        height: 15px;
    }
    ${Media('xlscreens')} {
        width: 3.64vw;
        img {
            height: 0.78vw;
        }
    }
`;
const StickyMenuItem = styled.div`
    width: 22%;
    background-color: ${(props) => props.bgColor && colors.white['100']};
    border-bottom-left-radius: ${(props) => props.borderLeft && '15px'};
    position: relative;
    display: flex;
    align-items: center;
    cursor: ${(props) => !props.menuTitle && 'pointer'};
    justify-content: ${(props) =>
        props.menuTitle ? 'space-between' : 'center'};
    a {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
            position: absolute;
            content: '';
            bottom: 0;
            height: 1px;
            width: 0;
            background-color: ${colors.pink['100']};
            transition: all 0.3s ease;
        }
        &.active {
            &:after {
                width: 80%;
            }
            ${MenuTitle} {
                svg path {
                    fill: ${colors.pink['100']};
                }
            }
        }
        &:hover {
            &:after {
                width: 80%;
            }
            ${MenuTitle} {
                svg path {
                    fill: ${colors.pink['100']};
                }
            }
        }
    }
    &:first-child {
        width: 34%;
    }
    ${Media('xlscreens')} {
        border-bottom-left-radius: ${(props) => props.borderLeft && '0.78vw'};
    }
`;

const ESGIntro = styled.div`
    padding-top: 50px;
    button {
        min-width: 220px;
        text-decoration: underline;
        svg {
            margin-left: 10px;
        }
    }
    ${Media('xlscreens')} {
        padding-top: 2.6vw;
        button {
            min-width: 11.45vw;
            svg {
                margin-left: 0.53vw;
            }
        }
    }
    ${Media('mobile')} {
        padding-top: 30px;
    }
`;

const IntroHeading = styled.h1`
    font-size: 80px;
    font-family: ${gilroySemibold};
    line-height: 1.17;
    ${Media('xlscreens')} {
        font-size: 4vw;
    }
    ${Media('tablet')} {
        font-size: 50px;
    }
    ${Media('mobile')} {
        font-size: 38px;
    }
`;

const IntroPara = styled.p`
    font-size: 22px;
    line-height: 1.54;
    margin: 50px 0;
    ${Media('xlscreens')} {
        font-size: 1.14vw;
        padding-right: 10.4vw;
        margin: 2.6vw 0;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
    ${Media('mobile')} {
        font-size: 16px;
    }
`;

const IntroButton = styled(Button)``;

const CustomCol = styled(Col)`
    ${(props) => {
        if (props.sidePadding) {
            return `
                ${Media('xlscreens')}{
                    padding: 0 2.08vw !important;
                }
            `;
        }
    }}
`;

const IntroImg = styled.div`
    padding: 0px 20px;
    img {
        width: 100%;
    }
    ${(props) => {
        if (props.intro) {
            return `
                ${Media('xlscreens')}{
                    position:relative;
                    top:-5.2vw;
                }
            `;
        }
    }}
    ${Media('xlscreens')} {
        padding: 0 1.04vw;
    }
    ${Media('tablet')} {
        margin: 70px 0;
    }
`;

const SectionSubHeading = styled.h3`
    font-size: 24px;
    border-bottom: 1px solid ${colors.esg['greyBorder']};
    padding-bottom: 20px;
    font-family: ${akiraSuperBold};
    margin-top: 0;
    margin-bottom: 70px;
    ${(props) => {
        if (props.white) {
            return `
                color:${colors.white['100']};
                border-color:${colors.white['50']};
            `;
        }
        if (props.noMargin) {
            return `
                margin-bottom:0 !important;
            `;
        }
        if (props.marginTop) {
            return `
                margin-top:115px;
                ${Media('xlscreens')} {
                    margin-top:5.98vw;
                }
                ${Media('tablet')}{
                    margin-top:50px;
                }
            `;
        }
    }}
    ${Media('xlscreens')} {
        font-size: 1.25vw;
        padding-bottom: 1.04vw;
        margin-bottom: 3.64vw;
    }
    ${Media('tablet')} {
        font-size: 20px;
        margin-bottom: 40px;
    }
    ${Media('mobile')} {
        font-size: 18px;
        margin-bottom: 20px;
    }
`;

const SectionHeading = styled.h2`
    font-size: 70px;
    font-family: ${gilroyBold};
    margin-bottom: 70px;
    ${Media('xlscreens')} {
        font-size: 3.64vw;
        margin-bottom: 3.64vw;
    }
    ${Media('tablet')} {
        font-size: 50px;
        margin-bottom: 40px;
    }
    ${Media('mobile')} {
        font-size: 42px;
    }
`;

const OurMission = styled.div`
    ${Media('xlscreens')} {
        padding-right: 2.66vw;
    }
`;

const ImgWrapper = styled.div`
    img {
        width: 100%;
    }
    ${Media('tablet')} {
        margin: 30px 0;
    }
    ${Media('mobile')} {
        ${(props) => {
            if (props.logos) {
                return `
                    margin:10px 0;
                `;
            }
        }}
    }
`;

const EsgPara = styled.p`
    font-size: 22px;
    line-height: 1.45;
    color: ${(props) => props.color && props.color};
    font-family: ${(props) =>
        props.fontFamily ? props.fontFamily : gilroyRegular};
    a {
        color: ${colors.pink['100']};
        text-decoration: underline;
    }
    ul {
        margin-top: 35px;
    }
    ${Media('xlscreens')} {
        font-size: 1.14vw;
        ul {
            margin-top: 1.82vw;
        }
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;

const Environment = styled.section`
    ${Media('mobile')} {
        padding: 30px 0;
    }
`;

const BlockIcon = styled.div`
    height: 50px;
    img {
        max-width: 40px;
        margin-top: 40px;
    }
    ${Media('xlscreens')} {
        img {
            max-width: 3vw;
            margin-top: 2.08vw;
        }
    }
`;

const TextBlock = styled.div`
    ${(props) => {
        if (props.social) {
            return `
                border-top:1px solid;
                margin-top:120px;
                ${Media('tablet')}{
                    margin-top:50px;
                }
            `;
        }
        if (props.borderTop) {
            return `
                border-top:3px solid;
            `;
        }
        if (props.noPaddingRight) {
            return `
                padding-right:0px !important;
            `;
        }
        if (props.marginTop) {
            return `
                margin-top:30px;
            `;
        }
    }}
    ${Media('xlscreens')} {
        padding-right: 2.08vw;
        ${(props) => {
            if (props.social) {
                return `
                margin-top:6.25vw;
            `;
            }
            if (props.marginTop) {
                return `
                margin-top:1.56vw;
            `;
            }
        }}
    }
`;

const BlockHeading = styled.h4`
    font-size: 28px;
    font-family: ${gilroyBlack};
    margin-bottom: 45px;
    color: ${(props) => props.color && props.color};
    ${(props) => {
        if (props.noMarginTop) {
            return `
                margin-top:0px !important;
                ${Media('mobile')}{
                    margin-top:30px !important;
                }
            `;
        }
    }}
    ${Media('xlscreens')} {
        font-size: 1.2vw;
        margin-bottom: 2.34vw;
    }
    ${Media('tablet')} {
        font-size: 22px;
        margin-bottom: 20px;
    }
    ${Media('mobile')} {
        font-size: 18px;
    }
`;

const Social = styled.section`
    ${Media('mobile')} {
        padding: 30px 0;
    }
`;

const SocialTextBlock = styled(Col)`
    ${(props) => {
        if (props.sideSpacing) {
            return `
                ${TextBlock}{
                    padding-right:0;
                }
                ${Media('xlscreens')}{
                    padding:0 2.6vw !important;
                }
            `;
        }
    }}
`;

const ProgressWrap = styled(Row)`
    margin-top: 90px;
    justify-content: center;
    ${Media('xlscreens')} {
        margin-top: 4.68vw;
    }
    ${Media('tablet')} {
        margin-top: 50px;
    }
    ${Media('mobile')} {
        margin-top: 30px;
    }
`;

const DiversityWrapper = styled(Col)`
    margin: 0 60px;
    ${Media('xlscreens')} {
        margin: 0 3.12vw;
    }
`;

const DiversityPerc = styled.h3`
    font-family: ${akiraSuperBold};
    font-size: 40px;
    ${Media('xlscreens')} {
        font-size: 2.08vw;
    }
    ${Media('tablet')} {
        font-size: 34px;
    }
    ${Media('mobile')} {
        font-size: 26px;
    }
`;

const DiverDesc = styled.h5`
    font-size: 28px;
    font-family: ${gilroyBold};
    ${Media('xlscreens')} {
        font-size: 1.45vw;
    }
    ${Media('tablet')} {
        font-size: 20px;
    }
    ${Media('mobile')} {
        font-size: 18px;
    }
`;

const Philanthrophy = styled.section`
    background-color: ${colors.white['100']};
`;

const Philanthrophies = styled(Row)`
    margin-top: 90px;
    ${Media('xlscreens')} {
        margin-top: 4.68vw;
    }
    ${Media('tablet')} {
        margin-top: 50px;
    }
    ${Media('mobile')} {
        margin-top: 30px;
    }
`;

const PhilanthropyItem = styled.a`
    font-size: 22px;
    font-family: ${gilroyMedium};
    display: block;
    line-height: 1.5;
    ${Media('xlscreens')} {
        font-size: 1.14vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
        margin-top: 10px;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;

const EmployeeExp = styled.section`
    background-color: ${colors.socialRep['brandsBG']};
`;

const EmployeExpCustRow = styled(Row)`
    ${Media('xlscreens')} {
        margin-left: 1.56vw;
    }
`;

const RewardRecog = styled.section`
    background-color: ${colors.white['100']};
`;

const Governance = styled.section`
    ${Media('mobile')} {
        padding: 30px 0;
    }
`;

const List = styled.ul`
    padding-left: 25px;
    margin-top: 0;
    li {
        list-style: disc;
        font-size: 22px;
        font-family: ${gilroyMedium};
        line-height: 1.5;
    }
    ${Media('xlscreens')} {
        padding-left: 1.3vw;
        li {
            font-size: 1.14vw;
        }
    }
    ${Media('tablet')} {
        margin-bottom: 0;
    }
    ${Media('mobile')} {
        li {
            font-size: 14px;
        }
    }
`;

const LogoWrapper = styled.div`
    margin-top: 30px;
    ${Media('xlscreens')} {
        margin-top: 1.56vw;
    }
    ${Media('mobile')} {
        margin-top: 10px;
    }
`;

const YTVideo = styled.div`
    iframe {
        height: 780px;
        width: 100%;
        margin-bottom: 50px;
    }
    ${Media('xlscreens')} {
        iframe {
            height: 40.62vw;
            margin-bottom: 2.6vw;
        }
    }
    ${Media('tablet')} {
        iframe {
            margin-bottom: 30px;
            margin-top: 30px;
        }
    }
    ${Media('mobile')} {
        iframe {
            height: 200px;
        }
    }
`;

export default ESG;
