import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import { IconButton } from './icon-button';
import { Para } from './para';
import ReactHtmlParser from 'react-html-parser';
import { baseURLPage } from 'shared-components/theme/helpers';

export const BottomSection = ({ heading, subHeading }) => {
    return (
        <WaveXBottomSection>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                <Heading>{heading}</Heading>
                {subHeading && <Para>{ReactHtmlParser(subHeading)}</Para>}
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                <IconButton
                    btnText="Talk to Us."
                    href={`${baseURLPage}contact-us`}
                />
            </ScrollAnimation>
        </WaveXBottomSection>
    );
};
const WaveXBottomSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.black['5']};
    a {
        margin-top: 40px;
    }
    p {
        margin-bottom: 0;
        margin-top: 50px;
    }
    ${Media('xlscreens')} {
        padding: 4vw 0;
        a {
            margin-top: 2.6vw;
        }
        p {
            margin-top: 2.6vw;
        }
    }
    ${Media('mobile')}{
        padding: 50px 15px;
        p{
            margin-top:30px;
        }
    }
`;
const Heading = styled.h2`
    font-size: 34px;
    font-family: ${gilroyBold};
    text-align: center;
    letter-spacing: -1.4px;
    margin: 0;
    ${Media('xlscreens')} {
        font-size: 3.38vw;
    }
    ${Media('mobile')} {
        font-size: 28px;
    }
`;
