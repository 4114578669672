import React from 'react';
import colors from 'shared-components/theme/colors';
import { gilroyBold, gilroyExtrabold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';

export const SAPageHeading = ({ children, size, color, align }) => {
    return (
        <Heading size={size} color={color} align={align}>
            {children}
        </Heading>
    );
};

const Heading = styled.h2`
    font-size: ${(props) => {
        if (props.size === 'large') {
            return '60px';
        } else if (props.size === 'small') {
            return '30px';
        } else {
            return '50px';
        }
    }};
    font-family: ${(props) =>
        props.size === 'large' ? gilroyBold : gilroyExtrabold};
    margin: 0px;
    margin-bottom: 40px;
    color: ${(props) => (props.color ? props.color : colors.fintech['text'])};
    text-align: ${(props) => props.align === 'center' && 'center'};
    ${Media('xlscreens')} {
        font-size: ${(props) => {
            if (props.size === 'large') {
                return '3.49vw';
            } else if (props.size === 'small') {
                return '2vw';
            } else {
                return '3.125vw';
            }
        }};
        margin-bottom: 2.2vw;
    }
    ${Media('mobile')} {
        font-size: ${(props) => {
            if (props.size === 'large') {
                return '44px';
            } else if (props.size === 'small') {
                return '24px';
            } else {
                return '36px';
            }
        }};
        br {
            display: none;
        }
    }
`;
