import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import {
    gilroyExtrabold,
    gilroyMedium,
    gilroyRegular,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import Layout from 'components/layout';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { BigCircle } from 'components/industries/common';
import { baseURL } from 'shared-components/theme/helpers';
import RetailIntro from 'components/retail/intro-section';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import Video from 'shared-components/video/video';
import ScrollAnimation from 'react-animate-on-scroll';
import LazyLoad from 'react-lazyload';
import Loader from 'components/loader/loader';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -210,
    },
    {
        name: 'respect',
        target: 'respect',
        offset: -100,
    },
    {
        name: 'integrity',
        target: 'integrity',
        offset: -100,
    },
    {
        name: 'transparency',
        target: 'transparancy',
        offset: -100,
    },
    {
        name: 'excellence',
        target: 'excellence',
        offset: -100,
    },
];
const values = [
    {
        img: `${baseURL}assets/images/core-value-1.jpg`,
        video: `${baseURL}assets/videos/core-values/respect.mp4`,
        heading: 'Respect',
        targetID: 'respect',
        para:
            'We create a culture of mutual appreciation, regard and value. In everything we do, we act towards others with dignity while celebrating diversity and acceptance.',
    },
    {
        img: `${baseURL}assets/images/core-value-2.jpg`,
        video: `${baseURL}assets/videos/core-values/integrity.mp4`,
        heading: 'Integrity',
        targetID: 'integrity',
        para:
            'Our integrity ensures our credibility. Honor your  commitments and take ownership of your actions. Our words and deeds are true and reliable.',
    },
    {
        img: `${baseURL}assets/images/core-value-3.jpg`,
        video: `${baseURL}assets/videos/core-values/transparency.mp4`,
        heading: 'Transparency',
        targetID: 'transparancy',
        para:
            'Transparency fosters trust and collaboration. We maintain and encourage straightforward dialogue with our leadership, employees, clients, and stakeholders.',
    },
    {
        img: `${baseURL}assets/images/core-value-4.jpg`,
        video: `${baseURL}assets/videos/core-values/excellence.mp4`,
        heading: 'Excellence',
        targetID: 'excellence',
        para:
            'We strive for excellence in all that we do. Each employee’s contribution Is vital towards delivering exceptional customer service experience to our clients.',
    },
];
const CoreValues = () => {
    return (
        <Layout>
            <Breadcrumbs>
                <li>Industry</li>
                <li>Core Values</li>
            </Breadcrumbs>
            <CoreValuesIntro>
                <BigCircle
                    imgUrl={`${baseURL}assets/images/multi-dots.svg`}
                    imgHeight={250}
                    top="31%"
                    right="51%"
                    zIndex={'1'}
                />
                <BigCircle
                    imgUrl={`${baseURL}assets/images/bigger-circle.svg`}
                    imgHeight={1000}
                    top="65%"
                    right="15%"
                    zIndex={'1'}
                />
                <RetailIntro
                    rightImg={`${baseURL}assets/images/core-values-intro.png`}
                    tag="BUILDING WHAT’S NEXT"
                    introHeading="Our Core <br /> Values"
                    introPara="To unite a business that connects <br/>people around the globe, you need to <br/>know what you stand for."
                    noButton={true}
                    right="-4%"
                    top="-40%"
                    zIndex="0"
                    id="home"
                    page="core-values"
                />
            </CoreValuesIntro>
            <ValueDriveSection>
                <Container>
                    <div>
                        <CoreHead>
                            <IntroHeading>
                                Values Drive Everything.
                            </IntroHeading>
                            <Text>
                                At ibex, we believe that a fundamental set of
                                principles, adopted across an <br />
                                organization, can power a dynamic and engaged
                                workforce. These are ours.
                            </Text>
                        </CoreHead>
                    </div>

                    {/* <Valus */}
                    {values &&
                        values.length > 0 &&
                        values.map((value, index) => {
                            return (
                                <div
                                    key={index}>
                                    {values.length === index + 1 ? (
                                        <ScrollNav data={scrollNavData} />
                                    ) : null}
                                    <CoreBox
                                        noGutters={true}
                                        id={value.targetID}
                                        reverse={index % 2 == 1 && true}>
                                        <Col lg="6">
                                            <CoreImg>
                                                <Video
                                                    image={value.img}
                                                    video={value.video}
                                                    videotype="mp4"
                                                />
                                                <span>0{index + 1}</span>
                                            </CoreImg>
                                        </Col>
                                        <Col lg="6">
                                            <CoreDesc>
                                                <h3>{value.heading}</h3>
                                                <Text>{value.para}</Text>
                                            </CoreDesc>
                                        </Col>
                                    </CoreBox>
                                </div>
                            );
                        })}
                </Container>
            </ValueDriveSection>
        </Layout>
    );
};
const CoreValuesIntro = styled.div`
    position: relative;
    ${Media('xlscreens')} {
        padding-bottom: 16vw;
        padding-top: 3.5vw;
    }
`;

const ValueDriveSection = styled.section`
    ${Media('tablet')} {
        padding: 0;
    }
`;
const IntroHeading = styled.h1`
    font-size: 50px;
    font-family: ${gilroySemibold};
    line-height: 0.97;
    letter-spacing: -1px;
    left: -5px;
    position: relative;
    ${Media('xlscreens')} {
        font-size: 3.38vw;
        margin: 1.5vw 0px 2vw;
        letter-spacing: -0.15vw;
    }

    ${Media('mobile')} {
        font-size: 36px;
        br {
            display: none;
        }
    }
`;
const CoreDesc = styled.div`
    background-color: ${colors.white['100']};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    ${Media('xlscreens')} {
        padding: 0 7vw;
        margin: 0 0.41vw;
    }
    ${Media('mobile')} {
        text-align: center;
    }
`;
const CoreBox = styled(Row)`
    justify-content: center;
    h3 {
        font-family: ${gilroyMedium};
        font-size: 30px;
        margin-bottom: 35px;
        margin-top: 0;
    }
    p {
        font-family: ${gilroyMedium};
        font-size: 14px;
    }
    ${(props) => {
        if (props.reverse) {
            return `
            flex-direction: row-reverse;
            span {
                ${Media('xlscreens')} {
                    left: unset !important;
                    right: -6.25vw !important;
                }
                ${Media('tablet')} {
                    right: auto;
                    left: 10px;
                }
            }
            `;
        }
    }}

    ${Media('xlscreens')} {
        margin-top: 3.12vw;
        margin-right: 0.5vw;
        h3 {
            font-size: 2.13vw;
            margin-bottom: 1.82vw;
        }
        p {
            font-size: 1.04vw;
        }
    }
    ${Media('tablet')} {
        margin: 100px 0 50px 0;
        h3 {
            font-size: 35px;
            margin-bottom: 15px;
        }
    }
`;
const CoreImg = styled.div`
    position: relative;
    span {
        position: absolute;
        font-size: 200px;
        font-family: ${gilroyExtrabold};
        -webkit-text-stroke: 1px ${colors.gray['border']};
        -webkit-text-fill-color: transparent;
        left: -98px;
        top: 50%;
        transform: translateY(-50%);
        right: auto;
        ${Media('xlscreens')} {
            font-size: 10.2vw;
            left: -5.1vw;
        }
        ${Media('tablet')} {
            font-size: 120px;
            left: 10px;
            right: auto;
            top: 0;
        }
    }
    ${Media('xlscreens')} {
        margin: 0 0.41vw;
        height: 31.25vw;
    }
`;
const CoreHead = styled.div`
    margin-bottom: 70px;
    ${Media('xlscreens')} {
        margin-bottom: 4.5vw;
    }
    ${Media('mobile')} {
        text-align: center;
    }
`;
const Text = styled.div`
    font-size: 18px;
    font-family: ${gilroyRegular};
    line-height: 1.41;
    color: ${(props) => props.white && colors.white['100']};
    ${Media('xlscreens')} {
        font-size: 1.25vw;
    }
    ${Media('tablet')} {
        font-size: 16px;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 14px;
        br {
            display: none;
        }
    }
`;
export default CoreValues;
