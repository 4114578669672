import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroySemibold, gilroyRegular } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
const BigParagraph = ({ bigParagraph, margin, styleTwo }) => {
    return (
        <ScrollAnimation animateIn="fadeInUp">
            <Wrapper margin={margin} styleTwo={styleTwo}>
                <p>{bigParagraph}</p>
            </Wrapper>
        </ScrollAnimation>
    );
};
const Wrapper = styled.div`
    margin-top: 20px;
    text-align: ${(props) => (props.styleTwo ? 'center' : 'left')};
    p {
        font-family: ${gilroySemibold};
        font-size: ${(props) => (props.styleTwo ? '48px' : '52px')};
        line-height: 1.54;
        letter-spacing: -2px;
        color: ${colors.gray_dark['100']};
        ${Media('xlscreens')} {
            font-size: ${(props) => (props.styleTwo ? '2.51vw' : '2.7vw')};
            margin-bottom: ${(props) => (props.margin ? '5.23vw' : '0px')};
            margin-top: 0px;
        }
        ${Media('tablet')} {
            font-size: 30px;
            font-family: ${gilroyRegular};
            letter-spacing: 0px;
            margin-bottom: 30px;
            margin-top: 10px;
            br {
                display: none;
            }
        }
    }
    ${Media('xlscreens')} {
        margin-bottom: ${(props) => (props.margin ? '5.23vw' : '0px')};
    }
`;
export default BigParagraph;
