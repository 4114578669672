import React, { Fragment } from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { baseURL } from 'shared-components/theme/helpers';

const MapSection = ({ page }) => {
    return (
        <Fragment>
            <ImgMap>
                {page === 'velocity' ? (
                    <img
                        src={`${baseURL}assets/images/map-velocity.svg`}
                        alt=""
                    />
                ) : page === 'financial' ? (
                    <img
                        src={`${baseURL}assets/images/map-financial.svg`}
                        alt=""
                    />
                ) : (
                    <img src={`${baseURL}assets/images/map.svg`} alt="" />
                )}

                <PointersUsa>
                    <PointerDetails>
                        <h6>United States</h6>
                        <p>7 sites</p>
                        <p>3,000+ FTEs</p>
                    </PointerDetails>
                </PointersUsa>
                <PointersC2>
                    <PointerDetails>
                        <h6>Nicaragua</h6>
                        <p>3 sites</p>
                        <p>3000+ FTEs</p>
                    </PointerDetails>
                </PointersC2>
                <PointersC3>
                    <PointerDetails>
                        <h6>Jamaica</h6>
                        <p>6 sites</p>
                        <p>8000+ FTEs</p>
                    </PointerDetails>
                </PointersC3>
                <PointersC5>
                    <PointerDetails>
                        <h6>United Kingdom</h6>
                        <p>W@H</p>
                        <p>35 FTEs</p>
                    </PointerDetails>
                </PointersC5>
                <PointersC6>
                    <PointerDetails>
                        <h6>Pakistan</h6>
                        <p>7 sites</p>
                        <p>6000+ FTEs</p>
                    </PointerDetails>
                </PointersC6>
                <PointersC7>
                    <PointerDetails>
                        <h6>Philippines</h6>
                        <p>8 sites</p>
                        <p>10,000+ FTEs</p>
                    </PointerDetails>
                </PointersC7>
                <PointersC8>
                    <PointerDetails>
                        <h6>Honduras</h6>
                        <p>1 site</p>
                        <p>400+ FTEs</p>
                    </PointerDetails>
                </PointersC8>
            </ImgMap>
        </Fragment>
    );
};

const ImgMap = styled.div`
    width: 100%;
    max-width: 1079px;
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
    transform: scale(0.9);
    height: 36vw;
    img {
        width: 100%;
        ${Media('tablet')} {
            display: none;
        }
    }
    ${Media('large')} {
        max-width: 1440px;
    }
    ${Media('tablet')} {
        transform: scale(1);
        margin-top: 30px;
    }
`;

const PointerDetails = styled.div`
    background-color: ${colors.gray_dark['100']};
    padding: 10px;
    border: 1px solid ${colors.gray_dark['100']};
    position: absolute;
    width: 200px;
    top: -50px;
    left: 10px;
    transition-duration: 0.3s;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    ${Media('tablet')} {
        opacity: 1;
        visibility: visible;
        top: 0;
        left: 0;
        width: 100% !important;
        transform: none !important;
    }
    h6 {
        font-size: 15px;
        margin: 0;
        color: ${colors.white['100']};
        ${Media('tablet')} {
            font-size: 16px;
        }
    }
    p {
        font-size: 14px;
        margin: 0;
        color: ${colors.white['100']};
        ${Media('tablet')} {
            font-size: 14px;
        }
    }
`;

const Pointers = styled.div`
    position: absolute;
    cursor: pointer;
    transition-duration: 0.3s;
    ${Media('tablet')} {
        width: 100% !important;
        height: 75px !important;
        margin: 10px 0px;
        transform: none !important;
        position: relative;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        float: left;
        top: 0 !important;
    }
    span {
        width: 20px;
        height: 20px;
        display: inline-block;
        position: relative;
        ${Media('tablet')} {
            display: none;
        }
        &:before {
            content: '';
            width: 20px;
            height: 20px;
            background-color: #9e9e9e;
            display: inline-block;
            border-radius: 50%;
            position: absolute;
            left: -3px;
            right: 0;
            top: -2px;
            margin: 0 auto;
            display: none;
        }
        &:after {
            content: '';
            width: 6px;
            height: 6px;
            border: 1px solid ${colors.pink['100']};
            background-color: ${colors.pink['100']};
            display: inline-block;
            border-radius: 50%;
            position: absolute;
            left: 0;
            right: 0;
            top: 5px;
            margin: 0 auto;
            display: none;
        }
    }
    &:hover {
        ${PointerDetails} {
            top: -53px;
            opacity: 1;
            visibility: visible;
            ${Media('mobile')} {
                top: 0px;
            }
        }
    }
`;

const PointersUsa = styled(Pointers)`
    top: 20%;
    left: 11%;
    width: 18%;
    height: 22%;
`;

const PointersC2 = styled(Pointers)`
    top: 48%;
    left: 20%;
    width: 6%;
    height: 5%;
    transform: rotate(-45deg) translate(-15px, 5px);
    ${PointerDetails} {
        transform: rotate(45deg) translate(55px, 55px);
    }
`;

const PointersC8 = styled(PointersC2)`
    top: 42%;
    left: 18%;
`;

const PointersC3 = styled(Pointers)`
    top: 43%;
    left: 25%;
    width: 6%;
    height: 6%;
`;

const PointersC5 = styled(Pointers)`
    top: 17%;
    left: 44.5%;
    width: 4.5%;
    height: 11%;
`;

const PointersC6 = styled(Pointers)`
    top: 32%;
    left: 63%;
    width: 8%;
    height: 15%;
`;

const PointersC7 = styled(Pointers)`
    top: 43%;
    left: 79.5%;
    width: 5%;
    height: 15%;
`;

export default MapSection;
