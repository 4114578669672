import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { gilroySemibold } from 'shared-components/theme/fonts';
import WhyOutSourceBox from 'shared-components/outsource/outsource-box';
import { baseURL } from 'shared-components/theme/helpers';

export const NavBlackSection = () => {
    return (
        <>
            <NavSection>
                <Container>
                    <Row>
                        <Col lg="12">
                            <ul>
                                <li>
                                    <a
                                        href="#01"
                                        className="nav-section inner-link">
                                        Expertise
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#02"
                                        className="nav-section inner-link">
                                        Delivery
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#03"
                                        className="nav-section inner-link">
                                        Leadership
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#04"
                                        className="nav-section inner-link">
                                        Recruitment
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#05"
                                        className="nav-section inner-link">
                                        Training
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#06"
                                        className="nav-section inner-link">
                                        Launch
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#07"
                                        className="nav-section inner-link">
                                        Speed
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#08"
                                        className="nav-section inner-link">
                                        Insights
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#09"
                                        className="nav-section inner-link">
                                        Compliance
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#010"
                                        className="nav-section inner-link">
                                        Security
                                    </a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </NavSection>
            <BlackSection>
                <Container>
                    <WhyOutSourceBox
                        sectionId="01"
                        hedNumber="02-01"
                        hedText="Domain expertise"
                        activateSubHeadingBox={true}
                        mainBoxHeading="Domain Expertise"
                        boxParagraph="You’ve put a huge investment into your product - hiring the right outsourcer can protect & accelerate this investment."
                        boxImage={`${baseURL}assets/images/think-about.png`}
                        statsData={[
                            {
                                columnNumber: '3',
                                statsCount: '01',
                                statsText:
                                    'Has the outsourcer had previous success with a brand like yours?',
                            },
                            {
                                columnNumber: '3',
                                statsCount: '02',
                                statsText:
                                    'What were the timelines of success does this meet your goals?',
                            },
                            {
                                columnNumber: '3',
                                statsCount: '03',
                                statsText:
                                    'How does client success differ across their locations?',
                            },
                            {
                                columnNumber: '4',
                                statsCount: '04',
                                statsText:
                                    'Do they have client references? Speaking with a current hyper- growth client of your potential outsourcing partner is an essential way to gauge their performance.',
                            },
                            {
                                columnNumber: '4',
                                statsCount: '05',
                                statsText:
                                    'Analyze the language they use - every industry has its own vernacular - if they know your industry, they’ll speak your language.',
                            },
                        ]}
                    />
                    <BorderLine></BorderLine>
                    <WhyOutSourceBox
                        sectionId="02"
                        hedNumber="02-02"
                        hedText="Delivery Options"
                        activateSubHeadingBox={true}
                        mainBoxHeading="Elite Turnkey Service Delivery Options"
                        boxParagraph="By building an outsourced team, you reap the rewards of an established operation working across multiple geographies, giving you access to a worldwide network of turnkey contact centers with multiple service delivery locations, pricing options and languages."
                        boxImage={`${baseURL}assets/images/earth.png`}
                        statsData={[
                            {
                                columnNumber: '3',
                                statsCount: '01',
                                statsText:
                                    'Brand immersion and site customization - are they tailoring their contact center floors to the branding & culture of your organization?',
                            },
                            {
                                columnNumber: '3',
                                statsCount: '02',
                                statsText:
                                    'Are they giving back to the communities in which they operate?',
                            },
                            {
                                columnNumber: '3',
                                statsCount: '03',
                                statsText:
                                    'How does client success differ across their locations?',
                            },
                            {
                                columnNumber: '4',
                                statsCount: '04',
                                statsText:
                                    'What does agent churn & satisfaction look like across geographies & sites?',
                            },
                        ]}
                    />
                    <BorderLine></BorderLine>
                </Container>
                <Container>
                    <Row>
                        <Col lg="6">
                            <SubHeadingBoxTwo>
                                <span>02-02</span>
                                <span className="hed">Delivery Options</span>
                            </SubHeadingBoxTwo>
                        </Col>
                    </Row>
                </Container>
            </BlackSection>
            <BlackSectionTwo>
                <Container fluid={true}>
                    <Row>
                        <Col lg="5">
                            <BigImage>
                                <img
                                    src={`${baseURL}assets/images/leadership-training.png`}
                                    alt=""
                                />
                            </BigImage>
                        </Col>
                        <Col lg={{ size: 6, offset: 0 }}>
                            <ContenttextContainer>
                                <WhyOutSourceBox
                                    sectionId="03"
                                    outBoxCol="8"
                                    mainBoxHeading="Upfront Leadership Training & Investment"
                                    boxParagraph="An expert outsourcer understands that when launching a client, having your leadership trained and invested upfront - before any agent is hired - is imperative to program success, and will result in dramatically higher performance and returns for your brand."
                                    statsData={[
                                        {
                                            columnNumber: '7',
                                            statsCount: '01',
                                            statsText:
                                                'Take notice of who you are interacting with - is it your vendor’s C-level team, or a more junior team?',
                                        },
                                        {
                                            columnNumber: '7',
                                            statsCount: '02',
                                            statsText:
                                                'Discover who is trained first when launching your program - if management isn’t involved, this is a red flag.',
                                        },
                                        {
                                            columnNumber: '7',
                                            statsCount: '03',
                                            statsText:
                                                'How many layers does your vendor org chart have? Be wary of layers - the more flat an organization is, the more quickly it can anticipate and adapt to your needs.',
                                        },
                                    ]}
                                />
                            </ContenttextContainer>
                        </Col>
                    </Row>
                </Container>
            </BlackSectionTwo>
            <BlackSection>
                <Container>
                    <WhyOutSourceBox
                        sectionId="04"
                        hedNumber="02 - 04"
                        hedText="Hiring"
                        activateSubHeadingBox={true}
                        mainBoxHeading="Precision hiring to Secure top talent"
                        boxParagraph="By outsourcing your customer support team, you get access to a highly skilled and experienced recruiting team that can quickly pinpoint the best candidates for your contact center, while managing employee retention, fostering engagement and controlling churn."
                        boxImage={`${baseURL}assets/images/hiring-bg.png`}
                        statsData={[
                            {
                                columnNumber: '3',
                                statsCount: '01',
                                statsText:
                                    'Recruitment areas - does the outsourcer understand where the best talent is located?',
                            },
                            {
                                columnNumber: '3',
                                statsCount: '02',
                                statsText:
                                    'Does the outsourcer have a defined and rigorous process for identifying and selecting candidates?',
                            },
                            {
                                columnNumber: '3',
                                statsCount: '03',
                                statsText:
                                    'Do they have a track record of recruiting the top talent - can they demonstrate this?',
                            },
                        ]}
                    />
                    <BorderLine></BorderLine>
                    <WhyOutSourceBox
                        sectionId="05"
                        hedNumber="02 - 05"
                        hedText="Agent Training"
                        activateSubHeadingBox={true}
                        mainBoxHeading="Advanced Training for Agent Performance"
                        boxParagraph="Working with an outsourcer lets you take advantage of cutting-edge learning technology for onboarding agents, including training simulators, gamiﬁcation and virtual learning, that empowers your team to continuously improve their proﬁciency and overachieve for your brand."
                        boxImage={`${baseURL}assets/images/agent-training.png`}
                        statsData={[
                            {
                                columnNumber: '3',
                                statsCount: '01',
                                statsText:
                                    'Any good training program will have data around speed-to-proficiency - make sure to ask for it',
                            },
                            {
                                columnNumber: '3',
                                statsCount: '02',
                                statsText:
                                    'Does your chosen outsourcer use Training Simulators to speed-up training times and increase day 1 performance?',
                            },
                            {
                                columnNumber: '3',
                                statsCount: '03',
                                statsText:
                                    'Beyond initial training, does the outsourcer offer its agents ongoing training for evolving their skillsets?',
                            },
                        ]}
                    />
                    <BorderLine></BorderLine>
                </Container>
                <Container>
                    <Row>
                        <Col lg="6">
                            <SubHeadingBoxTwo>
                                <span>02-06</span>
                                <span className="hed">Launch</span>
                            </SubHeadingBoxTwo>
                        </Col>
                    </Row>
                </Container>
            </BlackSection>
            <BlackSectionTwo>
                <Container fluid={true}>
                    <Row>
                        <Col lg="5">
                            <BigImage>
                                <img src={`${baseURL}assets/images/launch-bg.png`} alt="" />
                            </BigImage>
                        </Col>
                        <Col lg={{ size: 6, offset: 0 }}>
                            <ContenttextContainer>
                                <WhyOutSourceBox
                                    sectionId="06"
                                    outBoxCol="8"
                                    mainBoxHeading="Project Management Support for a Seamless Launch"
                                    boxParagraph="Having an experienced project management team dedicated to coordinating and planning your program launch lets you do it right the ﬁrst time, while redirecting valuable internal resources toward other important revenue-generating activity."
                                    statsData={[
                                        {
                                            columnNumber: '7',
                                            statsCount: '01',
                                            statsText:
                                                'Do they have a PMO group with defined implementation & launch procedures?',
                                        },
                                        {
                                            columnNumber: '7',
                                            statsCount: '02',
                                            statsText:
                                                'Do they have a implementation timeline they can show you?',
                                        },
                                        {
                                            columnNumber: '7',
                                            statsCount: '03',
                                            statsText:
                                                'Case studies around successful launch and implementation examples',
                                        },
                                    ]}
                                />
                            </ContenttextContainer>
                        </Col>
                    </Row>
                </Container>
            </BlackSectionTwo>
            <BlackSection>
                <Container>
                    <BorderLine></BorderLine>
                    <WhyOutSourceBox
                        sectionId="07"
                        hedNumber="02-07"
                        hedText="Launch"
                        activateSubHeadingBox={true}
                        mainBoxHeading="Speed-to-Performance"
                        boxParagraph=" With so many beneﬁts that set your frontline apart, including selective hiring, smart training and aligned leadership, your outsourced customer support team becomes rapidly proﬁcient and hits the ground running."
                        boxImage={`${baseURL}assets/images/speed-bg.png`}
                        statsData={[
                            {
                                columnNumber: '3',
                                statsCount: '01',
                                statsText:
                                    'Does your partner have a sophisticated Workforce Management team?',
                            },
                            {
                                columnNumber: '3',
                                statsCount: '02',
                                statsText:
                                    'Can they show a successful history of rapid speed-to-green?',
                            },
                            {
                                columnNumber: '3',
                                statsCount: '03',
                                statsText:
                                    'Take a second look at their training programs - this is where speed to proficiency is born',
                            },
                        ]}
                    />
                    <BorderLine></BorderLine>

                    <WhyOutSourceBox
                        sectionId="08"
                        hedNumber="02 - 08"
                        hedText="Insights"
                        activateSubHeadingBox={true}
                        mainBoxHeading="Business Intelligence & Data Analytics"
                        boxParagraph="There’s no growth without continuous improvement. And you can’t improve what you don’t measure. Working with an outsourcer gives you access to business intelligence and insights that help you identify key challenges, build alignment across the organization, and uncover opportunities to improve customer-focused processes."
                        boxImage={`${baseURL}assets/images/insight-bg.png`}
                        statsData={[
                            {
                                columnNumber: '3',
                                statsCount: '01',
                                statsText:
                                    'How deep are their Business Intelligence capabilities? Are they both Insightful and Actionable?',
                            },
                            {
                                columnNumber: '3',
                                statsCount: '02',
                                statsText:
                                    'Review the types of analyses your vendor can perform - do they include Complexity Studies and Churn Analytics?',
                            },
                            {
                                columnNumber: '3',
                                statsCount: '03',
                                statsText:
                                    'Are they able to provide insight across the lifecycle, and feed that back into your contact center for improved agent interactions and performance?',
                            },
                        ]}
                    />
                    <BorderLine></BorderLine>

                    <WhyOutSourceBox
                        sectionId="09"
                        hedNumber="02 - 09"
                        hedText="Insights"
                        activateSubHeadingBox={true}
                        mainBoxHeading="Regulatory Compliance & Governmental Affairs"
                        boxParagraph="For many brands, ensuring compliance with highly complex regulatory frameworks is critical to the operation and success of their brand. As such, it is necessary to seek-out a partner with expertise in the compliance frameworks relevant to your brand."
                        boxImage={`${baseURL}assets/images/compliance-bg.png`}
                        statsData={[
                            {
                                columnNumber: '3',
                                statsCount: '01',
                                statsText:
                                    'Does your partner have a Compliance or Governmental Affairs team?',
                            },
                            {
                                columnNumber: '3',
                                statsCount: '02',
                                statsText:
                                    'How familiar are they with GDPR, CCPA, TCPA, HIPAA?',
                            },
                            {
                                columnNumber: '3',
                                statsCount: '03',
                                statsText:
                                    'Can they describe their compliance work for any current brands that look like yours?',
                            },
                        ]}
                    />

                    <BorderLine></BorderLine>
                    <WhyOutSourceBox
                        sectionId="010"
                        hedNumber="02 - 10"
                        hedText="Security"
                        activateSubHeadingBox={true}
                        mainBoxHeading="Information Security"
                        boxParagraph="One data leak is all it takes to bring down a growing brand. For an outsourcer, security comes in two layers - the technology layer and the people layer, and both require highly sophisticated frameworks to ensure that company data and customer PII remain free from vulnerability."
                        boxImage={`${baseURL}assets/images/security-bg.png`}
                        statsData={[
                            {
                                columnNumber: '3',
                                statsCount: '01',
                                statsText:
                                    'Does your outsourcer have dedicated screen, audio, and keystroke monitoring & recording technology?',
                            },
                            {
                                columnNumber: '3',
                                statsCount: '02',
                                statsText:
                                    'Are there processes and protocols in place for fraud & compliance training, as well as a hotline setup for anonymous reporting?',
                            },
                            {
                                columnNumber: '3',
                                statsCount: '03',
                                statsText:
                                    'Is the outsourcer’s technology infrastructure secured by the latest encryption and data security protocols?',
                            },
                        ]}
                    />
                </Container>
            </BlackSection>
        </>
    );
};
const NavSection = styled.section`
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    position: sticky;
    top: 102px;
    z-index: 11;
    background: #fff;
    ${Media('xlscreens')} {
        top: 5.34vw;
    }
    ul {
        padding: 0;
        margin: 0;
        display: flex;

        li {
            flex: 1 0;
            text-align: center;
            transition: all 0.3s ease-in-out;

            a {
                display: flex;
                padding: 10px;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease-in-out;
                font-size: 14px;
                font-family: ${gilroySemibold};
                ${Media('xlscreens')} {
                    font-size: 0.94vw;
                }

                &:hover {
                    background-color: #121212;
                    color: #fa0060;
                }
            }
        }
    }
    ${Media('tablet')} {
        display: none;
    }
`;
const BorderLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 50px 0;
    ${Media('xlscreens')} {
        margin: 2.3vw 0;
    }
    ${Media('tablet')} {
        margin: 20px 0px;
    }
`;
const ContenttextContainer = styled.div`
    padding-left: 50px;
    .flex-box-text__TextFlexBox-sc-1wahhmh-0 {
        margin-bottom: 20px;
    }
    ${Media('tablet')} {
        padding: 0px 15px;
    }
    ${Media('mobile')} {
        padding: 0px;
    }
`;
const BigImage = styled.div`
    width: 100%;
    img {
        width: 100%;
    }
`;
const SubHeadingBoxTwo = styled.div`
    margin: 50px 0 100px;
    border-top: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    ${Media('xlscreens')} {
        margin: 2.6vw 0 1vw;
    }
    span {
        font-family: ${gilroySemibold};
        color: #fff;
        font-size: 16px;
        ${Media('xlscreens')} {
            font-size: 0.95vw;
        }
        &.hed {
            color: rgba(255, 255, 255, 0.5);
        }
    }
    ${Media('tablet')} {
        margin: 10px 0px;
    }
`;
const BlackSection = styled.section`
    background-color: #121212;
    position: relative;
    ${Media('tablet')} {
        padding: 20px 0px;
    }
`;

const BlackSectionTwo = styled(BlackSection)`
    padding-left: 0;
    ${Media('xlscreens')} {
        padding-left: 0 !important;
        padding-top: 1vw !important;
    }
    ${Media('tablet')} {
        padding: 20px 0px;
    }
`;
