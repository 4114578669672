import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyMedium } from 'shared-components/theme/fonts';
import { GoPlus } from 'react-icons/go';
import Media from 'shared-components/theme/medias';
import Video from 'shared-components/video/video';

const HomeHelping = ({
    cardVideo,
    cardImage,
    cardTitle,
    cardTitleIcon,
    cardMidLevel,
    cardMidContent,
    cardMidContentImage,
    link,
    target,
}) => {
    return (
        <Wrapper>
            <ImgBox>
                {/* <img src={cardVideo} alt="" /> */}
                <Video video={cardVideo} videotype="mp4" image={cardImage} />
            </ImgBox>
            <CardTop>
                <CardTitle>{cardTitle}</CardTitle>
                <CardIcon>{cardTitleIcon}</CardIcon>
            </CardTop>
            <CardMidText level={cardMidLevel}>
                {cardMidContent}
                <img
                    data-src={cardMidContentImage}
                    src={cardMidContentImage}
                    alt=""
                    className="lozad"
                />
            </CardMidText>
            <CardAnchor href={link} target={target}>
                See more
                <span>
                    <GoPlus />
                </span>
            </CardAnchor>
        </Wrapper>
    );
};

const ImgBox = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;
    width: 100%;
    z-index: -1;
    overflow: hidden;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: ${colors.gray_dark['50']};
        top: 0px;
        left: 0px;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 1s ease;
    }
`;

const Wrapper = styled.div`
    height: 630px;
    width: 100%;
    position: relative;
    padding: 40px 35px;
    border: 4px solid transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 100px;
    &:hover {
        ${ImgBox} {
            img {
                transform: scale(1.2);
            }
        }
    }
    ${Media('xlscreens')} {
        height: 40vw;
        margin-top: 4vw;
    }
    ${Media('mobile')} {
        margin-top: 0px;
        margin-bottom: 40px;
    }
`;

const CardTop = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CardTitle = styled.span`
    color: ${colors.white['100']};
    font-size: 13px;
    font-family: ${gilroyMedium};
    text-transform: uppercase;
    padding-left: 10%;
    white-space: nowrap;
    letter-spacing: 2px;
    ${Media('xlscreens')} {
        font-size: 0.85vw;
    }
`;

const CardIcon = styled.div`
    display: inline-flex;
    font-size: 24px;
    color: ${colors.pink['100']};
    ${Media('xlscreens')} {
        font-size: 1.5vw;
    }
`;

const CardMidText = styled.h4`
    color: ${colors.white['100']};
    text-align: center;
    line-height: 0.9;
    font-size: 33px;
    padding-top: 70px;
    flex: 1 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    img {
        display: block;
        margin: 0 auto;
        position: relative;
        margin-top: -20px;
        ${(props) => {
            if (props.level === 'one') {
                return `width: 210px;
            padding-right: 10px;`;
            } else if (props.level === 'two') {
                return `width: 190px;`;
            } else if (props.level === 'three') {
                return `width: 171px;`;
            }
        }};
    }
    ${Media('xlscreens')} {
        font-size: 2.4vw;
        img {
            margin-top: -1.3vw;
            ${(props) => {
                if (props.level === 'one') {
                    return `width: 15.2vw;
                    padding-right: 0.6vw;`;
                } else if (props.level === 'two') {
                    return `width: 13.9vw;`;
                } else if (props.level === 'three') {
                    return `width: 12.6vw;`;
                }
            }};
        }
    }
`;

const CardAnchor = styled.a`
    border-top: 1px solid ${colors.white['30']};
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 85px;
    left: 0;
    bottom: -8px;
    color: ${colors.white['100']};
    font-size: 13px;
    font-family: ${gilroyMedium};
    transition: all 0.3s ease-in-out;
    &::after {
        content: '';
        width: 0%;
        height: 100%;
        background-color: ${colors.pink['30']};
        position: absolute;
        left: 0;
        z-index: -1;
        transition: all 0.3s ease-in-out;
    }
    span {
        display: inline-flex;
        font-size: 15px;
        color: ${colors.pink['100']};
        position: relative;
        left: 10px;
        transition: all 0.3s ease-in-out;
    }
    &:hover {
        color: ${colors.white['100']};
        span {
            color: ${colors.white['100']};
        }
        &::after {
            width: 100%;
        }
    }
    ${Media('xlscreens')} {
        height: 5.4vw;
        font-size: 0.85vw;
        span {
            font-size: 1vw;
        }
    }
`;

export default HomeHelping;
