import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import {
    gilroyBold,
    gilroyMedium,
    sourceSansPro,
} from 'shared-components/theme/fonts';
import { baseURL, handlePrice } from 'shared-components/theme/helpers';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import Button from 'shared-components/button/button';
import ReactHtmlParser from 'react-html-parser';
import { BlogSubscription } from 'components/blogs/blog-subscription-form';

const yearOfExp = [
    {
        label: '0-2 Years',
        value: '0_2_years',
    },
    {
        label: '2-5 Years',
        value: '2_5_years',
    },
    {
        label: '5-10 Years',
        value: '5_10_years',
    },
    {
        label: '10+ Years',
        value: '10_years',
    },
];

const teamOptions = {
    job: '',
    jobOpen: false,
    jobData: [],
    region: '',
    regionOpen: false,
    regionData: [],
    years: '',
    yearsExp: '',
    yearsOpen: false,
    yearsData: [],
    jobSearch: '',
    typeSelectIndex: '',
    jobSelectIndex: '',
    savings: '',
};

export const TeamBuilderSection = () => {
    const [job, setJob] = useState([teamOptions]);
    const [jobData, setJobData] = useState([]);
    const [regionData, setRegionData] = useState([]);
    const [yearsData] = useState([]);
    const [jobDataRaw, setJobDataRaw] = useState([]);
    const [subscForm, setSubscForm] = useState(false);

    const getJobs = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                '/landings-posts?' +
                new URLSearchParams({
                    token: process.env.REACT_APP_API_TOKEN,
                    order: 'asc',
                    per_page: 1000,
                    post_type: 'augmented-job',
                })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData.status === 200 && resData.data.length !== 0) {
                setJobDataRaw(resData.data);
                regionDataFilter(resData.data);
                const newData = filteredData(resData.data);
                setJobData(newData);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getJobs();
    }, [job]);

    const regionDataFilter = (data) => {
        const dataOld = data[0].metadata.salary;
        var dataNew = [];
        Object.keys(dataOld).map((key) => {
            dataNew.push({
                name: key,
                title: key === 'asian' ? `${key} markets.` : key,
                value: dataOld[key],
            });
        });
        setRegionData(dataNew);
    };

    const filteredData = (data) => {
        var newCat = [];
        data.filter((item) => newCat.push(item.post_terms[0].name));
        const newCatStr = newCat.filter((item, index) => {
            return newCat.indexOf(item) === index;
        });
        const newStructure = newCatStr.map((item) => [
            item,
            data.filter((list) => list.post_terms[0].name === item),
        ]);

        return newStructure;
    };

    const handleOpenJob = (index) => {
        job[index] = { ...job[index], jobOpen: true, jobData: jobData };
        setJob([...job]);
    };

    const handleCloseJob = (index) => {
        job[index] = { ...job[index], jobOpen: false, jobSearch: '' };
        setJob([...job]);
    };

    const handleOpenRegion = (index) => {
        job[index] = {
            ...job[index],
            regionOpen: true,
            regionData: regionData,
        };
        setJob([...job]);
    };

    const handleCloseRegion = (index) => {
        job[index] = { ...job[index], regionOpen: false };
        setJob([...job]);
    };

    const handleOpenYears = (index) => {
        job[index] = { ...job[index], yearsOpen: true, yearsData: yearsData };
        setJob([...job]);
    };

    const handleCloseYears = (index) => {
        job[index] = { ...job[index], yearsOpen: false };
        setJob([...job]);
    };

    const handleAddJobTitle = (value, index, outerIndex, innerIndex) => {
        job[index] = {
            ...job[index],
            job: value,
            jobOpen: false,
            typeSelectIndex: outerIndex,
            jobSelectIndex: innerIndex,
        };
        setJob([...job]);
    };

    const handleAddRegion = (value, index) => {
        job[index] = {
            ...job[index],
            region: value,
            regionOpen: false,
        };
        setJob([...job]);
    };

    const handleJobSearch = (value, index) => {
        const filteredArray = jobDataRaw.filter((v) => {
            let newFind = v.post_title.toLowerCase();
            if (newFind.includes(value.toLowerCase())) {
                return true;
            }
        });
        const newData = filteredData(filteredArray);
        job[index] = { ...job[index], jobSearch: value, jobData: newData };
        setJob([...job]);
    };

    const handleAddBuilder = () => {
        setJob((prevState) => [...prevState, teamOptions]);
    };

    const handleExpData = (index, jobIndex) => {
        job[jobIndex] = {
            ...job[jobIndex],
            yearsExp: yearOfExp[index]['value'],
            yearsOpen: false,
        };
        setJob([...job]);
    };

    return (
        <>
            <Wrapper>
                <Row>
                    <Col lg="12">
                        <TeamBuilderBox>
                            <TeamBuilderHeader>
                                <TeamBuilderLogo>
                                    <img
                                        src={`${baseURL}assets/images/staff_aug/teambuilder-logo.svg`}
                                        alt=""
                                    />
                                    <h3>Team Builder</h3>
                                </TeamBuilderLogo>
                                <HeaderInfoText>
                                    Staff quickly. Improve output. Save money.
                                </HeaderInfoText>
                            </TeamBuilderHeader>
                            <TeamBuilderContent>
                                <BuilderOptionsList>
                                    {job &&
                                        job.length > 0 &&
                                        job.map((jobItem, jobIndex) => {
                                            return (
                                                <TeamBuilderInner
                                                    key={jobIndex}
                                                    last={
                                                        jobIndex ===
                                                        job.length - 1
                                                    }>
                                                    <RowCentered>
                                                        <CustomCol width="16.5%">
                                                            <OptionsList
                                                                heading
                                                                hide={
                                                                    jobIndex !==
                                                                    0
                                                                }>
                                                                <CountNum>
                                                                    1
                                                                </CountNum>
                                                                <OptionHeading>
                                                                    Job Type
                                                                </OptionHeading>
                                                            </OptionsList>
                                                            <OptionsList>
                                                                <OptionsDropdown>
                                                                    <SelectItems
                                                                        onClick={() =>
                                                                            handleOpenJob(
                                                                                jobIndex
                                                                            )
                                                                        }>
                                                                        <OptionsValue
                                                                            placeholderText="Select job"
                                                                            showPlaceholder={
                                                                                !jobItem.job &&
                                                                                jobItem.job !==
                                                                                undefined
                                                                            }>
                                                                            {
                                                                                jobItem.job
                                                                            }
                                                                        </OptionsValue>
                                                                    </SelectItems>
                                                                    <OptionsOverlay
                                                                        showDropdown={
                                                                            jobItem.jobOpen
                                                                        }
                                                                        onClick={() =>
                                                                            handleCloseJob(
                                                                                jobIndex
                                                                            )
                                                                        }
                                                                    />
                                                                    <DropDownBox
                                                                        showDropdown={
                                                                            jobItem.jobOpen
                                                                        }>
                                                                        <SearchBox>
                                                                            <SelectBox
                                                                                autoFocus={
                                                                                    jobItem.jobSearch
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    handleJobSearch(
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                        jobIndex
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    jobItem.jobSearch
                                                                                }
                                                                            />
                                                                            <SearchIcon
                                                                                src={`${baseURL}assets/images/staff_aug/search-icon.svg`}
                                                                            />
                                                                        </SearchBox>
                                                                        <DropDownListWrap>
                                                                            {jobItem.jobData &&
                                                                                jobItem
                                                                                    .jobData
                                                                                    .length >
                                                                                0 ? (
                                                                                jobItem.jobData.map(
                                                                                    (
                                                                                        item,
                                                                                        index
                                                                                    ) => {
                                                                                        return (
                                                                                            <DropDownList
                                                                                                key={
                                                                                                    10 +
                                                                                                    index
                                                                                                }>
                                                                                                <span>
                                                                                                    {ReactHtmlParser(
                                                                                                        item[0]
                                                                                                    )}
                                                                                                </span>

                                                                                                {item[1] &&
                                                                                                    item[1]
                                                                                                        .length >
                                                                                                    0 ? (
                                                                                                    item[1].map(
                                                                                                        (
                                                                                                            jobInner,
                                                                                                            jobInnerIndex
                                                                                                        ) => {
                                                                                                            return (
                                                                                                                <a
                                                                                                                    key={
                                                                                                                        101 +
                                                                                                                        jobInnerIndex
                                                                                                                    }
                                                                                                                    onClick={() =>
                                                                                                                        handleAddJobTitle(
                                                                                                                            jobInner.post_title,
                                                                                                                            jobIndex,
                                                                                                                            index,
                                                                                                                            jobInnerIndex
                                                                                                                        )
                                                                                                                    }>
                                                                                                                    {ReactHtmlParser(
                                                                                                                        jobInner.post_title
                                                                                                                    )}
                                                                                                                </a>
                                                                                                            );
                                                                                                        }
                                                                                                    )
                                                                                                ) : (
                                                                                                    <li>
                                                                                                        No
                                                                                                        Data
                                                                                                        Found
                                                                                                    </li>
                                                                                                )}
                                                                                            </DropDownList>
                                                                                        );
                                                                                    }
                                                                                )
                                                                            ) : (
                                                                                <OptionsNoData>
                                                                                    No
                                                                                    Data
                                                                                    Found
                                                                                </OptionsNoData>
                                                                            )}
                                                                        </DropDownListWrap>
                                                                    </DropDownBox>
                                                                </OptionsDropdown>
                                                            </OptionsList>
                                                        </CustomCol>

                                                        <CustomCol width="16.5%">
                                                            <OptionsList
                                                                heading
                                                                hide={
                                                                    jobIndex !==
                                                                    0
                                                                }>
                                                                <CountNum>
                                                                    2
                                                                </CountNum>
                                                                <OptionHeading>
                                                                    Region
                                                                </OptionHeading>
                                                            </OptionsList>
                                                            <OptionsList>
                                                                <OptionsDropdown>
                                                                    <SelectItems
                                                                        onClick={() =>
                                                                            handleOpenRegion(
                                                                                jobIndex
                                                                            )
                                                                        }>
                                                                        <OptionsValue
                                                                            placeholderText="Select Region"
                                                                            showPlaceholder={
                                                                                !jobItem.region &&
                                                                                jobItem.region !==
                                                                                undefined
                                                                            }>
                                                                            {jobItem.region &&
                                                                                jobItem.region !==
                                                                                undefined && (
                                                                                    <img
                                                                                        src={`${baseURL}assets/images/staff_aug/${jobItem.region}-flag.jpg`}
                                                                                        alt="-"
                                                                                    />
                                                                                )}
                                                                            {jobItem.region &&
                                                                                jobItem.region ===
                                                                                'asian'
                                                                                ? `${jobItem.region} Markets.`
                                                                                : jobItem.region ===
                                                                                    'usa'
                                                                                    ? jobItem.region.toUpperCase()
                                                                                    : jobItem.region}
                                                                        </OptionsValue>
                                                                    </SelectItems>
                                                                    <OptionsOverlay
                                                                        showDropdown={
                                                                            jobItem.regionOpen
                                                                        }
                                                                        onClick={() =>
                                                                            handleCloseRegion(
                                                                                jobIndex
                                                                            )
                                                                        }
                                                                    />
                                                                    <DropDownBox
                                                                        showDropdown={
                                                                            jobItem.regionOpen
                                                                        }>
                                                                        <DropDownList
                                                                            semiBold>
                                                                            {jobItem.regionData &&
                                                                                jobItem
                                                                                    .regionData
                                                                                    .length >
                                                                                0 &&
                                                                                jobItem.regionData.map(
                                                                                    (
                                                                                        region,
                                                                                        ind
                                                                                    ) => {
                                                                                        return (
                                                                                            <a
                                                                                                key={
                                                                                                    20 +
                                                                                                    ind
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    handleAddRegion(
                                                                                                        region.name,
                                                                                                        jobIndex
                                                                                                    )
                                                                                                }>
                                                                                                <img
                                                                                                    src={`${baseURL}assets/images/staff_aug/${region.name}-flag.jpg`}
                                                                                                    alt="-"
                                                                                                />
                                                                                                {region.title ===
                                                                                                    'usa'
                                                                                                    ? region.title.toUpperCase()
                                                                                                    : region.title}
                                                                                            </a>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                        </DropDownList>
                                                                    </DropDownBox>
                                                                </OptionsDropdown>
                                                            </OptionsList>
                                                        </CustomCol>

                                                        <CustomCol width="16.5%">
                                                            <OptionsList
                                                                heading
                                                                hide={
                                                                    jobIndex !==
                                                                    0
                                                                }>
                                                                <CountNum>
                                                                    3
                                                                </CountNum>
                                                                <OptionHeading>
                                                                    Years of
                                                                    Exp.
                                                                </OptionHeading>
                                                            </OptionsList>
                                                            <OptionsList>
                                                                <OptionsDropdown>
                                                                    <SelectItems
                                                                        onClick={() =>
                                                                            handleOpenYears(
                                                                                jobIndex
                                                                            )
                                                                        }>
                                                                        <OptionsValue
                                                                            placeholderText="Select Experience"
                                                                            showPlaceholder={
                                                                                !jobItem.yearsExp &&
                                                                                jobItem.yearsExp !==
                                                                                undefined
                                                                            }>
                                                                            {yearOfExp.map(
                                                                                (
                                                                                    data
                                                                                ) =>
                                                                                    data.value ===
                                                                                    jobItem.yearsExp &&
                                                                                    data.label
                                                                            )}
                                                                        </OptionsValue>
                                                                    </SelectItems>
                                                                    <OptionsOverlay
                                                                        showDropdown={
                                                                            jobItem.yearsOpen
                                                                        }
                                                                        onClick={() =>
                                                                            handleCloseYears(
                                                                                jobIndex
                                                                            )
                                                                        }
                                                                    />
                                                                    <DropDownBox
                                                                        showDropdown={
                                                                            jobItem.yearsOpen
                                                                        }>
                                                                        <DropDownList
                                                                            semiBold>
                                                                            {yearOfExp &&
                                                                                yearOfExp.length >
                                                                                0 &&
                                                                                yearOfExp.map(
                                                                                    (
                                                                                        item,
                                                                                        ind
                                                                                    ) => {
                                                                                        return (
                                                                                            <a
                                                                                                key={
                                                                                                    20 +
                                                                                                    ind
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    handleExpData(
                                                                                                        ind,
                                                                                                        jobIndex
                                                                                                    )
                                                                                                }>
                                                                                                {
                                                                                                    item.label
                                                                                                }
                                                                                            </a>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                        </DropDownList>
                                                                    </DropDownBox>
                                                                </OptionsDropdown>
                                                            </OptionsList>
                                                        </CustomCol>

                                                        <CustomCol width="15%">
                                                            <OptionsList
                                                                heading
                                                                hide={
                                                                    jobIndex !==
                                                                    0
                                                                }>
                                                                <OptionHeading>
                                                                    USA Cost
                                                                </OptionHeading>
                                                            </OptionsList>
                                                            <OptionsList>
                                                                <OptionsValue
                                                                    placeholderText="Salary"
                                                                    showPlaceholder={
                                                                        jobItem.typeSelectIndex ===
                                                                        '' ||
                                                                        jobItem.region ===
                                                                        '' ||
                                                                        jobItem.yearsExp ===
                                                                        ''
                                                                    }
                                                                    noArrow
                                                                    textStyle="medium">
                                                                   
                                                                    {jobItem.job &&
                                                                        jobItem.job !==
                                                                        undefined &&
                                                                        jobItem.region &&
                                                                        jobItem.region !==
                                                                        undefined &&
                                                                        jobItem.region !==
                                                                        '' &&
                                                                        jobItem.typeSelectIndex !==
                                                                        undefined &&
                                                                        jobItem.typeSelectIndex !==
                                                                        '' &&
                                                                        jobItem.yearsExp !==
                                                                        undefined &&
                                                                        jobItem.yearsExp !==
                                                                        '' &&
                                                                        '$' +
                                                                        handlePrice(
                                                                            jobItem
                                                                                .jobData[
                                                                                jobItem
                                                                                    .typeSelectIndex
                                                                            ][1][
                                                                                jobItem
                                                                                    .jobSelectIndex
                                                                            ]
                                                                                .metadata
                                                                                .salary[
                                                                            'usa'
                                                                            ][
                                                                            jobItem
                                                                                .yearsExp
                                                                            ]
                                                                        )}
                                                                </OptionsValue>
                                                            </OptionsList>
                                                        </CustomCol>

                                                        <CustomCol width="15%">
                                                            <OptionsList
                                                                heading
                                                                hide={
                                                                    jobIndex !==
                                                                    0
                                                                }>
                                                                <OptionHeading>
                                                                    ibex Cost
                                                                </OptionHeading>
                                                            </OptionsList>
                                                            <OptionsList>
                                                                <OptionsValue
                                                                    placeholderText="Salary"
                                                                    showPlaceholder={
                                                                        jobItem.typeSelectIndex ===
                                                                        '' ||
                                                                        jobItem.region ===
                                                                        '' ||
                                                                        jobItem.yearsExp ===
                                                                        ''
                                                                    }
                                                                    noArrow
                                                                    textStyle="medium">
                                                                    {jobItem.job &&
                                                                        jobItem.job !==
                                                                        undefined &&
                                                                        jobItem.region &&
                                                                        jobItem.region !==
                                                                        undefined &&
                                                                        jobItem.region !==
                                                                        '' &&
                                                                        jobItem.typeSelectIndex !==
                                                                        undefined &&
                                                                        jobItem.typeSelectIndex !==
                                                                        '' &&
                                                                        jobItem.yearsExp !==
                                                                        undefined &&
                                                                        jobItem.yearsExp !==
                                                                        '' &&
                                                                        '$' +
                                                                        handlePrice(
                                                                            jobItem
                                                                                .jobData[
                                                                                jobItem
                                                                                    .typeSelectIndex
                                                                            ][1][
                                                                                jobItem
                                                                                    .jobSelectIndex
                                                                            ]
                                                                                .metadata
                                                                                .salary[
                                                                            jobItem
                                                                                .region
                                                                            ][
                                                                            jobItem
                                                                                .yearsExp
                                                                            ]
                                                                        )}
                                                                </OptionsValue>
                                                            </OptionsList>
                                                        </CustomCol>

                                                        <CustomCol width="15%">
                                                            <OptionsList
                                                                last
                                                                heading
                                                                hide={
                                                                    jobIndex !==
                                                                    0
                                                                }>
                                                                <OptionHeading>
                                                                    Annual
                                                                    Savings
                                                                </OptionHeading>
                                                            </OptionsList>
                                                            <OptionsList last>
                                                                <OptionsValue
                                                                    placeholderText="Savings"
                                                                    showPlaceholder={
                                                                        jobItem.typeSelectIndex ===
                                                                        '' ||
                                                                        jobItem.region ===
                                                                        '' ||
                                                                        jobItem.yearsExp ===
                                                                        ''
                                                                    }
                                                                    noArrow
                                                                    textStyle="large"
                                                                    color={
                                                                        colors
                                                                            .staffAug[
                                                                        'green'
                                                                        ]
                                                                    }>
                                                                    {jobItem.job &&
                                                                        jobItem.job !==
                                                                        undefined &&
                                                                        jobItem.region &&
                                                                        jobItem.region !==
                                                                        undefined &&
                                                                        jobItem.region !==
                                                                        '' &&
                                                                        jobItem.typeSelectIndex !==
                                                                        undefined &&
                                                                        jobItem.typeSelectIndex !==
                                                                        '' &&
                                                                        jobItem.yearsExp !==
                                                                        undefined &&
                                                                        jobItem.yearsExp !==
                                                                        '' &&
                                                                        '$' +
                                                                        handlePrice(
                                                                            jobItem
                                                                                .jobData[
                                                                                jobItem
                                                                                    .typeSelectIndex
                                                                            ][1][
                                                                                jobItem
                                                                                    .jobSelectIndex
                                                                            ]
                                                                                .metadata
                                                                                .salary[
                                                                            'usa'
                                                                            ][
                                                                            jobItem
                                                                                .yearsExp
                                                                            ] -
                                                                            jobItem
                                                                                .jobData[
                                                                                jobItem
                                                                                    .typeSelectIndex
                                                                            ][1][
                                                                                jobItem
                                                                                    .jobSelectIndex
                                                                            ]
                                                                                .metadata
                                                                                .salary[
                                                                            jobItem
                                                                                .region
                                                                            ][
                                                                            jobItem
                                                                                .yearsExp
                                                                            ]
                                                                        )}
                                                                </OptionsValue>
                                                            </OptionsList>
                                                        </CustomCol>
                                                    </RowCentered>
                                                </TeamBuilderInner>
                                            );
                                        })}
                                    <TeamSubmitBtn>
                                        <Button round ifButton={true} onClick={() => setSubscForm(true)}>
                                            Let’s Talk.
                                        </Button>
                                    </TeamSubmitBtn>
                                </BuilderOptionsList>
                                <Row>
                                    <Col lg="12">
                                        <OptionsAddBtnWrap>
                                            <OptionsAddBtn
                                                onClick={handleAddBuilder}>
                                                Add +
                                            </OptionsAddBtn>
                                        </OptionsAddBtnWrap>
                                    </Col>
                                </Row>
                            </TeamBuilderContent>
                        </TeamBuilderBox>
                    </Col>
                </Row>
            </Wrapper>
            <BlogSubscription
                show={subscForm}
                closeSubForm={() => setSubscForm(false)}
                formId={1404}
                page="team-builder"
            />
        </>
    );
};

const Wrapper = styled.div`
    ${Media('xlscreens')}{
        padding:0px 7vw;
    }
    ${Media('tablet')}{
        padding: 0px 15px;
    }
`;

const TeamBuilderBox = styled.div``;

const TeamBuilderHeader = styled.div`
    background-color: ${colors.fintech['text']};
    border-radius: 32px;
    padding: 30px 50px 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${Media('xlscreens')} {
        padding: 1.5vw 2.6vw 3.85vw;
    }
    ${Media('tabletlandscape')} {
        flex-direction: column;
    }
    ${Media('mobile')} {
        padding-left: 30px;
        padding-right: 30px;
    }
`;

const TeamBuilderLogo = styled.div`
    display: flex;
    align-items: center;
    h3 {
        font-size: 40px;
        margin: 0;
        margin-left: 30px;
        font-family: ${gilroyBold};
        color: ${colors.white['100']};
    }
    ${Media('xlscreens')} {
        h3 {
            font-size: 2vw;
            margin-left: 1.5vw;
        }
        img {
            max-width: 3.3vw;
        }
    }
    ${Media('mobile')} {
        h3 {
            font-size: 24px;
            margin-left: 15px;
        }
        img {
            max-width: 40px;
        }
    }
`;

const HeaderInfoText = styled.p`
    font-size: 20px;
    color: ${colors.white['100']};
    font-family: ${sourceSansPro};
    ${Media('xlscreens')} {
        font-size: 1vw;
    }
    ${Media('tabletlandscape')} {
        margin: 15px 0 0;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;

const TeamBuilderContent = styled.div`
    background-color: ${colors.white['100']};
    border-radius: 16px;
    position: relative;
    top: -40px;
    box-shadow: 0px 24px 100px ${colors.black['10']};
    ${Media('xlscreens')} {
        top: -2.5vw;
    }
`;
const TeamBuilderInner = styled.div`
    ${Media('tabletlandscape')} {
        border-bottom: ${(props) =>
        !props.last && `1px solid ${colors.staffAug['border']}`};
        margin-bottom: ${(props) => !props.last && `20px`};
        padding-bottom: ${(props) => !props.last && `20px`};
    }
`;
const TeamSubmitBtn = styled.div`
    position: absolute;
    bottom: 70px;
    right: 40px;
    ${Media('xlscreens')} {
        bottom: 2.2vw;
        right: 2vw;
        a {
            min-width: 7vw;
        }
    }
    ${Media('tabletlandscape')} {
        position: static;
        text-align: center;
        padding-top: 20px;
    }
`;
const BuilderOptionsList = styled.div`
    position: relative;
    padding: 30px 50px;
    border-radius: 16px;
    background-color: ${colors.white['100']};
    box-shadow: 0px 24px 100px ${colors.black['10']};
    ${Media('xlscreens')} {
        padding: 1.5vw 2.6vw;
    }
    ${Media('mobile')} {
        padding-left: 30px;
        padding-right: 30px;
    }
`;

const OptionsList = styled.div`
    position: relative;
    padding: 15px 0;
    padding-right: 25px;
    min-height: 60px;
    width: 100%;
    display: ${(props) => (props.hide ? 'none' : 'flex')};
    align-items: center;
    &:before {
        content: '';
        width: 1px;
        height: 100%;
        background-color: ${colors.staffAug['border']};
        position: absolute;
        right: 0;
        top: 0;
        ${(props) => props.last && 'display: none;'}
    }
    ${Media('xlscreens')} {
        padding: 0.75vw 0;
        padding-right: 1.3vw;
        min-height: 3.44vw;
    }
    ${Media('tablet')} {
        padding-right: 0px;
    }
    ${Media('tabletlandscape')} {
        display: flex;
        ${(props) => props.heading && 'padding-bottom: 0'};
        min-height: 0;
        &:before {
            display: none;
        }
    }

    ${Media('mobile')} {
        padding-top: 10px;
        padding-bottom: 10px;
        ${(props) => props.heading && 'padding-bottom: 0'};
    }
`;

const CountNum = styled.span`
    background-color: ${colors.staffAug['lightGrey']};
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    font-size: 15px;
    font-family: ${gilroyMedium};
    color: ${colors.fintech['50']};
    align-items: center;
    margin-right: 15px;
    ${Media('xlscreens')} {
        height: 1.25vw;
        width: 1.25vw;
        font-size: 0.78vw;
        margin-right: 0.78vw;
    }
    ${Media('mobile')} {
        font-size: 12px;
    }
`;
const OptionHeading = styled.span`
    display: inline-flex;
    font-family: ${sourceSansPro};
    color: ${colors.fintech['text']};
    font-size: 18px;
    font-weight: bold;
    ${Media('xlscreens')} {
        font-size: 0.95vw;
    }
    ${Media('mobile')} {
        font-size: 16px;
    }
`;
const OptionsDropdown = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    span {
        font-size: 18px;
        font-family: ${sourceSansPro};
        color: ${colors.fintech['text']};
    }
    ${Media('xlscreens')} {
        span {
            font-size: 0.95vw;
        }
    }
    ${Media('mobile')} {
        span {
            font-size: 16px;
        }
    }
`;
const SelectItems = styled.div`
    position: relative;
    flex: 1;
    min-width: 0;
`;
const OptionsValue = styled.div`
    font-size: ${(props) => {
        if (props.textStyle === 'large' && !props.showPlaceholder) {
            return '26px';
        } else if (props.textStyle === 'medium' && !props.showPlaceholder) {
            return '21px';
        } else {
            return '18px';
        }
    }};
    font-family: ${sourceSansPro};
    color: ${(props) =>
        props.color && !props.showPlaceholder
            ? props.color
            : colors.fintech['text']};
    font-weight: 400;
    position: relative;
    width: 100%;
    text-transform: capitalize;
    cursor: ${(props) => (props.noArrow ? 'initial' : 'pointer')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 28px;
    line-height: 26px;
    img {
        float: left;
        max-width: 52px;
        margin-right: 15px;
    }
    &:before {
        content: '${(props) => props.placeholderText}';
        opacity: ${(props) => (props.showPlaceholder ? '0.5' : '0')};
        ${(props) => !props.showPlaceholder && 'display: none'};
    }
    &:after {
        content: '';
        position: absolute;
        width: 14px;
        height: 10px;
        background-image: url('${baseURL}assets/images/staff_aug/down-arrow-btn.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        ${(props) => props.noArrow && 'display: none'};
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    ${Media('xlscreens')} {
        font-size: ${(props) => {
        if (props.textStyle === 'large' && !props.showPlaceholder) {
            return '1.35vw';
        } else if (props.textStyle === 'medium' && !props.showPlaceholder) {
            return '1.1vw';
        } else {
            return '0.95vw';
        }
    }};
        padding-right: 1.5vw;
        line-height: 1.35vw;
        &:after {
            width: 0.73vw;
            height: 0.53vw;
        }
        img {
            margin-right: 0.78vw;
            max-width: 2.7vw;
        }
    }
    ${Media('mobile')} {
        font-size: ${(props) => {
        if (props.textStyle === 'large' && !props.showPlaceholder) {
            return '21px';
        } else if (props.textStyle === 'medium' && !props.showPlaceholder) {
            return '18px';
        } else {
            return '16px';
        }
    }};
    }
`;
const OptionsAddBtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    ${Media('xlscreens')} {
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
    }
`;
const OptionsAddBtn = styled.a`
    width: 60%;
    height: 58px;
    border: 2px dashed ${colors.gray_light['100']};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-radius: 5px;
    font-family: ${sourceSansPro};
    color: ${colors.fintech['80']};
    background-color: ${colors.white['50']};
    &:hover {
        border-style: solid;
        border-color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        height: 3vw;
        font-size: 0.95vw;
    }
`;
const OptionsOverlay = styled.div`
    position: fixed;
    z-index: 998;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.showDropdown ? '1' : '0')};
    visibility: ${(props) => (props.showDropdown ? 'visible' : 'hidden')};
`;
const OptionsNoData = styled.div`
    text-align: center;
    font-size: 14px;
    font-family: ${sourceSansPro};
    color: ${colors.fintech['80']};
    ${Media('xlscreens')} {
        font-size: 0.78vw;
    }
`;
const DropDownBox = styled.div`
    background-color: ${colors.white['100']};
    border-radius: 30px;
    padding: 30px 20px;
    position: absolute;
    z-index: 999;
    top: 30px;
    left: -11px;
    width: 100%;
    height: auto;
    opacity: ${(props) => (props.showDropdown ? '1' : '0')};
    visibility: ${(props) => (props.showDropdown ? 'visible' : 'hidden')};
    box-shadow: 0px 10px 20px ${colors.black['10']};
    ${Media('xlscreens')} {
        padding: 1.5vw 1vw;
        top: 2vw;
        left: -0.5vw;
    }
`;

const SearchBox = styled.div`
    position: relative;
`;

const DropDownListWrap = styled.div`
    max-height: 350px;
    overflow-y: auto;
    margin-top: 30px;
    padding-right: 8px;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: ${colors.gray_lightest['100']};
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: ${colors.pink['100']};
        border-radius: 10px;
    }
    ${Media('xlscreens')} {
        max-height: 18.3vw;
        margin-top: 1.8vw;
    }
`;

const DropDownList = styled.div`
    padding: 0;
    margin-bottom: 30px;
    a {
        color: ${colors.fintech['80']};
        font-size: 16px;
        font-family: ${sourceSansPro};
        font-weight: ${(props) => (props.semiBold ? '600' : '400')};
        display: flex;
        align-items: center;
        text-transform: capitalize;
        img {
            margin: 10px 15px;
            margin-left: 0;
        }
        &:hover {
            color: ${colors.pink['100']};
        }
        ${Media('xlscreens')} {
            font-size: 0.88vw;
            padding: 0.05vw 0;
            img {
                margin: 0.52vw 0.78vw;
                margin-left: 0;
            }
        }
    }
    span {
        font-size: 18px;
        font-family: ${sourceSansPro};
        font-weight: 700;
        display: block;
        margin-bottom: 10px;
        opacity: 0.8;
        ${Media('xlscreens')} {
            font-size: 0.95vw;
            margin-bottom: 0.5vw;
        }
    }
    ${Media('xlscreens')} {
        margin-bottom: 1.5vw;
    }
`;
const SearchIcon = styled.img`
    position: absolute;
    width: 15px;
    left: 15px;
    top: 10px;
    ${Media('xlscreens')} {
        width: 0.78vw;
        left: 0.78vw;
        top: 0.52vw;
    }
`;
const SelectBox = styled.input`
    border: 1px solid #8f8198;
    border-radius: 72px;
    height: 33px;
    padding: 10px;
    width: 100%;
    position: relative;
    padding-left: 40px;
    ${Media('xlscreens')} {
        padding-left: 2vw;
        height: 1.71vw;
    }
`;

const CustomCol = styled.div`
    width: ${(props) => props.width && props.width};
    padding: 0px 8px;
    ${Media('xlscreens')} {
        padding: 0 0.5vw;
    }
    ${Media('tablet')} {
        width: 100%;
        margin-bottom: 15px;
    }
`;
