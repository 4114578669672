import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import { gilroyMedium, gilroyBold } from 'shared-components/theme/fonts';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';

const data = [
    {
        imageUrl: `${baseURL}assets/images/bundledealer_zip.svg`,
        imageWidth: '70vw',
        title: 'Search',
        marginTop: null,
        desc:
            'Run a simple zip code search in to find all the internet, phone and cable providers in your area.',
    },
    {
        imageUrl: `${baseURL}assets/images/bundledealer_compare.svg`,
        imageWidth: '80vw',
        title: 'Compare',
        marginTop: '-4.7vw',
        desc:
            'Weigh your needs, compare the plans & pricing of every residential + business provider near you, and narrow down your options.',
    },
    {
        imageUrl: `${baseURL}assets/images/bundledealer_order.svg`,
        imageWidth: '61vw',
        title: 'Order',
        marginTop: null,
        desc:
            'Connect to your chosen provider and order the service via a quick phone call. Sit back and relax as your home is upgraded to the best of the best.',
    },
];

export const SearchCompareOrder = () => {
    return (
        <Wrap>
            <Container>
                <div>
                    <BoxGeneratedValue>
                        <RowCentered>
                            {data && data.length > 0
                                ? data.map((item, index) => {
                                      return (
                                          <CustCol
                                              lg="4"
                                              key={index}
                                              last={data.length === index + 1}>
                                              <Row>
                                                  <BoxGeneratedValueTitle>
                                                      <SearchCompareIconHolder
                                                          marginTop={
                                                              item.marginTop
                                                          }>
                                                          <img
                                                              src={
                                                                  item.imageUrl
                                                              }
                                                              width={
                                                                  item.imageWidth
                                                              }
                                                          />
                                                      </SearchCompareIconHolder>
                                                      {item.title}
                                                  </BoxGeneratedValueTitle>
                                              </Row>
                                              <Row>
                                                  <BoxGeneratedValueText>
                                                      {item.desc}
                                                  </BoxGeneratedValueText>
                                              </Row>
                                          </CustCol>
                                      );
                                  })
                                : null}
                        </RowCentered>
                    </BoxGeneratedValue>
                </div>
            </Container>
        </Wrap>
    );
};

const Wrap = styled.section`
    background: linear-gradient(180deg, #f5f5f7 66%, #fafafa 30%);
    padding-bottom: 1vw;
    ${Media('tablet')} {
        padding-bottom: 20px;
        [class^='col'] {
            border: none;
        }
    }
`;

const SearchCompareIconHolder = styled.div`
    position: absolute;
    margin: -5.7vw auto;
    width: 100%;
    text-align: center;
    margin-top: ${(props) => (props.marginTop && props.marginTop) || '-5.7vw'};
    img {
        display: inline-block;
    }
    ${Media('tablet')} {
        margin: 0 auto 10px;
        position: static;
    }
`;

const BoxGeneratedValueTitle = styled.div`
    font-family: ${gilroyBold};
    font-size: 1.6vw;
    padding-top: 3.3vw;
    text-align: center;
    width: 100%;
    position: relative;
    ${Media('tablet')} {
        font-size: 24px;
        padding-top: 30px;
    }
`;

const BoxGeneratedValueText = styled.div`
    font-family: ${gilroyMedium};
    font-size: 0.8vw;
    text-align: center;
    width: 100%;
    line-height: 1.4vw;
    padding: 1vw 4vw;
    padding-bottom: 3.2vw;
    ${Media('tablet')} {
        font-size: 14px;
        line-height: 1.4;
        padding: 20px 30px;
        padding-bottom: 50px;
    }
`;

const BoxGeneratedValue = styled.div`
    border-radius: 21px;
    border: 1px ${colors.gray['border']} solid;
    background: ${colors.white['100']};
    margin: 2.8vw;
    margin-bottom: 0vw;
    ${Media('tablet')} {
        margin: 0 30px;
        overflow: hidden;
    }
`;

const CustCol = styled(Col)`
    border-right: ${(props) =>
        props.last ? 'none' : `1px solid ${colors.gray['lighter']}`};
`;
