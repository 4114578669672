import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Layout from 'components/layout';
import JobBox from 'components/careers/jobbox/jobbox';
import { FaSearch } from 'react-icons/fa';
import { baseURL, baseURLPage } from 'shared-components/theme/helpers';
import fetch from 'cross-fetch';
import Loader from 'components/loader/loader';
import Input from 'shared-components/input/input';
import RetailIntro from 'components/retail/intro-section';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import Media from 'shared-components/theme/medias';
import { gilroyBold } from 'shared-components/theme/fonts';

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const getCountryFullName = (country) => {
    switch (country) {
        case 'usa':
            return country.toUpperCase();
        default:
            return capitalizeFirstLetter(country);
    }
};

const UsaPage = () => {
    const router = useParams();

    const [jobsLoading, setJobsLoading] = useState(true);
    const [formattedJobs, setFormattedJobs] = useState([]);
    const [stalejobs, setStaleJobs] = useState([]);
    const [catList, setCatList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [locationList, setLocList] = useState([]);
    const [search, setSearch] = useState('');
    const [categoryVal, setCategoryVal] = useState(null);
    const [typeVal, setTypeVal] = useState(null);
    const [locVal, setLocVal] = useState(null);
    useEffect(() => {
        getJobs();
    }, []);

    const getJobs = async () => {
        try {
            const res = await fetch(
                `${process.env.REACT_APP_IBEX_API}/icims-listing?token=${process.env.REACT_APP_API_TOKEN}&country=${router.country}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                setStaleJobs(resData.data);
                handleDropdownList(resData.data);
                setJobsLoading(false);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleDropdownList = (data) => {
        const category = [];
        const type = [];
        const location = [];
        data.filter((item) => {
            var loc = item.details.header.filter(
                (locate) =>
                    locate.label === 'Location' ||
                    locate.label === 'Job Locations'
            );
            var cat = item.details.header.filter((locate) =>
                locate.label.includes('Job Family')
            );
            location.push(loc[0].value);
            category.push(cat[0].value);
            type.push(item.field28138.value);
        });

        const newLocStr = location.filter((item, index) => {
            return location.indexOf(item) === index;
        });
        const newCatStr = category.filter((item, index) => {
            return category.indexOf(item) === index;
        });
        const newTypeStr = type.filter((item, index) => {
            return type.indexOf(item) === index;
        });
        const catLocArr = newLocStr.map((item) => {
            return { value: item, label: item };
        });
        const catListArr = newCatStr.map((item) => {
            return { value: item, label: item };
        });
        const typeListArr = newTypeStr.map((item) => {
            return { value: item, label: item };
        });
        handleJobGroup(catListArr, data);
        setLocList(catLocArr);
        setCatList(catListArr);
        setTypeList(typeListArr);
    };

    const handleJobGroup = (catListArr, data) => {
        const jobListing = [];
        catListArr &&
            catListArr.length > 0 &&
            catListArr.map((item) => {
                const relatedJobs = [];
                data.map((d) => {
                    d.details.header.map((d2) => {
                        if (
                            d2.label.includes('Job Family') &&
                            d2.value === item.label
                        ) {
                            relatedJobs.push(d);
                        }
                    });
                });
                if (relatedJobs && relatedJobs.length > 0) {
                    jobListing.push({
                        category: item.label,
                        jobs: relatedJobs,
                    });
                }
            });
        setFormattedJobs(jobListing);
    };

    const handleSearch = (value) => {
        setSearch(value);
        handleSearchFiltered('search', value);
    };
    const handleLocation = (value) => {
        var selectedLoc = value === 'all' ? '' : value;
        setLocVal(selectedLoc);
        handleSearchFiltered('location', selectedLoc);
    };
    const handleCategory = (value) => {
        var selectedCat = value === 'all' ? '' : value;
        setCategoryVal(selectedCat);
        handleSearchFiltered('category', selectedCat);
    };
    const handleType = (value) => {
        var selectedType = value === 'all' ? '' : value;
        setTypeVal(selectedType);
        handleSearchFiltered('type', selectedType);
    };

    const checkFilter = (value, check) => {
        if (value === check || value === '') {
            return true;
        }
    };

    const handleSearchFiltered = (types, values) => {
        const filteredArray = stalejobs.filter((item) => {
            var loc = item.details.header.filter(
                (locate) =>
                    locate.label === 'Location' ||
                    locate.label === 'Job Locations'
            );
            var cat = item.details.header.filter((locate) =>
                locate.label.includes('Job Family')
            );
            let newFind = item.details.header[0].value.toLowerCase();
            let newLoc = loc[0].value;
            let newCat = cat[0].value;
            let newType = item.field28138.value;
            if (
                newFind.includes(
                    types === 'search' ? values.toLowerCase() : search
                ) &&
                checkFilter(
                    types === 'location'
                        ? values
                        : locVal === null
                        ? ''
                        : locVal,
                    newLoc
                ) &&
                checkFilter(
                    types === 'category'
                        ? values
                        : categoryVal === null
                        ? ''
                        : categoryVal,
                    newCat
                ) &&
                checkFilter(
                    types === 'type' ? values : typeVal === null ? '' : typeVal,
                    newType
                )
            ) {
                return true;
            }
        });
        handleJobGroup(catList, filteredArray);
    };

    return (
        <Layout
            title={'ibex Careers'}
            og_description="We are an elite CX outsourcer for the world's startups, scale-ups, and blue-chips."
            page="">
            <Breadcrumbs>
                <li>
                    <a href={`${baseURLPage}join-us`}>Join Us</a>
                </li>
                <li>{getCountryFullName(router.country)}</li>
            </Breadcrumbs>
            <RetailIntro
                rightImg={`${baseURL}assets/images/industries/retail-intro-bg.png`}
                tag={getCountryFullName(router.country)}
                introHeading="Apply today to <br/>launch your career!"
                introPara="Interested in a career at one of the most innovative and fastest-growing <br/>customer engagement companies on the planet? Take a look at our <br/>openings and get in touch now. If you don’t see anything listed, feel free <br/>to reach out anyway, and tell us why you might be a good fit."
                right="-15%"
                top="-18vw"
                imgWidth={'50%'}
                noButton
                noPaddingBottom
                introImgHideTab
            />

            <JobSearchSection>
                <Container>
                    <Row>
                        <Col lg="4">
                            <SearchInputWrap>
                                <FaSearch />
                                <Input
                                    label="Search"
                                    name="Search"
                                    value={search}
                                    onChange={(e) =>
                                        handleSearch(e.target.value)
                                    }
                                />
                            </SearchInputWrap>
                        </Col>
                        <Col lg="8">
                            <Row>
                                <Col lg="4">
                                    <Input
                                        label="Location"
                                        name="location"
                                        select
                                        isAll
                                        options={locationList}
                                        value={
                                            locVal === null
                                                ? ''
                                                : locVal === ''
                                                ? 'all'
                                                : locVal
                                        }
                                        onChange={(e) =>
                                            handleLocation(e.target.value)
                                        }
                                    />
                                </Col>
                                <Col lg="4">
                                    <Input
                                        label="Category"
                                        name="category"
                                        select
                                        isAll
                                        options={catList}
                                        value={
                                            categoryVal === null
                                                ? ''
                                                : categoryVal === ''
                                                ? 'all'
                                                : categoryVal
                                        }
                                        onChange={(e) =>
                                            handleCategory(e.target.value)
                                        }
                                    />
                                </Col>
                                <Col lg="4">
                                    <Input
                                        label="Position Type"
                                        name="position_type"
                                        select
                                        isAll
                                        options={typeList}
                                        value={
                                            typeVal === null
                                                ? ''
                                                : typeVal === ''
                                                ? 'all'
                                                : typeVal
                                        }
                                        onChange={(e) =>
                                            handleType(e.target.value)
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {jobsLoading ? (
                        <Loader size="small" />
                    ) : !jobsLoading &&
                      formattedJobs &&
                      formattedJobs.length > 0 ? (
                        <JobContentBox>
                            {formattedJobs.map((items, index) => {
                                if (
                                    items &&
                                    items.jobs &&
                                    items.jobs.length > 0
                                ) {
                                    return (
                                        <JobsFamily key={index}>
                                            <FamilyTitle>
                                                {items.category}
                                            </FamilyTitle>
                                            <Row>
                                                {items.jobs.map(
                                                    (job, index2) => {
                                                        return (
                                                            <Col
                                                                lg="4"
                                                                md="6"
                                                                key={index2}>
                                                                <JobBox
                                                                    job={job}
                                                                />
                                                            </Col>
                                                        );
                                                    }
                                                )}
                                            </Row>
                                        </JobsFamily>
                                    );
                                }
                            })}
                        </JobContentBox>
                    ) : (
                        <p>
                            We do not have any job opening available for the
                            keywords that you entered.
                        </p>
                    )}
                </Container>
            </JobSearchSection>
        </Layout>
    );
};

const JobsFamily = styled.div`
    margin-bottom: 70px;
`;

const FamilyTitle = styled.h2`
    font-size: 30px;
    font-family: ${gilroyBold};
    ${Media('xlscreens')} {
        font-size: 2vw;
    }
`;

const JobSearchSection = styled.section`
    padding-top: 0px;
`;
const JobContentBox = styled.div`
    margin-top: 40px;
    ${Media('xlscreens')} {
        margin-top: 4vw;
    }
`;

const SearchInputWrap = styled.div`
    position: relative;
    svg {
        position: absolute;
        right: 1rem;
        bottom: 0.8rem;
        font-size: 1rem;
    }
    .form-group {
        input {
            padding-right: 80px;
        }
    }
`;

export default UsaPage;
