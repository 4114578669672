import React from 'react';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import {
    ContainerWideSRM,
    SRMCallActionSection,
} from 'components/social-rep-man/common';
import styled from 'styled-components';
import { baseURL } from 'shared-components/theme/helpers';
import { gilroyMedium, gilroySemibold } from 'shared-components/theme/fonts';
import { StrategySection } from 'components/technology';

export const IntegrationBottomSection = ({
    strategyContent,
    setDemoRequestForm,
}) => {
    return (
        <>
            <ContainerWideSRM>
                <IntegrationSection>
                    <SolutionTitle align="center">Integrations</SolutionTitle>
                    <SolutionPara align="center">
                        Easily connect to information from third-party
                        applications, including your CRM, to improve and
                        optimize routing decisions. Determine customer intent,
                        ensure <br />
                        callers are matched with the appropriate agents, and
                        empower your people with the customer information they
                        need to deliver highly personalized experiences.
                    </SolutionPara>
                    <IntegrationImg>
                        <img
                            src={`${baseURL}assets/images/tech/flexcx-integration-img.svg`}
                            alt="-"
                        />
                    </IntegrationImg>
                </IntegrationSection>
            </ContainerWideSRM>
            <StrategySection
                heading="Turn customers into evangelists <br/>and agents into all-stars"
                para="FleCX is ready to launch quickly and get you engaging with your customers in just a few clicks. "
                strategyContent={strategyContent}
                mediumPara
            />
            <SRMCallActionSection
                heading="Ready to launch your first agile cloud CRM?"
                para="Better CX is just a click away."
                btnText="Start Now"
                onBtnClick={() => setDemoRequestForm(true)}
            />
        </>
    );
};

const SolutionTitle = styled.h2`
    font-size: 32px;
    font-family: ${gilroySemibold};
    line-height: 1.45;
    text-align: ${(props) => (props.align ? props.align : 'left')};
    margin-top: 0;
    ${Media('xlscreens')} {
        font-size: 2.08vw;
    }
    ${Media('tablet')} {
        text-align: center;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 20px;
    }
`;
const SolutionPara = styled.p`
    font-size: 16px;
    font-family: ${gilroyMedium};
    line-height: 2;
    text-align: ${(props) => (props.align ? props.align : 'left')};
    ${Media('xlscreens')} {
        font-size: 0.93vw;
    }
    ${Media('tablet')} {
        text-align: center;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
const IntegrationSection = styled.section`
    background-color: ${colors.gray_lightest['100']};
    padding: 50px;
    ${Media('mobile')} {
        padding: 30px 20px;
    }
`;
const IntegrationImg = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 100px;
    ${Media('xlscreens')} {
        margin-top: 5.2vw;
    }
    ${Media('mobile')} {
        margin-top: 50px;
    }
`;
