import React, { useEffect, useRef, useState } from 'react';
import { EbookScrollDown } from 'components/ebook/common';
import { FlexColumnCentered } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import { gilroyBold, gilroyMedium } from 'shared-components/theme/fonts';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { Para } from './para';
import ReactHtmlParser from 'react-html-parser';

export const WaveXIntro = ({
    pageTag,
    pageHeading,
    pagePara,
    introImg,
    from,
    to,
    gradientImg,
    imgHeight,
    imgWidth,
}) => {
    const gradientRef = useRef(null);
    const [screenWidth, setScreenWidth] = useState('');
    const [gradientAnim, setGradientAnim] = useState(false);
    useEffect(() => {
        var controller = gradientRef.current;
        setScreenWidth(window.innerWidth);
        if (controller !== null && screenWidth > 1000) {
            window.onscroll = () => {
                var topPosition = document.body.getBoundingClientRect().top;
                if (topPosition < -200) {
                    setGradientAnim(true);
                } else {
                    setGradientAnim(false);
                }
            };
        }
    }, [screenWidth]);
    return (
        <Wrapper ref={gradientRef}>
            {!gradientAnim && (
                <Controller>
                    <Scene
                        triggerHook="onLeave"
                        duration="30%"
                        enabled={screenWidth < 1000 ? false : true}
                        offset={'-150%'}>
                        <Timeline>
                            <Tween from={from} to={to}>
                                <GradientBg
                                    imgHeight={imgHeight}
                                    imgWidth={imgWidth}>
                                    <img src={gradientImg} alt="-" />
                                </GradientBg>
                            </Tween>
                        </Timeline>
                    </Scene>
                </Controller>
            )}
            <Controller>
                <Scene
                    triggerElement=".wavex-intro-info"
                    triggerHook="onLeave"
                    duration="30%"
                    enabled={screenWidth < 1000 ? false : true}
                    offset={'-70%'}>
                    <Timeline>
                        <Tween
                            from={{
                                opacity: 1,
                            }}
                            to={{
                                opacity: 0,
                            }}>
                            <IntroInfo className="wavex-intro-info">
                                <PageTitle>{pageTag}</PageTitle>
                                <PageHeading>
                                    {ReactHtmlParser(pageHeading)}
                                </PageHeading>
                                <IntroPara>
                                    <Para>{ReactHtmlParser(pagePara)}</Para>
                                </IntroPara>
                                <ScrollDownWrap>
                                    <EbookScrollDown
                                        animate
                                        bgColor={'transparent'}
                                    />
                                    <p>Scroll Down</p>
                                </ScrollDownWrap>
                            </IntroInfo>
                        </Tween>
                    </Timeline>
                </Scene>
            </Controller>
            {gradientAnim && (
                <Controller>
                    <Scene triggerHook="onLeave" duration="30%" offset="100%">
                        <IntroImg imgHeight={imgHeight} imgWidth={imgWidth}>
                            <Controller>
                                <Scene
                                    triggerHook="onLeave"
                                    duration="30%"
                                    enabled={screenWidth < 1000 ? false : true}
                                    offset="100%">
                                    <Timeline>
                                        <Tween
                                            from={{
                                                transform:
                                                    'translateY(18vw) scale(1.7)',
                                            }}
                                            to={{
                                                transform:
                                                    'translateY(33vw) scale(1)',
                                            }}>
                                            <img src={introImg} alt="-" />
                                        </Tween>
                                    </Timeline>
                                </Scene>
                            </Controller>
                            <Controller>
                                <Scene
                                    triggerHook="onLeave"
                                    duration="30%"
                                    enabled={screenWidth < 1000 ? false : true}
                                    offset="100%">
                                    <Timeline>
                                        <Tween
                                            from={{
                                                opacity: 1,
                                                transform:
                                                    'translateY(18vw) scale(1.7)',
                                            }}
                                            to={{
                                                opacity: 0,
                                                transform:
                                                    'translateY(33vw) scale(1)',
                                            }}>
                                            <IntroImgMask
                                                imgHeight={imgHeight}
                                                imgWidth={imgWidth}>
                                                <img
                                                    src={gradientImg}
                                                    alt="-"
                                                />
                                            </IntroImgMask>
                                        </Tween>
                                    </Timeline>
                                </Scene>
                            </Controller>
                        </IntroImg>
                    </Scene>
                </Controller>
            )}
        </Wrapper>
    );
};
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    ${Media('xlscreens')} {
        height: 67vw;
    }
    ${Media('tablet')} {
        height: 580px;
        margin-top: 50px;
    }
    ${Media('mobile')} {
        height: 500px;
    }
`;
const IntroImg = styled.div`
    position: relative;
    margin: 0 auto;
    & > div {
        width: inherit;
        height: inherit;
    }
    & > div:nth-of-type(2) {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
    }
    img {
        height: ${(props) =>
            props.imgHeight ? props.imgHeight + 'px' : '560px'};
        width: ${(props) => (props.imgWidth ? props.imgWidth + 'px' : '520px')};
        object-fit: contain;
    }
    ${Media('xlscreens')} {
        img {
            height: ${(props) =>
                props.imgHeight ? props.imgHeight / 19.2 + 'vw' : '29vw'};
            width: ${(props) =>
                props.imgWidth ? props.imgWidth / 19.2 + 'vw' : '26vw'};
        }
    }
    ${Media('tablet')} {
        img {
            height: 250px;
            width: 250px;
        }
    }
`;
const IntroImgMask = styled.div`
    height: ${(props) => (props.imgHeight ? props.imgHeight + 'px' : '560px')};
    width: ${(props) => (props.imgWidth ? props.imgWidth + 'px' : '520px')};
    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    ${Media('xlscreens')} {
        height: ${(props) =>
            props.imgHeight ? props.imgHeight / 19.2 + 'vw' : '29vw'};
        width: ${(props) =>
            props.imgWidth ? props.imgWidth / 19.2 + 'vw' : '26vw'};
    }
    ${Media('tablet')} {
        height: 300px;
        width: 300px;
    }
`;
const PageTitle = styled.div`
    font-size: 22px;
    font-family: ${gilroyBold};
    ${Media('xlscreens')} {
        font-size: 1.14vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
const PageHeading = styled.h2`
    font-size: 100px;
    font-family: ${gilroyBold};
    line-height: 1.16;
    margin: 0;
    ${Media('xlscreens')} {
        font-size: 5.2vw;
    }
    ${Media('tablet')} {
        font-size: 40px;
        margin-top: 10px;
    }
    ${Media('mobile')} {
        font-size: 24px;
    }
`;
const IntroPara = styled.div`
    margin-top: 130px;
    ${Media('xlscreens')} {
        margin-top: 10vw;
    }
`;
const ScrollDownWrap = styled(FlexColumnCentered)`
    position: relative;
    p {
        font-size: 16px;
        font-family: ${gilroyMedium};
    }
    ${Media('xlscreens')} {
        top: -1.4vw;
        p {
            font-size: 0.83vw;
            margin-top: 0;
        }
    }
    ${Media('mobile')} {
        p {
            font-size: 14px;
        }
    }
`;
const IntroInfo = styled.div`
    text-align: center;
    position: absolute;
    z-index: 99;
    width: 100%;
    top: 80px;
    ${PageTitle} {
        color: inherit;
    }
    ${PageHeading} {
        color: inherit;
    }
    ${IntroPara} {
        p {
            color: inherit;
        }
    }
    ${ScrollDownWrap} {
        color: inherit;
        path {
            fill: inherit;
        }
        p {
            color: inherit;
        }
    }
    ${Media('xlscreens')} {
        top: 9vw;
    }
    ${Media('tablet')} {
        padding: 0px 50px;
        box-sizing: border-box;
    }
    ${Media('mobile')} {
        padding: 0px 18px;
        top: 80px;
    }
`;

const GradientBg = styled.div`
    margin: 0 auto;
    height: ${(props) => (props.imgHeight ? props.imgHeight + 'px' : '560px')};
    width: ${(props) => (props.imgWidth ? props.imgWidth + 'px' : '520px')};
    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    ${Media('xlscreens')} {
        height: ${(props) =>
            props.imgHeight ? props.imgHeight / 19.2 + 'vw' : '29vw'};
        width: ${(props) =>
            props.imgWidth ? props.imgWidth / 19.2 + 'vw' : '26vw'};
    }
    ${Media('tablet')} {
        height: 300px;
        width: 300px;
    }
    ${Media('mobile')} {
        height: 250px;
        width: 250px;
    }
`;
