import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import colors from '../theme/colors';
import Media from '../theme/medias';

const ScrollNav = ({ data }) => {
    return (
        <Wrapper>
            {data !== undefined && data.length > 0
                ? data.map((item, index) => {
                    return (
                        <Dot key={index}>
                            <Link
                                activeClass="active"
                                to={item.target}
                                spy={true}
                                smooth={true}
                                offset={item.offset}
                                duration={1000}
                                key={index}
                            />
                        </Dot>
                    );
                })
                : null}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: fixed;
    width: 25px;
    height: 150px;
    top: calc(50% - 75px);
    left: 5vw;
    z-index: 999;
    ${Media('tablet')} {
        display: none;
    }
`;

const Dot = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    a {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: ${colors.gray_dark['100']};
        position: relative;
        border: 1px solid transparent;
        transition: all 220ms ease-in-out;
        cursor: pointer;
        ${Media('xlscreens')} {
            width: 0.27vw;
            height: 0.27vw;
        }
        &:before {
            content: '';
            position: absolute;
            width: 30%;
            height: 30%;
            left: calc(50% - 15%);
            top: calc(50% - 15%);
            background-color: ${colors.white['100']};
            opacity: 0;
            border-radius: 50%;
            transition: all 0.5s cubic-bezier(0.54, -0.18, 0.36, 1.26);
            transform: scale(0.5);
        }
        &.active {
            width: 22px;
            height: 22px;
            border-color: ${colors.pink['100']};
            &:before {
                opacity: 1;
                transform: scale(1);
            }
            ${Media('xlscreens')} {
                width: 1.2vw;
                height: 1.2vw;
            }
        }
    }
    &:hover {
        a {
            width: 22px;
            height: 22px;
            border: 1px solid ${colors.pink['100']};
            &:before {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
`;

export default ScrollNav;
