import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NotFoundPage from 'pages/not-found';
import Loader from 'components/loader/loader';
import RetailSeasonalPeak from './retail-seasonal-peak-cx';
import FintechCXPlaybook from './fintech-cx-digital-playbook';
import CryptoEbook from './crypto-cx';
import CreditUnionPart1 from './credit-union-digital-member-experience-part-1';
import CreditUnionPart2 from './credit-union-digital-member-experience-part-2';
import CommunityBankPart1 from './community-bank-digital-customer-experience-part-1';
import CommunityBankPart2 from './community-bank-digital-customer-experience-part-2';

import Layout from 'components/layout';
import {
    WebinarContentIntro,
    WebinarContentCTA,
} from 'components/webinars/content/common';
import CXPocketGuide from './cx-pocket-guide';
import ImperativeCX from './11-imperatives-for-great-cx';
import TopBIMethodologies from './top-BI-methodologies';

const EbooksIndex = () => {
    const router = useParams();
    const [pageError, setPageError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pageData, setPageData] = useState({});

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/ebooks?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        post_name: router.slug,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
                setPageError(true);
                setLoading(false);
            }
            const resData = await res.json();
            if (resData.status === 200 && resData.data.length !== 0) {
                setLoading(false);
                setPageData(resData.data[0]);
            } else {
                setPageError(true);
                setLoading(false);
            }
        } catch (err) {
            setPageError(true);
            setLoading(false);
        }
    };
    const RenderEbook = () => {
        if (!pageData.metadata.pdf_viewer) {
            if (pageData.post_name === 'retail-seasonal-peak-cx') {
                return <RetailSeasonalPeak />;
            } else if (pageData.post_name === 'fintech-cx-digital-playbook') {
                return <FintechCXPlaybook />;
            } else if (pageData.post_name === 'crypto-cx') {
                return <CryptoEbook />;
            } else if (
                pageData.post_name ===
                'credit-union-digital-member-experience-part-1'
            ) {
                return <CreditUnionPart1 />;
            } else if (
                pageData.post_name ===
                'credit-union-digital-member-experience-part-2'
            ) {
                return <CreditUnionPart2 />;
            } else if (
                pageData.post_name ===
                'community-bank-digital-customer-experience-part-1'
            ) {
                return <CommunityBankPart1 />;
            } else if (
                pageData.post_name ===
                'community-bank-digital-customer-experience-part-2'
            ) {
                return <CommunityBankPart2 />;
            } else if (
                pageData.post_name ===
                '7-best-practices-for-launching-a-winning-cx-program-fast'
            ) {
                return <CXPocketGuide />;
            } else if (pageData.post_name === '11-imperatives-for-great-cx') {
                return <ImperativeCX />;
            } else if (
                pageData.post_name ===
                'top-bi-methodologies-every-cx-program-must-have'
            ) {
                return <TopBIMethodologies />;
            }
        } else {
            return (
                <Layout>
                    <WebinarContentIntro
                        heading={pageData.post_title}
                        headingSize="small"
                        para={pageData.metadata.page_description}
                        pdfUrl={pageData.metadata.pdf_file_url}
                    />

                    <WebinarContentCTA
                        heading={pageData.metadata.call_to_action_heading}
                        subheading={
                            pageData.metadata.call_to_action_sub_heading
                        }
                        btnText={pageData.metadata.call_to_action_button_text}
                    />
                </Layout>
            );
        }
    };
    return (
        <>
            {loading && <Loader position="absolute" />}
            {pageError ? (
                <NotFoundPage />
            ) : (
                Object.keys(pageData).length !== 0 &&
                pageData !== undefined &&
                RenderEbook()
            )}
        </>
    );
};

export default EbooksIndex;
