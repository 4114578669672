import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from '@bootstrap-styled/v4';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { baseURL } from 'shared-components/theme/helpers';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import DemoRequestForm from 'components/social-rep-man/demo-request-form';
import SRMIntroSection from 'components/social-rep-man/intro-section';
import {
    ContainerWideSRM,
    SRMCallActionSection,
    SRMonitoringSection,
    SRMSectionHeading,
    SRMStrategyBox,
} from 'components/social-rep-man/common';
import MainPara from 'shared-components/main-para/main-para';
import Media from 'shared-components/theme/medias';
import VerticalCards from 'components/social-rep-man/vertical-cards';
import ScrollAnimation from 'react-animate-on-scroll';
import Loader from 'components/loader/loader';
import LazyLoad from 'react-lazyload';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -210,
    },
    {
        name: 'monitor',
        target: 'monitor',
        offset: 0,
    },
    {
        name: 'manage',
        target: 'manage',
        offset: 0,
    },
];

const monitorList = [
    {
        icon: `${baseURL}assets/images/tech/round-monitor-icon-1.svg`,
        title: 'Connect',
        para: 'Enhance remote agent performance',
    },
    {
        icon: `${baseURL}assets/images/tech/round-monitor-icon-2.svg`,
        title: 'Communicate',
        para: 'Boost communication between agents and supervisors',
    },
    {
        icon: `${baseURL}assets/images/tech/round-monitor-icon-3.svg`,
        title: 'Resolve',
        para: 'Identify and resolve potential issues faster',
    },
    {
        icon: `${baseURL}assets/images/tech/round-monitor-icon-4.svg`,
        title: 'Accelerate',
        para: 'Improve customer service and satisfaction',
    },
];

const manageContent = [
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/ibexround-monitor-tag-1.svg`,
        VerticalCardHeading: `Supervise multiple remote or on-site agents`,
        VerticalCardParagraph: `Monitor up to 12 agents in one window. Drag-and-drop to arrange agent screens to your liking. `,
        VerticalCardImage: `${baseURL}assets/images/tech/ibexround-monitor-img-1.svg`,
    },
];

const manageContent2 = [
    {
        ImageRight: false,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/ibexround-monitor-tag-2.svg`,
        VerticalCardHeading: `Coach agents to continuously improve`,
        VerticalCardParagraph: `Provide real-time assistance, and manage long-<br />term performance by making coaching requests, which trigger notifications to the agent. `,
        VerticalCardImage: `${baseURL}assets/images/tech/ibexround-monitor-img-2.svg`,
    },
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/ibexround-monitor-tag-3.svg`,
        VerticalCardHeading: `Get a direct line to your team`,
        VerticalCardParagraph: `Instantly communicate with each agent through seamless integration with ibex Messenger. `,
        VerticalCardImage: `${baseURL}assets/images/tech/ibexround-monitor-img-3.svg`,
    },
];

const strategyContent = [
    {
        iconUrl: `${baseURL}assets/images/tech/ibexround-strategy-icon-1.svg`,
        heading: 'Monitor call and chat activity ',
        para: `Check in on voice, chat, or screen activity across multiple agent desktops. Monitor and assess calls by hovering over an agent’s screen. `,
    },
    {
        iconUrl: `${baseURL}assets/images/tech/ibexround-strategy-icon-2.svg`,
        heading: 'Find agents in a flash ',
        para: `Easily locate agents with screen search, and create tailored views using custom search. `,
    },
    {
        iconUrl: `${baseURL}assets/images/tech/ibexround-strategy-icon-3.svg`,
        heading: 'Up the efficiency with saved views ',
        para: `Reduce admin time by quickly saving up to five views to return to later.`,
    },
    {
        iconUrl: `${baseURL}assets/images/tech/ibexround-strategy-icon-4.svg`,
        heading: `Review up-to-the-minute agent information `,
        para: `Access an agent’s profile, schedule, open requests, and scorecard in a single click. `,
    },
];

const TechIbexRound = () => {
    const [demoRequestForm, setDemoRequestForm] = useState(false);

    return (
        <Layout>
            <Breadcrumbs>
                <li>Wave X</li>
                <li>Technology</li>
            </Breadcrumbs>
            <DemoRequestForm
                handleDemoForm={(val) => setDemoRequestForm(val)}
                formState={demoRequestForm}
                marketFormId="1087"
            />

            <SRMIntroSection
                preheading="ibex Round"
                heading="Multi-screen, real-time agent performance monitoring"
                para="ibex Round provides complete multi-agent call & desktop monitoring, for complete oversight of your global customer support teams. "
                mainImg={`${baseURL}assets/images/tech/ibexround-intro.svg`}
                textureImg={`${baseURL}assets/images/tech/ibexround-texture.svg`}
                textureImgPosition="back"
                onBtnClick={() => setDemoRequestForm(true)}
            />
           
                <SRMonitoringSection
                    id="monitor"
                    heading="Real-Time @Home or In-Center Real-Time Agent Oversight"
                    paragraph="ibex Round is a powerful all-in-one platform that
                    helps supervisors and operational managers
                    simultaneously monitor voice, chat, and
                    screen interactions of every customer experience
                    agent, in-center or remote."
                    listData={monitorList}
                />
                <ManageSection id="manage">
                    <ContainerWideSRM>
                        <Row>
                            <Col sm="12">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={true}>
                                    <SRMSectionHeading>
                                        Smarter, faster agent management
                                    </SRMSectionHeading>
                                    <MainPara align="center">
                                        With real-time oversight of agent status
                                        and performance, supervisors and
                                        managers can maintain tight quality
                                        control and ensure consistent CX
                                        outcomes.
                                    </MainPara>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                        {manageContent &&
                            manageContent.length > 0 &&
                            manageContent.map((item, index) => {
                                return (
                                    <VerticalCards
                                        key={index}
                                        ImageRight={item.ImageRight}
                                        ImageRightColEqual={item.ImageRight}
                                        padTopSmall={item.padTopSmall}
                                        CardTagImg={item.CardTagImg}
                                        VerticalCardHeading={
                                            item.VerticalCardHeading
                                        }
                                        VerticalCardParagraph={
                                            item.VerticalCardParagraph
                                        }
                                        VerticalCardImage={
                                            item.VerticalCardImage
                                        }
                                        sideExtraSmall
                                    />
                                );
                            })}

                        {strategyContent && strategyContent.length > 0 && (
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}
                                delay={100}>
                                <StrategyRow>
                                    {strategyContent.map((item, index) => {
                                        return (
                                            <Col lg="6" key={index}>
                                                <SRMStrategyBox
                                                    icon={item.iconUrl}
                                                    heading={item.heading}
                                                    para={item.para}
                                                />
                                            </Col>
                                        );
                                    })}
                                </StrategyRow>
                            </ScrollAnimation>
                        )}

                        {manageContent2 &&
                            manageContent2.length > 0 &&
                            manageContent2.map((item, index) => {
                                return (
                                    <VerticalCards
                                        key={index}
                                        ImageRight={item.ImageRight}
                                        ImageRightColEqual={item.ImageRight}
                                        padTopSmall={item.padTopSmall}
                                        CardTagImg={item.CardTagImg}
                                        VerticalCardHeading={
                                            item.VerticalCardHeading
                                        }
                                        VerticalCardParagraph={
                                            item.VerticalCardParagraph
                                        }
                                        VerticalCardImage={
                                            item.VerticalCardImage
                                        }
                                        sideExtraSmall
                                    />
                                );
                            })}
                    </ContainerWideSRM>
                </ManageSection>
           
                <SRMCallActionSection
                    heading="A seamless platform for real-time multi-screen agent performance monitoring & support."
                    para="Ready to enhance in-center and @home agent performance?"
                    btnText="Book a Demo"
                    onBtnClick={() => setDemoRequestForm(true)}
                />
                <ScrollNav data={scrollNavData} />
        </Layout>
    );
};

const ManageSection = styled.section``;

const StrategyRow = styled(Row)`
    ${Media('xlscreens')} {
        margin-top: 5vw;
        margin-bottom: 2vw;
    }
    ${Media('tablet')} {
        padding: 0px 20px;
        margin-top: 50px;
    }
`;

export default TechIbexRound;
