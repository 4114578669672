import React from 'react';
import LazyLoad from 'react-lazyload';
import Layout from 'components/layout';
import HomeIntro from 'components/home-intro/home-intro';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import Loader from 'components/loader/loader';
import { HelpingSection, GlobalStatsSection } from './sections';
import { baseURL } from 'shared-components/theme/helpers';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -170,
    },
    {
        name: 'helping',
        target: 'helping',
        offset: 0,
    },
    {
        name: 'delivery',
        target: 'delivery',
        offset: 0,
    },
];

const lazySections = [
    {
        id: 'helping',
        section: <HelpingSection />,
    },
    {
        id: 'delivery',
        section: <GlobalStatsSection />,
    },
];

const Home = () => {
    return (
        <Layout page="home">
            <HomeIntro
                mainTitle="We connect the world’s leading brands to their customers"
                mainPara="We’re an elite CX outsourcer for the world’s startups, scale-ups, and blue-chips. We build powerful customer engagement & insight solutions to help protect client investment, mitigate financial & operational risk, and accelerate ROI."
                isHome={true}
                headingColumn={6}
                headingOffset={1}
                mainParaPadding={true}
                landingVideoUrl={`${baseURL}assets/videos/home-video.mp4`}
                landingVideoImgUrl={`${baseURL}assets/images/video-img.jpg`}
            />

            {lazySections && lazySections.length > 0
                ? lazySections.map((item, index) => {
                      return (
                          <div id={item.id} key={index}>
                                {lazySections.length === index + 1 ? (
                                    <ScrollNav data={scrollNavData} />
                                ) : null}
                                {item.section}
                          </div>
                      );
                  })
                : null}
        </Layout>
    );
};

export default Home;
