import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyBold, gilroyRegular } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ReactHtmlParser from 'react-html-parser';
import { baseURL } from 'shared-components/theme/helpers';

export const LandingPageDesc = ({
    heading,
    description,
    bullet_list,
    mobileContent,
    noMarginTop,
    textSize,
    rightPaddingSmall,
}) => {
    return (
        <Wrapper rightPaddingSmall={rightPaddingSmall}>
            <MainContentWrap noMarginTop={noMarginTop} size={textSize}>
                <h2>{ReactHtmlParser(heading)}</h2>
                {ReactHtmlParser(description)}
                <ul>
                    {bullet_list &&
                        bullet_list.length > 0 &&
                        bullet_list.map((item, index) => {
                            return (
                                <li key={index}>
                                    {item.icon ? (
                                        <img src={item.icon} alt="-" />
                                    ) : (
                                        <img
                                            src={`${baseURL}assets/images/bullet-list-icon.png`}
                                            alt="-"
                                        />
                                    )}
                                    {item.point}
                                </li>
                            );
                        })}
                </ul>
            </MainContentWrap>
            <MainParaMobile noMarginTop={noMarginTop}>
                {mobileContent && mobileContent !== undefined
                    ? ReactHtmlParser(mobileContent)
                    : ReactHtmlParser(description)}
            </MainParaMobile>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;
    padding: 50px 0px;
    ${Media('xlscreens')} {
        padding: 2.6vw 0;
        padding-top: 0px;
        padding-right: ${(props) => (props.rightPaddingSmall ? '1vw' : '2vw')};
    }
    ${Media('mobile')} {
        padding: 10px 0px;
    }
`;

const MainContentWrap = styled.div`
    font-size: ${(props) => (props.size === 'small' ? '18px' : '22px')};
    line-height: ${(props) => (props.size === 'small' ? '1.4' : '1.58')};
    font-family: ${gilroyRegular};
    margin-top: ${(props) => (props.noMarginTop ? '0' : '60px')};
    h3,
    h2 {
        font-size: 32px;
        font-family: ${gilroyBold};
        margin: 0px;
        line-height: 1.3;
        margin-bottom: 30px;
    }
    p {
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
        margin-top: 0px;
        margin-bottom: 30px;
        display: inline-flex;
        align-items: center;
    }
    img {
        display: inline-flex;
        margin-left: 20px;
        width: 150px;
    }
    ul {
        list-style-type: none;
        padding: 20px 30px;
        background-color: ${colors.salt['100']};
        li {
            position: relative;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.6;
            padding-left: 60px;
            color: ${colors.gray_dark['100']};
            min-height: 20px;
            margin: 40px 0;
            img {
                width: 23px;
                height: 23px;
                position: absolute;
                top: 5px;
                left: 0;
                margin-left: 0px;
                transform: translateX(45%);
            }
            &:first-child {
                margin-top: 10px;
            }
            &:last-child {
                margin-bottom: 10px;
            }
        }
    }
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.size === 'small' ? '1vw' : '1.15vw')};
        margin-top: ${(props) => (props.noMarginTop ? '0' : '3vw')};
        h2 {
            font-size: 2.18vw;
            margin-bottom: 1.5vw;
        }
        h3 {
            font-size: 1.8vw;
            margin-bottom: 2vw;
        }
        img {
            margin-left: 1.04vw;
            width: 7.8vw;
        }
        ul {
            padding: 1.04vw 1.57vw;
            li {
                font-size: 0.95vw;
                margin: 2.08vw 0;
                padding-left: 3.125vw;
                min-height: 1.04vw;
                img {
                    width: 1.2vw;
                    height: 1.2vw;
                    top: 0.2vw;
                }
                &:first-child {
                    margin-top: 0.52vw;
                }
                &:last-child {
                    margin-bottom: 0.73vw;
                }
            }
        }
    }
    ${Media('tablet')} {
        font-size: ${(props) => (props.size === 'small' ? '14px' : '18px')};
        margin-top: 0px;
        br {
            display: none;
        }
        ul {
            margin: 0;
            margin-bottom: 0px;
            padding: 20px 30px;
            li {
                font-size: 18px;
                margin: 20px 0px;
                padding-left: 35px;
                &:before {
                    left: -15px;
                }
            }
        }
    }
    ${Media('mobile')} {
        margin: 30px;
        margin-top: ${(props) => props.noMarginTop && '0'};
        text-align: center;
        display: none;
        p {
            flex-direction: column;
            img {
                margin-right: 0px;
                margin-top: 0px;
            }
        }
    }
`;

const MainParaMobile = styled(MainContentWrap)`
    display: none;
    ${Media('mobile')} {
        display: block;
    }
`;
