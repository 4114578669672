import React from 'react';

import { Para } from '../cx-pocket-guide/para';
import { Col, Row } from '@bootstrap-styled/v4';
import { BigHeading } from '../cx-pocket-guide/big-heading';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';

export const Point8 = ({ active }) => {
    return (
        <Wrapper>
            <Row>
                <Col lg="4">
                    <BigHeading color={colors.cx_pocket_guide['pink']} noMarginTop typical active={active}>
                        Our digital universe is in
                        constant flux.
                    </BigHeading>
                    <Para color={colors.white['100']}>
                        It’s impossible to be fully effective across every digital channel. You need to pick the ones that matter most to your customers - and be remarkable at them.
                    </Para>
                    <Para color={colors.white['100']}>
                        Be so good that your customers won’t care that they can’t send you a support request through TikTok.
                    </Para>
                </Col>
                <Col lg={{ size: 7, offset: 1 }}>
                    <BlockImgWrapper>
                        {active &&
                            <ScrollAnimation delay={300} animateIn="fadeInUp"
                                animateOnce={true}>
                                <img src={`${baseURL}assets/images/ebook/imperative-ebook/point8_block_img.svg`} alt="-" />
                            </ScrollAnimation>
                        }
                    </BlockImgWrapper>
                </Col>
            </Row>

        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin-top: 20px;
    ${Media('xlscreens')}{
        margin-top: 1.1vw;
    }
`;

const BlockImgWrapper = styled.div`
    ${Media('tablet')}{
        height:400px;
        img{
            height: 100%;
            width: 100%;
        }
    }
`;
