import React from 'react';

import { Col, Row } from '@bootstrap-styled/v4';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';
import { CXSectionIntro } from '../cx-pocket-guide/cx-section-intro';
import { Para } from '../cx-pocket-guide/para';
import { BigHeading } from '../cx-pocket-guide/big-heading';
import { BlockImg } from './block-img';

export const Point4 = ({ active }) => {
    return (
        <div style={{ position: 'relative' }}>
            <Row>
                <Col lg="7">
                    <CXSectionIntro
                        style2
                        progress={0.86}
                        active={active}
                        source="Source:  babelforce"
                        progressImg={`${baseURL}assets/images/ebook/imperative-ebook/mic-white.svg`}
                        introPara="<span>86% of CX agents</span> feel that they don’t have the right resources in place to effectively support customers"
                    />
                    <BigHeading color={colors.cx_pocket_guide['pink']} typical active={active}>
                        Training should be an ongoing concern for any brand focused on delivering excellent customer experiences.
                    </BigHeading>
                    <Para color={colors.white['100']} lineHeight={1.4}>
                        The right training methodology will engage the skillsets your recruiters identified in each candidate, and harness them for optimal performance and customer outcomes.
                    </Para>
                    <Para color={colors.white['100']} lineHeight={1.4}>
                        Training should work for different learning types, different cultural backgrounds, and be adaptable to the different channels each CX agent will face.
                    </Para>
                    <Para color={colors.white['100']} lineHeight={1.4}>
                        Good training lifts brands. Bad training sinks them.
                    </Para>
                </Col>
                <Col lg="5">
                    <BlockImg imgUrl={`${baseURL}assets/images/ebook/imperative-ebook/point4_block_img.png`} />
                </Col>
            </Row>
        </div>
    );
};
