import React from 'react';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { ContainerWideSRM } from 'components/social-rep-man/common';
import styled from 'styled-components';
import { baseURL } from 'shared-components/theme/helpers';
import { ExpertOptions } from 'components/technology';
import VerticalCards from 'components/social-rep-man/vertical-cards';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { gilroySemibold } from 'shared-components/theme/fonts';
import { gilroyMedium } from 'shared-components/theme/fonts';
import ReactHtmlParser from 'react-html-parser';

export const ExpertBrowseSection = ({
    manageContent,
    expertData,
    cloudChromeFeatures,
}) => {
    return (
        <>
            <ExpertOptions
                expertData={expertData}
                heading="Match every customer with the best-fit agent"
                optionFlex="25%"
                styleTwo={true}
                para="Optimize every interaction by matching customers to the best available agent for their needs. FleCX routes contacts <br/>based on IVR selections, customer history, agent availability, third party data, and more. You can easily add or modify <br/>the criteria as business requirements change."
            />
            <BrowserSection>
                <BrowserImg>
                    <img
                        src={`${baseURL}assets/images/tech/flecx-browser.png`}
                        alt="-"
                    />
                </BrowserImg>
                <BrowserData>
                    <SolutionTitle align="center">Cloud + Chrome</SolutionTitle>
                    <BrowserPara>
                        Take advantage of a lighter installation and connect to
                        your CX platform on any device with our browser <br />
                        extension. Add the security, privacy and access of FleCX
                        CX VPN services to your Google Chrome <br />
                        browser, with no need to install or run separate
                        software.
                    </BrowserPara>
                    <Container>
                        <Row>
                            {cloudChromeFeatures &&
                                cloudChromeFeatures.length > 0 &&
                                cloudChromeFeatures.map((feat, index) => {
                                    return (
                                        <Col lg="4" key={index}>
                                            <CloudFeatBox>
                                                <img src={feat.icon} alt="-" />
                                                <p>
                                                    {ReactHtmlParser(feat.para)}
                                                </p>
                                            </CloudFeatBox>
                                        </Col>
                                    );
                                })}
                        </Row>
                    </Container>
                </BrowserData>
            </BrowserSection>
            <ManageSection id="manage">
                <ContainerWideSRM>
                    {manageContent &&
                        manageContent.length > 0 &&
                        manageContent.map((item, index) => {
                            return (
                                <VerticalCards
                                    key={index}
                                    ImageRight={item.ImageRight}
                                    ImageRightColEqual={item.ImageRight}
                                    padTopSmall={item.padTopSmall}
                                    VerticalCardHeading={
                                        item.VerticalCardHeading
                                    }
                                    VerticalCardParagraph={
                                        item.VerticalCardParagraph
                                    }
                                    VerticalCardImage={item.VerticalCardImage}
                                    list={item.list}
                                    sideExtraSmall
                                    mediumPara
                                />
                            );
                        })}
                </ContainerWideSRM>
            </ManageSection>
        </>
    );
};
const ManageSection = styled.section`
    ${Media('xlscreens')} {
        padding-top: 15vw;
        padding-bottom: 0;
    }
`;
const BrowserSection = styled.section`
    background-color: ${colors.reflecx.buildBg};
    position: relative;
    ${Media('xlscreens')} {
        height: 24vw;
    }
`;
const BrowserImg = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    img {
        width: 85%;
        object-fit: contain;
    }
    ${Media('tablet')} {
        position: relative;
        display: none;
    }
    ${Media('mobile')} {
        img {
            width: 95%;
        }
    }
`;
const BrowserData = styled.div`
    ${Media('xlscreens')} {
        position: absolute;
        width: 100%;
        top: 16.66vw;
    }
`;
const SolutionTitle = styled.h2`
    font-size: 32px;
    font-family: ${gilroySemibold};
    line-height: 1.45;
    text-align: ${(props) => (props.align ? props.align : 'left')};
    margin-top: 0;
    ${Media('xlscreens')} {
        font-size: 2.08vw;
    }
    ${Media('tablet')} {
        text-align: center;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 20px;
    }
`;
const BrowserPara = styled.p`
    text-align: center;
    font-size: 22px;
    font-family: ${gilroyMedium};
    margin-top: 30px;
    ${Media('xlscreens')} {
        font-size: 1.14vw;
        margin-top: 2.66vw;
        margin-bottom: 5.2vw;
    }
    ${Media('tablet')} {
        font-size: 16px;
        padding: 0px 50px;
        br {
            display: none;
        }
    }
`;
const CloudFeatBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        margin-bottom: 30px;
        max-height: 30px;
        max-width: 30px;
    }
    p {
        text-align: center;
        font-size: 18px;
        font-family: ${gilroyMedium};
        color: ${colors.black['70']};
        span {
            font-family: ${gilroySemibold};
        }
    }
    ${Media('xlscreens')} {
        img {
            margin-bottom: 1.56vw;
            max-height: 1.56vw;
            max-width: 1.56vw;
        }
        p {
            font-size: 0.93vw;
        }
    }
    ${Media('tablet')} {
        margin-top: 70px;
        img {
            margin-bottom: 10px;
        }
    }
    ${Media('mobile')}{
        br{
            display:none;
        }
        p{
            font-size:16px;
            padding:0px 20px;
        }
    }
`;
