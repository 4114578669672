import React from 'react';
import { baseURL } from 'shared-components/theme/helpers';
import { BigHeading } from './big-heading';
import { BlockHeading } from './block-heading';
import { CXSectionIntro } from './cx-section-intro';
import { Para } from './para';

export const CXPoint6 = ({ active }) => {
    return (
        <div>
            <CXSectionIntro
                active={active}
                progress={0.69}
                source="Source: Life Size"
                progressImg={`${baseURL}assets/images/ebook/cx-progress6.svg`}
                introPara="Notably, more than <span>69% of businesses</span> still have no cross-channel workforce management solution in place."
            />
            <BigHeading>
                In order to reach maximum performance at speed and scale, your
                outsourcer must rise to the challenge of balancing optimal staff
                levels to keep output high and costs down.
            </BigHeading>
            <Para>Consider the following:</Para>
            <BlockHeading>Demand Forecasting</BlockHeading>
            <Para>
                Ask your CX outsourcer about their methodology for long and
                short-term forecasting. Make sure they are using historical data
                to inform decisions.
            </Para>
            <BlockHeading>Workforce Planning and Scheduling</BlockHeading>
            <Para>
                Find out what their scheduling processes are. Look for a partner
                that integrates casual-, split-, and micro-shifts to enhance
                schedule efficiency, meet agent preferences, and optimize
                results.
            </Para>
            <BlockHeading>Real-Time Intraday Management</BlockHeading>
            <Para>
                Unexpected peaks, absenteeism & agent turnover, and unplanned
                outages can change the effectiveness of a forecasted schedule.
                Your contact center must have contingency plans for times like
                these.
            </Para>
            <BlockHeading>Data-driven Future Planning</BlockHeading>
            <Para>
                Make sure your team is tracking data and emerging patterns from
                past forecasts – this will help inform how to tweak future plans
                and ensure they continue to meet service expectations.
            </Para>
        </div>
    );
};
