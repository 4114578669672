import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import { FaAngleDown } from 'react-icons/fa';
import ReactHtmlParser from 'react-html-parser';
import { GlossaryBanner, GlossaryTerm } from './common';
import Layout from 'components/layout';
import { gilroyBold, gilroySemibold } from 'shared-components/theme/fonts';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { useParams } from 'react-router-dom';

const GlossaryDetail = () => {
    const [relatedData, setRelatedData] = useState([]);
    const [contentData, setContentData] = useState([]);
    const [glossaryData, setGlossaryData] = useState({});
    const router = useParams();

    const getGlossaryData = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/glossary?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        post_name: router.slug,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (res.status === 200 && resData.length !== 0) {
                setGlossaryData(resData.data[0]);
                getGlossaryExtraData(
                    resData.data[0].post_terms[0].term_id,
                    resData.data[0].post_name
                );
            }
        } catch (err) {
            console.error(err);
        }
    };

    const getGlossaryExtraData = async (termId, termName) => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/glossary?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        order: 'asc',
                        category_id: termId,
                        per_page: 1000,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (res.status === 200 && resData.data.length !== 0) {
                const newData = resData.data.filter(
                    (val) => val.post_name !== termName
                );
                setRelatedData(newData);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const getContentData = async () => {
        setContentData([]);
        try {
            const resWeb = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/landings-posts?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        post_type: 'webinars-landing',
                        post_name: 'digital-transformation-through-disruption',
                        per_page: 1,
                    })
            );
            const resEbook = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/landings-posts?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        post_type: 'ebooks-landing',
                        per_page: 1,
                    })
            );
            const resCS = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/landings-posts?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        post_type: 'case-study-landing',
                        per_page: 1,
                    })
            );
            if (
                resWeb.status >= 400 &&
                resEbook.status >= 400 &&
                resCS.status >= 400
            ) {
                throw new Error('Bad response from server');
            }
            const resDataWeb = await resWeb.json();
            const resDataEbook = await resEbook.json();
            const resDataCS = await resCS.json();
            if (resDataWeb.status === 200 && resDataWeb.data.length !== 0) {
                setContentData((value) => [
                    ...value,
                    resDataCS.data[0],
                    resDataWeb.data[0],
                    resDataEbook.data[0],
                ]);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getGlossaryData();
        getContentData();
    }, []);

    return (
        <Layout>
            <GlossaryBanner />

            <DefinitionWrapper>
                <Container>
                    <Row>
                        <Col sm="12">
                            <GlossaryDetailWrap>
                                <Row>
                                    <Col md="7">
                                        <PinkHead>Cx Term</PinkHead>
                                        <MainHead>
                                            {glossaryData.post_title}
                                        </MainHead>
                                    </Col>
                                    <Col md="5">
                                        <DefHead>
                                            Definition
                                            <FaAngleDown
                                                color={colors.pink['100']}
                                            />
                                        </DefHead>
                                        <GlossaryDesc>
                                            {ReactHtmlParser(
                                                glossaryData.post_content
                                            )}
                                        </GlossaryDesc>
                                    </Col>
                                </Row>
                            </GlossaryDetailWrap>
                        </Col>
                    </Row>
                </Container>
            </DefinitionWrapper>

            {relatedData && relatedData.length > 0 && (
                <MoreTermsWrapper>
                    <Container>
                        <Row>
                            <Col md="12">
                                <DefHead align="center">
                                    MORE CX TERMS TO BROWSE
                                </DefHead>
                            </Col>
                        </Row>
                        <Row>
                            {relatedData.map((termItem, ind) => {
                                return (
                                    <Col sm="12" md="6" lg="4" key={ind}>
                                        <GlossaryTerm
                                            term={termItem.post_title}
                                            link={`${termItem.post_name}`}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </Container>
                </MoreTermsWrapper>
            )}
            {contentData && contentData.length > 0 && (
                <BlogSection>
                    <Container>
                        <Row>
                            <Col md="12">
                                <DefHead align="center">Also check</DefHead>
                            </Col>
                        </Row>
                        <Row>
                            {contentData.map((item, index) => {
                                return (
                                    <Col key={index} sm="12" lg="4">
                                        <BlogBox href={item.permalink}>
                                            <img
                                                src={item.featured_image}
                                                width="100%"
                                            />
                                            <BlogDesc>
                                                <BlogTitle>
                                                    {item.post_title}
                                                </BlogTitle>
                                                <BlogText>
                                                    {ReactHtmlParser(
                                                        item.metadata
                                                            .header_banner_mobile
                                                            .page_content_mobile
                                                    )}
                                                </BlogText>
                                            </BlogDesc>
                                        </BlogBox>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Container>
                </BlogSection>
            )}
        </Layout>
    );
};

const DefinitionWrapper = styled.div``;

const GlossaryDetailWrap = styled.div`
    background-color: ${colors.salt['100']};
    margin-bottom: 50px;
    border-radius: 10px;
    padding: 30px 50px;
    ${Media('xlscreens')} {
        padding: 1.56vw 5vw;
    }
`;

const GlossaryDesc = styled.div`
    font-size: 20px;
    font-family: ${gilroySemibold};
    font-weight: normal;
    padding-top: 25px;
    line-height: 1.55;
    margin-bottom: 20px;
    p {
        font-size: inherit;
        font-family: inherit;
        font-weight: inherit;
        margin-bottom: 0px;
        a {
            color: ${colors.pink['100']};
            &:hover {
                color: ${colors.socialRep['brandsBG']};
            }
        }
    }
    ${Media('xlscreens')} {
        font-size: 1.04vw;
    }
    ${Media('tablet')} {
        font-size: 14px;
    }
`;

const PinkHead = styled.h5`
    color: ${colors.pink['100']};
    text-transform: uppercase;
    letter-spacing: 3px;
    font-family: ${gilroySemibold};
    font-size: 21px;
    margin: 50px 0;
    ${Media('xlscreens')} {
        font-size: 1.09vw;
    }
    ${Media('tablet')} {
        font-size: 17px;
        margin: 20px 0;
    }
    ${Media('mobile')} {
        font-size: 14px;
        margin: 15px 0;
    }
`;

const MainHead = styled.h3`
    color: ${colors.socialRep['brandsBG']};
    font-family: ${gilroyBold};
    font-size: 80px;
    width: 90%;
    ${Media('xlscreens')} {
        font-size: 4.16vw;
    }
    ${Media('tablet')} {
        font-size: 35px;
        width: 100%;
    }
    ${Media('mobile')} {
        font-size: 30px;
    }
`;

const DefHead = styled.h5`
    color: ${colors.socialRep['brandsBG']};
    text-transform: uppercase;
    letter-spacing: 3px;
    text-align: ${(props) => (props.align ? 'center' : 'left')};
    font-family: ${gilroySemibold};
    font-size: 21px;
    margin: 50px 0;
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.align ? 'center' : 'start')};
    width: 100%;
    svg {
        margin-left: 20px;
    }
    ${Media('xlscreens')} {
        font-size: 1.09vw;
    }
    ${Media('tablet')} {
        font-size: 17px;
        margin: 30px 0;
    }
    ${Media('mobile')} {
        font-size: 14px;
        margin: 15px 0;
    }
`;

const BlogSection = styled.div`
    padding-bottom: 50px;
    border-top: 1px solid ${colors.gray_dark['10']};
`;

const BlogBox = styled.a`
    background-color: ${colors.gray_lightest['50']};
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
    display: block;
    ${Media('tablet')} {
        max-width: 420px;
        margin: 0 auto 50px;
    }
`;

const BlogDesc = styled.div`
    padding: 35px;
    min-height: 290px;
    ${Media('xlscreens')} {
        padding: 1.8vw;
        min-height: 14vw;
    }
    ${Media('tablet')} {
        padding: 20px;
        min-height: 1px;
    }
`;

const BlogTitle = styled.h4`
    font-size: 28px;
    line-height: 30px;
    margin: 0;
    font-family: ${gilroyBold};
    font-weight: normal;
    overflow: hidden;
    ${Media('xlscreens')} {
        font-size: 1.45vw;
        line-height: 1.56vw;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    ${Media('tablet')} {
        font-size: 18px;
        line-height: 22px;
    }
`;

const BlogText = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;

const MoreTermsWrapper = styled.section`
    padding-top: 10px;
    ${DefHead} {
        padding-bottom: 30px;
    }
    ${Media('xlscreens')} {
        ${DefHead} {
            padding-bottom: 2vw;
        }
    }
`;

export default GlossaryDetail;
