import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import ReactHtmlParser from 'react-html-parser';
import { SAPageHeading, SAPagePara } from '../common';

const projectList = [
    {
        row: [
            {
                title: 'IT/Engineering',
                list: [
                    'Robotics Process Automation',
                    'Data Center Design Engineer',
                    'Web & App Development',
                ],
            },
            {
                title: 'Data Analytics & <br /> Business Intelligence',
                list: [
                    'Business Intelligence Analysts ',
                    'Data Analysts ',
                    'Reports Analysts',
                ],
            },
            {
                title: 'Sales Operations',
                list: [
                    'Account and Pipeline Management Experts ',
                    'Pricing and Quotation Specialists ',
                    'Firmographics Executives ',
                    'Compensation and Incentive Analysts',
                ],
            },
        ],
    },
    {
        row: [
            {
                title: 'Information/Cyber Security',
                list: [
                    'Cyber Security Analysts ',
                    'Cyber Security Engineer ',
                    'Cloud Security Analysts ',
                    'Cloud Security Engineer',
                ],
            },
            {
                title: 'Digital Marketing',
                list: [
                    'Ad Operations Analysts',
                    'SEO & SEM Analysts ',
                    'Social Media Specialists',
                ],
            },
            {
                title: 'Human Resources',
                list: [
                    'Recruitment Support Executives',
                    'Employee Onboarding / Offboarding Specialists ',
                    'HR Backoffice Support Analysts',
                    'Proactive Recruitment Outreach Consultants',
                ],
            },
        ],
    },
    {
        row: [
            {
                title: 'Visuals',
                list: ['Instructional Designers ', 'Graphic Designers'],
            },
            {
                title: 'CRM/Integration & Development',
                list: [
                    'Salesforce Architects and Developers',
                    'Zendesk Integrators ',
                    'AWS Developers ',
                ],
            },
            {
                title: 'Help Desk & Service Desk',
                list: [
                    'Customer Service Representative',
                    'Technicians ',
                    'Subject Matter Experts',
                ],
            },
        ],
    },
    {
        row: [
            {
                title: 'Operations Support',
                list: [
                    'Quality Analysts ',
                    'Queue Coordination and Monitoring ',
                    'Commercial Dispatch Executives ',
                    'Quality System Analysts',
                ],
            },
            {
                title: 'Marketing Operations <br /> & Customer Insights',
                list: [
                    'Marketing Operations Analysts',
                    'Forecasting and Budget Analysts ',
                    'Campaign Management Specialists',
                ],
            },
            {
                title: 'Accounting & Finance',
                list: [
                    'Account Receivable Analysts',
                    'Billing and Invoice Specialists',
                ],
            },
        ],
    },
    {
        row: [
            {
                title: 'Workforce Management',
                list: ['Real Time Analysts'],
            },
            {
                title: 'Supply Chain & Logistics',
                list: [
                    'Supply Chain Analysts',
                    'Vendor Relationship Representatives',
                ],
            },
            {
                title: 'Legal',
                list: ['Legal Operations Analysts'],
            },
        ],
    },
];

const platformList = [
    {
        title: 'Platforms',
        list: ['Salesforce', 'Zendesk', 'Marketo ', 'SAP', 'Siebel'],
    },
    {
        title: 'Tools ',
        list: [
            'Tableau',
            'Power BI',
            'MS Excel ',
            'MS Access',
            'Dart Pro',
            'Coupa',
            'ServiceNow',
            'Workday',
            'Hyperion',
            'SharePoint',
            'Anaplan',
            'Clari',
        ],
    },
    {
        title: 'Languages',
        list: [
            'Python',
            'C++',
            'Ruby',
            'Node',
            'Power Shell',
            'SQL',
            'Java',
            '.NET',
        ],
    },
];

export const SAProjectSection = () => {
    return (
        <>
            <ProjectSection id="project">
                <Container>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <SAPageHeading align="center">
                            Project-Ready Capabilities
                        </SAPageHeading>
                        <SAPagePara size="large" align="center">
                            Select from our expansive menu of recruitment
                            skill-sets.
                        </SAPagePara>
                    </ScrollAnimation>
                    {projectList &&
                        projectList.length > 0 &&
                        projectList.map((rowItem, index) => {
                            return (
                                <ListBoxWrap
                                    key={index}
                                    first={index === 0}
                                    last={index === projectList.length - 1}
                                    noGutters>
                                    {rowItem.row.map((colItem, ind) => {
                                        return (
                                            <Col md="12" lg="4" key={ind}>
                                                <ScrollAnimation
                                                    animateIn="fadeInUp"
                                                    animateOnce={false}>
                                                    <ListBox
                                                        last={
                                                            ind ===
                                                            rowItem.row.length -
                                                                1
                                                        }>
                                                        <h5>
                                                            {ReactHtmlParser(
                                                                colItem.title
                                                            )}
                                                        </h5>
                                                        <ul>
                                                            {colItem.list.map(
                                                                (li, index) => {
                                                                    return (
                                                                        <li
                                                                            key={
                                                                                index
                                                                            }>
                                                                            <SAPagePara size="small">
                                                                                {
                                                                                    li
                                                                                }
                                                                            </SAPagePara>
                                                                        </li>
                                                                    );
                                                                }
                                                            )}
                                                        </ul>
                                                    </ListBox>
                                                </ScrollAnimation>
                                            </Col>
                                        );
                                    })}
                                </ListBoxWrap>
                            );
                        })}
                </Container>
            </ProjectSection>

            <PlatformSection>
                <Container>
                    <PlatformBoxWrap>
                        <Row>
                            <Col sm="12">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={false}>
                                    <SAPageHeading align="center">
                                        Platform Expertise
                                    </SAPageHeading>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                        <Row>
                            {platformList &&
                                platformList.length > 0 &&
                                platformList.map((item, index) => {
                                    return (
                                        <Col lg="4" sm="12" key={index}>
                                            <ScrollAnimation
                                                animateIn="fadeInUp"
                                                animateOnce={false}>
                                                <PlatformBox
                                                    last={
                                                        index ===
                                                        platformList.length - 1
                                                    }>
                                                    <h3> {item.title} </h3>
                                                    <ul>
                                                        {item.list.map(
                                                            (li, index) => {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }>
                                                                        <SAPagePara size="small">
                                                                            {li}
                                                                        </SAPagePara>
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </ul>
                                                </PlatformBox>
                                            </ScrollAnimation>
                                        </Col>
                                    );
                                })}
                        </Row>
                    </PlatformBoxWrap>
                </Container>
            </PlatformSection>
        </>
    );
};

const ProjectSection = styled.section`
    ${Media('xlscreens')} {
        .container {
            padding-left: 9vw !important;
            padding-right: 9vw !important;
        }
    }
`;

const ListBox = styled.div`
    padding-left: ${(props) => (props.last ? '30px' : '0')};
    h5 {
        font-size: 27px;
        font-family: ${gilroySemibold};
        line-height: 1.4;
        margin-top: 0px;
        margin-bottom: 30px;
    }
    ul {
        padding: 0;
        margin: 0;
    }
    li {
        line-height: 2;
        p {
            line-height: inherit;
        }
    }
    ${Media('xlscreens')} {
        padding-left: ${(props) => (props.last ? '2vw' : '0')};
        h5 {
            font-size: 1.4vw;
            margin-bottom: 1.5vw;
        }
    }
    ${Media('tablet')} {
        border-bottom: 1px solid ${colors.fintech['text']}33;
        padding: 30px 0;
        text-align: center;
        h5 {
            font-size: 24px;
            margin-bottom: 20px;
        }
    }
`;
const ListBoxWrap = styled(Row)`
    border-bottom: ${(props) =>
        props.last ? 'none' : `1px solid ${colors.fintech['text']}33`};
    padding: 30px 0;
    margin-top: ${(props) => (props.first ? '30px' : '0')};
    justify-content: space-between;
    ${Media('xlscreens')} {
        margin-top: ${(props) => (props.first ? '2vw' : '0')};
        padding: 3vw 0;
    }
    ${Media('tablet')} {
        border: none;
        padding: 0px;
        &:last-of-type {
            ${Col} {
                &:last-child {
                    ${ListBox} {
                        border: none;
                    }
                }
            }
        }
    }
`;

const PlatformSection = styled.section`
    padding-top: 0px;
    padding-bottom: 30px;
    ${Media('xlscreens')} {
        padding-bottom: 3vw;
        .container {
            padding-left: 8vw !important;
            padding-right: 8vw !important;
        }
    }
`;
const PlatformBoxWrap = styled.div`
    background-color: ${colors.fintech['text']};
    padding: 50px;
    * {
        color: ${colors.white['100']};
    }
    ${Media('xlscreens')} {
        padding: 5vw 8vw;
    }
`;
const PlatformBox = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 40px 10px 25px;
    margin-top: 50px;
    border-right: ${(props) =>
        props.last ? 'none' : `1px solid ${colors.gray['20']}`};
    h3 {
        font-size: 27px;
        font-family: ${gilroySemibold};
        margin-top: 0;
        margin-bottom: 30px;
        &:after {
            content: '<';
            color: ${colors.pink['100']};
        }
    }
    ul {
        margin: 0px;
        padding: 0px;
        li {
            line-height: 2;
            position: relative;
            p {
                line-height: inherit;
                position: relative;
                display: inline-flex;
            }
            &:last-child {
                p {
                    &:after {
                        content: '/>';
                        color: ${colors.pink['100']};
                        font-size: 27px;
                        position: absolute;
                        right: -15px;
                        top: -2px;
                    }
                }
            }
        }
    }
    ${Media('xlscreens')} {
        padding: 0.52vw 3vw 0.52vw 1.5vw;
        margin-top: 4vw;
        h3 {
            font-size: 1.4vw;
        }
        ul {
            li {
                &:last-child {
                    p {
                        &:after {
                            font-size: 1.4vw;
                            right: -2vw;
                            top: -0.3vw;
                        }
                    }
                }
            }
        }
    }
    ${Media('tablet')} {
        border-right: none;
        border-bottom: ${(props) =>
            props.last ? 'none' : `1px solid ${colors.gray['20']}`};
        justify-content: center;
        margin: 0px;
        padding: 30px 0px;
        h3 {
            font-size: 23px;
            min-width: 180px;
        }
        ul {
            min-width: 150px;
            li {
                &:last-child {
                    p {
                        &:after {
                            right: -35px;
                            top: -5px;
                        }
                    }
                }
            }
        }
    }
    ${Media('mobile')} {
        h3 {
            min-width: 160px;
        }
        ul {
            min-width: 150px;
            li {
                &:last-child {
                    p {
                        &:after {
                            right: -40px;
                            top: -10px;
                        }
                    }
                }
            }
        }
    }
`;
