import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import {
    gilroySemibold,
    gilroyLight,
    gilroyBold,
} from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';

const SolutionBox = ({
    solutionImage,
    withQoute,
    imgRight,
    content,
    firstSection,
}) => {
    return (
        <Wrapper withQoute={withQoute}>
            <Container>
                {(imgRight && (
                    <CustomRow paddingTop>
                        <Col lg="6">
                            <ScrollAnimation animateIn="fadeInUp">
                                <LeftTextWrapper>
                                    <ContentWrap>
                                        {ReactHtmlParser(content)}
                                    </ContentWrap>
                                </LeftTextWrapper>
                            </ScrollAnimation>
                        </Col>
                        <CustomCol lg="6" md="12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <SolutionImage noMargin={true}>
                                    <img src={solutionImage} alt="" />
                                </SolutionImage>
                            </ScrollAnimation>
                        </CustomCol>
                    </CustomRow>
                )) || (
                    <CustomRow paddingTop={!firstSection}>
                        <Col lg="6">
                            <ScrollAnimation animateIn="fadeInUp">
                                <SolutionImage>
                                    <img src={solutionImage} alt="" />
                                </SolutionImage>
                            </ScrollAnimation>
                        </Col>
                        <CustomCol lg="6" md="12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <ContentWrap>
                                    {ReactHtmlParser(content)}
                                </ContentWrap>
                            </ScrollAnimation>
                        </CustomCol>
                    </CustomRow>
                )}
            </Container>
        </Wrapper>
    );
};
const CustomCol = styled(Col)`
    ${Media('xlscreens')} {
        padding-left: 3vw !important;
    }
    ${Media('tablet')} {
        margin-top: 20px;
    }
`;
const CustomRow = styled(Row)`
    padding-top: ${(props) => (props.paddingTop ? '80px' : '20px')};
    ${Media('xlscreens')} {
        padding-top: ${(props) => (props.paddingTop ? '4.166vw' : '1vw')};
    }
    ${Media('tablet')} {
        padding-top: 0px;
        flex-direction: column-reverse;
    }
`;
const Wrapper = styled.section`
    padding-top: 0px;
    ${Media('xlscreens')} {
        padding: ${(props) => props.withQoute && '0px'};
    }
    ${Media('tablet')} {
        padding: 0px;
    }
`;
const LeftTextWrapper = styled.div`
    ${Media('xlscreens')} {
        margin-left: 1.04vw;
    }
    ${Media('tablet')} {
        margin-top: 30px;
    }
`;
const SolutionImage = styled.div`
    img {
        width: 98%;
    }
    ${Media('xlscreens')} {
        margin-left: ${(props) => (props.noMargin ? '0px' : '1.04vw')};
    }
    ${Media('tablet')} {
        img {
            width: 100%;
        }
    }
`;
const ContentWrap = styled.div`
    padding-right: 50px;
    h4 {
        color: ${colors.black['100']};
        margin: 0px;
    }
    p {
        display: block;
        font-family: ${gilroyLight};
        font-size: 24px;
        padding-right: ${(props) => (props.imageStyleRight ? '90px' : '0')};
        color: ${(props) =>
            props.paraColor ? props.paraColor : colors.black['100']};
        line-height: 1.75;
        margin-top: 0px;
        span {
            font-family: ${gilroyBold};
            color: ${colors.black['100']};
        }
        strong {
            font-family: ${gilroySemibold};
            color: ${colors.black['100']};
        }
        ${Media('xlscreens')} {
            font-size: 1.25vw;
            margin-bottom: 2.6vw;
            margin-right: 2vw;
        }
        ${Media('tablet')} {
            font-size: 20px;
            margin-bottom: 20px;
            margin-top: 10px;
            br {
                display: none;
            }
            span {
                display: block;
            }
        }
        ${Media('tablet')} {
            font-size: 18px;
        }
    }
    ${Media('xlscreens')} {
        padding-right: 2.5vw;
    }
    ${Media('tablet')} {
        padding-right: 0;
    }
`;
export default SolutionBox;
