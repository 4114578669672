import React, { useState } from 'react';
import { Col, Container, Input, Row } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { gilroyMedium, gilroySemibold } from 'shared-components/theme/fonts';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import Button from 'shared-components/button/button';
import { FaSearch } from 'react-icons/fa';
import { GlossaryMainHeading } from './main-heading';
import { handleUrl } from 'shared-components/theme/helpers';

const popular = [
    {
        name: 'CSAT',
        link: '/cx-glossary/customer-satisfaction-rating-csat',
    },
    {
        name: 'NPS',
        link: '/cx-glossary/net-promoter-score-nps',
    },
    {
        name: 'AHT',
        link: '/cx-glossary/average-handling-time',
    },
];

export const GlossaryBanner = ({ onSearch, mainPage }) => {
    const [search, setSearch] = useState('');

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            mainPage
                ? onSearch(search)
                : (window.location.href = handleUrl(
                      `/cx-glossary?key=${search}`
                  ));
        }
    };

    return (
        <Wrapper>
            <Breadcrumbs
                bgColor={colors.socialRep['brandsBG']}
                color={colors.white['40']}>
                <li>Resources</li>
                <li>Glossary</li>
            </Breadcrumbs>

            <Container>
                <Row>
                    <Col sm="12">
                        <PreHeading>Glossary</PreHeading>
                    </Col>
                </Row>
                <Row>
                    <Col md="5" lg="6">
                        {mainPage ? (
                            <GlossaryMainHeading
                                as="h1"
                                color={colors.white['100']}
                                noMarginBottom>
                                The CX Glossary
                            </GlossaryMainHeading>
                        ) : (
                            <a href="/cx-glossary">
                                <GlossaryMainHeading
                                    as="h1"
                                    color={colors.white['100']}
                                    noMarginBottom>
                                    The CX Glossary
                                </GlossaryMainHeading>
                            </a>
                        )}
                        <BannerSubHeading>
                            The Ultimate A-Z Glossary of CX Terminology.
                        </BannerSubHeading>
                    </Col>
                    <Col md="7" lg="6">
                        <GlossarySearchWrap>
                            <Input
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={handleSearch}
                                placeholder="Enter the CX term you’re looking for…"
                            />
                            <Button
                                bgColor="transparent"
                                color={colors.socialRep['brandsBG']}
                                ifButton
                                onClick={() =>
                                    mainPage
                                        ? onSearch(search)
                                        : (window.location.href = handleUrl(
                                              `/cx-glossary?key=${search}`
                                          ))
                                }>
                                <FaSearch />
                            </Button>
                        </GlossarySearchWrap>
                        {popular && popular.length > 0 && (
                            <GlossaryTags>
                                <li className="head">Popular Terms:</li>
                                {popular.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <a href={item.link}>{item.name}</a>
                                        </li>
                                    );
                                })}
                            </GlossaryTags>
                        )}
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.section`
    background-color: ${colors.socialRep['brandsBG']};
    ${Media('xlscreens')} {
        padding-top: 1.3vw;
    }
    ${Media('mobile')} {
        padding-top: 20px;
    }
`;

const PreHeading = styled.div`
    font-size: 24px;
    font-family: ${gilroySemibold};
    color: ${colors.pink['100']};
    margin-top: 20px;
    margin-bottom: 20px;
    ${Media('xlscreens')} {
        font-size: 1.3vw;
        margin-top: 2.3vw;
        margin-bottom: 1.4vw;
    }
    ${Media('mobile')} {
        font-size: 21px;
    }
`;

const BannerSubHeading = styled.h3`
    font-size: 20px;
    font-family: ${gilroySemibold};
    margin: 0px;
    line-height: 1.3;
    margin-top: 10px;
    color: ${colors.white['100']};
    ${Media('xlscreens')} {
        font-size: 1.05vw;
        margin-top: 0.52vw;
    }
    ${Media('tablet')} {
        margin-bottom: 30px;
    }
`;

const GlossarySearchWrap = styled(FlexCentered)`
    background-color: ${colors.white['100']};
    border-radius: 8px;
    height: 45px;
    input {
        background: none;
        border: none;
        font-size: 18px;
        font-family: ${gilroyMedium};
        color: ${colors.gray_dark['100']};
        flex: 1 0;
        height: 100%;
        padding: 0px 15px;
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
    button,
    a {
        flex: 0 0 90px;
        max-width: 90px;
        min-width: 90px;
        height: 100%;
        font-size: 24px;
        border-radius: 0 8px 8px 0;
    }
    ${Media('xlscreens')} {
        height: 3.44vw;
        input {
            font-size: 1.05vw;
            padding: 0 1.1vw;
        }
        button,
        a {
            flex: 0 0 4.68vw;
            max-width: 4.68vw;
            min-width: 4.68vw;
            font-size: 1.2vw;
        }
    }
    ${Media('mobile')} {
        input {
            font-size: 14px;
        }
        button,
        a {
            font-size: 18px;
        }
    }
`;

const GlossaryTags = styled.ul`
    padding: 0px;
    margin: 20px 0 0;
    display: flex;
    align-items: center;
    li {
        font-size: 16px;
        font-family: ${gilroySemibold};
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0px 5px;
        color: ${colors.white['100']};
        &.head {
            text-transform: initial;
            letter-spacing: 0 !important;
            background-color: transparent;
            padding-left: 0px !important;
            margin-left: 0px !important;
        }
        a {
            min-width: 100px;
            padding: 12px;
            text-align: center;
            background-color: ${colors.pink['67']};
            border-radius: 18px;
            color: inherit;
            display: inline-flex;
            justify-content: center;
        }
    }
    ${Media('xlscreens')} {
        margin-top: 1.25vw;
        li {
            font-size: 0.95vw;
            letter-spacing: 0.02vw;
            border-radius: 0.95vw;
            margin: 0 0.42vw;
            a {
                padding: 0.65vw;
                min-width: 5.2vw;
            }
        }
    }
    ${Media('tablet')} {
        flex-wrap: wrap;
        li {
            font-size: 14px;
            a {
                padding: 6px;
                min-width: 60px;
            }
        }
    }
`;
