import React, { useEffect, useState } from "react";

import { Para } from '../cx-pocket-guide/para';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';
import { BlockImg } from "./block-img";
import { BigHeading } from "../cx-pocket-guide/big-heading";

export const Point11 = ({ active }) => {
    const [sourceAppear, setSourceAppear] = useState(false);
    useEffect(() => {
        if (active) {
            setTimeout(() => {
                setSourceAppear(true);
            }, 9300)
        }
        else {
            setSourceAppear(false);
        }
    }, [active, sourceAppear])
    return (
        <>
            <BlockImg imgUrl={`${baseURL}assets/images/ebook/imperative-ebook/point11_block_img.png`} />
            <Para color={colors.white['100']}>
                Everyone likes a mystery, just not when it comes to the work your vendor is doing for you.
            </Para>
            <BigHeading color={colors.cx_pocket_guide['pink']} typical active={active}>
                ‘When people could see the work that was going on behind the scenes, they perceived that more effort went into the delivery of the service.’
            </BigHeading>
            {
                sourceAppear && active &&
                <BigHeading color={colors.cx_pocket_guide['pink']} typical active={active} >
                    Ryan W.Buell, Harvard Business Review
                </BigHeading>
            }
            <Para color={colors.white['100']}>
                Transparency, trust, and accountability go hand in hand (in hand). By readily sharing bad news and good news with complete transparency, leaders can hold their people and partnerships accountable.
            </Para>
            <Para color={colors.white['100']}>
                Accountability is the name of the game. Keep CX secrets at your own peril.
            </Para>
        </>
    );
}
