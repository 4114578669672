import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import DigitalHeading from '../digital-heading/digital-heading';
import MainPara from '../digital-main-para/digital-main-para';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import { Parallax } from 'react-scroll-parallax';
import BannerMorph from '../../banner-morph/banner-morph';
import { baseURL } from 'shared-components/theme/helpers';

export const DigitalIntro = () => {
    const [vactive, setActive] = useState(false);
    const handleScroll = () => {
        const position = window.pageYOffset;
        if (position > 125 && vactive == false) {
            setActive(true);
        } else {
            setActive(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <Wrapper id="home">
            <Container>
                <Row>
                    <Col sm="12" md="7">
                        <div>
                            <div>
                                <DigitalHeading tag="h1" noMargin={false}>
                                    A digital marketing <br />
                                    agency for growth-
                                    <br /> obsessed brands
                                    <span>.</span>
                                </DigitalHeading>
                            </div>
                        </div>
                    </Col>
                    <Col sm="12" md={{ size: 4 }}>
                        <IntroRight>
                            <Tag>What we do</Tag>
                            <DownArrow>
                                <img
                                    src={`${baseURL}assets/images/down-arrow.svg`}
                                    alt=""
                                />
                            </DownArrow>
                            <MainPara>
                                AI-powered digital marketing <br />
                                for online to offline revenue growth <br /> and
                                profit maximization.
                            </MainPara>
                        </IntroRight>
                    </Col>
                </Row>
            </Container>
            <Container fluid={true} id="video_conatiner">
                <Row id="videoHolder">
                    <Col md="12">
                        <LandingDigitalVideo>
                            <BigCross x={[-20, 20]}>
                                <img
                                    src={`${baseURL}assets/images/big-_cross.png`}
                                    alt=""
                                />
                            </BigCross>
                        </LandingDigitalVideo>
                    </Col>
                </Row>
            </Container>
            <BannerMorph image={`${baseURL}assets/images/digital-poster.jpg`} />
        </Wrapper>
    );
};

const BigCross = styled(Parallax)`
    position: absolute;
    right: -65px;
    top: -70px;
    width: 30%;
    z-index: 99;
    ${Media('xlscreens')} {
        width: 20vw;
        right: 21.6vw;
        top: -1vw;
    }
    img {
        width: 100%;
    }
    ${Media('mobile')} {
        display: none;
    }
`;

const Wrapper = styled.section`
    position: relative;
    #video_conatiner {
        padding: 0;
    }
    ${Media('xlscreens')} {
        padding: 5.4vw 0 5vw;
        height: 70vw;
    }
`;
const IntroRight = styled.div`
    padding-left: 5.7vw;
    ${Media('tablet')} {
        padding-left: 4vw;
    }
`;
const Tag = styled.div`
    padding-top: 0.1vw;
    font-size: 12px;
    letter-spacing: -0.05vw;
    font-family: ${gilroyBold};
    color: ${colors.pink['100']};
    ${Media('xlscreens')} {
        font-size: 0.92vw;
    }
    ${Media('tablet')} {
        padding-top: 3.2vw;
        font-size: 4vw;
    }
`;

const DownArrow = styled.div`
    display: block;
    margin: 30px 0;
    ${Media('tablet')} {
        display: none;
    }
    ${Media('xlscreens')} {
        img {
            width: 0.45vw;
        }
        margin: 1.2vw 0.4vw;
    }
`;

const LandingDigitalVideo = styled.div`
    width: 100%;
    border-radius: 32px;
    position: relative;
    display: flex;
    align-items: center;
    ${Media('xlscreens')} {
        margin-top: 0vw;
    }
`;
