import React from 'react';
import WhoTheyAre from 'components/case-study/case-who-they-are';
import { ScrollWrapper } from '../common';

export const AboutSection = ({ data }) => {
    return (
        <ScrollWrapper marginTop={true}>
            {data && data['section_description'] !== '' && (
                <WhoTheyAre
                    content={data['section_description']}
                    contentImage={data['section_image']}
                />
            )}
        </ScrollWrapper>
    );
};
