import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import {
    gilroySemibold,
    gilroyMediumItalic,
} from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

const DigitalHeading = ({ children, tag, noMargin }) => {
    return (
        <Heading as={tag === 'h1' ? 'h1' : 'h2'} margin={noMargin}>
            {children}
        </Heading>
    );
};

const Heading = styled.h2`
    font-size: 38px;
    padding-left: 3vw;
    letter-spacing: -4px;
    color: ${colors.gray_dark['100']};
    font-family: ${gilroySemibold};
    line-height: 1.04;
    margin: ${(props) => (props.margin ? '0' : '5px 0')};
    span {
        color: ${colors.pink['100']};
    }
    i {
        font-family: ${gilroyMediumItalic};
    }
    ${Media('xlscreens')} {
        font-size: 4.24vw;
    }

    ${Media('tablet')} {
        margin-top: 1vw;
        padding-left: 3vw;
        letter-spacing: -1px;
    }
`;

export default DigitalHeading;
