import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const EbookFeatureBox = ({
    image,
    title,
    children,
    titleColor,
    bgColor,
    styleTwo,
}) => {
    return (
        <Wrapper bgColor={bgColor} styleTwo={styleTwo}>
            <ImageIcon>
                <img src={image} alt="-" />
            </ImageIcon>
            <Content>
                <Title titleColor={titleColor}>{title}</Title>
                {children}
            </Content>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    margin: 30px 0px;
    ${(props) => {
        if (props.bgColor) {
            return `
                position:relative;
                z-index:1;
                &:after{
                    position:absolute;
                    content:'';
                    height:100%;
                    width:93%;
                    right:0;
                    background-color:${props.bgColor};
                    z-index:-1;
                }
            `;
        }
    }}
    ${(props) => {
        if (props.styleTwo) {
            return `
                margin:20px 0;
                padding:50px 0;
                align-items:center;
                h3{
                    margin-bottom:10px;
                }
                p{
                    margin-bottom:0;
                }
            `;
        }
    }}
    ${Media('xlscreens')} {
        ${(props) => {
            if (props.styleTwo) {
                return `
                    margin: 1vw 0;
                    padding: 1.6vw 0;
                    padding-right: 4vw;
                    h3 {
                        margin-bottom:0.52vw;
                    }
                `;
            } else {
                return `margin: 2vw 0 3vw;`;
            }
        }}
    }
    ${Media('mobile')} {
        flex-direction: column;
        margin-bottom: 30px;
        margin-top: 30px;
        &:after {
            top: 0;
            width: 100%;
        }
    }
`;

const ImageIcon = styled.div`
    flex: 0 0 160px;
    max-width: 160px;
    height: 160px;
    margin-right: 50px;
    border-radius: 160px;
    overflow: hidden;
    img {
        height: 100%;
        width: 100%;
    }
    ${Media('xlscreens')} {
        flex: 0 0 8.3333vw;
        max-width: 8.3333vw;
        height: 8.3333vw;
        border-radius: 8.3333vw;
        margin-right: 4vw;
    }
    ${Media('mobile')} {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 40px;
    }
`;

const Content = styled.div`
    flex: 1 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    a {
        font-size: 8px;
        position: relative;
        color: ${colors.ebook.seasonal['main']};
        top: -20px;
        left: 1px;
        &:hover {
            color: ${colors.ebook.seasonal['main2']};
        }
        ${Media('xlscreens')} {
            font-size: 0.7vw;
            top: -0.5vw;
        }
    }
    ${Media('mobile')} {
        text-align: center;
        div,
        p {
            text-align: center;
        }
    }
`;

const Title = styled.h3`
    font-size: 24px;
    font-family: ${gilroyBold};
    font-weight: normal;
    color: ${(props) =>
        props.titleColor ? props.titleColor : colors.ebook.text};
    margin-top: 0px;
    margin-bottom: 30px;
    ${Media('xlscreens')} {
        font-size: 1.35vw;
        margin-bottom: 1vw;
    }
    ${Media('mobile')} {
        margin-bottom: 10px;
    }
`;
