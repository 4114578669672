import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import { gilroySemibold, gilroyMedium } from 'shared-components/theme/fonts';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import ScrollAnimation from 'react-animate-on-scroll';

const CXFeatureBox = ({ image, title, description }) => {
    return (
        <Wrapper>
            <Controller>
                <Scene
                    triggerHook="onLeave"
                    duration={'100%'}
                    offset={'-100%'}
                    classToggle="highlighter"
                    delay={500}>
                    <Timeline>
                        <Tween>
                            <div>
                                <Image>
                                    <img
                                        data-src={image}
                                        src={image}
                                        alt="-"
                                        className="lozad"
                                    />
                                </Image>
                                <Title>{title}</Title>
                                <Description>{description}</Description>
                            </div>
                        </Tween>
                    </Timeline>
                </Scene>
            </Controller>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    text-align: center;
    padding-top: 50px;
    margin-bottom: 50px;
    margin-left: auto;

    ${Media('xlscreens')} {
        width: 21.9vw;
        padding-top: 3vw;
        margin-bottom: 3vw;
        transform: scale(0.95);
    }

    /* .highlighter {
        opacity: 0.3;
        transition: opacity 0.3s ease-in-out;
        ${Media('mobile')} {
            opacity: 1;
        }
    } */
`;

const Image = styled.div`
    img {
        max-width: 100%;
        ${Media('xlscreens')} {
            width: 21.9vw;
        }
    }
`;

const Title = styled.div`
    font-size: 24px;
    font-family: ${gilroySemibold};
    margin: 30px auto;
    max-width: 80%;
    ${Media('xlscreens')} {
        font-size: 1.4vw;
        margin: 2vw auto;
    }
`;

const Description = styled.p`
    font-size: 16px;
    font-family: ${gilroyMedium};
    margin: 0px auto;
    max-width: 80%;
    ${Media('xlscreens')} {
        font-size: 0.82vw;
    }
`;

export default CXFeatureBox;
