import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import MainHeading from 'shared-components/main-heading/main-heading';
import MainPara from 'shared-components/main-para/main-para';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import Video from 'shared-components/video/video';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import ScrollAnimation from 'react-animate-on-scroll';
import { Parallax } from 'react-scroll-parallax';
import HomeIntroSolutions from './home-intro-solutions';
import LazyLoad from 'react-lazyload';
import Loader from 'components/loader/loader';
import { baseURL } from 'shared-components/theme/helpers';
import HomeBrand from 'components/home-brand/home-brand';
import {
    FaMobileAlt,
    FaDollarSign,
    FaGlobeAmericas,
    FaPlay,
    FaShieldAlt,
    FaCar,
    FaWarehouse,
} from 'react-icons/fa';
import { IoIosMail, IoMdBicycle, IoMdRadio, IoMdWatch } from 'react-icons/io';
import { FiMonitor } from 'react-icons/fi';

const homeBrandsItems = [
    {
        rank: '#1',
        title: (
            <Fragment>
                Telecommunications <br /> Network
            </Fragment>
        ),
        icon: <FaMobileAlt />,
        vdelay: 0,
    },
    {
        rank: '#1',
        title: (
            <Fragment>
                Money Transfer <br /> Service
            </Fragment>
        ),
        icon: <FaDollarSign />,
        vdelay: 100,
    },
    {
        rank: '#1',
        title: (
            <Fragment>
                Global <br /> Company
            </Fragment>
        ),
        icon: <FaGlobeAmericas />,
        vdelay: 300,
    },
    {
        rank: '#1',
        title: (
            <Fragment>
                Retail & <br /> E-Commerce
            </Fragment>
        ),
        icon: <IoIosMail />,
        vdelay: 400,
    },
    {
        rank: '#1',
        title: (
            <Fragment>
                Shipping & <br /> Logistics
            </Fragment>
        ),
        icon: <IoMdBicycle />,
        vdelay: 500,
    },
    {
        rank: '#1',
        title: (
            <Fragment>
                Streaming Media <br /> & Content
            </Fragment>
        ),
        icon: <FaPlay />,
        vdelay: 600,
    },
    {
        rank: '#1',
        title: (
            <Fragment>
                Consumer <br /> Electronics
            </Fragment>
        ),
        icon: <IoMdRadio />,
        vdelay: 700,
    },
    {
        rank: '#1',
        title: (
            <Fragment>
                Extended <br /> Warranty
            </Fragment>
        ),
        icon: <FaShieldAlt />,
        vdelay: 800,
    },
    {
        rank: '#1',
        title: (
            <Fragment>
                Leading Set <br /> Top Box
            </Fragment>
        ),
        icon: <FiMonitor />,
        vdelay: 900,
    },
    {
        rank: '#2',
        title: (
            <Fragment>
                Ride Sharing <br /> Service
            </Fragment>
        ),
        icon: <FaCar />,
        vdelay: 1100,
    },
    {
        rank: '#2',
        title: (
            <Fragment>
                Fitness <br /> Device
            </Fragment>
        ),
        icon: <IoMdWatch />,
        vdelay: 1200,
    },
    {
        rank: '#2',
        title: (
            <Fragment>
                Warehouse <br /> Club
            </Fragment>
        ),
        icon: <FaWarehouse />,
        vdelay: 1300,
    },
];

const HomeIntro = ({
    mainTitle,
    mainPara,
    isHome,
    headingColumn,
    headingOffset,
    mainParaPadding,
    landingVideoUrl,
    landingVideoImgUrl,
    equalSectionPadding,
    careersSection,
}) => {
    const [landingVideo, setLandingVideo] = useState('');
    useEffect(() => {
        setTimeout(() => {
            setLandingVideo(landingVideoUrl);
        }, 5000);
    }, []);
    return (
        <Wrapper id="home" equalSectionPadding={equalSectionPadding}>
            <Container>
                <RowCentered>
                    <Col lg={headingColumn}>
                        <div>
                            <IntroHeading>
                                <MainHeading tag="h1" noMargin={true}>
                                    {mainTitle}
                                    <span>.</span>
                                </MainHeading>
                            </IntroHeading>
                        </div>
                    </Col>
                    <Col
                        lg={{
                            size: 12 - headingColumn - headingOffset,
                            offset: headingOffset,
                        }}>
                        <div>
                            <IntroRight padding={mainParaPadding}>
                                {isHome && isHome !== undefined ? (
                                    <Fragment>
                                        <IntroShapes>
                                            <ul>
                                                <li>
                                                    <img
                                                        src={`${baseURL}assets/images/polygon-icon.svg`}
                                                        alt=""
                                                        height="12"
                                                        width="12"
                                                    />
                                                </li>
                                                <li>
                                                    <img
                                                        src={`${baseURL}assets/images/small-plus.svg`}
                                                        alt=""
                                                        height="12"
                                                        width="12"
                                                    />
                                                </li>
                                                <li>
                                                    <img
                                                        src={`${baseURL}assets/images/circle-icon.svg`}
                                                        alt=""
                                                        height="12"
                                                        width="12"
                                                    />
                                                </li>
                                            </ul>
                                        </IntroShapes>
                                        <Tag>
                                            BPO 2.0: The Future of CX, Now.
                                        </Tag>
                                        <DownArrow>
                                            <img
                                                src={`${baseURL}assets/images/down-arrow.svg`}
                                                alt=""
                                                width="9"
                                                height="21"
                                            />
                                        </DownArrow>
                                    </Fragment>
                                ) : null}

                                <MainPara>{mainPara}</MainPara>
                            </IntroRight>
                        </div>
                    </Col>
                </RowCentered>
                {careersSection && careersSection !== undefined && (
                    <div>
                        <Row>
                            <Col md="12">{careersSection}</Col>
                        </Row>
                    </div>
                )}
                {landingVideoUrl && landingVideoUrl !== undefined && (
                    <Row>
                        <Col md="12">
                            <div>
                                <LandingVideo>
                                    {isHome && isHome !== undefined ? (
                                        <Fragment>
                                            <BigTriangle x={[-20, 20]}>
                                                <img
                                                    src={`${baseURL}assets/images/big-triangle.svg`}
                                                    alt=""
                                                />
                                            </BigTriangle>
                                        </Fragment>
                                    ) : null}

                                    <Video
                                        video={landingVideo}
                                        videotype="mp4"
                                        image={landingVideoImgUrl}
                                    />
                                </LandingVideo>
                            </div>
                        </Col>
                    </Row>
                )}
                
                {isHome && isHome !== undefined ? (
                    <Fragment>
                        <HomeIntroSolutions />
                        <LeadingBrands>
                            <Row>
                                <Col lg="12">
                                    <MainHeading>
                                        The world’s leading brands trust
                                        ibex.
                                    </MainHeading>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <BrandsList>
                                        {homeBrandsItems &&
                                            homeBrandsItems.map(
                                                (item, ind) => (
                                                    <HomeBrand
                                                        rank={item.rank}
                                                        icon={item.icon}
                                                        vdelay={item.vdelay}
                                                        title={item.title}
                                                        key={ind}
                                                    />
                                                )
                                            )}
                                    </BrandsList>
                                </Col>
                            </Row>
                        </LeadingBrands>
                    </Fragment>
                ) : null}
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.section`
    position: relative;
    ${Media('xlscreens')} {
        padding: 8vw 0 5vw;
        padding-top: ${(props) => props.equalSectionPadding && '6vw'};
    }
`;

const IntroHeading = styled.div`
    ${Media('tablet')} {
        margin-bottom: 50px;
    }
`;

const IntroRight = styled.div`
    padding-left: ${(props) => (props.padding ? '4.2vw' : '0')};
    ${Media('mobile')} {
        padding: 0px;
    }
`;

const IntroShapes = styled.div`
    ul {
        padding: 0;
        margin-bottom: 35px;
        display: flex;
        display: ${(props) => (props.introShape ? props.introShape : 'flex')};
        align-items: center;
        li {
            padding-right: 10px;
            font-size: 17px;
            display: inline-flex;
            img {
                ${Media('xlscreens')} {
                    width: 0.63vw;
                }
                ${Media('mobile')} {
                    width: 12px;
                }
            }
            &:last-child {
                padding-right: 0px;
            }
        }
        ${Media('xlscreens')} {
            margin-bottom: 2.2vw;
        }
    }
`;

const Tag = styled.div`
    font-size: 12px;
    font-family: ${gilroyBold};
    color: ${colors.pink['100']};
    ${Media('xlscreens')} {
        font-size: 0.95vw;
    }
`;

const DownArrow = styled.div`
    display: block;
    margin: 30px 0;
    ${Media('xlscreens')} {
        img {
            width: 0.45vw;
        }
        margin: 1.8vw 0;
    }
`;

const LandingVideo = styled.div`
    width: 100%;
    margin-top: 130px;
    background-color: ${colors.gray_lighter['100']};
    border-radius: 32px;
    position: relative;
    display: flex;
    align-items: center;
    height: calc(100vh - 100px);
    video {
        border-radius: 32px;
    }
    ${Media('xlscreens')} {
        margin-top: 6.7vw;
    }
    ${Media('tablet')} {
        height: auto;
        margin-top: 70px;
    }
`;

const BigTriangle = styled(Parallax)`
    position: absolute;
    right: -65px;
    top: -70px;
    width: 30%;
    z-index: 99;
    ${Media('xlscreens')} {
        width: 20vw;
        right: -4vw;
        top: -4vw;
    }
    img {
        width: 100%;
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const LeadingBrands = styled.section`
    position: relative;
    h2 {
        text-align: center;
    }
`;

const BrandsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    ${Media('xlscreens')} {
        margin: 0px -4vw;
    }
`;

export default HomeIntro;
