import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import {
    gilroyMedium,
    gilroyBold,
    gilroySemibold,
    gilroyLight,
} from 'shared-components/theme/fonts';
import SlickSlider from 'shared-components/slider/slider';
import ScrollAnimation from 'react-animate-on-scroll';
import { baseURL } from 'shared-components/theme/helpers';
const manageSliderItems = [
    {
        boxNumber: '01',
        holderImgSrc: `${baseURL}assets/images/digital_01_unpack.svg`,
        holderImgWidth: '50%',
        headerContainer: 'Unpack',
        containerText: `We do a deep dive into every ad account you
        have; Facebook, Instagram, Google, etc. to
        get a full picture of everything you’ve done
        so far.`,
        backgroundColor: '#f5f5f5',
    },
    {
        boxNumber: '02',
        holderImgSrc: `${baseURL}assets/images/digital_imp02_map.svg`,
        holderImgWidth: '50%',
        headerContainer: 'Map',
        containerText: `We connect the dots between historic actions
        and outcomes, finding out what works, what
        doesn’t, and where to go next.`,
        backgroundColor: '#f0f0f0',
    },
    {
        boxNumber: '03',
        holderImgSrc: `${baseURL}assets/images/digital_imp03_measure.svg`,
        holderImgWidth: '50%',
        headerContainer: 'Measure',
        containerText: `We look at CRM data for high performing
        segments and audiences, pinpointing areas of
        high-growth potential and new market
        penetration.`,
        backgroundColor: '#f5f5f5',
    },
    {
        boxNumber: '04',
        holderImgSrc: `${baseURL}assets/images/digital_imp04_create.svg`,
        holderImgWidth: '50%',
        headerContainer: 'Create',
        containerText: `With everything mapped and measured, we
        create an advertising strategy tailored for
        maximum impact across each digital channel.`,
        backgroundColor: '#f0f0f0',
    },
    {
        boxNumber: '05',
        holderImgSrc: `${baseURL}assets/images/digital_imp05_launch.svg`,
        holderImgWidth: '50%',
        headerContainer: 'Launch',
        containerText: `We launch your spiffy new ad campaign and
        watch it fly, continuously tracking
        performance, implementing live-time
        adjustments, and optimizing, optimizing,
        optimizing.`,
        backgroundColor: '#f5f5f5',
    },
    {
        boxNumber: '06',
        holderImgSrc: `${baseURL}assets/images/digital_imp06_accelerate.svg`,
        holderImgWidth: '58%',
        headerContainer: 'Accelerate',
        containerText: `As new leads, customers, and subscribers
        flow in, we use our newly refined and
        perfected consumer knowledge to further
        optimize our keywords, more precisely tailor
        our audience and segmentation, and start the
        wild ride all over again.`,
        backgroundColor: '#f0f0f0',
    },
];
export const DigitalManage = () => {
    const settingsBlog = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        centerMode: false,
        centerPadding: '0px',
        focusOnSelect: false,
        autoplay: false,
        autoplaySpeed: 3000,
        initialSlide: 0,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <MainScrollerContainero id="manage">
            <div>
                <HeaderOne>
                    <HeaderTitle>
                        We build, implement <br />
                        & manage brand-
                        <br />
                        tailored digital <br />
                        advertising programs.
                    </HeaderTitle>
                    <HeaderText>
                        We deconstruct your ad strategy and <br />
                        historic channel performance, <br />
                        determine what works and what <br />
                        doesn’t, and then rebuild it like the mad <br />
                        marketing scientists that we are.
                    </HeaderText>
                </HeaderOne>
            </div>
            <div>
                <div>
                    <div  style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                        {manageSliderItems &&
                            manageSliderItems.map((item, ind) => {
                                return (
                                    <ManageSliderBox
                                        key={ind}
                                        bgColor={item.backgroundColor}>
                                        <ManageSizeCont>
                                            <BoxNumber>
                                                {item.boxNumber}
                                            </BoxNumber>
                                            <BoxImgHolder>
                                                <img
                                                    src={item.holderImgSrc}
                                                    width={item.holderImgWidth}
                                                    alt="-"
                                                />
                                            </BoxImgHolder>
                                            <BoxTraco>
                                                <img
                                                    src={`${baseURL}assets/images/digital_traco.png`}
                                                    alt="-"
                                                />
                                            </BoxTraco>
                                            <BoxHeaderContainer>
                                                {item.headerContainer}
                                            </BoxHeaderContainer>
                                            <BoxTextContainer>
                                                {item.containerText}
                                            </BoxTextContainer>
                                        </ManageSizeCont>
                                    </ManageSliderBox>
                                );
                            })}
                    </div>
                </div>
            </div>
        </MainScrollerContainero>
    );
};
const BoxTraco = styled.div`
    text-align: center;
    img {
        display: inline !important;
    }
`;
const ManageSizeCont = styled.div`
    width: 26vw;
    height: 45.83vw;
    ${Media('tablet')} {
        width: 100%;
        height: 70vw;
    }
`;

const BoxNumber = styled.div`
    padding: 4vw 0vw 0vw 4vw;
    font-size: 1.8vw;
    line-height: 2.4vw;
    margin-bottom: 2.7vw;
    font-family: ${gilroyBold};
    letter-spacing: -0.08vw;
    color: #1d1d1f;
    ${Media('tablet')} {
        width: 100%;
        height: auto;
        font-size: 9vw;
        text-align: center;
        padding: 13vw 0vw 8vw 0vw;
    }
`;

const BoxImgHolder = styled.div`
    text-align: center;
    height: 16.5vw;
    padding-left: 5vw;
    position: relative;
    ${Media('tablet')} {
        height: 45vw;
        text-align: center;
        img {
            display: inline !important;
            width: 30%;
        }
    }
`;

const ManageSliderBox = styled.div`
    background: ${(props) => props.bgColor && props.bgColor};
    ${Media('tablet')} {
        height: 155vw;
    }
`;

const BoxHeaderContainer = styled.div`
    font-family: ${gilroySemibold};
    color: #1d1d1f;
    font-size: 2.4vw;
    padding: 0vw 4vw;
    ${Media('tablet')} {
        font-size: 7vw;
        text-align: center;
        padding: 5vw 0vw 5vw 0vw;
    }
`;
const BoxTextContainer = styled.div`
    font-size: 0.8vw;
    letter-spacing: 0.05vw;
    line-height: 1.6vw;
    font-family: ${gilroyLight};
    padding: 1vw 4vw;
    ${Media('tablet')} {
        font-size: 4vw;
        line-height: 4vw;
        text-align: center;
        padding: 0vw 13vw 0vw 13vw !important;
    }
`;
const MainScrollerContainero = styled.section`
    background-color: #f5f5f7;
    padding-top: 0px;
    overflow: hidden;
    .slick-arrow {
        position: absolute;
        left: 50%;
        margin-left: -80px;
        top: 48vw;
        z-index: 99;
        &.slick-next {
            transform: translateX(50px);
        }
    }
    ${Media('tablet')} {
        padding-bottom: 0px !important;
        height: 230vw;
        .slick-arrow {
            position: absolute;
            left: 50%;
            margin-left: -40px;
            top: 125vw;
            z-index: 99;
            &.slick-next {
                transform: translateX(50px);
            }
        }
    }
`;

const HeaderTitle = styled.div`
    font-size: 2.1vw;
    line-height: 2.6vw;
    margin-bottom: 1.8vw;
    font-family: ${gilroyMedium};
    letter-spacing: -0.08vw;
    ${Media('tablet')} {
        font-size: 5.6vw;
        line-height: 6.5vw;
        text-align: center;
        padding-bottom: 4vw;
    }
`;

const HeaderText = styled.div`
    font-size: 0.8vw;
    letter-spacing: 0.05vw;
    line-height: 1.8vw;
    font-family: ${gilroyLight};
    ${Media('tablet')} {
        font-size: 3.6vw;
        line-height: 5.3vw;
        text-align: center;
    }
`;

const HeaderOne = styled.div`
    padding: 12.6vw 8vw 12vw 9vw;
    background: #1d1d1f;
    color: #fff;
    // float: left;
    ${Media('tablet')} {
        float: none;
    }
`;
