import React from 'react';
import styled from 'styled-components';
import { Container, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import {
    IndustrySectionHeading,
    IndustryTag,
    Text,
    ContentCenter,
    IndustryPerformanceBox,
    AmplifySection,
    LetsDiscuss,
} from 'components/industries/common';
import { RowContentCenter } from 'shared-components/theme/grid-helpers';

export const PerformanceAmplifySec = ({ performanceList, amplifyList }) => {
    return (
        <>
            <CXPerformance id="cx-performance">
                <Container>
                    <div>
                        <ContentCenter>
                            <IndustryTag>Chaos Reduction</IndustryTag>
                            <IndustrySectionHeading>
                                The On-Demand Experience Ecosystem, <br />
                                Powered by ibex
                            </IndustrySectionHeading>
                            <Text>
                                Ensure repeatable success with ibex’s CX
                                Performance Methodology.
                            </Text>
                        </ContentCenter>
                    </div>
                </Container>
                <Container fluid>
                    <PerformanceFluidRow>
                        {performanceList &&
                            performanceList.length > 0 &&
                            performanceList.map((listItem, index) => {
                                return (
                                    <Col lg="3" md="6" key={index}>
                                        <div>
                                            <IndustryPerformanceBox
                                                count={listItem.count}
                                                image={listItem.imgUrl}
                                                title={listItem.title}
                                                list={listItem.list}
                                            />
                                        </div>
                                    </Col>
                                );
                            })}
                    </PerformanceFluidRow>
                </Container>
            </CXPerformance>

            <AmplifySection
                amplifyList={amplifyList}
                tag="LOREM"
                heading="Scale Fast & Frictionless CX, Now"
                para="Featuring intelligent systems and hand-picked agents, ibex puts the power <br />
                of an industry-tested CX performance engine directly into the CX nerve center of your brand."
            />

            <LetsDiscuss
                heading="Looking to level-up your On-Demand Content & Entertainment CX?"
                btnText="Talk to us"
            />
        </>
    );
};
const CXPerformance = styled.section`
    background-color: ${colors.white['100']};
    padding-bottom: 0px;
`;

const PerformanceFluidRow = styled(RowContentCenter)`
    ${Media('xlscreens')} {
        margin: 0px 3vw !important;
    }
`;
