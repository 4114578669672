import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import {
    gilroyMedium,
    gilroyLight,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import SlickSlider from 'shared-components/slider/slider';
import ScrollAnimation from 'react-animate-on-scroll';
import { baseURL } from 'shared-components/theme/helpers';
import { baseURLPage } from 'shared-components/theme/helpers';
import Button from 'shared-components/button/button';
const DigitalCases = () => {
    const settingsBlog = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        centerMode: false,
        centerPadding: '0px',
        focusOnSelect: false,
        autoplay: false,
        autoplaySpeed: 3000,
        initialSlide: 0,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div>
        <Wrapper id="casestudies">
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                        <div>
                            <BlogSlider>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <BlogBox>
                                        <a
                                            href={`${baseURLPage}pixel-perfect-subscriptions`}>
                                            <Box>
                                                <Ccontent
                                                    style={{
                                                        backgroundImage: `url(${baseURL}assets/images/digital_trendybutler_image.png)`,
                                                    }}>
                                                    <img
                                                        src={`${baseURL}assets/images/digital_trendybutler.png`}
                                                    />
                                                </Ccontent>
                                            </Box>

                                            <CaseStudieText>
                                                Pixel-powered subscriptions
                                            </CaseStudieText>
                                        </a>
                                    </BlogBox>
                                    <BlogBox>
                                        <a
                                            href={`${baseURLPage}runway-to-the-holidays`}>
                                            <Box>
                                                <Ccontent
                                                    style={{
                                                        backgroundImage: `url(${baseURL}assets/images/digital_cirquet_image.png)`,
                                                    }}>
                                                    <img
                                                        src={`${baseURL}assets/images/digital_cirquet.png`}
                                                    />
                                                </Ccontent>
                                            </Box>
                                            <CaseStudieText>
                                                Converting more to Criquet
                                            </CaseStudieText>
                                        </a>
                                    </BlogBox>
                                    <BlogBox>
                                        <a
                                            href={`${baseURLPage}subscription-streetwear-influencer`}>
                                            <Box>
                                                <Ccontent
                                                    style={{
                                                        backgroundImage: `url(${baseURL}assets/images/digital_threadbeast_img.png)`,
                                                    }}>
                                                    <img
                                                        src={`${baseURL}assets/images/digital_threadbeast.png`}
                                                    />
                                                </Ccontent>
                                            </Box>

                                            <CaseStudieText>
                                                Influencing streetwear
                                                subscriptions
                                            </CaseStudieText>
                                        </a>
                                    </BlogBox>
                                </div>
                            </BlogSlider>
                        </div>
                    </Col>
                </Row>
                
            </Container>
        </Wrapper>
        <CustomerAcquisition>
            <Container>
                <Row>
                    <Col sm="12" md="12" lg="6">
                        <div>
                            <div>
                                <CustomerAcquisitionlTxt>
                                    <h3>
                                        We accelerate <br />
                                        customer acquisition <br />
                                        for tomorrow's brands
                                    </h3>
                                    <p>
                                        ibex delivers innovative online acquisition technology<br />
                                        and end-to-end customer engagement solutions to<br />
                                        help companies acquire, engage, and retain valuable<br />
                                        customers. Successfully managing over 200M<br />
                                        differentiated customer interactions
                                    </p>
                                    <h5>
                                        Our Flagship Digital Channels
                                    </h5>               
                                </CustomerAcquisitionlTxt>
                            </div>
                        </div>
                    </Col>
                    <Col sm="12" md="12" lg="6">
                        <CustomerAcquisitionlRight>
                            <img
                                src={`${baseURL}assets/images/lcd-logo.svg`}
                                alt=""
                            />
                           <p>LocalCableDeals is America's leading telecom marketplace, helping people find and compare top internet providers, access industry insights, read expert reviews, and order perfectly tailored plans for all home service needs.</p>
                           <Button
                                href="https://www.localcabledeals.com/"
                                round={true}
                                target="_blank"
                                >
                                Visit Site
                            </Button>
                        </CustomerAcquisitionlRight>
                        <CustomerAcquisitionlRight>
                            <img
                                src={`${baseURL}assets/images/btip-logo.svg`}
                                alt=""
                            />
                           <p>BuyTVInternetPhone is your trusted platform for internet & TV
                                 providers - compare, select, and shop in one click.</p>
                           <Button
                                href="https://www.buytvinternetphone.com/"
                                round={true}
                                target="_blank"
                                >
                                Visit Site
                            </Button>
                        </CustomerAcquisitionlRight>
                    </Col>
                </Row>
            </Container>
        </CustomerAcquisition>
       
        </div>
    );
};

const Box = styled.div`
    position: relative;
    width: 100%;
    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }
`;

const Ccontent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #333;
    color: #fff;
    line-height: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    border-radius: 21px;
    img {
        width: 38vw;
    }
    ${Media('xlscreens')} {
        img {
            width: 12vw;
        }
    }
`;
const Wrapper = styled.div`
    background-color: #f5f5f7;
    padding: 100px 0 50px 240px;
    ${Media('tablet')} {
        padding: 50px 0 0px 0px;
    }
    ${Media('xlscreens')} {
        // padding-left: 27.2vw;
        overflow: hidden;
    }
`;

const BlogSlider = styled.div`
    margin-top: 70px;
    margin-bottom: 30px;
    position: relative;
    .slick-arrow {
        position: absolute;
        left: -19vw;
        top: calc(25% - 18px);
        z-index: 99;
        &.slick-next {
            transform: translateY(50px);
        }
        ${Media('large')} {
            top: calc(45% - 25px);
            &.slick-next {
                transform: translateY(62px);
            }
        }
    }

    ${Media('tablet')} {
        margin-top: 50px;
        margin-bottom: 50px;
        .slick-arrow {
            left: calc(50% - 50px);
            top: auto;
            bottom: -30px;
            &.slick-next {
                transform: translateX(50px);
            }
        }
    }
`;

const BlogBox = styled.div`
    ${Media('xlscreens')} {
        width: 26vw !important;
        padding-right: 40px;
    }
`;

const CaseStudieText = styled.div`
    text-align: center;
    font-family: ${gilroySemibold};
    font-size: 1.6vw;
    padding: 50px 0 20px;

    ${Media('tablet')} {
        padding: 9vw 0.5vw;
        font-size: 7vw;
    }
    ${Media('xlscreens')} {
        padding-top: 3.8vw;
    }
    span {
        font-size: 10px;
        color: ${colors.gray_dark['50']};
        display: block;
        margin-bottom: 5px;
        ${Media('xlscreens')} {
            font-size: 0.65vw;
        }
    }

    h4 {
        font-size: 14px;
        color: ${colors.gray_dark['100']};
        margin-bottom: 15px;
        margin-top: 15px;
        font-family: ${gilroyMedium};
        font-weight: 500;
        ${Media('xlscreens')} {
            font-size: 0.95vw;
        }
    }

    p {
        font-size: 10px;
        font-family: ${gilroyLight};
        color: ${colors.gray_dark['100']};
        max-width: 60%;
        ${Media('xlscreens')} {
            font-size: 0.8vw;
        }
    }
`;

const CustomerAcquisition = styled.section`
 background-color: ${colors.white['100']};
`;

const CustomerAcquisitionlTxt = styled.div`
    h3{
        font-size: 38px;
        text-align: right;
        line-height: 1.5;
    }
    p{
        text-align: right;
        color: ${colors.gray['100']};

    }
    h5{
        font-size: 20px;
        text-align: right;
        margin-top: 20px;
    }

    ${Media('xlscreens')} {
        h3{
            font-size: 3vw;
        }

        h5{
            font-size: 1.3vw;
        }
    }

    ${Media('tablet')} {
        h3{
            text-align: left;
            font-size: 45px;
        }
        p{
            text-align: left;
            font-size: 25px;
        }
        h5{
            text-align: left;
            font-size: 35px;
        }
        
    }

    ${Media('mobile')} {
        padding: 0 35px;
        h3{
            text-align: center;
            font-size: 30px;
            line-height: 1.3;
        }
        p{
            text-align: center;
            font-size: 18px;
        }
        h5{
            text-align: center;
            font-size: 25px;
        }
       
    }
`;



const CustomerAcquisitionlRight = styled.div`
   margin-bottom: 25px;
   background-color:${colors.digitalBg} ;
   padding:30px;
   p{
    color: ${colors.gray['100']};
    font-size: 13px;
   }

   a{
        min-width: 7vw;
        font-size: 12px;
        padding: 8px 20px;
   }
   ${Media('xlscreens')} {
        padding: 2vw;
        img{
            width: 15vw;
        }
        p{
            font-size: 0.9vw;
        }
        a{
            font-size: .8vw;
        }
   }
   ${Media('tablet')} {
        padding:45px;
        img{
            width:50vw;
        }
        p{
            font-size:25px;
        }
        a{
            min-width: 210px;
            font-size: 25px;
            padding: 15px;
        }
    }

    ${Media('mobile')} {
        padding: 35px;
        p{
            text-align: center;
            font-size: 18px;
        }
        a{
            text-align: center;
            font-size: 18px;
            margin: auto;
            width:100%;
            padding:10px;
        }
        img{
            margin: auto;
            width:60vw;
        }
    }
`;



export default DigitalCases;
