import { Col, Row } from '@bootstrap-styled/v4';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import { IconButton } from './icon-button';
import { Para } from './para';
export const TechPage = ({ $reverse, title, para, thumbImg, pageLink }) => {
    return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
            <Wrapper noGutters $reverse={$reverse}>
                <TechPageDesc lg="5" md={6}>
                    <h2>{title}</h2>
                    <Para align="left">{para}</Para>
                    <IconButton btnText="Show Me" href={pageLink} />
                </TechPageDesc>
                <TechPageImg lg="7" md={6}>
                    <img src={thumbImg} alt="-" />
                </TechPageImg>
            </Wrapper>
        </ScrollAnimation>
    );
};
const Wrapper = styled(Row)`
    padding: 0px 100px;
    flex-direction: ${(props) => (props.$reverse ? 'row-reverse' : 'row')};
    margin-bottom: 100px;
    ${Media('xlscreens')} {
        padding: 0 5.2vw;
        margin-bottom: 5.2vw;
    }
    ${Media('tablet')} {
        padding: 0px 30px;
    }
    ${Media('mobile')} {
        padding: 0 15px;
        flex-direction: column-reverse;
    }
`;
const TechPageDesc = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
        font-size: 40px;
        font-family: ${gilroySemibold};
        margin: 0;
        line-height: 1.62;
    }
    p {
        margin: 60px 0;
        line-height: 1.8;
    }
    ${Media('xlscreens')} {
        padding: 0 5.2vw !important;
        h2 {
            font-size: 2.08vw;
        }
        p {
            margin: 3.125vw 0;
        }
    }
    ${Media('tablet')} {
        padding: 0 30px !important;
        h2 {
            font-size: 24px;
        }
        p {
            margin: 20px 0;
        }
    }
    ${Media('mobile')} {
        padding: 0 !important;
        margin-top:30px;
        text-align:center;
        align-items:center;
        h2 {
            font-size: 22px;
        }
        p {
            margin: 20px 0;
            text-align:center;
        }
    }
`;
const TechPageImg = styled(Col)`
    img {
        width: 100%;
    }
`;
