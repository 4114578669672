import React, { useState } from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import Input from 'shared-components/input/input';
import Button from 'shared-components/button/button';
import { RowCenteredBoth } from 'shared-components/theme/grid-helpers';
import fetch from 'cross-fetch';

const EventForm = ({ level, onSuccess }) => {
    const [first_name, set_first_name] = useState('');
    const [email_address, set_email_address] = useState('');
    const [submit_btn_text, set_submit_btn_text] = useState('Register');

    const handleForm = async (e) => {
        e.preventDefault();
        try {
            var requestOptions = {
                method: 'POST',
            };
            const res = await fetch(
                `${process.env.REACT_APP_IBEX_API}/subscribe-form?token=${process.env.REACT_APP_API_TOKEN}&first_name=${first_name}&email_address=${email_address}&referrer=event`,
                requestOptions
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                set_first_name('');
                set_email_address('');
                onSuccess(first_name, true);
            } else {
                set_contact_msg(result);
                set_submit_btn_text('Register');
            }
        } catch (err) {
            console.error(err);
            set_submit_btn_text('Register');
        }
    };

    return (
        <BannerForm level={level} onSubmit={(e) => handleForm(e)}>
            {level && level === 'bottom' ? (
                <RowCenteredBoth>
                    <Col lg="4">
                        <Input
                            label="Name"
                            name="first_name"
                            onChange={(val) => set_first_name(val.target.value)}
                            value={first_name}
                            color={colors.white['100']}
                            label_opacity={1}
                        />
                    </Col>
                    <Col lg="4">
                        <Input
                            label="Email"
                            name="email_address"
                            onChange={(val) =>
                                set_email_address(val.target.value)
                            }
                            value={email_address}
                            color={colors.white['100']}
                            label_opacity={1}
                        />
                    </Col>
                    <Col lg="2">
                        <FormBtn>
                            <Button
                                bgColor={colors.pink['100']}
                                color={colors.white['100']}
                                ifButton={true}
                                submit={true}
                                round={true}>
                                <b>{submit_btn_text}</b>
                            </Button>
                        </FormBtn>
                    </Col>
                </RowCenteredBoth>
            ) : (
                <Row>
                    <Col lg="4">
                        <Input
                            label="Name"
                            name="first_name"
                            onChange={(val) => set_first_name(val.target.value)}
                            value={first_name}
                            color={colors.white['100']}
                            label_opacity={1}
                        />
                    </Col>
                    <Col lg="4">
                        <Input
                            label="Email"
                            name="email_address"
                            onChange={(val) =>
                                set_email_address(val.target.value)
                            }
                            value={email_address}
                            color={colors.white['100']}
                            label_opacity={1}
                        />
                    </Col>
                    <Col lg="4">
                        <FormBtn>
                            <Button
                                bgColor={colors.pink['100']}
                                color={colors.white['100']}
                                ifButton={true}
                                submit={true}
                                round={true}>
                                <b>{submit_btn_text}</b>
                            </Button>
                        </FormBtn>
                    </Col>
                </Row>
            )}
        </BannerForm>
    );
};

const BannerForm = styled.form`
    margin-top: ${(props) => (props.level === 'bottom' ? '50px' : '0')};
    margin-bottom: 50px;
    ${Media('xlscreens')} {
        margin: 3vw 0;
        margin-top: ${(props) => (props.level === 'bottom' ? '6vw' : '0')};
        input,
        label {
            font-size: 1.05vw;
        }
    }
`;

const FormBtn = styled.div`
    margin-top: 1.3rem;
    padding-left: 10px;
    button {
        border-radius: 11px;
        font-size: 16px;
        letter-spacing: 0.5px;
    }
    ${Media('xlscreens')} {
        button {
            font-size: 1.05vw;
        }
    }
    ${Media('tablet')} {
        padding-left: 0px;
    }
`;

export default EventForm;
