import React, { Fragment } from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import ContactForm from '../contact-form/contact-form';
import { MdClose } from 'react-icons/md';
import { MarketoFormDesign } from 'shared-components/theme/grid-helpers';
import MarketoForm from 'shared-components/marketo-form/marketo-form';

const DemoRequestForm = ({ formState, handleDemoForm, marketFormId }) => {
    return (
        <Container open={formState}>
            <Overlay onClick={() => handleDemoForm(false)} />
            <DemoFormWrapper open={formState}>
                <ContactForm
                    heading={
                        <Fragment>
                            Demo Request<span>.</span>
                            <br />
                        </Fragment>
                    }
                    color={`${colors.white['100']}`}
                    headingSize={'2.42vw'}
                    padding={true}
                    cx_demo={true}
                    subHeading={
                        <Fragment>
                            <span>
                                Give us your details, and we&apos;ll get your demo
                                scheduled.
                            </span>
                            <br />
                            <br />
                        </Fragment>
                    }
                    noForm={marketFormId}
                />
                {marketFormId && (
                    <MarketoFormDesign halfField darkBack>
                        <MarketoForm Id={marketFormId} />
                    </MarketoFormDesign>
                )}
                <CloseFormBtn onClick={() => handleDemoForm(false)}>
                    <MdClose />
                </CloseFormBtn>
            </DemoFormWrapper>
        </Container>
    );
};
const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${colors.black['50']};
    z-index: 9999;
    transition: all 0.3s ease;
    ${(props) => {
        if (props.open) {
            return `opacity: 1;
                    visibility: visible;`;
        } else {
            return `opacity: 0;
                    visibility: hidden;`;
        }
    }};
    ${Media('xlscreens')} {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
const Overlay = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    ${Media('tablet')} {
        display: none;
    }
`;
const DemoFormWrapper = styled.div`
    width: 50%;
    background-color: ${colors.black['100']};
    padding: 50px;
    border-radius: 5px;
    position: relative;
    max-height: 100vh;
    overflow-y: auto;
    transform: scale(0.8);
    transition: all 0.3s ease;
    ${(props) => {
        if (props.open) {
            return `transform: scale(1)`;
        } else {
            return `transform: scale(0.8)`;
        }
    }};
    & > div:first-child {
        padding: 8px;
    }
    ${Media('xlscreens')} {
        min-height: 50%;
        padding: 2.6vw;
    }
    ${Media('tablet')} {
        height: 100%;
        width: 100%;
        padding: 0px;
    }
    ${Media('mobile')} {
        & > div:first-child {
            padding: 15px;
            padding-top: 25px;
        }
        form.mktoForm {
            padding: 8px 8px 20px !important;
        }
    }
`;
const CloseFormBtn = styled.div`
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    svg {
        color: ${colors.white['100']};
        font-size: 30px;
    }
    ${Media('xlscreens')} {
        top: 1.3vw;
        right: 1.3vw;
        svg {
            font-size: 1.57vw;
        }
    }
`;
export default DemoRequestForm;
