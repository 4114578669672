import React from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroySemibold, gilroyLight } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';

const BottomSection = ({
    leftPara,
    rightList,
    withoutQoute,
    mainHeading,
    smallHeading,
    smallLeftHeading,
    headingAlignCenter,
    styleTwo,
    withoutOffset,
}) => {
    return (
        <ScrollAnimation animateIn="fadeInUp">
            <Wrapper
                withoutQoute={withoutQoute}
                smallLeftHeading={smallLeftHeading}
                styleTwo={styleTwo}>
                <CustomContainer
                    fluid={styleTwo ? true : false}
                    styleTwo={styleTwo}>
                    {mainHeading && (
                        <Row>
                            <Col sm="12" lg={{ size: 10, offset: 1 }}>
                                <Heading
                                    smallHeading={smallHeading}
                                    headingAlignCenter={headingAlignCenter}>
                                    {ReactHtmlParser(mainHeading)}
                                </Heading>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <CustomCol
                            lg={withoutOffset ? '6' : { size: 5, offset: 1 }}
                            styleTwo={styleTwo}>
                            {ReactHtmlParser(leftPara)}
                        </CustomCol>
                        <Col lg="6">{ReactHtmlParser(rightList)}</Col>
                    </Row>
                </CustomContainer>
            </Wrapper>
        </ScrollAnimation>
    );
};
const Wrapper = styled.div`
    padding-top: 100px;
    padding-bottom: ${(props) => (props.withoutQoute ? '100px' : '250px')};
    background-color: ${colors.black['3']};
    h2 {
        color: ${colors.gray_dark['100']};
    }
    p {
        font-family: ${gilroyLight};
        color: ${colors.gray_dark['100']};
        font-size: 24px;
        padding-top: ${(props) => (props.withoutQoute ? '0px' : '20px')};
        padding-bottom: ${(props) => (props.withoutQoute ? '0px' : '60px')};
        line-height: 1.75;
        letter-spacing: -0.5px;
        ${Media('xlscreens')} {
            font-size: 1.25vw;
            width: 90%;
        }
    }
    ul {
        padding: 0;
        margin-top: 45px;
        ${Media('xlscreens')} {
            margin-top: 2.3vw;
            padding-left: 1.57vw;
        }
        li {
            color: ${colors.gray_dark['100']};
            font-family: ${gilroySemibold};
            font-size: ${(props) => (props.styleTwo ? '21px' : '24px')};
            position: relative;
            padding: 10px 5px;
            padding-left: 75px;
            ${Media('tablet')} {
                font-size: 18px;
            }
            &:after {
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: ${colors.pink['100']};
                position: absolute;
                left: 25px;
                top: 50%;
                transform: translateY(-50%);
            }
            ${Media('xlscreens')} {
                font-size: ${(props) => (props.styleTwo ? '1.09vw' : '1.25vw')};
                padding-left: 3.9vw;
                &:after {
                    width: 0.31vw;
                    height: 0.31vw;
                    left: 1.3vw;
                }
            }
            &:nth-child(odd) {
                background-color: ${colors.white['100']};
            }
        }
    }
    ${Media('xlscreens')} {
        padding-top: 5.23vw;
        padding-bottom: ${(props) => (props.withoutQoute ? '5.23vw' : '13vw')};
    }
    ${Media('tablet')} {
        padding: 50px 0px;
        h2 {
            font-size: 30px;
        }
        p {
            padding: 0px;
            font-size: 18px;
            br {
                display: none;
            }
        }
        ul {
            margin-top: 20px;
            li {
                padding-left: 60px;
            }
        }
    }
`;
const CustomContainer = styled(Container)`
    ${Media('xlscreens')} {
        padding-left: 13vw;
        padding-right: 13vw;
    }
    ${Media('tablet')} {
        max-width: 720px;
    }
`;
const CustomCol = styled(Col)`
    ${Media('xlscreens')} {
        padding-left: ${(props) => (props.styleTwo ? '1vw !important' : '0')};
    }
`;
const Heading = styled.h2`
    margin-bottom: 30px;
    text-align: center;
    ${Media('xlscreens')} {
        margin-bottom: 3vw;
    }
    ${Media('tablet')} {
        margin-bottom: 10px;
        text-align: left;
        font-size: 32px;
    }
`;
export default BottomSection;
