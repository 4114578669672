import React from 'react';
import { Container, Col, Row } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import Button from 'shared-components/button/button';
import { gilroyBold, gilroySemibold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';
import {
    RowContentCenter,
    FlexColumnCentered,
} from 'shared-components/theme/grid-helpers';
import {
    Text,
    IndustryTag,
    IndustrySectionHeading,
    ContentCenter,
    IndustryPerformanceBox,
} from 'components/industries/common';
import { baseURLPage } from 'shared-components/theme/helpers';

export const PerformanceEbookSec = ({ cxValues, performanceList }) => {
    return (
        <>
            <CXPerformance id="cx-performance">
                <Container>
                    <div>
                        <ContentCenter>
                            <IndustryTag>
                                From Startups to Fortune 500<span>s</span>.
                            </IndustryTag>
                            <IndustrySectionHeading>
                                Enabling and Enhancing the Financial Services
                                <br /> CX Value Chain
                            </IndustrySectionHeading>
                            <Text>
                                At ibex, we are experts at managing financial
                                interactions across the globe, seamlessly
                                processing high-volume interactions <br />
                                while unlocking value at every touchpoint.
                            </Text>
                        </ContentCenter>
                    </div>
                    <CxValues>
                        {cxValues &&
                            cxValues.length > 0 &&
                            cxValues.map((value, index) => {
                                return (
                                    <Col lg="1" md="3" xs="4" key={index}>
                                        <div>
                                            <FlexColumnCentered>
                                                <img src={value.icon} alt="-" />
                                                <h5>{value.title}</h5>
                                            </FlexColumnCentered>
                                        </div>
                                    </Col>
                                );
                            })}
                    </CxValues>
                    <RowContentCenter>
                        {performanceList &&
                            performanceList.length > 0 &&
                            performanceList.map((listItem, index) => {
                                return (
                                    <Col lg="4" md="6" key={index}>
                                        <div>
                                            <IndustryPerformanceBox
                                                image={listItem.imgUrl}
                                                title={listItem.title}
                                                list={listItem.list}
                                            />
                                        </div>
                                    </Col>
                                );
                            })}
                    </RowContentCenter>
                </Container>
            </CXPerformance>
            <EbookSection noGutters={true}>
                <Col lg="6">
                    <img
                        src={`${baseURL}assets/images/industries/fintech-ebook-cover.jpg`}
                        alt="-"
                    />
                </Col>
                <Col lg="6">
                    <EbookDetail>
                        <div>
                            <EbookHeading>
                                The Fintech CX <br />
                                Digital Playbook
                            </EbookHeading>
                            <EbookText>
                                Your Guide To Overcoming Complexity Across
                                <br />
                                Your Financial Customer Experience Ecosystem.
                            </EbookText>
                            <EbookDownBtn>
                                <Button
                                    ifButton={false}
                                    round={true}
                                    bgColor={colors.dark_purple['100']}
                                    href={`${baseURLPage}resources/ebooks/g/fintech-cx-digital-playbook`}>
                                    Download Now
                                </Button>
                            </EbookDownBtn>
                        </div>
                    </EbookDetail>
                </Col>
            </EbookSection>
        </>
    );
};
const Section = styled.section`
    ${Media('tablet')} {
        padding: 50px 0;
    }
`;
const CXPerformance = styled(Section)`
    background-color: ${colors.white['100']};
    padding-bottom: 0px;
`;
const CxValues = styled(Row)`
    margin-bottom: 50px;
    img {
        height: 35px;
        max-width: 40px;
        object-fit: contain;
    }
    h5 {
        font-size: 16px;
        font-family: ${gilroySemibold};
    }
    ${Media('xlscreens')} {
        margin-bottom: 4vw;
        justify-content: space-between;
        img {
            height: 2.08vw;
            max-width: 2.6vw;
        }
        h5 {
            font-size: 0.93vw;
        }
    }
    ${Media('tablet')} {
        h5 {
            margin-bottom: 50px;
        }
    }
`;
const EbookSection = styled(Row)`
    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
    ${Media('tablet')} {
        img {
            display: none;
        }
    }
`;
const EbookDetail = styled.div`
    height: 100%;
    background-color: ${colors.pink['100']};
    display: flex;
    align-items: center;
    padding-left: 50px;
    ${Media('xlscreens')} {
        padding-left: 8vw;
    }
    ${Media('tablet')} {
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 50px 0;
        width: 100%;
    }
`;
const EbookHeading = styled.h1`
    font-size: 36px;
    font-family: ${gilroyBold};
    line-height: 1.17;
    color: ${colors.white['100']};
    margin-top: 0;
    ${Media('xlscreens')} {
        font-size: 2.6vw;
    }
    ${Media('tablet')} {
        margin-top: 0;
    }
    ${Media('mobile')} {
        font-size: 30px;
    }
`;
const EbookText = styled.p`
    font-size: 14px;
    margin-top: 0;
    color: ${colors.white['100']};
    ${Media('xlscreens')} {
        font-size: 0.93vw;
    }
`;
const EbookDownBtn = styled.div`
    margin-top: 50px;
    a {
        padding: 15px 80px;
    }
    ${Media('xlscreens')} {
        margin-top: 2.6vw;
        a {
            padding: 0.78vw 4.166vw;
        }
    }
    ${Media('mobile')} {
        a {
            padding: 10px 40px;
        }
    }
`;
