import React from 'react';
import styled from 'styled-components';
import { gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import { UtilTag } from './tag';

export const UtilStepHeading = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
    display: flex;
    padding-bottom: 50px;
    .main-heading {
        letter-spacing: -0.5px;
        font-family: ${gilroySemibold};
        padding-left: 30px;
    }
    ${UtilTag} {
        padding-top: 5px;
    }
    ${Media('xlscreens')} {
        padding-bottom: 5vw;
        .main-heading {
            letter-spacing: -0.05vw;
            padding-left: 3.9vw;
        }
        ${UtilTag} {
            padding-top: 0.4vw;
        }
    }
    ${Media('mobile')} {
        padding-top: 50px;
        flex-direction: column;
        ${UtilTag} {
            padding-top: 0px;
            padding-bottom: 20px;
        }
    }
`;
