import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import { gilroyHeavy, gilroyLight } from 'shared-components/theme/fonts';
import ReactHtmlParser from 'react-html-parser';

const CaseStudyIntro2 = ({
    introPara,
    introImage,
    introHeading,
    introSubHeading,
    headingPaddingTop,
    brandLogo,
    noPadding,
    useLogo,
}) => {
    return (
        <Wrapper noPadding={noPadding}>
            {useLogo && brandLogo && brandLogo !== '' ? (
                <BrandLogo>
                    <img src={brandLogo} alt="" />
                </BrandLogo>
            ) : (
                introSubHeading && (
                    <IntroSubHeading>
                        {ReactHtmlParser(introSubHeading)}
                    </IntroSubHeading>
                )
            )}
            <IntroHeading headingPaddingTop={headingPaddingTop}>
                {ReactHtmlParser(introHeading)}
            </IntroHeading>
            <ParaSection>{ReactHtmlParser(introPara)}</ParaSection>
            <ImageSection>
                <img src={introImage} alt="-" />
            </ImageSection>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    ${Media('xlscreens')} {
        margin-top: 5.23vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 76.1vw;
        padding: ${(props) => (props.noPadding ? '0' : '0px 5vw')};
    }
    ${Media('tablet')} {
        padding-top: 30px;
    }
`;
const BrandLogo = styled.div`
    img {
        max-width: 300px;
    }
    ${Media('xlscreens')} {
        img {
            max-width: 15.625vw;
        }
    }
`;
const IntroHeading = styled.h1`
    margin: 30px 0px;
    text-align: center;
    ${Media('tablet')} {
        text-align: left;
    }
    ${Media('xlscreens')} {
        margin: 1.57vw 0;
    }
`;
const IntroSubHeading = styled.div`
    font-size: 28px;
    font-family: ${gilroyHeavy};
    ${Media('xlscreens')} {
        font-size: 1.46vw;
    }
`;
const ParaSection = styled.div`
    p {
        font-size: 24px;
        font-family: ${gilroyLight};
        line-height: 1.91;
    }
    ${Media('xlscreens')} {
        text-align: center;
        position: relative;
        z-index: 11;
        border-bottom-right-radius: 0.52vw;
        padding-bottom: 2.5vw;
        width: 80%;
        p {
            font-size: 1.25vw;
        }
    }
    ${Media('tablet')} {
        p {
            font-size: 18px;
            br {
                display: none;
            }
        }
    }
`;
const ImageSection = styled.div`
    ${Media('tablet')} {
        img {
            width: 100%;
        }
    }
    ${Media('xlscreens')} {
        img {
            width: 100%;
        }
    }
`;
export default CaseStudyIntro2;
