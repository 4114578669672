import { Col, Row } from '@bootstrap-styled/v4';
import React from 'react';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import { BigHeading } from './big-heading';
import { BlockHeading } from './block-heading';
import { CXSectionIntro } from './cx-section-intro';
import { Para } from './para';

export const CXPoint7 = ({ active }) => {
    return (
        <div>
            <CXSectionIntro
                active={active}
                progress={0.83}
                source="Source: Life Size"
                progressImg={`${baseURL}assets/images/ebook/cx-progress7.svg`}
                introPara="Significantly, <span>83% of businesses</span> say it’s important to develop leaders at all levels, yet less than 5% of companies have implemented leadership development across all levels."
            />
            <BigHeading>
                Leadership from CEO to the frontlines must be engaged and well-attuned to the day-to-day of the contact center. You’ll also want to look for partners with a flat organizational structure that is flexible and responsive. These solution providers will more quickly anticipate your needs and adapt when circumstances change.
            </BigHeading>
            <Para>Consider the following:</Para>
            <Row>
                <Col lg="6">
                    <BlockHeading>Launch Prep</BlockHeading>
                    <Para>
                        Look for leadership that is trained and invested up
                        front, before any agent is hired or trained. They should
                        be prepared to be effective ambassadors for your brand.
                    </Para>
                </Col>
                <Col lg="6">
                    <PaddingWrapper>
                        <BlockHeading>
                            Mantra of Customer Centricity
                        </BlockHeading>
                        <Para>
                            Look for customer-centric leadership. Make sure they
                            have a deep understanding of both your customers’
                            needs and agents’ frustrations and challenges.
                        </Para>
                    </PaddingWrapper>
                </Col>
                <Col lg="6">
                    <BlockHeading>Value Matching</BlockHeading>
                    <Para>
                        Look for a CX partner whose leadership values line up
                        with your own internal values and priorities. Ask about
                        how teams are managed – make sure agents are going to be
                        managed in a similar way to employees on your internal
                        team.
                    </Para>
                </Col>
                <Col lg="6">
                    <PaddingWrapper>
                        <BlockHeading>Industry Expertise</BlockHeading>
                        <Para>
                            Look for a partner with a proven track record in
                            your industry, and who can understand your specific
                            needs, desires, and challenges. Consider their
                            current and past clients, and ask for references.
                        </Para>
                    </PaddingWrapper>
                </Col>
            </Row>
        </div>
    );
};

const PaddingWrapper = styled.div`
    padding-left: 50px;
    ${Media('xlscreens')} {
        padding-left: 2.6vw;
    }
    ${Media('tablet')} {
        padding-left: 0;
    }
`;
