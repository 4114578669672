import React from 'react';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';

const AutomateAnim = ({ index }) => {
    return (
        <div>
            {index === 0 && (
                <AnimWrapper>
                    <AnimMainImg>
                        <img
                            src={`${baseURL}assets/images/wave-ix/automate/cx_anim_img_1.png`}
                            alt="-"
                        />
                    </AnimMainImg>
                    <CX1AnimPlanet>
                        <img
                            src={`${baseURL}assets/images/wave-ix/automate/cx_programme_1_planet.png`}
                            alt="-"
                        />
                    </CX1AnimPlanet>
                    <CX1AnimPlanet leftAlign>
                        <img
                            src={`${baseURL}assets/images/wave-ix/automate/cx_programme_1_planet.png`}
                            alt="-"
                        />
                    </CX1AnimPlanet>
                </AnimWrapper>
            )}
            {index === 1 && (
                <AnimWrapper>
                    <AnimMainImg>
                        <img
                             style={{ paddingLeft: '30px' }}
                            src={`${baseURL}assets/images/wave-ix/automate/cx_anim_img_2_test.png`}
                            alt="-"
                        />
                    </AnimMainImg>
                    <AnimCircleImg>
                        <img
                            src={`${baseURL}assets/images/wave-ix/automate/planet-circle-small.svg`}
                            alt="-"
                        />
                        <PlanetImg>
                            <img
                                src={`${baseURL}assets/images/wave-ix/automate/planet-small.png`}
                                alt="-"
                            />
                        </PlanetImg>
                    </AnimCircleImg>
                    <AnimCircleImg secondCircle>
                        <img
                            src={`${baseURL}assets/images/wave-ix/automate/planet-circle-small.svg`}
                            alt="-"
                        />
                        <PlanetImg>
                            <img
                                src={`${baseURL}assets/images/wave-ix/automate/planet-small.png`}
                                alt="-"
                            />
                        </PlanetImg>
                    </AnimCircleImg>
                    <AnimCircleImg thirdCircle>
                        <img
                            src={`${baseURL}assets/images/wave-ix/automate/planet-circle-small.svg`}
                            alt="-"
                        />
                        <PlanetImg>
                            <img
                                src={`${baseURL}assets/images/wave-ix/automate/planet-small.png`}
                                alt="-"
                            />
                        </PlanetImg>
                    </AnimCircleImg>
                    <CogIcon>
                        <img
                            src={`${baseURL}assets/images/wave-ix/automate/cog-icon.png`}
                            alt="-"
                        />
                    </CogIcon>
                </AnimWrapper>
            )}
            {index === 2 && (
                <AnimWrapper>
                    <AnimMainImg>
                        <img
                            src={`${baseURL}assets/images/wave-ix/automate/cx_anim_img_3_full.png`}
                            alt="-"
                        />
                    </AnimMainImg>
                    {/* <CX3AnimPlanet inner>
                        <img
                            src={`${baseURL}assets/images/wave-ix/eye-animate-circle-inner.png`}
                            alt="-"
                        />
                    </CX3AnimPlanet>
                    <CX3AnimPlanet>
                        <img
                            src={`${baseURL}assets/images/wave-ix/eye-animate-circle-outer.png`}
                            alt="-"
                        />
                    </CX3AnimPlanet> */}
                </AnimWrapper>
            )}
            {index === 3 && (
                <AnimWrapper>
                    <AnimMainImg>
                        <img
                            src={`${baseURL}assets/images/wave-ix/automate/cx_anim_img_4.png`}
                            alt="-"
                        />
                    </AnimMainImg>
                    <CX4AnimPlanet>
                        <img
                            src={`${baseURL}assets/images/wave-ix/automate/cx_programme_4_planet.png`}
                            alt="-"
                        />
                    </CX4AnimPlanet>
                    <CX4AnimPlanet reverse>
                        <img
                            src={`${baseURL}assets/images/wave-ix/automate/cx_programme_4_planet.png`}
                            alt="-"
                        />
                    </CX4AnimPlanet>
                    <CX4AnimStar>
                        <img
                            src={`${baseURL}assets/images/wave-ix/automate/cx_anim_img_4_star.png`}
                            alt="-"
                        />
                    </CX4AnimStar>
                </AnimWrapper>
            )}
        </div>
    );
};

const AnimWrapper = styled.div`
    position: relative;
    z-index: 9;
    ${Media('xlscreens')} {
        margin-right: 6vw;
    }
`;

const CX1AnimPlanet = styled.div`
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    animation: rotatePlanet 60s linear infinite;
    ${(props) => {
        if (props.leftAlign) {
            return `
                right:unset;
                left:0;
                animation:rotatePlanetAnti 60s linear infinite;
            `;
        }
    }}
    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    @keyframes rotatePlanet {
        0% {
            opacity: 0;
            transform: rotate(-30deg);
        }
        2% {
            opacity: 1;
        }
        98% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: rotate(140deg);
        }
    }
    @keyframes rotatePlanetAnti {
        0% {
            opacity: 0;
            transform: rotate(-230deg);
        }
        2% {
            opacity: 1;
        }
        98% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: rotate(-40deg);
        }
    }
`;

const AnimMainImg = styled.div`
    img {
        width: 100%;
    }
`;

const CogIcon = styled.div`
    height: 50%;
    width: 50%;
    position: absolute;
    top: 25%;
    left: 25%;
    z-index: -9;
    img {
        width: 100%;
        height: 100%;
    }
`;

const PlanetImg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    animation: rotate 15s linear infinite;
    height: 100%;
    width: 100%;
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

const AnimCircleImg = styled.div`
    position: absolute;
    top: 10%;
    left: 1%;
    height: 50%;
    width: 50%;
    img {
        height: 100%;
        width: 100%;
    }
    ${(props) => {
        if (props.secondCircle) {
            return `
                    right:1%;
                    left:unset;
                    ${PlanetImg}{
                        animation-delay:2s;
                    }
                `;
        }
        if (props.thirdCircle) {
            return `
                    top:58%;
                    left:25%;
                    ${PlanetImg}{
                        animation-delay:4s;
                    }
                `;
        }
    }};
`;

const CX3AnimPlanet = styled.div`
    position: absolute;
    z-index: -9;
    width: 100%;
    top: -68%;
    left: 0;
    img {
        height: 100%;
        width: 100%;
        animation: rotateCircleOuter 60s linear infinite;
    }
    ${(props) => {
        if (props.inner) {
            return `
                display:flex;
                justify-content:center;
                z-index:9;
                top:0%;
                left:0;
                img{
                    height:42%;
                    width:42%;
                    animation: rotateCircleInner 60s linear infinite;
                }
            `;
        }
    }}
    @keyframes rotateCircleOuter {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(-360deg);
        }
    }
    @keyframes rotateCircleInner {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

const CX4AnimPlanet = styled.div`
    position: absolute;
    right: 1%;
    top: 42%;
    height: 16%;
    width: 16%;
    animation: translateX 10s linear infinite;
    ${(props) => {
        if (props.reverse) {
            return `
                top:55.5%;
                animation-name:translateXRev;
            `;
        }
    }}
    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    @keyframes translateX {
        0% {
            right: 1%;
        }
        50% {
            right: 26%;
        }
        100% {
            right: 1%;
        }
    }
    @keyframes translateXRev {
        0% {
            right: 26%;
        }
        50% {
            right: 1%;
        }
        100% {
            right: 26%;
        }
    }
    ${Media('tablet')} {
        height: 15%;
        width: 21%;
    }
`;

const CX4AnimStar = styled.div`
    position: absolute;
    inset: 0;
    animation: rotate 30s linear infinite;
    z-index: -9;
    img {
        height: 100%;
        width: 100%;
    }
`;

export default AutomateAnim;
