import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import { LetsDiscuss } from 'components/industries/common';
import Layout from 'components/layout';
import { Parallax } from 'react-scroll-parallax';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import Button from 'shared-components/button/button';
import colors from 'shared-components/theme/colors';
import { gilroyMedium, gilroySemibold } from 'shared-components/theme/fonts';
import {
    RowCentered,
    RowContentCenter,
} from 'shared-components/theme/grid-helpers';
import { baseURL, baseURLPage } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import ScrollAnimation from 'react-animate-on-scroll';
import { Tween, Timeline } from 'react-gsap';

const ai_feat = [
    {
        icon: `${baseURL}assets/images/wave-ix/translate-ai-1.png`,
        title: '2-Way Voice & Text Translation',
        desc: 'Translate acts as the language interpreter between the customer and agent, supporting every CX channel.',
    },
    {
        icon: `${baseURL}assets/images/wave-ix/translate-ai-2.png`,
        title: 'Speech & Intent Recognition',
        desc: 'Translate flawlessly understands a huge array of accents and dialects, and gleans intent from context.',
    },
    {
        icon: `${baseURL}assets/images/wave-ix/translate-ai-3.png`,
        title: 'Brand Trained AI Voice',
        desc: 'In just 2-3 hours, the voice of your choice will be synthesized for your brand.',
    },
];

const support_items = [
    {
        img: `${baseURL}assets/images/wave-ix/translate-support-1.png`,
        title: 'Scale new markets & new products fast',
        para: 'Forget the need to find the right people with the right language skills to support your new product launch - use Wave iX Translate to quickly scale into new markets.',
    },
    {
        img: `${baseURL}assets/images/wave-ix/translate-support-2.png`,
        title: 'Performance Measurement & QA',
        para: 'Translate’s GenAI backbone performs routine quality assurance on all translated communications to ensure ongoing accuracy and performance measurement.',
    },
    {
        img: `${baseURL}assets/images/wave-ix/translate-support-3.png`,
        title: 'Back Office Integration',
        para: 'Translate can handle your back office needs, from training programs, to help documents, to knowledge bases, and more.',
    },
];

const translations = [
    {
        original: `Bonjour! <br/>Comment puis je vous aider?`,
        translate: `Hello! <br/>How can I help you?`,
    },
    {
        original: `Brindar soporte al cliente <br/> en cualquier idioma.`,
        translate: `Deliver customer support in any language.`,
    },
    {
        original: `Su intérprete de IA en tiempo real.`,
        translate: `Your Real-Time AI Interpreter.`,
    },
    {
        original: `Traducción bidireccional de voz y texto`,
        translate: `2-Way Voice & Text Translation`,
    },
    {
        original: `Reconocimiento de voz e intención`,
        translate: `Speech & Intent Recognition`,
    },
    {
        original: `Voz de IA entrenada por la marca`,
        translate: `Brand Trained AI Voice`,
    },
];

const Translate = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            if (activeIndex !== translations.length - 1) {
                setActiveIndex(activeIndex + 1);
            } else {
                setActiveIndex(0);
            }
        }, 6000);
    });
    return (
        <Layout>
            <Wrapper>
                <Breadcrumbs color={colors.white['50']}>
                    <li>Solutions</li>
                    <li>Wave iX</li>
                    <li>TranXlate</li>
                </Breadcrumbs>
                <Intro>
                    <Container>
                        <RowContentCenter>
                            <Col lg="5">
                                <IntroContent>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={true}>
                                        <img
                                            src={`${baseURL}assets/images/wave-ix/ix-logo.svg`}
                                            alt="-"
                                        />
                                        <h1>Translate</h1>
                                        <p>
                                            Deliver customer support in
                                            <br />
                                            <u>any language,</u> across any
                                            channel,
                                            <br />
                                            in real-time.
                                        </p>
                                        <Button
                                            href={`${baseURLPage}contact-us`}>
                                            Book a Demo
                                            <img
                                                src={`${baseURL}assets/images/wave-ix/automate/arrow-right.svg`}
                                            />
                                        </Button>
                                    </ScrollAnimation>
                                </IntroContent>
                            </Col>
                            <Col lg="7">
                                <IntroAnimation>
                                    <img
                                        src={`${baseURL}assets/images/wave-ix/circle-bg.png`}
                                        alt="-"
                                    />
                                    <Timeline
                                        repeat={-1}
                                        delay={2}
                                        target={
                                            <>
                                                <AnimationCircle>
                                                    <img
                                                        src={`${baseURL}assets/images/wave-ix/circle-animaition.svg`}
                                                        alt="-"
                                                    />
                                                </AnimationCircle>
                                            </>
                                        }>
                                        <Tween
                                            target={0}
                                            from={{ rotation: 0 }}
                                            to={{ rotation: 180 }}
                                            duration={2}
                                        />
                                        <Tween
                                            target={0}
                                            from={{ rotation: 180 }}
                                            to={{ rotation: 360 }}
                                            duration={2}
                                        />
                                    </Timeline>
                                    <AnimationContent>
                                        <Timeline
                                            repeat={-1}
                                            target={
                                                <>
                                                    <IntroCircleText>
                                                        <p>
                                                            {ReactHtmlParser(
                                                                translations[
                                                                    activeIndex
                                                                ].original
                                                            )}
                                                        </p>
                                                    </IntroCircleText>
                                                    <img
                                                        src={`${baseURL}assets/images/wave-ix/circle-anim-direction.svg`}
                                                        alt="-"
                                                    />
                                                    <IntroCircleText rightAlign>
                                                        <p>
                                                            {ReactHtmlParser(
                                                                translations[
                                                                    activeIndex
                                                                ].translate
                                                            )}
                                                        </p>
                                                    </IntroCircleText>
                                                </>
                                            }>
                                            <Tween
                                                target={0}
                                                from={{ opacity: 0 }}
                                                to={{ opacity: 1 }}
                                                duration={2}
                                                delay={2}
                                            />
                                            <Tween
                                                target={2}
                                                from={{ opacity: 0 }}
                                                to={{ opacity: 1 }}
                                                duration={2}
                                            />
                                        </Timeline>
                                    </AnimationContent>
                                </IntroAnimation>
                            </Col>
                        </RowContentCenter>
                    </Container>
                    <CircleAnim>
                        <Parallax x={['60%', '0%']}>
                            <img
                                src={`${baseURL}assets/images/wave-ix/translate-bg-circle.png`}
                                alt="-"
                            />
                        </Parallax>
                    </CircleAnim>
                </Intro>
                <AiInterpreter>
                    <Container>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <SectionHeader>
                                <h2>Your Real-Time AI Interpreter.</h2>
                                <p>
                                    Give every customer the opportunity for
                                    fast, reliable support in their native
                                    language.
                                </p>
                            </SectionHeader>
                        </ScrollAnimation>
                        <RowCentered>
                            <Col lg="6">
                                <AIImageAnimation>
                                    <AIImg>
                                        <img
                                            src={`${baseURL}assets/images/wave-ix/eye-animation-translate.png`}
                                            alt="-"
                                        />
                                    </AIImg>
                                    <AICircle inner>
                                        <img
                                            src={`${baseURL}assets/images/wave-ix/eye-animate-circle-inner.png`}
                                            alt="-"
                                        />
                                    </AICircle>
                                    <AICircle>
                                        <img
                                            src={`${baseURL}assets/images/wave-ix/eye-animate-circle-outer.png`}
                                            alt="-"
                                        />
                                    </AICircle>
                                </AIImageAnimation>
                            </Col>
                            <Col lg="6">
                                <AISectionContent>
                                    {ai_feat &&
                                        ai_feat.length > 0 &&
                                        ai_feat.map((item, index) => {
                                            return (
                                                <AIItem key={index}>
                                                    <IconWrapper>
                                                        <img
                                                            src={item.icon}
                                                            alt="-"
                                                        />
                                                    </IconWrapper>
                                                    <ContentWrapper>
                                                        <ScrollAnimation
                                                            animateIn="fadeInUp"
                                                            animateOnce={true}>
                                                            <h5>
                                                                {item.title}
                                                            </h5>
                                                            <p>{item.desc}</p>
                                                        </ScrollAnimation>
                                                    </ContentWrapper>
                                                </AIItem>
                                            );
                                        })}
                                </AISectionContent>
                            </Col>
                        </RowCentered>
                    </Container>
                </AiInterpreter>
            </Wrapper>
            <Investment>
                <Container>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <SectionHeader color={colors.gray_dark['100']}>
                            <h2>
                                <u>
                                    Financial Investment
                                </u>
                            </h2>
                        </SectionHeader>
                    </ScrollAnimation>
                    <InvestementsFeats>
                        <Row noGutters>
                            <Col lg="6">
                                <InvestmentBlock
                                    style={{
                                        backgroundColor: `${colors.white['100']}`,
                                    }}>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={true}>
                                        <img
                                            src={`${baseURL}assets/images/wave-ix/investment-icon-1.png`}
                                            alt="-"
                                        />
                                        <h3>Agent Assisted Translation</h3>
                                        <ul>
                                            <li>
                                                Agent Coast Call Handling Por
                                                minute rates
                                            </li>
                                            <li>
                                                3rd Party Translation Services
                                                Per minute and Hold Times
                                            </li>
                                            <li>
                                                Lock of integration and customer
                                                data insight No personalization
                                            </li>
                                        </ul>
                                    </ScrollAnimation>
                                </InvestmentBlock>
                            </Col>
                            <Col lg="6">
                                <InvestmentBlock
                                    style={{
                                        backgroundImage: `url(${baseURL}assets/images/wave-ix/investement-bg-img.png)`,
                                    }}
                                    styleInverse>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={true}>
                                        <img
                                            src={`${baseURL}assets/images/wave-ix/investment-icon-2.png`}
                                            alt="-"
                                        />
                                        <h3>Full Multilingual Automation</h3>
                                        <ul>
                                            <li>
                                                AI Bot Automation Call Cost
                                                Reduction of 30-50%
                                            </li>
                                            <li>
                                                AI Language Translation
                                                Reduction of 30-50%
                                            </li>
                                            <li>Save major $$ monthly</li>
                                        </ul>
                                    </ScrollAnimation>
                                </InvestmentBlock>
                            </Col>
                        </Row>
                    </InvestementsFeats>
                </Container>
            </Investment>
            <Support>
                <Container>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <SectionHeader color={colors.gray_dark['100']}>
                            <h2>
                                <u>Centralize Your Global Support.</u>
                            </h2>
                            <p>A global language team, at your service.</p>
                        </SectionHeader>
                    </ScrollAnimation>
                    <Row>
                        {support_items &&
                            support_items.length > 0 &&
                            support_items.map((item, index) => {
                                return (
                                    <Col lg="4" key={index}>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={true}>
                                            <SupportItem
                                                key={index}
                                                lastChild={
                                                    index ===
                                                    support_items.length - 1
                                                }>
                                                <img src={item.img} alt="-" />
                                                <h5>{item.title}</h5>
                                                <p>{item.para}</p>
                                            </SupportItem>
                                        </ScrollAnimation>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </Support>
            <LetsDiscuss
                heading="Ready to deliver support in any language, across any channel?"
                para="Book a demo today."
                btnText="Book a demo"
                bgColor={colors.socialRep['brandsBG']}
            />
        </Layout>
    );
};

const Wrapper = styled.div`
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 20px;
    ${Media('desktop')} {
        padding-top: 1.04vw;
        background-image: url(${baseURL}assets/images/translate-bg.jpg);
    }
    ${Media('tablet')} {
        padding-top: 10px;
        background-image: url(${baseURL}assets/images/mobo-bg.jpg);
    }
`;

const Intro = styled.div`
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    ${Media('xlscreens')} {
        padding-bottom: 5.2vw;
    }
    ${Media('tablet')} {
        padding-bottom: 50px;
    }
`;

const IntroContent = styled.div`
    padding-top: 140px;
    h1 {
        color: ${colors.white['100']};
        font-family: ${gilroyMedium};
        font-size: 64px;
    }

    p {
        color: ${colors.white['100']};
        font-family: ${gilroyMedium};
        margin-bottom: 56px;
        font-size: 20px;
    }

    img {
        margin-bottom: 110px;
        width: 100px;
    }

    a {
        background-color: ${colors.purple['100']};
        border-radius: 5px;
        box-shadow: -7px 6px 0 ${colors.purple['25']};
        img {
            width: 12px;
            margin-bottom: 0;
            margin-left: 10px;
        }
        &:hover {
            color: ${colors.white['100']};
            img {
                animation: topBottom 1s ease-in-out infinite;
            }
            ::before {
                display: none;
            }
        }
    }

    ${Media('xlscreens')} {
        padding-top: 7.2vw;
        h1 {
            font-size: 3.33vw;
        }
        p {
            font-size: 1.04vw;
            margin-bottom: 2.9vw;
        }
        img {
            margin-bottom: 5.7vw;
            width: 5.2vw;
        }
        a {
            img {
                width: 0.62vw;
                margin-left: 0.53vw;
            }
        }
    }
    ${Media('tablet')} {
        padding-top: 50px;
        margin-bottom: 50px;
        h1 {
            font-size: 42px;
        }
        p {
            font-size: 16px;
        }
        img {
            margin-bottom: 50px;
            width: 70px;
        }
    }
    @keyframes topBottom {
        0% {
            transform: translateX(0px) translateY(0px);
        }
        100% {
            transform: translateX(5px) translateY(-5px);
        }
    }
`;

const IntroAnimation = styled.div`
    img {
        width: 100%;
    }
    ${Media('tablet')} {
        padding: 0px 15px;
    }
`;

const AnimationContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    inset: 0;
    padding: 0px 29%;
    z-index: 999;
    img {
        height: 86px;
        width: 86px;
    }
    ${Media('xlscreens')} {
        img {
            width: 4.47vw;
            height: 4.47vw;
        }
    }
    ${Media('tablet')} {
        padding: 0 34%;
        img {
            width: 30px;
            height: 30px;
        }
    }
`;

const IntroCircleText = styled.div`
    height: 100px;
    margin: 10px 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 100px;
    p {
        /* animation: fadeInOut 5s infinite; */
        color: ${colors.white['40']};
        font-size: 20px;
        font-family: ${gilroyMedium};
        margin: 0;
    }
    ${Media('xlscreens')} {
        height: 5.2vw;
        margin: 0.53vw 0;
        p {
            font-size: 1.04vw;
        }
    }
    ${Media('tablet')} {
        margin: 10px 0;
        height: 40px;
        p {
            font-size: 8px;
        }
    }
    ${(props) => {
        if (props.rightAlign) {
            return `
                justify-content:flex-end;
                padding-right: 0px;
                padding-left: 100px;
            `;
        }
    }}/* @keyframes fadeInOut {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    } */
`;

const AnimationCircle = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    img {
        height: 100%;
        width: 100%;
    }
`;

const CircleAnim = styled.div`
    position: absolute;
    bottom: -270px;
    left: 150px;
    img {
        width: 430px;
        height: 430px;
    }
    ${Media('xlscreens')} {
        bottom: -14.06vw;
        left: 7.8vw;
        img {
            width: 22.3vw;
            height: 22.3vw;
        }
    }
    ${Media('tablet')} {
        img {
            width: 250px;
            height: 250px;
        }
    }
`;

const AiInterpreter = styled.section``;

const AIImageAnimation = styled.div`
    position: relative;
    z-index: 9;
    ${Media('tablet')} {
        margin: 150px 0;
    }
`;

const AICircle = styled.div`
    position: absolute;
    top: -61%;
    left: 4%;
    z-index: -9;
    width: 100%;
    img {
        animation: rotateCircleOuter 20s linear infinite;
        height: 94%;
        width: 94%;
    }
    ${(props) => {
        if (props.inner) {
            return `
                display:flex;
                justify-content:center;
                z-index:9;
                top:-1%;
                left:0;
                img{
                    height:42%;
                    width:42%;
                    animation: rotateCircleInner 20s linear infinite;
                }
            `;
        }
    }}
    @keyframes rotateCircleOuter {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(-360deg);
        }
    }
    @keyframes rotateCircleInner {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

const AIImg = styled.div`
    position: relative;
    z-index: 9;
    img {
        width: 100%;
    }
    ${Media('tablet')} {
        margin-top: 20px;
    }
`;

const SectionHeader = styled.div`
    text-align: center;
    margin-bottom: 100px;
    h2 {
        font-size: 45px;
        font-family: ${gilroySemibold};
        color: ${(props) => props.color || colors.white['100']};
    }
    p {
        color: ${(props) => props.color || colors.white['100']};
        font-size: 18px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 5.2vw;
        h2 {
            font-size: 2.34vw;
        }
        p {
            font-size: 0.93vw;
        }
    }
    ${Media('tablet')} {
        margin-bottom: 30px;
        h2 {
            font-size: 32px;
        }
        p {
            font-size: 16px;
        }
    }
`;

const AISectionContent = styled.div`
    ${Media('tablet')} {
        margin-top: 50px;
    }
`;

const AIItem = styled.div`
    padding: 44px 30px;
    width: 100%;
    border: 1px solid ${colors.white['50']};
    border-radius: 10px;
    display: flex;
    align-items: center;
    ${Media('xlscreens')} {
        padding: 2.29vw 1.57vw;
        border-radius: 0.53vw;
    }
    ${Media('tablet')} {
        padding: 32px 15px;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
    }
`;

const IconWrapper = styled.div`
    width: 100px;
    img {
        max-height: 105px;
        max-width: 95px;
    }
    ${Media('xlscreens')} {
        width: 5.2vw;
        img {
            max-height: 5.46vw;
            max-width: 4.94vw;
        }
    }
    ${Media('tablet')} {
        img {
            margin-bottom: 30px;
        }
    }
`;

const ContentWrapper = styled.div`
    margin-left: 30px;
    flex: 1;
    h5 {
        color: ${colors.white['100']};
        font-family: ${gilroySemibold};
        font-size: 20px;
        margin-top: 0;
    }
    p {
        color: ${colors.white['100']};
        font-family: ${gilroyMedium};
        font-size: 18px;
        margin-bottom: 0;
    }
    ${Media('xlscreens')} {
        margin-left: 1.57vw;
        h5 {
            font-size: 1.04vw;
        }
        p {
            font-size: 0.93vw;
        }
    }
    ${Media('tablet')} {
        margin-left: 10px;
        p {
            font-size: 16px;
        }
    }
`;

const Investment = styled.section`
    padding-bottom: 0;
`;

const InvestementsFeats = styled.div``;

const InvestmentBlock = styled.div`
    padding: 30px 100px;
    border-radius: 10px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    h3 {
        font-size: 38px;
        font-family: ${gilroySemibold};
        margin: 32px 0;
    }
    img {
        height: 115px;
        width: 115px;
    }
    ul {
        padding-left: 0;
        li {
            padding: 30px 0;
            border-bottom: 1px solid ${colors.gray['100']};
            font-size: 18px;
            font-family: ${gilroyMedium};
            color: ${colors.gray_dark['80']};
            padding-left: 50px;
            position: relative;
            display: flex;
            align-items: center;
            &:last-child {
                border-bottom: none;
            }
            &::before {
                background-image: url(${baseURL}assets/images/wave-ix/list-style-star.svg);
                content: '';
                position: absolute;
                left: 0;
                filter: grayscale(1);
                opacity: 0.4;
                height: 24px;
                width: 24px;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
    ${(props) => {
        if (props.styleInverse) {
            return `
                h3{
                    color:${colors.white['100']};
                }
                ul{
                    li{
                        color:${colors.white['80']};
                        &:before{
                            filter: grayscale(0);
                            opacity: 1;
                        }
                    }
                }
            `;
        }
    }}
    ${Media('xlscreens')} {
        padding: 1.57vw 5.2vw;
        border-radius: 0.53vw;
        h3 {
            font-size: 1.97vw;
            margin: 1.66vw 0;
        }
        img {
            height: 5.9vw;
            width: 5.9vw;
        }
        ul {
            li {
                padding: 1.57vw 0;
                font-size: 0.93vw;
                padding-left: 2.6vw;
                &::before {
                    height: 1.25vw;
                    width: 1.25vw;
                }
            }
        }
    }
    ${Media('tablet')} {
        padding: 30px;
        img {
            height: 70px;
            width: 70px;
        }
        h3 {
            font-size: 26px;
        }
        ul {
            li {
                font-size: 16px;
                padding-left: 40px;
                &::before {
                    height: 18px;
                    width: 18px;
                }
            }
        }
    }
`;

const Support = styled.section`
    background-color: ${colors.gray_lighter['100']};
`;

const SupportItem = styled.div`
    img {
        width: 100%;
    }
    h5 {
        font-size: 20px;
        font-family: ${gilroySemibold};
    }
    p {
        font-size: 18px;
        font-family: ${gilroyMedium};
    }
    ${Media('xlscreens')} {
        h5 {
            font-size: 1.04vw;
        }
        p {
            font-size: 0.93vw;
        }
    }

    ${Media('tablet')} {
        margin-bottom: 50px;
        padding-bottom: 30px;
        border-bottom: 1px solid ${colors.black['10']};
        p {
            font-size: 16px;
        }
        ${(props) => {
            if (props.lastChild) {
                return `
                    border-bottom: none;
                    margin-bottom: 0;
                    padding-bottom: 0;
                `;
            }
        }}
    }
`;

export default Translate;
