import React, { useEffect, useRef } from 'react';
import ProgressBar from 'progressbar.js';

import { Para } from '../cx-pocket-guide/para';
import { Col, Row } from '@bootstrap-styled/v4';
import { BigHeading } from '../cx-pocket-guide/big-heading';
import colors from 'shared-components/theme/colors';
import { akiraSuperBold } from 'shared-components/theme/fonts';
import styled from 'styled-components';
import { IntroPara } from './intro-para';
import Media from 'shared-components/theme/medias';
import { Source } from './source';

export const Point9 = ({ active }) => {
    const barProgress1 = useRef(null);
    const barProgress2 = useRef(null);

    const BarProgress = (element, barValue) => {
        if (element.current) {
            var bar = new ProgressBar.Line(element.current, {
                strokeWidth: 6,
                easing: 'easeInOut',
                duration: 1400,
                color: colors.pink['100'],
                trailColor: colors.cx_pocket_guide['pink'],
                trailWidth: 6,
                svgStyle: { width: 300, height: '20px' },
                text: {
                    style: {
                        color: colors.pink['100'],
                        position: 'relative',
                        left: '0px',
                        top: '15px',
                        padding: 0,
                        margin: 0,
                        transform: null,
                        fontSize: '28px',
                        fontFamily: akiraSuperBold,
                    },
                    autoStyleContainer: false,
                },
                from: { color: colors.cx_pocket_guide['100'] },
                to: { color: colors.cx_pocket_guide['100'] },
                step: (state, bar) => {
                    bar.setText(Math.round(bar.value() * 100) + ' %');
                },
            });

            bar.animate(barValue);
        }
    };

    useEffect(() => {
        BarProgress(barProgress1, 0.1);
        BarProgress(barProgress2, 0.99);
    }, [active]);

    return (
        <div>
            <Row>
                <Col lg="8">
                    <AnimationBlock>
                        <ProgressWrap>
                            {active && <div ref={barProgress1} />}
                        </ProgressWrap>
                        <IntroPara2>
                            A study at the University of Texas found{' '}
                            <span>Fortune 1000 companies</span> can see an
                            average revenue boost of $2 billion annually if they
                            improve data usability by 10%.
                        </IntroPara2>
                        <Source color={colors.white['100']} style2>
                            Source: datascienceassn
                        </Source>
                    </AnimationBlock>
                </Col>
                <Col lg="4">
                    <AnimationBlock>
                        <ProgressWrap>
                            {active && <div ref={barProgress2} />}
                        </ProgressWrap>
                        <IntroPara2>
                            <span>99% of contact centers</span> don’t think
                            their data intelligence strategy currently meets
                            business needs.
                        </IntroPara2>
                        <Source color={colors.white['100']} style2>
                            Source: CCW
                        </Source>
                    </AnimationBlock>
                </Col>
            </Row>
            <BigHeading color={colors.cx_pocket_guide['pink']} typical active={active}>
                Data != insight. If you know a KPI is dropping, but you can’t
                describe why, then you’ll quickly find that other KPIs are soon
                to follow.
            </BigHeading>
            <Para color={colors.white['100']}>
                Business Intelligence & Analytics teams should be the
                insight-generators of your organization. Think the Pre-Cogs in
                Minority Report, but with much more personality and less water.
                Their function is to help you understand your data, avert
                disaster, predict the CX future with a reliable degree of
                accuracy, and steer your CX program towards safe shores.
            </Para>
        </div>
    );
};

const AnimationBlock = styled.div`
    ${Media('tablet')} {
        margin-bottom: 80px;
    }
`;

const IntroPara2 = styled(IntroPara)`
    margin-top: 30px;
    padding-right: 50px;
    ${Media('xlscreens')} {
        margin-top: 1.57vw;
        padding-right: 2.66vw;
    }
`;

const ProgressWrap = styled.div`
    ${Media('xlscreens')} {
        height: 5vw;
    }
`;
