import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';

import {
    gilroyExtrabold,
    gilroyMedium,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import Layout from 'components/layout';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { BigCircle } from 'components/industries/common';
import { baseURL } from 'shared-components/theme/helpers';
import RetailIntro from 'components/retail/intro-section';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import Video from 'shared-components/video/video';
import ScrollAnimation from 'react-animate-on-scroll';
import SolutionBox from 'components/solution-box/solution-box';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -210,
    },
    {
        name: 'welcome',
        target: 'welcome',
        offset: -100,
    },
    {
        name: 'Accountability',
        target: 'Accountability',
        offset: -100,
    },
    {
        name: 'Belonging',
        target: 'Belonging',
        offset: -100,
    },
    {
        name: 'programmes',
        target: 'programmes',
        offset: -100,
    },
];
const diversityProgrammes = [
    {
        imageUrl: `${baseURL}assets/images/diversity-programme1.jpg`,
        title: 'Women of ibex',
        para: `Initiative where women and men from around the globe celebrate our women through career development, mentorship opportunities, book reviews and interactive networking sessions with the goal of inspiring, interacting and informing.`,
    },
    {
        imageUrl: `${baseURL}assets/images/diversity-programme2.jpg`,
        title: 'Siren Beauty Pageant',
        para: `Siren Beauty Pageant to celebrate our LGBTQIA+ employees and give them an opportunity to shine.`,
    },
    {
        imageUrl: `${baseURL}assets/images/diversity-programme3.jpg`,
        title: 'ibex Global Mentorship Initiative',
        para: `Ibex recently launched our first group of mentors in partnership with GMI. These leaders will be matched with college graduates from underserved areas all over the worlds.`,
    },
    {
        imageUrl: `${baseURL}assets/images/diversity-programme4.jpg`,
        title: 'Rainbow Project Movement',
        para: `Celebrations, festivities, festivals, master classes design to support the LGBTQUIA+ community`,
    },
];
const values = [
    {
        img: `${baseURL}assets/images/diversity-1.jpg`,
        heading: 'All Are Welcome',
        targetID: 'welcome',
        para:
            'Our employees’ race, gender identity, sexual orientation and expression foster a rewarding mix of creativity and partnership that translates into a high-performing, collaboration-driven company.',
    },
    {
        img: `${baseURL}assets/images/diversity-2.jpg`,
        heading: 'Culture, Accountability and Demographics',
        targetID: 'Accountability',
        para:
            'We believe that diversity across race, nationality, physical ability, socio-economic background, and religion creates dynamic opportunities for personal and professional growth.',
    },
    {
        img: `${baseURL}assets/images/diversity-3.jpg`,
        heading: 'A Culture of Belonging',
        targetID: 'Belonging',
        para:
            'Each unique perspective is a piece of a puzzle which, when put together, forms a clear picture of who we are as a company – one that is built on diversity, equity, inclusion, and most importantly, respect.',
    },
];
const CoreValues = () => {
    return (
        <Layout>
            <ScrollNav data={scrollNavData} />
            <Breadcrumbs>
                <li>Industry</li>
                <li>Core Values</li>
            </Breadcrumbs>
            <BigCircle
                imgUrl={`${baseURL}assets/images/multi-dots.svg`}
                imgHeight={330}
                top="14%"
                right="56%"
                zIndex={'1'}
            />
            <BigCircle
                imgUrl={`${baseURL}assets/images/bigger-circle.svg`}
                imgHeight={964}
                top="18%"
                right="10%"
                zIndex={'1'}
            />
            <CoreValuesIntro>
                <RetailIntro
                    rightImg={`${baseURL}assets/images/diversity-intro.png`}
                    tag="BUILDING WHAT’S NEXT"
                    introHeading="Diversity, Equity <br/>and Inclusion <br/>at ibex"
                    noButton={true}
                    right="-4%"
                    top="-4%"
                    zIndex="0"
                    id="home"
                    page="core-values"
                    lineHeight="1.1"
                />
            </CoreValuesIntro>
            <ValueDriveSection>
                <Container>
                    <CoreHead>
                        <IntroHeading>Our Commitment</IntroHeading>
                        <Text>
                            At ibex, we believe our employees’ individuality and{' '}
                            <br />
                            unique abilities form the DNA of our company.
                        </Text>
                    </CoreHead>

                    {/* <Valus */}
                    {values &&
                        values.length > 0 &&
                        values.map((value, index) => {
                            return (
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={true}
                                    delay={100}
                                    key={index}>
                                    <CoreBox
                                        noGutters={true}
                                        id={value.targetID}
                                        reverse={index % 2 == 1 && true}>
                                        <Col lg="6">
                                            <CoreImg>
                                                <Video
                                                    image={value.img}
                                                    videotype="mp4"
                                                />
                                                <span>{'0' + (index + 1)}</span>
                                            </CoreImg>
                                        </Col>
                                        <Col lg="6">
                                            <CoreDesc>
                                                <h3>{value.heading}</h3>
                                                <Text>{value.para}</Text>
                                            </CoreDesc>
                                        </Col>
                                    </CoreBox>
                                </ScrollAnimation>
                            );
                        })}
                </Container>
            </ValueDriveSection>
            <ProgrammesSection id="programmes">
                <Container fluid={true}>
                    <SectionHeading>
                        Every day at ibex we’re building programs that <br />
                        enhance and enrich the lives of our diverse employees.
                    </SectionHeading>
                    <SolutionBoxWrapper>
                        {diversityProgrammes &&
                            diversityProgrammes.length > 0 &&
                            diversityProgrammes.map((solution, index) => {
                                return (
                                    <Col lg="3" md="6" key={index}>
                                        <SolutionBox
                                            title={solution.title}
                                            imageUrl={solution.imageUrl}
                                            noImageOverlay
                                            para={solution.para}
                                            bigTitle
                                        />
                                    </Col>
                                );
                            })}
                    </SolutionBoxWrapper>
                </Container>
            </ProgrammesSection>
        </Layout>
    );
};

const CoreValuesIntro = styled.div`
    ${Media('xlscreens')} {
        padding-bottom: 12vw;
        padding-top: 4.5vw;
    }
`;

const ValueDriveSection = styled.section`
    ${Media('tablet')} {
        padding: 0;
    }
`;
const IntroHeading = styled.h1`
    font-size: 50px;
    font-family: ${gilroySemibold};
    line-height: 0.97;
    letter-spacing: -1px;
    left: -5px;
    position: relative;
    ${Media('xlscreens')} {
        font-size: 3.38vw;
        margin: 1.5vw 0px 2vw;
        letter-spacing: -0.15vw;
    }
    ${Media('mobile')} {
        font-size: 36px;
        br {
            display: none;
        }
    }
`;
const CoreDesc = styled.div`
    background-color: ${colors.white['100']};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    ${Media('xlscreens')} {
        padding: 0 7vw;
        margin: 0 0.41vw;
    }
    ${Media('mobile')} {
        text-align: center;
    }
`;
const CoreBox = styled(Row)`
    justify-content: center;
    h3 {
        font-family: ${gilroyMedium};
        font-size: 30px;
        margin-bottom: 35px;
        margin-top: 0;
        letter-spacing: -0.05px;
    }
    p {
        font-family: ${gilroyMedium};
        font-size: 14px;
    }
    ${(props) => {
        if (props.reverse) {
            return `
            flex-direction: row-reverse;
            span {
                ${Media('xlscreens')} {
                    left: unset !important;
                    right: -6.25vw !important;
                }
                ${Media('tablet')} {
                    right: auto;
                    left: 10px;
                }
            }
            `;
        }
    }}
    ${Media('xlscreens')} {
        margin-top: 3.12vw;
        margin-right: 0.5vw;
        h3 {
            font-size: 2.13vw;
            margin-bottom: 1.82vw;
            letter-spacing: -0.04vw;
        }
        p {
            font-size: 1.04vw;
        }
    }
    ${Media('tablet')} {
        margin: 100px 0 50px 0;
        h3 {
            margin-bottom: 15px;
        }
    }
    ${Media('mobile')} {
        h3 {
            font-size: 24px;
        }
    }
`;
const CoreImg = styled.div`
    position: relative;
    span {
        position: absolute;
        font-size: 200px;
        font-family: ${gilroyExtrabold};
        -webkit-text-stroke: 1px ${colors.gray['border']};
        -webkit-text-fill-color: transparent;
        left: -98px;
        top: 50%;
        transform: translateY(-50%);
        right: auto;
        ${Media('xlscreens')} {
            font-size: 10.2vw;
            left: -5.1vw;
        }
        ${Media('tablet')} {
            font-size: 120px;
            left: 10px;
            right: auto;
            top: 0;
        }
    }
    ${Media('xlscreens')} {
        margin: 0 0.41vw;
        height: 31.25vw;
    }
`;
const CoreHead = styled.div`
    margin-bottom: 70px;
    ${Media('xlscreens')} {
        margin-bottom: 4.5vw;
    }
    ${Media('mobile')} {
        text-align: center;
    }
`;
const Text = styled.div`
    font-size: 18px;
    font-family: ${gilroyMedium};
    line-height: 1.41;
    color: ${(props) => props.white && colors.white['100']};
    ${Media('xlscreens')} {
        font-size: 1.25vw;
    }
    ${Media('tablet')} {
        font-size: 16px;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 14px;
        br {
            display: none;
        }
    }
`;
const ProgrammesSection = styled.div``;
const SolutionBoxWrapper = styled(Row)`
    min-height: 700px;
    margin-bottom: 30px;
    ${Media('xlscreens')} {
        min-height: 36.4vw;
        padding: 0 7vw;
        margin-bottom: 7vw;
    }
`;
const SectionHeading = styled.h2`
    font-size: 42px;
    font-family: ${gilroySemibold};
    line-height: 1.3;
    text-align: center;
    letter-spacing: -1px;
    margin-bottom: 50px;
    ${Media('xlscreens')} {
        font-size: 2.18vw;
        letter-spacing: -0.08vw;
        margin-bottom: 3.64vw;
    }
    ${Media('tablet')} {
        font-size: 30px;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 22px;
    }
`;
export default CoreValues;
