import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Typical from 'react-typical';
import ScrollAnimation from 'react-animate-on-scroll';
import { MdContentCopy } from 'react-icons/md';
import { AiFillPlayCircle, AiOutlineGlobal } from 'react-icons/ai';
import MainHeading from 'shared-components/main-heading/main-heading';
import HomeHelping from 'components/home-helping/home-helping';
import Media from 'shared-components/theme/medias';
import { gilroyLight } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

export const HelpingSection = () => {
    return (
        <HelpingWrapper>
            <Container>
                <Row>
                    <Col md="12">
                        <div>
                            <MainHeading>
                                <MobileHeadingFixer>
                                    We are a <br />
                                    <Typical
                                        steps={[
                                            'people',
                                            2000,
                                            'people',
                                            2000,
                                            'insights',
                                            2000,
                                            'tech',
                                            2000,
                                            'tech',
                                            2000,
                                            'leaders',
                                            2000,
                                            'leaders',
                                            2000,
                                            'insights',
                                            2000,
                                            'insights',
                                            2000,
                                        ]}
                                        loop={Infinity}
                                        wrapper="b"
                                    />
                                    <span>-</span>helping
                                    <span>-</span>
                                    <Typical
                                        steps={[
                                            'people',
                                            2000,
                                            'brands',
                                            2000,
                                            'people',
                                            2000,
                                            'people',
                                            2000,
                                            'leaders',
                                            2000,
                                            'leaders',
                                            2000,
                                            'brands',
                                            2000,
                                            'leaders',
                                            2000,
                                            'brands',
                                            2000,
                                        ]}
                                        loop={Infinity}
                                        wrapper="b"
                                    />
                                    <br />
                                    company.
                                </MobileHeadingFixer>
                            </MainHeading>
                        </div>

                        <HelpingTextSection>
                            <div>
                                <PlusIcon>
                                    <img
                                        src={`${baseURL}assets/images/big-plus.svg`}
                                        alt=""
                                    />
                                </PlusIcon>
                                <p>
                                    We are a perfectly disruptive synthesis of
                                    people, technology, and expertise,
                                    delivering highly customized, flexible, and
                                    scalable CX programs for dynamic &
                                    discerning brands.
                                </p>
                            </div>
                        </HelpingTextSection>
                    </Col>
                </Row>
                <Row>
                    <HelpingWrap>
                        <Row noGutters={true}>
                            <Col md="4">
                                <div>
                                    <HomeHelping
                                        cardVideo={`${baseURL}assets/videos/home_001.mp4`}
                                        cardImage={`${baseURL}assets/images/engagement-bg.jpg`}
                                        cardTitle="OUR PEOPLE"
                                        cardTitleIcon={<AiFillPlayCircle />}
                                        cardMidLevel="one"
                                        cardMidContent={
                                            <div>
                                                Amazing <br /> humans. <br />
                                                &nbsp; Unmatched <br />
                                                engagement.
                                            </div>
                                        }
                                        cardMidContentImage={`${baseURL}assets/images/engagement-text.svg`}
                                        link="join-us"
                                        target="_self"
                                    />
                                </div>
                            </Col>
                            <Col md="4">
                                <div>
                                    <HomeHelping
                                        cardVideo={`${baseURL}assets/videos/home_002.mp4`}
                                        cardImage={`${baseURL}assets/images/interactions-bg.jpg`}
                                        cardTitle="OUR INSIGHTS"
                                        cardTitleIcon={<MdContentCopy />}
                                        cardMidLevel="two"
                                        cardMidContent={
                                            <div>
                                                Rich insights. <br />
                                                Intelligent <br />
                                                interactions.
                                            </div>
                                        }
                                        cardMidContentImage={`${baseURL}assets/images/interaction-text.svg`}
                                        link="business-intelligence"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    />
                                </div>
                            </Col>
                            <Col md="4">
                                <div>
                                    <HomeHelping
                                        cardVideo={`${baseURL}assets/videos/home_003.mp4`}
                                        cardImage={`${baseURL}assets/images/client-services-bg.jpg`}
                                        cardTitle="OUR SITES"
                                        cardTitleIcon={<AiOutlineGlobal />}
                                        cardMidLevel="three"
                                        cardMidContent={
                                            <div>
                                                Leading <br />
                                                Technology. <br />
                                                Continuous <br />
                                                Innovation.
                                            </div>
                                        }
                                        cardMidContentImage={`${baseURL}assets/images/client-services-text.svg`}
                                        link="wave-ix"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </HelpingWrap>
                </Row>
            </Container>
        </HelpingWrapper>
    );
};

const HelpingWrapper = styled.section`
    padding-bottom: 0px;
    ${Media('xlscreens')} {
        padding-top: 10vw;
    }
`;

const MobileHeadingFixer = styled.div`
    ${Media('mobile')} {
        height: 40vw;
    }
    b {
        ::after {
            font-size: 18vwpx;
            font-family: ${gilroyLight};
        }
    }
`;

const PlusIcon = styled.div`
    position: relative;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    img {
        width: 100%;
    }
    ${Media('xlscreens')} {
        margin-top: -10vw;
        width: 20.6vw;
        position: relative;
        left: -1vw;
    }
    & + p {
        padding-left: 6vw;
    }
    ${Media('mobile')} {
        display: none;
        & + p {
            padding-left: 0;
        }
    }
`;

const HelpingWrap = styled.div`
    transform: scale(0.9);
    width: 100%;
    ${Media('xlscreens')} {
        margin-top: 5.2vw;
    }
    ${Media('mobile')} {
        transform: scale(0.95);
    }
`;

const HelpingTextSection = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    right: 0;
    p {
        margin-top: 30px;
        font-family: ${gilroyLight};
    }
    ${Media('xlscreens')} {
        width: 26vw;
    }
    ${Media('mobile')} {
        position: static !important;
        width: 100%;
        img {
            display: none;
        }
    }
`;
