import React from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import { Text, IndustryTag, IndustrySectionHeading } from './index';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import ReactHtmlParser from 'react-html-parser';

export const TransformSection = ({
    tag,
    sectionHeading,
    img,
    para1,
    noBg,
    para2,
    noMarginTop,
}) => {
    return (
        <Wrapper noBg={noBg} noMarginTop={noMarginTop}>
            <Container>
                <div>
                    <IndustryTag alignCentered>{tag}</IndustryTag>
                    <IndustrySectionHeading bigText alignCentered>
                        {ReactHtmlParser(sectionHeading)}
                    </IndustrySectionHeading>
                </div>
                <Row>
                    <DGTransformImg lg="6">
                        <div>
                            <img src={img} alt="-" />
                        </div>
                    </DGTransformImg>
                    <DGTransformText lg="6">
                        <div>
                            <Text>{ReactHtmlParser(para1)}</Text>
                            {para2 && (
                                <MarginWrapper>
                                    <Text>{ReactHtmlParser(para2)}</Text>
                                </MarginWrapper>
                            )}
                        </div>
                    </DGTransformText>
                </Row>
            </Container>
        </Wrapper>
    );
};
const Wrapper = styled.section`
    margin-bottom: 30px;
    background-color: ${(props) =>
        props.noBg ? 'transparent' : colors.gray_lightest['100']};
    ${Media('xlscreens')} {
        margin-bottom: 1.56vw;
        margin-top: ${(props) => (props.noMarginTop ? '0' : '8vw')};
    }
    ${Media('tablet')} {
        padding: 50px 0;
    }
`;
const DGTransformImg = styled(Col)`
    img {
        width: 100%;
        border-radius: 10px;
    }
`;
const DGTransformText = styled(Col)`
    display: flex;
    align-items: center;
    ${Media('xlscreens')} {
        padding: 0px 4.5vw 0 4.16vw !important;
    }
    ${Media('tablet')} {
        margin-top: 40px;
    }
`;
const MarginWrapper = styled.div`
    margin-top: 30px;
    ${Media('xlscreens')} {
        margin-top: 2vw;
    }
`;
