import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import MainPara from 'shared-components/main-para/main-para';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { PageHeading } from './page-heading';
import { gilroySemibold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

export const ExpandedSection = () => {
    return (
        <ExpandedFlexible id="ExpandedFlexible">
            <Container>
                <Row>
                    <Col lg="8">
                        <ScrollAnimation
                            delay={100}
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <PageHeading noMargin={true}>
                                Expanded & Flexible <br /> Talent Pool
                            </PageHeading>
                        </ScrollAnimation>
                    </Col>
                    <Col lg={{ size: 4, offset: 0 }}>
                        <ScrollAnimation
                            delay={200}
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <MainPara>
                                @Home agents allow your brand to benefit from a
                                large geographic area, increasing your access to
                                skilled and qualified talent. Additionally, for
                                seasonal needs, @Home lets you tap into an
                                expansive seasonal talent pool for quick-ramp
                                planning and flexibility.
                            </MainPara>
                        </ScrollAnimation>
                    </Col>
                    <Col lg="12">
                        <ScrollAnimation
                            delay={100}
                            animateIn="fadeInUp"
                            animateOnce={false}>
                            <MapImage>
                                <ul>
                                    <li>In-Center Reach</li>
                                    <li className="active">@home Reach</li>
                                </ul>
                            </MapImage>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </ExpandedFlexible>
    );
};

const ExpandedFlexible = styled.section`
    padding: 100px 0 200px;
    background-color: ${colors.gray_lighter['100']};
`;

const MapImage = styled.div`
    background-image: url(${baseURL}assets/images/map-2.png);
    background-size: 100%;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    height: 62.19vw;
    position: relative;
    ul {
        position: absolute;
        right: 240px;
        top: 70px;

        ${Media('tablet')} {
            right: 30px;
            top: 70px;
        }

        ${Media('mobile')} {
            right: 80px;
            top: 250px;
        }
        li {
            display: block;
            font-size: 26px;
            margin-bottom: 60px;
            color: ${colors.gray_dark['100']};
            font-family: ${gilroySemibold};
            position: relative;
            ${Media('tablet')} {
                font-size: 20px;
                margin-bottom: 15px;
            }
            &:before {
                content: '';
                width: 66px;
                height: 66px;
                border-radius: 50%;
                border: 1px solid #707070;
                display: inline-block;
                position: absolute;
                left: -100px;
                top: -10px;
                ${Media('tablet')} {
                    width: 26px;
                    height: 26px;
                    left: -36px;
                    top: 3px;
                }
            }
            &.active {
                &:before {
                    border: 1px solid ${colors.pink['100']};
                }
            }
        }
    }
`;
