import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Timeline, Tween } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
export const BlockHeading = ({ children }) => {
    return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <Wrapper>
                <Heading>{children}</Heading>
                <Controller>
                    <Scene
                        triggerHook="onLeave"
                        duration="100%"
                        offset={'-800%'}
                        indicators={false}>
                        <Timeline>
                            <Tween
                                from={{
                                    width: '0%',
                                }}
                                to={{
                                    width: '100%',
                                }}>
                                <AnimationLine />
                            </Tween>
                        </Timeline>
                    </Scene>
                </Controller>
            </Wrapper>
        </ScrollAnimation>
    );
};

const Wrapper = styled.div`
    position: relative;
    width: fit-content;
`;

const Heading = styled.h3`
    font-size: 24px;
    font-family: ${gilroyBold};
    display: inline;
    ${Media('xlscreens')} {
        font-size: 1.25vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
    ${Media('mobile')} {
        font-size: 16px;
    }
`;

const AnimationLine = styled.div`
    height: 3px;
    position: absolute;
    background-color: ${colors.pink['100']};
`;
