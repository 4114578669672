import React, { useState, useEffect, Fragment } from 'react';
import Layout from 'components/layout';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loader from 'components/loader/loader';
import ReactHtmlParser from 'react-html-parser';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { RiPlayCircleLine } from 'react-icons/ri';
import CustomLightBox from 'components/blogs/media-popup';
import { gilroyMedium, gilroyBold } from 'shared-components/theme/fonts';
import Moment from 'react-moment';
import WaveZeroCard from 'components/blogs/blog-card';
import { gilroySemibold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';
import { gilroyRegular } from 'shared-components/theme/fonts';

const BlogDetail = () => {
    const [loading, setLoading] = useState(true);
    const [blog, setBlog] = useState({});
    const [relatedBlogs, setRelatedBlogs] = useState();
    const [lightBoxState, setlightBoxState] = useState(false);
    const router = useParams();

    useEffect(() => {
        getBlogDetail();
    }, [lightBoxState]);
    const getBlogDetail = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/blog?token=' +
                    process.env.REACT_APP_API_TOKEN +
                    '&post_name=' +
                    router.topicId
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                setLoading(false);
                setBlog(resData.data[0]);
                getRelatedBlogs(
                    resData.data[0].post_terms[0].term_id,
                    resData.data[0].ID
                );
            }
        } catch (err) {
            console.error(err);
        }
    };
    const getRelatedBlogs = async (termID, blogID) => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/blog?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        exclude: blogID,
                        per_page: 3,
                        category_id: termID,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                setRelatedBlogs(resData.data);
            }
        } catch (err) {
            console.error(err);
        }
    };
    const author =
        blog &&
        blog.metadata &&
        blog.metadata.authors[0] &&
        blog.metadata.authors[0];
    const handleThumbnailClick = () => {
        if (blog && blog.metadata && blog.metadata.post_type_video) {
            setlightBoxState(true);
        }
    };
    const HeaderWrapper = ({ children, fullWidth }) => {
        if (fullWidth) {
            return (
                <CustHeadContainer bgImage={blog.featured_image}>
                    {children}
                </CustHeadContainer>
            );
        } else {
            return <Fragment>{children}</Fragment>;
        }
    };
    const socialSharing = [
        {
            shareLink: `https://www.facebook.com/sharer.php?quote=${blog.post_title}&u=${window.location.href}`,
            iconURL: `${baseURL}assets/images/post-facebook.svg`,
        },
        {
            shareLink: `https://www.twitter.com/share?url=${window.location.href}&text=${blog.post_title}&hashtags=wavezero`,
            iconURL: `${baseURL}assets/images/twitter-logo2.svg`,
        },
        {
            shareLink: `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`,
            iconURL: `${baseURL}assets/images/linkedin-icon.svg`,
        },
        {
            shareLink: `mailto:?subject=${blog.post_title}&body=${window.location.href}`,
            iconURL: `${baseURL}assets/images/post-email.svg`,
        },
    ];
    const bannerHalf =
        blog &&
        blog.metadata &&
        ((blog.metadata.banner_style &&
            blog.metadata.banner_style === 'half') ||
            blog.metadata.post_type_video)
            ? true
            : false;
    return (
        <Layout noSearchHeader={true}>
            {loading ? (
                <Loader />
            ) : (
                <Wrapper>
                    <HeaderWrapper fullWidth={!bannerHalf && true}>
                        <BlogHeader noGutters={true} halfWidth={bannerHalf}>
                            <HeaderLeft
                                lg={bannerHalf ? '6' : '12'}
                                halfWidth={bannerHalf}>
                                <FeatureTag
                                    bgColor={
                                        blog &&
                                        blog.post_terms &&
                                        blog.post_terms[0] &&
                                        blog.post_terms[0].metadata
                                            .category_bg_color
                                    }>
                                    {blog &&
                                        blog.post_terms &&
                                        blog.post_terms[0] &&
                                        ReactHtmlParser(
                                            blog.post_terms[0].name
                                        )}
                                </FeatureTag>
                                <BlogTitle>
                                    {blog && ReactHtmlParser(blog.post_title)}
                                </BlogTitle>
                                <BlogDescription>
                                    {blog &&
                                        blog.metadata &&
                                        ReactHtmlParser(
                                            blog.metadata.sub_title
                                        )}
                                </BlogDescription>
                                {author && (
                                    <TagDetail forHeader={true}>
                                        <img src={author._picture} />
                                        <UserInfo>
                                            <AuthorName>
                                                {author.display_name}
                                            </AuthorName>
                                            <PublishDate>
                                                <Moment format="MMM D, YYYY">
                                                    {blog && blog.post_date}
                                                </Moment>
                                            </PublishDate>
                                        </UserInfo>
                                    </TagDetail>
                                )}
                                <SocialSharing forHeader={true}>
                                    <span>Share this story</span>
                                    <ul>
                                        {socialSharing &&
                                            socialSharing.length > 0 &&
                                            socialSharing.map(
                                                (social, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <a
                                                                href={
                                                                    social.shareLink
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer">
                                                                <img
                                                                    src={
                                                                        social.iconURL
                                                                    }
                                                                    alt="-"
                                                                />
                                                            </a>
                                                        </li>
                                                    );
                                                }
                                            )}
                                    </ul>
                                </SocialSharing>
                            </HeaderLeft>
                            {blog &&
                                blog.metadata &&
                                (blog.metadata.banner_style === 'half' ||
                                    blog.metadata.post_type_video) && (
                                    <HeaderRight
                                        lg="6"
                                        onClick={handleThumbnailClick}>
                                        <img
                                            src={blog && blog.featured_image}
                                        />
                                        {blog &&
                                            blog.metadata &&
                                            blog.metadata.post_type_video && (
                                                <VideoPlayIcon>
                                                    <RiPlayCircleLine
                                                        color={
                                                            colors.white['100']
                                                        }
                                                    />
                                                </VideoPlayIcon>
                                            )}
                                    </HeaderRight>
                                )}
                        </BlogHeader>
                    </HeaderWrapper>
                    <section>
                        <Container fluid>
                            <BodyWrapper>
                                {ReactHtmlParser(blog.post_content)}
                            </BodyWrapper>
                        </Container>
                        <Container>
                            <Row>
                                <SocialSharing>
                                    <span>Share this story</span>
                                    <ul>
                                        {socialSharing &&
                                            socialSharing.length > 0 &&
                                            socialSharing.map(
                                                (social, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <a
                                                                href={
                                                                    social.shareLink
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer">
                                                                <img
                                                                    src={
                                                                        social.iconURL
                                                                    }
                                                                    alt="-"
                                                                />
                                                            </a>
                                                        </li>
                                                    );
                                                }
                                            )}
                                    </ul>
                                </SocialSharing>
                            </Row>
                            {author && (
                                <Row>
                                    <TagDetail>
                                        <img src={author && author._picture} />
                                        <UserInfo>
                                            <Label>Written By:</Label>
                                            <AuthorName>
                                                {author && author.display_name}
                                            </AuthorName>
                                            <AuthorDesignation>
                                                {author &&
                                                    ReactHtmlParser(
                                                        author.user_description
                                                    )}
                                            </AuthorDesignation>
                                        </UserInfo>
                                    </TagDetail>
                                </Row>
                            )}
                        </Container>
                    </section>
                    {relatedBlogs && relatedBlogs.length > 0 && (
                        <RelatedBlogs>
                            <Container>
                                <h2>Also Check</h2>
                                <Row>
                                    {relatedBlogs.map((blog, ind) => (
                                        <CustomCol lg="4" key={ind}>
                                            <WaveZeroCard
                                                cardImg={
                                                    blog && blog.featured_image
                                                }
                                                cardTitle={
                                                    blog && blog.post_title
                                                }
                                                cardDesc={
                                                    blog &&
                                                    blog.metadata &&
                                                    blog.metadata
                                                        .short_description
                                                }
                                                authorImg={
                                                    blog &&
                                                    blog.metadata &&
                                                    blog.metadata.authors &&
                                                    blog.metadata.authors[0] &&
                                                    blog.metadata.authors[0]
                                                        ._picture
                                                }
                                                authorName={
                                                    blog &&
                                                    blog.metadata &&
                                                    blog.metadata.authors &&
                                                    blog.metadata.authors[0] &&
                                                    blog.metadata.authors[0]
                                                        .display_name
                                                }
                                                publishDate={
                                                    blog && blog.post_date
                                                }
                                                videoUrl={
                                                    (blog &&
                                                        blog.metadata &&
                                                        blog.metadata
                                                            .post_type_video &&
                                                        blog.metadata
                                                            .video_url) ||
                                                    false
                                                }
                                                isVideo={
                                                    (blog &&
                                                        blog.metadata &&
                                                        blog.metadata
                                                            .post_type_video) ||
                                                    false
                                                }
                                                blogTerm={
                                                    blog && blog.post_terms[0]
                                                }
                                                postID={blog && blog.ID}
                                                postName={
                                                    blog && blog.post_name
                                                }
                                                key={ind}
                                            />
                                        </CustomCol>
                                    ))}
                                </Row>
                            </Container>
                        </RelatedBlogs>
                    )}
                </Wrapper>
            )}
            {blog && blog.metadata && blog.metadata.video_url && (
                <CustomLightBox
                    state={lightBoxState}
                    videoUrl={blog.metadata.video_url}
                    closeLightBox={() => setlightBoxState(false)}
                />
            )}
        </Layout>
    );
};
const CustHeadContainer = styled(Container)`
    background-image: url(${(props) => props.bgImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    ${Media('tablet')} {
        margin: 0px;
        max-width: 100% !important;
    }
`;
const VideoPlayIcon = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
        font-size: 110px;
        transition: all 0.3s ease;
    }
    ${Media('xlscreens')} {
        svg {
            font-size: 5.72vw;
        }
    }
    ${Media('tablet')} {
        z-index: 999;
        svg {
            font-size: 80px;
        }
    }
`;
const Wrapper = styled.div`
    background-color: ${colors.white['100']};
`;
const BlogHeader = styled(Row)`
    width: 100%;
    position: relative;
    background: ${(props) =>
        props.halfWidth ? colors.blogs['detailHeaderBg'] : 'transparent'};
`;
const HeaderLeft = styled(Col)`
    padding: 100px 80px 90px 100px !important;
    height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    ${Media('xlscreens')} {
        padding: 0vw 4.166vw 0vw 8.85vw !important;
        height: 30vw;
    }
    ${Media('tablet')} {
        padding: 50px 30px !important;
        position: relative;
        z-index: 1;
        ${(props) => {
            if (props.halfWidth) {
                return `
                &:before {
                    position: absolute;
                    width: 100%;
                    background: ${colors.black['80']};
                    left: 0;
                    top: 0;
                    height: 100%;
                    content: '';
                    z-index: -1;
                }
                `;
            }
        }}
    }
`;
const BlogTitle = styled.h1`
    margin-top: 0;
    font-size: 40px;
    margin-bottom: 40px;
    color: ${colors.white['100']};
    line-height: 1.1;
    margin-top: 10px;
    font-family: ${gilroyBold};
    ${Media('xlscreens')} {
        font-size: 2.5vw;
        margin-bottom: 2.08vw;
        margin-top: 0.52vw;
    }
    ${Media('mobile')} {
        font-size: 30px;
    }
`;
const BlogDescription = styled.div`
    color: ${colors.white['100']};
    font-size: 22px;
    line-height: 1.4;
    ${Media('xlscreens')} {
        font-size: 1.05vw;
    }
    ${Media('mobile')} {
        font-size: 20px;
    }
`;
const FeatureTag = styled.span`
    font-size: 11px;
    padding: 5px 20px;
    color: ${colors.white['100']};
    background-color: ${(props) =>
        props.bgColor ? props.bgColor : colors.pink['100']};
    ${Media('xlscreens')} {
        font-size: 0.57vw;
        padding: 0.26vw 1.04vw;
    }
`;
const HeaderRight = styled.div`
    position: absolute;
    height: 100%;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    &:hover {
        svg {
            transform: scale(1.3);
        }
    }
    ${Media('xlscreens')} {
        right: 0;
        width: 50%;
    }
`;
const BodyWrapper = styled.div`
    line-height: 1.5;
    font-size: 20px;
    & > * {
        max-width: 850px;
    }
    p {
        margin: 0 auto;
        margin-bottom: 20px;
        display: block;
        font-weight: 400;
        line-height: 1.6;
    }
    pre {
        font-size: 24px;
        margin: 0 auto;
        margin-bottom: 30px;
        white-space: inherit;
        font-weight: 400;
        width: 100%;
        font-family: ${gilroyMedium};
        line-height: 1.6;
    }
    h1 {
        font-size: 50px;
    }
    h2 {
        font-size: 35px;
        margin: 0 auto;
        margin-bottom: 30px;
        white-space: inherit;
        font-weight: 400;
        width: 100%;
        line-height: 1.3;
    }
    h3 {
        margin: 0 auto;
        margin-bottom: 30px;
        font-size: 26px;
        color: ${colors.black['100']};
    }
    h4,
    h5,
    h6 {
        max-width: 80%;
        margin: 45px auto;
        padding: 70px 20%;
        font-size: 40px !important;
        color: ${colors.white['100']};
        text-align: center;
        line-height: 1.2;
        font-family: ${gilroySemibold};
        br {
            display: none;
        }
    }
    h4 {
        background-color: ${colors.black['100']};
    }
    h5 {
        background-color: ${colors.blogs['h5Bg']};
    }
    h6 {
        background-color: ${colors.blogs['h6Bg']};
    }
    img {
        width: 100%;
        height: auto !important;
        object-fit: cover;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }
    span {
        font-weight: 300;
    }
    a {
        color: ${colors.pink['100']};
        background-color: transparent;
    }
    strong {
        font-weight: bold;
    }
    blockquote {
        margin: 0 0 1rem;
    }
    iframe {
        width: 100%;
    }
    ul {
        list-style: none;
        padding-left: 40px;
        margin-top: 40px;
        font-size: 20px;
        margin: 0 auto;
        margin-bottom: 20px;
        font-weight: 400;
        li {
            margin: 0 0 0.5rem 0;
            position: relative;
            margin-bottom: 95px;
            font-size: 20px;
            &:before {
                content: '. ';
                font-size: 20px;
                font-weight: bold;
                position: absolute;
                --size: 32px;
                left: calc(-1 * var(--size) - 10px);
                line-height: var(--size);
                width: var(--size);
                height: var(--size);
                top: -6px;
                border-radius: 50%;
                text-align: center;
            }
            &:after {
                content: '';
                height: 1px;
                width: 100%;
                background-color: rgba(10, 6, 72, 0.16);
                position: absolute;
                bottom: -45px;
                left: 0;
            }
        }
    }
    ol {
        counter-reset: my-awesome-counter;
        list-style: none;
        padding-left: 40px;
        font-size: 20px;
        margin: 0 auto;
        margin-bottom: 20px;
        font-weight: 400;
        margin-top: 40px;
        li {
            font-weight: 300;
            margin: 0 0 0.5rem 0;
            counter-increment: my-awesome-counter;
            position: relative;
            font-size: 20px;
            &:before {
                content: counter(my-awesome-counter) '. ';
                font-size: 20px;
                font-weight: bold;
                position: absolute;
                --size: 32px;
                left: calc(-1 * var(--size) - 10px);
                line-height: var(--size);
                width: var(--size);
                height: var(--size);
                top: 0;
                border-radius: 50%;
                text-align: center;
            }
        }
    }
    ${Media('xlscreens')} {
        font-size: 1.15vw;
        & > * {
            max-width: 55.75vw;
        }
        p,
        ul li,
        ol li {
            font-size: 1.15vw;
        }
        p {
            margin-bottom: 1.15vw;
            font-family: ${gilroyRegular};
        }
        pre {
            font-size: 1.6vw;
            margin-bottom: 1.56vw;
        }
        h2 {
            font-size: 1.82vw;
            margin-bottom: 1.56vw;
        }
        h3 {
            margin-bottom: 1.6vw;
            font-size: 1.7vw;
        }
        h1 {
            margin: 70px auto;
            font-size: 2.66vw;
        }
        h4,
        h5,
        h6 {
            max-width: 80%;
            margin-top: 2.34vw;
            margin-bottom: 2.34vw;
            padding: 3.64vw 24vw;
            font-size: 2.5vw !important;
        }
        ul {
            padding-left: 3.64vw;
            margin-top: 3.64vw;
            font-size: 1.04vw;
            margin-bottom: 1.04vw;
            li {
                margin-bottom: 4.94vw;
                &:before {
                    --size: 1.66vw;
                    top: -0.31vw;
                }
                &:after {
                    bottom: -2.34vw;
                }
            }
        }
        ol {
            padding-left: 4.08vw;
            font-size: 1.04vw;
            margin-bottom: 1.04vw;
            margin-top: 1.08vw;
            li {
                margin-bottom: 0px;
                font-size: 1.05vw;
                &:before {
                    --size: 1.66vw;
                    font-size: 1.05vw;
                }
            }
        }
    }
    ${Media('tablet')} {
        h1 {
            font-size: 40px;
        }
        h2 {
            font-size: 28px;
        }
        h3 {
            word-break: break-all;
        }
        h4,
        h5,
        h6 {
            padding: 70px 55%;
            font-size: 24px !important;
        }
    }
    ${Media('mobile')} {
        ol {
            padding-left: 26px;
            margin-top: 20px;
            li {
                font-size: 14px;
                &::before {
                    font-size: 14px;
                    --size: 20px;
                }
            }
        }
    }
`;
const SocialSharing = styled.div`
    width: 100%;
    margin: 0 auto;
    margin: 20px auto 50px;
    overflow: hidden;
    max-width: 850px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 30px;
    padding-right: 50px;
    span {
        display: inline-block;
        position: relative;
        font-size: 12px;
    }
    ul {
        padding-left: 20px !important;
        display: inline-block;
        margin: 0px;
        li {
            display: inline-block;
            margin-bottom: 0 !important;
            a {
                display: block;
                padding: 0 7px;
                img {
                    opacity: 1;
                    transition: all 0.3s ease-in;
                    transform: scale(1);
                    height: 30px;
                    width: 30px;
                    object-fit: cover;
                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }
        }
    }
    ${(props) => {
        if (props.forHeader) {
            return `
                padding:0px !important;
                border:none;
                justify-content:flex-start;
                overflow:visible;
                margin:0px;
                max-width:unset !important;
                span{
                    color:${colors.white['100']};
                }
            `;
        }
    }}
    ${Media('xlscreens')} {
        max-width: 55.75vw;
        padding-top: 3.5vw !important;
        padding-right: 3vw;
        span {
            font-size: 0.95vw;
        }
        ul {
            padding-left: 1.04vw !important;
            li {
                a {
                    padding: 0 0.7vw;
                    img {
                        height: 2vw;
                        width: 2vw;
                    }
                }
            }
        }
    }
    ${Media('tablet')} {
        max-width: 100%;
        padding-right: 0;
    }
    ${Media('mobile')} {
        justify-content: flex-start;
        padding-left: 15px;
        ul {
            padding-left: 10px !important;
            li a {
                padding: 0 5px;
            }
        }
    }
`;
const UserInfo = styled.div`
    float: left;
    margin-left: 10px;
    margin-top: 0px;
    ${Media('xlscreens')} {
        margin-left: 0.52vw;
        width: 40%;
    }
    ${Media('mobile')} {
        flex: 1;
    }
`;
const Label = styled.div`
    font-size: 15px;
    color: rgba(94, 99, 107, 0.64);
    ${Media('xlscreens')} {
        font-size: 0.78vw;
    }
`;
const AuthorName = styled.h6`
    padding: 0px;
    font-size: 26px !important;
    text-align: left;
    background: none;
    line-height: 1.2;
    margin: 5px 0;
    color: ${colors.blogs['authorName']};
    ${Media('xlscreens')} {
        font-size: 1.35vw !important;
        margin: 0.2vw 0;
    }
    ${Media('mobile')} {
        font-size: 20px !important;
    }
`;
const AuthorDesignation = styled.div`
    font-size: 15px;
    display: block;
    color: rgba(94, 99, 107, 0.64);
    line-height: 1.5;
    ${Media('xlscreens')} {
        font-size: 0.78vw;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
const PublishDate = styled.div`
    font-size: 12px;
    color: ${colors.white['100']};
    margin-top: 5px;
    ${Media('xlscreens')} {
        font-size: 0.62vw;
        margin-top: 0.26vw;
    }
`;
const TagDetail = styled.div`
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    max-width: 850px;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    img {
        height: 65px;
        width: 65px;
        float: left;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
    }
    ${(props) => {
        if (props.forHeader) {
            return `
                max-width:unset !important;
                margin-top:50px;
                margin-bottom:30px;
               img{
                   height:30px;
                   width:30px;
               };
               ${AuthorName}{
                   color:${colors.white['100']};
                   font-size: 16px !important;
               } 
            `;
        }
    }}
    ${Media('xlscreens')} {
        max-width: 55.75vw;
        margin-bottom: 0;
        img {
            height: 4.2vw;
            width: 4.2vw;
        }
        ${(props) => {
            if (props.forHeader) {
                return `
                    margin-top:2.6vw;
                    img{
                        height:1.56vw;
                        width:1.56vw;
                    };
                    ${AuthorName}{
                        font-size: 0.83vw !important;
                    } 
                `;
            }
        }}
    }
    ${Media('mobile')} {
        padding-left: 15px;
        ${(props) => {
            if (props.forHeader) {
                return `
                    padding-left:0px !important;
                `;
            }
        }}
    }
`;
const RelatedBlogs = styled.div`
    background-color: ${colors.gray_lighter['100']};
    margin-bottom: 50px;
    h2 {
        font-size: 24px;
        margin-bottom: 10px;
        margin-top: 0;
        padding: 10px 0;
    }
    ${Media('xlscreens')} {
        padding: 2vw 7.5vw;
    }
    ${Media('laptops')} {
        padding: 0px;
        padding-top: 30px;
    }
    ${Media('tablet')} {
        padding: 0px 10px;
        margin-top: 82px;
        h2 {
            padding: 30px 0;
        }
    }
`;

const CustomCol = styled(Col)`
    padding: 0px 7px !important;
`;

export default BlogDetail;
