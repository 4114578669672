import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { PageHeading, PagePara } from '../common';
import HomeBigText from 'components/home-big-text/home-big-text';
import { gilroySemibold, gilroyLight } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

export const ComplianceSection = () => {
    return (
        <ComplianceWrapper>
            <Container>
                <Row>
                    <Col lg="12">
                        <div>
                            <ComplianceHeader>
                                <PageHeading color={colors.white['100']}>
                                    The Compliance Team
                                </PageHeading>
                                <PagePara color={colors.white['100']}>
                                    At ibex, our expert GAU team is supported by
                                    an operational team that monitors all
                                    potential compliance violations that might
                                    impact your brand. We have a QA team that
                                    actively monitors program standards, a
                                    Behavioural team that specifically pinpoints
                                    behaviours that pose a risk to the program,
                                    the consumer, and to our client, and a Fraud
                                    team that uses advanced technology to
                                    monitor agent actions.
                                </PagePara>
                            </ComplianceHeader>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <div>
                            <CompBox>
                                <h3>Process Team</h3>
                                <p>
                                    The Process Team is actively monitoring
                                    training, internal procedures, and active
                                    interactions to ensure compliance across
                                    each program.
                                </p>
                            </CompBox>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div>
                            <CompBox>
                                <h3>Behavioural Team</h3>
                                <p>
                                    Our Behavioural team looks closely at agent
                                    routines, actions, and habits to identify
                                    any behaviours that may lead to compliance
                                    infractions.
                                </p>
                            </CompBox>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div>
                            <CompBox>
                                <h3>Fraud Team</h3>
                                <p>
                                    Our Fraud team’s focus is to specifically
                                    leverage both internal and client
                                    technologies and tools to actively monitor
                                    fraud across every agent interaction.
                                </p>
                            </CompBox>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Row noGutters={true}>
                <Col lg="12">
                    <Controller>
                        <Scene
                            triggerHook="onEnter"
                            duration="80%"
                            offset="150%">
                            <Timeline>
                                <Tween
                                    from={{
                                        transform: 'translateX(20%)',
                                    }}
                                    to={{
                                        transform: 'translateX(0%)',
                                    }}>
                                    <ComplianceBanner />
                                </Tween>
                            </Timeline>
                        </Scene>
                    </Controller>
                    <HomeBigText
                        bigTextOne="Global Team"
                        bigTextColor={colors.white['100']}
                        level="cxOne"
                        textOneleft="50%"
                        textOneRight="-20%"
                        textSize="small"
                    />
                </Col>
            </Row>
            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <div>
                            <CompBox>
                                <h3>VP of Compliance & Risk Management</h3>
                                <p>
                                    Our VP of Compliance & Risk Management is
                                    responsible for translating regulatory
                                    changes into operational understanding and
                                    action. Working as the liaison between
                                    clients and legal teams, our VP of
                                    Compliance & Risk Management has a deep
                                    understanding of the regulatory framework
                                    and is dedicated to ensuring its
                                    implementation and enforcement across all
                                    programs.
                                </p>
                            </CompBox>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ size: 4, offset: 2 }}>
                        <div>
                            <CompBox>
                                <h3>Compliance Analysts</h3>
                                <p>
                                    Trained to walk through steps to identify
                                    where risks exist and provide
                                    recommendations on their observations.
                                    Analysts also monitor call compliance,
                                    perform operational risk reviews, and site
                                    reviews.
                                </p>
                            </CompBox>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div>
                            <CompBox>
                                <h3>Compliance Managers</h3>
                                <p>
                                    Responsible for understanding compliance
                                    complexity, leading the teams of compliance
                                    analysts across each program, and
                                    reporting/escalating to the VP of Compliance
                                    & Risk Management.
                                </p>
                            </CompBox>
                        </div>
                    </Col>
                </Row>
            </Container>
        </ComplianceWrapper>
    );
};

const ComplianceWrapper = styled.section`
    padding-top: 150px;
    text-align: center;
    background-color: ${colors.gray_dark['100']};
    ${Media('xlscreens')} {
        padding-top: 12vw;
    }
`;

const ComplianceHeader = styled.div`
    max-width: 86%;
    margin: 0px auto;
    h2 {
        margin: 30px 0px 50px;
    }
    ${Media('xlscreens')} {
        h2 {
            margin: 3vw 0px;
        }
    }
`;

const CompBox = styled.div`
    max-width: 92%;
    margin: 50px auto 20px;
    h3,
    p {
        color: ${colors.white['100']};
    }
    h3 {
        font-family: ${gilroySemibold};
        font-size: 24px;
        letter-spacing: -1px;
    }
    p {
        font-family: ${gilroyLight};
        font-size: 16px;
        line-height: 1.5;
    }
    ${Media('xlscreens')} {
        margin: 5vw auto;
        h3 {
            font-size: 1.7vw;
            letter-spacing: -0.06vw;
            margin-bottom: 2vw;
        }
        p {
            font-size: 1.1vw;
        }
    }
`;

const ComplianceBanner = styled.div`
    background-image: url('${baseURL}assets/images/comp-banner-img.jpg');
    background-size: cover;
    width: 100%;
    transform: translateX(20%);
    margin-left: auto;
    height: 400px;
    transition: all 220ms ease;
    margin-top: 80px;
    ${Media('xlscreens')} {
        height: 37.1vw;
        margin-top: 5vw;
        margin-bottom: 1.5vw;
    }
    ${Media('mobile')} {
        height: 280px;
        margin-top: 60px;
        margin-bottom: 20px;
    }
`;
