import React, { Fragment } from 'react';
import { gilroyLight, gilroyBold } from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';
import MainPara from 'shared-components/main-para/main-para';
import CXFeedbackBox from 'components/cx-feedback-box/cx-feedback-box';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import MainHeading from 'shared-components/main-heading/main-heading';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';

const cxFeedbacks = [
    {
        imgSrc: `${baseURL}assets/images/cx-feedback-1.png`,
        title: (
            <Fragment>
                Omni-channel <br />
                digital surveys
            </Fragment>
        ),
        description:
            'Get immediate feedback for any customer touchpoint along the engagement value chain. Following any sale or support call, immediately send an email or SMS or online survey to capture real-time sentiment regarding the interaction.',
    },
    {
        imgSrc: `${baseURL}assets/images/cx-feedback-2.png`,
        title: (
            <Fragment>
                Social Media Community <br />
                Management
            </Fragment>
        ),
        description:
            'The RefleCX platform is always on and actively listening for mentions of your brand across all major social media networks. As soon as your brand is mentioned, this data is piped into the ClearView dashboard for immediate action and follow-up.',
    },
    {
        imgSrc: `${baseURL}assets/images/cx-feedback-3.png`,
        title: (
            <Fragment>
                Smart, Real-Time <br />
                Issue Resolution
            </Fragment>
        ),
        description:
            'Effectively resolve negative customer sentiment in a heartbeat. Whether it’s from a survey, social media mention, chat conversation or phone call, our technology and people are able to immediately flag negative sentiment, alert the appropriate resolution personnel, and quickly protect your brand.',
    },
];
export const FeedbackSection = () => {
    return (
        <>
            <CXFeedback id="feedback">
                <Container>
                    <div>
                        <Row>
                            <Col md="12">
                                <FeedbackHead>
                                    <MainHeading color={colors.white['100']}>
                                        All the feedback, None of the noise
                                    </MainHeading>
                                    <MainPara color={colors.white['100']}>
                                        For the ultimate in CX, you need the
                                        ultimate toolset.
                                    </MainPara>
                                </FeedbackHead>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        {cxFeedbacks &&
                            cxFeedbacks.map((item, index) => {
                                return (
                                    <Col md="12" key={index}>
                                        <CXFeedbackBox
                                            image={item.imgSrc}
                                            title={item.title}
                                            description={item.description}
                                        />
                                    </Col>
                                );
                            })}
                        <Col md="12">
                            <CXFeedbackBox
                                image={`${baseURL}assets/images/cx-feedback-4.png`}
                                image_mobile={`${baseURL}assets/images/cx-feedback-4-mobile.jpg`}
                                title={
                                    <Fragment>
                                        High Volume Survey
                                        <br /> Processing & Analytics
                                    </Fragment>
                                }
                                description="We send, receive, analyze millions of survey results every year. This means Big Data processing and warehousing, large-scale issue management & resolution, thousands of reports & data visualizations, all to ensure your brand gets a full, accurate, and strategic picture of true customer satisfaction & delight."
                                bgColor="#212326">
                                <Row>
                                    <Col md="5">
                                        <FeedbackPoint>
                                            <h4>Advanced KPI Tracking</h4>
                                            <p>
                                                Actively track and monitor
                                                performance thresholds along
                                                with key goals and objectives,
                                                in real-time.
                                            </p>
                                        </FeedbackPoint>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="5">
                                        <h4>Key Driver Analysis</h4>
                                        <p>
                                            Driver analysis surgically
                                            identifies and analyzes the key
                                            issues that are driving overall
                                            customer satisfaction, loyalty,
                                            delight, and recommendation.
                                        </p>
                                    </Col>
                                    <Col md={{ size: 5, offset: 1 }}>
                                        <h4>Insight Mining</h4>
                                        <p>
                                            Continuous CX involves collecting a
                                            great deal of structured data.
                                            Brands often need help to uncover
                                            the meaning behind the data, and
                                            Insight Mining is a key tool in
                                            helping you see the forest for the
                                            trees.
                                        </p>
                                    </Col>
                                </Row>
                            </CXFeedbackBox>
                        </Col>
                    </Row>
                </Container>
            </CXFeedback>
            <CXJourney id="journey">
                <Container>
                    <Row>
                        <Col md="12">
                            <div>
                                <JourneyBox>
                                    <Row>
                                        <Col md="12">
                                            <JBoxHead>
                                                <h2>
                                                    The Customer Journey,
                                                    optimized.
                                                </h2>
                                                <p>
                                                    We’ve been gathering and
                                                    analyzing customer feedback
                                                    and sentiment for over 30
                                                    years. <br />
                                                    We’ve reached a flow-state
                                                    of sophisticated feedback
                                                    expertise, quick issue
                                                    resolution processes and
                                                    technologies, and delightful
                                                    customer retention
                                                    techniques.
                                                </p>
                                            </JBoxHead>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <JBoxFeature>
                                                <h3>
                                                    Be Excellent at Every
                                                    Touchpoint
                                                </h3>
                                                <p>
                                                    Experience unifies diverse
                                                    data sets into one simple,
                                                    action-oriented dashboard.
                                                    Monitor and act on survey
                                                    feedback, social media
                                                    feedback, call center
                                                    support/sales data, chat
                                                    data, retention data, and
                                                    customer search + click
                                                    data. Our data stream is
                                                    optimized for action,
                                                    insight, and delivering
                                                    delight across the entire
                                                    customer journey.
                                                </p>
                                            </JBoxFeature>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <JBoxFeature>
                                                <h3>Make CX Your Mantra</h3>
                                                <p>
                                                    Customer Experience isn’t a
                                                    temporary fix - it’s a way
                                                    of life. We provide your
                                                    teams with sophisticated
                                                    tools and applications that
                                                    will help them integrate CX
                                                    into their core business
                                                    functions, making CX a part
                                                    of every business decision.
                                                </p>
                                            </JBoxFeature>
                                        </Col>
                                        <Col md="6">
                                            <JBoxFeature>
                                                <h3>
                                                    Every Business Unit,
                                                    Connected
                                                </h3>
                                                <p>
                                                    Marshall every manager,
                                                    executive, and employee
                                                    towards the unique
                                                    fulfillment of customer
                                                    needs and delight. With our
                                                    connected toolset, your
                                                    employees can easily monitor
                                                    KPIs, push real-time
                                                    reports, set action plans,
                                                    and easily share information
                                                    across the entire
                                                    organization.
                                                </p>
                                            </JBoxFeature>
                                        </Col>
                                    </Row>
                                </JourneyBox>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </CXJourney>
        </>
    );
};
const CXFeedback = styled.section`
    background-color: ${colors.gray_dark['100']};
    .main-heading {
        ${Media('xlscreens')} {
            font-size: 3.2vw;
        }
    }
    ${Media('xlscreens')} {
        margin-top: 3vw;
    }
`;

const FeedbackHead = styled.div`
    text-align: center;
    p {
        ${Media('xlscreens')} {
            font-size: 1.15vw;
        }
    }
    ${Media('xlscreens')} {
        margin: 7vw 0 3vw;
    }
`;

const FeedbackPoint = styled.div`
    border-bottom: 1px solid ${colors.white['10']};
    padding: 15px 0px;
    margin-bottom: 15px;
    ${Media('xlscreens')} {
        padding: 1.5vw 0px;
        margin-bottom: 1vw;
    }
`;

const CXJourney = styled.section`
    position: relative;
    z-index: 0;
    padding-bottom: 150px;
    margin-bottom: 150px;
    &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 300px;
        background: ${colors.gray_dark['100']};
        z-index: -1;
    }
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: calc(100% - 300px);
        top: 300px;
        left: 0px;
        background: url('${baseURL}assets/images/cx-journey-bg.jpg') no-repeat
            center;
        background-size: cover;
        z-index: -1;
    }
    ${Media('xlscreens')} {
        padding-bottom: 10vw;
        margin-bottom: 8vw;
        &:before {
            height: 30vw;
        }
        &:after {
            height: calc(100% - 30vw);
            top: 30vw;
        }
    }
`;

const JourneyBox = styled.div`
    background: ${colors.white['100']};
    padding: 100px;
    p {
        font-family: ${gilroyLight};
        font-size: 16px;
        max-width: 90%;
    }
    ${Media('xlscreens')} {
        padding: 8.7vw 13.32vw;
        p {
            font-size: 0.87vw;
        }
    }
    ${Media('tablet')} {
        padding: 50px 30px;
    }
`;

const JBoxHead = styled.div`
    border-bottom: 1px solid ${colors.gray_dark['10']};
    padding-bottom: 50px;
    h2 {
        margin-top: 0px;
        font-size: 45px;
    }
    ${Media('xlscreens')} {
        padding-bottom: 2.6vw;
        h2 {
            font-size: 3.3vw;
        }
    }
    ${Media('mobile')} {
        h2 {
            font-size: 34px;
        }
    }
`;

const JBoxFeature = styled.div`
    margin-top: 70px;
    h3 {
        font-size: 25px;
        margin-top: 0px;
        margin-bottom: 40px;
        letter-spacing: -0.8px;
        font-family: ${gilroyBold};
    }
    ${Media('xlscreens')} {
        margin-top: 3.9vw;
        h3 {
            font-size: 1.32vw;
            margin-bottom: 2.12vw;
        }
    }
`;
