import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyBold, gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const GlossaryMainHeading = ({
    children,
    as,
    color,
    fontWeight,
    noMarginBottom,
}) => {
    return (
        <Wrapper
            as={as}
            color={color}
            fontWeight={fontWeight}
            noMarginBottom={noMarginBottom}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.h2`
    font-size: 48px;
    font-family: ${(props) =>
        props.fontWeight === 'semibold' ? gilroySemibold : gilroyBold};
    margin-top: 0;
    color: ${(props) =>
        props.color ? props.color : colors.socialRep['brandsBG']};
    letter-spacing: -1px;
    margin-bottom: ${(props) => props.noMarginBottom && '0'};
    ${Media('xlscreens')} {
        font-size: 3.125vw;
        letter-spacing: -0.05vw;
        margin-bottom: ${(props) => (props.noMarginBottom ? '0' : '2vw')};
    }
    ${Media('mobile')} {
        font-size: 36px;
    }
`;
