import React, { Fragment } from 'react';
import Layout from 'components/layout';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import colors from 'shared-components/theme/colors';
import PharmaIntro from 'components/case-study/case-study-pharma-intro';
import Media from 'shared-components/theme/medias';
import {
    gilroyBold,
    gilroySemibold,
    gilroyLight,
    gilroyRegular,
} from 'shared-components/theme/fonts';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import CaseBottomSolution from 'components/case-study/case-bottom-solution';
import BigText from 'components/case-study/case-bigtext';
import ReactHtmlParser from 'react-html-parser';
import { baseURL } from 'shared-components/theme/helpers';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -280,
    },
    {
        name: 'leveragingSection',
        target: 'leveragingSection',
        offset: -100,
    },
    {
        name: 'pharmaHelp',
        target: 'pharmaHelp',
        offset: -100,
    },
];

const careList = [
    {
        icon: `${baseURL}assets/images/icon-map-doctor.svg`,
        text:
            'Currently staffed with ~ 50 Doctors, Nurses, Pharmacists, Nutritionists and Psychologists',
    },
    {
        icon: `${baseURL}assets/images/icon-awesome-pills.svg`,
        text:
            'High skill activities such as pharmacovigilance and Contract Research Organization',
    },
    {
        icon: `${baseURL}assets/images/icon-awesome-chalkboard-teacher.svg`,
        text: 'Developed digital marketing and education for clients',
    },
    {
        icon: `${baseURL}assets/images/icon-metro-earth.svg`,
        text: 'Serving global players',
    },
];

const brands = [
    {
        image: `${baseURL}assets/images/lilly-logo-box.svg`,
        support_type: ['Pharmacists'],
        content: [
            {
                title: 'Pharmacovigilance',
                desc:
                    'Patient education, doctor education, order taking&nbsp; <br /> Doctor refers patient to hotline; agent calculates next dosage for diabetes injections, growth <br /> hormones, and other specialty meds',
            },
        ],
    },
    {
        image: `${baseURL}assets/images/novonordisk-logo-box.svg`,
        support_type: ['Customer service', 'specialists', 'Pharmacists'],
        content: [
            {
                title: 'Patient education and pharmacovigilance',
                desc:
                    'In support of current insulin medications: Agent contacts patient and captures details, <br /> information is reviewed by pharmacist and submitted to their Global Compliance group.',
            },
            {
                title: 'For drugs in trial stage',
                desc: 'Adverse Event (similar to above for trial participants)',
            },
        ],
    },
    {
        image: `${baseURL}assets/images/gsk-logo-box.svg`,
        support_type: ['Doctors', 'Customer service', 'Specialists'],
        content: [
            {
                title: 'Physician Education via phone:',
                desc:
                    'Call doctor to schedule appointment <br /> 40-minute training and medication overview presentation ',
                bold_text:
                    'Currently servicing over 3000 physicians, with creation of digital support <br /> materials and Facebook page management',
            },
        ],
    },
    {
        image: `${baseURL}assets/images/pfizer-logo-box.svg`,
        support_type: ['Customer service', 'Specialists'],
        content: [
            {
                title: 'Inbound & Outbound Customer Engagement',
                desc:
                    'Order-taking and health information for specialty cholesterol and cardiology drugs',
            },
        ],
    },
];

const Pharma = () => {
    return (
        <Layout page="health">
            <Breadcrumbs>
                <li>Health</li>
                <li>Success Story</li>
            </Breadcrumbs>
            <ScrollNav data={scrollNavData} />
            <ScrollWrapper id="home">
                <Container>
                    <PharmaIntro
                        introHeading={
                            <Fragment>
                                Delivering Specialized Support to <br />
                                Leading Healthcare & Pharmacare Brands
                            </Fragment>
                        }
                        introSubHeading="Pharmacare CX Expertise"
                        introPara={
                            <Fragment>
                                From pharmacovigilance, to doctor support, to
                                patient support, pharmacare companies are <br />
                                routinely faced with complexity when it comes to
                                delivering support and service experiences&nbsp;
                                <br />
                                to those who depend on them. As the industry
                                continues its transformation to heightened&nbsp;
                                <br />
                                digital consumer experiences, pharmacare
                                providers are increasingly looking to partners
                                who <br />
                                understand the digital consumer space - and who
                                can help them achieve effective digital <br />
                                transformation for accelerated patient care.
                            </Fragment>
                        }
                        introImage={`${baseURL}assets/images/pharma-bg.png`}
                    />
                </Container>
            </ScrollWrapper>

            <BigTextSection>
                <BigText textOne="Pharma" textTwo="Care" />
            </BigTextSection>

            <PharmaCare id="leveragingSection">
                <Container>
                    <Row>
                        <Col lg="12">
                            <SecondaryHeading>
                                How pharmacare brands are leveraging ibex
                                Health&nbsp;
                                <br />
                                to develop global CX capabilities
                            </SecondaryHeading>
                            <BodyText>
                                Today, ibex Health is providing clinical support
                                and patient outreach to local Pharma/Biotech and
                                Providers
                            </BodyText>
                        </Col>
                        <Col lg="12">
                            <PharmaCareList>
                                {careList && careList.length > 0
                                    ? careList.map((item, index) => {
                                          return (
                                              <li key={index}>
                                                  <img src={item.icon} alt="" />
                                                  <span>{item.text}</span>
                                              </li>
                                          );
                                      })
                                    : null}
                            </PharmaCareList>
                        </Col>
                    </Row>
                </Container>
            </PharmaCare>
            <PharmaHelp id="pharmaHelp">
                <Container>
                    <Row>
                        <Col lg="12">
                            <SecondaryHeading>
                                How we help Pharma brands today
                            </SecondaryHeading>
                        </Col>
                        {brands && brands.length > 0
                            ? brands.map((item, index) => {
                                  return (
                                      <Col lg="12" key={index}>
                                          <BrandBox>
                                              <LogoBox>
                                                  <img
                                                      src={item.image}
                                                      alt=""
                                                  />
                                              </LogoBox>
                                              <SupportType>
                                                  <span>Support Type</span>
                                                  {item.support_type.map(
                                                      (sup, ind) => {
                                                          return (
                                                              <p key={ind}>
                                                                  {sup}
                                                              </p>
                                                          );
                                                      }
                                                  )}
                                              </SupportType>
                                              <SupportDesc>
                                                  {item.content.map(
                                                      (con, ind) => {
                                                          return (
                                                              <SupportDescWrapper
                                                                  key={ind}
                                                                  withMarginTop={
                                                                      ind !== 0
                                                                  }>
                                                                  <SupportIcon>
                                                                      <img
                                                                          src={`${baseURL}assets/images/icon-ionic-ios-arrow-down.svg`}
                                                                          alt="-"
                                                                      />
                                                                      <Heading>
                                                                          {ReactHtmlParser(
                                                                              con.title
                                                                          )}
                                                                      </Heading>
                                                                  </SupportIcon>
                                                                  <SupportContent>
                                                                      <p>
                                                                          {ReactHtmlParser(
                                                                              con.desc
                                                                          )}
                                                                          {con.bold_text &&
                                                                              con.bold_text !==
                                                                                  undefined && (
                                                                                  <ParaBoldText>
                                                                                      {ReactHtmlParser(
                                                                                          con.bold_text
                                                                                      )}
                                                                                  </ParaBoldText>
                                                                              )}
                                                                      </p>
                                                                  </SupportContent>
                                                              </SupportDescWrapper>
                                                          );
                                                      }
                                                  )}
                                              </SupportDesc>
                                          </BrandBox>
                                      </Col>
                                  );
                              })
                            : null}
                    </Row>
                </Container>
            </PharmaHelp>

            <CaseBottomSolution
                bgImg={`${baseURL}assets/images/case-bottom-solution-bg.jpg`}
                solutionText={`Looking for a digital transformation partner?\nContact us today for elite outsourced CX solutions.`}
            />
        </Layout>
    );
};

const PharmaHelp = styled.section`
    padding-top: 40px;
    padding-bottom: 0;
    ul {
        padding: 80px 0px;
        border-top: 1px solid ${colors.gray['border']};
        margin: 0px;
        li {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
        }
    }
    ${Media('xlscreens')} {
        padding-top: 2vw;
        ul {
            padding: 4.188vw 0;
        }
    }
    ${Media('tablet')} {
        ul {
            padding: 40px 0px;
        }
    }
`;

const BrandBox = styled.div`
    padding: 80px 0px;
    border-top: 1px solid ${colors.gray['border']};
    margin: 0px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    ${Media('xlscreens')} {
        padding: 4.188vw 0;
    }
    ${Media('tablet')} {
        padding: 40px 0;
    }
`;

const BigTextSection = styled.section`
    position: relative;
    padding-top: 0;
    margin-top: -300px;
    ${Media('xlscreens')} {
        margin-top: -36.4vw;
    }
    ${Media('tablet')} {
        margin-top: -300px;
        padding-bottom: 100px;
    }
    ${Media('mobile')} {
        margin-top: -200px;
        padding-bottom: 50px;
    }
`;
const LogoBox = styled.div`
    width: 15%;
    img {
        width: 100%;
    }
    ${Media('mobile')} {
        width: 100%;
        margin-bottom: 30px;
    }
`;
const SupportType = styled.div`
    width: 25%;
    padding-left: 60px;
    padding-top: 20px;
    span {
        display: block;
        font-size: 28px;
        font-family: ${gilroySemibold};
        color: #2a2d36;
        letter-spacing: -0.3px;
        padding-bottom: 10px;
    }
    p {
        font-size: 20px;
        line-height: 1.46;
        margin-top: 0;
        margin-bottom: 0px;
        font-family: ${gilroyRegular};
    }
    ${Media('xlscreens')} {
        padding-left: 3.14vw;
        padding-top: 1.04vw;
        span {
            font-size: 1.46vw;
            padding-bottom: 0.26vw;
        }
        p {
            font-size: 1.04vw;
        }
    }
    ${Media('tablet')} {
        padding-left: 10px;
        padding-top: 0px;
        span {
            font-size: 20px;
        }
        p {
            font-size: 14px;
        }
    }
    ${Media('mobile')} {
        width: 100%;
        padding-left: 0px;
        margin-bottom: 20px;
        span {
            font-size: 22px;
        }
        p {
            font-size: 16px;
            br {
                display: none;
            }
        }
    }
`;
const SupportDesc = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;
const SupportDescWrapper = styled.div`
    padding-top: 20px;
    ${Media('xlscreens')} {
        padding-top: 1.04vw;
        margin-top: ${(props) => (props.withMarginTop ? '2.5vw' : '0vw')};
    }
    ${Media('tablet')} {
        padding-top: 0px;
        margin-top: ${(props) => (props.withMarginTop ? '20px' : '0px')};
    }
`;
const SupportIcon = styled.div`
    display: flex;
    align-items: center;
    img {
        width: auto;
        height: 20px;
    }
    ${Media('xlscreens')} {
        img {
            height: 1.04vw;
        }
    }
    ${Media('tablet')} {
        img {
            height: 15px;
        }
    }
`;
const ParaBoldText = styled.span`
    font-family: ${gilroyBold};
    display: block;
    margin-top: 30px;
    ${Media('xlscreens')} {
        margin-top: 1.6vw;
    }
`;
const SupportContent = styled.div`
    margin-left: 53px;
    p {
        font-size: 18px;
        font-family: ${gilroyRegular};
        line-height: 1.75;
        margin: 0px;
        letter-spacing:-0.3px span {
            font-family: ${gilroyBold} !important;
        }
    }
    ${Media('xlscreens')} {
        margin-left: 2.8vw;
        p {
            font-size: 0.94vw;
        }
    }
    ${Media('tablet')} {
        margin-left: 29px;
        p {
            font-size: 14px;
        }
    }
    ${Media('mobile')} {
        p {
            font-size: 16px;
        }
    }
`;
const PharmaCareList = styled.ul`
    padding: 0;
    display: block;
    li {
        display: flex;
        align-items: center;
        background-color: rgba(230, 232, 241, 0.5);
        padding: 20px 50px;
        margin-bottom: 20px;
        ${Media('xlscreens')} {
            padding: 1vw 2.6vw;
            margin-bottom: 1vw;
        }
        ${Media('mobile')} {
            padding: 20px 30px;
        }
        img {
            width: 34px;
            height: 34px;
            ${Media('xlscreens')} {
                width: 1.7vw;
                height: 1.7vw;
            }
            ${Media('mobile')} {
                height: 25px;
                width: 25px;
            }
        }
        span {
            font-size: 26px;
            font-family: ${gilroyRegular};
            padding-left: 40px;
            color: #2a2d36;
            ${Media('xlscreens')} {
                font-size: 1.3vw;
                padding-left: 2vw;
            }
            ${Media('tablet')} {
                font-size: 18px;
                text-align: left;
            }
            ${Media('mobile')} {
                font-size: 18px;
            }
        }
    }
`;
const PharmaCare = styled.section`
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
`;

const SecondaryHeading = styled.h2`
    font-size: 52px;
    font-family: ${gilroyBold};
    padding-bottom: 30px;
    text-align: center;
    margin-bottom: 0px;
    letter-spacing: -0.3px;
    line-height: 1.34;
    ${Media('xlscreens')} {
        font-size: 2.7vw;
        padding-bottom: 1.57vw;
    }
    ${Media('tablet')} {
        font-size: 28px;
        margin-top: 0px;
        padding-bottom: 15px;
        text-align: left;
        br {
            display: none;
        }
    }
`;
const BodyText = styled.p`
    font-family: ${gilroyLight};
    font-size: 28px;
    text-align: center;
    letter-spacing: -0.3px;
    ${Media('xlscreens')} {
        font-size: 1.4vw;
    }
    ${Media('tablet')} {
        font-size: 16px;
        text-align: left;
    }
    ${Media('mobile')} {
        text-align: left;
    }
`;
const Heading = styled.div`
    margin: 0px;
    margin-left: 40px;
    font-size: 28px;
    font-family: ${gilroySemibold};
    letter-spacing: -0.3px;
    span {
        font-family: ${gilroyRegular};
    }
    ${Media('xlscreens')} {
        margin-left: 2.09vw;
        font-size: 1.46vw;
    }
    ${Media('tablet')} {
        font-size: 20px;
        margin-left: 20px;
    }
`;
const ScrollWrapper = styled.div``;
export default Pharma;
