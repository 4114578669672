import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { Container } from '@bootstrap-styled/v4';
import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import ScrollAnimation from 'react-animate-on-scroll';
import Button from 'shared-components/button/button';
import { baseURLPage } from 'shared-components/theme/helpers';

const RetailIntro = ({
    rightImg,
    tag,
    tagImage,
    introHeading,
    introPara,
    introBtnText,
    introBtnPara,
    right,
    top,
    bottom,
    left,
    imgWidth,
    zIndex,
    noPaddingBottom,
    noButton,
    page,
    lineHeight,
    id,
    bigTag,
    introImgHideTab,
    btnBgColor,
    introBtnHref,
}) => {
    return (
        <Wrapper noPaddingBottom={noPaddingBottom} id={id}>
            <Container>
                <IntroImage
                    right={right}
                    left={left}
                    top={top}
                    bottom={bottom}
                    width={imgWidth}
                    zIndex={zIndex}
                    introImgHideTab={introImgHideTab}
                    page={page}>
                    <img src={rightImg} alt="-" />
                </IntroImage>
                <div>
                    <IntroSection id="home">
                        {tagImage && <TagImg src={tagImage} alt="_" />}
                        <Tag bigTag={bigTag}>{tag}</Tag>
                        <IntroHeading
                            lineHeight={lineHeight}
                            introImgHideTab={introImgHideTab}>
                            {ReactHtmlParser(introHeading)}
                        </IntroHeading>
                        <Text page={page} introImgHideTab={introImgHideTab}>
                            {ReactHtmlParser(introPara)}
                        </Text>
                        {noButton && noButton !== undefined ? null : (
                            <IntroBtn>
                                {introBtnPara && (
                                    <IntroBtnPara>{introBtnPara}</IntroBtnPara>
                                )}

                                <Button
                                    round={true}
                                    bgColor={btnBgColor}
                                    href={
                                        introBtnHref ||
                                        `${baseURLPage}contact-us`
                                    }>
                                    {introBtnText}
                                </Button>
                            </IntroBtn>
                        )}
                    </IntroSection>
                </div>
            </Container>
        </Wrapper>
    );
};
const Wrapper = styled.section`
    padding-bottom: ${(props) => props.noPaddingBottom && '0'};
    ${Media('xlscreens')} {
        padding-top: 7vw;
    }
    ${Media('tablet')} {
        padding: 50px 0;
    }
`;
const IntroImage = styled.div`
    position: absolute;
    right: ${(props) => props.right};
    top: ${(props) => props.top};
    left: ${(props) => props.left};
    bottom: ${(props) => props.bottom};
    height: 100%;
    width: ${(props) => (props.width ? props.width : '58%')};
    z-index: ${(props) => (props.zIndex ? props.zIndex : '-1')};
    img {
        width: 100%;
        object-fit: contain;
    }
    ${Media('tablet')} {
        top: ${(props) => (props.page === 'core-values' ? '-30%' : '0%')};
        right: -10%;
        width: 50%;
        ${(props) => {
            if (props.introImgHideTab) {
                return `display:none`;
            }
        }}
    }
    ${Media('mobile')} {
        display: none;
    }
`;

const TagImg = styled.img`
    width: 210px;
    ${Media('xlscreens')} {
        width: 10.9vw;
    }
`;

const IntroSection = styled.div`
    margin-bottom: 50px;
    position: relative;
    ${Media('xlscreens')} {
        margin-bottom: 5.2vw;
    }
    ${Media('mobile')} {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
`;
const Tag = styled.div`
    color: ${colors.pink['100']};
    text-transform: uppercase;
    font-size: 14px;
    font-family: ${gilroyBold};
    letter-spacing: 2px;
    margin-top: 0;
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.bigTag ? '1.04vw' : '0.83vw')};
    }
    ${Media('mobile')} {
        margin-bottom: 0;
        text-align: center;
    }
`;
const IntroHeading = styled.h1`
    font-size: 50px;
    font-family: ${gilroySemibold};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : '0.97')};
    letter-spacing: -1px;
    left: -5px;
    position: relative;
    ${Media('xlscreens')} {
        font-size: 4.42vw;
        margin: 1.5vw 0px 2vw;
        letter-spacing: -0.15vw;
    }
    ${Media('tablet')} {
        ${(props) => {
            if (props.introImgHideTab) {
                return `
                    br{
                        display:none;
                    }
                `;
            }
        }}
    }
    ${Media('mobile')} {
        font-size: 36px;
        br {
            display: none;
        }
    }
`;
const Text = styled.div`
    font-size: 16px;
    font-family: ${gilroyMedium};
    line-height: ${(props) =>
        props.page && props.page === 'core-values' ? '1.41' : '1.7'};
    color: ${(props) => props.white && colors.white['100']};
    ${Media('xlscreens')} {
        font-size: ${(props) =>
            props.page && props.page === 'core-values' ? '1.25vw' : '0.93vw'};
    }
    ${Media('tablet')} {
        ${(props) => {
            if (props.introImgHideTab) {
                return `
                    br{
                        display:none;
                    }
                `;
            }
        }}
    }
    ${Media('mobile')} {
        font-size: 14px;
        br {
            display: none;
        }
    }
`;
const IntroBtn = styled.div`
    display: flex;
    align-items: center;
    margin-top: 30px;
    ${Media('xlscreens')} {
        margin-top: ${(props) =>
            props.page && props.page === 'wave-ix' ? '2.5vw' : '5vw'};
    }
`;

const IntroBtnPara = styled.div`
    font-family: ${gilroyMedium};
    margin-right: 40px;
    ${Media('xlscreens')} {
        font-size: 0.93vw;
        margin-right: 2.08vw;
    }
`;
export default RetailIntro;
