import React from "react";

import { Para } from '../cx-pocket-guide/para';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';
import { BlockImg } from "./block-img";
import { BigHeading } from "../cx-pocket-guide/big-heading";

export const Point6 = ({ active }) => {
    return (
        <>
            <BlockImg imgUrl={`${baseURL}assets/images/ebook/imperative-ebook/point6_block_img.png`} />
            <BigHeading color={colors.cx_pocket_guide['pink']} typical active={active}>
                Swiss watches. The Japanese transit system. Well-run CX operations.
            </BigHeading>
            <Para color={colors.white['100']}>
                These are three things that require rigorous operational detail, stamina, and expertise, relying on a multitude of intricately connected systems and teams that must all move synchronously to ensure accurate, predictable functioning.
            </Para>
            <Para color={colors.white['100']}>
                Good operations teams ensure that your CX teams run on time and stay on track – season in, season out.
            </Para>
        </>
    );
}