import React from 'react';
import styled from 'styled-components';
import Layout from 'components/layout';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { gilroyBold, gilroyRegular } from 'shared-components/theme/fonts';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { baseURL } from 'shared-components/theme/helpers';
import { FaCheckCircle } from 'react-icons/fa';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import QuoteBox from 'components/case-study/case-quotebox';
import { LandingFormWrapper } from 'components/webinars/landing/common';
import MarketoForm from 'shared-components/marketo-form/marketo-form';
import ScrollAnimation from 'react-animate-on-scroll';

const FSAwards = () => {
    return (
        <Layout page="landingGatedForm">
            <Breadcrumbs>
                <li>Awards</li>
            </Breadcrumbs>
            <PageContainer>
                <div>
                    <Container>
                        <BrandLogo>
                            <img
                                src={`${baseURL}assets/images/frost-sullivan.svg`}
                                alt="-"
                            />
                        </BrandLogo>
                    </Container>
                    <Container fluid={true}>
                        <FormMainRow>
                            <CustomCol lg="6">
                                <PageDescWrap>
                                    <Heading tag="h1">
                                        Frost & Sullivan Names <br />
                                        ibex As Outsourcing <br />
                                        Company Of The Year
                                    </Heading>
                                    <MainPara>
                                        Showcasing a fully realized vision for
                                        delivering innovative, tech-
                                        <br />
                                        focused CX in the new digital economy,
                                        ibex has achieved <br />
                                        notable success, rapid growth, and is
                                        leading the way towards <br />
                                        BPO 2.0.
                                    </MainPara>
                                    <BulletPoints>
                                        <li>
                                            <FaCheckCircle /> Digital
                                            Transformation Leader
                                        </li>
                                        <li>
                                            <FaCheckCircle /> BPO 2.0 Builder
                                        </li>
                                        <li>
                                            <FaCheckCircle /> Wave X Technology
                                            Innovator
                                        </li>
                                        <li>
                                            <FaCheckCircle /> Best-In-Class
                                            Crisis Responder
                                        </li>
                                        <li>
                                            <FaCheckCircle /> People & Culture
                                            Pioneer
                                        </li>
                                        <li>
                                            <FaCheckCircle /> Trusted CX
                                            Provider
                                        </li>
                                    </BulletPoints>
                                </PageDescWrap>
                            </CustomCol>
                            <Col lg={{ size: 6 }}>
                                <ImageWrapper>
                                    <img
                                        src={`${baseURL}assets/images/Ibex-Award-Logo.png`}
                                        alt="-"
                                    />
                                </ImageWrapper>
                                <LandingFormWrapper
                                    title="Download the Award Report"
                                    titleAlign="left"
                                    noPadding
                                    noPaddingTop>
                                    <MarketoForm Id="1050" />
                                </LandingFormWrapper>
                            </Col>
                        </FormMainRow>
                    </Container>
                </div>
                <div>
                    <QuoteBox
                        quotePara="“ibex is leading the pack in efforts large and small to support clients in improving the <br /> customer experience and achieving significant success in the process.”"
                        quoteName="Sebastian Menutti - Senior Industry Analyst, Frost & Sullivan"
                        contentCenter
                        mediumPaddingTop
                        fullWidth
                        noSidePadding
                    />
                </div>
            </PageContainer>
        </Layout>
    );
};
const BrandLogo = styled.div`
    margin-bottom: 40px;
    img {
        width: auto;
        max-width: 100%;
        height: 18px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 2.08vw;
        img {
            height: 1vw;
        }
    }
    ${Media('tablet')} {
        margin-bottom: 10px;
        margin-top: 20px;
        img {
            height: 18px;
        }
    }
    ${Media('mobile')} {
        img {
            height: 14px;
        }
    }
`;
const ImageWrapper = styled.div`
    width: 230px;
    top: -110px;
    position: absolute;
    ${Media('xlscreens')} {
        width: 11.97vw;
        top: -7vw;
        left: 3.5vw;
        img {
            height: 11vw;
        }
    }
    ${Media('tablet')} {
        position: relative;
        top: 0px;
        left: 0px;
        margin-bottom: 50px;
        width: 100%;
        img {
            width: 100%;
        }
    }
`;
const Heading = styled.div`
    font-size: 62px;
    font-family: ${gilroyBold};
    letter-spacing: -0.26px;
    line-height: 1.0625;
    margin: 0px;
    ${Media('xlscreens')} {
        font-size: 3.22vw;
    }
    ${Media('tablet')} {
        font-size: 38px;
        br {
            display: none;
        }
    }
`;
const CustomCol = styled(Col)`
    ${Media('xlscreens')} {
        padding-left: 0px !important;
    }
`;
const MainPara = styled.div`
    font-size: 22px;
    line-height: 1.58;
    font-family: ${gilroyRegular};
    margin: 50px 0px;
    ${Media('xlscreens')} {
        font-size: 1.1vw;
        margin: 2vw 0;
    }
    ${Media('tablet')} {
        font-size: 18px;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        margin: 30px 0px;
    }
`;
const PageContainer = styled.div`
    width: 100%;
    min-height: calc(100vh - 82px);
    padding: 60px 0;
    ${Media('xlscreens')} {
        min-height: calc(100vh - 5.4vw);
        margin-right: 50px;
        padding: 3vw 0px;
    }
    ${Media('tablet')} {
        padding-top: 0px;
    }
`;

const FormMainRow = styled(Row)`
    justify-content: center;
    ${Media('xlscreens')} {
        padding-left: 13vw;
        padding-right: 8vw;
        h2 {
            margin-top: 3.5vw;
        }
    }
    ${Media('tablet')} {
        padding: 0px 30px;
    }
    ${Media('mobile')} {
        padding: 0;
    }
`;

const PageDescWrap = styled.div`
    position: relative;
    padding: 50px 0px;
    [class^='main-heading'] {
        margin-top: 0px;
        font-size: 24px;
        line-height: 1.4;
    }
    [class^='main-para'] {
        line-height: 1.8;
        font-weight: 400;
    }
    ${Media('xlscreens')} {
        padding: 2.6vw 0;
        padding-top: 0px;
        [class^='main-heading'] {
            font-size: 1.88vw;
            margin-bottom: 1.2vw;
        }
        [class^='main-para'] {
            font-size: 0.7vw;
        }
    }
    ${Media('tablet')} {
        padding: 20px 0px;
    }
    ${Media('mobile')} {
        padding: 30px 0px;
    }
`;
const BulletPoints = styled.ul`
    list-style-type: none;
    padding: 30px;
    margin: 0px 90px 50px 0px;
    background-color: ${colors.salt['100']};
    li {
        position: relative;
        font-size: 22px;
        font-weight: 400;
        padding-left: 50px;
        color: ${colors.gray_dark['100']};
        min-height: 20px;
        margin: 15px 0;
        svg {
            position: absolute;
            font-size: 22px;
            color: ${colors.pink['100']};
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    ${Media('xlscreens')} {
        padding: 1.57vw;
        margin: 0 4.7vw 2vw 0;
        li {
            font-size: 1.15vw;
            margin: 0.8vw 0;
            padding-left: 2.1vw;
            min-height: 1.04vw;
            svg {
                font-size: 1.2vw;
            }
        }
    }
    ${Media('tablet')} {
        margin: 0;
        margin-bottom: 50px;
        padding: 20px 30px;
        li {
            font-size: 18px;
            margin: 20px 0px;
            padding-left: 35px;
            svg {
                font-size: 18px;
            }
        }
    }
    ${Media('mobile')} {
        margin-bottom: 30px;
    }
`;
export default FSAwards;
