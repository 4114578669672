import React from 'react';
import styled from 'styled-components';
import { Col, Container } from '@bootstrap-styled/v4';
import Layout from 'components/layout';
import { gilroySemibold } from 'shared-components/theme/fonts';
import { RowCenteredBoth } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';
import { LandingFormWrapper } from 'components/webinars/landing/common';
import MainPara from 'shared-components/main-para/main-para';
import MarketoForm from 'shared-components/marketo-form/marketo-form';

const IpadGiveaway = () => {
    return (
        <Layout title="ibex Utilities - CS Week 2021 - iPad Giveaway">
            <MainSection>
                <Container>
                    <RowCenteredBoth>
                        <Col sm="12" md="12" lg="12">
                            <PageHeading>
                                Enter your details for a chance to win a new
                                iPad!
                            </PageHeading>
                        </Col>
                        <Col sm="12" md="10" lg="8">
                            <PagePara>
                                <MainPara align="center" noMargin>
                                    The winner will be awarded the iPad during
                                    CS Week. If the winner is no longer at the
                                    show, we&apos;ll get in touch and mail it
                                    directly.
                                </MainPara>
                            </PagePara>
                        </Col>
                        <Col sm="12" md="10" lg="8">
                            <LandingFormWrapper
                                noTitle
                                noPaddingBottom
                                noFormMargin>
                                <MarketoForm Id="1128" />
                            </LandingFormWrapper>
                        </Col>
                    </RowCenteredBoth>
                </Container>
            </MainSection>
        </Layout>
    );
};

const MainSection = styled.section`
    position: relative;
    ${Media('xlscreens')} {
        padding-top: 4vw;
    }
`;

const PageHeading = styled.h1`
    font-size: 48px;
    font-family: ${gilroySemibold};
    text-align: center;
    margin-top: 0px;
    ${Media('xlscreens')} {
        font-size: 2.5vw;
    }
    ${Media('mobile')} {
        font-size: 36px;
    }
`;

const PagePara = styled.div`
    ${Media('tablet')} {
        margin-bottom: 30px;
    }
`;
export default IpadGiveaway;
