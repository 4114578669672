import React from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import CXFeatureBox from 'components/cx-feature-box/cx-feature-box';
import Media from 'shared-components/theme/medias';
import { gilroySemibold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

const featureBoxItems = [
    {
        left: true,
        content: [
            {
                imgSrc: `${baseURL}assets/images/staff_aug/identify-gaps.jpg`,
                animationDelay: 100,
                title: 'Identify the gaps',
                description:
                    'ibex works with the client to identify the main scope of the project and the exact resource requirements.',
            },
            {
                imgSrc: `${baseURL}assets/images/staff_aug/selecting-hire.jpg`,
                animationDelay: 100,
                title: 'Selecting the hire',
                description:
                    'The client will be presented with a candidate matrix to review the resumes of top talent and make the final selection.',
            },
            {
                imgSrc: `${baseURL}assets/images/staff_aug/moving-forward.jpg`,
                animationDelay: 100,
                title: 'Moving Forward',
                description:
                    'We are here to support every move. The clients can scale up or down as needed with flexible options. Let’s move forward.',
            },
        ],
    },
    {
        left: false,
        content: [
            {
                imgSrc: `${baseURL}assets/images/staff_aug/right-talent.jpg`,
                animationDelay: 500,
                title: 'Sourcing the right talent',
                description:
                    'Ibex begins sourcing talent that best fits the client’s needs and is a good cultural and skill fit for a company.',
            },
            {
                imgSrc: `${baseURL}assets/images/staff_aug/ramp-success.jpg`,
                animationDelay: 500,
                title: 'Ramp to success',
                description:
                    'The selected hire will be briefed on the client’s processes and ramped seamlessly onto the designated Team/project.',
            },
        ],
    },
];
const FeatureBox = ({ children, left }) =>
    left ? (
        <Col md="6">
            <FeatureLeft>{children}</FeatureLeft>
        </Col>
    ) : (
        <Col md="6">
            <FeatureRight>{children}</FeatureRight>
        </Col>
    );
export const PlatformSection = () => {
    return (
        <>
            <CXFeatures id="features">
                <Container>
                    <Row>
                        <Col lg="4">
                            <FeatureHead>
                                <div>
                                    Our Process <br />
                                    For&nbsp;
                                    <u>
                                        Client <br />
                                        Success
                                    </u>
                                </div>
                            </FeatureHead>
                        </Col>
                        <Col lg="8">
                            <Row>
                                {featureBoxItems &&
                                    featureBoxItems.map((item, index) => {
                                        return (
                                            <FeatureBox
                                                key={index}
                                                left={item.left}>
                                                {item.content &&
                                                    item.content.map(
                                                        (item2, index2) => {
                                                            return (
                                                                <div
                                                                    key={
                                                                        index2
                                                                    }>
                                                                    <CXFeatureBox
                                                                        image={
                                                                            item2.imgSrc
                                                                        }
                                                                        title={
                                                                            item2.title
                                                                        }
                                                                        description={
                                                                            item2.description
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </FeatureBox>
                                        );
                                    })}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </CXFeatures>
        </>
    );
};
const CXFeatures = styled.section`
    padding-top: 0;
`;

const FeatureHead = styled.h2`
    position: sticky;
    top: 10vw;
    font-size: ${(props) => (props.size === 'small' ? '21px' : '24px')};
    font-family: ${gilroySemibold};
    line-height: 1.3;
    margin: ${(props) =>
        props.size === 'small' ? '30px 0 100px' : '100px 0 0'};
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.size === 'small' ? '2.2vw' : '2.7vw')};
        margin: ${(props) =>
            props.size === 'small' ? '2vw 0 8vw' : '21vw 0 0'};
    }
    ${Media('tablet')} {
        margin: ${(props) =>
            props.size === 'small' ? '30px 0 50px' : '30px 0 0'};
    }
`;

const FeatureLeft = styled.div``;

const FeatureRight = styled.div`
    margin-top: 100px;
    ${Media('xlscreens')} {
        margin-top: 15vw;
    }
    ${Media('tablet')} {
        margin-top: 250px;
    }
    ${Media('mobile')} {
        margin-top: 0px;
    }
`;
