import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import { FCXPageHeading, FCXPagePara } from '../common';
import {
    FlexColumnCentered,
    RowCenteredBoth,
    RowCentered,
} from 'shared-components/theme/grid-helpers';
import { gilroyBold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

const promoteList = [
    {
        image: `${baseURL}assets/images/ebook/fintech-award-innovation.svg`,
        title: 'Innovation',
    },
    {
        image: `${baseURL}assets/images/ebook/fintech-award-collaboration.svg`,
        title: 'Collaboration',
    },
    {
        image: `${baseURL}assets/images/ebook/fintech-award-performance.svg`,
        title: 'Performance',
    },
];

const NomineeList = [
    {
        first_name: 'Alissa',
        last_name: 'Ellis',
        company: 'Discover',
    },
    {
        first_name: 'Ather',
        last_name: 'Williams',
        company: 'Wells Fargo',
    },
    {
        first_name: 'Becky',
        last_name: 'Wilkins',
        company: 'Binance',
    },
    {
        first_name: 'Brian',
        last_name: 'Klaja',
        company: 'Brex',
    },
    {
        first_name: 'Brian',
        last_name: 'Szymanski',
        company: 'Paycron',
    },
    {
        first_name: 'Casper',
        last_name: 'Sorenson',
        company: 'Coinbase',
    },
    {
        first_name: 'Christine',
        last_name: 'Pfeiffer',
        company: 'Next Insurance',
    },
    {
        first_name: 'Cindy',
        last_name: `O'Neill`,
        company: 'BillGO',
    },
    {
        first_name: 'Darin',
        last_name: 'Ballard',
        company: 'Venmo',
    },
    {
        first_name: 'David',
        last_name: 'Sykes',
        company: 'Klarna',
    },
    {
        first_name: 'David',
        last_name: 'Ripley',
        company: 'Kraken',
    },
    {
        first_name: 'Dee',
        last_name: 'Choubey',
        company: 'Money Lion',
    },
    {
        first_name: 'Diana',
        last_name: 'Ellis',
        company: 'Truebill',
    },
    {
        first_name: 'Dontá',
        last_name: 'Wilson',
        company: 'Truist Financial',
    },
    {
        first_name: 'Emily',
        last_name: 'Haahr',
        company: 'Plaid',
    },
    {
        first_name: 'Erin',
        last_name: 'Erhart',
        company: 'Midwest BankCentre',
    },
    {
        first_name: 'Ginger',
        last_name: 'Baker',
        company: 'Plaid',
    },
    {
        first_name: 'Grant',
        last_name: 'SINCLAIR',
        company: 'Adyen',
    },
    {
        first_name: 'Hollie',
        last_name: 'Grantham',
        company: 'Betterment',
    },
    {
        first_name: 'Jacob',
        last_name: 'Flynn',
        company: 'Figure',
    },
    {
        first_name: 'Jean Michel',
        last_name: 'Rodrigues',
        company: 'Aseq',
    },
    {
        first_name: 'Jennifer',
        last_name: 'Erwin',
        company: 'The Zebra',
    },
    {
        first_name: 'John',
        last_name: 'Riordan',
        company: 'Shopify',
    },
    {
        first_name: 'JP',
        last_name: 'Noury',
        company: 'LendingClub',
    },
    {
        first_name: 'JP',
        last_name: 'Jolly',
        company: 'JP Morgan Chase',
    },
    {
        first_name: 'Julie',
        last_name: 'Valencia',
        company: 'Acorns',
    },
    {
        first_name: 'Kate',
        last_name: 'Cheesman',
        company: 'Dailypay',
    },
    {
        first_name: 'Katrina',
        last_name: 'Holt',
        company: 'Affirm',
    },
    {
        first_name: 'Keith',
        last_name: 'DeSchamp',
        company: 'LendingClub',
    },
    {
        first_name: 'Kelli',
        last_name: 'Dempsey',
        company: 'Varo Money',
    },
    {
        first_name: 'Kimberley',
        last_name: 'Meehan',
        company: 'Stash',
    },
    {
        first_name: 'Kimberly',
        last_name: 'Laubscher',
        company: 'Current',
    },
    {
        first_name: 'Kristina',
        last_name: 'Valkanoff',
        company: 'Carta',
    },
    {
        first_name: 'Kwok',
        last_name: 'Choy, MBA',
        company: 'Happy Money',
    },
    {
        first_name: 'Lauren',
        last_name: 'Quinn Volpe',
        company: 'Quadpay',
    },
    {
        first_name: 'Lauren',
        last_name: '"LJ" Mott',
        company: 'Robinhood',
    },
    {
        first_name: 'Liz',
        last_name: 'Pagel',
        company: 'TransUnion',
    },
    {
        first_name: 'Logan',
        last_name: 'Junger',
        company: 'Zillow',
    },
    {
        first_name: 'Mark',
        last_name: 'Webster',
        company: 'PayPal',
    },
    {
        first_name: 'Mariana',
        last_name: 'Gospodinova',
        company: 'Crypto.com',
    },
    {
        first_name: 'Megan',
        last_name: 'Cherry',
        company: 'Bestow',
    },
    {
        first_name: 'Mia',
        last_name: 'Alexander',
        company: 'Dave',
    },
    {
        first_name: 'Michael',
        last_name: 'Setrin',
        company: 'Trumid',
    },
    {
        first_name: 'Michael',
        last_name: 'Boucher',
        company: 'Sure',
    },
    {
        first_name: 'Michelle',
        last_name: 'Cheuvront',
        company: 'Lemonade',
    },
    {
        first_name: 'Monica',
        last_name: 'Lachance',
        company: 'Shopify',
    },
    {
        first_name: 'Nicolas',
        last_name: 'Dinh',
        company: 'Credit Sesame',
    },
    {
        first_name: 'Norm',
        last_name: 'Marcicini',
        company: 'Financial',
    },
    {
        first_name: 'Ori',
        last_name: 'Dek',
        company: 'eToro',
    },
    {
        first_name: 'Radostin',
        last_name: 'Pashov',
        company: 'Crypto.com',
    },
    {
        first_name: 'Rajesh',
        last_name: 'Venkatesh',
        company: 'Nium',
    },
    {
        first_name: 'Rebecca',
        last_name: 'Lowe',
        company: 'FTX Exchange',
    },
    {
        first_name: 'Salman',
        last_name: 'Syed',
        company: 'Marqeta',
    },
    {
        first_name: 'Shawna',
        last_name: 'Breslin',
        company: 'Marqeta',
    },
    {
        first_name: 'Smita',
        last_name: 'Bhatnagar',
        company: 'Affirm',
    },
    {
        first_name: 'Stallone',
        last_name: 'Dias',
        company: 'Coinsquare',
    },
    {
        first_name: 'Tina',
        last_name: 'Fan',
        company: 'Bolt',
    },
    {
        first_name: 'Tom',
        last_name: 'McCormick',
        company: 'Capital One Financial',
    },
    {
        first_name: 'Tony',
        last_name: 'Lawrence',
        company: 'Robinhood',
    },
    {
        first_name: 'Travis',
        last_name: 'Partridge, PMP',
        company: 'Zenefits',
    },
    {
        first_name: 'Tyler',
        last_name: 'Hogge',
        company: 'Divvy',
    },
];

export const PromoteSection = () => {
    const [nominee1, setNominee1] = useState([]);
    const [nominee2, setNominee2] = useState([]);

    useEffect(() => {
        getNominees();
    }, []);

    const getNominees = () => {
        const getData = NomineeList;
        const data1 = getData.slice(0, getData.length / 2);
        const data2 = getData.slice(getData.length / 2, getData.length);
        setNominee1(data1);
        setNominee2(data2);
    };

    return (
        <>
            <PromoteWrap>
                <Container>
                    <RowCenteredBoth>
                        <Col sm="12" lg="11">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <FCXPageHeading size="small" alignCenter>
                                    Promoting CX leaders across the FINTECH
                                    Industry
                                </FCXPageHeading>
                            </ScrollAnimation>
                            <Row>
                                {promoteList &&
                                    promoteList.length > 0 &&
                                    promoteList.map((item, index) => {
                                        return (
                                            <Col sm="12" md="4" key={index}>
                                                <ScrollAnimation
                                                    animateIn="fadeInUp"
                                                    animateOnce={false}
                                                    delay={`${index * 2}00`}>
                                                    <PromoteBox>
                                                        <img
                                                            src={item.image}
                                                            alt="-"
                                                        />
                                                        <h3>{item.title}</h3>
                                                    </PromoteBox>
                                                </ScrollAnimation>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </Col>
                    </RowCenteredBoth>
                </Container>
            </PromoteWrap>

            <NomineeSection id="nominees">
                <NomineeHead>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <FCXPageHeading color={colors.white['100']}>
                            The 2021 <span>Nominee</span> Shortlist
                        </FCXPageHeading>
                    </ScrollAnimation>
                </NomineeHead>
                <NomineeListing>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        {nominee1 &&
                            nominee1.length > 0 &&
                            nominee1.map((item, index) => {
                                return (
                                    <NomineeListitem key={index}>
                                        <RowCentered>
                                            <Col xs="7">
                                                <NomineeName>
                                                    <FCXPagePara
                                                        extraBold
                                                        color={
                                                            colors.white['100']
                                                        }>
                                                        {item.first_name}
                                                    </FCXPagePara>
                                                    <FCXPagePara
                                                        color={
                                                            colors.white['100']
                                                        }>
                                                        {item.last_name}
                                                    </FCXPagePara>
                                                </NomineeName>
                                            </Col>
                                            <Col xs="4">
                                                <NomineeStatus>
                                                    <FCXPagePara
                                                        size="small"
                                                        color={
                                                            colors.white['100']
                                                        }>
                                                        {item.company}
                                                    </FCXPagePara>
                                                </NomineeStatus>
                                            </Col>
                                        </RowCentered>
                                    </NomineeListitem>
                                );
                            })}
                    </ScrollAnimation>
                </NomineeListing>
                <NomineeListing last>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        {nominee2 &&
                            nominee2.length > 0 &&
                            nominee2.map((item, index) => {
                                return (
                                    <NomineeListitem key={index}>
                                        <RowCentered>
                                            <Col xs="7">
                                                <NomineeName>
                                                    <FCXPagePara
                                                        extraBold
                                                        color={
                                                            colors.white['100']
                                                        }>
                                                        {item.first_name}
                                                    </FCXPagePara>
                                                    <FCXPagePara
                                                        color={
                                                            colors.white['100']
                                                        }>
                                                        {item.last_name}
                                                    </FCXPagePara>
                                                </NomineeName>
                                            </Col>
                                            <Col xs="4">
                                                <NomineeStatus>
                                                    <FCXPagePara
                                                        size="small"
                                                        color={
                                                            colors.white['100']
                                                        }>
                                                        {item.company}
                                                    </FCXPagePara>
                                                </NomineeStatus>
                                            </Col>
                                        </RowCentered>
                                    </NomineeListitem>
                                );
                            })}
                    </ScrollAnimation>
                </NomineeListing>
            </NomineeSection>
        </>
    );
};

const PromoteWrap = styled.section`
    position: relative;
    background-color: ${colors.fintech['text']}03;
    padding-bottom: 120px;
    ${Media('xlscreens')} {
        padding-top: 7vw;
        padding-bottom: 10vw;
    }
    ${Media('tablet')} {
        padding-bottom: 180px;
    }
`;

const PromoteBox = styled(FlexColumnCentered)`
    text-align: center;
    margin-top: 50px;
    img {
        max-height: 65px;
    }
    h3 {
        font-size: 28px;
        font-family: ${gilroyBold};
        letter-spacing: 1px;
        margin: 20px 0 0;
        text-transform: uppercase;
    }
    ${Media('xlscreens')} {
        margin-top: 4vw;
        img {
            max-height: 3.38vw;
        }
        h3 {
            font-size: 1.5vw;
            letter-spacing: 0.03vw;
            margin: 1.2vw 0 0;
        }
    }
    ${Media('tablet')} {
        h3 {
            font-size: 24px;
        }
    }
`;

const NomineeSection = styled.section`
    background-color: ${colors.fintech['text']};
    margin-top: 0px;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    &:before {
        content: '';
        position: absolute;
        top: -165px;
        left: 0;
        width: 100%;
        height: 165px;
        background: url('${baseURL}assets/images/ebook/fintech-award-nominee-bg.png')
            no-repeat;
        background-position: center top;
        background-size: auto 100%;
    }
    ${Media('xlscreens')} {
        padding-right: 4.68vw;
        &:before {
            top: -8.6vw;
            height: 8.6vw;
        }
    }
    ${Media('tablet')} {
        flex-direction: column;
        justify-content: flex-start;
        &:before {
            top: -160px;
        }
    }
`;

const NomineeHead = styled.div`
    flex: 0 0 380px;
    max-width: 380px;
    display: inline-flex;
    justify-content: flex-end;
    padding-left: 90px;
    box-sizing: border-box;
    ${Media('xlscreens')} {
        flex: 0 0 19.8vw;
        max-width: 19.8vw;
        padding-left: 4.68vw;
    }
    ${Media('tablet')} {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        justify-content: center;
        padding-bottom: 50px;
    }
    ${Media('mobile')} {
        h2 {
            text-align: center;
        }
    }
`;

const NomineeListitem = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 20px;
    p {
        padding: 0px 10px;
        text-transform: uppercase;
    }
    ${Media('xlscreens')} {
        padding-left: 1vw;
        p {
            white-space: nowrap;
        }
    }
    ${Media('tablet')} {
        flex: 0 0 100%;
        max-width: 100%;
    }
    ${Media('mobile')} {
        p {
            font-size: 13px;
            padding-bottom: 5px;
        }
        .row {
            align-items: flex-start;
        }
    }
`;

const NomineeListing = styled.div`
    flex: 1 0;
    ${NomineeListitem} {
        border-right: ${(props) =>
            props.last ? 'none' : `1px solid ${colors.white['6']}`};
    }
    ${Media('tablet')} {
        flex: 0 0 100%;
        ${NomineeListitem} {
            border-right: none;
        }
    }
`;

const NomineeName = styled.div`
    display: flex;
    align-items: center;
    p {
        width: 50%;
        padding: 0px 10px;
        text-transform: uppercase;
        &:first-child {
            text-align: right;
        }
    }
    ${Media('xlscreens')} {
        p {
            letter-spacing: 0.1vw;
        }
    }
    ${Media('mobile')} {
        align-items: flex-start;
    }
`;

const NomineeStatus = styled.div`
    p {
        padding-left: 0;
    }
    ${Media('xlscreens')} {
        p {
            letter-spacing: 0.05vw;
        }
    }
    ${Media('mobile')} {
        p {
            font-size: 11px;
        }
    }
`;
