import React, { useState } from 'react';
import {
    Text,
    IndustryTag,
    IndustrySectionHeading,
    ContentCenter,
    IndustryPerformanceBox,
} from 'components/industries/common';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import { gilroyMedium, gilroySemibold } from 'shared-components/theme/fonts';
import {
    FlexCentered,
    FlexColumnCentered,
} from 'shared-components/theme/grid-helpers';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import colors from 'shared-components/theme/colors';

export const CaseStudyPerfSec = ({ expList, performanceList }) => {
    const [expListItem, setExplistItem] = useState(0);

    return (
        <>
            {/* <IndustryCaseStudy
                image={`${baseURL}assets/images/health-fitness.jpg`}
                heading="Becoming #1 In CSAT for <br /> a Leading HealthTech <br /> Wearable Brand"
                caseLink={`${baseURLPage}resources/case-studies/healthtech-CSAT-transformation`}
            /> */}

            <ExperienceSection id="experience">
                <Container>
                    <div>
                        <ContentCenter>
                            <IndustryTag>
                                Everything in its right place.
                            </IndustryTag>
                            <IndustrySectionHeading>
                                The On-Demand Patient Experience Ecosystem,
                                <br />
                                Powered by ibex
                            </IndustrySectionHeading>
                            <Text>
                                Ensure repeatable success with ibex’s CX
                                Performance Methodology, <br />
                                built specifically for Healthcare and Wellness.
                            </Text>
                        </ContentCenter>
                    </div>
                </Container>
                <PXIconWrapper>
                    <PXArrow>
                        <AiFillCaretLeft />
                    </PXArrow>
                    <PXIcon>PX</PXIcon>
                    <PXArrow right>
                        <AiFillCaretRight />
                    </PXArrow>
                </PXIconWrapper>
                <ExpListWrapper>
                    {expList &&
                        expList.length > 0 &&
                        expList.map((item, index) => {
                            return (
                                <ExpList
                                    key={index}
                                    active={expListItem === index}
                                    onMouseEnter={() => setExplistItem(index)}>
                                    <ExpTitleCard image={item.image}>
                                        <h3>{item.title}</h3>
                                    </ExpTitleCard>
                                    <ExpDetailCard>
                                        <h3>{item.details.heading}</h3>
                                        <ul>
                                            {item.details.list &&
                                                item.details.list.length > 0 &&
                                                item.details.list.map(
                                                    (listItem, ind) => {
                                                        return (
                                                            <li key={ind}>
                                                                {listItem}
                                                            </li>
                                                        );
                                                    }
                                                )}
                                        </ul>
                                    </ExpDetailCard>
                                </ExpList>
                            );
                        })}
                </ExpListWrapper>
            </ExperienceSection>

            <CXPerformance id="cx-performance">
                <Container>
                    <Row>
                        {performanceList &&
                            performanceList.length > 0 &&
                            performanceList.map((listItem, index) => {
                                return (
                                    <Col lg="4" md="6" key={index}>
                                        <div>
                                            <IndustryPerformanceBox
                                                count={listItem.count}
                                                image={listItem.imgUrl}
                                                title={listItem.title}
                                                list={listItem.list}
                                            />
                                        </div>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </CXPerformance>
        </>
    );
};
const CXPerformance = styled.section`
    padding-bottom: 0px;
    ${Media('xlscreens')} {
        padding-top: 7vw;
        padding-bottom: 2vw;
    }
`;

const ExperienceSection = styled.section`
    padding-bottom: 0;
    ${Media('xlscreens')} {
        padding-top: 7vw;
    }
`;

const PXIconWrapper = styled.div`
    margin-right: 15px;
    margin-left: 15px;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: ${colors.gray_dark['25']};
        top: 50%;
        transform: translateY(-50%);
    }
    ${Media('xlscreens')} {
        margin-right: 5vw;
        margin-left: 5vw;
        margin-bottom: 2vw;
    }
`;

const PXIcon = styled(FlexCentered)`
    background-color: ${colors.gray_darker['80']};
    width: 100px;
    height: 100px;
    font-family: ${gilroySemibold};
    font-size: 44px;
    color: ${colors.white['100']};
    position: relative;
    z-index: 10;
    border-radius: 100px;
    ${Media('xlscreens')} {
        width: 5.36vw;
        height: 5.36vw;
    }
    ${Media('mobile')} {
        transform: scale(0.7);
    }
`;

const PXArrow = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-flex;
    font-size: 20px;
    ${(props) => {
        if (props.right) {
            return `right: -10px;`;
        } else {
            return `left: -10px`;
        }
    }};
    ${Media('xlscreens')} {
        font-size: 1.05vw;
    }
`;

const ExpListWrapper = styled.div`
    display: flex;
    margin: 0 -5px;
    ${Media('tablet')} {
        flex-direction: column;
        margin: 0px;
    }
`;

const ExpTitleCard = styled(FlexCentered)`
    height: 450px;
    background-image: url('${(props) => props.image}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0 30px;
    transition: all 330ms ease;
    width: 100%;
    box-sizing: border-box;
    h3 {
        font-size: 30px;
        font-family: ${gilroySemibold};
        color: ${colors.white['100']};
        margin: 0px;
        letter-spacing: -0.5px;
        text-align: center;
        transition: all 330ms ease;
    }
    ${Media('xlscreens')} {
        height: 23.75vw;
        padding: 0 3vw;
        box-sizing: border-box;
        h3 {
            font-size: 1.82vw;
            letter-spacing: -0.03vw;
        }
    }
    ${Media('tablet')} {
        height: 350px;
    }
`;

const ExpDetailCard = styled(FlexColumnCentered)`
    background-color: ${colors.gray_lightest['100']};
    flex: 1 0;
    transition: all 330ms ease;
    text-align: center;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        border-left: 21px solid ${colors.gray_dark['100']};
        border-top: 21px solid transparent;
        border-bottom: 21px solid transparent;
    }
    h3 {
        font-size: 22px;
        font-family: ${gilroySemibold};
        color: ${colors.gray_dark['100']};
        margin: 0px;
        margin-bottom: 25px;
        letter-spacing: -0.5px;
    }
    ul {
        padding: 0px;
        margin: 0px;
        li {
            font-size: 16px;
            font-family: ${gilroyMedium};
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0px !important;
            }
        }
    }
    ${Media('xlscreens')} {
        &:before {
            border-left-width: 1.1vw;
            border-top-width: 1.1vw;
            border-bottom-width: 1.1vw;
        }
        h3 {
            font-size: 1.3vw;
            letter-spacing: -0.03vw;
            margin-bottom: 2vw;
        }
        ul {
            li {
                font-size: 1.05vw;
                margin-bottom: 0.85vw;
            }
        }
    }
    ${Media('tablet')} {
        padding: 60px 0 50px;
        &:before {
            top: -12px;
            left: 50%;
            transform: translateX(-50%) rotate(90deg);
        }
    }
`;

const ExpList = styled.div`
    display: flex;
    margin: 0 5px;
    transition: all 330ms ease;
    flex: 0 0 100%;
    max-width: 100%;
    ${Media('xlscreens')} {
        flex: ${(props) => (props.active ? '0 0 50%' : '0 0 16%')};
        max-width: ${(props) => (props.active ? '50%' : '16%')};
        ${ExpTitleCard} {
            max-width: ${(props) => (props.active ? '27.7vw' : '16vw')};
        }
        ${ExpDetailCard} {
            max-width: ${(props) => (props.active ? '26vw' : '0')};
            opacity: ${(props) => (props.active ? '1' : '0')};
            visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
        }
    }
    ${Media('tablet')} {
        flex-direction: column;
        ${ExpTitleCard}, ${ExpDetailCard} {
            max-width: 100%;
        }
    }
`;
