import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import { baseURL } from 'shared-components/theme/helpers';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import DemoRequestForm from 'components/social-rep-man/demo-request-form';
import SRMIntroSection from 'components/social-rep-man/intro-section';
import {
    ContainerWideSRM,
    SRMCallActionSection,
    SRMonitoringSection,
    SRMSectionHeading,
} from 'components/social-rep-man/common';
import MainPara from 'shared-components/main-para/main-para';
import Media from 'shared-components/theme/medias';
import VerticalCards from 'components/social-rep-man/vertical-cards';
import ScrollAnimation from 'react-animate-on-scroll';
import { FlexColumnCentered } from 'shared-components/theme/grid-helpers';
import { gilroySemibold } from 'shared-components/theme/fonts';
import Loader from 'components/loader/loader';
import LazyLoad from 'react-lazyload';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -210,
    },
    {
        name: 'monitor',
        target: 'monitor',
        offset: 0,
    },
    {
        name: 'manage',
        target: 'manage',
        offset: 0,
    },
];

const monitorList = [
    {
        icon: `${baseURL}assets/images/tech/round-monitor-icon-1.svg`,
        title: 'Optimize',
        para: 'Improve contact center efficiency',
    },
    {
        icon: `${baseURL}assets/images/tech/vfms-monitor-icon-2.svg`,
        title: 'Collaborate',
        para: 'Connect fromanywhere',
    },
    {
        icon: `${baseURL}assets/images/tech/round-monitor-icon-4.svg`,
        title: 'Accelerate',
        para: 'Reduce average handle time (AHT)',
    },
    {
        icon: `${baseURL}assets/images/tech/vfms-monitor-icon-4.svg`,
        title: 'Win',
        para: 'Increase customer satisfaction',
    },
];

const manageContent = [
    {
        ImageRight: false,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/vfms-monitor-tag-1.svg`,
        VerticalCardHeading: `Real-time, on-time remote assistance`,
        VerticalCardParagraph: `Agent SMEs can assist multiple agents simultaneously, in real time, from anywhere – whether remotely or on-site.`,
        VerticalCardImage: `${baseURL}assets/images/tech/vfms-monitor-img-1.svg`,
    },
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/vfms-monitor-tag-2.svg`,
        VerticalCardHeading: `Seamless team collaboration`,
        VerticalCardParagraph: `Agents can easily share their screen with Agent SMEs, so it’s easy to work together on a quick resolution that improves AHT.`,
        VerticalCardImage: `${baseURL}assets/images/tech/vfms-monitor-img-2.svg`,
    },
    {
        ImageRight: false,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/vfms-monitor-tag-3.svg`,
        VerticalCardHeading: `Custom escalation types`,
        VerticalCardParagraph: `Preset, customizable escalations let agents select the reason for the request, allowing you to identify why the agent needs help, assign the best Agent SME for the task, understand common drivers, and identify training gaps.`,
        VerticalCardImage: `${baseURL}assets/images/tech/vfms-monitor-img-3.svg`,
    },
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/vfms-monitor-tag-4.svg`,
        VerticalCardHeading: `Secure, role-based access`,
        VerticalCardParagraph: `vFMS configuration ensures users have access to only the features and functionality they need to complete tasks specific to their role.`,
        VerticalCardImage: `${baseURL}assets/images/tech/vfms-monitor-img-4.svg`,
    },
    {
        ImageRight: false,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/vfms-monitor-tag-5.svg`,
        VerticalCardHeading: `Live and historical performance tracking`,
        VerticalCardParagraph: `The KPI dashboard allows you to view up-to-the-minute and historical performance data, so you can track when and why agents request assistance.`,
        VerticalCardImage: `${baseURL}assets/images/tech/vfms-monitor-img-5.svg`,
    },
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/tech/vfms-monitor-tag-6.svg`,
        VerticalCardHeading: `Enhanced logging and reporting`,
        VerticalCardParagraph: `Logs record all interactions for compliance and coaching purposes with reports that show both summary and detailed information about agents and response staff.`,
        VerticalCardImage: `${baseURL}assets/images/tech/vfms-monitor-img-6.svg`,
    },
];

const expertData = [
    {
        icon: `${baseURL}assets/images/tech/expert-icon-1.svg`,
        text: 'Agent requests assistance',
    },
    {
        icon: `${baseURL}assets/images/tech/expert-icon-2.svg`,
        text: 'Agent SME accepts request',
    },
    {
        icon: `${baseURL}assets/images/tech/expert-icon-3.svg`,
        text: 'Agent and Agent SME start remote chat to resolve issue',
    },
    {
        icon: `${baseURL}assets/images/tech/expert-icon-4.svg`,
        text: 'Agent SME leaves chat once resolved',
    },
    {
        icon: `${baseURL}assets/images/tech/expert-icon-5.svg`,
        text: 'Agent completes survey on Agent SME performance',
    },
];

const TechIbexRound = () => {
    const [demoRequestForm, setDemoRequestForm] = useState(false);

    return (
        <Layout>
            <Breadcrumbs>
                <li>Wave X</li>
                <li>Technology</li>
            </Breadcrumbs>
            <DemoRequestForm
                handleDemoForm={(val) => setDemoRequestForm(val)}
                formState={demoRequestForm}
                marketFormId="1086"
            />

            <SRMIntroSection
                preheading="Agent Workforce Management"
                heading="Virtual Floor Management System"
                para="Boost agent productivity and CX outcomes with ibex’s Virtual Floor Management system."
                mainImg={`${baseURL}assets/images/tech/vfms-intro.svg`}
                textureImg={`${baseURL}assets/images/tech/vfms-texture.svg`}
                textureImgPosition="back"
                onBtnClick={() => setDemoRequestForm(true)}
            />
            
                <ExpertiseSection>
                    <Container>
                        <Row>
                            <Col sm="12">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={true}>
                                    <SRMSectionHeading>
                                        Marshall The Expertise Of Your Contact
                                        Center for Any Customer Interaction
                                    </SRMSectionHeading>
                                    <MainPara align="center">
                                        For any agent who needs assistance
                                        supporting a customer, vFMS’s seamless
                                        interface enables agents to quickly
                                        request help from supervisory agents
                                        known as Agent SMEs (Subject Matter
                                        Experts), who communicate with them
                                        through the vFMS interface to resolve
                                        the issue.
                                    </MainPara>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                        <Row>
                            {expertData &&
                                expertData.length > 0 &&
                                expertData.map((item, index) => {
                                    return (
                                        <ExpCol sm="12" key={index}>
                                            <ExpertBox>
                                                <img src={item.icon} alt="-" />
                                                <p>{item.text}</p>
                                                {expertData.length !==
                                                    index + 1 && (
                                                    <ExpertArrow>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="15.995"
                                                            height="15.59"
                                                            viewBox="0 0 15.995 15.59">
                                                            <path
                                                                id="Icon_awesome-arrow-down"
                                                                data-name="Icon awesome-arrow-down"
                                                                d="M14.943,9.051l.793.793a.853.853,0,0,1,0,1.21L8.8,17.994a.853.853,0,0,1-1.21,0l-6.94-6.94a.853.853,0,0,1,0-1.21l.793-.793a.858.858,0,0,1,1.224.014l4.1,4.3V3.107a.855.855,0,0,1,.857-.857H8.763a.855.855,0,0,1,.857.857v10.26l4.1-4.3A.852.852,0,0,1,14.943,9.051Z"
                                                                transform="translate(-2.25 15.987) rotate(-90)"
                                                                fill="#fa0060"
                                                            />
                                                        </svg>
                                                    </ExpertArrow>
                                                )}
                                            </ExpertBox>
                                        </ExpCol>
                                    );
                                })}
                        </Row>
                    </Container>
                </ExpertiseSection>
            
                <SRMonitoringSection
                    id="monitor"
                    heading="Faster Issue Resolution Drives Better CX Outcomes"
                    paragraph="By equipping your remote and in-center agents with enhanced support and communication tools, you create more productive agents and happier customers."
                    listData={monitorList}
                />
            
                <ManageSection id="manage">
                    <ContainerWideSRM>
                        <Row>
                            <Col sm="12">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={true}>
                                    <SRMSectionHeading>
                                        A Real-Time Agent Enablement Toolset{' '}
                                        Designed for Customer Success
                                    </SRMSectionHeading>
                                </ScrollAnimation>
                            </Col>
                        </Row>

                        {manageContent &&
                            manageContent.length > 0 &&
                            manageContent.map((item, index) => {
                                return (
                                    <VerticalCards
                                        key={index}
                                        ImageRight={item.ImageRight}
                                        ImageRightColEqual={item.ImageRight}
                                        padTopSmall={item.padTopSmall}
                                        CardTagImg={item.CardTagImg}
                                        VerticalCardHeading={
                                            item.VerticalCardHeading
                                        }
                                        VerticalCardParagraph={
                                            item.VerticalCardParagraph
                                        }
                                        VerticalCardImage={
                                            item.VerticalCardImage
                                        }
                                        sideExtraSmall
                                    />
                                );
                            })}
                    </ContainerWideSRM>
                </ManageSection>
            
                <SRMCallActionSection
                    heading="Boost agent productivity and elevate contact center performance with vFMS."
                    para="Upgrade your Contact Center today."
                    btnText="Book a Demo"
                    onBtnClick={() => setDemoRequestForm(true)}
                />
                <ScrollNav data={scrollNavData} />
        </Layout>
    );
};

const ManageSection = styled.section``;

const ExpertiseSection = styled.section``;

const ExpCol = styled(Col)`
    &&& {
        ${Media('xlscreens')} {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
`;

const ExpertBox = styled(FlexColumnCentered)`
    margin: 50px 0 20px;
    position: relative;
    text-align: center;
    img {
        height: 100%;
        width: auto;
    }
    p {
        font-size: 16px;
        font-family: ${gilroySemibold};
        margin: 20px 0 0px;
        padding: 0 30px;
    }
    ${Media('xlscreens')} {
        margin: 4vw 0 0;
        img {
            max-height: 6.3vw;
        }
        p {
            font-size: 0.95vw;
            margin: 1.05vw 0 0px;
            padding: 0 2vw;
        }
    }
`;

const ExpertArrow = styled.div`
    position: absolute;
    right: 0px;
    top: 60px;
    ${Media('xlscreens')} {
        top: 2.8vw;
        right: -1.2vw;
        svg {
            max-width: 0.8125vw;
        }
    }
    ${Media('tablet')} {
        top: auto;
        bottom: -40px;
        right: 50%;
        transform: translateX(50%) rotate(90deg);
    }
`;

export default TechIbexRound;
