import React, { useEffect, useRef } from 'react';
import Layout from 'components/layout';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import { gilroyMedium } from 'shared-components/theme/fonts';
import { sourceSansPro } from 'shared-components/theme/fonts';
import { EbookBigText } from 'components/ebook/common/big-text';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import {
    DEHeader,
    DEFooter,
    DEHeading,
    DEHeading2,
    DEMediumHeading,
    DEPara,
    DEFullHeight,
} from 'components/ebook/digital-experience';
import { EbookFloatingImage } from 'components/ebook/common/floating-image';
import ReactHtmlParser from 'react-html-parser';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-scroll';
import ProgressBar from 'progressbar.js';
import useOnScreen from './viewport-observer';

const FeatureList1 = [
    {
        icon: `${baseURL}assets/images/ebook/de-feature-1.svg`,
        title: 'Digital transformation',
        para: `Community banks are falling behind big banks in areas such as AI, cloud, <br/>fintech, digital transformation and marketing automation.<a>3</a> Digital <br/>service delivery is one of the key areas where community banks need to <br/>compete, but few have the budget or in-house expertise to plan and <br/>implement a digital transformation.`,
    },
    {
        icon: `${baseURL}assets/images/ebook/de-feature-2.svg`,
        title: 'Changing customer behaviors',
        para:
            'Over three quarters of Americans used a mobile device the last time they <br/>checked their account balance.<a>5</a>  With the push to contactless payments <br/>during the pandemic, experts predict mobile devices will also become one <br/>of the main ways people interact with ATMs, make payments, and have <br/>banking consultations.<a>6</a>  Without the right technology and CX program, <br/>community banks will struggle to give customers a robust mobile experience.',
    },
    {
        icon: `${baseURL}assets/images/ebook/de-feature-3.svg`,
        title: 'Employee expertise',
        para:
            'Increasingly, employee experience is customer experience. Employees at <br/>small community banks wear many hats and don’t have the time to learn, <br/>implement, and maintain the new tech that digital services necessitate.<a>4</a>  <br/>If employees and customer support agents aren’t well-equipped to help <br/>customers, the experience will inevitably suffer.',
    },
    {
        icon: `${baseURL}assets/images/ebook/de-feature-4.svg`,
        title: 'Rise of challenger banks',
        para:
            'The growth in total assets of the community banks combined with the decline in the number of such banks means that the average size of banks has increased. In 2003, the average size of a community bank was $195.6 million and by 2014 the average size was $341.9 million. While community banks lost millennial and Gen Z consumers in 2020, digital banks won out, increasing their customer base by 7% between January and December 2020 alone.<a>9</a>  Challenger banks’ alarming growth among key demographics poses a significant threat to community banks’ long-term growth.',
    },
];

const benefits = [
    'Delivers an easy, seamless experience for customers and employees',
    'Lets customers use their preferred channel, which is increasingly digital',
    'Helps attract and retain Millennial and Gen Z demographics',
    'Empowers agents to perform better, putting critical information at their fingertips',
    'Eliminates siloes, so your whole team collaborates better to resolve issues faster',
    'Enables you to compete with big banks and challengers on CX',
];

const BuildingDigitalExp = () => {
    const barRef1 = useRef(null);
    const barRef2 = useRef(null);
    const barRef3 = useRef(null);
    const barRef4 = useRef(null);

    const BarWrap = useRef();

    const isVisible = useOnScreen(BarWrap);

    const OmniCXFeature = [
        {
            icon: `${baseURL}assets/images/ebook/icon-awesome-arrow-up.svg`,
            perce: '131%',
            title: 'higher asset growth',
            ref: barRef1,
        },
        {
            icon: `${baseURL}assets/images/ebook/icon-awesome-arrow-up.svg`,
            perce: '90%',
            title: 'higher loan growth',
            ref: barRef2,
        },
        {
            icon: `${baseURL}assets/images/ebook/icon-awesome-arrow-up.svg`,
            perce: '93%',
            title: 'higher share growth',
            ref: barRef3,
        },
        {
            icon: `${baseURL}assets/images/ebook/icon-awesome-arrow-up.svg`,
            perce: '77%',
            title: 'higher return on assets',
            ref: barRef4,
        },
    ];

    const BarProgress = (element, percent) => {
        if (element.current) {
            var bar = new ProgressBar.Line(element.current, {
                strokeWidth: 4,
                easing: 'easeInOut',
                duration: 3000,
                color: colors.socialRep['brandsBG'],
                trailColor: colors.ebook.digitalExp['green'],
                trailWidth: 4,
                svgStyle: { width: '100%', height: '100%' },
            });
            bar.animate(percent);
        }
    };

    useEffect(() => {
        BarProgress(barRef1, 1);
        BarProgress(barRef2, 0.9);
        BarProgress(barRef3, 0.93);
        BarProgress(barRef4, 0.77);
    });

    return (
        <Layout noheaderfooter npPaddingBottom>
            {/* Intro Section */}
            <DEFullHeight bgDark>
                <DEHeader />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle.svg`}
                    width={880}
                    right="15%"
                    top="72%"
                    x={['-20%', '20%']}
                />
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-light.svg`}
                    width={207}
                    right="2%"
                    top="50%"
                    x={['-20%', '20%']}
                />
                <BigTextWrapper>
                    <EbookBigText color={'transparent'}>DIGITAL</EbookBigText>
                    <EbookBigText color={'transparent'} reverse>
                        EXPERIENCE
                    </EbookBigText>
                </BigTextWrapper>
                <SidePaddingWrapper>
                    <div>
                        <EbookPart>PART 1</EbookPart>
                        <IntroHeading>
                            Building a Digital <br />
                            Customer Experience <br />
                            for Community Banks.
                        </IntroHeading>
                        <IntroPara>
                            A guide to planning your digital transformation
                            for&nbsp;
                            <br />
                            maximum engagement, loyalty and walletshare
                        </IntroPara>
                    </div>
                    <IntroImg>
                        <img
                            src={`${baseURL}assets/images/ebook/de-intro.png`}
                            alt="-"
                        />
                    </IntroImg>
                </SidePaddingWrapper>
                <DEFooter iconLight />
            </DEFullHeight>

            {/* Content Section */}
            <DEFullHeight>
                <Contents>
                    <SidePaddingWrapper topPadding>
                        <div>
                            <Container fluid={true}>
                                <Row>
                                    <Col lg="6">
                                        <h1>Contents</h1>
                                    </Col>
                                    <Col lg="6">
                                        <ol>
                                            <li>
                                                <Link
                                                    to={'introduction'}
                                                    spy={true}
                                                    smooth={true}
                                                    duration={1000}>
                                                    Introduction
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={'current-state'}
                                                    spy={true}
                                                    smooth={true}
                                                    duration={1000}>
                                                    The current state of
                                                    community bank CX
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={'streamlined-customer'}
                                                    spy={true}
                                                    smooth={true}
                                                    duration={1000}>
                                                    The value of a streamlined
                                                    customer experience
                                                </Link>
                                            </li>
                                        </ol>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </SidePaddingWrapper>
                </Contents>
            </DEFullHeight>

            {/* Introduction Section */}
            <DEFullHeight bgDark id="introduction">
                <IntroductionWrapper>
                    <BigTextWrapper>
                        <EbookBigText color={'transparent'}>INTRO</EbookBigText>
                        <EbookBigText color={'transparent'} reverse>
                            DUCTION
                        </EbookBigText>
                    </BigTextWrapper>
                    <Container fluid>
                        <Row noGutters={true}>
                            <Col lg="5">
                                <div>
                                    <img
                                        src={`${baseURL}assets/images/ebook/de-introsec-img.jpg`}
                                        alt="-"
                                    />
                                </div>
                            </Col>
                            <Col lg="7">
                                <div>
                                    <IntroContent>
                                        <h3>Introduction</h3>
                                        <DEPara light>
                                            <p>
                                                As of the end of 2019, the U.S.
                                                had 4,750 community banks with
                                                more than 29,000 branches
                                                throughout the country,
                                                according to the FDIC&apos;s
                                                2020 Community Banking Study
                                                <Sup
                                                    light
                                                    href="https://www.fdic.gov/resources/community-banking/report/2020/2020-cbi-study-full.pdf">
                                                    1
                                                </Sup>
                                                . But, as big banks eat up more
                                                market share, community banks
                                                face steep competition. In the
                                                same year, 45% of households in
                                                the U.S. named one of the
                                                country’s three largest banks as
                                                their primary financial
                                                institution—a number that’s been
                                                on the rise since 2018.
                                                <Sup light>2</Sup>
                                            </p>
                                            <p>
                                                The trend that’s driving
                                                consumers toward big banks—as
                                                well as up-and-coming challenger
                                                banks—is likely to continue
                                                unless community banks evolve
                                                and adapt. Without the speed,
                                                ease, and convenience that big
                                                banks offer, community banks
                                                will struggle to compete.
                                            </p>
                                            <p>
                                                In this guide, we’ll explore how
                                                you can differentiate your
                                                community bank by developing and
                                                executing a purpose-built
                                                omnichannel customer experience
                                                strategy. Boosting engagement
                                                across all channels—especially
                                                digital ones—can help you gain
                                                greater loyalty and walletshare
                                                among existing customers while
                                                attracting new business.
                                            </p>
                                        </DEPara>
                                    </IntroContent>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </IntroductionWrapper>
            </DEFullHeight>

            {/* Community Bank Section */}
            <DEFullHeight paddingTop id="current-state">
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-lighter.svg`}
                    width={493}
                    zIndex="9"
                    right="12%"
                    top="30%"
                    x={['-20%', '20%']}
                />
                <ImageTextBlock>
                    <Container fluid>
                        <Row>
                            <Col lg="6">
                                <div>
                                    <DEHeading>
                                        The current state <br />
                                        of community bank CX.
                                    </DEHeading>
                                    <DEMediumHeading>
                                        Challenges and opportunities for
                                        building a&nbsp;
                                        <br />
                                        satisfying customer journey
                                    </DEMediumHeading>
                                    <DEPara>
                                        Community banks differentiate from big
                                        banks by being community-
                                        <br />
                                        first and owned locally: operating
                                        locally, offering better rates,
                                        delivering <br />
                                        high-touch customer service, and
                                        building strong relationships.
                                        But,&nbsp;
                                        <br />
                                        thanks to the rise of digital banking,
                                        they’re up against a number of <br />
                                        obstacles when it comes to competing
                                        with big financial players <br />
                                        and rising fintechs.
                                    </DEPara>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div>
                                    <BlockImg>
                                        <img
                                            src={`${baseURL}assets/images/ebook/de-block-img1.jpg`}
                                            alt="-"
                                        />
                                    </BlockImg>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <NextBox>
                        <h5>NEXT</h5>
                        <DEPara light noMarginBottom>
                            Here are some of the biggest <br />
                            customer experience challenges <br />
                            facing community banks today.
                        </DEPara>
                        <Link
                            to={'features-slide'}
                            spy={true}
                            smooth={true}
                            duration={1000}>
                            <a>
                                <img
                                    src={`${baseURL}assets/images/arrow_right-blue.svg`}
                                    alt="-"
                                />
                            </a>
                        </Link>
                    </NextBox>
                </ImageTextBlock>
            </DEFullHeight>

            {/* Features Section 1*/}
            <DEFullHeight id="features-slide">
                <FeaturesWrapper>
                    <SidePaddingWrapper topPadding>
                        <Container fluid>
                            <Row>
                                {FeatureList1 &&
                                    FeatureList1.length > 0 &&
                                    FeatureList1.map((f, index) => {
                                        return (
                                            <Col lg="6" key={index}>
                                                <div>
                                                    <DEMediumHeading>
                                                        <img
                                                            src={f.icon}
                                                            alt=""
                                                        />
                                                        {f.title}
                                                    </DEMediumHeading>
                                                    <DEPara>
                                                        {ReactHtmlParser(
                                                            f.para
                                                        )}
                                                    </DEPara>
                                                </div>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </Container>
                    </SidePaddingWrapper>
                </FeaturesWrapper>
            </DEFullHeight>

            {/* Winning the battle Section*/}
            <DEFullHeight>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-lighter.svg`}
                    width={880}
                    left="-20%"
                    top="75%"
                    x={['0%', '20%']}
                />
                <WinningBattle>
                    <Container fluid>
                        <Row noGutters>
                            <Col lg="5">
                                <WinningBattleLight>
                                    <div>
                                        <DEHeading2>
                                            Winning the <br />
                                            battle for customer <br />
                                            walletshare.
                                        </DEHeading2>
                                        <DEPara>
                                            Aside from focusing strategic
                                            efforts on the major business
                                            challenges we just outlined, what
                                            competitive advantages can community
                                            banks leverage to capture more
                                            customer walletshare? To start,
                                            community banks can double down on
                                            their strength in one key area:
                                            customer-centricity.
                                        </DEPara>
                                    </div>
                                </WinningBattleLight>
                            </Col>
                            <Col lg="7">
                                <WinningBattleDark>
                                    <WinningDarkTop>
                                        <Row noGutters>
                                            <Col lg="3">
                                                <img
                                                    src={`${baseURL}assets/images/ebook/de-win-battle.jpg`}
                                                    alt="-"
                                                />
                                            </Col>
                                            <Col lg="9">
                                                <div>
                                                    <h5>
                                                        Community banks’
                                                        personal touch
                                                    </h5>
                                                    <p>
                                                        The game-changing
                                                        element of digital
                                                        transformation, no
                                                        matter which industry
                                                        we’re talking about,
                                                        isn’t technology.&nbsp;
                                                        <a>
                                                            It’s
                                                            customer-centricity
                                                        </a>
                                                        .<Sup light>10</Sup>
                                                        &nbsp; Putting your
                                                        customers’ needs first
                                                        leads to long-term
                                                        success. Generally
                                                        speaking, community
                                                        banks embody this
                                                        principle. The
                                                        underlying ethos of
                                                        “people helping people”
                                                        is central to many
                                                        community banks’ brands
                                                        and customer
                                                        experiences. That human
                                                        touch is a note most big
                                                        banks and even some
                                                        up-and-coming fintechs
                                                        struggle to hit.
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </WinningDarkTop>
                                    <WinningDarkPara>
                                        <div>
                                            <DEPara light>
                                                When planning their digital
                                                transformation, community banks
                                                can leverage their advanced
                                                knowledge of individual
                                                customers to level up the
                                                customer experience through
                                                digital. The big challenge will
                                                be figuring out how to extend
                                                the personalized advice,
                                                support, education, and other
                                                services customers get in
                                                physical branches to the digital
                                                space—and ensuring a seamless,
                                                omnichannel experience no matter
                                                which door they walk through.
                                            </DEPara>
                                        </div>
                                    </WinningDarkPara>
                                </WinningBattleDark>
                            </Col>
                        </Row>
                    </Container>
                </WinningBattle>
            </DEFullHeight>

            {/* Streamlined customer experience Section*/}
            <DEFullHeight bgDark id="streamlined-customer">
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-lighter.svg`}
                    width={493}
                    right="4%"
                    top="-18%"
                    x={['-20%', '20%']}
                />
                <BigTextWrapper>
                    <EbookBigText color={'transparent'}>CUSTOMER</EbookBigText>
                    <EbookBigText color={'transparent'} reverse>
                        EXPERI
                    </EbookBigText>
                </BigTextWrapper>
                <SidePaddingWrapper topPadding>
                    <Streamlined>
                        <div>
                            <DEHeading light>
                                The value of a streamlined <br />
                                customer experience.
                            </DEHeading>
                            <StreamlinedContent>
                                <Row>
                                    <Col lg="6">
                                        <div>
                                            <img
                                                src={`${baseURL}assets/images/ebook/de-streamline.jpg`}
                                                alt="-"
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <StreamlinedInfo>
                                            <DEMediumHeading light>
                                                How omnichannel CX smooths the
                                                customer journey
                                            </DEMediumHeading>
                                            <DEPara light>
                                                Now that we’ve explored the
                                                challenges and opportunities
                                                community banks are facing,
                                                let’s look at where your
                                                customer journey might be
                                                falling behind and how boosting
                                                omnichannel engagement can
                                                improve your results.
                                            </DEPara>
                                        </StreamlinedInfo>
                                    </Col>
                                </Row>
                            </StreamlinedContent>
                        </div>
                    </Streamlined>
                </SidePaddingWrapper>
            </DEFullHeight>

            {/* Omini Channel CX Section*/}
            <DEFullHeight>
                <SidePaddingWrapper>
                    <Container fluid>
                        <Row>
                            <Col lg="6">
                                <OminiCXLeft>
                                    <div>
                                        <DEHeading2>
                                            What is omnichannel CX?
                                        </DEHeading2>
                                        <DEPara>
                                            <p>
                                                An omnichannel customer
                                                experience (CX) involves
                                                streamlining all customer
                                                interactions across multiple
                                                touchpoints in a unified way to
                                                deliver a consistent brand
                                                experience—whether customers
                                                contact sales, customer support,
                                                or marketing. From the
                                                customer’s perspective, every
                                                interaction is part of a unified
                                                experience.
                                            </p>
                                            <p>
                                                While many community banks have
                                                succeeded in offering
                                                multi-channel sales, support and
                                                marketing, with many ways for a
                                                customer to get in touch, those
                                                channels tend to be siloed and
                                                disconnected, creating friction.
                                            </p>
                                            <p>
                                                Studies show that streamlining
                                                CX through omnichannel delivery
                                                creates happier customers and
                                                higher walletshare for community
                                                banks. Organizations that
                                                succeed in that respect
                                                outperform in the community bank
                                                space. According to Harvard
                                                Business Review, a 5% boost in
                                                customer retention can boost
                                                community bank income by 25% to
                                                95%.
                                            </p>
                                        </DEPara>
                                    </div>
                                </OminiCXLeft>
                            </Col>
                            <Col lg={{ size: 5, offset: 1 }}>
                                <OminiCXRight>
                                    <div>
                                        <DEMediumHeading>
                                            Customer experience is everything
                                        </DEMediumHeading>
                                        <DEPara>
                                            Community banks with a strong focus
                                            on customer experience beat out
                                            industry averages in the following
                                            areas:&nbsp;
                                            <Sup>11</Sup>
                                        </DEPara>
                                        <OminiFeatureWrapper ref={BarWrap}>
                                            {OmniCXFeature &&
                                                OmniCXFeature.length > 0 &&
                                                OmniCXFeature.map(
                                                    (of, index) => {
                                                        return (
                                                            <OminiFeature
                                                                key={index}>
                                                                <Feature>
                                                                    <img
                                                                        src={
                                                                            of.icon
                                                                        }
                                                                        alt="-"
                                                                    />
                                                                    <h3>
                                                                        {
                                                                            of.perce
                                                                        }
                                                                    </h3>
                                                                    <h5>
                                                                        {
                                                                            of.title
                                                                        }
                                                                    </h5>
                                                                </Feature>
                                                                {isVisible && (
                                                                    <BarContainer
                                                                        ref={
                                                                            of.ref
                                                                        }
                                                                    />
                                                                )}
                                                            </OminiFeature>
                                                        );
                                                    }
                                                )}
                                        </OminiFeatureWrapper>
                                        <OminiSource>
                                            <DEPara>Source:</DEPara>
                                            <DEPara>
                                                <a href="#">CustomerXP</a>
                                            </DEPara>
                                        </OminiSource>
                                    </div>
                                </OminiCXRight>
                            </Col>
                        </Row>
                    </Container>
                </SidePaddingWrapper>
            </DEFullHeight>

            {/* Eliminating friction Section*/}
            <DEFullHeight>
                <ImageTextBlock noPaddingLeft>
                    <div>
                        <Container fluid>
                            <Row>
                                <Col lg="5">
                                    <div>
                                        <BlockImg>
                                            <img
                                                src={`${baseURL}assets/images/ebook/de-friction-img.jpg`}
                                                alt="-"
                                            />
                                        </BlockImg>
                                    </div>
                                </Col>
                                <Col lg={{ size: 6, offset: 1 }}>
                                    <DEHeading2>
                                        Eliminating friction in the <br />
                                        customer journey.
                                    </DEHeading2>
                                    <DEPara>
                                        As you plan your move to omnichannel CX,
                                        you’ll want to identify issues in <br />
                                        the customer journey across channels.
                                        Beyond digital transformation from
                                        a&nbsp;
                                        <br />
                                        strictly tech perspective, community
                                        banks need to focus on one surprisingly{' '}
                                        <br />
                                        simple strategy: making customers’ lives
                                        easier.
                                    </DEPara>
                                    <DEMediumHeading>
                                        <img
                                            src={`${baseURL}assets/images/ebook/de-switch-icon.svg`}
                                            alt="-"
                                        />
                                        Switching between channels
                                    </DEMediumHeading>
                                    <DEPara>
                                        Can your customers start a borrowing
                                        application online and complete it
                                        in&nbsp;
                                        <br />
                                        person? One of the top pain points in a
                                        multi-channel approach is poor <br />
                                        hand-off between channels. When the
                                        experience is siloed, hopping from&nbsp;
                                        <br />
                                        one delivery channel to another can be
                                        painful and repetitive. Customers
                                        can&nbsp;
                                        <br />
                                        feel like they’re going in circles when
                                        they have to re-explain an issue
                                        or&nbsp;
                                        <br />
                                        share the same information over and
                                        over.
                                    </DEPara>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ImageTextBlock>
            </DEFullHeight>

            {/* Channel thrashing Section*/}
            <DEFullHeight>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-lighter.svg`}
                    width={493}
                    right="-7%"
                    top="60%"
                    x={['-20%', '20%']}
                />
                <ImageTextBlock>
                    <div>
                        <Container fluid>
                            <Row>
                                <Col lg="6">
                                    <DEMediumHeading>
                                        <img
                                            src={`${baseURL}assets/images/ebook/de-emoji.svg`}
                                            alt="-"
                                        />
                                        Channel thrashing
                                    </DEMediumHeading>
                                    <DEPara>
                                        Can your borrower complete their
                                        application entirely online if
                                        they&nbsp;
                                        <br />
                                        want to? When a customer wants to stick
                                        to their preferred channel, such <br />
                                        as a lending app, but is forced to move
                                        to another channel, such as <br />
                                        talking to a human agent, it’s called
                                        “channel thrashing.” It’s a major <br />
                                        pain point for consumers who are short
                                        on time and patience.
                                    </DEPara>
                                    <DEMediumHeading>
                                        <img
                                            src={`${baseURL}assets/images/ebook/de-swap.svg`}
                                            alt="-"
                                        />
                                        Operations-centric thinking
                                    </DEMediumHeading>
                                    <DEPara>
                                        How does your community bank think about
                                        the customer journey? Many <br />
                                        financial institutions focus too much on
                                        discrete channels and not on the <br />
                                        retail banking experience. For
                                        consumers, every conversation they
                                        have&nbsp;
                                        <br />
                                        is with “the community bank,” whether
                                        it’s through voice, chat, email, or at
                                        a&nbsp;
                                        <br />
                                        branch. Service and functionality should
                                        be the same, regardless of <br />
                                        which path they take to get to you.
                                    </DEPara>
                                </Col>
                                <Col lg="4">
                                    <CustomFlexCentered>
                                        <div>
                                            <BlockImg>
                                                <img
                                                    src={`${baseURL}assets/images/ebook/de-scan-img.jpg`}
                                                    alt="-"
                                                />
                                            </BlockImg>
                                        </div>
                                    </CustomFlexCentered>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ImageTextBlock>
            </DEFullHeight>

            {/* Benefits of an omnichannel Section*/}
            <DEFullHeight bgDark>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-lighter.svg`}
                    width={1015}
                    right="-35%"
                    top="-50%"
                    x={['-20%', '20%']}
                />
                <SidePaddingWrapper topPadding>
                    <Container fluid>
                        <Row>
                            <Col lg="5">
                                <div>
                                    <DEHeading2 light>
                                        Benefits of an <br />
                                        omnichannel CX strategy.
                                    </DEHeading2>
                                    <DEPara light>
                                        <p>
                                            The big takeaway? It’s time to stop
                                            thinking about digital and physical
                                            customer experiences as two
                                            different things. All interactions
                                            belong to the same customer journey
                                            and an integrated approach can make
                                            the whole experience simpler and
                                            more enjoyable.
                                        </p>
                                        <p>
                                            When your customers are happy, your
                                            business results speak for
                                            themselves. A great omnichannel
                                            customer experience drives loyalty,
                                            retention, referrals—and revenue.
                                            Companies that have the strongest
                                            omnichannel customer engagement
                                            strategies see 10% growth
                                            year-over-year. Plus, they increase
                                            order value by another 10% and
                                            improve close rates by 25%.
                                            <Sup light>12</Sup>
                                        </p>
                                    </DEPara>
                                </div>
                            </Col>
                            <Col lg={{ size: 6, offset: 1 }}>
                                <div>
                                    <DEMediumHeading light>
                                        Omnichannel CX is better for
                                        customers&nbsp;
                                        <br />
                                        and better for business
                                    </DEMediumHeading>
                                    <BenefitsList>
                                        {benefits &&
                                            benefits.length > 0 &&
                                            benefits.map((b, index) => {
                                                return (
                                                    <Benefit key={index}>
                                                        <img
                                                            src={`${baseURL}assets/images/ebook/icon-awesome-check-circle.svg`}
                                                            alt="-"
                                                        />
                                                        <p>{b}</p>
                                                    </Benefit>
                                                );
                                            })}
                                    </BenefitsList>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </SidePaddingWrapper>
            </DEFullHeight>

            {/* Last Slide */}
            <DEFullHeight bgDark>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/ebook/pink-circle-lighter.svg`}
                    width={493}
                    right="6%"
                    top="-20%"
                    x={['-20%', '20%']}
                />
                <LastSlide>
                    <Container fluid>
                        <Row>
                            <Col lg="7">
                                <LastSlideLeft>
                                    <div>
                                        <DEHeading light>
                                            Interested in more? Click ahead
                                            to&nbsp;
                                            <a
                                                href={`/resources/ebooks/community-bank-digital-customer-experience-part-2`}>
                                                Part 2
                                            </a>
                                            !
                                        </DEHeading>
                                        <DEMediumHeading light>
                                            What you’ll find in Part 2:
                                        </DEMediumHeading>
                                        <ul>
                                            <li>
                                                <img
                                                    src={`${baseURL}assets/images/ebook/de-icon-orbit.svg`}
                                                    alt="-"
                                                />
                                                Building Your Omnichannel CX
                                                Program
                                            </li>
                                            <li>
                                                <img
                                                    src={`${baseURL}assets/images/ebook/de-icon-count-calender.svg`}
                                                    alt="-"
                                                />
                                                Digital CX Planning for 2022 and
                                                Beyond
                                            </li>
                                        </ul>
                                    </div>
                                </LastSlideLeft>
                            </Col>
                            <Col lg="5">
                                <div>
                                    <LastSlideRightImg>
                                        <div>
                                            <img
                                                src={`${baseURL}assets/images/ebook/de-part1-last.jpg`}
                                                alt="-"
                                            />
                                        </div>
                                    </LastSlideRightImg>
                                    <ReadPart2
                                        href={`/resources/ebooks/community-bank-digital-customer-experience-part-2`}>
                                        Read Part 2
                                        <span>
                                            <img
                                                src={`${baseURL}assets/images/ebook/icon-awesome-long-arrow-alt-right.svg`}
                                            />
                                        </span>
                                    </ReadPart2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </LastSlide>
            </DEFullHeight>
        </Layout>
    );
};

const BarContainer = styled.div`
    height: 5px;
    ${Media('xlscreens')} {
        width: 30vw;
        height: 0.46vw;
        margin-top: 1vw;
    }
    ${Media('tablet')} {
        width: 90%;
        margin-top: 5px;
    }
`;

const SidePaddingWrapper = styled.div`
    padding: 50px;
    box-sizing: border-box;
    position: relative;
    ${Media('xlscreens')} {
        height: 100%;
        padding: ${(props) =>
            props.topPadding ? '7.29vw 5.98vw' : '0 5.98vw'};
    }
    ${Media('mobile')} {
        padding: 15px;
    }
`;

const EbookPart = styled.h4`
    font-family: ${gilroyBold};
    font-size: 22px;
    letter-spacing: 6px;
    color: ${colors.white['100']};
    margin-top: 0;
    ${Media('xlscreens')} {
        font-size: 2.08vw;
        letter-spacing: 0.31vw;
    }
    ${Media('mobile')} {
        font-size: 18px;
    }
`;

const IntroHeading = styled.h1`
    font-size: 50px;
    color: ${colors.white['100']};
    letter-spacing: 1.13;
    font-family: ${gilroyBold};
    letter-spacing: -3px;
    ${Media('xlscreens')} {
        font-size: 5.3vw;
        letter-spacing: -0.25vw;
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 36px;
        letter-spacing: -2px;
    }
`;

const IntroPara = styled.p`
    color: ${colors.white['100']};
    font-size: 22px;
    line-height: 1.72;
    margin-top: 40px;
    ${Media('xlscreens')} {
        font-size: 1.66vw;
        margin-top: 2.08vw;
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 18px;
    }
`;

const IntroImg = styled.div`
    img {
        width: auto;
        height: 948px;
    }
    ${Media('xlscreens')} {
        position: absolute;
        right: -5vw;
        top: -4.5vw;
        img {
            height: 47vw;
        }
    }
    ${Media('tablet')} {
        img {
            height: 300px;
            float: right;
        }
    }
    ${Media('mobile')} {
        img {
            margin-left: 20px;
            float: unset;
        }
    }
`;

const BigTextWrapper = styled.div`
    position: absolute;
    bottom: 50px;
    .parallax-inner {
        -webkit-text-stroke: 0.4px ${colors.pink['50']};
    }
    ${Media('xlscreens')} {
        bottom: 2.6vw;
        .parallax-inner {
            font-size: 25vw !important;
            line-height: 0.8;
        }
    }
`;

const Contents = styled.div`
    background-image: url(${baseURL}assets/images/ebook/de-content-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    h1 {
        font-size: 50px;
        font-family: ${gilroyBold};
        letter-spacing: -3px;
        margin-top: 0;
    }
    ol {
        li {
            font-size: 22px;
            list-style: decimal;
            font-family: ${gilroyMedium};
            padding: 15px;
            &::marker {
                color: ${colors.pink['100']};
                font-family: ${gilroyBold};
                margin-right: 20px;
            }
        }
    }
    ${Media('xlscreens')} {
        h1 {
            font-size: 6.25vw;
            letter-spacing: -0.25vw;
        }
        ol {
            li {
                font-size: 1.61vw;
                padding: 1.04vw;
                &::marker {
                    margin-right: 1.56vw;
                }
            }
        }
    }
    ${Media('tablet')} {
        padding-top: 50px;
        padding-bottom: 300px;
        ${SidePaddingWrapper} {
            padding-top: 0;
        }
    }
    ${Media('mobile')} {
        h1 {
            font-size: 36px;
            letter-spacing: -2px;
            margin-bottom: 15px;
        }
        ol {
            padding-left: 15px;
            li {
                font-size: 14px;
            }
        }
    }
`;

const IntroductionWrapper = styled.div`
    h3 {
        font-size: 35px;
        font-family: ${gilroyBold};
        color: ${colors.white['100']};
    }
    img {
        width: 100%;
    }
    .col-lg-7 {
        z-index: 99;
    }
    ${Media('xlscreens')} {
        font-size: 2.34vw;
        ${BigTextWrapper} {
            z-index: 9;
        }
    }
    ${Media('tablet')} {
        p {
            margin-bottom: 0;
        }
    }
    ${Media('mobile')} {
        h3 {
            font-size: 26px;
        }
    }
`;

const IntroContent = styled.div`
    padding: 50px;
    ${Media('xlscreens')} {
        padding: 10vw 6.25vw 5.2vw;
    }
    ${Media('mobile')} {
        padding: 15px;
    }
`;

const Sup = styled.a`
    font-size: 8px;
    position: relative;
    color: ${(props) =>
        props.light ? colors.white['100'] : colors.black['100']};
    top: -20px;
    left: 1px;
    &:hover {
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        font-size: 0.7vw;
        top: -0.5vw;
    }
`;

const ImageTextBlock = styled.div`
    padding-left: ${(props) => (props.noPaddingLeft ? '0' : '50px')};
    height: 100%;
    width: 100%;
    ${Media('xlscreens')} {
        padding: 7.29vw 0;
        padding-left: ${(props) => (props.noPaddingLeft ? '0' : '5.98vw')};
    }
    ${Media('tablet')} {
        padding: 50px;
        box-sizing: border-box;
        position: relative;
    }
    ${Media('mobile')} {
        padding: 15px;
    }
`;

const BlockImg = styled.div`
    img {
        height: 100%;
        width: 100%;
    }
    ${Media('tablet')} {
        margin: 50px 0;
    }
`;

const NextBox = styled.div`
    position: absolute;
    background-color: ${colors.socialRep['brandsBG']};
    bottom: 0px;
    left: calc(50%);
    padding: 30px;
    z-index: 99;
    h5 {
        color: ${colors.white['100']};
        font-size: 20px;
        font-family: ${sourceSansPro};
        letter-spacing: 12px;
        margin-top: 0;
    }
    img {
        width: 40px;
        position: absolute;
        bottom: 20px;
        right: 40px;
        transform: rotate(90deg);
        &:hover {
            animation: down 2s infinite;
        }
    }
    ${Media('xlscreens')} {
        padding: 3.64vw;
        padding-right: 7.8vw;
        h5 {
            font-size: 1.35vw;
            letter-spacing: 0.625vw;
        }
        img {
            width: 2.6vw;
            bottom: 1.04vw;
            right: 2.08vw;
        }
    }
    ${Media('mobile')} {
        padding: 15px;
        left: calc(30%);
        h5 {
            font-size: 16px;
        }
        img {
            width: 30px;
            right: 10px;
        }
    }
    @keyframes down {
        0% {
            transform: rotate(90deg) translate(0);
        }
        20% {
            transform: rotate(90deg) translateX(15px);
        }
        40% {
            transform: rotate(90deg) translate(0);
        }
        60% {
            transform: rotate(90deg) translateX(15px);
        }
        100% {
            transform: rotate(90deg) translate(0);
        }
    }
`;

const FeaturesWrapper = styled.div`
    box-sizing: border-box;
    a {
        font-size: 8px;
        position: relative;
        color: ${(props) =>
            props.light ? colors.white['100'] : colors.black['100']};
        top: -20px;
        left: 1px;
        &:hover {
            color: ${colors.pink['100']};
        }
        ${Media('xlscreens')} {
            font-size: 0.7vw;
            top: -0.5vw;
        }
    }
`;

const WinningBattle = styled.div`
    height: 100%;
`;
const WinningBattleLight = styled.div`
    padding: 50px;
    background-color: ${colors.technologyDropdown['box']};
    ${Media('xlscreens')} {
        height: calc(56.25vw - 5.8vw);
        padding: 9.3vw 5.98vw;
    }
    ${Media('tablet')} {
        padding-top: 0;
    }
    ${Media('mobile')} {
        padding: 15px;
    }
`;
const WinningBattleDark = styled.div`
    background-color: ${colors.socialRep['brandsBG']};
    ${Media('xlscreens')} {
        height: calc(56.25vw - 5.8vw);
    }
    ${Media('tablet')} {
        padding: 50px;
    }
    ${Media('mobile')} {
        padding: 15px;
    }
`;
const WinningDarkTop = styled.div`
    margin-bottom: 30px;
    img {
        width: 100%;
    }
    h5 {
        font-size: 26px;
        font-family: ${gilroyBold};
        color: ${colors.white['100']};
    }
    p {
        color: ${colors.white['100']};
        font-size: 20px;
        word-spacing: 2px;
        a {
            color: ${colors.pink['100']};
        }
    }
    ${Media('xlscreens')} {
        margin-bottom: 1.56vw;
        h5 {
            font-size: 1.87vw;
            padding-left: 4.68vw;
        }
        p {
            padding: 0 4.68vw;
            font-size: 1.3vw;
        }
    }
    ${Media('mobile')} {
        h5 {
            font-size: 22px;
        }
        p {
            font-size: 14px;
        }
    }
`;

const WinningDarkPara = styled.div`
    ${Media('xlscreens')} {
        margin: 4.16vw;
    }
`;

const Streamlined = styled.div`
    img {
        width: 100%;
    }
`;

const StreamlinedContent = styled(Container)`
    margin-top: 100px;
    h3 {
        margin-top: 0;
    }
    ${Media('xlscreens')} {
        margin-top: 9.37vw;
    }
    ${Media('tablet')} {
        padding: 0;
    }
    ${Media('mobile')} {
        margin-top: 50px;
    }
`;

const StreamlinedInfo = styled.div`
    ${Media('xlscreens')} {
        margin-left: 2.66vw;
    }
    ${Media('tablet')} {
        margin-top: 30px;
    }
`;

const OminiFeature = styled.div`
    margin-bottom: 30px;
    ${Media('xlscreens')} {
        margin-bottom: 1.56vw;
    }
`;

const Feature = styled.div`
    display: flex;
    align-items: center;
    img {
        height: 30px;
        margin-right: 20px;
    }
    h3 {
        font-size: 50px;
        font-family: ${gilroyBold};
        margin: 0;
        margin-right: 30px;
    }
    h5 {
        font-size: 30px;
        font-family: ${gilroyBold};
        margin: 0px;
    }
    ${Media('xlscreens')} {
        img {
            margin-right: 1.04vw;
            height: 1.56vw;
        }
        h3 {
            font-size: 2.66vw;
            margin-right: 1.56vw;
        }
        h5 {
            font-size: 1.56vw;
        }
    }
    ${Media('mobile')} {
        img {
            height: 15px;
        }
        h3 {
            font-size: 30px;
        }
        h5 {
            font-size: 22px;
        }
    }
`;

const OminiCXLeft = styled.div`
    ${Media('xlscreens')} {
        padding-top: 7.29vw;
    }
`;

const OminiCXRight = styled.div`
    ${Media('xlscreens')} {
        padding-top: 2vw;
    }
`;

const OminiFeatureWrapper = styled.div`
    margin-top: 70px;
    ${Media('xlscreens')} {
        margin-top: 3.64vw;
    }
    ${Media('mobile')} {
        margin-top: 50px;
    }
`;
const OminiSource = styled.div`
    display: flex;
    a {
        color: ${colors.ebook.digitalExp['green']};
    }
`;
const CustomFlexCentered = styled(FlexCentered)`
    height: 100%;
`;

const BenefitsList = styled.div`
    margin-top: 50px;
    ${Media('xlscreens')} {
        margin-top: 2.6vw;
    }
    ${Media('mobile')} {
        margin-top: 30px;
    }
`;

const Benefit = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    img {
        height: 26px;
        margin-right: 20px;
    }
    p {
        font-size: 20px;
        color: ${colors.white['100']};
        margin: 0;
    }
    ${Media('xlscreens')} {
        margin-bottom: 1.56vw;
        img {
            height: 1.82vw;
            margin-right: 1.04vw;
        }
        p {
            font-size: 1.25vw;
        }
    }
    ${Media('mobile')} {
        img {
            height: 20px;
        }
        p {
            font-size: 14px;
        }
    }
`;

const LastSlide = styled.div`
    padding-left: 50px;
    ${Media('xlscreens')} {
        padding-left: 5.98vw;
    }
    ${Media('tablet')} {
        padding: 0px 50px;
    }
    ${Media('mobile')} {
        padding: 0px 15px;
    }
`;

const LastSlideLeft = styled.div`
    padding-top: 140px;
    padding-right: 120px;
    ul {
        padding-left: 0;
        padding-right: 150px;
        li {
            display: flex;
            align-items: center;
            font-size: 24px;
            padding: 20px 30px;
            margin-bottom: 10px;
            color: ${colors.white['100']};
            background-color: ${colors.white['8']};
            font-family: ${gilroyMedium};
            img {
                margin-right: 30px;
                height: 60px;
                width: 60px;
            }
        }
    }
    ${Media('xlscreens')} {
        padding-top: 7.29vw;
        padding-right: 6.25vw;
        ul {
            padding-right: 7.81vw;
            li {
                font-size: 1.56vw;
                padding: 1.04vw 1.56vw;
                margin-bottom: 0.52vw;
                img {
                    margin-right: 1.56vw;
                    height: 5.2vw;
                    width: 5.2vw;
                }
            }
        }
    }
    ${Media('tablet')} {
        padding: 0;
        ul {
            padding-right: 0;
            margin-bottom: 50px;
        }
    }
    ${Media('mobile')} {
        ul {
            li {
                font-size: 14px;
                img {
                    height: 40px;
                    width: 40px;
                }
            }
        }
    }
`;

const LastSlideRightImg = styled.div`
    img {
        width: 100%;
        opacity: 0.72;
    }
`;

const ReadPart2 = styled.a`
    position: absolute;
    top: 30%;
    background-color: ${colors.pink['100']};
    padding: 100px 150px 100px 60px;
    font-size: 39px;
    color: ${colors.white['100']};
    font-family: ${gilroyBold};
    span {
        position: absolute;
        right: 70px;
        bottom: 30px;
        img {
            height: 15px;
        }
    }
    ${Media('xlscreens')} {
        padding: 5.2vw 7.8vw 5.2vw 3.125vw;
        font-size: 2.03vw;
        span {
            right: 3.64vw;
            bottom: 1.56vw;
            img {
                height: 0.78vw;
            }
        }
    }
    ${Media('mobile')} {
        padding: 30px;
        font-size: 26px;
        span {
            right: 10px;
            bottom: 10px;
            img {
                height: 10px;
            }
        }
    }
    &:hover {
        color: ${colors.white['100']};
        img {
            animation: right 2s infinite;
        }
    }
    @keyframes right {
        0% {
            transform: translate(0);
        }
        20% {
            transform: translateX(15px);
        }
        40% {
            transform: translate(0);
        }
        60% {
            transform: translateX(15px);
        }
        100% {
            transform: translate(0);
        }
    }
`;
export default BuildingDigitalExp;
