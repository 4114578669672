import React from 'react';
import styled from 'styled-components';
import { Row } from '@bootstrap-styled/v4';
import Col from '@bootstrap-styled/v4/lib/Col';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import Media from 'shared-components/theme/medias';
import Video from 'shared-components/video/video';

const BannerMorphPill = (props) => {
    return (
        <Wrapper>
            <Row>
                <Col md="12">
                    <Controller>
                        <Scene
                            triggerHook="onLeave"
                            duration="50%"
                            offset="-500%"
                            indicators={false}>
                            <Timeline>
                                <Tween
                                    from={{
                                        width: '100vw',
                                        borderRadius: '0%',
                                    }}
                                    to={{
                                        width: '50vw',
                                        borderRadius: '25vw',
                                    }}>
                                    <IntroVideo>
                                        <Video {...props} />
                                    </IntroVideo>
                                </Tween>
                            </Timeline>
                        </Scene>
                    </Controller>
                </Col>
            </Row>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    height: 550px;
    ${Media('xlscreens')} {
        height: 55vw;
    }
    ${Media('mobile')} {
        height: 300px;
    }
`;

const IntroVideo = styled.div`
    width: calc(100vh - 82px);
    height: calc(100vh - 82px);
    border-radius: 50%;
    overflow: hidden;
    margin: 60px auto 0;
    ${Media('xlscreens')} {
        width: 67vw;
        height: 80vw;
        margin-top: 1.5vw;
    }
    ${Media('tablet')} {
        width: 100%;
    }
    ${Media('mobile')} {
        height: 270px;
    }
`;

export default BannerMorphPill;
