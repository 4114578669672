import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

const HomeBigTag = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.span`
    font-size: 34px;
    font-family: ${gilroySemibold};
    color: ${colors.pink['100']};
    ${Media('xlscreens')} {
        font-size: 2.3vw;
    }
    ${Media('mobile')} {
        font-size: 30px;
    }
`;

export default HomeBigTag;
