import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NotFoundPage from 'pages/not-found';
import Layout from 'components/layout';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import Loader from 'components/loader/loader';
import {
    Banner,
    LandingFormWrapper,
    LandingPageDesc,
} from 'components/webinars/landing/common';
import { gilroyBold, gilroySemibold } from 'shared-components/theme/fonts';
import colors from 'shared-components/theme/colors';
import MarketoForm from 'shared-components/marketo-form/marketo-form';
import ReactHtmlParser from 'react-html-parser';

const ResourcesIndex = () => {
    const router = useParams();
    const [pageError, setPageError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pageHeaderData, setPageHeaderData] = useState({});
    const [pageContentData, setPageContentData] = useState('');
    const [pageFormData, setPageFormData] = useState({});
    const [pageMobileData, setPageMobileData] = useState('');

    useEffect(() => {
        getData();
    }, []);

    const handlePostType = (type) => {
        switch (type) {
            case 'webinars':
                return 'webinars-landing';
            case 'ebooks':
                return 'ebooks-landing';
            case 'case-studies':
                return 'case-study-landing';
            case 'whitepapers':
                return 'whitepapers-landing';
            default:
                return '';
        }
    };

    const getData = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/landings-posts?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        post_type: handlePostType(router.type),
                        post_name: router.slug,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
                setPageError(true);
                setLoading(false);
            }
            const resData = await res.json();
            if (resData.status === 200 && resData.data[0].length !== 0) {
                setPageHeaderData(resData.data[0].metadata.header_banner);
                setPageContentData(
                    resData.data[0].metadata.page_content_desktop
                );
                setPageFormData(resData.data[0].metadata.form_content);
                setPageMobileData(
                    resData.data[0].metadata.header_banner_mobile
                );
                setLoading(false);
            } else {
                setPageError(true);
                setLoading(false);
            }
        } catch (err) {
            console.error(err);
            setPageError(true);
            setLoading(false);
        }
    };

    return (
        <>
            {loading && <Loader position="absolute" />}
            {pageError ? (
                <NotFoundPage />
            ) : (
                <Layout page="landingGatedForm">
                    <PageContainer>
                        <Banner
                            bgImage={
                                pageHeaderData.banner_background_image
                                    ? pageHeaderData.background_image
                                    : ''
                            }
                            bgImageMobile={
                                pageMobileData.banner_mobile_image
                                    ? pageMobileData.banner_image
                                    : ''
                            }
                            bgColor={
                                pageHeaderData.banner_background_color ||
                                pageMobileData.banner_background_color
                            }>
                            <BannerContent>
                                {pageHeaderData.banner_logos &&
                                    pageHeaderData.logo_list.length > 0 && (
                                        <ul>
                                            {pageHeaderData.logo_list.map(
                                                (item, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <img
                                                                src={item.logo}
                                                                alt="-"
                                                            />
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    )}
                                {ReactHtmlParser(pageHeaderData.banner_content)}
                            </BannerContent>
                        </Banner>
                        <Container fluid={true}>
                            <FormMainRow>
                                <CustomCol lg="5">
                                    <LandingPageDesc
                                        heading={pageContentData.page_heading}
                                        description={
                                            pageContentData.page_description
                                        }
                                        bullet_list={
                                            pageContentData.points_list
                                        }
                                        mobileContent={
                                            pageMobileData.page_content_mobile
                                        }
                                        textSize="small"
                                    />
                                </CustomCol>
                                <Col lg={{ size: 7 }}>
                                    <LandingFormWrapper
                                        title={pageFormData.heading}>
                                        <MarketoForm
                                            Id={pageFormData.marketo_code}
                                        />
                                    </LandingFormWrapper>
                                </Col>
                            </FormMainRow>
                        </Container>
                    </PageContainer>
                </Layout>
            )}
        </>
    );
};

const BannerContent = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    left: -50px;
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        margin: 0px 0 20px;
        li {
            padding: 2px 0;
            margin-right: 15px;
            padding-right: 15px;
            border-right: 1px solid ${colors.gray_light['40']};
            img {
                max-height: 22px;
                min-height: 21px;
                object-fit: contain;
            }
            &:last-child {
                margin-right: 0;
                padding-right: 0;
                border-right: none;
            }
        }
    }
    h3,
    h4 {
        font-size: 16px;
        line-height: 1;
        font-family: ${gilroyBold};
        color: ${colors.white['100']};
        text-align: center;
        text-transform: uppercase;
        margin-top: 0px;
        margin-bottom: 20px;
        letter-spacing: 4px;
    }
    h1,
    h2 {
        font-size: 35px;
        font-family: ${gilroySemibold};
        color: ${colors.white['100']};
        line-height: 1.2;
        letter-spacing: -0.32px;
        text-align: center;
        text-transform: capitalize;
        margin: 0;
    }
    ${Media('xlscreens')} {
        left: -2vw;
        top: -0.4vw;
        ul {
            margin: 0px 0 1.2vw;
            li {
                margin-right: 0.85vw;
                padding-right: 0.85vw;
                img {
                    max-height: 1.6vw;
                    min-height: 1.5vw;
                }
            }
        }
        h3,
        h4 {
            font-size: 0.78vw;
            letter-spacing: 0.4vw;
            margin-bottom: 1.1vw;
        }
        h1,
        h2 {
            font-size: 1.6vw;
            letter-spacing: -0.02vw;
        }
    }
    ${Media('tablet')} {
        left: 0;
        width: 80%;
        h3,
        h4 {
            font-size: 14px;
        }
        h1,
        h2 {
            font-size: 30px;
        }
    }
    ${Media('mobile')} {
        h1,
        h2 {
            font-size: 24px;
        }
        ul{
            flex-direction: column;
            li{
                border: none;
                margin-right: 0;
                padding-right: 0;
                margin-bottom: 10px;
                img{
                    width: 100%;
                }
            }
        }
    }
`;

const CustomCol = styled(Col)`
    ${Media('xlscreens')} {
        padding-left: 0px !important;
    }
`;

const PageContainer = styled.div`
    width: 100%;
    min-height: calc(100vh - 82px);
    margin-bottom: 70px;
    ${Media('xlscreens')} {
        min-height: calc(100vh - 5.4vw);
        margin-right: 50px;
    }
`;

const FormMainRow = styled(Row)`
    justify-content: center;
    ${Media('xlscreens')} {
        padding-left: 13vw;
        padding-right: 5vw;
    }
    ${Media('tablet')} {
        padding: 0px 30px;
    }
    ${Media('mobile')} {
        padding: 0;
    }
`;

export default ResourcesIndex;
