import React, { useEffect, useRef } from 'react';
import ProgressBar from 'progressbar.js';

import { Para } from '../cx-pocket-guide/para';
import { Col, Row } from '@bootstrap-styled/v4';
import { BigHeading } from '../cx-pocket-guide/big-heading';
import colors from 'shared-components/theme/colors';
import { akiraSuperBold } from 'shared-components/theme/fonts';
import styled from 'styled-components';
import { IntroPara } from './intro-para';
import Media from 'shared-components/theme/medias';
import { Source } from './source';

export const Point5 = ({ active }) => {
    const barProgress1 = useRef(null);
    const barProgress2 = useRef(null);
    const barProgress3 = useRef(null);

    const BarProgress = (element, barValue) => {
        if (element.current) {
            var bar = new ProgressBar.Line(element.current, {
                strokeWidth: 6,
                easing: 'easeInOut',
                duration: 1400,
                color: colors.pink['100'],
                trailColor: colors.cx_pocket_guide['pink'],
                trailWidth: 6,
                svgStyle: { width: '100%', height: '100%' },
                text: {
                    style: {
                        color: colors.pink['100'],
                        position: 'relative',
                        left: '0px',
                        top: '15px',
                        padding: 0,
                        margin: 0,
                        transform: null,
                        fontSize: '28px',
                        fontFamily: akiraSuperBold,
                    },
                    autoStyleContainer: false,
                },
                from: { color: colors.cx_pocket_guide['100'] },
                to: { color: colors.cx_pocket_guide['100'] },
                step: (state, bar) => {
                    bar.setText(Math.round(bar.value() * 100) + ' %');
                },
            });

            bar.animate(barValue);
        }
    };

    useEffect(() => {
        BarProgress(barProgress1, 0.35);
        BarProgress(barProgress2, 0.83);
        BarProgress(barProgress3, 0.19);
    }, [active]);

    return (
        <div>
            <Row>
                <Col lg="4">
                    <AnimationBlock>
                        <BarWrapper>
                            {active && <div ref={barProgress1} />}
                        </BarWrapper>
                        <IntroPara2>
                            Diverse companies are as much as 
                            <span>
                                35% more likely to outperform their competitors.
                            </span>
                        </IntroPara2>
                        <Source color={colors.white['100']} style2>
                            Source: mckinsey
                        </Source>
                    </AnimationBlock>
                </Col>
                <Col lg="4">
                    <AnimationBlock>
                        <BarWrapper>
                            {active && <div ref={barProgress2} />}
                        </BarWrapper>
                        <IntroPara2>
                            <span>83% of Millennials</span> are actively engaged
                            when they feel the company they’re working for
                            fosters an inclusive culture.
                        </IntroPara2>
                        <Source color={colors.white['100']} style2>
                            Source: deloitte
                        </Source>
                    </AnimationBlock>
                </Col>
                <Col lg="4">
                    <AnimationBlock>
                        <BarWrapper>
                            {active && <div ref={barProgress3} />}
                        </BarWrapper>
                        <IntroPara2>
                            Diverse companies produce&nbsp;
                            <span>19% more revenue.</span>
                        </IntroPara2>
                        <Source color={colors.white['100']} style2>
                            Source: forbes
                        </Source>
                    </AnimationBlock>
                </Col>
            </Row>
            <BigHeading color={colors.cx_pocket_guide['pink']} typical active={active}>
                In the last few years, businesses have finally come to the
                shocking realization that human talent comes in a dizzying array
                of forms and expressions.
            </BigHeading>
            <Para color={colors.white['100']}>
                And, surprise – people like being around – and interacting with
                - a variety of people. Multi-national, multi-ethnic, and a
                myriad of styles, culture, and expressions.
            </Para>
            <Para color={colors.white['100']}>
                This is how your CX teams should look – diverse, inclusive, and
                equitable, and ready for a diversity of customers with a
                complexity of experiences and needs.
            </Para>
        </div>
    );
};

const AnimationBlock = styled.div`
    ${Media('tablet')} {
        margin-bottom: 80px;
    }
`;

const IntroPara2 = styled(IntroPara)`
    margin-top: 30px;
    padding-right: 50px;
    ${Media('xlscreens')} {
        margin-top: 1.57vw;
        padding-right: 2.66vw;
    }
`;

const BarWrapper = styled.div`
    ${Media('xlscreens')} {
        width: 90%;
    }
    ${Media('tablet')} {
        height: 65px;
        width: 100%;
    }
`;
