import React, { Fragment, useEffect, useState } from 'react';
import { FaLinkedinIn } from 'react-icons/fa';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import {
    gilroyMedium,
    gilroyLight,
    gilroyBold,
} from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ContactForm from './contact-form/contact-form';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import fetch from 'cross-fetch';
import { baseURL, handleMenuTarget } from 'shared-components/theme/helpers';

const Footer = ({ vPage, noFooterForm }) => {
    const [footerMenu1, setFooterMenu1] = useState();
    const [footerMenu2, setFooterMenu2] = useState();
    const [footerMenu3, setFooterMenu3] = useState();
    const [footerTopMenu, setFooterTopMenu] = useState();

    useEffect(() => {
        getFooterMenu1();
        getFooterMenu2();
        getFooterMenu3();
        getFooterTopMenu();
    }, []);

    const getFooterMenu1 = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/menu?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        name: 'footer_col_1',
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                setFooterMenu1(resData.data);
            }
        } catch (err) {
            console.error(err);
        }
    };
    const getFooterMenu2 = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/menu?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        name: 'footer_col_2',
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                setFooterMenu2(resData.data);
            }
        } catch (err) {
            console.error(err);
        }
    };
    const getFooterMenu3 = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/menu?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        name: 'footer_col_3',
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                setFooterMenu3(resData.data);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const getFooterTopMenu = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/menu?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        name: 'footer_top_menu',
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                setFooterTopMenu(resData.data);
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Wrapper vpage={vPage}>
            <FooterBreadcrumb>
                <Container fluid={true}>
                    <Row>
                        <Col lg="12">
                            <p>
                                Innovation Guaranteed / Strategy Embedded /
                                Execution Delivered
                            </p>
                        </Col>
                    </Row>
                </Container>
            </FooterBreadcrumb>
            <Controller>
                <div>
                    <div>
                        <FooterMain>
                            <Controller>
                                <div>
                                    <div>
                                        <FooterCover>
                                            <Container fluid={true}>
                                                <Row>
                                                    <Col md="6">
                                                        <FooterUpper>
                                                            <h3>
                                                                Amazing CX for
                                                                amazing brands.
                                                            </h3>
                                                            <Copyright>
                                                                <p>
                                                                    Customer
                                                                    Engagement,
                                                                    Evolved.
                                                                </p>
                                                                <p>
                                                                    &copy;&nbsp;
                                                                    {new Date().getFullYear()}
                                                                    &nbsp;ibex
                                                                    Limited.&nbsp;
                                                                </p>
                                                            </Copyright>
                                                            {footerTopMenu &&
                                                                footerTopMenu.length >
                                                                    0 && (
                                                                    <ul>
                                                                        {footerTopMenu.map(
                                                                            (
                                                                                menuItem,
                                                                                index
                                                                            ) => {
                                                                                return (
                                                                                    <li
                                                                                        key={
                                                                                            index
                                                                                        }>
                                                                                        {handleMenuTarget(
                                                                                            menuItem
                                                                                        )}
                                                                                    </li>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </ul>
                                                                )}
                                                        </FooterUpper>
                                                    </Col>
                                                    <Col md="6">
                                                        {vPage ===
                                                            'landingGatedForm' ||
                                                        noFooterForm ? null : (
                                                            <ContactForm />
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="6" md="3">
                                                        <FooterLower>
                                                            <h3>ibex.</h3>
                                                            {footerMenu1 &&
                                                                footerMenu1.length >
                                                                    0 && (
                                                                    <ul>
                                                                        {footerMenu1.map(
                                                                            (
                                                                                menuItem,
                                                                                index
                                                                            ) => {
                                                                                return (
                                                                                    <li
                                                                                        key={
                                                                                            index
                                                                                        }>
                                                                                        {handleMenuTarget(
                                                                                            menuItem
                                                                                        )}
                                                                                    </li>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </ul>
                                                                )}
                                                        </FooterLower>
                                                    </Col>
                                                    <Col sm="6" md="3">
                                                        <FooterLower>
                                                            <h3>Solutions</h3>
                                                            {footerMenu2 &&
                                                                footerMenu2.length >
                                                                    0 && (
                                                                    <ul>
                                                                        {footerMenu2.map(
                                                                            (
                                                                                menuItem,
                                                                                index
                                                                            ) => {
                                                                                return (
                                                                                    <Fragment
                                                                                        key={
                                                                                            index
                                                                                        }>
                                                                                        <li>
                                                                                            {handleMenuTarget(
                                                                                                menuItem
                                                                                            )}
                                                                                        </li>
                                                                                        {index ===
                                                                                            2 && (
                                                                                            <Bar />
                                                                                        )}
                                                                                    </Fragment>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </ul>
                                                                )}
                                                        </FooterLower>
                                                    </Col>
                                                    <Col sm="6" md="3">
                                                        <FooterLower>
                                                            <h3>
                                                                Technologies
                                                            </h3>
                                                            {footerMenu3 &&
                                                                footerMenu3.length >
                                                                    0 && (
                                                                    <ul>
                                                                        {footerMenu3.map(
                                                                            (
                                                                                menuItem,
                                                                                index
                                                                            ) => {
                                                                                return (
                                                                                    <li
                                                                                        key={
                                                                                            index
                                                                                        }>
                                                                                        {handleMenuTarget(
                                                                                            menuItem
                                                                                        )}
                                                                                    </li>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </ul>
                                                                )}
                                                        </FooterLower>
                                                    </Col>
                                                    <Col sm="6" md="3">
                                                        <FooterSocial>
                                                            <ul>
                                                                <li>
                                                                    <a
                                                                        href="https://twitter.com/ibexWaveZero"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer">
                                                                        <img
                                                                            src={`${baseURL}assets/images/twitter-logo.svg`}
                                                                            alt="-"
                                                                        />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a
                                                                        href="https://www.linkedin.com/company/ibex-global"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer">
                                                                        <FaLinkedinIn />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </FooterSocial>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </FooterCover>
                                    </div>
                                </div>
                            </Controller>
                        </FooterMain>
                    </div>
                </div>
            </Controller>
        </Wrapper>
    );
};

const Wrapper = styled.footer`
    overflow: hidden;
    position: relative;
    padding-bottom: 30vh;
    ${Media('tablet')} {
        padding-bottom: 0;
    }
    ${Media('xlscreens')} {
        margin-top: ${(props) => {
            if (props.vpage == 'cx') {
                return '-110vh';
            } else {
                return '-90vh';
            }
        }};
    }
    padding-bottom: 200vh;
`;

const FooterBreadcrumb = styled.div`
    position: relative;
    z-index: 99;
    background-color: ${colors.gray_lighter['100']};
    border-top: 1px solid ${colors.gray_dark['10']};
    padding: 40px 0;
    p {
        margin: 0px;
        font-size: 20px;
        font-family: ${gilroyMedium};
        text-align: center;
    }
    ${Media('xlscreens')} {
        padding: 4vw 0 2.5vw;
        top: 90vh;
        p {
            font-size: 1.5vw;
        }
    }
    ${Media('mobile')} {
        font-size: 16px;
    }
`;

const FooterMain = styled.div`
    background-color: ${colors.white['100']};
    padding: 90px;
    width: 80%;
    margin-left: auto;
    box-sizing: border-box;

    ${Media('xlscreens')} {
        overflow: hidden;
        position: absolute;
        height: 100vh;
        bottom: 0px;
        right: 0px;
        z-index: 98;
        width: 82vw;
        padding: 4vw 8vw;
        padding-right: 8vw;
        .container-fluid {
            transform: scale(0.88);
        }
    }
    ${Media('tablet')} {
        width: 100%;
        height: 100% !important;
        padding: 60px 20px;
    }
`;

const FooterCover = styled.div`
    transition-duration: 0.5s;
`;

const FooterUpper = styled.div`
    h3 {
        font-size: 39px;
        font-family: ${gilroyBold};
        margin: 0;
        line-height: 1.2;
        ${Media('xlscreens')} {
            font-size: 2.9vw;
        }
        ${Media('tablet')} {
            font-size: 30px;
        }
    }
    ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        display: flex;
        align-items: center;
        li {
            margin-right: 25px;
            &:last-child {
                margin-right: 0;
            }
            a {
                font-size: 12px;
                font-family: ${gilroyBold};
                transition-duration: 0.3s;
                ${Media('xlscreens')} {
                    font-size: 1vw;
                }
            }
        }
        ${Media('tablet')} {
            margin-bottom: 30px;
        }
    }
    ${Media('xlscreens')} {
        min-height: 26vw;
    }
`;

const Copyright = styled.div`
    margin: 50px 0px;
    p {
        font-size: 12px;
        font-family: ${gilroyLight};
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
        ${Media('xlscreens')} {
            font-size: 1vw;
        }
    }
`;

const FooterLower = styled.div`
    padding-top: 40px;
    h3 {
        font-size: 19px;
        font-family: ${gilroyBold};
        margin: 0 0 20px;
        line-height: 1.2;
        ${Media('xlscreens')} {
            font-size: 1.5vw;
        }
    }
    ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        li {
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0px;
            }
            a {
                font-size: 12px;
                font-family: ${gilroyLight};
                line-height: 1.5;
                transition-duration: 0.3s;
                opacity: 0.42;
                &:hover {
                    opacity: 1;
                }
            }
            ${Media('xlscreens')} {
                margin-bottom: 0.4vw;
                a {
                    font-size: 1vw;
                }
            }
            ${Media('tablet')} {
                a {
                    font-size: 13px;
                }
            }
        }
    }
`;
const Bar = styled.div`
    height: 2px;
    width: 40%;
    background-color: ${colors.gray['border']};
    margin: 20px 0px;
`;
const FooterSocial = styled(FooterLower)`
    ul {
        display: flex;
        align-items: center;
        li {
            margin-right: 15px;
            &:last-child {
                margin-right: 0px;
            }
            a {
                opacity: 1;
                font-size: 14px;
                img {
                    height: 20px;
                }
                ${Media('xlscreens')} {
                    img {
                        height: 1.04vw;
                    }
                }
                ${Media('large')} {
                    font-size: 20px;
                }
            }
        }
    }
`;

export default Footer;
