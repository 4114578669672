import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';

const GoalBox = ({ icon, desc }) => {
    return (
        <ScrollAnimation animateIn="fadeInUp">
            <Wrapper>
                <ImageWrapper>
                    <img src={icon} alt="-" />
                </ImageWrapper>
                <Desc>{ReactHtmlParser(desc)}</Desc>
            </Wrapper>
        </ScrollAnimation>
    );
};
const Wrapper = styled.div`
    border: 1px solid ${colors.gray['border']};
    background-color: ${colors.white['100']};
    height: 135px;
    display: flex;
    align-items: center;
    ${Media('xlscreens')} {
        height: 7.06vw;
    }
    ${Media('tablet')} {
        margin: 10px 0px;
    }
    ${Media('mobile')} {
        height: auto;
        padding: 30px 0;
        padding-right: 20px;
    }
`;
const ImageWrapper = styled.div`
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-width: 27px;
        max-height: 25px;
    }
    ${Media('xlscreens')} {
        img {
            max-width: 1.41vw;
            max-height: 1.3vw;
        }
    }
`;
const Desc = styled.div`
    flex: 1;
    font-size: 24px;
    font-family: ${gilroyMedium};
    padding-right: 10px;
    ${Media('xlscreens')} {
        padding-right: 1.04vw;
        font-size: 1.25vw;
    }
    ${Media('mobile')} {
        font-size: 18px;
        br {
            display: none;
        }
    }
`;
export default GoalBox;
