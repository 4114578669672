import React from 'react';
import styled from 'styled-components';
import { Col, Row } from '@bootstrap-styled/v4';
import { Parallax } from 'react-scroll-parallax';
import { MdArrowForward } from 'react-icons/md';
import ReactHtmlParser from 'react-html-parser';
import Media from 'shared-components/theme/medias';
import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';

const SRMIntroSection = ({
    preheading,
    heading,
    para,
    mainImg,
    textureImg,
    textureimgHeight,
    textureImgPosition,
    btnBgColor,
    onBtnClick,
    customImage,
}) => {
    return (
        <>
            <Wrapper id="home">
                <Row>
                    <Col lg="5">
                        <IntroText>
                            <SubHeading>
                                {ReactHtmlParser(preheading)}
                            </SubHeading>
                            <MainHeading>
                                {ReactHtmlParser(heading)}
                            </MainHeading>
                            <IntroPara>{ReactHtmlParser(para)}</IntroPara>
                            <DemoButtonWrap>
                                <DemoButton onClick={onBtnClick}>
                                    <ButtonText bgColor={btnBgColor}>
                                        Book a Demo
                                    </ButtonText>
                                    <ButtonIcon color={btnBgColor}>
                                        <MdArrowForward />
                                    </ButtonIcon>
                                </DemoButton>
                            </DemoButtonWrap>
                        </IntroText>
                    </Col>
                    <Col lg="7">
                        {!customImage ? (
                            <IntroImg>
                                <img src={mainImg} alt="-" />
                                <Texturebgimg
                                    x={[5, -5]}
                                    position={textureImgPosition}
                                    height={textureimgHeight}>
                                    <img src={textureImg} alt="-" />
                                </Texturebgimg>
                                <LandingBlackBg x={[-10, 10]}>
                                    <img
                                        src={`${baseURL}assets/images/landing-black-bg.svg`}
                                        alt="-"
                                    />
                                </LandingBlackBg>
                            </IntroImg>
                        ) : (
                            customImage
                        )}
                    </Col>
                </Row>
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    overflow: hidden;
    ${Media('xlscreens')} {
        padding-left: 13vw;
        margin-top: 2vw;
        padding-bottom: 11.5vw;
    }
    ${Media('tablet')} {
        margin-bottom: 50px;
    }
`;
const IntroText = styled.div`
    max-width: 75%;
    ${Media('tablet')} {
        margin: 0 auto;
    }
    ${Media('mobile')} {
        max-width: 100%;
    }
`;
const SubHeading = styled.div`
    font-size: 25px;
    font-family: ${gilroySemibold};
    margin-bottom: 60px;
    ${Media('xlscreens')} {
        font-size: 1.3vw;
        margin-bottom: 3.125vw;
        margin-top: 3vw;
    }
    ${Media('tablet')} {
        text-align: center;
        margin: 30px 0px;
    }
    ${Media('mobile')} {
        font-size: 16px;
    }
`;
const MainHeading = styled.div`
    font-size: 60px;
    font-family: ${gilroyBold};
    letter-spacing: -1.4px;
    line-height: 1.15;
    ${Media('xlscreens')} {
        font-size: 3.12vw;
    }
    ${Media('tablet')} {
        text-align: center;
        font-size: 45px;
    }
    ${Media('mobile')} {
        font-size: 35px;
    }
`;
const IntroPara = styled.div`
    font-size: 20px;
    font-family: ${gilroyMedium};
    line-height: 1.8;
    margin-top: 30px;
    margin-bottom: 60px;
    ${Media('xlscreens')} {
        font-size: 1vw;
        margin-top: 1.57vw;
        margin-bottom: 3.12vw;
    }
    ${Media('tablet')} {
        text-align: center;
    }
    ${Media('mobile')} {
        font-size: 14px;
        padding: 0px 40px;
        br {
            display: none;
        }
    }
`;
const DemoButtonWrap = styled.div`
    ${Media('tablet')} {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
`;
const ButtonText = styled.div`
    font-size: 23px;
    font-family: ${gilroyBold};
    color: ${colors.white['100']};
    background-color: ${(props) =>
        props.bgColor ? props.bgColor : colors.pink['100']};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.black['100']};
    width: 80%;
    ${Media('xlscreens')} {
        font-size: 1.19vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
`;
const ButtonIcon = styled.div`
    height: 100%;
    background-color: ${colors.black['100']};
    font-size: 30px;
    color: ${(props) => (props.color ? props.color : colors.pink['100'])};
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    ${Media('xlscreens')} {
        font-size: 1.56vw;
    }
`;
const DemoButton = styled.div`
    height: 50px;
    width: 230px;
    display: flex;
    cursor: pointer;
    user-select: none;
    @keyframes arrow {
        0% {
            transform: translate(0);
        }
        20% {
            transform: translateX(5px);
        }
        40% {
            transform: translate(0);
        }
        60% {
            transform: translateX(5px);
        }
        100% {
            transform: translate(0);
        }
    }
    &:hover {
        ${ButtonIcon} {
            svg {
                animation: arrow 2s infinite;
            }
        }
    }
    ${Media('xlscreens')} {
        height: 2.6vw;
        width: 11.9vw;
    }
    ${Media('tablet')} {
        height: 35px;
        width: 200px;
    }
`;
const IntroImg = styled.div`
    height: 600px;
    position: relative;
    right: 20px;
    z-index: 1;
    .parallax-inner {
        height: 100%;
    }
    img {
        height: 100%;
    }
    ${Media('xlscreens')} {
        height: 30.6vw;
        right: 1.04vw;
    }
    ${Media('tablet')} {
        width: 100%;
        height: unset;
        right: 0px;
        transform: scale(0.8);
        img {
            height: auto;
            width: 100%;
        }
    }
`;

const Texturebgimg = styled(Parallax)`
    left: 520px;
    position: absolute;
    bottom: -110px;
    z-index: ${(props) => {
        if (props.position === 'front') {
            return '1';
        } else if (props.position === 'middle') {
            ('-1');
        } else {
            return '-3';
        }
    }};
    height: ${(props) => (props.height ? `${props.height}px` : '700px')};
    img {
        height: 100%;
    }
    ${Media('xlscreens')} {
        left: 20vw;
        bottom: -11.5vw;
        height: ${(props) =>
            props.height ? `${props.height / 19.2}vw` : '36.4vw'};
    }
    ${Media('tablet')} {
        bottom: -75px;
        height: unset;
        left: 360px;
        top: initial;
        img {
            width: auto;
            height: 300px;
        }
    }
`;

const LandingBlackBg = styled(Parallax)`
    left: 86px;
    position: absolute;
    top: 73px;
    z-index: -2;
    height: 580px;
    img {
        height: 100%;
    }
    ${Media('xlscreens')} {
        height: 30.2vw;
        left: 4.47vw;
        top: 3.8vw;
    }
    ${Media('tablet')} {
        height: unset;
        left: 50px;
        top: 70px;
        img {
            height: auto;
            width: 100%;
        }
    }
`;

export default SRMIntroSection;
