import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const EbookFeatureBoxList = ({ number, title, children }) => {
    return (
        <Wrapper>
            <ImageIcon>
                <span>{number}</span>
            </ImageIcon>
            <Content>
                <Title>{title}</Title>
                {children}
            </Content>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    margin: 30px 0px;
    ${Media('xlscreens')} {
        margin: 2vw 0;
    }
`;

const ImageIcon = styled.div`
    flex: 0 0 81px;
    max-width: 81px;
    height: 81px;
    margin-right: 40px;
    border-radius: 81px;
    background-color: ${colors.ebook.seasonal['pink']};
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        font-size: 36px;
        font-family: ${gilroyBold};
        color: ${colors.ebook.seasonal['main']};
        ${Media('xlscreens')} {
            font-size: 1.8vw;
        }
    }
    ${Media('xlscreens')} {
        flex: 0 0 4.24vw;
        max-width: 4.24vw;
        height: 4.24vw;
        border-radius: 4.24vw;
        margin-right: 2vw;
    }
    ${Media('mobile')} {
        flex: 0 0 70px;
        max-width: 70px;
        height: 70px;
        margin-right: 20px;
        span {
            font-size: 21px;
        }
    }
`;

const Content = styled.div`
    flex: 1 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Title = styled.h3`
    font-size: 24px;
    font-family: ${gilroyBold};
    font-weight: normal;
    color: ${colors.ebook.seasonal['main']};
    margin-top: 25px;
    margin-bottom: 30px;
    ${Media('xlscreens')} {
        font-size: 1.46vw;
        margin-bottom: 1.5vw;
        margin-top: 1.2vw;
    }
    ${Media('mobile')} {
        margin-top: 18px;
        margin-bottom: 10px;
    }
`;
