import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import {
    GlossaryBanner,
    GlossaryMainHeading,
    GlossaryMainPara,
    GlossaryTabMenu,
    GlossaryTerm,
} from './common';
import Layout from 'components/layout';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import Loader from 'components/loader/loader';

const Glossary = () => {
    const [loading, setLoading] = useState(true);
    const [glossaryData, setGlossaryData] = useState([]);
    const [glossaryRawData, setGlossaryRawData] = useState([]);

    const alphabetArr = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
    ];

    const getGlossaryData = async () => {
        const getKey = window.location.search;
        const refinedKey = getKey.replace('?key=', '');
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/glossary?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        order: 'asc',
                        per_page: 1000,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData.status === 200 && resData.data.length !== 0) {
                setLoading(false);
                setGlossaryRawData(resData.data);
                if (refinedKey !== '') {
                    searchData(resData.data, refinedKey);
                } else {
                    filteredData(resData.data);
                }
            }
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        getGlossaryData();
    }, []);

    const handleSearch = (key) => {
        searchData(glossaryRawData, key);
    };

    const searchData = (data, key) => {
        const newKey = key.toLowerCase();
        const searchedData = data.filter((value) => {
            var title = value.post_title.toLowerCase();
            return title.includes(newKey);
        });
        filteredData(searchedData);
    };

    const filteredData = (data) => {
        var newAlph = [];
        data.filter((item) => newAlph.push(item.post_terms[0].name));
        const newAlphStr = newAlph.filter((item, index) => {
            return newAlph.indexOf(item) === index;
        });
        const newStructure = newAlphStr.map((item) => [
            item,
            data.filter((list) => list.post_terms[0].name === item),
        ]);
        setGlossaryData(newStructure);
    };

    return (
        <Layout>
            <GlossaryBanner onSearch={handleSearch} mainPage />

            <TabsContent>
                <GlossaryTabMenu data={alphabetArr} />

                {loading && <Loader />}

                <Container>
                    <Row>
                        <Col sm="12">
                            {glossaryData &&
                                glossaryData.length > 0 &&
                                glossaryData.map((item, index) => {
                                    return (
                                        <ContentWrapper
                                            key={index}
                                            id={item[0]}
                                            last={
                                                index ===
                                                glossaryData.length - 1
                                            }>
                                            <GlossaryMainHeading fontWeight="semibold">
                                                {item[0]}
                                            </GlossaryMainHeading>

                                            <Row>
                                                {item[1] &&
                                                item[1].length > 0 ? (
                                                    item[1].map(
                                                        (termItem, ind) => {
                                                            return (
                                                                <Col
                                                                    sm="12"
                                                                    md="6"
                                                                    lg="4"
                                                                    key={ind}>
                                                                    <GlossaryTerm
                                                                        term={
                                                                            termItem.post_title
                                                                        }
                                                                        link={
                                                                            termItem.post_name
                                                                        }
                                                                    />
                                                                </Col>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <Col sm="12">
                                                        <GlossaryMainPara>
                                                            No Data Found
                                                        </GlossaryMainPara>
                                                    </Col>
                                                )}
                                            </Row>
                                        </ContentWrapper>
                                    );
                                })}
                        </Col>
                    </Row>
                </Container>
            </TabsContent>
        </Layout>
    );
};

const TabsContent = styled.div`
    position: relative;
`;

const ContentWrapper = styled.div`
    padding: 30px 0px;
    border-bottom: ${(props) =>
        props.last ? 'none' : `1px solid ${colors.gray['border']}`};
    ${Media('xlscreens')} {
        padding: 4vw 0 3vw;
    }
`;

export default Glossary;
