import React from 'react';
import styled from 'styled-components';
import Layout from 'components/layout';
import { Container, Col } from '@bootstrap-styled/v4';
import colors from 'shared-components/theme/colors';
import { gilroyMedium, gilroySemibold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';
import { FaLongArrowAltRight, FaCheckCircle } from 'react-icons/fa';
import { RowContentCenter } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';
import { Helmet } from 'react-helmet-async';
import { baseURLPage } from 'shared-components/theme/helpers';

const ThankYou = () => {
    return (
        <Layout>
            <Helmet>
                {/* Event snippet for Lead Form */}
                <script async>{`
                    function gtag_report_conversion(url) {
                            var callback = function () {
                                if (typeof(url) != 'undefined') {
                                window.location = url;
                                }
                            };
                            gtag('event', 'conversion', {
                                'send_to': 'AW-1040627358/iUafCPDQnZUBEJ7tmvAD',
                                'event_callback': callback
                            });
                            return false;
                        }
                `}</script>
            </Helmet>
            <SuccessMessage>
                <FaCheckCircle color={colors.bullet_green['100']} />
                <h1>Thank you!</h1>
                <p>
                    Your information has been received, and we’ll be in touch
                    shortly.
                </p>
            </SuccessMessage>
            <DiveDeeper>
                <h3>Dive deeper into ibex:</h3>
                <Container>
                    <RowContentCenter>
                        <Col lg="3" md="6">
                            <LinkCard styleTwo>
                                <img
                                    src={`${baseURL}assets/images/dive-deeper-1.jpg`}
                                    alt="-"
                                />
                                <h4>Moving More Money, Faster:</h4>
                                <p>Improving AHT & CX for Western Union.</p>
                                <Linkbottom>
                                    <a
                                        href={`${baseURLPage}resources/case-studies/g/western-union-digital-transformation`}>
                                        Take Me To It
                                    </a>
                                    <FaLongArrowAltRight
                                        color={colors.pink['100']}
                                    />
                                </Linkbottom>
                            </LinkCard>
                        </Col>
                        <Col lg="3" md="6">
                            <LinkCard styleTwo>
                                <img
                                    src={`${baseURL}assets/images/dive-deeper-2.jpg`}
                                    alt="-"
                                />
                                <h4>Hyper Growth With Postmates</h4>
                                <p>
                                    Managing CX Volume Spikes & Marketplace
                                    Complexity
                                </p>
                                <Linkbottom>
                                    <a
                                        href={`${baseURLPage}resources/case-studies/g/postmates-managing-cx-volume`}>
                                        Take Me To It
                                    </a>
                                    <FaLongArrowAltRight
                                        color={colors.pink['100']}
                                    />
                                </Linkbottom>
                            </LinkCard>
                        </Col>
                        <Col lg="3" md="6">
                            <LinkCard styleTwo>
                                <img
                                    src={`${baseURL}assets/images/dive-deeper-3.jpg`}
                                    alt="-"
                                />
                                <h4>BPO Predictions for 2021</h4>
                                <p>
                                    we’ve asked our internal BPO experts -
                                    what’s next for 2021?
                                </p>
                                <Linkbottom>
                                    <a href={`${baseURLPage}join-us`}>Show Me!</a>
                                    <FaLongArrowAltRight
                                        color={colors.pink['100']}
                                    />
                                </Linkbottom>
                            </LinkCard>
                        </Col>
                    </RowContentCenter>
                </Container>
            </DiveDeeper>
        </Layout>
    );
};

const LinkCard = styled.div`
    background-color: ${colors.white['100']};
    border-radius: 10px;
    height: 310px;
    width: 320px;
    margin-top: 50px;
    padding: 30px 40px;
    img {
        height: 70px;
        width: 70px;
        object-fit: cover;
    }
    h4 {
        font-size: 18px;
        font-family: ${gilroySemibold};
        margin: 10px 0;
    }
    p {
        margin-top: 0;
        font-size: 18px;
        font-family: ${gilroyMedium};
        line-height: 1.6;
    }
    ${(props) => {
        if (props.styleTwo) {
            return `
                    height:368px;
                    img{
                        height:118px;
                        width:100%;
                        border-radius:8px;
                        margin-bottom:25px;
                    }
                `;
        }
    }}
    ${Media('xlscreens')} {
        border-radius: 0.53vw;
        height: 16.14vw;
        width: 16.66vw;
        margin-top: 2.66vw;
        padding: 1.56vw 2.08vw;
        img {
            height: 3.64vw;
            width: 3.64vw;
        }
        h4 {
            font-size: 0.93vw;
            margin: 0.53vw 0;
        }
        p {
            font-size: 0.93vw;
        }
        ${(props) => {
            if (props.styleTwo) {
                return `
                        height:19.16vw;
                        img{
                            height:6.14vw;
                            border-radius:0.41vw;
                            margin-bottom:1.30vw;
                            width:100%;
                        }
                    `;
            }
        }}
    }
    ${Media('tablet')} {
        width: 100%;
        margin-top: 15px;
    }
`;
const Linkbottom = styled.div`
    display: flex;
    align-items: center;
    a {
        font-size: 18px;
        font-family: ${gilroySemibold};
        text-decoration: underline;
    }
    svg {
        margin-left: 20px;
        font-size: 14px;
    }
    ${Media('xlscreens')} {
        a {
            font-size: 0.93vw;
        }
        svg {
            margin-left: 1.04vw;
            font-size: 0.72vw;
        }
    }
`;
const SuccessMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 20px;
    text-align: center;
    height: 90vh;
    box-sizing: border-box;
    svg {
        height: 50px;
        width: 50px;
    }
    h1 {
        font-size: 45px;
        font-family: ${gilroySemibold};
    }
    p {
        font-size: 24px;
        font-family: ${gilroyMedium};
    }
    ${Media('xlscreens')} {
        padding: 3.125vw 0;
        svg {
            height: 2.66vw;
            width: 2.66vw;
        }
        h1 {
            font-size: 2.34vw;
        }
        p {
            font-size: 1.25vw;
        }
    }
    ${Media('tablet')} {
        padding: 40px 20px;
        svg {
            height: 40px;
            width: 40px;
        }
        h1 {
            font-size: 36px;
        }
        p {
            font-size: 18px;
            margin-top: 0;
        }
    }
`;
const DiveDeeper = styled.div`
    display: none;
    padding: 50px 0;
    background-color: ${colors.gray_lighter['lighter']};
    h3 {
        font-size: 24px;
        font-family: ${gilroySemibold};
        text-align: center;
    }
    ${Media('xlscreens')} {
        padding: 2.66vw 0;
        h3 {
            font-size: 1.25vw;
        }
    }
`;

export default ThankYou;
