import React from 'react';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';

export const ScrollWrapper = ({ children, marginTop, id }) => {
    return (
        <Wrapper marginTop={marginTop} id={id}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    ${Media('xlscreens')} {
        padding-top: ${(props) => (props.marginTop ? '1.57vw' : '0')};
    }
`;
