import React from 'react';
import { gilroyBold } from 'shared-components/theme/fonts';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { RiPlayCircleLine } from 'react-icons/ri';
import ReactHtmlParser from 'react-html-parser';
import { baseURLPage } from 'shared-components/theme/helpers';

const BlogCard = ({
    cardImg,
    cardTitle,
    cardDesc,
    featurePost,
    blogTerm,
    isVideo,
    postName,
    externalURL,
}) => {
    return (
        <Wrapper featurePost={featurePost}>
            <CardImg featurePost={featurePost}>
                <a
                    href={
                        externalURL
                            ? externalURL
                            : `${baseURLPage}resources/blogs/` + postName
                    }
                    target={externalURL && '_blank'}>
                    <img src={cardImg} />
                    {blogTerm && (
                        <FeatureTag
                            bgColor={blogTerm.metadata.category_bg_color}>
                            {ReactHtmlParser(blogTerm.name)}
                        </FeatureTag>
                    )}
                    {isVideo && (
                        <VideoPlayIcon>
                            <RiPlayCircleLine color={colors.white['100']} />
                        </VideoPlayIcon>
                    )}
                </a>
            </CardImg>
            <CardDetails>
                <a
                    href={
                        externalURL
                            ? externalURL
                            : `${baseURLPage}resources/blogs/` + postName
                    }
                    target={externalURL && '_blank'}>
                    <h3>{ReactHtmlParser(cardTitle)}</h3>
                </a>
                <p>{ReactHtmlParser(cardDesc)}</p>
            </CardDetails>
        </Wrapper>
    );
};
const CardImg = styled.div`
    overflow: hidden;
    position: relative;
    height: ${(props) => (props.featurePost ? '320px' : '220px')};
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 2s ease-in-out;
    }
    ${Media('xlscreens')} {
        height: ${(props) => (props.featurePost ? '16.75vw' : '11.5vw')};
    }
`;
const VideoPlayIcon = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        font-size: 70px;
        transition: all 0.3s ease;
    }
    ${Media('xlscreens')} {
        svg {
            font-size: 3.64vw;
        }
    }
`;
const CardDetails = styled.div`
    h3 {
        font-size: 20px;
        font-family: ${gilroyBold};
        transition: all 0.3s ease;
    }
    p {
        font-size: 14px;
        line-height: 1.4;
        color: ${colors.gray['100']};
    }
    ${Media('xlscreens')} {
        h3 {
            font-size: 1.33vw;
        }
        p {
            font-size: 0.92vw;
        }
    }
`;
const Wrapper = styled.div`
    width: 100%;
    margin: ${(props) => (props.featurePost ? '0px 0px' : '30px 0px')};
    &:hover {
        ${CardImg} {
            img {
                transform: scale(1.2);
            }
        }
        ${VideoPlayIcon} {
            svg {
                transform: scale(1.3);
            }
        }
        ${CardDetails} {
            h3 {
                color: ${colors.pink['100']};
            }
        }
    }
    ${Media('xlscreens')} {
        margin: ${(props) => (props.featurePost ? '0px 0px' : '1.57vw 0px')};
    }
    ${Media('tablet')} {
        margin: 0px;
        margin-bottom: 50px;
    }
`;
const FeatureTag = styled.span`
    position: absolute;
    left: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 11px;
    display: inline-flex;
    padding: 3px 20px;
    color: ${colors.white['100']};
    background-color: ${(props) =>
        props.bgColor ? props.bgColor : colors.pink['100']};
    ${Media('xlscreens')} {
        padding: 0.15vw 1.04vw;
    }
`;
export default BlogCard;
