import React, { Fragment, useEffect, useState } from 'react';
import Layout from 'components/layout';
import styled from 'styled-components';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import colors from 'shared-components/theme/colors';
import { baseURL } from 'shared-components/theme/helpers';
import {
    gilroyLight,
    gilroyMedium,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import ReactHtmlParser from 'react-html-parser';
import { LetsDiscuss } from 'components/industries/common';
import SlickSlider from 'shared-components/slider/slider';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import Loader from 'components/loader/loader';
import Moment from 'react-moment';
import Button from 'shared-components/button/button';
import HeadingTag from 'components/heading-tag/heading-tag';

const AI = () => {
    const [aiBlogs, setAIBlogs] = useState([]);
    const [loader, setLoader] = useState(true);

    const settingsBlog = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        centerMode: false,
        centerPadding: '0px',
        focusOnSelect: false,
        autoplay: false,
        autoplaySpeed: 3000,
        initialSlide: 0,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const seamless_cx = [
        {
            icon: `${baseURL}assets/images/ai-assets/heart-icon.svg`,
            heading: 'Supercharge CX Productivity',
        },
        {
            icon: `${baseURL}assets/images/ai-assets/hit.svg`,
            heading: 'Harness Real-Time CX Insights',
        },
        {
            icon: `${baseURL}assets/images/ai-assets/star.svg`,
            heading: 'Accelerate CX Agility',
        },
        {
            icon: `${baseURL}assets/images/ai-assets/lock.svg`,
            heading: 'Enhance CX Privacy & Security',
        },
    ];

    const cx_experience = [
        {
            icon: `${baseURL}assets/images/wave-ix/cx-interactions-suite-icon.svg`,
            title: 'Agent',
            heading: `The Agent <br /> Performance Suite`,
            para: `The leading AI suite for agent productivity and workflow enhancement. Integrates across the agent experience to drive enhanced outcomes for every agent action. `,
            cxExperienceLists: [
                'Total Agent Performance Management',
                'Hyper-personalized Agent Engagement',
                'AI Conversational Assistance, Coaching, & Optimization ',
                'Real-Time Language Translation',
            ],
            videoURL: `${baseURL}assets/images/wave-ix/anim-1.mp4`,
        },

        {
            icon: `${baseURL}assets/images/wave-ix/cx-insights-suite-icon.svg`,
            title: 'Customer',
            heading: `The Customer <br /> Interactions Suite`,
            para: `AI technology that boosts every interaction along the customer journey, using the latest in Generative AI.`,
            cxExperienceLists: [
                'LLM Tailored to Your Brand',
                'Virtual Agents & Conversational AI',
                'AI Voice Engagement',
                'Advanced NLP',
            ],
            videoURL: `${baseURL}assets/images/wave-ix/anim-3.mp4`,
        },

        {
            icon: `${baseURL}assets/images/wave-ix/agent-performance-suite-icon.svg`,
            title: 'Insights',
            heading: `The CX <br />Insights Suite`,
            para: `An end-to-end insights layer measuring real-time CX interaction data supported by a Business Intelligence team surfacing insights that drive business forward. `,
            cxExperienceLists: [
                '100% Interaction & Data Capture',
                'Real-Time Analysis, QA, and Action',
                'Predictive, Brand-Tailored Insights',
                'Continuously Learning Knowledge Base',
            ],
            videoURL: `${baseURL}assets/images/wave-ix/anim-2.mp4`,
        },
    ];

    const performance = [
        {
            title: 'Omnichannel AI Support',
            para: 'AI optimized to enable customers to seamlessly switch between channels without losing conversational history or context.',
        },
        {
            title: 'Enhanced Personalization',
            para: 'Use chatbots or virtual assistants to offer tailored recommendations, product suggestions, and relevant content based on a seamless analysis of customer engagement and purchase history.',
        },
        {
            title: 'Proactive AI Support',
            para: 'Anticipate customer needs based on engagement and purchase history, and offer proactive personalized support to enhance their experience.',
        },
        {
            title: 'Enhanced Self-Service',
            para: 'Use AI chatbots or virtual assistants to guide customers through self-service options and troubleshoot common issues.',
        },
    ];

    const guide = [
        {
            icon: `${baseURL}assets/images/ai-assets/guide-icon-1.svg`,
            title: 'Human + AI',
            para: 'Foster a seamless collaboration between AI systems and human agents. Provide real-time suggestions or knowledge to human agents during customer interactions to enhance accuracy and customer satisfaction.',
        },
        {
            icon: `${baseURL}assets/images/ai-assets/guide-icon-2.svg`,
            title: 'Big NLP',
            para: 'Train your AI using Natural Language Processing based on big data sets for highly accurate customer engagement across chatbots, automated email responses, & voice assistants.',
        },
        {
            icon: `${baseURL}assets/images/ai-assets/guide-icon-3.svg`,
            title: 'AI Sentiment Analysis',
            para: 'Understand customer sentiment in real-time. Monitor social media mentions, customer feedback, and support interactions to identify trends and proactively address issues before they escalate.',
        },
        {
            icon: `${baseURL}assets/images/ai-assets/guide-icon-4.svg`,
            title: 'Intelligent Routing',
            para: 'Match customer inquiries with agent expertise or AI system capabilities, reducing response times and enhancing customer satisfaction.',
        },
        {
            icon: `${baseURL}assets/images/ai-assets/guide-icon-5.svg`,
            title: 'Continuous Learning',
            para: 'Continuously train and improve AI systems using real-time customer data. Leverage machine learning techniques to analyze customer interactions, identify patterns, and make predictions to meet changing customer needs and preferences.',
        },
        {
            icon: `${baseURL}assets/images/ai-assets/guide-icon-6.svg`,
            title: 'Privacy & Data Security',
            para: 'Ensure compliance with privacy regulations and prioritize data security. Protect customer data and build trust with customers by clearly communicating your privacy policies and obtaining explicit consent for data usage.',
        },
    ];

    const clientBoxInfo = [
        {
            para: (
                <Fragment>
                    2024 Artificial Intelligence Excellence Award for Wave iX{' '}
                    <br />
                </Fragment>
            ),
            imgURL: `${baseURL}assets/images/wave-ix/business-inteloiggence-group-color2.svg`,
            imgWidth: '100%',
        },
        {
            para: (
                <Fragment>
                    2024 Customer Experience Innovation Award for Wave iX
                </Fragment>
            ),
            imgURL: `${baseURL}assets/images/wave-ix/customer-award.svg`,
            imgWidth: '35%',
        },
        {
            para: (
                <Fragment>
                    2023 <br />
                    Most Innovative <br /> Company
                </Fragment>
            ),
            imgURL: `${baseURL}assets/images/wave-ix/titan-award.svg`,
            imgWidth: '35%',
        },
    ];

    const scrollNavData = [
        {
            name: 'intro',
            target: 'intro',
            offset: -100,
        },

        {
            name: 'cx_experience',
            target: 'cx_experience',
            offset: -100,
        },
        {
            name: 'performance',
            target: 'performance',
            offset: -100,
        },
        {
            name: 'guide',
            target: 'guide',
            offset: 0,
        },
    ];

    const ai_journey = [
        {
            title: 'TAILORED SOLUTIONING',
            desc: 'We don’t make your problem fit our product – we learn your challenge and design custom solutions to fit your needs.',
        },
        {
            title: 'RIGOROUS TESTING',
            desc: 'We test, test, and test before any live implementation to ensure your quality and security standards are met.',
        },
        {
            title: 'SEAMLESS IMPLEMENTATION',
            desc: 'Our launch and implementation teams ensure timely and smooth solution deployment that hits KPIs fast.',
        },
        {
            title: 'CONTINUOUS INSIGHTS',
            desc: 'Before, during, and after launch our Business Intelligence team continuously surfaces actionable program insights to keep building on your success.',
        },
    ];

    const getBlogs = async () => {
        setLoader(true);
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/blog?per_page=10&category_slug=ai&' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                    })
            );
            setLoader(false);
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                setAIBlogs(resData.data);
            }
        } catch (err) {
            setLoader(false);
            console.error(err);
        }
    };

    const engage = [
        {
            title: 'Chat',
            icon: `${baseURL}assets/images/wave-ix/engage-icon1.svg`,
            desc: '24/7 chat availability across your desktop, mobile, and app platforms.',
        },
        {
            title: 'Email',
            icon: `${baseURL}assets/images/wave-ix/engage-icon2.svg`,
            desc: 'Deploy Gen AI to handle simple email queries or to assist agents with complex requests.',
        },
        {
            title: 'Messaging',
            icon: `${baseURL}assets/images/wave-ix/engage-icon3.svg`,
            desc: 'Integrate AI across your app messaging channels for comprehensive conversational engagement.',
        },
        {
            title: 'Social',
            icon: `${baseURL}assets/images/wave-ix/engage-icon4.svg`,
            desc: 'Wave iX is ready to empower engagement across all social channels.',
        },
        {
            title: 'Voice',
            icon: `${baseURL}assets/images/wave-ix/engage-icon5.svg`,
            desc: 'Enhance your voice channel with AI built for human-like conversations.',
        },
        {
            title: 'Search',
            icon: `${baseURL}assets/images/wave-ix/engage-icon6.svg`,
            desc: 'AI-enabled search is the key to next-generation self-service capabilities - and to reducing the cost to serve.',
        },
    ];

    useEffect(() => {
        getBlogs();
    }, []);

    return (
        <Layout page={'wave-ix'}>
            <BreadcrumbsWrap>
                <Breadcrumbs>
                    <li>Solutions</li>
                    <li>Wave iX</li>
                </Breadcrumbs>
            </BreadcrumbsWrap>

            <ScrollNav data={scrollNavData} />

            <IntroWrapper id="intro">
                <IntroContent>
                    <PageInfo>
                        <div>
                            <img
                                src={`${baseURL}assets/images/wave-ix/waveix-logo-black.svg`}
                            />
                            <h1>
                                The <span>AI</span> Customer
                                <br /> Experience Platform
                            </h1>
                            <p>
                                Wave iX is an AI-enhanced solution for&nbsp;
                                <b>
                                    <i>
                                        <u>Generative CX</u>
                                    </i>
                                </b>
                                , <br />
                                helping agents, customers, and CX leaders do
                                more, go faster, and win.
                            </p>
                        </div>
                    </PageInfo>
                    <TakeAssessment>
                        <h5>Starting Your AI Journey?</h5>
                        <Button
                            bgColor={colors.socialRep['brandsBG']}
                            round
                            target={'_blank'}
                            href={
                                'https://ibexglobal.my.site.com/SurveyTest/survey/runtimeApp.app?invitationId=0KiGB000000sYQ9&surveyName=ibex_digital_assessment&UUID=01a5e5a9-a3fb-40f5-8bd3-d3eb4a73ac9f'
                            }>
                            Take the AI Assessment
                        </Button>
                    </TakeAssessment>
                </IntroContent>
                <IntroVideo>
                    <video playsInline loop autoPlay muted>
                        <source
                            src={`${baseURL}/assets/images/wave-ix/waveix-intro-video.mp4`}
                        />
                    </video>
                </IntroVideo>
            </IntroWrapper>

            <SeamlessCX>
                <Container>
                    <Row>
                        {seamless_cx?.length &&
                            seamless_cx.map((item, index) => {
                                return (
                                    <SeamlessBoxCol lg="3" key={index}>
                                        <div>
                                            <SeamlessBox
                                                borderLeft={index === 0}
                                                borderRight={
                                                    index ===
                                                    seamless_cx.length - 1
                                                }>
                                                <IconWrapper>
                                                    <img
                                                        src={item.icon}
                                                        alt="-"
                                                    />
                                                </IconWrapper>
                                                <h3>
                                                    {ReactHtmlParser(
                                                        item.heading
                                                    )}
                                                </h3>
                                            </SeamlessBox>
                                        </div>
                                    </SeamlessBoxCol>
                                );
                            })}
                    </Row>
                </Container>
            </SeamlessCX>

            <CXSection>
                <div>
                    <CxExperienceTag alignCentered small>
                        <img
                            src={`${baseURL}assets/images/wave-ix/waveix-logo-black.svg`}
                            alt="-"
                        />
                    </CxExperienceTag>
                </div>
                <CXSectionWrap>
                    <Row>
                        <Col lg="5">
                            <div>
                                <video playsInline loop autoPlay muted>
                                    <source
                                        src={`${baseURL}assets/images/wave-ix/anim-4.mp4`}
                                    />
                                </video>
                            </div>
                        </Col>
                        <Col lg="7">
                            <CxContent>
                                <div>
                                    <CxExperienceSectionHeading
                                        color={colors.white['100']}>
                                        <h4>
                                            A Consultative Approach To&nbsp;
                                            <br />
                                            <UnderlineText>
                                                AI & CX
                                            </UnderlineText>
                                        </h4>
                                    </CxExperienceSectionHeading>
                                    <CxExperienceSectionPara
                                        noMargin
                                        left
                                        color={colors.white['100']}>
                                        The sheer number of AI options on the
                                        market can make it difficult for CX
                                        leaders to know where to start. At ibex,
                                        we’ve spent decades working with the
                                        industry’s top brands to develop highly
                                        successful, highly customized CX
                                        solutions for omnichannel client
                                        success.
                                    </CxExperienceSectionPara>
                                    <CXStats>
                                        <Row>
                                            <StatBox lg="3">
                                                <h5>
                                                    400<span>+</span>
                                                </h5>
                                                <p>IT Professionals</p>
                                            </StatBox>
                                            <StatBox lg="3">
                                                <h5>
                                                    250<span>+</span>
                                                </h5>
                                                <p>
                                                    INSIGHTS <br />
                                                    ANALYSTS
                                                </p>
                                            </StatBox>
                                            <StatBox lg="3">
                                                <h5>
                                                    50<span>+</span>
                                                </h5>
                                                <p>
                                                    SOLUTIONS <br />
                                                    DEVELOPED
                                                </p>
                                            </StatBox>
                                            <StatBox lg="3">
                                                <h5>
                                                    100<span>+</span>
                                                </h5>
                                                <p>DEPLOYMENTS</p>
                                            </StatBox>
                                        </Row>
                                    </CXStats>
                                    <CXAwards>
                                        <Row>
                                            {clientBoxInfo &&
                                                clientBoxInfo.map(
                                                    (info, ind) => {
                                                        return (
                                                            <Award
                                                                lg="4"
                                                                key={ind}>
                                                                <p>
                                                                    {info.para}
                                                                </p>
                                                                <img
                                                                    src={
                                                                        info.imgURL
                                                                    }
                                                                />
                                                            </Award>
                                                        );
                                                    }
                                                )}
                                        </Row>
                                    </CXAwards>
                                </div>
                            </CxContent>
                        </Col>
                    </Row>
                </CXSectionWrap>
            </CXSection>

            <CxjourneyWrap>
                <Cxjourney>
                    <CxjourneyTagline>AI DESIGNED FOR YOU</CxjourneyTagline>
                    <CxjourneyHeading>
                        Start Your AI Journey With Confidence
                    </CxjourneyHeading>
                    <Row>
                        {ai_journey &&
                            ai_journey.map((item, index) => {
                                return (
                                    <CxjourneyBox lg="3" key={index}>
                                        <CxjourneyTextWrap>
                                            <CxjourneyBoxHeading>
                                                {item.title}
                                            </CxjourneyBoxHeading>
                                            <CxjourneyBoxText>
                                                {item.desc}
                                            </CxjourneyBoxText>
                                        </CxjourneyTextWrap>
                                    </CxjourneyBox>
                                );
                            })}
                    </Row>
                </Cxjourney>
            </CxjourneyWrap>

            <CxExperience id="cx_experience">
                <Row>
                    <CxExperienceWrap fullWidth>
                        <CxExperienceTag alignCentered small>
                            <img
                                src={`${baseURL}assets/images/wave-ix/waveix-logo-black.svg`}
                                alt="-"
                            />
                        </CxExperienceTag>
                        <CxExperienceSectionHeading bigText alignCentered>
                            <h4>Powering The Future of Customer Experience</h4>
                        </CxExperienceSectionHeading>
                        <CxExperienceSectionPara>
                            Wave iX combines ibex’s years of award-winning BPO
                            operational excellence with Generative AI to augment
                            <br /> humans, insights, and action across the
                            end-to-end CX value chain.
                        </CxExperienceSectionPara>
                    </CxExperienceWrap>

                    {cx_experience?.length &&
                        cx_experience.map((item, index) => {
                            return (
                                <CXExpRow key={index}>
                                    <Col
                                        lg="6"
                                        style={{
                                            padding: '5px',
                                        }}>
                                        <CxExperiencTextBox className="textBox">
                                            <div>
                                                {/* <CxIconWrap>
                                                    <img
                                                        src={item.icon}
                                                        alt="-"
                                                    />
                                                </CxIconWrap> */}
                                                <HeadingTag
                                                    title={item?.title}
                                                />
                                                <LeftSectionHeading>
                                                    {ReactHtmlParser(
                                                        item.heading
                                                    )}
                                                </LeftSectionHeading>
                                                <LeftSectionPara>
                                                    {ReactHtmlParser(item.para)}
                                                </LeftSectionPara>
                                                <LeftSectionList>
                                                    {item.cxExperienceLists.map(
                                                        (li, index) => (
                                                            <li key={index}>
                                                                {li}
                                                            </li>
                                                        )
                                                    )}
                                                </LeftSectionList>
                                            </div>
                                        </CxExperiencTextBox>
                                    </Col>
                                    <Col
                                        lg="6"
                                        style={{
                                            padding: '5px',
                                        }}>
                                        <CxExperiencImgBox>
                                            <div>
                                                <video autoPlay muted>
                                                    <source
                                                        src={item.videoURL}
                                                    />
                                                </video>
                                            </div>
                                        </CxExperiencImgBox>
                                    </Col>
                                </CXExpRow>
                            );
                        })}
                </Row>
            </CxExperience>

            <Engage>
                <div>
                    <CxExperienceSectionHeading
                        bigText
                        alignCentered
                        color={colors.white['100']}>
                        <h4>
                            Seamlessly engage your customers across <br />
                            their channels of choice
                        </h4>
                    </CxExperienceSectionHeading>
                    <CxExperienceSectionPara color={colors.white['80']}>
                        Wave iX is an omnichannel powerhouse ready to deliver
                        Day 1 results.
                    </CxExperienceSectionPara>
                </div>
                <EngageFeature>
                    <Row>
                        {engage &&
                            engage.map((item, index) => {
                                return (
                                    <EngageFeatCol lg="4" key={index}>
                                        <EngageBox>
                                            <div>
                                                <img src={item.icon} alt="-" />
                                                <h5>{item.title}</h5>
                                                <p>{item.desc}</p>
                                            </div>
                                        </EngageBox>
                                    </EngageFeatCol>
                                );
                            })}
                    </Row>
                </EngageFeature>
            </Engage>

            <Performance id="performance">
                <div>
                    <h3>Enhance Performance Across The Customer Journey</h3>
                    <PerformaceSubHeading>
                        AI designed to enhance every engagement at every
                        touchpoint
                    </PerformaceSubHeading>
                </div>
                <Row>
                    {performance?.length &&
                        performance.map((item, index) => {
                            return (
                                <Col lg="6" key={index}>
                                    <div>
                                        <PerformanceBox>
                                            <h5>{item.title}</h5>
                                            <p>{item.para}</p>
                                        </PerformanceBox>
                                    </div>
                                </Col>
                            );
                        })}
                </Row>
            </Performance>

            <Guide id="guide">
                <Container>
                    <CxExperienceTag alignCentered small>
                        <img
                            src={`${baseURL}assets/images/wave-ix/waveix-logo-black.svg`}
                            alt="-"
                        />
                    </CxExperienceTag>
                    <SectionHeading>
                        <u>Guide & Protect</u> Every Customer Interaction
                    </SectionHeading>
                    <GuideItems>
                        <Row>
                            {guide?.length &&
                                guide.map((item, index) => {
                                    return (
                                        <Col lg="4" key={index}>
                                            <div>
                                                <h5>
                                                    <img
                                                        src={item.icon}
                                                        alt="-"
                                                    />
                                                    {item.title}
                                                </h5>
                                                <p>{item.para}</p>
                                            </div>
                                        </Col>
                                    );
                                })}
                        </Row>
                    </GuideItems>
                </Container>
            </Guide>

            <BlogsWrap>
                <BlogsHeading>AI Thought Leadership</BlogsHeading>
                {loader ? (
                    <Loader size="small" />
                ) : aiBlogs && aiBlogs.length > 0 ? (
                    <BlogSlider>
                        <SlickSlider settings={settingsBlog}>
                            {aiBlogs.map((item, index) => {
                                return (
                                    <BlogBox key={index}>
                                        <BlogImg>
                                            <a
                                                href={item.permalink || ''}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                tabIndex="0">
                                                <img
                                                    data-src={
                                                        item.featured_image
                                                    }
                                                    src={
                                                        item.featured_image ||
                                                        ''
                                                    }
                                                    height="100"
                                                    width="100"
                                                    alt=""
                                                    className="lozad"
                                                />
                                            </a>
                                        </BlogImg>
                                        <BlogContent>
                                            <span>
                                                <Moment format="MMM DD, YYYY">
                                                    {item.post_date || ''}
                                                </Moment>
                                            </span>
                                            <h4>
                                                <a
                                                    href={item.permalink || ''}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    tabIndex="0">
                                                    {item.post_title}
                                                </a>
                                            </h4>
                                            <p>
                                                {item.metadata
                                                    ?.short_description || ''}
                                            </p>
                                        </BlogContent>
                                    </BlogBox>
                                );
                            })}
                        </SlickSlider>
                    </BlogSlider>
                ) : null}
            </BlogsWrap>

            <LetsDiscuss
                heading="Ready to Upgrade Your CX With The Power Of AI?"
                para="Get started with ibex today."
                btnText="Talk To A CX Expert"
                bgColor={colors.socialRep['brandsBG']}
            />
        </Layout>
    );
};

const BreadcrumbsWrap = styled.div`
    li {
        text-transform: none;
    }
`;

const IntroWrapper = styled.div`
    h1 {
        font-size: 54px;
        margin-top: 60px;
        line-height: 1.17;
        letter-spacing: -1.6px;
        span {
            color: ${colors.pink['lighter']};
        }
        ${Media('xlscreens')} {
            font-size: 3.33vw;
            margin-top: 3.12vw;
        }
    }
    ${Media('xlscreens')} {
        height: calc(100vh - 5.2vw);
        margin-top: -3.38vw;
        display: flex;
        video {
            object-fit: cover;
        }
    }
    ${Media('tablet')} {
        h1 {
            font-size: 40px;
            margin: 30px 0;
        }
        img {
            width: 150px;
        }
    }
    ${Media('mobile')} {
        h1 {
            font-size: 30px;
        }
    }
`;

const IntroContent = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    img {
        width: 210px;
    }
    p {
        letter-spacing: -0.5px;
    }
    ${Media('xlscreens')} {
        width: 50%;
        p {
            margin-bottom: 0;
        }
        img {
            width: 10.9vw;
        }
    }
    ${Media('tablet')} {
        img {
            margin: auto;
            width: 130px;
        }
    }
    ${Media('mobile')} {
        img {
            width: 90px;
        }
        p {
            br {
                display: none;
            }
        }
    }
`;

const PageInfo = styled.div`
    padding-top: 150px;
    padding-left: 250px;
    margin-bottom: 35px;
    ${Media('xlscreens')} {
        padding-top: 3.9vw;
        padding-left: 13vw;
        margin-bottom: 3.9vw;
        flex: 1;
        display: flex;
        align-items: center;
    }
    ${Media('tablet')} {
        padding: 50px;
        text-align: center;
        margin-bottom: 0;
    }
    ${Media('mobile')} {
        padding: 30px;
    }
`;

const IntroVideo = styled.div`
    div {
        height: 100%;
        width: 100%;
    }
    video {
        width: 100%;
        height: 100%;
        background-color: ${colors.black['100']};
    }
    ${Media('xlscreens')} {
        width: 50%;
    }
`;

const TakeAssessment = styled.div`
    background-color: ${colors.gray_lightest['100']};
    padding: 100px 0px;
    padding-left: 250px;
    display: flex;
    align-items: center;
    h5 {
        font-size: 18px;
        font-family: ${gilroyMedium};
        color: ${colors.socialRep['brandsBG']};
    }
    a {
        margin-left: 50px;
    }
    ${Media('xlscreens')} {
        padding: 3vw 0;
        padding-left: 13vw;
        margin-top: auto;
        h5 {
            font-size: 0.95vw;
        }
        a {
            margin-left: 2.66vw;
        }
    }
    ${Media('tablet')} {
        padding: 30px;
        justify-content: center;
        h5 {
            font-size: 14px;
        }
        a {
            margin-left: 30px;
        }
    }
    ${Media('mobile')} {
        a {
            margin-left: 0;
        }
        flex-direction: column;
        h5 {
            margin-top: 0;
        }
    }
`;

const SeamlessCX = styled.section`
    background-color: ${colors.socialRep['brandsBG']};
    text-align: center;
    padding: 0;
    ${Media('tablet')} {
        padding: 30px 15px;
    }
`;

const SeamlessBoxCol = styled(Col)`
    padding: 0px !important;
`;

const SectionHeading = styled.h2`
    font-size: 45px;
    font-family: ${gilroySemibold};
    text-align: center;
    line-height: 1.17;
    color: ${(props) => props.color && props.color};
    margin-bottom: 50px;
    letter-spacing: -1.3px;
    ${Media('xlscreens')} {
        font-size: 2.3vw;
        margin-bottom: 2.66vw;
    }
    ${Media('tablet')} {
        font-size: 30px;
        padding: 0px 10px;
    }
    ${Media('mobile')} {
        font-size: 22px;
    }
`;

const IconWrapper = styled.div`
    height: 56px;
    width: 56px;
    background-color: ${colors.white['100']};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    margin-right: 30px;
    img {
        max-width: 50%;
    }
    ${Media('xlscreens')} {
        height: 2.9vw;
        width: 2.9vw;
        margin-right: 1.57vw;
    }
    ${Media('tablet')} {
        height: 50px;
        width: 50px;
        img {
            max-width: 40%;
        }
    }
    ${Media('mobile')} {
        margin-right: 15px;
        height: 40px;
        width: 40px;
    }
`;

const SeamlessBox = styled.div`
    display: flex;
    padding: 35px 0;
    h3 {
        font-size: 18px;
        font-family: ${gilroyMedium};
        color: ${colors.white['100']};
        margin: auto 0;
    }
    &:hover {
        ${IconWrapper} {
            background-color: ${colors.pink['100']};
        }
    }
    ${(props) => {
        if (props.borderLeft) {
            return `
                border-top-left-radius:35px;
                border-bottom-left-radius:35px;
                ${Media('xlscreens')}{
                    border-top-left-radius:1.82vw;
                    border-bottom-left-radius:1.82vw;
                }
            `;
        }
        if (props.borderRight) {
            return `
                border-top-right-radius:35px;
                border-bottom-right-radius:35px;
                ${Media('xlscreens')}{
                    border-top-right-radius:1.82vw;
                    border-bottom-right-radius:1.82vw;
                }
            `;
        }
    }}
    ${Media('xlscreens')} {
        padding: 1.82vw 0;
        h3 {
            font-size: 0.85vw;
            text-align: left;
        }
    }
    ${Media('tablet')} {
        border-radius: 10px;
        box-shadow: 0 10px 10px ${colors.black['3']};
        padding: 14px 0;
        h3 {
            font-size: 18px;
        }
    }
    ${Media('mobile')} {
        h3 {
            font-size: 14px;
        }
    }
`;

const Performance = styled.section`
    background-image: url(${baseURL}assets/images/ai-assets/performance-bg.png);
    background-repeat: no-repeat;
    background-position: left;
    background-color: ${colors.black['100']};
    background-size: contain;
    border-radius: 35px;
    padding-left: 35%;
    padding-right: 80px;
    color: ${colors.white['100']};
    margin: 0px 140px;
    padding-top: 80px;
    margin-top: 30px;
    h3 {
        font-size: 45px;
        color: inherit;
        letter-spacing: -1.3px;
    }
    p {
        font-size: 24px;
        color: inherit;
    }
    ${Media('xlscreens')} {
        border-radius: 1.82vw;
        padding-right: 4.16vw;
        margin: 0px 7.2vw;
        padding-top: 4.16vw;
        margin-top: 1.57vw;
        h3 {
            font-size: 2.3vw;
        }
        p {
            font-size: 1.25vw;
        }
    }
    ${Media('tablet')} {
        background-image: none;
        padding-left: 5%;
        padding-right: 20px;
        margin: 0px 30px;
        h3 {
            font-size: 30px;
        }
        p {
            font-size: 20px;
        }
    }
    ${Media('mobile')} {
        margin: 0px;
        border-radius: 0;
        padding-top: 30px;
        h3 {
            font-size: 22px;
        }
    }
`;

const PerformaceSubHeading = styled.h5`
    font-size: 20px;
    font-family: ${gilroyMedium};
    color: ${colors.white['100']};
    ${Media('xlscreens')} {
        font-size: 1.04vw;
    }
    ${Media('mobile')} {
        font-size: 16px;
    }
`;

const PerformanceBox = styled.div`
    h5 {
        font-size: 22px;
        font-family: ${gilroySemibold};
    }
    p {
        font-size: 20px;
        font-family: ${gilroyMedium};
    }
    ${Media('xlscreens')} {
        h5 {
            font-size: 1.04vw;
        }
        p {
            font-size: 0.95vw;
        }
    }
    ${Media('tablet')} {
        h5 {
            font-size: 20px;
        }
        p {
            font-size: 16px;
        }
    }
    ${Media('mobile')} {
        h5 {
            font-size: 16px;
            margin-bottom: 10px;
        }
        p {
            font-size: 14px;
        }
    }
`;

const Guide = styled.section``;

const GuideItems = styled.div`
    margin-top: 100px;
    h5 {
        font-size: 22px;
        display: flex;
        font-family: ${gilroySemibold};
        margin-bottom: 18px;
        align-items: center;
        img {
            margin-right: 25px;
        }
    }
    p {
        font-size: 18px;
        font-family: ${gilroyMedium};
        margin-top: 0;
        padding-right: 50px;
        line-height: 1.55;
    }
    ${Media('xlscreens')} {
        margin-top: 3vw;
        h5 {
            font-size: 1.14vw;
            margin-bottom: 0.93vw;
            img {
                margin-right: 1.3vw;
            }
        }
        p {
            font-size: 0.95vw;
            padding-right: 2.6vw;
        }
    }
    ${Media('tablet')} {
        margin-top: 15px;
        h5 {
            font-size: 20px;
        }
        p {
            font-size: 16px;
            padding-right: 0;
        }
    }
    ${Media('mobile')} {
        h5 {
            font-size: 16px;
            img {
                margin-right: 15px;
            }
        }
        p {
            font-size: 14px;
        }
    }
`;

const CxExperience = styled.div`
    padding: 0px 140px;
    padding-top: 80px;
    .row {
        margin: 0;
    }
    ${Media('xlscreens')} {
        padding: 0 7.2vw;
        padding-top: 4.16vw;
    }
    ${Media('tablet')} {
        padding: 50px 30px;
    }
    ${Media('mobile')} {
        padding: 50px 15px;
    }
`;

const CxExperienceWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${(props) => props.fullWidth && '100%'};
`;

const CxExperienceTag = styled.div`
    img {
        width: ${(props) => (props.small ? 164 : 211)}px;
        margin: 0 auto;
    }
    ${Media('xlscreens')} {
        img {
            width: ${(props) => (props.small ? 8.5 : 10.9)}vw;
        }
    }
    ${Media('tablet')} {
        img {
            width: ${(props) => (props.small ? 120 : 160)}px;
        }
    }
`;

const CxExperienceSectionHeading = styled.div`
    h4 {
        margin-top: 20px;
        margin-bottom: 30px;
        font-family: ${gilroySemibold};
        letter-spacing: -1.3px;
        font-size: 44px;
        color: ${(props) => props.color && props.color};
        text-align: ${(props) => (props.alignCentered ? 'center' : 'left')};
        line-height: 1.17;
    }
    ${Media('xlscreens')} {
        h4 {
            margin-top: 1.04vw;
            margin-bottom: 1.57vw;
            font-size: 2.29vw;
        }
    }
    ${Media('tablet')} {
        h4 {
            font-size: 30px;
            text-align: center;
        }
    }
    ${Media('mobile')} {
        h4 {
            font-size: 24px;
        }
    }
`;

const CxExperienceSectionPara = styled.p`
    text-align: ${(props) => (props.left ? 'left' : 'center')};
    margin: 0px 45px 45px;
    line-height: 1.55;
    color: ${(props) => (props.color ? props.color : colors.black['80'])};
    ${Media('xlscreens')} {
        margin: 0 2.34vw 2.64vw;
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        margin: 0px 30px 30px;
        br {
            display: none;
        }
    }
    ${(props) => {
        if (props.noMargin) {
            return `
                margin-right:0 !important;
            `;
        }
    }}
`;

const CxExperiencTextBox = styled.div`
    background-color: ${colors.white['100']};
    height: 100%;
    padding: 70px 112px;
    border-bottom-left-radius: 35px;
    border-top-left-radius: 35px;
    ${Media('xlscreens')} {
        padding: 3.64vw 5.8vw;
        border-bottom-left-radius: 1.82vw;
        border-top-left-radius: 1.82vw;
    }
    ${Media('tablet')} {
        padding: 30px 50px;
        border-radius: 0;
    }
    ${Media('mobile')} {
        padding: 30px 25px;
    }
`;

const LeftSectionHeading = styled.h4`
    font-family: ${gilroySemibold};
    margin-bottom: 30px;
    line-height: 1.2;
    letter-spacing: -1.3px;
    margin-top: 0;
    ${Media('xlscreens')} {
        font-size: 2.08vw;
        margin-bottom: 1.57vw;
    }
    ${Media('tablet')} {
        font-size: 26px;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 22px;
    }
`;

const CxIconWrap = styled.div`
    width: 66px;
    height: 66px;
    background: ${colors.black['100']};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin-bottom: 42px;
    img {
        max-width: 45%;
    }
    ${Media('xlscreens')} {
        width: 3.45vw;
        height: 3.45vw;
        margin-bottom: 2.18vw;
    }
    ${Media('tablet')} {
        height: 50px;
        width: 50px;
    }
`;

const LeftSectionPara = styled.p`
    font-family: ${gilroyMedium};
    color: ${colors.black['80']};
    ${Media('xlscreens')} {
        font-size: 0.93vw;
    }
`;

const LeftSectionList = styled.ul`
    padding-left: 30px;
    li {
        list-style: disc;
        margin: 28px 0;
        font-family: ${gilroyMedium};
        font-size: 18px;
        color: ${colors.black['80']};
        &::marker {
            color: ${colors.pink['100']};
        }
    }
    ${Media('xlscreens')} {
        padding-left: 1.57vw;
        li {
            margin: 1.45vw 0;
            font-size: 0.95vw;
        }
    }
    ${Media('tablet')} {
        li {
            font-size: 14px;
            margin: 15px 0;
        }
    }
    ${Media('mobile')} {
        margin-top: 20px;
        li {
            font-size: 12px;
            margin: 10px 0;
        }
    }
`;

const CxExperiencImgBox = styled.div`
    height: 100%;
    video {
        width: 100%;
        height: 100%;
    }

    ${Media('xlscreens')} {
        video {
            border-bottom-right-radius: 1.82vw;
            border-top-right-radius: 1.82vw;
        }
    }
`;

const CXExpRow = styled(Row)`
    margin-bottom: 3px;
    &:nth-child(odd) {
        flex-direction: row-reverse;
        .textBox {
            border-bottom-right-radius: 35px;
            border-top-right-radius: 35px;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
        ${Media('xlscreens')} {
            video {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
                border-bottom-left-radius: 1.82vw;
                border-top-left-radius: 1.82vw;
            }
            .textBox {
                border-bottom-right-radius: 1.82vw;
                border-top-right-radius: 1.82vw;
            }
        }
        ${Media('tablet')} {
            .textBox {
                border-radius: 0;
            }
        }
    }
    ${Media('xlscreens')} {
        margin-bottom: 0.15vw;
    }
    ${Media('tablet')} {
        margin-bottom: 50px !important;
    }
`;

const UnderlineText = styled.span`
    text-decoration: underline;
`;

const BlogSlider = styled.div`
    margin-bottom: 30px;
    position: relative;
    .slick-arrow {
        position: absolute;
        left: -7vw;
        top: calc(25% - 18px);
        z-index: 99;
        &.slick-next {
            transform: translateY(50px);
        }
        ${Media('large')} {
            top: calc(45% - 25px);
            &.slick-next {
                transform: translateY(62px);
            }
        }
    }
    ${Media('xlscreens')} {
        margin-bottom: 1.57vw;
    }

    ${Media('tablet')} {
        margin-top: 50px;
        margin-bottom: 50px;
        .slick-arrow {
            left: calc(50% - 50px);
            top: auto;
            bottom: -30px;
            &.slick-next {
                transform: translateX(50px);
            }
        }
    }
    ${Media('mobile')} {
        margin-top: 10px;
        padding: 0px 15px;
    }
`;

const BlogBox = styled.div`
    ${Media('xlscreens')} {
        width: 26vw !important;
        padding-right: 40px;
    }
`;

const BlogImg = styled.div`
    height: 240px;
    width: 100%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition-duration: 0.3s;
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
    ${Media('xlscreens')} {
        height: 15.4vw;
    }
`;

const BlogContent = styled.div`
    padding: 50px 0 20px;
    ${Media('xlscreens')} {
        padding-top: 2vw;
    }
    span {
        font-size: 12px;
        color: ${colors.gray_dark['50']};
        display: block;
        margin-bottom: 5px;
        ${Media('xlscreens')} {
            font-size: 0.65vw;
        }
    }

    h4 {
        font-size: 15px;
        color: ${colors.gray_dark['100']};
        margin-bottom: 15px;
        margin-top: 15px;
        font-family: ${gilroyMedium};
        font-weight: 500;
        ${Media('xlscreens')} {
            font-size: 0.95vw;
        }
    }

    p {
        font-size: 12px;
        font-family: ${gilroyLight};
        color: ${colors.gray_dark['100']};
        max-width: 75%;
        ${Media('xlscreens')} {
            font-size: 0.8vw;
        }
        ${Media('mobile')} {
            max-width: 80%;
        }
    }
`;

const BlogsWrap = styled.div`
    ${Media('xlscreens')} {
        padding-left: 19.2vw;
    }
`;

const BlogsHeading = styled.h4`
    font-family: ${gilroySemibold};
    text-align: left;
    margin-bottom: 35px;
    font-size: 44px;
    ${Media('xlscreens')} {
        margin-bottom: 1.82vw;
        font-size: 2.29vw;
    }
    ${Media('tablet')} {
        font-size: 32px;
    }
    ${Media('mobile')} {
        font-size: 22px;
        text-align: center;
    }
`;

const CxjourneyWrap = styled.div`
    padding: 0 140px;
    margin-bottom: 10px;
    ${Media('xlscreens')} {
        padding: 0 7.2vw;
        margin-bottom: 0.52vw;
    }
    ${Media('tablet')} {
        padding: 0px 30px;
    }
    ${Media('mobile')} {
        padding: 0;
        margin-bottom: 0;
    }
`;

const Cxjourney = styled.div`
    background-color: ${colors.socialRep['brandsBG']};
    border-radius: 35px;
    padding: 85px 60px;
    ${Media('xlscreens')} {
        border-radius: 1.82vw;
        padding: 4.42vw 3.125vw;
        .row {
            margin: 0;
        }
    }
    ${Media('tablet')} {
        padding: 30px;
    }
    ${Media('mobile')} {
        padding: 30px 15px;
        border-radius: 0;
    }
`;

const CxjourneyTextWrap = styled.div`
    ${Media('xlscreens')} {
        padding-right: 3vw;
    }
`;

const CxjourneyTagline = styled.p`
    color: ${colors.white['100']};
`;

const CxjourneyHeading = styled.h4`
    font-family: ${gilroySemibold};
    color: ${colors.white['100']};
    margin: 0 0 20px 0;
    text-decoration: underline;
    ${Media('xlscreens')} {
        font-size: 2.29vw;
        margin: 0 0 1.04vw 0;
    }
    ${Media('tablet')} {
        font-size: 26px;
    }
    ${Media('mobile')} {
        font-size: 22px;
    }
`;

const CxjourneyBoxHeading = styled.h5`
    color: ${colors.white['100']};
    font-size: 20px;
    font-family: ${gilroySemibold};
    ${Media('xlscreens')} {
        font-size: 1.04vw;
    }
    ${Media('tablet')} {
        font-size: 20px;
    }
    ${Media('mobile')} {
        font-size: 16px;
        margin-bottom: 10px;
    }
`;

const CxjourneyBoxText = styled.p`
    color: ${colors.white['80']};
    font-family: ${gilroyMedium};
    font-size: 18px;
    line-height: 1.55;
    ${Media('xlscreens')} {
        font-size: 0.95vw;
        margin-bottom: 0;
    }
    ${Media('tablet')} {
        font-size: 16px;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;

const CxjourneyBox = styled(Col)`
    position: relative;
    &:after {
        content: '';
        background-image: url(${baseURL}assets/images/wave-ix/plus-icon.svg);
        background-size: 45px;
        background-repeat: no-repeat;
        position: absolute;
        top: 106px;
        right: 5%;
        height: 45px;
        width: 45px;
    }
    &:last-child {
        &:after {
            display: none;
        }
    }
    ${Media('xlscreens')} {
        &:after {
            background-size: 2.3vw;
            top: 5.6vw;
            height: 2.3vw;
            width: 2.3vw;
        }
    }
    ${Media('tablet')} {
        &:after {
            display: none;
        }
    }
`;

const Engage = styled.section`
    background-color: ${colors.socialRep['brandsBG']};
    margin: 0px 140px;
    border-radius: 35px;
    padding: 80px 0;
    margin-top: 30px;
    h4 {
        margin-top: 0;
    }

    ${Media('xlscreens')} {
        margin: 0 7.29vw;
        border-radius: 1.82vw;
        padding: 4.16vw 0;
        margin-top: 1.57vw;
    }
    ${Media('tablet')} {
        margin: 0px;
        border-radius: 0;
        padding-bottom: 50px;
    }
`;

const EngageFeature = styled.div`
    padding: 0px 120px;
    ${Media('xlscreens')} {
        padding: 0px 6.25vw;
    }
    ${Media('tablet')} {
        margin-top: 50px;
        padding: 0 15px;
    }
`;

const EngageFeatCol = styled(Col)`
    margin-bottom: 30px;
    ${Media('xlscreens')} {
        margin-bottom: 1.57vw;
    }
`;

const EngageBox = styled.div`
    border: 1px solid ${colors.white['30']};
    padding: 50px;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    padding-right: 90px;
    img {
        max-width: 60px;
        max-height: 62px;
    }
    h5 {
        color: ${colors.white['100']};
        font-size: 20px;
        font-family: ${gilroySemibold};
        margin: 30px 0;
        text-transform: uppercase;
    }
    p {
        color: ${colors.white['100']};
        font-size: 18px;
        margin-bottom: 0;
        font-family: ${gilroyMedium};
    }
    ${Media('xlscreens')} {
        padding: 2.6vw;
        border-radius: 0.53vw;
        padding-right: 4.68vw;
        img {
            max-width: 3.125vw;
            max-height: 3.22vw;
        }
        h5 {
            font-size: 1.04vw;
            margin: 1.57vw 0;
        }
        p {
            font-size: 0.93vw;
        }
    }

    ${Media('tablet')} {
        padding: 30px;
        img {
            max-width: 40px;
            max-height: 42px;
        }
        h5 {
            font-size: 18px;
            margin: 15px 0;
        }
        p {
            font-size: 14px;
        }
    }
`;

const CXSection = styled.section`
    padding: 0px 140px;
    margin-top: 80px;
    ${Media('xlscreens')} {
        padding: 0 7.29vw;
        margin-bottom: 1.3vw;
        margin-top: 4.16vw;
    }
    ${Media('tablet')} {
        padding: 0px;
    }
`;

const CXSectionWrap = styled.div`
    padding: 68px 30px;
    border-radius: 35px;
    background-color: ${colors.black['100']};
    margin-top: 65px;
    padding-bottom: 20px;
    video {
        width: 100%;
    }

    p {
        margin-left: 0;
    }

    ${Media('xlscreens')} {
        padding: 3.5vw 1.57vw;
        border-radius: 1.82vw;
        margin-top: 3.38vw;
        padding-bottom: 1.04vw;
    }
    ${Media('tablet')} {
        border-radius: 0;
    }
`;

const CxContent = styled.div`
    ${Media('xlscreens')} {
        padding-right: 6.25vw;
    }
    ${Media('tablet')} {
        h4 {
            text-align: left;
        }
    }
`;

const CXStats = styled.div`
    margin: 55px 0;
    ${Media('xlscreens')} {
        margin: 2.86vw 0;
    }
`;

const StatBox = styled(Col)`
    border: 1px solid ${colors.white['30']};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px 0;
    h5 {
        margin: 0;
        font-size: 38px;
        font-family: ${gilroySemibold};
        color: ${colors.white['100']};
        span {
            color: ${colors.pink['100']};
        }
    }
    p {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 16px;
        color: ${colors.white['100']};
    }
    ${Media('xlscreens')} {
        padding: 1.87vw 0;
        h5 {
            font-size: 1.97vw;
            height: 2.6vw;
        }
        p {
            font-size: 0.83vw;
        }
    }

    ${Media('tablet')} {
        h5 {
            font-size: 28px;
        }
    }
`;

const CXAwards = styled.div``;

const Award = styled(Col)`
    position: relative;
    p {
        color: ${colors.white['100']};
        font-size: 18px;
        font-family: ${gilroyMedium};
        text-align: center;
    }
    img {
        max-width: 104px;
        max-height: 28px;
        margin: 0 auto;
    }

    &::before {
        content: '';
        width: 30%;
        height: 60%;
        background-image: url(${baseURL}assets/images/wave-ix/left-flower.svg);
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        left: -20px;
        top: 18px;
    }
    &::after {
        content: '';
        width: 30%;
        height: 60%;
        background-image: url(${baseURL}assets/images/wave-ix/right-flower.svg);
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        right: -20px;
        top: 18px;
    }

    ${Media('xlscreens')} {
        p {
            font-size: 0.98vw;
        }
        img {
            max-width: 5.4vw;
            max-height: 1.45vw;
        }
        &::before {
            left: -1.04vw;
            top: 0.98vw;
        }
        &::after {
            right: -1.04vw;
            top: 0.98vw;
        }
    }

    ${Media('tablet')} {
        margin-bottom: 50px;
        &:after {
            height: 70%;
        }
        &:before {
            height: 70%;
        }
    }
`;

export default AI;