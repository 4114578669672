import React from 'react';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import styled from 'styled-components';
import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import { Col, Container } from '@bootstrap-styled/v4';
import colors from 'shared-components/theme/colors';
import { RowContentCenter } from 'shared-components/theme/grid-helpers';
import { baseURL, baseURLPage } from 'shared-components/theme/helpers';
import Button from 'shared-components/button/button';
import { MdHeadsetMic, MdBusinessCenter } from 'react-icons/md';
import { LetsDiscuss } from 'components/industries/common';
import Media from 'shared-components/theme/medias';

const PKSiteSection = () => {
    return (
        <Layout>
            <Breadcrumbs>
                <li>Sites</li>
            </Breadcrumbs>
            <Wrapper>
                <Container>
                    <h1>Please choose your job category.</h1>
                    <RowContentCenter>
                        <Col lg="5">
                            <SiteBox>
                                <BoxHead>
                                    <IconWrap>
                                        <MdHeadsetMic />
                                    </IconWrap>
                                    <img
                                        src={`${baseURL}assets/images/pk-flag.svg`}
                                        alt="-"
                                    />
                                </BoxHead>
                                <BoxBody>
                                    <h3>Contact Center Positions</h3>
                                    <img
                                        src={`${baseURL}assets/images/pk-site-1.png`}
                                        alt="-"
                                    />
                                    <p>
                                        Looking to join the best contact center
                                        <br />
                                        team in the world? Hit the button below
                                        to get started.
                                    </p>
                                    <hr />
                                    <Button href="https://www.talentibex.com/">
                                        Get Started
                                    </Button>
                                </BoxBody>
                            </SiteBox>
                        </Col>
                        <Col lg="5">
                            <SiteBox>
                                <BoxHead>
                                    <IconWrap>
                                        <MdBusinessCenter />
                                    </IconWrap>
                                    <img
                                        src={`${baseURL}assets/images/pk-flag.svg`}
                                        alt="-"
                                    />
                                </BoxHead>
                                <BoxBody>
                                    <h3>Tech & Corporate Positions</h3>
                                    <img
                                        src={`${baseURL}assets/images/pk-site-2.png`}
                                        alt="-"
                                    />
                                    <p>
                                        Interested in helping drive our business
                                        forward? Hit the button below to
                                        discover our latest technology &
                                        corporate roles.
                                    </p>
                                    <hr />
                                    <Button
                                        href={`${baseURLPage}join-us/pakistan/`}>
                                        Get Started
                                    </Button>
                                </BoxBody>
                            </SiteBox>
                        </Col>
                    </RowContentCenter>
                </Container>
            </Wrapper>
            <LetsDiscuss
                heading="Upgrade Your CX with us."
                para="Let’s discuss your next move."
                btnText="Let’s Talk."
            />
        </Layout>
    );
};

const Wrapper = styled.div`
    margin-bottom: 200px;
    margin-top: 50px;
    h1 {
        font-size: 60px;
        font-family: ${gilroySemibold};
        text-align: center;
        margin-bottom: 70px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 10.4vw;
        margin-top: 2.6vw;
        h1 {
            font-size: 3.12vw;
            margin-bottom: 3.64vw;
        }
    }
    ${Media('tablet')} {
        margin-bottom: 80px;
        h1 {
            font-size: 36px;
        }
    }
    ${Media('mobile')} {
        h1 {
            font-size: 28px;
        }
    }
`;

const SiteBox = styled.div`
    background-color: ${colors.waveX['darkText']};
    border-radius: 30px;
    padding: 40px;
    ${Media('xlscreens')} {
        border-radius: 1.57vw;
        padding: 2.08vw;
    }
    ${Media('tablet')} {
        padding: 30px;
        margin-bottom: 30px;
    }
    ${Media('mobile')} {
        padding: 30px 20px;
        border-radius: 20px;
    }
`;

const BoxHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
        height: 30px;
    }
    ${Media('xlscreens')} {
        img {
            height: 1.57vw;
        }
    }
    ${Media('tablet')} {
        img {
            height: 20px;
        }
    }
`;

const IconWrap = styled.div`
    height: 60px;
    width: 60px;
    background-color: ${colors.white['100']};
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 30px;
    ${Media('xlscreens')} {
        height: 3.125vw;
        width: 3.125vw;
        font-size: 1.57vw;
    }
    ${Media('tablet')} {
        height: 40px;
        width: 40px;
        font-size: 22px;
    }
`;

const BoxBody = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
    h3 {
        color: ${colors.white['100']};
        font-size: 24px;
        font-family: ${gilroyBold};
    }
    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        margin: 30px 0;
        border-radius: 10px;
    }
    p {
        color: ${colors.white['100']};
        font-size: 20px;
        font-family: ${gilroyMedium};
    }
    hr {
        margin: 30px 0;
    }
    a {
        width: 100%;
        border-radius: 10px;
        font-family: ${gilroySemibold};
        font-size: 22px;
    }
    ${Media('xlscreens')} {
        margin-top: 1.04vw;
        margin-bottom: 1.57vw;
        h3 {
            font-size: 1.25vw;
        }
        img {
            height: 10.4vw;
            margin: 1.57vw 0;
            border-radius: 0.5vw;
        }
        p {
            font-size: 1.04vw;
        }
        hr {
            margin: 1.57vw 0;
        }
        a {
            border-radius: 0.5vw;
            font-size: 1.14vw;
        }
    }
    ${Media('tablet')} {
        h3 {
            font-size: 18px;
        }
        img {
            height: 180px;
        }
        p {
            font-size: 16px;
        }
        a {
            font-size: 16px;
        }
    }
`;

export default PKSiteSection;
