import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { gilroyBold, gilroySemibold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import ReactHtmlParser from 'react-html-parser';
import { SAPageHeading, SAPagePara } from '../common';

const globalCount = [
    {
        count: '15+',
        title: 'Years of experience ',
    },
    {
        count: '40+',
        title: 'Clients',
    },
    {
        count: '31+',
        title: 'Global Delivery Centers',
    },
    {
        count: '100%',
        title: 'Client Retention',
    },
    {
        count: '31k+',
        title: 'Employees Worldwide',
    },
    {
        count: '',
        title: 'PCI Certified HIPAA Compliant',
    },
    {
        count: '450M',
        title: 'Company Revenue',
    },

    {
        count: '',
        title: 'IT Security Framework based on ISO 27001',
    },
];

const globalState = [
    {
        imageUrl: `${baseURL}assets/images/staff_aug/us-flag.jpg`,
        name: 'United States',
        desc: '8 sites • 3,000+ employees',
    },
    {
        imageUrl: `${baseURL}assets/images/staff_aug/nicaragua-flag.jpg`,
        name: 'Nicaragua',
        desc: '3 sites • 1,500 employees',
    },
    {
        imageUrl: `${baseURL}assets/images/staff_aug/philliphine-flag.jpg`,
        name: 'Philippines',
        desc: '8 sites • 10,000+ employees',
    },
    {
        imageUrl: `${baseURL}assets/images/staff_aug/jamaica-flag.jpg`,
        name: 'Jamaica',
        desc: '6 sites • 5,000+ employees',
    },
    {
        imageUrl: `${baseURL}assets/images/staff_aug/pak-flag.jpg`,
        name: 'Pakistan',
        desc: '7 sites • 3,400 employees',
    },
    {
        imageUrl: '',
        name: '',
        desc: 'Access to  markets producing <b>1.5 million graduates</b> / year. Serving <b>40+ various functions</b> of our clients in 3 different languages and geos.',
    },
];

export const SAGlobalSection = () => {
    return (
        <GlobalSection id="global">
            <Container>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                    <SAPageHeading size="large" align="center">
                        Our Global Talent Hubs
                    </SAPageHeading>
                    <SAPagePara size="large" align="center">
                        Around the world, our experienced recruitment
                        professionals are ready to build your dream team,
                        on-premises or at-home.
                    </SAPagePara>
                </ScrollAnimation>
                <GlobalStatsWrap>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                        <Row noGutters>
                            {globalCount &&
                                globalCount.length > 0 &&
                                globalCount.map((global, index) => {
                                    return (
                                        <Col md="6" key={index}>
                                            <GlobalStats>
                                                {global.count !== '' && (
                                                    <GlobalCount>
                                                        {global.count}
                                                    </GlobalCount>
                                                )}
                                                <SAPagePara>
                                                    {global.title}
                                                </SAPagePara>
                                            </GlobalStats>
                                        </Col>
                                    );
                                })}
                        </Row>
                    </ScrollAnimation>
                </GlobalStatsWrap>
                <Row noGutters>
                    {globalState &&
                        globalState.length > 0 &&
                        globalState.map((item, index) => {
                            return (
                                <Col md="6" lg="4" key={index}>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}>
                                        <StateBox>
                                            {item.imageUrl !== '' &&
                                                item.name !== '' && (
                                                    <StateHead>
                                                        <img
                                                            src={item.imageUrl}
                                                            alt="-"
                                                        />
                                                        <h4>{item.name}</h4>
                                                    </StateHead>
                                                )}
                                            {index ===
                                            globalState.length - 1 ? (
                                                <SAPagePara noLetterSpacing>
                                                    {ReactHtmlParser(item.desc)}
                                                </SAPagePara>
                                            ) : (
                                                <p>{item.desc}</p>
                                            )}
                                        </StateBox>
                                    </ScrollAnimation>
                                </Col>
                            );
                        })}
                </Row>
            </Container>
        </GlobalSection>
    );
};

const GlobalSection = styled.section`
    background-image: url('${baseURL}assets/images/staff_aug/staff-aug-globe.png');
    background-color: ${colors.white['100']};
    background-repeat: no-repeat;
    background-position: center 70%;
    background-size: auto 75%;
    padding-bottom: 100px;
    ${Media('xlscreens')} {
        padding-bottom: 8vw;
    }
`;
const GlobalStatsWrap = styled.div`
    margin: 50px 0px;
    ${Media('xlscreens')} {
        margin: 3vw 0;
    }
`;
const GlobalGreyBg = styled.div`
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    margin: 0 2.5px 5px;
    padding: 10px 25px;
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: ${colors.staffAug['lightGrey']};
        opacity: 0.56;
        left: 0px;
        top: 0px;
        z-index: -1;
    }
    ${Media('xlscreens')} {
        margin: 0 0.13vw 0.26vw;
        padding: 0.52vw 1.45vw;
    }
`;
const GlobalStats = styled(GlobalGreyBg)`
    padding-left: 40px;
    min-height: 82px;
    ${Media('xlscreens')} {
        padding-left: 3.3vw;
        min-height: 4.27vw;
    }
    ${Media('tablet')} {
        min-height: 92px;
        span {
            min-width: 85px;
            font-size: 30px;
        }
    }
    ${Media('mobile')} {
        min-height: 82px;
    }
`;
const GlobalCount = styled.h3`
    min-width: 130px;
    font-size: 45px;
    font-family: ${gilroyBold};
    margin: 0px;
    ${Media('xlscreens')} {
        min-width: 9vw;
        font-size: 2.34vw;
        letter-spacing: -0.01vw;
    }
    ${Media('tablet')} {
        min-width: 85px;
        font-size: 30px;
    }
`;
const StateBox = styled(GlobalGreyBg)`
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: 175px;
    padding-left: 30px;
    p {
        margin: 0px;
        font-size: 20px;
        font-family: ${gilroySemibold};
        color: ${colors.fintech['text']};
        letter-spacing: 1px;
    }
    ${Media('xlscreens')} {
        padding-left: 3vw;
        min-height: 9.11vw;
        p {
            font-size: 1.15vw;
            letter-spacing: 0.05vw;
        }
    }
    ${Media('tablet')} {
        min-height: 185px;
    }
`;
const StateHead = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    h4 {
        color: ${colors.pink['100']};
        font-family: ${gilroyBold};
        text-transform: uppercase;
        font-size: 28px;
        letter-spacing: 1px;
        margin: 0;
        margin-left: 20px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 0.85vw;
        h4 {
            font-size: 1.45vw;
            margin-left: 1.25vw;
        }
        img {
            max-width: 2.7vw;
        }
    }
    ${Media('tablet')} {
        h4 {
            font-size: 20px;
        }
    }
`;
