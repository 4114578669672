import React from 'react';
import colors from 'shared-components/theme/colors';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';

export const SAPagePara = ({
    children,
    size,
    color,
    align,
    noLetterSpacing,
}) => {
    return (
        <Paragraph
            size={size}
            color={color}
            align={align}
            noLetterSpacing={noLetterSpacing}>
            {children}
        </Paragraph>
    );
};

const Paragraph = styled.p`
    font-size: ${(props) => {
        if (props.size === 'large') {
            return '31px';
        } else if (props.size === 'small') {
            return '21px';
        } else {
            return '24px';
        }
    }};
    font-family: ${gilroyMedium};
    margin: 0px;
    line-height: 1.66;
    color: ${(props) => (props.color ? props.color : colors.fintech['text'])};
    text-align: ${(props) => props.align === 'center' && 'center'};
    letter-spacing: ${(props) => props.noLetterSpacing && '0 !important'};
    ${Media('xlscreens')} {
        font-size: ${(props) => {
            if (props.size === 'large') {
                return '1.61vw';
            } else if (props.size === 'small') {
                return '1.09vw';
            } else {
                return '1.25vw';
            }
        }};
    }
    ${Media('tablet')} {
        font-size: ${(props) => {
            if (props.size === 'large') {
                return '24px';
            } else if (props.size === 'small') {
                return '18px';
            } else {
                return '21px';
            }
        }};
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: ${(props) => {
            if (props.size === 'large') {
                return '21px';
            } else if (props.size === 'small') {
                return '16px';
            } else {
                return '19px';
            }
        }};
    }
`;
