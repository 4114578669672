import React from 'react';
import RetailIntro from 'components/retail/intro-section';
import { baseURL } from 'shared-components/theme/helpers';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import {
    DigitalTransform,
    CXSolution,
    PerformanceEbookSec,
    AmplifyBottomSec,
} from './sections';
import { BigCircle } from 'components/industries/common';
import Loader from 'components/loader/loader';
import LazyLoad from 'react-lazyload';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -250,
    },
    {
        name: 'cx-solution',
        target: 'cx-solution',
        offset: -50,
    },
    {
        name: 'cx-performance',
        target: 'cx-performance',
        offset: -50,
    },
    {
        name: 'amplify-section',
        target: 'amplify-section',
        offset: -50,
    },
];
const financialSolutions = [
    {
        imageUrl: `${baseURL}assets/images/industries/financial-solution1.jpg`,
        title:
            'Tap Into the Power of <br/>the ibex Financial <br/>Services CX Ecosystem',
        para: `Our global CX teams are ready to unlock brand performance at every customer channel, leveraging CX delivery expertise across Banking, Payments, Transfers, Investments, Warranty, Loans, Retail, and Crypto market segments, featuring a technology-enabled,  crisis-tested approach to high-impact CX execution.`,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/financial-solution2.jpg`,
        title: 'Build Customer <br/>Trust,  Loyalty, and <br/>Lifetime Value',
        para: `From Training Simulators to A.I. Social Media Review management, our Wave X platform is designed to optimize agent performance, enhance each customer interaction, resolve real-time customer issues, and provide full interaction transparency to our clients.`,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/financial-solution3.jpg`,
        title:
            'Reduce Fraud, Secure <br/>Customer Data, and <br/>Manage Compliance',
        para: `Our data security & anti-fraud technology stack forms the backbone of our CX data ecosystem, while our dedicated Governmental Affairs Unit actively monitors the regulatory environment for changes and surgically translates these into operational norms.`,
    },
    {
        imageUrl: `${baseURL}assets/images/industries/financial-solution4.jpg`,
        title:
            'Enhance Every Interaction <br/>with Integrated BI & <br/>Analytics',
        para: `Our sophisticated Business intelligence and Professional Services division can discover useful insights from within your customer interaction data, revealing areas for potential program enhancement, risk mitigation, and cost optimization.`,
    },
];
const cxValues = [
    {
        icon: `${baseURL}assets/images/industries/piggy-bank.svg`,
        title: 'Banking',
    },
    {
        icon: `${baseURL}assets/images/industries/card.svg`,
        title: 'Payments',
    },
    {
        icon: `${baseURL}assets/images/industries/pie-chart.svg`,
        title: 'Investment',
    },
    {
        icon: `${baseURL}assets/images/industries/transfer.svg`,
        title: 'Transfers',
    },
    {
        icon: `${baseURL}assets/images/industries/bitcoin.svg`,
        title: 'Crypto',
    },
    {
        icon: `${baseURL}assets/images/industries/shield.svg`,
        title: 'Warranty',
    },
    {
        icon: `${baseURL}assets/images/industries/dollar.svg`,
        title: 'Loans',
    },
];
const performanceList = [
    {
        imgUrl: `${baseURL}assets/images/industries/f-cx-icon1.svg`,
        title: `Customer Support`,
        list: ['Technical Support ', 'Account Support ', 'Billing Support'],
    },
    {
        imgUrl: `${baseURL}assets/images/industries/f-cx-icon2.svg`,
        title: `Merchant Partnerships`,
        list: [
            'Application Support ',
            'Merchant Support ',
            'Back Office Support ',
            'Learning Management ',
            'Corporate Account Creation & Mgmt ',
        ],
    },
    {
        imgUrl: `${baseURL}assets/images/industries/f-cx-icon3.svg`,
        title: `Regulatory Compliance`,
        list: [
            'Governance Team ',
            'Agent Compliance Training ',
            'HIPAA, PCI, ISO, NIST Certification',
        ],
    },
    {
        imgUrl: `${baseURL}assets/images/industries/f-cx-icon4.svg`,
        title: `Digital Customer Acquisition`,
        list: [
            'Search and Social Advertising ',
            'Performance Marketing ',
            'Google, Facebook, Pinterest, Reddit Ads ',
            'A.I. Ad Bidding & Launching',
        ],
    },
    {
        imgUrl: `${baseURL}assets/images/industries/f-cx-icon5.svg`,
        title: `The End-to-End CX Data Layer`,
        list: [
            'End-to-End BI & Analytics ',
            'Social Media Review Management ',
            'Customer Journey Mapping ',
            'Proactive 5-Star Surveying & Feedback Analytics ',
            'Issue Alerts & Resolution ',
            'Text & Sentiment Analytics',
        ],
    },
];
const amplifyList = [
    {
        icon: `${baseURL}assets/images/industries/rocket.svg`,
        title: 'Fast.',
        para: `We ensure your agent teams are prepared to hit key KPIs quickly and consistently, ensuring there’s no gap in CX output once ibex is switched on.`,
    },
    {
        icon: `${baseURL}assets/images/industries/icon-awesome-check.svg`,
        title: 'Flawless.',
        para: `Our teams have in-depth experience working with leading banking, trading, transfers, and crypto brands around the globe, and they’re ready to deploy this expertise for you.`,
    },
    {
        icon: `${baseURL}assets/images/industries/chameleon.svg`,
        title: 'Flexible.',
        para: `We are able to ramp, train, and flex agent teams to suit your growth needs, at dynamic offshore, nearshore, and onshore locations around the globe.`,
    },
    {
        icon: `${baseURL}assets/images/industries/crystal-ball.svg`,
        title: 'Predictable.',
        para: `Anyone can do well on a single program, but delivering consistent performance outcomes requires a battle-tested methodology for repeatable, predictable, and reliable program execution that you can set your watch to.`,
    },
];
const Financial = () => {
    const sections = [
        {
            component: <DigitalTransform />,
            isScrollNav: false,
        },
        {
            component: <CXSolution financialSolutions={financialSolutions} />,
            isScrollNav: false,
        },
        {
            component: (
                <PerformanceEbookSec
                    cxValues={cxValues}
                    performanceList={performanceList}
                />
            ),
            isScrollNav: false,
        },
        {
            component: <AmplifyBottomSec amplifyList={amplifyList} />,
            isScrollNav: true,
        },
    ];

    return (
        <Layout>
            <Breadcrumbs>
                <li>Industry</li>
                <li>Banking & Financial Services</li>
            </Breadcrumbs>
            <BigCircle
                imgUrl={`${baseURL}assets/images/industries/big-dollar-sign.svg`}
                imgHeight={785}
                top="16.7vw"
                right="35%"
                zIndex={'1'}
            />
            <RetailIntro
                rightImg={`${baseURL}assets/images/industries/financial-intro-bg.png`}
                tag="INDUSTRY"
                introHeading="Banking & <br/>Financial Services"
                introPara="An uncompromising vision for Financial Services outsourcing, <br/>driving CX performance, innovation, and security at scale."
                noButton={true}
                right="-8%"
                top="-23.5vw"
                zIndex="0"
            />
            {sections &&
                sections.map((section, index) => {
                    return (
                        <div
                            key={index}>
                            {section.isScrollNav && (
                                <ScrollNav data={scrollNavData} />
                            )}
                            {section.component}
                        </div>
                    );
                })}
        </Layout>
    );
};
export default Financial;
