import { Container } from '@bootstrap-styled/v4';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { gilroyBold } from 'shared-components/theme/fonts';

export const SubIntro = ({ imgURL, subHeading }) => {
    return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
            <BelowFoldImg>
                <img src={imgURL} alt="-" />
            </BelowFoldImg>
            <Container>
                <SubHeading>{ReactHtmlParser(subHeading)}</SubHeading>
            </Container>
        </ScrollAnimation>
    );
};
const SubHeading = styled.h2`
    text-align: center;
    font-size: 45px;
    font-family: ${gilroyBold};
    line-height: 1.5;
    margin: 50px 0;
    ${Media('xlscreens')} {
        font-size: 2.34vw;
        margin-bottom: 5.2vw;
    }
    ${Media('tablet')} {
        font-size: 26px;
        margin-top: 0;
        margin: 50px 0;
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 22px;
    }
`;
const BelowFoldImg = styled.div`
    img {
        height: 300px;
        width: 300px;
        margin: 0 auto;
        margin-bottom: 20px;
        object-fit: contain;
    }
    ${Media('xlscreens')} {
        display: none;
    }
    ${Media('mobile')} {
        img {
            width: 250px;
            height: 250px;
        }
    }
`;
