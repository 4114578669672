import React from 'react';
import colors from 'shared-components/theme/colors';
import { LetsDiscuss, AmplifySection } from 'components/industries/common';

export const AmplifyBottomSection = ({ amplifyList }) => {
    return (
        <>
            <AmplifySection
                amplifyList={amplifyList}
                tag="PEOPLE. TECHNOLOGY. SUCCESS."
                heading="Amplify and Accelerate Memorable Travel Customer Experiences "
                para="Featuring best-in-class talent, innovative technologies, and data-driven insights, ibex’s Travel & Hospitality CX <br /> solution pairs the power of an industry-tested CX performance engine directly with the future-proof systems <br /> you need at the heart of your brand, delivering unforgettable CX for every customer journey. "
            />
            <LetsDiscuss
                heading="Ready to level-up your CX?"
                para="Let’s work together."
                btnText="Talk to Us."
                bgColor={colors.pink['100']}
                btnDark
            />
        </>
    );
};
