import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import CaseArrowHeading from 'components/case-study/case-arrow-heading';
import SolutionTechBox from 'components/case-study/case-study-solutiontech-box';
import BottomSection from 'components/case-study/case-bottom-section';
import CaseBottomSolution from 'components/case-study/case-bottom-solution';
import { ScrollWrapper, SectionWrapper } from '../common';
import Media from 'shared-components/theme/medias';

export const GoalsTransformSection = ({ goals, transform }) => {
    return (
        <>
            {goals && goals['list'] && (
                <SectionWrapper>
                    <Container>
                        <CaseArrowHeading
                            marginBottom
                            marginTop
                            heading={goals['heading_text']}
                        />
                        <Row>
                            {goals['list'].map((item, index) => {
                                return (
                                    <SolutionTechCol lg="3" key={index}>
                                        <SolutionTechBox
                                            img={item['icon']}
                                            desc={item['text']}
                                        />
                                    </SolutionTechCol>
                                );
                            })}
                        </Row>
                    </Container>
                </SectionWrapper>
            )}

            <ScrollWrapper>
                <BottomSection
                    mainHeading={transform['heading_text']}
                    leftPara={transform['section_description']}
                    rightList={transform['section_description_2']}
                    withoutQoute={true}
                    withoutOffset={true}
                    styleTwo={true}
                />
            </ScrollWrapper>

            <CaseBottomSolution
                bgImg={transform['call_to_action_background_image']}
                solutionText={transform['call_to_action_content']}
                bgColor={transform['call_to_action_background_color']}
            />
        </>
    );
};

const SolutionTechCol = styled(Col)`
    ${Media('xlscreens')} {
        padding: 0.52vw !important;
    }
`;
