import { Col, Container, Row } from '@bootstrap-styled/v4';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import { SRMSectionHeading, SRMStrategyBox } from '../social-rep-man/common';
import ReactHtmlParser from 'react-html-parser';
import MainPara from 'shared-components/main-para/main-para';

export const StrategySection = ({
    strategyContent,
    heading,
    para,
    mediumPara,
}) => {
    return (
        <Wrapper id="strategy">
            <Container>
                <Row>
                    <Col sm="12">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}
                            delay={100}>
                            <SRMSectionHeading>
                                {ReactHtmlParser(heading)}
                            </SRMSectionHeading>
                            <MainPara align="center" styleTwo={mediumPara}>
                                {ReactHtmlParser(para)}
                            </MainPara>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
            {strategyContent && strategyContent.length > 0 && (
                <Container>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOnce={true}
                        delay={100}>
                        <StrategyRow>
                            {strategyContent.map((item, index) => {
                                return (
                                    <Col lg="6" key={index}>
                                        <SRMStrategyBox
                                            icon={item.iconUrl}
                                            heading={item.heading}
                                            para={item.para}
                                            mediumPara={mediumPara}
                                        />
                                    </Col>
                                );
                            })}
                        </StrategyRow>
                    </ScrollAnimation>
                </Container>
            )}
        </Wrapper>
    );
};
const Wrapper = styled.section``;

const StrategyRow = styled(Row)`
    ${Media('tablet')} {
        padding: 0px 20px;
        margin-top: 50px;
    }
`;
