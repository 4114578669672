import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import VerticalCards from 'components/social-rep-man/vertical-cards';
import { baseURL } from 'shared-components/theme/helpers';
import colors from 'shared-components/theme/colors';
import { SRMSectionHeading } from 'components/social-rep-man/common';
import MainPara from 'shared-components/main-para/main-para';

const growthContent = [
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/monitor-tag.svg`,
        VerticalCardHeading: `Monitor real-time social review data, in one screen.`,
        VerticalCardParagraph: `Our technology streamlines feedback from 
        your channels of choice into one intuitive 
        dashboard for quick analysis, insight-sharing, 
        and action, helping you rapidly resolve any 
        customer questions, comments, or complaints.`,
        VerticalCardImage: `${baseURL}assets/images/monitor-real-time.svg`,
    },
    {
        ImageRight: false,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/quick-access-square.svg`,
        VerticalCardHeading: `Quickly assess  feedback using A.I  Sentiment Assessment`,
        VerticalCardParagraph: `Our A.I. intelligently assigns a sentiment  score to each review, allowing users to  rapidly assess incoming feedback and  address issues immediately.`,
        VerticalCardImage: `${baseURL}assets/images/quickly-assess-feedback.svg`,
    },
    {
        ImageRight: true,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/categorize-feedback-square.svg`,
        VerticalCardHeading: `Categorize feedback. Uncover trends.`,
        VerticalCardParagraph: `Once the review content is pulled into the  system, our technology automatically dissects  and categorizes feedback in over 35  languages to provide a bigger picture of the   direction of customer concerns, helping you  get ahead of trends before they’re trending.`,
        VerticalCardImage: `${baseURL}assets/images/categorize-feedback.svg`,
    },
    {
        ImageRight: false,
        padTopSmall: false,
        CardTagImg: `${baseURL}assets/images/respond-feedback-square.svg`,
        VerticalCardHeading: `Respond to Feedback. Close the Loop.`,
        VerticalCardParagraph: `Our technology allows users to respond directly to customer feedback right from .the system. Responses are posted to the .originating site for immediate customer .and user visibility, closing the loop on. every customer issue.`,
        VerticalCardImage: `${baseURL}assets/images/respond-feedback.svg`,
    },
    {
        ImageRight: true,
        padTopSmall: true,
        CardTagImg: `${baseURL}assets/images/build-an-effective-square.svg`,
        VerticalCardHeading: `Build effective social strategy using actionable feedback insights.`,
        VerticalCardParagraph: `Turn unstructured social feedback into high-level cross-organizational action. Our analytics engine turns massive amounts of social media feedback into focused and actionable insights, ensuring every member in your CX organization stays head of the social media curve. `,
        VerticalCardImage: `${baseURL}assets/images/build-an-effective.svg`,
    },
];

export const BusinessValueSection = () => {
    return (
        <Wrapper>
            <Container fluid={true}>
                <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}
                            delay={100}>
                            <SRMSectionHeading>
                                Grow Digital Influence And Generate Real
                                Business Value
                            </SRMSectionHeading>
                            <MainPara align="center">
                                Customer data is massive and messy. Our
                                technology makes it easy to aggregate social
                                feedback, <br />
                                take targeted action, and surface actionable
                                insights.
                            </MainPara>
                        </ScrollAnimation>
                    </Col>
                </Row>
                {growthContent &&
                    growthContent.length > 0 &&
                    growthContent.map((item, index) => {
                        return (
                            <VerticalCards
                                ImageRight={item.ImageRight}
                                padTopSmall={item.padTopSmall}
                                CardTagImg={item.CardTagImg}
                                VerticalCardHeading={item.VerticalCardHeading}
                                VerticalCardParagraph={
                                    item.VerticalCardParagraph
                                }
                                VerticalCardImage={item.VerticalCardImage}
                                key={index}
                            />
                        );
                    })}
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.section`
    background-color: ${colors.gray_lightest['100']};
    padding-left: 140px;
    padding-right: 140px;
    ${Media('xlscreens')} {
        padding-left: 7.32vw;
        padding-right: 7.32vw;
    }

    ${Media('tablet')} {
        padding-left: 20px;
        padding-right: 20px;
    }
    h2 {
        text-align: center;
    }
`;
