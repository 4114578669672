import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { gilroyExtrabold } from 'shared-components/theme/fonts';
import ReactHtmlParser from 'react-html-parser';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import MainPara from 'shared-components/main-para/main-para';
const LayersCards = ({
    layerCardimg,
    HeadingLayerCard,
    ParagraphLayerCard,
    imgRound,
    imgBorder,
}) => {
    return (
        <LayersCardsContainer>
            <Controller>
                <Scene triggerHook="onEnter" duration="100%" offset="100%">
                    <Timeline>
                        <Tween
                            from={{
                                transform: 'translate(-23px, -24px)',
                            }}
                            to={{
                                transform: 'translate(0px, 0px)',
                            }}>
                            <LayerShadow></LayerShadow>
                        </Tween>
                    </Timeline>
                </Scene>
            </Controller>

            <PeopleImgWrapper imgRound={imgRound} imgBorder={imgBorder}>
                <img src={layerCardimg} alt="" />
            </PeopleImgWrapper>
            <HeadingWrapper>
                <h3>{ReactHtmlParser(HeadingLayerCard)}</h3>
            </HeadingWrapper>
            <MainPara>{ReactHtmlParser(ParagraphLayerCard)}</MainPara>
        </LayersCardsContainer>
    );
};
const PeopleImgWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
    img {
        height: 255px;
        border-radius: ${(props) => props.imgRound && '50%'};
        border: ${(props) =>
            props.imgBorder && `1px solid ${colors.socialRep['brandsBG']}`};
    }
    ${Media('xlscreens')} {
        margin-bottom: 3vw;
        img {
            height: 13.2vw;
        }
    }
    ${Media('tablet')} {
        margin-bottom: 30px;
        img {
            height: 180px;
        }
    }
`;
const HeadingWrapper = styled.div`
    height: 150px;
    display: flex;
    align-items: flex-end;
    h3 {
        font-size: 35px;
        font-family: ${gilroyExtrabold};
        color: ${colors.black['100']};
    }
    ${Media('xlscreens')} {
        height: 7.8vw;
        h3 {
            font-size: 1.7vw;
        }
    }
    ${Media('tablet')} {
        height: unset;
        h3 {
            font-size: 18px;
            br {
                display: none;
            }
        }
    }
`;
const LayersCardsContainer = styled.div`
    padding: 90px 50px 50px;
    border: 2px solid ${colors.black['100']};
    position: relative;
    margin: 50px 0;
    height: 800px;
    background-color: ${colors.white['100']};
    ${Media('xlscreens')} {
        padding: 2.6vw;
        padding-top: 5.1vw;
        padding-bottom: 3.1vw;
        margin: 2.5vw 2.03vw;
        height: 42vw;
    }
    ${Media('tablet')} {
        height: unset;
        padding: 50px 25px;
        margin: 35px 0;
        &::after {
            right: -15px;
            top: 15px;
        }
    }
`;

const LayerShadow = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    right: -24px;
    top: 24px;
    background-color: ${colors.black['100']};
    z-index: -1;
`;

export default LayersCards;
