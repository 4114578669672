import React from 'react';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import { AiOutlinePlus } from 'react-icons/ai';

export const IconButton = ({ btnText, href }) => {
    return (
        <DemoButton as={href ? 'a' : 'button'} href={href ? href : null}>
            <ButtonText>{btnText}</ButtonText>
            <ButtonIcon>
                <AiOutlinePlus />
            </ButtonIcon>
        </DemoButton>
    );
};
const ButtonText = styled.div`
    font-size: 23px;
    font-family: ${gilroyBold};
    color: ${colors.white['100']};
    background-color: ${(props) =>
        props.bgColor ? props.bgColor : colors.pink['100']};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.black['100']};
    width: 75%;
    ${Media('xlscreens')} {
        font-size: 1.19vw;
    }
    ${Media('tablet')} {
        font-size: 18px;
    }
`;
const ButtonIcon = styled.div`
    height: 100%;
    background-color: ${colors.black['100']};
    font-size: 30px;
    color: ${(props) => (props.color ? props.color : colors.pink['100'])};
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border: 1px solid ${colors.black['100']};
    ${Media('xlscreens')} {
        font-size: 1.56vw;
    }
`;
const DemoButton = styled.button`
    height: 50px;
    width: 230px;
    display: flex;
    cursor: pointer;
    border: none;
    user-select: none;
    background: transparent;
    ${Media('xlscreens')} {
        height: 2.6vw;
        width: 10.5vw;
    }
    ${Media('tablet')} {
        height: 35px;
        width: 200px;
    }
`;
