import React from 'react';
import { Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import {
    gilroyBold,
    gilroyLight,
    gilroyRegular,
} from 'shared-components/theme/fonts';
import colors from 'shared-components/theme/colors';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import ScrollAnimation from 'react-animate-on-scroll';
import { baseURL } from 'shared-components/theme/helpers';

const HomeIntroSolutions = () => {
    return (
        <SolutionUpper>
            <Row>
                <Col lg="12">
                    <div>
                        <SolutionUpperHead>
                            <PagePara size="large">
                                Customer inspired. Client Driven.
                            </PagePara>
                            <PageHeading>
                                A Connected Lifecycle Empowering the
                                <br />
                                End-to-End Customer Experience.
                            </PageHeading>
                        </SolutionUpperHead>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SolFeatures>
                        <Row>
                            <Col md="6" lg="4">
                                <div>
                                    <SolFeatureBox>
                                        <PreHead>
                                            Omnichannel Service & Support.
                                        </PreHead>
                                        <SolInnerBox>
                                            <SolFeatImg
                                                style={{
                                                    backgroundImage: `url(${baseURL}assets/images/sol-feat-2.jpg)`,
                                                }}>
                                                <SolFeatIcon
                                                    src={`${baseURL}assets/images/sol-feat-icon-2.svg`}
                                                    alt="-"
                                                />
                                            </SolFeatImg>
                                            <SolFeatBrand>
                                                <img
                                                    src={`${baseURL}assets/images/home-feat-connect-icon.svg`}
                                                    alt="-"
                                                />
                                                ibex Connect.
                                            </SolFeatBrand>
                                            <SolFeatTitle>
                                                Elite Outsourced <br />
                                                Customer Care.
                                            </SolFeatTitle>
                                            <SolFeatList>
                                                <li>
                                                    Customer Sales & Support
                                                </li>
                                                <li>Global Talent Network </li>
                                                <li>Award-Winning Agents</li>
                                                <li>
                                                    Customized Contact Centers
                                                </li>
                                                <li>Performance Technology</li>
                                                <li>
                                                    Deep information security
                                                </li>
                                            </SolFeatList>
                                            <SolFeatBtnWrap>
                                                <SolFeatBtn
                                                    as="a"
                                                    href="connect">
                                                    Show Me
                                                </SolFeatBtn>
                                            </SolFeatBtnWrap>
                                        </SolInnerBox>
                                    </SolFeatureBox>
                                </div>
                            </Col>
                            <Col md="6" lg="4">
                                <div>
                                    <SolFeatureBox>
                                        <PreHead>
                                            Digital Customer Acquisition.
                                        </PreHead>
                                        <SolInnerBox>
                                            <SolFeatImg
                                                style={{
                                                    backgroundImage: `url(${baseURL}assets/images/sol-feat-1.jpg)`,
                                                }}>
                                                <SolFeatIcon
                                                    src={`${baseURL}assets/images/sol-feat-icon-1.svg`}
                                                    alt="-"
                                                />
                                            </SolFeatImg>
                                            <SolFeatBrand>
                                                <img
                                                    src={`${baseURL}assets/images/home-feat-digital-icon.svg`}
                                                    alt="-"
                                                />
                                                ibex Digital.
                                            </SolFeatBrand>
                                            <SolFeatTitle>
                                                Online Customer <br />
                                                Acquisition.
                                            </SolFeatTitle>
                                            <SolFeatList>
                                                <li>
                                                    Search & Social Advertising
                                                </li>
                                                <li>
                                                    Digital Order Orchestration
                                                </li>
                                                <li>
                                                    Precision Conversion
                                                    Attribution
                                                </li>
                                                <li>Payment Distribution</li>
                                                <li>Bid Optimization</li>
                                            </SolFeatList>
                                            <SolFeatBtnWrap>
                                                <SolFeatBtn
                                                    as="a"
                                                    href="digital">
                                                    Show Me
                                                </SolFeatBtn>
                                            </SolFeatBtnWrap>
                                        </SolInnerBox>
                                    </SolFeatureBox>
                                </div>
                            </Col>
                            <Col md="6" lg="4">
                                <div>
                                    <SolFeatureBox>
                                        <PreHead>
                                            Surveys & Feedback Analytics.
                                        </PreHead>
                                        <SolInnerBox>
                                            <SolFeatImg
                                                style={{
                                                    backgroundImage: `url(${baseURL}assets/images/sol-feat-3.jpg)`,
                                                }}>
                                                <SolFeatIcon
                                                    src={`${baseURL}assets/images/sol-feat-icon-3.svg`}
                                                    alt="-"
                                                />
                                            </SolFeatImg>
                                            <SolFeatBrand>
                                                <img
                                                    src={`${baseURL}assets/images/home-feat-cx-icon.svg`}
                                                    alt="-"
                                                />
                                                ibex CX.
                                            </SolFeatBrand>
                                            <SolFeatTitle>
                                                All-Channel Digital <br />
                                                Customer Outreach.
                                            </SolFeatTitle>
                                            <SolFeatList>
                                                <li>
                                                    Lifecycle Touchpoint
                                                    Outreach&nbsp;
                                                </li>
                                                <li>
                                                    Email, Text, & Web CX
                                                    surveys&nbsp;
                                                </li>
                                                <li>5-Star Surveys</li>
                                                <li>
                                                    Real-Time Issue
                                                    Resolution&nbsp;
                                                </li>
                                                <li>
                                                    Data Analytics &
                                                    Visualization
                                                </li>
                                                <li>Social Media Monitoring</li>
                                            </SolFeatList>
                                            <SolFeatBtnWrap>
                                                <SolFeatBtn as="a" href="cx">
                                                    Show Me
                                                </SolFeatBtn>
                                            </SolFeatBtnWrap>
                                        </SolInnerBox>
                                    </SolFeatureBox>
                                </div>
                            </Col>
                        </Row>
                    </SolFeatures>
                </Col>
            </Row>
        </SolutionUpper>
    );
};

const SolutionUpper = styled.div`
    padding-top: 60px;
    padding-bottom: 50px;
    ${Media('xlscreens')} {
        padding-top: 5vw;
        padding-bottom: 3vw;
    }
`;

const SolutionUpperHead = styled.div`
    text-align: center;
    ${Media('xlscreens')} {
        padding: 2vw 0 0;
    }
    ${Media('tablet')} {
        padding: 60px 0 0;
    }
`;

const PageHeading = styled.h2`
    font-size: 44px;
    letter-spacing: -1px;
    line-height: 1.3;
    ${Media('xlscreens')} {
        font-size: 3.2vw;
        letter-spacing: -0.1vw;
    }
    ${Media('mobile')} {
        font-size: 33px;
    }
`;

const PagePara = styled.p`
    font-family: ${gilroyLight};
    margin-top: 0px;
    font-size: ${(props) => {
        if (props.size === 'small') {
            return '16px';
        } else if (props.size === 'medium') {
            return '17px';
        } else if (props.size === 'large') {
            return '18px';
        }
    }};
    ${Media('xlscreens')} {
        font-size: ${(props) => {
            if (props.size === 'small') {
                return '1vw';
            } else if (props.size === 'medium') {
                return '1.05vw';
            } else if (props.size === 'large') {
                return '1.1vw';
            }
        }};
    }
    ${Media('mobile')} {
        font-size: ${(props) => {
            if (props.size === 'small') {
                return '14px';
            } else if (props.size === 'medium') {
                return '15px';
            } else if (props.size === 'large') {
                return '16px';
            }
        }};
    }
`;

const SolFeatures = styled.div`
    width: 100%;
`;

const SolFeatureBox = styled.div`
    padding-top: 50px;
    transition: all 330ms ease;
    &:hover {
        transform: translateY(-10px);
    }
    ${Media('xlscreens')} {
        padding-top: 3vw;
    }
`;

const PreHead = styled.div`
    font-family: ${gilroyRegular};
    font-size: 14px;
    letter-spacing: 2px;
    color: ${colors.dark['100']};
    padding-bottom: 20px;
    text-align: center;
    ${Media('xlscreens')} {
        font-size: 0.73vw;
        letter-spacing: 0.08vw;
        padding-bottom: 1vw;
    }
`;

const SolFeatImg = styled(FlexCentered)`
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    height: 220px;
    margin: 20px;
    transition: all 0.3s ease;
    ${Media('xlscreens')} {
        margin: 1.5vw;
        height: 11.5vw;
    }
`;

const SolFeatBtn = styled(FlexCentered)`
    width: 150px;
    height: 40px;
    font-size: 16px;
    font-family: ${gilroyBold};
    border-radius: 10px 0px 10px 0px;
    border: 1px solid ${colors.gray_dark['100']};
    color: ${colors.gray_dark['100']};
`;

const SolInnerBox = styled.div`
    background-color: ${colors.white['100']};
    border-radius: 23px;
    overflow: hidden;
    transition: all 330ms ease;
    box-shadow: 0px 3px 66px rgba(10, 20, 125, 0.01);
    &:hover {
        box-shadow: 0px 3px 66px rgba(10, 20, 125, 0.05);
        ${SolFeatBtn} {
            border-color: ${colors.pink['100']};
            background-color: ${colors.pink['100']};
            color: ${colors.white['100']};
        }
        ${SolFeatImg} {
            background-size: 120%;
        }
    }
`;

const SolFeatIcon = styled.img`
    width: ${(props) => (props.level === 'wavex' ? '60px' : '35px')};
    ${Media('xlscreens')} {
        width: ${(props) => (props.level === 'wavex' ? '3.3vw' : '1.6vw')};
    }
`;

const SolFeatBrand = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: ${gilroyRegular};
    margin: 30px 20px 25px;
    img {
        width: ${(props) => (props.level === 'wavex' ? '70px' : '24px')};
        max-height: 24px;
        margin-right: 15px;
    }
    ${Media('xlscreens')} {
        font-size: 0.9vw;
        margin: 1.7vw 1.65vw 1.4vw;
        min-height: 1.15vw;
        img {
            width: ${(props) => (props.level === 'wavex' ? '3.9vw' : '1.15vw')};
            max-height: 1.15vw;
            margin-right: 0.8vw;
        }
    }
`;

const SolFeatTitle = styled.h3`
    font-size: 20px;
    font-family: ${gilroyBold};
    margin: 0px 20px 30px;
    line-height: 1.2;
    ${Media('xlscreens')} {
        font-size: 1.22vw;
        margin: 0 1.5vw 2vw;
    }
`;

const SolFeatList = styled.ul`
    padding: 0px;
    margin: 0px;
    min-height: 184px;
    li {
        font-family: ${gilroyRegular};
        font-size: 14px;
        padding: 5px 20px;
        padding-left: 30px;
        position: relative;
        color: ${colors.dark['65']};
        &:nth-child(even) {
            background-color: ${colors.gray['border_light']};
        }
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            width: 8px;
            height: 12px;
            background-image: url('${baseURL}assets/images/sol-feat-list-arrow.svg');
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }
    ${Media('xlscreens')} {
        min-height: 12.2vw;
        li {
            font-size: 0.82vw;
            padding: 0.35vw 1.65vw;
            padding-left: 2.6vw;
            &:before {
                width: 0.4vw;
                height: 0.6vw;
                left: 1.65vw;
            }
        }
    }
`;

const SolFeatBtnWrap = styled(FlexCentered)`
    height: 125px;
    ${Media('xlscreens')} {
        height: 6.5vw;
    }
`;

export default HomeIntroSolutions;
