import React, { Fragment } from 'react';
import LazyLoad from 'react-lazyload';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { Parallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import Layout from 'components/layout';
import colors from 'shared-components/theme/colors';
import HomeIntro from 'components/home-intro/home-intro';
import Media from 'shared-components/theme/medias';
import HomeBigText from 'components/home-big-text/home-big-text';
import { gilroySemibold } from 'shared-components/theme/fonts';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import {
    SupportSection,
    ProfitSection,
    FeaturesSection,
    BrandsSection,
} from './sections';
import Loader from 'components/loader/loader';
import { baseURL } from 'shared-components/theme/helpers';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -170,
    },
    {
        name: 'thousandSmiles',
        target: 'thousandSmiles',
        offset: -100,
    },
    {
        name: 'products',
        target: 'products',
        offset: -80,
    },
    {
        name: 'delivery',
        target: 'delivery',
        offset: 100,
    },
    {
        name: 'industries',
        target: 'industries',
        offset: -80,
    },
    {
        name: 'brands',
        target: 'brands',
        offset: -110,
    },
];
const clientBoxInfo = [
    {
        para: (
            <Fragment>
                Best Place to <br />
                Work 2020
            </Fragment>
        ),
        imgURL: `${baseURL}assets/images/great-place.svg`,
        imgWidth: '22%',
    },
    {
        para: (
            <Fragment>
                Nearshore Company <br /> of the Year
            </Fragment>
        ),
        imgURL: `${baseURL}assets/images/nearshore.svg`,
        imgWidth: '41%',
    },
    {
        para: (
            <Fragment>
                Growth <br /> Excellence Leadership <br /> Award
            </Fragment>
        ),
        imgURL: `${baseURL}assets/images/frost.svg`,
        imgWidth: '80%',
    },
    {
        para: (
            <Fragment>
                Customer Value <br /> Leadership Award in <br /> North America.
            </Fragment>
        ),
        imgURL: `${baseURL}assets/images/frost.svg`,
        imgWidth: '80%',
    },
];
const lazyLoadComponents = [
    {
        offset: 100,
        component: <SupportSection />,
        isScrollNav: false,
    },
    {
        offset: 100,
        component: <ProfitSection />,
        isScrollNav: false,
    },
    {
        offset: 100,
        component: <FeaturesSection />,
        isScrollNav: false,
    },
    {
        offset: 100,
        component: <BrandsSection />,
        isScrollNav: true,
    },
];
const Connect = () => {
    return (
        <Layout page="connect">
            <Breadcrumbs>
                <li>Solutions</li>
                <li>Connect</li>
            </Breadcrumbs>

            <HomeIntro
                headingColumn={8}
                headingOffset={0}
                mainParaPadding={false}
                mainTitle={
                    <Fragment>
                        An expert outsourcer <br />
                        with that in-house feel
                    </Fragment>
                }
                landingVideoUrl={`${baseURL}assets/videos/connect-video.mp4`}
                landingVideoImgUrl={`${baseURL}assets/images/connect-video-cover.jpg`}
                mainPara="Our award-winning agents support New Economy and Blue Chip brands, seamlessly convert service to sales, and dynamically accelerate lifetime customer value."
            />
            <ClientDetails>
                <Container>
                    <Row>
                        <Col lg="12">
                            <div>
                                <ClientContainer>
                                    <Row>
                                        {clientBoxInfo &&
                                            clientBoxInfo.map((info, ind) => {
                                                return (
                                                    <Col lg="3" key={ind}>
                                                        <ClientBox>
                                                            <p>{info.para}</p>
                                                            <img
                                                                src={
                                                                    info.imgURL
                                                                }
                                                                alt=""
                                                                width={
                                                                    info.imgWidth
                                                                }
                                                            />
                                                        </ClientBox>
                                                    </Col>
                                                );
                                            })}
                                    </Row>
                                </ClientContainer>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </ClientDetails>
            <BigTextSection>
                <HomeBigText
                    bigTextOne={<Fragment>Engage. Transform.</Fragment>}
                    bigTextTwo={<Fragment>Grow.</Fragment>}
                    level="cxOne"
                    textOneleft="80%"
                    textOneRight="-50%"
                    textTwoleft="-130%"
                    textTwoRight="20%"
                />

                <BigTriangle x={[-20, 20]}>
                    <img
                        src={`${baseURL}assets/images/big-triangle.svg`}
                        alt=""
                    />
                </BigTriangle>
            </BigTextSection>

            {lazyLoadComponents &&
                lazyLoadComponents.map((comp, ind) => (
                    <div
                        key={ind}>
                        {comp && comp.isScrollNav && (
                            <ScrollNav data={scrollNavData} />
                        )}
                        {comp.component && comp.component}
                    </div>
                ))}
        </Layout>
    );
};
const BigTriangle = styled(Parallax)`
    position: absolute;
    right: -65px;
    top: -70px;
    width: 30%;
    ${Media('xlscreens')} {
        width: 20vw;
        right: 8vw;
        top: 0vw;
    }
    img {
        width: 100%;
    }
    ${Media('tablet')} {
        display: none;
    }
`;
const BigTextSection = styled.section`
    position: relative;
    padding-top: 40px;
`;

const ClientBox = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    flex-direction: column;
    position: relative;
    &::before {
        content: '';
        width: 30px;
        height: 100px;
        background-image: url(${baseURL}assets/images/left-flower.svg);
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        left: 0px;
        top: 60px;
    }
    &::after {
        content: '';
        width: 30px;
        height: 100px;
        background-image: url(${baseURL}assets/images/right-flower.svg);
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        right: 0px;
        top: 60px;
    }
    p {
        font-family: ${gilroySemibold};
        font-size: 16px;
        margin-top: 0;
        color: ${colors.gray_dark['100']};
    }
    ${Media('xlscreens')} {
        height: 16vw;
        &:before,
        &:after {
            width: 2.3vw;
            height: 15.15vh;
        }
        p {
            font-size: 0.95vw;
        }
    }
    ${Media('tablet')} {
        margin: 15px auto 30px;
        max-width: 250px;
        &:before,
        &:after {
            top: 10px;
        }
    }
`;
const ClientDetails = styled.section`
    padding-top: 0;
`;

const ClientContainer = styled.div`
    border: 1px solid ${colors.gray['border']};
    border-radius: 22px;
    padding: 0 30px;
    ${Media('tablet')} {
        padding: 20px 40px;
    }
`;

export default Connect;
