import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import { LetsDiscuss } from 'components/industries/common';
import Layout from 'components/layout';
import Breadcrumbs from 'shared-components/breadcrumbs/breadcrumbs';
import Button from 'shared-components/button/button';
import colors from 'shared-components/theme/colors';
import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import { RowCentered } from 'shared-components/theme/grid-helpers';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';

const cX_complexity = [
    {
        icon: `${baseURL}assets/images/wave-ix/virtual-agent/cX_complexity_icon_1.svg`,
        title: 'Infinite Scale',
        para: 'Scale up or down on demand.',
    },
    {
        icon: `${baseURL}assets/images/wave-ix/virtual-agent/cX_complexity_icon_2.svg`,
        title: 'Omnichannel & Multilanguage',
        para: 'Communicate with anyone, anywhere.',
    },
    {
        icon: `${baseURL}assets/images/wave-ix/virtual-agent/cX_complexity_icon_3.svg`,
        title: 'Seamless Escalation',
        para: 'From Virtual to Human, effortlessly.',
    },
    {
        icon: `${baseURL}assets/images/wave-ix/virtual-agent/cX_complexity_icon_4.svg`,
        title: 'Strict Governance',
        para: 'Safe & seamless deployment.',
    },
];

const translations = [
    {
        original: `Bonjour! <br/>Comment puis je vous aider?`,
        translate: `Hello! <br/>How can I help you?`,
    },
    {
        original: `Brindar soporte al cliente <br/> en cualquier idioma.`,
        translate: `Deliver customer support in any language.`,
    },
    {
        original: `Su intérprete de IA en tiempo real.`,
        translate: `Your Real-Time AI Interpreter.`,
    },
    {
        original: `Traducción bidireccional de voz y texto`,
        translate: `2-Way Voice & Text Translation`,
    },
    {
        original: `Reconocimiento de voz e intención`,
        translate: `Speech & Intent Recognition`,
    },
    {
        original: `Voz de IA entrenada por la marca`,
        translate: `Brand Trained AI Voice`,
    },
];

const cx_programmes = [
    {
        icon: `${baseURL}assets/images/wave-ix/virtual-agent/cx_icon_1.svg`,
        img: `${baseURL}assets/images/wave-ix/virtual-agent/human-agents-img-1.png`,
        title: 'Specialized Virtual Agents',
        desc: 'Establish various specialized Virtual Agents and allocate them to a targeted queue.',
    },
    {
        icon: `${baseURL}assets/images/wave-ix/virtual-agent/cx_icon_2.svg`,
        img: `${baseURL}assets/images/wave-ix/virtual-agent/human-agents-img-2.png`,
        title: 'Customized omnichannel queue management',
        desc: 'Allocate a virtual agent for incoming calls and chats or use existing queue-level routing options.',
    },
    {
        icon: `${baseURL}assets/images/wave-ix/virtual-agent/cx_icon_3.svg`,
        img: `${baseURL}assets/images/wave-ix/virtual-agent/human-agents-img-3.png`,
        title: 'Seamless transition between Virtual and Human agents.',
        desc: 'Enable human agents to handle escalations from a Virtual Agent, or hand off a session to a Virtual Agent.',
    },
];

const support_items = [
    {
        img: `${baseURL}assets/images/wave-ix/virtual-agent/feat-img-1.png`,
        title: 'Hyper Personalized Conversations',
        para: 'LLM trained on your knowledge bases and support history, and tightly controlled within your brand guidelines.',
    },
    {
        img: `${baseURL}assets/images/wave-ix/virtual-agent/feat-img-2.png`,
        title: 'Conversational Analyics',
        para: 'Acquire understanding of user actions and patterns, enabling admins to make rapid, informed choices.',
    },
    {
        img: `${baseURL}assets/images/wave-ix/virtual-agent/feat-img-3.png`,
        title: 'Multi-Language Support',
        para: 'Identify and dynamically convert conversations into the preferred language of the end user.',
    },
];

const Automate = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            if (activeIndex !== translations.length - 1) {
                setActiveIndex(activeIndex + 1);
            } else {
                setActiveIndex(0);
            }
        }, 6000);
    });
    return (
        <Layout>
            <Wrapper>
                <Breadcrumbs color={colors.white['50']}>
                    <li>Solutions</li>
                    <li>Wave iX</li>
                    <li>Virtual Agent</li>
                </Breadcrumbs>
                <Intro>
                    <Container>
                        <RowCentered>
                            <Col lg="5">
                                <IntroContent>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={true}>
                                        <img
                                            src={`${baseURL}assets/images/wave-ix/ix-logo.svg`}
                                            alt="-"
                                        />
                                        <h1>Virtual Agent</h1>
                                        <p>
                                            Cutting edge converational AI for
                                            <br />
                                            chat, voice, and CSAT augmentation.
                                        </p>
                                        <Button>
                                            Book a Demo
                                            <img
                                                src={`${baseURL}assets/images/wave-ix/automate/arrow-right.svg`}
                                            />
                                        </Button>
                                    </ScrollAnimation>
                                </IntroContent>
                            </Col>
                            <Col lg="7">
                                <IntroImg>
                                    <img
                                        src={`${baseURL}assets/images/wave-ix/virtual-agent/virtual-agent-bg.png`}
                                        alt="-"
                                    />
                                </IntroImg>
                            </Col>
                        </RowCentered>
                    </Container>
                </Intro>
                <CxComplexity>
                    <Container>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <SectionHeader
                                style={{
                                    textAlign: 'left',
                                }}
                                medium>
                                <h2 style={{ color: colors.gray['text'] }}>
                                    Gen AI for personalized text & voice
                                    conversations, <br />
                                    trained to your brand guidelines and guard
                                    rails, <br />
                                    and seamlessly connected to your human
                                    agents.
                                </h2>
                            </SectionHeader>
                        </ScrollAnimation>
                        <CxComplexityList>
                            <Row>
                                {cX_complexity &&
                                    cX_complexity.length > 0 &&
                                    cX_complexity.map((item, index) => {
                                        return (
                                            <Col lg="3" key={index}>
                                                <ScrollAnimation
                                                    animateIn="fadeInUp"
                                                    animateOnce={true}>
                                                    <CxComplexityItem
                                                        key={index}
                                                        lastChild={
                                                            index ===
                                                            cX_complexity.length -
                                                                1
                                                        }>
                                                        <img
                                                            src={item.icon}
                                                            alt="-"
                                                        /> 
                                                        <h5>{item.title}</h5>
                                                        <p>{item.para}</p>
                                                    </CxComplexityItem>
                                                </ScrollAnimation>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </CxComplexityList>
                    </Container>
                </CxComplexity>
                <CXProgramme>
                    <Container>
                        <CXProgrammesList>
                            {cx_programmes &&
                                cx_programmes.length > 0 &&
                                cx_programmes.map((item, index) => {
                                    return (
                                        <CXProgrammeRow key={index}>
                                            <Col lg="6">
                                                <CXProgrammeImg>
                                                    <img
                                                        src={item.img}
                                                        alt="-"
                                                    />
                                                </CXProgrammeImg>
                                            </Col>
                                            <Col lg="6">
                                                <CXProgrammeContent>
                                                    <ScrollAnimation
                                                        animateIn="fadeInUp"
                                                        animateOnce={true}>
                                                        <img
                                                            src={item.icon}
                                                            alt="-"
                                                        />
                                                        <h5>{item.title}</h5>
                                                        <p>{item.desc}</p>
                                                    </ScrollAnimation>
                                                </CXProgrammeContent>
                                            </Col>
                                        </CXProgrammeRow>
                                    );
                                })}
                        </CXProgrammesList>
                    </Container>
                </CXProgramme>
            </Wrapper>
            <Support>
                <Container>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <SectionHeader color={colors.gray_dark['100']}>
                            <h2>
                                <u>
                                    Supercharge CSAT With Personalized, Brand{' '}
                                    <br />
                                    Compliant Virtual Agents
                                </u>
                            </h2>
                        </SectionHeader>
                    </ScrollAnimation>
                    <Row>
                        {support_items &&
                            support_items.length > 0 &&
                            support_items.map((item, index) => {
                                return (
                                    <Col lg="4" key={index}>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOnce={true}>
                                            <SupportItem
                                                key={index}
                                                lastChild={
                                                    index ===
                                                    support_items.length - 1
                                                }>
                                                <img src={item.img} alt="-" />
                                                <h5>{item.title}</h5>
                                                <p>{item.para}</p>
                                            </SupportItem>
                                        </ScrollAnimation>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </Support>
            <LetsDiscuss
                heading="Ready to onboard your new all-star Virtual Agent?"
                para="Book a demo today."
                btnText="Book a demo"
                bgColor={colors.socialRep['brandsBG']}
            />
        </Layout>
    );
};

const Wrapper = styled.div`
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 20px;
    ${Media('desktop')} {
        background-image: url(${baseURL}assets/images/wave-ix/automate/bg.jpg);
        padding-top: 1.04vw;
    }
    ${Media('tablet')} {
        padding-top: 10px;
        background-image: url(${baseURL}assets/images/wave-ix/mobo-bg.jpg);
    }
`;

const Intro = styled.div`
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    ${Media('xlscreens')} {
        padding-bottom: 2.5vw;
    }
    ${Media('tablet')} {
        padding: 0px 15px;
        padding-bottom: 50px;
    }
`;

const IntroContent = styled.div`
    h1 {
        color: ${colors.white['100']};
        font-family: ${gilroyMedium};
        font-size: 64px;
    }

    p {
        color: ${colors.white['100']};
        font-family: ${gilroyMedium};
        margin-bottom: 56px;
        font-size: 20px;
    }
    a {
        background-color: ${colors.purple['100']};
        border-radius: 5px;
        box-shadow: -7px 6px 0 ${colors.purple['25']};
        img {
            width: 12px;
            margin-bottom: 0;
            margin-left: 10px;
            transition: all 0.3s ease;
        }
        &:hover {
            color: ${colors.white['100']};
            img {
                animation: topBottom 1s ease-in-out infinite;
            }
            ::before {
                display: none;
            }
        }
    }

    img {
        margin-bottom: 30px;
        width: 100px;
    }
    ${Media('xlscreens')} {
        h1 {
            font-size: 3.33vw;
        }
        p {
            font-size: 1.04vw;
            margin-bottom: 2.9vw;
        }
        img {
            margin-bottom: 1.57vw;
            width: 5.2vw;
        }
        a {
            img {
                width: 0.62vw;
                margin-left: 0.53vw;
            }
        }
    }
    ${Media('tablet')} {
        padding-top: 50px;
        margin-bottom: 50px;
        h1 {
            font-size: 42px;
        }
        p {
            font-size: 16px;
        }
        img {
            margin-bottom: 50px;
            width: 70px;
        }
    }
    @keyframes topBottom {
        0% {
            transform: translateX(0px) translateY(0px);
        }
        100% {
            transform: translateX(5px) translateY(-5px);
        }
    }
`;

const IntroImg = styled.div`
    position: relative;
    img {
        width: 100%;
    }
`;

const SectionHeader = styled.div`
    text-align: center;
    margin-bottom: 100px;
    h2 {
        font-size: 45px;
        font-family: ${(props) =>
            props.medium ? gilroyMedium : gilroySemibold};
        color: ${(props) => props.color || colors.white['100']};
    }
    p {
        color: ${(props) => props.color || colors.white['100']};
        font-size: 18px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 5.2vw;
        h2 {
            font-size: 2.34vw;
        }
        p {
            font-size: 0.93vw;
        }
    }
    ${Media('tablet')} {
        margin-bottom: 30px;
        br {
            display: none;
        }
        h2 {
            font-size: 32px;
        }
        p {
            font-size: 16px;
        }
    }
`;

const CXProgramme = styled.section``;

const CXProgrammesList = styled.div``;

const CXProgrammeRow = styled(RowCentered)`
    margin-bottom: 70px;
    ${Media('xlscreens')} {
        &:nth-of-type(even) {
            flex-direction: row-reverse;
        }
        margin-bottom: 3.64vw;
    }
    ${Media('tablet')} {
        margin: 80px 0;
    }
`;

const CXProgrammeImg = styled.div`
    img {
        width: 100%;
    }
    ${Media('xlscreens')} {
        margin-right: 3vw;
    }
`;

const CXProgrammeContent = styled.div`
    color: ${colors.white['100']};
    margin-left: 20px;
    img {
        margin-bottom: 15px;
        max-width: 62px;
        max-height: 55px;
    }
    h5 {
        font-family: ${gilroyMedium};
        font-size: 35px;
        margin: 0;
        margin-bottom: 30px;
        font-weight: 400;
    }
    p {
        color: inherit;
    }
    ul {
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 70px;
        padding-right: 30px;
        margin-top: 50px;
        li {
            width: 50%;
            padding: 16px 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px dashed ${colors.white['50']};
            font-size: 18px;
            text-align: center;
            font-family: ${gilroyMedium};
        }
    }
    a {
        padding: 15px 33px;
        background-color: ${colors.white['100']};
        border-radius: 5px;
        font-size: 18px;
        font-family: ${gilroyMedium};
        box-shadow: -7px 6px 0 ${colors.purple['25']};
        img {
            display: inline-block;
            margin-bottom: 0;
            height: 12px;
            width: 12px;
            margin-left: 10px;
        }
    }
    ${Media('xlscreens')} {
        margin-left: 1.04vw;
        padding-right: 2vw;
        img {
            margin-bottom: 0.53vw;
            max-width: 3.22vw;
            max-height: 2.86vw;
        }
        h5 {
            font-size: 1.82vw;
            margin-bottom: 1.57vw;
        }
        ul {
            margin-bottom: 3.64vw;
            padding-right: 1.57vw;
            margin-top: 2.6vw;
            li {
                padding: 0.83vw 1.14vw;
                font-size: 0.93vw;
            }
        }
        a {
            padding: 0.78vw 1.71vw;
            border-radius: 0.2vw;
            font-size: 0.93vw;
            img {
                height: 0.62vw;
                width: 0.62vw;
                margin-left: 0.53vw;
            }
        }
    }
    ${Media('tablet')} {
        margin-top: 50px;
        img {
            max-width: 52px;
            max-height: 45px;
        }
        h5 {
            font-size: 26px;
        }
        ul {
            li {
                justify-content: flex-start;
                width: 100%;
                font-size: 14px;
                text-align: left;
            }
        }
        a {
            font-size: 14px;
            padding: 10px 26px;
        }
    }
`;

const CxComplexity = styled.section`
    ${Media('xlscreens')} {
        padding-bottom: 1.57vw;
    }
    ${Media('tablet')} {
        padding: 30px 0;
    }
`;

const CxComplexityList = styled.div`
    color: ${colors.white['100']};
    p {
        color: ${colors.white['100']};
    }
`;

const CxComplexityItem = styled.div`
    h5 {
        font-size: 18px;
        font-family: ${gilroyBold};
    }
    ${Media('xlscreens')} {
        padding-right: 1vw;
        h5 {
            font-size: 0.93vw;
        }
    }
`;

const Support = styled.section`
    background-color: ${colors.gray_lighter['100']};
`;

const SupportItem = styled.div`
    img {
        width: 100%;
    }
    h5 {
        font-size: 20px;
        font-family: ${gilroySemibold};
    }
    p {
        font-size: 18px;
        font-family: ${gilroyMedium};
    }
    ${Media('xlscreens')} {
        h5 {
            font-size: 1.04vw;
        }
        p {
            font-size: 0.93vw;
        }
    }

    ${Media('tablet')} {
        margin-bottom: 50px;
        padding-bottom: 30px;
        border-bottom: 1px solid ${colors.black['10']};
        p {
            font-size: 16px;
        }
        ${(props) => {
            if (props.lastChild) {
                return `
                    border-bottom: none;
                    margin-bottom: 0;
                    padding-bottom: 0;
                `;
            }
        }}
    }
`;

export default Automate;
