import React, { Fragment } from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import Layout from 'components/layout';
import Loader from 'components/loader/loader';
import colors from 'shared-components/theme/colors';
import HomeIntro from 'components/home-intro/home-intro';
import ScrollNav from 'shared-components/scroll-navigation/scroll-navigation';
import {
    BrandSection,
    DeliverySection,
    CallCenterSection,
    CommunitySection,
} from './sections';
import Media from 'shared-components/theme/medias';
import JoinUsBlog from 'components/join-us-blog/join-us-blog';
import { gilroySemibold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';
import { baseURLPage } from 'shared-components/theme/helpers';

const scrollNavData = [
    {
        name: 'home',
        target: 'home',
        offset: -170,
    },
    {
        name: 'perfect',
        target: 'perfect',
        offset: -50,
    },
    {
        name: 'delivery',
        target: 'delivery',
        offset: 0,
    },
    {
        name: 'callCenter',
        target: 'callCenter',
        offset: 0,
    },
    {
        name: 'community',
        target: 'community',
        offset: 0,
    },
];

const lazySections = [
    {
        id: 'perfect',
        section: <BrandSection />,
    },
    {
        id: 'delivery',
        section: <DeliverySection />,
    },
    {
        id: 'callCenter',
        section: <CallCenterSection />,
    },
    {
        id: 'community',
        section: <CommunitySection />,
    },
];

const countries = [
    {
        name: 'USA',
        link: `${baseURLPage}join-us/usa/`,
        target: false,
    },
    {
        name: 'Pakistan',
        link: `${baseURLPage}join-us/pk-sites/`,
        target: '_blank',
    },
    {
        name: 'Philippines',
        link: `${baseURLPage}join-us/philippines/`,
        target: false,
    },
    {
        name: 'Jamaica',
        link: `${baseURLPage}join-us/jamaica/`,
        target: false,
    },
    {
        name: 'Nicaragua',
        link: `${baseURLPage}join-us/nicaragua/`,
        target: false,
    },
    {
        name: 'Honduras',
        link: `${baseURLPage}join-us/honduras/`,
        target: false,
    },
    {
        name: 'Corporate',
        link: `${baseURLPage}join-us/corporate/`,
        target: false,
    },
];

const JoinUsPage = () => {
    return (
        <Layout page="join-us">
            <JoinUsIntro>
                <HomeIntro
                    headingColumn={7}
                    headingOffset={0}
                    mainParaPadding={true}
                    mainTitle={
                        <Fragment>
                            Apply today and <br />
                            help us change
                            <br />
                            an industry
                        </Fragment>
                    }
                    landingVideoUrl={`${baseURL}assets/videos/careers.mp4`}
                    landingVideoImgUrl={`${baseURL}assets/images/careers-video-cover.jpg`}
                    mainPara="Interested in a career at one of the most innovative
                    and fastest-growing customer engagement companies
                    on the planet? Take a look at our openings and get in
                    touch now. If you don’t see anything listed, feel free to
                    reach out anyway, and tell us why you might be a
                    good fit."
                    equalSectionPadding
                    careersSection={
                        <TabsWrapper>
                            {countries &&
                                countries.length > 0 &&
                                countries.map((country, index) => {
                                    return (
                                        <Tab key={index}>
                                            <a
                                                href={country.link}
                                                target={country.target}
                                                rel={
                                                    country.target &&
                                                    'noopener noreferrer'
                                                }>
                                                {country.name}
                                            </a>
                                        </Tab>
                                    );
                                })}
                        </TabsWrapper>
                    }
                />
            </JoinUsIntro>
            <JoinUsBlogsWrapper>
                <JoinUsBlog />
            </JoinUsBlogsWrapper>
            {lazySections && lazySections.length > 0
                ? lazySections.map((item, index) => {
                      return (
                          <div id={item.id} key={index}>
                             {lazySections.length === index + 1 ? (
                                      <ScrollNav data={scrollNavData} />
                                  ) : null}
                                  {item.section}
                          </div>
                      );
                  })
                : null}
                {/* {lazySections && lazySections.map((item,index)=>{
                    return <div></div>
                })} */}
        </Layout>
    );
};

const JoinUsIntro = styled.div`
    background-color: ${colors.gray_light['100']};
`;
const TabsWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 140px;
    align-items: center;
    margin-top: 75px;
    ${Media('xlscreens')} {
        height: 7.36vw;
        margin-top: 3.94vw;
    }
    ${Media('tablet')} {
        height: auto;
        flex-wrap: wrap;
    }
    ${Media('desktopscreens')} {
        height: 80px !important;
    }
`;
const Tab = styled.div`
    height: 100%;
    flex: 0 0 205px;
    max-width: 205px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid;
    cursor: pointer;
    font-size: 30px;
    font-family: ${gilroySemibold};
    a {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    ${Media('xlscreens')} {
        max-width: 10.78vw;
        font-size: 1.57vw;
    }
    &:last-child {
        border: none;
    }
    &:hover {
        background: ${colors.black['100']};
        border-bottom: 5px solid ${colors.pink['100']};
        transition: all 0.3s ease;
        a {
            color: ${colors.white['100']};
        }
    }
    ${Media('tablet')} {
        border: 1px solid !important;
        width: 100%;
        padding: 10px 0px;
        margin: 10px;
        flex: 0 0 100%;
        max-width: 100%;
        &:hover {
            border-bottom: 1px solid;
            a {
                color: ${colors.white['100']};
            }
        }
    }
`;
const JoinUsBlogsWrapper = styled.div`
    background-color: ${colors.gray_light['100']};
`;
export default JoinUsPage;
