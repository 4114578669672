import React from 'react';
import { baseURL } from 'shared-components/theme/helpers';
import {
    TransformSection
} from 'components/industries/common';

export const DigitalTransform = () => {
    return (
        <TransformSection
            tag="Harness the Digital CX Wave."
            sectionHeading=" Manage Complex, Fast, High-Compliance CX <br />Across The Digital Financial Services Ecosystem"
            img={`${baseURL}assets/images/industries/financial-complex.jpg`}
            para1="The Financial Services sector is at the
                tipping point of widescale digital consumer
                adoption, fast becoming the world’s most
                fertile ground for digital, consumer-driven
                innovation. From payments and transfers, to
                banking, to crypto and DeFi, brands across
                the industry are competing to fill the giant
                gap left by legacy brick and mortar
                institutions and become the financial giants
                of tomorrow."
            para2=" Within this mix, delivering a seamless
                customer experience is a more complex value
                proposition than ever before. Your brand’s
                ability to manage omnichannel interactions
                and deliver frictionless customer
                experiences is a make-or-break challenge as
                the race for users, loyalty, and longevity
                drives the industry into completely new
                hyper-growth territory."
        />
    );
};
