import React from "react";
import colors from "shared-components/theme/colors";
import { gilroyMedium } from "shared-components/theme/fonts";
import Media from "shared-components/theme/medias";
import styled from "styled-components";
export const IntroPara = ({ children, noMarginTop }) => {
    return (
        <Wrapper noMarginTop={noMarginTop}>
            {children}
        </Wrapper>
    )
}
const Wrapper = styled.p`
    color:${colors.white['100']};
    font-size: 28px;
    font-family: ${gilroyMedium};
    span{
        color:${colors.pink['100']};
        text-decoration: underline;
    }
    ${props => {
        if (props.noMarginTop) {
            return `
                margin-top:0px;
            `;
        }
    }}
    ${Media('xlscreens')}{
        font-size:1.45vw;
    }
    ${Media('tablet')}{
        font-size:18px;
    }
`;