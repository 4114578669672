import { Container, Row, Col } from '@bootstrap-styled/v4';
import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import styled from 'styled-components';
import { EbookFloatingImage, EbookMainHeading } from 'components/ebook/common';
import Layout from 'components/layout';
import colors from 'shared-components/theme/colors';
import {
    gilroyBlack,
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';
import ScrollAnimation from 'react-animate-on-scroll';
import { RowContentCenter } from 'shared-components/theme/grid-helpers';

const profiles = [
    {
        image: `${baseURL}assets/images/dough-meet-us.png`,
        name: 'Doug Hoffschwelle',
        designation: 'GM & SVP, Hypergrowth CX',
        linkText: 'Chat with Doug at Money 20/20',
        link: 'https://calendly.com/hoffschwelle',
    },
    {
        image: `${baseURL}assets/images/brian-meet-us.png`,
        name: 'Bryan Larimore',
        designation: 'SVP, Business Development',
        linkText: 'Chat with Bryan at Money 20/20',
        link: 'https://calendly.com/bryan-larimore',
    },
    {
        image: `${baseURL}assets/images/anne-meet-us.png`,
        name: 'Anne Jung',
        designation: 'VP, Business Development',
        linkText: 'Chat with Anne at Money 20/20',
        link: 'https://calendly.com/anne-jung/30min',
    },
];

const features = [
    {
        title: 'Disrupt',
        desription:
            'Learn how advanced CX outsourcing can transform loyalty, CSAT, and revenue',
    },
    {
        title: 'Accelerate',
        desription:
            'Discover the power of the Fintech CX Ecosystem, and how it can help you now ',
    },
    {
        title: 'Connect',
        desription:
            'Catch our session above, or hit the links below to schedule some time with our CX experts.',
    },
];

const MeetUs = () => {
    return (
        <Layout>
            <MeetUsLive>
                <Container>
                    <Row>
                        <Col sm="12" lg="12">
                            <MoneyIbexLogo>
                                <img
                                    src={`${baseURL}assets/images/money2020-ibex-logo.png`}
                                    alt="-"
                                />
                            </MoneyIbexLogo>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" lg="6">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <LiveTag noMarginBottom>
                                    Live at Money20/20
                                </LiveTag>
                                <EbookMainHeading
                                    color={colors.white['100']}
                                    lineHeight={1.25}
                                    className="m">
                                    Official Sponsor: <br />
                                    <span>Do. Better. Together.</span>
                                </EbookMainHeading>
                                <LiveMetaInfo>
                                    <li>October 24th</li>
                                    <li>3:10 p.m. PT</li>
                                    <li>
                                        <FaMapMarkerAlt />
                                        Location: The Venetian
                                    </li>
                                </LiveMetaInfo>
                                <LiveContent>
                                    <PagePara>
                                        Catch Julie Casteel, Chief Strategic
                                        Accounts and Marketing Officer, at
                                        Money20/20’s Do. Better. Together
                                        session!
                                    </PagePara>
                                    <PagePara>
                                        ibex and Money20/20 believe that women,
                                        minorities, and underrepresented
                                        professionals should have a louder voice
                                        and more seats at the table in financial
                                        services. Catch us on stage as we work
                                        to amplify those voices, collaborating
                                        with our community to empower real
                                        change and erode systemic bias.
                                    </PagePara>
                                </LiveContent>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12" lg="6">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <EbookFloatingImage
                                    image={`${baseURL}assets/images/money2020-logo.png`}
                                    width="38vw"
                                    top="-30%"
                                    right="-16%"
                                    zIndex="-1"
                                    x={['0', '0']}
                                    className="m"
                                />
                                <LiveTag className="white-right">
                                    FEATURED SPEAKER
                                </LiveTag>
                                <MeetUsProfile>
                                    <img
                                        src={`${baseURL}assets/images/julie-casteel-meet-us.png`}
                                        alt="-"
                                    />
                                    <MeetUsProfileContent>
                                        <h3>Julie Casteel</h3>
                                        <p>
                                            CMO and Chief Strategic Accounts
                                            Officer
                                        </p>
                                    </MeetUsProfileContent>
                                </MeetUsProfile>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                    <Row noGutters>
                        {features.map((item, index) => {
                            return (
                                <Col sm="12" lg="4" key={index}>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOnce={false}
                                        delay={`${index + 2}00`}>
                                        <FeatureBox
                                            last={
                                                index === features.length - 1
                                            }>
                                            <h3>{item.title}</h3>
                                            <PagePara>
                                                {item.desription}
                                            </PagePara>
                                        </FeatureBox>
                                    </ScrollAnimation>
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            </MeetUsLive>
            <MeetIntro>
                <EbookFloatingImage
                    image={`${baseURL}assets/images/20-20-big.svg`}
                    width={1630}
                    right="-45%"
                    top="3%"
                    zIndex="-1"
                    x={['20%', '-20%']}
                />
                <Container>
                    <Row>
                        <Col sm="12" lg="6">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={false}>
                                <MeetIntroContent>
                                    <MoneyLogo>
                                        <img
                                            src={`${baseURL}assets/images/money2020.svg`}
                                            alt="-"
                                        />
                                    </MoneyLogo>
                                    <EbookMainHeading
                                        color={colors.fintech['text']}>
                                        Scalable, <br /> Volume-Ready CX, at
                                        Money20/20
                                    </EbookMainHeading>
                                    <PagePara>
                                        You’re an industry-disrupting financial
                                        technology leader. We’re a leading CX
                                        outsourcer building the support system
                                        that protects your customer loyalty,
                                        CSAT, and revenue.
                                    </PagePara>
                                    <PagePara>
                                        As a proud sponsor of Money20/20, we’re
                                        ready to chat with CX leaders and
                                        showcase the latest in Fintech CX
                                        outsourcing, with a focus on people,
                                        security, governance, and technology.
                                    </PagePara>
                                </MeetIntroContent>
                            </ScrollAnimation>
                        </Col>
                        <Col sm="12" lg="6">
                            <RowContentCenter>
                                {profiles.map((item, index) => {
                                    return (
                                        <Col sm="12" md="6" key={index}>
                                            <ScrollAnimation
                                                animateIn="fadeInUp"
                                                animateOnce={false}
                                                delay={`${index + 2}00`}>
                                                <MeetUsProfile>
                                                    <img
                                                        src={item.image}
                                                        alt="-"
                                                    />
                                                    <MeetUsProfileContent>
                                                        <h3>{item.name}</h3>
                                                        <p>
                                                            {item.designation}
                                                        </p>
                                                    </MeetUsProfileContent>
                                                    <MeetUsProfileLink
                                                        href={item.link}
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        {item.linkText}
                                                    </MeetUsProfileLink>
                                                </MeetUsProfile>
                                            </ScrollAnimation>
                                        </Col>
                                    );
                                })}
                            </RowContentCenter>
                        </Col>
                    </Row>
                </Container>
            </MeetIntro>
        </Layout>
    );
};

const MeetIntro = styled.section`
    position: relative;
    margin-bottom: -80px;
    z-index: 10;
    ${Media('xlscreens')} {
        margin-bottom: -4.5vw;
    }
`;

const MeetIntroContent = styled.div`
    padding-right: 50px;
    ${Media('xlscreens')} {
        padding-right: 3.3vw;
    }
`;

const MoneyLogo = styled.div`
    margin-bottom: 30px;
    img {
        max-width: 160px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 2vw;
        img {
            max-width: 8.28vw;
        }
    }
`;

const MoneyIbexLogo = styled.div`
    margin-bottom: 30px;

    img {
        width: 14vw;
        min-width: 218px;
    }

    ${Media('xlscreens')} {
        margin-bottom: 2vw;
    }
`;

const PagePara = styled.p`
    font-size: 20px;
    font-family: ${gilroyMedium};
    margin-top: 0;
    margin-bottom: 25px;
    ${Media('xlscreens')} {
        margin-bottom: 1.5vw;
        font-size: 1.15vw;
    }
    ${Media('mobile')} {
        font-size: 16px;
    }
`;

const MeetUsProfile = styled.div`
    max-width: 322px;
    margin: 50px auto;
    img {
        max-width: 100%;
        max-height: 410px;
    }
    ${Media('xlscreens')} {
        max-width: 22vw;
        margin-top: 0;
        margin-bottom: 30px;
        img {
            max-height: 22.5vw;
        }
    }
    ${Media('tablet')} {
        margin-top: 20px;
        margin-bottom: 50px;
    }
`;

const MeetUsProfileContent = styled.div`
    background-color: ${colors.gray_lightest['50']};
    padding: 20px;
    text-align: center;
    h3,
    p {
        color: ${colors.fintech['text']};
        margin: 0;
        line-height: 1.5;
    }
    h3 {
        font-size: 22px;
        font-family: ${gilroyBold};
    }
    p {
        font-size: 16px;
        font-family: ${gilroyMedium};
    }
    ${Media('xlscreens')} {
        padding: 0.5vw 1vw;
        h3 {
            font-size: 1.25vw;
        }
        p {
            font-size: 0.95vw;
        }
    }
`;

const MeetUsProfileLink = styled.a`
    background-color: ${colors.pink['100']};
    padding: 15px;
    font-size: 16px;
    font-family: ${gilroyBold};
    color: ${colors.white['100']};
    cursor: pointer;
    text-align: center;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: ${colors.white['100']};
        transform: translateX(-100%);
        transition: all 330ms ease;
    }
    &:hover {
        color: ${colors.pink['100']};
        &:before {
            transform: translateX(0%);
        }
    }
    ${Media('xlscreens')} {
        min-height: 3.6vw;
        font-size: 0.95vw;
        border-bottom-left-radius: 0.53vw;
        border-bottom-right-radius: 0.53vw;
    }
`;

const FeatureBox = styled.div`
    background-color: ${colors.gray_lighter['100']};
    min-height: 270px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 50px;
    margin-right: ${(props) => (props.last ? '0' : '5px')};
    margin-top: 50px;
    h3,
    p {
        color: ${colors.fintech['text']};
        margin-top: 0px;
    }
    h3 {
        font-size: 28px;
        font-family: ${gilroyBlack};
        margin-bottom: 30px;
        text-transform: uppercase;
    }
    p {
        margin-bottom: 0px;
    }
    ${Media('xlscreens')} {
        padding: 1vw 3.5vw;
        min-height: 14.375vw;
        margin-top: 3.3vw;
        h3 {
            font-size: 1.45vw;
            margin-bottom: 2vw;
        }
    }
    ${Media('tablet')} {
        margin-top: 0px;
        margin-bottom: ${(props) => (props.last ? '0' : '5px')};
    }
`;

const MeetUsLive = styled.section`
    // padding-top: 150px;
    background-color: ${colors.fintech['text']};
    position: relative;
    z-index: 9;
    ${Media('xlscreens')} {
        padding-top: 5vw;
        padding-bottom: 0;
        .row {
            // align-items: center;
        }
        ${MeetUsProfile} {
            margin-right: 0;
        }
    }
    ${Media('tablet')} {
        &:before {
            display: none;
        }
    }
`;

const LiveTag = styled.div`
    background-color: ${colors.meet['pink']};
    color: ${colors.fintech['text']};
    padding: 15px 25px;
    font-size: 20px;
    font-family: ${gilroyBold};
    text-transform: uppercase;
    margin-bottom: 30px;
    display: inline-flex;
    justify-content: center;
    letter-spacing: 2px;
    text-align: center;

    &.white-right {
        margin-right: -0.5vw;
        float: right;
        color: ${colors.white['100']};
    }

    ${Media('xlscreens')} {
        font-size: 1.1vw;
        padding: 0.68vw 1.5vw;
        letter-spacing: 0.2vw;
        min-width: 18vw;
        margin-bottom: 1.5vw;
    }
    ${(props) => {
        if (props.noMarginBottom) {
            return `
                margin-bottom:0 !important;
            `;
        }
    }}
`;

const LiveMetaInfo = styled.ul`
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
    top: -20px;
    li {
        font-size: 18px;
        font-family: ${gilroySemibold};
        color: ${colors.white['100']};
        margin-right: 30px;
        display: flex;
        align-items: center;
        &:last-child {
            margin-right: 0;
            font-size: 22px;
        }
        svg {
            color: ${colors.meet['pink']};
            margin-right: 15px;
        }
    }
    ${Media('xlscreens')} {
        top: -1vw;
        margin-bottom: 1.5vw;
        li {
            font-size: 1vw;
            margin-right: 1vw;
            svg {
                font-size: 1.15vw;
                margin-right: 1vw;
            }
            &:last-child {
                padding-left: 1vw;
                border-left: 1px solid rgba(255, 255, 255, 0.16);
                margin-right: 0px;
                font-size: 1.25vw;
            }
        }
    }
    ${Media('mobile')} {
        flex-direction: row;
        align-items: flex-start;
        font-size: 16px;

        li {
            margin-right: 10px;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0px;
                font-size: 16px;

                svg {
                    margin-right: 5px;
                }
            }
        }
    }
`;

const LiveContent = styled.div`
    p {
        color: ${colors.white['100']};
    }

    ${Media('tablet')} {
        max-width: 95%;
    }
    ${Media('mobile')} {
        max-width: 100%;
    }
`;

export default MeetUs;
