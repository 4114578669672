import React from 'react';
import { LetsDiscuss, AmplifySection } from 'components/industries/common';
export const AmplifyBottomSec = ({ amplifyList }) => {
    return (
        <>
            <AmplifySection
                amplifyList={amplifyList}
                tag="PEOPLE. TECHNOLOGY. SUCCESS."
                heading="Deliver Award-Winning Financial Services CX, Now"
                para="For every touchpoint in your network, we are
                ready to deploy people, technology, and
                expertise to manage and solve your <br />
                complex customer experience needs."
            />
            <LetsDiscuss
                heading="Looking to level-up your Financial Services CX?"
                btnText="Let's Talk."
            />
        </>
    );
};
