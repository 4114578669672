import React from 'react';
import { Redirect } from 'react-router-dom';

const CommunityBankMemberExp2 = () => {
    return (
        <Redirect
            to={`/resources/ebooks/community-bank-digital-customer-experience-part-2`}
        />
    );
};

export default CommunityBankMemberExp2;
