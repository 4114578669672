import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import {
    gilroyHeavy,
    gilroyLight,
    gilroySemibold,
} from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

const PharmaIntro = ({
    introPara,
    introImage,
    introHeading,
    introSubHeading,
    headingPaddingTop,
}) => {
    return (
        <Wrapper>
            {introSubHeading && (
                <IntroSubHeading>{introSubHeading}</IntroSubHeading>
            )}
            <IntroHeading headingPaddingTop={headingPaddingTop}>
                {introHeading}
            </IntroHeading>
            <ParaSection>
                <p>{introPara}</p>
            </ParaSection>
            <ClientsLogo>
                <img
                    src={`${baseURL}assets/images/clients-logos.svg`}
                    alt="-"
                />
            </ClientsLogo>
            <ImageSection>
                <img src={introImage} alt="-" />
            </ImageSection>
        </Wrapper>
    );
};

const ClientsLogo = styled.div`
    padding: 0;
    margin-bottom: 113px;
    ${Media('tablet')} {
        margin-bottom: 30px;
    }
    ${Media('xlscreens')} {
        margin-bottom: 5.9vw;
    }
    img {
        width: 100%;
    }
`;
const Wrapper = styled.div`
    ${Media('xlscreens')} {
        margin-top: 5.23vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 76vw;
    }
    ${Media('tablet')} {
        padding-top: 30px;
    }
`;
const IntroHeading = styled.div`
    font-size: 80px;
    font-family: ${gilroySemibold};
    line-height: 1.21;
    letter-spacing: -1px;
    margin: 30px 0px;
    text-align: center;
    ${Media('tablet')} {
        font-size: 38px;
        text-align: left;
        br {
            display: none;
        }
    }
    ${Media('xlscreens')} {
        font-size: 3.8vw;
        margin: 1.57vw 0;
    }
`;
const IntroSubHeading = styled.div`
    font-size: 28px;
    font-family: ${gilroyHeavy};
    ${Media('xlscreens')} {
        font-size: 1.46vw;
    }
`;
const ParaSection = styled.div`
    p {
        font-size: 24px;
        font-family: ${gilroyLight};
        line-height: 1.91;
    }
    ${Media('xlscreens')} {
        text-align: center;
        position: relative;
        z-index: 11;
        border-bottom-right-radius: 0.52vw;
        padding-bottom: 2.5vw;
        padding-left: 0px;
        padding-right: 2.61vw;
        p {
            font-size: 1.25vw;
        }
    }
    ${Media('tablet')} {
        p {
            font-size: 18px;
            br {
                display: none;
            }
        }
    }
`;
const ImageSection = styled.div`
    ${Media('tablet')} {
        img {
            width: 100%;
        }
    }
    ${Media('xlscreens')} {
        img {
            width: 100%;
        }
    }
`;
export default PharmaIntro;
