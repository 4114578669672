import React from 'react';
import {
    LetsDiscuss,
    AmplifySection,
    IndustryCaseStudy,
} from 'components/industries/common';
import { baseURL } from 'shared-components/theme/helpers';
import { baseURLPage } from 'shared-components/theme/helpers';

export const CaseStudyAmplify = ({ amplifyList }) => {
    return (
        <>
            <IndustryCaseStudy
                tag="Webinar"
                image={`${baseURL}assets/images/industries/dl-webinar-image.jpg`}
                preheading="WEBINAR-ON-DEMAND"
                heading="Helping FedEx Strike The <br /> Right Balance Between <br /> Work-from-Home and In- <br /> Center CX Delivery"
                btnText="Watch Now"
                caseLink={`${baseURLPage}resources/webinars/g/how-fedex-achieved-in-center-at-home-cx`}
            />
            <AmplifySection
                amplifyList={amplifyList}
                tag="EVERY TOUCHPOINT MATTERS."
                heading="Deliver Fast & Frictionless CX, Now"
                para="There’s no better time than now to begin optimizing
                your multi-touchpoint Delivery & Logistics CX <br />
                ecosystem for fast, frictionless, and flexible CX."
            />
            <LetsDiscuss
                heading="Looking to level-up your delivery & logistics ROI?"
                btnText="Let's Talk."
            />
        </>
    );
};
